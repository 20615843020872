import React, { Component } from "react"
import { UserConsumer } from "../../contexts/User"
import ImageCropUtility from "../ImageCropUtility"
class CropUtilityModal extends Component {
    state = {
        croppedBlob: null
    }
    updateBlobbedFile = (blobFile)=>{

        let file = new File([blobFile], this.props.file?.name || "croppedImage.jpg", {lastModified: new Date(), type: blobFile?.type})
        this.setState({croppedBlob: [file]})
    }
    // updating our input fields as the user enters keys
    render() {
        return (
            <UserConsumer>
                {({ user, login, loginMessage }) => (
                    <>
                        <header className="modal-header">
                            <h2 id="add_scene_title" className="modal-title">Crop Your Photo</h2>
                            <button aria-label="Close Modal" data-micromodal-close="add_to_project_modal" className="modal-close" type="button" onClick={this.props.handleClose}></button>
                        </header>


                        {/* // // {# Modal Content #} */}
                        <div id="add_scene_content" class="modal-content" >
                           <ImageCropUtility isProfilePhoto={this.props.isProfilePhoto} user={user} file={this.props.file} updateBlobbedFile={this.updateBlobbedFile} />
                            {/* {# End Form #} */}

                        </div>
                        <div className="modal-footer">
                            <div className="input-group mb-10" style={{maxWidth:"80%", margin: "auto"}}>
                                <div className="grid-row-sm">
                                    <div className="col w-1/2 mb-20 sm:mb-0">
                                        <button onClick={this.props.handleClose} className="btn btn-stroke form-btn w-full" type="button" aria-label="Close Modal" data-micromodal-close="create_project_modal">Cancel</button>
                                    </div>
                                    <div className="col w-1/2">
                                        <button onClick={()=>this.props.saveCrop(this.state.croppedBlob)} className="btn btn-primary form-btn w-full">Save Crop</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}</UserConsumer>
        )
    }

}
export default CropUtilityModal

const pricingFeatures = {
  blank: {
    name: "",
    description: ""
  },
  commercialWork: {
    name: "Coverage for Client & Commercial Work",
    description: "Subscription includes usage for commercial work for you and your clients."
  },
  discountedPricing10: {
    name: "Discounted Pricing for Other Coverage",
    description:
      "You will receive 10% off any license that is not part of your subscription plan.",
  },
  discountedPricing25: {
    name: "Discounted Pricing for Other Coverage",
    description:
      "You will receive 25% off any license that is not part of your subscription plan.",
  },
  monetization: {
    name: "Includes Monetization",
    description: "You will be able to clear any copyright claims to be able to fully monetize your content.",
  },
  monetizationPurchased: {
    name: "Purchased Licenses Includes Monetization",
    description: "You will be able to clear any copyright claims to be able to fully monetize your content.",
  },
  payPerLicense: {
    name: "Pay Per Music License",
    description: "You will pay for each Music License individually.",
  },
  prioritySupport: {
    name: "Priority Support",
    description:
      "You will be put at the top of the queue and receive faster response times from our customer support team.",
  },
  unlimitedLicensingPersonal: {
    name: "Music Licensing for Personal Use",
    description: "Check out as many track licenses as you need for your projects during your subscription term."
  },
  unlimitedLicensingCommercial: {
    name: "Music Licensing for Commercial Use",
    description: "Check out as many track licenses as you need for your projects during your subscription term."
  },
  unlimitedLicensingCustom: {
    name: "Music Licensing for your Custom Needs",
    description: "Check out as many track licenses as you need for your projects during your subscription term."
  },
  unwatermarkedPreviews: {
    name: "Un-watermarked Previews",
    description:
      "You will be able to download full previews of tracks and versions of the track without the Deep Sounds audio watermark.",
  },
  whitelisting: {
    name: "Channel Whitelisting",
    description: "Add your YouTube channel to our whitelist to automatically clear any copyright claims instantly."
  },
  broadcastTVTheatrical: {
    name: "Broadcast, TV, & Theatrical",
    description: "Get a custom plan that would cover content aired on TV or screened theatrically."
  },
  filmsGamesApps: {
    name: "Feature Films, Video Games, & Apps",
    description: "Get a Custom subscription for Feature Films, Video Games, and Apps."
  },
  onlineSocialMediaPersonal: {
    name: "Online, Social Media, & Podcasts",
    description: "Plan covers you for personal use content published on social media, podcasts, or online."
  },
  onlineSocialMediaCommercial: {
    name: "Online, Social Media, & Podcasts",
    description: "Plan covers you for commercial content published on social media, podcasts, or online."
  },
  onlineSocialMediaCustom: {
    name: "Online, Social Media, & Podcasts",
    description: "Plan covers you for content published social media, podcasts, or online."
  }
}

const pricingPlans = [
  {
    title: "Personal Use",
    priceType: "personal",
    price: {
      month: "$15/month",
      year: "$9/month"
    },
    priceSubtext: {
      year: "billed yearly at $108"
    },
    description:
      "Unlimited Music for individual content creators for content published online",
    includedFeatures: [
      pricingFeatures.onlineSocialMediaPersonal,
      pricingFeatures.monetization,
      pricingFeatures.whitelisting,
      pricingFeatures.discountedPricing10,
      pricingFeatures.unwatermarkedPreviews
    ],
    notIncluded: [
      pricingFeatures.commercialWork,
      pricingFeatures.broadcastTVTheatrical,
      pricingFeatures.filmsGamesApps
    ],
    cardClass: "border-gray-400",
    textClass: "text-gray-400",
    buttonClass: "btn-light-gray",
  },
  {
    title: "Commercial Use",
    priceType: "commercial",
    price: {
      month: "$49/month",
      year: "$20/month"
    },
    priceSubtext: {
      year: "billed yearly at $240"
    },
    description:
      "Unlimited Music for freelancers and businesses for content published online",
    includedFeatures: [
      pricingFeatures.onlineSocialMediaCommercial,
      pricingFeatures.monetization,
      pricingFeatures.whitelisting,
      pricingFeatures.discountedPricing25,
      pricingFeatures.unwatermarkedPreviews,
      pricingFeatures.commercialWork
    ],
    notIncluded: [
      pricingFeatures.broadcastTVTheatrical,
      pricingFeatures.filmsGamesApps
    ],
    cardClass: "border-primary",
    textClass: "text-primary",
    buttonClass: "btn-primary",
  },
  {
    title: "Custom",
    priceType: "custom",
    price: {
      month: "Custom",
      year: "Custom"
    },
    description:
      "Unlimited Music tailored to your specific needs",
    includedFeatures: [
      pricingFeatures.onlineSocialMediaCustom,
      pricingFeatures.monetization,
      pricingFeatures.whitelisting,
      pricingFeatures.discountedPricing25,
      pricingFeatures.unwatermarkedPreviews,
      pricingFeatures.commercialWork,
      pricingFeatures.broadcastTVTheatrical,
      pricingFeatures.filmsGamesApps
    ],
    notIncluded: [
    ],
    cardClass: "",
    textClass: "text-gray-400",
    buttonClass: "btn-stroke",
  },
];

export default pricingPlans;

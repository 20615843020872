const downloadURL = ({ filename, url }) => {
  let downloadEl = document.createElement("a");
  downloadEl.setAttribute("href", url);
  downloadEl.setAttribute("download", filename.split(" ").join(""));
  downloadEl.style.display = "none";
  document.body.appendChild(downloadEl);
  downloadEl.click();
  // console.log("download el ", downloadEl);
};

export default downloadURL;

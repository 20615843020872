import React from 'react'

const FilterEnabled = (props) => {

  const { 
    addSearchOption,
    excludeSearchOption,
    isMobile,
    option,
    selectedSearchOptions,
    type
  } = props;

  let addButtonClasses = "icon-plus";
  let excludeButtonClasses = "icon-ignore";
  const mobileButtonClasses = "circle-icon circle-icon-sm text-icon-sm ";

  if (isMobile) {
    addButtonClasses = mobileButtonClasses + addButtonClasses;
    excludeButtonClasses = mobileButtonClasses + excludeButtonClasses;
  }

  return (
    <li
      className={`filter-tag ${
        selectedSearchOptions.filter((selectedOption) => {
          return selectedOption.value === option.value;
        }).length > 0 &&
        (selectedSearchOptions.filter((selectedOption) => {
          return selectedOption.value === option.value;
        })[0].include
          ? "is-active"
          : "is-ignored")
      }
          `}
      key={"filter" + type + option.label}
    >
      <div
        onClick={() =>
          addSearchOption(
            option.label,
            option.value,
            option.type,
            option.id
          )
        }
        className="filter-tag-label"
      >
        {option.label}
      </div>
      <div className="filter-tag-actions">
        <button
          type="button"
          className={addButtonClasses}
          onClick={() =>
            addSearchOption(
              option.label,
              option.value,
              type,
              option.id
            )
          }
        />
        <button
          type="button"
          className={excludeButtonClasses}
          onClick={() =>
            excludeSearchOption(
              option.label,
              option.value,
              type,
              option.id
            )
          }
        />
      </div>
    </li>
  )
}

export default FilterEnabled
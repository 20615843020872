import React, { Component } from "react";
import MicroModal from "react-micro-modal";
import MediaQuery from 'react-responsive';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Sticky from 'react-sticky-el';
import { Tooltip } from "react-tippy";
import 'react-tippy/dist/tippy.css';
import { toast } from "react-toastify";
import socketIOClient from "socket.io-client";
import resolveConfig from "tailwindcss/resolveConfig";
import { UserConsumer } from "../../contexts/User";
import { confirm } from "../confirmModal/index";
import CharacterCount from "../FormElements/characterCount";
import customStyles from "../FormElements/customStyles";
import customStylesMulti from "../FormElements/customStylesMulti";
import KeywordsInput from '../FormElements/keywordsInput';
import TechnicalRequirementsLearnMore from "../Links/technicalRequirementsLearnMore";
import VersionsExport from "../Links/versionsExport";
import CircularIndeterminant from "../Loading/circularIndeterminant";
import AlbumModal from "../modal/albumModal";
import ApprovalModal from "../modal/approvalModal";
import RejectionCommentModal from "../modal/rejectionCommentModal";
import tailwindConfig from "../../tailwind.config";
import { Error, Success, SuccessRedirect } from "../Toast/index";
import api from '../utils/api';
import FullTrackWaveform from "../Waveform/forAdminApproveFullTrack";
import Waveform from "../Waveform/forVersionsAndStems";
import AdminTrackTooltip from "./adminTrackTooltip";
import LoadingModal from "./loadingModal";
import formatAlbumLabel from "../FormElements/TrackForm/formatAlbumLabel";
import versionNameOptions from "../FormElements/TrackForm/versionNameOptions"


const tw = resolveConfig(tailwindConfig);

// Styles for Multi-Select

const customStylesCity = {
    option: (provided, state) => {
      console.log(state);
      return {
        // ...provided,
        borderBottom: "1px solid hsla(0, 0%, 100%, .05)",
        backgroundColor: tw.theme.colors.gray[200],
        padding: tw.theme.spacing[15] + " " + tw.theme.spacing[20],
        transition: "color 0.3s",
        cursor: state.isDisabled ? "default" : "pointer",
        color:
          state.isFocused
            ? tw.theme.colors.white || state.isDisabled
            : tw.theme.colors.gray[400]
      };
    },
    control: (provided, state) => {
      return {
        ...provided,
        border: "none",
        backgroundColor: tw.theme.colors.gray[200],
        minHeight: tw.theme.spacing[60],
        borderRadius: "none",
        cursor: "pointer",
        boxShadow: 0
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        marginTop: "0",
        marginBottom: "0",
        backgroundColor: tw.theme.colors.gray[200],
        position: "absolute",
        boxShadow: tw.theme.boxShadow.lg,
        borderRadius: "none"
      };
    },
    menuList: (provided, state) => {
      return {
        ...provided,
        scrollbarColor: "hsla(0, 0%, 100%, .2) transparent",
        scrollbarWidth: "thin",
        "::-webkit-scrollbar": {
          width: "0.375rem"
        },
        "::-webkit-scrollbar-track": {
          backgroundColor: "transparent"
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "hsla(0, 0%, 100%, .2)",
          borderRadius: tw.theme.borderRadius.full
        }
      };
    },
    input: (provided, state) => {
      //   console.log("input ", provided, " ", state);
      return {
        // ...provided,
        color: tw.theme.colors.white,
        lineHeight: tw.theme.lineHeight.snug
      };
    },
    placeholder: (provided, state) => {
      return {
        ...provided,
        color: tw.theme.colors.gray[400],
        margin: "0",
        display: "flex"
      };
    },
    singleValue: (provided, state) => {
      return {
        ...provided,
        color: "white",
        margin: "0",
        lineHeight: tw.theme.lineHeight.snug
      };
    },
    valueContainer: (provided, state) => {
      //   console.log("val ", provided);
      return {
        ...provided,
        alignSelf: "stretch",
        padding: tw.theme.spacing[10] + " " + tw.theme.spacing[20],
        backgroundColor: "#1c1c1c"
      };
    },
    dropdownIndicator: (provided, state) => {
      //   console.log("drop  ", provided);
      return {
        ...provided,
        transition: "all .2s ease",
        transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
        color: tw.theme.colors.gray[500],
        cursor: "pointer",
        backgroundColor: "transparent",
        padding: "0 " + tw.theme.spacing[15],
        ":hover": { color: "white" }
      };
    },
    indicatorSeparator: (provided, state) => {
      return {
        ...provided,
        width: "0",
        height: "0"
      };
    },
    indicatorsContainer: (provided, state) => {
      //   console.log("ind con  ", provided);
      return {
        ...provided,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      };
    }
  };


class AdminApproveTrack extends Component {
    state = {
        approvability: "incomplete",
        title: "",
        isLoadingModalOpen: false,
        isAlbumModalOpen: false,
        album: "",
        myAlbums: [ ],
        file: "",
        funFact: "",
        genreOptions: [ ],
        emotionOptions: [ ],
        moodOptions: [ ],
        attributeOptions: [ ],
        instrumentOptions: [ ],
        cityOptions: [],
        bpmOptions: [],
        genres: [ ],
        emotions: [ ],
        moods: [ ],
        nowPlaying: "stop",
        attributes: [ ],
        instruments: [ ],
        cities: [],
        containsLyrics: "true",
        explicitLyrics: "false",
        trackBPM: [],
        versionsAvailable: "false",
        stemsAvailable: "false",
        rejectionComment: "",
        mainTrackRejectionBody:{
            trackQuality: "approve",
            trackURL: ""
        },
        additionalVersions: [],
        stems: [],
        tagStems: [],
        incompleteTaggedStems: [],
        incompleteCustomStems: [],
        completeTaggedStems: [],
        completeCustomStems: [],
        stems: [],
        totalSteps: 6,
        totalVersionSteps: 5,
        totalStemSteps: 4,
        isApprovalModalOpen: false,
        versionsRejectionBody: [],
        stemsRejectionBody: [],
    }
//updating our input fields as the user enters keys
handleInputChange = event => {
    this.props.handleFormUnsaved(true)
    let detailsComplete

    const { name, value } = event.target;
    this.setState({
        [name]: value,
    }, ()=>{
        if(this.state.title.length > 0 && this.state.album){
            detailsComplete = true
        }
        else{
            detailsComplete = false
        }
        this.setState({detailsComplete: detailsComplete})
    });
}
albumLoadingCB=(isLoading)=>{
    this.setState({albumLoading: isLoading})
}
fileFilterMainTrack = ()=>{
    //api call to file filter
        this.setState({
            mainTrackLoading: true,
            mainTrackApprovalStepDisplay: "Checking File Type"
        })
        api.mainTrackFileFilter(this.props.match.params.trackId)
            .then(res => {
                //on success update approvalStep to virus scan (done on backend)
                if(res?.data.success){
                    //call virusScan
                    let progress = Math.round(100* (1/this.state.totalSteps))
                    this.setState({approvalStep: "virus scan", mainTrackLoading: false, mainTrackApprovalStepDisplay: "Scanning for Viruses", mainTrackStatusIcon: "icon-time", mainTrackProgress: progress,
                    mainTrackRejectionBody: {trackQuality: "approve", trackURL: this.state.wavURL}, mainTrackFileFilter: true, mainTrackFileFilterComplete: true
                },
                    ()=>this.virusScanMainTrack())
                }
                else{
                    this.setState({approvalStep: "file filter" ,mainTrackApprovalError: true, mainTrackLoading: false, mainTrackStatusIcon: "icon-warning text-primary",
                    mainTrackApprovalStepDisplay: "Error Checking File Type"})
                    Error({message:"Error Checking File Type"})
                }
            })
            .catch(error=>{
                if(error?.response?.status === 415){
                    console.log("full error " ,error?.response)
                    // console.log("json eror ", error?.response?.json())
                    let errorMessage = error?.response?.data?.message;
                    let reason
                    if(errorMessage.indexOf("Bit Depth")){
                        reason = "bit_depth"
                    }
                    else if(errorMessage.indexOf("Sample Rate")){
                        reason = "sample_rate"
                    }
                    else if(errorMessage.indexOf("Invalid File Type")){
                        reason = "invalid_file"
                    }
                    console.log("error message " ,error?.response?.message)
                    Error({message: "Invalid File Type"})
                    this.setState({approvalStep: "file filter" ,mainTrackApprovalError: null, mainTrackLoading: false, mainTrackStatusIcon: "icon-warning text-primary",
                    mainTrackApprovalStepDisplay: "Invalid File Type", invalidFileType: true, mainTrackFileFilterComplete: true, mainTrackRejectionBody: {trackQuality: "reject", trackURL: this.state.wavURL, reason: reason}})
                    // let rejectionBody = {}
                    // rejectionBody.content = this.state.contentCheck
                    // rejectionBody.curation = this.state.curationCheck
                    // rejectionBody.exclusivity = this.state.exclusivityCheck
                    // rejectionBody.trackQuality = "reject"
                    // rejectionBody.trackURL = this.state.wavURL
                    // let versions = []
                    // this.state.additionalVersions.forEach(version=>{
                    //     versions.push({id: version.id, wavURL: version.wavURL, approval: version.approval })
                    // })
                    // if(versions.length > 0){
                    //     rejectionBody.versions = versions
                    // }
                    // let stems = []
                    // this.state.stems.forEach(stem=>{
                    //     stems.push({id: stem.id, wavURL: stem.wavURL, approval: stem.approval})
                    // })
                    // if(stems.length > 0){
                    //     rejectionBody.stems = stems
                    // }
                    // this.setState({mainTrackRejectionBody:{
                    //     trackQuality: "reject",
                    //     trackURL: this.state.wavURL
                    // }})
                    // api.rejectForInvalidFileType(this.props.match.params.trackId, this.state.localname, rejectionBody)
                }
                else{
                    Error({message: error?.response?.message || "Error Checking File Type"})
                    this.setState({approvalStep: "file filter" ,mainTrackApprovalError: true, mainTrackLoading: false, mainTrackStatusIcon: "icon-warning text-primary",
                    mainTrackApprovalStepDisplay: "Error Checking File Type"})
                }

            })

}

virusScanMainTrack =()=>{
        if(this.state.invalidFileType){
            return
        }
        else{
            this.setState({mainTrackLoading: true, mainTrackApprovalStepDisplay: "Scanning for viruses"})
            //api call to virus scan
            api.mainTrackVirusScan(this.props.match.params.trackId)
                .then(res=>{
                    //on success update approvalStep to virus scanned
                    //call watermark
                    if(res?.data.success){
                        let progress = Math.round(100* (2/this.state.totalSteps))
                        this.setState({approvalStep: "mp3 encode", mainTrackApprovalError: false, mainTrackLoading: false, mainTrackStatusIcon: 'icon-time', mainTrackApprovalStepDisplay: "Encoding MP3", mainTrackProgress: progress,
                        mainTrackVirusScanComplete: true,},
                        ()=>this.mp3EncodeMainTrack())

                    }
                    else if(res?.data.virus){
                        Error({message: "Potential Virus Detected"})
                        this.setState({approvalStep: "virus scan" ,mainTrackApprovalError: null, mainTrackLoading: false, mainTrackStatusIcon: "icon-warning text-primary", mainTrackVirusScanComplete: true,
                        mainTrackApprovalStepDisplay: "Potential Virus Detected!", virusDetected: true, mainTrackRejectionBody: {trackQuality: "reject", trackURL: this.state.wavURL, reason: "virus_detected"}})
                        // let rejectionBody = {}
                        // rejectionBody.content = this.state.contentCheck
                        // rejectionBody.curation = this.state.curationCheck
                        // rejectionBody.exclusivity = this.state.exclusivityCheck
                        // rejectionBody.trackQuality = "reject"
                        // rejectionBody.trackURL = this.state.wavURL
                        // let versions = []
                        // this.state.additionalVersions.forEach(version=>{
                        //     versions.push({id: version.id, wavURL: version.wavURL, approval: version.approval })
                        // })
                        // if(versions.length > 0){
                        //     rejectionBody.versions = versions
                        // }
                        // let stems = []
                        // this.state.stems.forEach(stem=>{
                        //     stems.push({id: stem.id, wavURL: stem.wavURL, approval: stem.approval})
                        // })
                        // if(stems.length > 0){
                        //     rejectionBody.stems = stems
                        // }

                        // api.rejectForVirusDetected(this.props.match.params.trackId, this.state.localname, rejectionBody)
                    }
                    else{
                        Error({message: "Error Scanning for Virus"})
                        this.setState({approvalStep: "virus scan" ,mainTrackApprovalError: true, mainTrackLoading: false, mainTrackStatusIcon: "icon-warning text-primary",
                        mainTrackApprovalStepDisplay: "Error Scanning for Virus"})
                    }
                })
                .catch(error=>{
                    Error({message: error?.response?.message || "Error Scanning for Virus"})
                    this.setState({approvalStep: "virus scan" ,mainTrackApprovalError: true, mainTrackLoading: false, mainTrackStatusIcon: "icon-warning text-primary",
                    mainTrackApprovalStepDisplay: "Error Scanning for Virus"})
                })
        }

}
mp3EncodeMainTrack = ()=>{
    if(this.state.invalidFileType || this.state.virusDetected){
        return
    }
    else{
        this.setState({mainTrackLoading: true, mainTrackApprovalStepDisplay: "Encoding MP3"})
        //api call to mp3 encode
        api.mainTrackMP3Encode(this.props.match.params.trackId)
            .then(res=>{
                //on sucess update approval step to mp3 encoded
                if(res?.data.success){
                    let progress = Math.round(100* (3/this.state.totalSteps))
                    this.setState({approvalStep: "watermark", mainTrackApprovalError: false, mainTrackLoading: false, mainTrackStatusIcon: 'icon-time', mainTrackApprovalStepDisplay: "Watermarking Track",
                    mainTrackProgress: progress},
                    ()=>this.watermarkMainTrack())
                }
                else{
                    Error({message: "Error Encoding Track"})
                    this.setState({approvalStep: "mp3 encode" ,mainTrackApprovalError: true, mainTrackLoading: false, mainTrackStatusIcon: "icon-warning text-primary",
                    mainTrackApprovalStepDisplay: "Error Encoding Track"})
                }
            })
            .catch(error=>{
                Error({message: error?.response?.message || "Error Encoding Track"})
                this.setState({approvalStep: "mp3 encode" ,mainTrackApprovalError: true, mainTrackLoading: false, mainTrackStatusIcon: "icon-warning text-primary",
                mainTrackApprovalStepDisplay: "Error Encoding Track"})
            })
    }
    //call watermark
}
watermarkMainTrack = ()=>{
    if(this.state.invalidFileType || this.state.virusDetected){
        return
    }
    else{
        this.setState({mainTrackLoading: true, mainTrackApprovalStepDisplay: "Watermarking Track"})
        //api call to water mark main track
        api.mainTrackWaterMark(this.props.match.params.trackId)
            .then(res=>{
            //on success update approvalStep to watermarked
                if(res?.data.success){
                    let progress = Math.round(100* (4/this.state.totalSteps))
                    this.setState({approvalStep: "mp3 preview", mainTrackApprovalError: false, mainTrackLoading: false, mainTrackStatusIcon: 'icon-time', mainTrackApprovalStepDisplay: "Creating 30s Preview",
                        mainTrackProgress: progress},
                    ()=>this.mp3PreviewMainTrack())
                }
                else{
                    Error({message: "Error Watermarking Track"})
                    this.setState({approvalStep: "watermark" ,mainTrackApprovalError: true, mainTrackLoading: false, mainTrackStatusIcon: "icon-warning text-primary",
                    mainTrackApprovalStepDisplay: "Error Watermarking Track"})
                }

            })
            .catch(error=>{
                Error({message: error?.response?.message || "Error Watermarking Track"})
                this.setState({approvalStep: "watermark" ,mainTrackApprovalError: true, mainTrackLoading: false, mainTrackStatusIcon: "icon-warning text-primary",
                mainTrackApprovalStepDisplay: "Error Watermarking Track"})
            })
    }
    //call mp3 preview
}
updateAlbumsCB = (newAlbum)=>{
    let myAlbumOptions = [...this.state.myAlbums]
    myAlbumOptions.push(newAlbum)
    this.setState({isAlbumModalOpen: false, myAlbums: myAlbumOptions, album: myAlbumOptions[myAlbumOptions.length-1]})
}
mp3PreviewMainTrack = ()=>{
    if(this.state.invalidFileType || this.state.virusDetected){
        return
    }
    else{
        this.setState({mainTrackLoading: true, mainTrackApprovalStepDisplay: "Creating 30s Preview"})
        //api call to mp3 encode
        api.mainTrackMP3Preview(this.props.match.params.trackId)
            .then(res=>{
                //on sucess update approval step to mp3 encoded
                if(res?.data.success){
                    let progress = Math.round(100* (5/this.state.totalSteps))
                    this.setState({approvalStep: "generate waveform", mainTrackApprovalError: false, mainTrackLoading: false, mainTrackStatusIcon: 'icon-time', mainTrackApprovalStepDisplay: "Generating Waveform",
                        mainTrackProgress: progress},
                    ()=>this.generateWaveformMainTrack())
                }
                else{
                    Error({message: "Error Generating Preview"})
                    this.setState({approvalStep: "mp3 preview" ,mainTrackApprovalError: true, mainTrackLoading: false, mainTrackStatusIcon: "icon-warning text-primary",
                    mainTrackApprovalStepDisplay: "Error Generating Preview"})
                }
            })
            .catch(error=>{
                Error({message: error?.response?.message || "Error Generating Preview"})
                this.setState({approvalStep: "mp3 preview" ,mainTrackApprovalError: true, mainTrackLoading: false, mainTrackStatusIcon: "icon-warning text-primary",
                mainTrackApprovalStepDisplay: "Error Generating Preview"})
            })
    }
}
generateWaveformMainTrack = ()=>{
    if(this.state.invalidFileType || this.state.virusDetected){
        return
    }
    else{
        this.setState({mainTrackLoading: true, mainTrackApprovalStepDisplay: "Generating Waveform"})
        //api call to mp3 encode
        api.mainTrackGenerateWaveform(this.props.match.params.trackId)
            .then(res=>{
                //on sucess update approval step to mp3 encoded
                if(res?.data.success){
                    let progress = Math.round(100* (6/this.state.totalSteps))
                    this.setState({approvalStep: "complete", mainTrackApprovalError: false, mainTrackLoading: false, mainTrackStatusIcon: 'icon-check', mainTrackApprovalStepDisplay: "Complete",
                        mainTrackProgress: progress})
                }
                else{
                    Error({message: "Error Generating Waveform"})
                    this.setState({approvalStep: "generate waveform" ,mainTrackApprovalError: true, mainTrackLoading: false, mainTrackStatusIcon: "icon-warning text-primary",
                    mainTrackApprovalStepDisplay: "Error Generating Waveform"})
                }
            })
            .catch(error=>{
                Error({message: error?.response?.message || "Error Generating Waveform"})
                this.setState({approvalStep: "generate waveform" ,mainTrackApprovalError: true, mainTrackLoading: false, mainTrackStatusIcon: "icon-warning text-primary",
                mainTrackApprovalStepDisplay: "Error Generating Waveform"})
            })
    }
}
fileFilterVersion = (id, index)=>{
        this.setState(({additionalVersions})=>{
            return({
                additionalVersions:[
                    ...additionalVersions.slice(0, index),
                    {
                        ...additionalVersions[index],
                        approvalLoading: true,
                        approvalStepDisplay: "Checking File Type",
                        statusIcon: "icon-time"
                    },
                    ...additionalVersions.slice(index+1)
                ]
            })
        },()=>{
            api.versionFileFilter(this.props.match.params.trackId, id)
                .then(res=>{
                    //on success update approvalStep to virus scan (done on backend)
                    if(res?.data.success){
                        //call virusScan
                        this.setState(({additionalVersions})=>{
                            return({
                                additionalVersions:[
                                    ...additionalVersions.slice(0, index),
                                    {
                                        ...additionalVersions[index],
                                        approvalStep: "virus scan",
                                        approvalLoading: false,
                                        approvalStepDisplay: "Scanning for Viruses",
                                        statusIcon: "icon-time",
                                        approvalProgress: Math.round(100 * (1/this.state.totalVersionSteps)),
                                        fileFilterComplete: true
                                    },
                                    ...additionalVersions.slice(index+1)
                                ]
                            })
                        }, ()=>this.versionVirusScan(id, index))
                    }
                    else{
                        this.setState(({additionalVersions})=>{
                            return({
                                additionalVersions:[
                                    ...additionalVersions.slice(0, index),
                                    {
                                        ...additionalVersions[index],
                                        approvalStep: "file filter",
                                        approvalError: true,
                                        approvalLoading: false,
                                        approvalStepDisplay: "Error Checking File Type",
                                        statusIcon: "icon-warning text-primary",
                                    },
                                    ...additionalVersions.slice(index+1)
                                ]
                            })
                        })
                        Error({message:"Error Checking File Type"})
                    }
                })
                .catch(error=>{
                    if(error?.response?.status === 415){
                        Error({message: "Invalid File Type"})
                        let errorMessage = error?.response?.data?.message;
                        let reason
                        if(errorMessage.indexOf("Bit Depth")){
                            reason = "bit_depth"
                        }
                        else if(errorMessage.indexOf("Sample Rate")){
                            reason = "sample_rate"
                        }
                        else if(errorMessage.indexOf("Invalid File Type")){
                            reason = "invalid_file"
                        }
                        this.setState(({additionalVersions})=>{
                            return({
                                additionalVersions:[
                                    ...additionalVersions.slice(0, index),
                                    {
                                        ...additionalVersions[index],
                                        approvalStep: "file filter",
                                        approvalError: null,
                                        approvalLoading: false,
                                        approvalStepDisplay: "Invalid File Type",
                                        statusIcon: "icon-warning text-primary",
                                        approval: "reject",
                                        rejectionReason: reason,
                                        fileFilterComplete: true
                                    },
                                    ...additionalVersions.slice(index+1)
                                ],
                                invalidFileType: true
                            })
                        })
                        // let rejectionBody = {}
                        // rejectionBody.content = this.state.contentCheck
                        // rejectionBody.curation = this.state.curationCheck
                        // rejectionBody.exclusivity = this.state.exclusivityCheck
                        // rejectionBody.trackQuality = "approve"
                        // rejectionBody.trackURL = this.state.wavURL
                        // let versions = []
                        // this.state.additionalVersions.forEach(version=>{
                        //     if(version.id === id){
                        //         versions.push({id: version.id, wavURL: version.wavURL, approval: "reject" })
                        //     }
                        //     else{
                        //         versions.push({id: version.id, wavURL: version.wavURL, approval: version.approval })
                        //     }
                        // })
                        // if(versions.length > 0){
                        //     rejectionBody.versions = versions
                        // }
                        // let stems = []
                        // this.state.stems.forEach(stem=>{
                        //     stems.push({id: stem.id, wavURL: stem.wavURL, approval: stem.approval})
                        // })
                        // if(stems.length > 0){
                        //     rejectionBody.stems = stems
                        // }
                        // if(version.id === id){
                        //     this.setState(({versionsRejectionBody})=>{
                        //         return({
                        //             versionsRejectionBody:[
                        //                 ...versionsRejectionBody,
                        //                 {
                        //                     id: id,
                        //                     wavURL: this.state.additionalVersions[index].wavURL,
                        //                     approval: "reject"
                        //                 }
                        //             ]
                        //         })
                        //     })
                        // }

                        // api.rejectForInvalidFileType(this.props.match.params.trackId, this.state.additionalVersions[index].localname, rejectionBody)

                    }
                    else{
                        Error({message: error?.response?.message || "Error Checking File Type"})
                        this.setState(({additionalVersions})=>{
                            return({
                                additionalVersions:[
                                    ...additionalVersions.slice(0, index),
                                    {
                                        ...additionalVersions[index],
                                        approvalStep: "file filter",
                                        approvalError: true,
                                        approvalLoading: false,
                                        approvalStepDisplay: "Error Checking File Type",
                                        statusIcon: "icon-warning text-primary"
                                    },
                                    ...additionalVersions.slice(index+1)
                                ]
                            })
                        })
                    }

                })
        })
    //api call to file filter
    //on success update approvalStep to correct file type
    //update state in proper way for array
    //call virusScan
}

versionVirusScan =(id, index)=>{
    if(this.state.invalidFileType){
        return
    }
    else{
        this.setState(({additionalVersions})=>{
            return({
                additionalVersions:[
                    ...additionalVersions.slice(0, index),
                    {
                        ...additionalVersions[index],
                        approvalLoading: true,
                        approvalStepDisplay: "Scanning for Viruses",
                    },
                    ...additionalVersions.slice(index+1)
                ]
            })
        },()=>{
            api.versionVirusScan(this.props.match.params.trackId, id)
                .then(res=>{
                    //on success update approvalStep to virus scan (done on backend)
                    if(res?.data?.success){
                        //call virusScan
                        this.setState(({additionalVersions})=>{
                            return({
                                additionalVersions:[
                                    ...additionalVersions.slice(0, index),
                                    {
                                        ...additionalVersions[index],
                                        approvalStep: "mp3 encode",
                                        approvalLoading: false,
                                        approvalStepDisplay: "Encoding mp3",
                                        statusIcon: "icon-time",
                                        approvalProgress: Math.round(100 * (2/this.state.totalVersionSteps)),
                                        virusScanComplete: true
                                    },
                                    ...additionalVersions.slice(index+1)
                                ]
                            })
                        }, ()=>this.versionMP3Encode(id, index))
                    }
                    else if(res.data?.virus){
                        this.setState(({additionalVersions})=>{
                            return({
                                additionalVersions:[
                                    ...additionalVersions.slice(0, index),
                                    {
                                        ...additionalVersions[index],
                                        approvalStep: "virus scan",
                                        approvalError: null,
                                        approvalLoading: false,
                                        approvalStepDisplay: "Potential Virus Detected",
                                        statusIcon: "icon-warning text-primary",
                                        virusScanComplete: true,
                                        approval: "reject",
                                        rejectionReason: "virus_detected",
                                    },
                                    ...additionalVersions.slice(index+1)
                                ],
                                virusDetected: true
                            })
                        })
                        Error({message:"Potential Virus Detected"})
                        let rejectionBody = {}
                        rejectionBody.content = this.state.contentCheck
                        rejectionBody.curation = this.state.curationCheck
                        rejectionBody.exclusivity = this.state.exclusivityCheck
                        rejectionBody.trackQuality = "approve"
                        rejectionBody.trackURL = this.state.wavURL
                        let versions = []
                        this.state.additionalVersions.forEach(version=>{
                            if(version.id === id){
                                versions.push({id: version.id, wavURL: version.wavURL, approval: "reject" })
                            }
                            else{
                                versions.push({id: version.id, wavURL: version.wavURL, approval: version.approval })
                            }
                        })
                        if(versions.length > 0){
                            rejectionBody.versions = versions
                        }
                        let stems = []
                        this.state.stems.forEach(stem=>{
                            stems.push({id: stem.id, wavURL: stem.wavURL, approval: stem.approval})
                        })
                        if(stems.length > 0){
                            rejectionBody.stems = stems
                        }

                        // api.rejectForVirusDetected(this.props.match.params.trackId, this.state.additionalVersions[index].localname, rejectionBody)
                    }
                    else{
                        this.setState(({additionalVersions})=>{
                            return({
                                additionalVersions:[
                                    ...additionalVersions.slice(0, index),
                                    {
                                        ...additionalVersions[index],
                                        approvalStep: "virus scan",
                                        approvalError: true,
                                        approvalLoading: false,
                                        approvalStepDisplay: "Error Scanning for Virus",
                                        statusIcon: "icon-warning text-primary"
                                    },
                                    ...additionalVersions.slice(index+1)
                                ]
                            })
                        })
                        Error({message:"Error Scanning for Virus"})
                    }
                })
                .catch(error=>{
                    Error({message: error?.response?.message || "Error Scanning for Virus"})
                    this.setState(({additionalVersions})=>{
                        return({
                            additionalVersions:[
                                ...additionalVersions.slice(0, index),
                                {
                                    ...additionalVersions[index],
                                    approvalStep: "virus scan",
                                    approvalError: true,
                                    approvalLoading: false,
                                    approvalStepDisplay: "Error Scanning for Virus",
                                    statusIcon: "icon-warning text-primary"
                                },
                                ...additionalVersions.slice(index+1)
                            ]
                        })
                    })

                })
        })
    }
    //api call to virus scan
    //on success update approvalStep to virus scanned
    //update state in proper way for array
    //call watermark
}
versionMP3Encode = (id, index)=>{
    if(this.state.invalidFileType || this.state.virusDetected){
        return
    }
    else{
        this.setState(({additionalVersions})=>{
            return({
                additionalVersions:[
                    ...additionalVersions.slice(0, index),
                    {
                        ...additionalVersions[index],
                        approvalLoading: true,
                        approvalStepDisplay: "Encoding MP3",
                    },
                    ...additionalVersions.slice(index+1)
                ]
            })
        },()=>{
            api.versionMP3Encode(this.props.match.params.trackId, id)
                .then(res=>{
                    //on success update approvalStep to virus scan (done on backend)
                    if(res?.data?.success){
                        //call virusScan
                        this.setState(({additionalVersions})=>{
                            return({
                                additionalVersions:[
                                    ...additionalVersions.slice(0, index),
                                    {
                                        ...additionalVersions[index],
                                        approvalStep: "watermark",
                                        approvalLoading: false,
                                        approvalStepDisplay: "Watermarking Track",
                                        statusIcon: "icon-time",
                                        approvalProgress: Math.round(100 * (3/this.state.totalVersionSteps))
                                    },
                                    ...additionalVersions.slice(index+1)
                                ]
                            })
                        }, ()=>this.watermarkVersion(id, index))
                    }
                    else{
                        this.setState(({additionalVersions})=>{
                            return({
                                additionalVersions:[
                                    ...additionalVersions.slice(0, index),
                                    {
                                        ...additionalVersions[index],
                                        approvalStep: "mp3 encode",
                                        approvalError: true,
                                        approvalLoading: false,
                                        approvalStepDisplay: "Error Encoding Track",
                                        statusIcon: "icon-warning text-primary"
                                    },
                                    ...additionalVersions.slice(index+1)
                                ]
                            })
                        })
                        Error({message:"Error Encoding Track"})
                    }
                })
                .catch(error=>{
                    Error({message: error?.response?.message || "Error Encoding Track"})
                    this.setState(({additionalVersions})=>{
                        return({
                            additionalVersions:[
                                ...additionalVersions.slice(0, index),
                                {
                                    ...additionalVersions[index],
                                    approvalStep: "mp3 encode",
                                    approvalError: true,
                                    approvalLoading: false,
                                    approvalStepDisplay: "Error Encoding Track",
                                    statusIcon: "icon-warning text-primary"
                                },
                                ...additionalVersions.slice(index+1)
                            ]
                        })
                    })

                })
        })
    }
    //api call to water mark main version
    //on success update approvalStep to watermarked
    //update state in proper way for array
    //call mp3 encode
}
watermarkVersion = (id, index)=>{
    if(this.state.invalidFileType || this.state.virusDetected){
        return
    }
    else{
        this.setState(({additionalVersions})=>{
            return({
                additionalVersions:[
                    ...additionalVersions.slice(0, index),
                    {
                        ...additionalVersions[index],
                        approvalLoading: true,
                        approvalStepDisplay: "Watermarking Version",
                    },
                    ...additionalVersions.slice(index+1)
                ]
            })
        },()=>{
            api.versionWatermark(this.props.match.params.trackId, id)
                .then(res=>{
                    //on success update approvalStep to virus scan (done on backend)
                    if(res?.data?.success){
                        //call virusScan
                        this.setState(({additionalVersions})=>{
                            return({
                                additionalVersions:[
                                    ...additionalVersions.slice(0, index),
                                    {
                                        ...additionalVersions[index],
                                        approvalStep: "generate waveform",
                                        approvalLoading: false,
                                        approvalStepDisplay: "Generating Waveform",
                                        statusIcon: "icon-time",
                                        approvalProgress: Math.round(100 * (4/this.state.totalVersionSteps))
                                    },
                                    ...additionalVersions.slice(index+1)
                                ]
                            })
                        }, ()=>this.generateWaveformVersion(id, index))
                    }
                    else{
                        this.setState(({additionalVersions})=>{
                            return({
                                additionalVersions:[
                                    ...additionalVersions.slice(0, index),
                                    {
                                        ...additionalVersions[index],
                                        approvalStep: "watermark",
                                        approvalError: true,
                                        approvalLoading: false,
                                        approvalStepDisplay: "Error Watermarking Track",
                                        statusIcon: "icon-warning text-primary"
                                    },
                                    ...additionalVersions.slice(index+1)
                                ]
                            })
                        })
                        Error({message:"Error Watermarking Track"})
                    }
                })
                .catch(error=>{
                    Error({message: error?.response?.message || "Error Watermarking Track"})
                    this.setState(({additionalVersions})=>{
                        return({
                            additionalVersions:[
                                ...additionalVersions.slice(0, index),
                                {
                                    ...additionalVersions[index],
                                    approvalStep: "watermark",
                                    approvalError: true,
                                    approvalLoading: false,
                                    approvalStepDisplay: "Error Watermarking Track",
                                    statusIcon: "icon-warning text-primary"
                                },
                                ...additionalVersions.slice(index+1)
                            ]
                        })
                    })

                })
        })
    }
}

generateWaveformVersion = (id, index)=>{
    //api call to waveform (and duration)
    //on success update approvalStep to complete
    //update state in proper way for array
    if(this.state.invalidFileType || this.state.virusDetected){
        return
    }
    else{
        this.setState(({additionalVersions})=>{
            return({
                additionalVersions:[
                    ...additionalVersions.slice(0, index),
                    {
                        ...additionalVersions[index],
                        approvalLoading: true,
                        approvalStepDisplay: "Generating Waveform",
                    },
                    ...additionalVersions.slice(index+1)
                ]
            })
        },()=>{
            api.versionWaveform(this.props.match.params.trackId, id)
                .then(res=>{
                    //on success update approvalStep to virus scan (done on backend)
                    if(res?.data?.success){
                        //call virusScan
                        this.setState(({additionalVersions})=>{
                            return({
                                additionalVersions:[
                                    ...additionalVersions.slice(0, index),
                                    {
                                        ...additionalVersions[index],
                                        approvalStep: "complete",
                                        approvalLoading: false,
                                        approvalStepDisplay: "Complete",
                                        statusIcon: "icon-check",
                                        approvalProgress: Math.round(100 * (5/this.state.totalVersionSteps))
                                    },
                                    ...additionalVersions.slice(index+1)
                                ]
                            })
                        })
                    }
                    else{
                        this.setState(({additionalVersions})=>{
                            return({
                                additionalVersions:[
                                    ...additionalVersions.slice(0, index),
                                    {
                                        ...additionalVersions[index],
                                        approvalStep: "generate waveform",
                                        approvalError: true,
                                        approvalLoading: false,
                                        approvalStepDisplay: "Error Generating Waveform",
                                        statusIcon: "icon-warning text-primary"
                                    },
                                    ...additionalVersions.slice(index+1)
                                ]
                            })
                        })
                        Error({message:"Error Generating Waveform"})
                    }
                })
                .catch(error=>{
                    Error({message: error?.response?.message || "Error Generating Waveform"})
                    this.setState(({additionalVersions})=>{
                        return({
                            additionalVersions:[
                                ...additionalVersions.slice(0, index),
                                {
                                    ...additionalVersions[index],
                                    approvalStep: "generate waveform",
                                    approvalError: true,
                                    approvalLoading: false,
                                    approvalStepDisplay: "Error Generating Waveform",
                                    statusIcon: "icon-warning text-primary"
                                },
                                ...additionalVersions.slice(index+1)
                            ]
                        })
                    })

                })
        })
    }
}
removeVersionFromState = (index)=>{
    this.props.handleFormUnsaved(true)
    let additionalVersions = this.state.additionalVersions.filter((version, ind)=>ind !==index)
    this.setState({additionalVersions: additionalVersions})
}
fileFilterStem = (id, index)=>{

        this.setState(({stems})=>{
            return({
                stems:[
                    ...stems.slice(0, index),
                    {
                        ...stems[index],
                        approvalLoading: true,
                        approvalStepDisplay: "Checking File Type",
                        statusIcon: "icon-time"
                    },
                    ...stems.slice(index+1)
                ]
            })
        },()=>{
            api.stemFileFilter(this.props.match.params.trackId, id)
                .then(res=>{
                    //on success update approvalStep to virus scan (done on backend)
                    if(res?.data.success){
                        //call virusScan
                        this.setState(({stems})=>{
                            return({
                                stems:[
                                    ...stems.slice(0, index),
                                    {
                                        ...stems[index],
                                        approvalStep: "virus scan",
                                        approvalLoading: false,
                                        approvalStepDisplay: "Scanning for Viruses",
                                        statusIcon: "icon-time",
                                        approvalProgress: Math.round(100 * (1/this.state.totalStemSteps)),
                                        fileFilterComplete: true
                                    },
                                    ...stems.slice(index+1)
                                ]
                            })
                        }, ()=>this.stemVirusScan(id, index))
                    }
                    else{
                        this.setState(({stems})=>{
                            return({
                                stems:[
                                    ...stems.slice(0, index),
                                    {
                                        ...stems[index],
                                        approvalStep: "file filter",
                                        approvalError: true,
                                        approvalLoading: false,
                                        approvalStepDisplay: "Error Checking File Type",
                                        statusIcon: "icon-warning text-primary"
                                    },
                                    ...stems.slice(index+1)
                                ]
                            })
                        })
                        Error({message:"Error Checking File Type"})
                    }
                })
                .catch(error=>{
                    if(error?.response?.status === 415){
                        Error({message: "Invalid File Type"})
                        let errorMessage = error?.response?.data?.message;
                        let reason
                        if(errorMessage.indexOf("Bit Depth")){
                            reason = "bit_depth"
                        }
                        else if(errorMessage.indexOf("Sample Rate")){
                            reason = "sample_rate"
                        }
                        else if(errorMessage.indexOf("Invalid File Type")){
                            reason = "invalid_file"
                        }
                        this.setState(({stems})=>{
                            return({
                                stems:[
                                    ...stems.slice(0, index),
                                    {
                                        ...stems[index],
                                        approvalStep: "file filter",
                                        approvalError: null,
                                        approvalLoading: false,
                                        approvalStepDisplay: "Invalid File Type",
                                        statusIcon: "icon-warning text-primary",
                                        approval: "reject",
                                        rejectionReason: reason,
                                        fileFilterComplete: true
                                    },
                                    ...stems.slice(index+1)
                                ],
                                invalidFileType: true
                            })
                        })
                        // let rejectionBody = {}
                        // rejectionBody.content = this.state.contentCheck
                        // rejectionBody.curation = this.state.curationCheck
                        // rejectionBody.exclusivity = this.state.exclusivityCheck
                        // rejectionBody.trackQuality = "approve"
                        // rejectionBody.trackURL = this.state.wavURL
                        // let versions = []
                        // this.state.additionalVersions.forEach(version=>{
                        //     versions.push({id: version.id, wavURL: version.wavURL, approval: version.approval })
                        // })
                        // if(versions.length > 0){
                        //     rejectionBody.versions = versions
                        // }
                        // let stems = []
                        // this.state.stems.forEach(stem=>{
                        //     if(stem.id === id){
                        //         stems.push({id: stem.id, wavURL: stem.wavURL, approval: "reject"})
                        //     }
                        //     else{
                        //         stems.push({id: stem.id, wavURL: stem.wavURL, approval: stem.approval})
                        //     }
                        // })
                        // if(stems.length > 0){
                        //     rejectionBody.stems = stems
                        // }
                        // if(stem.id === id){
                        //     this.setState(({stemsRejectionBody})=>{
                        //         return({
                        //             stemsRejectionBody:[
                        //                 ...stemsRejectionBody,
                        //                 {
                        //                     id: stem.id,
                        //                     wavURL: stem.wavURL,
                        //                     approval: "reject"
                        //                 }
                        //             ]
                        //         })
                        //     })
                        // }
                        // else{
                        //     this.setState(({stemsRejectionBody})=>{
                        //         return({
                        //             stemsRejectionBody:[
                        //                 ...stemsRejectionBody,
                        //                 {
                        //                     id: stem.id,
                        //                     wavURL: stem.wavURL,
                        //                     approval: stem.approval
                        //                 }
                        //             ]
                        //         })
                        //     })
                        // }
                        // api.rejectForInvalidFileType(this.props.match.params.trackId, this.state.additionalVersions[index].localname, rejectionBody)

                    }
                    else{
                        Error({message: error?.response?.message || "Error Checking File Type"})
                        this.setState(({stems})=>{
                            return({
                                stems:[
                                    ...stems.slice(0, index),
                                    {
                                        ...stems[index],
                                        approvalStep: "file filter",
                                        approvalError: true,
                                        approvalLoading: false,
                                        approvalStepDisplay: "Error Checking File Type",
                                        statusIcon: "icon-warning text-primary"
                                    },
                                    ...stems.slice(index+1)
                                ]
                            })
                        })
                    }

                })
        })
    //api call to file filter
    //on success update approvalStep to correct file type
    //update state in proper way for array
    //call virusScan
}

stemVirusScan =(id, index)=>{
    if(this.state.invalidFileType){
        return
    }
    else{
        this.setState(({stems})=>{
            return({
                stems:[
                    ...stems.slice(0, index),
                    {
                        ...stems[index],
                        approvalLoading: true,
                        approvalStepDisplay: "Scanning for Viruses",
                    },
                    ...stems.slice(index+1)
                ]
            })
        },()=>{
            api.stemVirusScan(this.props.match.params.trackId, id)
                .then(res=>{
                    //on success update approvalStep to virus scan (done on backend)
                    if(res?.data?.success){
                        //call virusScan
                        this.setState(({stems})=>{
                            return({
                                stems:[
                                    ...stems.slice(0, index),
                                    {
                                        ...stems[index],
                                        approvalStep: "mp3 encode",
                                        approvalLoading: false,
                                        approvalStepDisplay: "Encoding mp3",
                                        statusIcon: "icon-time",
                                        approvalProgress: Math.round(100 * (2/this.state.totalStemSteps)),
                                        virusScanComplete: true
                                    },
                                    ...stems.slice(index+1)
                                ]
                            })
                        }, ()=>this.stemMP3Encode(id, index))
                    }
                    else if(res.data?.virus){
                        this.setState(({stems})=>{
                            return({
                                stems:[
                                    ...stems.slice(0, index),
                                    {
                                        ...stems[index],
                                        approvalStep: "virus scan",
                                        approvalError: null,
                                        approvalLoading: false,
                                        approvalStepDisplay: "Potential Virus Detected",
                                        statusIcon: "icon-warning text-primary",
                                        virusScanComplete: true,
                                        approval: "reject",
                                        rejectionReason: "virus_detected"
                                    },
                                    ...stems.slice(index+1)
                                ],
                                virusDetected: true
                            })
                        })
                        Error({message:"Potential Virus Detected"})
                        let rejectionBody = {}
                        rejectionBody.content = this.state.contentCheck
                        rejectionBody.curation = this.state.curationCheck
                        rejectionBody.exclusivity = this.state.exclusivityCheck
                        rejectionBody.trackQuality = "approve"
                        rejectionBody.trackURL = this.state.wavURL
                        let versions = []
                        this.state.additionalVersions.forEach(version=>{
                            versions.push({id: version.id, wavURL: version.wavURL, approval: version.approval })
                        })
                        if(versions.length > 0){
                            rejectionBody.versions = versions
                        }
                        let stems = []
                        this.state.stems.forEach(stem=>{
                            if(stem.id === id){
                                stems.push({id: stem.id, wavURL: stem.wavURL, approval: "reject"})
                            }
                            else{
                                stems.push({id: stem.id, wavURL: stem.wavURL, approval: stem.approval})
                            }
                        })
                        if(stems.length > 0){
                            rejectionBody.stems = stems
                        }

                        // api.rejectForVirusDetected(this.props.match.params.trackId, this.state.additionalVersions[index].localname, rejectionBody)

                    }
                    else{
                        this.setState(({stems})=>{
                            return({
                                stems:[
                                    ...stems.slice(0, index),
                                    {
                                        ...stems[index],
                                        approvalStep: "virus scan",
                                        approvalError: true,
                                        approvalLoading: false,
                                        approvalStepDisplay: "Error Scanning for Virus",
                                        statusIcon: "icon-warning text-primary"
                                    },
                                    ...stems.slice(index+1)
                                ]
                            })
                        })
                        Error({message:"Error Scanning for Virus"})
                    }
                })
                .catch(error=>{
                    Error({message: error?.response?.message || "Error Scanning for Virus"})
                    this.setState(({stems})=>{
                        return({
                            stems:[
                                ...stems.slice(0, index),
                                {
                                    ...stems[index],
                                    approvalStep: "virus scan",
                                    approvalError: true,
                                    approvalLoading: false,
                                    approvalStepDisplay: "Error Scanning for Virus",
                                    statusIcon: "icon-warning text-primary"
                                },
                                ...stems.slice(index+1)
                            ]
                        })
                    })

                })
        })
    }
    //api call to virus scan
    //on success update approvalStep to virus scanned
    //update state in proper way for array
    //call watermark
}
stemMP3Encode = (id, index)=>{
    if(this.state.invalidFileType || this.state.virusDetected){
        return
    }
    else{
        this.setState(({stems})=>{
            return({
                stems:[
                    ...stems.slice(0, index),
                    {
                        ...stems[index],
                        approvalLoading: true,
                        approvalStepDisplay: "Encoding MP3",
                    },
                    ...stems.slice(index+1)
                ]
            })
        },()=>{
            api.stemMP3Encode(this.props.match.params.trackId, id)
                .then(res=>{
                    //on success update approvalStep to virus scan (done on backend)
                    if(res?.data?.success){
                        //call virusScan
                        this.setState(({stems})=>{
                            return({
                                stems:[
                                    ...stems.slice(0, index),
                                    {
                                        ...stems[index],
                                        approvalStep: "generate waveform",
                                        approvalLoading: false,
                                        approvalStepDisplay: "Watermarking Stem",
                                        statusIcon: "icon-time",
                                        approvalProgress: Math.round(100 * (3/this.state.totalStemSteps))
                                    },
                                    ...stems.slice(index+1)
                                ]
                            })
                        }, ()=>this.generateWaveformStem(id, index))
                    }
                    else{
                        this.setState(({stems})=>{
                            return({
                                stems:[
                                    ...stems.slice(0, index),
                                    {
                                        ...stems[index],
                                        approvalStep: "mp3 encode",
                                        approvalError: true,
                                        approvalLoading: false,
                                        approvalStepDisplay: "Error Encoding Track",
                                        statusIcon: "icon-warning text-primary"
                                    },
                                    ...stems.slice(index+1)
                                ]
                            })
                        })
                        Error({message:"Error Encoding Track"})
                    }
                })
                .catch(error=>{
                    Error({message: error?.response?.message || "Error Encoding Track"})
                    this.setState(({stems})=>{
                        return({
                            stems:[
                                ...stems.slice(0, index),
                                {
                                    ...stems[index],
                                    approvalStep: "mp3 encode",
                                    approvalError: true,
                                    approvalLoading: false,
                                    approvalStepDisplay: "Error Encoding Track",
                                    statusIcon: "icon-warning text-primary"
                                },
                                ...stems.slice(index+1)
                            ]
                        })
                    })

                })
        })
    }
    //api call to water mark main version
    //on success update approvalStep to watermarked
    //update state in proper way for array
    //call mp3 encode
}
generateWaveformStem = (id, index)=>{
    if(this.state.invalidFileType || this.state.virusDetected){
        return
    }
    else{
        //api call to waveform (and duration)
        //on success update approvalStep to complete
        //update state in proper way for array
        this.setState(({stems})=>{
            return({
                stems:[
                    ...stems.slice(0, index),
                    {
                        ...stems[index],
                        approvalLoading: true,
                        approvalStepDisplay: "Generating Waveform",
                    },
                    ...stems.slice(index+1)
                ]
            })
        },()=>{
            api.stemWaveform(this.props.match.params.trackId, id)
                .then(res=>{
                    //on success update approvalStep to virus scan (done on backend)
                    if(res?.data?.success){
                        //call virusScan
                        this.setState(({stems})=>{
                            return({
                                stems:[
                                    ...stems.slice(0, index),
                                    {
                                        ...stems[index],
                                        approvalStep: "complete",
                                        approvalLoading: false,
                                        approvalStepDisplay: "Complete",
                                        statusIcon: "icon-check",
                                        approvalProgress: Math.round(100 * (4/this.state.totalStemSteps))
                                    },
                                    ...stems.slice(index+1)
                                ]
                            })
                        })
                    }
                    else{
                        this.setState(({stems})=>{
                            return({
                                stems:[
                                    ...stems.slice(0, index),
                                    {
                                        ...stems[index],
                                        approvalStep: "generate waveform",
                                        approvalError: true,
                                        approvalLoading: false,
                                        approvalStepDisplay: "Error Generating Waveform",
                                        statusIcon: "icon-warning text-primary"
                                    },
                                    ...stems.slice(index+1)
                                ]
                            })
                        })
                        Error({message:"Error Generating Waveform"})
                    }
                })
                .catch(error=>{
                    Error({message: error?.response?.message || "Error Generating Waveform"})
                    this.setState(({stems})=>{
                        return({
                            stems:[
                                ...stems.slice(0, index),
                                {
                                    ...stems[index],
                                    approvalStep: "generate waveform",
                                    approvalError: true,
                                    approvalLoading: false,
                                    approvalStepDisplay: "Error Generating Waveform",
                                    statusIcon: "icon-warning text-primary"
                                },
                                ...stems.slice(index+1)
                            ]
                        })
                    })

                })
        })
    }
}

retryMain = ()=>{
    this.setState({mainTrackApprovalError: false})
    switch(this.state.approvalStep){
        case "file filter":
            this.fileFilterMainTrack();
            break;
        case "virus scan":
            this.virusScanMainTrack();
            break;
        case "mp3 encode":
            this.mp3EncodeMainTrack();
            break;
        case "watermark":
            this.watermarkMainTrack();
            break;
        case "mp3 preview":
            this.mp3PreviewMainTrack();
            break;
        case "generate waveform":
            this.generateWaveformMainTrack();
            break;
        default:
            break;
    }
}
retryVersion = (id, index)=>{
    this.setState(({additionalVersions})=>{
        return({
            additionalVersions:[
                ...additionalVersions.slice(0, index),
                {
                    ...additionalVersions[index],
                    approvalError: false,
                    statusIcon: "icon-time",
                },
                ...additionalVersions.slice(index+1)
            ]
        })
    })
    switch(this.state.additionalVersions[index].approvalStep){
        case "file filter":
            this.fileFilterVersion(id, index);
            break;
        case "virus scan":
            this.versionVirusScan(id, index);
            break;
        case "mp3 encode":
            this.versionMP3Encode(id, index);
            break;
        case "watermark":
            this.watermarkVersion(id, index);
            break;
        case "generate waveform":
            this.generateWaveformVersion(id, index);
            break;
        default:
            break;
    }
}
retryStem = (id, index)=>{
    this.setState(({stems})=>{
        return({
            stems:[
                ...stems.slice(0, index),
                {
                    ...stems[index],
                    approvalError: false,
                    statusIcon: "icon-time",
                },
                ...stems.slice(index+1)
            ]
        })
    })
    switch(this.state.stems[index].approvalStep){
        case "file filter":
            this.fileFilterStem(id, index);
            break;
        case "virus scan":
            this.stemVirusScan(id, index);
            break;
        case "mp3 encode":
            this.stemMP3Encode(id, index);
            break;
        case "generate waveform":
            this.generateWaveformStem(id, index);
            break;
        default:
            break;
    }
}
handleApprove = ()=>{
    // this.setState({isLoadingModalOpen: true})
    let {approvability, detailsComplete, tagsComplete} = this.checkApprovability()
    if(approvability === true && detailsComplete === true && tagsComplete === true ){
        let keywords = this.state.keywords || []
        let trackInfo = {
            title: this.state.title,
            albumId: this.state.album.value,
            // keywords: this.state.keywords ? this.state.keywords.map(keyword=> keyword.value).join(", ") : undefined,
            funFact: this.state.funFact,
            publisherName: this.state.publisherRights === "true" ? this.state.publisherName: null,
            publisherProOrg: this.state.publisherRights  === "true" ? this.state.publisherProOrg.value : null,
            publisherProNum: this.state.publisherRights  === "true" ? this.state.publisherProNum : null,
            publisherPercent: this.state.publisherRights  ==="true" ? (Number(this.state.publisherPercent)*(1/100)).toFixed(2) : null
        }
        let tags = []
        if(this.state.genres){
            this.state.genres.forEach(genre=>{tags.push(genre.value)})
        }
        if(this.state.emotions){
            this.state.emotions.forEach(emotion=>{tags.push(emotion.value)})
        }
        if(this.state.moods){
            this.state.moods.forEach(mood=>{tags.push(mood.value)})
        }
        if(this.state.attributes){
            this.state.attributes.forEach(attribute=>{tags.push(attribute.value)})
        }
        if(this.state.instruments){
            this.state.instruments.forEach(instrument=>{tags.push(instrument.value)})
        }
        if(this.state.city){
            tags.push(this.state.city.value)
        }
        if(this.state.trackBPM){
            tags.push(parseInt(this.state.trackBPM))
        }
        if(this.state.containsLyrics === "true"){
            //need to point to lyrics tag in db
            tags.push(173)
            if(this.state.explicitLyrics === "true"){
                tags.push(174)
            }
        }
        else{
            tags.push(172)
        }

        //save any changed track info and start the first steps of approval
        //open the approval modal
        this.setState({
            approvalStep: "file filter",
            mainTrackLoading: true,
            mainTrackApprovalStepDisplay: "Preparing",
            isApprovalModalOpen: "true",
            mainTrackStatusIcon: "icon-time"
        })

        api.initiateApproval(
            this.props.match.params.trackId,
            trackInfo,
            tags,
            keywords
        )
        .then(res => {
            if (res.data.success ) {
                    this.setState(
                        {status: "approvalInitiated"},
                        () => {
                            //start main file filter
                            this.fileFilterMainTrack()
                            //for each version
                            this.state.additionalVersions.forEach((version, index)=>{
                                //start the file filter
                                this.fileFilterVersion(version.id, index)
                            })
                            //for each stem
                            this.state.stems.forEach((stem, index)=>{
                                this.fileFilterStem(stem.id, index)
                            })
                            //start the file filter
                        }
                    )
                    // Success( {onClose: this.successToastSave, message:"Track Saved", autoClose: 3000})
            }
            else {
                Error({message:"An error occurred!", onClose: this.errorToast})
            }
        })
        .catch(error => {
            console.log(error)

            Error({message:"An error occurred!", onClose:this.errorToast})
        })

        // api.approveTrack(this.props.match.params.trackId, trackInfo)
        //     .then(res=>{
        //         let versionsCreated = 0
        //         let stemsCreated = 0
        //         console.log(res)
        //         this.state.additionalVersions.forEach(version =>{
        //             api.createVersionMp3(version.id)
        //             .then(verResp=>{
        //                 console.log(verResp)
        //                 versionsCreated++
        //                 if(stemsCreated === this.state.stems.length && versionsCreated === this.state.additionalVersions.length){
        //                     this.setState({isLoadingModalOpen: false})
        //                     SuccessRedirect({message:"Track Approved!", onClose: this.successToastSubmit})
        //                 }
        //             })
        //         })
        //         this.state.stems.forEach(stem=>{
        //             api.createStemMp3(stem.id)
        //                 .then(stemResp=>{
        //                     console.log(stemResp)
        //                     stemsCreated++
        //                     if(stemsCreated === this.state.stems.length && versionsCreated === this.state.additionalVersions.length){
        //                         this.setState({isLoadingModalOpen: false})
        //                         SuccessRedirect({message:"Track Approved!", onClose: this.successToastSubmit})
        //                     }
        //                 })
        //         })
        //         if(res.data.success && this.state.additionalVersions.length === 0 && this.state.stems.length === 0){
        //             this.setState({isLoadingModalOpen: false})
        //             SuccessRedirect({message:"Track Approved!", onClose: this.successToastSubmit})
        //         }
        //     })
        //     .catch(error=>{
        //         console.log(error)
        //         this.setState({isLoadingModalOpen: false})
        //         Error({message: "System Error", onClose: this.errorToast})
        //     })
    }
}
hanldeRejectionCommentChange = (event)=>{
    event.preventDefault();
    const target = event.target;
    const value = target.value;
    if(value.length > 200){
        return
    }
    else{
        this.setState({rejectionComment: value})
    }
}
handleRejectionCommentOpen = ()=>{
    let {approvability} = this.checkApprovability()
    if(approvability === false){
        this.setState({isRejectionCommentModalOpen: true})
    }
}
handleRejectionCommentClose = ()=>{
    this.setState({isRejectionCommentModalOpen: false})
}
handleReject = ()=>{
    this.setState({isLoadingModalOpen: true, isRejecting: true})
    let {approvability} = this.checkApprovability()
    if(approvability === false ){
        console.log("rejecting track")
        let rejectionBody = {}
        rejectionBody.content = this.state.contentCheck
        rejectionBody.curation = this.state.curationCheck
        rejectionBody.exclusivity = this.state.exclusivityCheck
        if(this.state.trackCheck === "reject"){
            rejectionBody.trackQuality = "reject"
            rejectionBody.trackURL = this.state.wavURL
            rejectionBody.reason = "manual"
        }
        else if(this.state.trackCheck === "approve"){
            rejectionBody.trackQuality = "approve"
        }
        let versions = []
        this.state.additionalVersions.forEach(version=>{
            if(version.approval === "reject"){
                versions.push({id: version.id, wavURL: version.wavURL, approval: version.approval, reason: "manual"})
            }
            else{
                versions.push({id: version.id, wavURL: version.wavURL, approval: version.approval})
            }
        })
        if(versions.length > 0){
            rejectionBody.versions = versions
        }
        let stems = []
        this.state.stems.forEach(stem=>{
            if(stem.approval === "reject"){
                stems.push({id: stem.id, wavURL: stem.wavURL, approval: stem.approval, reason: "manual"})
            }
            else{
                stems.push({id: stem.id, wavURL: stem.wavURL, approval: stem.approval})
            }
        })
        if(stems.length > 0){
            rejectionBody.stems = stems
        }
        let trackInfo = {
            title: this.state.title,
            albumId: this.state.album.value,
            keywords: this.state.keywords || [],
            funFact: this.state.funFact,
        }

        let keywords = this.state.keywords || []

        let tags = []
        if(this.state.genres){
            this.state.genres.forEach(genre=>{tags.push(genre.value)})
        }
        if(this.state.emotions){
            this.state.emotions.forEach(emotion=>{tags.push(emotion.value)})
        }
        if(this.state.moods){
            this.state.moods.forEach(mood=>{tags.push(mood.value)})
        }
        if(this.state.attributes){
            this.state.attributes.forEach(attribute=>{tags.push(attribute.value)})
        }
        if(this.state.instruments){
            this.state.instruments.forEach(instrument=>{tags.push(instrument.value)})
        }
        if(this.state.city){
            tags.push(this.state.city.value)
        }
        if(this.state.trackBPM){
            tags.push(parseInt(this.state.trackBPM))
        }
        if(this.state.containsLyrics === "true"){
            //need to point to lyrics tag in db
            tags.push(173)
            if(this.state.explicitLyrics === "true"){
                tags.push(174)
            }
        }
        else{
            tags.push(172)
        }

        api.editTrackById(this.props.match.params.trackId, trackInfo, "saving", tags, keywords)
        .then(res=>{
            if(res.data.success ){
                    Success( {onClose: this.successToastSave, message:"Track Saved", autoClose: 3000})
                    api.rejectTrack(this.props.match.params.trackId, rejectionBody, trackInfo, this.state.rejectionComment)
                        .then(res=>{
                            this.setState({isLoadingModalOpen: false, isRejectionCommentModalOpen: false})
                            SuccessRedirect({message:"Track Rejected!", onClose: this.successToastSubmit})
                        })
                        .catch(error=>{
                            console.log(error)
                            this.setState({isLoadingModalOpen: false})
                            Error({message: "System Error", onClose: this.errorToast})
                        })
                        console.log("rejection ",rejectionBody)
            }
            else{
                Error({message:"An error occurred!", onClose: this.errorToast})
                this.setState({isLoadingModalOpen: false})
            }
        }).catch(error=>{
            console.log(error)
            Error({message:"An error occurred!", onClose:this.errorToast})
            this.setState({isLoadingModalOpen: false})
        })


    }
}
successToastSubmit = ()=>{
    toast.dismiss()
    window.location.replace("/dashboard/tracks/pending")
}
successToastSave = ()=>{
    toast.dismiss()
    window.location.replace("/dashboard/tracks/pending")
}
errorToast = ()=>{
    toast.dismiss()
}
handleSelect = (event)=> {
this.props.handleFormUnsaved(true)
console.log(event)
this.setState({album: event.target.value});
}
setTotalFailure = ()=>{
    console.log("totally failed")
    if(this.state.totallyFailed !== false){
        this.setState({totallyFailed: true})
    }
}
checkApprovalProgress = ()=>{
    if(this.state.initialMount){
        console.log("checking approval progress")
        if(this.totalFailureTimout){
            clearTimeout(this.totalFailureTimout)
        }
        this.totalFailureTimout = setTimeout(this.setTotalFailure, 180000)
        let versionsComplete = this.state.additionalVersions.filter(version=>version.approvalStep !== "complete").length > 0;
        let stemsComplete = this.state.stems.filter(stem=>stem.approvalStep !== "complete").length > 0;
        if(this.state.approvalStep === "complete" && !versionsComplete && !stemsComplete){
            return true
        }
        else{
            return false
        }
    }
    else{
        return false
    }
}
checkApprovability = ()=>{
    let detailsComplete
    let tagsComplete
    let funFactComplete
    let attributes = this.state.attributes || []
    let emotions = this.state.emotions || []
    let genres = this.state.genres || []
    let instruments = this.state.instruments || []
    let moods = this.state.moods || []
    let trackBPM = this.state.trackBPM || ""
    let approvability = true;
    if(this.state.title.length > 0 && this.state.album){
        detailsComplete = true
    }
    else{
        detailsComplete = false
    }
    if(attributes.length > 0 && emotions.length > 0 && genres.length > 0 && instruments.length > 0
        && moods.length > 0 && this.state.city && trackBPM ){
            tagsComplete = true
    }
    else{
            tagsComplete = false
    }
    if(this.state.funFact?.length && this.state.funFact?.length <= 400) {
        funFactComplete = true
    }
    else if(this.state.funFact?.length > 400) {
        funFactComplete = "incomplete"
    }

    if(this.state.contentCheck !== "approve" || this.state.curationCheck !== "approve" || this.state.exclusivityCheck !== "approve" || this.state.trackCheck !== "approve" ){
        if(this.state.contentCheck === undefined || this.state.curationCheck === undefined || this.state.exclusivityCheck === undefined){
            approvability ="incomplete"
        }
        else{
            approvability = false
        }
    }
    let unapprovedVersions = this.state.additionalVersions.filter(additionVersion=>additionVersion.approval !== "approve" )
    let versionsDone = true
    if(unapprovedVersions.length > 0){
        if(unapprovedVersions.filter(unnapproved => unnapproved.approval ===undefined).length >0){
            approvability = "incomplete"
            versionsDone = "incomplete"
        }
        else{
        versionsDone = false
        approvability = false
        }

    }
    let unapprovedStems = this.state.stems.filter(stem=>stem.approval !== "approve")
    let stemsDone =true
    if(unapprovedStems.length > 0){
        if(unapprovedStems.filter(unnapproved => unnapproved.approval ===undefined).length > 0){
            approvability = "incomplete"
            stemsDone = "incomplete"
            return {approvability, versionsDone, stemsDone, detailsComplete, tagsComplete, funFactComplete: funFactComplete}
        }
        else{
            stemsDone = false
            approvability = false
        }

    }
    console.log("can approve ",approvability)
    return {approvability, versionsDone, stemsDone, detailsComplete, tagsComplete, funFactComplete: funFactComplete}
}
restartApproval = ()=>{
    api.restartApproval(this.props.match.params.trackId)
        .then(res=>{
            if(res.data.success){
                if(this.state.approvalFailures > 1){
                    this.setState({approvalFailures: this.state.approvalFailures + 1})
                }
                else{
                    window.location.reload()
                }
            }
        })
}
setTrackBroken = ()=>{
    // api.setTrackBroken(this.props.match.params.trackId)
    //     .then(res=>{
    //         if(res.data.success){
    //             window.location.assign('/dashboard/tracks/pending')
    //         }
    //     })
}
componentDidUpdate =(prevProps, prevState)=>{
    let {approvability, versionsDone, stemsDone, detailsComplete, tagsComplete, funFactComplete} = this.checkApprovability()
    if(this.state.approvability !== approvability || this.state.versionsDone !== versionsDone || this.state.stemsDone !== stemsDone || this.state.detailsComplete !== detailsComplete || this.state.tagsComplete !== tagsComplete || this.state.funFactComplete !== funFactComplete){
        this.setState({
            approvability: approvability,
            versionsDone: versionsDone,
            stemsDone: stemsDone,
            detailsComplete: detailsComplete,
            tagsComplete: tagsComplete,
            funFactComplete: funFactComplete
        })
    }
    if(!this.state.approvalComplete){
        if(this.checkApprovalProgress()){
            this.setState({approvalComplete: true}, ()=>{
                api.finalizeApproval(this.props.match.params.trackId)
                    .then(res=>{
                        if(res.data.success){
                            this.props.handleFormUnsaved(false)
                            this.setState({approvalComplete: true, finalTrackURL: res.data.trackURL},()=>{
                                // SuccessRedirect({message: "Track Approved!", autoClose: 1000, onClose: window.location.replace("/tracks/" + res.data.trackURL)})
                            })
                        }
                        else{
                            Error({message: "An error occorued in the final approval process"})
                            this.setState({approvalComplete: false})
                        }
                    })
                    .catch(error=>{
                        Error({message: "An error occured in the final approval process"})
                        this.setState({approvalComplete: false})
                    })
            })
        }
    }
    if(!this.state.fileFilterComplete){
        let fileFilterComplete = this.checkIfFileFilterDone()
        if(fileFilterComplete){
            this.setState({fileFilterComplete: true, totallyFailed: false},()=>{
                console.log("main track rejection body ", this.state.mainTrackRejectionBody)
                let versionsFileFilterError = this.state.additionalVersions.filter(version=>version.approval === "reject").length > 0
                let stemsFileFilterError = this.state.stems.filter(stem=>stem.approval === "reject").length > 0
                if(this.state.mainTrackRejectionBody?.trackQuality === "reject" || versionsFileFilterError || stemsFileFilterError){
                    let rejectionBody = {}
                    rejectionBody.content = "approve"
                    rejectionBody.curation = "approve"
                    rejectionBody.exclusivity = "approve"
                    rejectionBody.trackQuality = this.state.mainTrackRejectionBody.trackQuality
                    rejectionBody.trackURL = this.state.wavURL
                    rejectionBody.reason = this.state.mainTrackRejectionBody.reason
                    if(this.state.additionalVersions?.length > 0){
                        rejectionBody.versions = this.state.additionalVersions.map(version=>{
                            let rejectionVersion = {
                                id: version.id,
                                wavURL: version.wavURL,
                                approval: version.approval,
                                reason: version.rejectionReason,
                            }
                            return rejectionVersion
                        })
                    }
                    if(this.state.stems?.length > 0){
                        rejectionBody.stems = this.state.stems.map(stem=>{
                            let rejectionStem = {
                                id: stem.id,
                                wavURL: stem.wavURL,
                                approval: stem.approval,
                                reason: stem.rejectionReason
                            }
                            return rejectionStem
                        })
                    }
                    let localFileNames = ''
                    if(this.state.mainTrackRejectionBody?.trackQuality === "reject"){
                        localFileNames += this.state.localname
                    }
                    this.state.additionalVersions.forEach(version=>{
                        if(version.approval === "reject"){
                            if(localFileNames.length > 0){
                                localFileNames += ", " + version.localname
                            }
                            else{
                                localFileNames += version.localname
                            }
                        }
                    })
                    this.state.stems.forEach(stem=>{
                        if(stem.approval === "reject"){
                            if(localFileNames.length > 0){
                                localFileNames += ", " + stem.localname
                            }
                            else{
                                localFileNames += stem.localname
                            }
                        }
                    })
                    this.props.handleFormUnsaved(false)

                    let rejectionComment = ""
                    api.rejectForInvalidFileType(this.props.match.params.trackId, rejectionComment, rejectionBody)
                    .then(res=>{
                        if(res.data.success){
                            this.setState({totallyFailed: undefined})
                        }
                        else{
                            this.setState({totallyFailed: true})
                        }
                    })
                    .catch(err=>{
                        this.setState({totallyFailed: true})
                    })
                }
            })
        }
    }
    else if(!this.state.virusScanComplete){
        let virusScanComplete = this.checkIfVirusScanDone()
        if(virusScanComplete){
            this.setState({virusScanComplete: true, totallyFailed: false},()=>{
                let versionsVirusScanError = this.state.additionalVersions.filter(version=>version.approval === "reject" && version.rejectionReason === "virus_detected").length > 0
                let stemsVirusScanError = this.state.stems.filter(stem=>stem.approval === "reject" && stem.rejectionReason === "virus_detected").length > 0
                if(((this.state.mainTrackRejectionBody?.trackQuality === "reject" && this.state.mainTrackRejectionBody?.reason === "virus_detected")  || versionsVirusScanError || stemsVirusScanError) && this.state.virusDetected){
                    let rejectionBody = {}
                    rejectionBody.content = "approve"
                    rejectionBody.curation = "approve"
                    rejectionBody.exclusivity = "approve"
                    rejectionBody.trackQuality = this.state.mainTrackRejectionBody.trackQuality
                    rejectionBody.trackURL = this.state.wavURL
                    rejectionBody.reason = this.state.mainTrackRejectionBody.reason
                    if(this.state.additionalVersions?.length > 0){
                        rejectionBody.versions = this.state.additionalVersions.map(version=>{
                            let rejectionVersion = {
                                id: version.id,
                                wavURL: version.wavURL,
                                approval: version.approval,
                                reason: version.rejectionReason,
                            }
                            return rejectionVersion
                        })
                    }
                    if(this.state.stems?.length > 0){
                        rejectionBody.stems = this.state.stems.map(stem=>{
                            let rejectionStem = {
                                id: stem.id,
                                wavURL: stem.wavURL,
                                approval: stem.approval,
                                reason: stem.rejectionReason,
                            }
                            return rejectionStem
                        })
                    }
                    let localFileNames = ''
                    if(this.state.mainTrackRejectionBody?.trackQuality === "reject"){
                        localFileNames += this.state.localname
                    }
                    this.state.additionalVersions.forEach(version=>{
                        if(version.approval === "reject"){
                            if(localFileNames.length > 0){
                                localFileNames += ", " + version.localname
                            }
                            else{
                                localFileNames += version.localname
                            }
                        }
                    })
                    this.state.stems.forEach(stem=>{
                        if(stem.approval === "reject"){
                            if(localFileNames.length > 0){
                                localFileNames += ", " + stem.localname
                            }
                            else{
                                localFileNames += stem.localname
                            }
                        }
                    })
                    rejectionBody.virusDetected = true

                    this.props.handleFormUnsaved(false)
                    let rejectionComment = ""
                    api.rejectForVirusDetected(this.props.match.params.trackId, rejectionComment, rejectionBody)
                    .then(res=>{
                        if(res.data.success){
                            this.setState({totallyFailed: undefined})
                        }
                        else{
                            this.setState({totallyFailed: true})
                        }
                    })
                    .catch(err=>{
                        this.setState({totallyFailed: true})
                    })
                }
            })
        }
    }
}
checkIfFileFilterDone = ()=>{
    let mainFileFiltered = this.state.mainTrackFileFilterComplete
    let versionFilesFiltered = false
    let versionsOnFilter = this.state.additionalVersions.filter(version=>{
        return !version.fileFilterComplete;
    })
    versionFilesFiltered = versionsOnFilter.length < 1
    let stemFilesFiltered = false
    let stemsOnFilter = this.state.stems.filter(stem=>{
        return !stem.fileFilterComplete;
    })
    stemFilesFiltered = stemsOnFilter.length < 1
    console.log("file filter status ", mainFileFiltered && versionFilesFiltered && stemFilesFiltered)
    return mainFileFiltered && versionFilesFiltered && stemFilesFiltered
}
checkIfVirusScanDone = ()=>{
    let mainVirusScanned = this.state.mainTrackVirusScanComplete
    let versionsVirusScanned= false
    let versionsOnVirusScan = this.state.additionalVersions.filter(version=>{
        return !version.virusScanComplete;
    })
    versionsVirusScanned= versionsOnVirusScan.length < 1
    let stemsVirusScanned= false
    let stemsOnVirusScan = this.state.stems.filter(stem=>{
        return !stem.virusScanComplete;
    })
    stemsVirusScanned= stemsOnVirusScan.length < 1
    console.log("virus filter status ", mainVirusScanned && versionsVirusScanned && stemsVirusScanned)
    return mainVirusScanned && versionsVirusScanned && stemsVirusScanned
}

changeNowPlaying = (event, s3Key)=>{
    console.log(s3Key, " ", this.state.nowPlaying)
    event.preventDefault()
    if(this.state.nowPlaying === s3Key){
        console.log("true")
        this.setState({nowPlaying: "stop"})
    }
    else{
        this.setState({nowPlaying: s3Key})
    }
}
unsetNowPlaying = ()=>{
    console.log("unsetting now playing")
    this.setState({nowPlaying: null})
}
versionsFileHandler = (label, event) =>{
    this.props.handleFormUnsaved(true)
    console.log(event.target.files[0])
    // console.log("index ",index)
    let versionName
    let file = event.target.files[0]
    let index
    let notNamed = false
    this.setState(({
        additionalVersions
    })=>{
        index = additionalVersions.findIndex(vers=>vers.value.toUpperCase() === label.toUpperCase() || vers.label.toUpperCase() === label.toUpperCase())
        const socket = socketIOClient();
        socket.on("progressVersion" +this.props.match.params.trackId +additionalVersions[index]?.value.replace(/^\w/, c => c.toUpperCase()).replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}) , data => {
            console.log(data)
            this.updateVersionProgress(data, index)
        });
        versionName = additionalVersions[index]?.value.replace(/^\w/, c => c.toUpperCase())
        if(versionName === undefined || versionName === "Select Version Name"){
            Error({message: "Select version name before uploading"})
            notNamed = true
            return
        }
        else{
            return({
                additionalVersions:[
                    ...additionalVersions.slice(0, index),
                    {
                        ...additionalVersions[index],
                        localname: file.name,
                        error: null,
                        loading: true,
                        file: file
                    },
                    ...additionalVersions.slice(index+1)
                ]
            })
        }
    },()=>{
        if(notNamed){
            return
        }
        const fileData = new FormData()
        fileData.append('file', file)
        api.uploadTrackVersion(this.props.match.params.trackId, versionName.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}), fileData)
            .then(res=>{
                if(res.data.success){
                    this.setState(({additionalVersions})=>{
                        index = additionalVersions.findIndex(vers=>vers.value.toUpperCase() === label.toUpperCase() || vers.label.toUpperCase() === label.toUpperCase())

                        return({
                            additionalVersions:[
                                ...additionalVersions.slice(0, index),
                                {
                                    ...additionalVersions[index],
                                    wavURL: res.data.version.wavURL,
                                    localname: res.data.version.localname,
                                    id: res.data.version.id,
                                    loading: false,
                                },
                                ...additionalVersions.slice(index+1)
                            ]
                        })
                    })
                }
            })
            .catch(error=>{
                if(error.response?.data?.message?.indexOf("Mismatched Length") !==-1){
                    Error({message: `Versions must be the same exact length as selected.`})
                    this.setState(({additionalVersions})=>({
                        additionalVersions:[
                            ...additionalVersions.slice(0, index),
                            {
                                ...additionalVersions[index],
                                error: <div>Versions must be the same exact length as selected. <VersionsExport underline/>.</div>,
                                loading: false
                            },
                            ...additionalVersions.slice(index+1)
                        ]
                    }))
                }
                else if(error?.response?.data?.message?.message?.indexOf("File Too Large") !==-1){
                    Error({message: `File rejected because it is over 250 mb`})
                    this.setState(({additionalVersions})=>({
                        additionalVersions:[
                            ...additionalVersions.slice(0, index),
                            {
                                ...additionalVersions[index],
                                error: "Track file is over 250 mb",
                                loading: false
                            },
                            ...additionalVersions.slice(index+1)
                        ]
                    }))
                }
                else if(error?.response?.data?.message?.message?.indexOf("File is longer than 12 minutes") !==-1){
                    Error({message: "File was rejected because it is longer than 12 minutes."})
                    this.setState(({additionalVersions})=>({
                        additionalVersions:[
                            ...additionalVersions.slice(0, index),
                            {
                                ...additionalVersions[index],
                                error: "Track file is longer than 12 minutes",
                                loading: false
                            },
                            ...additionalVersions.slice(index+1)
                        ]
                    }))
                }
                else{
                    this.setState(({additionalVersions})=>({
                        additionalVersions:[
                            ...additionalVersions.slice(0, index),
                            {
                                ...additionalVersions[index],
                                error: error?.response?.statusText || "Error Uploading Version",
                                loading: false
                            },
                            ...additionalVersions.slice(index+1)
                        ]
                    }))
                }
            })
    })
    // let versionName = this.state.additionalVersions[index].value.replace(/^\w/, c => c.toUpperCase())
    // if(versionName === undefined){
    //     return
    // }
    // let additionalVersions = this.state.additionalVersions;
    // additionalVersions[index].localname = event.target.files[0].name
    // additionalVersions[index].error = null
    // additionalVersions[index].loading = true
    // additionalVersions[index].file = event.target.files[0]
    // this.setState({
    //     additionalVersions: additionalVersions
    // },()=>{
    //     const fileData = new FormData()
    //     fileData.append('file', additionalVersions[index].file)
    //     console.log("data ", fileData)
    //         api.uploadTrackVersion(this.props.match.params.trackId,versionName.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}), fileData)
    //             .then(res=>{
    //                 console.log(res)
    //                 if(res.data.success){
    //                     additionalVersions[index].wavURL= res.data.version.wavURL
    //                     additionalVersions[index].duration = res.data.version.duration
    //                     additionalVersions[index].peaks= res.data.version.peaks
    //                     additionalVersions[index].localname = res.data.version.localname
    //                     additionalVersions[index].id = res.data.version.id
    //                     additionalVersions[index].loading = false
    //                     this.setState({additionalVersions: additionalVersions})
    //                 }
    //             })
    //             .catch(error =>{
    //                 console.log("error ",error, error.code, error.response)
    //                 additionalVersions[index].error=error.response.statusText
    //                 additionalVersions[index].loading = false
    //                 this.setState({additionalVersions: additionalVersions})
    //             })
    // })

    }
    updateVersionProgress = (progressData, index)=>{
        let parsedData = JSON.parse(progressData)
        console.log("updating progress ", progressData)
        let progress = Math.round(100 * parsedData.loaded/parsedData.total)
        this.setState(({additionalVersions})=>{
            if(additionalVersions[index]?.value){
                return({
                    additionalVersions:[
                        ...additionalVersions.slice(0,index),
                        {
                            ...additionalVersions[index],
                            progress: progress
                        },
                        ...additionalVersions.slice(index+1)
                    ]
                })
            }
        })
    }
stemsFileHandler = (index, event) =>{
console.log(event.target.files[0])
let stemName = this.state.instruments[index].value
this.setState({
    [`stemFile${index}`]: event.target.files[0], [`stemFile${index}Loading`]: true
},()=>{
    const fileData = new FormData()
    fileData.append('file', this.state[`stemFile${index}`])
    console.log("data ", fileData)
        api.uploadTrackStem(this.props.match.params.trackId, stemName, fileData)
            .then(res=>{
                console.log(res)
                if(res.data.success){
                    this.setState(state=>{
                        let stems = state.instruments
                        // state[`versionFile${index}Loading`] = false
                        stems[index].key= res.data.key
                        stems[index].duration = res.data.duration
                        stems[index].peaks = res.data.peaks
                        return{
                            stems,
                            [`stemFile${index}Loading`]: false
                        }
                    })
                }
            })
})

}
handleGenreChange = selectedGenres =>{
this.props.handleFormUnsaved(true)
console.log(selectedGenres)
if(selectedGenres){
    if(selectedGenres.length > 2){
        return
    }
}

this.setState(state => {
    return {
      genres: selectedGenres
    };
})
}
handleEmotionChange = selectedEmotions =>{
this.props.handleFormUnsaved(true)
if(selectedEmotions){
    if(selectedEmotions.length > 1){
        return
    }
}
this.setState(state => {
    return {
      emotions: selectedEmotions
    };
})
}
handleKeywordChange = selectedKeywords =>{
    this.props.handleFormUnsaved(true)
    this.setState(state => {
        return {
          keywords: selectedKeywords
        };
    })
    }
handleMoodChange = selectedMoods =>{
this.props.handleFormUnsaved(true)
if(selectedMoods){
    if(selectedMoods.length > 4){
        return
    }
}
this.setState(state => {
    return {
      moods: selectedMoods
    };
})
}
handleAttributeChange = selectedAttributes =>{
    this.props.handleFormUnsaved(true)
    if(selectedAttributes.length > 8){

        return
    }
    this.setState(state => {
        return {
            attributes: selectedAttributes
        };
})
}
handleInstrumentsChange = selectedInstruments =>{
this.props.handleFormUnsaved(true)
this.setState(state => {
    return {
      instruments: selectedInstruments
    };
})
}
changeCityInput = (newValue)=>{
    console.log("change city input called ", newValue, this.state.city)
    // if(!newValue && this.state.city?.label){
    //     const inputValue =this.state.city?.label
    //     this.setState({cityInput: inputValue})
    //     return inputValue
    // }
    // else{
        const inputValue =newValue
        this.setState({cityInput: inputValue})
        return inputValue
    // }

}
cityKeyDown = (event)=>{
    console.log("calling key down ", event.keyCode, " ", this.state.cityInput)
    if(event.keyCode === 8 && (this.state.cityInput?.length ===1 || !this.state.cityInput)){
        this.handleCityChange(null)
    }
}
altChangeCityInput = (newValue)=>{
    console.log("change city input clicked")
    // const inputValue =newValue
    // this.setState({cityInput: inputValue})
    // return inputValue
}
handleCityChange = selectedCity =>{
    this.props.handleFormUnsaved(true)
    if(selectedCity){
        if(selectedCity.length > 1){
            return
        }
    }
    this.setState(state => {
        return {
          city: selectedCity,
          cityInput: selectedCity?.label || ""
        };
    })
}
addNewVersionDiv = (event)=>{
    this.props.handleFormUnsaved(true)
event.preventDefault()
let additionalVersions = this.state.additionalVersions;
additionalVersions.push({name: ""})
this.setState({additionalVersions: additionalVersions})
}
handleVersionNameChange = (index, selectedVersionName) =>{
let additionalVersions = this.state.additionalVersions;
additionalVersions[index] = selectedVersionName;
console.log(additionalVersions)
console.log("version info ",selectedVersionName, " ", index)
this.setState(state=>{
    return{
        additionalVersions:additionalVersions
    }
})
}
getCities = (cityInput)=>{
    console.log("city input ", cityInput)
    if(cityInput?.length > 2){
            return api.getCities(cityInput)
                .then(res=>{
                    let cities = res.data.cityTags
                    console.log(cities)
                    let citiesOptions = cities.map(city=>{
                        let cityOption = {
                            label: city.name,
                            value: city.id
                        }
                        return cityOption
                    })
                    console.log("city options ", citiesOptions)
                    return citiesOptions
                })
    }else{
        return [{label: "", value: ""}]
    }


}
handleVersionApproval = (event, index)=>{
    this.props.handleFormUnsaved(true)
    const {value} = event.target;
    let additionalVersions = this.state.additionalVersions
    additionalVersions[index].approval = value;
    this.setState({additionalVersions: additionalVersions})
    console.log(value)
}
handleStemApproval = (event, index)=>{
    this.props.handleFormUnsaved(true)
    const {value} = event.target;
    let stems = this.state.stems
    stems[index].approval = value;
    this.setState({stems: stems})
    console.log(value)
}
deleteTrack = async ()=>{
    if(await confirm("Are you sure you want to remove this track? (This cannot be undone)", "Remove Track")){
        api.removeTrack(this.props.match.params.trackId)
        .then(res=>{
            console.log(res)
            if(res.data.success === true){
                window.location.replace("/dashboard/tracks/approved")
            }
        })
    }
    else{
        return
    }

}
removeVersion = (versionId)=>{
    api.removeVersion(versionId)
        .then(res=>{
            console.log(res)
            if(res.data.success){
                let remainingVersions = this.state.additionalVersions.filter(version => version.id !== res.data.deleted)
                this.setState({additionalVersions: remainingVersions})
            }
        })
}

removeStem = (stemId)=>{
    api.removeStem(stemId)
        .then(res=>{
            console.log(res)
            if(res.data.success){
                let remainingStems = this.state.instruments.filter(stem => stem.stemId !== res.data.deleted)
                this.setState({instruments: remainingStems})
            }
        })
}
componentDidMount = ( )=>{
    document.addEventListener('mousedown', this.handleModalClick, false)
    this.props.handleFormUnsaved(false)
    api.getTags()
        .then(res=>{
            //filter genre tags
            let genreOptions = []
            let emotionOptions = []
            let moodOptions = []
            let attributeOptions = []
            let instrumentOptions = []
            let cityOptions = []
            let bpmOptions = []
            let allOptions = []
            res.data.forEach(tag=>{
                switch(tag.type){
                    case 1: genreOptions.push({value: tag.id, label: tag.name.replace(/^\w/, c => c.toUpperCase())})
                        break;
                    case 2: emotionOptions.push({value: tag.id, label: tag.name.replace(/^\w/, c => c.toUpperCase())})
                        break;
                    case 3: moodOptions.push({value: tag.id, label: tag.name.replace(/^\w/, c => c.toUpperCase())})
                        break;
                    case 4: attributeOptions.push({value: tag.id, label: tag.name.replace(/^\w/, c => c.toUpperCase())})
                        break;
                    case 5: instrumentOptions.push({value: tag.id, label: tag.name})
                        break;
                    case 6: cityOptions.push({value: tag.id,label: tag.name.replace(/^\w/, c => c.toUpperCase())})
                        break;
                    case 8: bpmOptions.push({value: tag.id, label: tag.name})
                        break;
                    default:
                        break;
                }
            })

            allOptions = allOptions.concat(genreOptions, emotionOptions, moodOptions, attributeOptions, instrumentOptions, cityOptions, bpmOptions )
            // console.log('bpmOptions', bpmOptions)
            this.setState({
                genreOptions: genreOptions,
                emotionOptions: emotionOptions,
                moodOptions: moodOptions,
                attributeOptions: attributeOptions,
                instrumentOptions: instrumentOptions,
                cityOptions: cityOptions,
                bpmOptions: bpmOptions,
                allOptions: allOptions
            })
        })

        api.getIncompleteTrackById(this.props.match.params.trackId)
        .then( async res=>{
            if(!this.props.user.admin){
                window.location.replace("/dashboard/pending-track/" + this.props.match.params.trackId)
            }

            let publisherName;
            let publisherProOrg;
            let publisherProNum;
            let publisherPercent;
            let publisherRights;
            if(res.data.track.publisherProOrg === null){
                let publisherInfo = await api.getMyPublisher()
                console.log("publisher info ",publisherInfo)
                if(publisherInfo?.data.length > 0){
                    publisherName = publisherInfo.data[0].publisherName
                    publisherProOrg = {label: publisherInfo.data[0].org, value: publisherInfo.data[0].org}
                    publisherProNum = publisherInfo.data[0].number
                }
            }
            else{
                publisherName = res.data.track.publisherName
                publisherProOrg = {label: res.data.track.publisherProOrg, value: res.data.track.publisherProOrg}
                publisherProNum = res.data.track.publisherProNum
                publisherPercent = (res.data.track.publisherPercent*100).toFixed(0)
                publisherRights = "true"
            }

            let tags = res.data.track.tags
            console.log('track tags', tags)

            let genres = []
            let emotions = []
            let moods = []
            let attributes = []
            let instruments = []
            let city;
            let lyrics = {}
            let trackBPM

            tags.forEach(tag=>{
                switch(tag.type){
                    case 1: genres.push({value: tag.tagId, label: tag.name})
                        break;
                    case 2: emotions.push({value: tag.tagId, label: tag.name})
                        break;
                    case 3: moods.push({value: tag.tagId, label: tag.name})
                        break;
                    case 4: attributes.push({value: tag.tagId, label: tag.name})
                        break;
                    case 5: instruments.push({value: tag.tagId, label: tag.name})
                        break;
                    case 6: city = {value: tag.tagId, label: tag.name}
                        break;
                    case 7: if(tag.tagId === 172){
                        lyrics.exist = "false"
                        }
                        else if(tag.tagId === 173){
                            lyrics.exist = "true"
                        }
                        else if(tag.tagId === 174){
                            lyrics.explicit = "true"
                        }
                        break;
                    case 8: trackBPM = tag.tagId
                        break;
                }
            })

            console.log('trackBPM', trackBPM)

            let keywords = []
            let versions = []
            res.data.track.versions.forEach(version=>{
                versions.push({
                    value: version.name,
                    label: version.name,
                    wavURL: version.mp3URL || version.wavURL || "",
                    duration: version.duration,
                    peaks: version.peaks,
                    id: version.id,
                    localname: version.localname,
                    approvalStep: version.approvalStep
                })
            })
            //get stem data from response
            let stems = res.data.track.stems
            let completeCustomStems = []
            let completeTaggedStems = []
            let incompleteTaggedStems = []
            //for every stem in the response assign a label and value based on the stem nam
            if(stems){
                if(stems.length){
                    completeTaggedStems = stems.filter(stem=>{
                        let stemIndInTagsOptions = this.state.instrumentOptions.findIndex(instTag=> instTag.label === stem.name)
                        if(stemIndInTagsOptions ===-1){
                            completeCustomStems.push(stem)
                            return false
                        }
                        else{
                            let stemIsTagged = instruments.findIndex(instTag=>instTag.label === stem.name)
                            let instId = this.state.instrumentOptions.filter(inst=> inst.label === stem.name)[0].value
                            if(stemIsTagged === -1){
                                instruments.push({label: stem.name, value: instId})
                            }
                            return true
                        }
                    })
                }
                if(instruments.length > 0){
                    instruments.forEach( inst=>{
                        let instTagInSavedStems = stems.findIndex(stem=>stem.name === inst.label)
                        if(instTagInSavedStems === -1){
                            incompleteTaggedStems.push(inst)
                        }
                    })
                }
            }
            else{
                //if no stems were retreieved from the response, set the stems to the instruments
                stems = instruments
            }
            let alphaSort = (a,b)=>{
                if(a.name > b.name ){
                    return 1
                }
                else if(a.name < b.name){
                    return -1
                }
                else{
                    return 0
                }
            }
            completeTaggedStems = completeTaggedStems.map(stem=>{
                stem.wavURL = stem.mp3URL || stem.wavURL
                return stem
            })
            completeCustomStems = completeCustomStems.map(stem=>{
                stem.wavURL = stem.mp3URL || stem.wavURL
                return stem
            })
            stems.sort(alphaSort)

            let existingKeywords = []
            if(res.data.track.keywords) {
                console.log('track data keywords', res.data.track.keywords)
                // let trackKeywords = res.data.track.keywords
                existingKeywords = res.data.track.keywords.map(keyword => {
                    return{
                        id: keyword.keywordId,
                        label: keyword.name,
                        value: keyword.name
                    }
                })
            }
            let savedComposers = []
            let addedComposers =[]
            let myTotalShare = this.state.myTotalShare
            // let composerOptions  = composers
            if(res.data.composers.length > 0){
                res.data.composers.forEach(composer=>{
                    myTotalShare -= (composer.percent * 100).toFixed(0)

                    if(composer.artistId){
                        savedComposers.push({composerTrackId: composer.composerTrackId, composerId: composer.composerId, artistId: composer.artistId, profilePhoto: composer.profilePhoto, name: composer.artistName, label: composer.artistName, value: composer.artistName, percent: (composer.percent * 100).toFixed(0), dsComp: true, saved: true, })
                    }
                    else{
                        savedComposers.push({composerTrackId: composer.composerTrackId, composerId: composer.composerId, name: composer.compRecName, label: composer.compRecName, value: composer.compRecName, proOrg: {label: composer.compRecProOrg, value: composer.compRecProOrg}, proNum: composer.compRecProNum, percent: (composer.percent * 100).toFixed(0), nonDSComp: true, saved: true})
                    }
                })
            }
            else{
                savedComposers.push()
            }
            let rejectionReasons
            if(res.data.track.rejectionReasons){
                rejectionReasons = JSON.parse(res.data.track.rejectionReasons)
                versions.forEach(version=>{
                    console.log(" already vers ",version)
                    let matchedVersion =[]
                    if(rejectionReasons.versions){
                        if(rejectionReasons.versions.length > 0){
                            matchedVersion = rejectionReasons.versions.filter(additional=> additional.id === version.id)
                        }
                    }
                    console.log("matched ", matchedVersion)
                    if(matchedVersion.length > 0){
                        version.approval = matchedVersion[0].approval
                    }
                    else{
                        version.approval = undefined
                    }
                    // version.approval = rejectionReasons.versions.filter(additional=> additional.id == version.id)[0].approval
                })
                stems.forEach(stem=>{
                    let matchedStem =[]
                    if(rejectionReasons.stems){
                        if(rejectionReasons.stems.length > 0){
                            matchedStem = rejectionReasons.stems.filter(addtional => addtional.id === stem.id)
                        }
                    }
                    if(matchedStem.length > 0){
                        stem.approval = matchedStem[0].approval
                    }
                    else{
                        stem.approval = undefined
                    }
                })
            }
            else{
                rejectionReasons = {}
            }
            let mainTrackFileFilterComplete;
            let mainTrackVirusScanComplete;
            if(res.data.track?.trackStatus === "approvalStarted" || res.data.track?.trackStatus === "approved"){
                let stepDisplay;
                let progress;

                switch(res.data.track?.approvalStep){
                    case "file filter":
                        stepDisplay = "Stopped on File Filter"
                        progress = Math.round(100* (0/this.state.totalSteps))
                        break;
                    case "virus scan":
                        stepDisplay = "Stopped on Virus Scan"
                        mainTrackFileFilterComplete = true
                        progress = Math.round(100* (1/this.state.totalSteps))
                        break;
                    case "mp3 encode":
                        stepDisplay = "Stopped on MP3 Encode"
                        mainTrackFileFilterComplete = true
                        mainTrackVirusScanComplete = true
                        progress = Math.round(100* (2/this.state.totalSteps))
                        break;
                    case "watermark":
                        stepDisplay = "Stopped on Watermark"
                        mainTrackFileFilterComplete = true
                        mainTrackVirusScanComplete = true
                        progress = Math.round(100* (3/this.state.totalSteps))
                        break;
                    case "mp3 preview":
                        stepDisplay = "Stopped on MP3 Preview"
                        mainTrackFileFilterComplete = true
                        mainTrackVirusScanComplete = true
                        progress = Math.round(100* (4/this.state.totalSteps))
                        break;
                    case "generate waveform":
                        stepDisplay = "Stopped on Waveform Generation"
                        mainTrackFileFilterComplete = true
                        mainTrackVirusScanComplete = true
                        progress = Math.round(100* (5/this.state.totalSteps))
                        break;
                    case "complete":
                        stepDisplay = "Complete"
                        mainTrackFileFilterComplete = true
                        mainTrackVirusScanComplete = true
                        progress = Math.round(100* (6/this.state.totalSteps))
                        break;
                    default:
                        break;
                }
                let statusIcon;
                let mainTrackApprovalError
                if(res.data.track?.approvalStep !== "complete"){
                    statusIcon = "icon-warning text-primary"
                    mainTrackApprovalError = true
                }
                else{
                    statusIcon = "icon-check"
                    mainTrackApprovalError = false
                }
                this.setState({
                    approvalStep: res.data.track?.approvalStep,
                    mainTrackApprovalStepDisplay: stepDisplay,
                    mainTrackApprovalError: mainTrackApprovalError,
                    mainTrackProgress: progress,
                    mainTrackStatusIcon: statusIcon,
                    isApprovalModalOpen: true,
                    approvalComplete: res.data.track?.status === "approved"
                })
            }
            stems = stems.map(stem=>{
                if(!stem.approvalStep){
                    stem.approvalStep = "file filter"
                    if(res.data.track?.trackStatus === "approvalStarted"){
                        stem.approvalStepDisplay = "Stopped on File Filter"
                        stem.approvalError = true
                        stem.statusIcon = "icon-warning text-primary"
                    }
                    else{
                        stem.approvalLoading = true
                        stem.approvalStepDisplay = "Preparing"
                    }
                }
                else{
                    let progress;
                    switch(stem.approvalStep){
                        case "file filter":
                            stem.approvalStepDisplay = "Stopped on File Filter"
                            progress = Math.round(100 * (0/this.state.totalStemSteps))
                            break;
                        case "virus scan":
                            stem.approvalStepDisplay = "Stopped on Virus Scan"
                            stem.fileFilterComplete = true
                            progress = Math.round(100 * (1/this.state.totalStemSteps))
                            break;
                        case "mp3 encode":
                            stem.approvalStepDisplay = "Stopped on MP3 Encode"
                            stem.fileFilterComplete = true
                            stem.virusScanComplete = true
                            progress = Math.round(100 * (2/this.state.totalStemSteps))
                            break;
                        case "generate waveform":
                            stem.approvalStepDisplay = "Stopped on Waveform Generation"
                            stem.fileFilterComplete = true
                            stem.virusScanComplete = true
                            progress = Math.round(100 * (3/this.state.totalStemSteps))
                            break;
                        case "complete":
                            stem.approvalStepDisplay = "Complete"
                            stem.fileFilterComplete = true
                            stem.virusScanComplete = true
                            progress = Math.round(100 * (4/this.state.totalStemSteps))
                        default:
                            break;
                    }
                    stem.approvalProgress = progress
                    if(stem.approvalStep !== "complete"){
                        stem.approvalError = true
                        stem.statusIcon = "icon-warning text-primary"
                    }
                    else{
                        stem.statusIcon = "icon-check"
                    }
                }

                return stem
            })
            versions = versions.map(version=>{
                if(!version.approvalStep){
                    version.approvalStep = "file filter"
                    if(res.data.track?.trackStatus === "approvalStarted"){
                        version.approvalStepDisplay = "Stopped on File Filter"
                        version.approvalError = true
                        version.statusIcon = "icon-warning text-primary"
                    }
                    else{
                        version.approvalLoading = true
                        version.approvalStepDisplay = "Preparing"
                    }
                }
                else{
                    let progress;
                    switch(version.approvalStep){
                        case "file filter":
                            version.approvalStepDisplay = "Stopped on File Filter"
                            progress = Math.round(100 * (0/this.state.totalVersionSteps))
                            break;
                        case "virus scan":
                            version.approvalStepDisplay = "Stopped on Virus Scan"
                            version.fileFilterComplete = true
                            progress = Math.round(100 * (1/this.state.totalVersionSteps))
                            break;
                        case "mp3 encode":
                            version.approvalStepDisplay = "Stopped on MP3 Encode"
                            version.fileFilterComplete = true
                            version.virusScanComplete = true
                            progress = Math.round(100 * (2/this.state.totalVersionSteps))
                            break;
                        case "watermark":
                            version.approvalStepDisplay = "Stopped on Watermark"
                            version.fileFilterComplete = true
                            version.virusScanComplete = true
                            progress = Math.round(100 * (3/this.state.totalVersionSteps))
                            break;
                        case "generate waveform":
                            version.approvalStepDisplay = "Stopped on Waveform Generation"
                            version.fileFilterComplete = true
                            version.virusScanComplete = true
                            progress = Math.round(100 * (4/this.state.totalVersionSteps))
                            break;
                        case "complete":
                            version.approvalStepDisplay = "Complete"
                            version.fileFilterComplete = true
                            version.virusScanComplete = true
                            progress = Math.round(100 * (5/this.state.totalVersionSteps))
                            break;
                        default:
                            break;
                    }
                    version.approvalProgress = progress
                    if(version.approvalStep !== "complete"){
                        version.approvalError = true
                        version.statusIcon = "icon-warning text-primary"
                    }
                    else{
                        version.statusIcon = "icon-check"
                    }
                }

                return version
            })
            if(this.props.user.admin){
                api.getArtistAlbumsForAdmin(res.data.track.artistId)
                .then(albumRes=>{
                    console.log(albumRes)
                    if(albumRes.data.length){
                        let album = albumRes.data.filter(album=> album.id=== res.data.track.albumId)[0]
                        this.setState({
                            myAlbums: albumRes.data.map(album=>{
                                return{
                                    label: album.title,
                                    value: album.id,
                                    imageUrl: album.imageUrl
                                }
                            }),
                            album: {label: album.title, value: album.id, imageUrl: album.imageUrl}
                        })
                    }
                })
            }
            else{
                api.getMyAlbums()
                .then(albumRes=>{
                    console.log(albumRes)
                    if(albumRes.data.length){
                        let album = albumRes.data.filter(album=> album.id=== res.data.track.albumId)[0]
                        this.setState({
                            myAlbums: albumRes.data.map(album=>{
                                return{
                                    label: album.title,
                                    value: album.id,
                                    imageUrl: album.imageUrl
                                }
                            }),
                            album: {label: album.title, value: album.id, imageUrl: album.imageUrl}
                        })
                    }
                })
            }

        //    console.log("setting comp state", composers)
            // console.log("setting trackBPM", trackBPM)
            this.setState({
                artistName: res.data.track.artistName,
                artistId: res.data.track.artistId,
                contentCheck:  rejectionReasons.content || undefined,
                curationCheck:  rejectionReasons.curation || undefined,
                exclusivityCheck:  rejectionReasons.exclusivity || undefined,
                trackCheck: rejectionReasons.trackQuality || undefined,
                // addedComposers: addedComposers,
                savedComposers: savedComposers,
                mainTrackFileFilterComplete: mainTrackFileFilterComplete,
                mainTrackVirusScanComplete: mainTrackVirusScanComplete,
                // composers: composers,
                myTotalShare: myTotalShare,
                title: res.data.track.trackTitle,
                localname: res.data.track.localname,
                duration: res.data.track.duration,
                attributes: attributes,
                emotions: emotions,
                genres: genres,
                instruments: instruments,
                stems: stems,
                incompleteTaggedStems: incompleteTaggedStems,
                completeTaggedStems: completeTaggedStems,
                completeCustomStems: completeCustomStems,
                moods: moods,
                city: city,
                trackBPM: trackBPM || "",
                containsLyrics: lyrics ? lyrics.exist === "true" ? "true": "false": "false",
                explicitLyrics: lyrics ? lyrics.explicit === "true" ? "true": "false": "false",
                funFact: res.data.track.funFact,
                additionalVersions: versions,
                additionalSongwriters: savedComposers.length ? "true": this.state.additionalSongwriters,
                versionsAvailable: versions.length ? "true": this.state.versionsAvailable,
                stemsAvailable: res.data.track.stems.length ? "true": this.state.stemsAvailable,
                keywords: existingKeywords,
                trackStatus: res.data.track.trackStatus,
                approvalFailures: res.data.track.approvalFailures,
                wavURL: res.data.track.mp3URL || res.data.track.wavURL || "",
                peaks: res.data.track.peaks,
                publisherRights: publisherRights,
                publisherName: publisherName,
                publisherProOrg: publisherProOrg,
                publisherProNum: publisherProNum,
                publisherPercent: publisherPercent,
                initialMount: true,
                pageLoaded: true
            })
        })
        .catch(err => {
            this.setState({ error: err.message });
        })



    }


setFileState = (file)=>{
    console.log("setting file state")
    this.setState({
        file: file
    })
}

handleAlbumChange = (selectedAlbum)=>{
    this.props.handleFormUnsaved(true)
    this.setState(state =>{
        return{
            album: selectedAlbum
        }
    })
}
handleFormSubmit = (submitting) => {
    console.log("submitting")
    let rejectionBody = {}
    rejectionBody.content = this.state.contentCheck
    rejectionBody.curation = this.state.curationCheck
    rejectionBody.exclusivity = this.state.exclusivityCheck
    if(this.state.trackCheck === "reject"){
        rejectionBody.trackQuality = "reject"
        rejectionBody.trackURL = this.state.wavURL
    }
    else if(this.state.trackCheck === "approve"){
        rejectionBody.trackQuality = "approve"
    }
    let versions = []
    this.state.additionalVersions.forEach(version=>{
        versions.push({id: version.id, wavURL: version.wavURL, approval: version.approval})
    })
    if(versions.length > 0){
        rejectionBody.versions = versions
    }
    let stems = []
    this.state.stems.forEach(stem=>{
        stems.push({id: stem.id, wavURL: stem.wavURL, approval: stem.approval})
    })
    if(stems.length > 0){
        rejectionBody.stems = stems
    }

    console.log("rejection ",rejectionBody)

    console.log("trackBPM", this.state.trackBPM)

    let keywords = this.state.keywords || []

    let trackInfo = {
        title: this.state.title,
        albumId: this.state.album.value,
        keywords: this.state.keywords ? this.state.keywords.map(keyword=> keyword.value).join(", ") : undefined,
        funFact: this.state.funFact,
        rejectionReasons: rejectionBody
    }
    let tags = []
    if(this.state.genres){
        this.state.genres.forEach(genre=>{tags.push(genre.value)})
    }
    if(this.state.emotions){
        this.state.emotions.forEach(emotion=>{tags.push(emotion.value)})
    }
    if(this.state.moods){
        this.state.moods.forEach(mood=>{tags.push(mood.value)})
    }
    if(this.state.attributes){
        this.state.attributes.forEach(attribute=>{tags.push(attribute.value)})
    }
    if(this.state.instruments){
        this.state.instruments.forEach(instrument=>{tags.push(instrument.value)})
    }
    if(this.state.city){
        tags.push(this.state.city.value)
    }
    if(this.state.trackBPM){
        tags.push(parseInt(this.state.trackBPM))
    }
    if(this.state.containsLyrics === "true"){
        //need to point to lyrics tag in db
        tags.push(173)
        if(this.state.explicitLyrics === "true"){
            tags.push(174)
        }
    }
    else{
        tags.push(172)
    }
    api.editTrackById(this.props.match.params.trackId, trackInfo, "saving", tags, keywords)
        .then(res=>{
            if(res.data.success ){
                    Success( {onClose: this.successToastSave, message:"Track Saved", autoClose: 3000})
            }
            else{
                Error({message:"An error occurred!", onClose: this.errorToast})
            }
        }).catch(error=>{
            console.log(error)

            Error({message:"An error occurred!", onClose:this.errorToast})
        })

}
openAlbumModal = ()=>{
    this.setState({isAlbumModalOpen: true})
}
handleAlbumModalClose= ()=>{
    if(this.state.albumLoading){
        return
    }
    else{
        this.setState({isAlbumModalOpen: false})
    }

}
downloadFullTrack=()=>{
    console.log("downloading full track")
    this.setState({isDownloading: true})
    let filename = this.props.artistName + "_" + this.props.trackTitle + "_Full.wav"

    api.adminMainTrackDownload(this.props.match.params.trackId)
        .then(res=>{
            console.log("down res" ,res)

            if(res.data?.url){
                console.log("down res" ,res)
                let downloadEl = document.createElement("a")
                downloadEl.setAttribute("href", res.data.url)
                downloadEl.setAttribute("download", res.data.filename.split(" ").join(""))
                downloadEl.style.display = 'none'
                document.body.appendChild(downloadEl);
                downloadEl.click();
                console.log("download el ",downloadEl)
                this.setState({isDownloading: false})
            }

        })
        .catch(err=>{
            console.log("error ", err)
            this.setState({isDownloading: false})
        })
}
downloadVersion=(versionId)=>{
    console.log("downloading full track")
    this.setState({isDownloading: true})
    let filename = this.props.artistName + "_" + this.props.trackTitle + "_Full.wav"

    api.adminDownloadFullVersion(versionId)
        .then(res=>{
            console.log("down res" ,res)

            if(res.data?.url){
                console.log("down res" ,res)
                let downloadEl = document.createElement("a")
                downloadEl.setAttribute("href", res.data.url)
                downloadEl.setAttribute("download", res.data.filename.split(" ").join(""))
                downloadEl.style.display = 'none'
                document.body.appendChild(downloadEl);
                downloadEl.click();
                console.log("download el ",downloadEl)
                this.setState({isDownloading: false})
            }

        })
        .catch(err=>{
            console.log("error ", err)
            this.setState({isDownloading: false})
        })
}
downloadStem=(stemId)=>{
    console.log("downloading full track")
    this.setState({isDownloading: true})
    let filename = this.props.artistName + "_" + this.props.trackTitle + "_Full.wav"

    api.adminDownloadStem(stemId)
        .then(res=>{
            console.log("down res" ,res)

            if(res.data?.url){
                console.log("down res" ,res)
                let downloadEl = document.createElement("a")
                downloadEl.setAttribute("href", res.data.url)
                downloadEl.setAttribute("download", res.data.filename.split(" ").join(""))
                downloadEl.style.display = 'none'
                document.body.appendChild(downloadEl);
                downloadEl.click();
                console.log("download el ",downloadEl)
                this.setState({isDownloading: false})
            }

        })
        .catch(err=>{
            console.log("error ", err)
            this.setState({isDownloading: false})
        })
}
    render () {
        return(
            <UserConsumer>
            {({ user, login, logout})=>(

            <div className="pt-50 lg:pt-0">

<MicroModal
                                        open={this.state.isAlbumModalOpen}
                                        closeOnEscapePress={true}
                                        handleClose={()=>this.handleAlbumModalClose()}
                                        closeOnOverlayClick={true}
                                        closeOnAnimationEnd={true}
                                        modalOverlayClassName="modal-overlay"
                                        modalClassName="modal micromodal-slide is-open"
                                        modalOverlayStyles={null}
                                        children={handleClose=><AlbumModal loadingCB={this.albumLoadingCB} handleClose={handleClose} updateAlbumsCB={this.updateAlbumsCB} artistId={this.state.artistId}/>}
                                        containerStyles={{background: "#131313", padding:"asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box"}}
                                    ></MicroModal>

                    <MicroModal
                                open={this.state.isApprovalModalOpen}
                                children={handleClose=><ApprovalModal mainTrackApprovalStepDisplay={this.state.mainTrackApprovalStepDisplay} mainTrackLoading={this.state.mainTrackLoading} mainTrackStatusIcon={this.state.mainTrackStatusIcon}
                                    retryMain={this.retryMain} retryVersion={this.retryVersion} retryStem={this.retryStem} mainTrackApprovalError={this.state.mainTrackApprovalError} mainTrackProgress={this.state.mainTrackProgress} versions={this.state.additionalVersions} stems={this.state.stems} approvalComplete={this.state.approvalComplete} finalTrackURL={this.state.finalTrackURL}
                                    invalidFileType={this.state.invalidFileType} fileFilterComplete={this.state.fileFilterComplete} virusDetected={this.state.virusDetected} virusScanComplete={this.state.virusScanComplete} totallyFailed={this.state.totallyFailed} restartApproval={this.restartApproval} approvalFailures={this.state.approvalFailures} setTrackBroken={this.setTrackBroken}/>}
                                modalOverlayClassName="modal-overlay"
                                modalClassName="modal micromodal-slide is-open"
                                containerStyles={{ background: "#131313", padding: "asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box" }}

                    />
                    <MicroModal
                        open={this.state.isRejectionCommentModalOpen}
                        children={handleClose=><RejectionCommentModal handleInputChange={this.hanldeRejectionCommentChange} rejectionComment={this.state.rejectionComment} rejectionCommentError={this.state.rejectionCommentError} submitting={this.state.isRejecting} rejectTrack={this.handleReject} handleClose={this.handleRejectionCommentClose}/>}
                        modalOverlayClassName="modal-overlay"
                        modalClassName="modal micromodal-slide is-open"
                        containerStyles={{ background: "#131313", padding: "asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box" }}

                    />
                                                <MicroModal
                                open={this.state.isLoadingModalOpen}
                                children={handleClose=><LoadingModal/>}
                                modalOverlayClassName="modal-overlay"
                                modalClassName="modal micromodal-slide is-open"
                                containerStyles={{ background: "#131313", padding: "asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box", zIndex: 75 }}

                    />
                {/* {!this.state.pageLoaded &&
                    <div style={{height: "100vh", position: "fixed", width: "100%",zIndex: "100", paddingRight: "50%"}}>
                        <BackdropOverlay loading={!this.state.pageLoaded} size={100}/>
                    </div>
                } */}
                {!this.state.pageLoaded?
                <div style={{height: "100vh"}}>
                    <div style={{margin: "25% auto", width: "50%"}}>
                        <CircularIndeterminant size={40} forceCenter={true}/>
                    </div>
                </div>:
                <>
                <div className="container">


                    {/* {# App Header #} */}
                    <div className="view-header">
                        <h1 className="view-title">New Track</h1>
                        <div className="view-header-controls">
                            {this.state.approvability === true ?
                            <button type="button" disabled={this.state.detailsComplete !== true || this.state.tagsComplete !== true} onClick={(event)=>{event.preventDefault();this.handleApprove()}} className={`btn btn-stroke ${this.state.detailsComplete !== true || this.state.tagsComplete !== true ? "btn-disabled": ""}`}>Approve Track</button>:
                                                            <>
                                                            {this.state.approvability === false ?
                                                                <button type="button" disabled={this.state.detailsComplete !== true || this.state.tagsComplete !== true} onClick={(event)=>{event.preventDefault();this.handleRejectionCommentOpen()}} className={`btn btn-stroke ${this.state.detailsComplete !== true || this.state.tagsComplete !== true ? "btn-disabled": ""}`}>Reject Track</button>:
                                                                <button type="button" disabled onClick={(event)=>{event.preventDefault();}} className="btn btn-disabled btn-stroke">Reject Track</button>
                                                            }
                                                            </>
                            }
                            <button type="button" onClick={(event)=>{event.preventDefault();this.handleFormSubmit(false)}} className="btn btn-primary">Save and Quit</button>
                            <div>
                              <Tooltip
                                  useContext={true}
                                  arrow={false}
                                  visible={this.state.showTippy}
                                  interactive={true}
                                  position={'bottom-end'}
                                  trigger={'click'}
                                  maxWidth={'180'}
                                  html={<AdminTrackTooltip
                                            delete={this.deleteTrack}>
                                          </AdminTrackTooltip>}
                                  allowHTML={true}
                                  theme={'dark'}
                                  onClickOutside={()=>{this.state.showTippy ? this.setState({showTippy: false}): this.setState({showTippy: true})}}
                              >
                                <button className="icon-btn js-dropdown-toggle"
                                    // data-tippy-html="#new_track_options"
                                    type="button"
                                    data-tippy-placement="bottom-end"
                                    onClick={()=>{this.state.showTippy ? this.setState({showTippy: false}): this.setState({showTippy: true})}}
                                    >
                                    <span className="icon-dots-vert"></span>
                                </button>

                              </Tooltip>
                            </div>
                        </div>
                    </div>

                    {/* {# Track Preview #} */}

                    </div>
                    <div className="container">
                    {/* {# App Main Inner #} */}
                    <div className="app-sublayout">

                        {/* {# App Main Content #} */}
                        <div className="app-sublayout-content mb-50 lg:mb-80">

                            {/* {# Form #} */}
                            <form action="" className="ds-form">
                            {/* {# Viability Checklist #} */}
                            <div className="form-section" id="viability">
                                <h2 className="text-label-lg mb-40">Viability Checklist</h2>

                                {/* {# Content #} */}
                                <div className="input-group" id="content">
                                    <p className="input-label mb-10" htmlFor="track_title">Content</p>
                                    <p className="mb-20">The track does not contain any inappropriate content.</p>
                                    <div className="grid-row-xs">
                                        <div className="col w-1/2">
                                            <label className="radio-option radio-btn">
                                                <input checked={this.state.contentCheck === "approve"} onChange={this.handleInputChange} type="radio" className="form-radio" name="contentCheck" value="approve"/>
                                                Approve
                                            </label>
                                        </div>
                                        <div className="col w-1/2">
                                            <label className="radio-option radio-btn">
                                                <input checked={this.state.contentCheck === "reject"} onChange={this.handleInputChange} type="radio" className="form-radio form-radio-reject" name="contentCheck" value="reject"/>
                                                Reject
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/* {# Curation #} */}
                                <div className="input-group" id="curation">
                                    <p className="input-label mb-10" htmlFor="track_title">Curation</p>
                                    <p className="mb-20">The track fits within Deep Sounds' curation standards.</p>
                                    <div className="grid-row-xs">
                                        <div className="col w-1/2">
                                            <label className="radio-option radio-btn">
                                                <input checked={this.state.curationCheck === "approve"} onChange={this.handleInputChange} type="radio" className="form-radio" name="curationCheck" value="approve"/>
                                                Approve
                                            </label>
                                        </div>
                                        <div className="col w-1/2">
                                            <label className="radio-option radio-btn">
                                                <input checked={this.state.curationCheck === "reject"} onChange={this.handleInputChange} type="radio" className="form-radio form-radio-reject" name="curationCheck" value="reject"/>
                                                Reject
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                {/* {# Exclusivity #} */}
                                <div className="input-group" id="exclusivity">
                                    <p className="input-label mb-10" htmlFor="track_title">Exclusivity</p>
                                    <p className="mb-20">The track is not available for sync licensing on any other music library.</p>
                                    <div className="grid-row-xs">
                                        <div className="col w-1/2">
                                            <label className="radio-option radio-btn">
                                                <input checked={this.state.exclusivityCheck === "approve"} onChange={this.handleInputChange} type="radio" className="form-radio" name="exclusivityCheck" value="approve"/>
                                                Approve
                                            </label>
                                        </div>
                                        <div className="col w-1/2">
                                            <label className="radio-option radio-btn">
                                                <input checked={this.state.exclusivityCheck === "reject"} onChange={this.handleInputChange} type="radio" className="form-radio form-radio-reject" name="exclusivityCheck" value="reject"/>
                                                Reject
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            {/* {# Full Track #} */}
                            <div className="form-section" id="full_track">
                                <h2 className="text-label-lg mb-40">Full Track</h2>
                                <p className="input-label mb-10">Verify Audio Quality</p>
                                <p className="mb-20">Please verify that the audio quality is at a premium level</p>
                                {/* {# Full Track Admin View #} */}
                                <div className="input-fieldset">
                                    {/* {# File #} */}
                                    <div className="upload-item">
                                        <div className="upload-item-details">
                                            {this.state.wavURL ?
                                            <div className="upload-item-title">{this.state.wavURL.substring(this.state.wavURL.lastIndexOf("/")+1)}.wav</div>:
                                            <></>
                                            }
                                        </div>
                                        <div className="upload-item-controls">
                                            <button type="button" onClick={this.downloadFullTrack} class="circle-icon circle-icon-sm"><span class="icon-download"></span></button>
                                            <button type="button"  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                        </div>
                                    </div>
                                    <hr className="divider-sm"/>
                                    {/* {# Track Preview #} */}
                                    <div className="track-preview track-preview-sm track-preview-sm">
                                        <div className="track-preview-play">
                                        <button type="button"  onClick={(event)=>{this.changeNowPlaying(event,this.state.wavURL)}} className="circle-icon-border circle-icon-lg">{this.state.nowPlaying === this.state.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                        </div>
                                        <div className="track-preview-waveform">
                                            {/* {% include "components/audio/waveform-lg.html" %} */}
                                            {this.state.wavURL ?
                                            <FullTrackWaveform unsetNowPlaying={this.unsetNowPlaying} nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={this.state.wavURL} peaks={this.state.peaks} />:
                                            <></>}
                                        </div>
                                        <div className="track-preview-duration">

                                        </div>
                                    </div>
                                    {/* {# Approval #} */}
                                    <div className="input-fieldset-cond is-visible">
                                        <hr className="divider-sm"/>
                                        <div className="grid-row-sm">
                                            <div className="col w-1/2">
                                                <label className="radio-option">
                                                    <input checked={this.state.trackCheck ==="approve"} onChange={this.handleInputChange} type="radio" className="form-radio" name="trackCheck" value="approve"/>
                                                    Approve
                                                </label>
                                            </div>
                                            <div className="col w-1/2">
                                                <label className="radio-option">
                                                    <input checked={this.state.trackCheck ==="reject"} onChange={this.handleInputChange} type="radio" className="form-radio form-radio-reject" name="trackCheck" value="reject"/>
                                                    Reject
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {/* {# Versions #} */}
                            <div className="form-section" id="versions_quality">
                                <h2 className="text-label-lg mb-40">Versions</h2>
                                <div className="grid-row-sm justify-between items-center">
                                    <div className="col">
                                        <p className="input-label mb-10">Verify Audio Quality</p>
                                        <p className="mb-20">Please verify that the audio quality is at a premium level</p>
                                    </div>
                                </div>
                                {this.state.additionalVersions.map((version, index)=>(
                                    <div className="input-fieldset mb-20">
                                          <div className="upload-item">
                                            <div className="upload-item-pre">
                                <button type="button"  onClick={(event)=>{this.changeNowPlaying(event, version.wavURL)}} className="circle-icon-border circle-icon-sm">{this.state.nowPlaying === version.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                            </div>
                                            <div className="upload-item-details">
                                                <span className="upload-item-title">{version.label}</span>
                                                <span className="upload-item-file">{version.localname}</span>
                                            </div>
                                            <div className="upload-item-wave">
                                                <Waveform unsetNowPlaying={this.unsetNowPlaying} nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={version.wavURL} peaks={version.peaks} />
                                                {/* {% include "components/audio/waveform unsetNowPlaying={this.unsetNowPlaying}-sm.html" %} */}
                                                <span className="upload-item-duration">{version.duration}</span>
                                            </div>
                                            <div className="upload-item-controls">
                                                <button type="button" onClick={()=>this.downloadVersion(version.id)} class="circle-icon circle-icon-sm"><span class="icon-download"></span></button>
                                                <button type="button"  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                            </div>
                                        </div>

                                    {/* {# Approval #} */}
                                    <hr className="divider-sm"/>
                                    <div className="grid-row-sm">
                                        <div className="col w-1/2">
                                            <label className="radio-option">
                                                <input checked={version.approval==="approve"} onChange={(event)=>this.handleVersionApproval(event,index)} type="radio" className="form-radio" name={`version-${version.wavURL}`} value="approve"/>
                                                Approve
                                            </label>
                                        </div>
                                        <div className="col w-1/2">
                                            <label className="radio-option">
                                                <input checked={version.approval==="reject"} onChange={(event)=>this.handleVersionApproval(event,index)} type="radio" className="form-radio form-radio-reject" name={`version-${version.wavURL}`} value="reject"/>
                                                Reject
                                            </label>
                                        </div>
                                    </div>

                                </div>
                                ))}
                                {/* {% include "components/forms/versions-admin.html" %} */}

                            </div>

                            {/* {# Stems #} */}
                            <div className="form-section" id="stems_quality">
                                <h2 className="text-label-lg mb-40">Stems</h2>
                                <div className="grid-row-sm justify-between items-center">
                                    <div className="col">
                                        <p className="input-label mb-10">Verify Audio Quality</p>
                                        <p className="mb-20">Please verify that the audio quality is at a premium level</p>
                                    </div>
                                </div>
                                {/* {# Stems Item #} */}
                                {this.state.stems.map((stem, index) =>(
                                    <div className="input-fieldset mb-20">
                                        {/* {# Upload Item #} */}
                                        <div className="upload-item">
                                            <div className="upload-item-pre">
                                                <button type="button"  onClick={(event)=>{this.changeNowPlaying(event, stem.wavURL)}} className="circle-icon-border circle-icon-sm">{this.state.nowPlaying === stem.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                            </div>
                                            <div className="upload-item-details">
                                                <span className="upload-item-title">{stem.name}</span>
                                                <span className="upload-item-file">{stem.localname}</span>
                                            </div>
                                            <div className="upload-item-wave">
                                                <Waveform unsetNowPlaying={this.unsetNowPlaying} nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={stem.wavURL} peaks={stem.peaks} />
                                                {/* {% include "components/audio/waveform unsetNowPlaying={this.unsetNowPlaying}-sm.html" %} */}
                                                <span className="upload-item-duration">{stem.duration}</span>
                                            </div>
                                            <div className="upload-item-controls">
                                            <button type="button" onClick={()=>this.downloadStem(stem.id)} class="circle-icon circle-icon-sm"><span class="icon-download"></span></button>
                                                <button type="button"  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                            </div>
                                        </div>
                                        {/* {# Approval #} */}
                                        <hr className="divider-sm"/>
                                        <div className="grid-row-sm">
                                            <div className="col w-1/2">
                                                <label className="radio-option">
                                                    <input checked={stem.approval ==="approve"} onChange={(event)=>this.handleStemApproval(event,index)} type="radio" className="form-radio" name={`stem-${stem.wavURL}`} value="approve"/>
                                                    Approve
                                                </label>
                                            </div>
                                            <div className="col w-1/2">
                                                <label className="radio-option">
                                                    <input checked={stem.approval ==="reject"} onChange={(event)=>this.handleStemApproval(event,index)} type="radio" className="form-radio form-radio-reject" name={`stem-${stem.wavURL}`} value="reject"/>
                                                    Reject
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                ))}

                                {/* {% include "components/forms/stems-admin.html" %} */}
                            </div>
                                {/* {# Track Details #} */}
                                <div className="form-section">
                                    <h2 className="text-label-lg mb-40" id="details">Track Details</h2>

                                    {this.state.artistId !== user.id &&
                                        <div className="input-group">
                                            <label className="input-label" htmlFor="track_title">
                                                Track Artist
                                            </label>
                                            <input
                                                disabled
                                                id="track_artist"
                                                className="form-input"
                                                value={this.state.artistName}
                                                type="text"
                                                name="artist"
                                            />
                                        </div>
                                    }

                                    <div className="input-group">
                                        <label className="input-label" htmlFor="track_title">
                                            Track Title
                                            <span class="input-label-required">*</span>
                                        </label>
                                        <input
                                            id="track_title"
                                            className="form-input"
                                            value={this.state.title}
                                            onChange={this.handleInputChange}
                                            type="text"
                                            name="title"
                                            placeholder="Enter track title here..."/>
                                    </div>

                                    {/* {# Album Select #} */}
                                    <div className="form-section" id="album_select">
                                      <div className="input-group">
                                        <label htmlFor="album" className="input-label">
                                          Link to Album
                                          <span class="input-label-required">*</span>
                                          <span className="input-label-info">Choose an Album</span>
                                        </label>
                                        <div className="grid-row-xs">
                                          <div className="col flex-1">
                                        <Select
                                            name="album"
                                            className="select-container"
                                            classNamePrefix="select"
                                            styles={customStyles}
                                            placeholder="Select an Album"
                                            value={this.state.album}
                                            options={this.state.myAlbums}
                                            onChange={this.handleAlbumChange}
                                            formatOptionLabel={formatAlbumLabel}
                                        />
                                         </div>
                                          <div className="col w-auto flex-initial">
                                              <button
                                                type="button"
                                                onClick={(e)=>{e.preventDefault();console.log("album modal button clicked");this.openAlbumModal()}}
                                                className="btn btn-stroke form-btn w-full"
                                              >
                                                <span className="icon-plus mr-5"></span>
                                                Create
                                              </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                </div>

                                {/* {# Tags #} */}
                                <div className="form-section" id="tags">
                                    <h2 className="text-label-lg mb-40">Tags</h2>

                                    {/* {# Genres #} */}
                                    <div className="input-group">
                                        <label htmlFor="genres" className="input-label">
                                            What Genres best describes this track?
                                            <span class="input-label-required">*</span>
                                            <span className="input-label-info">Choose up to 2</span>
                                        </label>
                                        <Select
                                            name="genres"
                                            className="select-container"
                                            classNamePrefix="select"
                                            styles={customStylesMulti}
                                            placeholder="Search for Genres..."
                                            value={this.state.genres}
                                            options={this.state.genreOptions}
                                            onChange={this.handleGenreChange}
                                            isMulti
                                            isSearchable
                                        />

                                    </div>

                                    {/* {# Emotions #} */}
                                    <div className="input-group">
                                        <label htmlFor="emotions" className="input-label">
                                            What Primary Emotion does this track evoke?
                                            <span class="input-label-required">*</span>
                                            <span className="input-label-info">Choose 1</span>
                                        </label>
                                        <Select
                                            name="emotions"
                                            className="select-container"
                                            classNamePrefix="select"
                                            styles={customStylesMulti}
                                            placeholder="Search for an Emotion..."
                                            value={this.state.emotions}
                                            options={this.state.emotionOptions}
                                            onChange={this.handleEmotionChange}
                                            isMulti
                                            isSearchable
                                        />
                                    </div>
                                    {/* {# Moods #} */}
                                    <div className="input-group">
                                        <label htmlFor="moods" className="input-label">
                                            What Moods best match this track?
                                            <span class="input-label-required">*</span>
                                            <span className="input-label-info">Choose up to 4</span>
                                        </label>
                                        <Select
                                            name="moods"
                                            className="select-container"
                                            classNamePrefix="select"
                                            styles={customStylesMulti}
                                            placeholder="Search for Moods..."
                                            value={this.state.moods}
                                            options={this.state.moodOptions}
                                            onChange={this.handleMoodChange}
                                            isMulti
                                            isSearchable
                                        />
                                    </div>

                                    {/* {# Attributes #} */}
                                    <div className="input-group">
                                        <label htmlFor="attributes" className="input-label">
                                            What Attributes best match this track?
                                            <span class="input-label-required">*</span>
                                            <span className="input-label-info">Choose up to 8</span>
                                        </label>
                                        <Select
                                            name="attributes"
                                            className="select-container"
                                            classNamePrefix="select"
                                            styles={customStylesMulti}
                                            placeholder="Search for Attributes..."
                                            value={this.state.attributes}
                                            options={this.state.attributeOptions}
                                            onChange={this.handleAttributeChange}
                                            isMulti
                                            isSearchable
                                        />
                                    </div>


                                    {/* {# Instruments #} */}
                                    <div className="input-group">
                                        <label htmlFor="instruments" className="input-label">
                                            What Instruments are featured on this track?
                                            <span class="input-label-required">*</span>
                                            <span className="input-label-info">Choose as many as apply</span>
                                        </label>
                                        <Select
                                            name="instruments"
                                            className="select-container"
                                            classNamePrefix="select"
                                            styles={customStylesMulti}
                                            placeholder="Search for Instruments..."
                                            value={this.state.instruments}
                                            options={this.state.instrumentOptions}
                                            onChange={this.handleInstrumentsChange}
                                            isMulti
                                            isSearchable
                                        />
                                    </div>
                                <KeywordsInput
                                    keywords={this.state.keywords}
                                    customStylesMulti={customStylesMulti}
                                    handleKeywordChange={this.handleKeywordChange}
                                    allOptions={this.state.allOptions}
                                />
                                {/* {# Lyrics #} */}
                                <div className="input-group">
                                    <p className="input-label">
                                        Does this track contain lyrics?
                                        <span class="input-label-required">*</span>
                                    </p>
                                    {/* {# Fieldset #} */}
                                    <fieldset className="input-fieldset">
                                        <div className="grid-row-sm">
                                            <div className="col w-1/2">
                                                <label className="radio-option">
                                                    <input onChange={this.handleInputChange} type="radio" className="form-radio" name="containsLyrics" value="false" checked={this.state.containsLyrics === "false"}/>
                                                    No
                                                </label>
                                            </div>
                                            <div className="col w-1/2">
                                                <label className="radio-option">
                                                    <input onChange={this.handleInputChange} type="radio" className="form-radio" name="containsLyrics" value="true" checked={this.state.containsLyrics === "true"}/>
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                        {/* {# Conditional Fields #} */}
                                        {this.state.containsLyrics ==="true" ?
                                        <div className="input-fieldset-cond is-visible">
                                            <hr className="divider-sm"/>
                                            <p className="input-label">
                                                Does this track contain explicit lyrics?
                                                <span class="input-label-required">*</span>
                                            </p>
                                            <div className="grid-row-sm">
                                                <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input onChange={this.handleInputChange} type="radio" className="form-radio" name="explicitLyrics" value="false" checked={this.state.explicitLyrics === "false"}/>
                                                        No
                                                    </label>
                                                </div>
                                                <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input onChange={this.handleInputChange} type="radio" className="form-radio" name="explicitLyrics" value="true" checked={this.state.explicitLyrics === "true"}/>
                                                        Yes
                                                    </label>
                                                </div>
                                            </div>
                                        </div>: <></>}
                                    </fieldset>
                                </div>
                                <div className="input-group">
                                    <p className="input-label">
                                        What BPM accurately describes this track?
                                        <span class="input-label-required">*</span>
                                    </p>
                                    <div className="grid-row-xs">
                                            {this.state.bpmOptions.map((bpm, index)=>(
                                                <div className="col w-full md:w-1/2 mb-20">
                                                    <label className="radio-option radio-btn">
                                                        <input
                                                            onChange={this.handleInputChange}
                                                            type="radio"
                                                            className="form-radio"
                                                            name="trackBPM"
                                                            value={bpm.value}
                                                            checked={parseInt(this.state.trackBPM) === parseInt(bpm.value)}
                                                        />
                                                        {bpm.label}
                                                    </label>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                                {/* {# City #} */}
                                <div className="input-group">
                                <label htmlFor="city" className="input-label">
                                    In which City was this track made?
                                    <span class="input-label-required">*</span>
                                    <span className="input-label-info">Choose one</span>
                                </label>
                                <p>We recommend using the closest major city for best results.</p>
                                    <AsyncSelect
                                            key={"citSelect"+this.state.city?.label}
                                            name="city"
                                            styles={customStylesCity}
                                            cacheOptions
                                            // defaultOptions={this.state.city ? [{label: this.state.city.label, value: this.state.city.value }]:[]}
                                            // defaultInputValue={this.state.city?.label}
                                            inputValue={this.state.cityInput}
                                            loadOptions={this.getCities}
                                            onInputChange={this.changeCityInput}
                                            onKeyDown={this.cityKeyDown}
                                            onFocus={()=>{
                                                if(this.state.city?.label && !this.state.cityInput){
                                                    this.changeCityInput(this.state.city?.label)
                                                }
                                            }}
                                            onClick={this.altChangeCityInput}
                                            placeholder="Type to add a City..."
                                            value={this.state.city}
                                            // options={this.state.cityOptions}
                                            noOptionsMessage={() => "Options will be populated as you type"}

                                            onChange={this.handleCityChange}
                                            // isSearchable
                                        />
                                </div>
                                </div>
                                {/* {# Fun Fact #} */}
                                <div className="form-section" id="fun_fact">
                                    <h2 className="text-label-lg mb-40">Fun Fact</h2>
                                    <div className="input-group">
                                        <label className="input-label" htmlFor="track_fun_fact">
                                            Tell us about your track (optional).
                                        </label>
                                        <textarea
                                            name="funFact"
                                            value={this.state.funFact}
                                            onChange={this.handleInputChange}
                                            id="track_fun_fact"
                                            className="form-textarea"
                                            rows="6"
                                            placeholder="Do you have any interesting facts about this track? What inspired you to make it? This info will be listed in the About section of the track and can help customers make a connection to your track!"
                                            >
                                        </textarea>
                                        <CharacterCount
                                            limit="400"
                                            value={this.state.funFact?.length}
                                        />
                                    </div>
                                </div>


                                <div className="form-section" id="versions">
                                <h2 className="text-label-lg mb-40">Versions</h2>
                                <div className="input-group">
                                    <p className="input-label mb-10">Would you like to add alternate versions of this track?</p>
                                    <p className="mb-20">Alternate versions make your track more attractive to potential customers.</p>

                                    <fieldset className="input-fieldset">
                                        <div className="grid-row-sm">
                                        <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input onChange={this.handleInputChange} type="radio" className="form-radio" name="versionsAvailable" value="false" checked={this.state.versionsAvailable === "false"}/>
                                                        No
                                                    </label>
                                                </div>
                                                <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input onChange={this.handleInputChange} type="radio" className="form-radio" name="versionsAvailable" value="true" checked={this.state.versionsAvailable === "true"}/>
                                                        Yes
                                                    </label>
                                                </div>

                                        </div>
                                        {this.state.versionsAvailable === "true" ?
                                        <div className="input-fieldset-cond is-visible">
                                            <hr className="divider-sm"/>
                                            <p className="input-label mb-10">
                                                Upload Versions
                                                <span class="input-label-required">*</span>
                                            </p>
                                            <p className="mb-20">48 kHz &amp; 16-bit stereo uncompressed WAV files only accepted. <TechnicalRequirementsLearnMore red/>.</p>

                                            {this.state.additionalVersions.map((version, index)=>(
                                                            <div className="input-group-sm">
                                                            {version.wavURL  || version.loading || version.error?
                                                            <>
                                                            {!version.error ?
                                                                <div className="upload-item">
                                                                    <div className="upload-item-pre">
                                                                        {/* {# Replace div with react loader, swap with play button once uploaded #} */}
                                                                        {version.loading ?
                                                                            <CircularIndeterminant thickness={2.5}  size={40}/>
                                                                            :
                                                                            <button type="button"  onClick={(event)=>{this.changeNowPlaying(event, version.wavURL)}} className="circle-icon-border circle-icon-sm">{this.state.nowPlaying === version.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                                                        }

                                                                    </div>
                                                                    <div className="upload-item-details">
                                                                        <span className="upload-item-title">{version.label}</span>
                                                                        <div className="upload-item-file">{version.localname}</div>
                                                                    </div>

                                                                    <div className="upload-item-wave">
                                                                        {version.wavURL &&
                                                                            <Waveform unsetNowPlaying={this.unsetNowPlaying} nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={version.wavURL} peaks={version.peaks}/>
                                                                        }
                                                                        {/* {% include "components/audio/waveform unsetNowPlaying={this.unsetNowPlaying}-sm.html" %} */}
                                                                        <span className="upload-item-duration">{version.duration}</span>
                                                                    </div>
                                                                    <div className="upload-item-controls">
                                                                    <button type="button" onClick={()=>this.downloadVersion(version.id)} class="circle-icon circle-icon-sm"><span class="icon-download"></span></button>
                                                                        {this.state.trackStatus === "pending" ?
                                                                            <button type="button"  disabled onClick={(event)=>{event.preventDefault();}}  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>:
                                                                            <button type="button"  onClick={(event)=>{event.preventDefault();this.removeVersion(version.id)}}  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                                                        }

                                                                    </div>
                                                                </div>:
                                                                <>
                                                                    <div className="upload-item has-error">
                                                                        <div className="upload-item-pre">
                                                                            <button type="button"  className="circle-icon-border circle-icon-sm"><span className="icon-play"></span></button>
                                                                        </div>
                                                                        <div className="upload-item-details">
                                                                        <span className="upload-item-title">{version.label}</span>
                                                                        <div className="upload-item-file">{version.localname}</div>
                                                                        </div>
                                                                        <div className="upload-item-wave">
                                                                            {/* {% include "components/audio/waveform unsetNowPlaying={this.unsetNowPlaying}-sm.html" %} */}
                                                                            <span className="upload-item-duration">{version.duration}</span>
                                                                        </div>
                                                                        <div className="col btn-group">
                                                                            {this.state.trackStatus === "pending" ?
                                                                                <input disabled id={'versionFile' + index} type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) =>e.preventDefault()} />:
                                                                                <input id={'versionFile' + index} type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.versionsFileHandler(version.label, e)} />
                                                                            }
                                                                            <label htmlFor={'versionFile' + index} className="btn btn-stroke btn-sm">Reupload</label>
                                                                            <button type="button" onClick={(event)=>{event.preventDefault();this.removeVersionFromState(index)}} className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>

                                                                        </div>
                                                                    </div>
                                                                    {/* {# Error Message #} */}
                                                                    <div className="error-message is-file">{version.error}</div>
                                                                </>
                                                            }
                                                                {/* <div>{version.label}</div>
                                                                <br></br> */}
                                                            </>:
                                                                <div className="grid-row-xs">
                                                                    <div className="col w-auto flex-1" key={index}>

                                                                            <Select
                                                                                name={"versionName" + index}
                                                                                placeholder="Version Name..."
                                                                                styles={customStyles}
                                                                                value={{label: this.state.additionalVersions[index].label, value: this.state.additionalVersions[index].value}}
                                                                                options={versionNameOptions.filter(version => {
                                                                                    for (let i = 0; i < this.state.additionalVersions.length; i++) {
                                                                                        if (this.state.additionalVersions[i].value === version.value || this.state.additionalVersions[i]?.label?.toLowerCase() === version?.value?.toLowerCase()) {
                                                                                            return false
                                                                                        }
                                                                                    }
                                                                                    return true
                                                                                })
                                                                                }
                                                                                onChange={(e) => this.handleVersionNameChange(index, e)}
                                                                            />

                                                                    </div>
                                                                    <div className="col btn-group">
                                                                        <input id={'versionFile' + index} type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.versionsFileHandler(version.label, e)} />
                                                                        <label htmlFor={'versionFile' + index} className="btn btn-stroke form-btn">Upload Version</label>
                                                                        <button type="button"  onClick={(event)=>{event.preventDefault();this.removeVersionFromState(index)}} className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                                                    </div>
                                                                </div>
                                                            }
                                                            </div>
                                                        ))}
                                            <hr className="divider"></hr>

                                            <div className="input-group ">
                                                <button type="button"  onClick={this.addNewVersionDiv} className="btn btn-stroke form-btn w-full"><span className="icon-plus mr-5"></span>Add a Version</button>
                                            </div>

                                        </div>: <></>}
                                    </fieldset>

                                </div>
                            </div>
                            {/* {# Stems #} */}
                            <div className="form-section" id="stems">
                                <h2 className="text-label-lg mb-40">Stems</h2>
                                <div className="input-group">
                                    <p className="input-label mb-10">Would you like to add individual stems for this track?</p>
                                    <p className="mb-20">Stems make your track more attractive to higher-end productions.</p>

                                    <div className="input-fieldset">
                                        <div className="grid-row-sm">
                                        <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input onChange={this.handleInputChange} type="radio" className="form-radio" name="stemsAvailable" value="false" checked={this.state.stemsAvailable === "false"}/>
                                                        No
                                                    </label>
                                                </div>
                                                <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input onChange={this.handleInputChange} type="radio" className="form-radio" name="stemsAvailable" value="true" checked={this.state.stemsAvailable === "true"}/>
                                                        Yes
                                                    </label>
                                                </div>
                                        </div>
                                        {/* {# Conditional Fields #} */}
                                        {this.state.stemsAvailable === "true" ?
                                        <div className="input-fieldset-cond is-visible">
                                            <hr className="divider-sm"/>
                                            <p className="input-label mb-10">
                                                Upload Stems
                                                <span class="input-label-required">*</span>
                                            </p>
                                            <p className="mb-10">The instruments below are populated based on the instruments featured in the track that you provided earlier.  You can add multiple stems based on the same instrument (Example: Select "Strings" twice when uploading stems for short strings and another stem for long strings.)</p>
                                            <p className="mb-10">48 kHz &amp; 16-bit stereo uncompressed WAV files only accepted. <TechnicalRequirementsLearnMore red/>.</p>
                                            <p className="text-gray-400 text-sm mb-20">Please make sure all stems are the exact same length to the millisecond or your stem will automatically be rejected.</p>

                                {this.state.completeTaggedStems.map((stem, index) =>(

                                    <div key={"completeTagStem " +index} className="input-group-sm">

                                        <div className="upload-item">
                                            <div className="upload-item-pre">
                                                {/* {# Replace div with react loader, swap with play button once uploaded #} */}
                                                {stem.loading ?
                                                   <CircularIndeterminant thickness={2.5}  size={40}/>:
                                                    <button type="button"  onClick={(event)=>{this.changeNowPlaying(event, stem.wavURL)}} className="circle-icon-border circle-icon-sm">{this.state.nowPlaying === stem.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                                }

                                            </div>
                                            <div className="upload-item-details">
                                                <span className="upload-item-title">{stem.name}</span>
                                                <div className="upload-item-file">{stem.localname}</div>
                                            </div>

                                            <div className="upload-item-wave">
                                                {stem.wavURL ?
                                                <Waveform unsetNowPlaying={this.unsetNowPlaying} nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={stem.wavURL} peaks={stem.peaks}/>:
                                                <></>
                                                }
                                                {/* {% include "components/audio/waveform unsetNowPlaying={this.unsetNowPlaying}-sm.html" %} */}
                                                <span className="upload-item-duration">{stem.duration}</span>
                                            </div>
                                            <div className="upload-item-controls">
                                            <button type="button" onClick={()=>this.downloadStem(stem.id)} class="circle-icon circle-icon-sm"><span class="icon-download"></span></button>
                                                {this.state.trackStatus === "pending" ?
                                                    <button type="button"  disabled  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>:
                                                    <button type="button"  onClick={(event)=>{event.preventDefault();this.removeStem(stem.id)}}  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    ))}
                                {this.state.completeCustomStems.map((stem, index) =>(
                                    <div  key={"completeCustomStem " +index} className="input-group-sm">
                                        <div className="upload-item">
                                            <div className="upload-item-pre">
                                                <button type="button"onClick={(event)=>{this.changeNowPlaying(event, stem.wavURL)}} className="circle-icon-border circle-icon-sm">{this.state.nowPlaying === stem.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                            </div>
                                            <div className="upload-item-details">
                                                <span className="upload-item-title">{stem.name}</span>
                                                <div className="upload-item-file">{stem.localname}</div>
                                            </div>
                                            <div className="upload-item-wave">
                                                {stem.wavURL ?
                                                <Waveform unsetNowPlaying={this.unsetNowPlaying} nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={stem.wavURL} peaks={stem.peaks}/>:
                                                <></>
                                                }
                                                {/* {% include "components/audio/waveform unsetNowPlaying={this.unsetNowPlaying}-sm.html" %} */}
                                                <span className="upload-item-duration">{stem.duration}</span>
                                            </div>
                                            <div className="upload-item-controls">
                                            <button type="button" onClick={()=>this.downloadStem(stem.id)} class="circle-icon circle-icon-sm"><span class="icon-download"></span></button>
                                                {this.state.trackStatus === "pending" ?
                                                    <button type="button"disabled  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>:
                                                    <button type="button"onClick={(event)=>{event.preventDefault();this.removeStem(stem.id)}}  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                    ))}
                                                                                {this.state.incompleteTaggedStems.map((stem, index) =>(

                                                <div key={"incompleteTagStem " +index} className="input-group-sm">
                                                {stem.wavURL  || stem.loading || stem.error?
                                                <>
                                                {!stem.error ?
                                                    <div className="upload-item">
                                                        <div className="upload-item-pre">
                                                            {/* {# Replace div with react loader, swap with play button once uploaded #} */}
                                                            {stem.loading ?
                                                               <CircularIndeterminant thickness={2.5}  size={40}/>:
                                                                <button type="button"onClick={(event)=>{this.changeNowPlaying(event, stem.wavURL)}} className="circle-icon-border circle-icon-sm">{this.state.nowPlaying === stem.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                                            }

                                                        </div>
                                                        <div className="upload-item-details">
                                                            <span className="upload-item-title">{stem.name}</span>
                                                            <div className="upload-item-file">{stem.localname}</div>
                                                        </div>

                                                        <div className="upload-item-wave">
                                                            {stem.wavURL ?
                                                            <Waveform unsetNowPlaying={this.unsetNowPlaying} nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={stem.wavURL} peaks={stem.peaks}/>:
                                                            <></>
                                                            }
                                                            {/* {% include "components/audio/waveform unsetNowPlaying={this.unsetNowPlaying}-sm.html" %} */}
                                                            <span className="upload-item-duration">{stem.duration}</span>
                                                        </div>
                                                        <div className="upload-item-controls">
                                                            {this.state.trackStatus === "pending" ?
                                                                <button type="button" disabled  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>:
                                                                <button type="button" onClick={(event)=>{event.preventDefault();this.removeStem(stem.id)}}  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                                            }
                                                        </div>
                                                    </div>:
                                                    <>
                                                        <div className="upload-item has-error">
                                                            <div className="upload-item-pre">
                                                                <button type="button" className="circle-icon-border circle-icon-sm"><span className="icon-play"></span></button>
                                                            </div>
                                                            <div className="upload-item-details">
                                                            <span className="upload-item-title">{stem.label}</span>
                                                            <div className="upload-item-file">{stem.localname}</div>
                                                            </div>
                                                            <div className="upload-item-wave">
                                                                {/* {% include "components/audio/waveform unsetNowPlaying={this.unsetNowPlaying}-sm.html" %} */}
                                                                <span className="upload-item-duration">{stem.duration}</span>
                                                            </div>
                                                            <div className="upload-item-controls">
                                                            <div className="upload-item-controls">
                                                                <input id={`taggedStem${stem.name}`} type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.stemsFileHandler(index, e, "tag")} />
                                                                <label htmlFor={`taggedStem${stem.name}`} className="btn btn-stroke btn-sm">Reupload</label>
                                                            </div>
                                                            </div>
                                                        </div>
                                                        {/* {# Error Message #} */}
                                                        <div className="error-message is-file">{stem.error}</div>
                                                    </>
                                                }

                                                </>:
                                                     <div className="grid-row-xs">
                                                     <div className="col w-auto flex-1" key={index}>
                                                            <input
                                                                className="form-input"
                                                                name={"newStemName"}
                                                                placeholder="Stem Name..."
                                                                isDisabled
                                                                value={stem.label}
                                                                // onChange={(e) => this.handleStemNameChange(e, index)}
                                                            />
                                                     </div>
                                                     <div className="col w-auto flex-initial">
                                                         <input id={'taggedStem' + index} type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.stemsFileHandler(index, e, "tag")} />
                                                         <label htmlFor={'taggedStem' + index} className="btn btn-stroke form-btn">Upload Stem</label>
                                                     </div>
                                                 </div>
                                                }
                                                </div>
                                ))}
                                {this.state.incompleteCustomStems.map((stem, index) =>(
                                    <div key={"incompleteCustomStem " +index} className="input-group-sm">
                                    {stem.wavURL  || stem.loading || stem.error?
                                    <>
                                    {!stem.error ?
                                        <div className="upload-item">
                                            <div className="upload-item-pre">
                                                {/* {# Replace div with react loader, swap with play button once uploaded #} */}
                                                {stem.loading ?
                                                    <CircularIndeterminant thickness={2.5}  size={40}/>:
                                                    <button type="button" onClick={(event)=>{this.changeNowPlaying(event, stem.wavURL)}} className="circle-icon-border circle-icon-sm">{this.state.nowPlaying === stem.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                                }

                                            </div>
                                            <div className="upload-item-details">
                                                <span className="upload-item-title">{stem.name}</span>
                                                <div className="upload-item-file">{stem.localname}</div>
                                            </div>

                                            <div className="upload-item-wave">
                                                {stem.wavURL ?
                                                <Waveform unsetNowPlaying={this.unsetNowPlaying} nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={stem.wavURL} peaks={stem.peaks}/>:
                                                <></>
                                                }
                                                {/* {% include "components/audio/waveform unsetNowPlaying={this.unsetNowPlaying}-sm.html" %} */}
                                                <span className="upload-item-duration">{stem.duration}</span>
                                            </div>
                                            <div className="upload-item-controls">
                                                {this.state.trackStatus === "pending" ?
                                                    <button type="button" disabled  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>:
                                                    <button type="button" onClick={(event)=>{event.preventDefault();this.removeStem(stem.id)}}  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                                }
                                            </div>
                                        </div>:
                                        <>
                                            <div className="upload-item has-error">
                                                <div className="upload-item-pre">
                                                    <button type="button" className="circle-icon-border circle-icon-sm"><span className="icon-play"></span></button>
                                                </div>
                                                <div className="upload-item-details">
                                                <span className="upload-item-title">{stem.label}</span>
                                                <div className="upload-item-file">{stem.localname}</div>
                                                </div>
                                                <div className="upload-item-wave">
                                                    {/* {% include "components/audio/waveform unsetNowPlaying={this.unsetNowPlaying}-sm.html" %} */}
                                                    <span className="upload-item-duration">{stem.duration}</span>
                                                </div>
                                                <div className="upload-item-controls">
                                                <div className="upload-item-controls">
                                                    <input id={`customStem${stem.name}`} type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.stemsFileHandler(index, e, "custom")} />
                                                    <label htmlFor={`customStem${stem.name}`} className="btn btn-stroke btn-sm">Reupload</label>
                                                </div>
                                                </div>
                                            </div>
                                            {/* {# Error Message #} */}
                                            <div className="error-message is-file">{stem.error}</div>
                                        </>
                                    }

                                    </>:
                                         <div className="grid-row-xs">
                                         <div className="col w-auto flex-1" key={index}>
                                             {this.state.trackStatus !== "pending"?
                                                <input
                                                    className="form-input"
                                                    name={"newStemName"}
                                                    placeholder="Stem Name..."
                                                    value={stem.value}
                                                    onChange={(e) => this.handleStemNameChange(e, index)}
                                                />:
                                            <Select
                                                isDisabled
                                                name={"stemName" + index}
                                                placeholder="Stem Name..."
                                                styles={customStyles}
                                                value={{label: stem.label, value: stem.value}}
                                                options={this.state.instruments}
                                                onChange={(e) => this.handleVersionNameChange(index, e)}
                                            />
                                             }
                                         </div>
                                         <div className="col btn-group">
                                             <input id={'customStem' + index} type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.stemsFileHandler(index, e, "custom")} />
                                             <label htmlFor={'customStem' + index} className="btn btn-stroke form-btn">Upload Stem</label>
                                             <button type="button"  onClick={(event)=>{event.preventDefault();this.removeStemFromState(index)}}className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                         </div>
                                     </div>
                                    }
                                    </div>
                    ))}
                                <div className="grid-row-xs">
                                                        <div className="col w-auto flex-1" >
                                                            <hr className="divider"></hr>
                                                            <div className="input-group ">
                                                                {this.state.trackStatus === "pending" ?
                                                                    <button type="button" disabled className="btn btn-disabled btn-stroke form-btn w-full"><span className="icon-plus mr-5"></span>Add a Stem</button>:
                                                                    <button type="button" onClick={this.addNewStemDiv} className="btn btn-stroke form-btn w-full"><span className="icon-plus mr-5"></span>Add a Stem</button>
                                                                }
                                                            </div>

                                                        </div>
                                                    </div>
                            </div> : <></>}
                                    </div>

                                </div>
                            </div>
                          </form>
                            {/* {# End Form #} */}

                        </div>
                        {/* {# End App Main Content #} */}

                        {/* {# Right Info Sidebar #} */}
                        <div className="app-sublayout-info">
                            {/* {# Form Progress #} */}
                            <MediaQuery minWidth={1024}>
                                <Sticky scrollElement=".app-main" stickyClassName="mt-15">
                                    <div className={`form-progress`}>
                                        <div className="form-progress-header">
                                            <h4 className="text-label-lg">Progress</h4>
                                        </div>
                                        <div className="form-progress-details">
                                            <p className="text-sm">You must approve all required sections below before you can publish the track.</p>
                                            {/* {# Progress Details #} */}
                                            <ul className="form-progress-sections">
                                                <li className={`${this.state.contentCheck === "approve" && "is-complete"} ${this.state.contentCheck === "reject" && "has-error"}`}>
                                                    <a href="#content">Content <span className="form-progress-req">Req.</span></a>
                                                </li>
                                                <li className={`${this.state.curationCheck === "approve" && "is-complete"} ${this.state.curationCheck === "reject" && "has-error"}`}>
                                                    <a href="#curation">Curation <span className="form-progress-req">Req.</span></a>
                                                </li>
                                                <li className={`${this.state.exclusivityCheck === "approve" && "is-complete"} ${this.state.exclusivityCheck === "reject" && "has-error"}`}>
                                                    <a href="#exclusivity">Exclusivity <span className="form-progress-req">Req.</span></a>
                                                </li>
                                                <li className={`${this.state.trackCheck === "approve" && "is-complete"} ${this.state.trackCheck === "reject" && "has-error"}`}>
                                                    <a href="#full_track">Full Track Quality <span className="form-progress-req">Req.</span></a>
                                                </li>
                                                <li className={`${this.state.versionsDone === true && "is-complete"} ${this.state.versionsDone === false && "has-error"}`}>
                                                    <a href="#versions_quality">Versions Quality <span className="form-progress-req">Req.</span></a>
                                                </li>
                                                <li className={`${this.state.stemsDone === true && "is-complete"} ${this.state.stemsDone === false && "has-error"}`}>
                                                    <a href="#stems_quality">Stems Quality <span className="form-progress-req">Req.</span></a>
                                                </li>
                                                <li className={`${this.state.detailsComplete === true && this.state.tagsComplete === true && "is-complete"}`}>
                                                    <a href="#general">General Info <span className="form-progress-req">Req.</span></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Sticky>
                            </MediaQuery>
                        </div>
                        {/* {# End Info Sidebar #} */}

                    </div>
                    {/* {# End App Main Inner #} */}

                </div>
                </>
                }
                {/* {# End Container #} */}

            </div>

            )}
            </UserConsumer>
        )
    }
}
export default AdminApproveTrack

import moment from "moment"
import React from 'react'
import api from "../components/utils/api"

const ProjectsContext = React.createContext()

class ProjectsProvider extends React.Component {
  state = {
    allProjects: [],
    myProjects: [],
    sharedProjects: [],
    completeProjects: [],
    projectsOptions: [],
    currentProject: [],
    currentScene: [],
    sceneOptions: [],
    latestProjects: [],
    projectTypes: [],
    myCollaborators: [],
    isLoaded: false
  }
  componentDidMount = () => {

    // Need to wait for this.props.user!!
    // console.log('projectsprovider this.props.user', this.props.user);

    const { user } = this.props || {};

    let projectTypes = []
    if (user?.user_id) {
      api.getAllProjectTypes()
        .then(res => {
          res.data.forEach(projType => {
            projectTypes.push({ label: projType.name, value: projType.id })
          })
          this.setState({ projectTypes: projectTypes })
        })
      api.getMyProjects()
        .then(res => {
          let completeProjects = []
          let myProjects = res.data.myProjects;
          myProjects.forEach((project, projInd) => {
            let tracks = []
            let playOrder = []
            project.scenes = project.scenes.sort((a, b) => {
              const weightA = a.weight
              const weightB = b.weight
              let comparison = 0
              if (weightA > weightB) {
                comparison = 1;
              }
              else {
                comparison = -1
              }
              return comparison
            })
            project.scenes.forEach(scene => {
              let sceneTracks = []
              scene.sceneTracks.forEach(sceneTrack => {
                let positiveVotes = sceneTrack.sceneTrackVotes.filter(vote => vote.voteValue === 1).length
                let negativeVotes = sceneTrack.sceneTrackVotes.length - positiveVotes
                tracks.push({ ...sceneTrack.tracks, positiveVotes: positiveVotes, negativeVotes: negativeVotes })
                sceneTracks.push({ ...sceneTrack.tracks, positiveVotes: positiveVotes, negativeVotes: negativeVotes })
              })
              let compare = (a, b) => {
                const scoreA = a.positiveVotes - a.negativeVotes;
                const scoreB = b.positiveVotes - b.negativeVotes;

                let comparison = 0;
                if (scoreA > scoreB) {
                  comparison = -1;
                } else if (scoreA < scoreB) {
                  comparison = 1;
                }
                return comparison * 1;
              }
              sceneTracks = sceneTracks.sort(compare)
              let sceen = {}
              playOrder = playOrder.concat(sceneTracks.map(track => track.trackId)).filter(track => {
                return sceen.hasOwnProperty(track) ? false : (sceen[track] = true)
              })
            })
            let compare = (a, b) => {
              const scoreA = a.positiveVotes - a.negativeVotes;
              const scoreB = b.positiveVotes - b.negativeVotes;

              let comparison = 0;
              if (scoreA > scoreB) {
                comparison = -1;
              } else if (scoreA < scoreB) {
                comparison = 1;
              }
              return comparison * 1;
            }
            tracks = tracks.sort(compare)
            myProjects[projInd].allTracks = tracks
            myProjects[projInd].playOrder = playOrder
          })
          myProjects = myProjects.filter(project => {
            if (project.status !== "complete") {
              return true
            }
            else {
              completeProjects.push(project)
              return false
            }
          })
          let sharedProjects = res.data.sharedProjects;
          sharedProjects.forEach((project, projInd) => {
            let tracks = []
            let playOrder = []
            project.scenes = project.scenes.sort((a, b) => {
              const weightA = a.weight
              const weightB = b.weight
              let comparison = 0
              if (weightA > weightB) {
                comparison = 1;
              }
              else {
                comparison = -1
              }
              return comparison
            })

            project.scenes.forEach(scene => {
              let sceneTracks = []
              scene.sceneTracks.forEach(sceneTrack => {
                let positiveVotes = sceneTrack.sceneTrackVotes.filter(vote => vote.voteValue === 1).length
                let negativeVotes = sceneTrack.sceneTrackVotes.length - positiveVotes
                tracks.push({ ...sceneTrack.tracks, positiveVotes: positiveVotes, negativeVotes: negativeVotes })
                sceneTracks.push({ ...sceneTrack.tracks, positiveVotes: positiveVotes, negativeVotes: negativeVotes })
              })
              let compare = (a, b) => {
                const scoreA = a.positiveVotes - a.negativeVotes;
                const scoreB = b.positiveVotes - b.negativeVotes;

                let comparison = 0;
                if (scoreA > scoreB) {
                  comparison = -1;
                } else if (scoreA < scoreB) {
                  comparison = 1;
                }
                return comparison * 1;
              }
              sceneTracks = sceneTracks.sort(compare)
              let sceen = {}
              playOrder = playOrder.concat(sceneTracks.map(track => track.trackId)).filter(track => {
                return sceen.hasOwnProperty(track) ? false : (sceen[track] = true)
              })

            })
            let compare = (a, b) => {
              const scoreA = a.positiveVotes - a.negativeVotes;
              const scoreB = b.positiveVotes - b.negativeVotes;

              let comparison = 0;
              if (scoreA > scoreB) {
                comparison = -1;
              } else if (scoreA < scoreB) {
                comparison = 1;
              }
              return comparison * 1;
            }
            tracks = tracks.sort(compare)
            sharedProjects[projInd].allTracks = tracks
            sharedProjects[projInd].playOrder = playOrder
            sharedProjects[projInd].client = project.client
            sharedProjects[projInd].id = project.id
            sharedProjects[projInd].name = project.name
            sharedProjects[projInd].scenes = project.scenes
          })
          let invitedProjects = []
          sharedProjects = sharedProjects.filter(project => {
            if (project.collabStatus === "accepted") {
              return true
            }
            else if (project.collabStatus === "accepted-complete") {
              completeProjects.push(project)
              return false
            }
            else if (project.collabStatus === "invited") {
              invitedProjects.push(project)
              return false
            }
            else {
              return false
            }
          })
          let allProjects = myProjects.concat(sharedProjects)
          // console.log('allProjects', allProjects);
          allProjects = allProjects.sort(function (project1, project2) {
            let project1LastAction = project1.projectActions
            if (project1LastAction.length > 0) {
              project1LastAction = project1LastAction[0].updatedOn
            }
            else {
              project1LastAction = "0000-00-00T00:00:00.000Z"
            }
            let project2LastAction = project2.projectActions
            if (project2LastAction.length > 0) {
              project2LastAction = project2LastAction[0].updatedOn
            }
            else {
              project2LastAction = "0000-00-00T00:00:00.000Z"
            }
            if (project1LastAction > project2LastAction) return -1
            if (project1LastAction < project2LastAction) return 1
            return 0
          })
          // console.log('sortedAllProjects', allProjects);
          let projectsOptions = []
          allProjects.forEach((project, projInd) => {
            projectsOptions.push({ value: project.id, label: project.name })
            let activeCollabs = project.collaborators.filter(collaborator => collaborator.status === "accepted" || collaborator.status === "accepted-complete")
            if (project.isOwner && user) {
              const {
                firstName,
                lastName,
                email,
                profilePhoto
              } = user || {};

              let myCollab = {
                firstName,
                lastName,
                email,
                profilePhoto
              }

              // console.log('myCollab', myCollab);
              // console.log('this.props', this.props);

              activeCollabs.push(myCollab)
              let tempCollab

              let collaboratorNum = activeCollabs.length

              switch (collaboratorNum) {
                case 0:
                case 1:
                  break;
                case 2:
                  tempCollab = activeCollabs[1]
                  activeCollabs[1] = activeCollabs[collaboratorNum - 1]
                  activeCollabs[collaboratorNum - 1] = tempCollab
                  break;
                case 3:
                  tempCollab = activeCollabs[2]
                  activeCollabs[2] = activeCollabs[collaboratorNum - 1]
                  activeCollabs[collaboratorNum - 1] = tempCollab
                  break;
                case 4:
                  tempCollab = activeCollabs[3]
                  activeCollabs[3] = activeCollabs[collaboratorNum - 1]
                  activeCollabs[collaboratorNum - 1] = tempCollab
                  break;
                default:
                  tempCollab = activeCollabs[2]
                  activeCollabs[2] = activeCollabs[collaboratorNum - 1]
                  activeCollabs[collaboratorNum - 1] = tempCollab
                  break;
              }
              allProjects[projInd].activeCollabs = activeCollabs
            }
            else {
              activeCollabs.splice(0, 0, { firstName: project.ownerFn, lastName: project.ownerLn, email: project.ownerEmail, profilePhoto: project.ownerPhoto })
              let collaboratorNum = activeCollabs.length


              let myCollabPosition = 0;
              if (user) {
                myCollabPosition = activeCollabs.findIndex(collab => collab.email.toLowerCase() === user.email.toLowerCase())
              }

              let tempCollab

              switch (collaboratorNum) {
                case 0:
                case 1:
                  break;
                case 2:
                  tempCollab = activeCollabs[1]
                  activeCollabs[1] = activeCollabs[myCollabPosition]
                  activeCollabs[myCollabPosition] = tempCollab
                  break;
                case 3:
                  tempCollab = activeCollabs[2]
                  activeCollabs[2] = activeCollabs[myCollabPosition]
                  activeCollabs[myCollabPosition] = tempCollab
                  break;
                case 4:
                  tempCollab = activeCollabs[3]
                  activeCollabs[3] = activeCollabs[myCollabPosition]
                  activeCollabs[myCollabPosition] = tempCollab
                  break;
                default:
                  tempCollab = activeCollabs[2]
                  activeCollabs[2] = activeCollabs[myCollabPosition]
                  activeCollabs[myCollabPosition] = tempCollab
                  break;
              }
              allProjects[projInd].activeCollabs = activeCollabs
            }
          })


          let decodedCookie = decodeURIComponent(document.cookie);
          let ca = decodedCookie.length ? decodedCookie.split(';') : [];
          let latestProject = ca.filter(cookieItem => cookieItem.indexOf('latestProject') !== -1)
          let latestScene = ca.filter(cookieItem => cookieItem.indexOf('latestScene') !== -1)
          let setLatestProj;
          let parsedLatestProj;
          let setLatestScene
          let parsedLatestScene
          let sceneOptions

          if (latestProject?.length) {

            setLatestProj = latestProject[0].substring(latestProject[0].indexOf('=') + 1)

            setLatestProj = allProjects.filter(project => project.id === parseInt(setLatestProj))[0]

            if (setLatestProj) {
              parsedLatestProj = {
                label: setLatestProj.name,
                value: setLatestProj.id
              }

              sceneOptions = setLatestProj.scenes.map(scene => {
                let sceneOption = {
                  label: scene.name,
                  value: scene.id
                }
                return sceneOption
              })

              if (latestScene.length) {

                setLatestScene = latestScene[0].substring(latestScene[0].indexOf('=') + 1)
                setLatestScene = setLatestProj.scenes.filter(scene => scene.id === parseInt(setLatestScene))[0] || null
                if (setLatestScene) {
                  parsedLatestScene = {
                    label: setLatestScene.name,
                    value: setLatestScene.id
                  }
                }


              }

            }

          }

          // console.log('finalAllProjects', allProjects);
          this.setState({
            allProjects: allProjects,
            myProjects: myProjects,
            sharedProjects: sharedProjects,
            completeProjects: completeProjects,
            invitedProjects: invitedProjects,
            projectsOptions: projectsOptions,
            currentProject: parsedLatestProj || [],
            currentScene: parsedLatestScene || [],
            sceneOptions: sceneOptions || [],
            latestProjects: allProjects.slice(0, 3),
            myCollaborators: res.data.collaborators || [],
            isLoaded: true
          })
        })
        .catch(err => {
          if (err.response?.status === 401) {
            this.setState({ isLoaded: true })
          }
        })
    }
    else {
      this.setState({
        isLoaded: true
      })
    }
  }
  addSceneToProject = (projectId, newScene)=>{
    let index = this.state.allProjects.findIndex(project=>project.id === projectId)
    this.setState(({allProjects})=>({
      allProjects:[
        ...allProjects.slice(0, index),
        {
          ...allProjects[index],
          scenes: [...allProjects[index].scenes,
                  {
                    id: newScene.id,
                    name: newScene.name,
                    projectId: newScene.projectId,
                    weight: newScene.weight
                  },
          ]
        },
        ...allProjects.slice(index+1)
      ]
    }))
  }
  editSceneName = (projectId, sceneId, sceneName)=>{
    let index = this.state.allProjects.findIndex(project=>project.id === projectId)
    if(index !==-1){
      let sceneIndex = this.state.allProjects[index].scenes.findIndex(scene=>scene.id === sceneId)
      this.setState(({allProjects})=>({
        allProjects:[
          ...allProjects.slice(0, index),
          {
            ...allProjects[index],
            scenes: [
                      ...allProjects[index].scenes.slice(0, sceneIndex),
                    {
                      ...allProjects[index].scenes[sceneIndex],
                      name: sceneName,
                    },
                    ...allProjects[index].scenes.slice(sceneIndex + 1),
            ]
          },
          ...allProjects.slice(index+1)
        ]
      }),()=>{
        let allProjects = this.state.allProjects
        let latestProject = allProjects[index]?.id

        let latestScene = allProjects[index]?.scenes[sceneIndex]?.id
        if(latestProject){
          document.cookie = `latestProject=${latestProject}; path=/`
        }
        if(latestScene){
          document.cookie = `latestScene=${latestScene}; path=/`
        }
        let setLatestProj;
        let parsedLatestProj;
        let setLatestScene
        let parsedLatestScene
        let sceneOptions
        if (latestProject) {
          setLatestProj = latestProject
          setLatestProj = allProjects.filter(project => project.id === parseInt(setLatestProj))[0]

          if (setLatestProj) {
            parsedLatestProj = {
              label: setLatestProj.name,
              value: setLatestProj.id
            }
            sceneOptions = setLatestProj.scenes.map(scene => {
              let sceneOption = {
                label: scene.name,
                value: scene.id
              }
              return sceneOption
            })

            if (latestScene) {
              setLatestScene = latestScene
              setLatestScene = setLatestProj.scenes.filter(scene => scene.id === parseInt(setLatestScene))[0]
              parsedLatestScene = {
                label: setLatestScene.name,
                value: setLatestScene.id
              }
            }
          }

        }
        this.setState({
          sceneOptions: sceneOptions,
          currentProject: parsedLatestProj || [],
          currentScene: parsedLatestScene || [],
        })

      })

      }

  }
  updateProjectInfo = (projectId, name, client, typeName, typeId )=>{
    let allProjects = [...this.state.allProjects]
    allProjects.forEach((project, index)=>{
      if(project.id === parseInt(projectId)){
        allProjects[index].name = name;
        allProjects[index].client = client;
        allProjects[index].projectType = typeId;
      }
    })
    this.setState({allProjects: allProjects},()=> this.updateLatest(parseInt(projectId)))
  }
  updateLatestWithScene = (projectId, newScene, sceneTrack, projectInfo)=>{

    let allProjects = [...this.state.allProjects]
    allProjects.forEach((project, index)=>{

      if(project.id === projectId){
        let projectActions = project.projectActions || []
        projectActions.unshift({updatedOn: moment().add(7, 'days').format("YYYY-MM-DD[T]hh:mm:ss[.]SSS[Z]")})

        allProjects[index].projectActions = projectActions
        allProjects[index].coverArt = projectInfo.coverArt;
        allProjects[index].userSet = projectInfo.userSet
        allProjects[index].scenes.push({...newScene, sceneTracks: sceneTrack?.trackId? [{...sceneTrack, myVote: [], sceneTrackVotes: [], score: 0}]: []})
        if(allProjects[index].allTracks && allProjects[index].playOrder){
          if(sceneTrack?.trackId){
            allProjects[index].allTracks = allProjects[index].allTracks.concat({...sceneTrack})
            allProjects[index].playOrder = allProjects[index].playOrder.concat({...sceneTrack})
          }
        }
        else{
          let tracks = []
          let playOrder = []
          allProjects[index].scenes.forEach(scene => {
            let sceneTracks = []
            scene.sceneTracks.forEach(sceneTrack => {
              let positiveVotes = sceneTrack.sceneTrackVotes.filter(vote => vote.voteValue === 1).length
              let negativeVotes = sceneTrack.sceneTrackVotes.length - positiveVotes
              tracks.push({ ...sceneTrack.tracks, positiveVotes: positiveVotes, negativeVotes: negativeVotes })
              sceneTracks.push({ ...sceneTrack.tracks, positiveVotes: positiveVotes, negativeVotes: negativeVotes })
            })

            let compare = (a, b) => {
              const scoreA = a.positiveVotes - a.negativeVotes;
              const scoreB = b.positiveVotes - b.negativeVotes;

              let comparison = 0;
              if (scoreA > scoreB) {
                comparison = -1;
              } else if (scoreA < scoreB) {
                comparison = 1;
              }
              return comparison * 1;
            }
            sceneTracks = sceneTracks.sort(compare)
            let sceen = {}
            playOrder = playOrder.concat(sceneTracks.map(track=>track.trackId)).filter(track=>{
              return sceen.hasOwnProperty(track) ? false: (sceen[track] = true)
            })
          })
          let compare = (a, b) => {
            const scoreA = a.positiveVotes - a.negativeVotes;
            const scoreB = b.positiveVotes - b.negativeVotes;

            let comparison = 0;
            if (scoreA > scoreB) {
              comparison = -1;
            } else if (scoreA < scoreB) {
              comparison = 1;
            }
            return comparison * 1;
          }
          tracks = tracks.sort(compare)
          allProjects[index].allTracks = tracks
          allProjects[index].playOrder = playOrder
        }

      }
    })

    allProjects = allProjects.sort(function(project1, project2){
      let project1LastAction = project1.projectActions
      if(project1LastAction.length > 0){
          project1LastAction = project1LastAction[0].updatedOn
      }
      else{
          project1LastAction = "0000-00-00T00:00:00.000Z"
      }
      let project2LastAction = project2.projectActions
      if(project2LastAction.length > 0){
          project2LastAction = project2LastAction[0].updatedOn
      }
      else{
          project2LastAction = "0000-00-00T00:00:00.000Z"
      }
      if(project1LastAction > project2LastAction) return -1
      if(project1LastAction < project2LastAction) return 1
      return 0
    })

    let latestProject = allProjects[0]?.id

    let latestScene = allProjects[0]?.scenes[allProjects[0]?.scenes?.length-1]?.id
    if(latestProject){
      document.cookie = `latestProject=${latestProject}; path=/`
    }
    if(latestScene){
      document.cookie = `latestScene=${latestScene}; path=/`
    }
    let setLatestProj;
    let parsedLatestProj;
    let setLatestScene
    let parsedLatestScene
    let sceneOptions
    if (latestProject) {
      setLatestProj = latestProject
      setLatestProj = allProjects.filter(project => project.id === parseInt(setLatestProj))[0]

      if (setLatestProj) {
        parsedLatestProj = {
          label: setLatestProj.name,
          value: setLatestProj.id
        }
        sceneOptions = setLatestProj.scenes.map(scene => {
          let sceneOption = {
            label: scene.name,
            value: scene.id
          }
          return sceneOption
        })

        if (latestScene) {
          setLatestScene = latestScene
          setLatestScene = setLatestProj.scenes.filter(scene => scene.id === parseInt(setLatestScene))[0]
          parsedLatestScene = {
            label: setLatestScene.name,
            value: setLatestScene.id
          }
        }
      }

    }
    this.setState({
      allProjects: allProjects,
      latestProjects: allProjects.slice(0,3),
      sceneOptions: sceneOptions,
      currentProject: parsedLatestProj || [],
      currentScene: parsedLatestScene || [],})
  }
  removeSceneFromContext = (projectId,sceneId)=>{

    let allProjects = [...this.state.allProjects]
    allProjects.forEach((project, index)=>{

      if(project.id === projectId){
        let projectActions = project.projectActions || []
        projectActions.unshift({updatedOn: moment().add(7, 'days').format("YYYY-MM-DD[T]hh:mm:ss[.]SSS[Z]")})
        allProjects[index].projectActions = projectActions
        allProjects[index].scenes = allProjects[index].scenes.filter(projScene=> projScene.id !== sceneId)
      }
    })
    allProjects = allProjects.sort(function(project1, project2){
      let project1LastAction = project1.projectActions
      if(project1LastAction.length > 0){
          project1LastAction = project1LastAction[0].updatedOn
      }
      else{
          project1LastAction = "0000-00-00T00:00:00.000Z"
      }
      let project2LastAction = project2.projectActions
      if(project2LastAction.length > 0){
          project2LastAction = project2LastAction[0].updatedOn
      }
      else{
          project2LastAction = "0000-00-00T00:00:00.000Z"
      }
      if(project1LastAction > project2LastAction) return -1
      if(project1LastAction < project2LastAction) return 1
      return 0
    })

    let latestProject = allProjects[0]?.id

    let latestScene = allProjects[0]?.scenes[allProjects[0]?.scenes?.length-1]?.id
    if(latestProject){
      document.cookie = `latestProject=${latestProject}; path=/`
    }
    if(latestScene){
      document.cookie = `latestScene=; path=/`
    }
    let setLatestProj;
    let parsedLatestProj;

    let sceneOptions
    if (latestProject) {
      setLatestProj = latestProject
      setLatestProj = allProjects.filter(project => project.id === parseInt(setLatestProj))[0]

      if (setLatestProj) {
        parsedLatestProj = {
          label: setLatestProj.name,
          value: setLatestProj.id
        }
        sceneOptions = setLatestProj.scenes.map(scene => {
          let sceneOption = {
            label: scene.name,
            value: scene.id
          }
          return sceneOption
        })


      }

    }
    this.setState({
      allProjects: allProjects,
      latestProjects: allProjects.slice(0,3),
      sceneOptions: sceneOptions,
      currentProject: parsedLatestProj || [],
      currentScene: [],})
  }
  updateLatest = (projectId)=>{
    let allProjects = [...this.state.allProjects]
    allProjects.forEach((project, index)=>{
      if(project.id === projectId){
        let projectActions = project.projectActions || []
        projectActions.unshift({updatedOn: moment().add(7, 'days').format("YYYY-MM-DD[T]hh:mm:ss[.]SSS[Z]")})
        allProjects[index].projectActions = projectActions
      }
    })
    allProjects = allProjects.sort(function(project1, project2){
      let project1LastAction = project1.projectActions
      if(project1LastAction.length > 0){
          project1LastAction = project1LastAction[0].updatedOn
      }
      else{
          project1LastAction = "0000-00-00T00:00:00.000Z"
      }
      let project2LastAction = project2.projectActions
      if(project2LastAction.length > 0){
          project2LastAction = project2LastAction[0].updatedOn
      }
      else{
          project2LastAction = "0000-00-00T00:00:00.000Z"
      }
      if(project1LastAction > project2LastAction) return -1
      if(project1LastAction < project2LastAction) return 1
      return 0
    })

    let latestProject = allProjects[0]?.id

    let latestScene = allProjects[0]?.scenes[0]?.id
    if(latestProject){
      document.cookie = `latestProject=${latestProject}; path=/`
    }
    if(latestScene){
      document.cookie = `latestScene=${latestScene}; path=/`
    }
    let projectsOptions = []
    allProjects.forEach((project, projInd) => {
      projectsOptions.push({ value: project.id, label: project.name })
    })
    let setLatestProj;
    let parsedLatestProj;
    let setLatestScene
    let parsedLatestScene
    let sceneOptions
    if (latestProject) {
      setLatestProj = latestProject
      setLatestProj = allProjects.filter(project => project.id === parseInt(setLatestProj))[0]
      if (setLatestProj) {
        parsedLatestProj = {
          label: setLatestProj.name,
          value: setLatestProj.id
        }
        sceneOptions = setLatestProj.scenes.map(scene => {
          let sceneOption = {
            label: scene.name,
            value: scene.id
          }
          return sceneOption
        })
        if (latestScene) {
          setLatestScene = latestScene
          setLatestScene = setLatestProj.scenes.filter(scene => scene.id === parseInt(setLatestScene))[0]
          parsedLatestScene = {
            label: setLatestScene.name,
            value: setLatestScene.id
          }
        }
      }

    }
    this.setState({
      allProjects: allProjects,
      latestProjects: allProjects.slice(0,3),
      sceneOptions: sceneOptions,
      projectsOptions: projectsOptions,
      currentProject: parsedLatestProj || [],
      currentScene: parsedLatestScene || [],})
  }
  updateLatestWithTrack = (projectId, sceneId, sceneTrack, projectInfo)=>{
    let allProjects = [...this.state.allProjects]
    allProjects.forEach((project, index)=>{
      if(project.id === projectId){
        let projectActions = project.projectActions || []
        projectActions.unshift({updatedOn: moment().add(7, 'days').format("YYYY-MM-DD[T]hh:mm:ss[.]SSS[Z]")})
        allProjects[index].projectActions = projectActions

        allProjects[index].coverArt = projectInfo.coverArt;
        allProjects[index].userSet = projectInfo.userSet
        let sceneIndex = allProjects[index].scenes.findIndex(scene=>scene.id === sceneId)
        allProjects[index].scenes[sceneIndex].sceneTracks = allProjects[index].scenes[sceneIndex].sceneTracks.concat({...sceneTrack})
        if(allProjects[index].allTracks && allProjects[index].playOrder){
          if(sceneTrack?.trackId){
            allProjects[index].allTracks = allProjects[index].allTracks.concat({...sceneTrack})
            allProjects[index].playOrder = allProjects[index].playOrder.concat({...sceneTrack})
          }
        }
        else{
          let tracks = []
          let playOrder = []
          allProjects[index].scenes.forEach(scene => {
            let sceneTracks = []
            scene.sceneTracks.forEach(sceneTrack => {
              let positiveVotes = sceneTrack.sceneTrackVotes.filter(vote => vote.voteValue === 1).length
              let negativeVotes = sceneTrack.sceneTrackVotes.length - positiveVotes
              tracks.push({ ...sceneTrack.tracks, positiveVotes: positiveVotes, negativeVotes: negativeVotes })
              sceneTracks.push({ ...sceneTrack.tracks, positiveVotes: positiveVotes, negativeVotes: negativeVotes })
            })
            let compare = (a, b) => {
              const scoreA = a.positiveVotes - a.negativeVotes;
              const scoreB = b.positiveVotes - b.negativeVotes;

              let comparison = 0;
              if (scoreA > scoreB) {
                comparison = -1;
              } else if (scoreA < scoreB) {
                comparison = 1;
              }
              return comparison * 1;
            }
            sceneTracks = sceneTracks.sort(compare)
            let sceen = {}
            playOrder = playOrder.concat(sceneTracks.map(track=>track.trackId)).filter(track=>{
              return sceen.hasOwnProperty(track) ? false: (sceen[track] = true)
            })
          })
        }
      }
    })
    allProjects = allProjects.sort(function(project1, project2){
      let project1LastAction = project1.projectActions
      if(project1LastAction.length > 0){
          project1LastAction = project1LastAction[0].updatedOn
      }
      else{
          project1LastAction = "0000-00-00T00:00:00.000Z"
      }
      let project2LastAction = project2.projectActions
      if(project2LastAction.length > 0){
          project2LastAction = project2LastAction[0].updatedOn
      }
      else{
          project2LastAction = "0000-00-00T00:00:00.000Z"
      }
      if(project1LastAction > project2LastAction) return -1
      if(project1LastAction < project2LastAction) return 1
      return 0
    })

    let latestProject = allProjects[0]?.id

    let latestScene = allProjects[0]?.scenes[0]?.id
    if(latestProject){
      document.cookie = `latestProject=${latestProject}; path=/`
    }
    if(latestScene){
      document.cookie = `latestScene=${latestScene}; path=/`
    }
    let projectsOptions = []
    allProjects.forEach((project, projInd) => {
      projectsOptions.push({ value: project.id, label: project.name })
    })
    let setLatestProj;
    let parsedLatestProj;
    let setLatestScene
    let parsedLatestScene
    let sceneOptions
    if (latestProject) {
      setLatestProj = latestProject
      setLatestProj = allProjects.filter(project => project.id === parseInt(setLatestProj))[0]
      if (setLatestProj) {
        parsedLatestProj = {
          label: setLatestProj.name,
          value: setLatestProj.id
        }
        sceneOptions = setLatestProj.scenes.map(scene => {
          let sceneOption = {
            label: scene.name,
            value: scene.id
          }
          return sceneOption
        })
        if (latestScene) {
          setLatestScene = latestScene
          setLatestScene = setLatestProj.scenes.filter(scene => scene.id === parseInt(setLatestScene))[0]
          parsedLatestScene = {
            label: setLatestScene.name,
            value: setLatestScene.id
          }
        }
      }

    }
    this.setState({
      allProjects: allProjects,
      latestProjects: allProjects.slice(0,3),
      sceneOptions: sceneOptions,
      projectsOptions: projectsOptions,
      currentProject: parsedLatestProj || [],
      currentScene: parsedLatestScene || [],})
  }
  updateProjectArt = (projectId, coverArt, userSet)=>{
    let allProjects = [...this.state.allProjects]
    allProjects.forEach((project,index)=>{
      if(project.id === projectId){
        allProjects[index].coverArt = coverArt
        allProjects[index].userSet = userSet
      }
    })
    this.setState({allProjects: allProjects})
  }
  removeFromList = (projectId)=>{
    let allProjects = [...this.state.allProjects]
    allProjects = allProjects.filter(project=>project.id !== projectId)
    allProjects = allProjects.sort(function(project1, project2){
      let project1LastAction = project1.projectActions
      if(project1LastAction.length > 0){
          project1LastAction = project1LastAction[0].updatedOn
      }
      else{
          project1LastAction = "0000-00-00T00:00:00.000Z"
      }
      let project2LastAction = project2.projectActions
      if(project2LastAction.length > 0){
          project2LastAction = project2LastAction[0].updatedOn
      }
      else{
          project2LastAction = "0000-00-00T00:00:00.000Z"
      }
      if(project1LastAction > project2LastAction) return -1
      if(project1LastAction < project2LastAction) return 1
      return 0
    })
    let projectsOptions = []
    allProjects.forEach((project, projInd) => {
      projectsOptions.push({ value: project.id, label: project.name })
    })
    let latestProject = allProjects[0]?.id

    let latestScene = allProjects[0]?.scenes[0]?.id
    if(latestProject){
      document.cookie = `latestProject=${latestProject}; path=/`
    }
    if(latestScene){
      document.cookie = `latestScene=${latestScene}; path=/`
    }
    let setLatestProj;
    let parsedLatestProj;
    let setLatestScene
    let parsedLatestScene
    let sceneOptions
    if (latestProject) {
      setLatestProj = latestProject
      setLatestProj = allProjects.filter(project => project.id === parseInt(setLatestProj))[0]
      if (setLatestProj) {
        parsedLatestProj = {
          label: setLatestProj.name,
          value: setLatestProj.id
        }
        sceneOptions = setLatestProj.scenes.map(scene => {
          let sceneOption = {
            label: scene.name,
            value: scene.id
          }
          return sceneOption
        })
        if (latestScene) {
          setLatestScene = latestScene
          setLatestScene = setLatestProj.scenes.filter(scene => scene.id === parseInt(setLatestScene))[0]
          parsedLatestScene = {
            label: setLatestScene.name,
            value: setLatestScene.id
          }
        }
      }

    }
    this.setState({
      allProjects: allProjects,
      projectsOptions: projectsOptions,
      currentProject: parsedLatestProj || [],
      currentScene: parsedLatestScene || [],
      sceneOptions: sceneOptions || [],
      latestProjects: allProjects.slice(0,3),
    })
  }
  addToList = (projectId)=>{
    let allProjects =[...this.state.allProjects]
    let myProjects = [...this.state.myProjects]
    api.getProjectById(projectId)
      .then(res=>{
        let project = res.data

        const {
          firstName,
          lastName,
          email,
          profilePhoto
        } = this.props.user;

        let myCollab = {
          firstName,
          lastName,
          email,
          profilePhoto
        }
        let activeCollabs = []
        activeCollabs.push(myCollab)
        project.activeCollabs =activeCollabs
        allProjects.push(project)
        let tracks = []
        let playOrder = []
        project.scenes = project.scenes.sort((a,b)=>{
          const weightA = a.weight
          const weightB = b.weight
          let comparison = 0
          if(weightA > weightB){
            comparison = 1;
          }
          else{
            comparison = -1
          }
          return comparison
        })
        project.scenes.forEach(scene => {
          let sceneTracks = []
          scene.sceneTracks.forEach(sceneTrack => {
            let positiveVotes = sceneTrack.sceneTrackVotes.filter(vote => vote.voteValue === 1).length
            let negativeVotes = sceneTrack.sceneTrackVotes.length - positiveVotes
            tracks.push({ ...sceneTrack.tracks, positiveVotes: positiveVotes, negativeVotes: negativeVotes })
            sceneTracks.push({ ...sceneTrack.tracks, positiveVotes: positiveVotes, negativeVotes: negativeVotes })
          })
          let compare = (a, b) => {
            const scoreA = a.positiveVotes - a.negativeVotes;
            const scoreB = b.positiveVotes - b.negativeVotes;

            let comparison = 0;
            if (scoreA > scoreB) {
              comparison = -1;
            } else if (scoreA < scoreB) {
              comparison = 1;
            }
            return comparison * 1;
          }
          sceneTracks = sceneTracks.sort(compare)
          let sceen = {}
          playOrder = playOrder.concat(sceneTracks.map(track=>track.trackId)).filter(track=>{
            return sceen.hasOwnProperty(track) ? false: (sceen[track] = true)
          })
        })
        let compare = (a, b) => {
          const scoreA = a.positiveVotes - a.negativeVotes;
          const scoreB = b.positiveVotes - b.negativeVotes;

          let comparison = 0;
          if (scoreA > scoreB) {
            comparison = -1;
          } else if (scoreA < scoreB) {
            comparison = 1;
          }
          return comparison * 1;
        }
        tracks = tracks.sort(compare)
        project.allTracks = tracks
        project.playOrder = playOrder
        let projectsOptions = []
        allProjects.forEach((project, projInd) => {
          projectsOptions.push({ value: project.id, label: project.name })
        })
        document.cookie = `latestProject=${projectId}; path=/`
        document.cookie = `latestScene=${project.scenes[0]?.id}; path=/`


        myProjects.push(project)
        this.setState({allProjects: allProjects, myProjects: myProjects}, ()=>{
          this.updateLatest(projectId)
        })
      })

  }
  render() {
    return (
      <ProjectsContext.Provider
        value={{
          allProjects: this.state.allProjects,
          myProjects: this.state.myProjects,
          sharedProjects: this.state.sharedProjects,
          completeProjects: this.state.completeProjects,
          projectsOptions: this.state.projectsOptions,
          currentProject: this.state.currentProject,
          currentScene: this.state.currentScene,
          sceneOptions: this.state.sceneOptions,
          latestProjects: this.state.latestProjects,
          projectTypes: this.state.projectTypes,
          myCollaborators: this.state.myCollaborators,
          isLoaded: this.state.isLoaded,
          updateLatest: this.updateLatest,
          removeFromList: this.removeFromList,
          addToList: this.addToList,
          addSceneToProject: this.addSceneToProject,
          updateLatestWithScene: this.updateLatestWithScene,
          updateLatestWithTrack: this.updateLatestWithTrack,
          removeSceneFromContext: this.removeSceneFromContext,
          updateProjectArt: this.updateProjectArt,
          updateProjectInfo: this.updateProjectInfo,
          editSceneName: this.editSceneName
        }}
      >
        {React.cloneElement(this.props.children, {user: this.props.user, triggerLoginModal: this.props.triggerLoginModal, isLoginModalOpen: this.props.isLoginModalOpen})}
      </ProjectsContext.Provider>
    )
  }
}

const ProjectsConsumer = ProjectsContext.Consumer
export { ProjectsProvider, ProjectsConsumer, ProjectsContext }


import React, { useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import TechnicalRequirementsLearnMore from "../Links/technicalRequirementsLearnMore"
import { Error } from '../Toast/index'
function InitialTrackUpload (props) {
  const [files, setFiles] = useState([])
  const { getRootProps, getInputProps } = useDropzone({
    accept: 'audio/x-wav, audio/wav',
    multiple: false,
    onDrop: acceptedFiles => {
      if (acceptedFiles.length > 0) {
        const files = acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
        console.log('accpted ', acceptedFiles)
        setFiles(files)
        if (props.onChange) {
          props.onChange(files)
        }
      } else {
        Error({ message: 'Wrong file type. We only accept uncompressed .wav files.' })
      }
    }

  })

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview))
    },
    [files]
  )
   const  onClickHandler= (e)=>{
      e = e || window.event;
      let src = e.target || e.srcElement;
      let preventOpen = src.getAttribute("data-preventopen")
      console.log("event src ", src.getAttribute("data-preventopen"))
      if(preventOpen){
        e.stopPropagation()
      }
    }
  return (
    <>
      <div onClick={(event) => { event.preventDefault(); console.log("dropzone event ", event)}} {...getRootProps({ className: 'btn-dropzone', onClick: onClickHandler })}>
        <input {...getInputProps()} />

        <div className='prompt cursor-pointer'>
          <div className='prompt-content'>
            <div className='text-icon-xl mb-15'><span className='icon-add-track-lg' /></div>
            <h3 className='prompt-title'>Drag your track file here or click to browse</h3>
            <p>48 kHz &amp; 16-bit stereo uncompressed WAV files only accepted. <TechnicalRequirementsLearnMore red/>.</p>
            <p className='text-sm text-gray-400'>Tracks can’t be over 12 minutes in length or 250 MB in size.</p>
          </div>
          <div className='prompt-footer'>
            <div className='grid-row'>
              {/* {# Callout #} */}
              <div className='col w-full md:w-1/2'>
                <div className='callout'>
                  <div className='callout-num'>01</div>
                  <div className='callout-text'>
                    <p>By uploading your track you agree to keep the song on Deep Sounds for a minimum of 1 year.</p>
                  </div>
                </div>
              </div>
              {/* {# Callout #} */}
              <div className='col w-full md:w-1/2'>
                <div className='callout'>
                  <div className='callout-num'>02</div>
                  <div className='callout-text'>
                    <p>This track will be exclusive to Deep Sounds and can only be licensed through this website.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InitialTrackUpload

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Tooltip } from "react-tippy";
import SalesSubscriptionPayoutsRow from "./SalesSubscriptionPayoutsRow";
import usersAPI from "../../../api/users";
import LoadingContainer from "../../Loading/LoadingContainer";
import paginate from "../../../utils/paginate";
import SalesPagination from "../../Pagination/salesPagination";
import currencyFormatter from "../../../utils/currencyFormatter";

const SalesSubscriptionPayouts = () => {
  const history = useHistory();
  const currentPage = Number(history.location.pathname.split("/").pop()) || 1;
  const perPage = 12;
  const setCurrentPage = (pageNumber) => {
    if (pageNumber !== currentPage) {
      history.push(`/dashboard/sales/subscription-royalties/${pageNumber}`);
    }
  };

  const [isLoaded, setIsLoaded] = useState(true);
  const [subscriptionPayouts, setSubscriptionPayouts] = useState(null);
  const [currentPageSubscriptionPayouts, setCurrentPageSubscriptionPayouts] = useState(null);
  const [totalPaid, setTotalPaid] = useState(null);

  useEffect(() => {
    setIsLoaded(false);
    usersAPI.getSubscriptionPayouts({ currentPage, perPage }).then((res) => {
      console.log("getSubscriptionPayouts res", res);
      const paginatedSubscriptionPayouts = paginate(res?.data?.subscriptionPayouts, perPage) || [];
      const paginatedSubscriptionPayoutsPage = paginatedSubscriptionPayouts[currentPage - 1];
      setCurrentPageSubscriptionPayouts(paginatedSubscriptionPayoutsPage);
      setSubscriptionPayouts(res?.data?.subscriptionPayouts);
      const totalPaidFormatted = currencyFormatter(res?.data?.totalPaid, true);
      setTotalPaid(totalPaidFormatted);
      setIsLoaded(true);
    });
  }, [currentPage]);

  return (
    <LoadingContainer isLoaded={isLoaded}>
      <div class="bg-gray-100 p-20 pb-10 lg:p-25 lg:pb-15 mb-40">
        {/* {# Table Header #} */}
        <div class="flex flex-col md:flex-row items-stretch justify-between bg-gray-100 mb-40">
          <div className="flex-1 flex items-top mb-15 md:mb-0">
            <h3 class="text-label-lg mr-10">
              Subscription Royalties
              <Tooltip
                useContext={true}
                animation="shift"
                arrow="true"
                position="top"
                html={
                  <>
                    <div className="price-tool-title mb-10">Subscription Royalties</div>
                    <div className="text-gray-400 price-tool-desc">
                      Subscription Royalties are monthly payments given to artists to compensate
                      them for licenses checked out by customers who have an unlimited subscription
                      plan. Your Subscription Royalty is paid out in a single monthly payment with a
                      one month delay. For example, you will receive your January Subscription
                      Royalty on March 1st.{" "}
                    </div>
                  </>
                }
              >
                &nbsp;<span className="icon-help"></span>
              </Tooltip>
            </h3>
            <span className="flex-none text-xs mt-5">
              Showing
              {/* {perPage < licenseSales?.length ? perPage : licenseSales?.length} of */}{" "}
              {subscriptionPayouts?.length}
            </span>
          </div>
          {/* {# Summary #} */}
          <div class="flex-none flex flex-col sm:flex-row">
            {/* {# Payout Summary #} */}
            <div class="flex flex-col flex-end pr-30 lg:pr-60 leading-snug mb-30 md:mb-0">
              <span class="text-label-sm mb-3 text-right">Total Paid</span>
              <span class="text-h3 text-white font-display leading-tight text-right">
                {totalPaid}
              </span>
            </div>
          </div>
        </div>

        {/* {# Table #} */}
        <div class="table-wrap ds-scrollbar">
          <table class="stats-table w-full">
            <thead class="text-left">
              <tr>
                <th class="text-label-sm">Month Earned</th>
                <th class="text-label-sm">Transaction ID</th>
                <th class="text-label-sm">Description</th>
                <th class="text-label-sm">Payout Date</th>
                <th class="text-label-sm text-right" style={{ minWidth: "14rem" }}>
                  Total Earnings
                </th>
              </tr>
            </thead>
            <tbody>
              {currentPageSubscriptionPayouts?.map((payout) => {
                return <SalesSubscriptionPayoutsRow key={payout.id} {...payout} />;
              })}
            </tbody>
          </table>
        </div>
        <SalesPagination
          pageLimit={perPage}
          currentPage={currentPage}
          totalRecords={subscriptionPayouts?.length}
          gotoPage={setCurrentPage}
        />
      </div>
    </LoadingContainer>
  );
};

export default SalesSubscriptionPayouts;

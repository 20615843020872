import React, { Component } from "react"
import { Tooltip } from "react-tippy"
import DefaultAvatar from "../../assets/svg/user-avatar.svg"
import CollaboratorEmailInput from "./collaboratorEmailInput"
import CollaboratorToolTip from "./collaboratorTooltip"

class ManageCollaboratorsModal extends Component{
    state={
        isCollaboratorTooltipOpen: this.props.collaborators.map(collaborator=> false),
        previousCollaborators: [{name: "Billy"}, {name: "Bob"}, {name: "Brendan"}, {name: "Bryan"}]
    }
    toggleCollaboratorTooltip =(index)=>{
        let tippys = this.state.isCollaboratorTooltipOpen
        tippys[index] = !tippys[index]
        this.props.handleFormUnsaved(false)
        this.setState({isCollaboratorTooltipOpen: tippys})
    }
    resolveProfilePhoto=(profilePhotoKey)=>{
        const key = profilePhotoKey
        if(profilePhotoKey){
            const ext = key.split('.').reverse()[0];
            let final = key.replace('.' + ext, '') + '-minified-60x60'  + '.' + ext
            return final
        }
    }    
    render(){
        return(
            
            <React.Fragment>
             <header class="modal-header">
                <h2 id="collaborators_title" class="modal-title">
                    {this.props.isOwner ? 
                        "Manage Collaborators" : 
                        "View Collaborators"
                    }
                </h2>
                <button onClick={this.props.handleClose} aria-label="Close Modal" data-micromodal-close="collaborators_modal" class="modal-close" type="button"></button>
            </header>

            {/* {# Modal Content #} */}
            <div id="collaborators_content" class="modal-content">

                {/* {# Collab User List #} */}
                <div class="collab-users">

                    {/* {# Collab User #} */}
                    {/* {% for user in collaborators.slice(0,6) %} */}
                        <div className="collab-user">
                            <div class="collab-user-img">
                                {this.props.owner?.ownerPhoto ? 
                                    <img src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveProfilePhoto(this.props.owner?.ownerPhoto)}`} alt={this.props.owner?.name}/>:
                                    <img src={DefaultAvatar} alt={this.props.owner?.name}/>}

                            </div>
                            <div class="collab-user-info">
                                            <div class="collab-user-name">
                                                {this.props.owner?.name} (Owner)
                                            </div>
                                            <div class="collab-user-subtext">
                                                {this.props.owner?.email}                                            
                                            </div>
                            </div>
                        </div>
                        {this.props.collaborators.map((collaborator, index)=>
                        <div key={"collab" + index} class="collab-user" x-data="{ invited: false }">

                            {/* {# Collab User Image #} */}
                            <div class="collab-user-img">
                                {collaborator.profilePhoto ? <img src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveProfilePhoto(collaborator.profilePhoto)}`} alt={(collaborator.firstName) + " " + (collaborator.lastName)}/>:
                              <img src={DefaultAvatar} alt={(collaborator.firstName) + " " + (collaborator.lastName)}/>}
                                
                            </div>

                            {/* {# Collab User Info #} */}
                            
                            <div class="collab-user-info">
                                {collaborator.status ==="accepted" ? 
                                    <>
                                        <div class="collab-user-name">
                                            {(collaborator.firstName) + " " + (collaborator.lastName)}
                                        </div>
                                        <div class="collab-user-subtext">
                                            {collaborator.em || collaborator.email}                                            
                                        </div>
                                    </>
                                :
                                    <>
                                        <div class="collab-user-name">{collaborator.email}</div>
                                        <div class="collab-user-subtext">Invited</div>
                                    </>
                                }
                            </div>

                            {/* {# Collab Controls #} */}
                            {this.props.isOwner &&
                                <div class="collab-user-controls">
                                    {/* // {# Add Collaborator #} */}
                                    <Tooltip
                                        arrow={false}
                                        open={this.state.isCollaboratorTooltipOpen[index]}
                                        interactive={true}
                                        position={'top-start'}
                                        trigger={'click'}
                                        useContext={true}
                                        // hideOnClick={true}
                                        html={(<CollaboratorToolTip 
                                                    removeCollaborator={this.props.removeCollaborator}
                                                    toggleCollaboratorTooltip={this.toggleCollaboratorTooltip}
                                                    collaboratorId={collaborator.pjCId}
                                                    collabIndex={index}
                                                    projectId={this.props.projectId}
                                                >
                                            </CollaboratorToolTip>
                                        )}
                                        allowHTML={true}
                                        onUntrigger={()=>this.toggleCollaboratorTooltip(index)}
                                        onRequestClose={()=>this.toggleCollaboratorTooltip(index)}
                                    >
                                        <button class="circle-icon circle-icon-sm"
                                            id={"collaboratorTooltip" + index}
                                            onClick={()=>this.toggleCollaboratorTooltip(index)}
                                            data-tippy-html={"#collaboratorTooltip" + index}
                                            data-tippy-placement="top-start"
                                            type="button"
                                        >
                                            <span class="icon-dots-vert"></span>
                                        </button>
                                    </Tooltip>
                                    
                                </div>
                            }
                            {/* {# End Collab Controls #} */}

                        </div>)}
                    {/* {% endfor %} */}
                    {/* {# End Collab User #} */}

                </div>
                {/* {# End Collab User List #} */}

            </div>
            {/* {# End Modal Content #} */}

            {/* {# Modal Footer #} */}
            {(this.props.isOwner && !this.props.complete) &&
                <div class="modal-footer">
                    <CollaboratorEmailInput projectId={this.props.projectId} projectName={this.props.projectName} addCollaboratorToState={this.props.addCollaboratorToState} options={this.props.filteredCollaborators}
                        collaboratorEmail={this.props.collaboratorEmail} handleInputChange={this.props.handleInputChange} onClick={this.props.onClick} onKeyDown={this.props.onKeyDown} activeOption={this.props.activeOption}
                        updateLatest={this.props.updateLatest} />
                
                    {/* {# End Invite by Email #} */}

                </div>
            }
            {/* {# End Modal Footer #} */}

            {/* {# Share CTA #} */}
            {((this.props.projectLink && this.props.linkEnabled === "active" )|| this.props.isOwner) ?
                <div class="modal-footer-cta">
                    <a 
                        href="#"
                        class="text-link-white flex items-center"
                        onClick={this.props.handleShareModalOpen}>
                            <span class="icon-share text-icon-md mr-3"></span>
                            <span class="text-sm xl:text-base">Share this project via a public share link</span>
                    </a>
                </div>
                :
                <></>
            }
</React.Fragment>
        )
    }
}
export default ManageCollaboratorsModal
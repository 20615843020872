import React from "react";
export default function TechnicalRequirements(props){
    let linkText = props.children || 'technical requirements'
    return(
        <a 
            data-preventOpen={true} 
            className={props.red ? "text-link": ""} 
            target="_blank" 
            href={"http://help.deepsounds.com/en/articles/4813154-audio-technical-requirements"}
        >
            {props.underline ? 
                <span style={{textDecoration: "underline"}}>{linkText}</span>
            :  
                <span >{linkText}</span>
            }
        </a>
    )
}

import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { LicenseContext } from "../../../contexts/License";
import { CartContext } from "../../../contexts/Cart";
import { PlayerContext } from "../../../PlayerContext";
import { UserContext } from "../../../contexts/User";
import LicensePrice from "../LicenseForm/LicensePrice";
import { Tabs, TabItemContent } from "../../UI/Tabs";
import PricingCardFeatures from "../../UI/PricingCardFeatures";
import LicenseForm from "../LicenseForm";
import LicenseAndDownload from "./LicenseAndDownload";
import DownloadPreview from "../../Track/DownloadPreview";

const DownloadPanel = (props) => {
  const { demoSubscription, handleClose, index, isDemo, project, stem, track, version } =
    props;

  const history = useHistory();

  const cartContext = useContext(CartContext);
  const licenseContext = useContext(LicenseContext);
  const currentUser = useContext(UserContext);
  const player = useContext(PlayerContext);

  const { addToCart: addToCartContextFunction, updateCartItem: updateCartContextFunction } =
    cartContext || {};
  const { licenseProductDataPersonal, licenseProductDataCommercial } = licenseContext || {};

  console.log("currentUser", currentUser);
  // console.log("playerContext", player);
  // console.log("DownloadPanel props", props);
  const hasActiveSubscription = currentUser?.user?.planStatus === "active";
  const subscriber = isDemo ? demoSubscription : hasActiveSubscription;
  // console.log("subscriber", subscriber);
  const { plan_type: planType } = currentUser?.user?.subscription?.plan?.metadata || {};
  console.log("planType", planType);

  const { handlePause, mediaPlayer } = player;

  useEffect(() => {
    if (mediaPlayer?.isTrackPlaying) {
      handlePause();
    }
  }, []);

  const defaultValues = {
    licenseMain: "B",
  };

  const { handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const addToCart = (data) => {
    console.log("addToCart data", data);

    // return;

    const licenseInfo = {
      track: {
        trackId: track.trackId,
        trackTitle: track.trackTitle,
        artistId: track.artistId,
        artistName: track.artistName,
        albumArt: track.albumArt,
      },
      license: {
        licenseMain: data?.licenseMain,
        price: data?.product?.priceId,
        product: data?.product,
        originalPrice: data?.originalPrice,
        finalPrice: data?.finalPrice,
      },
      subscriber: subscriber,
    };
    console.log("addToCart licenseInfo", licenseInfo);

    addToCartContextFunction(licenseInfo);
  };

  const onSubmit = (submitParams) => (data, e) => {
    e.preventDefault();
    const { redirectPath } = submitParams || {};
    data = submitParams?.cartData;
    if (redirectPath) {
      handleClose();
      history.push(redirectPath);
    } else if (!isDemo) {
      addToCart(data);
    }
  };

  let tabItems = ["Personal", "Commercial", "Other"];
  if (planType === "commercial") {
    tabItems = ["Commercial", "Other"];
  }
  const [activeTab, setActiveTab] = useState(tabItems[0]);

  return (
    <>
      <Tabs activeTab={activeTab} setActiveTab={setActiveTab} tabItems={tabItems}>
        <TabItemContent activeTab={activeTab} name="Personal">
          <form onSubmit={handleSubmit(onSubmit({}))} className="ds-form" id="download-form">
            {planType === "personal" ? (
              <>
                <LicenseAndDownload
                  addToCart={addToCart}
                  isDemo={isDemo}
                  licenseData={licenseProductDataPersonal}
                  track={track}
                  stem={stem}
                  version={version}
                />
              </>
            ) : (
              <>
                <PricingCardFeatures
                  excluded={[
                    "Coverage for Client & Commercial Work",
                    "Feature Films, Video Games, & Broadcast TV",
                  ]}
                  included={["Cleared for Social Media/Web/Podcasts", "Monetize Your Videos"]}
                />

                <LicensePrice
                  activeTab={activeTab}
                  license={licenseProductDataPersonal?.license}
                  handleClose={handleClose}
                  handleLicenseFormSubmit={handleSubmit}
                  isDemo={isDemo}
                  onSubmit={onSubmit}
                  tabItem={tabItems[0]}
                  subscriber={subscriber}
                  track={track}
                  type={"personal"}
                />

                {!isDemo &&
                  (!subscriber && stem ? (
                    <p className="text-center text-sm">
                      Purchase a subscription to download stem previews
                    </p>
                  ) : (
                    <p className="text-center">
                      <DownloadPreview
                        handleClose={handleClose}
                        stem={stem}
                        track={track}
                        version={version}
                      >
                        {subscriber ? "Download mp3 preview" : "Download watermarked preview"}
                      </DownloadPreview>
                    </p>
                  ))}
              </>
            )}
          </form>
        </TabItemContent>
        <TabItemContent activeTab={activeTab} name="Commercial">
          <form onSubmit={handleSubmit(onSubmit({}))} className="ds-form" id="download-form">
            {planType === "commercial" ? (
              <>
                <LicenseAndDownload
                  addToCart={addToCart}
                  isDemo={isDemo}
                  licenseData={licenseProductDataCommercial}
                  stem={stem}
                  track={track}
                  version={version}
                />
              </>
            ) : (
              <>
                <PricingCardFeatures
                  excluded={["Feature Films, Video Games, & Broadcast TV"]}
                  included={[
                    "Cleared for Social Media/Web/Podcasts",
                    "Monetize Your Videos",
                    "Coverage for Client & Commercial Work",
                  ]}
                />
                <LicensePrice
                  activeTab={activeTab}
                  license={licenseProductDataCommercial?.license}
                  handleClose={handleClose}
                  handleLicenseFormSubmit={handleSubmit}
                  isDemo={isDemo}
                  onSubmit={onSubmit}
                  tabItem={tabItems[1]}
                  subscriber={subscriber}
                  track={track}
                  type={"commercial"}
                />
                {!isDemo &&
                  (!subscriber && stem ? (
                    <p className="text-center text-sm">
                      Purchase a subscription to download stem previews
                    </p>
                  ) : (
                    <p className="text-center">
                      <DownloadPreview stem={stem} track={track} version={version}>
                        {subscriber ? "Download mp3 preview" : "Download watermarked preview"}
                      </DownloadPreview>
                    </p>
                  ))}
              </>
            )}
          </form>
        </TabItemContent>
        <TabItemContent activeTab={activeTab} name="Other">
          <p className="mt-20 mb-0">Choose how you will use this track to get a quote below:</p>
          <LicenseForm
            activeTab={activeTab}
            handleClose={handleClose}
            isDemo={isDemo}
            demoSubscription={demoSubscription}
            index={index}
            track={track}
            tabItem={tabItems[2]}
            project={project}
          />
          {!isDemo &&
            (!subscriber && stem ? (
              <p className="text-center text-sm">
                Purchase a subscription to download stem previews
              </p>
            ) : (
              <p className="text-center">
                <DownloadPreview stem={stem} track={track} version={version}>
                  {subscriber ? "Download mp3 preview" : "Download watermarked preview"}
                </DownloadPreview>
              </p>
            ))}
        </TabItemContent>
      </Tabs>
    </>
  );
};

export default React.memo(DownloadPanel);

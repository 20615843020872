import getBlobDuration from "get-blob-duration";
import moment from "moment";
import React, { Component } from "react";
import MicroModal from "react-micro-modal";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import socketIOClient from "socket.io-client";
import { PlayerContext } from "../../PlayerContext";
import { UserConsumer } from "../../contexts/User";
import SortBar from "../Browse/sortBar";
import { confirm } from "../confirmModal/index";
import InitialTrackUpload from "../Dropzone/initialTrackUpload";
import CircularIndeterminant from "../Loading/circularIndeterminant";
import NewTrackModal from "../modal/newTrackModal";
import Pagination from "../Pagination/pagination";
import StripeSetupPrompt from '../Stripe/stripeSetupPrompt';
import { Error, Success } from "../Toast/index";
import api from "../utils/api";
import ApprovedTracks from "./approved";
import IncompleteTracks from "./incomplete";
import PendingTracks from "./pending";
import RejectedTracks from "./rejected";
import SearchBar from "./searchBar";


var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment)


class Main extends Component {
    state = {
        myAlbums: [],
        searchTerm: "",
        currentPage: 1,
        totalTracks: 0,
        newAlbumTitle: "",
        filteredTracks: {1:[]},
        trackTitle: "",
        newTrackTitle: "",
        trackList: [],
        mediaUrl: "",
        trackId: null,
        sourceToGet: "preview",
        peaks: "[]",
        albumArt: [],
        file: [],
        sortCriteria: "Date Approved - Asc",
        isTrackModalOpen: false,
        uploadProgress: 0,
        loaded: false
    }
    handleTrackModalClose = async ()=>{

            if(this.state.isTrackModalOpen){
                if(await confirm("Are you sure you want to exit without saving?", "")){

                    this.setState({
                        isTrackModalOpen: false,
                        newTrackTitle: "",
                        newTrackAlbum: false,
                        file: []
                    })
                }
                else{
                    return
                }
            }

    }

    removeTrackCB =(trackId)=>{
        let trackList = [...this.state.trackList]
        trackList =trackList.filter(track=>track.trackId !== trackId)
        this.setState({trackList: trackList})
    }
    handleInputChange = (event)=>{
        event.preventDefault()
        this.props.handleFormUnsaved(true)
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let trackTitleError;
        if(name === "newTrackTitle" && value.length > 1){
            trackTitleError =""
        }
        else if(name === "newTrackTitle"){
            trackTitleError = "Track Title Must be Valid"
        }
        if(name === "newTrackTitle" && value.length > 60){
          Error({message: "Track title cannot be more than 60 characters."})
          return
        }
        else{
          this.setState({
            [name]: value,
            trackTitleError: trackTitleError
        });
        }

    }
    delayedSearch = ()=>{}
    updateSearchTerm = (event)=>{

      clearTimeout(this.delayedSearch)
      event.preventDefault()
      const target = event.target;
      const {value} = target;
      this.setState({searchTerm: value, loaded: false}, ()=>{
        this.delayedSearch = setTimeout(this.refreshSearch, 650 )
      })
    }
    refreshSearch =()=>{
        this.setState({loaded: false, updatingSearch: true}, ()=>{
          api.getMyTracksWithFilter(this.props.match.params.status, this.state.searchTerm || undefined, this.state.sortQuery, 0)
            .then(res => {

                let filteredTracks = {1: res.data.tracks}
                this.setState({ filteredTracks: filteredTracks, totalTracks: res.data.totalTracks, loaded: true,  })
            })
            .catch(error=>{
                this.setState({loaded: true})

            })
        })

    }
    handleAlbumChange = (selectedAlbum)=>{
        this.props.handleFormUnsaved(true)
        this.setState(state =>{
            return{
                newTrackAlbum: selectedAlbum,
                albumTrackError: ""
            }
        })
    }
    componentDidUpdate = (prevProps, prevState)=>{

      if(prevProps.match.params.status !== this.props.match.params.status){
        let initialSort;
        switch(this.props.match.params.status){
          case "approved":
            initialSort = "appDateD";
            break;
          case "incomplete":
            initialSort = "dateD";
            break;
          default:
            initialSort = "dateD";
            break;
        }
        if(this.props.match.params.status !== "add-tracks"){
          this.setState({searchTerm: "",loaded: false}, ()=>{
            api.getMyTracksWithFilter(this.props.match.params.status,  undefined, initialSort, 0)
                .then(res => {

                    let filteredTracks = {1: res.data.tracks}
                    this.setState({ filteredTracks: filteredTracks, totalTracks: res.data.totalTracks, sortQuery: initialSort, searchTerm: "", sortCriteria: this.props.match.params.status === "approved" ? "Date Approved - Desc": "Recently Added - Desc", loaded: true },()=>{
                      this.gotoPage(1, res.data.totalTracks)
                    })
                })
                .catch(error=>{
                    this.setState({loaded: true})

                })
              })
        }
        else{
          this.setState({loaded: true})
        }

      }
    }
    //get a requested set of tracks from the db
    componentDidMount = () => {
        this.props.handleFormUnsaved(false)

        let initialSort;
        switch(this.props.match.params.status){
          case "approved":
            initialSort = "appDateD";
            break;
          case "incomplete":
            initialSort = "dateD";
            break;
          default:
            initialSort = "dateD";
            break;
        }

        if(this.props.match.params.status !== "add-tracks"){
          api.getMyTracksWithFilter(this.props.match.params.status, undefined, initialSort, 0)
          .then(res => {

              let filteredTracks = {1: res.data.tracks}
              this.setState({ filteredTracks: filteredTracks, totalTracks: res.data.totalTracks, loaded: true, sortQuery: initialSort })
          })
          .catch(error=>{
                this.setState({loaded: true})

          })
        }

        api.getMyAlbums()
            .then(res=>{

                if(res.data.length){
                    let myAlbumOptions = []
                    res.data.forEach(album =>{
                        myAlbumOptions.push({
                            value: album.id,
                            label: album.title,
                            imageUrl: album.imageUrl
                        })
                    })
                    this.setState({
                        myAlbums: myAlbumOptions, albumModalOpen: false
                    })
                }
            })
        api.getStripeAccount()
        .then(
            (res) => {

                this.setState({
                    loaded: true,
                    stripeComplete: res.data.complete
                })
            },
            (error) => {

            }
        )

    }
    updateProgress = (progressData)=>{
      let parsedData = JSON.parse(progressData)
      let progress = Math.round(100 * parsedData.loaded/parsedData.total)
      if(progress > this.state.uploadProgress){
        clearInterval(this.fakeLoad)
        this.setState({uploadProgress: progress})
      }
    }
    submitNewTrack = (changeNavFlag)=>{
        if(this.state.newTrackTitle.length > 1 && this.state.newTrackAlbum){
            let newTrack = {
                title: this.state.newTrackTitle.trim(),
                albumId: this.state.newTrackAlbum.value
            }
            this.setState({submitting: true, uploadProgress: 0})
            api.submitTrackInfo(newTrack)
                .then(res=>{

                    this.fakeLoad= setInterval(() => {

                      if(this.state.uploadProgress < 25){
                        let updated = this.state.uploadProgress + 1
                        this.setState({uploadProgress:updated})
                      }else{
                        clearInterval(this.fakeLoad)
                      }

                    }, 70);
                    if(res.data.success){
                        const data = new FormData()
                        const socket = socketIOClient();
                        socket.on("progress" + res.data.trackId, data => {


                          this.updateProgress(data)
                        });
                        data.append('file', this.state.file[0])
                        api.uploadTrackSource(res.data.trackId, data)
                            .then(response=>{

                                if(response.data.success){
                                  if(changeNavFlag === true){
                                    this.setState({newTrackId: res.data.trackId})
                                    this.props.handleFormUnsaved(false)

                                    this.props.history.push("/dashboard/new-track/"+this.state.newTrackId, {showToast: true})
                                    // SuccessRedirect({message:"Track Added!", autoClose: 1000,onClose: this.successToastSubmit})
                                  }
                                  else{
                                    Success({message:"Track Added!", onClose: this.successToastSave})
                                    this.setState({submitting: false, isTrackModalOpen: false, uploadProgress: 0, newTrackTitle: "", newTrackAlbum: undefined})
                                    this.props.handleFormUnsaved(false)
                                  }
                                }
                                else{
                                  Error({message:"An error occurred!", onClose: this.errorToast})
                                }
                            })
                            .catch(error=>{
                                // an error occured uploading your souce


                                if(changeNavFlag){
                                  this.props.handleFormUnsaved(false)
                                    this.setState({newTrackId: res.data.trackId}, ()=>{
                                      if(error.response?.data === "File Too Large"){

                                        this.props.history.push("/dashboard/new-track/"+this.state.newTrackId, {showErrorToast: true, errorToastMessage: "Your track file was rejected because it is larger than 250 mb."})

                                        // Error({message:"Your track file was rejected because it is larger than 250 mb. Your track can be found in incomplete", onClose: this.errorToastSubmit, autoClose: 3000})
                                      }
                                      else if(error.response?.data === "File is longer than 12 minutes"){
                                        this.props.handleFormUnsaved(false)

                                        this.props.history.push("/dashboard/new-track/"+this.state.newTrackId, {showErrorToast: true, errorToastMessage: "Your track file was rejected because it is longer than 12 minutes."})

                                        // Error({message:"Your track file was rejected because it is longer than 12 minutes. Your track can be found in incomplete", onClose: this.errorToastSubmit, autoClose: 3000})
                                      }
                                      else{
                                        this.props.handleFormUnsaved(false)

                                        this.props.history.push("/dashboard/new-track/"+this.state.newTrackId, {showErrorToast: true, errorToastMessage: "An error occurred uploading your source!"})

                                        // Error({message:"An error occurred uploading your source! Your track can be found in incomplete", onClose: this.errorToastSubmit, autoClose: 3000})

                                      }
                                    })


                                }
                                else{
                                    this.setState({submitting: false, isTrackModalOpen: false})
                                    if(error.response?.data === "File Too Large"){
                                      Error({message:"Your track file was rejected because it is larger than 250 mb. Your track can be found in incomplete", onClose: this.errorToastSave})
                                    }
                                    else if(error.response?.data === "File is longer than 12 minutes"){
                                      Error({message:"Your track file was rejected because it is longer than 12 minutes. Your track can be found in incomplete", onClose: this.errorToastSave})
                                    }
                                    else{
                                      Error({message:"An error occurred uploading your source! Your track can be found in incomplete", onClose: this.errorToastSave})
                                    }
                                }
                            })
                    }
                    else{
                        //an error occured creating the track
                        Error({message:"An error occurred creating your track!", onClose: this.errorToast})
                    }
                })
                .catch(error=>{
                    // an error creating your track
                    if(error.response?.status === 403){
                      Error({message:"Please create your stripe account before uploading a track!"})
                      this.setState({submitting: false})
                    }
                    else{
                      Error({message:"An error occurred creating your track!", onClose: this.errorToast})
                    }
                })
        }
        else{
            let trackTitleError;
            let albumTrackError;
            if(this.state.newTrackTitle.length < 1){
                trackTitleError = "Track Title Must be Valid!"
            }
            if(!this.state.newTrackAlbum){
                albumTrackError ="Must Select an Album for This Track!"
            }
            this.setState({
                trackTitleError: trackTitleError,
                albumTrackError: albumTrackError
            })
        }
    }
    updateAlbumsCB = (newAlbum)=>{
        let myAlbumOptions = this.state.myAlbums
        myAlbumOptions.push(newAlbum)
        this.setState({myAlbums: myAlbumOptions, newTrackAlbum: myAlbumOptions[myAlbumOptions.length-1]})

    }
    submitNewAlbum = ()=>{
        if(this.state.newAlbumTitle.length > 1 && this.state.albumArt.length >0){
            let newAlbum = {
                title: this.state.newAlbumTitle
            }
            api.createNewAlbum(newAlbum)
                .then(response=>{

                    if(response.data.success === true){
                        const data = new FormData()
                        data.append('file', this.state.albumArt[0])
                        api.updateAlbumArt(response.data.album.id, data)
                            .then(res=>{

                                if(res.data.success === true){
                                    let myAlbumOptions = this.state.myAlbums
                                    myAlbumOptions.push({
                                        value: res.data.album.id,
                                        label: res.data.album.title,
                                        imageUrl: res.data.album.imageUrl
                                    })
                                    Success({message:"Album Added!", onClose: this.successAlbumToastSubmit})
                                    this.setState({albumModalOpen: false, myAlbums: myAlbumOptions, newTrackAlbum: myAlbumOptions[myAlbumOptions.length-1], albumTitleError: undefined})
                                }
                                else{
                                    Error({message:"An error occurred!", onClose: this.errorToast})
                                }
                            })
                            .catch(error=>{
                                Error({message:"An error occurred!", onClose: this.errorToast})
                            })
                    }
                    else{
                        Error({message:"An error occurred!", onClose: this.errorToast})
                    }
                })
                .catch(error=>{
                    Error({message:"An error occurred!", onClose: this.errorToast})
                    if(error.response.status === 400){
                        this.setState({albumTitleError: "Album with that title exists!"})
                    }
                    else{
                        this.setState({albumTitleError: error.response.statusText})
                    }

                })
        }
        else{
            let albumTitleError;
            let albumArtError;
            if(this.state.newAlbumTitle.length < 1){
                albumTitleError = "Album Title Must be Valid"
            }
            if(this.state.albumArt.length < 1){
                albumArtError = "Album Art Must be Valid"
            }
            this.setState({
                albumTitleError: albumTitleError,
                albumArtError: albumArtError
            })
        }
    }
    successAlbumToastSubmit = ()=>{
        toast.dismiss()
    }
    successToastSubmit = ()=>{
        toast.dismiss()
        this.setState({trackModalOpen: false})
        window.location.assign('/dashboard/new-track/'+this.state.newTrackId)
    }
    errorToastSubmit = ()=>{
        toast.dismiss()
        this.setState({trackModalOpen: false})
        window.location.assign('/dashboard/new-track/'+this.state.newTrackId)
    }
    errorToastSave = ()=>{
        toast.dismiss()
        api.getTracks()
        .then(res => {

            this.setState({ trackList: res.data })
        })
    }
    errorToast = ()=>{
        toast.dismiss()
    }
    successToastSave = ()=>{
        toast.dismiss()
        // change to get MY tracks for artist
        api.getTracks()
        .then(res => {

            this.setState({ trackList: res.data })
        })
    }

    changeTrack = (track) => {
        this.context.showPlayer()


        if (track.trackId) {
            if(this.context.mediaPlayer.trackId !== track.trackId){
                api.getAudio("mp3", track.trackId)
                .then(res => {

                    track.url=res.data.url
                    this.context.loadTrackInMediaPlayer(track.trackId, track.idKey)
                    this.context.setAutoPlay(false)
                })
            }
            else{
                this.context.playPause()
            }

        }
    }
    onPageChanged = data => {
      const { currentPage, totalPages } = data;
      // const searchBarEl = document.getElementById("search")
      // searchBarEl.scrollIntoView()
      // const offset = (currentPage - 1) * pageLimit;

      this.setState({ currentPage, totalPages, });
    }
    gotoPage = (page, totalPages) => {

      // const { onPageChanged = f => f } = this.props;
        const searchBarEl = document.getElementById("track-list-navs")

      const currentPage = Math.max(0, Math.min(page, totalPages));

      const paginationData = {
        currentPage,
        totalPages: totalPages,
        pageLimit: 10,
        totalRecords: this.state.totalTracks
      };

      if(this.state.filteredTracks[currentPage]?.length > 0 ){
          this.setState({ currentPage }, () => this.onPageChanged(paginationData));
          if(this.state.firstLoad){
            searchBarEl.scrollIntoView(true)
            }
            this.setState({firstLoad: true})
      }
      else if(this.state.loaded){
        this.setState({ currentPage: currentPage, loaded: false }, () => this.onPageChanged(paginationData));
          api.getMyTracksWithFilter(this.props.match.params.status, this.state.searchTerm || undefined, this.state.sortQuery, (currentPage-1)* 10)
          .then(res => {

              this.setState(prevState=>({...prevState, filteredTracks: {...prevState.filteredTracks, [currentPage]: res.data.tracks }, totalTracks: res.data.totalTracks}),()=>{
                this.setState({loaded: true }, () => this.onPageChanged(paginationData));
              })
              if(this.state.firstLoad){
                searchBarEl.scrollIntoView(true)
            }
            this.setState({firstLoad: true})
          })
          .catch(error=>{
            this.setState({loaded: true})

          })
          // this.setState({loaded: false})
          // api.getMyCustLicenses(((currentPage-1)* 12),this.state.searchTerm)
          // .then(res=>{
          //     if(res.data){
          //         this.setState(prevState=>({...prevState, filteredCustLicenses: {...prevState.filteredCustLicenses, [currentPage]: res.data.licenses}}),()=>{
          //             this.setState({ currentPage, loaded: true }, () => this.onPageChanged(paginationData));
          //         })
          //     }
          //
          // })
      }
      else{

      }


    }
    playPreview = ()=>{
        this.setState({playPreview: !this.state.playPreview})
    }
    getBlobDuration = async (blob)=>{
        const duration = await getBlobDuration(blob)
        let formatted = moment.duration(Math.floor(duration), 'seconds').format("m:ss",{forceLength: true, trim: false})

        this.setState({previewDuration: formatted})
    }
    setWavePreview = (file)=>{
        let wavePreview
        let reader = new FileReader()
        reader.onload = (evt)=>{
            wavePreview = new window.Blob([new Uint8Array(evt.target.result)])

            this.getBlobDuration(wavePreview)
            this.setState({wavePreview: wavePreview})
        }
        reader.readAsArrayBuffer(file[0])
    }
    setTrackFileState = (file)=>{
        this.props.handleFormUnsaved(true)

        this.setWavePreview(file)
        this.setState({
            file: file,
            isTrackModalOpen: true
        })
    }
    emitPlayable = ()=>{
        this.setState({playable: true})
    }
    setAlbumArtState = (file)=>{

        if(file.error){
            this.setState({albumArtError: "File must be an image in a 1x1 ratio!"})
        }
        else{
            this.setState({
                albumArt: file,
                albumPreview: URL.createObjectURL(file[0]),
                albumArtError: null
            })
        }

    }
    toggleSortTippy = ()=>{
        this.setState({isSortTippyOpen: !this.state.isSortTippyOpen})
    }
    sortBy = (criteria, overRide)=>{
        if(criteria !== this.state.sortCriteria || overRide){
            let updateSort;

            switch(criteria){
                case "Recently Added - Asc":
                  updateSort = "dateA"
                break;
                case "Recently Added - Desc":
                  updateSort = "dateD"
                break;
                case "Most Played":
                  updateSort = "mostPlayed"
                break;
                case "Most Downloaded":
                  updateSort = "mostDownloaded"
                break;
                case "Most Liked":
                  updateSort = "mostLiked"
                break;
                case "Most Projects":
                  updateSort = "mostProjects"
                break;
                case "Most Licensed":
                  updateSort = "mostLicensed"
                  break;
                case "Date Approved - Asc":
                  updateSort = "appDateA"
                  break;
                case "Date Approved - Desc":
                  updateSort = "appDateD"
                  break;
                case "Name - Asc":
                  updateSort = "nameA"
                  break;
                case "Name - Desc":
                  updateSort = "nameD"
                  break;
                default:
                  updateSort = "dateA"
                  break;
            }
            api.getMyTracksWithFilter(this.props.match.params.status, this.state.searchTerm || undefined, updateSort, 0)
            .then(res => {

                let filteredTracks = {1: res.data.tracks}
                this.setState({ filteredTracks: filteredTracks, totalTracks: res.data.totalTracks, loaded: true })
            })
            .catch(error=>{
                this.setState({loaded: true})

            })
            this.setState({sortCriteria: criteria, isSortTippyOpen: false, sortQuery: updateSort, currentPage: 1})
        }
        else{
            this.setState({isSortTippyOpen: false, currentPage: 1})
        }
    }
    render() {

        return (
            <UserConsumer>
                {({ user, login, logout }) => (
                    <>
                        <div className="container mb-40 lg:mb-80">
                            <MicroModal
                                open={this.state.isTrackModalOpen}
                                closeOnEscapePress={true}
                                handleClose={()=>this.handleTrackModalClose()}
                                closeOnOverlayClick={true}
                                closeOnAnimationEnd={true}
                                modalOverlayClassName="modal-overlay"
                                modalClassName="modal micromodal-slide is-open"
                                modalOverlayStyles={null}
                                children={ handleClose =>
                                    <NewTrackModal
                                        handleClose={handleClose}
                                        submitting={this.state.submitting}
                                        playable={this.state.playable}
                                        playPreview={this.playPreview}
                                        playPreviewState={this.state.playPreview}
                                        file={this.state.file} emitPlayable={this.emitPlayable}
                                        wavePreview={this.state.wavePreview}
                                        previewDuration={this.state.previewDuration}
                                        newTrackTitle={this.state.newTrackTitle}
                                        trackTitleError={this.state.trackTitleError}
                                        handleInputChange={this.handleInputChange}
                                        albumTrackError={this.state.albumTrackError}
                                        newTrackAlbum={this.state.newTrackAlbum}
                                        myAlbums={this.state.myAlbums}
                                        handleAlbumChange={this.handleAlbumChange}
                                        submitNewTrack={this.submitNewTrack}
                                        updateAlbumsCB={this.updateAlbumsCB}
                                        progress={this.state.uploadProgress}
                                    />
                                }
                                containerStyles={{background: "#131313", padding:"asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box"}}
                            ></MicroModal>
                            {/* {# App Header #} */}
                            <div className="view-header">
                                <h1 className="view-title"  >Tracks</h1>
                                <div className="view-header-controls">
                                  {this.props.match.params.status.toLowerCase() !== "add-tracks" ? (
                                    <a href="/dashboard/tracks/add-tracks" className="btn btn-primary"><span className="icon-plus"></span> Add Track</a>
                                    ) : ""
                                  }
                                </div>
                            </div>

                            {/* {# Tabs #} */}
                            {/* {% import "components/tracks/tracks-tabs.html" as nav %}
                                    {{ nav.active('Approved') }} */}
                            <nav className="tabs mb-30" id="track-list-navs">
                                <ul className="tab-items ">
                                    <li className={`tab-item ${this.props.match.params.status.toLowerCase() === "approved" ? "active" : ""}`}>

                                        <NavLink to="/dashboard/tracks/approved" className='tab-item-title' >Approved</NavLink>
                                    </li>
                                    <li className={`tab-item ${this.props.match.params.status.toLowerCase() === "incomplete" ? "active" : ""}`}>
                                        <NavLink to="/dashboard/tracks/incomplete" className="tab-item-title">Incomplete</NavLink>
                                    </li>
                                    <li className={`tab-item ${this.props.match.params.status.toLowerCase() === "pending" ? "active" : ""}`}>
                                        <NavLink to="/dashboard/tracks/pending" className="tab-item-title">Pending</NavLink>
                                    </li>
                                    <li className={`tab-item ${this.props.match.params.status.toLowerCase() === "rejected" ? "active" : ""}`}>
                                        <NavLink to="/dashboard/tracks/rejected" className="tab-item-title">Rejected</NavLink>
                                    </li>
                                    {user?.admin &&
                                     <li className={`tab-item ${this.props.match.params.status.toLowerCase() === "broken" ? "active" : ""}`}>
                                      <NavLink to="/dashboard/tracks/broken" className="tab-item-title">Broken</NavLink>
                                     </li> }
                                    <li className={`tab-item ${this.props.match.params.status.toLowerCase() === "add-tracks" ? "active" : ""}`}>
                                        <NavLink to="/dashboard/tracks/add-tracks" className="tab-item-title">Add Tracks</NavLink>
                                    </li>
                                </ul>
                            </nav>
                            {this.props.match.params.status !== "add-tracks" &&
                                <SearchBar updateSearchTerm={this.updateSearchTerm} searchTerm={this.state.searchTerm}/>
                            }
                            {this.state.loaded ?
                            <>
                            {this.props.match.params.status !== "add-tracks" &&
                                <SortBar resultsText={`${this.state.totalTracks} Tracks`} trackStatus={this.props.match.params.status} artistTrackList={true} sortCriteria={this.state.sortCriteria}  sortBarId={"track_sort"} marginClasses={"mb-20 md:mb-30"} toggleSortTippy={this.toggleSortTippy} isSortTippyOpen={this.state.isSortTippyOpen} sortBy={this.sortBy}/>

                            }
                            {/* {# Tracks List #} */}
                            <div className={`track-list-container mb-40 ${this.state.totalTracks === 0 && "text-h4 text-white"}`}>
                                {/* // {# Track List Card #} */}

                                {this.props.match.params.status==="add-tracks" &&
                                    (this.state.loaded && this.state.stripeComplete ?
                                        <InitialTrackUpload onChange={this.setTrackFileState}></InitialTrackUpload>
                                    :
                                        <StripeSetupPrompt />
                                    )
                                }
                                {this.props.match.params.status==="approved" &&
                                    <ApprovedTracks tracks={this.state.filteredTracks[this.state.currentPage]} changeTrack={this.changeTrack}  removeTrackCB={this.removeTrackCB} ></ApprovedTracks>}
                                {this.props.match.params.status === "incomplete" &&
                                    <IncompleteTracks tracks={this.state.filteredTracks[this.state.currentPage]} removeTrackCB={this.removeTrackCB}></IncompleteTracks>}
                                {this.props.match.params.status === "pending" &&(
                                    user?.admin ?
                                    <PendingTracks user={this.props.user} tracks={this.state.filteredTracks[this.state.currentPage]?.length > 0 ? this.state.filteredTracks[this.state.currentPage].filter(track=>track.trackStatus !== "broken"): []}></PendingTracks>:
                                    <PendingTracks user={this.props.user} tracks={this.state.filteredTracks[this.state.currentPage]}></PendingTracks>
                                )}

                                {(this.props.match.params.status === "broken" && user?.admin)&&
                                    <PendingTracks user={this.props.user} tracks={this.state.filteredTracks[this.state.currentPage]}></PendingTracks>}
                                {this.props.match.params.status === "rejected" &&
                                    <RejectedTracks user={this.props.user} tracks={this.state.filteredTracks[this.state.currentPage]}></RejectedTracks>}
                                    <div className="mt-20">
                                        <Pagination pageLimit={12} currentPage={this.state.currentPage} totalRecords={this.state.totalTracks} gotoPage={this.gotoPage}/>

                                    </div>
                                    </div>
                                    </>: <>
                                    <CircularIndeterminant forceCenter/>
                                    </>
                                    }
                                    </div>

                </>)}
            </UserConsumer>
        )
    }

}
Main.contextType = PlayerContext
export default Main

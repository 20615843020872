import React from "react";
export default function TermsAndConditions(props){
    let linkText = props.children || 'Learn more'
    return(
        <a 
            data-preventOpen={true} 
            className={props.red ? "text-link": ""} 
            target="_blank" 
            href={"/terms"}
        >
            {props.underline ? 
                <span style={{textDecoration: "underline"}}>{linkText}</span>
            :  
                <span >{linkText}</span>
            }
        </a>
    )
}

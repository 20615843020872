import React, { Component } from "react";
import { Tooltip } from "react-tippy";
import CircularIndeterminant from "../Loading/circularIndeterminant";
import "./style.css";
import TopPayoutTooltip from "./topPayoutTooltip";
import currencyFormatter from "../../utils/currencyFormatter"

const moment = require("moment");
class PayoutRow extends Component {
  state = {
    isPayoutTippyOpen: false,
  };
  togglePayoutTippy = () => {
    this.setState({ isPayoutTippyOpen: !this.state.isPayoutTippyOpen });
  };

  render() {
    return (
      <tr>
        {this.props.getArtistSubscriptionPayouts ? (
          <td
            onClick={() =>
              this.props.getArtistSubscriptionPayouts(this.props.id)
            }
          >
            {this.props.status === "transferErrors" && (
              <span
                style={{ textDecoration: "none" }}
                className="icon-warning text-primary text-icon-sm ml-5"
              ></span>
            )}
            <span style={{ textDecoration: "underline", cursor: "pointer" }}>
              {moment(this.props.forMonth).utc().format("MM/YY")}
            </span>
          </td>
        ) : (
          <td>
            <span>{moment(this.props.forMonth).utc().format("MM/YY")}</span>
          </td>
        )}
        {this.props.subscriptionRevenue ? (
          <td>
            {currencyFormatter(this.props.subscriptionRevenue / 100)}
          </td>
        ) : (
          <td style={{ textAlign: "center" }}>-</td>
        )}
        {this.props.subscriptionRevenueLessFees ? (
          <td>
            {currencyFormatter(this.props.subscriptionRevenueLessFees / 100)}
          </td>
        ) : (
          <td style={{ textAlign: "center" }}>-</td>
        )}
        {this.props.artistPool ? (
          <td
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() =>
              this.props.getArtistSubscriptionPayouts(this.props.id)
            }
          >
            {currencyFormatter(this.props.artistPool / 100)}
          </td>
        ) : (
          <td style={{ textAlign: "center" }}>-</td>
        )}
        {this.props.artistPoolPercent ? (
          <td>{(this.props.artistPoolPercent * 100)?.toFixed(2)}%</td>
        ) : (
          <td style={{ textAlign: "center" }}>-</td>
        )}
        {this.props.totalLicenseRevenue ? (
          <td>{currencyFormatter(this.props.totalLicenseRevenue / 100)}</td>
        ) : (
          <td style={{ textAlign: "center" }}>-</td>
        )}
        {this.props.licenseRevenueWeight ? (
          <td>{(this.props.licenseRevenueWeight * 100)?.toFixed(2)}%</td>
        ) : (
          <td style={{ textAlign: "center" }}>-</td>
        )}
        {this.props.totalApprovedTracks ? (
          <td>{this.props.totalApprovedTracks}</td>
        ) : (
          <td style={{ textAlign: "center" }}>-</td>
        )}
        {this.props.approvedTracksWeight ? (
          <td>{(this.props.approvedTracksWeight * 100)?.toFixed(2)}%</td>
        ) : (
          <td style={{ textAlign: "center" }}>-</td>
        )}
        {this.props.totalSeniorityDays ? (
          <td>{this.props.totalSeniorityDays}</td>
        ) : (
          <td style={{ textAlign: "center" }}>-</td>
        )}
        {this.props.seniorityWeight ? (
          <td>{(this.props.seniorityWeight * 100)?.toFixed(2)}%</td>
        ) : (
          <td style={{ textAlign: "center" }}>-</td>
        )}
        {this.props.isLoading ||
        (this.props.isLatestLoading && this.props.index === undefined) ? (
          <td>
            <CircularIndeterminant forceCenter size={18} />
          </td>
        ) : (
          <td>{this.props.status || "Needs Calculating"}</td>
        )}
        <td>
          <Tooltip
            arrow={false}
            open={this.state.isPayoutTippyOpen}
            interactive={true}
            position={"bottom-end"}
            trigger={"click"}
            useContext={true}
            // hideOnClick={true}
            html={
              <TopPayoutTooltip
                forMonth={this.props.forMonth}
                recalculatePayout={this.props.recalculatePayout}
                calculatePayout={this.props.calculatePayout}
                closeTippy={() => this.setState({ isPayoutTippyOpen: false })}
                index={this.props.index}
                handlePWModalOpen={this.props.handlePWModalOpen}
                payoutId={this.props.id}
                status={this.props.status}
                handlePWModalOpenForReapprove={
                  this.props.handlePWModalOpenForReapprove
                }
                handlePWModalOpenForUnapprove={
                  this.props.handlePWModalOpenForUnapprove
                }
                handlePWModalOpenForApproveAndDistribute={
                  this.props.handlePWModalOpenForApproveAndDistribute
                }
              ></TopPayoutTooltip>
            }
            allowHTML={true}
            onUntrigger={this.togglePayoutTippy}
            onRequestClose={this.togglePayoutTippy}
          >
            <button
              id={"timeFrameTippy"}
              class="text-dropdown mb-3 js-dropdown-toggle"
              data-tippy-html="#timeframe_sort"
              onClick={() =>
                this.props.isLoading ||
                (this.props.isLatestLoading && this.props.index === undefined)
                  ? () => {}
                  : this.togglePayoutTippy()
              }
            >
              <span class="icon-dots-vert"></span>
            </button>
          </Tooltip>
        </td>
      </tr>
    );
  }
}
export default PayoutRow;

import React, { Component } from "react";
import { UserConsumer } from "../../contexts/User";
import EditBlock from "./editBlock";
import PaymentCardOptions from "./paymentCardOptions";


class CardsAndAccounts extends Component {
    state = {
        isBlockOpen: false
    }

    toggleEdit = () => {
        if (this.state.isBlockOpen === true) {
            this.props.loadPaymentMethods();
        }
        this.setState({
            isBlockOpen: !this.state.isBlockOpen
        })
    }

    render() {
        return (
            <UserConsumer>
                {({ user, login, logout})=>(
                    this.state.isBlockOpen ?
                        <EditBlock
                            user={user}
                            toggleEdit={this.toggleEdit}
                            closeBlock={this.closeBlock}
                            paymentId={this.props.paymentId}
                            lastFour={this.props.lastFour}
                            cardName={this.props.cardName}
                            cardNick={this.props.cardNick}
                            exp={this.props.exp}
                            text="Edit Card Details"
                            icon_className='icon-plus'
                        />
                    :
                        <>
                            <div className="payment-card">
                                <div class="flex items-center mb-30 pr-50 lg:pr-80">
                                    <div class="text-label-lg leading-norma mr-10">
                                        { this.props.cardNick } {this.props.acctType }
                                    </div>
                                    {this.props.primary &&
                                        <span class="circle-icon-fill circle-icon-2xs cursor-default bg-primary">
                                            <span class="icon-star-fill text-white"></span>
                                        </span>
                                    }
                                </div>
                                <div className="payment-card-logo">
                                    {this.props.logo ?
                                        <img alt="card-logo" src={this.props.logo}/>:
                                        <i class="far fa-credit-card"></i>
                                    }
                                </div>
                                <div className="grid-row">
                                    {this.props.bank === true ?
                                        <>
                                            <div className="col mb-20 md:mb-30">
                                                <p className="text-label font-body text-xs font-medium mb-5">Account Number</p>
                                                <p className="text-h4 text-white m-0">
                                                    •••• ••••{this.props.lastFour}</p>
                                            </div>
                                            <div className="col mb-20 md:mb-30">
                                                <p className="text-label font-body text-xs font-medium mb-5">Routing Number</p>
                                                <p className="text-h4 text-white m-0">{this.props.routingNumber}</p>
                                            </div>
                                        </>
                                    :
                                        <>
                                            <div className="col w-full mb-20 md:mb-30">
                                                <p className="text-label font-body text-xs font-medium mb-5">Name</p>
                                                <p className="text-h4 text-white m-0">{this.props.cardName}</p>
                                            </div>
                                            <div className="col mb-20 md:mb-30">
                                                <p className="text-label font-body text-xs font-medium mb-5">Card Number</p>
                                                <p className="text-h4 text-white m-0">
                                                    •••• •••• •••• {this.props.lastFour}
                                                </p>
                                            </div>
                                            <div className="col mb-20 md:mb-30">
                                                <p className="text-label font-body text-xs font-medium mb-5">Expiration</p>
                                                <p className="text-h4 text-white m-0">{this.props.exp}</p>
                                            </div>
                                            {/* <div className="col mb-20 md:mb-30">
                                                <p className="text-label font-body text-xs font-medium mb-5">CVV</p>
                                                <p className="text-h4 text-white m-0">•••</p>
                                            </div> */}
                                        </>
                                    }
                                </div>

                                <PaymentCardOptions
                                    toggleEdit={this.toggleEdit}
                                    id={this.props.paymentId}
                                    loginLink={this.props.loginLink}
                                    removable={this.props.removable}>
                                </PaymentCardOptions>
                            </div>
                        </>
                )}
            </UserConsumer>
        )
    }

}
export default CardsAndAccounts

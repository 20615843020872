import React from "react";
import RoyaltyPaymentsQuarter from "./RoyaltyPaymentsQuarter";

const RoyaltyPaymentsQuarters = ({ contentIDPayoutsQuarters }) => {
  return contentIDPayoutsQuarters?.map((contentIDPayoutsQuarter) => {
    const { quarter } = contentIDPayoutsQuarter || {};
    return (
      <RoyaltyPaymentsQuarter key={quarter} contentIDPayoutsQuarter={contentIDPayoutsQuarter} />
    );
  });
};

export default RoyaltyPaymentsQuarters;

import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import customStyles from "../FormElements/customStyles";
import CircularIndeterminateButton from "../Loading/circularIndeterminantButton";
import { Error, Success } from "../Toast/index";
import api from "../utils/api";



const roleOptions = [
    { value: "user", label: "User" },
    { value: "artist", label: "Artist" },    
    { value: "manager", label: "Manager" },
];

export default function UserCreateForm() {
    const { control, register, handleSubmit, watch, setError, errors, reset } = useForm();
    const onSubmit = (data, e) => {
    	setisLoading(true);
    	setisDisabled(false);
    	
    	let emails = data.inviteEmails;
		if (emails) {
		    emails = emails.replace(/\s+/g, '').split(",");
		}
		let role = data.inviteRole.value;

		api.inviteUsers(emails, role)
            .then(res=>{
                
                //User(s) successfully invited
                if (res.data === 'success') {
                    setisLoading(false);
                    setisDisabled(true);
                    setuserSaveText("Invites Sent");
                    Success({message: "Invites Sent"})
                    e.target.reset();
                    setisDisabled(false);
                    setuserSaveText("Send Invites");
                }
                else if (res.data === "ER_DUP_ENTRY") {
	                setisLoading(false);
	         	    setError("inviteEmails", {
	         	    	type: "manual", 
	         	    	message: "Email address already exists."
	         	    });
	                setisDisabled(false);
                    Error({message: "Email address already exists."})
                }
                
            })
            .catch(err => {
                
                setisLoading(false);
                setuserSaveText("Invites Not Sent");
                setisDisabled(true);
                setFormError(err.message);
                Error({message: err.message})
            })
    };

    const [userSaveText, setuserSaveText] = useState('Save User');
    const [isLoading, setisLoading] = useState(false);
    const [isDisabled, setisDisabled] = useState(false);
    const [formError, setFormError] = useState(false);
    const [shouldBlockNavigation, setshouldBlockNavigation] = useState(true);

    return (
		<form onSubmit={handleSubmit(onSubmit)} class="ds-form">
	        <div class="form-section">                            
	            <div class="mb-30">
	                <h3 class="text-label-lg mb-5">User Details</h3>
	                
	                {formError &&
	                    <div className="error-message">
	                        
	                        {formError}
	                    </div>
	                }
	            </div>
	            <div class="grid-row-sm">    
	                <div class="col w-full md:w-8/12">
	                    <div class="input-group-sm input-inline">
	                        <input 
	                            type="input" 
	                            name="inviteEmails"
	                            id="invite_email" 
	                            class="form-input" 
	                            placeholder="Email Address" 
	                            autocomplete="off" 
	                            style={{backgroundImage: "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAkCAYAAADo6zjiAAAAAXNSR0IArs4c6QAAAbNJREFUWAntV8FqwkAQnaymUkpChB7tKSfxWCie/Yb+gbdeCqGf0YsQ+hU95QNyDoWCF/HkqdeiIaEUqyZ1ArvodrOHxanQOiCzO28y781skKwFW3scPV1/febP69XqarNeNTB2KGs07U3Ttt/Ozp3bh/u7V7muheQf6ftLUWyYDB5yz1ijuPAub2QRDDunJsdGkAO55KYYjl0OUu1VXOzQZ64Tr+IiPXedGI79bQHdbheCIAD0dUY6gV6vB67rAvo6IxVgWVbFy71KBKkAFaEc2xPQarXA931ot9tyHphiPwpJgSbfe54Hw+EQHMfZ/msVEEURjMfjCjbFeG2dFxPo9/sVOSYzxmAwGIjnTDFRQLMQAjQ5pJAQkCQJ5HlekeERxHEsiE0xUUCzEO9AmqYQhiF0Oh2Yz+ewWCzEY6aYKKBZCAGYs1wuYTabKdNNMWWxnaA4gp3Yry5JBZRlWTXDvaozUgGTyQSyLAP0dbb3DtQlmcan0yngT2ekE9ARc+z4AvC7nauh9iouhpcGamJeX8XF8MaClwaeROWRA7nk+tUnyzGvZrKg0/40gdME/t8EvgG0/NOS6v9NHQAAAABJRU5ErkJggg==')", backgroundRepeat: "no-repeat", backgroundAttachment: "scroll", backgroundSize: "16px 18px", backgroundPosition: "98% 50%"}} 
	                            ref={register({ 
	                            		pattern: /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/g,
	                            		required: "Email is required."
	                            	})}
	                        />
	                        <label class="input-label" for="update_email">Email Address</label>
	                        {errors.userEmail && <span className="error-message">{errors.userEmail.message}</span>}
	                    </div>
	                </div>
	                
	                <div class="col w-full md:w-4/12">
	                    <div class="input-group-sm input-inline">
	                        <label class="input-label" for="invite_role">Role</label>
	                        <Controller
	                            name="inviteRole"
	                            as={Select}
	                            styles={customStyles}
	                            className="select-container" 
	                            classNamePrefix="select"
	                            placeholder="Role"
	                            options={roleOptions}
	                            id="invite_role"
	                            control={control}
	                            rules={{ required: "Role is required." }}
	                        />
	                        {errors.userRole && <span className="error-message">{errors.userRole.message}</span>}
	                    </div>
	                </div>

	            </div>
	                <div class="input-group-sm">
	                    <button
	                    	name="submit"
	                        disabled={(isDisabled)? "disabled" : ""}
	                        class={`btn ${isDisabled ? 'btn-disabled' : 'btn-primary'} form-btn w-full`} 
	                        type="submit">
	                            {isLoading ? <CircularIndeterminateButton  size={45}  thickness={5.5}/> : userSaveText}
	                        </button>
	                </div>
	        </div>
	    </form>
	)
}
import React, { Component } from "react";
import { splitFormProps, useField, useForm } from "react-form";
import CardArtUpload from "../Dropzone/collectionCardUpload";
import CoverArtUpload from "../Dropzone/collectionCoverUpload";
import CircularIndeterminantButton from "../Loading/circularIndeterminantButton";
import { Error, Success } from "../Toast";
import api from "../utils/api";

let nameSet = false;
const NameField = React.forwardRef((props,ref) =>{
    const [field, fieldOptions, rest] = splitFormProps(props)

    const {
        meta: {error, isTouched, isValidating },
        getInputProps,
        setValue
    } = useField(field, fieldOptions);
    if(getInputProps().value !== props.name && !nameSet){
      setValue(props.name)
      nameSet = true
    }

    // setValue(props.name)
    // let {value, onChange, }
    return(
          <>
            <input {...getInputProps()}  id={"collectionName"} class="form-input" placeholder={"Collection Name"}  name="collectionName"  defaultValue={props.name}
        />
        {props.children || ''}
                {isValidating ?(
                    <em>Validating...</em>
                ) : isTouched && error ?(
                    <em className="error-message">{error}</em>
                ): null}
          </>
    )
})
let descriptionSet = false;
const DescriptionField = React.forwardRef((props,ref) =>{
  const [field, fieldOptions, rest] = splitFormProps(props)

  const {
      meta: {error, isTouched, isValidating },
      getInputProps,
      setValue
  } = useField(field, fieldOptions);
  if(getInputProps().value !== props.description && !descriptionSet){
    setValue(props.description)
    descriptionSet = true
  }

  return(
        <>
          <textarea {...getInputProps()} maxLength={225} style={{height: "auto", paddingTop: "1.6rem"}}  id="description" name="description" className="form-input" placeholder="Description" defaultValue={props.description}  rows={4}/>
              {isValidating ?(
                  <em>Validating...</em>
              ) : isTouched && error ?(
                  <em className="error-message">{error}</em>
              ): null}
              <label className="input-label" htmlFor="description">Description</label>
              <div style={{float: "right"}}>
                <span>{getInputProps().value?.length || 0}</span>
                <span>/225</span>
              </div>
        </>
  )
})
const CardField = React.forwardRef((props,ref) =>{
  const [field, fieldOptions, rest] = splitFormProps(props)

  const {
      meta: {error, isTouched, isValidating },
      getInputProps,
      setValue,
      runValidation
  } = useField(field, fieldOptions);

  return(
        <>
          <CardArtUpload onChange={props.setCardArtState} coverArt={props.cardArt} coverArtError={isValidating ?("Validating...") :  error ?(error): null} removeCoverArt={props.removeCardArt} validate={runValidation}
              initialCoverArt={props.initialArt}  openPopout={props.openPopout} isCropUtilityOpen={props.isCropUtilityOpen} handleCropUtilityClose={props.handleCropUtilityClose} />

        </>
  )
})
const CoverField = React.forwardRef((props,ref) =>{
  const [field, fieldOptions, rest] = splitFormProps(props)

  const {
      meta: {error, isTouched, isValidating },
      getInputProps,
      setValue,
      runValidation
  } = useField(field, fieldOptions);
  return(
        <>
          <CoverArtUpload onChange={props.setCoverArtState} coverArt={props.coverArt} coverArtError={isValidating ?("Validating...") : isTouched && error ?(error): null} removeCoverArt={props.removeCoverArt} validate={runValidation}
              initialCoverArt={props.initialArt}          />

        </>
  )
})
const validateName = (value, instance, props)=>{
  if(value?.length < 3){
    return "Name must be at least 3 characters"
  }
  if(value?.length > 28){
    return "Name must be at less than 28 characters"
  }
  else if(value === undefined){
    return "Name required"
  }
  return false
}
const validateDescription = (value, instance, props)=>{
  if(value?.length < 3){
    return "Description must be at least 3 characters"
  }
  else if(value === undefined){
    return "Description required"
  }
  return false
}
const validateCard = (value, instance, props)=>{
  if(typeof value === "string"){
    if(!props.initialCardArtState){
      return "File must be an image in a 1x1 ratio!"
    }
    else{
      Error({message:"File must be an image in a 1x1 ratio!" })
    }
  }
  else if(!Array.isArray(value) && !props.initialCardArtState){
    return "Card art required"
  }
  else{
    return false
  }
}
const validateCover = (value, instance, props)=>{

  if(!Array.isArray(value) && !props.initialCoverArtState){
    return "Cover art required"
  }
  else{
    return false
  }
}
const MyForm = (props)=>{

  const {
    Form,
    meta: { isSubmitting, canSubmit }
  } = useForm({
    onSubmit: async (values, instance)=> {



      let collectionBody={

      }
      if(props.name !==values.name){
        collectionBody.name = values.name
      }
      if(props.description !== values.description){
        collectionBody.description = values.description
      }
      if(Object.keys(collectionBody).length === 0 && (!props.coverArtUpdated && !props.cardArtUpdated)){
        return
      }
      else if ((props.coverArtUpdated || props.cardArtUpdated) && Object.keys(collectionBody).length === 0 ){
        if(props.coverArtUpdated && props.cardArtUpdated){
          const coverData = new FormData()
          coverData.append('file', props.coverArt[0])
          props.setLoading(true)
          let coverArtRes
          try{
            coverArtRes = await api.updateCollectionCoverArt(props.id, coverData)
          }
          catch(error){
            props.setLoading(false)
            Error({message: "Error updating Cover Art"})
          }
          const cardData = new FormData()
          cardData.append('file', props.cardArt[0])
          let cardArtRes
          try{
            cardArtRes = await api.updateCollectionCardArt(props.id, cardData)
          }
          catch(error){
            props.setLoading(false)
            Error({message: "Error updating Card Art"})
          }
          if(coverArtRes.data.success && cardArtRes.data.success){
            props.setLoading(false)
            window.location.reload()
          }
        }
        if(props.coverArtUpdated){
          props.setLoading(true)
          const coverData = new FormData()
          coverData.append('file', props.coverArt[0])
          let coverArtRes
          try{
            coverArtRes = await api.updateCollectionCoverArt(props.id, coverData)
          }
          catch(error){
            props.setLoading(false)
            Error({message: "Error updating Cover Art"})
          }
          if(coverArtRes.data.success){
            props.setLoading(false)
            window.location.reload()
          }
        }
        if(props.cardArtUpdated){
          props.setLoading(true)
          const cardData = new FormData()
          cardData.append('file', props.cardArt[0])
          let cardArtRes
          try{
            cardArtRes = await api.updateCollectionCardArt(props.id, cardData)
          }
          catch(error){
            props.setLoading(false)
            Error({message: "Error updating Card Art"})
          }
          if(cardArtRes.data.success){
            props.setLoading(false)
            window.location.reload()
          }
        }
      }
      else{
        props.setLoading(true)
        let res
        try{
          res = await api.updateCollection(props.id, collectionBody)
        }
        catch(error){
          props.setLoading(false)
          if(error.response?.status === 406){
            Error({message: "Collection name taken"})
          }
          else{
            Error({message: "Error updating collection"})
          }
        }

        if(props.coverArtUpdated){
          const coverData = new FormData()
          coverData.append('file', props.coverArt[0])
          let coverArtRes = await api.updateCollectionCoverArt(props.id, coverData)
        }
        if(props.cardArtUpdated){
          const cardData = new FormData()
          cardData.append('file', props.cardArt[0])
          let cardArtRes = await api.updateCollectionCardArt(props.id, cardData)
        }
        if(res){
          if(res.data.success){
            props.setLoading(false)
            Success({message: "Collection Updated"})
            nameSet = false
            descriptionSet = false
            if(props.coverArtUpdated || props.cardArtUpdated){
              window.location.reload()
            }
            props.updateCollection(props.id, collectionBody)
            props.handleClose()
          }
        }
      }
    }
  })
  return(
                <Form action="" className="ds-form">
                    {/* {# Title #} */}
                    {/* <div className="input-group-sm input-inline">
                        <input id="project_title" name="newCollectionName" className="form-input" placeholder="Collection Name" value={this.props.newCollectionName} onChange={this.props.handleInputChange} required/>
                        <label className="input-label" htmlFor="newCollectionName" >Collection Name</label>
                        <div className="error-message">{this.props.newCollectionNameError}</div>
                    </div> */}
                    <div className="input-group-sm input-inline">
                      <NameField field="name" {...props} validate={(value, instance)=>validateName(value, instance, {...props})}>
                        <label className="input-label" htmlFor="newCollectionName" >Collection Name</label>
                      </NameField>
                      <div className="my-10 text-sm">
                        Make sure to choose a succinct Collection Name that will fit on a single line.
                      </div>
                    </div>
                    {/* {# Client #} */}
                    <div className="input-group-sm input-inline">
                        {/* <textarea maxLength={225} style={{height: "auto"}}  id="newCollectionDescription" name="newCollectionDescription" className="form-input" placeholder="Description (Optional)" value={props.newCollectionDetail} onChange={props.handleInputChange} rows={4}/> */}
                        <DescriptionField field="description" {...props} validate={(value, instance)=>validateDescription(value, instance, {...props})}/>

                    </div>
                    <div className="input-group">
                        <CardField field="cardField" initialArt={props.initialCardArtState} {...props} validate={(value, instance)=>validateCard(props.cardArt, instance, {...props})}  openPopout={props.openPopout} isCropUtilityOpen={props.isCropUtilityOpen}
                                    handleCropUtilityClose={props.handleCropUtilityClose}/>
                    </div>
                    <div className="input-group">
                        <CoverField field="coverField" initialArt={props.initialCoverArtState} {...props} validate={(value, instance)=>validateCover(value ||props.coverArt, instance, {...props})}/>
                    </div>

                    <div className="input-group pt-20">
                        <div className="grid-row-sm">
                            <div className="col w-1/2 mb-20 sm:mb-0">
                                <button onClick={props.handleClose} className="btn btn-stroke form-btn w-full" type="button" aria-label="Close Modal" data-micromodal-close="create_project_modal">Cancel</button>
                            </div>
                            <div className="col w-1/2">
                                <button type="submit" disabled={!canSubmit || props.isLoading} className="btn btn-primary form-btn w-full">{props.isLoading ? <CircularIndeterminantButton thickness={5.5} />: "Update Collection"}</button>
                            </div>
                        </div>
                    </div>

                </Form>

  )
}
class EditCollectionModal extends Component{
    setLoading =(isLoading)=>{

      this.setState({isLoading: isLoading})
    }
    state={
      isCropUtilityOpen: false
    }
    componentDidMount = ()=>{
      this.setState({
        initialCardArtState:[this.props.cardArt],
        initialCoverArtState:[this.props.coverArt],
      })
    }
    setCardArtState = (file, validateCB)=>{

      if(file.error){
          this.setState({cardArt: "File must be an image in a 1x1 ratio!"},()=> validateCB())
      }
      else{
          this.setState({
              cardArt: file.length > 0 ? file: null,
              cardArtPreview: file.length > 0 ? URL.createObjectURL(file[0]): null,
              cardArtError: null,
              cardArtUpdated: file.length > 0 ? true : false
          },()=> validateCB())
      }
    }
    removeCardArt =(event)=>{
      // event.preventDefault();
                  this.setState({
                      cardArt: null,
                      cardArtPreview: null,
                      cardArtError: null,
                      cardArtUpdated: false,
                  })
    }
    setCoverArtState = (file, validateCB)=>{

        if(file.error){
            this.setState({coverArtError: "File must be an image in a 1x1 ratio!"})
        }
        else{
            this.setState({
                coverArt: file.length > 0 ? file: null,
                coverArtPreview: file.length > 0 ? URL.createObjectURL(file[0]): null,
                coverArtError: null,
                coverArtUpdated: file.length > 0 ? true : false
            }, ()=>validateCB())
        }
    }
    removeCoverArt =(event)=>{
        // event.preventDefault();
                    this.setState({
                        coverArt: null,
                        coverArtPreview: null,
                        coverArtError: null,
                        coverArtUpdated: false,
                    })
    }
    handleCropUtilityClose = (cropSaved)=>{
      this.setState({isCropUtilityOpen: false})

      if(cropSaved){
          cropSaved()
      }
      else{
      }
  }
    render(){
        return(

            <React.Fragment>
            <header className="modal-header">
                <h2 id="create_project_title" className="modal-title">Edit Collection</h2>
                <button aria-label="Close Modal" data-micromodal-close="create_project_modal" className="modal-close" type="button" onClick={this.props.handleClose}></button>
            </header>

            {/* {# Modal Content #} */}
            <div id="create_project_content" className="modal-content">
                <MyForm {...this.props} isLoading={this.state.isLoading} setLoading={this.setLoading} initialCardArtState={this.state.initialCardArtState} initialCoverArtState={this.state.initialCoverArtState}
                setCardArtState={this.setCardArtState} cardArt={this.state.cardArt} setCoverArtState={this.setCoverArtState} coverArt={this.state.coverArt}
                removeCardArt={this.removeCardArt} removeCoverArt={this.removeCoverArt} coverArtUpdated={this.state.coverArtUpdated} cardArtUpdated={this.state.cardArtUpdated}
                openPopout={()=>{this.setState({isCropUtilityOpen: true});}} isCropUtilityOpen={this.state.isCropUtilityOpen} handleCropUtilityClose={this.handleCropUtilityClose}/>
            </div>
            {/* {# End Modal Content #} */}

        {/* </div> */}
        {/* {# End Modal Container #} */}
    {/* </div> */}
    {/* {# End Modal Overlay #} */}
{/* </div> */}
</React.Fragment>
        )
    }
}
export default EditCollectionModal

import buildBaseURL from "./buildBaseURL";

const buildTrackURL = (trackURL) => {
  const baseURL = buildBaseURL();

  let tracksPathPrefix = 'tracks';

  const fullURL = baseURL + '/' + tracksPathPrefix + '/' + trackURL;

  return fullURL;
}
export default buildTrackURL;

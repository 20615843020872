import React from 'react'

const BestDeal = (props) => {

  const { children = "Best Deal!" } = props || {}

  return (
    <span style={{
      backgroundColor: "rgb(235 25 45/var(--tw-bg-opacity))",
      fontSize: "11px",
      lineHeight: "1.4rem",
      margin: "-0 5px",
      padding: "3px",
      verticalAlign: "top"
    }}>
      {children}
    </span>
  )
}

export default BestDeal

import React, { Component } from "react";
import { splitFormProps, useField, useForm } from "react-form";
import Select, { components } from "react-select";
// import { val } from "objection";
// import { select } from "@tailwindcss/custom-forms/src/defaultOptions";
import { ProjectsContext } from "../../ProjectsContext";
import CardArtUpload from "../Dropzone/collectionCardUpload";
import CoverArtUpload from "../Dropzone/collectionCoverUpload";
import customStyles from "../FormElements/customStyles";
import CircularIndeterminantButton from "../Loading/circularIndeterminantButton";
import { Error, Success } from "../Toast/index";
import api from "../utils/api";
const NameField = React.forwardRef((props,ref) =>{
    const [field, fieldOptions, rest] = splitFormProps(props)

    const {
        meta: {error, isTouched, isValidating },
        getInputProps,
        setValue
    } = useField(field, fieldOptions);
    return(
          <>
            <input onChange={(e) => props.handleInputChange(e, setValue)} id={"newCollectionName"} class="form-input" placeholder={"Collection Name"} value={props.newCollectionName} name="newCollectionName" />
            {props.children || ''}

                {isValidating ?(
                    <em>Validating...</em>
                ) : isTouched && error ?(
                    <em className="error-message">{error}</em>
                ): null}
          </>
    )
})
const DescriptionField = React.forwardRef((props,ref) =>{
  const [field, fieldOptions, rest] = splitFormProps(props)

  const {
      meta: {error, isTouched, isValidating },
      getInputProps,
      setValue
  } = useField(field, fieldOptions);
  return(
        <>
          <textarea maxLength={225} style={{height: "auto" , paddingTop: "1.6rem"}}  id="newCollectionDescription" name="newCollectionDescription" className="form-input" placeholder="Description (Optional)" value={props.newCollectionDetail} onChange={(e)=>props.handleInputChange(e,setValue)} rows={4}/>
              {isValidating ?(
                  <em>Validating...</em>
              ) : isTouched && error ?(
                  <em className="error-message">{error}</em>
              ): null}
        </>
  )
})
const CardField = React.forwardRef((props,ref) =>{
  const [field, fieldOptions, rest] = splitFormProps(props)

  const {
      meta: {error, isTouched, isValidating },
      getInputProps,
      setValue,
      runValidation
  } = useField(field, fieldOptions);

  return(
        <>
          <CardArtUpload isCropUtilityOpen={props.isCropUtilityOpen} openPopout={props.handleCropUtilityModalOpen} handleCropUtilityClose={props.handleCropUtilityModalClose} onChange={props.setCardArtState} coverArt={props.cardArt} coverArtError={isValidating ?("Validating...") :  error ?(error): null} removeCoverArt={props.removeCardArt} validate={runValidation}
                        />

        </>
  )
})
const CoverField = React.forwardRef((props,ref) =>{
  const [field, fieldOptions, rest] = splitFormProps(props)

  const {
      meta: {error, isTouched, isValidating },
      getInputProps,
      setValue,
      runValidation
  } = useField(field, fieldOptions);
  return(
        <>
          <CoverArtUpload onChange={props.setCoverArtState} coverArt={props.coverArt} coverArtError={isValidating ?("Validating...") : isTouched && error ?(error): null} removeCoverArt={props.removeCoverArt} validate={runValidation}
                        />

        </>
  )
})
const validateName = (value, instance, props)=>{
  if(value?.length < 3){
    return "Name must be at least 3 characters"
  }
  if(value?.length > 28){
    return "Name must be at less than 28 characters"
  }
  else if(value === undefined){
    return "Name required"
  }
  return false
}
const validateDescription = (value, instance, props)=>{
  if(value?.length < 3){
    return "Description must be at least 3 characters"
  }
  else if(value === undefined){
    return "Description required"
  }
  return false
}
const validateCard = (value, instance, props)=>{

  if(typeof value === "string"){
    return "File must be an image in a 1x1 ratio!"
  }
  else if(!Array.isArray(value)){
    return "Card art required"
  }
  else{
    return false
  }
}
const validateCover = (value, instance, props)=>{




  if(!Array.isArray(value)){
    return "Cover art required"
  }
  else{
    return false
  }
}
const MyForm = (props)=>{

  const {
    Form,
    meta: { isSubmitting, canSubmit }
  } = useForm({
    onSubmit:()=> props.onSubmit(props.trackId, props.handleClose)
  })
  return(
                <Form action="" className="ds-form">
                    {/* {# Title #} */}
                    {/* <div className="input-group-sm input-inline">
                        <input id="project_title" name="newCollectionName" className="form-input" placeholder="Collection Name" value={this.props.newCollectionName} onChange={this.props.handleInputChange} required/>
                        <label className="input-label" htmlFor="newCollectionName" >Collection Name</label>
                        <div className="error-message">{this.props.newCollectionNameError}</div>
                    </div> */}
                    <div className="input-group-sm input-inline">
                      <NameField
                        field="collectionName"
                        {...props}
                        validate={(value, instance)=>validateName(value, instance, {...props})}
                      >
                        <label className="input-label" htmlFor="newCollectionName" >Collection Name</label>
                      </NameField>
                      <div className="my-10 text-sm">
                        Make sure to choose a succinct Collection Name that will fit on a single line.
                      </div>
                    </div>
                    {/* {# Client #} */}
                    <div className="input-group-sm input-inline">
                        {/* <textarea maxLength={225} style={{height: "auto"}}  id="newCollectionDescription" name="newCollectionDescription" className="form-input" placeholder="Description (Optional)" value={props.newCollectionDetail} onChange={props.handleInputChange} rows={4}/> */}
                        <DescriptionField field="collectionDescription" {...props} validate={(value, instance)=>validateDescription(value, instance, {...props})}/>
                        <label className="input-label" htmlFor="newCollectionDescription">Description</label>
                        <div style={{float: "right"}}>
                          <span>{props.newCollectionDescription?.length || 0}</span>
                          <span>/225</span>
                        </div>
                    </div>
                    <div className="input-group">
                        <CardField field="cardField" {...props} validate={(value, instance)=>validateCard(props.cardArt, instance, {...props})}/>
                    </div>
                    <div className="input-group">
                        <CoverField field="coverField" {...props} validate={(value, instance)=>validateCover(value ||props.coverArt, instance, {...props})}/>
                    </div>

                    <div className="input-group pt-20">
                        <div className="grid-row-sm">
                            <div className="col w-1/2 mb-20 sm:mb-0">
                                <button onClick={props.handleClose} className="btn btn-stroke form-btn w-full" type="button" aria-label="Close Modal" data-micromodal-close="create_project_modal">Cancel</button>
                            </div>
                            <div className="col w-1/2">
                  <button type="submit" disabled={!canSubmit || props.isLoading} className="btn btn-primary form-btn w-full">{props.isLoading ? <CircularIndeterminantButton thickness={5.5}/>: "Add & Create"}</button>
                            </div>
                        </div>
                    </div>

                </Form>

  )
}

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};
class AddToCollection extends Component{
    state = {
        projectTypes: [{label: "Commercial", value: 1}, {label: "Movie", value: 2}],
        myProjects: [],
        sharedProjects: [],
        projectsOptions: [],
        currentProject: [],
        sceneOptions: [],
        newSceneTitle: "Scene 1",
        newProjectTitle: ""
    }
    handleCropUtilityModalOpen=()=>{
      this.setState({isCropUtilityOpen: true})
    }
    handleCropUtilityModalClose=()=>{
      this.setState({isCropUtilityOpen: false})
    }
    addToProject = (handleClose) => {
        if(this.state.newProject){
          if(this.state.newProjectType && !this.state.newProjectNameError && !this.state.newProjectSceneError){
            let existingProjectNames = this.state.myProjects.map(project=>project.name)
            let isNameTaken = existingProjectNames.filter(name=>name===this.state.newProjectTitle).length > 0
            let errors
            if(isNameTaken){
              this.setState({newProjectNameError: "Project name already used. Please choose a different name"})
              errors = true
            }
            else if(this.state.newProjectTitle.length < 1){
              this.setState({newProjectNameError: "Project name required"})
              errors = true
            }
            if(this.state.newSceneTitle.length < 1){
              this.setState({newProjectSceneError: "Scene name required"})
              errors = true
            }

            if(errors){
               return true
            }
            let projectBody = {
              name: this.state.newProjectTitle,
              client: this.state.newProjectClient,
              projectType: this.state.newProjectType.value,
              sceneName: this.state.newSceneTitle,
            }
            api.addTrackToNewProject(projectBody, this.props.trackId)
              .then(res=>{
                if(res.data.success){
                  Success({message: `${this.props.trackTitle} added to ${this.state.newProjectTitle}`})
                  handleClose()
                  document.cookie = `latestProject=${res.data.project.id}; path=/`
                  document.cookie = `latestScene=${res.data.project.scenes[0].id}; path=/`
                }
                else{
                  Error({message: "Error adding track!"})
                }
              })
              .catch(error=>{
                Error({message: "Error adding track!"})
              })
          }
          else{
            Error({message:"An error occurred!", onClose: this.errorToast})
            if(!this.state.newProjectType){
                this.setState({projectTypeError: "Please select a project type"})
            }
            if(!this.state.newProjectTitle){
              this.setState({newProjectNameError: "Project name required"})
            }
            if(!this.state.newSceneTitle){
              this.setState({newProjectSceneError: "Scene name required"})
            }
        }
        }
        else{
          if(this.state.newScene){
            let sceneBody = {
              name: this.state.newSceneTitle
            }
            api.addSceneToProject(this.state.currentProject.value, sceneBody)
              .then(res=>{
                if(res.data.success){
                    this.context.updateLatest(this.state.currentProject.value)
                    let scenes = this.state.sceneOptions
                    scenes.push({label: res.data.newScene.name, value: res.data.newScene.id})
                    this.setState({sceneOptions: scenes})
                    api.addTrackToExistingProject(this.state.currentProject.value, res.data.newScene.id, this.props.trackId)
                    .then(res=>{

                      if(res.data.success){
                        Success({message: `${this.props.trackTitle} added to ${this.state.currentProject.label}`})
                        handleClose()
                        document.cookie = `latestProject=${this.state.currentProject.value}; path=/`
                        document.cookie = `latestScene=${this.state.currentScene.value}; path=/`
                      }
                      else{
                        Error({message: "Error adding track!"})
                      }
                    })
                    .catch(error=>{
                      if(error.response.status === 406){
                        Error({message: "Track already in scene"})
                      }
                      else{
                        Error({message: "Error adding track!"})
                      }
                    })
                }
                else{
                    Error({message:"An error occurred!", onClose: this.errorToast})
                }
              })
          .catch(error=>{

              Error({message:"An error occurred!", onClose: this.errorToast})
          })
          }
          else{
            api.addTrackToExistingProject(this.state.currentProject.value, this.state.currentScene.value, this.props.trackId)
              .then(res=>{

                if(res.data.success){
                  this.context.updateLatest(this.state.currentProject.value)
                  Success({message: `${this.props.trackTitle} added to ${this.state.currentProject.label}`})
                  handleClose()
                  document.cookie = `latestProject=${this.state.currentProject.value}; path=/`
                  document.cookie = `latestScene=${this.state.currentScene.value}; path=/`
                }
                else{
                  Error({message: "Error adding track!"})
                }
              })
              .catch(error=>{
                if(error.response.status === 406){
                  Error({message: "Track already in scene"})
                }
                else{
                  Error({message: "Error adding track!"})
                }
              })
            }
        }
        // Error({message: "Not ready yet."})
        // this.setState({showTrackTippy: false})
    }
    addTrack = ()=>{
      this.setState({isLoading: true}, ()=>{
          api.addTrackToExistingCollection(this.state.selectedCollection.value, this.props.trackId)
          .then(res=>{
            this.setState({isLoading: false})

            document.cookie = `latestCollectionValue=${this.state.selectedCollection.value}; path=/`
            document.cookie = `latestCollectionLabel=${this.state.selectedCollection.label}; path=/`
            Success({message: `${this.props.trackTitle} added to ${this.state.selectedCollection.label}`})
            this.props.handleClose()
          })
          .catch(error=>{
            this.setState({isLoading: false})
            if(error.response?.status === 406){
              Error({message: "Track already in collection!"})
            }
            else{
              Error({message: "Error adding track!"})
            }
          })
        })
    }
    handleCollectionChange= (selectedCollection)=>{
      if(selectedCollection){
        this.setState({selectedCollection: selectedCollection, projectTypeError: ""})
      }
    }
    componentDidMount = ()=>{
        this.props.toggleTrackTippy()
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.length ? decodedCookie.split(';') : [];
        let latestCollectionValue = ca.filter(cookieItem => cookieItem.indexOf('latestCollectionValue') !== -1)
        let latestCollectionLabel = ca.filter(cookieItem => cookieItem.indexOf('latestCollectionLabel') !== -1)
        if(latestCollectionValue?.length >0 &&  latestCollectionLabel?.length >0){
          let parsedLCV = latestCollectionValue[0].substring(latestCollectionValue[0].indexOf('=') + 1)
          let parsedLCL = latestCollectionLabel[0].substring(latestCollectionLabel[0].indexOf('=') + 1)

          this.setState({selectedCollection:{label: parsedLCL, value: parsedLCV}})
        }

        api.getAllAdminCollections()
        .then(res=>{

          if(res.data.collections?.length > 0){
            let collections = res.data.collections
            let collectionOptions =[]
            collections.forEach(collection=>{
              collectionOptions.push({label: collection.name, value: collection.id})
            })

            this.setState({collectionOptions: collectionOptions})
          }
        })
        // this.setState(this.context)
    }

    render(){
        return(
                this.props.newCollection ?
                 <div className="input-group-sm">
                 <div className="grid-row-xs">
                  <MyForm {...this.props} handleCropUtilityModalOpen={this.handleCropUtilityModalOpen} handleCropUtilityModalClose={this.handleCropUtilityModalClose} isCropUtilityOpen={this.state.isCropUtilityOpen} />
                  </div>
                  </div>:

                <form action="" className="ds-form">
                  <div className="input-group-sm">
                    <div className="grid-row-xs">
                        <div className="col flex-1">
                            {/* {# Existing Project Dropdown #} */}
                              <div className="block">
                                  <div className="input-inline">
                                      <label className="input-label" htmlFor="existing_collection">Choose Existing Collection</label>
                                      <Select
                                          name={"existingProject"}
                                          id="existing_collection"
                                          placeholder="Choose Existing Collection"
                                          isSearchable={false}
                                          styles={customStyles}
                                          options={this.state.collectionOptions}
                                          onChange={(e) => this.handleCollectionChange(e)}
                                          value={this.state.selectedCollection}
                                          className="select-container"
                                          classNamePrefix="select"
                                          components={{
                                            ValueContainer: CustomValueContainer
                                          }}
                                          menuPortalTarget={document.body}
                                      />
                                  </div>
                              </div>
                        </div>
                      </div>
                    </div>
                    <div className="input-group pt-20">
                        <div className="grid-row-sm">
                            <div className="col w-1/2 mb-20 sm:mb-0">
                                <button disabled={this.props.isLoading} onClick={this.props.handleClose} className="btn btn-stroke form-btn w-full" type="button" aria-label="Close Modal" data-micromodal-close="create_project_modal">Cancel</button>
                            </div>
                            <div className="col w-1/2">
                                <button disabled={this.state.isLoading} onClick={(e)=>{e.preventDefault();this.addTrack()}} type="submit" className="btn btn-primary form-btn w-full">{this.state.isLoading ? <CircularIndeterminantButton thickness={5.5}/>:"Add"}</button>
                            </div>
                        </div>
                    </div>

                </form>

        )
    }
}
AddToCollection.contextType = ProjectsContext
export default AddToCollection

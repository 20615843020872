import React, { Component } from "react";
// import {NavLink} from "react-router-dom"
class AdminCollectionTolltip extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {

            }

    render() {
        return (

                <div className="dropdown-menu">
                    <div className="dropdown-group">
                            <a href="#" onClick={()=>{this.props.handleEditCollectionModalOpen();this.props.toggleCollectionTippy()}}>Edit Collection</a>
                            {this.props.publish !==undefined &&
                                <a href="#" onClick={()=>{this.props.publish(this.props.collectionId);this.props.toggleCollectionTippy()}}>Publish</a>
                            }   
                            {this.props.unpublish !==undefined &&
                                <a href="#" onClick={()=>{this.props.unpublish(this.props.collectionId);this.props.toggleCollectionTippy()}}>Unpublish</a>
                            }
                            {this.props.makeFeatured !==undefined &&
                                <a href="#" onClick={()=>{this.props.makeFeatured(this.props.collectionId);this.props.toggleCollectionTippy()}}>Make Featured</a>
                            }
                            {this.props.makeUnfeatured !==undefined &&
                                <a href="#" onClick={()=>{this.props.makeUnfeatured(this.props.collectionId);this.props.toggleCollectionTippy()}}>Make Unfeatured</a>
                            }      
                    </div>                
                 
                </div>

        )
    }

}
export default AdminCollectionTolltip
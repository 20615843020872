import React, { Component } from "react";
import { UserConsumer } from "../../contexts/User";
import Footer from '../Footer';
import Navbar from "../Navbar";


class ArtistDetailLayout extends Component {
  state = {
      navClass: ""
    }

  setCurrentNav = (updatedNav)=>{
    this.setState({currentNav: updatedNav})
  }

  componentDidMount = ()=>{
    let appMainEl = document.getElementsByClassName('app-main')[0]
    appMainEl.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount = ()=>{
      let appMainEl = document.getElementsByClassName('app-main')[0]
      appMainEl.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll =()=>{
      let appMainEl = document.getElementsByClassName('app-main')[0]
      let stickyNav = ""
      if (appMainEl.scrollTop > 200) {
          stickyNav = " navbar-sticky"
      }
      this.setState({
         navClass: stickyNav
      })
  }

  render() {
    return (
      <UserConsumer>
        {({ user, login, logout, currentNav, setCurrentNav})=>(
          <>
            <Navbar pathname={window.location.pathname} navClass={this.state.navClass}></Navbar>
            <div className='app-container has-transparent-nav has-hero-image'>
              <div className='app-main'>
                    {this.props.children}

                <Footer></Footer>
              </div>
            </div>
          </>
        )}
      </UserConsumer>
    )
  }
}

export default ArtistDetailLayout

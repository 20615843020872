import React, { Component } from "react";
import { UserConsumer } from "../../contexts/User";
import AppLayout from "../Layouts/appLayout";
import api from "../utils/api";

class StartStripeOnboard extends Component {

    state = {
       redirecting: true,
       message: "Loading..."
    }

    componentDidMount = ()=>{
        api.stripeOnboardStart()
            .then(res=>{
                if (res.data?.url) {
                    this.setState({
                        redirecting: true,
                        message: "Redirecting to Stripe..."
                    })
                    setTimeout(()=>{window.location.replace(res.data.url)}, 100)
                }
                else if (res.data?.url) {
                    this.setState({
                        redirecting: false,
                        message: "Your Stripe Connect account has already been created."
                    })
                }
                else {
                    this.setState({
                        redirecting: false,
                        message: "Could not create Stripe Onboarding Link."
                    })
                }

            })
    }


    render(){
        return(
        	<AppLayout>
	            <UserConsumer>
	                {({user, login, logout})=>(
	                    <div className="container">
                            <div className="view-header">
                                <h1 className="view-title">Stripe Account Setup</h1>
                            </div>
                            <p>{this.state.message}</p>
                        </div>
	                )}
	            </UserConsumer>
            </AppLayout>
        )
    }
}

export default StartStripeOnboard

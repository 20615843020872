import React, { Component } from "react";
import Slider from "react-slick";
import { PlayerContext } from "../../PlayerContext";
import "../Carousels/index.css";
import PublicCard from "../Collections/publicCard";
class FeaturedCollections extends Component{
    state = {

    }
    playTracks = (tracks, collectionId, idKey)=>{

        if((idKey?.indexOf("collectionListCard-" + collectionId) ||-1)!==-1){
            this.context.handlePlay()
        }
        else{
            if(this.context.isMainOpen || this.context.isDemoOpen){
                this.context.closeVideoMain()
                // this.context.unloadVideo(true)
                let playList = tracks.map(track=>track.trackId)
                setTimeout(()=>{
                    this.context.setPlaylist(playList)
                    this.context.loadTrackInMediaPlayer(playList[0], `artist-${this.context.loadedTracks[playList[0]]?.artistId}-album-${this.context.loadedTracks[playList[0]]?.albumId}-track-${playList[0]}-element-collectionListCard-${collectionId}`)
                    this.context.setAutoPlay(true)
                },50)

            }
            else{
                let playList = tracks.map(track=>track.trackId)

                this.context.setPlaylist(playList)
                this.context.loadTrackInMediaPlayer(playList[0], `artist-${this.context.loadedTracks[playList[0]]?.artistId}-album-${this.context.loadedTracks[playList[0]]?.albumId}-track-${playList[0]}-element-collectionListCard-${collectionId}`)
                this.context.setAutoPlay(true)
            }

        }
        // if(this.context.mediaPlayer.idKey?.indexOf(idKey) !==-1 || this.context.mediaPlayer.idKey === undefined){
        //     let playList = tracks
        //     playList.forEach((track, index)=>{
        //         playList[index].idKey = "collectionCard-" + this.state.collection?.id + "-trackId-" + track.trackId
        //     })
        //     this.context.setPlaylist(playList)
        //     this.context.loadTrackInMediaPlayer(playList[0].trackId, playList[0].idKey)
        //     this.context.setAutoPlay(true)
        //     // this.setState({playingCollection: collectionId})
        // }
        // else{
        //     this.context.handlePlay()
        // }

    }
    render(){

        const settings = {
            arrows: false,
            swipeToSlide: true,
            infinite: false,
            variableWidth: true,
            slidesToShow: 2,
            edgeFriction: '.05',
            adaptiveHeight: false,
          draggable: true,
          rows: 1,
        }
        return(
            <div className="featured-carousel">
                <div className="container">
                    <div className="grid-row-sm">
                        <div className="col w-full xl:w-1/3">
                            <div className="featured-carousel-header">
                                <div className="flex flex-col mb-30">
                                <h2 className="text-h2 mb-20">Discover Collections</h2>
                        <p className="text-h4 max-w-md mb-0">Discover and support independent artists while you find the perfect song for your project.</p>
                                </div>
                                <div className="mb-40"><a href="/collections" className="btn btn-primary">Browse Collections</a></div>
                                <div className="ui-control-arrows flex">
                                    <button onClick={(e)=>{e.preventDefault();this.slider.slickPrev()}}  className="circle-icon-border lg:circle-icon-lg icon-chevron-left mr-15"></button>
                                    <button onClick={(e)=>{e.preventDefault();this.slider.slickNext()}}  className="circle-icon-border lg:circle-icon-lg icon-chevron-right"></button>
                                </div>
                            </div>
                        </div>
                        <div className="col w-full xl:w-2/3">
                            <Slider ref={slider => (this.slider = slider)} {...settings} className="card-carousel">
                                {this.props.featuredCollections.map((collection, index)=>
                                    <div  key={"featuredCon-" + collection.id}  className="carousel-slide">
                                        <PublicCard idKey={"collectionCard-" + collection.id} key={'featuredCollectionCard-'+collection.id} {...collection} playTracks={this.playTracks}  isPlaying={this.state.playingCollection === collection.id}/>
                                    </div>
                                )}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
FeaturedCollections.contextType = PlayerContext
export default FeaturedCollections

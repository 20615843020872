import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Tooltip } from "react-tippy";
import SalesTransfersRow from "./SalesTransfersRow";
import usersAPI from "../../../api/users";
import LoadingContainer from "../../Loading/LoadingContainer";
import paginate from "../../../utils/paginate";
import SalesPagination from "../../Pagination/salesPagination";
import currencyFormatter from "../../../utils/currencyFormatter";

const SalesTransfers = () => {
  const history = useHistory();
  const currentPage = Number(history.location.pathname.split("/").pop()) || 1;
  const perPage = 12;
  const setCurrentPage = (pageNumber) => {
    if (pageNumber !== currentPage) {
      history.push(`/dashboard/sales/bonus-earnings/${pageNumber}`);
    }
  };
  const [isLoaded, setIsLoaded] = useState(true);
  const [transfers, setTransfers] = useState(null);
  const [currentPageTransfers, setCurrentPageTransfers] = useState(null);
  const [totalPaid, setTotalPaid] = useState(null);

  useEffect(() => {
    setIsLoaded(false);
    usersAPI.getTransfers({ currentPage, perPage }).then((res) => {
      console.log("getTransfers res", res);
      setTransfers(res?.data?.transfers);
      const paginatedTransfers = paginate(res?.data?.transfers, perPage) || [];
      const paginatedTransfersPage = paginatedTransfers[currentPage - 1];
      setCurrentPageTransfers(paginatedTransfersPage);
      const totalPaidFormatted = currencyFormatter(res?.data?.totalPaid / 100);
      setTotalPaid(totalPaidFormatted);
      setIsLoaded(true);
    });
  }, [currentPage]);

  return (
    <LoadingContainer isLoaded={isLoaded}>
      <div class="bg-gray-100 p-20 pb-10 lg:p-25 lg:pb-15 mb-40">
        {/* {# Table Header #} */}
        <div class="flex flex-col md:flex-row items-stretch justify-between bg-gray-100 mb-40">
          <div className="flex-1 flex items-top mb-15 md:mb-0">
            <h3 class="text-label-lg mr-10">
              Bonus Transfers
              <Tooltip
                useContext={true}
                animation="shift"
                arrow="true"
                position="top"
                html={
                  <>
                    <div className="price-tool-title mb-10">Bonus Earnings</div>
                    <div className="text-gray-400 price-tool-desc">
                      Any bonus earnings from contests or just thank yous from the Deep Sounds team{" "}
                    </div>
                  </>
                }
              >
                &nbsp;<span className="icon-help"></span>
              </Tooltip>
            </h3>
            <span className="flex-none text-xs mt-5">
              Showing
              {/* {perPage < licenseSales?.length ? perPage : licenseSales?.length} of */}{" "}
              {transfers?.length}
            </span>
          </div>
          {/* {# Summary #} */}
          <div class="flex-none flex flex-col sm:flex-row">
            {/* {# Payout Summary #} */}
            <div class="flex flex-col flex-end pr-30 lg:pr-60 leading-snug mb-30 md:mb-0">
              <span class="text-label-sm mb-3 text-right">Total Paid</span>
              <span class="text-h3 text-white font-display leading-tight text-right">
                {totalPaid}
              </span>
            </div>
          </div>

          {/* {# Sort #} */}
          <div class="flex-none pr-20"></div>
        </div>

        {/* {# Table #} */}
        <div class="table-wrap ds-scrollbar">
          <table class="stats-table w-full">
            <thead class="text-left">
              <tr>
                <th class="text-label-sm">Date</th>
                <th class="text-label-sm">Transaction ID</th>
                <th class="text-label-sm">From</th>
                <th class="text-label-sm">Description</th>
                <th class="text-label-sm">Status</th>
                <th class="text-label-sm text-right" style={{ minWidth: "14rem" }}>
                  Total Earnings
                </th>
              </tr>
            </thead>
            <tbody>
              {currentPageTransfers?.length > 0 ? (
                currentPageTransfers?.map((payout) => {
                  return <SalesTransfersRow key={payout.id} {...payout} />;
                })
              ) : (
                <p className="mt-30">No results to display.</p>
              )}
            </tbody>
          </table>
        </div>
        <SalesPagination
          pageLimit={perPage}
          currentPage={currentPage}
          totalRecords={transfers?.length}
          gotoPage={setCurrentPage}
        />
      </div>
    </LoadingContainer>
  );
};

export default SalesTransfers;

import React, { useContext, useEffect, useRef } from "react";
import api from "../utils/api";
import { Controller, useForm } from "react-hook-form";
import { ProjectsContext } from "../../ProjectsContext";
import { Error, Success } from "../Toast/index";

export default function AddSceneModal(props) {
  const { control, register, handleSubmit, watch, setError, errors, reset, trigger } = useForm();

  const {
    handleClose,
    newSceneName,
    projectId,
    successToastSave,
    updateScenes
  } = props;

  const { updateLatestWithScene } = useContext(ProjectsContext);

  const addScene = () => {
    const sceneBody = {
        name: newSceneName,
    }
    api.addSceneToProject(projectId, sceneBody)
      .then(res => {
        const { newScene, success, updatedProject } = res.data;
        if (success) {
          updateLatestWithScene(
            parseInt(projectId), newScene, {}, updatedProject)
            Success({
              message: "Scene Added!",
              onClose: successToastSave
            })
          updateScenes(newScene);
          handleClose();
        }
        else{
          Error({
            message: "An error occurred!"
          })
        }
      })
      .catch(error=>{
        console.log("error ", error?.response.data)
        if(error?.response?.data){
          Error({
            message: error?.response?.data.message
          })
        }
        else {
          Error({
            message: "An error occurred!"
          })
        }
      })
  }


        const newSceneNameRef = useRef();

        useEffect(() => {
            if (newSceneNameRef.current) {
                  register(newSceneNameRef.current)
                  newSceneNameRef.current.focus()
                }
            }, []);

        // useEffect(() => {
        //     // Show all errors as Toastify
        //     Object.entries(errors).map((error) => (
        //         Error({
        //             message: error[1].message,
        //             toastId: error[0]
        //         })
        //     ))
        // },[errors]);

        return(
            <React.Fragment>
 {/* {# Modal Header #} */}
            <header class="modal-header">
                <h2 id="add_scene_title" class="modal-title">New Scene</h2>
                <button type="button"  onClick={props.handleClose} aria-label="Close Modal" data-micromodal-close="add_scene_modal" class="modal-close" type="button"></button>
            </header>

            {/* {# Modal Content #} */}
            <div id="add_scene_content" class="modal-content">

                {/* {# Form #} */}
                <form action="" class="ds-form" onSubmit={(e)=>{e.preventDefault(); handleSubmit(addScene(trigger, errors, props.handleClose))}}>

                    {/* {# Title #} */}
                    <div class="input-group-sm input-inline">
                        <Controller
                            name="newSceneName1"
                            control={control}
                            id="project_title"
                    placeholder="Scene Title"
                    defaultValue={"Scene 1"}
                            value={props.newSceneName}
                            render={pro=> <input  {...pro} ref={newSceneNameRef} class="form-input" name={"newSceneName"}
                                value={props.newSceneName} onChange={(e)=>{pro.onChange(e);props.handleInputChange(e);}} />}
                            rules={{
                                required: "Scene Name Required",
                                minLength: { message: "Scene Title Must be at Least 3 Characters", value: 3},
                                maxLength: { message: "Scene Title Cannot be More Than 99 Characters", value: 100},
                                // validate: value => props.checkIfUsed(value)
                            }
                            }
                        />
                        <label class="input-label" for="project_title">Scene Title</label>
                        {errors.newSceneName && <span className="error-message">{errors.newSceneName.message}</span>}
                    </div>

                    {/* {# Buttons #} */}
                    <div class="input-group pt-20">
                        <div class="grid-row-sm">
                            <div class="col w-1/2 mb-20 sm:mb-0">
                                <button onClick={props.handleClose} class="btn btn-stroke form-btn w-full" type="button" aria-label="Close Modal" data-micromodal-close="add_scene_modal">Cancel</button>
                            </div>
                            <div class="col w-1/2">
                                <button type="submit" class="btn btn-primary form-btn w-full">Create</button>
                            </div>
                        </div>
                    </div>

                </form>
                {/* {# End Form #} */}

            </div>
</React.Fragment>
        )

}

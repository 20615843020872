import React, { Component } from 'react';
import { UserConsumer } from "../../contexts/User";
import SideNavAccount from './sideNavAccount';
import SideNavAdminSub from './sideNavAdminSub';
import SideNavArtistSub from './sideNavArtistSub';
import SideNavCustomerSub from './sideNavCustomerSub';
import SideNavIncompleteSub from './sideNavIncompleteSub';
class SideNav extends Component {
    state={

    }

    render(){
        return(
			<UserConsumer>
				{({user, login, logout})=>(
					<div className="app-sidebar">
					<SideNavAccount pathname={this.props.pathname}> </SideNavAccount>
					{
						{
						'admin':<SideNavAdminSub  pathname={this.props.pathname}/>,
						'artist': <SideNavArtistSub pathname={this.props.pathname}></SideNavArtistSub>,
						'customer': <SideNavCustomerSub  pathname={this.props.pathname} />,
						'incomplete': <SideNavIncompleteSub pathname={this.props.pathname}/>
						}[user.role]
					}

					</div>
				)}

			</UserConsumer>
        )
    }
}
export default SideNav


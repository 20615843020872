const numberAbbreviator = (number, threshold = "billion") => {
  try {
    let numberToFormat = number;
    if (typeof number !== "number") {
      numberToFormat = Number(number);
    }
    let stringedNumber = number.toLocaleString();
    let numberLength = stringedNumber.length;

    switch (numberLength) {
      case 1:
      case 2:
      case 3:
        return stringedNumber;
        break;
      case 4:
      case 5:
      case 6:
        if (threshold === "thousand") {
          console.log("returning thousand");
          return (numberToFormat / 1000).toFixed(1) + "k";
        } else {
          return stringedNumber;
        }
        break;
      case 7:
      case 8:
      case 9:
        if (threshold == "million") {
          return (numberToFormat / 1000000).toFixed(1) + "m";
        } else {
          return stringedNumber;
        }
        break;
      case 10:
      case 11:
      case 12:
        return (numberToFormat / 1000000000).toFixed(1) + "b";
      default:
        return stringedNumber;
    }
  } catch (error) {
    return number;
  }
};
export default numberAbbreviator;

import buildArtistPath from "./buildArtistPath";

const buildAlbumPath = (artistURL, title) => {
  const artistBasePath = buildArtistPath(artistURL);

  let albumsPathPrefix = "#album";

  const fullPath = '/' + artistBasePath + '/' + albumsPathPrefix + '-' + title.replace(/\s/g, "-");

  return fullPath;
}
export default buildAlbumPath;

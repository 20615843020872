import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import SlidingPane from "react-sliding-pane";
import Question from "./question";
import QuoteContactForm from "../Forms/QuoteContactForm";
import SlidingPaneWrapper from "../SlidingPanes";

export default function FAQ() {
  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);

  const handlePlanModalOpen = () => {
    console.log('handling plan modal open');
    setIsPlanModalOpen(true);
  };

  const handlePlanModalClose = () => {
    setIsPlanModalOpen(false);
  };


  const subscriptionQuestions = () => [
    {
      header: "What is an Unlimited Subscription?",
      body: (
        <>
          <p>
            Our Unlimited Subscription Plans give you the freedom to download as much music as you
            need from our platform to soundtrack your content.
          </p>

          <p>
            Our plans also come with some great perks, including access to unwatermarked downloads,
            extra discounts for licenses not included in your plan, and the ability to whitelist your
            YouTube channel. This ensures you can completely avoid copyright claims, making your
            content creation a hassle-free and worry-free process.
          </p>
        </>
      ),
    },
    {
      header: "What's The Difference Between a Personal and Commercial Subscription?",
      body: (
        <>
          <p>
            Our Personal Subscriptions are tailored for individuals crafting content for
            non-commercial purposes like social media, vlogs, or personal projects. You can even
            monetize your personal account through ad revenue.
          </p>

          <p>
            On the other hand, our Commercial Subscriptions are designed for individuals, businesses,
            or organizations who are creating content for commercial purposes, such as promotional
            content, advertisements, branded content, educational videos, or any other form of content
            that directly or indirectly promotes a business or service. Whether you are a business or
            an individual creating content for clients, this plan has you covered.
          </p>
        </>
      ),
    },
    {
      header: "Can I Pay Month to Month for the Unlimited Subscription?",
      body: (
        <>
          <p>
            Yes. We offer month to month billing, or you can prepay for a year and save a ton of money!
          </p>
        </>
      ),
    },
    {
      header: "What is the difference between a Download and a License?",
      body: (
        <>
          <p>
            With our Subscription plans, every download you make automatically comes with a license.
            It's that easy! So, each time you grab a track, you're not just getting the music; you're
            getting the green light to use it in your project.
          </p>
        </>
      ),
    },
    {
      header: "Do My Licenses Expire When the Unlimited Subscription Ends?",
      body: (
        <>
          <p>
            Any licenses generated while your subscription is active will remain valid even if your
            subscription expires. So, no need to worry about the licenses you've already obtained –
            they're still good to go.
          </p>
          <p>
            There's one thing to keep in mind, though. After your subscription expires, you won't be
            able to use these licenses for new projects. But don't fret, any existing projects will be
            unaffected.
          </p>
        </>
      ),
    },
    {
      header: "What Does the Personal Unlimited Plan Not Cover?",
      body: (
        <>
          <p>
            Just a heads up: if you're thinking about diving into the world of commercial content or
            working with clients, our Personal Unlimited Plan won't have you covered for that. In
            order to be covered for that use, you'll want to consider our Commercial Unlimited Plan.
          </p>
        </>
      ),
    },
    {
      header: "What Does the Commercial Unlimited Plan Not Cover?",
      body: (
        <>
          <p>
            Our Commercial Unlimited Subscription has you covered for any content published online or
            on social media. However, it's essential to know that it doesn't cover some larger-scale
            projects like feature films, television shows, video games, apps, or content aired on
            Broadcast TV, Cable TV, or Satellite TV, as well as any theatrical screenings. If you're
            eyeing those big endeavors, you might want to explore our custom plans or purchase an
            individual license for those.
          </p>
        </>
      ),
    },
    {
      header: "Can I use the music to create another song?",
      body: (
        <>
          <p>
            We're all about making your projects sound fantastic! However, just a quick heads up – the
            music on our platform is there to enhance your multi-media content by using it as
            background music, but it's not meant for creating new songs.
          </p>
          <p>You can not use any of the tracks on our platform in another music creation.</p>
        </>
      ),
    },
    {
      header: "What Subscription Plan Do I Need for Wedding Videos or Non-Profit?",
      body: (
        <>
          <p>The Commercial Subscription plan will cover you for those needs.</p>
        </>
      ),
    },
    {
      header: "Can I Purchase a License Outside The Scope Of My Subscription?",
      body: (
        <>
          <p>
            Yes! You will receive 10% off any license that is not covered under a personal
            subscription plan and 25% off for commercial subscription plans.
          </p>

          <p>
            Example 1: You have a Personal Subscription plan and need to license a single track for
            commercial use. You will receive 10% off the cost of any commercial license as a
            subscriber.
          </p>

          <p>
            Example 2: You have a Commercial Subscription plan and you need to license a track for a
            commercial aired on Broadcast TV. You will receive 25% off the cost of the Commercial Pro
            or Commercial Max Music License as a subscriber.
          </p>
        </>
      ),
    },
    {
      header:
        "Do you have a plan for Marketing Agencies, Major Publishers, Video Game Developers, or Studios?",
      body: (
        <>
          <p>
            Yes! Our custom plans are tailored to specific needs. Please
            {` `}
            <button
              className="text-white underline"
              onClick={() => {
                console.log('clicked it');
                handlePlanModalOpen();
              }}
            >
              contact us
            </button>
            {` `}
            so we can get you the perfect plan for your business.
          </p>
        </>
      ),
    },
  ];

  const licensingQuestions = [
    {
      header: "What is Music Licensing?",
      body: (
        <>
          <p>
            Deep Sounds is a platform where creators can easily license indie artists' music which
            grants them the legal right to use their tracks as background music in their content.
          </p>
        </>
      ),
    },
    {
      header: "Can I Monetize My Content?",
      body: (
        <>
          <p>Yes. All our licenses allow for monetization through social media.</p>

          <p>
            If you purchase an Unlimited Subscription plan, you can whitelist your YouTube channel to
            automatically clear any copyright tracks.
          </p>

          <p>
            If you purchase a license with our free starter account, you will get a unique content ID
            code to use to automatically clear the copyright claim.
          </p>
        </>
      ),
    },
    {
      header: "Do I Need A Subscription To Purchase A Track License?",
      body: (
        <>
          <p>
            Nope! Our starter accounts are free forever, which allows you to purchase each track
            license individually.
          </p>

          <p>
            If you have frequent music needs, the Unlimited Subscription plans will be the best bang
            for your buck.
          </p>
        </>
      ),
    },
  ];

  const refundsQuestions = [
    {
      header: "Can I Cancel My Subscription Plan?",
      body: (
        <>
          <p>
            Don’t worry! If you have a Personal or Commercial plan, you can cancel your plan at any
            time.
          </p>

          <p>
            Your subscription stays active until the current term ends. You won't lose out on any time
            you've already paid for.
          </p>
        </>
      ),
    },
    {
      header: "Can I Get A Refund For My Subscription Plan?",
      body: (
        <>
          <p>
            If you've subscribed to a Personal or Commercial plan and wish to request a refund, you
            can do so within 14 days of initial purchase, provided you haven't generated any licenses
            by downloading tracks.
          </p>
        </>
      ),
    },
    {
      header: "Can I Get A Refund For My License?",
      body: (
        <>
          <p>Once you purchase a license, we unfortunately are not able to offer you a refund.</p>
          <p>
            If you think you purchased the wrong license, reach out to our{" "}
            <NavLink to="/help" className="text-white underline">
              customer support team
            </NavLink>{" "}
            and they will be able to assist you.
          </p>
        </>
      ),
    },
    {
      header: "Can I Change My Unlimited Plan?",
      body: (
        <>
          <p>You can upgrade from a Personal to Commercial or Custom plan anytime.</p>
          <p>
            You can not downgrade from a Commercial or Custom plan during your active subscription.
          </p>
          <p>
            If you believe you picked the wrong plan, please reach out to our{" "}
            <NavLink to="/help" className="text-white underline">
              customer support team
            </NavLink>{" "}
            within 14 days of purchase and they will be able to assist you.
          </p>
        </>
      ),
    },
  ];


  const [questions, setQuestions] = useState(subscriptionQuestions);
  const [activeTab, setActiveTab] = useState("subscription");

  return (
    <>
      <div className="tabs">
        <ul className="tab-items js-tabs-scroll">
          <li className={`tab-item ${activeTab === "subscription" ? "active" : ""}`}>
            <button
              className={`tab-item-title ${activeTab === "subscription" ? "active" : ""}`}
              onClick={() => {
                setActiveTab("subscription");
                setQuestions(subscriptionQuestions);
              }}
            >
              Subscription
            </button>
          </li>
          <li className={`tab-item ${activeTab === "licensing" ? "active" : ""}`}>
            <button
              className={`tab-item-title ${activeTab === "licensing" ? "active" : ""}`}
              onClick={() => {
                setActiveTab("licensing");
                setQuestions(licensingQuestions);
              }}
            >
              Licensing
            </button>
          </li>
          <li className={`tab-item ${activeTab === "refunds" ? "active" : ""}`}>
            <button
              className={`tab-item-title ${activeTab === "refunds" ? "active" : ""}`}
              onClick={() => {
                setActiveTab("refunds");
                setQuestions(refundsQuestions);
              }}
            >
              Refunds
            </button>
          </li>
        </ul>
      </div>

      <div className="accordion">
        {questions.map((question) => (
          <Question
            handlePlanModalOpen={handlePlanModalOpen}
            key={question.header}
            {...question}
          />
        ))}
      </div>

      <SlidingPane
        isOpen={isPlanModalOpen}
        onRequestClose={handlePlanModalClose}
        from={"right"}
        width={"100%"}
        children={
          <SlidingPaneWrapper handleClose={handlePlanModalClose} title="Let's Find Your Plan">
            <QuoteContactForm quoteType="subscription" requestType="Subscription - Custom" />
          </SlidingPaneWrapper>
        }
      />
    </>
  );
}

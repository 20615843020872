import React from "react";
import MicroModal from "react-micro-modal";
import AddToCollectionModal from "../Collections/addToCollectionModal";
import AddToProjectModalLink from "../Projects/AddToProjectModalLink";
import FeatureTrack from "./TrackTooltip/FeatureTrack";
import DownloadTrack from "./DownloadTrack";

const TrackTooltip = (props) => {
  const {
    artistId,
    artistName,
    followArtist,
    following,
    imageURL,
    isFeatured,
    liked,
    likeTrack,
    removeTrack,
    sceneTrackId,
    shareTrack,
    toggleTrackTippy,
    triggerLoginModal,
    trackId,
    trackTitle,
    unfollowArtist,
    unlikeTrack,
    user,
  } = props;

  const notLoggedInOpen = () => {
    triggerLoginModal();
    toggleTrackTippy();
  };

  return (
    <div className="dropdown-menu">
      <div className="dropdown-group">
        {removeTrack && sceneTrackId && (
          <a
            href="#"
            onClick={() => {
              removeTrack(sceneTrackId, trackTitle);
              toggleTrackTippy();
            }}
          >
            <span className="icon-close" />
            Remove Track
          </a>
        )}

        <AddToProjectModalLink
          user={user}
          notLoggedInOpen={notLoggedInOpen}
          trackId={trackId}
          trackTitle={trackTitle}
          TriggerElement={({ user, handleOpen, notLoggedInOpen }) => (
            <>
              <a
                href="#"
                onClick={user ? handleOpen : notLoggedInOpen}
                data-micromodal-trigger="add_to_project_modal"
              >
                <span className="icon-plus"></span>
                Add to Project
              </a>
            </>
          )}
        />

        {user?.admin && (
          <>
            <MicroModal
              trigger={(handleOpen) => (
                <a href="#" onClick={handleOpen} data-micromodal-trigger="add_to_project_modal">
                  <span className="icon-plus"></span>
                  Add to Collection
                </a>
              )}
              closeOnEscapePress={true}
              closeOnOverlayClick={true}
              closeOnAnimationEnd={true}
              modalOverlayClassName="modal-overlay"
              modalClassName="modal micromodal-slide is-open"
              modalOverlayStyles={null}
              children={(handleClose) => (
                <AddToCollectionModal
                  toggleTrackTippy={toggleTrackTippy}
                  handleClose={handleClose}
                  trackId={trackId}
                  trackTitle={trackTitle}
                />
              )}
              containerStyles={{
                background: "#131313",
                padding: "asdf",
                maxWidth: "40.625rem !important",
                maxHeight: "100vh",
                borderRadius: "asdf",
                overflowY: "auto",
                boxSizing: "border-box",
              }}
            />
            <FeatureTrack
              isFeatured={isFeatured}
              toggleTrackTippy={toggleTrackTippy}
              trackId={trackId}
            />
          </>
        )}
        <DownloadTrack
          artistId={artistId}
          artistName={artistName}
          icon="icon-add-to-cart"
          imageURL={imageURL}
          text={"License Track"}
          textLink={true}
          toggleTooltip={toggleTrackTippy}
          trackId={trackId}
          trackTitle={trackTitle}
        />
        {liked === 1 ? (
          <a
            href="#"
            onClick={() => {
              toggleTrackTippy();
              unlikeTrack(trackId, trackTitle);
            }}
          >
            <span className="icon-like-fill"></span>Unlike Track
          </a>
        ) : (
          <a
            href="#"
            onClick={() => {
              toggleTrackTippy();
              likeTrack(trackId);
            }}
          >
            <span className="icon-like"></span>Like Track
          </a>
        )}
        <DownloadTrack
          artistId={artistId}
          artistName={artistName}
          icon="icon-download"
          imageURL={imageURL}
          text={"Download Track"}
          textLink={true}
          toggleTooltip={toggleTrackTippy}
          trackId={trackId}
          trackTitle={trackTitle}
        />
        {following ? (
          <a
            href="#"
            onClick={() => {
              toggleTrackTippy();
              unfollowArtist(artistId, artistName);
            }}
          >
            <span className="icon-follow"></span>Unfollow Artist
          </a>
        ) : (
          <a
            href="#"
            onClick={() => {
              toggleTrackTippy();
              followArtist(artistId, artistName);
            }}
          >
            <span className="icon-follow"></span>Follow Artist
          </a>
        )}
      </div>
      <div className="dropdown-group">
        <a
          href="# "
          onClick={() => {
            toggleTrackTippy();
            shareTrack();
          }}
        >
          <span class="icon-share"></span>Share Track
        </a>
      </div>
    </div>
  );
};
export default TrackTooltip;

import { Box, LinearProgress, Typography } from '@material-ui/core';
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import ApprovalItem from "./approvalItem";
class ApprovalModal extends Component {
    state = {
        redirectTo: null,
        isAlbumModalOpen: false,
        totalProgress: 0,
        totalProgressPool: 1
    }
    //updating our input fields as the user enters keys
    componentDidMount = ()=>{
      let totalProgressPool = 0;
      //add 100 for main track
      totalProgressPool +=100;
      //add 100 for each version
      let numVersions = this.props.versions.length
      let numStems = this.props.stems.length
      totalProgressPool += (numVersions * 100)
      totalProgressPool += (numStems * 100)
      this.setState({totalProgressPool})
    }
    componentDidUpdate =()=>{
      let totalProgressPool = 0;
      //add 100 for main track
      totalProgressPool +=100;
      //add 100 for each version
      let numVersions = this.props.versions.length
      let numStems = this.props.stems.length
      totalProgressPool += (numVersions * 100)
      totalProgressPool += (numStems * 100)
      if(totalProgressPool !== this.state.totalProgressPool){
        this.setState({totalProgressPool})
      }
    }
    render() {
        return (
          <>
            {/* {# Modal Header #} */}
            <header className="modal-header">
              <h2 id="new_track_modal_title" className="modal-title">Track Approval</h2>
            </header>
            <div id="new_track_modal_content" className="modal-content">
              {/* main track */}
                  <Box display="flex" alignItems="center" style={{marginBottom: '20px'}}>
                    <Box width="100%" mr={1}>
                        <LinearProgress  value={Math.round(100 *((this.props.mainTrackProgress + this.props.versions.reduce((a,b)=>a + (b.approvalProgress || 0),0) + this.props.stems.reduce((a,b)=>a + (b.approvalProgress || 0),0))/this.state.totalProgressPool)) || 0} variant={"determinate"} classes={{colorPrimary: 'colorPrimary', barColorPrimary: 'barColorPrimary'}}/>
                    </Box>
                    <Box minWidth={35}>
                      <Typography variant="body2" color="white">{`${Math.round(100 *((this.props.mainTrackProgress + this.props.versions.reduce((a,b)=>a + (b.approvalProgress || 0),0) + + this.props.stems.reduce((a,b)=>a + (b.approvalProgress || 0),0))/this.state.totalProgressPool)) || 0}%`}</Typography>
                    </Box>
                  </Box>
       
                  <ApprovalItem loading={this.props.mainTrackLoading} statusIcon={this.props.mainTrackStatusIcon} title={"Main Track"} step={this.props.mainTrackApprovalStepDisplay}
                  retry={this.props.retryMain} error={this.props.mainTrackApprovalError} progress={this.props.mainTrackProgress} disableButton={(this.props.invalidFileType && this.props.fileFilterComplete ) || (this.props.virusDetected && this.props.virusScanComplete)}/>
                  {this.props.versions.map((version, index)=>(
                       <ApprovalItem version={true} key={version.id} id={version.id} index={index} loading={version.approvalLoading} statusIcon={version.statusIcon} title={version.label} step={version.approvalStepDisplay}
                       retry={this.props.retryVersion} error={version.approvalError} progress={version.approvalProgress} disableButton={(this.props.invalidFileType && this.props.fileFilterComplete ) || (this.props.virusDetected && this.props.virusScanComplete)}/>
                  ))}
                  {this.props.stems.map((stem, index)=>(
                       <ApprovalItem version={true} key={stem.id} id={stem.id} index={index} loading={stem.approvalLoading} statusIcon={stem.statusIcon} title={stem.name} step={stem.approvalStepDisplay}
                       retry={this.props.retryStem} error={stem.approvalError} progress={stem.approvalProgress} disableButton={(this.props.invalidFileType && this.props.fileFilterComplete ) || (this.props.virusDetected && this.props.virusScanComplete)} />
                  ))}
                  {this.props.approvalComplete &&
                    <div className="grid-row flex justify-center  mt-30">
                      <div className="col">
                          <NavLink className={"btn btn-primary"} to={`/tracks/${this.props.finalTrackURL}`}>Finish & View Track</NavLink>
                      </div>
                    </div>
                  }
                  {((this.props.virusScanComplete || this.props.fileFilterComplete) && !this.props.totallyFailed ) &&
                    <>
                      <div className="grid-row flex justify-center  mt-30">
                        <div className="col">
                          {this.props.invalidFileType &&
                            <div>Track automatically rejected because of invalid file type.</div>
                          }
                          {this.props.virusDetected &&
                            <div>Track automatically rejected because a virus was detected</div>
                          }
                          {/* <NavLink className={"btn btn-primary"} to={`/dashboard/tracks/pending`}>Go To Pending Tracks</NavLink> */}
                        </div>
                      </div>
                      {(this.props.invalidFileType || this.props.virusDetected) &&
                        <div className="grid-row flex justify-center  mt-15">
                          <div className="col">
                            <NavLink className={"btn btn-primary"} to={`/dashboard/tracks/pending`}>Pending Tracks</NavLink>
                          </div>
                        </div>
                      }
                    </>
                  }
                  {(this.props.totallyFailed && ((this.props.invalidFileType && this.props.fileFilterComplete ) || (this.props.virusDetected && this.props.virusScanComplete)) &&
                    (!this.props.approvalComplete))&&
                    <>
                    {this.props.approvalFailures < 3  ?
                    <div className="grid-row flex justify-center  mt-30">
                      <div className="col">
                          <div>Critical error in approval process</div>
                      </div>
                    </div>:
                     <div className="grid-row flex justify-center  mt-30">
                     <div className="col">
                         <div>Critical error in approval process. Approval attempts exceeded</div>
                     </div>
                   </div>
                    }
                    {this.props.approvalFailures < 3  ?
                      <div className="grid-row flex justify-center  mt-15">
                        <div className="col">
                          <button className={"btn btn-primary"} onClick={this.props.restartApproval}>Start over</button>
                        </div>
                      </div>:
                      <div className="grid-row flex justify-center  mt-15">
                      <div className="col">
                      <NavLink className={"btn btn-primary"} to={`/dashboard/tracks/pending`}>Pending Tracks</NavLink>
                      </div>
                    </div>
                  }
                  </>
                    }
                  {/* {(this.props.invalidFileType && this.props.fileFilterComplete) &&
                    <div className="grid-row flex justify-center  mt-30">
                      <div className="col">
                        <div>Track automatically rejected because of invalid file type.</div>
                        <NavLink className={"btn btn-primary"} to={`/dashboard/tracks/pending`}>Go To Pending Tracks</NavLink>
                      </div>
                    </div>
                  }
                  {(this.props.virusDetected && this.props.virusScanComplete) &&
                      <div className="grid-row flex justify-center  mt-30">
                        <div className="col">
                          <div>Track automatically rejected because a virus was detected</div>
                          <NavLink className={"btn btn-primary"} to={`/dashboard/tracks/pending`}>Go To Pending Tracks</NavLink>
                        </div>
                      </div>
                  } */}
            </div>
          </>
        )
    }

}
export default ApprovalModal
import moment from "moment";
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { FavoritesConsumer } from "../../FavoritesContext/index";
import { PlayerContext } from "../../PlayerContext";
import PublicCard from "../Collections/publicCard";
import CircularIndeterminant from "../Loading/circularIndeterminant";
import SearchBar from "../SearchBar/searchBar.js";
import SortBar from "../SortBar/results";
import TrackListItem from "../Track/trackListItem";
import api from "../utils/api";
import FavAlbumCard from "./favAlbumCard";
import FavArtistCard from "./favArtistCard";
var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment)

  const trackFilters= [{filter: "NameA", displayText: "Name - Asc"}, {filter: "NameD", displayText: "Name - Desc"}, {filter: "RecentA", displayText: "Recently Liked - Asc"}
  ,{filter: "RecentD", displayText: "Recently Liked - Desc"}]
  const artistFilters= [{filter: "NameA", displayText: "Name - Asc"}, {filter: "NameD", displayText: "Name - Desc"}, {filter: "RecentA", displayText: "Recently Followed - Asc"}
  ,{filter: "RecentD", displayText: "Recently Followed - Desc"}]
  const albumFilters= [{filter: "NameA", displayText: "Name - Asc"}, {filter: "NameD", displayText: "Name - Desc"}, {filter: "RecentA", displayText: "Recently Liked - Asc"}
  ,{filter: "RecentD", displayText: "Recently Liked - Desc"}]
  const collectionFilters= [{filter: "NameA", displayText: "Name - Asc"}, {filter: "NameD", displayText: "Name - Desc"}, {filter: "RecentA", displayText: "Recently Followed - Asc"}
  ,{filter: "RecentD", displayText: "Recently Followed - Desc"}]
class Favorites extends Component {
    state = {
        artists: [],
        filteredArtists: [],
        tracks: [],
        filteredTracks: [],
        albums: [],
        filteredAlbums: [],
        collections: [],
        filteredCollections: [],
        artistsSortChoice: "NameA",
        artistsSortText: "Name - Asc",
        tracksSortChoice: "NameA",
        tracksSortText: "Name - Asc",
        albumsSortChoice: "NameA",
        albumsSortText: "Name - Asc",
        collectionsSortChoice: "NameA",
        collectionsSortText: "Name - Asc",
    }
    //get a requested set of tracks from the db
    componentDidMount = () => {

        let artists = []
            let filteredArtists = []
            let tracks = []
            let filteredTracks = []
            let albums = this.state.albums || []
            let filteredAlbums = this.state.filteredAlbums || []
            let collections = this.state.collections || []
            let filteredCollections = this.state.filteredCollections || []
            let promises = []
            if(this.props.artists.length > 0){
                promises.push(api.getArtistsByIds(this.props.artists.map(artist=>artist.artistId)))
            }
            if(this.props.tracks.length > 0){
                promises.push(api.getTracksByIds(this.props.tracks.map(track=>track.trackId)))
            }
            if(this.props.albums.length > 0){
                promises.push(api.getAlbumsByIds(this.props.albums.map(album=>album.albumId)))
            }
            if(this.props.collections.length > 0){
                promises.push(api.getCollectionsByIds(this.props.collections.map(collection=>collection.collectionId)))
            }

            if(promises.length > 0){
                Promise.all(promises).then(results=>{

                    results.forEach(favList=>{
                        if(favList.data.success){
                            let category = favList.data.favCategory
                            switch(category){
                                case "artists":
                                    artists = favList.data.artists
                                    artists.forEach((artist, index)=>{
                                        let artistFollowedOn = this.props.artists.filter(fav=>fav.artistId === artist.artistId)[0].followedOn
                                        let totalTracks = artist.tracks.length
                                        let likedTracks = artist.tracks.filter(track=>track.liked ===1).length
                                        artists[index].followedOn = artistFollowedOn
                                        artists[index].totalTracks = totalTracks
                                        artists[index].likedTracks = likedTracks
                                    })
                                    filteredArtists = artists.sort((a,b)=>{
                                        const aName = a.artistName.toUpperCase();
                                        const bName = b.artistName.toUpperCase();

                                        let comparison = 0;
                                        if (aName < bName) {
                                            comparison = -1;
                                        } else if (aName > bName) {
                                            comparison = 1;
                                        }
                                        return comparison;
                                    })
                                    break;
                                case "tracks":
                                    tracks = favList.data.tracks
                                    tracks.forEach((track, index)=>{
                                        let trackLikedOn = this.props.tracks.filter(fav=>fav.trackId === track.trackId)[0].likedOn
                                        tracks[index].likedOn = trackLikedOn
                                    })
                                    filteredTracks = tracks || []
                                    filteredTracks = tracks.sort((a, b)=> {
                                        // Use toUpperCase() to ignore character casing
                                        const aName = a.trackTitle.toUpperCase();
                                        const bName = b.trackTitle.toUpperCase();

                                        let comparison = 0;
                                        if (aName < bName) {
                                            comparison = -1;
                                        } else if (aName > bName) {
                                            comparison = 1;
                                        }
                                        return comparison;
                                    })
                                    break;
                                case "albums":
                                    albums = favList.data.albums
                                    albums.forEach((album, index)=>{
                                        let albumLikedOn = this.props.albums.filter(fav=>fav.albumId === album.albumId)[0].likedOn
                                        let totalTracks = album.tracks.length
                                        let likedTracks = album.tracks.filter(track=>track.liked ===1).length
                                        albums[index].likedOn = albumLikedOn
                                        albums[index].totalTracks = totalTracks
                                        albums[index].likedTracks = likedTracks
                                    })
                                    filteredAlbums = albums || []
                                    filteredAlbums = albums.sort((a, b)=> {
                                        // Use toUpperCase() to ignore character casing
                                        const aName = a.albumTitle.toUpperCase();
                                        const bName = b.albumTitle.toUpperCase();

                                        let comparison = 0;
                                        if (aName < bName) {
                                            comparison = -1;
                                        } else if (aName > bName) {
                                            comparison = 1;
                                        }
                                        return comparison;
                                    })
                                    break;
                                case "collections":
                                        collections = favList.data.collections
                                        collections.forEach((collection, index)=>{
                                            let collectionLikedOn = this.props.collections.filter(fav=>fav.collectionId === collection.id)[0].likedOn
                                            let totalTracks = collection.tracks.length
                                            collections[index].likedOn = collectionLikedOn
                                            collections[index].totalTracks = totalTracks
                                        })
                                        filteredCollections = collections || []
                                        filteredCollections = collections.sort((a, b)=> {
                                            // Use toUpperCase() to ignore character casing
                                            const aName = a.name.toUpperCase();
                                            const bName = b.name.toUpperCase();

                                            let comparison = 0;
                                            if (aName < bName) {
                                                comparison = -1;
                                            } else if (aName > bName) {
                                                comparison = 1;
                                            }
                                            return comparison;
                                        })
                                        break;
                            }
                        }

                    })
                    filteredTracks = filteredTracks.map(track=>{
                        let retTrack = track;
                        retTrack.idKey="favoritesTrackListItem-"+track.trackId
                        return retTrack
                    })
                    let actuallyLoaded = artists.length > 0 || tracks.length > 0 || albums.length > 0 || collections.length > 0
                    this.setState({artists: artists, filteredArtists: filteredArtists, tracks: tracks, filteredTracks: filteredTracks, albums: albums, filteredAlbums: filteredAlbums,
                        collections: collections, filteredCollections: filteredCollections, isLoaded: actuallyLoaded})
                })
            }
            else{
                this.setState({isLoaded: true})
            }
        let placeholder = ""
        switch(this.props.match.params.favoriteList){
            case "artists":
                placeholder = "Search by name..."
                break;
            case "tracks":
                placeholder = "Search by title, genre, instrument, etc."
                break;
            case "albums":
                placeholder = "Search by title..."
                break;
        }
        this.setState({placeholder: placeholder,})
    }
    componentDidUpdate =(prevProps)=>{

        if(prevProps.tracks !== this.props.tracks || prevProps.artists !== this.props.artists || prevProps.albums !== this.props.albums || prevProps.collections !== this.props.collections){

            let artists = this.state.artists || []
            let filteredArtists = this.state.filteredArtists || []
            let tracks = this.state.tracks || []
            let filteredTracks = this.state.filteredTracks || []
            let albums = this.state.albums || []
            let filteredAlbums = this.state.filteredAlbums || []
            let collections = this.state.collections || []
            let filteredCollections = this.state.filteredCollections || []
            let promises = []
            if(this.props.artists.length > 0){
                let artistsToGet = this.props.artists.filter(artist=>{
                    if(this.state.artists.findIndex(artistData=>artistData.artistId === artist.artistId) ===-1){
                        return true
                    }
                })
                if(artistsToGet.length > 0){
                    promises.push(api.getArtistsByIds(artistsToGet.map(artist=>artist.artistId)))
                }
            }
            if(this.props.tracks.length > 0){
                let tracksToGet = this.props.tracks.filter(track=>{
                    if(this.state.tracks.findIndex(trackData=>trackData.trackId === track.trackId) ===-1){
                        return true
                    }
                })
                if(tracksToGet.length > 0){
                    promises.push(api.getTracksByIds(tracksToGet.map(track=>track.trackId)))
                }
            }
            if(this.props.albums.length > 0){
                let albumsToGet = this.props.albums.filter(album=>{
                    if(this.state.albums.findIndex(albumData=>albumData.albumId === album.albumId) ===-1){
                        return true
                    }
                })
                if(albumsToGet.length > 0){
                    promises.push(api.getAlbumsByIds(albumsToGet.map(album=>album.albumId)))
                }
            }
            if(this.props.collections.length > 0){
                let collectionsToGet = this.props.collections.filter(collection=>{
                    if(this.state.collections.findIndex(collectionData=>collectionData.id === collection.collectionId) ===-1){
                        return true
                    }
                })
                if(collectionsToGet.length > 0){
                    promises.push(api.getCollectionsByIds(collectionsToGet.map(collection=>collection.collectionId)))
                }
            }
            Promise.all(promises).then(results=>{

                if(results.length > 0){
                    results.forEach(favList=>{
                        if(favList.data.success){
                            let category = favList.data.favCategory
                            switch(category){
                                case "artists":
                                    let retrievedArtists = favList.data.artists
                                    retrievedArtists.forEach((artist, index)=>{
                                        let artistFollowedOn = this.props.artists.filter(fav=>fav.artistId === artist.artistId)[0].followedOn
                                        let totalTracks = artist.tracks.length
                                        let likedTracks = artist.tracks.filter(track=>track.liked ===1).length
                                        retrievedArtists[index].followedOn = artistFollowedOn
                                        retrievedArtists[index].totalTracks = totalTracks
                                        retrievedArtists[index].likedTracks = likedTracks
                                    })
                                    artists = artists.concat(retrievedArtists)
                                    filteredArtists = artists.sort((a,b)=>{
                                        const aName = a.artistName.toUpperCase();
                                        const bName = b.artistName.toUpperCase();

                                        let comparison = 0;
                                        if (aName < bName) {
                                            comparison = -1;
                                        } else if (aName > bName) {
                                            comparison = 1;
                                        }
                                        return comparison;
                                    })
                                    break;
                                case "tracks":
                                    let retrievedTracks = favList.data.tracks
                                    retrievedTracks.forEach((track, index)=>{
                                        let trackLikedOn = this.props.tracks.filter(fav=>fav.trackId === track.trackId)[0].likedOn
                                        retrievedTracks[index].likedOn = trackLikedOn
                                    })
                                    tracks = tracks.concat(retrievedTracks)
                                    filteredTracks = tracks || []
                                    filteredTracks = tracks.sort((a, b)=> {
                                        // Use toUpperCase() to ignore character casing
                                        const aName = a.trackTitle.toUpperCase();
                                        const bName = b.trackTitle.toUpperCase();

                                        let comparison = 0;
                                        if (aName < bName) {
                                            comparison = -1;
                                        } else if (aName > bName) {
                                            comparison = 1;
                                        }
                                        return comparison;
                                    })
                                    break;
                                case "albums":
                                    let retrievedAlbums = favList.data.albums
                                    retrievedAlbums.forEach((album, index)=>{
                                        let albumLikedOn = this.props.albums.filter(fav=>fav.albumId === album.albumId)[0].likedOn
                                        let totalTracks = album.tracks.length
                                        let likedTracks = album.tracks.filter(track=>track.liked ===1).length
                                        retrievedAlbums[index].likedOn = albumLikedOn
                                        retrievedAlbums[index].totalTracks = totalTracks
                                        retrievedAlbums[index].likedTracks = likedTracks
                                    })
                                    albums = albums.concat(retrievedAlbums)
                                    filteredAlbums = albums || []
                                    filteredAlbums = albums.sort((a, b)=> {
                                        // Use toUpperCase() to ignore character casing
                                        const aName = a.albumTitle.toUpperCase();
                                        const bName = b.albumTitle.toUpperCase();

                                        let comparison = 0;
                                        if (aName < bName) {
                                            comparison = -1;
                                        } else if (aName > bName) {
                                            comparison = 1;
                                        }
                                        return comparison;
                                    })
                                    break;
                                case "collections":
                                    let retrievedCollections = favList.data.collections
                                    retrievedCollections.forEach((collection, index)=>{
                                        let collectionLikedOn = this.props.collections.filter(fav=>fav.collectionId === collection.id)[0].likedOn
                                        let totalTracks = collection.tracks.length
                                        let likedTracks = collection.tracks.filter(track=>track.liked ===1).length
                                        retrievedCollections[index].likedOn = collectionLikedOn
                                        retrievedCollections[index].totalTracks = totalTracks
                                        retrievedCollections[index].likedTracks = likedTracks
                                    })
                                    collections = collections.concat(retrievedCollections)
                                    filteredCollections = collections || []
                                    filteredCollections = collections.sort((a, b)=> {
                                        // Use toUpperCase() to ignore character casing
                                        const aName = a.name.toUpperCase();
                                        const bName = b.name.toUpperCase();

                                        let comparison = 0;
                                        if (aName < bName) {
                                            comparison = -1;
                                        } else if (aName > bName) {
                                            comparison = 1;
                                        }
                                        return comparison;
                                    })
                                    break;

                            }
                        }

                    })

                    filteredTracks = filteredTracks.map(track=>{
                        let retTrack = track;
                        retTrack.idKey="favoritesTrackListItem-"+track.trackId
                        return retTrack
                    })
                    this.setState({artists: artists, filteredArtists: filteredArtists, tracks: tracks, filteredTracks: filteredTracks, albums: albums, filteredAlbums: filteredAlbums,
                        collections: collections, filteredCollections: filteredCollections, isLoaded: true})
                }
                else{
                    artists = artists.filter(artist=>{
                        if(this.props.artists.findIndex(favArtist=>favArtist.artistId === artist.artistId) !==-1){
                            return true
                        }
                    })
                    filteredArtists = filteredArtists.filter(artist=>{
                        if(this.props.artists.findIndex(favArtist=>favArtist.artistId === artist.artistId) !==-1){
                            return true
                        }
                    })
                    tracks = tracks.filter(track=>{
                        if(this.props.tracks.findIndex(favTrack=>favTrack.trackId === track.trackId) !==-1){
                            return true
                        }
                    })
                    filteredTracks = filteredTracks.filter(track=>{
                        if(this.props.tracks.findIndex(favTrack=>favTrack.trackId === track.trackId) !==-1){
                            return true
                        }
                    })
                    albums = albums.filter(album=>{
                        if(this.props.albums.findIndex(favAlbum=>favAlbum.albumId === album.albumId) !==-1){
                            return true
                        }
                    })
                    filteredAlbums = filteredAlbums.filter(album=>{
                        if(this.props.albums.findIndex(favAlbum=>favAlbum.albumId === album.albumId) !==-1){
                            return true
                        }
                    })
                    collections = collections.filter(collection=>{
                        if(this.props.collections.findIndex(favCollection=>favCollection.collectionId === collection.id) !==-1){
                            return true
                        }
                    })
                    filteredCollections = filteredCollections.filter(collection=>{
                        if(this.props.collections.findIndex(favCollection=>favCollection.collectionId === collection.id) !==-1){
                            return true
                        }
                    })
                    filteredTracks = filteredTracks.map(track=>{
                        let retTrack = track;
                        retTrack.idKey="favoritesTrackListItem-"+track.trackId
                        return retTrack

                    })
                    this.setState({artists: artists, filteredArtists: filteredArtists, tracks: tracks, filteredTracks: filteredTracks, albums: albums, filteredAlbums: filteredAlbums,
                    collections: collections, filteredCollections: filteredCollections, isLoaded: true})
                }
            })
            if(promises.length < 1){
                this.setState({isLoaded: true})
            }

        }
        if(prevProps.match.params.favoriteList !== this.props.match.params.favoriteList){
            let placeholder = ""
        switch(this.props.match.params.favoriteList){
            case "artists":
                placeholder = "Search by name..."
                break;
            case "tracks":
                placeholder = "Search by title, genre, instrument, etc."
                break;
            case "albums":
                placeholder ="Search by title..."
                break;
            case "collections":
                placeholder = "Search by name..."
                break;
        }
        this.setState({placeholder: placeholder}, ()=>{
                if(this.props.location.state?.fromAlbum){
                    this.handleSearchChange({target: {value: this.props.location.state.fromAlbum}})
                }else if(this.props.location.state?.fromArtist){
                    this.handleSearchChange({target: {value: this.props.location.state.fromArtist}})
                }
                else{
                    this.handleSearchChange({target:{value: ""}})
                }
            })
        }
    }

    emitPlayable = ()=>{
        this.setState({playable: true})
    }
    toggleSortTippy = (sortChoice, overRideTippy)=>{
        switch(this.props.match.params.favoriteList){
            case "artists":
                this.setState({isSortTippyOpen: overRideTippy === false ? false : !this.state.isSortTippyOpen, artistsSortChoice: sortChoice})
                break;
            case "tracks":
                this.setState({isSortTippyOpen: overRideTippy === false ? false : !this.state.isSortTippyOpen, tracksSortChoice: sortChoice})
                break;
            case "albums":
                this.setState({isSortTippyOpen: overRideTippy === false ? false : !this.state.isSortTippyOpen, albumsSortChoice: sortChoice})
                break;
            case "collections":
                this.setState({isSortTippyOpen: overRideTippy === false ? false : !this.state.isSortTippyOpen, collectionsSortChoice: sortChoice})
                break;
        }
    }
    sortTracksBy = (sortChoice, sortText, overRide, tippyStateCB, currentSortChoice, overRideTippy)=>{
        let unsortedTracks = [...this.state.filteredTracks]
        let compare;
        let sorted;

        if(sortChoice !== this.state.tracksSortChoice || overRide){

            switch(sortChoice){
                case "NameA":
                    compare = (a, b)=> {
                        // Use toUpperCase() to ignore character casing
                        const aName = a.trackTitle.toUpperCase();
                        const bName = b.trackTitle.toUpperCase();

                        let comparison = 0;
                        if (aName < bName) {
                          comparison = -1;
                        } else if (aName > bName) {
                          comparison = 1;
                        }
                        return comparison;
                      }
                    sorted = unsortedTracks.sort(compare)
                    break;
                case "NameD":
                    compare = (a, b)=> {

                        // Use toUpperCase() to ignore character casing
                        const aName = a.trackTitle.toUpperCase();
                        const bName = b.trackTitle.toUpperCase();

                        let comparison = 0;
                        if (aName < bName) {
                          comparison = -1;
                        } else if (aName > bName) {
                          comparison = 1;
                        }
                        return comparison*-1;
                      }
                    sorted = unsortedTracks.sort(compare)
                    break;
                case "RecentA":
                    compare = (a,b)=>{
                        const aLikedOn = a.likedOn;
                        const bLikedOn = b.likedOn;

                        let comparison = 0;
                        if (aLikedOn < bLikedOn){
                            comparison = -1
                        }
                        else if (aLikedOn > bLikedOn){
                            comparison = 1;
                        }
                        return comparison
                    }
                    sorted = unsortedTracks.sort(compare)
                    break;
                case "RecentD":
                    compare = (a,b)=>{
                        const aLikedOn = a.likedOn;
                        const bLikedOn = b.likedOn;

                        let comparison = 0;
                        if (aLikedOn < bLikedOn){
                            comparison = -1
                        }
                        else if (aLikedOn > bLikedOn){
                            comparison = 1;
                        }
                        return comparison * -1
                    }
                    sorted = unsortedTracks.sort(compare)
                    break;
            }
            this.setState({filteredTracks: sorted, tracksSortText: sortText}, ()=>tippyStateCB(sortChoice, overRideTippy))
        }
        else{
            tippyStateCB(sortChoice)
        }
    }
    sortArtistsBy = (sortChoice, sortText, overRide, tippyStateCB, currentSortChoice, overRideTippy)=>{
        let unsortedArtists = [...this.state.filteredArtists]
        let compare;
        let sorted;

        if(sortChoice !== this.state.artistsSortChoice || overRide){

            switch(sortChoice){
                case "NameA":
                    compare = (a, b)=> {
                        // Use toUpperCase() to ignore character casing
                        const aName = a.artistName.toUpperCase();
                        const bName = b.artistName.toUpperCase();

                        let comparison = 0;
                        if (aName < bName) {
                          comparison = -1;
                        } else if (aName > bName) {
                          comparison = 1;
                        }
                        return comparison;
                      }
                    sorted = unsortedArtists.sort(compare)
                    break;
                case "NameD":
                    compare = (a, b)=> {

                        // Use toUpperCase() to ignore character casing
                        const aName = a.artistName.toUpperCase();
                        const bName = b.artistName.toUpperCase();

                        let comparison = 0;
                        if (aName < bName) {
                          comparison = -1;
                        } else if (aName > bName) {
                          comparison = 1;
                        }
                        return comparison*-1;
                      }
                    sorted = unsortedArtists.sort(compare)
                    break;
                case "RecentA":
                    compare = (a,b)=>{
                        const aFollowedOn = a.followedOn;
                        const bFollowedOn = b.followedOn;

                        let comparison = 0;
                        if (aFollowedOn < bFollowedOn){
                            comparison = -1
                        }
                        else if (aFollowedOn > bFollowedOn){
                            comparison = 1;
                        }
                        return comparison
                    }
                    sorted = unsortedArtists.sort(compare)
                    break;
                case "RecentD":
                    compare = (a,b)=>{
                        const aFollowedOn = a.followedOn;
                        const bFollowedOn = b.followedOn;

                        let comparison = 0;
                        if (aFollowedOn < bFollowedOn){
                            comparison = -1
                        }
                        else if (aFollowedOn > bFollowedOn){
                            comparison = 1;
                        }
                        return comparison * -1
                    }
                    sorted = unsortedArtists.sort(compare)
                    break;
            }
            this.setState({filteredArtists: sorted, artistsSortText: sortText}, ()=>tippyStateCB(sortChoice, overRideTippy))
        }
        else{
            tippyStateCB(sortChoice)
        }
    }
    playCollectionTracks = (tracks, collectionId)=>{
        if(this.context.mediaPlayer.idKey === "favoriteCollectionsGridItem-"+collectionId){
            this.context.playPause()
            return
        }
        let playList = tracks
        tracks.map(track=>{
            let retTrack = track
            track.idKey = "favoriteCollectionsGridItem-"+collectionId
            return retTrack
        })
        this.context.setPlaylist(playList)
        this.context.loadTrackInMediaPlayer(playList[0].trackId, playList[0].idKey)
        this.context.setAutoPlay(true)
        this.setState({playingCollection: collectionId})
    }
    sortAlbumsBy = (sortChoice, sortText, overRide, tippyStateCB, currentSortChoice, overRideTippy)=>{
        let unsortedAlbums = [...this.state.filteredAlbums]
        let compare;
        let sorted;

        if(sortChoice !== this.state.albumsSortChoice || overRide){

            switch(sortChoice){
                case "NameA":
                    compare = (a, b)=> {
                        // Use toUpperCase() to ignore character casing
                        const aName = a.albumTitle.toUpperCase();
                        const bName = b.albumTitle.toUpperCase();

                        let comparison = 0;
                        if (aName < bName) {
                          comparison = -1;
                        } else if (aName > bName) {
                          comparison = 1;
                        }
                        return comparison;
                      }
                    sorted = unsortedAlbums.sort(compare)
                    break;
                case "NameD":
                    compare = (a, b)=> {

                        // Use toUpperCase() to ignore character casing
                        const aName = a.albumTitle.toUpperCase();
                        const bName = b.albumTitle.toUpperCase();

                        let comparison = 0;
                        if (aName < bName) {
                          comparison = -1;
                        } else if (aName > bName) {
                          comparison = 1;
                        }
                        return comparison*-1;
                      }
                    sorted = unsortedAlbums.sort(compare)
                    break;
                case "RecentA":
                    compare = (a,b)=>{
                        const aLikedOn = a.likedOn;
                        const bLikedOn = b.likedOn;

                        let comparison = 0;
                        if (aLikedOn < bLikedOn){
                            comparison = -1
                        }
                        else if (aLikedOn > bLikedOn){
                            comparison = 1;
                        }
                        return comparison
                    }
                    sorted = unsortedAlbums.sort(compare)
                    break;
                case "RecentD":
                    compare = (a,b)=>{
                        const aLikedOn = a.likedOn;
                        const bLikedOn = b.likedOn;

                        let comparison = 0;
                        if (aLikedOn < bLikedOn){
                            comparison = -1
                        }
                        else if (aLikedOn > bLikedOn){
                            comparison = 1;
                        }
                        return comparison * -1
                    }
                    sorted = unsortedAlbums.sort(compare)
                    break;
            }
            this.setState({filteredAlbums: sorted, albumsSortText: sortText}, ()=>tippyStateCB(sortChoice, overRideTippy))
        }
        else{
            tippyStateCB(sortChoice)
        }
    }
    sortCollectionssBy = (sortChoice, sortText, overRide, tippyStateCB, currentSortChoice, overRideTippy)=>{
        let unsortedCollections = [...this.state.filteredCollections]
        let compare;
        let sorted;

        if(sortChoice !== this.state.collectionsSortChoice || overRide){

            switch(sortChoice){
                case "NameA":
                    compare = (a, b)=> {
                        // Use toUpperCase() to ignore character casing
                        const aName = a.name.toUpperCase();
                        const bName = b.name.toUpperCase();

                        let comparison = 0;
                        if (aName < bName) {
                          comparison = -1;
                        } else if (aName > bName) {
                          comparison = 1;
                        }
                        return comparison;
                      }
                    sorted = unsortedCollections.sort(compare)
                    break;
                case "NameD":
                    compare = (a, b)=> {

                        // Use toUpperCase() to ignore character casing
                        const aName = a.name.toUpperCase();
                        const bName = b.name.toUpperCase();

                        let comparison = 0;
                        if (aName < bName) {
                          comparison = -1;
                        } else if (aName > bName) {
                          comparison = 1;
                        }
                        return comparison*-1;
                      }
                    sorted = unsortedCollections.sort(compare)
                    break;
                case "RecentA":
                    compare = (a,b)=>{
                        const aFollowedOn = a.followedOn;
                        const bFollowedOn = b.followedOn;

                        let comparison = 0;
                        if (aFollowedOn < bFollowedOn){
                            comparison = -1
                        }
                        else if (aFollowedOn > bFollowedOn){
                            comparison = 1;
                        }
                        return comparison
                    }
                    sorted = unsortedCollections.sort(compare)
                    break;
                case "RecentD":
                    compare = (a,b)=>{
                        const aFollowedOn = a.followedOn;
                        const bFollowedOn = b.followedOn;

                        let comparison = 0;
                        if (aFollowedOn < bFollowedOn){
                            comparison = -1
                        }
                        else if (aFollowedOn > bFollowedOn){
                            comparison = 1;
                        }
                        return comparison * -1
                    }
                    sorted = unsortedCollections.sort(compare)
                    break;
            }
            this.setState({filteredCollections: sorted, collectionsSortText: sortText}, ()=>tippyStateCB(sortChoice, overRideTippy))
        }
        else{
            tippyStateCB(sortChoice)
        }
    }
    handleSearchChange= (event)=>{
        let {value} = event.target
        this.setState({searchTerm: value},()=>this.search())
    }
    search = ()=>{
        switch (this.props.match.params.favoriteList){
            case "artists":
                this.filterArtists()
                break;
            case "tracks":
                this.filterTracks()
                break;
            case "albums":
                this.filterAlbums()
                break;
            case "collections":
                this.filteredCollections()
                break;
            default:
                break;
        }
    }
    filterTracks = ()=>{
        let allTracks = [...this.state.tracks]
        let filteredTracks = allTracks.filter(track=>{
            if(track.trackTitle.toLowerCase().includes(this.state.searchTerm.toLowerCase())){
                return true
            }
            else if(track.artistName.toLowerCase().includes(this.state.searchTerm.toLowerCase())){
                return true
            }
            else if(track.albumTitle.toLowerCase().includes(this.state.searchTerm.toLowerCase())){
                return true
            }
            else{
                let retVal
                track.tags.forEach(tag=>{
                    if(tag.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())){
                        retVal =true
                    }
                })
                return retVal
            }
        })
        filteredTracks = filteredTracks.map(track=>{
            let retTrack = track;
            retTrack.idKey="favoritesTrackListItem-"+track.trackId
            return retTrack

        })
        this.setState({filteredTracks: filteredTracks}, ()=>this.sortTracksBy(this.state.tracksSortChoice, this.state.tracksSortText, true, this.toggleSortTippy,undefined, false))
    }
    filterArtists = ()=>{
        let allArtists = [...this.state.artists]
        let filteredArtists = allArtists.filter(artist=>{
            if(artist.artistName.toLowerCase().includes(this.state.searchTerm.toLowerCase())){
                return true
            }
        })
        this.setState({filteredArtists: filteredArtists}, ()=>this.sortArtistsBy(this.state.artistsSortChoice, this.state.artistsSortText, true, this.toggleSortTippy, undefined, false))
    }
    filterAlbums = ()=>{
        let allAlbums = [...this.state.albums]
        let filteredAlbums = allAlbums.filter(album=>{
            if(album.albumTitle.toLowerCase().includes(this.state.searchTerm.toLowerCase())){
                return true
            }

        })
        this.setState({filteredAlbums: filteredAlbums}, ()=>this.sortTracksBy(this.state.albumsSortChoice, this.state.albumsSortText, true, this.toggleSortTippy,undefined, false))
    }
    filteredCollections = ()=>{
        let allCollections = [...this.state.collections]
        let filteredCollections = allCollections.filter(collection=>{
            if(collection.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())){
                return true
            }
        })
        this.setState({filteredCollections: filteredCollections}, ()=>this.sortArtistsBy(this.state.collectionsSortChoice, this.state.collectionsSortText, true, this.toggleSortTippy, undefined, false))
    }
    playAlbum = (albumId)=>{
        if(this.context.mediaPlayer.idKey === "favoriteAlbumGridItem-"+albumId ){
            this.context.playPause()
            return
        }
        let album = this.state.albums.filter(album=>album.albumId === albumId)[0]
        let trackList = album.tracks.map(track=>{
            let retTrack = track
            retTrack.idKey = "favoriteAlbumGridItem-"+album.albumId
            return retTrack
        })

        this.context.setPlaylist(trackList)
        this.context.loadTrackInMediaPlayer(trackList[0].trackId, trackList[0].idKey)
        this.context.setAutoPlay(true)
    }

    playCB = (idKey, trackId) => {
        // console.log('in play callback function with idKey', idKey);
        let playList = [...this.state.filteredTracks];
        // console.log('playList', playList);

        let foundIndex = playList.findIndex(filteredTrack => filteredTrack.idKey === idKey)
        // console.log('foundIndex', foundIndex);

        // this.context.loadTrackInMediaPlayer(playList[foundIndex]);
        this.context.loadTrackInMediaPlayer(trackId, idKey);
        this.context.setAutoPlay(false)
        this.context.setPlaylist(this.state.filteredTracks)
    }
    render() {
        return (
                        <div className="container">

                            {/* {# App Header #} */}
                            <div className="view-header">
                            <h1 class="view-title">Favorites</h1>

                            </div>

                            {/* {# Tabs #} */}
                            {/* {% import "components/tracks/tracks-tabs.html" as nav %}
                                    {{ nav.active('Approved') }} */}
                            <nav className="tabs mb-30">
                                <ul className="tab-items">
                                    <li className={`tab-item ${this.props.match.params.favoriteList.toLowerCase() === "artists" ? "active" : ""}`}>
                                        <NavLink to="/dashboard/favorites/artists" className='tab-item-title' >Artists</NavLink>
                                    </li>
                                    <li className={`tab-item ${this.props.match.params.favoriteList.toLowerCase() === "tracks" ? "active" : ""}`}>
                                        <NavLink to="/dashboard/favorites/tracks" className="tab-item-title">Tracks</NavLink>
                                    </li>
                                    <li className={`tab-item ${this.props.match.params.favoriteList.toLowerCase() === "albums" ? "active" : ""}`}>
                                        <NavLink to="/dashboard/favorites/albums" className="tab-item-title">Albums</NavLink>
                                    </li>
                                    <li className={`tab-item ${this.props.match.params.favoriteList.toLowerCase() === "collections" ? "active" : ""}`}>
                                        <NavLink to="/dashboard/favorites/collections" className="tab-item-title">Collections</NavLink>
                                    </li>
                                </ul>
                            </nav>

                                <SearchBar value={this.state.searchTerm} onChange={this.handleSearchChange} placeholder={this.state.placeholder} />
                                {this.props.match.params.favoriteList === "tracks" &&
                                   <SortBar sortFilters={trackFilters} sortBarId={"favoriteTracks"} resultsText={`${this.state.filteredTracks.length} Tracks`} sortCriteria={this.state.tracksSortText} marginClasses={"mb-30"} toggleSortTippy={this.toggleSortTippy} isSortTippyOpen={this.state.isSortTippyOpen} sortBy={this.sortTracksBy}
                                    sortTippyCB={this.toggleSortTippy}/>
                                }
                                {this.props.match.params.favoriteList === "artists" &&
                                   <SortBar sortFilters={artistFilters} sortBarId={"favoriteArtists"} resultsText={`${this.state.filteredArtists.length} Artists`} sortCriteria={this.state.artistsSortText} marginClasses={"mb-30"} toggleSortTippy={this.toggleSortTippy} isSortTippyOpen={this.state.isSortTippyOpen} sortBy={this.sortArtistsBy}
                                    sortTippyCB={this.toggleSortTippy}/>
                                }
                                {this.props.match.params.favoriteList === "albums" &&
                                   <SortBar sortFilters={albumFilters} sortBarId={"favoriteAlbums"} resultsText={`${this.state.filteredAlbums.length} Artists`} sortCriteria={this.state.albumsSortText} marginClasses={"mb-30"} toggleSortTippy={this.toggleSortTippy} isSortTippyOpen={this.state.isSortTippyOpen} sortBy={this.sortAlbumsBy}
                                    sortTippyCB={this.toggleSortTippy}/>
                                }
                                {this.props.match.params.favoriteList === "followers" &&
                                   <SortBar sortFilters={collectionFilters} sortBarId={"favoriteCollections"} resultsText={`${this.state.filteredCollections.length} Collections`} sortCriteria={this.state.collectionsSortText} marginClasses={"mb-30"} toggleSortTippy={this.toggleSortTippy} isSortTippyOpen={this.state.isSortTippyOpen} sortBy={this.sortCollectionsBy}
                                    sortTippyCB={this.toggleSortTippy}/>
                                }

                            {/* {# Tracks List #} */}
                                {this.state.isLoaded ? <>
                                    {this.props.match.params.favoriteList==="artists" &&
                                        <div class="grid-row-xs mb-40">
                                            {this.state.filteredArtists.map(artist=>
                                            <div key={"favArtist-" + artist.artistId} className="col w-full md:w-1/2 xxl:w-1/3 mb-10 sm:mb-20">
                                                <FavArtistCard {...artist} unfollowArtist={this.props.unfollowArtist}/>
                                            </div>)}
                                        </div>
                                    }
                                    {this.props.match.params.favoriteList === "tracks" &&
                                        <div className="track-list-container mb-40">
                                            {this.state.filteredTracks.map(track=><TrackListItem key={'favTrack-'+track.trackId} {...track}
                                                idKey={"favoritesTrackListItem-"+track.trackId} playCB={this.playCB}/>)}
                                        </div>
                                    }
                                    {this.props.match.params.favoriteList === "albums" &&
                                    <div class="grid-row mb:20 md:mb-0">
                                        {this.state.filteredAlbums.map(album=><div key={'favAlbum-'+album.albumId} class="col w-full sm:w-1/2 md:w-1/3 mb-20 md:mb-40">
                                                <FavAlbumCard key={'favAlbumCard-'+album.albumId}  {...album} unlikeAlbum={this.props.unlikeAlbum}  playAlbum={this.playAlbum}
                                                    idKey={"favoriteAlbumGridItem-"+album.albumId}/>
                                            </div>
                                            )}
                                            {/* {% include "components/cards/artwork-card-album.html" %} */}
                                        </div>
                                        }
                                    {this.props.match.params.favoriteList === "collections" &&
                                        <div class="grid-row">
                                            {this.state.filteredCollections.map(collection=><div class="col w-full sm:w-1/2 md:w-1/3 mb-20 md:mb-40">
                                                <PublicCard key={"favoriteCollectionsGridItem-" + collection.id} {...collection}  playTracks={this.playCollectionTracks} isPlaying={this.context?.mediaPlayer.idKey === "favoriteCollectionsGridItem-" + collection.id}
                                                    idKey={"favoriteCollectionsGridItem-" + collection.id}/>
                                            </div>)}

                                        </div>
                                    }
                                   </>:<CircularIndeterminant forceCenter/>}
                                    </div>
                    )
    }

}
Favorites.contextType = PlayerContext

export const Favs = (props) => (
  <FavoritesConsumer>
    {({
      tracks,
      artists,
      albums,
      collections,
      followArtist,
      unfollowArtist,
      likeAlbum,
      unlikeAlbum,
      unfollowCollection,
    }) => (
      <Favorites
        {...props}
        tracks={tracks}
        artists={artists}
        albums={albums}
        followArtist={followArtist}
        unfollowArtist={unfollowArtist}
        unlikeAlbum={unlikeAlbum}
        collections={collections}
        unfollowCollection={unfollowCollection}
      />
    )}
  </FavoritesConsumer>
);

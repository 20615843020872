import React from "react";
import Moment from "react-moment";

const LicenseTab = (props) => {
  console.log('LicenseTab props', props);

  const { license, licenseNumberDisplay, orderDate, status } = props;

  const { licenseBudg, licenseDist, licenseMain, licenseSub, licenseTer, product } = license || {};

  const { label } = licenseMain || {};
  const { label: licenseSubLabel } = licenseSub || {};
  const { name } = product || {};

  return (
    <div className="tab-item-content active" id="tab_details">
      <div className="grid-row">
        {status === "ach failed" ? (
          <div className="error-message col w-full mb-30">
            The payment failed, and this license is not valid.
          </div>
        ) : (
          <>
            {/* {# License Date #} */}
            <div className="col w-1/2 md:w-1/3 mb-30">
              <h3 className="text-label-sm mb-5">Date</h3>
              <span key="1">
                <Moment format="MM.DD.YYYY">{orderDate}</Moment>
              </span>
            </div>

            {/* {# License Number #} */}
            <div className="col w-1/2 md:w-1/3 mb-30">
              <h3 className="text-label-sm mb-5">License Number</h3>
              <span key="1">{licenseNumberDisplay}</span>
            </div>

            {/* {# Term #} */}
            <div className="col w-1/2 md:w-1/3 mb-30">
              <h3 className="text-label-sm mb-5">License Term</h3>
              <span key="2">In Perpetuity</span>
            </div>

            {/* {# Type #} */}
            {(licenseSubLabel || name) && (
              <div className="col w-1/2 md:w-1/3 mb-30">
                <h3 className="text-label-sm mb-5">License Type</h3>
                <span key="3">{licenseSubLabel || name}</span>
              </div>
            )}

            {/* {# Distribution #} */}
            {licenseDist?.label && (
              <div className="col w-1/2 md:w-1/3 mb-30">
                <h3 className="text-label-sm mb-5">Distribution</h3>
                <span key="3">{licenseDist.label}</span>
              </div>
            )}

            {/* {# Territory #} */}
            {licenseTer?.label && (
              <div className="col w-1/2 md:w-1/3 mb-30">
                <h3 className="text-label-sm mb-5">Territory</h3>
                <span key="3">{licenseTer.label}</span>
              </div>
            )}

            {/* {# Budget #} */}
            {licenseBudg?.label && (
              <div className="col w-1/2 md:w-1/3 mb-30">
                <h3 className="text-label-sm mb-5">Budget</h3>
                <span key="3">{licenseBudg.label}</span>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default LicenseTab;

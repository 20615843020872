import React, { useContext, useEffect, useState } from "react";
import usersAPI from "../../api/users";
import LoadingContainer from "../Loading/LoadingContainer";
import StripeAccountLink from "../Stripe/StripeAccountLink";
import StripeTransactionsLink from "../Stripe/StripeTransactionsLink";
import { UserContext } from "../../contexts/User";
import currencyFormatter from "../../utils/currencyFormatter";
import SalesCard from "./SalesCard";
import SalesChart from "./SalesChart";

const SalesSummary = () => {
  const { user } = useContext(UserContext);
  const [allSales, setAllSales] = useState(null);
  const [allMonths, setAllMonths] = useState(null);
  const [allLicenseSales, setAllLicenseSales] = useState(null);
  const [licenseSalesTotal, setLicenseSalesTotal] = useState(null);
  const [allContentIDSales, setAllContentIDSales] = useState(null);
  const [contentIDSalesTotal, setContentIDSalesTotal] = useState(null);
  const [allSubscriptionPayouts, setAllSubscriptionPayouts] = useState(null);
  const [subscriptionPayoutsTotal, setSubscriptionPayoutsTotal] = useState(null);
  const [allTransfers, setAllTransfers] = useState(null);
  const [transfersTotal, setTransfersTotal] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);

  useEffect(() => {
    setIsLoaded(false);
    usersAPI.getSalesSummary().then((res) => {
      console.log("getAllSales res", res);
      const allSalesAmount = (res?.data?.allSales || 0) / 100;
      const allSubscriptionPayoutsAmount =
        (res?.data?.allSubscriptionPayouts?.payoutTotal || 0) / 100;
      const allLicenseSalesAmount = (res?.data?.allLicenseSales?.payoutTotal || 0) / 100;
      const allContentIDSalesAmount = (res?.data?.allContentIDSales?.payoutTotal || 0) / 100;
      const allTransfersAmount = (res?.data?.allTransfers?.payoutTotal || 0) / 100;
      setAllMonths(res?.data?.allMonths || []);
      setAllSales(allSalesAmount);
      setAllLicenseSales(res?.data?.allLicenseSales);
      setLicenseSalesTotal(allLicenseSalesAmount);
      setAllContentIDSales(res?.data?.allContentIDSales);
      setContentIDSalesTotal(allContentIDSalesAmount);
      setAllTransfers(res?.data?.allTransfers);
      setTransfersTotal(allTransfersAmount);
      setAllSubscriptionPayouts(res?.data?.allSubscriptionPayouts);
      setSubscriptionPayoutsTotal(allSubscriptionPayoutsAmount);
      setIsLoaded(true);
    });
  }, []);

  return (
    <LoadingContainer isLoaded={isLoaded}>
      <div className="flex flex-col md:flex-row items-stretch justify-between bg-gray-100 p-20 lg:p-25 mb-40">
        <div className="flex-1 flex flex-col justify-between pr-20 mb-30 md:mb-0">
          <div className="flex flex-col leading-snug mb-5">
            <span className="text-label-sm mb-5">User Since {user.since}</span>
            <span className="text-h4 text-white">{user?.name}</span>
          </div>
          <StripeAccountLink />
        </div>
        {/* {# Summary #} */}
        <div className="flex-none flex flex-col sm:flex-row">
          {/* {# Payout Summary #} */}
          <div className="flex flex-col flex-none text-right pr-30 lg:pr-60 leading-snug mb-30 md:mb-0">
            <span className="text-label-sm mb-3">Total Earnings</span>
            <span className="text-h3 text-white font-display leading-tight">
              {currencyFormatter(allSales)}
            </span>
          </div>
          {/* {# Payout Summary #} */}
          {/* <div className="flex flex-col flex-none text-right pr-30 lg:pr-60 leading-snug mb-30 md:mb-0">
            <span className="text-label-sm mb-3">Pending Payouts</span>
            <span className="text-h3 text-white font-display leading-tight">
              {currencyFormatter(balance)}
            </span>
          </div> */}
        </div>
        {/* {# Button #} */}
        <div className="flex-none flex flex-col leading-snug">
          <span className="text-label-sm mb-3">&nbsp;</span>
          <StripeTransactionsLink />
        </div>
      </div>
      <div className="grid-row-sm items-stretch">
        <SalesCard
          link="/dashboard/sales/licenses"
          title="Single Track Licenses"
          tooltipText={"Your total earnings for Individual Track Licenses Purchased"}
          totalAmount={licenseSalesTotal}
        />
        <SalesCard
          link="/dashboard/sales/subscription-royalties"
          title="Subscription Royalties"
          tooltipText={
            "Your Total earnings for Subscription Royalties from licenses generated by Paid Subscribers"
          }
          totalAmount={subscriptionPayoutsTotal}
        />
        <SalesCard
          link="/dashboard/sales/content-id"
          title="Content ID Royalties"
          tooltipText={"Your earnings of Content ID Royalties collected from YouTube"}
          totalAmount={contentIDSalesTotal}
        />
        <SalesCard
          link="/dashboard/sales/bonus-earnings"
          title="Bonus Earnings"
          tooltipText={
            "Any bonus earnings from contests or just thank yous from the Deep Sounds team"
          }
          totalAmount={transfersTotal}
        />
      </div>
      <SalesChart
        allLicenseSales={allLicenseSales}
        allSubscriptionPayouts={allSubscriptionPayouts}
        allContentIDSales={allContentIDSales}
        allTransfers={allTransfers}
        allMonths={allMonths}
        allSales={allSales}
      />
    </LoadingContainer>
  );
};

export default SalesSummary;

import React, { Component } from "react";
import Select, { components } from "react-select";
import { UserConsumer } from "../../contexts/User";
import customStyles from '../FormElements/customStyles';
import CustomValueContainer from '../FormElements/customValueContainer';
import describeOptions from '../FormElements/Options/describeOptions';
import CircularIndeterminateButton from "../Loading/circularIndeterminantButton";
import { Error } from "../Toast/index";
import api from "../utils/api";

const { ValueContainer, Placeholder } = components;

class SignUpDetails extends Component {
    static contextType = UserConsumer

    //the state for the login component keeps track for the email and password inputs
    state = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        bestDescribed: "",
        loginMessage: "",
        role: "customer",
        submitting: false
    }

    componentDidMount = ()=> {

    }

    //updating our input fields as the user enters keys
    handleInputChange = event => {
        const { name, value } = event.target;

        this.setState({
            [name]: value
        });
    }
    handleBestDecribed = (event)=> {
        this.setState({bestDescribed: event.value});
    }

    handleFormSubmit = (user) => event => {
        event.preventDefault();

        if (this.state.firstName && this.state.lastName && this.state.bestDescribed) {
            this.setState({
                submitting: true
            })
            let firstName = this.state.firstName
            let lastName = this.state.lastName
            let userBody = {
                firstName: firstName,
                lastName: lastName,
                email: user.email,
                bestDescribed: this.state.bestDescribed,
                signupStatus: 100,
            }


            api.signUpComplete(userBody)
                .then(res=>{

                    if (res.status === 200){
                        document.cookie = `name=${encodeURIComponent(firstName) + " " + encodeURIComponent(lastName)}; path=/;`
                        document.cookie = `role=${this.state.role}; path=/;`
                        setTimeout(()=>{window.location.replace("/signup/confirm")}, 500)
                    }
                })
                .catch(error=>{

                    // if(error.response?.status === 409){
                        this.setState({loginMessage: "Account signup could not be completed."})
                    // }
                })
        }
        else {
            Error({
                message: "Please enter all fields.",
                toastId: 'user-all-fields'
            })
            this.setState({
                loginMessage: "Please enter all fields.",
                submitting: false
            })
        }
    }
    render() {
        return (
            <UserConsumer>
                {({ user, login, logout})=>(
                    <>
                        <div className="hero-fixed">
                            <div className="hero-img" style={{backgroundImage: "url(/images/hero/login-02.jpg)"}}></div>
                        </div>
                        <div className="onboarding-main container container-xl">

                            <h1 className="ds-title mb-30 lg:mb-40">Sign Up</h1>

                            <h6 className="mb-30 lg:mb-40">Enter your account details.</h6>

                            <form className="onboarding-form" onSubmit={this.handleFormSubmit(user)}>

                                <div className="input-group-sm input-inline">
                                    <input className="form-input"
                                        value={user.firstName ? user.firstName : this.state.firstName}
                                        onChange={this.handleInputChange}
                                        type="text"
                                        name="firstName"
                                        placeholder="First Name *"
                                    />
                                    <label className="input-label" htmlFor="firstName">
                                        First Name <span class="input-label-required">*</span>
                                    </label>
                                </div>
                                <div className="input-group-sm input-inline">
                                    <input className="form-input"
                                        value={user.lastName ? user.lastName : this.state.lastName}
                                        onChange={this.handleInputChange}
                                        type="text"
                                        name="lastName"
                                        placeholder="Last Name *"
                                    />
                                    <label className="input-label" htmlFor="lastName">
                                        Last Name  <span class="input-label-required">*</span>
                                    </label>
                                </div>
                                {(this.state.role == "customer") &&
                                    <div className="input-group-sm input-inline">
                                        <Select
                                            name="bestDescribed"
                                            styles={customStyles}
                                            className="select-container"
                                            classNamePrefix="select"
                                            placeholder="Which best describes you? *"
                                            options={describeOptions}
                                            id="describe_select"
                                            onChange={this.handleBestDecribed}
                                            menuPlacement="top"
                                            defaultValue={user.bestDescribed}
                                            components={{
                                                ValueContainer: CustomValueContainer
                                              }}
                                        />
                                        <label className="input-label" htmlFor="bestDescribed">
                                            Which best describes you?  <span class="input-label-required">*</span>
                                        </label>
                                    </div>
                                }

                                <div className="input-group-sm input-inline">
                                    <h6 className="text-danger">{this.state.loginMessage}</h6>
                                </div>
                                <div className="input-group-sm">
                                    <button className="btn btn-primary form-btn w-full"
                                        name="login"
                                        type="submit"
                                    >
                                        {this.state.submitting ? <CircularIndeterminateButton /> : 'Next'}
                                    </button>

                                </div>
                                </form>
                            </div>

                    </>
                )}
            </UserConsumer>
        )
    }

}
export default SignUpDetails

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import TrackStats from "../ArtistTrackList/trackStats";
export default function AlbumTrackListItem(props) {
  const {
    artistName,
    artistURL,
    index,
    status,
    trackId,
    trackTitle,
    trackURL
  } = props;

  console.log('AlbumTrackListItem props', props);

  return (
    <div
      className={`track-list-card is-unplayable`}
    >
      <div className="track-first-col">
        <div className="track-pre">
          {/* {# Track Number #} */}
          <div className="track-number">{index + 1}</div>
          {/* {# Track Hover #} */}
        </div>
        <div className="track-title-artist">
          {status === "approved" ? (
            <NavLink to={"/tracks/" + trackURL}>
              <span className="track-title">{trackTitle}</span>
            </NavLink>
          ) : (
            <NavLink to={"/dashboard/new-track/" + trackId}>
              <span className="track-title">{trackTitle}</span>
            </NavLink>
          )}
          <NavLink to={"/artists/" + artistURL}>
            <span className="track-subtext">{artistName}</span>
          </NavLink>
        </div>
      </div>
      {status === "approved" ? (
        <TrackStats {...props} />
      ) : (
        <div className="track-stats">Track not approved yet.</div>
      )}
      <div className="track-last-col"></div>
    </div>
  );
}

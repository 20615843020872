import React, { createContext, useEffect, useState } from "react";
import { useHistory, useLocation, withRouter } from "react-router-dom";
import subscriptionsAPI from "../../api/subscriptions";
import { handleAPIError } from "../../utils/handleAPIError";

export const SubscriptionContext = createContext();

export const SubscriptionProvider = withRouter(({ children, props }) => {
  const defaultSubscriptionPrices = {
    commercial: {
      month: 4900,
      year: 24000
    },
    personal: {
      month: 1500,
      year: 10800,
    }
  }

  const history = useHistory();
  const location = useLocation();

  const [pricesByType, setPricesByType] = useState(null);
  const [promo, setPromo] = useState(null);
  const [subscriptionType, setSubscriptionType] = useState(null);

  const getPlanTypeFromURL = () => {
    const { pathname } = location || {};
    const pathnameParts = pathname.split("/");
    if (pathnameParts[2] !== 'subscription') {
      return;
    }
    const planType = pathnameParts[3];
    return planType;
  }
  const getPlanIntervalFromURL = () => {
    const { pathname } = location || {};
    const pathnameParts = pathname.split("/");
    if (pathnameParts[2] !== 'subscription') {
      return;
    }
    const planInterval = pathnameParts[4];
    return planInterval;
  }


  const planInterval = getPlanIntervalFromURL();

  const yearlyPrice = pricesByType?.find((price) => {
    return price?.recurring?.interval === "year";
  });

  const monthlyPrice = pricesByType?.find((price) => {
    return price?.recurring?.interval === "month";
  });

  const [selectedPrice, setSelectedPrice] = useState(null);
  const [checkoutType, setCheckoutType] = useState(null);
  const [finalPrice, setFinalPrice] = useState(null);

  // const reapplyPromo = () => {
  //   let decodedCookie = decodeURIComponent(document.cookie);
  //   let ca = decodedCookie.length ? decodedCookie.split(";") : [];
  //   let promoCookie = ca.filter(
  //     (cookieItem) => cookieItem.indexOf("subscriptionPromo") !== -1
  //   );
  //   if (promo) {
  //     setPromoCode(promo?.code);
  //   } else if (promoCookie?.length > 0) {
  //     let parsedPromoCookie = promoCookie[0].substring(
  //       promoCookie[0].indexOf("=") + 1
  //     );
  //     setPromoCode(parsedPromoCookie);
  //   }
  // };

  // const resetPrice = () => {
  //   setFinalPrice(null);
  // };

  // const resetSelectedPlan = () => {
  //   setSelectedPlan(null);
  //   setFinalPrice(null);
  // };

  // const setSubscriptionTerm = (planTerm) => {
  //   setSelectedPlanTerm(planTerm);
  //   if (planTerm && location.pathname === "/pricing") {
  //     history.replace({
  //       search: `term=${planTerm}`,
  //     });
  //   }
  // };

  // const setSubscriptionType = (planType) => {
  //   setSelectedPlanType(planType);
  //   getSubscriptionPrices(planType);
  //   if (planType && location.pathname === "/pricing") {
  //     history.replace({
  //       search: `type=${planType}`,
  //     });
  //   }
  // };

  // const submitPromo = () => {
  //   setPromoError(false);
  //   api
  //     .getSubscriptionPromo(
  //       promoCode,
  //       subscription?.product?.id
  //     )
  //     .then((res) => {
  //       if (res.data.success) {
  //         this.context.applyPromo(res.data.coupon);
  //       } else {
  //         Error({ message: res.data.reason });
  //         this.setState({ promoError: res.data.reason });
  //         this.context.removePromo();
  //         document.cookie = `subscriptionPromo=''; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT`;
  //       }
  //     });
  // };

  const getAlternatePrice = (selectedPrice) => {
    return pricesByType?.find((price) => {
      return price.id !== selectedPrice?.id;
    })
  }

  const getSubscriptionPrices = (planType) => {
    if (!planType) {
      planType = getPlanTypeFromURL();
    }
    setSubscriptionType(planType);
    // console.log("getting subscription prices");
    if (planType) {
      subscriptionsAPI
        .get(planType)
        .then((res) => {
          console.log("subscriptionsAPI.get res", res);
          setPricesByType(res.data.subscriptionPrices);
        })
        .catch((error) => {
          handleAPIError(error);
        });
    }
  };

  useEffect(() => {
    // console.log('setting selectedPrice', yearlyPrice);
    if (planInterval === 'month') {
      setSelectedPrice(monthlyPrice);
    }
    else {
      setSelectedPrice(yearlyPrice);
    }
  }, [pricesByType])

  useEffect(() => {
    if (!pricesByType) {
      getSubscriptionPrices();
    }
  }, [location]);

  // console.log('context selectedPrice', selectedPrice);

  const toggleSelectedPrice = () => {
    if (selectedPrice?.recurring?.interval === "year") {
      setSelectedPrice(monthlyPrice);
      history.replace('month');
    }
    if (selectedPrice?.recurring?.interval === "month") {
      setSelectedPrice(yearlyPrice)
      history.replace('year');
    }
  }

  // const setSelectedPlanCookie = (selectedPriceId) => {
  //   document.cookie = `selectedSubscription=${selectedPriceId}; path=/; expires=${new Date(
  //     Date.now() + 1000 * 60 * 60 * 24
  //   )}`;
  // };

  // const setSubscriptionFromLink = (planType, planTerm) => {};

  // useEffect(() => {
  //   getSubscriptionPrices(selectedPlanType);
  // }, [selectedPlanType]);

  return (
    <SubscriptionContext.Provider
      value={{
        checkoutType,
        defaultSubscriptionPrices,
        finalPrice,
        getAlternatePrice,
        getPlanIntervalFromURL,
        getPlanTypeFromURL,
        getSubscriptionPrices,
        promo,
        pricesByType,
        selectedPrice,
        setCheckoutType,
        subscriptionType,
        toggleSelectedPrice
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
});

export const SubscriptionConsumer = SubscriptionContext.Consumer;

import React, { Component } from "react";
import { Prompt } from 'react-router';
import Select, { components } from "react-select";
import { UserContext } from "../../contexts/User";
import { countries } from "../FormElements/countries";
import { states } from "../FormElements/states";
import ArtistCoverPhotoUpload from "../Dropzone/artistCoverPhotoUpload";
import ProfilePhotoUpload from "../Dropzone/profilePhotoUpload";
import customStyles from '../FormElements/customStyles';
import describeOptions from '../FormElements/Options/describeOptions';
import CircularIndeterminant from "../Loading/circularIndeterminant";
import { Error, Success, SuccessRedirect } from "../Toast/index";
import api from "../utils/api";

const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

class Details extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {
            shouldBlockNavigation: false,
            loginMessage: "",
            bio: this.props.details?.bio || "",
            isPopoutOpen: false,
            isCropUtilityOpen: false,
            userDetails: []
            }
    componentDidMount = ()=>{
        this.props.handleFormUnsaved(false)


    }
    componentDidUpdate = ()=>{
        if(this.state.country === undefined && this.props.details?.country){
            let countryOption = countries.filter(country=>country.name.toLowerCase() === this.props.details?.country?.toLowerCase())[0]
            let parsedCountryOption = {
                label: countryOption?.name,
                id: countryOption?.id,
                value: countryOption?.name,
                sortName: countryOption?.sortname
            }
            this.setState({country: parsedCountryOption})
        }
    }
    handleCropUtilityClose = (cropSaved)=>{
        this.setState({isCropUtilityOpen: false})

        if(cropSaved){
            cropSaved()
        }
        else{
        }
    }
    //updating our input fields as the user enters keys
    handleInputChange = event => {
        this.props.handleFormUnsaved(true)
        const { name, value } = event.target;
        // if(name==="bio"){
        //     if(value.length > 750){
        //         return
        //     }
        // }

        this.setState({
            userDetails: {
                ...this.state.userDetails,
                [name]: value
            }
        })
    }
    handleCountry = (event)=> {
        this.props.handleFormUnsaved(true)
        this.setState({
            userDetails: {
                ...this.state.userDetails,
                country: event.value
            },
            country: {
                ...event
            }
        });
    }
    handleState = (event)=>{
        this.props.handleFormUnsaved(true)
        this.setState({
            userDetails: {
                ...this.state.userDetails,
                state: event.value
            }
        });
    }
    handleBestDecribed = (event)=> {
        this.props.handleFormUnsaved(true)
        this.setState({
            userDetails: {
                ...this.state.userDetails,
                bestDescribed: event.value
            }
        });
    }
    handlePrimaryGenre = (event)=> {
        this.props.handleFormUnsaved(true)
        this.setState({
            userDetails: {
                ...this.state.userDetails,
                primaryGenre: event.value
            }
        });
    }
    handleSecondaryGenre = (event)=> {
        this.props.handleFormUnsaved(true)
        this.setState({
            userDetails: {
                ...this.state.userDetails,
                secondaryGenre: event.value
            }
        });
    }

    setprofilePhotoState = (file)=>{

        if(file.error){

            this.setState({profilePhotoError: file.error })
        }
        else{
            this.props.handleFormUnsaved(true)
            this.setState({
                file: file,
                profilePhotoError: false
            })
        }
    }
    setCoverPhotoState = (file)=>{
        if(file.error){
            this.setState({coverPhotoError: "File must be an image in a 1x1 ratio!"})
        }
        else{
            this.props.handleFormUnsaved(true)
            this.setState({
                file: file,
                coverPhotoError: false
            })
        }

    }
    updateProfilePhoto = updateProfilePhotoContext=> (event)=>{
        event.preventDefault()
            if(Array.isArray(this.state.file)){
                this.setState({isProfilePhotoLoading: true}, ()=>{
                    const data = new FormData()
                    data.append('file', this.state.file[0])

                    api.updateProfilePhoto(data)
                        .then(res=>{
                            this.props.handleFormUnsaved(false)
                            this.setState({isProfilePhotoLoading: false})

                            updateProfilePhotoContext(res.data.profilePhoto)
                            SuccessRedirect({message: "Profile Photo Updated", onClose: ()=>window.location.reload() })
                        })
                        .catch(error=>{
                            this.props.handleFormUnsaved(false)
                            this.setState({isProfilePhotoLoading: false})
                            Error({message: "Error Updating Profile Photo"})
                        })
                })
            }
            else{
                this.props.handleFormUnsaved(false)
                Error({message: "Error Updating Profile Photo"})
                return
            }

    }
    removeProfilePhoto = updatedProfilePhotoContext=> (event)=>{
        this.props.handleFormUnsaved(false)
        event.preventDefault()
        api.removeProfilePhoto()
        .then(res=>{
            updatedProfilePhotoContext(res.data.profilePhoto)
            SuccessRedirect({message: "Profile Photo Removed", onClose: ()=>window.location.reload() })
        })
        .catch(error=>{
            Error({message: "Error Removing Cover Photo"})
        })
    }
    updateCoverPhoto = (event)=>{
        event.preventDefault()
        if(Array.isArray(this.state.file)){
            this.setState({isCoverLoading: true}, ()=>{
                const data = new FormData()
                data.append('file', this.state.file[0])

                api.updateCoverPhoto(data)
                    .then(res=>{
                        this.props.handleFormUnsaved(false)
                        this.setState({isCoverLoading: false})

                        SuccessRedirect({message: "Cover Photo Updated", onClose: ()=>window.location.reload() })
                    })
                    .catch(error=>{
                        this.props.handleFormUnsaved(false)
                        this.setState({isCoverLoading: false})
                        Error({message: "Error Updating Cover Photo"})
                    })
            })
        }else{
            this.props.handleFormUnsaved(false)
            this.setState({isLoading: false})
            Error({message: "Error Updating Cover Photo"})
            return
        }

    }
    removeCoverPhoto = (event)=>{
        this.props.handleFormUnsaved(false)
        event.preventDefault()
        api.removeCoverPhoto()
        .then(res=>{
            SuccessRedirect({message: "Cover Photo Removed", onClose: ()=>window.location.reload() })
        })
        .catch(error=>{
            Error({message: "Error Removing Cover Photo"})
        })
    }
    saveAccountDetails = event=>{

        event.preventDefault()
        let {line1, line2, city, state, postal_code, country, shouldBlockNavigation, loginMessage, ...otherDetails} = this.state.userDetails

        if(line1 || line2 || city || state || postal_code || country){
            let billingAddress = {
                line1: line1 || this.props.details.line1,
                line2: line2 || this.props.details.line2,
                city: city || this.props.details.city,
                state: state || this.props.details.state,
                postal_code: postal_code || this.props.details.postal_code,
                country: country || this.props.details.country
            }



            api.updateBillingAddress(billingAddress)
                .then(res=>{

                    this.props.handleFormUnsaved(false)
                })
                .catch(error=>{
                    Error({message: "Error Updating Billing Address"})
                })
        }
        api.updateMyDetails(otherDetails)
            .then(res=>{

                this.props.handleFormUnsaved(false)
                Success({message: "Details Updated"})
            })
            .catch(error=>{
                Error({message: "Error Updating Details"})
            })
    }
    updateBio = event =>{
        event.preventDefault()
        if(this.state.userDetails.bio?.length >= 170 && this.state.userDetails.bio?.length <= 750 ){
            api.updateMyDetails({bio: this.state.userDetails.bio})
                .then(res=>{

                    Success({message: "Artist Bio Updated"})
                    this.props.handleFormUnsaved(false)
                })
                .catch(error=>{
                    Error({message: "Error Updating Artist Bio"})
                })
        }
        else{

            if(this.state.userDetails.bio?.length < 170){
                Error({message: "Artist Bio too short"})
            }
            else if (this.state.userDetails.bio?.length > 750) {
                Error({message: "Artist Bio too long"})
            }
        }
    }

    render() {
        return (

                        this.props.loaded ?
                        <>
                          <Prompt when={this.state.shouldBlockNavigation}
                    message={(location)=> window.location.pathname === location.pathname ? true: 'You have unsaved changes, are you sure you want to leave?'}></Prompt>
                    <div className="mb-50">
                        <h2 className="text-h3 text-white mb-20">Update Account Details</h2>
                        <p className="text-h4 text-gray-400 mb-0">Please use the form below to edit your account details.</p>
                        <h6 className="text-danger">{this.state.loginMessage}</h6>
                    </div>

                {/* {# Form Container #} */}
                <div className="w-full xl:w-2/3 mb-50 lg:mb-80">

                    {/* {# Form #} */}
                    <form  className="ds-form">

                        {/* {# Section #} */}
                        <div className="form-section">
                            <div className="grid-row-sm">
                            {((this?.context.user?.role === "artist") || (this?.context.user?.role === "admin") )&&
                                <div className="col w-full">
                                    <div className="input-group input-inline">
                                        <input
                                            disabled
                                            name="artistName"
                                            id="artist_name"
                                            className="form-input"
                                            placeholder="Artist Name"
                                            defaultValue={this.props.details.artistName}
                                        />
                                        <label className="input-label" htmlFor="artist_name">Artist Name</label>
                                    </div>
                                </div>
                            }
                             {((this?.context.user?.role === "artist") || (this?.context.user?.role === "admin")) &&
                            <>
                                <div className="col w-full md:w-1/2">
                                    <div className="input-group input-inline">
                                        <label className="input-label" htmlFor="primary_genre">Primary Genre</label>
                                        <Select
                                            name="primarygenre"
                                            styles={customStyles}
                                            className="select-container"
                                            classNamePrefix="select"
                                            placeholder="Primary Genre"
                                            options={this.props.details.genreOptions.filter(option=>option.id !== this.props.details.secondaryGenre?.id && option.id !== this.state.userDetails?.secondaryGenre).map(genreOption=>({value: genreOption.id, label: genreOption.name}))}
                                            id="primary_genre"
                                            onChange={this.handlePrimaryGenre}
                                            defaultValue={{ label: this.props.details.primaryGenre?.name, value: this.props.details.primaryGenre?.id }}
                                            components={{
                                                ValueContainer: CustomValueContainer
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col w-full md:w-1/2">
                                    <div className="input-group input-inline">
                                        <label className="input-label" htmlFor="secondary_genre">Secondary Genre</label>
                                        <Select
                                            name="secondarygenre"
                                            styles={customStyles}
                                            className="select-container"
                                            classNamePrefix="select"
                                            placeholder="Secondary Genre"
                                            options={this.props.details.genreOptions.filter(option=>option.id !== this.props.details.primaryGenre?.id && option.id !== this.state.userDetails?.primaryGenre).map(genreOption=>({value: genreOption.id, label: genreOption.name}))}
                                            id="secondary_genre"
                                            onChange={this.handleSecondaryGenre}
                                            defaultValue={{ label: this.props.details.secondaryGenre?.name, value: this.props.details.secondaryGenre?.id }}
                                            components={{
                                                ValueContainer: CustomValueContainer
                                            }}
                                        />
                                    </div>
                                </div>
                            </>}
                                <div className="col w-full md:w-1/2">
                                    <div className="input-group input-inline">
                                        <input
                                            name="firstName"
                                            id="first_name"
                                            className="form-input"
                                            placeholder="First Name"
                                            value={this.state.firstName}
                                            onChange={this.handleInputChange}
                                            defaultValue={this.props.details.firstName}
                                            />
                                        <label className="input-label" htmlFor="first_name">First Name</label>
                                    </div>
                                </div>

                                <div className="col w-full md:w-1/2">
                                    <div className="input-group input-inline">
                                        <input
                                            name="lastName"
                                            id="first_name"
                                            className="form-input"
                                            placeholder="First Name"
                                            value={this.state.lastName}
                                            onChange={this.handleInputChange}
                                            defaultValue={this.props.details.lastName}
                                            />
                                        <label className="input-label" htmlFor="last_name">Last Name</label>
                                    </div>
                                </div>

                                <div className="col w-full md:w-1/2">
                                    <div className="input-group input-inline">
                                        <input
                                            name="phone"
                                            id="phone"
                                            className="form-input"
                                            placeholder="Phone Number"
                                            value={this.state.phone}
                                            onChange={this.handleInputChange}
                                            defaultValue={this.props.details.phone}
                                            />
                                        <label className="input-label" htmlFor="phone">Phone Number</label>
                                    </div>
                                </div>

                                <div className="col w-full md:w-1/2">
                                    <div className="input-group input-inline">
                                        <input
                                            name="secondaryPhone"
                                            id="secondary_phone"
                                            className="form-input"
                                            placeholder="Secondary Phone"
                                            value={this.state.secondaryPhone}
                                            onChange={this.handleInputChange}
                                            defaultValue={this.props.details.secondaryPhone}
                                            />
                                        <label className="input-label" htmlFor="secondary_phone">Secondary Phone</label>
                                    </div>
                                </div>
                                {(this?.context.user?.role !== "artist") &&
                                    <div className="col w-full md:w-1/2">
                                        <div className="input-group input-inline">
                                            <input
                                                name="company"
                                                id="company"
                                                className="form-input"
                                                placeholder="Company"
                                                value={this.state.company}
                                                onChange={this.handleInputChange}
                                                defaultValue={this.props.details.company}
                                                />
                                            <label className="input-label" htmlFor="company">Company</label>
                                        </div>
                                    </div>
                                }
                                {(this?.context.user?.role !== "artist") &&
                                    <div className="col w-full md:w-1/2">
                                        <div className="input-group input-inline">
                                            <input
                                                name="position"
                                                id="company_role"
                                                className="form-input"
                                                placeholder="Role at Company"
                                                value={this.state.position}
                                                onChange={this.handleInputChange}
                                                defaultValue={this.props.details.position}
                                                />
                                            <label className="input-label" htmlFor="company_role">Role at Company</label>
                                        </div>
                                    </div>
                                }

                                <div className="col w-full md:w-1/2">
                                    <div className="input-group input-inline">
                                        <input
                                            name="line1"
                                            id="address1"
                                            className="form-input"
                                            placeholder="Address"
                                            value={this.state.line1}
                                            onChange={this.handleInputChange}
                                            defaultValue={this.props.details.line1}
                                            />
                                        <label className="input-label" htmlFor="address1">Address</label>
                                    </div>
                                </div>

                                <div className="col w-full md:w-1/2">
                                    <div className="input-group input-inline">
                                        <input
                                            name="line2"
                                            id="address2"
                                            className="form-input"
                                            placeholder="Apt / Ste/ Unit"
                                            value={this.state.line2}
                                            onChange={this.handleInputChange}
                                            defaultValue={this.props.details.line2}
                                            />
                                        <label className="input-label" htmlFor="address2">Apt / Ste / Unit</label>
                                    </div>
                                </div>
                                <div className="col w-full">
                                    <div className="input-group input-inline">
                                        <label className="input-label" htmlFor="country_select">Country</label>
                                        <Select
                                            name="country"
                                            styles={customStyles}
                                            className="select-container"
                                            classNamePrefix="select"
                                            placeholder="Country"
                                            options={countries.map(country=>{
                                                return{
                                                    label: country.name,
                                                    id: country.id,
                                                    value: country.name,
                                                    sortname: country.sortname
                                                }
                                            })}
                                            id="country_select"
                                            onChange={this.handleCountry}
                                            defaultValue={this.props.details?.country ? { label: this.props.details.country, value: this.props.details.country }: undefined}
                                            components={{
                                                ValueContainer: CustomValueContainer
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="col w-1/2 md:w-1/4">
                                    <div className="input-group input-inline">
                                        <input
                                            name="postal_code"
                                            id="zipcode"
                                            className="form-input"
                                            placeholder="Zipcode"
                                            value={this.state.zipcode}
                                            onChange={this.handleInputChange}
                                            defaultValue={this.props.details.postal_code}
                                            disabled={!this.state.country?.id && !this.props.details?.country}
                                        />
                                        <label className="input-label" htmlFor="zipcode">Zip / Postal Code</label>
                                    </div>
                                </div>
                                <div className="col w-1/2 md:w-1/2">
                                    <div className="input-group input-inline">
                                        <input
                                            name="city"
                                            id="city"
                                            className="form-input"
                                            placeholder="City"
                                            value={this.state.city}
                                            onChange={this.handleInputChange}
                                            defaultValue={this.props.details.city}
                                            disabled={!this.state.country?.id && !this.props.details?.country}
                                            />
                                        <label className="input-label" htmlFor="city">City</label>
                                    </div>
                                </div>

                                <div className="col w-full md:w-1/4">
                                    <div className="input-group input-inline">
                                        <label className="input-label" htmlFor="state_select">State / Province</label>
                                        <Select
                                            name="state"
                                            styles={customStyles}
                                            className="select-container"
                                            classNamePrefix="select"
                                            placeholder="State"
                                            options={states.filter(state=>state.country_id === (this.state.country?.id + "")).map(state=>{
                                                return{
                                                    label: state.name,
                                                    id: state.id,
                                                    value: state.name,
                                                    sortname: state.sortname
                                                }
                                            })}
                                            id="state_select"
                                            onChange={this.handleState}
                                            isDisabled={!this.state.country?.id && !this.props.details?.country}
                                            defaultValue={{ label: this.props.details.state, value: this.props.details.state }}
                                            components={{
                                                ValueContainer: CustomValueContainer
                                            }}
                                        />
                                    </div>
                                </div>




                                {(this?.context.user?.role !== "artist") &&
                                    <div className="col w-full">
                                        <div className="input-group input-inline">
                                            <label className="input-label" htmlFor="describe_select">Which best describes you?</label>
                                            <Select
                                                name="bestdescribed"
                                                styles={customStyles}
                                                className="select-container"
                                                classNamePrefix="select"
                                                placeholder="Which best describes you?"
                                                options={describeOptions}
                                                id="describe_select"
                                                onChange={this.handleBestDecribed}
                                                defaultValue={{ label: this.props.details.bestDescribed, value: this.props.details.bestDescribed }}
                                                components={{
                                                    ValueContainer: CustomValueContainer
                                                }}
                                            />
                                        </div>
                                    </div>
                                }
                                <div className="col w-full">
                                    <button type="button" onClick={this.saveAccountDetails} className="btn btn-primary form-btn w-full">Save Account Details</button>
                                </div>

                            </div>
                        </div>
                        <ProfilePhotoUpload removeProfilePhoto={this.removeProfilePhoto(this.context.updateProfilePhotoContext)} onChange={this.setprofilePhotoState} profilePhoto={this.context?.user.profilePhoto} updateProfilePhoto={this.updateProfilePhoto(this.context.updateProfilePhotoContext)} profilePhotoError={this.state.profilePhotoError}
                            openPopout={()=>{this.setState({isCropUtilityOpen: true});}} isCropUtilityOpen={this.state.isCropUtilityOpen} handleCropUtilityClose={this.handleCropUtilityClose} role={this.context?.user?.role} isLoading={this.state.isProfilePhotoLoading}></ProfilePhotoUpload>
                        {((this?.context.user?.role === "artist") || (this?.context.user?.role === "admin")) &&
                            <ArtistCoverPhotoUpload removeCoverPhoto={this.removeCoverPhoto} onChange={this.setCoverPhotoState} coverPhoto={this.props.details.coverPhoto} updateCoverPhoto={this.updateCoverPhoto} coverPhotoError={this.state.coverPhotoError} isLoading={this.state.isCoverLoading}></ArtistCoverPhotoUpload>
                        }
                        {/* {# Section #} */}
                        {((this?.context.user?.role === "artist") || (this?.context.user?.role === "admin") )&&
                            <div className="form-section">
                                <h3 className="text-label-lg mb-20">Artist Bio</h3>
                                <p className="mb-10">This will be your short bio on your public artist page. You need a minimum of 170 characters with a max of 750.</p>
                                <div className="input-group input-inline">
                                    <label className="input-label" htmlFor="text_area">Text Area</label>

                                    <textarea defaultValue={this.props.details.bio} name="bio" onChange={this.handleInputChange} value={this.state.userDetails.bio} id="text_area" className="form-textarea" rows="5" placeholder="Enter your artist bio here..."></textarea>
                                    <div style={{float: "right"}}>
                                        <span
                                            className={(this.state.userDetails.bio?.length < 170 || this.state.userDetails.bio?.length > 750 ) ? "error-message": ""}
                                            style={{fontSize: "0.9375rem"}}
                                        >
                                            {this.state.userDetails.bio?.length || this.props.details.bio?.length || 0}
                                        </span>
                                        <span>/750</span>
                                    </div>
                                </div>
                                <button type="button" onClick={this.updateBio} className="btn btn-primary w-full">Update Artist Bio</button>
                            </div>
                        }
                    </form>

                </div>

                </>:
                <CircularIndeterminant forceCenter/>
        )
    }

}
Details.contextType = UserContext
export default Details

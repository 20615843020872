import React from "react"
import FilterEnabled from "./FilterGroup/FilterEnabled"
import FilterDisabled from "./FilterGroup/FilterDisabled"

const FilterGroup = (props) => {
  const {
    addSearchOption,
    disabledFilterOptions,
    excludeSearchOption,
    filterOptions,
    groupTitle,
    isMobile,
    isOpen,
    selectedSearchOptions,
    toggleOpen,
    type
  } = props;

  // console.log('FilterGroup props', props);

  return (
    <li className={`filter-group ${isOpen ? " is-open": ""}`} >
      <button className="filter-header" type="button" onClick={toggleOpen}>
          {groupTitle}
      </button>
      <ul className="filter-subgroup"
          x-ref="subgroup_container"
      >
        {filterOptions.map((option, index) => (
          <FilterEnabled
            key={index + "=" + type}
            addSearchOption={addSearchOption}
            excludeSearchOption={excludeSearchOption}
            isMobile={isMobile}
            option={option}
            selectedSearchOptions={selectedSearchOptions}
            type={type}
          />
        ))}

        {(filterOptions.length > 0 && disabledFilterOptions?.length > 0) && (
          <div className="pt-15">
            {disabledFilterOptions.map((option, index) => (
              <FilterDisabled
                key={index + "=" + type}
                addSearchOption={addSearchOption}
                excludeSearchOption={excludeSearchOption}
                isMobile={isMobile}
                option={option}
                selectedSearchOptions={selectedSearchOptions}
                type={type}
              />
            ))}
          </div>
        )}
      </ul>
    </li>
  )
}
export default FilterGroup

import React, { useContext, useState } from "react";
import { UserContext } from "../../contexts/User";
import LoginForm from "../Login/LoginForm";
import SignUpForm from "../SignUp/signUpForm";

const LoginSignup = (props) => {
  const {
    login
  } = useContext(UserContext);
  const { checkoutType, reapplyPromo } = props;

  const [isLogin, setIsLogin] = useState(false);

  const toggleLogin = (value) => {
    setIsLogin(value);
  };

  return (
    <>
      {isLogin ? (
        <>
          <h1 class="modal-title mb-20">Sign In</h1>
          <p class="max-w-sm">
            Or{" "}
            <button
              onClick={() => toggleLogin(false)}
              className="text-link-gray text-gray-500 py-3"
            >
              sign up
            </button>{" "}
            for a new account.
          </p>
          <LoginForm
            login={login}
            loginAction="login"
            toggleLogin={toggleLogin}
          />
        </>
      ) : (
        <>
          <h1 class="modal-title mb-20">Sign Up</h1>
          <SignUpForm
            headerText={
              <p class="mb-30 max-w-sm">
                Create your account now to continue your {checkoutType === 'subscription' && 'subscription'} checkout,
                or{" "}
                <button
                  href="#"
                  onClick={() => toggleLogin(true)}
                  className="text-link-gray text-gray-500 py-3"
                >
                  sign in
                </button>{" "}
                if you already have an account.
              </p>
            }
            loaded={true}
            buttonText={"Sign Up"}
            login={login}
            loginCallback={reapplyPromo}
            redirect={"noredirect"}
            signUpLocation={"subscription-checkout"}
          />

          <div className="text-gray-400 text-sm pt-20">
            <p>
              Already have an account?{" "}
              <button
                onClick={() => toggleLogin(true)}
                className="text-link-gray text-gray-500 py-3"
              >
                Sign In.
              </button>
            </p>
          </div>
        </>
      )}
    </>
  );
};

export default LoginSignup;

import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { PlayerConsumer } from '../../PlayerContext';
import { UserConsumer } from '../../contexts/User';
import PlayerWaveform from '../Waveform/forPlayer';
import WaveformLoggedOut from '../Waveform/forPlayerLoggedOut';
import VideoStudioMediaPlayer from '../Waveform/forPlayerVideoStudioActive';



class Player extends Component {
    state = {
        src: this.props.src || "",
		peaks: this.props.peaks,
		play: true,
        currentTime: "0:00"
    }

  componentDidMount() {
  }
  componentWillUnmount() {

  }
  render() {
    return (
      <UserConsumer>
        {({ user, login, logout, triggerLoginModal})=>(

          <PlayerConsumer>
            {({currentTrack, mediaPlayer, visible, playList, trackIndex, currentTime, playing, hidePlayer, showPlayer,  setCurrentTime,
            isMainOpen, isDemoOpen, videoElement, seekTo, playVideo, playVideoFromIn, isLoaderOpen, playVideoLoop, pauseVideo, isVideoPlaying, isLoopEnabled })=>(
              visible === true ?
                <>
                  {user !== null || window.location.pathname.indexOf("/projects/public") !==-1 || ((isDemoOpen || isLoaderOpen) && window.location.pathname === "/") ?
                    isMainOpen ?
                    <VideoStudioMediaPlayer history={this.props.history} user={user} playVideo={playVideo} playVideoFromIn={playVideoFromIn} playVideoLoop={playVideoLoop} isVideoPlaying={isVideoPlaying} pauseVideo={pauseVideo}  triggerLoginModal={triggerLoginModal}  playing={playing} artistName={mediaPlayer.artistNameDisplay}  setCurrentTime={this.setCurrentTime} play={this.state.play}  peaks={mediaPlayer.peaks} videoElement={videoElement} seekTo={seekTo}
                      isLoopEnabled={isLoopEnabled}></VideoStudioMediaPlayer>:
                    <PlayerWaveform history={this.props.history} user={user} isDemoOpen={isDemoOpen}   triggerLoginModal={triggerLoginModal}  playing={playing} artistName={mediaPlayer.artistNameDisplay}  setCurrentTime={this.setCurrentTime} play={this.state.play}  peaks={mediaPlayer.peaks}></PlayerWaveform>:
                    <WaveformLoggedOut history={this.props.history}  isDemoOpen={isDemoOpen} triggerLoginModal={triggerLoginModal}   playing={playing} artistName={mediaPlayer.artistNameDisplay}  setCurrentTime={this.setCurrentTime} play={this.state.play}  peaks={mediaPlayer.peaks}></WaveformLoggedOut>
                  }
                </>
              : <></>)}
          </PlayerConsumer>)}
      </UserConsumer>
    )
  }
}

Player.defaultProps = {
  src: "",
}
export default withRouter(Player)

import buildAlbumArtURL from "../../Albums/AlbumArt/index.js";

const formatAlbumLabel = ({ value, label, imageUrl }) => (
  <div className="flex items-center">
      <img
          src={buildAlbumArtURL(imageUrl)}
          alt="Album Art"
          className="w-30 h-30 bg-gray-300 mr-15"
      />
      {label}
  </div>
)

export default formatAlbumLabel;

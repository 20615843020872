import React from 'react'
import { NavLink } from "react-router-dom"
import { Tooltip } from "react-tippy"
import 'react-tippy/dist/tippy.css'
import ArtistCardToolTip from "./artistCardToolTip"
const resolveArtistPhoto=(artistPhotoKey)=>{
    const key = artistPhotoKey
    if(artistPhotoKey){
        const ext = key.split('.').reverse()[0];
        let sizeMod;
        let width = window.innerWidth
        if(width > 1720){
            sizeMod = '500x500'
        }
        else if(width > 1280 && width < 1720){
            sizeMod = '325x325'
        }
        else if(width > 1190 && width < 1280){
            sizeMod = '500x500'
        }
        else if(width > 1024 && width < 1190){
            sizeMod = '325x325'
        }
        else if(width > 810 && width < 1024){
            sizeMod = '500x500'
        }
        else{
            sizeMod = '325x325'
        }
        let final = key.replace('.' + ext, '') + '-minified-' + sizeMod + '.' + ext
        return final
    }
}
const ArtistCard =(props)=> (<div className="track-grid-card">
    <div className="track-image">
        <img src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${resolveArtistPhoto(props.profilePhoto)}`} alt={props.artistName + " profile picture"}/>
        <div className="track-image-hover">
            {((props.contextIdKey?.indexOf(`artistItemCard-${props.artistId}`) || -1) !==-1 ) && props.isPlaying ?
                <div onClick={props.handlePause} className="circle-icon-fill circle-icon-xl bg-white text-black">
                    <span  className="icon-pause"></span>
                </div>:
                ((props.contextIdKey?.indexOf(`artistItemCard-${props.artistId}`) || -1) !==-1 ) ?
                <div onClick={props.handlePlay} className="circle-icon-fill bg-white text-black circle-icon-xl">
                    <span  className="icon-play"></span>
                </div>:
                <div onClick={()=>{props.play(props.tracks, props.idKey)}} className="circle-icon-fill bg-white text-black circle-icon-xl">
                    <span  className="icon-play"></span>
                </div>
            }
            <div className="wav-form-vector">
                {/* {# Wav SVG #} */}
                {/* {% include "components/audio/waveform-lg.html" %} */}
            </div>
        </div>
    </div>
    <div className="track-grid-content">
        <div className="track-first-col">
            <div className="track-title-artist">
        <NavLink
          className="track-title truncate inline-block"
          to={"/artists/" + props.artistURL}
        >
          {props.artistName}
        </NavLink>

                {/* {# Meta Info #} */}
                <div className="flex w-full items-center ml-3">

                    <div className="flex items-center mr-10">
                        {props.searchTerms > 0 ?
                           <NavLink to={`browse?${"artist="+encodeURIComponent(props.artistURL)+"&"+ window.location.search.substring(1) +'&page=1&sort=Rel'}`}>
                                <div className="flex items-center mr-10">
                                    <span className="icon-tracks text-gray-400 text-icon-sm mr-3"></span>
                                    {props.tracks.length}  of {props.totalTracks} Tracks Matched
                                </div>
                            </NavLink>:
                             <>

                                <div className="flex items-center mr-10">
                                    <span className="icon-tracks text-gray-400 text-icon-sm mr-3"></span>
                                    <NavLink to={'/artists/'+ props.artistURL}>
                                      {props.tracks.length} <span className="hidden sm:inline">Tracks</span>
                                    </NavLink>
                                </div>
                                <div className="flex items-center mr-10">
                                    <span className="icon-albums text-gray-400 text-icon-sm mr-3"></span>
                                    <NavLink to={'/artists/'+ props.artistURL}>
                                      {props.albums.length} <span className="hidden sm:inline">Albums</span>
                                    </NavLink>
                                </div>

                         </>
                        }
                    </div>
                </div>

            </div>
        </div>
        <div className="genres-stems-versions items-top">
            <div className="track-genres text-gray-400">
                {props.primaryGenre &&
                    <span>{props.primaryGenre}</span>
                }
                {props.secondaryGenre &&
                    <span>{props.secondaryGenre}</span>
                }
            </div>
        </div>
        <div className="track-grid-icons">
            <div className="btn-group" x-data="{ followed: false }">
                {/* {# Like #} */}
                {props.following ?
                    <button
                    className={"circle-icon btn-jelly js-tooltip " + ('is-followed hover:text-primary')}
                        title={"Follow " +props.artistName}
                        type="button"
                        onClick={()=>props.unfollowArtist(props.artistId, props.artistName)}
                    >
                        <span className={'icon-following-heart'}></span>
                    </button>
                    :
                    <button
                    className={"circle-icon btn-jelly js-tooltip "}
                        title={"Follow " +props.artistName}
                        type="button"
                        onClick={()=>props.followArtist(props.artistId, props.artistName)}
                    >
                        <span className={'icon-follow-heart'}></span>
                    </button>
                }
            </div>
            <div>
                <Tooltip
                    arrow={false}
                    open={props.isArtistTippyOpen}
                    interactive={true}
                    position={'bottom-end'}
                    popperOptions={{
                        modifiers: {
                            preventOverflow: { enabled: false },
                            flip: { enabled: false },
                            hide: { enabled: false },
                            addZIndex: {
                                enabled: true,
                                order: 810,
                                fn: data => ({
                                    ...data,
                                    styles: {
                                    ...data.styles,
                                    zIndex: 10,
                                    },
                                })
                            }
                         }
                    }}
                    trigger={'click'}
                    useContext={true}
                    // hideOnClick={true}
                    html={(<ArtistCardToolTip
                                artistURL={props.artistURL}
                                artistId={props.artistId}
                                tracks={props.tracks}
                                playTracks={props.playTracks}
                                shareArtist={props.shareArtist}
                            >
                        </ArtistCardToolTip>
                    )}
                    allowHTML={true}
                    onUntrigger={()=>props.toggleArtistCardTippy(props.artistId)}
                    onRequestClose={()=>props.toggleArtistCardTippy(props.artistId)}
                >
                <div className="circle-icon">
                    <span onClick={()=>props.toggleArtistCardTippy(props.artistId)} className="icon-dots-vert"></span>
                </div>
                </Tooltip>
            </div>
        </div>
    </div>
</div>)
export default ArtistCard

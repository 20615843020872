import React, { useState } from "react";
import currencyFormatter from "../../../utils/currencyFormatter";
import contentIDAPI from "../../../api/contentID";
import LoadingButton from "../../FormElements/LoadingButton";
import { Success, Error } from "../../Toast";
import RoyaltyPaymentsRows from "./RoyaltyPaymentsRows";
import { DateTime } from "luxon";

const RoyaltyPaymentsQuarter = ({ contentIDPayoutsQuarter }) => {
  const {
    quarter,
    payouts,
    payout_date,
    status: defaultStatus,
    totalPayoutAmount,
    totalArtists,
  } = contentIDPayoutsQuarter || {};

  const [isLoaded, setIsLoaded] = useState(true);
  const [showRows, setShowRows] = useState(false);
  const [status, setStatus] = useState(defaultStatus);

  const toggleDropdown = () => {
    setShowRows(!showRows);
  };

  const approvePayout = () => {
    setIsLoaded(false);
    contentIDAPI
      .approvePayout({ quarter })
      .then((response) => {
        console.log("approvePayout response", response);
        const { status } = response?.data?.contentIDPayout || {};
        setStatus(status);
        setIsLoaded(true);
        Success({ message: "Content ID payout approved." });
      })
      .catch((error) => {
        console.log("approvePayout error", error);
        setIsLoaded(true);
        Error({ message: "There was an error approving the payout." });
      });
  };

  const cancelPayout = () => {
    setIsLoaded(false);
    contentIDAPI
      .cancelPayout({ quarter })
      .then((response) => {
        console.log("cancelPayout response", response);
        const { status } = response?.data?.contentIDPayout || {};
        setStatus(status);
        setIsLoaded(true);
        Success({ message: "Content ID payout cancelled." });
      })
      .catch((error) => {
        console.log("cancelPayout error", error);
        setIsLoaded(true);
        Error({ message: "There was an error cancelling the payout." });
      });
  };

  // console.log("contentIDPayoutItemsFormatted", contentIDPayoutItemsFormatted);

  return (
    <>
      <tr style={{ maxWidth: "100%" }}>
        <td>
          <button className="text-white underline" onClick={toggleDropdown}>
            {quarter}
          </button>
        </td>
        <td>{totalArtists}</td>
        <td>{currencyFormatter(totalPayoutAmount / 100)}</td>
        <td>{DateTime.fromISO(payout_date).toFormat("yyyy-MM-dd")}</td>
        <td>{status}</td>
        <td>
          <LoadingButton
            className={status === "approved" ? "btn btn-stroke  w-1/2" : "btn btn-primary w-1/2"}
            isLoading={!isLoaded}
            isDisabled={status === "approved"}
            onClick={status === "approved" ? null : approvePayout}
          >
            {status === "approved" ? (
              <>
                <span className="icon-check"></span> Approved
              </>
            ) : (
              "Approve"
            )}
          </LoadingButton>
        </td>
      </tr>

      <tr className={showRows ? "" : "hidden"}>
        <td colSpan="6">
          {payouts?.length > 0 ? <RoyaltyPaymentsRows payouts={payouts} /> : null}
        </td>
      </tr>
    </>
  );
};

export default RoyaltyPaymentsQuarter;

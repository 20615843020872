import React, { Component } from "react";

class SceneTooltip extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {

            }

    render() {
        return (    
                <div className="dropdown-menu">
                    <div className="dropdown-group">
                        <a href="#" onClick={()=>this.props.handleEditSceneModalOpen()}>Rename Scene</a>
                        <a href="#" onClick={()=>this.props.removeScene(this.props.sceneId, this.props.sceneIndex)}>Delete Scene</a>
                    </div>                   
                </div>
        )
    }

}
export default SceneTooltip
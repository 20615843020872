import React, { Component } from "react";
import BackdropOverlay from "../LoadingOverlay/backdrop";
import api from "../utils/api";
import PayoutRow from "./payoutRow";

class PayoutTable extends Component {
  state = {};
  recalculatePayout = (payoutId, index) => {
    this.props.setPayoutLoading(index, true);
    api.recalculatePayoutFor(payoutId).then((res) => {
      if (res?.data?.subscriptionPayout?.id) {
        this.props.updatePayouts(index, res.data.subscriptionPayout);
      }
    });
  };
  calculatePayout = (forMonth, index) => {
    this.props.setPayoutLoading(index, true);
    api.generateSubscriptionPayout(forMonth).then((res) => {
      if (res?.data?.subscriptionPayout?.id) {
        this.props.updatePayouts(index, res.data.subscriptionPayout);
      }
    });
  };
  render() {
    let totalApprovedTracks = 0;
    let totalSubscriptionLicenseRevenue = 0;
    let totalSeniorityDays = 0;
    let totalSeniorityRate = 0;
    let totalSubscriptionPayRate = 0;
    let totalApprovedTracksRate = 0;
    let totalPotPercentage = 0;
    let totalPayoutTotal = 0;
    return (
      <div class="bg-gray-100 p-20 pb-10 lg:p-25 lg:pb-15 mb-40">
        {/* {# Table Header #} */}
        <div class="flex flex-col md:flex-row items-start md:items-center justify-between mb-30 lg:mb-40">
          {/* {# Title #} */}
          <div class="flex-1 flex items-center mb-15 md:mb-0">
            <h3 class="text-label-lg mr-10">Artist Pools</h3>
          </div>
        </div>

        {/* // {# Table #} */}
        <div class="table-wrap ds-scrollbar">
          <table class="stats-table w-full">
            <thead class="text-left">
              <tr>
                <th class="text-label-sm">For</th>
                <th class="text-label-sm">Subscription Revenue</th>
                <th class="text-label-sm">Subscription Revenue Less Fees</th>
                <th class="text-label-sm">Artist Pool</th>
                <th class="text-label-sm">Artist Pool Percent</th>
                <th class="text-label-sm">Total License Revenue</th>
                <th class="text-label-sm">License Revenue Weight</th>
                <th class="text-label-sm">Total Approved Tracks</th>
                <th class="text-label-sm">Approved Tracks Weight</th>
                <th class="text-label-sm">Total Seniority Days</th>
                <th class="text-label-sm">Seniority Weight</th>
                <th class="text-label-sm">Status</th>
                <th class="text-label-sm">Tools</th>
              </tr>
            </thead>
            {!this.props.arePayoutsLoaded ? (
              <tr style={{ height: "444px", width: "100%" }}>
                <td style={{ height: "444px" }} colSpan={5}>
                  <BackdropOverlay
                    invisible={true}
                    loading={!this.props.arePayoutsLoaded}
                  />
                </td>
              </tr>
            ) : (
              <tbody>
                {this.props.shouldShowLatest && (
                  <PayoutRow
                    isLatestLoading={this.props.isLatestLoading}
                    calculatePayout={this.calculatePayout}
                    forMonth={this.props.latestForMonth}
                  />
                )}
                {/* {% for item in purchases %} */}
                {this.props?.subscriptionPayouts.map((payout, index) => {
                  totalApprovedTracks += payout.approvedTracks;
                  totalSubscriptionLicenseRevenue +=
                    payout.subscriptionLicenseRevenue;
                  totalSeniorityDays += payout.seniorityDays;
                  totalSubscriptionPayRate += payout.subscriptionPayRate;
                  totalApprovedTracksRate += payout.approvedTracksPayRate;
                  totalSeniorityRate += payout.seniorityPayRate;
                  totalPotPercentage += payout.potPercentage;
                  totalPayoutTotal += payout.payoutTotal;
                  return (
                    <PayoutRow
                      key={`subscriptionPayout-${payout.id}`}
                      {...payout}
                      index={index}
                      recalculatePayout={this.recalculatePayout}
                      getArtistSubscriptionPayouts={
                        this.props.getArtistSubscriptionPayouts
                      }
                      handlePWModalOpen={this.props.handlePWModalOpen}
                      handlePWModalOpenForReapprove={
                        this.props.handlePWModalOpenForReapprove
                      }
                      handlePWModalOpenForUnapprove={
                        this.props.handlePWModalOpenForUnapprove
                      }
                      calculatePayout={this.calculatePayout}
                      handlePWModalOpenForApproveAndDistribute={
                        this.props.handlePWModalOpenForApproveAndDistribute
                      }
                    />
                  );
                })}

                {/* {% endfor %} */}
              </tbody>
            )}
          </table>
        </div>

        {/* // {# Pagination #}
                // {% include "components/tables/table-pagination.html" %} */}
      </div>
    );
  }
}
export default PayoutTable;

import axios from "axios";

const cartsAPI = {
  getMyActive: function() {
    return axios({
      method: 'get',
      url: '/api/carts/myActive',
    })
  },
  createCartItem: function({ license }) {
    return axios({
      method: 'post',
      url: '/api/carts/myActive/cartItems',
      data: { license }
    })
  },
  updateCartItem: function({ index, license = null, project = null }) {
    return axios({
      method: 'patch',
      url: '/api/carts/myActive/cartItems/' + index + '',
      data: { license, project }
    })
  },
  removeCartItem: function({ index }) {
    return axios({
      method: 'delete',
      url: '/api/carts/myActive/cartItems/' + index
    })
  },
  sync: function({ sessionCartInfo }) {
    return axios({
      method: 'post',
      url: '/api/carts/myActive/sync',
      data: { sessionCartInfo }
    })
  },
  createOrUpdate: function({ cartInfo }) {
    return axios({
      method: 'post',
      url: '/api/carts',
      data: { cartInfo }
    })
  },
  removePromo: function ({ checkoutType }) {
    return axios({
      method: 'put',
      url: '/api/carts/removePromo',
      data: { checkoutType }
    })
  }
};
export default cartsAPI;

import React from "react"

const Event =  (props)=>{

    return <div className="w-full md:w-1/2 lg:w-1/3 xl:w-1/4 px-40 xl:px-20 mb-30">
                <div className="event-item flex">
	                <div className="w-40 mr-5 leading-tight">
	                	<div className="text-h3 font-display font-medium text-white">{props.day}</div>
	                	<div className="text-label-sm text-gray-400">{props.month}</div>
	                </div>
	                <div className="block flex-1 mt-3 pr-10">
	                	<div className="text-white font-medium leading-snug">{props.title}</div>
	                	<div className="text-sm">{props.venueName}, {props.venueCity}</div>
	                	<div className="text-sm">{props.dayOfWeek}, {props.time}</div>
	                </div>
	                <button className="circle-icon-border self-center">
                        <a href={props.url} target="_blank" rel="noreferer"><span className="icon-chevron-right"></span></a>
	                </button>
                </div>
            </div>

}

export default Event
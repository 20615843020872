import React, { Component } from "react"
import { Helmet } from "react-helmet"
import MicroModal from "react-micro-modal"
import wav1 from '../../assets/placeholders/wav-01.png'
import wav2 from '../../assets/placeholders/wav-02.png'
import wav3 from '../../assets/placeholders/wav-03.png'
import wav4 from '../../assets/placeholders/wav-04.png'
import wav5 from '../../assets/placeholders/wav-05.png'
import wav6 from '../../assets/placeholders/wav-06.png'
import { PlayerContext } from "../../PlayerContext"
import { UserConsumer } from "../../contexts/User"
import CircularIndeterminant from "../Loading/circularIndeterminant"
import NotFound from "../NotFound"
import { Error } from "../Toast/index"
import api from "../utils/api"
import EnterPWModal from "./enterPWModal"
import ProjectAccordion from "./projectAccordion"
import ProjectEditHeaderPublic from "./projectEditHeaderPublic"
import './styles.css'


const wavList = [wav1, wav2, wav3, wav4, wav5, wav6]
const addSceneModalPromt =(handleOpen)=> <a onClick={handleOpen} href="#" class="prompt cursor-pointer" data-micromodal-trigger="add_scene_modal">
<div class="prompt-content">
    {/* {# Icon #} */}
    <div class="text-icon-xl mb-15">
        <span class="icon-add-scene-lg"></span>
    </div>
    {/* {# Heading #} */}
    <h3 class="prompt-title">Add some scenes to your project</h3>
    {/* {# Description #} */}
    <p class="max-w-sm">Mauris non tempor quam, et lacinia sapien, Mauris accumsan eros eget.</p>
</div>
</a>
const sortFilters= [{filter: "NameA", displayText: "Name - Asc"}, {filter: "NameD", displayText: "Name - Desc"}, {filter: "VotesA", displayText: "Votes - Asc"}
,{filter: "VotesD", displayText: "Votes - Desc"}]

class PublicPage extends Component{
    state={
        newSceneName: "",
        scenes: [],
        isProjectDetailTippyOpen: false,
        sortText: "Votes - Desc",
        isSceneModalOpen: false,
        isProjectEditModalOpen: false,
        projectTypes: [{label: "Commercial", value: 1}, {label: "Movie", value: 2}],
        isPWReqModalOpen: false,
        randomWavIndex: Math.round(Math.random() *5)

    }
    toggleProjectDetailTippy = ()=>{
        this.setState({isProjectDetailTippyOpen: !this.state.isProjectDetailTippyOpen})
    }
    closeProjectDetailTippy = ()=>{
        this.setState({isProjectDetailTippyOpen: false})
    }
    updateFollowing = (artistId)=>{
        let sceneTracks = [...this.state.sceneTracks]
        sceneTracks.forEach((sceneTrack, index)=>{

            if(sceneTrack.tracks.artistId === artistId){
                sceneTracks[index].tracks.following = sceneTracks[index].tracks.following === 1 ? 0: 1
            }
        })
        this.setState({sceneTracks: sceneTracks})
    }
    sortBy = (sortChoice, sortText, overRide, tippyStateCB, currentSortChoice)=>{
        let unsortedSceneTracks = [...this.state.sceneTracks]
        let compare;
        let sorted;
        if(sortChoice !== currentSortChoice || overRide){
            switch(sortChoice){
                case "NameA":
                    compare = (a, b)=> {
                        // Use toUpperCase() to ignore character casing
                        const aName = a.tracks.trackTitle.toUpperCase();
                        const bName = b.tracks.trackTitle.toUpperCase();

                        let comparison = 0;
                        if (aName < bName) {
                          comparison = -1;
                        } else if (aName > bName) {
                          comparison = 1;
                        }
                        return comparison;
                      }
                    sorted = unsortedSceneTracks.sort(compare)
                    break;
                case "NameD":
                    compare = (a, b)=> {
                        // Use toUpperCase() to ignore character casing
                        const aName = a.tracks.trackTitle.toUpperCase();
                        const bName = b.tracks.trackTitle.toUpperCase();

                        let comparison = 0;
                        if (aName < bName) {
                          comparison = -1;
                        } else if (aName > bName) {
                          comparison = 1;
                        }
                        return comparison*-1;
                      }
                    sorted = unsortedSceneTracks.sort(compare)
                    break;
                case "VotesD":
                    sorted = unsortedSceneTracks.sort(function(sceneTrack1, sceneTrack2){
                        Object.filter = (obj, predicate) =>
                        Object.keys(obj)
                          .filter( key => predicate(obj[key]) )
                          .reduce( (res, key) => (res[key] = obj[key], res), {} );
                        let sceneTrack1PosVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===1)
                        let sceneTrack1NegVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===0)
                        let sceneTrack2PosVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===1)
                        let sceneTrack2NegVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===0)
                        sceneTrack1.score = Object.keys(sceneTrack1PosVotes).length - Object.keys(sceneTrack1NegVotes).length
                        sceneTrack2.score = Object.keys(sceneTrack2PosVotes).length - Object.keys(sceneTrack2NegVotes).length

                        if(sceneTrack1.score > sceneTrack2.score) return -1
                        if(sceneTrack1.score < sceneTrack2.score) return 1
                        if(sceneTrack1.sceneTrackAdded > sceneTrack2.sceneTrackAdded) return 1
                        if(sceneTrack1.sceneTrackAdded < sceneTrack2.sceneTrackAdded) return -1
                    })
                    break;
                case "VotesA":
                    sorted = unsortedSceneTracks.sort(function(sceneTrack1, sceneTrack2){
                        Object.filter = (obj, predicate) =>
                        Object.keys(obj)
                          .filter( key => predicate(obj[key]) )
                          .reduce( (res, key) => (res[key] = obj[key], res), {} );
                        let sceneTrack1PosVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===1)
                        let sceneTrack1NegVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===0)
                        let sceneTrack2PosVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===1)
                        let sceneTrack2NegVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===0)
                        sceneTrack1.score = Object.keys(sceneTrack1PosVotes).length - Object.keys(sceneTrack1NegVotes).length
                        sceneTrack2.score = Object.keys(sceneTrack2PosVotes).length - Object.keys(sceneTrack2NegVotes).length

                        if(sceneTrack1.score > sceneTrack2.score) return 1
                        if(sceneTrack1.score < sceneTrack2.score) return -1
                        if(sceneTrack1.sceneTrackAdded > sceneTrack2.sceneTrackAdded) return 1
                        if(sceneTrack1.sceneTrackAdded < sceneTrack2.sceneTrackAdded) return -1
                    })
            }
            this.setState({sceneTracks: sorted, sortText: sortText}, ()=>tippyStateCB(sortChoice))
        }
        else{
            tippyStateCB(sortChoice)
        }
    }
    handleInputChange = (event)=>{

        this.props.handleFormUnsaved(true)
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value,
        });
    }

    handleSceneModalOpen = ()=>{
        this.setState({isSceneModalOpen: true, isProjectDetailTippyOpen: false})
    }

    handleSceneModalClose=()=>{

        this.setState({isSceneModalOpen: false})
    }
    handleProjectEditModalOpen = ()=>{
        this.setState({isProjectEditModalOpen: true, isProjectDetailTippyOpen: false})
    }
    handleProjectEditModalClose=()=>{

        this.setState({isProjectEditModalOpen: false})
    }
    handlePWModalOpen = ()=>{
        this.setState({isPWReqModalOpen: true})
    }

    handlePWModalClose=()=>{

        this.setState({isPWReqModalOpen: false})
    }
    componentDidUpdate = (prevProps)=>{

        if(prevProps.match.params.id !== this.props.match.params.id){
            api.getProjectById(this.props.match.params.id)
            .then(res=>{
                let projectTypes = []
                api.getAllProjectTypes()
                .then(res=>{
                    res.data.forEach(projType=>{
                        projectTypes.push({label: projType.name, value: projType.id})
                    })
                    this.setState({projectTypes: projectTypes})
                })

                let {scenes, ...project} = res.data
                let sceneTracks = []
                scenes.forEach(scene=>{
                    sceneTracks = sceneTracks.concat(scene.sceneTracks)
                })
                let sceneTrackVotes = []
                let allTracks = []

                sceneTracks = sceneTracks.map(sceneTrack=>{
                    const keyedVotes = {}

                    sceneTrack.sceneTrackVotes =sceneTrack.sceneTrackVotes.reduce((obj, item)=>{
                        return{
                            ...obj,
                            [item.sTVID]: item,
                        };
                    }, keyedVotes)

                    if(allTracks.findIndex(track=>track.trackId === sceneTrack.tracks.trackId) ===-1){
                        allTracks.push({...sceneTrack.tracks})
                    }
                    return sceneTrack

                })
                this.context.updateLoadedTracks(allTracks)

                sceneTracks = sceneTracks.sort(function(sceneTrack1, sceneTrack2){
                    Object.filter = (obj, predicate) =>
                    Object.keys(obj)
                      .filter( key => predicate(obj[key]) )
                      .reduce( (res, key) => (res[key] = obj[key], res), {} );
                    let sceneTrack1PosVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===1)
                    let sceneTrack1NegVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===0)
                    let sceneTrack2PosVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===1)
                    let sceneTrack2NegVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===0)
                    sceneTrack1.score = Object.keys(sceneTrack1PosVotes).length - Object.keys(sceneTrack1NegVotes).length
                    sceneTrack2.score = Object.keys(sceneTrack2PosVotes).length - Object.keys(sceneTrack2NegVotes).length

                    if(sceneTrack1.score > sceneTrack2.score) return -1
                    if(sceneTrack1.score < sceneTrack2.score) return 1
                    if(sceneTrack1.sceneTrackAdded > sceneTrack2.sceneTrackAdded) return 1
                    if(sceneTrack1.sceneTrackAdded < sceneTrack2.sceneTrackAdded) return -1
                })
                let defaultArt = sceneTracks[0]?.tracks.imageURL


                this.setState({project: project, scenes: scenes, sceneTracks, sceneTrackVotes, editedProjectName: project.name, editedClientName: project.client, editedProjectType:{label: project.projectTypeName, value: project.projectTypeId},
                    coverArt: project.coverArt, defaultArt: defaultArt, initialCoverArt: project.coverArt, isLoaded: true })
            })
            .catch(error=>{
                if(error.response.status === 401){
                    this.props.history.push("/dashboard/projects/all")
                }
            })
        }
    }
    componentDidMount = ()=>{
        api.getProjectByShareUrl(this.props.match.params.url)
            .then(res=>{
                if(res.data.passwordReq){
                    this.setState({isPWReqModalOpen: true})
                }
                else{
                    let projectTypes = []
                    api.getAllProjectTypes()
                    .then(res=>{
                        res.data.forEach(projType=>{
                            projectTypes.push({label: projType.name, value: projType.id})
                        })
                        this.setState({projectTypes: projectTypes})
                    })

                    let {scenes, ...project} = res.data
                    let sceneTracks = []
                    let refs = []

                    scenes.forEach(scene=>{
                        scene.sceneTracks.forEach((sceneTrack,index)=>{
                            scene.sceneTracks[index].weight = scene.weight
                        })
                        sceneTracks = sceneTracks.concat(scene.sceneTracks)
                        refs.push(React.createRef());
                        this.sceneElements = refs
                    })
                    let sceneTrackVotes = []
                    let allTracks = []
                    sceneTracks = sceneTracks.map(sceneTrack=>{
                        const keyedVotes = {}

                        sceneTrack.sceneTrackVotes =sceneTrack.sceneTrackVotes.reduce((obj, item)=>{
                            return{
                                ...obj,
                                [item.sTVID]: item,
                            };
                        }, keyedVotes)

                        if(allTracks.findIndex(track=>track.trackId === sceneTrack.tracks.trackId) ===-1){
                            allTracks.push({...sceneTrack.tracks})
                        }
                        return sceneTrack

                    })
                    this.context.updateLoadedTracks(allTracks)

                    sceneTracks = sceneTracks.sort(function(sceneTrack1, sceneTrack2){
                        Object.filter = (obj, predicate) =>
                        Object.keys(obj)
                        .filter( key => predicate(obj[key]) )
                        .reduce( (res, key) => (res[key] = obj[key], res), {} );
                        let sceneTrack1PosVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===1)
                        let sceneTrack1NegVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===0)
                        let sceneTrack2PosVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===1)
                        let sceneTrack2NegVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===0)
                        sceneTrack1.score = Object.keys(sceneTrack1PosVotes).length - Object.keys(sceneTrack1NegVotes).length
                        sceneTrack2.score = Object.keys(sceneTrack2PosVotes).length - Object.keys(sceneTrack2NegVotes).length

                        if(sceneTrack1.score > sceneTrack2.score) return -1
                        if(sceneTrack1.score < sceneTrack2.score) return 1
                        if(sceneTrack1.sceneTrackAdded > sceneTrack2.sceneTrackAdded) return 1
                        if(sceneTrack1.sceneTrackAdded < sceneTrack2.sceneTrackAdded) return -1
                    })
                    let defaultArt = sceneTracks[0]?.tracks.imageURL


                    this.setState({project: project, scenes: scenes, sceneTracks, sceneTrackVotes, editedProjectName: project.name, editedClientName: project.client, editedProjectType:{label: project.projectTypeName, value: project.projectTypeId},
                        coverArt: project.coverArt, defaultArt: defaultArt, initialCoverArt: project.coverArt, isLoaded: true })
                    }
            })
            .catch(error=>{

                if(error.response?.status === 401){
                    this.props.history.push("/dashboard/projects/all")
                }
                else if(error.response?.status === 404){
                    this.setState({notFound: true})
                }
            })
    }
    componentWillUnmount = ()=>{
        if(this.context.isMainOpen){
            if(!this.context.user?.user_id){
                this.context.pruneURLs()
                this.context.closeVideoMain()
                this.context.unloadVideo(true)
            }
        }
        else{
            if(!this.context.user?.user_id){
                this.context.pruneURLs()
                this.context.forceReload()
            }
        }
    }
    duplicateProject = ()=>{
        this.setState({isProjectDetailTippyOpen: false});
        api.duplicateProject(this.props.match.params.id)
            .then(res=>{

                if(res.data.success){
                    setTimeout(this.props.history.push("/dashboard/projects/edit/"+res.data.duplicatedProject.id), 1250)
                    // SuccessRedirect({message: `${this.state.project.name} Duplicated!`, onClose: ()=>{this.props.history.push("/dashboard/projects/edit/"+res.data.duplicatedProject.id)}})
                }
                else{
                    Error({message: "An Error Occured!"})
                }
            })
            .catch(err=>{

                Error({message: "An Error Occured!"})
            })
    }
    onChange = (event)=>{
        let {value} = event.target
        this.setState({password: value})
    }
    checkPW =()=>{
        api.checkProjectPW(this.props.match.params.url, this.state.password)
            .then(res=>{
                let projectTypes = []
                    api.getAllProjectTypes()
                    .then(res=>{
                        res.data.forEach(projType=>{
                            projectTypes.push({label: projType.name, value: projType.id})
                        })
                        this.setState({projectTypes: projectTypes, isPWReqModalOpen: false,})
                    })

                    let {scenes, ...project} = res.data
                    let sceneTracks = []
                    let refs = []

                    scenes.forEach(scene=>{
                        sceneTracks = sceneTracks.concat(scene.sceneTracks)
                        refs.push(React.createRef());
                        this.sceneElements = refs
                    })
                    let sceneTrackVotes = []
                    let allTracks = []
                    sceneTracks = sceneTracks.map(sceneTrack=>{
                        const keyedVotes = {}

                        sceneTrack.sceneTrackVotes =sceneTrack.sceneTrackVotes.reduce((obj, item)=>{
                            return{
                                ...obj,
                                [item.sTVID]: item,
                            };
                        }, keyedVotes)

                        if(allTracks.findIndex(track=>track.trackId === sceneTrack.tracks.trackId) ===-1){
                            allTracks.push({...sceneTrack.tracks})
                        }
                        return sceneTrack


                    })
                    this.context.updateLoadedTracks(allTracks)

                    sceneTracks = sceneTracks.sort(function(sceneTrack1, sceneTrack2){
                        Object.filter = (obj, predicate) =>
                        Object.keys(obj)
                        .filter( key => predicate(obj[key]) )
                        .reduce( (res, key) => (res[key] = obj[key], res), {} );
                        let sceneTrack1PosVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===1)
                        let sceneTrack1NegVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===0)
                        let sceneTrack2PosVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===1)
                        let sceneTrack2NegVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===0)
                        sceneTrack1.score = Object.keys(sceneTrack1PosVotes).length - Object.keys(sceneTrack1NegVotes).length
                        sceneTrack2.score = Object.keys(sceneTrack2PosVotes).length - Object.keys(sceneTrack2NegVotes).length

                        if(sceneTrack1.score > sceneTrack2.score) return -1
                        if(sceneTrack1.score < sceneTrack2.score) return 1
                        if(sceneTrack1.sceneTrackAdded > sceneTrack2.sceneTrackAdded) return 1
                        if(sceneTrack1.sceneTrackAdded < sceneTrack2.sceneTrackAdded) return -1
                    })
                    let defaultArt = sceneTracks[0]?.tracks.imageURL

                this.setState({project: res.data, passError: "", isPWReqModalOpen: false,  scenes: scenes, sceneTracks, defaultArt: defaultArt, coverArt: res.data?.coverArt, isLoaded: true})
            })
            .catch(error=>{
                if(error.response){
                    if(error.response.status === 401){
                        this.setState({passError: "Invalid Password"})
                    }
                }
            })
    }
    resolveCoverArt=(coverPhotoKey)=>{
        const key = coverPhotoKey
        if(coverPhotoKey){
            const ext = key.split('.').reverse()[0];
            let final = key.replace('.' + ext, '') + '-minified'  + '.' + ext
            return final
        }
    }
    getThumbnail=(photoKey)=>{
        const key = photoKey
        if(photoKey){
            const ext = key.split('.').reverse()[0];
            let final = key.replace('.' + ext, '') + '-minified-325x325' + '.' + ext
            return final
        }
    }
    render(){
        return(
            <UserConsumer>
                {({ user, login, logout, triggerLoginModal})=>(
                    this.state.notFound ?
                    <NotFound/>
                    :
                    this.state.isLoaded ?
                    <>
                        <Helmet title={`${this.state.project?.name} - Deep Sounds | Music for Creators`} meta={[{property: "og:image", content: `https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.getThumbnail(this.state.coverArt)}`}]}/>

                        <div class="hero-bg bg-blur">
                        {typeof this.state?.coverArt === "string" ?
                            <div class="hero-img" style={{backgroundImage: `url('https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveCoverArt(this.state.coverArt)}')`}}></div>:
                            this.state?.defaultArt ?
                            <div class="hero-img" style={{backgroundImage: `url('https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveCoverArt(this.state.defaultArt)}')`}}></div>:
                            <div class="hero-img" style={{backgroundImage: `url('${wavList[this.state.randomWavIndex]}')`}}></div>
                        }
                        </div>
                    <div class="container pt-140 md:pt-300 relative mb-40 lg:mb-80">
                        <ProjectEditHeaderPublic name={this.state.project?.name} client={this.state.project?.client} projectType={this.state.project?.projectTypeName} projectArtwork={this.state.coverArt || this.state.defaultArt} handleSceneModalOpen={this.handleSceneModalOpen} markComplete={this.markComplete} duplicateProject={this.duplicateProject}
                        closeProjectDetailTippy={this.closeProjectDetailTippy} isProjectDetailTippyOpen={this.state.isProjectDetailTippyOpen} markIncomplete={this.markIncomplete} leaveProject={this.leaveProject}
                        complete={this.state.project?.status === "complete" ? true: false} handleProjectEditModalOpen={this.handleProjectEditModalOpen} handleProjectEditModalClose={this.handleProjectEditModalClose}
                        isOwner={this.state.project?.isOwner } collaborators={this.state.project?.collaborators || []} projectId={this.state.project?.id} removeCollaborator={this.removeCollaborator} addCollaboratorToState={this.addCollaboratorToState}
                        ownerFn={this.state.project?.ownerFn} ownerLn={this.state.project?.ownerLn} ownerPhoto={this.state.project?.ownerPhoto} toggleProjectDetailTippy={this.toggleProjectDetailTippy} projectLink={this.state.project?.projectLink}
                        />
                        {/* {# Prompts #} */}

                            <div class="project-scenes project-public">
                    <div class="accordion js-accordion mb-30 lg:mb-50">

                        {this.state.scenes.map((scene, index)=>{
                            return(
                                <ProjectAccordion user={user} triggerLoginModal={triggerLoginModal} index={index} name={scene.name} playlistTracks={this.state.sceneTracks.map(sceneTrack=>sceneTrack).sort((a,b)=>a.weight === b.weight ? 0 :a.weight < b.weight ? -1: 1).filter((sceneTrack, index)=>{sceneTrack.realInd=index; return sceneTrack.sceneId === scene.id})} tracks={this.state.sceneTracks.filter((sceneTrack, index)=>{sceneTrack.realInd=index; return sceneTrack.sceneId === scene.id})}
                                submitVote={this.submitVote} updateVote={this.updateVote} autoExpand={index===0} projectId={this.state.project.id} sortFilters={sortFilters} sortBy={this.sortBy} sortCriteria={this.state.sortText}
                                toggleSortTippy={this.toggleSortTippy} handleEditSceneModalOpen={this.handleEditSceneModalOpen} isEditSceneModalOpen={this.state.isEditSceneModalOpen}  toggleSceneTooltip={this.toggleSceneTooltip} isSceneTooltipOpen={this.state.isSceneTooltipOpen}
                                handleFormUnsaved={this.props.handleFormUnsaved} editScene={this.editScene} sceneId={scene.id} removeScene={this.removeScene}  forceClose={scene.forceClose} ref={this.sceneElements[index]} removeTrack={this.removeTrack}
                                isOwner={this.state.project?.isOwner } checkIfUsed={this.checkIfUsed} isPublicPage={this.props.location.pathname.includes('/projects/public/')} updateFollowing={this.updateFollowing}/>
                            )
                        })}
                    </div>
                </div>

                <MicroModal
                    open={this.state.isPWReqModalOpen}
                    handleClose={()=>this.handlePWModalClose()}
                    closeOnAnimationEnd={true}
                    closeOnEscapePress={false}
                    closeOnOverlayClick={false}
                    modalOverlayClassName="modal-overlay"
                    modalClassName="modal micromodal-slide is-open"
                    modalOverlayStyles={null}
                    children={handleClose => <EnterPWModal handleClose={handleClose} onChange={this.onChange} password={this.state.password} checkPW={this.checkPW} passError={this.state.passError}/>}
                    containerStyles={{ background: "#131313", padding: "asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box" }}
                />

                    </div>
                    </>:
                    <div style={{marginTop: "20%"}}>
                         <MicroModal
                    open={this.state.isPWReqModalOpen}
                    handleClose={()=>this.handlePWModalClose()}
                    closeOnAnimationEnd={true}
                    closeOnEscapePress={false}
                    closeOnOverlayClick={false}
                    modalOverlayClassName="modal-overlay"
                    modalClassName="modal micromodal-slide is-open"
                    modalOverlayStyles={null}
                    children={handleClose => <EnterPWModal handleClose={handleClose} onChange={this.onChange} password={this.state.password} checkPW={this.checkPW} passError={this.state.passError}/>}
                    containerStyles={{ background: "#131313", padding: "asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box" }}
                />
                    <CircularIndeterminant size={40} forceCenter={true}/>
                </div>
                )}
            </UserConsumer>
        )
    }
}
PublicPage.contextType = PlayerContext
export default PublicPage

import React, { useState } from "react";
import MicroModal from "react-micro-modal";
import LicenseItem from "../../Checkout/LicenseItem";
import CheckoutAddToProjectModal from "../../modal/checkoutAddToProjectModal";
import purchasesAPI from "../../../api/purchases";
import { handleAPIError } from "../../../utils/handleAPIError";
import LoadingButton from "../../FormElements/LoadingButton";
import TermsAndConditions from "../../Links/termsAndConditions";
import LicenseTerms from "../../Links/licenseTerms";

const LicenseAndDownload = ({ handleClose, isDemo, licenseData, stem, track, version }) => {
  const [isNewProjectModalOpen, setIsNewProjectModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);

  console.log("LicenseAndDownload licenseData", licenseData);

  const { license, originalPrice, finalPrice } = licenseData || {};
  const { product } = license || {};

  const handleNewProjectModalClose = () => {
    setIsNewProjectModalOpen(false);
  };

  const handleNewProjectModalOpen = () => {
    setIsNewProjectModalOpen(true);
  };

  const associateProjectToOneTimeCartItem = (index, projectId, projectName) => {
    // console.log('associateProjectToOneTimeCartItem projectId', projectId);
    // console.log('associateProjectToOneTimeCartItem projectName', projectName);
    setSelectedProject({
      id: projectId,
      name: projectName,
    });
  };

  const submitCheckout = (event) => {
    event.preventDefault();
    setIsPaymentProcessing(true);
    let invoicePrices = [];

    invoicePrices.push({
      id: product?.priceId,
      finalPrice,
      originalPrice,
    });

    const cartInfo = licenseData;

    cartInfo.track = track;
    cartInfo.project = selectedProject;

    const purchaseData = {
      finalPrice,
      invoicePrices,
      originalPrice,
      type: "license",
      cartInfo: [cartInfo],
    };

    // Save purchase data to our db
    purchasesAPI
      .create({ purchaseData })
      .then((res) => {
        const { orderNumber } = res?.data?.purchase;
        console.log("purchase res", res);
        let completeURL =
          window.location.origin + "/checkout/complete/" + orderNumber + "?download=1";
        if (stem) {
          completeURL = completeURL + "&stem=" + stem;
        }
        if (version) {
          completeURL = completeURL + "&version=" + version;
        }
        if (res.data.success) {
          window.location.replace(completeURL);
        } else {
          Error({ message: "Could not complete purchase" });
        }
      })
      .catch((error) => {
        handleAPIError(error);
        setIsPaymentProcessing(false);
      });
  };

  return isDemo ? (
    <>
      <p className="my-20">This license would be fully covered by your subscription.</p>
      <div className="mb-40">
        <button
          onClick={() => handleClose()}
          className="btn btn-primary form-btn w-full"
          type="button"
        >
          Back
        </button>
      </div>
    </>
  ) : (
    <>
      <div className="mt-20 mb-0">Select a Project for this track license:</div>
      <LicenseItem
        associateProjectToCartItem={associateProjectToOneTimeCartItem}
        track={track}
        license={license}
        index={0}
        handleNewProjectModalOpen={handleNewProjectModalOpen}
        project={selectedProject}
        setSelectedProject={setSelectedProject}
        hideControls={true}
      />

      <LoadingButton
        disabled={!selectedProject?.id}
        className="btn btn-primary w-full form-btn mt-10"
        isLoading={isPaymentProcessing}
        type="submit"
        onClick={(event) => {
          setIsPaymentProcessing(true);
          submitCheckout(event);
        }}
      >
        <span className="icon-download mr-5"></span>
        Download & License
      </LoadingButton>

      <p className="text-sm mt-20">
        <span>
          By clicking the button above, I agree to the{" "}
          <TermsAndConditions underline>Terms of Use</TermsAndConditions> and{" "}
          <LicenseTerms underline />.
        </span>
      </p>

      <MicroModal
        open={isNewProjectModalOpen}
        closeOnEscapePress={true}
        closeOnOverlayClick={true}
        closeOnAnimationEnd={true}
        modalOverlayClassName="modal-overlay"
        modalClassName="modal micromodal-slide is-open"
        modalOverlayStyles={null}
        handleClose={handleNewProjectModalClose}
        children={(handleClose) => (
          <CheckoutAddToProjectModal
            associateProjectToCartItem={associateProjectToOneTimeCartItem}
            handleClose={handleClose}
            trackId={track.id}
            trackTitle={track.trackTitle}
            currentItemIndex={0}
          />
        )}
        containerStyles={{
          background: "#131313",
          padding: "asdf",
          maxWidth: "40.625rem !important",
          maxHeight: "100vh",
          borderRadius: "asdf",
          overflowY: "auto",
          boxSizing: "border-box",
        }}
      />
    </>
  );
};

export default LicenseAndDownload;

const resolveAlbumArtFilename = (albumPhotoKey) => {
    if (albumPhotoKey) {
        const extension = albumPhotoKey.split('.').reverse()[0];
        const sizeName = 'minified-140x140';
        const fileNameRaw = albumPhotoKey.replace('.' + extension, '');
        const fullFileName = fileNameRaw + '-' + sizeName + '.' + extension
        return fullFileName;
    }
}

const buildAlbumArtURL = (track) => {
    const baseURL = `https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/`;
  
    let filePathPrefix = '';
  
    if (process.env.REACT_APP_S3_SUB_BUCKET) {
      filePathPrefix += process.env.REACT_APP_S3_SUB_BUCKET + "/";
    }
  
    const fullURL = baseURL + filePathPrefix + resolveAlbumArtFilename(track?.albumArt);
  
    return fullURL;
  }
export default buildAlbumArtURL;
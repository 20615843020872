import React from 'react';
import { Helmet } from "react-helmet";
import { NavLink, useParams } from "react-router-dom";
import { UserConsumer } from "../../contexts/User";
import ArtistTerms from "./artist";
import LicenseTerms from "./license";
import SiteTerms from "./terms";



function Terms() {

    const params = useParams();

    let section;
    let title;
    switch(params.termsType) {
        case 'artist':
            title = 'Artist Terms'
            break;
        default:
            title = 'Deep Sounds Terms of Use'
      }

    return (
        <UserConsumer>
            {({user}) =>
                <div className="container pt-80 md:pt-100 z-10">
                    <Helmet title={`${title} - Deep Sounds | Music for Creators`} />

                    <div className="view-header">
                        <h1 className="view-title" style={{margin: '0 auto'}}>{title}</h1>
                    </div>

                    <nav className="tabs mb-30">
                        <ul className="tab-items">
                            <li className={`tab-item ${params.termsType === undefined ? 'active' : ''}`}>
                                <NavLink to="/terms" className='tab-item-title'>Terms of Use</NavLink>
                            </li>
                            <li className={`tab-item ${params.termsType === "artist" ? 'active' : ''}`}>
                                <NavLink to="/terms/artist" className="tab-item-title">Artist Terms</NavLink>
                            </li>
                        </ul>
                    </nav>

                    {params.termsType === undefined &&
                        <SiteTerms />
                    }
                    {params.termsType === "license" &&
                        <LicenseTerms />
                    }
                    {params.termsType === "artist" &&
                        <ArtistTerms />
                    }
                </div>

            }
        </UserConsumer>
    );
}
export default Terms;

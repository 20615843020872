import React, { Component } from "react";
import { UserConsumer } from "../../contexts/User";

class SingleTrack extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {
                pendingTitle: "",
                pendingFunFact: "",
                pendingTags: "",
                pedingActualURL: "",
                approveTitle: false,
                approveFunFact: false
            }
    //updating our input fields as the user enters keys
    handleInputChange = event => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    };
    handleSelect = (event)=> {

        this.setState({album: event.target.value});
      }

    setFileState = (file)=>{

        this.setState({
            file: file
        })
    }

    handleFormSubmit = (event) => {
        event.preventDefault()
    }
    render() {
        return (
            <UserConsumer>
                {({ user, login, logout})=>(
                    <tr>
                        <td>
                            <div>{this.props.pendingTrackTitle}</div>
                            <input
                                name="approveTitle"
                                type="checkbox"
                                checked={this.state.approveTitle}
                                onChange={this.handleInputChange}
                            />
                        </td>
                        <td>
                            <div>{this.props.pendingFunFact}</div>
                            <input
                                name="approveFunFact"
                                type="checkbox"
                                checked={this.state.approveFunFact}
                                onChange={this.handleInputChange}
                            />
                        </td>
                        <td>
                            <button onClick={()=>this.props.changeTrack("default", this.props.trackId)}>Play</button>
                        </td>
                    </tr>
                )}
            </UserConsumer>
        )
    }

}
export default SingleTrack

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { PlayerContext } from "../../PlayerContext";
import AlbumStats from "./albumStats";
import AlbumTrackListItem from "./albumTrackListItem";
import buildAlbumPath from "../../utils/buildAlbumPath";
import buildAlbumArtURL from "./AlbumArt";

export default function AlbumListItem({ album }) {
  const [isDropdown, setIsDropdown] = useState(false);

  const {
    approvedTracks,
    artistName,
    artistURL,
    imageUrl,
    title,
    tracks,
    tracksCount
  } = album || {};

  const albumImageURL = buildAlbumArtURL(imageUrl);
  const albumPath = buildAlbumPath(artistURL, title);

  const toggleDropdown = () => {
    setIsDropdown(!isDropdown);
  };

  const classes = [
    "track-list-card",
    "is-unplayable"
  ];
  if (isDropdown) {
    classes.push("dropdown-open");
  }

  return (
    <div className={classes.join(" ")}>
      <div className={`track-first-col`}>
        {tracksCount > 0 ? (
          <div className="track-image">
            <img
              src={albumImageURL}
              alt="Album art"
            />
          </div>
        ) : (
          <div className="track-image">
            <img
              src={albumImageURL}
              alt="Album art"
            />
          </div>
        )}
        <div className="track-title-artist">
          {approvedTracks > 0 ? (
            <NavLink
              to={albumPath}
            >
              <span className="track-title">{title}</span>
            </NavLink>
          ) : (
            <span className="track-title">{title}</span>
          )}
          <span class="track-subtext">
            {tracksCount}
            {tracksCount === 1 ? " Track" : " Tracks"}
          </span>
        </div>
        {tracksCount > 0 && (
          <button
            className={`circle-icon circle-icon-sm icon-chevron-down${
              isDropdown ? " dropdown-open" : ""
            }`}
            onClick={toggleDropdown}
          ></button>
        )}
      </div>
      {approvedTracks > 0 ? (
        <AlbumStats {...album} />
      ) : (
        <div className="track-stats">No approved tracks.</div>
      )}
      <div className="track-last-col"></div>

      <div class="track-dropdown">
        <div class="track-dropdown-content pt-15 pb-0">
          <div class="track-list-container">
            {tracks?.map((track, index) => (
              <AlbumTrackListItem
                artistName={artistName}
                artistURL={artistURL}
                key={`albumTrackListItem-${track.trackId}`}
                index={index}
                {...track}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

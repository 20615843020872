import React, { useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import api from '../utils/api';

function Tags() {
    const [tagsData, setTagsData] = useState({ tags: [] })
    const [tagTypesData, setTagTypesData] = useState({ tagTypes: [] })
    const [isLoaded, setIsLoaded] = useState(false)
    const [isSending, setIsSending] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            api.getTagsByType()
            .then(res => {
                console.log('res', res);
                setTagTypesData({tagTypes: res.data.tagTypes});
                setTagsData({tags: res.data.tags});
                setIsLoaded(true)
            })
            .catch(error=>{
                console.log(error)
            });
        }
        fetchData();

    }, []);

    // does not work -- was just doing some testing but liked the code!
    // const sendTagsUpdateRequest = useCallback(async (tagUpdateData) => {
    //     // don't send again while we are sending
    //     if (isSending) return
    //     // update state
    //     setIsSending(true)
    //     // send the actual request
    //     await api.getTagsByName(tagUpdateData.name)
    //             .then(res => {
    //                 console.log('res', res);
    //             })
    //             .catch(error=>{
    //                 console.log(error)
    //             });
    //     // once the request is sent, update state again
    //     setIsSending(false)
    //   }, [isSending]) // update the callback if the state changes

    return (
        <>
            {isLoaded &&
                <div className="container">
                    <Helmet title={`Tags - Deep Sounds | Music for Creators`} />

                    <div className="view-header">
                        <h1 className="view-title">Tags</h1>
                    </div>

                    <div className="container">
                        <div className="grid-row-xs mb-40">
                            {/* console.log('tagTypesData', tagTypesData) */}

                            {Object.keys(tagTypesData.tagTypes).map((item, i) => (
                                <div key={i} className="col w-full md:w-1/2 xxl:w-1/3 mb-10 sm:mb-20">
                                    <h5 className="mb-20">{tagTypesData.tagTypes[item]}</h5>
                                    <ul>
                                        {Object.keys(tagsData.tags[item]).map((tag, i) => (
                                            <li key={i} className="mt-5 mb-5">
                                                {console.log('tagsData', tagsData.tags[item])}
                                                {tagsData.tags[item].tag}
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </div>



                </div>


            }
        </>
    );
}
export default Tags;

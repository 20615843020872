import React, { Component } from "react";
import MicroModal from "react-micro-modal";
import { NavLink } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import { Tooltip } from "react-tippy";
import { Error, Success } from "../Toast";
import api from "../utils/api";
import AdminCollectionTooltip from "./adminCollectionTooltip";
import AdminCollectionTrack from "./adminCollectionTrack";
import EditCollectionModal from "./editCollectionModal";

class EditCollection extends Component {
  state = {
    collection: {},
    isEditCollectionModalOpen: false,
  };
  toggleCollectionTippy = () => {
    this.setState({ showCollectionTippy: !this.state.showCollectionTippy });
  };
  handleEditCollectionModalOpen = () => {
    this.setState({ isEditCollectionModalOpen: true });
  };
  handleEditCollectionModalClose = () => {
    this.setState({ isEditCollectionModalOpen: false });
  };
  componentDidMount = () => {
    api.getAdminCollection(this.props.match.params.collectionId).then((res) => {
      if (res.data.success) {
        this.setState({ collection: res.data.collection });
      }
    });
  };
  updateCollection = (collectionId, data) => {
    let collection = { ...this.state.collection };
    collection.name = data.name || collection.name;
    collection.cardArt = data.cardArt || collection.cardArt;
    collection.coverArt = data.coverArt || collection.coverArt;
    collection.description = data.description || collection.description;
    collection.featured = data.featured === undefined ? collection.featured : data.featured;
    collection.featuredWeight = data.featuredWeight || collection.featuredWeight;
    collection.published = data.published === undefined ? collection.published : data.published;

    this.setState({ collection: collection });
  };
  makeFeatured = () => {
    api.makeFeatured(this.state.collection?.id).then((res) => {
      if (res.data.success) {
        this.updateCollection(this.state.collection?.id, {
          featured: 1,
          featuredWeight: res.data.featuredWeight,
        });
        Success({ message: "Collection Featured!" });
      }
    });
  };
  makeUnfeatured = () => {
    api.makeUnfeatured(this.state.collection?.id).then((res) => {
      if (res.data.success) {
        this.updateCollection(this.state.collection?.id, { featured: 0 });
        Success({ message: "Collection Unfeatured!" });
      }
    });
  };
  publish = () => {
    api
      .publishCollection(this.state.collection?.id)
      .then((res) => {
        if (res.data.success) {
          this.updateCollection(this.state.collection?.id, { published: 1 });
          Success({ message: "Collection Published!" });
        }
      })
      .catch((error) => {
        if ((error.response.status = 406)) {
          Error({ message: "Collection needs at least 5 tracks" });
        } else {
          Error({ message: "Error publishing collection" });
        }
      });
  };
  unpublish = () => {
    api.unpublishCollection(this.state.collection?.id).then((res) => {
      if (res.data.success) {
        this.updateCollection(this.state.collection?.id, { published: 0, featured: 0 });
        Success({ message: "Collection Unpublished!" });
      }
    });
  };
  rearrangeTracks = (newOrder) => {
    this.setState((prevState) => ({
      ...prevState,
      collection: {
        ...prevState.collection,
        tracks: newOrder,
      },
    }));
  };
  setSort = (sortable) => {
    this.updateTrackOrder(sortable);
  };
  updateTrackOrder = (sortable) => {
    let trackOrder = sortable.toArray();
    trackOrder.forEach((collectionTrackId, index) => {
      api
        .updateCollectionTrackOrder(collectionTrackId, { weight: index })
        .then((res) => {})
        .catch((error) => {});
    });
  };
  removeTrack = (collectionTrackId) => {
    api
      .removeTrackFromCollection(collectionTrackId)
      .then((res) => {
        if (res.data.success) {
          Success({ message: "Track removed from collection" });
          this.setState((prevState) => ({
            ...prevState,
            collection: {
              ...prevState.collection,
              tracks: prevState.collection.tracks.filter(
                (track) => track.collectionTrackId !== collectionTrackId
              ),
            },
          }));
        } else {
          Error({ message: "Error removing track" });
        }
      })
      .catch((error) => {
        if (error.response?.status === 401) {
          Error({ message: "Unauthorized" });
        } else {
          Error({ message: "Error removing track" });
        }
      });
  };
  render() {
    return (
      <div class="container mb-40 lg:mb-80">
        <NavLink to="/dashboard/collections/published" class="view-header-back">
          <span class="icon-chevron-left"></span>
          <span class="text-link-gray text-label">All Collections</span>
        </NavLink>
        <MicroModal
          open={this.state.isEditCollectionModalOpen}
          handleClose={this.handleEditCollectionModalClose}
          closeOnOverlayClick={true}
          closeOnAnimationEnd={true}
          modalOverlayClassName="modal-overlay"
          modalClassName="modal micromodal-slide is-open"
          modalOverlayStyles={null}
          children={(handleClose) => (
            <EditCollectionModal
              handleClose={() => {
                handleClose();
              }}
              {...this.state.collection}
              updateCollection={this.updateCollection}
            />
          )}
          containerStyles={{
            background: "#131313",
            padding: "asdf",
            maxWidth: "40.625rem !important",
            maxHeight: "100vh",
            borderRadius: "asdf",
            overflowY: "auto",
            boxSizing: "border-box",
          }}
        />
        {/* {# View Header #} */}
        <div class="view-header view-header-nowrap mt-0">
          {/* {# Header Content #} */}
          <div>
            <h1 class="view-title">{this.state.collection?.name}</h1>
            <h6>
              {this.state.collection?.followerCount > 0 &&
                `${this.state.collection.followerCount} Follower${
                  this.state.collection.followerCount > 1 ? "s" : ""
                }`}
            </h6>
          </div>
          <div class="view-header-controls">
            <div class="btn-group btn-group-lg">
              {/* {# Share #} */}
              <button
                class="circle-icon-border hidden md:flex"
                data-micromodal-trigger="share_project_modal"
              >
                <span class="icon-share"></span>
              </button>
              {/* {# More Dropdown #} */}
              <Tooltip
                className="circle-icon"
                arrow={false}
                open={this.state.showCollectionTippy}
                interactive={true}
                useContext={true}
                position={"bottom-end"}
                trigger={"click"}
                html={
                  <AdminCollectionTooltip
                    collectionId={this.state.collection?.id}
                    handleEditCollectionModalOpen={this.handleEditCollectionModalOpen}
                    toggleCollectionTippy={this.toggleCollectionTippy}
                    makeFeatured={
                      this.state.collection?.published && !this.state.collection?.featured
                        ? this.makeFeatured
                        : undefined
                    }
                    makeUnfeatured={
                      this.state.collection?.published && this.state.collection?.featured
                        ? this.makeUnfeatured
                        : undefined
                    }
                    publish={!this.state.collection?.published ? this.publish : undefined}
                    unpublish={this.state.collection?.published ? this.unpublish : undefined}
                  />
                }
                allowHTML={true}
                onUntrigger={() => {
                  this.state.showCollectionTippy
                    ? this.setState({ showCollectionTippy: false })
                    : this.setState({ showCollectionTippy: true });
                }}
                onRequestClose={() => {
                  this.state.showCollectionTippy
                    ? this.setState({ showCollectionTippy: false })
                    : this.setState({ showCollectionTippy: true });
                }}
              >
                <button onClick={this.toggleCollectionTippy} class="circle-icon">
                  <span class="icon-dots-vert"></span>
                </button>
              </Tooltip>
            </div>
          </div>
        </div>
        <div class="track-list-container mb-40">
          <span className="text-gray-400">{this.state.collection?.tracks?.length} Tracks</span>
          <ReactSortable
            // multiDrag={true}
            // className="grid-row-xs"
            group="tracks"
            animation={200}
            handle=".icon-drag"
            // onChoose={(event)=>{this.forceClose(event)}}
            // onEnd={(event)=>{this.removePadding()}}
            ghostClass="ghost-class"
            // onUnchoose={(event)=>this.onSort(event)}
            forceFallback={true}
            delayOnTouchOnly={true}
            delay={0.9}
            dataIdAttr="tracki"
            list={this.state.collection?.tracks || []}
            setList={(newState) => this.rearrangeTracks(newState)}
            forceFallback={true}
            fallbackClass="col w-full md:w-1/2 xxl:w-1/3 mb-10 md:mb-20"
            store={{
              set: (sortable) => this.setSort(sortable),
            }}
          >
            {this.state.collection?.tracks?.map((track) => (
              <AdminCollectionTrack {...track} removeTrack={this.removeTrack} />
            ))}
          </ReactSortable>
          {/* {% for track in tracks.slice(0,12) %}
                        {% include "components/tracks/list-card-approved.html" %}
                    {% endfor %} */}
        </div>

        {/* {# Add Tracks #} */}
        <NavLink
          to="/music"
          class="btn btn-lg btn-stroke btn-wide"
          data-micromodal-trigger="add_scene_modal"
          type="button"
        >
          <span class="icon-tracks"></span>
          Add Tracks
        </NavLink>
        {/* {# More Dropdown #} */}
        <div class="hidden" id="coll_edit_options">
          <div class="dropdown-menu">
            <div class="dropdown-group">
              <a href="#">Rename Collection</a>
              <a href="#">Share</a>
              <a href="#">Duplicate</a>
            </div>
            <div class="dropdown-group">
              <a href="#">
                <span class="icon-trash"></span>Delete Collection
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default EditCollection;

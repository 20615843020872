import axios from "axios";

const downloadsAPI = {
  // downloadVersionPreview: function({ id }) {
  //   return axios({
  //     method: 'get',
  //     url: '/api/tracks/versions/' + id + '/downloadPreview'
  //   })
  // },
  downloadMainTrack: function({ id }) {
    return axios({
      method: 'get',
      url: '/api/downloads/mainTrack/' + id,
    })
  },
  downloadTrackPreview: function({ trackId }) {
    return axios({
      method: 'get',
      url: '/api/tracks/' + trackId + '/downloadPreview'
    })
  },
  downloadStemFull: function({ trackId, stemId }) {
    return axios({
      method: 'get',
      url: '/api/tracks/' + trackId + '/stems/' + stemId + '/downloadFull'
    })
  },
  downloadStemPreview: function({ trackId, stemId }) {
    return axios({
      method: 'get',
      url: '/api/tracks/' + trackId + '/stems/' + stemId + '/downloadPreview'
    })
  },
  downloadVersionFull: function({ trackId, versionId }) {
    return axios({
      method: 'get',
      url: '/api/tracks/' + trackId + '/versions/' + versionId + '/downloadFull'
    })
  },
  downloadVersionPreview: function({ trackId, versionId }) {
    return axios({
      method: 'get',
      url: '/api/tracks/' + trackId + '/versions/' + versionId + '/downloadPreview'
    })
  }
};
export default downloadsAPI;

import moment from "moment";
import React, { Component, createRef } from "react";
import WaveSurfer from 'wavesurfer.js';
import { PlayerContext } from "../../PlayerContext";

var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment)
// import { PlayerContext } from "../../PlayerContext";

class VersionWaveform extends Component {
    state = {
        src: this.props.src || "",
        peaks: this.props.peaks,
        currentTime: "0:00",
        duration: "0:00",
        sendPlay: 0,
        volume: 1,
        ready: false
    }
    handleOnChange = (value) => {
      console.log(value)
      this.setState({
        volume: value.x
      })
    }
  setVolume = ()=>{
    console.log("setting volume to ", this.state.volume)
    this.wavesurfer.setVolume(this.state.volume)
  }
  waveReady = ()=>{}

  wrapper = createRef()
  componentDidMount() {
  
    this.$el = this.wrapper.current
    console.log(this.$el)
    this.$waveform = this.$el.querySelector('.wave')
    this.wavesurfer = WaveSurfer.create({
        container: this.$waveform,
        normalize: true,
        barMinHeight: 2,
        barWidth: 1,
        barGap: 2,
        fillParent: true,
        hideScrollbar: true,
        interact: !this.props.videoStudio,
        cursorWidth: 0,
        backend: "MediaElement",
        // fillParent: true,
        height: (this.props.forceHeight || 1.875 )* (parseFloat(getComputedStyle(document.documentElement).fontSize)),
        // barHeight: 3,
        progressColor: "rgba(255,255,255,1)",
        responsive: true,
        waveColor: "rgba(255,255,255,.5)",
        xhr:{ cache: 'default', mode: 'cors', method: 'GET', credentials: 'same-origin', redirect: 'follow', referrer: 'client', headers: [ { key: 'Authorization', value: 'my-token' } ]}
        // fillParent: true
        // fillParent: true,        
    })
    this.waveReady = this.wavesurfer.on('ready', ()=>{
      console.log("wave ready")
      this.setState({ready: true})
    })

    this.wavesurferError = this.wavesurfer.on('error', (error)=>{console.log("wave error ", error)})
    this.wavesurfer.backend.peaks = JSON.parse(this.props.peaks)
    this.wavesurfer.drawBuffer()
    this.setState({ready: true})
    this.onInteract = this.wavesurfer.on('seek', (seekTime)=>{
      console.log("seek called")
        if(this.context.waveFormEl){
          if(this.context.user || ((window.location.pathname + window.location.search) === "/?videoStudioDemo"))
          {
            if(seekTime <= .02 && this.context.mediaPlayer.currentTime/this.context.waveFormEl.getDuration() > 0.02){
              console.log("should be seeking to 0")
              if((this.context.user || ((window.location.pathname + window.location.search) === "/?videoStudioDemo")) && !this.context.isMainOpen){
                this.context.waveFormEl.seekTo(0)
              }
              else{
                // this.context.triggerLoginModal()
              }
              // this.props.emitToOthers(0, "track", this.context.mediaPlayer.idKey);
            }
            else if(seekTime - this.context.mediaPlayer.currentTime/this.context.waveFormEl.getDuration() > .02 || seekTime - this.context.mediaPlayer.currentTime/this.context.waveFormEl.getDuration() < -.02 ){
                console.log("should be seeking to ", seekTime  - this.context.mediaPlayer.currentTime/this.context.waveFormEl.getDuration())
                // this.props.emitToOthers(seekTime * this.context.waveFormEl.getDuration(), "track", this.context.mediaPlayer.idKey);
                if((this.context.user || ((window.location.pathname + window.location.search) === "/?videoStudioDemo")) && !this.context.isMainOpen){
                  this.context.waveFormEl.seekTo(seekTime)
                }
                else{
                  // this.context.triggerLoginModal()
                }
              }
            }
            else{
              if(seekTime <= .02 && this.context.mediaPlayer.ratioCurrentTime/this.context.waveFormEl.getDuration() > 0.02){
                console.log("should be seeking to 0")
                this.context.triggerLoginModal()

                // this.props.emitToOthers(0, "track", this.context.mediaPlayer.idKey);
              }
              else if(seekTime - this.context.mediaPlayer.ratioCurrentTime/this.context.waveFormEl.getDuration() > .02 || seekTime - this.context.mediaPlayer.ratioCurrentTime/this.context.waveFormEl.getDuration() < -.02 ){
                  console.log("should be seeking to ", seekTime  - this.context.mediaPlayer.ratioCurrentTime/this.context.waveFormEl.getDuration())
                  // this.props.emitToOthers(seekTime * this.context.waveFormEl.getDuration(), "track", this.context.mediaPlayer.idKey);
                  this.context.triggerLoginModal()
                }
            }
          }
         
      
      
    })
  }
  updateWaveformFromMain =()=>{
    if(this.context.waveFormEl){
      let duration = this.context.waveFormEl.getDuration()
      if(!isNaN(duration)){
        if(this.context.user || ((window.location.pathname + window.location.search) === "/?videoStudioDemo" || window.location.pathname.indexOf("projects/public/")!==-1)){
          if(this.context.mediaPlayer.currentTime/duration < 1 && this.context.mediaPlayer.currentTime/duration > 0){
            this.wavesurfer.seekTo(this.context.mediaPlayer.currentTime/duration)
          }
          else if (this.context.mediaPlayer.currentTime/duration === 0){
            this.wavesurfer.seekTo(0)
          }
        }
        else{
          if(this.context.mediaPlayer.currentTime/duration < 1 && this.context.mediaPlayer.currentTime/duration > 0){
            this.wavesurfer.seekTo(this.context.mediaPlayer.ratioCurrentTime/duration)
          }
          else if (this.context.mediaPlayer.currentTime/duration === 0){
            this.wavesurfer.seekTo(0)
          }
        }
      }
    }
    

  }
  onInteract = ()=>{}
  songFinished = ()=>{}
  songLoaded = ()=>{}
  wavesurferError = ()=>{}
  currentTimeInt = ()=>{}
  componentDidUpdate = (prevProps, prevState, snapshot)=>{
    let duration;
    if(this.context.waveFormEl){
      duration = this.context.waveFormEl.getDuration()
    }
    if(this.state.isMainOpen !== this.context.isMainOpen){
      if(this.context.isMainOpen){
        this.setState({isMainOpen: true}, ()=>{
          if(this.wavesurfer.params.interact){
            this.wavesurfer.toggleInteraction()
          }
        })
      }
      else{
        this.setState({isMainOpen: false}, ()=>{
          if(!this.wavesurfer.params.interact){
            this.wavesurfer.toggleInteraction()
          }
        })
      }
    }
      this.updateWaveformFromMain()
 }

  componentWillUnmount() {

  }
  render() {
    return (
      <div style={{width: "100%"}}>

  <div ref={this.wrapper} className="" style={{width: "100%"}}>

<div style={{backgroundColor: "rgba(255, 0, 0, 0.0)", width: "100%" }} className='waveform'>
  <div className='wave'/>
</div>
</div>
</div>
    
    )
  }
}

VersionWaveform.defaultProps = {
  src: "",
  peaks: "",
  currentTime: 0
}

VersionWaveform.contextType = PlayerContext
export default VersionWaveform
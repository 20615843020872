import React, { useContext } from "react";
import { Route, useHistory, useLocation } from "react-router-dom";
import { Error, Success } from "../Toast/index";
import { UserContext } from "../../contexts/User";
import LoadingContainer from "../Loading/LoadingContainer";

const PublicRoute = ({ component: Component, ...rest }) => {
  const history = useHistory();
  const location = useLocation();
  const showToast = history?.location?.state?.showToast;
  const showErrorToast = history?.location?.state?.showErrorToast;
  const toastMessage = history?.location?.state?.toastMessage;
  const toastAutoClose = history?.location?.state?.toastAutoClose;

  const userContext = useContext(UserContext);
  const { userLoaded } = userContext || {};

  if (showToast) {
    Success({
      message: toastMessage,
      autoClose: toastAutoClose || 3000,
    });
    location.state.showToast = false;
  }
  if (showErrorToast) {
    Error({ message: toastMessage });
    // this.props.history.location.state.showErrorToast = false
  }

  return (
    <LoadingContainer isLoaded={userLoaded}>
      <Route render={(props) => <Component {...props} />} {...rest} />
    </LoadingContainer>
  );
};

export default PublicRoute;

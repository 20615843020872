import React from "react";
export default function TechnicalRequirementsLearnMore(props){
    
    return(
        <a 
            data-preventOpen={true} 
            className={props.red ? "text-link": ""} 
            target="_blank" 
            href={"http://help.deepsounds.com/en/articles/4813154-audio-technical-requirements"}
        >
            {props.underline ? 
                <span preventOpen style={{textDecoration: "underline"}}>Learn more</span>
            :  
                <span data-preventOpen={true}>Learn more</span>
            }
        </a>
    )
}

import React, { Component } from "react";
import ReactAudioPlayer from "react-audio-player";
import { UserConsumer } from "../../contexts/User";
import AppLayout from "../Layouts/appLayout";
import api from "../utils/api";
import Track from "./singleTrack";


class PendingTracks extends Component {
    state = {
        trackList: [],
        trackId: null,
        sourceToGet: "pending",
    }
    //get a requested set of tracks from the db
    componentDidMount = ()=>{
        api.getTracks()
            .then( res=>{

                this.setState({trackList: res.data})
            })
    }
    changeTrack = (source, track)=>{

        this.setState({
            sourceToGet: source,
            trackId: track
        })
    }
    render() {

        return (
            <AppLayout>
            <UserConsumer>
                {({ user, login, logout})=>(
                    <div>
                        <ReactAudioPlayer src={`/api/tracks/getAudio/${this.state.sourceToGet}/${this.state.trackId}`} controls autoPlay/>
                    <table>
                        <thead>
                            <th>Pending Title</th>
                            <th>Pending Fun Fact</th>
                            <th>Change Track</th>
                        </thead>
                        <tbody>
                            {this.state.trackList.map(track=><Track {...track} changeTrack={this.changeTrack} key={"track-" + track.trackId}></Track>)}
                        </tbody>
                    </table>
                    </div>
                )}
            </UserConsumer>
            </AppLayout>
        )
    }

}
export default PendingTracks

import axios from "axios";

const transfersAPI = {
  getContentIDPayouts: function (planType) {
    return axios({
      method: "get",
      url: `/api/transfers/contentID`,
    });
  },
  sendPendingContentID: function () {
    return axios({
      method: "post",
      url: "/api/transfers/sendPendingContentID",
    });
  },
};
export default transfersAPI;

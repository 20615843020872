import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import "../Carousels/index.css";
import TrackGridItem from "../Track/trackGridItem";
import ProjectSummaryHeader from "./projectSummaryHeader";

class ProjectItem extends Component {
  render() {
    const settings = {
      arrows: false,
      swipeToSlide: true,
      variableWidth: true,
      slidesToShow: 2,
      edgeFriction: ".05",
      adaptiveHeight: false,
      draggable: true,
      rows: 1,
      infinite: false,
    };
    return (
      <div class="project-item">
        {/* {# Project Summary Heading #}
                {% set project_title = "New Film Project" %}
                {% set shared_project = true %}
                {% include "components/heading-groups/project-summary-heading.html" %} */}
        {/* {# Grid #} */}
        <ProjectSummaryHeader
          name={this.props.name}
          projectId={this.props.projectId}
          collaborators={this.props.collaborators}
          isOwner={this.props.isOwner}
          removeCollaborator={this.props.removeCollaborator}
          addCollaboratorToState={this.props.addCollaboratorToState}
          projectType={this.props.projectType}
          client={this.props.client}
          handleFormUnsaved={this.props.handleFormUnsaved}
          projectTypes={this.props.projectTypes}
          coverArt={this.props.coverArt}
          updateParent={this.props.updateParent}
          defaultArt={this.props.tracks[0]?.imageURL}
          complete={this.props.complete}
          purchaseId={this.props.purchaseId}
          deleteCB={this.props.deleteCB}
          leaveProjectCB={this.props.leaveProjectCB}
          markCompleteCB={this.props.markCompleteCB}
          markIncompleteCB={this.props.markIncompleteCB}
          isOwner={this.props.isOwner}
          projectLink={this.props.projectLink}
          linkEnabled={this.props.projectLinkActive}
          passwordEnabled={this.props.passwordEnabled}
          enableLink={this.props.enableLink}
          enablePassword={this.props.enablePassword}
          updateProjectLink={this.props.updateProjectLink}
          updatePassword={this.props.updatePassword}
          projectPassword={this.props.password}
          projectLinkId={this.props.projectLinkId}
          clearPW={this.props.clearPW}
          pwBlur={this.props.pwBlur}
          generateProjectLink={this.props.generateProjectLink}
          collaboratorEmail={this.props.collaboratorEmail}
          filteredCollaborators={this.props.filteredCollaborators}
          handleInputChange={this.props.handleInputChange}
          onKeyDown={this.props.onKeyDown}
          activeOption={this.props.activeOption}
          onClick={this.props.onClick}
          updateLatest={this.props.updateLatest}
          ownerEmail={this.props.ownerEmail}
          ownerName={this.props.ownerName}
          ownerPhoto={this.props.ownerPhoto}
          activeCollabs={this.props.activeCollabs}
          userSet={this.props.userSet}
        />

        {this.props.tracks.length > 0 ? (
          <Slider ref={(slider) => (this.slider = slider)} {...settings} className="card-carousel">
            {this.props.tracks.map((track) => {
              return (
                <div key={"track" + track.title} class="carousel-slide">
                  <TrackGridItem
                    {...track}
                    idKey={`artist-${track.artistId}-album-${track.albumId}-track-${track.trackId}-projectGridItem`}
                    otherTracks={this.props.tracks.map((track) => {
                      let trackRet = { ...track };
                      trackRet.idKey = "projectGridItem-" + track.trackId;
                      return trackRet;
                    })}
                  />
                </div>
              );
            })}
          </Slider>
        ) : !this.props.complete ? (
          <NavLink to="/music" class="prompt cursor-pointer">
            <div class="prompt-content">
              {/* {# Icon #} */}
              <div class="text-icon-xl mb-15">
                <span class="icon-add-track-lg"></span>
              </div>
              {/* {# Heading #} */}
              <h3 class="prompt-title">Add some tracks to your project</h3>
              {/* {# Description #} */}
              <p class="max-w-sm">
                Mauris non tempor quam, et lacinia sapien, Mauris accumsan eros eget.
              </p>
            </div>
          </NavLink>
        ) : (
          <div class="prompt">
            <div class="prompt-content">
              <div class="text-icon-xl mb-15">
                <span class="icon-no-tracks-lg"></span>
              </div>
              <h3 class="prompt-title">There are no tracks in this project.</h3>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default ProjectItem;

import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { PlayerConsumer } from "../../PlayerContext";
import { UserConsumer } from "../../contexts/User";
import api from "../utils/api";
import CTALarge from "./ctaLarge";
import FeatureBlock from "./featureBlock";
import FeatureBlockIcon from "./featureBlockIcon";
import FeaturedArtists from "./featuredArtists";
import FeaturedCollections from "./featuredCollections";

// TODO: Refactor this to use modal components so it doesn't duplicate code from ArtisTrackList

class Homepage extends Component {
  state = {
    featuredArtists: [],
    featuredCollections: [],
    activeTab: "featuredCollections",
  };
  componentDidMount = () => {
    let appMainEl = document.getElementsByClassName("app-main")[0];
    appMainEl.addEventListener("scroll", this.handleScroll);
    this.props.history.replace("/");
    api.getFeaturedCollections().then((res) => {
      if (res.data.collections.length > 0) {
        this.setState({ featuredCollections: res.data.collections });
      }
    });
    api.getFeaturedArtists().then((res) => {
      if (res.data?.featuredArtists?.length > 0) {
        this.setState({ featuredArtists: res.data.featuredArtists });
      }
    });
    api.getFreeCollection().then((res) => {
      if (res.data?.freeCollection?.length > 0) {
        this.setState({ freeCollection: res.data.freeCollection });
      }
    });
  };
  componentWillUnmount = () => {
    let appMainEl = document.getElementsByClassName("app-main")[0];
    appMainEl.removeEventListener("scroll", this.handleScroll);
    if (this.props.isMainOpen || this.props.isDemoOpen) {
      this.props.closeVideoMain();
    }
    // this.props.unloadVideo(true)
  };

  changeActiveTab = (tab) => {
    this.setState({ activeTab: tab });
  };

  handleScroll = () => {
    let videos = document.querySelectorAll("video.w-full");
    if (videos.length > 0) {
      let appMainEl = document.getElementsByClassName("app-main")[0];
      let fraction = 0.8;
      let scrollTop = appMainEl.scrollTop + 600;
      for (let i = 0; i < videos.length; i++) {
        let video = videos[i];
        let nextVideo = videos[i + 1];
        let videoY = video.offsetTop;

        video.setAttribute("muted", "");
        if (nextVideo) {
          let nextVideoY = nextVideo.offsetTop;

          if (videoY <= scrollTop && nextVideoY >= scrollTop) {
            video.play();
          } else {
            video.pause();
          }
        } else {
          if (videoY <= scrollTop) {
            video.play();
          } else {
            video.pause();
          }
        }
      }
    }
  };
  handleVideoStudio = () => {
    this.props.history.push("?videoStudioDemo");
    if (this.props.video) {
      this.props.openVideoDemo();
      let firstTrack = this.state.freeCollection[0].tracks[0];
      let playList = this.state.freeCollection[0].tracks.map((track) => track.trackId);
      this.props.setAutoPlay(false);
      this.props.setPlaylist(playList);
      this.props.loadTrackInMediaPlayer(
        playList[0],
        `artist-${firstTrack.artistId}-album-${firstTrack.albumId}-track-${firstTrack.trackId}-videoStudioDemoButton`
      );
    } else {
      this.props.openVideoLoader();
      let firstTrack = this.state.freeCollection[0].tracks[0];
      let playList = this.state.freeCollection[0].tracks.map((track) => track.trackId);
      this.props.setAutoPlay(false);
      this.props.setPlaylist(playList);
      this.props.loadTrackInMediaPlayerNoPlay(
        playList[0],
        `artist-${firstTrack.artistId}-album-${firstTrack.albumId}-track-${firstTrack.trackId}-videoStudioDemoButton`
      );
    }
  };
  render() {
    const callouts = [
      {
        icon: "icon-search",
        title: "Browsing Reimagined",
        desc: "Discover the perfect track for your project by searching for music and artists.",
      },
      {
        icon: "icon-albums",
        title: "Fair Trade Music",
        desc: "Unlike other platforms, artists retain 100% ownership in their music when they submit it to our library.",
      },
      {
        icon: "icon-stems-versions",
        title: "Versions & Stems",
        desc: "Some tracks contain various versions and include stems to have full control of the mix.",
      },
      {
        icon: "icon-success",
        title: "Monetize Your Content",
        desc: "Easily clear copyright claims on YouTube, or whitelist your channel with a subscription plan.",
      },
    ];
    return (
      <UserConsumer>
        {({ user, login, logout }) => (
          <>
            <Helmet>
              <title>Deep Sounds | Music for Creators</title>
              <meta
                name="description"
                content="Find amazing curated music from indie artists to use as the soundtrack to your content. Get royalty-free sync licensing using an Unlimited Music Subscription, or purchase individual track licenses."
              />
            </Helmet>
            <div className="hero-lg">
              {/* <!-- Content --> */}
              <div className="container z-10">
                <div className="grid-row flex-col md:flex-row md:items-end justify-between">
                  <div className="col">
                    <h1 className="ds-title leading-tightest relative">
                      Music For <br />
                      Your
                      <span className="text-anim-rotate">
                        <span>YouTube</span>
                        <span>Films</span>
                        <span>Twitch</span>
                        <span>Ads</span>
                      </span>
                    </h1>
                  </div>
                  <div className="col flex-none pt-40 md:pb-10 xl:pb-20">
                    <a href="/music" className="btn btn-primary">
                      Browse Music
                    </a>
                  </div>
                </div>
              </div>

              {/* <!-- Image --> */}
              <div
                className="hero-img"
                style={{
                  backgroundImage: `url("${process.env.PUBLIC_URL}/images/hero/hero-05.jpg")`,
                }}
              ></div>
            </div>
            {/* {# Featured Image/Video #} */}
            <section className="-mt-30 lg:mb-80 relative z-10">
              {/* <div className="container">
                                <figure className="w-full shadow-md"><img src={`${process.env.PUBLIC_URL}/images/hero/home-featured-01.jpg`} alt=""/></figure>
                            </div> */}
              <div className="container">
                {/* {# Feature Block #} */}
                {/* {% set feature = {
                        "video": "/assets/video/ThePerfectTrack.webm",
                        "title": "The Perfect Track",
                        "desc": "Search for Music using our many filters to narrow down the exact vibe you are going for.",
                        "button_text": "Browse Music"
                    } %} */}
                <div style={{ bottom: 0 }}>
                  <FeatureBlock
                    title={"Royalty-free Music"}
                    desc={
                      "Find amazing music from indie artists to use as the soundtrack to your content."
                    }
                    buttonText={"Browse Music"}
                    mp4={`${process.env.PUBLIC_URL}/videos/210628_ThePerfectTrack.mp4`}
                    webm={`${process.env.PUBLIC_URL}/videos/210628_ThePerfectTrack.webm`}
                    poster={`${process.env.PUBLIC_URL}/images/home/210628_ThePerfectTrack.jpg`}
                    link={"/music"}
                    forceEnd={true}
                  />
                </div>
                <div className="grid-row mt-40 lg:mt-60 mb-100 lg:mb-120 xl:mb-180">
                  {callouts.map((callout) => (
                    <div className="col w-1/2 xl:w-1/4" key={callout.icon}>
                      <div className="my-20">
                        <div className="mb-20">
                          <span className={callout.icon + " text-icon-lg text-gray-400"}></span>
                        </div>
                        <h3 className="font-display2 text-label text-white mb-20">
                          {callout.title}
                        </h3>
                        <p className="mb-0">{callout.desc}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </section>
            <section className="mb-100 lg:mb-120 xl:mb-180">
              <div className="container">
                <div className="tabs" x-data="{ tab: 'tab-1' }">
                  <ul className="tab-items">
                    <li
                      className={`tab-item ${
                        this.state.activeTab === "featuredCollections" && "active"
                      }`}
                    >
                      <button
                        onClick={() => {
                          this.changeActiveTab("featuredCollections");
                        }}
                        type="button"
                        className="tab-item-title"
                      >
                        Featured Collections
                      </button>
                    </li>
                    <li
                      className={`tab-item ${
                        this.state.activeTab === "featuredArtists" && "active"
                      }`}
                    >
                      <button
                        onClick={() => {
                          this.changeActiveTab("featuredArtists");
                        }}
                        type="button"
                        className="tab-item-title"
                      >
                        Featured Artists
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content">
                    <div
                      className={`tab-item-content pt-40 lg:pt-60 ${
                        this.state.activeTab === "featuredArtists" && "active"
                      }`}
                    >
                      <FeaturedArtists
                        closeVideoMain={this.props.closeVideoMain}
                        unloadVideo={this.props.unloadVideo}
                        featuredArtists={this.state.featuredArtists}
                      />
                    </div>
                    <div
                      className={`tab-item-content pt-40 lg:pt-60 ${
                        this.state.activeTab === "featuredCollections" && "active"
                      }`}
                    >
                      <FeaturedCollections
                        closeVideoMain={this.props.closeVideoMain}
                        unloadVideo={this.props.unloadVideo}
                        featuredCollections={this.state.featuredCollections}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <section className="mb-100 lg:mb-120 xl:mb-180">
              <div className="container">
                {/* {# Feature Block #} */}
                {/* {% set feature = {
                        "video": "/assets/video/ThePerfectTrack.webm",
                        "title": "The Perfect Track",
                        "desc": "Search for Music using our many filters to narrow down the exact vibe you are going for.",
                        "button_text": "Browse Music"
                    } %} */}
                {/* <FeatureBlock
                        title={"The Perfect Track"}
                        desc={"Search for Music using our many filters to narrow down the exact vibe you are going for."}
                        buttonText={"Browse Music"}
                        mp4={`${process.env.PUBLIC_URL}/videos/210628_ThePerfectTrack.mp4`}
                        webm={`${process.env.PUBLIC_URL}/videos/210628_ThePerfectTrack.webm`}
                        poster={`${process.env.PUBLIC_URL}/images/home/210628_ThePerfectTrack.png`}
                        link={"/music"}
                    /> */}

                {/* {# Icon Callouts #} */}
                {/* <div className="grid-row mt-40 lg:mt-60 mb-100 lg:mb-120 xl:mb-180">
                        {callouts.map(callout=>
                            <div className="col w-1/2 xl:w-1/4">
                                <div className="my-20">
                                    <div className="mb-20"><span className={callout.icon +  " text-icon-lg text-gray-400"}></span></div>
                                    <h3 className="font-display2 text-label text-white mb-20">{callout.title}</h3>
                                    <p className="mb-0">{callout.desc}</p>
                                </div>
                            </div>)}
                    </div>  */}
                <section className="mb-100 lg:mb-120 xl:mb-180">
                  <div className="container">
                    <FeatureBlock
                      title={"Video Studio"}
                      desc={
                        "Audition your tracks right here on our website by uploading your videos and using our video studio. Since it is only uploaded locally to your browser and not our servers, your videos are 100% secure."
                      }
                      buttonText={"Try Video Studio"}
                      mp4={`${process.env.PUBLIC_URL}/videos/210628_Video_Studio-08.mp4`}
                      webm={`${process.env.PUBLIC_URL}/videos/210628_Video_Studio-08.webm`}
                      poster={`${process.env.PUBLIC_URL}/images/home/210628_Video_Studio-08.jpg`}
                      buttonAction={this.handleVideoStudio}
                      isVideoStudio={true}
                    />
                  </div>
                </section>
                <section className="mb-100 lg:mb-120 xl:mb-180">
                  <div className="container">
                    <FeatureBlockIcon
                      title={"Designed for You"}
                      desc={
                        "Create projects to save potential tracks and share them with your collaborators or clients."
                      }
                      mp4={`${process.env.PUBLIC_URL}/videos/210628_DesignedForYou-02.mp4`}
                      webm={`${process.env.PUBLIC_URL}/videos/210628_DesignedForYou-02.webm`}
                      poster={`${process.env.PUBLIC_URL}/images/home/210628_DesignedForYou-02.jpg`}
                      alt={true}
                    />
                  </div>
                </section>
              </div>
            </section>
            <CTALarge
              title="Royalty-free music for your videos"
              subtitle={"Flexible Pricing"}
              desc={
                "Buy Individual Song Licenses or get Unlimited Music with a Subscription Plan. "
              }
              buttonPath={"pricing"}
              buttonText={"View Pricing"}
            />
          </>
        )}
      </UserConsumer>
    );
  }
}

export const HomepageWrapped = (props) => (
  <PlayerConsumer>
    {({
      setPlaylist,
      loadTrackInMediaPlayer,
      loadTrackInMediaPlayerNoPlay,
      setAutoPlay,
      playPause,
      isLoaderOpen,
      isMainOpen,
      isDemoOpen,
      isMini,
      openVideoLoader,
      openVideoDemo,
      closeVideoMain,
      closeVideoDemo,
      unloadVideo,
      closeVideoLoader,
      openVideoMain,
      video,
    }) => (
      <Homepage
        loadTrackInMediaPlayer={loadTrackInMediaPlayer}
        loadTrackInMediaPlayerNoPlay={loadTrackInMediaPlayerNoPlay}
        isDemoOpen={isDemoOpen}
        openVideoLoader={openVideoLoader}
        openVideoDemo={openVideoDemo}
        closeVideoDemo={closeVideoDemo}
        video={video}
        openVideoDemo
        closeVideoLoader={closeVideoLoader}
        closeVideoMain={closeVideoMain}
        unloadVideo={unloadVideo}
        setPlaylist={setPlaylist}
        setAutoPlay={setAutoPlay}
        playPause={playPause}
        {...props}
        isMainOpen={isMainOpen}
        isDemoOpen={isDemoOpen}
      />
    )}
  </PlayerConsumer>
);

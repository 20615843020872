import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import React from "react";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));
export default function BackdropOverlayFull (props){
    const classes = useStyles();
    return(
        <Backdrop invisible={props.invisible} className={classes.backdrop}  open={props.loading} >
            <CircularProgress size={props.size || 40} variant={props.variant || "indeterminate"} value={props.value || 0} color="inherit" />
        </Backdrop>
    )
}

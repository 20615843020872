import React, { useState } from "react";
import { confirmable, createConfirmation } from "react-confirm";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { withStyles } from "@material-ui/styles";
import ReactDOM from "react-dom";
import "./style.css";

export const Confirmation = React.forwardRef((props, ref) => {
  const [open, setOpen] = useState(true);

  const GlobalCss = withStyles({
    // @global is handled by jss-plugin-global.
    "@global": {
      // You should target [class*="MuiButton-root"] instead if you nest themes.
      ".MuiDialog-root": {
        margin: "0 auto",
      },
      ".MuiPaper-root": {
        fontSize: "1rem",
        background: "none",
        color: "white",
        borderRadius: 0,
      },
    },
  })(() => null);

  // const useStyles = makeStyles({
  //   root: {
  //     background: 'none',
  //     borderRadius: 0,
  //     color: 'white',
  //   }
  // });

  // const classes = useStyles();

  const { proceedLabel, cancelLabel, confirmation, proceed, title } = props;

  return ReactDOM.createPortal(
    open && (
      <>
        <GlobalCss />
        <Dialog
          title={title}
          onClose={() => proceed(false)}
          open={open}
          aria-labelledby="confirmation-dialog-title"
          className="max-w-lg"
        >
          <div
            role="dialog"
            aria-modal="true"
            aria-labelledby="confirm_modal_title"
            className="modal-container max-w-lg"
          >
            <div id="confirm_content" className="modal-content text-center">
              <div className="modal-container text-center" style={{ overflow: "hidden" }}>
                <p className="text-h4 text-white font-medium mb-5" id="confirmation-dialog-title">
                  {title}
                </p>
                <DialogContent className="modal-content text-center text-gray-500 mb-15">
                  {confirmation}
                </DialogContent>
                <DialogActions>
                  <div className="form-section" id="form_actions">
                    <div className="grid-row-xs">
                      <div className="col w-1/2">
                        <button
                          autoFocus
                          className="btn btn-stroke w-full"
                          id="modal-cancel"
                          onClick={() => {
                            setOpen(false);
                            proceed(false);
                          }}
                        >
                          {cancelLabel}
                        </button>
                      </div>
                      <div className="col w-1/2">
                        <button
                          className="btn btn-primary w-full"
                          id="modal-confirm"
                          onClick={() => {
                            setOpen(false);
                            proceed(true);
                          }}
                        >
                          {proceedLabel}
                        </button>
                      </div>
                    </div>
                  </div>
                </DialogActions>
              </div>
            </div>
          </div>
        </Dialog>
      </>
    ),
    props.fromProjectModal ? document.body : document.getElementById("app-root")
  );
});

export function confirm(
  confirmation,
  title,
  fromProjectModal,
  passRef,
  proceedLabel = "OK",
  cancelLabel = "Cancel",
  options = {}
) {
  return createConfirmation(confirmable(Confirmation))({
    confirmation,
    title,
    fromProjectModal,
    passRef,
    proceedLabel,
    cancelLabel,
    ...options,
  });
}

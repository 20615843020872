import React from "react"
import { Tooltip } from "react-tippy"
import 'react-tippy/dist/tippy.css'
import SortToolTip from "./sortToolTip"
const SortBar = (props)=>(
<>
    <div className={`sort-bar ${props.marginClasses}`}>
        <div className="sort-module">
            <span className="text-gray-400 sort-label">Sort by</span>
            <Tooltip
                arrow={false}
                useContext={true}
                open={props.isSortTippyOpen}
                interactive={true}
                position={'bottom-end'}
                trigger={'click'}
                // hideOnClick={true}
                html={(<SortToolTip 
                            sortBy={props.sortBy}
                            artistBrowse={props.artistBrowse}
                            artistTrackList={props.artistTrackList}
                            trackStatus={props.trackStatus}
                        >
                    </SortToolTip>
                )}
                allowHTML={true}
                onUntrigger={props.toggleSortTippy}
                onRequestClose={props.toggleSortTippy}
            >
            <button id={"sortTippy"} className="sort-toggle js-dropdown-toggle"
                onClick={props.toggleSortTippy}
                data-tippy-html={props.sortBarId}
                data-tippy-placement="bottom">
                {props.sortCriteria}
                <span className="sort-toggle-icon icon-chevron-down"></span>
            </button>
            </Tooltip>
            </div>
            <span className="text-gray-400">{props.resultsText}</span>
            {props.trackBrowse && 
                <div class="btn-group">
                    <button 
                        class="circle-icon circle-icon-sm"
                        onClick={()=>props.changeView('list')}
                    >
                        <span class={"icon-list " + (props.view === 'list' && 'text-white')}></span>
                    </button>
                    <button 
                        class="circle-icon circle-icon-sm"
                        onClick={()=>props.changeView('grid')}
                    >
                        <span class={"icon-grid " + (props.view === 'grid' && 'text-white')}></span>
                    </button>
        </div>}

    </div>
    {/* <div className="hidden" id={props.sortBarId}>
    <div className="dropdown-menu">
        {props.artistTrackList === true ?
            <div className="dropdown-group">
                <a href="#">Date Approved - ASC</a>
                <a href="#">Date Approved - DESC</a>
                <a href="#">Recently Added</a>
                <a href="#">Name - Asc</a>
                <a href="#">Name - Desc</a>
                {props.artistBrowse && <a href="#">Followers - Asc</a>}
                {props.artistBrowse && <a href="#">Followers - Desc</a>}
            </div>:
            <div className="dropdown-group">
                <a href="#">Most Relevant</a>
                <a href="#">Recently Added</a>
                <a href="#">Name - Asc</a>
                <a href="#">Name - Desc</a>
                {props.artistBrowse && <a href="#">Followers - Asc</a>}
                {props.artistBrowse && <a href="#">Followers - Desc</a>}
            </div>
        }
    </div>
</div> */}
</>
)
export default SortBar
function paginate(arr, size) {
  console.log("array to paginate", arr);
  if (arr?.length < 1) return;
  return arr.reduce((acc, val, i) => {
    let idx = Math.floor(i / size);
    let page = acc[idx] || (acc[idx] = []);
    page.push(val);

    return acc;
  }, []);
}
export default paginate;

import React from "react";
import MicroModal from "react-micro-modal";
import AddToProjectModal from "../addToProjectModal";

export default function AddToProjectModalLink(props) {
  const {
    className,
    user,
    notLoggedInOpen,
    toggleTrackTippy,
    trackId,
    trackTitle,
    TriggerElement,
    triggerLoginModal
  } = props;

  const defaultClassName = "circle-icon circle-icon-sm js-tooltip";

  return (
    <MicroModal
      trigger={(handleOpen) => (
        TriggerElement ? (
          <TriggerElement
            handleOpen={handleOpen}
            notLoggedInOpen={notLoggedInOpen}
            user={user}
          />
        ) : (
          <>
            <button
                className={className ? className : defaultClassName}
                onClick={user ? handleOpen : triggerLoginModal}
                title="Add to Project"
                type="button"
            >
              <span className="icon-plus"></span>
            </button>
          </>
        )
      )}
      closeOnEscapePress={true}
      closeOnOverlayClick={true}
      closeOnAnimationEnd={true}
      modalOverlayClassName="modal-overlay"
      modalClassName="modal micromodal-slide is-open"
      modalOverlayStyles={null}
      children={(handleClose) => (
        <AddToProjectModal
          toggleTrackTippy={toggleTrackTippy}
          handleClose={handleClose}
          trackId={trackId}
          trackTitle={trackTitle}
        />
      )}
      containerStyles={{
        background: "#131313",
        padding: "asdf",
        maxWidth: "40.625rem !important",
        maxHeight: "100vh",
        borderRadius: "asdf",
        overflowY: "auto",
        boxSizing: "border-box",
        zIndex: 7001,
      }}
    />
  );
}

import React from "react";
import { Error } from "../../Toast";
import LoadingButton from "../../FormElements/LoadingButton";
// import { useForm } from "react-hook-form";

const DSForm = (props) => {
  const defaultHandleSubmit = (data) => {
    console.log("data", data);
  };
  const defaultOnSubmit = (data, e) => console.log(data, e);
  const defaultOnError = (errors, e) => {
    Object.keys(errors).forEach(function (key, index) {
      Error({ message: errors[key].message, toastId: index });
    });
  };

  const {
    children,
    disabled = false,
    formId = "deepsounds-form",
    isLoaded = true,
    handleSubmit = defaultHandleSubmit,
    onError = defaultOnError,
    onSubmit = defaultOnSubmit,
    submitClassName = "btn btn-primary form-btn",
    submitText = "Submit",
  } = props;

  return (
    <form onSubmit={handleSubmit(onSubmit, onError)} className="ds-form" id={formId}>
      {children}
      <LoadingButton
        isDisabled={disabled || !isLoaded}
        isLoading={!isLoaded}
        className={submitClassName}
        id={`submit-${formId}`}
        type="submit"
      >
        {submitText}
      </LoadingButton>
    </form>
  );
};

export default DSForm;

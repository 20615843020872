import React, { useEffect, useState } from 'react';
import { loadStripe } from "@stripe/stripe-js";
import stripeAPI from "../api/stripe";
import { handleAPIError } from './handleAPIError';

export const useStripePromise = () => {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    // if (!stripePublishableKey) {
      stripeAPI
        .getPublishableKey()
        .then((res) => {
          const stripePublishableKey = res.data.stripeKey;
          console.log('stripePublishableKey', stripePublishableKey)
          setStripePromise(loadStripe(stripePublishableKey));
        })
        .catch((error) => {
          handleAPIError(error);
        });
    // }
  }, []);

  return stripePromise;
}

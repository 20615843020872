import React, { useEffect, useState, useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { UserContext } from "../../contexts/User";
import CircularIndeterminate from "../Loading/circularIndeterminant";
import CircularIndeterminateButton from "../Loading/circularIndeterminantButton";
import { Success } from "../Toast/index";
import api from "../utils/api";

// const parsedQuery = queryString.parse(this.props.location.search)

function redirectConfirmedUser(user, history) {
  let redirect;
  console.log("redirect user", user);
  if (user?.confirmRedirect && user?.confirmRedirect !== "/") {
    redirect = user?.confirmRedirect;
  } else {
    redirect = "/music";
  }

  history.push(redirect, {
    showToast: true,
    toastMessage: "Email Confirmed.",
    toastAutoClose: 10000,
  });
}

export default function UserConfirm() {
  let history = useHistory();
  const queryString = require("query-string");
  const location = useLocation();
  const parsedQuery = queryString.parse(location?.search);
  const confirmToken = parsedQuery?.enM;

  const [confirmed, setConfirmed] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  // const [data, setData] = useState([]);

  // async function getData(confirmToken) {
  //     api.confirmEmail(confirmToken)
  //         .then(res => {
  //             console.log('res.data', res.data);

  //             setConfirmed(res.data?.emailConfirmed);
  //             setLoaded(true);

  //             if (res.data?.emailConfirmed) {
  //                 document.cookie = `confirmed=${res.data?.emailConfirmed}; path=/;`
  //                 redirectConfirmedUser(res.data, history);
  //             }
  //         })
  //         .catch(err => {
  //             console.log(err);
  //         })
  // }

  useEffect(() => {
    if (confirmToken?.length) {
      console.log("confirm token", confirmToken);
      api
        .confirmEmail(confirmToken)
        .then((res) => {
          console.log("res.data", res.data);
          const updatedUser = res.data;
          setConfirmed(updatedUser?.emailConfirmed);
          setLoaded(true);

          if (updatedUser?.emailConfirmed) {
            document.cookie = `confirmed=${updatedUser?.emailConfirmed}; path=/;`;
            redirectConfirmedUser(updatedUser, history);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setLoaded(true);
      setConfirmed(false);
    }
  }, confirmToken);

  const userContext = useContext(UserContext);
  const user = userContext?.user;
  console.log("UserContext", userContext);

  if (user?.confirmed) {
    redirectConfirmedUser(user, history);
  }

  function handleResend(e) {
    e.preventDefault();
    setSubmitting(true);
    api
      .resendConfirmEmail()
      .then((res) => {
        if (res.status === 200) {
          Success({
            message: "Email confirmation re-sent!",
          });
          setSubmitting(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }

  if (!loaded || confirmed || user?.confirmed) {
    return (
      <div style={{ paddingBottom: "80%" }}>
        <CircularIndeterminate forceCenter />
      </div>
    );
  } else {
    return (
      <>
        <div className="hero-fixed">
          <div
            className="hero-img"
            style={{ backgroundImage: "url(/images/hero/signup-03.jpg)" }}
          ></div>
        </div>
        <div className="onboarding-main container container-xl">
          <h1 className="ds-title mb-30 lg:mb-40">Sign Up</h1>

          <div className="onboarding-form">
            <h4 className="mb-30 lg:mb-40">Confirm your email</h4>

            <p>
              Please check your email at <strong>{user.email}</strong> and click the confirmation
              link to begin using Deep Sounds.
            </p>

            <h5 className="mb-30 mt-30">Didn't receive the email?</h5>

            <div className="input-group-sm">
              <p>
                <button
                  className="btn btn-primary form-btn w-full"
                  disabled={submitting}
                  onClick={handleResend}
                >
                  {submitting ? <CircularIndeterminateButton /> : "Resend email"}
                </button>
              </p>
              <p>
                <a className="btn btn-primary form-btn w-full" href="/dashboard/account/settings">
                  Change email
                </a>
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

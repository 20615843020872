import React, { useContext, useEffect, useState } from "react";
import ChannelSummary from "./ChannelSummary";
import ChannelSearch from "./ChannelSearch";
import LoadingContainer from "../../Loading/LoadingContainer";
import { ContentIDContext } from "../../../ContentIDContext";
import { UserContext } from "../../../contexts/User";
export default function ChannelWhitelist(props) {
  const { userChannelData } = useContext(ContentIDContext);

  const { user } = useContext(UserContext);
  console.log("ChannelWhitelist user", user);

  const [isLoaded, setIsLoaded] = useState(props.isLoaded);
  useEffect(() => {
    setIsLoaded(userChannelData?.isLoaded);
  }, [userChannelData?.isLoaded]);

  return (
    <div className="my-50">
      <h2 className="text-h3 text-white mb-20">Channel Whitelisting</h2>

      <p className="text-h4 text-gray-400 mb-0">
        Add your YouTube channel ID below to be whitelisted for content ID and
        automatically clear any copyright claims.{" "}
        <a
          className="underline text-white"
          href="https://help.deepsounds.com/en/articles/5973749-whitelisting-your-youtube-channel"
          target="_blank"
          rel="noreferrer"
        >
          Learn more
        </a>
        .
      </p>

      {!(user.subscription && user.planStatus === "active") ? (
        <div className="mt-20">
          <a class="btn btn-primary" href="/pricing">
            Upgrade
          </a>
        </div>
      ) : (
        <LoadingContainer isLoaded={isLoaded}>
          {userChannelData?.status == "processing" ||
          userChannelData?.status == "success" ? (
            <div className="my-30">
              <ChannelSummary youtubeChannelData={userChannelData} />
            </div>
          ) : (
            <ChannelSearch />
          )}
        </LoadingContainer>
      )}
    </div>
  );
}

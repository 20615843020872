import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

export default function CircularIndeterminateButton(props) {
  const { color, size, thickness } = props;

  return (
    <CircularProgress
      thickness={thickness || 3.6}
      style={{
        color: color || "white",
        verticalAlign: "middle",
      }}
      size={size || "1.5rem"}
      // value={props.progress || 0}
      variant={"indeterminate"}
    />
  );
}

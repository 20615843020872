import CircularProgress from '@material-ui/core/CircularProgress';
import React from "react";
import "./loading.css";
export default function CircluarDeterminate(props){
    
    return(
        // <Box position="relative" display="inline-flex">
            props.forceCenter ? 
                <div className="force-center" style={{margin: "0 auto", paddingTop: "40px", paddingBottom: "40px"}}>
                    <CircularProgress
                        thickness={props.thickness || 3.6}
                        style={{color: "white"}}
                        size={props.size || 40}
                        // value={props.progress || 0}
                        variant={"indeterminate"}
                    /> 
                </div>:
            <div style={{ paddingTop: "40px", paddingBottom: "40px"}}>
            <CircularProgress
                thickness={props.thickness || 3.6}
                style={{color: "white", paddingTop: "40px", paddingBottom: "40px"}}
                size={props.size || 40}
                // value={props.progress || 0}
                variant={"indeterminate"}
            /> 
            </div>
        // {/* </Box> */}
    )
}


import Skeleton from "@material-ui/lab/Skeleton";
import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { Tooltip } from "react-tippy";
import { CartConsumer } from "../../contexts/Cart";
import { FavoritesConsumer } from "../../FavoritesContext/index";
import { PlayerContext } from "../../PlayerContext";
import { UserConsumer } from "../../contexts/User/index";
import AddToProjectModalLink from "../Projects/AddToProjectModalLink";
import LicenseModal from "../modal/LicenseModal";
import { Error, Success } from "../Toast/index";
import TrackGridBrowse from "../Waveform/trackGridBrowse";
import TrackGridBrowseNoInteract from "../Waveform/trackGridBrowseNoInteract";
import TrackIcons from "../Track/TrackIcons";
import TrackTooltip from "./trackTooltip";

const contentDis = require("content-disposition");
class TrackGridItem extends Component {
  state = {};

  componentDidUpdate = (prevProps) => {
    var isEqual = function (value, other) {
      // Get the value type
      var type = Object.prototype.toString.call(value);

      // If the two objects are not the same type, return false
      if (type !== Object.prototype.toString.call(other)) return false;

      // If items are not an object or array, return false
      if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;

      // Compare the length of the length of the two items
      var valueLen = type === "[object Array]" ? value.length : Object.keys(value).length;
      var otherLen = type === "[object Array]" ? other.length : Object.keys(other).length;
      if (valueLen !== otherLen) return false;

      // Compare two items
      var compare = function (item1, item2) {
        // Get the object type
        var itemType = Object.prototype.toString.call(item1);

        // If an object or array, compare recursively
        if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
          if (!isEqual(item1, item2)) return false;
        }

        // Otherwise, do a simple comparison
        else {
          // If the two items are not the same type, return false
          if (itemType !== Object.prototype.toString.call(item2)) return false;

          // Else if it's a function, convert to a string and compare
          // Otherwise, just compare
          if (itemType === "[object Function]") {
            if (item1.toString() !== item2.toString()) return false;
          } else {
            if (item1 !== item2) return false;
          }
        }
      };

      // Compare properties
      if (type === "[object Array]") {
        for (var i = 0; i < valueLen; i++) {
          if (compare(value[i], other[i]) === false) return false;
        }
      } else {
        for (var key in value) {
          if (value.hasOwnProperty(key)) {
            if (compare(value[key], other[key]) === false) return false;
          }
        }
      }

      // If nothing failed, return true
      return true;
    };
    //
    if (
      !isEqual(prevProps.otherTracks, this.props.otherTracks) &&
      this.props.otherTracks !== undefined
    ) {
      if (this.context.mediaPlayer.trackId === this.props.trackId) {
        this.context.setPlaylist(this.props.otherTracks);
      }
    }
  };

  play = () => {
    let idKey = `artist-${this.context.loadedTracks[this.props.trackId].artistId}-album-${
      this.context.loadedTracks[this.props.trackId].albumId
    }-track-${
      this.props.trackId
    }-project-(projectId)-collection-(collectionId)-element-(elementId)`;
    if ((this.context.mediaPlayer?.idKey?.indexOf(this.props.trackId) || -1) === -1) {
      if (this.props.playCB) {
        this.props.playCB(this.props.idKey, this.props.trackId);
      } else {
        let track = {
          trackId: this.props.trackId,
          idKey: this.props.idKey,
          artistURL: this.props.artistURL,
          peaks: this.props.peaks,
          imageURL: this.props.imageURL,
          duration: this.props.duration,
          trackTitle: this.props.trackTitle,
          artistName: this.props.artistName,
          liked: this.props.liked,
          trackURL: this.props.trackURL,
          albumTitle: this.props.albumTitle,
          versions: this.props.versions,
          stems: this.props.stems,
        };
        this.context.setAutoPlay(false);
        this.context.loadTrackInMediaPlayer(this.props.trackId, this.props.idKey);
        this.context.setProjectId(null);
        this.context.setCollectionId(null);
        if (this.props.otherTracks?.length > 0) {
          this.context.setPlaylist(this.props.otherTracks);
        }
      }
    } else {
      if (this.context.mediaPlayer.isTrackPlaying || this.context.isVideoPlaying) {
        this.context.handlePause();
      } else {
        this.context.handlePlay();
      }
    }
  };
  resolveAlbumPhoto = (albumPhotoKey) => {
    const key = albumPhotoKey;
    if (albumPhotoKey) {
      const ext = key.split(".").reverse()[0];
      let final = key.replace("." + ext, "") + "-minified-500x500" + "." + ext;
      return final;
    }
  };

  render() {
    return (
      <UserConsumer>
        {({ user, triggerLoginModal }) => (
          <FavoritesConsumer>
            {({
              artists,
              tracks,
              albums,
              collections,
              likeTrack,
              unlikeTrack,
              followArtist,
              unfollowArtist,
            }) =>
              !this.context.areTracksLoading ? (
                <div className="track-grid-card">
                  <div className="track-image">
                    <img
                      src={`https://${
                        process.env.REACT_APP_S3_PHOTO_BUCKET
                      }.s3.us-east-2.amazonaws.com/${
                        process.env.REACT_APP_S3_SUB_BUCKET
                          ? process.env.REACT_APP_S3_SUB_BUCKET + "/"
                          : ""
                      }${this.resolveAlbumPhoto(this.props.imageURL)}`}
                      alt="albumArt"
                    />
                    <div className="track-image-hover">
                      <div
                        onClick={this.play}
                        className="circle-icon-fill bg-white text-black hover-grow circle-icon-lg"
                      >
                        {(this.context.mediaPlayer?.idKey?.indexOf("track-" + this.props.trackId) ||
                          -1) !== -1 &&
                        (this.context.mediaPlayer.isTrackPlaying || this.context.isVideoPlaying) ? (
                          <span className="icon-pause"></span>
                        ) : (
                          <span className="icon-play"></span>
                        )}
                      </div>
                      <div className="wav-form-vector">
                        {/* {# Wav SVG #} */}
                        {this.props.peaks && (
                          <div>
                            {(this.context.mediaPlayer.idKey?.indexOf(
                              "track-" + this.props.trackId
                            ) || -1) !== -1 ? (
                              <TrackGridBrowse
                                idKey={this.props.idKey}
                                currentTime={this.props.currentTime}
                                trackId={this.props.trackId}
                                s3Key={this.props.wavURL}
                                peaks={this.props.peaks}
                              />
                            ) : (
                              <TrackGridBrowseNoInteract
                                idKey={this.props.idKey}
                                currentTime={this.props.currentTime}
                                trackId={this.props.trackId}
                                s3Key={this.props.wavURL}
                                peaks={this.props.peaks}
                              />
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="track-grid-content">
                    <div className="track-first-col flex items-top justify-between">
                      <div className="track-title-artist">
                        <NavLink
                          className="track-title truncate inline-block"
                          to={"/tracks/" + this.props.trackURL}
                        >
                          {this.props.trackTitle}
                        </NavLink>
                        <NavLink className="track-artist" to={"/artists/" + this.props.artistURL}>
                          {this.props.artistName}
                        </NavLink>
                      </div>
                      <span className="text-gray-400">{this.props.duration}</span>
                    </div>
                    <div className="genres-stems-versions items-top">
                      <div className="track-genres text-gray-400">
                        {Array.isArray(this.props.tags) && (
                          <>
                            {this.props.tags
                              .filter((tag) => tag.type === 2)
                              .map((emotion) =>
                                window.location.pathname.includes("/music") ||
                                /\/artists\/{0}[^\/]*$/.test(window.location.pathname) ? (
                                  <div>{emotion.name}</div>
                                ) : (
                                  <Link
                                    to={{
                                      pathname: "/music",
                                      search: `?inc_emo=${emotion.tagId}`,
                                      state: { from: window.location.pathname },
                                    }}
                                  >
                                    <span>{emotion.name}</span>
                                  </Link>
                                )
                              )}
                            {this.props.tags.filter((tag) => tag.type === 1).length > 0 &&
                              (window.location.pathname.includes("/music") ||
                              /\/artists\/{0}[^\/]*$/.test(window.location.pathname) ? (
                                <div>
                                  , {this.props.tags.filter((tag) => tag.type === 1)[0].name}
                                </div>
                              ) : (
                                <Link
                                  to={{
                                    pathname: "/music",
                                    search: `?inc_gen=${
                                      this.props.tags.filter((tag) => tag.type === 1)[0].tagId
                                    }`,
                                    state: { from: window.location.pathname },
                                  }}
                                >
                                  <span> </span>
                                  <span>
                                    {this.props.tags.filter((tag) => tag.type === 1)[0].name}
                                  </span>
                                </Link>
                              ))}
                          </>
                        )}
                      </div>
                      <span
                        className={`icons-stems-versions text-icon-md text-gray-400 icon${
                          this.props.stemId ? "-stems" : ""
                        }${this.props.versionId ? "-versions" : ""}`}
                      ></span>
                    </div>
                    <CartConsumer>
                      {({ handleAddedToCartModalClose }) => (
                        <SlidingPane
                          isOpen={this.state.isLicenseModalOpen || false}
                          onRequestClose={() => {
                            this.handleLicenseModalClose();
                            handleAddedToCartModalClose();
                          }}
                          // className={"test"}
                          from={"right"}
                          width={"100%"}
                          children={
                            <LicenseModal
                              handleClose={() => {
                                this.handleLicenseModalClose();
                                handleAddedToCartModalClose();
                              }}
                              track={{
                                trackId: this.props.trackId,
                                trackTitle: this.props.trackTitle,
                                artistId: this.props.artistId,
                                artistName: this.props.artistName,
                                albumArt: this.props.imageURL,
                              }}
                            />
                          }
                        ></SlidingPane>
                      )}
                    </CartConsumer>
                    <TrackIcons
                      artistId={this.props.artistId}
                      artistName={this.props.artistName}
                      isFeatured={this.props.isFeatured}
                      imageURL={this.props.imageURL}
                      itemType="grid"
                      trackId={this.props.trackId}
                      trackTitle={this.props.trackTitle}
                      trackURL={this.props.trackURL}
                    />
                  </div>
                </div>
              ) : (
                <div className="track-grid-card">
                  <Skeleton
                    width={"100%"}
                    height={"25.3rem"}
                    className="track-image"
                    style={{ backgroundColor: "rgb(210 205 205 / 42%)", lineHeight: 0 }}
                    animation="pulse"
                    variant="rect"
                  >
                    <img alt="albumArt" />
                  </Skeleton>
                  <div className="track-grid-content">
                    <div className="track-first-col flex items-top justify-between">
                      <div className="track-title-artist">
                        <Skeleton
                          style={{ backgroundColor: "rgb(210 205 205 / 42%)" }}
                          animation="pulse"
                        >
                          <span className="track-title">
                            <NavLink to={"/tracks/" + this.props.trackURL}>Placeholder</NavLink>
                          </span>
                        </Skeleton>
                        <Skeleton
                          style={{ backgroundColor: "rgb(210 205 205 / 42%)" }}
                          animation="pulse"
                        >
                          <span className="track-artist">
                            <NavLink to={"/artists/" + this.props.artistURL}>Placeholder</NavLink>
                          </span>
                        </Skeleton>
                      </div>
                      <Skeleton
                        style={{ backgroundColor: "rgb(210 205 205 / 42%)" }}
                        animation="pulse"
                      >
                        <span className="text-gray-400">0:00</span>
                      </Skeleton>
                    </div>
                    <div className="genres-stems-versions items-top">
                      <div className="track-genres text-gray-400">
                        <Skeleton
                          style={{ backgroundColor: "rgb(210 205 205 / 42%)" }}
                          animation="pulse"
                          variant="text"
                        >
                          <div>placer</div>
                        </Skeleton>
                        <Skeleton
                          style={{ backgroundColor: "rgb(210 205 205 / 42%)" }}
                          animation="pulse"
                          variant="text"
                        >
                          <div>holder</div>
                        </Skeleton>
                      </div>
                      <span
                        className={`icons-stems-versions text-icon-md text-gray-400 icon${
                          this.props.stemId ? "-stems" : ""
                        }${this.props.versionId ? "-versions" : ""}`}
                      ></span>
                    </div>
                    <CartConsumer>
                      {({ handleAddedToCartModalClose }) => (
                        <SlidingPane
                          isOpen={this.state.isLicenseModalOpen}
                          onRequestClose={() => {
                            this.handleLicenseModalClose();
                            handleAddedToCartModalClose();
                          }}
                          // className={"test"}
                          from={"right"}
                          width={"100%"}
                          children={
                            <LicenseModal
                              handleClose={() => {
                                this.handleLicenseModalClose();
                                handleAddedToCartModalClose();
                              }}
                              track={{
                                trackId: this.props.trackId,
                                trackTitle: this.props.trackTitle,
                                artistId: this.props.artistId,
                                artistName: this.props.artistName,
                                albumArt: this.props.imageURL,
                              }}
                            />
                          }
                        ></SlidingPane>
                      )}
                    </CartConsumer>
                    <div className="track-grid-icons">
                      <div className="btn-group-shrink">
                        <button
                          className="circle-icon circle-icon-sm btn-jelly js-tooltip"
                          title="Like Track"
                          type="button"
                        >
                          <span className="icon-like"></span>
                        </button>

                        <button className="circle-icon">
                          <span className="icon-add-to-cart"></span>
                        </button>
                      </div>
                      <div>
                        <Tooltip
                          arrow={false}
                          open={this.state.showTrackTippy}
                          interactive={true}
                          position={"bottom-end"}
                          popperOptions={{
                            modifiers: {
                              preventOverflow: { enabled: false },
                              flip: { enabled: false },
                              hide: { enabled: false },
                              addZIndex: {
                                enabled: true,
                                order: 810,
                                fn: (data) => ({
                                  ...data,
                                  styles: {
                                    ...data.styles,
                                    zIndex: 10,
                                  },
                                }),
                              },
                            },
                          }}
                          useContext={true}
                          className="circle-icon"
                          html={<TrackTooltip></TrackTooltip>}
                        >
                          <span id="trackToolTip" className="icon-dots-vert"></span>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </FavoritesConsumer>
        )}
      </UserConsumer>
    );
  }
}

TrackGridItem.contextType = PlayerContext;
export default TrackGridItem;

import Moment from "moment";
import React, { Component } from "react";
import 'react-tippy/dist/tippy.css';
import api from "../utils/api";

class InvitedUsers extends Component {
    state = {
        loaded: false,
        userList: [],
    }

    componentDidMount = () => {
        api.getInvites()
            .then(res => {
                
                this.setState({ 
                    loaded: true,
                    invitesList: res.data 
                })
            })
    }
    resolveProfilePhoto=(profilePhotoKey)=>{
        const key = profilePhotoKey
        if(profilePhotoKey){
            const ext = key.split('.').reverse()[0];
            let final = key.replace('.' + ext, '') + '-minified-140x140'  + '.' + ext
            return final
        }
    }
    render() {
        
        return (
            <div className="grid-row-xs mb-40">
                {/* {# Invites List #} */}
                {this.state.loaded && this.state.invitesList.map( (invite) => (

                    <div className="col w-full md:w-1/2 xl:w-1/3 mb-10 sm:mb-20" >
                        <div className={`user-card-sm` }>
                            {/* {# Left #} */}
                                <div className="user-card-content">
                                    <div className="user-card-name ml-15">
                                        <span className="user-card-heading">
                                            {invite.email}
                                        </span>
                                        <div class="user-card-meta">
                                            <span>{invite.role?.charAt(0).toUpperCase() + invite.role?.slice(1)}</span>
                                            <span class="mx-5 text-gray-400">•</span>
                                            <span>Invited on {Moment(invite.createdOn).format("LL")}</span>
                                        </div>
                                    </div>
                                </div>
                    
                            
                            {/* 
                             <button className="circle-icon circle-icon-sm icon-dots-vert"></button>
                             <button className="track-list-selected text-white"><span className="icon-check-active"></span></button> */}

                        </div>
                    </div>
                ))}
            </div>
        )
      }
        
}

export default InvitedUsers
import React, { useContext, useState } from "react";
import { CircularProgress } from "@material-ui/core";
import downloadsAPI from "../../api/downloads";
import { handleAPIError } from "../../utils/handleAPIError";
import downloadURL from "../../utils/downloadURL";
import { UserContext } from "../../contexts/User";

const DownloadPreview = ({
  children,
  handleClose,
  stem: stemId,
  track,
  version: versionId }) => {
  // @param children (component)
  // - component to render
  // @param handleClose (function)
  // - function to close parent element
  // @param track (object)
  // - track to download
  // @param version (string)
  // - version ID

  const [isDownloading, setIsDownloading] = useState(false);

  const { trackId } = track;

  const userContext = useContext(UserContext);
  const { user, triggerLoginModal } = userContext || {};
  const { user_id } = user || {};

  const downloadTrackPreview = () => {
    setIsDownloading(true);
    downloadsAPI
      .downloadTrackPreview({ trackId })
      .then((res) => {
        // console.log("down res", res)
        const { filename, url } = res?.data || {};
        if (url) {
          downloadURL({ filename, url });
        }
        setIsDownloading(false);
      })
      .catch((error) => {
        handleAPIError(error);
        setIsDownloading(false);
      });
  };

  const downloadVersionPreview = () => {
    setIsDownloading(true);
    downloadsAPI
      .downloadVersionPreview({ trackId, versionId })
      .then((res) => {
        // console.log("down res", res)
        const { filename, url } = res?.data || {};
        if (url) {
          downloadURL({ filename, url });
        }
        setIsDownloading(false);
      })
      .catch((error) => {
        handleAPIError(error);
        setIsDownloading(false);
      });
  };

  const downloadStemPreview = () => {
    setIsDownloading(true);
    downloadsAPI
      .downloadStemPreview({ stemId, trackId })
      .then((res) => {
        console.log("down res", res)
        const { filename, url } = res?.data || {};
        if (url) {
          downloadURL({ filename, url });
        }
        setIsDownloading(false);
      })
      .catch((error) => {
        handleAPIError(error);
        setIsDownloading(false);
      });
  }

  const downloadFunction = () => {
    if (!user_id) {
      handleClose();
      triggerLoginModal();
      return;
    }

    if (stemId) {
      downloadStemPreview();
    }
    else if (versionId) {
      downloadVersionPreview();
    }
    else {
      downloadTrackPreview();
    }
  }



  return (
    <>
      <button
        onClick={(e) => {
          e.preventDefault();
          downloadFunction();
        }}
        className="text-sm"
      >
        {isDownloading ? (
          <CircularProgress style={{ color: "#5F5D5D", marginRight: 1 }} size={".75rem"} variant={"indeterminate"} />
        ) : (
          <span className="icon-download"></span>
        )}{" "}
        {children ? children : "Download watermarked preview"}
      </button>
    </>
  );
};

export default DownloadPreview;

import React from "react"
import { NavLink } from "react-router-dom"
import { Tooltip } from "react-tippy"
import 'react-tippy/dist/tippy.css'
import ArtistCardToolTip from "./artistCardToolTip"

const resolveArtistPhoto=(artistPhotoKey)=>{
    const key = artistPhotoKey
    if(artistPhotoKey){
        const ext = key.split('.').reverse()[0];
        let sizeMod;
        let width = window.innerWidth
        if(width >= 1024){
            sizeMod = '500x500'
        }
        else{
            sizeMod = '325x325'
        }
        let final = key.replace('.' + ext, '') + '-minified-' + sizeMod + '.' + ext
        return final
    }
}
const resolveAlbumPhoto=(artistPhotoKey)=>{
    const key = artistPhotoKey
    if(artistPhotoKey){
        const ext = key.split('.').reverse()[0];
        let final = key.replace('.' + ext, '') + '-minified-140x140'+ '.' + ext
        return final
    }
}
const FeaturedArtistCard = (props)=><div className="artwork-card">
<div className="artwork-card-image">
    <img src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${resolveArtistPhoto(props.profilePhoto)}`} alt={props.artistName + " profile picture"}/>
    <div className="artwork-card-hover">
        {((props.contextIdKey?.indexOf(`featuredArtistItemCard-${props.artistId}`) || -1) !==-1 ) && props.isPlaying ?
        <div onClick={props.handlePause} className="circle-icon-fill circle-icon-xl bg-white text-black hover-grow">
        <span  className="icon-pause"></span>
            </div>:
            ((props.contextIdKey?.indexOf(`featuredArtistItemCard-${props.artistId}`) || -1) !==-1 ) ?
            <div onClick={props.handlePlay} className="circle-icon-fill bg-white text-black circle-icon-xl hover-grow">
                <span  className="icon-play"></span>
            </div>:
            <div onClick={()=>{props.play(props.tracks, props.idKey)}} className="circle-icon-fill bg-white text-black circle-icon-xl hover-grow">
                <span  className="icon-play"></span>
            </div>
        }

    </div>
</div>
<div className="artwork-card-content">
    <div className="artwork-card-title"><NavLink to={"/artists/"+props.artistURL}>{props.artistName}</NavLink></div>
    <div className="artwork-card-subtext">{props.tracks.length} Tracks</div>
    <div className="img-summary mt-10">
            {props.albums.slice(0,3).map(album=>
                <div key={"featuredArtistAlbum"+album.title}className="img-summary-item js-tooltip" title={album.title}>
                    <img src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${resolveAlbumPhoto(album.imageURL)}`} alt={album.title + " album art"}/>
                </div>
            )}
            {props.albums.length > 3 && <div className="img-summary-more">+{props.albums.length- 3}</div> }

    </div>

    <button  className="circle-icon text-gray-500">
            <Tooltip
                useContext={true}
                arrow={false}
                open={props.isArtistTippyOpen}
                interactive={true}
                position={'bottom-end'}
                popperOptions={{
                    modifiers: {
                        preventOverflow: { enabled: false },
                        flip: { enabled: false },
                        hide: { enabled: false },
                        addZIndex: {
                            enabled: true,
                            order: 810,
                            fn: data => ({
                                ...data,
                                styles: {
                                ...data.styles,
                                zIndex: 10,
                                },
                            })
                        }
                     }
                }}
                trigger={'click'}
                // hideOnClick={true}
                html={(<ArtistCardToolTip
                            artistURL={props.artistURL}
                            artistId={props.artistId}
                            tracks={props.featuredOrder}
                            playTracks={props.playTracks}
                            shareArtist={props.shareArtist}
                        >
                    </ArtistCardToolTip>
                )}
                allowHTML={true}
                onUntrigger={()=>props.toggleFeaturedTippy(props.artistId)}
                onRequestClose={()=>props.toggleFeaturedTippy(props.artistId)}
            >
        <span onClick={()=>props.toggleFeaturedTippy(props.artistId)} className="icon-dots-vert"></span>
    </Tooltip>

    </button>

</div>
</div>

export default FeaturedArtistCard

import React, { Component } from "react";
// import tippy from "../../tooltips"

class RejectedToolTip extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {

            }
    //updating our input fields as the user enters keys
    handleInputChange = event => {   
    };

    render() {
        return (    
            <div  id={`${this.props.track.title}_errors`}>
                <div className="dropdown-menu dropdown-menu-sm">
                    <div className="dropdown-group">
                        {this.props.track.errors.map(error=>(
                            <li className="text-white">
                                <span className="icon-reject text-primary"></span>
                                {error.title}
                            </li>
                        ))}
                    </div>
                </div>
            </div>
        )
    }

}
export default RejectedToolTip
import React from "react";

const SubscriptionIndicator = ({
  label,
  desc,
  value,
  licensesIncluded,
  override,
}) => {
  return (
    <div className="flex items-center">
      <div className="flex-1">
        <div className="text-white item-label">{label}</div>
        <div className="text-xs text-gray-500 font-medium item-description">{desc}</div>
      </div>
      {licensesIncluded?.indexOf(value) !== -1 && !override && (
        <div className="flex items-center flex-none text-icon-md text-gray-400 item-icon">
          <span className="icon-checklist"></span>
        </div>
      )}
    </div>
  );
};

export default SubscriptionIndicator;

import React from "react";
import { Helmet } from "react-helmet";
import buildAlbumArtURL from "../../Albums/AlbumArt";
import buildArtistURL from "../../../utils/buildArtistURL";
import formatListArray from "../../../utils/formatListArray";
export default function ArtistDetailHelmet({
  artist,
  artistURL,
  featuredTracks
}) {
  const getThumbnail = (photoKey) => {
    const key = photoKey;
    if (photoKey) {
      const ext = key.split(".").reverse()[0];
      let final = key.replace("." + ext, "") + "-minified-325x325" + "." + ext;
      return final;
    }
  };

  const {
    artistName,
    bio,
    primaryGenreName,
    profilePhoto,
    secondaryGenreName
  } = artist;

  const thumbnailURL = getThumbnail(profilePhoto);
  const photoURL = buildAlbumArtURL(thumbnailURL);
  const pageURL = buildArtistURL(artistURL);
  let genresDisplay = primaryGenreName;
  if (secondaryGenreName) {
    genresDisplay = genresDisplay + " & " + secondaryGenreName;
  }
  const featuredTracksDisplay = formatListArray(featuredTracks.map(track => `"${track.title}"`));
  console.log("featuredTracks", featuredTracks);

  const description = `${artistName} is a musical artist who makes ${genresDisplay} songs. Their top tracks are ${featuredTracksDisplay}. ${bio}`;

  return (
    <Helmet>
      <title>{`${artistName} - Deep Sounds | Music for Creators`}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={`${artistName} - Deep Sounds | Music for Creators`} />
      <meta property="og:image" content={photoURL} />
      <meta property="og:type" content="music.album" />
      <meta property="og:music:musician" content={artistName} />
      <meta property="og:url" content={pageURL} />
      <meta property="og:description" content={description} />
      <link rel="canonical" href={pageURL} />
    </Helmet>
  );
}


import React, { Component } from "react"
import { UserConsumer } from "../../contexts/User"
import CharacterCount from "../FormElements/characterCount"

// Styles for Single Select
class RejectionCommentModal extends Component {
    render() {
        return (
            <UserConsumer>
                {({ user, login, loginMessage }) => (
                    <>
                                   {/* {# Modal Header #} */}
                                   <header className="modal-header">
<h2 id="new_track_modal_title" className="modal-title">Add Rejection Comment</h2>
<button type="button"  onClick={this.props.handleClose} aria-label="Close Modal"  className="modal-close"></button>
</header>

<div id="new_track_modal_content" className="modal-content">

{/* {# Form #} */}
<form action="" className="ds-form">


 {/* {# Title #} */}
 <div className="input-group">
     <label className="input-label" htmlFor="track_title">Rejection Comment</label>
     {this.props.rejectionCommentError &&
        <div className="text-primary">{this.props.rejectionCommentError}</div>
     }
      <input value={this.props.rejectionComment} onChange={this.props.handleInputChange} name={"rejectionComment"} id="rejection_comment" className="form-input" placeholder="Enter rejection comment.."/>
      <CharacterCount
        limit="200"
        value={this.props.rejectionComment?.length}
      />
 </div>


 {/* {# Buttons #} */}
 <div className="input-group pt-20">
     <div className="grid-row-sm">
         <div className="col w-1/2 mb-20 sm:mb-0">
             <button disabled={this.props.submitting} onClick={(event)=>{event.preventDefault(); if(!this.props.submitting)this.props.rejectTrack()}} className="btn btn-stroke form-btn w-full">Reject Track</button>
         </div>
         <div className="col w-1/2">
             <button disabled={this.props.submitting} onClick={(event)=>{event.preventDefault(); if(!this.props.submitting)this.props.handleClose()}} className="btn btn-primary form-btn w-full">Cancel</button>
         </div>
     </div>
 </div>

</form>
 </div>
                    </>
                )}</UserConsumer>
        )
    }

}
export default RejectionCommentModal

import React from "react"

export default function TrackInfo(props){
    return(
        <div className="flex border-t border-gray-300 pt-25">

            {/* {# Name / Artist #} */}
            <div className="flex-1 text-sm pr-15">
                <div className="text-white">{props.trackTitleDisplay}</div>
                <div className="text-gray-400">{props.artistNameDisplay}</div>
            </div>

            {/* {# Loop / Duration #} */}
            <div className="flex-none text-sm w-100 text-right">
                <div className="flex text-white items-center justify-end mb-5">
                    <div className="mr-5">
                        Loop
                    </div>
                    {/* {# Switch #} */}
                    <label className="form-switch form-switch-sm">
                        <input onChange={props.toggleLoop}  type="checkbox" id="loop_enabled" checked={props.isLoopEnabled} name="loop_enabled"/>
                        <span
                            className="form-switch-slider">
                            {/* // @click="enabled = !enabled"> */}
                        </span>
                    </label>
                </div>
                <div className="text-gray-400">
                    <span className="vs-loop-start">{props.region?.startFormatted}</span>
                                    -
                    <span className="vs-loop-end">{props.region?.endFormatted}</span>
                </div>
            </div>

        </div>
    )
}

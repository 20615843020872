import React, { Component } from "react";
import ReactPasswordStrength from "react-password-strength";
import Select, { components } from "react-select";
import { Tooltip } from "react-tippy";
import { UserConsumer } from "../../contexts/User";
import customStyles from "../FormElements/customStyles";
import proOrgs from '../FormElements/Options/proOrgs';
import CircularIndeterminateButton from "../Loading/circularIndeterminantButton";
import { Error, Success } from "../Toast/index";
import api from "../utils/api";
import ChangeEmailField from "./SettingsFields/changeEmailField";
import CustomValueContainer from "../FormElements/customValueContainer"

class Settings extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {
                email: "",
                oldPassword: "",
                newPassword: "",
                newPasswordConfirm:"",
                loginMessage: "",
                updatePasswordText: "Update Password",
                updateEmailText: "Update Email Address",
                proText: "Update Songwriter P.R.O. Information",
                publisherProText: "Update Publisher Information",
                shouldBlockNavigation: false
            }
    // updating our input fields as the user enters keys
    componentDidMount = ()=>{
        this.props.handleFormUnsaved(false)
        this.passwordStrengthRef = React.createRef()
    }
    handleInputChange = event => {
        const { name, value } = event.target;
        if(!this.state.disableInput){

            this.props.handleFormUnsaved(true)

            this.setState({
                [name]: value,
            })
        }

    };
    setArtistPro = (event)=>{
        this.props.handleFormUnsaved(true)
        this.setState({artistPro: event.value});
    }

    setPublisherPro = (event)=>{
        this.props.handleFormUnsaved(true)
        this.setState({publisherOrg: event.value});
    }

    updatePassword = event => {
        event.preventDefault();
        this.setState({
            passwordLoading: true,
        })
        if (this.state.newPassword && this.state.pwValid && this.state.oldPassword) {
            let passwordResetBody = {
                oldPassword: this.state.oldPassword,
                newPassword: this.state.newPassword,
                email: this.state.email,
            }

                api.updatePassword(passwordResetBody)
                    .then(res=>{
                        console.log('updatePassword res', res)
                        if (res.data?.success){
                            Success({message: "Password Updated"})

                            this.setState({
                                passwordLoading: false,
                                newPassword: "",
                                newPasswordConfirm: "",
                                oldPassword: "",
                                shouldBlockNavigation: false
                            })
                            this.props.handleFormUnsaved(false)
                            this.passwordStrengthRef.current.clear()
                        }
                        else{
                            Error({message: "Error Updating Password"})
                            this.setState({
                                passwordLoading: false,
                                shouldBlockNavigation: false

                            })
                            this.props.handleFormUnsaved(false)
                        }
                    })
                    .catch(error=>{
                        if(error.response?.data === "Invalid email or password"){
                            Error({message: "Current Password is Incorrect."})
                        }
                        else{
                            Error({message: "Error Updating Password"})
                        }
                        this.setState({
                            passwordLoading: false,
                            shouldBlockNavigation: false

                        })
                        this.props.handleFormUnsaved(false)
                    })


        }
    }
    // updateEmail= (event)=>{
    //     event.preventDefault()

    //     this.setState({
    //         emailLoading: true
    //     })
    //     if(this.state.email && this.state.emailPasswordConfirm){
    //         api.updateEmail({newEmail: this.state.email, password: this.state.emailPasswordConfirm})
    //             .then(res=>{

    //                 if(res.status === 200){
    //                     Success({message: "Email updated (need to confirm)."})
    //                     this.setState({
    //                         emailLoading: false,
    //                         shouldBlockNavigation: false,
    //                         emailPasswordConfirm: ""
    //                     })
    //                     this.props.handleFormUnsaved(false)
    //                     document.cookie = `pendingEmail=${this.state.email}; path=/;`
    //                 }
    //                 else{
    //                     Error({message: "Error updating email."})
    //                     this.setState({
    //                         emailLoading: false,
    //                     })
    //                     this.props.handleFormUnsaved(false)
    //                 }
    //             })
    //             .catch(error=>{
    //                 if(error.response?.status === 401){
    //                     Error({message: "Invalid password."})
    //                 }
    //                 else if(error.response?.status === 400){

    //                     if(error.response?.data?.indexOf("Email already exists") !==-1){
    //                         Error({message: "Email already exists."})
    //                     }
    //                     else if(error.response?.data?.indexOf("This is already your email address.") !==-1){
    //                         Error({message: "This is already your email address."})
    //                     }
    //                     else{
    //                         Error({message: "Invalid password."})
    //                     }
    //                 }
    //                 else{
    //                     Error({message: "Error updating email."})
    //                 }
    //                 this.setState({
    //                     emailLoading: false,
    //                     shouldBlockNavigation: false,
    //                     emailPasswordConfirm: ""
    //                 })
    //             })
    //     }
    //     else{
    //         if(!this.state.email){
    //             this.setState({newEmailFieldError: "Please enter email."})
    //         }
    //         if(!this.state.emailPasswordConfirm){
    //             this.setState({newEmailPasswordError: "Please enter password."})
    //         }

    //     }

    // }

    addSelfPro = (event) => {
        event.preventDefault()
        this.setState({
            proText: "",
            proLoading: true
        })
        let proRequest = {
            org: this.state.artistPro,
            number: this.state.proNumber,
        }
        if (this.props.details.selfPro.id === null) {
            api.addProRecord(proRequest)
                .then(res => {

                    //pro was successfully updated
                    if (res.status === 200) {
                        Success({message: "P.R.O. Added"})
                        this.setState({
                            proLoading: false,
                            shouldBlockNavigation: false
                        })
                        this.props.handleFormUnsaved(false)
                    }
                })
                .catch(error=>{
                    Error({message: "Error Adding P.R.O."})
                })
        }
        else {
            api.editProRecord(this.props.details.selfPro.id, proRequest)
                .then(res => {

                    //pro was successfully updated
                    if (res.status === 200) {
                        Success({message: "P.R.O. Information Updated"})
                        this.setState({
                            proLoading: false,
                            shouldBlockNavigation: false
                        })
                        this.props.handleFormUnsaved(false)
                    }
                })
                .catch(error=>{
                    Error({message: "Error Updating P.R.O. Information"})
                })
        }
    }
    addPublisherPro = (event) => {
        event.preventDefault()
        this.setState({
            publisherProLoading: true
        })
        let proRequest = {
            org: this.state.publisherOrg,
            number: this.state.publisherProNumber,
            publisherName: this.state.publisherName
        }
        if (this.props.details.publisherPro.id === null) {
            api.addProRecord(proRequest)
                .then(res => {

                    //pro was successfully updated
                    if (res.status === 200) {
                        Success({message: "Publisher Added"})
                        this.setState({
                            publisherProLoading: false,
                            shouldBlockNavigation: false

                        })
                        this.props.handleFormUnsaved(false)
                    }
                })
                .catch(error=>{
                    Error({message: "Error Adding Publisher"})
                })
        }
        else {
            api.editProRecord(this.props.details.publisherPro.id, proRequest)
                .then(res => {

                    //pro was successfully updated
                    if (res.status === 200) {
                        Success({message: "Publisher Information Updated"})
                        this.setState({
                            publisherProLoading: false,
                            shouldBlockNavigation: false
                        })
                        this.props.handleFormUnsaved(false)
                    }
                })
                .catch(error=>{
                    Error({message: "Error Updating Publisher Information"})
                })
        }
    }
    changeCallback=(state, fullObject)=>{

        if(state.isValid && this.state.passwordError){
            this.setState({passwordError: false})
        }
        this.setState({newPassword: state.password, pwScore: state.score, pwValid: state.isValid, pwSuggestions: fullObject?.feedback?.suggestions || [], pwWarnings: fullObject?.feedback?.warning, fullObject: fullObject})
    }
    focusPW(shouldFocus){
        this.setState({pwFocused: shouldFocus, isPWTippyOpen: shouldFocus})
    }
    render() {
        return (
            <UserConsumer>
                {({ user, login, logout})=>(
                    <>
                    {/* <Prompt when={this.state.shouldBlockNavigation}
                    message='You have unsaved changes, are you sure you want to leave?'></Prompt> */}
                         <div className="mb-50">
                             <h2 className="text-h3 text-white mb-20">Update Account Settings</h2>
                             <p className="text-h4 text-gray-400 mb0">Please use the form below to edit your account settings.</p>
                         </div>

                            {/* {# Form Container #} */}
                         <div className="w-full xl:w-2/3 mb-50 lg:mb-80">

                             {/* {# Section #} */}
                             <ChangeEmailField
                                email={user.email}
                                handleFormUnsaved={this.props.handleFormUnsaved}
                            />
                             {/* <div className="form-section">
                                 <h3 className="text-label-lg mb-30">Update Email Address</h3>

                                 <div className="input-group input-inline">
                                    <input
                                        autofill='off'
                                        onChange={this.handleInputChange}
                                        name="email"
                                        value={this.state.email}
                                        type="text"
                                        id="update_email"
                                        className="form-input"
                                        placeholder={"New Email Address *"}
                                    />
                                     <label className="input-label" htmlFor="update_email">New Email Address *</label>
                                 </div>

                                 <div className="input-group input-inline">
                                     <input onChange={this.handleInputChange} name="emailPasswordConfirm" value={this.state.emailPasswordConfirm} type="password" id="update_email_pass" className="form-input" placeholder="Confirm Password *"/>
                                     <label className="input-label" htmlFor="update_email_pass">Confirm Password *</label>
                                 </div>
                                 <div className="col w-full">
                                     <button onClick={this.updateEmail}  className="btn btn-primary form-btn w-full">
                                        {this.state.emailLoading ? <CircularIndeterminateButton  size={45}  thickness={5.5}/> : this.state.updateEmailText}</button>
                                 </div>

                             </div>  */}

                             {/* {# Section #} */}
                             <div className="form-section">
                                 <h3 className="text-label-lg mb-30">Update Password</h3>
                                 {this.state.loginMessage &&
                                 <div className="text-primary" style={{marginTop: "-30px"}}>{this.state.loginMessage}</div>
                                 }
                                 <div className="input-group input-inline">
                                     <input onChange={this.handleInputChange} name="oldPassword" value={this.state.oldPassword} type="password" id="current_pass" className="form-input" placeholder="Current Password"/>
                                     <label className="input-label" htmlFor="current_pass">Current Password</label>
                                 </div>

                                 <div className="grid-row-sm">

                                     <div className="col w-full">
                                     <div className="input-group-sm input-inline">
                                    <Tooltip
                                        arrow={false}
                                        open={this.state.isPWTippyOpen}
                                        position={'top'}
                                                   html={(!this.state.pwValid ? this.state.newPassword?.length > 7 ? <>{this.state.pwWarnings && <> <span className="icon-reject text-primary"></span>{this.state.pwWarnings}</>}
                                                {this.state.pwSuggestions.length > 0 && <div>Suggestions:</div>}
                                                {this.state.pwSuggestions.map(suggestion=><div>{suggestion}</div>)}
                                                </> : <><div>Password must be at least 8 characters</div></>: <><div>Password is good to go!</div></>
                                        )}
                                        allowHTML={true}
                                    >
                                        <>
                                        {this.state.passwordError &&
                                            <div className="error-message">Password not strong enough</div>
                                        }
                                        <ReactPasswordStrength
                                            inputProps={{placeholder: "New Password", type: this.state.pwFocused ? "text":"password", name: "newPassword",
                                                onFocus: ()=>this.focusPW(true),
                                                onBlur: ()=>this.focusPW(false),
                                                className: "form-input",
                                                id:"password" }}
                                            minScore={3}
                                            minLength={8}
                                            ref={this.passwordStrengthRef}
                                            changeCallback={this.changeCallback}
                                            namespaceClassName={"dspw"}
                                            scoreWords={['ultra weak','very weak', 'weak', 'strong', 'very strong']}
                                        />
                                        </>
                                    </Tooltip>

                                    <label className="input-label" htmlFor="password">Password</label>
                                </div>
                                         {/* <div className="input-group input-inline">
                                             <input onChange={this.handleInputChange} name="newPassword" value={this.state.newPassword} type="password" id="new_pass" className="form-input" placeholder="New Password"/>
                                             <label className="input-label" htmlFor="new_pass">New Password</label>
                                         </div> */}
                                     </div>

                                     {/* <div className="col w-full md:w-1/2">
                                         <div className="input-group input-inline">
                                             <input onChange={this.handleInputChange} name="newPasswordConfirm" value={this.state.newPasswordConfirm} type="password" id="new_pass_confirm" className="form-input" placeholder="Confirm New Password"/>
                                             <label className="input-label" htmlFor="new_pass_confirm">Confirm New Password</label>
                                         </div>
                                     </div> */}

                                 </div>
                                 <div className="col w-full">
                                    <button onClick={this.updatePassword} className="btn btn-primary form-btn w-full">
                                        {this.state.passwordLoading ? <CircularIndeterminateButton  size={45}  thickness={5.5}/> :this.state.updatePasswordText}</button>
                                 </div>
                             </div>

                             {/* {# Section #} */}
                             {(user.role === "admin" || user.role === "artist") &&
                                <div className="form-section">
                                    <h3 className="text-label-lg mb-30">Your Songwriter P.R.O. Information</h3>

                                    <div className="grid-row-sm">

                                        <div className="col w-full md:w-1/2">
                                            <div className="input-group input-inline">
                                                <label className="input-label" htmlFor="artist_pro_org">P.R.O. Organization</label>
                                                <Select
                                                    name="artistProOrg"
                                                    styles={customStyles}
                                                    className="select-container"
                                                    classNamePrefix="select"
                                                    placeholder="P.R.O. Organization"
                                                    options={proOrgs}
                                                    id="artist_pro_org"
                                                    onChange={this.setArtistPro}
                                                    defaultValue={{ label: this.props.details.selfPro?.org, value: this.props.details.selfPro?.org }}
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className="col w-full md:w-1/2">
                                            <div className="input-group input-inline">
                                                <input defaultValue={this.props.details.selfPro?.number} onChange={this.handleInputChange} value={this.state.proNumber} name="proNumber" type="text" id="pro_ipi_cae" className="form-input" placeholder="IPI / CAE #"/>
                                                <label className="input-label" htmlFor="pro_ipi_cae">IPI / CAE #</label>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col w-full">
                                        <button onClick={this.addSelfPro} className="btn btn-primary form-btn w-full">
                                        {this.state.proLoading ? <CircularIndeterminateButton  size={45}  thickness={5.5}/> :"Update Songwriter P.R.O. Information"}</button>
                                    </div>
                                </div>
                            }

                            {/* {# Section #} */}
                            {(user.role ==="admin" || user.role ==="artist") &&
                             <div className="form-section">
                                 <h3 className="text-label-lg mb-30">Publisher Information</h3>

                                 <div className="grid-row-sm">

                                     <div className="col w-full">
                                         <div className="input-group input-inline">
                                             <input value={this.state.publisherName} defaultValue={this.props.details.publisherPro?.publisherName} onChange={this.handleInputChange} name="publisherName" type="text" id="pub_name" className="form-input" placeholder="Publisher Name"/>
                                             <label className="input-label" htmlFor="pro_org">Publisher Name</label>
                                         </div>
                                     </div>

                                     <div className="col w-full md:w-1/2">
                                         <div className="input-group input-inline">
                                             <label className="input-label" htmlFor="pub_pro_org">P.R.O. Organization</label>
                                             <Select
                                                    name="pubProOrg"
                                                    styles={customStyles}
                                                    className="select-container"
                                                    classNamePrefix="select"
                                                    placeholder="P.R.O. Organization"
                                                    options={proOrgs}
                                                    id="pub_pro_org"
                                                    onChange={this.setPublisherPro}
                                                    defaultValue={this.props.details?.publisherPro?.id ? { label: this.props.details.publisherPro?.org, value: this.props.details.publisherPro?.org } : null}
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                />
                                         </div>
                                     </div>

                                     <div className="col w-full md:w-1/2">
                                         <div className="input-group input-inline">
                                             <input defaultValue={this.props.details?.publisherPro?.number} onChange={this.handleInputChange} value={this.state.publisherProNumber} name={"publisherProNumber"}type="text" id="pub_ipi_cae" className="form-input" placeholder="IPI / CAE #"/>
                                             <label className="input-label" htmlFor="pub_ipi_cae">IPI / CAE #</label>
                                         </div>
                                     </div>

                                 </div>
                                 <div className="col w-full">
                                     <button onClick={this.addPublisherPro} className="btn btn-primary form-btn w-full">
                                        {this.state.publisherProLoading ? <CircularIndeterminateButton  size={45}  thickness={5.5}/> : this.state.publisherProText}
                                     </button>
                                 </div>
                             </div>
                            }
                         </div>
                         {/* {# End Form Container #} */}
                         </>
                         )}
            </UserConsumer>
        )
    }

}
export default Settings

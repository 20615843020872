import moment from 'moment';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import usersAPI from '../../../api/users';
import { UserContext } from '../../../contexts/User';
import { handleAPIError } from "../../../utils/handleAPIError";

const SubscriptionLearnMore = (props) => {

  const history = useHistory();

  const {
    children,
    handleClose,
    subscriptionPriceAmount,
    subscriptionType,
    trackId,
    trackPriceId,
    trackPriceAmount,
  } = props;

  const { user } = useContext(UserContext);

  const clickedAt = moment().format();

  useEffect(() => {
    handleImpression();
  }, []);


  const handleImpression = () => {
    // console.log('handleImpression user', user);
    if (user?.user_id) {
      const userData = {
        subscriptionPriceAmount,
        subscriptionType,
        trackId,
        trackPriceId,
        trackPriceAmount
      }

      usersAPI
        .saveSubscriptionLearnMore(userData)
        .then(res => {
          if (res?.data?.success) {
            // console.log('saveSubscriptionLearnMore Impression success!');
            // history.push('/pricing');
          }
          else {

          }
        })
        .catch(error => {
          handleAPIError(error);
        })
    }
  }

  const handleClick = (event) => {
    event.preventDefault();

    if (user?.user_id) {
      const userData = {
        clickedAt,
        subscriptionPriceAmount,
        subscriptionType,
        trackId,
        trackPriceId,
        trackPriceAmount
      }

      usersAPI
        .saveSubscriptionLearnMore(userData)
        .then(res => {
          if (res?.data?.success) {
            console.log('saveSubscriptionLearnMore Click success!');
            history.push('/pricing');
            handleClose();
          }
          else {

          }
        })
        .catch(error => {
          handleAPIError(error);
        })
    }
    else {
      history.push('/pricing');
      handleClose();
    }
  }

  return (
    <a
      className="btn btn-stroke btn-sm w-full capitalize mt-15 mb-30"
      id="subscription-learn-more"
      onClick={handleClick}
      href="#"
    >
      {children}
    </a>
  )
}

export default SubscriptionLearnMore

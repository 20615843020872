import React, { Component } from "react";
class VSTooltip extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {

            }

    render() {
        return (

                <div style={{zIndex:"10001"}} className="dropdown-menu">
                    <div className="dropdown-group">

                            <a href="#" onClick={()=>{this.props.unloadVideo();this.props.toggleVSTippy()}}>Load New Video</a>
                    </div>                
                 
                </div>

        )
    }

}
export default VSTooltip
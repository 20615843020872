import React from "react"

export default function VolumeMute(props){
    return(
        <div className="flex items-center text-sm py-10 lg:py-15">
        <div className="w-100 flex-none">
            {props.label}
        </div>
        <button
            className="text-link-gray text-xs font-bold px-3 active"
            type="button"
            onClick={props.toggleMute}
        >
            <span className={`${props.isMuted ? 'text-primary' :'text-gray-400'}`}>M</span>
        </button>
    </div>
     
    )
}
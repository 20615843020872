import buildBaseURL from "./buildBaseURL";

const buildArtistURL = (artistURL) => {
  const baseURL = buildBaseURL();

  let artistsPathPrefix = 'artists';

  const fullURL = baseURL + '/' + artistsPathPrefix + '/' + artistURL;

  return fullURL;
}
export default buildArtistURL;

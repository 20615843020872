import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { UserConsumer } from "../../contexts/User";
class SideNavCustomerSub extends Component {
  state = {};

  render() {
    return (
      <UserConsumer>
        {({ user, login, logout }) => (
          <div className="subnav">
            <NavLink
              to="/dashboard/projects/all"
              className={`subnav-link ${
                window.location.pathname.indexOf("projects") !== -1 &&
                window.location.pathname.indexOf("dashboard/projects") !== -1 &&
                window.location.pathname.indexOf("dashboard/projects/edit") === -1 &&
                window.location.pathname.indexOf("/projects/public") === -1
                  ? "active"
                  : ""
              }`}
            >
              <span className="icon-projects"></span> Projects
            </NavLink>
            <NavLink
              to="/dashboard/favorites/artists"
              className={`subnav-link ${
                window.location.pathname.indexOf("favorites") !== -1 ? "active" : ""
              }`}
            >
              <span className="icon-like"></span> Favorites
            </NavLink>
            <NavLink
              to="/dashboard/licenses/"
              className={`subnav-link ${
                window.location.pathname.indexOf("licenses") !== -1 ? "active" : ""
              }`}
            >
              <span className="icon-download"></span> Licenses
            </NavLink>
            <NavLink
              to="/dashboard/account/details"
              className={`subnav-link ${
                window.location.pathname.indexOf("account") !== -1 ? "active" : ""
              }`}
            >
              <span className="icon-settings"></span> Account
            </NavLink>
            {!user?.artist && !user?.admin && (
              <NavLink
                to="/pricing"
                className={`subnav-link ${
                  window.location.pathname.indexOf("/pricing") !== -1 ? "active" : ""
                }`}
              >
                <span className="icon-subscriptions"></span> Pricing
              </NavLink>
            )}
            <a
              className="subnav-link"
              href="/help"
              className={`subnav-link ${
                window.location.pathname.indexOf("/help") !== -1 ? "active" : ""
              }`}
            >
              <span className="icon-help"></span> Help
            </a>
            <NavLink to="/logout" onClick={logout} className="subnav-link">
              <span className="icon-log-out"></span> Log Out
            </NavLink>
          </div>
        )}
      </UserConsumer>
    );
  }
}
export default SideNavCustomerSub;

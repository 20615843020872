import React, { Component } from "react"
import MicroModal from "react-micro-modal"
import { NavLink } from "react-router-dom"
// import CollectionsCardTooltip from "./CollectionsCardTooltip"
import { Tooltip } from "react-tippy"
import dsLogo from "../../assets/photos/dsLogo.png"
import { FavoritesConsumer } from "../../FavoritesContext"
import { PlayerContext } from "../../PlayerContext"
import EditCollectionModal from "./editCollectionModal"
import PublicCollectionTooltip from "./publicCollectionTooltip"

class PublicCard extends Component {
    state={
        showCollectionTippy: false,
        isEditCollectionModalOpen: false,
    }
    toggleCollectionTippy = ()=>{

        this.setState( prevState=>({showCollectionTippy: !prevState.showCollectionTippy}))
    }
    handleEditCollectionModalOpen = ()=>{
        this.setState({isEditCollectionModalOpen: true})
    }
    handleEditCollectionModalClose = ()=>{
        this.setState({isEditCollectionModalOpen: false})
    }
    resolveCardPhoto=(cardPhotoKey)=>{
        const key = cardPhotoKey
        if(cardPhotoKey){
            const ext = key.split('.').reverse()[0];
            let sizeMod;
            let width = window.innerWidth
            if(width >= 1280){
                sizeMod = '500x500'
            }
            else if(width >= 410 && width < 1280){
                sizeMod = '325x325'
            }
            else{
                sizeMod = '500x500'
            }
            let final = key.replace('.' + ext, '') + '-minified-' + sizeMod + '.' + ext
            return final
        }
    }
    render() {
        return (
            <FavoritesConsumer>
            {({artists, tracks, albums, collections,followCollection, unfollowCollection})=>(
            <div className="artwork-card">
                {(((this.context.mediaPlayer.idKey?.indexOf('collectionListCard-' + this.props.id) || -1) !== -1) && (this.context.mediaPlayer.isTrackPlaying || this.context.isVideoPlaying)) ? (
                        <div
                            className="circle-icon-fill bg-white text-black circle-icon-xl pause hover-grow"
                            onClick={()=>this.context.handlePause()}
                        >
                            <span className="icon-pause"></span>
                        </div>
                ) : (
                    (this.context.mediaPlayer.idKey?.indexOf('collectionListCard-' + this.props.id) || -1) !== -1 ? (
                        <div
                            className="circle-icon-fill bg-white text-black circle-icon-xl play hover-grow"
                            onClick={()=>this.context.handlePlay()}
                        >
                            <span className="icon-play"></span>
                        </div>

                    ) : (
                        <div
                            className="circle-icon-fill bg-white text-black circle-icon-xl playTracks hover-grow"
                            onClick={()=>this.props.playTracks(this.props.tracks, this.props.id, this.props.idKey)}
                        >
                            <span  className="icon-play"></span>
                        </div>
                    )
                )}
                {/* {# Card Image #} */}
                <div className="artwork-card-image">
                  <img
                    src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ? process.env.REACT_APP_S3_SUB_BUCKET + "/" : "")}${this.resolveCardPhoto(this.props.cardArt)}`}
                  />
                    <div className="artwork-card-hover">

                    </div>
                </div>
                {/* {# Card Content #} */}
                <div className="artwork-card-content">
                    {/* {# Details #} */}
                    <div className="artwork-card-title"><NavLink to={`/collections/${this.props.collectionURL}`}>{this.props.name}</NavLink></div>
                    <div className="artwork-card-subtext">{this.props.tracks.length} Tracks</div>
                    <div className="img-summary-item mt-10 rounded-full js-tooltip" >
                        <img title={"by Deep Sounds"} src={dsLogo}/>
                    </div>
                    <div className="artwork-card-controls">

                        {/* {# More Dots #} */}
                        <MicroModal
                                open={this.state.isEditCollectionModalOpen}
                                handleClose={this.handleEditCollectionModalClose}
                                closeOnOverlayClick={true}
                                closeOnAnimationEnd={true}
                                modalOverlayClassName="modal-overlay"
                                modalClassName="modal micromodal-slide is-open"
                                modalOverlayStyles={null}
                                children={handleClose=><EditCollectionModal handleClose={()=>{handleClose()}} {...this.props}  />}
                                containerStyles={{background: "#131313", padding:"asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box"}}
                        />
                        <Tooltip
                        className="circle-icon"
                            arrow={false}
                            open={this.state.showCollectionTippy}
                            interactive={true}
                            useContext={true}
                            position={'bottom-end'}
                            popperOptions={{
                                modifiers: {
                                    preventOverflow: { enabled: false },
                                    flip: { enabled: false },
                                    hide: { enabled: false },
                                    addZIndex: {
                                        enabled: true,
                                        order: 810,
                                        fn: data => ({
                                            ...data,
                                            styles: {
                                            ...data.styles,
                                            zIndex: 10,
                                            },
                                        })
                                    }
                                 }
                            }}
                            html={(<PublicCollectionTooltip
                                collectionId={this.props.collectionId}
                                collectionURL={this.props.collectionURL}
                                name={this.props.name}
                                toggleCollectionTippy={this.toggleCollectionTippy}
                                includeLink={true}
                                allowFollow={true}
                                following={collections?.filter(fav=>fav.collectionId === this.props.id).length === 1}
                                followCollection={followCollection}
                                unfollowCollection={unfollowCollection}
                            />)}
                            allowHTML={true}
                            onUntrigger={()=>{this.toggleCollectionTippy()}}
                            onRequestClose={()=>{this.setState({showCollectionTippy: false})}}
                        >
                            <button
                                onClick={this.toggleCollectionTippy}
                                className="circle-icon text-gray-500"
                            >
                                <span className="icon-dots-vert"></span>
                            </button>
                        </Tooltip>
                    </div>
                </div>
            </div>)}
            </FavoritesConsumer>
        )
    }
}
PublicCard.contextType = PlayerContext
export default PublicCard

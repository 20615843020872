import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import CircularIndeterminate from '../Loading/circularIndeterminant';
import CircularIndeterminateButton from '../Loading/circularIndeterminantButton';
import { Error, Success } from "../Toast/index";
import api from '../utils/api';

export default function Deposits(props) {

    const { handleSubmit } = useForm();

    const [firstLoad, setFirstLoad] = useState(props.loaded)

    const [isLoading, setisLoading] = useState(false);
    const [isDisabled, setisDisabled] = useState(false);
    const [message, setMessage] = useState('');
    const [accountComplete, setAccountComplete] = useState(false);

    useEffect(() => {
        let isMounted = true; // this flag denotes mount status

        setFirstLoad(false)

        const fetchData = async () => {
            
            api.stripeOnboardComplete(props.params.code)
            .then(res=>{
                
                if (res.data.status === 'complete') {
                    let userData = {};
                    userData.signupStatus = 100;
                    api.updateSignupStatus(userData)
                        .then(signupStatusRes => {
                            
                            if (signupStatusRes?.data?.signupStatus === 100) {
                                Success({message: "Account setup complete!"})
                            }
                        })
                        .catch(error=>{
                            Error({message: "Error updating your account"})
                        })
                    setAccountComplete(true);
                }
                setFirstLoad(true)
            })

            return () => { isMounted = false };
        }
        fetchData();
    
    }, []);

    const onSubmit = (data, e) => {
    	setisLoading(true);
    	setisDisabled(true);
    	

        api.stripeOnboardStart()
        .then(res=>{
            
            if (res.data?.url) {
                setTimeout(()=>{window.location.replace(res.data.url)}, 100);
            }
            else if (res.data?.status) {
                setisLoading(false);
                setAccountComplete(true);
                setMessage(res.data?.status);
            }
            else {
                setMessage("Could not create Stripe Onboarding Link.");
            }
            
        })


    };


    
    return (
        !firstLoad ?
            <CircularIndeterminate forceCenter />
        :
            <>
                <div className="mb-50">
                    <p className="mb-0">
                        {!accountComplete ?
                            'We use Stripe Connect as our payments partner. This allows us to deposit money directly to your bank account in a fast and secure way. By clicking the link below you will be guided through the process of linking your bank account and automatically redirected back here when finished.'
                        :
                            'Your account setup is complete!'
                        }
                    </p>
                </div>
                <form onSubmit={handleSubmit(onSubmit)} className="ds-form">
                    <div className="prompt">
                        <div className="prompt-content">
                            {!accountComplete ?
                                <>
                                    <div className="text-icon-xl mb-15">
                                        <span className="icon-add-stripe-account-lg"></span>
                                    </div>
                                    <h3 className="prompt-title">Link your Stripe account</h3>
                                    <button 
                                        className="btn btn-primary w-full"
                                        type="submit"
                                        disabled={isLoading}
                                    >
                                        {isLoading ?
                                            <CircularIndeterminateButton forceCenter />
                                            :
                                            (props.buttonText ?
                                                props.buttonText
                                            :
                                                'Setup Payment Account'         
                                            )
                                        }
                                        
                                    </button>
                                </>
                            :
                                <>
                                    <div className="text-icon-xl mb-15">
                                        <span className="icon-add-track-lg"></span>
                                    </div>
                                    <h3 className="prompt-title">You're all setup. Get started with some tracks!</h3>
                                    <a 
                                        href="/dashboard/tracks/add-tracks" 
                                        className="btn btn-primary"
                                    >
                                        Finish
                                    </a>
                                </>
                            }
                        </div>
                    </div>
                </form>
            </>
    )

}
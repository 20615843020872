import React from "react";
import a from "indefinite";
import { Helmet } from "react-helmet";
import moment from "moment";
import buildAlbumArtURL from "../../Albums/AlbumArt";
import buildTrackURL from "../../../utils/buildTrackURL";
import formatListArray from "../../../utils/formatListArray";
export default function TrackDetailHelmet({
  currentTrack,
  genres,
  emotion,
  moods,
  speed,
  attributes,
  instruments,
}) {
  const getThumbnail = (photoKey) => {
    const key = photoKey;
    if (photoKey) {
      const ext = key.split(".").reverse()[0];
      let final = key.replace("." + ext, "") + "-minified-325x325" + "." + ext;
      return final;
    }
  };

  const { imageURL, trackTitle, trackURL, artistName, duration, albumTitle } =
    currentTrack;

  const thumbnailURL = getThumbnail(imageURL);
  const albumArtURL = buildAlbumArtURL(thumbnailURL);
  const pageURL = buildTrackURL(trackURL);
  const genresDisplay = genres.map((genre) => genre.name).join(", ");
  const emotionDisplay = emotion
    .map((emotion) => emotion.name)
    .join("/")
  const moodsDisplay = formatListArray(
    moods.map((mood) => mood.name)
  )
  const speedDisplay = speed
    .map((speed) => speed.name)
    .join("/")
  const attributesDisplay = formatListArray(
    attributes.map((attribute) => attribute.name)
  )
  const instrumentsDisplay = formatListArray(
    instruments.map((instrument) => instrument.name)
  )

  const description = `${trackTitle} is ${a(
    genresDisplay
  )} song by ${artistName}. It sounds ${emotionDisplay} while also being ${moodsDisplay} with a ${speedDisplay} tempo. Attributes to describe it are ${attributesDisplay}, and it features the following instruments: ${instrumentsDisplay}. License it on Deep Sounds.`;

  const durationElements = duration.split(":");
  const durationElementsReversed = durationElements.reverse();
  const durationSeconds = durationElementsReversed[0] || "00";
  const durationMinutes = durationElementsReversed[1] || "00";
  const durationHours = durationElementsReversed[2] || "00";
  const fullDuration =
    durationHours + ":" + durationMinutes + ":" + durationSeconds;
  const durationAsSeconds = moment.duration(fullDuration).asSeconds();

  return (
    <Helmet>
      <title>{`${trackTitle} - Deep Sounds | Music for Creators`}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={`${trackTitle} - Deep Sounds | Music for Creators`} />
      <meta property="og:image" content={albumArtURL} />
      <meta property="og:type" content="music.song" />
      <meta property="og:music:duration" content={durationAsSeconds} />
      <meta property="og:music:album" content={albumTitle} />
      <meta property="og:music:track" content={trackTitle} />
      <meta property="og:music:musician" content={artistName} />
      <meta property="og:url" content={pageURL} />
      <meta property="og:description" content={description} />
      <link rel="canonical" href={pageURL} />
    </Helmet>
  );
}

import React from "react";
export default function ArtistTerms(props){
    let linkText = props.children || 'Artist Terms'
    return(
        <a 
            data-preventOpen={true} 
            className={props.red ? "text-link": ""} 
            target="_blank" 
            href={"/terms/artist"}
        >
            {props.underline ? 
                <span style={{textDecoration: "underline"}}>{linkText}</span>
            :  
                <span >{linkText}</span>
            }
        </a>
    )
}

import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { UserContext } from "../../contexts/User";
import DetailsForm from "../Account/accountDetailsForm";
import { countries } from "../FormElements/countries";
import { states } from "../FormElements/states";
import api from '../utils/api';
import Bio from "./bio";
import CoverPhoto from "./coverPhoto";
import Deposits from "./deposits";
import Pro from "./pro";
import ProfilePhoto from "./profilePhoto";
import signupSteps from "./signupSteps";

function SignUp() {
    // grab userContext data
    const currentUser = useContext(UserContext);

    // grab URL params
    const params = useParams();

    // set currentStep based on url params path
    const currentStep = signupSteps.filter(step => step.path === params.step)[0]

    // set up userData
    const [userData, setUserData] = useState({});
    const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);

    // we're loading until we're not
    const [isLoading, setisLoading] = useState(true);

    useEffect(() => {
        let isMounted = true; // this flag denotes mount status

        let invite;

        const fetchData = async () => {

            // fetch current user data
            api.getMyDetails()
            .then(res=>{
                if (isMounted) {
                    api.getInviteByUser(res.data.id)
                    .then(inviteRes => {

                        invite = inviteRes.data.invite
                    })
                    api.getTags()
                    .then(genreRes => {
                        //
                        //filter genre tags
                        let genres = genreRes.data.filter(tag => tag.type === 1)
                        let address = JSON.parse(res.data.billingAddress) || {line1: "", line2: ""}
                        //

                        let parsedCountryOption = {}
                        if (address.country) {
                            let countryOption = countries.filter(country=>country.name.toLowerCase() === address.country.toLowerCase())[0]
                            parsedCountryOption = {
                                label: countryOption?.name,
                                id: countryOption?.id,
                                value: countryOption?.name,
                                sortName: countryOption?.sortname
                            }
                        }

                        let parsedStateOption = {}
                        if (address.state) {
                            let stateOption = states.filter(state=>state.name.toLowerCase() === address.state.toLowerCase())[0]
                            parsedStateOption = {
                                label: stateOption?.name,
                                id: stateOption?.id,
                                value: stateOption?.name,
                                sortName: stateOption?.sortname
                            }
                        }

                        let updatedStep;
                        // make sure we're on the right step before finishing loading
                        // if (res.data?.signupStatus < currentStep?.step) {
                        //     updatedStep = signupSteps.filter(step => step.step === res.data.signupStatus)[0]
                        //     if (updatedStep?.length) {
                        //         return;
                        //     }
                        // }

                        // redirect if we're trying to access a step the user hasn't gotten to
                        try{

                            if (res.data?.signupStatus === 100 && currentStep?.step < 6) {
                                window.location.replace('/dashboard/tracks/add-tracks')
                                return;
                            }
                            if (res.data?.signupStatus < currentStep?.step) {
                                updatedStep = signupSteps.filter(step => step.step === res.data.signupStatus)[0]
                                if (updatedStep?.path) {
                                    window.location.replace(updatedStep.path)
                                }

                                return;
                            }
                        }
                        catch(error){

                        }

                        const primaryGenre = genres.filter(genre=>genre.id === res.data.primaryGenre)[0]
                        if (primaryGenre?.length) {
                            primaryGenre.value = primaryGenre.id
                        }

                        const secondaryGenre = genres.filter(genre=>genre.id === res.data.secondaryGenre)[0]
                        if (secondaryGenre?.length) {
                            secondaryGenre.value = secondaryGenre.id
                        }

                        setUserData({
                            loaded: true,
                            invite: invite,
                            email: res.data.email,
                            artistName: res.data.artistName,
                            firstName: res.data.firstName,
                            lastName: res.data.lastName,
                            phone: res.data.phone,
                            secondaryPhone: res.data.secondaryPhone,
                            company: res.data.company,
                            position: res.data.position,
                            line1: address.line1,
                            line2: address.line2,
                            city: address.city,
                            state: address.state,
                            stateOption: parsedStateOption,
                            postal_code: address.postal_code,
                            country: address.country,
                            countryOption: parsedCountryOption || [],
                            bestDescribed: res.data.bestDescribed,
                            primaryGenre: primaryGenre || [],
                            secondaryGenre: secondaryGenre || [],
                            coverPhoto: res.data.coverPhoto,
                            bio: res.data.bio,
                            genreOptions: genres,
                            selfPro: res.data.selfPro,
                            publisherPro: res.data.publisherPro || {id: null, publisherName: "", org: null, number: null},
                            subscription: res.data.subscription || {amount: null, status: null, name: null, interval: null, benefit: null, currentPeriodEnd: null},
                            signupStatus: res.data.signupStatus
                        })

                        //

                    })
                }

            })
            .then(res => {
                // now we've loaded all the data
                setisLoading(false);
            })
            .catch(error=>{

            });


            return () => { isMounted = false };
        }
        fetchData();
    }, [params.step]);




        return (
            <div className="container pt-40 md:pt-40 z-10">
                <Helmet title={`${currentStep?.title} - Deep Sounds | Music for Creators`} />

                <h1 className="mb-20">Artist Sign Up</h1>
                <p className="mb-60">Welcome to Deep Sounds. Please finish setting up your artist account.</p>

                {/* {
                <nav className="tabs mb-30">
                    <ul className="tab-items">
                        { signupSteps.filter(item => item.step < 7).map((item) =>
                            <li key={item.step} className={`tab-item ${params.step === item.path ? 'active' : ''}`}>
                                {
                                    item.step > userData?.signupStatus ?
                                        <span className="tab-item-title">{item.step}. {item.title}</span>
                                    :
                                        <NavLink to={`/signup/${item.path}`} className='tab-item-title' onClick={setisLoading}>
                                            {item.step}. {item.title}
                                        </NavLink>
                                }
                            </li>
                        )}

                    </ul>
                </nav>

                {/* {

                <Prompt
                    when={shouldBlockNavigation}
                    message={
                        (location)=> window.location.pathname === location.pathname ?
                            true
                        :
                            'You have unsaved changes. Are you sure you want to leave?'
                    }
                />

                {/* {
                {isLoading ?
                    <CircularIndeterminate forceCenter />
                : */}
                    <>
                        {/* {# Form Container #} */}
                        <div className="w-full xl:w-2/3 mb-50 lg:mb-80">
                            {params.step === 'details' &&
                                <DetailsForm details={userData} currentUser={currentUser} nextStep={currentStep?.nextStep}/>
                            }
                            {params.step === 'profile' &&
                                <ProfilePhoto details={userData} currentUser={currentUser} nextStep={currentStep?.nextStep} loaded={userData.loaded} />
                            }
                            {params.step === 'cover' &&
                                <CoverPhoto details={userData} currentUser={currentUser} nextStep={currentStep?.nextStep} />
                            }
                            {params.step === 'bio' &&
                                <Bio bio={userData.bio} currentUser={currentUser} nextStep={currentStep?.nextStep} loaded={userData.loaded} />
                            }
                            {params.step === 'pro' &&
                                <Pro pro={userData.selfPro} currentUser={currentUser} nextStep={currentStep?.nextStep} loaded={userData.loaded} />
                            }
                            {params.step === 'deposits' &&
                                <Deposits params={params} currentUser={currentUser} nextStep={currentStep?.nextStep} loaded={userData.loaded} />
                            }
                            {params.step === 'complete' &&
                                <Deposits params={params} currentUser={currentUser} nextStep={currentStep?.nextStep} loaded={userData.loaded} />
                            }

                        </div>
                    </>
                {/* } */}

            </div>
        )

}
export default SignUp;

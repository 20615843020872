import React from 'react'

const FormatGroupLabel = ({ message }) => (
  <div className="flex items-center justify-between">
    <div className="text-xs text-gray-400">{message}</div>
    <div className="flex items-center flex-none text-icon-md text-gray-400">
      <span className="icon-checklist"></span>
    </div>
  </div>
);

export default FormatGroupLabel;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import blogAPI from "../../api/blog";
import { handleAPIError } from "../../utils/handleAPIError";

export default function Blog() {
  const [blogBody, setBlogBody] = useState(null);

  useEffect(() => {
    blogAPI
      .getIndex()
      .then((res) => {
        const { bodyHTML } = res.data || {};
        setBlogBody(bodyHTML);
      })
      .catch((error) => {
        handleAPIError(error);
      });
  }, []);

  const description =
    "Navigate music licensing professionally. Gain insights, trends, and tips for your projects. Elevate creativity with our concise blog. #MusicLicensing #ProfessionalInsights";

  return (
    <>
      <Helmet>
        <title>Deep Sounds Blog | Music for Creators</title>
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content="Deep Sounds Blog" />
        <meta property="og:title" content="Deep Sounds Blog | Music for Creators"></meta>
        <meta property="og:type" content="website" />
      </Helmet>
      <section className="container max-w-lg md:max-w-screen-3xl mb-0">
        {ReactHtmlParser(blogBody)}
      </section>
    </>
  );
}

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { PlayerContext } from "../../PlayerContext";
import numberAbbreviator from "../NumberFormatters/numberAbbreviator";

class TrackListStatItem extends Component {
  state = {};
  resolveAlbumPhoto = (albumPhotoKey) => {
    const key = albumPhotoKey;
    if (albumPhotoKey) {
      const ext = key.split(".").reverse()[0];
      let sizeMod;
      let width = window.innerWidth;
      if (width >= 1280) {
        sizeMod = "140x140";
      } else if (width >= 1024 && width < 1280) {
        sizeMod = "140x140";
      } else if (width >= 760 && width < 1024) {
        sizeMod = "140x140";
      } else {
        sizeMod = "140x140";
      }
      let final = key.replace("." + ext, "") + "-minified-" + sizeMod + "." + ext;
      return final;
    }
  };
  play = () => {
    if (this.context.mediaPlayer.idKey !== this.props.idKey) {
      let track = { trackId: this.props.trackId, idKey: this.props.idKey };
      this.context.loadTrackInMediaPlayer(this.props.trackId, this.props.idKey);
      this.context.setAutoPlay(false);
      if (this.props.otherTracks?.length > 0) {
        this.context.setPlaylist(this.props.otherTracks);
      }
    } else {
      this.context.playPause();
    }
  };
  render() {
    return (
      <div
        className={`track-list-card${this.state.isDropdown ? " dropdown-open" : ""}${
          this.context.mediaPlayer.idKey === this.props.idKey && this.context.playing
            ? " is-playing"
            : ""
        }`}
      >
        {/* {# First Col #} */}
        <div class="track-first-col">
          <div onClick={this.play} class="track-image">
            <img
              src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${
                process.env.REACT_APP_S3_SUB_BUCKET ? process.env.REACT_APP_S3_SUB_BUCKET + "/" : ""
              }${this.resolveAlbumPhoto(this.props.imageURL)}`}
            />
          </div>
          <div class="track-title-artist">
            <NavLink to={`/tracks/${this.props.trackURL}`}>
              <span class="track-title">{this.props.trackTitle}</span>
            </NavLink>
            <span class="track-artist">
              ${(this.props.totalSales / 100).toLocaleString("en")} Earned
            </span>
          </div>
        </div>
        {/* {# Stats #} */}
        <div class="track-stats">
          <div class="track-stat-item js-tooltip" title="Plays">
            <span class="icon-play"></span> {numberAbbreviator(this.props.plays)}
          </div>
          <div class="track-stat-item js-tooltip" title="Downloads">
            <span class="icon-download"></span> {numberAbbreviator(this.props.downloads)}
          </div>
          <div class="track-stat-item js-tooltip" title="Favorites">
            <span class="icon-like"></span> {numberAbbreviator(this.props.likes)}
          </div>
          <div class="track-stat-item js-tooltip" title="Projects">
            <span class="icon-projects"></span> {numberAbbreviator(this.props.projects)}
          </div>
          <div class="track-stat-item js-tooltip" title="Licenses">
            <span class="icon-download"></span> {numberAbbreviator(this.props.purchases)}
          </div>
        </div>
        {/* {# Last Col #} */}
        <div class="track-last-col">
          <div class="track-list-icons text-gray-400">
            <button class="track-list-more">
              <span class="icon-dots-vert"></span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}
TrackListStatItem.contextType = PlayerContext;

export default TrackListStatItem;

import React, { Component } from "react";
import 'react-tippy/dist/tippy.css';
import { PlayerContext } from "../../PlayerContext";
import ApprovedListItem from "./approvedListItem";
class ApprovedTracks extends Component {
    state = {
        myAlbums: [],
        trackTitle: "",
        trackList: [],
        mediaUrl: "",
        trackId: null,
        sourceToGet: "preview",
        peaks: "[]"
    }
    formatNumber = (num)=>{
        if (num === undefined) num =0
        switch(num.toString().length || 0){
            case 0: 
            case 1:
            case 2: 
            case 3: return num
            case 4: return num.toString()[0] + "." + num.toString()[1] + "k"
            case 5: return num.toString().substr(0,2) + "k"
            case 6: return num.toString().substr(0,3) + "k"
            default: return num
        }
    }
    toggleTrackTippy = ()=>{
        this.setState({showTrackTippy: !this.state.showTrackTippy})
    }

    render() {
        return (
            // {/* {# Tracks List #} */}
                this.props.tracks?.length > 0 ?
                this.props.tracks.map( (track) => (
                        <ApprovedListItem {...track} removeTrackCB={this.props.removeTrackCB}/>
                ))
                :
                <p style={{textAlign: "center"}} className="mt-25">Sorry, your search resulted in no matches.</p>
        )
      }
        
}

ApprovedTracks.defaultProps ={
  tracks: []
}
ApprovedTracks.contextType = PlayerContext
export default ApprovedTracks
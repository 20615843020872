import React, { useEffect, useState } from "react";
import DashboardLayout from "../../Layouts/DashboardLayout";
import ContentIDSubnav from "../ContentIDSubnav";
import contentIDAPI from "../../../api/contentID";
import LoadingContainer from "../../Loading/LoadingContainer";
import RoyaltyPaymentsQuarters from "./RoyaltyPaymentsQuarters";
import { Error } from "../../Toast";

const RoyaltyPayments = () => {
  const [contentIDPayoutsQuarters, setContentIDPayoutsQuarters] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const getContentIDPayouts = () => {
    contentIDAPI
      .getPayouts()
      .then((res) => {
        console.log("getPayouts res", res);
        const { contentIDPayoutsQuarters } = res.data || {};
        setIsLoaded(true);
        setContentIDPayoutsQuarters(contentIDPayoutsQuarters);
      })
      .catch((error) => {
        console.log("getPayouts error", error);
        setIsLoaded(true);
        Error({ message: "There was an error loading the payouts." });
      });
  };

  useEffect(() => {
    setIsLoaded(false);
    getContentIDPayouts();
  }, []);

  return (
    <DashboardLayout title="Royalty Payments">
      <ContentIDSubnav />
      <LoadingContainer isLoaded={isLoaded}>
        <div className="bg-gray-100 p-20 pb-10 lg:p-25 lg:pb-15 mb-40">
          <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
            <div className="flex-1 flex items-center mb-15 md:mb-0">
              <h3 className="text-label-lg mr-10">Royalty Payments by Quarter</h3>
              <span className="flex-none text-xs">Showing {contentIDPayoutsQuarters?.length}</span>
            </div>
          </div>
          <div className="table-wrap ds-scrollbar">
            <table className="stats-table w-full mt-30">
              <thead className="text-left">
                <tr>
                  <th className="text-label-sm">Quarter</th>
                  <th className="text-label-sm">Total Artists</th>
                  <th className="text-label-sm">Total Payout</th>
                  <th className="text-label-sm">Payout Date</th>
                  <th className="text-label-sm">Status</th>
                  <th className="text-label-sm">Actions</th>
                </tr>
              </thead>
              <tbody>
                {contentIDPayoutsQuarters?.length > 0 ? (
                  <RoyaltyPaymentsQuarters contentIDPayoutsQuarters={contentIDPayoutsQuarters} />
                ) : null}
              </tbody>
            </table>
          </div>
        </div>
      </LoadingContainer>
    </DashboardLayout>
  );
};

export default RoyaltyPayments;

import React from "react"

export default function VolumeSlider(props){
    return(
        <div className="flex items-center text-sm py-10 lg:py-15">
            <div className="w-100 flex-none">
                {props.label}
            </div>
            <div className="flex-1 flex items-center mr-15">
                <input onChange={props.onChange} type="range" step={props.step || 1} min={props.min || 0} max={props.max || 100} value={props.value} className="input-range"/>
            </div>
            <div className="flex-none">{props.value > 1 ? (props.value/100).toFixed(2) : parseFloat(props.value).toFixed(2)}</div>
        </div>
    )
}

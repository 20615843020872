import React, { Component } from "react"
import CircularIndeterminant from "../Loading/circularIndeterminant"

class LoadingModal extends Component{

    render(){
        return(
            
            <React.Fragment>
             <header class="modal-header">
                <h2 id="collaborators_title" class="modal-title">
                        Please Wait
                </h2>
                
            </header>

            {/* {# Modal Content #} */}
            <div id="collaborators_content" class="modal-content">
            <div style={{margin: "25% auto", width: "50%"}}>
                        <CircularIndeterminant size={40} forceCenter={true}/>
                    </div>
            </div>
                <div class="modal-footer">

                
                </div>
</React.Fragment>
        )
    }
}
export default LoadingModal
import React, { Component } from "react"

class SearchBar extends Component{
    render (){
        return(
            <form action="" class="ds-form mb-20 md:mb-30">
            <div class="form-search" >
                <input onChange={this.props.updateSearchTerm} value={this.props.searchTerm} type="text" id="track-search" class="form-input" placeholder="Search by title or album..."/>
            </div>
        </form>
        )
    }
}
export default SearchBar
import React, { Component } from "react"
import MicroModal from "react-micro-modal"
import { NavLink } from "react-router-dom"
// import CollectionsCardTooltip from "./CollectionsCardTooltip"
import { Tooltip } from "react-tippy"
import AdminCollectionTooltip from "./adminCollectionTooltip"
import EditCollectionModal from "./editCollectionModal"
class CollectionsCard extends Component {
    state={
        showCollectionTippy: false,
        isEditCollectionModalOpen: false
    }
    toggleCollectionTippy = ()=>{
        this.setState({showCollectionTippy: !this.state.showCollectionTippy})
    }
    handleEditCollectionModalOpen = ()=>{
        this.setState({isEditCollectionModalOpen: true})
    }
    handleEditCollectionModalClose = ()=>{
        this.setState({isEditCollectionModalOpen: false})
    }
    resolveCardPhoto=(cardPhotoKey)=>{
        const key = cardPhotoKey
        if(cardPhotoKey){
            const ext = key.split('.').reverse()[0];
            let sizeMod;
            let width = window.innerWidth
            if(width >= 1280){
                sizeMod = '500x500'
            }
            else if(width >= 410 && width < 1280){
                sizeMod = '325x325'
            }
            else{
                sizeMod = '500x500'
            }
            let final = key.replace('.' + ext, '') + '-minified-' + sizeMod + '.' + ext
            return final
        }
    }    
    render() {
        return (
            <div class="artwork-card">
            {/* {# Card Image #} */}
            <div class="artwork-card-image">
                <img src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveCardPhoto(this.props.cardArt)}`}/>
                <div class="artwork-card-hover">
                    <div class="circle-icon-fill bg-white text-black circle-icon-lg">
                        {/* {this.context?.mediaPlayer.albumId === this.props.albumId && this.context.playing ? 
                            <span  onClick={()=>this.context.playPause()} class="icon-pause"></span>:                 
                            <span  onClick={()=>this.props.playAlbum()} class="icon-play"></span>} */}
                    </div>
                </div>
            </div>

            {/* {# Card Content #} */}
            <div class="artwork-card-content">

                {/* {# Details #} */}
                <div class="artwork-card-title"><NavLink to={`/dashboard/collections/edit/${this.props.id}`}>{this.props.name}</NavLink></div>
                        <div class="artwork-card-subtext">{this.props.tracks.length} Tracks {this.props.followerCount > 0 &&  this.props.followerCount + ` Follower${this.props.followerCount > 1 ? 's':''}`}</div>
                {this.props.forFeatured ?
                    <div className="artwork-card-controls">
                        <button class="circle-icon text-gray-500" onClick={()=>this.props.makeFeatured(this.props.id)}>
                            <span class="icon-plus"></span>
                        </button>
                        {/* {# More Dots #} */}
                        <MicroModal
                                open={this.state.isEditCollectionModalOpen}
                                handleClose={this.handleEditCollectionModalClose}
                                closeOnOverlayClick={true}
                                closeOnAnimationEnd={true}
                                modalOverlayClassName="modal-overlay"
                                modalClassName="modal micromodal-slide is-open"
                                modalOverlayStyles={null}
                                children={handleClose=><EditCollectionModal handleClose={()=>{handleClose()}} {...this.props}  />}
                                containerStyles={{background: "#131313", padding:"asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box"}}
                        />
                        <Tooltip
                        className="circle-icon"
                            arrow={false}
                            open={this.state.showCollectionTippy}
                            interactive={true}
                            useContext={true}
                            position={'bottom-end'}
                            html={(<AdminCollectionTooltip
                                collectionId={this.props.id}
                                handleEditCollectionModalOpen={this.handleEditCollectionModalOpen}
                                toggleCollectionTippy={this.toggleCollectionTippy}
                                publish={this.props.publish}
                                unpublish={this.props.unpublish} 
                            />)}
                            allowHTML={true}
                            onUntrigger={()=>{this.state.showCollectionTippy ? this.setState({showCollectionTippy: false}): this.setState({showCollectionTippy: true})}}
                            onRequestClose={()=>{this.state.showCollectionTippy ? this.setState({showCollectionTippy: false}): this.setState({showCollectionTippy: true})}}
                        >
                            <button 
                                onClick={this.toggleCollectionTippy} 
                                class="circle-icon text-gray-500"
                            >
                                <span class="icon-dots-vert"></span>
                            </button>
                        </Tooltip>
                    </div>:
                    <>
                        {/* {# More Dots #} */}
                        <MicroModal
                                open={this.state.isEditCollectionModalOpen}
                                handleClose={this.handleEditCollectionModalClose}
                                closeOnOverlayClick={true}
                                closeOnAnimationEnd={true}
                                modalOverlayClassName="modal-overlay"
                                modalClassName="modal micromodal-slide is-open"
                                modalOverlayStyles={null}
                                children={handleClose=><EditCollectionModal handleClose={()=>{handleClose()}} {...this.props}  />}
                                containerStyles={{background: "#131313", padding:"asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box"}}
                        />
                        <Tooltip
                        className="circle-icon"
                            arrow={false}
                            open={this.state.showCollectionTippy}
                            interactive={true}
                            useContext={true}
                            position={'bottom-end'}
                            popperOptions={{
                                modifiers: {
                                    preventOverflow: { enabled: false },
                                    flip: { enabled: false },
                                    hide: { enabled: false },
                                    addZIndex: {
                                        enabled: true,
                                        order: 810,
                                        fn: data => ({
                                            ...data,
                                            styles: {
                                            ...data.styles,
                                            zIndex: 10,
                                            },
                                        })
                                    }
                                 }
                            }}
                            html={(<AdminCollectionTooltip
                                collectionId={this.props.id}
                                handleEditCollectionModalOpen={this.handleEditCollectionModalOpen}
                                toggleCollectionTippy={this.toggleCollectionTippy} 
                                publish={this.props.publish}
                                unpublish={this.props.unpublish} 
                            />)}
                            allowHTML={true}
                            onUntrigger={()=>{this.state.showCollectionTippy ? this.setState({showCollectionTippy: false}): this.setState({showCollectionTippy: true})}}
                            onRequestClose={()=>{this.state.showCollectionTippy ? this.setState({showCollectionTippy: false}): this.setState({showCollectionTippy: true})}}
                        >
                            <button 
                                onClick={this.toggleCollectionTippy} 
                                class="circle-icon text-gray-500"
                            >
                                <span class="icon-dots-vert"></span>
                            </button>
                        </Tooltip>
                    </>
                }
            </div>

            </div>
        )
    }
}
// FavAlbumCard.contextType = PlayerContext
export default CollectionsCard
import { Error } from "../components/Toast";

export const handleAPIError = (error, showToast = true) => {
  console.log('API error', error);
  console.log('API error response', error.response);

  const defaultMessage = "There was an error";
  const { message } = error.response?.data || error || {};
  // new error handling 2022.02.15
  if (showToast) {
    Error({
      message: message || defaultMessage
    });
  }
}

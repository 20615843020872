import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Tooltip } from "react-tippy";
import contentIDAPI from "../../../api/contentID";
import currencyFormatter from "../../../utils/currencyFormatter";
import LoadingContainer from "../../Loading/LoadingContainer";
import SalesContentIDMonths from "./SalesContentIDMonths";
import paginate from "../../../utils/paginate";
import SalesPagination from "../../Pagination/salesPagination";

const SalesContentID = () => {
  const history = useHistory();
  const currentPage = Number(history.location.pathname.split("/").pop()) || 1;
  const perPage = 12;
  const setCurrentPage = (pageNumber) => {
    if (pageNumber !== currentPage) {
      history.push(`/dashboard/sales/content-id/${pageNumber}`);
    }
  };

  const [contentIDAssetReports, setContentIDAssetReports] = useState([]);
  const [contentIDAssetReportItems, setContentIDAssetReportItems] = useState([]);
  const [currentPageAssetReports, setCurrentPageAssetReports] = useState(null);
  const [totalPaid, setTotalPaid] = useState(null);
  const [totalPending, setTotalPending] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const getContentIDPayouts = () => {
    contentIDAPI
      .getContentIDArtistPayouts()
      .then((res) => {
        console.log("getContentIDArtistPayouts res", res);
        const { assetReports, assetReportItems, totalPaid, totalPending } = res.data || {
          assetReports: [],
          assetReportItems: [],
          totalPaid: 0,
          totalPending: 0,
        };
        setIsLoaded(true);
        setContentIDAssetReports(assetReports);
        setContentIDAssetReportItems(assetReportItems);
        const paginatedAssetReports = paginate(assetReports, perPage) || [];
        const paginatedAssetReportsPage = paginatedAssetReports[currentPage - 1];
        console.log("paginatedAssetReportsPage", paginatedAssetReportsPage);
        setCurrentPageAssetReports(paginatedAssetReportsPage);
        const totalPaidFormatted = currencyFormatter(totalPaid / 100);
        const totalPendingFormatted = currencyFormatter(totalPending / 100);
        setTotalPaid(totalPaidFormatted);
        setTotalPending(totalPendingFormatted);
      })
      .catch((error) => {
        console.log("getContentIDArtistPayouts error", error);
        setIsLoaded(true);
        Error({ message: "There was an error loading the payouts." });
      });
  };

  useEffect(() => {
    setIsLoaded(false);
    getContentIDPayouts();
  }, [currentPage]);

  return (
    <LoadingContainer isLoaded={isLoaded}>
      <div className="bg-gray-100 p-20 pb-10 lg:p-25 lg:pb-15 mb-40">
        <div class="flex flex-col md:flex-row items-stretch justify-between bg-gray-100 mb-40">
          <div className="flex-1 flex items-top mb-15 md:mb-0">
            <h3 className="text-label-lg mr-10">
              Content ID Royalties Summary
              <Tooltip
                useContext={true}
                animation="shift"
                arrow="true"
                position="top"
                html={
                  <>
                    <div className="price-tool-title mb-10">Content ID Royalties</div>
                    <div className="text-gray-400 price-tool-desc">
                      Royalties earned from YouTube videos featuring your Deep Sounds tracks
                      detected through Content ID.{" "}
                    </div>
                  </>
                }
              >
                &nbsp;<span className="icon-help"></span>
              </Tooltip>
            </h3>
            <span className="flex-none text-xs mt-5">Showing {contentIDAssetReports?.length}</span>
          </div>
          {/* {# Summary #} */}
          <div class="flex-none flex flex-col sm:flex-row">
            {/* {# Payout Summary #} */}
            <div class="flex flex-col flex-end pr-30 lg:pr-60 leading-snug mb-30 md:mb-0">
              <span class="text-label-sm mb-3 text-right">
                Pending Payouts
                <Tooltip
                  useContext={true}
                  animation="shift"
                  arrow="true"
                  position="top"
                  html={
                    <>
                      <div className="price-tool-title mb-10">Pending Payouts</div>
                      <div className="text-gray-400 price-tool-desc">
                        Funds will be disbursed once they exceed the $10 minimum threshold in the
                        upcoming payment quarter.{" "}
                      </div>
                    </>
                  }
                >
                  &nbsp;<span className="icon-help"></span>
                </Tooltip>
              </span>
              <span class="text-h3 text-white font-display leading-tight text-right">
                {totalPending}
              </span>
            </div>
            {/* {# Payout Summary #} */}
            <div class="flex flex-col flex-none pr-30 lg:pr-60 leading-snug mb-30 md:mb-0">
              <span class="text-label-sm mb-3 text-right">Total Paid</span>
              <span class="text-h3 text-white font-display leading-tight text-right">
                {totalPaid}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between"></div>
        <div className="table-wrap ds-scrollbar">
          <table className="stats-table w-full">
            <thead className="text-left">
              <tr>
                <th className="text-label-sm">Month Earned</th>
                <th className="text-label-sm">Total Videos Claimed</th>
                <th className="text-label-sm">Total Views</th>
                <th className="text-label-sm text-right" style={{ minWidth: "14rem" }}>
                  Total Earnings
                </th>
              </tr>
            </thead>
            <tbody>
              {currentPageAssetReports?.length > 0 ? (
                <SalesContentIDMonths
                  contentIDAssetReports={currentPageAssetReports}
                  contentIDAssetReportItems={contentIDAssetReportItems}
                />
              ) : null}
            </tbody>
          </table>
        </div>
        <SalesPagination
          pageLimit={perPage}
          currentPage={currentPage}
          totalRecords={contentIDAssetReports?.length}
          gotoPage={setCurrentPage}
        />
      </div>
    </LoadingContainer>
  );
};

export default SalesContentID;

import React, { Component } from "react";
// import tippy from "../../tooltips"

class LicenseTooltip extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {

            }
    notLoggedInOpen = ()=>{
        this.props.triggerLoginModal()
        this.props.toggleTrackTippy()
    }


    render() {
        return (
                <div className="dropdown-menu">
                    <div className="dropdown-group">
                        {this.props.signedLicensePDFURL && (
                            <a href={this.props.signedLicensePDFURL} target="_blank" onClick={()=>{this.props.toggleTrackTippy();}}>
                                <span className="icon-download"></span>Download License
                            </a>
                        )}
                        {this.props.hostedInvoiceURL && (
                            <a href={this.props.hostedInvoiceURL} target="_blank" onClick={()=>{this.props.toggleTrackTippy();}}>
                                <span className="icon-share"></span>View Receipt
                            </a>
                        )}
                        {/* <PDFDownloadLink document={<LicensePDF license={this.props.license} />} fileName={`DeepSounds-License-${this.props.licenseId}.pdf`}>
                            {({ blob, url, loading, error }) => (loading ? 'Loading document..' : <div><span className="icon-download"></span> Download License</div>)}
                        </PDFDownloadLink> */}
                    </div>
                    <div className="dropdown-group">
                        <a href="# " onClick={()=>{this.props.toggleTrackTippy();this.props.shareTrack()}}><span class="icon-share"></span>Share Track</a>
                    </div>
                </div>
        )
    }

}
export default LicenseTooltip

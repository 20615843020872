import React from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";

export default function QuoteRequestComplete() {
  const { email } = useParams();

  return (
    <>
      <Helmet title="Quote Request Complete - Deep Sounds | Music for Creators" />
      <div className="container max-w-4xl text-center leading-tightest pt-100 pb-40 xl:pt-120 xl:pb-40">
        <h1 className="text-h1-sm md:text-h1 mb-10 md:mb-20">Thank you</h1>
      </div>
      <section className="container max-w-lg md:max-w-screen-3xl mb-60 lg:mb-80">
        <div class="article max-w-2xl mx-auto pb-100 text-center">
          <p class="text-h4 text-white">
            You will hear from us shortly! <br />
            We will email you at {email}.
          </p>
          <a href="/music" className="btn btn-primary">
            Browse Music
          </a>
        </div>
      </section>
    </>
  );
}

import React, { Component } from "react";

class TagTypeTooltip extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {

            }

    render() {
        return (    
                <div className="dropdown-menu">
                    <div className="dropdown-group">
                        <a onClick={()=>{this.props.changeTagType(2, "Emotion");this.props.closeTippy()}} href="#">Emotion</a>
                        <a onClick={()=>{this.props.changeTagType(1, "Genre");this.props.closeTippy()}} href="#">Genre</a>
                        <a onClick={()=>{this.props.changeTagType(3, "Mood");this.props.closeTippy()}} href="#">Mood</a>
                        <a onClick={()=>{this.props.changeTagType(4, "Attribute");this.props.closeTippy()}} href="#">Attribute</a>
                        <a onClick={()=>{this.props.changeTagType(7, "Lyrics");this.props.closeTippy()}} href="#">Lyrics</a>
                        <a onClick={()=>{this.props.changeTagType(8, "BPM");this.props.closeTippy()}} href="#">BPM</a>
                        <a onClick={()=>{this.props.changeTagType(5, "Instrument");this.props.closeTippy()}} href="#">Instrument</a>
                    </div>
                </div>
        )
    }

}
export default TagTypeTooltip
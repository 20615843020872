const currencyFormatter = (number, fromCents = false) => {
  let numberToFormat = number || 0;
  if (typeof numberToFormat !== "number") {
    numberToFormat = Number(numberToFormat);
  }
  if (fromCents) {
    numberToFormat = numberToFormat / 100;
  }

  // Create our number formatter.
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  try {
    return formatter.format(numberToFormat);
  } catch (error) {
    return numberToFormat;
  }
};
export default currencyFormatter;

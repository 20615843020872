import React from "react"
import VersionWaveform from "../../Waveform/versionBrowse"
import VersionWaveformNoInteract from "../../Waveform/versionBrowseNoInteract"

const parseAwayZeros = (duration)=>{
    let parts = duration.split(":")
    if(parts[0].length === 2 && parts[0].charAt(0) === "0"){
        return parts[0].charAt(1) + ":" + parts[1]
    }
    else{
        return duration
    }
}
export default function FullTrackItem(props){
    return(
        <div class="vs-version-item">

        {/* {# Play / Pause #} */}
        <div class="flex-none -ml-5 mr-5">
            <button
                class="circle-icon circle-icon-2xs"
                type="button"
                // @click="is_playing = !is_playing"
            >
                {(((props.mediaPlayer?.idKey?.indexOf("track-" + props.baseTrack.trackId) || -1) !== - 1) && props.playing ) ? 
                    <span onClick={()=>props.pauseVideo()} className="icon-pause text-white"></span>
                    : 
                    (props.mediaPlayer?.idKey?.indexOf("track-" + props.baseTrack.trackId) || -1) !==-1 ? 
                        <span onClick={()=>{props.playVideo()}} className="icon-play"></span>:
                        <span onClick={()=>{props.changeCurrentTrack(props.baseTrack?.trackId, `artist-${props.baseTrack?.artistId}-album-${props.baseTrack.albumId}-track-${props.baseTrack.trackId}-trackItemVS`);props.setAutoPlay(false)}} className="icon-play"></span>}
                
            </button>
        </div>

        {/* {# Name #} */}
        <div class="flex-1 text-xs">
            <div
                class={`vs-version-name ${(props.mediaPlayer?.idKey?.indexOf("track-" + props.baseTrack.trackId) || -1) !==-1 && props.playing ? "text-white" : "text-gray-400"}`}
                // class="{ 'text-white' : is_playing, 'text-gray-400' : !is_playing }"
            >
                Full Version
            </div>
        </div>

        {/* {# Wav #} */}
        <div class="vs-waveform">
        {(props.mediaPlayer?.idKey?.indexOf("track-" + props.baseTrack.trackId) || -1) !==-1   ?
            <VersionWaveform videoStudio={true} forceHeight={1.25} currentTime={props.currentTime} trackId={props.baseTrack?.id} s3Key={props.baseTrack?.wavURL} peaks={props.baseTrack?.peaks} duration={props.baseTrack?.duration} id={props.baseTrack?.id}/>:
            <VersionWaveformNoInteract currentTime={props.baseTrack?.currentTime} trackId={props.baseTrack?.id} s3Key={props.baseTrack?.wavURL} peaks={props.baseTrack?.peaks} duration={props.baseTrack?.duration} id={props.baseTrack?.id}/>
        }
        </div>

        {/* {# Duration #} */}
        <div class="flex-none w-25 text-right text-xs">
            {parseAwayZeros(props.baseTrack?.duration)}
        </div>

        </div>
    )
}

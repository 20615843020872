import React, { Component } from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";

class CheckoutLayout extends Component {
  state = {
    navClass: "",
  };

  setCurrentNav = (updatedNav) => {
    this.setState({ currentNav: updatedNav });
  };

  render() {
    return (
      <>
        <Navbar pathname={window.location.pathname}></Navbar>
        <div className="app-container">
          <div className="app-main pt-50 lg:pt-80">
            {this.props.children}
            <Footer></Footer>
          </div>
        </div>
      </>
    );
  }
}

export default CheckoutLayout;

import React, { Component } from "react"

class PendingProjectHeader extends Component {

    render() {
        return (
            <div class="project-header">

                <h3>
                    <div >{this.props.name}</div>
                </h3>
                <div class="heading-icon-section">
                    {/* {# Share Project #} */}
                    <button onClick={()=>this.props.acceptInvitation(this.props.projectId)} className='btn btn-primary'>Accept</button>
                    <button className='btn btn-stroke' onClick={()=>this.props.declineInvitation(this.props.projectId)}>Decline</button>


                </div>
            </div>
        )
    }
}

export default PendingProjectHeader;
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { LabelRequired } from "../../FormElements/labels";
import CircularIndeterminateButton from "../../Loading/circularIndeterminantButton";
import { Error, Success } from "../../Toast/index";
import api from "../../utils/api";
import BackdropOverlayComponent from "../../LoadingOverlay/backdropComponent";
import { SetIdentityMailFromDomainCommand } from "@aws-sdk/client-ses";

const schema = Joi.object({
  oneTimeCode: Joi.string()
    .length(6)
    .required()
    .messages({
      "string.length": "Please enter a valid code",
      "string.empty": "Please enter a valid code",
      "any.required": "Please enter a valid code",
    }),
});

export default function ConfirmationCodeForm(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const { register, handleSubmit, setError, formState, errors } = useForm({
    mode: "onSubmit",
    defaultValues: {},
    resolver: joiResolver(schema),
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const oneTimeCodeRef = useRef(null);
  useEffect(() => {
    if (oneTimeCodeRef.current) {
      register(oneTimeCodeRef.current);
      oneTimeCodeRef.current.focus();
    }
  }, []);

  useEffect(() => {
    // console.log('formState', formState);
    // Show all errors as Toastify
    Object.entries(errors).map((error) =>
      Error({
        message: error[1].message,
        toastId: error[0],
      })
    );
  }, [errors]);

  const onSubmit = (data, e) => {
    setIsLoading(true);
    setIsDisabled(true);

    api
      .confirmEmailChange({ 
        passwordToken: props.passwordToken,
        oneTimeCode: data.oneTimeCode
      })
      .then((res) => {
        // console.log("confirmEmailChange res", res);

        if (res.status === 200) {
          Success({ message: "Email successfully changed" });
          setIsLoading(false);
          setIsDisabled(false);
          props.setEmail(res.data.email);
          props.handleFormUnsaved(false);
          props.handleClose();
          document.cookie = `email=${res.data.email}; path=/;`;
        } 
        else {
          Error({ message: "Error changing email." });
          setIsLoading(false);
          setIsDisabled(false);
          props.handleFormUnsaved(false);
        }
      })
      .catch((error) => {
          // console.log('updateEmail error', error.response);
          if (error.response.status === 404) {
            props.handleFormUnsaved(false);
            props.handleClose();
            Error({
              message: `Could not validate the request. Please start over.`
            })
          }
          else if (error?.response?.data) {
            Error({
                message: error.response.data
            }); 
          }
        setIsDisabled(false);
        setIsLoading(false);
      });
    // console.log("formData", data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} class="ds-form">
      <div className="grid-row-xs">
        {isLoading && <BackdropOverlayComponent loading={true} size={40} />}

        <div className="col w-full mb-20">
          We just sent a secure one-time confirmation code to your new email address. Please enter that code here.
        </div>

        <div className="col w-full">
          
          <div className="input-group input-inline mb-20">
            <input
              name="oneTimeCode"
              id="one_time_code"
              autoComplete="one-time-code"
              data-lpignore="true"
              className="form-input"
              placeholder={"Confirmation Code *"}
              ref={oneTimeCodeRef}
            />
            <LabelRequired for="newEmail">Confirmation Code</LabelRequired>
          </div>

          <div className="col w-full">
            <div className="input-group input-inline mb-10">
              <button
                type="button"
                className="w-full btn form-btn btn-primary"
                onClick={handleSubmit(onSubmit)}
                disabled={isDisabled}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

import React from "react";

const LicensePanel = (props) => {
  const { children, handleClose, headerText, licenseArtwork, licenseSubtitle, licenseTitle } =
    props;

  return (
    <div className="license-panel is-open" style={{ maxWidth: "100%" }}>
      <div className="license-panel-container">
        <div className="license-panel-header">
          <h3 className="text-label-lg text-white flex-1">{headerText}</h3>
          <button onClick={handleClose} className="circle-icon -mr-10" type="button">
            <span className="icon-close"></span>
          </button>
        </div>

        <div className="license-panel-content">
          {/* {# Track Info #} */}
          <div className="flex flex-1 items-center justify-between py-20">
            {/* {# Artwork #} */}
            <figure className="license-panel-artwork">
              {licenseArtwork ? (
                licenseArtwork
              ) : (
                <span class="text-icon-lg icon-download text-gray-400"></span>
              )}
            </figure>
            {/* {# Title #} */}
            <div className="flex flex-col flex-1 leading-snug">
              <span className="text-white text-h4 font-medium mb-3">{licenseTitle}</span>
              <span className="text-base text-gray-500">{licenseSubtitle}</span>
            </div>
            {/* {# Controls #} */}
            <div className="flex-none -mr-10">
              <button type="button" className="circle-icon">
                <span className="icon-dots-vert"></span>
              </button>
            </div>
          </div>

          {children}
        </div>
      </div>
    </div>
  );
};

export default LicensePanel;

import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select, { components } from "react-select";
import { countries } from "../FormElements/countries";
import { states } from "../FormElements/states";
import CharacterCount from "../FormElements/characterCount";
import customStyles from '../FormElements/customStyles';
import CustomValueContainer from '../FormElements/customValueContainer';
import describeOptions from '../FormElements/Options/describeOptions';
import CircularIndeterminate from '../Loading/circularIndeterminant';
import CircularIndeterminateButton from '../Loading/circularIndeterminantButton';
import { Error, Success, SuccessRedirect } from "../Toast/index";
import api from '../utils/api';

export default function AccountDetailsForm(props) {
    const { control, register, handleSubmit, watch,  } = useForm({mode: 'onChange'});

    async function artistNameExists(artistName) {
        try {
            let artistData = await api.getArtistsByName(artistName)

            return artistData;
          }
          catch (error) {
                // all errors will be captured here for anything in the try block
               console.log(error)
        }
    }

    const onSubmit = (data, e) => {
    	setisLoading(true);
    	setisDisabled(true);
    	console.log('formData', data);

        // make sure all required fields are submitted
        if (!data.artistName ||
            !data.primaryGenre?.value ||
            !data.firstName ||
            !data.lastName ||
            !data.lastName ||
            !data.phone ||
            !data.line1 ||
            !data.country?.id ||
            !data.postal_code ||
            !data.city ||
            !data.state?.id
        ) {
            Error({
                message: "Please fill out required fields.",
                toastId: "required-fields"
            });
            setisDisabled(false);
            setisLoading(false);
            return;
        }

        // make sure primary & secondary genres not the same
        if (data.primaryGenre.value === data.secondaryGenre?.value) {
            Error({
                message: "Primary and Secondary Genre cannot be the same.",
                toastId: "genre-match"
            });
            setisDisabled(false);
            setisLoading(false);
            return;
        }

        // check if artist name is taken
        const existingArtist = artistNameExists(data.artistName);
        console.log('existingArtist', existingArtist)
        if (existingArtist.value?.data?.success) {
            Error({
                message: "This Artist Name is already taken. Please choose another.",
                toastId: "artist-name"
            });
            setisDisabled(false);
            setisLoading(false);
            return;
        }

        // check if artist name too long
        if (data.artistName.length > 41) {
            Error({
                message: "Artist Name must be 41 characters or less.",
                toastId: 'artist-name'
            })
            setisDisabled(false);
            setisLoading(false);
            return;
        }

        let {line1, line2, city, state, postal_code, country, shouldBlockNavigation, loginMessage, ...otherDetails} = data
        console.log(line1, line2, city, state, postal_code, country, " ", otherDetails)
        if(line1 || line2 || city || state || postal_code || country){
            let billingAddress = {
                line1: line1 || props.details.line1,
                line2: line2 || props.details.line2,
                city: city || props.details.city,
                state: state.value || props.details.state,
                postal_code: postal_code || props.details.postal_code,
                country: country.value || props.details.country
            }
            console.log("billing address ", billingAddress)

            api.updateBillingAddress(billingAddress)
                .then(res=>{
                    console.log(res);
                    setFormUnsaved(false)
                })
                .catch(error=>{
                    setFormUnsaved(true)
                    Error({message: "Error updating Billing Address"})
                })
        }

        let userDetails = {
            artistName: data.artistName,
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone,
            secondaryPhone: data.secondaryPhone,
            primarygenre: data.primaryGenre.value,
            secondarygenre: data.secondaryGenre?.value || null,
            bestDescribed: data.bestDescribed,
        }
        //should spaces be replaced with "-"?
        if (props.details.invite?.role === "artist" && data.artistName && data.primaryGenre?.value && data.country.value) {
            userDetails.artistURL = data.artistName.toLowerCase().replace(/[$]/g, "s").replace(/[&]/g, "and").replace(/[&\/\\#,+()~%.'":;*?<>!{}\[\]@=-\^&]/g,'').replace(/\s/g, "")
        }

        if (props.details.signupStatus === 1) {
            userDetails.signupStatus = 2
            userDetails.invite = props.details.invite || null
            api.signUpComplete(userDetails)
                .then(res=>{
                    console.log('signUpComplete res', res)
                    if (res.status === 200){
                        if (props.details.invite.role === "artist") {
                            document.cookie = `artistName=${encodeURIComponent(userDetails.artistName)}; path=/;`
                            document.cookie = `userURL=/artists/${userDetails.artistURL}; path=/;`
                        }
                        document.cookie = `name=${encodeURIComponent(userDetails.firstName) + " " + encodeURIComponent(userDetails.lastName)}; path=/;`
                        document.cookie = `role=${props.details.invite.role}; path=/;`
                        SuccessRedirect({message: "Account Details saved", onClose: ()=>window.location.replace(props.nextStep) })
                    }
                })
                .catch(error=>{
                    setisLoading(false);
                    console.log('error!', error.response)
                    if(error.response?.status === 409){
                        Error({
                            message: "This Artist Name is already taken. Please choose another.",
                            toastId: "artist-name"
                        });
                    }
                    else {
                        Error({message: "Error updating Account Details"})
                    }
                    setisDisabled(false);
                })
        }
        else {
            api.updateMyDetails(userDetails)
            .then(res=>{
                console.log('updateMyDetails res', res);
                setFormUnsaved(false)
                if (props.nextStep) {
                    SuccessRedirect({message: "Account Details saved", onClose: ()=>window.location.replace(props.nextStep) })
                }
                else {
                    Success({message: "Account Details saved"})
                }
            })
            .catch(error=>{
                setFormUnsaved(true)
                setisLoading(false);
                Error({message: "Error updating Account Details"})
            })
        }

    };

    const [formUnsaved, setFormUnsaved] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [isDisabled, setisDisabled] = useState(false);
    const [formError, setFormError] = useState(false);

    const noStateOptionsMessage = props => {
        console.log('noOptions props', props);
        return (
            <components.NoOptionsMessage {...props}>
                First select a Country
            </components.NoOptionsMessage>
        );
      };

    const watchArtistName = watch("artistName", props.details.artistName);

    const watchCountry = watch("country", props.details.countryOption);
    // console.log('watchCountry', watchCountry);

    const watchPrimaryGenre = watch("primaryGenre", props.details.primaryGenre);
    // console.log('watchPrimaryGenre', watchPrimaryGenre);

    const watchSecondaryGenre = watch("secondaryGenre", props.details.secondaryGenre);
    // console.log('watchSecondaryGenre', watchSecondaryGenre);


        return(
            !props.details?.loaded ?
                <CircularIndeterminate forceCenter />
            :
                <>
                    <div className="mb-50">
                        <p className="mb-0">
                            Please use the form below to add your Account Details.
                        </p>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)} className="ds-form">
                        <div className="form-section">
                            {console.log('currentUser', props.currentUser.user)}
                            {console.log('details', props.details)}
                            {formError &&
                                <div className="error-message">
                                    {console.log(formError)}
                                    {formError}
                                </div>
                            }
                            <div className="grid-row-sm">
                                {((props.currentUser?.user.role === "artist") || (props.currentUser?.user.role === "admin") ) &&
                                    <div className="col w-full">
                                        <div className="input-group input-inline">
                                            <input
                                                disabled={props.currentUser.user.signupStatus === 100}
                                                name="artistName"
                                                id="artist_name"
                                                className="form-input"
                                                placeholder="Artist Name *"
                                                defaultValue={props.details.artistName}
                                                ref={
                                                    register({
                                                        validate: {
                                                            asyncValidate: async value => artistNameExists(value)
                                                        }
                                                    })
                                                }
                                            />
                                            <label className="input-label" htmlFor="artist_name">
                                                Artist Name
                                                <span className="input-label-required">*</span>
                                            </label>
                                            <CharacterCount
                                                limit="41"
                                                value={watchArtistName?.length}
                                            />
                                        </div>
                                    </div>
                                }
                                {((props.currentUser?.user.role === "artist") || (props.currentUser?.user.role === "admin")) &&
                                <>
                                    <div className="col w-full md:w-1/2">
                                        <div className="input-group input-inline">
                                            <label className="input-label" htmlFor="primary_genre">
                                                Primary Genre
                                                <span className="input-label-required">*</span>
                                            </label>
                                            <Controller
                                                name="primaryGenre"
                                                styles={customStyles}
                                                className="select-container"
                                                classNamePrefix="select"
                                                id="primary_genre"
                                                placeholder="Primary Genre *"
                                                defaultValue={
                                                    props.details.primaryGenre?.id ?
                                                        { label: props.details.primaryGenre?.name, value: props.details.primaryGenre?.id }
                                                    :
                                                        { label: "Select a genre", value: "", disabled: true }
                                                }
                                                as={
                                                    <Select
                                                        options={props.details?.genreOptions?.filter(option => option.id !== watchSecondaryGenre.value).map(
                                                            genreOption => {
                                                                return {
                                                                    label: genreOption.name,
                                                                    value: genreOption.id
                                                                }
                                                            })
                                                        }
                                                        components={{
                                                            ValueContainer: CustomValueContainer
                                                        }}
                                                    />
                                                }
                                                control={control}
                                            />
                                        </div>
                                    </div>
                                    <div className="col w-full md:w-1/2">
                                        <div className="input-group input-inline">
                                            <label className="input-label" htmlFor="secondary_genre">
                                                Secondary Genre
                                            </label>
                                            <Controller
                                                name="secondaryGenre"
                                                styles={customStyles}
                                                className="select-container"
                                                classNamePrefix="select"
                                                placeholder="Secondary Genre"
                                                id="secondary_genre"
                                                defaultValue={
                                                    props.details.secondaryGenre?.id ?
                                                        { label: props.details.secondaryGenre?.name, value: props.details.secondaryGenre?.id }
                                                    :
                                                        { label: "Select a genre", value: "", disabled: true }
                                                }
                                                as={
                                                    <Select
                                                        options={props.details?.genreOptions?.filter(option => option.id !== watchPrimaryGenre.value).map(
                                                            genreOption => {
                                                                return {
                                                                    label: genreOption.name,
                                                                    value: genreOption.id
                                                                }
                                                            })
                                                        }
                                                        components={{
                                                            ValueContainer: CustomValueContainer
                                                        }}
                                                    />
                                                }
                                                control={control}
                                            />
                                        </div>
                                    </div>
                                </>}
                                    <div className="col w-full md:w-1/2">
                                        <div className="input-group input-inline">
                                            <input
                                                name="firstName"
                                                id="first_name"
                                                className="form-input"
                                                placeholder="First Name *"
                                                // onChange={this.handleInputChange}
                                                defaultValue={props.details.firstName}
                                                ref={register}
                                            />
                                            <label className="input-label" htmlFor="first_name">
                                                First Name
                                                <span className="input-label-required">*</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col w-full md:w-1/2">
                                        <div className="input-group input-inline">
                                            <input
                                                name="lastName"
                                                id="first_name"
                                                className="form-input"
                                                placeholder="Last Name *"
                                                // onChange={this.handleInputChange}
                                                defaultValue={props.details.lastName}
                                                ref={register}
                                            />
                                            <label className="input-label" htmlFor="last_name">
                                                Last Name
                                                <span className="input-label-required">*</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col w-full md:w-1/2">
                                        <div className="input-group input-inline">
                                            <input
                                                name="phone"
                                                id="phone"
                                                className="form-input"
                                                placeholder="Phone Number *"
                                                // onChange={this.handleInputChange}
                                                defaultValue={props.details.phone}
                                                ref={register}
                                            />
                                            <label className="input-label" htmlFor="phone">
                                                Phone Number
                                                <span className="input-label-required">*</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col w-full md:w-1/2">
                                        <div className="input-group input-inline">
                                            <input
                                                name="secondaryPhone"
                                                id="secondary_phone"
                                                className="form-input"
                                                placeholder="Secondary Phone"
                                                // onChange={this.handleInputChange}
                                                defaultValue={props.details.secondaryPhone}
                                                ref={register}
                                            />
                                            <label className="input-label" htmlFor="secondary_phone">Secondary Phone</label>
                                        </div>
                                    </div>
                                    {(props.currentUser.user?.role !== "artist") &&
                                        <div className="col w-full md:w-1/2">
                                            <div className="input-group input-inline">
                                                <input
                                                    name="company"
                                                    id="company"
                                                    className="form-input"
                                                    placeholder="Company"
                                                    // onChange={this.handleInputChange}
                                                    defaultValue={props.details.company}
                                                    ref={register}
                                                />
                                                <label className="input-label" htmlFor="company">Company</label>
                                            </div>
                                        </div>
                                    }
                                    {(props.currentUser.user?.role !== "artist") &&
                                        <div className="col w-full md:w-1/2">
                                            <div className="input-group input-inline">
                                                <input
                                                    name="position"
                                                    id="company_role"
                                                    className="form-input"
                                                    placeholder="Role at Company"
                                                    // onChange={this.handleInputChange}
                                                    defaultValue={props.details.position}
                                                    ref={register}
                                                />
                                                <label className="input-label" htmlFor="company_role">Role at Company</label>
                                            </div>
                                        </div>
                                    }

                                    <div className="col w-full md:w-1/2">
                                        <div className="input-group input-inline">
                                            <input
                                                name="line1"
                                                id="address1"
                                                className="form-input"
                                                placeholder="Address *"
                                                // onChange={this.handleInputChange}
                                                defaultValue={props.details.line1}
                                                ref={register}
                                            />
                                            <label className="input-label" htmlFor="address1">
                                                Address
                                                <span className="input-label-required">*</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col w-full md:w-1/2">
                                        <div className="input-group input-inline">
                                            <input
                                                name="line2"
                                                id="address2"
                                                className="form-input"
                                                placeholder="Apt / Ste/ Unit"
                                                // onChange={this.handleInputChange}
                                                defaultValue={props.details.line2}
                                                ref={register}
                                            />
                                            <label className="input-label" htmlFor="address2">Apt / Ste / Unit</label>
                                        </div>
                                    </div>
                                    <div className="col w-full">
                                        <div className="input-group input-inline">
                                            <label className="input-label" htmlFor="country_select">
                                                Country
                                                <span className="input-label-required">*</span>
                                            </label>
                                            <Controller
                                                name="country"
                                                styles={customStyles}
                                                className="select-container"
                                                classNamePrefix="select"
                                                placeholder="Country *"
                                                id="country_select"
                                                // onChange={this.handleCountry}
                                                defaultValue={
                                                    props.details.countryOption?.id ?
                                                        props.details.countryOption
                                                    :
                                                        { label: "Select a country", value: "", disabled: true }
                                                }
                                                components={{
                                                    ValueContainer: CustomValueContainer
                                                }}
                                                as={
                                                    <Select
                                                        options={countries.map(country=>{
                                                            return {
                                                                label: country.name,
                                                                id: country.id,
                                                                value: country.name,
                                                                sortname: country.sortname
                                                            }
                                                        })}
                                                    />
                                                }
                                                control={control}
                                            />
                                        </div>
                                    </div>
                                    <div className="col w-1/2 md:w-1/4">
                                        <div className="input-group input-inline">
                                            <input
                                                name="postal_code"
                                                id="zipcode"
                                                className="form-input"
                                                placeholder="Postal Code *"
                                                // onChange={this.handleInputChange}
                                                defaultValue={props.details.postal_code}
                                                // disabled={!this.state.country?.id}
                                                ref={register}
                                            />
                                            <label className="input-label" htmlFor="zipcode">
                                                Zip / Postal Code
                                                <span className="input-label-required">*</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col w-1/2 md:w-1/2">
                                        <div className="input-group input-inline">
                                            <input
                                                name="city"
                                                id="city"
                                                className="form-input"
                                                placeholder="City *"
                                                // onChange={this.handleInputChange}
                                                defaultValue={props.details.city}
                                                // disabled={!this.state.country?.id}
                                                ref={register}
                                            />
                                            <label className="input-label" htmlFor="city">
                                                City
                                                <span className="input-label-required">*</span>
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col w-full md:w-1/4">
                                        <div className="input-group input-inline">
                                            <label className="input-label" htmlFor="state_select">
                                                State / Province
                                                <span className="input-label-required">*</span>
                                            </label>
                                            {console.log('states', states)}
                                            {console.log('countryOption', props.details.countryOption)}
                                            {console.log('stateOption', props.details.stateOption)}
                                            <Controller
                                                disabled={props.details.countryOption?.id}
                                                name="state"
                                                styles={customStyles}
                                                className="select-container"
                                                classNamePrefix="select"
                                                placeholder="State *"
                                                options={
                                                    states.filter(state => state.country_id === (watchCountry?.id + "")).map(state=>{
                                                        return {
                                                            label: state.name,
                                                            id: state.id,
                                                            value: state.name,
                                                            sortname: state.sortname
                                                        }
                                                    })
                                                }
                                                id="state_select"
                                                // onChange={this.handleState}
                                                //  isDisabled={!this.state.country?.id}
                                                defaultValue={
                                                    props.details.stateOption?.id ?
                                                        props.details.stateOption
                                                    :
                                                        { label: "Select a state", value: "", disabled: true }
                                                }
                                                components={{
                                                    NoOptionsMessage: noStateOptionsMessage,
                                                    ValueContainer: CustomValueContainer
                                                }}
                                                as={Select}
                                                control={control}
                                            />
                                        </div>
                                    </div>




                                    {(props.currentUser.user?.role !== "artist") &&
                                        <div className="col w-full">
                                            <div className="input-group input-inline">
                                                <label className="input-label" htmlFor="describe_select">
                                                    Which best describes you?
                                                    <span className="input-label-required">*</span>
                                                </label>
                                                <Controller
                                                    name="bestdescribed"
                                                    styles={customStyles}
                                                    className="select-container"
                                                    classNamePrefix="select"
                                                    placeholder="Which best describes you? *"
                                                    options={describeOptions}
                                                    id="describe_select"
                                                    // onChange={this.handleBestDecribed}
                                                    defaultValue={
                                                        props.details.bestDescribed && { label: props.details.bestDescribed, value: props.details.bestDescribed }
                                                    }
                                                    components={{
                                                        ValueContainer: CustomValueContainer
                                                    }}
                                                    as={Select}
                                                    control={control}
                                                />
                                            </div>
                                        </div>
                                    }
                                    <div className="col w-full">
                                        <button
                                            disabled={isDisabled}
                                            type="submit"
                                            className="btn btn-primary form-btn w-full"
                                        >
                                            {isLoading ?
                                                <CircularIndeterminateButton forceCenter />
                                            :
                                                "Save and Continue"
                                            }
                                        </button>
                                    </div>

                                </div>
                            </div>
                            <div className="form-section"></div>
                    </form>
                </>
        )
}

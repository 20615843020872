import { Box, LinearProgress, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import moment from "moment"
import React, { Component } from "react"
import MicroModal from "react-micro-modal"
import Select from "react-select"
import { UserConsumer } from "../../contexts/User"
import customStyles from "../FormElements/customStyles"
import Wavesurfer from "../Waveform/forTrackModal"
import AlbumModal from "./albumModal"
import formatAlbumLabel from "../FormElements/TrackForm/formatAlbumLabel";
import "./linearProgress.css"



const useStyles = makeStyles({
  colorPrimary:{
    backgrounColor: "rgba(192,192,192,0.3)"
  },
  root: {
    background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)',
    borderRadius: 3,
    border: 0,
    color: 'white',
    height: 48,
    padding: '0 30px',
    boxShadow: '0 3px 5px 2px rgba(255, 105, 135, .3)',
    // $disabled is a reference to the local disabled
    // rule within the same style sheet.
    // By using &, we increase the specificity.
    '&$disabled': {
      background: 'rgba(0, 0, 0, 0.12)',
      color: 'white',
      boxShadow: 'none',
    },
  },
  disabled: {},
});
var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment)

// Styles for Single Select

class NewTrackModal extends Component {
    state = {
        redirectTo: null,
        isAlbumModalOpen: false,
    }
    //updating our input fields as the user enters keys

    updateAlbumsCB=(newAlbum)=>{
        this.props.updateAlbumsCB(newAlbum)
        this.setState({isAlbumModalOpen: false})
    }

    openAlbumModal = ()=>{
        this.setState({isAlbumModalOpen: true})
    }
    handleAlbumModalClose= ()=>{
        if(this.state.albumLoading){
            return
        }
        else{
            this.setState({isAlbumModalOpen: false})
        }
    }
    albumLoadingCB=(isLoading)=>{
        this.setState({albumLoading: isLoading})
    }
    render() {
        return (
            <UserConsumer>
                {({ user, login, loginMessage }) => (
                    <>
                                   {/* {# Modal Header #} */}
                                   <header className="modal-header">
<h2 id="new_track_modal_title" className="modal-title">New Track</h2>
<button type="button" onClick={this.props.handleClose} aria-label="Close Modal"  className="modal-close"></button>
</header>

<div id="new_track_modal_content" className="modal-content">

{/* {# Form #} */}
<form action="" className="ds-form">

 {/* {# Upload Item #} */}
 <div className="input-group">
     <div className="upload-item upload-item-block">
         {console.log(process.env)}
         <div className="upload-item-pre">
             {/* {# Replace div with react loader, swap with play button once uploaded #} */}
             {/* <div className="w-30 h-30 flex border border-gray-500 border-2 rounded-full"></div> */}
             {this.props.playable &&
                <button type="button" onClick={(event)=>{event.preventDefault();this.props.playPreview()}} className="circle-icon-border circle-icon-sm">{this.props.playPreviewState ? <span className="icon-pause"></span>: <span className="icon-play"></span>}</button>
             }
         </div>
         <div className="upload-item-details">
             <div className="upload-item-title">{this.props.file?.length > 0 ? this.props?.file[0].name: ""}</div>
         </div>
         <div className="upload-item-wave">
            {/* {% include "components/audio/waveform-sm.html" %} */}
                {this.props.wavePreview &&
                <Wavesurfer emitPlayable={this.props.emitPlayable} nowPlaying={this.props.playPreviewState} src={this.props.wavePreview} ></Wavesurfer>
                }
            {this.props.previewDuration &&
                <span className="upload-item-duration">{this.props.previewDuration}</span>
            }
         </div>

         {/* <div className="upload-item-controls"> */}
             {/* <button className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button> */}
         {/* </div> */}
     </div>
      {this.props.submitting &&
      <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
              <LinearProgress  value={this.props.progress || 0} variant={"determinate"} classes={{colorPrimary: 'colorPrimary', barColorPrimary: 'barColorPrimary'}}/>
            </Box>
            <Box minWidth={35}>
              <Typography variant="body2" color="white">{`${this.props.progress || 0}%`}</Typography>
            </Box>
          </Box>
    }
 </div>

 {/* {# Title #} */}
 <div className="input-group">
     <label className="input-label" htmlFor="track_title">Track Title</label>
     {this.props.trackTitleError &&
        <div className="text-primary">{this.props.trackTitleError}</div>
     }
     <input value={this.props.newTrackTitle} onChange={this.props.handleInputChange} name={"newTrackTitle"} id="track_title" className="form-input" placeholder="Enter track title here..."/>
 </div>

 {/* {# Album Select #} */}
 <div className="input-group">
    <label className="input-label" htmlFor="album_select">Link to Album</label>
    {this.props.albumTrackError &&
        <div className="text-primary">{this.props.albumTrackError}</div>
    }
    <div className="grid-row-xs">
        <div className="col flex-1">
            <Select
                name="newTrackAlbum"
                className="select-container"
                classNamePrefix="select"
                styles={customStyles}
                placeholder="Select an Album"
                value={this.props.newTrackAlbum}
                options={this.props.myAlbums}
                onChange={this.props.handleAlbumChange}
                formatOptionLabel={formatAlbumLabel}
            />
        </div>
        <MicroModal
            open={this.state.isAlbumModalOpen}
            closeOnEscapePress={true}
            handleClose={()=>this.handleAlbumModalClose()}
            closeOnOverlayClick={true}
            closeOnAnimationEnd={true}
            modalOverlayClassName="modal-overlay"
            modalClassName="modal micromodal-slide is-open"
            modalOverlayStyles={null}
            children={ handleClose =>
              <AlbumModal
                loadingCB={this.albumLoadingCB}
                handleClose={handleClose}
                updateAlbumsCB={this.updateAlbumsCB}
              />
            }
            containerStyles={{background: "#131313", padding:"asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box"}}
        ></MicroModal>
        <div className="col w-auto flex-initial">
            <button
              type="button"
              disabled={this.props.submitting}
              onClick={(e)=>{e.preventDefault();if(!this.props.submitting){this.openAlbumModal()}}}
              className="btn btn-stroke form-btn w-full">
                <span className="icon-plus mr-5"></span>Create
            </button>
        </div>
    </div>
</div>

 {/* {# Buttons #} */}
 <div className="input-group pt-20">
     <div className="grid-row-sm">
         <div className="col w-1/2 mb-20 sm:mb-0">
             <button type="button"  disabled={this.props.submitting} onClick={(event)=>{event.preventDefault(); if(!this.props.submitting)this.props.submitNewTrack(false)}} className="btn btn-stroke form-btn w-full">Save and Quit</button>
         </div>
         <div className="col w-1/2">
             <button type="button"  disabled={this.props.submitting} onClick={(event)=>{event.preventDefault(); if(!this.props.submitting)this.props.submitNewTrack(true)}} className="btn btn-primary form-btn w-full">Continue</button>
         </div>
     </div>
 </div>

</form>
 </div>
                    </>
                )}</UserConsumer>
        )
    }

}
export default NewTrackModal

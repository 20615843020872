import { ErrorMessage } from '@hookform/error-message';
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import customStyles from '../FormElements/customStyles';
import proOrgs from '../FormElements/Options/proOrgs';
import CircularIndeterminateButton from '../Loading/circularIndeterminantButton';
import { Error, Success, SuccessRedirect } from "../Toast/index";
import api from '../utils/api';

export default function ProForm(props) {
    const [formUnsaved, setFormUnsaved] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [isDisabled, setisDisabled] = useState(false);


    const { control, register, handleSubmit, errors, } = useForm();

    const onSubmit = (data, e) => {
    	setisLoading(true);
    	setisDisabled(false);
    	

        if(data.number && data.org){
            let proData = {
                number: data.number,
                org: data.org
            }
            api.updateMyPro(proData)
                .then(res=>{
                    
                    let userData = res.data;
                    
                    userData.signupStatus = 6;
                    api.updateSignupStatus(userData)
                        .then(signupStatusRes => {
                            
                            if (signupStatusRes?.data?.signupStatus === 6) {
                                if (props.nextStep) {
                                    SuccessRedirect({message: "P.R.O. information updated", onClose: ()=>window.location.replace(props.nextStep) })
                                }
                                else {
                                    Success({message: "P.R.O. information updated"})
                                }
                            }
                        })
                        .catch(error=>{
                            Error({message: "Error updating your account"})
                        })
                    setFormUnsaved(false)
                })
                .catch(error=>{
                    Error({message: "Error updating P.R.O. information"})
                })
        }
        else {
            Error({
                message: "Please fill out required fields.",
                toastId: "required-fields"
            })
            return;
        }

    };

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="ds-form">
            <div className="form-section">
                <ErrorMessage errors={errors} name="multipleErrorInput">
                    {({ messages }) =>
                        messages &&
                        Object.entries(messages).map(([type, message]) => (
                            <p key={type}>{message}</p>
                        ))
                    }
                </ErrorMessage>
                <div className="input-group-sm">
                    <div className="grid-row-xs">
                        <div className="col w-1/2 mb-20 md:mb-0">
                            <label className="input-label" htmlFor="org">
                                P.R.O. Name 
                                <span className="input-label-required">*</span>
                            </label>
                            <Controller
                                name="org"
                                id="org"
                                styles={customStyles}
                                options={proOrgs}
                                as={Select}
                                defaultValue={
                                    props.pro?.org &&   
                                        {label: props.pro?.org, value: props.pro?.org}
                                }
                                control={control}
                            />
                        </div>
                        <div className="col w-1/2 mb-20 md:mb-0">
                            <label className="input-label" htmlFor="number">
                                IPI/CAE #  <span className="input-label-required">*</span>
                            </label>
                            <input 
                                name="number"
                                id="number"
                                className="form-input"
                                defaultValue={props.pro?.number}
                                placeholder="Enter IPI/CAE #"
                                ref={register}
                            />
                        </div>
                    </div>
                </div>
                <button 
                    className="btn btn-primary w-full"
                    type="submit"
                    disabled={isLoading}
                >
                    {isLoading ?
                        <CircularIndeterminateButton forceCenter />
                        :
                        (props.buttonText ?
                            props.buttonText
                        :
                            'Update P.R.O. Information'         
                        )
                    }
                    
                </button>
            </div>
        </form>
    )
}
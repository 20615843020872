import React, { Component } from "react"
import ReactDOMServer from 'react-dom/server'
import Moment from "react-moment"
import { NavLink } from "react-router-dom"
import LicenseSummary from "../Licenses/licenseSummary"

class LicensesTab extends Component{

    render(){
        return(
            <div class="tab-item-content active" id="tab_licenses">
                <div className="table-wrap ds-scrollbar">
                    <table className="stats-table w-full">
                        <thead class="text-left text-bold">
                            <tr>
                                <th class="text-label-sm"><strong>Date</strong></th>
                                <th class="text-label-sm"><strong>Project Name</strong></th>
                                <th class="text-label-sm"><strong>License Type</strong></th>
                            </tr>
                        </thead>
                        <tbody>

                            {this.props.licenses.map( licenseFull => {
                                let licenseInfo = JSON.parse(licenseFull.info)
                                let license = JSON.parse(licenseFull.info).license

                                
                                return(
                                    <tr>
                                        <td>
                                            <Moment format="MM.DD.YYYY">
                                                {licenseFull.purchaseDate}
                                            </Moment>
                                        </td>
                                        <td>
                                            <NavLink 
                                                to={"/dashboard/projects/edit/" + licenseInfo.project.id} 
                                                title={licenseInfo.project.name}
                                            >
                                                {licenseInfo.project.name}
                                            </NavLink>
                                        </td>
                                        <td>
                                            <NavLink 
                                                to={"/dashboard/licenses/?licenseNumber="+licenseFull.licenseNumber} 
                                                title={ReactDOMServer.renderToStaticMarkup(<LicenseSummary license={license} />)}
                                            >
                                                <LicenseSummary license={license} />
                                            </NavLink>
                                        </td>
                                    </tr>        
                                )
                            }
                            )}
                        </tbody>
                    </table>
                </div>
                {(this.props.trackTitle !== false && this.props.trackTitle !== undefined && this.props.licenses.length === 5) &&
                    <div className="mt-30 moreLicenses">
                        <NavLink 
                            to={`/dashboard/licenses/?trackTitle=${this.props.trackTitle}`} 
                            title="View More Licenses for This Track"
                            className="text-link-white"
                        >
                            View More Licenses for This Track
                            <span class="icon-arrow-right text-icon-sm ml-5"></span>
                        </NavLink>
                    </div>
                }   
            </div>
        )
    }
}
export default LicensesTab
import React from "react";
import { Helmet } from "react-helmet";
import TermsAndConditions from "../Links/termsAndConditions";

function LicenseTerms() {
  return (
    <div className="container pt-80 md:pt-100 z-10">
      <Helmet title="License Terms - Deep Sounds | Music for Creators" />

      <div className="view-header">
        <h1 className="view-title" style={{ margin: "0 auto" }}>
          License Terms
        </h1>
      </div>

      <p>
        Deep Sounds LLC (&ldquo;Deep Sounds&rdquo;, &ldquo;us&rdquo;, and/or
        &ldquo;we&rdquo;) and the above-identified Licensee
        (&ldquo;Licensee&rdquo;, &ldquo;you&rdquo;, and/or &rdquo;your&rdquo;)
        hereby enter this agreement, setting forth the terms by which Deep
        Sounds is providing a limited license to you to use certain music
        pursuant to the terms of this Music License Agreement and the Deep
        Sounds Terms of Use, which are incorporated by reference herein
        (collectively, the &ldquo;Agreement&rdquo;). By purchasing or generating
        a license for any Track from the Deep Sounds website (the
        &ldquo;Platform&rdquo;), you agree to the following:
      </p>

      <h6 className="pt-10 pb-20">1. DEFINITIONS</h6>

      <p>
        Capitalized terms shall have the following meaning in this Agreement:
      </p>

      <div className="pl-30">
        <p>
          &ldquo;Composition&rdquo; shall mean the musical composition
          identified as the Composition above, solely as recorded by the Artist
          identified above.
        </p>

        <p>
          &ldquo;License&rdquo; shall mean the limited license granted by Deep
          Sounds to the Licensee as provided in this Agreement.
        </p>

        <p>
          &ldquo;License Scope&rdquo; shall mean the selected uses and
          limitations selected for each License Type, as set forth and&nbsp;
          defined in Schedule A, incorporated herein:&nbsp;
        </p>

        <p>
          &ldquo;License Type&rdquo; shall mean the license selected by Licensee
          as set forth above.
        </p>

        <p>
          &ldquo;Term&rdquo; shall mean perpetual unless the license is
          cancelled or terminated by Deep Sounds as provided herein.
        </p>

        <p>
          &ldquo;Territory&rdquo; shall be global unless otherwise specified in
          the License Scope.
        </p>

        <p>
          &ldquo;Track&rdquo; shall mean the master audio recording of the
          Composition by the Artist as provided by Deep Sounds on the Platform,
          including any and all component stem recordings provided therewith,
          together with the musical composition embodied in the Track.
        </p>

        <p>
          &ldquo;Work&rdquo; shall mean the single visual or audio project
          identified at the time of the purchase or generation of the license in
          conjunction with which the Track is used as background music, but
          shall not include the Track standing alone or packaged with other
          sound recordings as part of a compilation or music library.&nbsp;
        </p>
      </div>

      <h6 className="pt-10 pb-20">2. GRANT OF LICENSE</h6>

      <p>
        In consideration of and upon complete payment of the applicable license
        fee to Deep Sounds, subject to the terms of this Agreement, Deep Sounds
        hereby grants Licensee the non-exclusive, non-transferable (except as
        explicitly set forth herein), limited license to use the Track in
        conjunction with the Work and solely as set forth in and accordance with
        the License Scope and License Type and within the Territory during the
        Term. The License granted hereunder does not include any right to
        perform the Track in conjunction with the Work, which may require a
        performance license from the appropriate performing rights organization
        as set forth below.&nbsp;
      </p>

      <h6 className="pt-10 pb-20">3. TERMINATION</h6>

      <p>
        Deep Sounds may, in its sole discretion, terminate the License in the
        event that Licensee breaches any provision or term of this Agreement,
        the{" "}
        <TermsAndConditions underline>
          Deep Sounds Terms of Service
        </TermsAndConditions>
        , or any other agreement between you and Deep Sounds, and such breach is
        not cured by Licensee within fifteen (15) days after notice of the
        default to Licensee by Deep Sounds, which notice shall be deemed
        effective and delivered to Licensee immediately upon sending by Deep
        Sounds.&nbsp;&nbsp;
      </p>

      <h6 className="pt-10 pb-20">4. OWNERSHIP AND NON-MERGER</h6>

      <p>
        Licensee agrees and understands that Deep Sounds is providing only a
        non-exclusive, limited license to use the Track within the selected
        License Scope and that the copyright in and to the Track and the
        Composition therein shall remain the property of their respective
        owner(s). Licensee acknowledges and agrees that it shall not challenge
        the originality of the Track or the Composition or the right or interest
        of ownership of the Track or Composition in and by their respective
        owners. Licensee further agrees that it shall not use Content ID to
        claim ownership of the Track or Composition, or to claim, collect, or
        seek entitlement to any royalties payable for the performance of the
        Track or Composition. The copyrights in and to the Composition and/or
        the Track shall remain independent of, and shall not be merged with, the
        copyright in and to any Work in which the Track is incorporated, with
        which the Track is used, and/or to which the Track is synchronized, and
        Licensee shall take all steps necessary to specifically identify and
        disclaim the Track and Composition in any and all applications for
        registration of the copyright in and to any Work in which the Track is
        incorporated, with which the track is used, and/or to which the Track is
        synchronized.&nbsp;
      </p>

      <h6 className="pt-10 pb-20">5. PUBLIC PERFORMANCE</h6>

      <p>
        Licensee shall be responsible for maintaining and submitting all
        records, cue sheets, and other information and submissions necessary to
        report the use of the Track to the appropriate performing right
        organization. The performance of any Track in conjunction with a Work
        may require a performance license from Broadcast Music, Inc., (a/k/a
        &ldquo;BMI&rdquo;), American Society of Composers Authors and Publishers
        (a/k/a/ &ldquo;ASCAP&rdquo;). Society of European Stage, Authors and
        Composers (a/k/a &ldquo;SESAC&rdquo;) or other performing rights
        organization, and is subject to clearance by such performing rights
        organizations, in accordance with their applicable terms and fees.
        Nothing in this Agreement shall supersede, replace, or negate any
        requirement that Licensee obtain a valid performance license from the
        applicable performing rights organization.&nbsp;&nbsp;&nbsp;&nbsp;
      </p>

      <h6 className="pt-10 pb-20">6. VARIATIONS PERMITTED</h6>

      <p>
        Unless otherwise stated in the License Scope, in the event that Licensee
        wishes to re-edit a Work to change content, a separate License shall be
        required for each re-edited version of a Work. By way of example only,
        if a License is purchased for use of the Track in a television
        advertisement, the License shall be valid for a Work constituting a
        single television commercial and shall not be valid for multiple
        versions of the Work containing distinct content. Notwithstanding the
        preceding, the following variations to a Work shall not require a
        separate license:
      </p>

      <div className="pl-30">
        <p>
          a. the addition of subtitles in different languages added to a Work
        </p>

        <p>
          b. the addition of overdubs of different languages added to a Work
        </p>

        <p>
          c. minor changes to splash screens or calls to action included in a
          Work
        </p>

        <p>d. a change to the aspect ratio for the presentation of a Work</p>

        <p>
          e. deletion of dialogue or content of a Work to conform with broadcast
          or performance requirements of a Work&nbsp;
        </p>
      </div>

      <h6 className="pt-10 pb-20">7. PROHIBITED USES</h6>

      <div className="pl-30">
        <p>
          a. You may not use of a Track in conjunction with any material that:
        </p>

        <div className="pl-30">
          <p>
            i. is sexually explicit, obscene, or constitutes, in Deep Sounds
            sole and exclusive discretion, pornography;
          </p>

          <p>
            ii. is defamatory, invades the privacy rights, or violates the
            rights of publicity of a third party;
          </p>

          <p>
            iii. infringes the copyright, database rights, trademark rights, or
            any other proprietary rights of any third party;
          </p>

          <p>
            iv. threatens, promotes, endorses, or encourages hatred, violence,
            or discrimination against any person or group of persons, or is
            derogatory of any race, nationality, ethnicity, religion, sexual
            orientation, gender or gender identity;&nbsp;
          </p>

          <p>
            v. constitutes or promotes any illegal or fraudulent activity or is
            made in breach of any contractual obligations or duties; and/or
          </p>

          <p>
            vi. promotes, opposes, encourages, or discourages the election of
            any individual, slate, or political party to any office, position,
            or employment,&nbsp;
          </p>
        </div>

        <p>
          b. You may not resell, lease, offer or make the Track available for
          download standing alone or as a package except as embodied in a Work.
        </p>

        <p>
          c. You may not combine the Track with any other material that
          infringes on the copyright, trademark, service mark, or other
          proprietary right, or any right of privacy, of any other person or
          entity.
        </p>

        <p>
          d. You may not modify the Track in such a way that the Composition is
          materially altered and is not recognizable or to degenerate the
          perceived quality of the Track. Notwithstanding this prohibition, you
          may:
        </p>

        <div className="pl-30">
          <p>
            i. Add reverb or other effects for the purpose of emulating playback
            within an environment or under technical conditions
          </p>

          <p>
            ii. Use stems to alter the mix of the Track to emphasize or
            de-emphasize instruments, sounds or effects contained in the Track
          </p>

          <p>iii. Loop sections of a Track</p>
        </div>
      </div>

      <h6 className="pt-10 pb-20">8. INDEMNIFICATION</h6>

      <p>
        To the fullest extent permitted by law, you agree to indemnify, defend
        and hold harmless Deep Sounds, its officers, directors, members,
        managers, shareholders, employees, agents, representatives,
        subsidiaries, affiliates, parent companies, successors, assigns,
        suppliers, vendors, and licensors, from any and all claims, liabilities,
        losses, damages, costs, and expenses (including but not limited to
        reasonable attorneys&rsquo; fees) arising from or relating to (a) your
        breach or alleged breach of this Agreement; (b) any act or omission in
        which you engage by or through your use of the Services or Platform; (c)
        any infringement or alleged infringement of the intellectual property
        rights of any other party; (d) any violation or alleged violation of any
        law, statute, ordinance, or regulation; (e) your use of the Platform or
        Services; and/or (f) your breach or alleged breach of any
        third-party&nbsp; license.
      </p>

      <h6 className="pt-10 pb-20">9. ASSIGNABILITY OR SUBLICENSE</h6>

      <p>
        You may not assign, sell, transfer, share, or sublicense the License
        granted herein except&nbsp; You may transfer the License to any third
        party acquiring ownership of the Work in its entirety. Deep Sounds may
        assign this Agreement without your consent.
      </p>

      <h6 className="pt-10 pb-20">10. DISCLAIMER OF WARRANTY</h6>

      <p>
        ALL TRACKS ARE LICENSED &ldquo;AS IS&rdquo; AND &ldquo;AS
        AVAILABLE&rdquo; AND DEEP SOUNDS AND THE OWNERS OF THE COMPOSITIONS
        AND/OR TRACKS MAKE NO REPRESENTATION, WARRANTY, OR CONDITION OF ANY
        KIND, EXPRESS OR IMPLIED, WITH RESPECT TO THE TRACKS AND/OR THE
        COMPOSITIONS, AND DISCLAIM ANY&nbsp;{" "}
        <strong>WARRANTY OF MERCHANTABILITY</strong>,{" "}
        <strong>WARRANTY OF FITNESS FOR A PARTICULAR PURPOSE</strong>, AND ANY
        WARRANTY OF NON-INFRINGEMENT. DEEP SOUNDS DOES NOT WARRANT THAT A TRACK
        OR COMPOSITION WILL MEET LICENSEE&rsquo;S NEEDS, TECHNICAL
        SPECIFICATIONS, OR QUALITY REQUIREMENTS. NEITHER DEEP SOUNDS NOR THE
        OWNERS OF ANY TRACK OR COMPOSITION WARRANTS THAT THE DEEP SOUNDS
        PLATFORM, OR ANY MATERIAL CONTAINED THEREIN, IS FREE OF MALWARE OR OTHER
        HARMFUL COMPONENTS.&nbsp;
      </p>

      <p>
        DEEP SOUNDS MAKES NO REPRESENTATION OR WARRANTY REGARDING, AND DOES NOT
        GUARANTEE OR ASSUME RESPONSIBILITY FOR, ANY THIRD PARTY APPLICATIONS (OR
        THE CONTENT THEREOF), USER CONTENT, OR ANY OTHER PRODUCT OR SERVICE
        ADVERTISED, PROMOTED, OR OFFERED BY A THIRD PARTY ON OR THROUGH THE DEEP
        SOUNDS PLATFORM OR IN ANY BANNER OR OTHER ADVERTISING, OR ACCESSIBLE
        VIA&nbsp; HYPERLINK. DEEP SOUNDS DOES NOT WARRANT AND IS NOT RESPONSIBLE
        OR LIABLE FOR ANY TRANSACTION BETWEEN YOU AND THIRD PARTY PROVIDERS OF
        THE FOREGOING.&nbsp;
      </p>

      <p>THIS SECTION DOES NOT AFFECT YOUR STATUTORY RIGHTS AS A CONSUMER.</p>

      <h6 className="pt-10 pb-20">11. LIMITATION OF LIABILITY</h6>

      <p>
        DEEP SOUNDS (INCLUDING ITS OFFICERS, MEMBERS, SHAREHOLDERS,
        AGENTS,PARTNERS&nbsp; AND EMPLOYEES) AND/OR THE OWNERS OF THE RIGHTS IN
        AND TO TRACKS AND/OR COMPOSITIONS SHALL NOT BE LIABLE, WHETHER IN TORT
        OR IN CONTRACT, UNDER ANY CIRCUMSTANCES FOR ANY INCIDENTAL, INDIRECT,
        CONSEQUENTIAL (INCLUDING BUT NOT LIMITED TO LOST OPPORTUNITY OR LOST
        PROFIT), EXEMPLARY, OR PUNITIVE DAMAGES, EVEN IF DEEP SOUNDS AND/OR THE
        OWNERS OF THE RIGHTS IN AND TO THE TRACKS AND/OR COMPOSITIONS HAVE BEEN
        ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, IN CONNECTION WITH ANY LOSS,
        CLAIM, DAMAGE, ACTION, SUIT, OR PROCEEDING ARISING FROM OR RELATING TO
        THIS AGREEMENT, OR THE PROVISION OF ANY TRACK, COMPOSITION, LICENSE, OR
        ANY SERVICE, OR FROM THE USE OF THE PLATFORM, INCLUDING BUT NOT LIMITED
        TO LICENSEE&rsquo;S USE OR EXPLOITATION OF A TRACK, COMPOSITION, OR
        LICENSE, OR ANY PART THEREOF. IN ANY AND ALL EVENTS, DEEP SOUNDS&rsquo;
        MAXIMUM AGGREGATE LIABILITY SHALL BE LIMITED TO THE AMOUNTS ACTUALLY
        PAID BY THE LICENSEE TO DEEP SOUNDS PURSUANT TO THIS AGREEMENT.
      </p>

      <h6 className="pt-10 pb-20">12. CHOICE OF LAW, JURISDICTION AND VENUE</h6>

      <p>
        This Agreement shall be governed by and construed in accordance with the
        laws of the State of Illinois without regard for its conflict of law
        provisions. The parties agree that any claims or other actions arising
        out of or relating to this Agreement shall be exclusively litigated in
        the federal courts located in Chicago, Illinois or state courts located
        in Cook County, Illinois, and the parties hereby consent to the exercise
        of personal jurisdiction over them by all such courts.
      </p>

      <h6 className="pt-10 pb-20">13. NON-WAIVER</h6>

      <p>
        No term or provision hereof may be waived by Deep Sounds except in
        writing signed by its duly authorized officer or agent.&nbsp;
      </p>

      <h6 className="pt-10 pb-20">14. SEVERABILITY</h6>

      <p>
        In the event that any provision or any part of a provision of this
        Agreement shall be finally determined to be superseded, invalid, illegal
        or otherwise unenforceable pursuant to applicable laws by an authority
        having jurisdiction, such determination shall not impair or otherwise
        affect the validity, legality, or enforceability of the remaining
        provisions or parts of provisions of this Agreement, which shall remain
        in full force and effect as if the unenforceable provision or part were
        deleted.
      </p>

      <h6 className="pt-10 pb-20">15. NOTICES</h6>

      <p>
        Deep Sounds may serve notice of termination, or any other notice
        relating to this Agreement or any License, by email to the address
        provided by Licensee, which notice shall be effective upon sending by
        Deep Sounds. Licensee shall have sole responsibility to ensure that the
        email address provided to Deep Sounds is accurate and that any change
        thereto is timely updated.
      </p>

      <p>
        Notice by Licensee to Deep Sounds shall be made in writing and shall be
        made by email to{" "}
        <a
          className="underline"
          href="mailto:help@deepsounds.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          help@deepsounds.com
        </a>{" "}
        or to any other email address indicated by Deep Sounds. Such notice to
        Deep Sounds shall be deemed effective upon receipt by Deep Sounds.
      </p>

      <h5 className="pt-40 pb-20">LICENSE TYPES</h5>
      <p>
        ALL BOLDED TERMS SHALL HAVE THE MEANING ASCRIBED TO THEM HEREIN. All
        other terms shall have the meaning ascribed to them in the Terms of
        Service and/or Agreements incorporated therein.
      </p>

      <h6 className="pt-10 pb-20">PERSONAL</h6>
      <p>
        A <strong>Personal Use License</strong> is a license to a single
        individual for use of the Track for personal, household or family use
        and not for use for any commercial purpose or for the advertising or
        promotion of any brand or business but allows for monetization on
        youtube and other Social Media networks. This license permits
        distribution only on social media, online, podcasts, and on video
        sharing platforms. Distribution for physical media such as DVD, Blu-Ray
        or USB Thumb Drives is also permitted but restricted to a maximum
        cumulative total of 50 copies.
      </p>
      <p>
        This license does not include usage for any content that is aired on
        Broadcast TV, Satellite TV, Cable TV, Satellite Radio, Terrestrial
        Radio, or screened theatrically. It also does not include usage for any
        content that is streamed on Subscription or Paid Video-On-Demand
        services such as Netflix, Hulu, Disney Plus, Apple TV Plus, or any other
        similar services.
      </p>

      <h6 className="pt-10 pb-20">COMMERCIAL</h6>
      <p>
        A <strong>Commercial Music License</strong> is a license to an individual, Business, Sole Proprietorships, Non-Profit Organization which is defined as a not-for-profit charitable entity (501(c)(3) or an international equivalent), educational entity, religious entity, or governmental agency for a single use of a Track as background music in promotional or educational multimedia content for such individual or entity within the restrictions listed below:
      </p>

      <div className="pl-30">
        <p>
          <strong>Commercial Standard Music License</strong> is a single use of a Track in a single multimedia content that is published only on social media or online and may have social media boosting & promotional spend behind it.
        </p>

        <p>
          This license does not include usage for any content that is aired on
          Broadcast TV, Satellite TV, Cable TV, Satellite Radio, Terrestrial
          Radio, or screened theatrically. It also does not include usage for
          any content that is streamed on Subscription or Paid Video-On-Demand
          services such as Netflix, Hulu, Disney Plus, Apple TV Plus, or any
          other similar services.
        </p>

        <p>
          <strong>Commercial Plus Music License</strong> is a single use of a Track in a single commercial multimedia content that is published only online or social media and has any amount of advertising spend behind it.
        </p>

        <p>
          This license does not include usage for any content that is aired on
          Broadcast TV, Satellite TV, Cable TV, Satellite Radio, Terrestrial
          Radio, or screened theatrically. It also does not include usage for
          any content that is streamed on Subscription or Paid Video-On-Demand
          services such as Netflix, Hulu, Disney Plus, Apple TV Plus, or any
          other similar services.
        </p>

        <p>
          <strong>Commercial Pro Music License</strong> is a single use of a Track in a single commercial multimedia content that is published online and/or broadcast on Television via broadcast TV, Cable TV, or Satellite TV and is limited to one country.
        </p>

        <p>
          <strong>Commercial Max Music License</strong> is a single use of a Track in a single commercial multimedia content that is published online and/or broadcast on Television via broadcast TV, Cable TV, or Satellite TV and can be broadcast globally.
        </p>
      </div>

      <h6 className="pt-10 pb-20">FILM</h6>
      <p>
        A <strong>Film Music License</strong> is a single use of a Track in a
        single Feature Film, Short Film, Film Trailer, or Bonus Content, as
        selected at the time that the License is purchased or generated, and
        subject to the the following restrictions:
      </p>

      <div className="pl-30">
        <p>
          <strong>Short Film Music License</strong> is a single use of a Track
          in a single narrative or documentary motion picture that is shorter in
          length than a Feature Film, with a runtime of forty (40) minutes or
          less.{" "}
        </p>

        <p>
          <strong>
            Feature Film (Under $2M Production Budget) Music License
          </strong>{" "}
          is a single use of a Track in a single full length narrative or
          documentary motion picture, with a runtime of more than forty (40)
          minutes and a production budget of less than $2,000,000 USD.{" "}
        </p>

        <p>
          <strong>
            Feature Film (Over $2M Production Budget) Music License
          </strong>{" "}
          is a single use of a Track in a single full length narrative or
          documentary motion picture, with a runtime of more than forty (40)
          minutes and a production budget greater than $2,000,000 USD.{" "}
        </p>

        <p>
          <strong>
            Film Trailer (Under $2M Production Budget) Music License
          </strong>{" "}
          is a single use of a Track in a single Film trailer for a motion
          picture that has a production budget of less than $2,000,000 USD.
        </p>

        <p>
          <strong>
            Film Trailer (Under $2M Production Budget) Music License
          </strong>{" "}
          is a single use of a Track in a single Film trailer for a motion
          picture that has a production budget of greater than $2,000,000 USD.
        </p>

        <p>
          <strong>
            Film Bonus Content (Under $2M Production Budget) Music License
          </strong>{" "}
          is a single use of a Track in a single promotional content for a
          single feature film or short film that has a production budget less
          than $2,000,000 USD.
        </p>

        <p>
          <strong>Film Bonus Content (Under $2M Production Budget) Music License</strong> is a
          single use of a Track in a single promotional content for a single
          feature film or short film that has a production budget greater than
          $2,000,000 USD.
        </p>
      </div>

      <h6 className="pt-10 pb-20">TELEVISION SHOW</h6>
      <p>
        A <strong>Television Show Music License</strong> use is a single use of
        a Track in a single <strong>Television Episode</strong>,{" "}
        <strong>Webisode</strong>, or <strong>Television Show Promo</strong> as
        selected at the time that the License is purchased or generated, and
        subject to the following restrictions:
      </p>

      <div className="pl-30">
        <p>
          <strong>Television Show (Under $500k Per Episode) Music License</strong> is a single
          use of a Track in a single TV Episode or Webisode with a production
          budget less than $500,000 USD Per Episode.
        </p>

        <p>
          <strong>
            Television Show (Over $500k Per Episode) Music License
          </strong>{" "}
          is a single use of a Track in a single TV Episode or Webisode with a
          production budget greater than $500,000 USD Per Episode.
        </p>

        <p>
          <strong>
            Television Show Promo (Under $500k Per Episode) Music License
          </strong>{" "}
          is a single use of a Track in a single piece of multimedia content
          that is promoting a TV Episode or Webisode with a production budget
          less than $500,000 USD Per Episode.
        </p>

        <p>
          <strong>
            Television Show Promo (Over $500k Per Episode) Music License
          </strong>{" "}
          is a single use of a Track in a single piece of multimedia content
          that is promoting a TV Episode or Webisode with a production budget
          greater than $500,000 USD Per Episode.
        </p>
      </div>

    </div>
  );
}
export default LicenseTerms;

import React, { Component } from "react";
import { UserConsumer } from "../../contexts/User";
import CircularIndeterminant from "../Loading/circularIndeterminant";
// import SortBar from "../SortBar/results"
// import SearchBar from "../SearchBar/searchBar.js"
import api from "../utils/api";
import StatsTable from "./statsTable";
import TagStatsTable from "./tagStatsTable";

const queryStringer = require("query-string");
class ArtistStats extends Component {
  state = {
    filteredStats: { 1: [] },
    filteredTags: [],
    statsSortChoice: "MostE",
    statsSortDB: "totalSales",
    statsSortText: "Most Earned",
    tagsSortChoice: "MostE",
    tagsSortDB: "totalSales",
    tagsSortText: "Most Plays",
    selectedTagType: "Emotion",
    currentStatsPage: 1,
    currentTagsPage: 1,
    firstTime: true,
    isStatsSortTippyOpen: false,
  };
  componentDidMount = () => {
    api.getMyTotalApprovedTracks().then((res) => {
      if (res.data) {
        if (res.data.totalTracks?.length > 0) {
          this.setState({ totalTracks: res.data.totalTracks[0].total });
        }
      }
    });
    api
      .getMyStats(0, "totalSales")
      .then((res) => {
        if (res.data) {
          this.setState({
            filteredStats: { 1: res.data.tracks },
            totalSalesLoaded: true,
            initialLoad: true && this.state.tagStatsLoaded,
          });
        }
      })
      .catch((error) => {
        this.setState({ totalSalesLoaded: true, initialLoad: true && this.state.tagStatsLoaded });
      });
    api.getMyTagStats(2).then((res) => {
      if (res.data) {
        if (res.data.tags?.length > 0) {
          let allTags = res.data.tags;
          let parsedTags = {};
          allTags.forEach((tag) => {
            if (parsedTags[tag.tagId]) {
              parsedTags[tag.tagId].plays += tag.plays;
              parsedTags[tag.tagId].likes += tag.likes;
              parsedTags[tag.tagId].downloads += tag.downloads;
              parsedTags[tag.tagId].projects += tag.projects;
              parsedTags[tag.tagId].licenses += tag.purchases;
            } else {
              parsedTags[tag.tagId] = {
                name: tag.name,
                plays: tag.plays || 0,
                likes: tag.likes || 0,
                downloads: tag.downloads || 0,
                projects: tag.projects || 0,
                licenses: tag.purchases || 0,
              };
            }
          });
          let arrayedObj = [];
          for (let key in parsedTags) {
            arrayedObj.push(parsedTags[key]);
          }
          arrayedObj = arrayedObj.sort((a, b) => {
            if (a.plays > b.plays) {
              return -1;
            } else if (a.plays < b.plays) {
              return 1;
            } else {
              return 0;
            }
          });

          this.setState({
            filteredTags: arrayedObj,
            tagStatsLoaded: true,
            initialLoad: true && this.state.totalSalesLoaded,
          });
        } else {
          this.setState({ tagStatsLoaded: true, initialLoad: true && this.state.totalSalesLoaded });
        }
      }
    });
  };
  onPageChanged = (data) => {
    const { filteredCollections } = this.state;
    const { currentPage, totalPages, pageLimit } = data;
    // const searchBarEl = document.getElementById("search")
    // searchBarEl.scrollIntoView()
    const offset = (currentPage - 1) * pageLimit;

    this.setState({ currentPage, totalPages });
  };
  gotoPage = (page, totalPages) => {
    // const { onPageChanged = f => f } = this.props;

    const currentPage = Math.max(0, Math.min(page, totalPages));
    const trackStatsHeader = document.getElementById("trackStatsHeader");
    if (!this.state.firstTime) {
      trackStatsHeader.scrollIntoView();
    }
    const paginationData = {
      currentPage,
      totalPages: totalPages,
      pageLimit: 6,
      totalRecords: this.state.totalTracks,
    };

    if (this.state.filteredStats[currentPage]?.length > 0) {
      this.setState({ currentStatsPage: currentPage, firstTime: false }, () =>
        this.onPageChanged(paginationData)
      );
    } else {
      this.setState({ totalSalesLoaded: false, firstTime: false }, () => {
        api
          .getMyStats((currentPage - 1) * 6, this.state.statsSortDB)
          .then((res) => {
            if (res.data) {
              this.setState(
                (prevState) => ({
                  ...prevState,
                  filteredStats: { ...prevState.filteredStats, [currentPage]: res.data.tracks },
                  totalSalesLoaded: true,
                }),
                () => {
                  this.setState({ currentStatsPage: currentPage }, () =>
                    this.onPageChanged(paginationData)
                  );
                }
              );
            }
          })
          .catch((error) => {
            this.setState({ totalSalesLoaded: true });
          });
      });
    }
  };
  goToTagPage = (page, totalPages) => {
    const currentPage = Math.max(0, Math.min(page, totalPages));

    const paginationData = {
      currentPage,
      totalPages: totalPages,
      pageLimit: 6,
      totalRecords: this.state.totalTracks,
    };

    if (this.state.filteredStats[currentPage]?.length > 0) {
      this.setState({ currentStatsPage: currentPage }, () => this.onPageChanged(paginationData));
    } else {
      this.setState({ tagStatsLoaded: false }, () => {
        api
          .getMyStats((currentPage - 1) * 6, this.state.statsSortDB)
          .then((res) => {
            if (res.data) {
              this.setState(
                (prevState) => ({
                  ...prevState,
                  filteredStats: { ...prevState.filteredStats, [currentPage]: res.data.tracks },
                  tagStatsLoaded: true,
                }),
                () => {
                  this.setState({ currentStatsPage: currentPage }, () =>
                    this.onPageChanged(paginationData)
                  );
                }
              );
            } else {
              this.setState({ tagStatsLoaded: true });
            }
          })
          .catch((error) => {
            this.setState({ tagStatsLoaded: true });
          });
      });
    }
  };
  toggleStatsSortTippy = () => {
    this.setState({ isStatsSortTippyOpen: !this.state.isStatsSortTippyOpen });
  };
  toggleTagStatsSortTippy = () => {
    this.setState({ isTagStatsSortTippyOpen: !this.state.isTagStatsSortTippyOpen });
  };
  toggleTagTypeTippy = () => {
    this.setState({ isTagTypeTippyOpen: !this.state.isTagTypeTippyOpen });
  };
  closeStatsSortTippy = () => {
    this.setState({ isStatsSortTippyOpen: false });
  };
  closeTagStatsSortTippy = () => {
    this.setState({ isTagStatsSortTippyOpen: false });
  };
  closeTagTypeTippy = () => {
    this.setState({ isTagTypeTippyOpen: false });
  };
  changeTagType = (tagType, tagTypeText) => {
    if (tagTypeText !== this.state.selectedTagType) {
      this.setState({ tagStatsLoaded: false }, () => {
        api
          .getMyTagStats(tagType)
          .then((res) => {
            if (res.data) {
              if (res.data.tags?.length > 0) {
                let allTags = res.data.tags;
                let parsedTags = {};
                allTags.forEach((tag) => {
                  if (parsedTags[tag.tagId]) {
                    parsedTags[tag.tagId].plays += tag.plays;
                    parsedTags[tag.tagId].likes += tag.likes;
                    parsedTags[tag.tagId].downloads += tag.downloads;
                    parsedTags[tag.tagId].projects += tag.projects;
                    parsedTags[tag.tagId].licenses += tag.purchases;
                  } else {
                    parsedTags[tag.tagId] = {
                      name: tag.name,
                      plays: tag.plays || 0,
                      likes: tag.likes || 0,
                      downloads: tag.downloads || 0,
                      projects: tag.projects || 0,
                      licenses: tag.purchases || 0,
                    };
                  }
                });
                let arrayedObj = [];
                for (let key in parsedTags) {
                  arrayedObj.push(parsedTags[key]);
                }
                arrayedObj = arrayedObj.sort((a, b) => {
                  if (a.plays > b.plays) {
                    return -1;
                  } else if (a.plays < b.plays) {
                    return 1;
                  } else {
                    return 0;
                  }
                });

                this.setState(
                  { filteredTags: arrayedObj, selectedTagType: tagTypeText, tagStatsLoaded: true },
                  () => this.sortTagStatsBy(this.state.tagsSortChoice, true)
                );
              } else {
                this.setState({ tagStatsLoaded: true });
              }
            }
          })
          .catch((error) => {
            this.setState({ tagStatsLoaded: true });
          });
      });
    } else {
      return;
    }
  };
  sortStatsBy = (sortChoice) => {
    if (sortChoice !== this.state.statsSortChoice) {
      this.setState({ totalSalesLoaded: false }, () => {
        switch (sortChoice) {
          case "MostE":
            api.getMyStats(0, "totalSales").then((res) => {
              if (res.data) {
                let filteredStats = {
                  1: res.data.tracks,
                };
                this.setState({
                  currentStatsPage: 1,
                  filteredStats: filteredStats,
                  statsSortChoice: "MostE",
                  statsSortText: "Most Earned",
                  statsSortDB: "totalSales",
                  totalSalesLoaded: true,
                });
              }
            });
            break;
          case "MostP":
            api.getMyStats(0, "plays").then((res) => {
              if (res.data) {
                let filteredStats = {
                  1: res.data.tracks,
                };
                this.setState({
                  currentStatsPage: 1,
                  filteredStats: filteredStats,
                  statsSortChoice: "MostP",
                  statsSortText: "Most Played",
                  statsSortDB: "plays",
                  totalSalesLoaded: true,
                });
              }
            });
            break;
          case "MostL":
            api.getMyStats(0, "likes").then((res) => {
              if (res.data) {
                let filteredStats = {
                  1: res.data.tracks,
                };
                this.setState({
                  currentStatsPage: 1,
                  filteredStats: filteredStats,
                  statsSortChoice: "MostL",
                  statsSortText: "Most Liked",
                  statsSortDB: "likes",
                  totalSalesLoaded: true,
                });
              }
            });
            break;
          case "MostD":
            api.getMyStats(0, "downloads").then((res) => {
              if (res.data) {
                let filteredStats = {
                  1: res.data.tracks,
                };
                this.setState({
                  currentStatsPage: 1,
                  filteredStats: filteredStats,
                  statsSortChoice: "MostD",
                  statsSortText: "Most Downloads",
                  statsSortDB: "downloads",
                  totalSalesLoaded: true,
                });
              }
            });
            break;
          case "MostPr":
            api.getMyStats(0, "projects").then((res) => {
              if (res.data) {
                let filteredStats = {
                  1: res.data.tracks,
                };
                this.setState({
                  currentStatsPage: 1,
                  filteredStats: filteredStats,
                  statsSortChoice: "MostPr",
                  statsSortText: "Most Projects",
                  statsSortDB: "projects",
                  totalSalesLoaded: true,
                });
              }
            });
            break;
          case "MostLic":
            api.getMyStats(0, "purchases").then((res) => {
              if (res.data) {
                let filteredStats = {
                  1: res.data.tracks,
                };
                this.setState({
                  currentStatsPage: 1,
                  filteredStats: filteredStats,
                  statsSortChoice: "MostLic",
                  statsSortText: "Most Licenses",
                  statsSortDB: "purchases",
                  totalSalesLoaded: true,
                });
              }
            });
            break;
        }
      });
    } else {
      this.closeStatsSortTippy();
    }
  };
  sortTagStatsBy = (sortChoice, override) => {
    let unsorted = [...this.state.filteredTags];
    let sorted;
    if (sortChoice !== this.state.tagsSortChoice || override) {
      switch (sortChoice) {
        case "MostP":
          sorted = unsorted.sort((a, b) => {
            if (a.plays > b.plays) {
              return -1;
            } else if (a.plays < b.plays) {
              return 1;
            } else {
              return 0;
            }
          });
          this.setState({
            filteredTags: sorted,
            tagsSortText: "Most Plays",
            tagsSortChoice: "MostP",
          });
          break;
        case "MostL":
          sorted = unsorted.sort((a, b) => {
            if (a.likes > b.likes) {
              return -1;
            } else if (a.likes < b.likes) {
              return 1;
            } else {
              return 0;
            }
          });
          this.setState({
            filteredTags: sorted,
            tagsSortText: "Most Likes",
            tagsSortChoice: "MostL",
          });
          break;
        case "MostD":
          sorted = unsorted.sort((a, b) => {
            if (a.downloads > b.downloads) {
              return -1;
            } else if (a.downloads < b.downloads) {
              return 1;
            } else {
              return 0;
            }
          });
          this.setState({
            filteredTags: sorted,
            tagsSortText: "Most Downloads",
            tagsSortChoice: "MostD",
          });
          break;
        case "MostPr":
          sorted = unsorted.sort((a, b) => {
            if (a.projects > b.projects) {
              return -1;
            } else if (a.projects < b.projects) {
              return 1;
            } else {
              return 0;
            }
          });
          this.setState({
            filteredTags: sorted,
            tagsSortText: "Most Projects",
            tagsSortChoice: "MostPr",
          });
          break;
        case "MostLic":
          sorted = unsorted.sort((a, b) => {
            if (a.licenses > b.licenses) {
              return -1;
            } else if (a.licenses < b.licenses) {
              return 1;
            } else {
              return 0;
            }
          });
          this.setState({
            filteredTags: sorted,
            tagsSortText: "Most Licenses",
            tagsSortChoice: "MostLic",
          });
          break;
      }
    } else {
      this.closeTagStatsSortTippy();
    }
  };
  render() {
    return (
      <UserConsumer>
        {({ user, login, logout }) => (
          <div class="container mb-40 lg:mb-80">
            {/* {# App Header #} */}
            <div class="view-header">
              <h1 class="view-title">Stats</h1>
            </div>
            {this.state.initialLoad ? (
              <>
                <StatsTable
                  tracks={this.state.filteredStats[this.state.currentStatsPage]}
                  gotoPage={this.gotoPage}
                  currentPage={this.state.currentStatsPage}
                  totalTracks={this.state.totalTracks}
                  sortText={this.state.statsSortText}
                  toggleStatsSortTippy={this.toggleStatsSortTippy}
                  closeStatsSortTippy={this.closeStatsSortTippy}
                  isStatsSortTippyOpen={this.state.isStatsSortTippyOpen}
                  sortStatsBy={this.sortStatsBy}
                  isLoaded={this.state.totalSalesLoaded}
                />

                <TagStatsTable
                  tags={this.state.filteredTags}
                  goToPage={this.goToTagPage}
                  currentPage={this.state.currentTagsPage}
                  totalTags={this.state.totalTags}
                  sortText={this.state.tagsSortText}
                  toggleStatsSortTippy={this.toggleTagStatsSortTippy}
                  closeStatsSortTippy={this.closeTagStatsSortTippy}
                  isStatsSortTippyOpen={this.state.isTagStatsSortTippyOpen}
                  sortStatsBy={this.sortTagStatsBy}
                  selectedTagType={this.state.selectedTagType}
                  closeTagTypeTippy={this.closeTagTypeTippy}
                  toggleTagTypeTippy={this.toggleTagTypeTippy}
                  isTagTypeTippyOpen={this.state.isTagTypeTippyOpen}
                  changeTagType={this.changeTagType}
                  isLoaded={this.state.tagStatsLoaded}
                />
              </>
            ) : (
              <div>
                <CircularIndeterminant forceCenter />
              </div>
            )}
          </div>
        )}
      </UserConsumer>
    );
  }
}
export default ArtistStats;

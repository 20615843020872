import React from "react";
import CheckoutAddToProject from "./checkoutAddToProject";

const CheckoutAddToProjectModal = (props) => {

  const {
    associateProjectToCartItem,
    currentItemIndex,
    handleClose,
    toggleTrackTippy,
    trackId,
    trackTitle
  } = props;

  return (
    <React.Fragment>
      {/* <div id="add_to_project_modal" aria-hidden="true" className="modal micromodal-slide"> */}

      {/* {# Modal Overlay #} */}
      {/* <div tabindex="-1" data-micromodal-close="add_to_project_modal" className="modal-overlay"> */}

      {/* {# Modal Container #} */}
      {/* <div role="dialog" aria-modal="true" aria-labelledby="add_scene_title" className="modal-container"> */}

      {/* {# Modal Header #} */}
      <header className="modal-header">
        <h2 id="add_scene_title" className="modal-title">
          Create New Project
        </h2>
        <button
          type="button"
          aria-label="Close Modal"
          data-micromodal-close="add_to_project_modal"
          className="modal-close"
          type="button"
          onClick={handleClose}
        ></button>
      </header>

      {/* {# Modal Content #} */}
      <div id="add_scene_content" className="modal-content">
        <p className="text-h4 text-white mb-30 max-w-3xl">
          What is the name of the project this track will be used for?
        </p>
        <CheckoutAddToProject
          trackId={trackId}
          trackTitle={trackTitle}
          currentItemIndex={currentItemIndex}
          associateProjectToCartItem={associateProjectToCartItem}
          toggleTrackTippy={toggleTrackTippy}
          handleClose={handleClose}
        />
      </div>
      {/* {# End Modal Content #} */}

      {/* </div> */}
      {/* {# End Modal Container #} */}
      {/* </div> */}
      {/* {# End Modal Overlay #} */}
      {/* </div> */}
    </React.Fragment>
  );
};

export default CheckoutAddToProjectModal;

module.exports = {
  content: [
    "./src/**/*.{js,jsx,ts,tsx}",
    "./public/index.html",
  ],
    future: {
        purgeLayersByDefault: true
    },
    purge: false,
    // {
        // enabled: false,
        // layers: ["utilities"],
        // content: ["./templates/**/*.html", "./layouts/**/*.html", './**/*.{js,jsx,ts,tsx}', '../public/index.html']
    // },
    theme: {
        spacing: {
            0: "0",
            1: "0.0625rem",
            2: "0.125rem",
            3: "0.1875rem",
            5: "0.3125rem",
            10: "0.625rem",
            15: "0.9375rem",
            20: "1.25rem",
            25: "1.5625rem",
            30: "1.875rem",
            40: "2.5rem",
            50: "3.125rem",
            60: "3.75rem",
            80: "5rem",
            100: "6.25rem",
            120: "7.5rem",
            140: "8.75rem",
            180: "11.25rem",
            200: "12.5rem",
            250: "15.625rem",
            300: "18.75rem",
            350: "21.875rem"
        },
        screens: {
            sm: "480px",
            md: "760px",
            lg: "1024px",
            xl: "1280px",
            xxl: "1480px",
            '3xl': "1600px"
        },
        fontFamily: {
            display: [
                "Druk",
                "Arial Narrow",
                "-apple-system",
                "BlinkMacSystemFont",
                "Helvetica",
                "sans-serif"
            ],
            display2: [
                '"Druk Wide"',
                "-apple-system",
                "BlinkMacSystemFont",
                "Helvetica",
                "sans-serif"
            ],
            body: [
                '"Scto Grotesk A"',
                "-apple-system",
                "BlinkMacSystemFont",
                "Helvetica",
                "sans-serif"
            ],
            icons: ["ds-icons"]
        },
        colors: {
            black: "#000",
            white: "#fff",
            gray: {
                100: "#131313",
                200: "#1C1C1C",
                300: "#2F2F2F",
                400: "#767474",
                500: "#AFAAAA"
            },
            primary: "#EB192D",
            secondary: "#F42539",
            tertiary: "#D31225",
            success: "#68D391",
            transparent: "transparent"
        },
        fontSize: {
            title: "15.625rem",
            "title-sm": "9.375rem",
            h1: "7.5rem",
            "h1-sm": "4.5rem",
            h2: "4.375rem",
            "h2-sm": "3.25rem",
            h3: "3.125rem",
            "h3-sm": "2.125rem",
            h4: "1.125rem",
            h5: "0.875rem",
            h6: "0.625rem",
            base: "0.9375rem",
            sm: "0.8125rem",
            xs: "0.625rem",
            "icon-lg": "2rem",
            "icon-md": "1.5rem",
            "icon-sm": "1.125rem",
            "icon-xs": "1rem"
        },
        letterSpacing: {
            tightest: "-0.125rem",
            tighter: "-0.003125rem",
            tight: "-0.000625rem",
            normal: "0.000625rem",
            wide: "0.015625rem",
            wider: "0.0625rem",
            widest: "0.125rem"
        },
        lineHeight: {
            none: "0",
            tightest: ".85",
            tighter: ".95",
            tight: "1.125",
            snug: "1.3",
            normal: "1.5",
            loose: "1.7"
        },
        borderRadius: {
            none: "0",
            DEFAULT: "1px",
            lg: "0.5em",
            full: "9999px"
        },
        boxShadow: {
            DEFAULT: "0 8px 24px 3px rgba(0,0,0,0.60)",
            sm: "0 4px 8px 0 rgba(0,0,0,0.20)",
            lg: "0 20px 50px 5px rgba(0,0,0,0.60)",
            stroke: "inset 0 0 0 1px rgba(255,255,255,0.2)",
            inset: "inset 0 0 0 1px rgba(255,255,255,0.2)",
            none: "none"
        },
        zIndex: {
            0: 0,
            10: 10,
            20: 20,
            30: 30,
            40: 40,
            50: 50,
            60: 60,
            70: 70,
            80: 80,
            90: 90,
            100: 100,
            7001: 7001,
            auto: "auto"
        },
        maxHeight: {
            0: "0",
            none: "none",
            full: "100%",
            screen: "100vh",
            max: "9999px"
        },
        aspectRatio: {
            1: '1',
            2: '2',
            3: '3',
            4: '4',
            9: '9',
            16: '16'
        },
        extend: {}
    },
    variants: {
        accessibility: ["focus"],
        alignContent: ["responsive"],
        alignItems: ["responsive"],
        alignSelf: ["responsive"],
        appearance: [],
        aspectRatio: ["responsive"],
        backgroundAttachment: [],
        backgroundClip: [],
        backgroundColor: ["hover", "focus"],
        backgroundImage: [],
        gradientColorStops: [],
        backgroundOpacity: ["hover", "focus"],
        backgroundPosition: ["responsive"],
        backgroundRepeat: [],
        backgroundSize: [],
        borderCollapse: [],
        borderColor: ["hover", "focus"],
        borderOpacity: ["hover", "focus"],
        borderRadius: [],
        borderStyle: [],
        borderWidth: [],
        boxShadow: ["hover", "focus"],
        boxSizing: [],
        container: [],
        cursor: [],
        display: ["responsive"],
        divideColor: [],
        divideOpacity: [],
        divideStyle: [],
        divideWidth: ["responsive"],
        fill: [],
        flex: ["responsive"],
        flexDirection: ["responsive"],
        flexGrow: ["responsive"],
        flexShrink: ["responsive"],
        flexWrap: ["responsive"],
        float: ["responsive"],
        clear: ["responsive"],
        fontFamily: [],
        fontSize: ["responsive"],
        fontSmoothing: [],
        fontVariantNumeric: [],
        fontStyle: [],
        fontWeight: [],
        height: ["responsive"],
        inset: [],
        justifyContent: ["responsive"],
        justifyItems: ["responsive"],
        justifySelf: ["responsive"],
        letterSpacing: [],
        lineHeight: [],
        listStylePosition: [],
        listStyleType: [],
        margin: ["responsive"],
        maxHeight: ["responsive"],
        maxWidth: ["responsive"],
        minHeight: ["responsive"],
        minWidth: ["responsive"],
        objectFit: [],
        objectPosition: [],
        opacity: ["hover", "focus"],
        order: ["responsive"],
        outline: ["focus"],
        overflow: [],
        overscrollBehavior: [],
        padding: ["responsive"],
        placeContent: ["responsive"],
        placeItems: ["responsive"],
        placeSelf: ["responsive"],
        placeholderColor: ["focus"],
        placeholderOpacity: ["focus"],
        pointerEvents: [],
        position: ["responsive"],
        resize: [],
        space: ["responsive"],
        stroke: [],
        strokeWidth: [],
        tableLayout: [],
        textAlign: ["responsive"],
        textColor: ["hover", "focus"],
        textOpacity: ["hover", "focus"],
        textDecoration: ["hover", "focus"],
        textTransform: [],
        textOverflow: ["responsive"],
        userSelect: [],
        verticalAlign: [],
        visibility: ["responsive"],
        whitespace: [],
        width: ["responsive"],
        wordBreak: [],
        zIndex: [],
        gap: [],
        gridAutoFlow: [],
        gridTemplateColumns: [],
        gridAutoColumns: [],
        gridColumn: [],
        gridColumnStart: [],
        gridColumnEnd: [],
        gridTemplateRows: [],
        gridAutoRows: [],
        gridRow: [],
        gridRowStart: [],
        gridRowEnd: [],
        transform: ["responsive"],
        transformOrigin: ["responsive"],
        scale: ["responsive", "hover", "focus"],
        rotate: ["responsive", "hover", "focus"],
        translate: ["responsive", "hover", "focus"],
        skew: ["responsive", "hover", "focus"],
        transitionProperty: [],
        transitionTimingFunction: [],
        transitionDuration: [],
        transitionDelay: [],
        animation: []
    },
    plugins: [
        require('@tailwindcss/aspect-ratio')
    ],
    corePlugins: {
        preflight: true,
        container: false,
        accessibility: true,
        alignContent: true,
        alignItems: true,
        alignSelf: true,
        animation: true,
        appearance: true,
        backgroundAttachment: false,
        backgroundClip: false,
        backgroundColor: true,
        backgroundImage: false,
        backgroundOpacity: true,
        backgroundPosition: true,
        backgroundRepeat: true,
        backgroundSize: true,
        borderCollapse: false,
        borderColor: true,
        borderOpacity: true,
        borderRadius: true,
        borderStyle: true,
        borderWidth: true,
        boxShadow: true,
        boxSizing: true,
        clear: true,
        cursor: true,
        display: true,
        divideColor: true,
        divideOpacity: true,
        divideStyle: true,
        divideWidth: true,
        fill: true,
        flex: true,
        flexDirection: true,
        flexGrow: true,
        flexShrink: true,
        flexWrap: true,
        float: true,
        fontFamily: true,
        fontSize: true,
        fontSmoothing: true,
        fontStyle: true,
        fontVariantNumeric: false,
        fontWeight: true,
        gap: false,
        gradientColorStops: false,
        gridAutoColumns: false,
        gridAutoFlow: false,
        gridAutoRows: false,
        gridColumn: false,
        gridColumnEnd: false,
        gridColumnStart: false,
        gridRow: false,
        gridRowEnd: false,
        gridRowStart: false,
        gridTemplateColumns: false,
        gridTemplateRows: false,
        height: true,
        inset: true,
        justifyContent: true,
        justifyItems: true,
        justifySelf: true,
        letterSpacing: true,
        lineHeight: true,
        listStylePosition: true,
        listStyleType: true,
        margin: true,
        maxHeight: true,
        maxWidth: true,
        minHeight: true,
        minWidth: true,
        objectFit: false,
        objectPosition: false,
        opacity: true,
        order: true,
        outline: true,
        overflow: true,
        overscrollBehavior: true,
        padding: true,
        placeContent: true,
        placeholderColor: false,
        placeholderOpacity: true,
        placeItems: true,
        placeSelf: true,
        pointerEvents: true,
        position: true,
        resize: false,
        rotate: true,
        scale: true,
        skew: true,
        space: true,
        stroke: true,
        strokeWidth: true,
        tableLayout: true,
        textAlign: true,
        textColor: true,
        textDecoration: true,
        textOpacity: true,
        textTransform: true,
        transform: true,
        transformOrigin: true,
        transitionDelay: true,
        transitionDuration: true,
        transitionProperty: true,
        transitionTimingFunction: true,
        translate: true,
        userSelect: true,
        verticalAlign: true,
        visibility: true,
        whitespace: true,
        width: true,
        wordBreak: true,
        zIndex: true
    }
};

import React, { Component } from "react"

class CharacterCount extends Component{

    render(){
        return(

			<div style={{float: "right"}}>
			    <span 
			        className={(this.props.value > this.props.limit || this.props.value < this.props.min) ? "error-message": ""} 
			        style={{fontSize: "0.9375rem"}}
			    >
			        {this.props.value || 0}
			    </span>
			    <span>/{this.props.limit}</span>
			</div>

		)
    }
}
export default CharacterCount
import React, { useState } from "react";
import { Tooltip } from "react-tippy";
import { CartConsumer } from "../../../contexts/Cart";
import "./style.css";
import LicenseForm from "../../Forms/LicenseForm";
import CircularIndeterminate from "../../Loading/circularIndeterminant";
import buildAlbumArtURL from "../../Albums/AlbumArt";

const LicenseModal = (props) => {
  const { demoSubscription, demoSubscriptionTitle, handleClose, index, isDemo, project, track } =
    props;

  const [isLoading, setIsLoading] = useState(false);

  const albumArtURL = buildAlbumArtURL(track?.albumArt);

  let licenseTitle = track?.trackTitle;
  let licenseSubtitle = track?.artistName;
  let licenseArtwork = <img src={albumArtURL} alt="" />;
  if (isDemo) {
    licenseArtwork = <span class="text-icon-lg icon-download text-gray-400"></span>;
    licenseTitle = demoSubscriptionTitle ? demoSubscriptionTitle : "Track Licensing Pricing";
    licenseSubtitle = "Use dropdowns below to see pricing";
  }

  return (
    <CartConsumer>
      {({ addToCart, updateCartItem, isAddedToCartModalOpen, handleAddedToCartModalClose }) => (
        <>
          {
            // Remove this in favor of:
            // isAddedToCart? which displays the text/links
            // ditch AddedToCartModal component
            // add conditional below to either show the LicenseForm or this
            // update the value in CartContext
            isAddedToCartModalOpen ? (
              <>
                <div className="license-panel is-open" style={{ maxWidth: "100%" }}>
                  <div class="license-panel-container">
                    <div class="license-panel-header">
                      <h3 class="text-label-lg text-white flex-1">Track Added to Cart</h3>
                      <button onClick={handleClose} class="circle-icon -mr-10" type="button">
                        <span class="icon-close"></span>
                      </button>
                    </div>
                    <div class="license-panel-content">
                      {/* {# Track Info #} */}
                      <div class="flex flex-1 items-center justify-between py-20">
                        {/* {# Artwork #} */}
                        <figure class="license-panel-artwork">{licenseArtwork}</figure>
                        {/* {# Title #} */}
                        <div class="flex flex-col flex-1 leading-snug">
                          <span class="text-white text-h4 font-medium mb-3">{licenseTitle}</span>
                          <span class="text-base text-gray-500">{licenseSubtitle}</span>
                        </div>
                        {/* {# Controls #} */}
                        <div class="flex-none -mr-10">
                          <button type="button" class="circle-icon">
                            <span class="icon-dots-vert"></span>
                          </button>
                        </div>
                      </div>
                      <div class="mb-40">
                        <a class="btn btn-primary form-btn w-full mb-20" href="/checkout">
                          Checkout Now
                        </a>
                        <button
                          type="button"
                          onClick={() => {
                            handleClose();
                            handleAddedToCartModalClose();
                          }}
                          class="btn btn-stroke form-btn w-full"
                          type="button"
                        >
                          Continue Shopping
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/*// <AddedToCartModal
              //   handleClose={() => {
              //     handleClose();
              //     handleAddedToCartModalClose();
              //   }}
                    // />
              */}
              </>
            ) : (
              /* // // {# Modal Content #} */
              <div className="license-panel is-open" style={{ maxWidth: "100%" }}>
                <div class="license-panel-container">
                  <div class="license-panel-header">
                    <h3 class="text-label-lg text-white flex-1">
                      Music License
                      <Tooltip
                        useContext={true}
                        animation="shift"
                        arrow="true"
                        position="top"
                        html={
                          <>
                            <div className="price-tool-title mb-10">Music License</div>
                            <div className="text-gray-400 price-tool-desc">
                              Each track license covers you for a single project based on the usage,
                              organization size, and distribution selected below.
                            </div>
                          </>
                        }
                      >
                        &nbsp;<span className="icon-help"></span>
                      </Tooltip>
                    </h3>
                    <button onClick={handleClose} class="circle-icon -mr-10" type="button">
                      <span class="icon-close"></span>
                    </button>
                  </div>

                  <div class="license-panel-content">
                    {/* {# Track Info #} */}
                    <div class="flex flex-1 items-center justify-between py-20">
                      {/* {# Artwork #} */}
                      <figure class="license-panel-artwork">{licenseArtwork}</figure>
                      {/* {# Title #} */}
                      <div class="flex flex-col flex-1 leading-snug">
                        <span class="text-white text-h4 font-medium mb-3">{licenseTitle}</span>
                        <span class="text-base text-gray-500">{licenseSubtitle}</span>
                      </div>
                      {/* {# Controls #} */}
                      <div class="flex-none -mr-10">
                        <button type="button" class="circle-icon">
                          <span class="icon-dots-vert"></span>
                        </button>
                      </div>
                    </div>

                    {/* {# Form #} */}
                    {isLoading ? (
                      <CircularIndeterminate forceCenter />
                    ) : (
                      <LicenseForm
                        addToCartContextFunction={addToCart}
                        handleAddedToCartModalClose={handleAddedToCartModalClose}
                        updateCartContextFunction={updateCartItem}
                        isDemo={isDemo}
                        demoSubscription={demoSubscription}
                        index={index}
                        track={track}
                        project={project}
                        handleClose={handleClose}
                      />
                    )}
                  </div>
                </div>
              </div>
            )
          }
        </>
      )}
    </CartConsumer>
  );
};

export default LicenseModal;

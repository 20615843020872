import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import { PlayerContext } from "../../PlayerContext";
import { Error, Success } from "../Toast/index";
import ArtistCardToolTip from "./artistCardToolTip";

class SimilarArtists extends Component {
  state = {};
  resolveArtistPhoto = (artistPhotoKey) => {
    const key = artistPhotoKey;
    if (artistPhotoKey) {
      const ext = key.split(".").reverse()[0];
      let sizeMod;
      let width = window.innerWidth;
      if (width > 1720) {
        sizeMod = "500x500";
      } else if (width > 1280 && width < 1720) {
        sizeMod = "325x325";
      } else if (width > 1190 && width < 1280) {
        sizeMod = "500x500";
      } else if (width > 1024 && width < 1190) {
        sizeMod = "325x325";
      } else if (width > 810 && width < 1024) {
        sizeMod = "500x500";
      } else {
        sizeMod = "325x325";
      }
      let final = key.replace("." + ext, "") + "-minified-" + sizeMod + "." + ext;
      return final;
    }
  };
  shareArtist = () => {
    var textArea = document.createElement("textarea");

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = "2em";
    textArea.style.height = "2em";

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = "transparent";

    if (window.location.port) {
      textArea.value =
        window.location.protocol +
        window.location.hostname +
        ":" +
        window.location.port +
        "/artists/" +
        this.props.artistURL;
    } else {
      textArea.value =
        window.location.protocol + window.location.hostname + "/artists/" + this.props.artistURL;
    }

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      if (successful) {
        Success({ message: "Artist URL Copied to Clipboard" });
      } else {
        Error({ message: "Couldn't Copy Artist URL " });
      }
      var msg = successful ? "successful" : "unsuccessful";
    } catch (err) {}

    document.body.removeChild(textArea);
  };
  toggleArtistCardTippy = () => {
    this.setState({ isArtistTippyOpen: !this.state.isArtistTippyOpen });
  };
  playTracks = (tracks, artistId) => {
    let playList = tracks;
    this.context.setPlaylist(playList);
    this.context.loadTrackInMediaPlayer(playList[0].trackId, playList[0].idKey);
    this.context.setAutoPlay(true);
    this.setState({ showArtistTippy: false, playingArtistId: artistId });
  };
  render() {
    return (
      <div className="col w-full sm:w-1/2 lg:w-1/3 xl:w-1/4 mb-20 md:mb-40">
        <div className="track-grid-card">
          <div className="track-image">
            <img
              src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${
                process.env.REACT_APP_S3_SUB_BUCKET ? process.env.REACT_APP_S3_SUB_BUCKET + "/" : ""
              }${this.resolveArtistPhoto(this.props.profilePhoto)}`}
              alt="artist profile pic"
            />
            <div className="track-image-hover">
              <div className="circle-icon-fill circle-icon-xl bg-white text-black">
                <NavLink to={"" + this.props.artistURL + "?autoplay=true"} className="icon-play">
                  {" "}
                </NavLink>
              </div>
              <div className="wav-form-vector">
                {/* {# Wav SVG #}
                {% include "components/audio/waveform-lg.html" %} */}
              </div>
            </div>
          </div>
          <div className="track-grid-content">
            <div className="track-first-col">
              <div className="track-title-artist">
                <NavLink to={"/artists/" + this.props.artistURL} className="track-title">
                  {this.props.artistName}
                </NavLink>

                {/* {# Meta Info #} */}
                <div className="flex w-full items-center -ml-3">
                  <div className="flex items-center mr-10">
                    <span className="icon-tracks text-gray-400 text-icon-sm mr-3"></span>
                    {this.props.tracks.length} Tracks
                  </div>
                  <div className="flex items-center">
                    <span className="icon-albums text-gray-400 text-icon-sm mr-3"></span>
                    {this.props.albums.length} Albums
                  </div>
                </div>
              </div>
            </div>
            <div className="genres-stems-versions items-top">
              <div className="track-genres text-gray-400">
                {window.location.pathname.includes("/music") ||
                /\/artists\/{0}[^\/]*$/.test(window.location.pathname) ? (
                  <div>{this.props.primaryGenreName}</div>
                ) : (
                  <span>
                    <Link
                      to={{
                        pathname: "/music",
                        search: `?inc_gen=${this.props.primaryGenre}`,
                        state: { from: window.location.pathname },
                      }}
                    >
                      <span>{this.props.primaryGenreName}</span>
                    </Link>
                  </span>
                )}
                {window.location.pathname.includes("/music") ||
                /\/artists\/{0}[^\/]*$/.test(window.location.pathname) ? (
                  <div>{this.props.secondaryGenreName}</div>
                ) : (
                  <span>
                    <Link
                      to={{
                        pathname: "/music",
                        search: `?inc_gen=${this.props.secondaryGenre}`,
                        state: { from: window.location.pathname },
                      }}
                    >
                      <span>{this.props.secondaryGenreName}</span>
                    </Link>
                  </span>
                )}
              </div>
            </div>
            <div className="track-grid-icons">
              <div className="btn-group" x-data="{ followed: false }">
                {/* {# Like #} */}
                {this.props.following ? (
                  <button
                    className="circle-icon btn-jelly js-tooltip is-followed hover:text-primary"
                    title={"Follow " + this.props.artistName}
                    type="button"
                    onClick={() =>
                      this.props.unfollowArtist(this.props.artistId, this.props.artistName)
                    }
                  >
                    <span className="icon-following-heart"></span>
                  </button>
                ) : (
                  <button
                    className="circle-icon btn-jelly js-tooltip"
                    title={"Follow " + this.props.artistName}
                    type="button"
                    onClick={() =>
                      this.props.followArtist(this.props.artistId, this.props.artistName)
                    }
                  >
                    <span className="icon-follow-heart"></span>
                  </button>
                )}
              </div>
              <div className="btn-group">
                <button>
                  <Tooltip
                    arrow={false}
                    open={this.state.isArtistTippyOpen}
                    interactive={true}
                    position={"bottom-end"}
                    useContext={true}
                    trigger={"click"}
                    className="circle-icon"
                    // hideOnClick={true}
                    html={
                      <ArtistCardToolTip
                        artistURL={this.props.artistURL}
                        artistId={this.props.artistId}
                        tracks={this.props.tracks.map((track) => {
                          return { trackId: track.trackId };
                        })}
                        playTracks={this.playTracks}
                        shareArtist={this.shareArtist}
                        toggleArtistCardTippy={this.toggleArtistCardTippy}
                      ></ArtistCardToolTip>
                    }
                    allowHTML={true}
                    onUntrigger={() => this.toggleArtistCardTippy()}
                    onRequestClose={() => this.toggleArtistCardTippy()}
                  >
                    <span onClick={() => this.toggleArtistCardTippy()} className="icon-dots-vert" />
                  </Tooltip>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SimilarArtists.contextType = PlayerContext;
export default SimilarArtists;

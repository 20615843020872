import React from "react"
import { NavLink } from "react-router-dom"

export default function StripeSetupPrompt() {
    return (
        <div className="prompt">
            <div className="prompt-content">
                <div className="text-icon-xl mb-15">
                    <span className="icon-add-stripe-account-lg"></span>
                </div>
                <h3 className="prompt-title">Link your Stripe account</h3>
                <p className="mb-30 lg:mb-40">You will need to setup a payment account before you’re able to upload tracks. It will only take a few minutes.</p>
                <NavLink to="/stripeOnboard/start" className="btn btn-primary">Setup Payment Account</NavLink>
            </div>
        </div>
    )
}
import React, { Component } from "react";
import CircularIndeterminateButton from '../Loading/circularIndeterminantButton';


class EnterPWModal extends Component{

    render(){
        return(
            <React.Fragment>
              <header class="modal-header">
                  <>{
                  {
                      'approve': <h2 id="share_project_title" class="modal-title">Please enter your account password to approve this payout.</h2>,
                      'reapprove': <h2 id="share_project_title" class="modal-title">Please enter your account password to retry this payout.</h2>,
                      'unapprove': <h2 id="share_project_title" class="modal-title">Please enter your account password to unapprove this payout.</h2>,
                  }[this.props.action]
                }</>
                {/* <button onClick={this.props.handleClose} aria-label="Close Modal" data-micromodal-close="share_project_modal" class="modal-close" type="button"></button> */}
            </header>

            {/* {# Modal Content #} */}
            <div id="share_project_content" class="modal-content">

                {/* {# Form #} */}
                <form action="" class="ds-form" onSubmit={(e)=>{e.preventDefault();this.props.approvePayout()}}>

                            {/* {# Title #} */}
                            <div class="input-group-sm input-inline mb-0">
                                <input id="project_title" class="form-input" placeholder="Password" type="password" onChange={this.props.onChange} value={this.props.password}/>
                                <label class="input-label" for="project_title">Password</label>
                                <div className="error-message">{this.props.passError}</div>
                            </div>
                            <div class={`input-group pt-20`}>
                                <div class="grid-row-sm">
                                    <div class="col w-full">
                                        <button disabled={this.props.isLoading} type="submit" class="btn btn-primary form-btn w-full">
                                            {this.props.isLoading ?
                                                <CircularIndeterminateButton  />
                                            :
                                            {
                                                'approve': "Approve Payout",
                                                'reapprove': "Retry Payout",
                                                'unapprove': "Unapprove Payout"
                                                
                                            }[this.props.action]
                                            }
                                        </button>
                                    </div>
                                </div>
                            </div>
                </form>
                {/* {# End Form #} */}

            </div>
</React.Fragment>
        )
    }
}
export default EnterPWModal
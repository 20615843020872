import React, { createContext, useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import cartsAPI from "../../api/carts";
import { Error } from "../../components/Toast/index";
import { handleAPIError } from "../../utils/handleAPIError";
import { UserContext } from "../User";

// userCart: Cart saved to database associated with user
// sessionCart: Cart saved to browser session for logged out users

export const CartContext = createContext();

export const CartProvider = withRouter(({ children, props }) => {

  const userContext = useContext(UserContext);
  const { user, userLoaded } = userContext || {};
  const { user_id } = user || {};
  // console.log('cartContext user', user);

  const [userCartInfo, setUserCartInfo] = useState([]);
  const [isAddedToCartModalOpen, setIsAddedToCartModalOpen] = useState(false);
  const [isCartLoaded, setIsCartLoaded] = useState(false);
  const [licensePromo, setLicensePromo] = useState(null);
  const [subscriptionPromo, setSubscriptionPromo] = useState(null);

  const getSessionCartInfo = () => {
    return JSON.parse(sessionStorage.getItem("cart") || '[]');
  }

  const clearSessionCart = () => {
    sessionStorage.removeItem("cart");
  }

  const syncSessionCart = () => {
    const sessionCartInfo = getSessionCartInfo();
    const sessionLicensePromo = sessionStorage.getItem("licensePromo") || "{}";
    const sessionSubscriptionPromo = sessionStorage.getItem("subscriptionPromo") || "{}";

    console.log('sessionCartInfo', sessionCartInfo);

    if (user_id) {
      cartsAPI
        .sync({ sessionCartInfo })
        .then((response) => {
          const { oneTimeCart, savedCart } = response?.data || {};

          console.log('cartsAPI.sync response', response);

          if (savedCart) {
            const cartInfo = JSON.parse(savedCart?.info || '[]');
            let licensePromo = JSON.parse(sessionLicensePromo);
            let subscriptionPromo = JSON.parse(sessionSubscriptionPromo);

            if (savedCart.promo) {
              licensePromo = JSON.parse(savedCart.promo);
            }

            setUserCartInfo(cartInfo);
            setLicensePromo(licensePromo);
            setSubscriptionPromo(subscriptionPromo);
            setIsCartLoaded(true);
          }
          else {
            setIsCartLoaded(true);
            setUserCartInfo([]);
          }

          clearSessionCart();
        })
        .catch((error) => {
          handleAPIError(error);
          setIsCartLoaded(true);
          setUserCartInfo([]);
        });
    }
  }

  useEffect(() => {
    if (userLoaded) {
      const sessionLicensePromo = sessionStorage.getItem("licensePromo") || "{}";
      const sessionSubscriptionPromo = sessionStorage.getItem("subscriptionPromo") || "{}";

      setIsCartLoaded(false);

      if (user_id) {
        cartsAPI
          .getMyActive()
          .then((response) => {
            console.log('cartsAPI.getMyActive response', response);
            const { cart } = response?.data || {};

            if (cart) {
              const cartInfo = JSON.parse(cart?.info || '[]');
              let licensePromo = JSON.parse(sessionLicensePromo);
              let subscriptionPromo = JSON.parse(sessionSubscriptionPromo);

              if (cart.promo) {
                licensePromo = JSON.parse(cart.promo);
              }

              setUserCartInfo(cartInfo);
              setLicensePromo(licensePromo);
              setSubscriptionPromo(subscriptionPromo);
              setIsCartLoaded(true);
            } else {
              setIsCartLoaded(true);
              setUserCartInfo([]);
            }
          })
          .catch((error) => {
            handleAPIError(error);
            setIsCartLoaded(true);
            setUserCartInfo([]);
          });
      }
      else {
        setUserCartInfo([]);
        setIsCartLoaded(true);
      }
    }
  }, [user_id]);

  const addToCart = (license) => {
    setIsCartLoaded(false);
    if (user_id) {
      cartsAPI
        .createCartItem({ license })
        .then((response) => {
          console.log("createCartItem response", response);
          const { cart, success } = response?.data || {};
          const cartInfo = JSON.parse(cart?.info || '[]');
          if (success) {
            setUserCartInfo(cartInfo);
            setTimeout(() => {
              window.location.replace("/checkout");
            }, 100);
          }
          else {
            setIsCartLoaded(true);
          }
        })
        .catch((error) => {
          handleAPIError(error);
        });
    }
    else {
      sessionStorage.setItem("cart", JSON.stringify([license]));
      setIsCartLoaded(true);
      setTimeout(() => {
        window.location.replace("/checkout");
      }, 100);
    }
  };

  const removeFromCart = (index) => {
    setIsCartLoaded(false);
    if (user_id) {
      cartsAPI
        .removeCartItem({ index })
        .then((response) => {
          const { cart, success } = response?.data || {};
          const cartInfo = JSON.parse(cart?.info || '[]');
          if (success) {
            console.log('setting user cartInfo to', cartInfo)
            setUserCartInfo(cartInfo);
            setIsCartLoaded(true);
          }
          else {
            setIsCartLoaded(true);
          }
        })
        .catch((error) => {
          handleAPIError(error);
          setIsCartLoaded(true);
        });
    }
    else {
      Error({ message: "Must be logged in" });
      setIsCartLoaded(true);
    }
  };

  const clearCart = () => {
    sessionStorage.removeItem("cart");
    setIsCartLoaded(true);
    setUserCartInfo([]);
  };

  const updateCartItem = (index, license) => {
    console.log("updating cart with", license);
    cartsAPI
      .updateCartItem({ index, license })
      .then((response) => {
        const { cart, success } = response?.data || {};
        const cartInfo = JSON.parse(cart?.info || '[]');
        if (success) {
          setUserCartInfo(cartInfo);
        }
      })
      .catch((error) => {
        setIsCartLoaded(true);
        handleAPIError(error);
      });
  };
  const associateProjectToCartItem = (index, projectId, projectName) => {
    const project = {
      id: projectId,
      name: projectName,
    };
    cartsAPI
      .updateCartItem({ index, project })
      .then((response) => {
        const { cart, success } = response?.data || {};
        const cartInfo = JSON.parse(cart?.info || '[]');
        if (success) {
          setUserCartInfo(cartInfo);
        }
      })
      .catch((error) => {
        handleAPIError(error);
      });
  };

  const handleAddedToCartModalClose = () => {
    setIsAddedToCartModalOpen(false);
  };

  const applyPromo = ({ promo, checkoutType }) => {
    sessionStorage.setItem(`${checkoutType}Promo`, JSON.stringify(promo));

    if (checkoutType === "license") {
      setLicensePromo(promo);
    }
    if (checkoutType === "subscription") {
      setSubscriptionPromo(promo);
    }
  };

  const removePromo = (checkoutType = "license") => {
    sessionStorage.setItem(`${checkoutType}Promo`, null);
    if (checkoutType === "license") {
      cartsAPI.removePromo({ checkoutType }).then((res) => {
        setLicensePromo(null);
      });
    } else if (checkoutType === "subscription") {
      setSubscriptionPromo(null);
    }
  };

  return (
    <CartContext.Provider
      value={{
        addToCart,
        applyPromo,
        associateProjectToCartItem,
        clearCart,
        clearSessionCart,
        getSessionCartInfo,
        handleAddedToCartModalClose,
        isAddedToCartModalOpen,
        isCartLoaded: isCartLoaded && userLoaded,
        licensePromo,
        removeFromCart,
        removePromo,
        subscriptionPromo,
        syncSessionCart,
        updateCartItem,
        userCartInfo
      }}
    >
      {children}
    </CartContext.Provider>
  );
});

export const CartConsumer = CartContext.Consumer;

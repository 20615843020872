import React, { useContext, useState } from 'react';
import api from "../../../utils/api";
import { Error, Success } from "../../../Toast";
import { handleAPIError } from "../../../../utils/handleAPIError";
import LoadingButton from "../../../FormElements/LoadingButton";
import { ContentIDContext } from '../../../../ContentIDContext';

export default function SubmitChannel(props) {
  const { handleClose, youtubeChannelData } = props;
  const [isLoading, setIsLoading] = useState(false);

  const context = useContext(ContentIDContext);
  console.log('useContext(ContentIDContext)', context);
  const { setUpdateUserChannelData } = context;

  const submitWhitelistRequest = () => {
    setIsLoading(true);
    if (!youtubeChannelData) {
      Error({
        message: "Please select a valid Youtube channel",
      });
      setIsLoading(false);
      return;
    }

    // Success({
    //   message: "This will submit your channel!",
    // });
    // setIsLoading(false);

    api.createChannelWhitelist(youtubeChannelData)
      .then(response => {
        console.log('createChannelWhitelist response', response);
        if (response.data?.success) {
          Success({
            message: response.data?.message || "Success!"
          });
          setUpdateUserChannelData(true);
          handleClose();
        }
        setIsLoading(false);
      })
      .catch(error => {
        handleAPIError(error);
        setIsLoading(false);
      })
  };

  return (
    <>
      <LoadingButton
        className="w-full btn btn-primary"
        isLoading={isLoading}
        onClick={submitWhitelistRequest}
        type="button"
      >
        Submit Whitelist Request
      </LoadingButton>
    </>
  )
}

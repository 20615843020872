const formatListArray = (arr) => {
  var output = "";
  if (arr.length === 1) {
    output = arr[0];
  } else if (arr.length === 2) {
      //joins all with "and" but no commas
      //example: "bob and sam"
      output = arr.join(' and ');
  } else if (arr.length > 2) {
      //joins all with commas, but last one gets ", and" (oxford comma!)
      //example: "bob, joe, and sam"
      output = arr.slice(0, -1).join(', ') + ', and ' + arr.slice(-1);
  }
  return output;
}
export default formatListArray;

import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import { Tooltip } from "react-tippy"
import { Error, Success } from "../Toast/index"
import FavArtistCardTooltip from "./favArtistCardTooltip"
class FavArtistCard extends Component {
    state={
        showArtistTippy: false
    }
    toggleArtistTippy = ()=>{
        this.setState({showArtistTippy: !this.state.showArtistTippy})
    }
    shareArtist = ()=>{
        var textArea = document.createElement("textarea");

        // Place in top-left corner of screen regardless of scroll position.
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em';
        textArea.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = 0;

        // Clean up any borders.
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';

        // Avoid flash of white box if rendered for any reason.
        textArea.style.background = 'transparent';


        textArea.value = window.location.protocol + "//" +window.location.hostname + (window.location.port ? ":" + window.location.port : "") +"/" + "artists/" + this.props.artistURL;

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            if(successful){
                Success({message: "Artist URL Copied to Clipboard"})
            }
            else{
                Error({message: "Couldn't Copy Artist URL "})
            }
            var msg = successful ? 'successful' : 'unsuccessful';
            
        } catch (err) {
            
        }

        document.body.removeChild(textArea);

        this.setState({showArtistTippy: false})
    }
    resolveProfilePhoto=(profilePhotoKey)=>{
        const key = profilePhotoKey
        if(profilePhotoKey){
            const ext = key.split('.').reverse()[0];
            let final = key.replace('.' + ext, '') + '-minified-140x140'  + '.' + ext
            return final
        }
    }    
    render() {
        return (
            <div class="user-card-sm">
                <div class="user-card-content">
                    <NavLink to={`/artists/${this.props.artistURL}`} >
                        <div class="user-card-image" title={this.props.artistName} style={{backgroundImage: `url('https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveProfilePhoto(this.props.profilePhoto)}')`}}>
                        </div>
                    </NavLink>
                    <div class="user-card-name ml-15">
                        <NavLink to={`/artists/${this.props.artistURL}`} class="user-card-heading">{this.props.artistName }</NavLink>
                        <div class="user-card-meta">
                            <NavLink to={`/artists/${this.props.artistURL}`}><span>{this.props.totalTracks} Total Tracks</span></NavLink>
                            <span class="mx-5 text-gray-400">•</span>
                            <NavLink to={{pathname: `tracks`, state:{fromArtist: this.props.artistName}}} ><span>{this.props.likedTracks} Liked</span></NavLink>
                        </div>
                    </div>
                </div>
                <Tooltip
                    arrow={false}
                    open={this.state.showArtistTippy}
                    interactive={true}
                    useContext={true}
                    html={(<FavArtistCardTooltip
                            artistId={this.props.artistId}
                            artistURL={this.props.artistURL}
                            artistName={this.props.artistName}
                            shareArtist={this.shareArtist}
                            unfollowArtist={this.props.unfollowArtist}
                    />)}
                    allowHTML={true}
                    onUntrigger={()=>{this.state.showArtistTippy ? this.setState({showArtistTippy: false}): this.setState({showArtistTippy: true})}}
                    onRequestClose={()=>{this.state.showArtistTippy ? this.setState({showArtistTippy: false}): this.setState({showArtistTippy: true})}}
                >
                    <button
                        onClick={this.toggleArtistTippy} 
                        class="circle-icon circle-icon-sm icon-dots-vert"
                    ></button>
                </Tooltip>
            </div>
        )
    }
}
export default FavArtistCard
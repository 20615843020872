import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import MicroModal from "react-micro-modal";
import DefaultAvatar from "../../assets/svg/user-avatar.svg";
import { confirm } from "../confirmModal/index";
import CircularIndeterminateButton from '../Loading/circularIndeterminantButton';
import CropUtilityModal from "../modal/cropUtilityModal";
import { Warning } from "../Toast/index";

// import "./style.css"
let fileInserted = false;

function ProfilePhotoUpload(props) {
  const [files, setFiles] = useState([]);
  const [isLoading, setisLoading] = useState(false);

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    noDrag: true,
    onDrop: acceptedFiles => {
      fileInserted = true;
      const files = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      const i = new Image()
        i.onload = ()=>{
          let reader = new FileReader()
          reader.readAsDataURL(files[0])
          reader.onload = ()=>{
            console.log({
              src: files[0].preview,
              width: i.width,
              height: i.height,
              data: reader.result
            })
          }
        
        if(i.width >=1000 && i.height >= 1000){
          setFiles(files);
          if (props.onChange) {
            props.onChange(files);
            props.openPopout()
          }
        }
        else{
          console.log("erroring")
          if (props.onChange) {
            props.onChange({error: "File must be at least 1000 x 1000!"});
          }
        }
      }
        i.src = files[0].preview 
    }
  });


  const removeFile = file => async (event) => {
    console.log("removing file")
    if(event){
      event.preventDefault()
      console.log('file ', file)
      if(file.files.length === 0 && !props.profilePhoto){
        Warning({message: "Cannot remove defualt avatar"})
        return
      }
      
      if(await confirm("Are you sure you want to remove your profile photo?", "Remove Photo")){
        console.log('file ',file)

        fileInserted = false
        if(file?.files.length > 0){
          console.log(" file ",file)
          const newFiles = [...files];
          newFiles.splice(newFiles.indexOf(file), 1);
          setFiles(newFiles);
        }
        else{
          props.removeProfilePhoto(event)
        }
      }
      else{
        return
      }
    }
    else{
      fileInserted = false
      if(file?.files.length > 0){
        console.log(" file ",file)
        const newFiles = [...files];
        newFiles.splice(newFiles.indexOf(file), 1);
        setFiles(newFiles);
      }
  }
  };
  const saveCrop= (files)=>{
    fileInserted = true;

    let file = files[0]
    console.log("saving crop ", files)
    Object.assign(file, {
      preview: URL.createObjectURL(file)
    })
    setFiles(files);
      if (props.onChange) {
        props.onChange(files);
        props.handleCropUtilityClose()
      }
    // const i = new Image()
    // i.onload = ()=>{
    //   let reader = new FileReader()
    //   reader.readAsDataURL(files[0])
    //   reader.onload = ()=>{
    //     console.log({
    //       src: files[0].preview,
    //       width: i.width,
    //       height: i.height,
    //       data: reader.result
    //     })
    //   }
      
    // }
    //   i.src = file.preview 

  }
  const thumbs = files.map(file => (
    <div  key={file.name}>
      <div >
        
        {file.type === "aasdf" ? 
          <img className={"rounded-full"} src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${props.profilePhoto}`}  alt="" />
          :
          <img className={"rounded-full"} src={file.preview}  alt="" />
        } 
        {/* <embed width="191" height="207" name="plugin" src={file.preview} type="application/pdf"/> */}
      </div>
      {/* <button className={"removeButton"} >Remove File</button> */}
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <>
                            <div className="form-section">
                            <h3 className="text-label-lg mb-30">Profile Photo</h3>
                            {props.profilePhotoError && 
                                    <div className="text-primary">{props.profilePhotoError }</div>
                                }
    <div className="grid-row-sm mb-20">

                                <div className="col w-120 mb-20 md:mb-0">

                                    <aside >{thumbs}</aside>
                                    {fileInserted === false ?
                                      props.profilePhoto ? 
                                        <img className={"rounded-full"} src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${props.profilePhoto}`}  alt="" />:
                                        <img style={{width: "100%"}}src={DefaultAvatar}  alt="" />
                                        :
                                     <></>
                                    }

                                    {/* <img src="https://pbs.twimg.com/profile_images/953658232517652480/Xp0NB-TL.jpg" className="rounded-full"/> */}
                                </div>
                                <div className="col flex flex-1 flex-col justify-center">
                                    <p className="mb-15">
                                      Images must be 1000 x 1000 pixels or larger. <br/>
                                      Accepted file types are jpg, png, gif, svg.
                                    </p>
                                    <ul className="list-disc text-xs">
                                        <li>Images with text are not allowed.</li>
                                        <li>Use high quality images.</li>
                                        <li>Color images recommended over Black &amp; White.</li>
                                    </ul>
                                    <div>
                                    {/* {fileInserted &&
                                      <button onClick={props.openPopout} className="btn btn-stroke">Crop Image</button>
                                    } */}
                                  </div>
                                </div>
                              
                            </div>
                            <MicroModal
                              open={props.isCropUtilityOpen}
                              closeOnEscapePress={true}
                              handleClose={()=>{console.log("handling close");props.handleCropUtilityClose(removeFile({files}))}}
                              closeOnOverlayClick={false}
                              closeOnAnimationEnd={true}
                              modalOverlayClassName="modal-overlay"
                              modalClassName="modal micromodal-slide is-open"
                              modalOverlayStyles={null}
                              children={
                                handleClose => 
                                  <CropUtilityModal 
                                    isProfilePhoto={true}
                                    handleClose={handleClose} 
                                    file={files[0]} 
                                    saveCrop={(files) => saveCrop(files)}
                                  />
                              }
                              containerStyles={{background: "#131313", padding:"asdf", maxWidth: "32rem", maxHeight: "95vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box"}}
                            ></MicroModal>
                            {/* {# Upload Status #} */}
                            <div className="input-group flex w-full flex-col md:flex-row">
                                <div className="flex md:flex-1 border-t border-b border-gray-300 py-15 items-center h-50 mb-20 md:mb-0 md:mr-30">
                                    {props.profilePhoto && 
                                      <div className="upload-status-icon flex flex-none items-center mr-10">
                                          <span className="text-icon-md text-white icon-check"></span>
                                      </div>
                                    }
                                    {files.length > 0 ? 
                                      <span className="flex-1">{files[0].name}</span>:
                                      <span className="flex-1">
                                        {props.profilePhoto ? 
                                          props.profilePhoto.split("/")[props.profilePhoto.split("/").length-1] 
                                        : 
                                          "Please upload an image."
                                        }
                                      </span>}
                                    {props.role !== "artist" &&
                                      <button type="button" onClick={removeFile({files})} className="flex px-10 flex-none items-center transition-colors duration-300 text:gray-400 hover:text-white">
                                          <span className="text-icon-md icon-trash"></span>
                                      </button>
                                    }
                                </div>
                                 <div {...getRootProps({ className: "btn-dropzone" })}>
                               <input {...getInputProps()} />
                                <button type="button" className="btn btn-stroke" onClick={(event)=>{event.preventDefault()}}>Upload Profile Photo</button>
                                </div>

                                {/* <button className="btn btn-stroke">Upload Photo</button> */}
                            </div>
                            <button 
                              type="button"
                              className="btn btn-primary w-full"
                              onClick={props.updateProfilePhoto}
                              disabled={props.isLoading}
                            >
                              {props.isLoading ?
                                <CircularIndeterminateButton forceCenter />
                              :
                                (props.buttonText ?
                                  props.buttonText
                                :
                                  'Update Profile Photo'         
                                )
                              }
                              
                            </button>

                            </div>
                            </>
  );
}

export default ProfilePhotoUpload;
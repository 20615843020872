import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import MicroModal from "react-micro-modal";
import { Prompt } from "react-router";
import { ContentIDContext } from "../../ContentIDContext";
import EditSubscriptionModal from "../modal/editSubscriptionModal";
import { Error, Success } from "../Toast/index";
import currencyFormatter from "../../utils/currencyFormatter";
import ChannelWhitelist from "./ChannelWhitelist";
import subscriptionsAPI from "../../api/subscriptions";
import LoadingContainer from "../Loading/LoadingContainer";
import { ContentIDProvider } from "../../ContentIDContext";

const formatEndDate = (endDate, autorenew) => {
  console.log("formatting enddate", endDate);
  let formattedEndDate;
  autorenew
    ? (formattedEndDate = moment.unix(endDate).utc().format("MMM Do, YYYY"))
    : (formattedEndDate = moment.unix(endDate).utc().subtract(1, "days").format("MMM Do, YYYY"));
  console.log("setting formattedEndDate = ", formattedEndDate);
  return formattedEndDate;
};

const Subscriptions = (props) => {
  const { details, location } = props;

  const [freePlan, setFreePlan] = useState(null);
  const [subscription, setSubscription] = useState(details?.subscription);
  const [autorenewDate, setAutorenewDate] = useState(null);
  const [autorenewStatus, setAutorenewStatus] = useState(null);
  const [expireDate, setExpireDate] = useState(null);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);

  const modalMinHeight = "35.5em";

  const getAutoRenewStatus = () => {
    // console.log('getting autorenew status');
    subscriptionsAPI
      .getUserActiveSubscription()
      .then((res) => {
        console.log("getUserActiveSubscription res", res);
        const user = res?.data;
        const { freePlan, subscription } = user || {};
        const { cancelAt, currentPeriodEnd } = subscription || {};
        const autorenew = cancelAt === null;

        setFreePlan(freePlan);
        setSubscription(subscription);

        const currentPeriodEndDate = formatEndDate(currentPeriodEnd, autorenew);
        const cancelAtDate = formatEndDate(cancelAt, autorenew);

        console.log("setting autorenew state to", autorenew);
        setAutorenewStatus(autorenew);
        if (autorenew) {
          setAutorenewDate(currentPeriodEndDate);
        } else {
          setExpireDate(cancelAtDate);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (location?.search === "?edit" && subscription?.interval) {
      setIsEditModalOpen(true);
    }

    getAutoRenewStatus();
  }, []);

  const toggleAutoRenew = () => {
    if (!autorenewStatus) {
      subscriptionsAPI
        .enableAutorenew()
        .then((res) => {
          console.log("enableAutorenew res", res);
          const { updatedStripeSubscription } = res.data || {};
          const { cancel_at: cancelAt, current_period_end: currentPeriodEnd } =
            updatedStripeSubscription || {};
          const autorenew = cancelAt === null;
          const currentPeriodEndDate = formatEndDate(currentPeriodEnd, autorenew);

          // console.log('autorenewStatus', autorenewStatus);

          if (res.data?.success && currentPeriodEndDate) {
            setAutorenewStatus(autorenew);
            setAutorenewDate(currentPeriodEndDate);
            Success({ message: "Auto-Renew Turned ON" });
          } else {
            Error({ message: "There was an error.  Please try again." });
            setAutorenewStatus(false);
            setAutorenewDate(null);
          }
        })
        .catch((error) => {
          Error({ message: "There was an error.  Please try again." });
          setAutorenewStatus(false);
          setAutorenewDate(null);
        });
    } else {
      subscriptionsAPI
        .disableAutorenew()
        .then((res) => {
          console.log("disableAutorenew res", res);
          const { updatedStripeSubscription } = res.data || {};
          const { cancel_at: cancelAt } = updatedStripeSubscription || {};
          const autorenew = cancelAt === null;
          const cancelAtDate = formatEndDate(cancelAt, autorenew);

          // console.log('autorenewStatus', autorenewStatus);

          if (res.data?.success && cancelAtDate) {
            setAutorenewStatus(autorenew);
            setAutorenewDate(null);
            setExpireDate(cancelAtDate);
            Success({ message: "Auto-Renew Turned OFF" });
          } else {
            Error({ message: "There was an error.  Please try again." });
            setAutorenewStatus(false);
            setAutorenewDate(null);
          }
        })
        .catch((error) => {
          Error({ message: "There was an error.  Please try again." });
          setAutorenewStatus(false);
          setAutorenewDate(null);
        });
    }
  };

  const handleEditModalOpen = () => {
    setIsEditModalOpen(true);
  };
  const handleEditModalClose = () => {
    setIsEditModalOpen(false);
    getAutoRenewStatus();
  };

  console.log("subscription data", subscription);

  const totalAmount = subscription?.amount - subscription?.deep?.discount;

  return (
    <LoadingContainer isLoaded={freePlan || (subscription && autorenewStatus !== null)}>
      <Prompt
        when={shouldBlockNavigation}
        message={(location) =>
          window.location.pathname === location.pathname
            ? true
            : "You have unsaved changes, are you sure you want to leave?"
        }
      />

      <div className="mb-50">
        <h2 className="text-h3 text-white mb-20">Current Plan</h2>
        <p className="text-h4 text-gray-400 mb0">
          Please view information regarding your active subscription below.
        </p>
      </div>

      <div className="plan-item">
        {/* {# Plan Title #} */}

        <div className="plan-item-title">
          <h6 className="text-label text-white mb-10">
            {subscription?.interval ? `${subscription?.description}` : "Starter Account"}
          </h6>
          <span className="block leading-snug">
            {subscription?.description ? "Unlimited Music" : "Free Plan"}
          </span>
        </div>
        {/* {# Plan Details #} */}
        <div className="plan-item-details">
          <div className="grid-row-sm">
            <div className="col w-full sm:w-1/2 py-5">
              <div className="plan-callout">
                <span className="icon-download"></span>
                {subscription?.interval ? " Unlimited Licenses" : "Individual Licensing"}
              </div>
            </div>
            <div className="col w-full sm:w-1/2 py-5">
              <div className="plan-callout">
                <span className="icon-subscriptions"></span>
                {subscription?.interval
                  ? `${currencyFormatter(subscription?.amount / 100)}/${subscription.interval}`
                  : "No Payment Necessary"}
              </div>
            </div>
            <div className="col w-full sm:w-1/2 py-5">
              <div className="plan-callout">
                <span className={subscription?.interval ? "icon-star" : "icon-list"}></span>
                {subscription?.interval ? "Premium Features" : "Standard Features"}
              </div>
            </div>
            <div className="col w-full sm:w-1/2 py-5">
              <div className="plan-callout">
                <span className="icon-history"></span>
                {subscription?.amount > 0 ? (
                  autorenewStatus === true && autorenewDate ? (
                    `Renews at ${currencyFormatter(subscription?.amount / 100)}/${
                      subscription.interval
                    } on ${autorenewDate}`
                  ) : (
                    `Expires ${expireDate}`
                  )
                ) : (
                  <>Free Forever</>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* {# Plan Controls #} */}
        <div class="plan-item-controls">
          <div class="grid-row-xs">
            <div class="col flex-1 w-1/2 md:w-auto">
              {freePlan && (
                <a class="btn btn-stroke w-full" href="/pricing">
                  Upgrade
                </a>
              )}
            </div>
            {subscription?.interval && (
              <div class="col w-1/2 md:w-auto">
                <button onClick={handleEditModalOpen} type="button" class="btn btn-gray w-full">
                  Edit
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      <ContentIDProvider>
        <ChannelWhitelist />
      </ContentIDProvider>

      <MicroModal
        open={isEditModalOpen}
        handleClose={() => handleEditModalClose()}
        closeOnEscapePress={true}
        closeOnOverlayClick={true}
        closeOnAnimationEnd={true}
        modalOverlayClassName="modal-overlay"
        modalClassName="modal micromodal-slide is-open"
        modalOverlayStyles={null}
        children={(handleClose) => (
          <EditSubscriptionModal
            handleClose={handleClose}
            toggleAutoRenew={toggleAutoRenew}
            autorenewStatus={autorenewStatus}
            expireDate={expireDate}
            autorenewDate={autorenewDate}
          />
        )}
        containerStyles={{
          background: "#131313",
          padding: "asdf",
          maxWidth: "40.625rem !important",
          maxHeight: "100vh",
          borderRadius: "asdf",
          overflowY: "auto",
          boxSizing: "border-box",
        }}
      />
    </LoadingContainer>
  );
};

export default Subscriptions;

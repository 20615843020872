import React, { useMemo, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { stripeAppearance } from './stripeAppearance';
import { useStripePromise } from '../../../../utils/stripe';
import LoadingContainer from '../../../Loading/LoadingContainer';

export default function StripeElementsWrapper({children, paymentIntent}) {
  console.log('in StripeElementsWrapper')

  const [stripePromise, setStripePromise] = useState(null);

  // useStripePromise()
  //   .then((res) => {
  //     setStripePromise(res);
  //   });
  // console.log('stripePromise', stripePromise);

  const stripeOptions = useMemo(() => ({
    clientSecret: paymentIntent.clientSecret,
    appearance: stripeAppearance
  }));

  return (
    // <LoadingContainer isLoaded={stripeOptions}>
      <Elements stripe={useStripePromise()} options={stripeOptions}>
        {children}
      </Elements>
    // </LoadingContainer>
  );
}

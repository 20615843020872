import React from "react";
import currencyFormatter from "../../../utils/currencyFormatter";
import { Tooltip } from "react-tippy";
import LicenseSummary from "../../Licenses/licenseSummary";
import { DateTime } from "luxon";

const SalesLicenseRow = ({
  artistShare,
  createdOn,
  license,
  price,
  priceSubtext,
  priceType,
  selectedInterval,
  status,
  trackTitle,
  trackURL,
  type,
}) => {
  return (
    <tr>
      <td>{createdOn}</td>
      <td>
        <a className="text-white underline" href={`/tracks/${trackURL}`}>
          {trackTitle}
        </a>
      </td>
      <td>
        {
          {
            "Full Subscription": (
              <Tooltip
                useContext={true}
                animation="shift"
                arrow="true"
                position="top"
                html={
                  <>
                    <div className="price-tool-title mb-10">{type}</div>
                    <div className="text-gray-400 price-tool-desc">
                      This purchase was made through an account that does have a subscription plan
                      and this license was fully covered by their subscription.
                    </div>
                  </>
                }
              >
                <span>{type}</span>
              </Tooltip>
            ),
            "Partial Subscription": (
              <Tooltip
                useContext={true}
                animation="shift"
                arrow="true"
                position="top"
                html={
                  <>
                    <div className="price-tool-title mb-10">{type}</div>
                    <div className="text-gray-400 price-tool-desc">
                      This purchase was made through an account that does have a subscription plan
                      and this license was partially covered by their subscription. You will be
                      compensated through your Subscription Royalty, as well as compensated for your
                      share of the overage they paid.{" "}
                    </div>
                  </>
                }
              >
                <span>{type}</span>
              </Tooltip>
            ),
            "No Subscription": (
              <Tooltip
                useContext={true}
                animation="shift"
                arrow="true"
                position="top"
                html={
                  <>
                    <div className="price-tool-title mb-10">{type}</div>
                    <div className="text-gray-400 price-tool-desc">
                      This purchase was made through an account that does not have a subscription
                      plan.{" "}
                    </div>
                  </>
                }
              >
                <span>{type}</span>
              </Tooltip>
            ),
          }[type]
        }
      </td>
      <td>
        <LicenseSummary license={license} />
      </td>
      <td class="text-right">
        {artistShare > 0
          ? `${currencyFormatter(artistShare / 100)}`
          : status === "complete"
          ? ""
          : "Pending "}
        {
          {
            transfered: (
              <Tooltip
                useContext={true}
                animation="shift"
                arrow="true"
                position="top"
                html={
                  <>
                    <div className="price-tool-title mb-10">Payout Complete</div>
                    <div className="text-gray-400 price-tool-desc">
                      This payout has been sent to your account.
                    </div>
                  </>
                }
              >
                <span className="icon-check"></span>
              </Tooltip>
            ),
            "transfered overage": (
              <Tooltip
                useContext={true}
                animation="shift"
                arrow="true"
                position="top"
                html={
                  <>
                    <div className="price-tool-title mb-10">Payout Complete</div>
                    <div className="text-gray-400 price-tool-desc">
                      This track was licensed under an unlimited subscription plan but was only
                      partially discounted. You received a payment for this license and were also
                      credited towards your Subscription Royalty.
                    </div>
                  </>
                }
              >
                <span className="icon-check"></span>
              </Tooltip>
            ),
            "card waiting transfer": (
              <Tooltip
                useContext={true}
                animation="shift"
                arrow="true"
                position="top"
                html={
                  <>
                    <div className="price-tool-title mb-10">Payout Pending</div>
                    <div className="text-gray-400 price-tool-desc">
                      This payout is pending and will be issued seven to ten business days from the
                      purchase date.
                    </div>
                  </>
                }
              >
                <span className="icon-time"></span>
              </Tooltip>
            ),
            "ach pending": (
              <Tooltip
                useContext={true}
                animation="shift"
                arrow="true"
                position="top"
                html={
                  <>
                    <div className="price-tool-title mb-10">Payout Pending</div>
                    <div className="text-gray-400 price-tool-desc">
                      This payout is pending and will be issued once the payment has been cleared.
                    </div>
                  </>
                }
              >
                <span className="icon-time"></span>
              </Tooltip>
            ),
            disputed: (
              <Tooltip
                useContext={true}
                animation="shift"
                arrow="true"
                position="top"
                html={
                  <>
                    <div className="price-tool-title mb-10">Purchase Disputed</div>
                    <div className="text-gray-400 price-tool-desc">
                      This purchase has been disputed by the customer and is under review by our
                      team.
                    </div>
                  </>
                }
              >
                <span className="icon-warning"></span>
              </Tooltip>
            ),
            refunded: (
              <Tooltip
                useContext={true}
                animation="shift"
                arrow="true"
                position="top"
                html={
                  <>
                    <div className="price-tool-title mb-10">Purchase Refunded</div>
                    <div className="text-gray-400 price-tool-desc">
                      This purchase was disputed by the customer and has been fully refunded to
                      them. The license has been invalidated.
                    </div>
                  </>
                }
              >
                <span className="icon-reject"></span>
              </Tooltip>
            ),
            failed: (
              <Tooltip
                useContext={true}
                animation="shift"
                arrow="true"
                position="top"
                html={
                  <>
                    <div className="price-tool-title mb-10">Payment Failed</div>
                    <div className="text-gray-400 price-tool-desc">
                      This purchase has failed to clear, and the license has been invalidated.
                    </div>
                  </>
                }
              >
                <span className="icon-reject"></span>
              </Tooltip>
            ),
            complete: (
              <Tooltip
                useContext={true}
                animation="shift"
                arrow="true"
                position="top"
                html={
                  <>
                    <div className="price-tool-title mb-10">Subscription Royalty Payout</div>
                    <div className="text-gray-400 price-tool-desc">
                      This track was licensed under an unlimited subscription plan and you will be
                      compensated for this through your monthly Subscription Royalty.
                    </div>
                  </>
                }
              >
                <span className="icon-help"></span>
              </Tooltip>
            ),
          }[status]
        }
      </td>
    </tr>
  );
};

export default SalesLicenseRow;

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import 'react-tippy/dist/tippy.css';
import { UserConsumer } from "../../contexts/User";
import UserEditForm from "./userEditForm";


class EditUser extends Component {
    state = {
        isLoading: true
    }

    componentDidMount = ()=> {
        this.props.handleFormUnsaved(false)
        this.setState({
            isLoading: false
        })
    }

    componentDidCatch(error, info) {
        // if we have a promise then we can deal with it
        if(error instanceof Promise) {
        // we have a promise so lets update our state
        this.setState({ isLoading: true });

        // once the promise has resolved we can update our state again and grab the data
        error.then((data) => this.setState({ isLoading: false, data }));
    }
  }

    render() {

        return (
            <UserConsumer>
                {({ user, login, logout})=>(
                    <div className="container mb-40 lg:mb-80">
                        {/* {# App Header #} */}
                        <div className="view-header">
                            <h1 className="view-title">Edit User</h1>
                        </div>
                        <nav className="tabs mb-40">
                            <ul className="tab-items">
                                <li>
                                    <NavLink to="/dashboard/users/approved" className='tab-item-title' >
                                        <span class="icon-chevron-left"></span>Back to Users
                                    </NavLink>
                                </li>
                            </ul>
                        </nav>
                        <div class="w-full max-w-2xl mb-50 lg:mb-80">
                            <UserEditForm />
                        </div>
                    </div>
                )}
            </UserConsumer>
        )
      }

}

export default EditUser

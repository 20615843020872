import React, { Component } from "react"
import MicroModal from "react-micro-modal"
import { Tooltip } from "react-tippy"
import wav1 from '../../assets/placeholders/wav-01.png'
import wav2 from '../../assets/placeholders/wav-02.png'
import wav3 from '../../assets/placeholders/wav-03.png'
import wav4 from '../../assets/placeholders/wav-04.png'
import wav5 from '../../assets/placeholders/wav-05.png'
import wav6 from '../../assets/placeholders/wav-06.png'
import DefaultAvatar from "../../assets/svg/user-avatar.svg"
import ProjectDetailTooltip from "./projectDetailTooltip"
import ShareProjectModal from "./shareProjectModal"
import './styles.css'

const wavList = [wav1, wav2, wav3, wav4, wav5, wav6]
class ProjectEditHeaderPublic
 extends Component{
    state={
        isShareModalOpen: false
    }
    handleShareModalOpen = ()=>{
        this.props.closeProjectDetailTippy()
        this.setState({isShareModalOpen: true})
    }
    handleShareModalClose = ()=>{
        this.setState({isShareModalOpen: false})
    }
    resolveProfilePhoto=(profilePhotoKey)=>{
        const key = profilePhotoKey
        if(profilePhotoKey){
            const ext = key.split('.').reverse()[0];
            let final = key.replace('.' + ext, '') + '-minified-60x60'  + '.' + ext
            return final
        }
    }
    resolveProjectPhoto=(projectPhotoKey)=>{
        const key = projectPhotoKey
        if(key){
            const ext = key.split('.').reverse()[0];
            if(key.indexOf('albumArt/') !==-1){
                let sizeMod= '140x140'
                let final = key.replace('.' + ext, '') + '-minified-' + sizeMod + '.' + ext
                return final
            }
            else{
                let sizeMod;
                sizeMod = '140x140'
                let final = key.replace('.' + ext, '') + '-minified-' + sizeMod + '.' + ext
                return final
            }
          
        }
    }    
    render(){
        return(
<>
{/* <NavLink to="/dashboard/projects/all" class="view-header-back">
	<span class="icon-chevron-left"></span>
	<span class="text-link-gray text-label">All Projects</span>
</NavLink> */}

{/* // {# View Header #} */}
<div class="view-header view-header-nowrap project-header">
	{/* {# Header Content #} */}
    <div class="flex flex-1 items-center">
        
        <div class="flex items-center mt-10">
            {/* owner profile photo */}
            <div class="flex-none">
            {this.props.projectArtwork ? 
                <img src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveProjectPhoto(this.props.projectArtwork)}`} class="w-80 h-80 xl:w-100 xl:h-100 mr-10 xl:mr-15"/>:
                <img src={wavList[this.state.randomWavIndex]} alt={this.props.name} class="w-80 h-80 xl:w-100 xl:h-100 mr-10 xl:mr-15"/>
            }
            </div>
        <div className="flex flex-col flex-1">
        <h1 class="view-title">{this.props.name}</h1>
        <div className="flex items-center">
            {this.props.ownerPhoto ? 
                <img src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveProfilePhoto(this.props.ownerPhoto)}`} class="rounded-full w-30 h-30 mr-10"/>:
                <img src={DefaultAvatar} class="rounded-full w-30 h-30 mr-5"/>
            }
            <div class="inline-block">
                <span class="text-gray-400 mr-3">by</span>
                {/* owner name */}
                <a href="#" class="text-link-gray">{this.props.ownerFn} {this.props.ownerLn}</a>
            </div>
            </div>
        </div>
        </div>
    </div>

    <div class="view-header-controls">
        <div class="btn-group btn-group-lg">
            {/* {# Share #} */}
            <button onClick={this.handleShareModalOpen} class="circle-icon-border icon-share hidden md:flex" data-micromodal-trigger="share_project_modal">
                <span class=""></span>
            </button>
            <MicroModal
                        // trigger={handleOpen => <button onClick={handleOpen} class="circle-icon-border icon-share hidden md:flex" data-micromodal-trigger="share_project_modal">
                        //     <span class=""></span>
                        //     </button>}
                        open={this.state.isShareModalOpen}
                        closeOnEscapePress={true}
                        closeOnOverlayClick={true}
                        closeOnAnimationEnd={true}
                        handleClose={()=>this.handleShareModalClose()}
                        modalOverlayClassName="modal-overlay"
                        modalClassName="modal micromodal-slide is-open"
                        modalOverlayStyles={null}
                        children={handleClose => <ShareProjectModal handleClose={handleClose}  projectLink={this.props.projectLink} />}
                        containerStyles={{ background: "#131313", padding: "asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box" }}
                    />

            
            {/* {# More Dropdown #} */}
            <Tooltip
                useContext={true}
                arrow={false}
                open={this.props.isProjectDetailTippyOpen}
                interactive={true}
                position={'bottom-end'}
                trigger={'click'}
                className="display-mobile"
                // hideOnClick={true}
                html={(<ProjectDetailTooltip 
                            isPublicPage={true}
                            handleShareModalOpen={this.handleShareModalOpen}
                        >
                    </ProjectDetailTooltip>
                )}
                allowHTML={true}
                onUntrigger={this.props.toggleProjectDetailTippy}
                onRequestClose={this.props.toggleProjectDetailTippy}
            >
                <button className="circle-icon js-dropdown-toggle display-mobile"
                    id="project_edit_options"
                    onClick={this.props.toggleProjectDetailTippy}
                    data-tippy-html="#project_edit_options"
                    data-tippy-placement="bottom-end"
                    type="button"
                >
                    <span className="icon-dots-vert"></span>
                </button>
            </Tooltip>
        </div>
    </div>
</div>

        </>)
    }
}

export default ProjectEditHeaderPublic
;
import axios from "axios";

export default {
  get: function (mainCategory) {
    return axios({
      method: 'get',
      url: '/api/licenseProducts/' + mainCategory
    });
  }
}

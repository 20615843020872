import React, { useEffect, useState } from "react";
import stripeAPI from "../../api/stripe";

const StripeAccountLink = () => {
  const [stripeAccountLink, setStripeAccountLink] = useState(null);
  useEffect(() => {
    let isMounted = true;
    stripeAPI
      .getAccountLink()
      .then((res) => {
        // console.log("stripeAccountLink res", res);
        if (isMounted) setStripeAccountLink(res?.data?.accountLink);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <a className="text-xs text-link-dark" href={stripeAccountLink} target="_blank" rel="noreferrer">
      View Stripe Account
    </a>
  );
};

export default StripeAccountLink;

import React, { useState } from "react";
import ProfilePhotoUpload from "../Dropzone/profilePhotoUpload";
import CircularIndeterminate from '../Loading/circularIndeterminant';
import { Error, SuccessRedirect } from "../Toast/index";
import api from '../utils/api';

export default function ProfilePhoto(props) {
    const [isCropUtilityOpen, setIsCropUtilityOpen] = useState(false);
    const [profilePhotoError, setProfilePhotoError] = useState('')
    const [formUnsaved, setFormUnsaved] = useState(false);
    const [profilePhotoFile, setProfilePhotoFile] = useState(null);
    const [isLoading, setisLoading] = useState(false);

    function handleCropUtilityClose(cropSaved) {
        setIsCropUtilityOpen(false)
        
        if(cropSaved){
            cropSaved()
        }
        else{
        }
    }

    function removeProfilePhoto(event) {
        event.preventDefault()
        api.removeProfilePhoto()
        .then(res=>{
            props.currentUser.updateProfilePhotoContext(res.data.profilePhoto)
            SuccessRedirect({message: "Profile Photo Removed", onClose: ()=>window.location.reload() })
        })
        .catch(error=>{
            Error({message: "Error Removing Cover Photo"})
        })
    }

    function setprofilePhotoState(file) {
        
        if(file.error){
            
            setProfilePhotoError(file.error)
        }
        else{
            setFormUnsaved(true)
            setProfilePhotoFile(file)
            setProfilePhotoError(false)
        }
    }

    function updateProfilePhoto(event) {
        event.preventDefault()
        // 
        setisLoading(true);
        if(Array.isArray(profilePhotoFile)){
            const data = new FormData()
            data.append('file', profilePhotoFile[0])
            
            api.updateProfilePhoto(data)
                .then(res => {
                    let userData = {};
                    props.currentUser.updateProfilePhotoContext(res.data.profilePhoto);
                    // update signup status
                    // make this more dynamic #refactor
                    userData.signupStatus = 3;
                    api.updateSignupStatus(userData)
                        .then(signupStatusRes => {
                            
                            if (signupStatusRes?.data?.signupStatus === 3) {
                                if (props.nextStep) {
                                    SuccessRedirect({message: "Profile Photo updated", onClose: () => window.location.replace(props.nextStep) })
                                }
                                else {
                                    SuccessRedirect({message: "Profile Photo updated", onClose: () => window.location.reload() })
                                }
                            }
                        })
                        .catch(error=>{
                            Error({message: "Error updating your account"})
                        })
                })
                .catch(error=>{
                    Error({message: "Error updating Profile Photo"})
                })
        }
        else if (props.currentUser?.user.profilePhoto && props.nextStep) {
            let userData = {};
            // update signup status
            // make this more dynamic #refactor
            userData.signupStatus = 3;
            api.updateSignupStatus(userData)
                .then(signupStatusRes => {
                    
                    if (signupStatusRes?.data?.signupStatus === 3) {
                        if (props.nextStep) {
                            SuccessRedirect({message: "Profile Photo saved", onClose: () => window.location.replace(props.nextStep) })
                        }
                        else {
                            SuccessRedirect({message: "Profile Photo updated", onClose: () => window.location.reload() })
                        }
                    }
                })
                .catch(error=>{
                    Error({message: "Error updating your account"})
                })
            
        }
        else {
            Error({message: "Error updating Profile Photo"})
            return
        }      
    }

    return (
        !props.loaded ?
            <CircularIndeterminate forceCenter />
        :
            <>
                <div className="mb-50">
                    <p className="mb-0">
                        Please add a profile photo.  This will be public facing so make sure it looks nice!
                    </p>
                </div>
                <ProfilePhotoUpload 
                    removeProfilePhoto={(event) => removeProfilePhoto(event, props.currentUser.updateProfilePhotoContext)} 
                    onChange={setprofilePhotoState} 
                    profilePhoto={props.currentUser?.user.profilePhoto} 
                    updateProfilePhoto={(event) => updateProfilePhoto(event, props.currentUser.updateProfilePhotoContext)} 
                    profilePhotoError={profilePhotoError}
                    openPopout={()=>{setIsCropUtilityOpen(true)}} 
                    isCropUtilityOpen={isCropUtilityOpen} 
                    handleCropUtilityClose={handleCropUtilityClose} 
                    role={props.currentUser?.user?.role}
                    isLoading={isLoading}
                    buttonText="Save and Continue"
                />
            </>
    )

}
import React from "react";
import BioForm from '../Account/bioForm';
import CircularIndeterminate from '../Loading/circularIndeterminant';

export default function Bio(props) {

    return (
        !props.loaded ?
            <CircularIndeterminate forceCenter />
        :
            <>
                <div className="mb-50">
                    <p className="mb-0">
                        This will be your short bio on your public artist page. Tell the world about yourself. Humbly brag about your accomplishments. Let the world know who you are, what you’ve done, and what inspires you to create. <a className="text-link-white text-white-500" href="http://help.deepsounds.com/en/articles/4828469-setting-up-your-artist-account%23h_2464287b44" target="_blank">Learn more about best practices for writing your bio</a>.<br/><br/>
                        You need a minimum of 170 characters with a max of 750.
                    </p>
                </div>
                <BioForm 
                    bio={props.bio}
                    nextStep={props.nextStep}
                    buttonText="Save and Continue" 
                />
            </>
    )

}
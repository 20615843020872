import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config";
const tw = resolveConfig(tailwindConfig);

// Styles for Single Select
const customStyles = {
    option: (provided, state) => {
        return {
            // ...provided,
            borderBottom: "1px solid hsla(0, 0%, 100%, .05)",
            backgroundColor: !state.isDisabled ? tw.theme.colors.gray[200] : tw.theme.colors.gray[300],
            padding: tw.theme.spacing[15] + " " + tw.theme.spacing[20],
            transition: "color 0.3s",
            cursor: state.isDisabled ? "not-allowed" : "pointer",
            color:
                state.isSelected || state.isFocused
                    ? tw.theme.colors.white || state.isDisabled
                    : tw.theme.colors.gray[400]
        };
    },
    control: (provided, state) => {
        return {
            ...provided,
            border: "none",
            backgroundColor: tw.theme.colors.gray[200],
            minHeight: tw.theme.spacing[60],
            borderRadius: "none",
            cursor: "pointer",
            boxShadow: 0
        };
    },
    menu: (provided, state) => {
        return {
            ...provided,
            marginTop: "0",
            marginBottom: "0",
            backgroundColor: tw.theme.colors.gray[200],
            position: "absolute",
            boxShadow: tw.theme.boxShadow.lg,
            borderRadius: "none",
            zIndex: 9999
        };
    },
    menuList: (provided, state) => {
        return {
            ...provided,
            "::-webkit-scrollbar": {
                width: "0.5rem !important"
            },
            "::-webkit-scrollbar-track": {
                backgroundColor: tw.theme.colors.gray[100] + " !important"
            },
            "::-webkit-scrollbar-thumb": {
                backgroundColor: tw.theme.colors.gray[300] + " !important",
                borderRadius: tw.theme.borderRadius.full
            }
        };
    },
    menuPortal: (base) => ({
      ...base,
      zIndex: 9999
    }),
    input: (provided, state) => {
        //
        return {
            // ...provided,
            color: tw.theme.colors.white,
            lineHeight: tw.theme.lineHeight.snug
        };
    },
    placeholder: (provided, state) => {
        return {
            ...provided,
            color: tw.theme.colors.gray[400],
            margin: "0",
            display: "flex"
        };
    },
    singleValue: (provided, state) => {
        return {
            ...provided,
            color: "white",
            margin: "0",
            lineHeight: tw.theme.lineHeight.snug
        };
    },
    valueContainer: (provided, state) => {
        //
        return {
            ...provided,
            alignSelf: "stretch",
            padding: tw.theme.spacing[10] + " " + tw.theme.spacing[20],
            backgroundColor: "#1c1c1c"
        };
    },
    dropdownIndicator: (provided, state) => {

        //
        return state?.selectProps?.dontShowDropI ? {
            display: "none"
        }: {
            ...provided,
            transition: "all .2s ease",
            transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
            color: tw.theme.colors.gray[500],
            cursor: "pointer",
            backgroundColor: "transparent",
            padding: "0 " + tw.theme.spacing[15],
            ":hover": { color: "white" }
        };
    },
    indicatorSeparator: (provided, state) => {
        return {
            ...provided,
            width: "0",
            height: "0"
        };
    },
    indicatorsContainer: (provided, state) => {
        //
        return {
            ...provided,
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        };
    }
};
export default customStyles;

import React, { useState } from "react"

export default function Question(props){
    const [isOpen, setIsOpen] = useState(false)
    const toggleOpen = ()=>{
        setIsOpen(!isOpen)
    }
    return(
    // {/* {# Accordion Item #} */}
    <div className={`accordion-item ${isOpen ? "active": ""}`}>

         {/* {# Accordion Header Group #} */}
        <div className="accordion-header-group">
            {/* {# Accordion Header #} */}
            <div className="accordion-header" onClick={toggleOpen}>
                {props.header}
            </div>
        </div>

        {/* {# Accordion Body #} */}
        <div className="accordion-body">
            <div className="accordion-body-content max-w-screen-lg text-h4">
                {props.body}

            </div>
        </div>
    </div>


    )
}

const buildBaseURL = () => {
  let baseURL = "";

  if (typeof window !== 'undefined') {
    baseURL = window.location.protocol + '//' + window.location.host;
  }

  return baseURL;
}
export default buildBaseURL;

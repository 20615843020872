import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
// import tippy from "../../tooltips"

class FavArtistCardTooltip extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {

            }

    render() {
        return (    
                <div className="dropdown-menu">
                    <div className="dropdown-group">
                        <NavLink to={`/artists/${this.props.artistURL}`}><span class="icon-user"></span>Artist Page</NavLink>   
                        <a href="# " onClick={this.props.shareArtist}><span class="icon-share"></span>Share Artist</a>   
                        <a href="# " onClick={()=>this.props.unfollowArtist(this.props.artistId, this.props.artistName)}><span class="icon-follow"></span>Unfollow Artist</a>   
                    </div>
                </div>
        )
    }

}
export default FavArtistCardTooltip
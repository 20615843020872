import React, { Component } from "react";
import { Error, Success } from "../Toast";
import api from "../utils/api";
import AddToCollection from "./addToCollection";

class AddToCollectionModal extends Component{

    state={}
    handleInputChange = (event, cb)=>{
        event.preventDefault()

        let {name, value} = event.target
        if(name === "newCollectionDescription"){
            if (value.length > 225){
                return
            }
        }
        cb(value)
        this.setState({[name]: value})
    }
    setCardArtState = (file, validateCB)=>{

        if(file.error){
            this.setState({cardArt: "File must be an image in a 1x1 ratio!"},()=> validateCB())
        }
        else{
            this.setState({
                cardArt: file.length > 0 ? file: null,
                cardArtPreview: file.length > 0 ? URL.createObjectURL(file[0]): null,
                cardArtError: null,
                cardArtUpdated: file.length > 0 ? true : false
            },()=> validateCB())
        }
    }
    removeCardArt =(event)=>{
        // event.preventDefault();
                    this.setState({
                        cardArt: null,
                        cardArtPreview: null,
                        cardArtError: null,
                        cardArtUpdated: false,
                        initialCardArt: null
                    })
    }
    setCoverArtState = (file, validateCB)=>{

        if(file.error){
            this.setState({coverArtError: "File must be an image in a 1x1 ratio!"})
        }
        else{
            this.setState({
                coverArt: file.length > 0 ? file: null,
                coverArtPreview: file.length > 0 ? URL.createObjectURL(file[0]): null,
                coverArtError: null,
                coverArtUpdated: file.length > 0 ? true : false
            }, ()=>validateCB())
        }
    }
    removeCoverArt =(event)=>{
        // event.preventDefault();
                    this.setState({
                        coverArt: null,
                        coverArtPreview: null,
                        coverArtError: null,
                        coverArtUpdated: false,
                        initialCoverArt: null
                    })
    }
    submitNewCollection = (trackId, cb)=>{
        if(!Array.isArray(this.state.cardArt) || !Array.isArray(this.state.coverArt)){

            if(!Array.isArray(this.state.cardArt)){
                this.setState({cardArtError: "Card art required"})
            }
            if(!Array.isArray(this.state.coverArt)){
                this.setState({coverArtError: "Cover art required"})
            }
            return
        }

        if(!this.state.newCollectionNameError && !this.state.newCollectionDescriptionError &&
            !this.state.cardArtError && !this.state.coverArtError){
                this.setState({isLoading: true}, ()=>{

                    let collectionBody = {
                        name: this.state.newCollectionName,
                        description: this.state.newCollectionDescription
                    }
                    api.addTrackToNewCollection(collectionBody, trackId)
                        .then(res=>{
                            if(res.data.success){
                                document.cookie = `latestCollectionValue=${res.data.collection.id}; path=/`
                                document.cookie = `latestCollectionLabel=${res.data.collection.name}; path=/`
                                const cardData = new FormData()
                                cardData.append('file', this.state.cardArt[0])
                                api.updateCollectionCardArt(res.data.collection.id, cardData)
                                    .then(response=>{
                                        if(response.data.success){
                                            const coverData = new FormData()
                                            coverData.append('file', this.state.coverArt[0])
                                            api.updateCollectionCoverArt(res.data.collection.id, coverData)
                                                .then(resp=>{
                                                    this.setState({isLoading: false})
                                                    if(resp.data.success){
                                                        Success({message: "Collection Created!"})

                                                        cb()
                                                    }
                                                    else{
                                                        Error({message: "Error uploading cover photo!"})
                                                    }
                                                })
                                                .catch(error=>{
                                                    this.setState({isLoading: false})

                                                    Error({message: "Error uploading cover photo!"})
                                                })
                                        }
                                        else{
                                            this.setState({isLoading: false})
                                            Error({message: "Error uploading card photo"})
                                        }
                                    })
                                    .catch(error=>{
                                        this.setState({isLoading: false})
                                        Error({message: "Error uploading card photo"})
                                    })
                            }
                            else{
                                this.setState({isLoading: false})
                                Error({message: "Error creating collection"})
                            }
                        })
                        .catch(error=>{
                            this.setState({isLoading: false})
                            if(error.response?.status === 406){
                                Error({message: "Collection name taken!"})
                                this.setState({newCollectionNameError: "Collection name taken"})
                            }
                            else{
                                Error({message: "Error creating collection"})
                            }
                        })
                })
            }
    }
    render(){
        return(
            <React.Fragment>
                {/* <div id="add_to_project_modal" aria-hidden="true" className="modal micromodal-slide"> */}

                    {/* {# Modal Overlay #} */}
                    {/* <div tabindex="-1" data-micromodal-close="add_to_project_modal" className="modal-overlay"> */}

                        {/* {# Modal Container #} */}
                            {/* <div role="dialog" aria-modal="true" aria-labelledby="add_scene_title" className="modal-container"> */}

                                {/* {# Modal Header #} */}
                                <header className="modal-header">
                                    <h2 id="add_scene_title" className="modal-title">Add Track to Collection</h2>
                                    <button aria-label="Close Modal" data-micromodal-close="add_to_project_modal" className="modal-close" type="button" onClick={this.props.handleClose}></button>
                                </header>

                                {/* {# Modal Content #} */}
                                <div id="add_scene_content" className="modal-content">
                                    <h6 className="track-title mb-30">{this.props.trackTitle}</h6>
                                    <div className="input-group-sm">
                                      <div class="grid-row-xs">
                                        <div class="col flex-1">

                                          <AddToCollection trackId={this.props.trackId} trackTitle={this.props.trackTitle} toggleTrackTippy={this.props.toggleTrackTippy} handleClose={this.props.handleClose} newCollection={this.state.newCollection}
                                          handleInputChange={this.handleInputChange} newCollectionName={this.state.newCollectionName} newCollectionDescription={this.state.newCollectionDescription}
                                          submitNewProject={this.submitNewProject} newCollectionNameError={this.state.newCollectionNameError} setCoverArtState={this.setCoverArtState}  coverArt={this.state.coverArt} coverArtPreview={this.state.coverArtPreview} coverArtError={this.state.coverArtError}
                                          removeCoverArt={this.removeCoverArt} setCardArtState={this.setCardArtState}  cardArt={this.state.cardArt} cardArtPreview={this.state.cardArtPreview} cardArtError={this.state.cardArtError}
                                          removeCardArt={this.removeCardArt} onSubmit={this.submitNewCollection} isLoading={this.state.isLoading} />
                                        </div>
                                        <div className="col flex-none">

                                          {this.state.newCollection ? (
                                            <>
                                              {/* {# New Project Button #} */}
                                                <button
                                                    type="button"
                                                    className="circle-icon-border circle-icon-lg"
                                                    onClick={(e)=>{e.preventDefault();this.setState({newCollection: false})}}
                                                >
                                                    <span className="icon-trash"></span>
                                                </button>
                                              {/* {# End New Project Button #} */}
                                            </>
                                          ) : (
                                            <>
                                              {/* {# New Project Button #} */}
                                                <button
                                                    type="button"
                                                    className="circle-icon-border circle-icon-lg"
                                                    onClick={(e)=>{e.preventDefault();this.setState({newCollection: true})}}
                                                >
                                                    <span className="icon-plus"></span>
                                                </button>
                                              {/* {# End New Project Button #} */}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                    </div>
                                </div>
                                {/* {# End Modal Content #} */}

                            {/* </div> */}
                        {/* {# End Modal Container #} */}
                    {/* </div> */}
                    {/* {# End Modal Overlay #} */}
                {/* </div> */}
            </React.Fragment>
        )
    }
}
export default AddToCollectionModal

import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import DefaultAvatar from "../../assets/svg/user-avatar.svg"

class AboutTab extends Component{
    resolveProfilePhoto=(profilePhotoKey)=>{
        const key = profilePhotoKey
        if(profilePhotoKey){
            const ext = key.split('.').reverse()[0];
            let final = key.replace('.' + ext, '') + '-minified-60x60'  + '.' + ext
            return final
        }
    }   
    render(){
        return(
            <div className="tab-item-content active" id="tab_about">
                <div className="grid-row-lg">
                    
                    {/* {# Fun Fact #} */}
                    {this.props.funFact &&
                        <div className="col w-full md:w-7/12 mb-30">
                            <h3 className="text-label-sm mb-5">Fun Fact</h3>
                            <div className="text-block">
                                <p className="text-white">{this.props.funFact}</p>
                            </div>
                        </div>
                    }

                    {/* {# Artists #} */}
                    <div className="col w-full md:w-5/12 mb-30">
                        <h3 className="text-label-sm mb-5">Performed By</h3>
                        <div className="grid-row-sm my-15">
                        <div className="col mb-15">
                                    <a href={"# "} className="flex items-center text-link-white">
                                        {this.props.artist.profilePhoto ? 
                                            <img src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveProfilePhoto(this.props.artist.profilePhoto)}`} alt="Artist Photo" className="w-30 h-30 rounded-full mr-10"/>:
                                            <img src={DefaultAvatar} alt="Artist Photo" className="w-30 h-30 rounded-full mr-10"/>
                                        }
                                        <span className="leading-snug">{this.props.artist.artistName}</span>
                                    </a>
                                </div>
                                {this.props.artists.map((artist=><div className="col mb-15">
                                    <NavLink to={artist.artistURL ? "/artists/" + artist.artistURL : "# "} className="flex items-center text-link-white">
                                        {artist.profilePhoto ? 
                                            <img src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveProfilePhoto(artist.profilePhoto)}`} alt="Artist Photo" className="w-30 h-30 rounded-full mr-10"/>:
                                            <img src={DefaultAvatar} alt="Artist Photo" className="w-30 h-30 rounded-full mr-10"/>
                                        }
                                        <span className="leading-snug">{artist.artistName || artist.name}</span>
                                    </NavLink>
                                </div>))}
                        </div>
                    </div>

                </div>
            </div>
    )

    }
}
export default AboutTab;
import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { UserConsumer } from "../../contexts/User";
import CircularIndeterminate from "../Loading/circularIndeterminant";
import api from "../utils/api";
import SignUpForm from './signUpForm';
import { Error } from "../Toast";
const queryString = require('query-string');

class SignUp extends Component {
    constructor(props) {
        super(props);
    }

    //the state for the login component keeps track fo the email and password inputs
    state = {
        firstName: "",
        lastName: "",
        email: "",
        token: "",
        password: "",
        passwordConfirm:"",
        redirectTo: null,
        loginMessage: "",
        isLoaded: false,
        submitting: false
    }

    componentDidMount = ()=> {
        //
        let params = queryString.parse(this.props.location.search);
        //
        if (params.invite) {
            api.getInvite(params.invite)
            .then(res => {
                if (res.data.invite?.userId) {
                   this.setState({
                        loginMessage: "This invite has already been used.",
                    })
                }
                else {
                    this.setState({
                        email: res.data.invite?.email,
                        token: res.data.invite?.token,
                        role: res.data.invite?.role // only using this to show Artist Terms
                    })
                }
            })
            .then(res => {
                this.setState({
                    isLoaded: true
                })
            })
            .catch(error => {
                console.log('error.response', error.response);
                const errorMessage = error.response?.data?.message || "An error occurred";
                Error({
                    message: errorMessage,
                    onClose: this.errorToast
                })
            })
        }
        else {
            this.setState({
                isLoaded: true
            })
        }
    }

    changeCallback=(state, fullObject)=>{

        if(state.isValid && this.state.passwordError){
            this.setState({passwordError: false})
        }
        this.setState({password: state.password, pwScore: state.score, pwValid: state.isValid, pwSuggestions: fullObject?.feedback?.suggestions || [], pwWarnings: fullObject?.feedback?.warning, fullObject: fullObject})
    }
    toggleLogin = (value) => {
        this.setState({ accountExists: value, loginMessage: "" })
    }


    render() {
        return (
            <UserConsumer>
                {({ user, login, logout})=>(
                    <>
                        <Helmet title="Sign Up - Deep Sounds | Music for Creators" />
                        <div className="hero-fixed">
                            <div className="hero-img" style={{backgroundImage: `url("${process.env.PUBLIC_URL}/images/hero/signup-03.jpg")`}}></div>
                        </div>
                        <div className="onboarding-main container container-xl">
                            {this.state.isLoaded === false ?
                                <div className="onboarding-form pb-100">
                                    <CircularIndeterminate forceCenter />
                                </div>
                            :
                                <>

                                    <h1 className="ds-title mb-30 lg:mb-40">Sign Up</h1>
                                    <div className="onboarding-form">
                                        <SignUpForm
                                            headerText={<p>Please complete the following fields:</p>}
                                            loaded={this.state.isLoaded}
                                            role={this.state.role}
                                            token={this.state.token}
                                            email={this.state.email}
                                            buttonText={
                                                this.state.role === 'artist' ?
                                                    "Agree & Continue"
                                                :
                                                    "Start Listening"
                                            }
                                            login={login}
                                            signUpLocation="signup"
                                            user={user}
                                        />

                                        <div className="text-gray-400 text-sm">
                                            Already have an account? <a href="/login" className="text-link-gray text-gray-500 py-3">Sign In.</a>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </>
                )}
            </UserConsumer>
        )
    }

}
export default SignUp

import React, { Component } from "react";
import 'react-tippy/dist/tippy.css';
import { UserConsumer } from "../../contexts/User";
import UserInviteForm from "./userInviteForm";


class InviteUsers extends Component {
    state = {}

    componentDidMount = ()=> {
        this.props.handleFormUnsaved(false)
    }

    componentDidCatch(error, info) {
        // if we have a promise then we can deal with it
        if(error instanceof Promise) {
        // we have a promise so lets update our state
        this.setState({ isLoading: true });

        // once the promise has resolved we can update our state again and grab the data
        error.then((data) => this.setState({ isLoading: false, data }));
    }
  }

    render() {

        return (
            <UserConsumer>
                {({ user, login, logout})=>(
                    <div class="w-full max-w-2xl mb-50 lg:mb-80">
                        <UserInviteForm />
                    </div>
                )}
            </UserConsumer>
        )
      }

}

export default InviteUsers

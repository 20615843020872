import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { PlayerContext } from "../../PlayerContext";
import { confirm } from "../confirmModal/index";
import { SuccessRedirect } from "../Toast/index";
import api from "../utils/api";
import Waveform from "../Waveform/trackListBrowse";

class ArtistTrackListItem extends Component {

	state = {
		showTrackTippy: false
    }

    toggleTrackTippy = ()=>{
        this.setState({showTrackTippy: !this.state.showTrackTippy})
    }
	resolveAlbumPhoto=(albumPhotoKey)=>{
        const key = albumPhotoKey
        if(albumPhotoKey){
            const ext = key.split('.').reverse()[0];
            let sizeMod;
            let width = window.innerWidth
            if(width >= 1280){
                sizeMod = '140x140'
            }
            else if(width >= 1024 && width < 1280){
                sizeMod = '140x140'
            }
            else if(width >= 760 && width < 1024){
                sizeMod = '140x140'
            }
            else{
                sizeMod = '140x140'
            }
            let final = key.replace('.' + ext, '') + '-minified-' + sizeMod + '.' + ext
            return final
        }
    }
    deleteTrack = async () => {
	    if(await confirm("Are you sure you want to remove this track? (This cannot be undone)", "Remove Track")){
	        api.removeTrack(this.props.trackId)
	        .then(res=>{
	            
	            if(res.data.success === true){
					this.props.removeTrackCB(this.props.trackId)
					SuccessRedirect({message: "Track Removed", onClose: this.successToastSubmit})
	            }
	        })
	    }
	    else{
	        return
	    }
	    
	}

	render(){
		return(
			<div className={'track-list-card is-unplayable'}>
	            {/* {# Left #} */}
	            <div className="track-first-col">
	                <div className="track-image" style={{backgroundImage: `url('https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveAlbumPhoto(this.props.track.imageURL)}')`}}></div>
	                <div className="track-title-artist">
	                    <NavLink to={"/dashboard/new-track/" + this.props.track.trackId}><span className="track-title">{this.props.track.trackTitle}</span></NavLink>
	                    <NavLink to={"/artists/" + this.props.track.artistURL}>
	                        <span className="track-artist">{this.props.track.artistName}</span>    
	                    </NavLink>
	                </div>
	            </div>
	    
	            {/* {# Middle #} */}
	            <div className="track-genres text-gray-400">
	                {this.props.track.tags &&
	                    this.props.track.tags.filter(tag=>tag.type ===1).map(genre=><span>{genre.name}</span>)
	                }
	            </div>
	    
	            {/* {# Right #} */}
	            <div className="track-last-col">
	                <div className="track-wave-time flex items-center">
	                    <span className={`icons-stems-versions text-gray-400 icon${this.props.track?.stems?.length ? "-stems":""}${this.props.track?.versions?.length ? "-versions":""}`}></span>
	                    {this.props.track.peaks ? 
	                        <Waveform peaks={this.props.track.peaks}></Waveform>
	                    :
	                        ""
	                    }
	                    <span className="text-gray-400">{this.props.track.duration}</span>
	                </div>
	                {/* {# Card Actions #} */}
	                <div className="track-list-icons text-gray-400">
	                    <Tooltip  
	                        arrow={false}
	                        open={this.state.showTrackTippy}
	                        interactive={true}
	                        position={'bottom-end'}
							popperOptions={{
                                modifiers: {
                                    preventOverflow: { enabled: false },
                                    flip: { enabled: false },
                                    hide: { enabled: false },
                                    addZIndex: {
                                        enabled: true,
                                        order: 810,
                                        fn: data => ({
                                            ...data,
                                            styles: {
                                            ...data.styles,
                                            zIndex: 10,
                                            },
                                        })
                                    }
                                 }
                            }}
							trigger={'click'}
							useContext={true}
	                        // hideOnClick={true}
	                        html={(
	                            <div className="dropdown-menu">
	                                <div className="dropdown-group">
	                                    <a href={`/dashboard/new-track/${this.props.track.trackId}`}><span className="icon-arrow-right"></span>View Track</a>
										<a href="#" onClick={this.deleteTrack}><span></span>Delete</a>
										<a href="/help" ><span ></span>Help</a>
	                                </div>
	                            </div>
	                        )}
	                        allowHTML={true}
	                        onUntrigger={()=>{this.state.showTrackTippy ? this.setState({showTrackTippy: false}): this.setState({showTrackTippy: true})}}
	                        onRequestClose={()=>{this.state.showTrackTippy ? this.setState({showTrackTippy: false}): this.setState({showTrackTippy: true})}}
	                    >  
	                    <button 
	                        id="trackToolTip"
	                        className="track-list-more"
	                        onClick={this.toggleTrackTippy}
	                        data-tippy-html="#trackToolTip"
	                        data-tippy-placement="bottom-end"
	                        type="button"
	                    >
	                        <span className="icon-dots-vert"></span>
	                    </button>
	                </Tooltip>
	                </div>
	                {/* {# End Card Actions #} */}
	            </div>
	    
	        </div>
		)
    }
}                        


ArtistTrackListItem.contextType = PlayerContext
export default ArtistTrackListItem
import React from "react"

export const LabelRequired = (props) => {
    return(
        <label className="input-label" htmlFor={props.for} style={props.style}>
            { props.children}
            <span className="input-label-required">*</span>
        </label>
    )
}
export const LabelRequiredVisible = (props) => {
    return(
        <label 
            className="input-label" 
            htmlFor={props.for} 
            style={{
                top: "0.75rem",
                visibility: "visible",
                opacity: 1
                }}
        >
            { props.children}
            <span className="input-label-required">*</span>
        </label>
    )
}

export const LabelOptionalVisible = (props) => {
    return(
        <label 
            className="input-label" 
            htmlFor={props.for} 
            style={{
                top: "0.75rem",
                visibility: "visible",
                opacity: 1
                }}
        >
            { props.children}
        </label>
    )
}





import React, { PureComponent } from "react";
import ReactCrop from 'react-image-crop';
import "react-image-crop/dist/ReactCrop.css";

class ImageCropUtility extends PureComponent{
    state={
    crop: {
        aspect: 1/1,
       }
    }
    componentDidMount= ()=>{
        const reader = new FileReader();
     

        reader.addEventListener("load", () =>
        this.setState({ src: reader.result })
      );
      reader.readAsDataURL(this.props.file);
    }
  
    onChange = (crop, pxCrop) => {
        
        
        this.setState({ crop, pxCrop });
      }
      async makeClientCrop(crop) {
        if (this.imageRef && crop.width && crop.height) {
          const {croppedImageUrl, fullBlob} = await this.getCroppedImg(
            this.imageRef,
            crop,
            "newFile.jpeg"
          );
          this.setState({ croppedImageUrl, fullBlob },
            ()=>this.props.updateBlobbedFile(this.state.fullBlob)
          );
        }
      }
      onCropComplete = (crop) => {
          this.makeClientCrop(crop);
        };
        onImageLoaded = (image) => {
            
            this.imageRef = image;
            setTimeout(()=>
            this.setState({crop: {
                aspect : 1/1,
                height:  this.imageRef.height *(this.props.user?.artist ? 1000/this.imageRef.naturalHeight :  500/this.imageRef.naturalHeight)
            }}), 1000)
          };
      getCroppedImg(image, crop, fileName) {
        
        const canvas = document.createElement("canvas");
        const scaleX = image.naturalWidth / image.width;
        
        const scaleY = image.naturalHeight / image.height;
        canvas.width = image.naturalWidth * (this.state.pxCrop?.width /100) ;
        canvas.height =  image.naturalHeight * (this.state.pxCrop?.height /100);
        const ctx = canvas.getContext("2d");
    
        ctx.drawImage(
          image,
          crop.x * scaleX,
          crop.y * scaleY,
          crop.width * scaleX,
          crop.height * scaleY,
          0,
          0,
          Math.ceil(image.naturalWidth * (this.state.pxCrop?.width /100) ),
          Math.ceil(image.naturalWidth * (this.state.pxCrop?.width /100) )
        );
    
        return new Promise((resolve, reject) => {
          canvas.toBlob((blob) => {
            if (!blob) {
              //reject(new Error('Canvas is empty'));
              console.error("Canvas is empty");
              return;
            }
            blob.name = fileName;
            window.URL.revokeObjectURL(this.fileUrl);
            this.fileUrl = window.URL.createObjectURL(blob);
            resolve({croppedImageUrl: this.fileUrl, fullBlob: blob});
          }, "image/jpeg");
        });
      }
    render(){
        return(
            <div className={"flex justify-center"}>
                {this.state.src &&
                <ReactCrop imageStyle={{maxHeight: "50vh", width: "auto"}} minHeight={this.imageRef ? this.imageRef.height *(this.props.user?.artist ? 1000/this.imageRef.naturalHeight :  500/this.imageRef.naturalHeight) : 1} src={this.state.src} onChange={this.onChange} crop={this.state.crop}  
                onComplete={this.onCropComplete} onImageLoaded={this.onImageLoaded} circularCrop={this.props.isProfilePhoto} keepSelection={true}
/>}
{/* {this.state.croppedImageUrl && (
          <img  className={""}  alt="Crop" style={{ maxWidth: "100%" }} src={this.state.croppedImageUrl} />
        )} */}
            </div>
        )
    }
}
export default ImageCropUtility
import React, { Component } from "react";
import api from "../utils/api";
import { Error } from "../Toast";
// import tippy from "../../tooltips"

class CardOptionsToolTip extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {
                email: "",
                oldPassword: "",
                newPassword: "",
                newPasswordConfirm:"",
                loginMessage: ""
            }
    //updating our input fields as the user enters keys
    handleInputChange = event => {   
    };

    removePaymentMethod = ()=>{
        api.removePaymentMethod(this.props.paymentId)
            .then(res => {
                console.log('remove res', res)
                if(res.status === 200) {
                    window.location.reload();
                }
            })
            .catch(error => {
                Error({
                    message: error.response?.data?.message
                });
            });
    }

    makePrimary = ()=>{
        api.makePrimary(this.props.paymentId)
            .then(res=>{
                
                if(res.data.success){
                    window.location.reload()
                }
            })
        
    }

    render() {
        return (
                
                        <div className="" id={"card_options"}>
                                <div className="dropdown-menu">
                                    {!this.props.primary && (
                                        <div className="dropdown-group">
                                            <a href="# " onClick={this.makePrimary}>Make Primary</a>
                                        </div>
                                    )}
                                    {this.props.removable && (
                                        <div className="dropdown-group">
                                            <a href="# " onClick={this.removePaymentMethod}><span className="icon-trash"></span>Remove</a>
                                        </div>
                                    )}
                                </div>
                            </div>
        )
    }

}
export default CardOptionsToolTip
import React from "react";
import { DateTime } from "luxon";
import { Tooltip } from "react-tippy";
import currencyFormatter from "../../../utils/currencyFormatter";

const SalesTransfersRow = ({ fromName, date, transferId, status, description, payoutAmount }) => {
  const ISOString = new Date(date).toISOString();
  const payedOnFormatted = DateTime.fromISO(ISOString).toFormat("yyyy.MM.dd");
  return (
    <tr>
      <td>{payedOnFormatted}</td>
      <td>{transferId}</td>
      <td>{fromName}</td>
      <td>{description}</td>
      <td>{status}</td>
      <td className="text-right">{currencyFormatter(payoutAmount / 100)}</td>
    </tr>
  );
};

export default SalesTransfersRow;

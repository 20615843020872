import React, { Component } from "react"
import MicroModal from "react-micro-modal"
import { NavLink } from 'react-router-dom'
import { Tooltip } from "react-tippy"
import wav1 from '../../assets/placeholders/wav-01.png'
import wav2 from '../../assets/placeholders/wav-02.png'
import wav3 from '../../assets/placeholders/wav-03.png'
import wav4 from '../../assets/placeholders/wav-04.png'
import wav5 from '../../assets/placeholders/wav-05.png'
import wav6 from '../../assets/placeholders/wav-06.png'
import DefaultAvatar from "../../assets/svg/user-avatar.svg"
import { ProjectsContext } from "../../ProjectsContext/index"
import { confirm } from "../confirmModal/index"
import { Error, Success } from "../Toast/index"
import api from "../utils/api"
import EditProjectModal from "./editProjectModal"
import ManageCollaboratorsModal from "./manageCollaboratorsModal"
import ProjectTooltip from "./projectTooltip"
import ShareProjectModal from "./shareProjectModal"
const wavList = [wav1, wav2, wav3, wav4, wav5, wav6]

class ProjectSummaryHeader extends Component {
    state ={
        isProjectEditModalOpen: false,
        isShareModalOpen: false,
        editedProjectType: {label: this.props.projectType?.name, value: this.props.projectType?.id},
        editedProjectName: this.props.name,
        editedClientName: this.props.client,
        coverArt: this.props.coverArt,
        defaultArt: this.props.defaultArt,
        initialCoverArt: this.props.coverArt,
        isManageCollaboratorsModalOpen: false,
        isCropUtilityOpen: false,
        randomWavIndex: Math.round(Math.random() *5),
        // defaultArt: 
    }
    toggleProjectTippy = ()=>{
        this.setState({isProjectTippyOpen: !this.state.isProjectTippyOpen})
    }
    closeProjectTippy = ()=>{
        this.setState({isProjectTippyOpen: false})
    }
    handleProjectEditModalOpen = ()=>{
        this.setState({isProjectEditModalOpen: true, isProjectTippyOpen: false})
    }
    handleShareModalOpen = ()=>{
        if((this.props.projectLink === null || this.props.projectLink === undefined)&& this.props.isOwner){
            this.props.generateProjectLink(this.props.projectId)
        }
        this.setState({isShareModalOpen: true, isProjectDetailTippyOpen: false})
    }
    handleShareModalClose = ()=>{
        this.props.handleFormUnsaved(false)
        this.setState({isShareModalOpen: false})
    }
    handleManageCollaboratorsModalOpen = ()=>{
        this.setState({isManageCollaboratorsModalOpen: true,isProjectTippyOpen: false})
    }
    handleManageCollaboratorsModalClose = ()=>{
        if(!this.props.complete){
            this.props.handleInputChange({target: {value: "", name: "collaboratorEmail"}})
            this.props.handleFormUnsaved(false)

        }
        this.setState({isManageCollaboratorsModalOpen: false})
    }
    handleProjectEditModalClose=()=>{
        
        this.props.handleFormUnsaved(false)
        this.setState({isProjectEditModalOpen: false, editedProjectType: {label: this.props.projectType?.name, value: this.props.projectType?.id},
            editedProjectName: this.props.name,
            editedClientName: this.props.client,
            coverArt: this.props.coverArt,
            defaultArt: this.props.defaultArt,
            initialCoverArt: this.props.coverArt})
    }
    handleInputChange = (event)=>{
        this.props.handleFormUnsaved(true)
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    }
    handleProjectTypeChange= (selectedType)=>{
        this.setState({editedProjectType: selectedType})
    }
    setCoverArtState = (file)=>{
        
        if(file.error){
            this.setState({coverArtError: "File must be at least 250x250"})
        }
        else{
            this.setState({
                coverArt: file.length > 0 ? file: null,
                coverArtPreview: file.length > 0 ? URL.createObjectURL(file[0]): null,
                coverArtError: null,
                coverArtUpdated: file.length > 0 ? true : false
            })
        }
      
    }
    setRealCoverArtState = (file)=>{
        
        if(file.error){
            this.setState({realCoverArtError: "File must be an image in a 1x1 ratio!"})
        }
        else{
            this.setState({
                realCoverArt: file.length > 0 ? file: null,
                realCoverArtPreview: file.length > 0 ? URL.createObjectURL(file[0]): null,
                realCoverArtError: null,
                realCoverArtUpdated: file.length > 0 ? true : false
            })
        }
      
    }
    checkIfProjectUsed = (value)=>{
        let existingProjectNames = this.context.myProjects.filter(project=>project.id !== this.props.projectId).map(project=>project.name)
        
        let isNameTaken = existingProjectNames.filter(name=>name?.toUpperCase()===value?.toUpperCase()).length > 0
        if(isNameTaken){
            return "Project name already used"
        }
        else{
            return true
        }
    }
    removeCoverArt =(event)=>{
        // event.preventDefault();
        api.removeProjectArt(this.props.projectId)
            .then(res=>{
                
                if(res.data.success){
                    Success({message: "Cover Art Removed!"})
                    this.setState({
                        coverArt: null,
                        coverArtPreview: null,
                        coverArtError: null,
                        coverArtUpdated: false,
                        initialCoverArt: null
                    },()=>{
                        this.context.updateProjectArt(this.props.projectId, res.data.project?.coverArt, false)
                    })
                }
            })
    }
    removeRealCoverArt =(event)=>{
        // event.preventDefault();
        api.removeProjectCoverArt(this.props.projectId)
            .then(res=>{
                
                if(res.data.success){
                    Success({message: "Cover Art Removed!"})
                    this.setState({
                        realCoverArt: null,
                        realCoverArtPreview: null,
                        realCoverArtError: null,
                        realCoverArtUpdated: false,
                        initialRealCoverArt: null
                    })
                }
            })
    }
    updateProject = async (trigger, errors, cb)=>{
        
        let triggerRes = await trigger()
        
        
        let projectBody ={
            name: this.state.editedProjectName,
            client: this.state.editedClientName,
            projectType: this.state.editedProjectType.value
        }
        if(this.state.editedProjectName.toUpperCase() === this.props.name.toUpperCase()){
            delete projectBody.name
        }

        if(triggerRes){
            this.setState({isLoading: true}, ()=>{
                if(this.state.coverArtUpdated && this.state.realCoverArtUpdated){
                    const data = new FormData()
                    data.append('file', this.state.coverArt[0])
                    api.updateProjectArt(this.props.projectId, data)
                        .then(res=>{
                            const coverData = new FormData()
                            coverData.append('file', this.state.realCoverArt[0])
                            api.updateProjectCoverArt(this.props.projectId, coverData)
                                .then(res=>{
                                    if(res.data.success === true){
                                        api.updateProjectById(this.props.projectId, projectBody)
                                        .then(respo=>{
                                            this.setState({isLoading: false})
                                            if(respo.data.success){
                                                this.context.updateProjectInfo(this.props.projectId, projectBody.name || this.props.name,  projectBody.client, "",  projectBody.projectType )
                                                cb()
                                                window.location.reload()
                                                // SuccessRedirect({message: "Project Updated!", onClose: ()=> window.location.reload()})
                                            }
                                            else{
                                                Error({message:"An error occurred!", onClose: this.errorToast})
                                            }                
                                        })
                                        .catch(error=>{
                                            this.setState({isLoading: false})
                                            if(error.response.status === 406){
                                                Error({message:"Project name already used", onClose: this.errorToast})
                                            }
                                            else{
                                                Error({message:"An error occurred!", onClose: this.errorToast})
                                            }
                                    
                                        })
                                    }
                                    else{
                                        this.setState({isLoading: false})
                                        Error({message:"An error occurred!", onClose: this.errorToast})

                                    }
                                })
                        })
                        .catch(error=>{
                            this.setState({isLoading: false})
                            
                            Error({message:"An error occurred!", onClose: this.errorToast})
                        })
                }
                else if(this.state.coverArtUpdated){
                    const data = new FormData()
                    data.append('file', this.state.coverArt[0])
                    api.updateProjectArt(this.props.projectId, data)
                        .then(res=>{
                            if(res.data.success === true){
                                this.context.updateProjectArt(this.props.projectId, res.data.project?.coverArt, true)
                                api.updateProjectById(this.props.projectId, projectBody)
                                .then(respo=>{
                                    this.setState({isLoading: false})
                                    if(respo.data.success){
                                        this.context.updateProjectInfo(this.props.projectId, projectBody.name || this.props.name,  projectBody.client, "",  projectBody.projectType )
                                        cb()
                                        window.location.reload()
                                        // SuccessRedirect({message: "Project Updated!", onClose: ()=> window.location.reload()})
                                    }
                                    else{
                                        Error({message:"An error occurred!", onClose: this.errorToast})
                                    }                
                                })
                                .catch(error=>{
                                    this.setState({isLoading: false})
                                    if(error.response.status === 406){
                                        Error({message:"Project name already used", onClose: this.errorToast})
                                    }
                                    else{
                                        Error({message:"An error occurred!", onClose: this.errorToast})
                                    }
                            
                                })
                            }
                            else{
                                this.setState({isLoading: false})
                                Error({message:"An error occurred!", onClose: this.errorToast})

                            }
                        })
                        .catch(error=>{
                            this.setState({isLoading: false})
                            
                            Error({message:"An error occurred!", onClose: this.errorToast})
                        })
                }
                else if(this.state.realCoverArtUpdated){
                    const data = new FormData()
                    data.append('file', this.state.realCoverArt[0])
                    api.updateProjectCoverArt(this.props.projectId, data)
                        .then(res=>{
                            if(res.data.success === true){
                                api.updateProjectById(this.props.projectId, projectBody)
                                .then(respo=>{
                                    this.setState({isLoading: false})
                                    if(respo.data.success){
                                        this.context.updateProjectInfo(this.props.projectId, projectBody.name || this.props.name,  projectBody.client, "",  projectBody.projectType )
                                        cb()
                                        window.location.reload()
                                        // SuccessRedirect({message: "Project Updated!", onClose: ()=> window.location.reload()})
                                    }
                                    else{
                                        Error({message:"An error occurred!", onClose: this.errorToast})
                                    }                
                                })
                                .catch(error=>{
                                    this.setState({isLoading: false})
                                    if(error.response.status === 406){
                                        Error({message:"Project name already used", onClose: this.errorToast})
                                    }
                                    else{
                                        Error({message:"An error occurred!", onClose: this.errorToast})
                                    }
                            
                                })
                            }
                            else{
                                this.setState({isLoading: false})
                                Error({message:"An error occurred!", onClose: this.errorToast})

                            }
                        })
                        .catch(error=>{
                            this.setState({isLoading: false})
                            
                            Error({message:"An error occurred!", onClose: this.errorToast})
                        })
                }
                else{
                    api.updateProjectById(this.props.projectId, projectBody)
                    .then(res=>{
                        this.setState({isLoading: false})
                        if(res.data.success){
                            this.context.updateProjectInfo(this.props.projectId, projectBody.name || this.props.name,  projectBody.client, "",  projectBody.projectType )
                            this.props.handleFormUnsaved(false)
                            Success({message: "Project Updated!"})
                            // this.props.updateLatest(this.props.projectId)
                            let name = this.state.editedProjectName || this.props.name
                            let client = this.state.editedClientName || this.props.client
                            let projectType = this.state.editedProjectType || this.props.projectType
                            // this.setState({project: project})
                            this.props.updateParent(name, client, projectType, this.props.projectId)
                            cb()
                        }
                        else{
                            Error({message:"An error occurred!", onClose: this.errorToast})
                        }                
                    })
                    .catch(error=>{
                        this.setState({isLoading: false})
                        if(error.response.status === 406){
                            Error({message:"Project name already used", onClose: this.errorToast})
                        }
                        else{
                            Error({message:"An error occurred!", onClose: this.errorToast})
                        }
                
                    })
                }
            })
        }

    }
    markComplete =()=>{
        this.closeProjectTippy()
        this.setState({isProjectDetailTippyOpen: false}, ()=>
            api.markProjectComplete(this.props.projectId)
                .then(res=>{
                    
                    if(res.data.success){
                        Success({message: "Project Marked as Complete"})
                        this.context.removeFromList(this.props.projectId)
                        this.props.markCompleteCB(this.props.projectId)
                    }
                })
        )
    }  
    markIncomplete =()=>{
        this.closeProjectTippy()
        this.setState({isProjectDetailTippyOpen: false}, ()=>
            api.markProjectIncomplete(this.props.projectId)
                .then(res=>{
                    
                    if(res.data.success){
                        Success({message: "Project Marked as Active"})
                        this.props.markIncompleteCB(this.props.projectId)
                    }
                })
        )
    }
    
    duplicateProject = ()=>{
        this.closeProjectTippy()
        this.setState({isProjectDetailTippyOpen: false});
        api.duplicateProject(this.props.projectId)
            .then(res=>{
                
                if(res.data.success){
                    setTimeout(window.location.replace("/dashboard/projects/edit/"+res.data.duplicatedProject.id), 1000)
                    // this.props.history.push("/dashboard/projects/edit/"+res.data.duplicatedProject.id)
                    // SuccessRedirect({message: `${this.state.project.name} Duplicated!`, onClose: ()=>{this.props.history.push("/dashboard/projects/edit/"+res.data.duplicatedProject.id)}})
                }
                else{
                    Error({message: "An Error Occured!"})
                }
            })
            .catch(err=>{
                
                Error({message: "An Error Occured!"})
            })
    }
    leaveProject = async ()=>{
        this.closeProjectTippy()
        if(await confirm("Are you sure you want to leave this project? You will not have access to it unless you are invited back", "Leave Project", true)){
            api.leaveProject(this.props.projectId)
                .then(res=>{
                    
                    if(res.data.success){
                        // SuccessRedirect({message: "Left Project!", onClose: ()=>{this.props.history.push("/dashboard/projects/all")}})
                        this.context.removeFromList(this.props.projectId)
                        this.props.leaveProjectCB(this.props.projectId)
                        Success({message: `Left ${this.props.name}`})
                    }
                    else{
                        Error({message: "There was an error"})
                    }
                })
                .catch(error=>{
                    Error({message: "There was an error"})
                })
        }
    }
    deleteProject = async ()=>{
        this.closeProjectTippy()
        if(await confirm("Are you sure you want to delete this project? You will no longer have access to it after this is done", "Delete Project", true)){
            api.deleteProject(this.props.projectId)
                .then(res=>{
                    
                    if(res.data.success){
                        this.context.removeFromList(this.props.projectId)
                        this.props.deleteCB(this.props.projectId)
                        Success({message: `${this.props.name} was deleted`})
                    }
                    else{
                        Error({message: "There was an error"})
                    }
                })
                .catch(error=>{
                    if(error?.response?.status === 401){
                        Error({message: "You cannot delete a project with an associated purchase"})
                    }
                    else if (error?.response?.status === 403){
                        Error({message: error?.response.data})
                    }
                    else{
                        Error({message: "There was an error"})
                    }
                })
        }
    }    
    resolveProfilePhoto=(profilePhotoKey)=>{
        const key = profilePhotoKey
        if(profilePhotoKey){
            const ext = key.split('.').reverse()[0];
            let final = key.replace('.' + ext, '') + '-minified-60x60'  + '.' + ext
            return final
        }
    }   
    handleCropUtilityClose = (cropSaved)=>{
        this.setState({isCropUtilityOpen: false})
        
        if(cropSaved){
            cropSaved()
        }
        else{
        }
    }
    render() {
        return (
            <div class="project-header">

                <h3>
                    <NavLink to={"/dashboard/projects/edit/"+this.props.projectId}>{this.props.name}</NavLink>
                </h3>
                <div class="heading-icon-section">
                    {/* {# Share Project #} */}
                    {((this.props.projectLink && this.props.linkEnabled === "active" )|| this.props.isOwner) ?
                    <>
                        <button onClick={this.handleShareModalOpen} class="circle-icon-border icon-share hidden md:flex" data-micromodal-trigger="share_project_modal">
                            <span class=""></span>
                        </button>
                        <MicroModal
                            open={this.state.isShareModalOpen}
                            closeOnEscapePress={true}
                            closeOnOverlayClick={true}
                            closeOnAnimationEnd={true}
                            handleClose={()=>this.handleShareModalClose()}
                            modalOverlayClassName="modal-overlay"
                            modalClassName="modal micromodal-slide is-open"
                            modalOverlayStyles={null}
                            children={handleClose => <ShareProjectModal handleClose={handleClose} isOwner={this.props.isOwner} projectLink={this.props.projectLink} projectLinkId={this.props.projectLinkId} linkEnabled={this.props.linkEnabled} enableLink={this.props.enableLink} passwordEnabled={this.props.passwordEnabled} enablePassword={this.props.enablePassword}
                            updateProjectLink={this.props.updateProjectLink} updatePassword={this.props.updatePassword} password={this.props.projectPassword} projectLinkId={this.props.projectLinkId} clearPW={this.props.clearPW} pwBlur={this.props.pwBlur} projectId={this.props.projectId} handleManageCollaboratorsModalOpen={this.handleManageCollaboratorsModalOpen} />}
                            containerStyles={{ background: "#131313", padding: "asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box" }}
                        />
                    </>
                    :
                    <></>
                    }


                    {/* {# Users #} */}
                    <div class="users-block">

                    {this.props.activeCollabs?.length < 5 ? 
                                this.props.activeCollabs?.slice(0,4).map(collaborator=> <div class="user-block-image-container">
                                    {collaborator.profilePhoto ? 
                                        <div class="user-image js-tooltip" title={(collaborator.firstName) + " " + (collaborator.lastName)} style={{backgroundImage: `url(https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveProfilePhoto(collaborator.profilePhoto)})`}}></div>:
                                        <div class="user-image js-tooltip" title={(collaborator.firstName) + " " + (collaborator.lastName)} style={{backgroundImage: `url(${DefaultAvatar})`}}></div>}
                                    </div> )
                                        :
                                this.props.activeCollabs?.slice(0,3).map(collaborator=> <div class="user-block-image-container">
                                    {collaborator?.profilePhoto ? 
                                        <div class="user-image js-tooltip" title={(collaborator?.firstName) + " " + (collaborator?.lastName)} style={{backgroundImage: `url(https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveProfilePhoto(collaborator.profilePhoto)})`}}></div>:
                                        <div class="user-image js-tooltip" title={(collaborator?.firstName) + " " + (collaborator?.lastName)} style={{backgroundImage: `url(${DefaultAvatar})`}}></div>}
                                    </div> )
                            }
                        
                            {/* {% endfor %} */}

                            {/* {# Additional Users Indicator #} */}
                            {this.props.activeCollabs?.length > 4 &&  <span class="circle-icon-fill bg-gray-300 text-gray-500 additional-users">+{this.props.activeCollabs?.length -3}</span>}

                        {/* {# Manage Collaborators #}  */}
                        <button onClick={this.handleManageCollaboratorsModalOpen} class="circle-icon-border" data-micromodal-trigger="collaborators_modal">
                                <span class="icon-follow"></span>
                            </button>
                        <MicroModal
                            open ={this.state.isManageCollaboratorsModalOpen}
                            handleClose={this.handleManageCollaboratorsModalClose}
                            closeOnEscapePress={true}
                            closeOnOverlayClick={true}
                            closeOnAnimationEnd={true}
                            modalOverlayClassName="modal-overlay"
                            modalClassName="modal micromodal-slide is-open"
                            modalOverlayStyles={null}
                            children={handleClose => <ManageCollaboratorsModal handleClose={handleClose} collaborators={this.props.collaborators} projectId={this.props.projectId} projectName={this.props.name} 
                                removeCollaborator={this.props.removeCollaborator} addCollaboratorToState={this.props.addCollaboratorToState}isOwner={this.props.isOwner} projectTypes={this.props.projectTypes} 
                                projectLink={this.props.projectLink} linkEnabled={this.props.linkEnabled} handleShareModalOpen={()=>{handleClose();this.handleShareModalOpen();}}
                                collaboratorEmail={this.props.collaboratorEmail} handleInputChange={this.props.handleInputChange} filteredCollaborators={this.props.filteredCollaborators} onKeyDown={this.props.onKeyDown} activeOption={this.props.activeOption}
                                onClick={this.props.onClick} updateLatest={this.props.updateLatest} handleFormUnsaved={this.props.handleFormUnsaved} owner={{email:this.props.ownerEmail,name:this.props.ownerName, ownerPhoto: this.props.ownerPhoto }} complete={this.props.complete}/>}
                            containerStyles={{ background: "#131313", padding: "asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box" }}
                        />

                            <MicroModal
                                        open={this.state.isProjectEditModalOpen}
                                        closeOnEscapePress={true}
                                        handleClose={()=>this.handleProjectEditModalClose()}
                                        closeOnOverlayClick={true}
                                        closeOnAnimationEnd={true}
                                        modalOverlayClassName="modal-overlay"
                                        modalClassName="modal micromodal-slide is-open"
                                        modalOverlayStyles={null}
                                        children={handleClose=><EditProjectModal handleClose={handleClose} handleInputChange={this.handleInputChange} editedProjectName={this.state.editedProjectName}  
                                        editedClientName={this.state.editedClientName} editedProjectType={this.state.editedProjectType} handleProjectTypeChange={this.handleProjectTypeChange} updateProject={this.updateProject}
                                        projectTypes={this.props.projectTypes} setCoverArtState={this.setCoverArtState}  coverArt={this.state.coverArt} setRealCoverArtState={this.setRealCoverArtState}  realCoverArt={this.state.realCoverArt} coverArtPreview={this.state.coverArtPreview} coverArtError={this.state.coverArtError}
                                        realCoverArtPreview={this.state.realCoverArtPreview} realCoverArtError={this.state.realCoverArtError} removeCoverArt={this.removeRealCoverArt}
                                        removeCoverArt={this.removeCoverArt} defaultArt={this.state.defaultArt} initialCoverArt={this.state.coverArt} initialRealCoverArt={this.state.realCoverArt} checkIfProjectUsed={this.checkIfProjectUsed}
                                        openPopout={()=>{this.setState({isCropUtilityOpen: true});}} isCropUtilityOpen={this.state.isCropUtilityOpen} handleCropUtilityClose={this.handleCropUtilityClose} isLoading={this.state.isLoading} userSet={this.props.userSet} wavPlaceholder={wavList[this.state.randomWavIndex]}
                                        />}
                                        containerStyles={{background: "#131313", padding:"asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box"}}
                            ></MicroModal>
                    </div>

                    {/* {# More Menu #} */}
                    <Tooltip
                        useContext={true}
                        arrow={false}
                        open={this.state.isProjectTippyOpen}
                        interactive={true}
                        position={'bottom-end'}
                        trigger={'click'}
                        // hideOnClick={true}
                        useContext={true}
                        html={(<ProjectTooltip 
                                    markComplete={this.markComplete}
                                    markIncomplete={this.markIncomplete}
                                    handleProjectEditModalOpen={this.handleProjectEditModalOpen}
                                    complete={this.props.complete}
                                    artistBrowse={this.props.artistBrowse}
                                    artistTrackList={this.props.artistTrackList}
                                    trackStatus={this.props.trackStatus}
                                    duplicateProject={this.duplicateProject}
                                    leaveProject={this.leaveProject}
                                    isOwner={this.props.isOwner}
                                    purchaseId={this.props.purchaseId}
                                    deleteProject={this.deleteProject}
                                    projectId={this.props.projectId}
                                    projectLink={this.props.projectLink} 
                                    linkEnabled={this.props.linkEnabled}
                                    handleManageCollaboratorsModalOpen={this.handleManageCollaboratorsModalOpen}
                                    handleShareModalOpen={this.handleShareModalOpen}
                                >
                            </ProjectTooltip>
                        )}
                        allowHTML={true}
                        onUntrigger={this.toggleProjectTippy}
                        onRequestClose={this.toggleProjectTippy}
                    >
                        <button class="circle-icon js-dropdown-toggle"
                            id="project_edit_options"
                            onClick={this.toggleProjectTippy}
                            data-tippy-html="#project_edit_options"
                            data-tippy-placement="bottom-end"
                            type="button"
                        >
                            <span class="icon-dots-vert"></span>
                        </button>
                    </Tooltip>

                </div>

            </div>
        )
    }
}
ProjectSummaryHeader.contextType = ProjectsContext
export default ProjectSummaryHeader;
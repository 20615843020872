import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { PlayerContext } from "../../PlayerContext";
class AlbumCard extends Component{
    state={

    }
    resolvePhotoLink=(albumPhotoKey)=>{
        
        const key = albumPhotoKey
        const ext = key.split('.').reverse()[0];
        let final = key.replace('.' + ext, '') + '-minified-500x500.' + ext
        return final
    }
    scrollToAlbum = (e)=>{
        const albumEl = document.getElementById('album-'+this.props.albumTitle.replace(/\s/g, "-"))
        albumEl.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
        // document.querySelector(".app-main").scrollTo({top: (albumEl.offsetTop -80), left: albumEl.offsetLeft, behavior: "smooth"})
        this.props.history.push('#album-'+ this.props.albumTitle.replace(/\s/g, "-"))
    }
    render(){
        return(
            <div onClick={(e)=>this.scrollToAlbum(e)} className="col w-1/2 lg:w-1/3 xl:w-1/4 mb-20 lg:mb-40">
                <div className="artwork-card-block">
                    <div className="artwork-card mb-15 lg:mb-20">
                        <div className="artwork-card-image">
                            <img src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolvePhotoLink(this.props.artWork)}`} alt="albumArt"/>
                            <div className="artwork-card-hover">
                                <div className="circle-icon-fill bg-white text-black circle-icon-lg">
                                    {(this.context.mediaPlayer.idKey?.indexOf('albumTrackItem-'+this.props.albumId) || -1)!==-1 && (this.context.mediaPlayer.isTrackPlaying || this.context.isVideoPlaying) ? 
                                        <span onClick={this.context.handlePause} className="icon-pause"></span> :
                                        <span onClick={(e)=>this.props.playAlbum(e, this.props.albumId, this.props.albumTitle)} className="icon-play"></span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="block leading-snug">
                        <div className="text-link-white text-base lg:text-h4 md:pr-40 mb-5">
                            <div  onClick={(e)=>this.scrollToAlbum(e)}>{this.props.albumTitle}</div>
                        </div>
                        {/* <div className="text-sm text-gray-400">{{ item.year }}</div> */}
                    </div>
                </div>
            </div>
        )
    }
}

AlbumCard.contextType = PlayerContext
export default withRouter(AlbumCard)
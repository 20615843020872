import React, { Component } from "react";
import { NavLink } from "react-router-dom";
// import {NavLink} from "react-router-dom"
class ProjectDetailTooltip extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {

            }

    render() {
        return (

                <div className="dropdown-menu">
                    <div className="dropdown-group">
                     
                            <NavLink to={"/dashboard/projects/edit/"+ this.props.projectId}>Project Page</NavLink> 
                        {this.props.isOwner && !this.props.complete &&
                            <a href="#" onClick={()=>this.props.handleProjectEditModalOpen()}>Edit Project</a>
                        }
                        {this.props.isOwner &&
                            <a href="#" onClick={()=>this.props.duplicateProject()}>Duplicate Project</a>
                        }
                                                {((this.props.projectLink && this.props.linkEnabled === "active" )|| this.props.isOwner) ?
                                <a href="#" className="display-mobile"onClick={()=>this.props.handleShareModalOpen()}>Share Project</a>
                                :<></>
                        }
                        <a href="#" className="display-mobile"onClick={()=>this.props.handleManageCollaboratorsModalOpen()}>{this.props.isOwner ? "Invite Collaborators" : "View Collaborators"}</a>
                        {
                            this.props.complete ?   
                                <a href="#" onClick={()=>this.props.markIncomplete()}>Mark as Active</a>:
                                <a href="#" onClick={()=>this.props.markComplete()}>Mark as Complete</a>
                        }
                        {!this.props.isOwner ? <a href="#" onClick={()=>this.props.leaveProject()}>Leave Project</a> : <></>}
                        {(this.props.isOwner && !this.props.purchaseId) &&
                            <a href="#" onClick={()=>this.props.deleteProject()}>Delete</a>
                        }

                    </div>                   
                </div>

        )
    }

}
export default ProjectDetailTooltip
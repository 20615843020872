import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { UserConsumer } from "../../contexts/User";

class MobileBottomNav extends Component {
  state = {
    profileNavOpen: false,
  };

  toggleProfileNav = (event) => {
    if (document.body.classList.contains("profile-nav-open")) {
      document.body.classList.remove("profile-nav-open");
      document.body.classList.add("profile-nav-closed");
      event.preventDefault();
    } else {
      document.body.classList.add("profile-nav-open");
      document.body.classList.remove("profile-nav-closed");
      document.body.classList.add("nav-closed");
      document.body.classList.remove("nav-open");
      event.preventDefault();
    }
  };

  render() {
    return (
      <UserConsumer>
        {({ user, login, logout }) => (
          <header className="mobile-navbar">
            <nav className="mobile-navbar-menu">
              <ul>
                <li>
                  <NavLink to="/music">
                    <span className="icon-search"></span>Music
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/artists">
                    <span className="icon-artists"></span>Artists
                  </NavLink>
                </li>
                <li>
                  <NavLink exact to="/collections">
                    <span className="icon-collections"></span>Collections
                  </NavLink>
                </li>
                {!user ? (
                  <li>
                    <NavLink to="/pricing">
                      <span className="icon-subscriptions"></span>Pricing
                    </NavLink>
                  </li>
                ) : (
                  <li>
                    <NavLink onClick={this.toggleProfileNav} to="/dashboard">
                      <span className="icon-dashboard"></span>Dashboard
                    </NavLink>
                  </li>
                )}
              </ul>
            </nav>
          </header>
        )}
      </UserConsumer>
    );
  }
}
export default MobileBottomNav;

import moment from "moment";
import React, { Component, createRef } from "react";
import WaveSurfer from 'wavesurfer.js';
import { PlayerContext } from "../../PlayerContext";

var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment)
// import { PlayerContext } from "../../PlayerContext";

class GridWaveform extends Component {
    state = {
        src: this.props.src || "",
        peaks: this.props.peaks,
        currentTime: "0:00",
        duration: "0:00",
        sendPlay: 0,
        volume: 1,
        ready: false
    }
    handleOnChange = (value) => {
      console.log(value)
      this.setState({
        volume: value.x
      })
    }
  setVolume = ()=>{
    console.log("setting volume to ", this.state.volume)
    this.wavesurfer.setVolume(this.state.volume)
  }

  waveReady = ()=>{}

  wrapper = createRef()
  componentDidMount() {

    this.$el = this.wrapper.current
    this.$waveform = this.$el.querySelector('.wave')
    this.wavesurfer = WaveSurfer.create({
        container: this.$waveform,
        normalize: true,
        barMinHeight: 1,
        barWidth: 1,
        barGap: 2,
        hideScrollbar: true,
        interact: false,
        cursorWidth: 0,
        backend: "MediaElement",
        fillParent: true,
        height: 70,
        // barHeight: 3,
        progressColor: "#fff",
        responsive: true,
        waveColor: "#626060",
        xhr:{ cache: 'default', mode: 'cors', method: 'GET', credentials: 'same-origin', redirect: 'follow', referrer: 'client', headers: [ { key: 'Authorization', value: 'my-token' } ]}
        // fillParent: true
        // fillParent: true,
    })
    this.waveReady = this.wavesurfer.on('ready', ()=>{
      console.log("wave ready")
      this.setState({ready: true})
    })
    this.wavesurferError = this.wavesurfer.on('error', (error)=>{console.log("wave error ", error)})
    this.wavesurfer.backend.peaks = JSON.parse(this.props.peaks)
    this.wavesurfer.drawBuffer()
    this.setState({ready: true})
  }
  songFinished = ()=>{}
  songLoaded = ()=>{}
  wavesurferError = ()=>{}
  currentTimeInt = ()=>{}

  componentWillUnmount() {

  }
  render() {
    return (
      <div style={{width: "100%"}}>

  <div ref={this.wrapper} className="" style={{width: "100%"}}>

<div style={{backgroundColor: "rgba(255, 0, 0, 0.0);", width: "100%" }} className='waveform' onClick={()=>{if(this.props.user ===null)this.props.triggerLoginModal()}}>
  <div className='wave'/>
</div>
</div>
</div>

    )
  }
}
GridWaveform.defaultProps = {
  src: "",
  peaks: "",
  currentTime: 0
}

GridWaveform.contextType = PlayerContext
export default GridWaveform

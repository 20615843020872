// metadata for user signup steps
const signupSteps = [
    {
        path: 'details',
        title: 'Account Details',
        step: 1,
        nextStep: '/signup/profile',
    },
    {
        path: 'profile',
        title: 'Profile Photo',
        step: 2,
        nextStep: '/signup/cover',
    },
    {
        path: 'cover',
        title: 'Cover Photo',
        step: 3,
        nextStep: '/signup/bio',
    },
    {
        path: 'bio',
        title: 'Bio',
        step: 4,
        nextStep: '/signup/pro',
    },
    {
        path: 'pro',
        title: 'P.R.O. Information',
        step: 5,
        nextStep: '/signup/deposits',
    },
    {
        path: 'deposits',
        title: 'Deposit Account Setup',
        step: 6,
        nextStep: '/signup/complete',
    },
    {
        path: 'complete',
        title: 'Deposit Account Complete',
        step: 7,
        nextStep: '/dashboard/tracks/add-tracks',
    }
];

export default signupSteps;
import React from "react";
import { Controller } from "react-hook-form";
import Select from "react-select";
import customStyles from "../../FormElements/customStyles";
import CustomValueContainer from "../../FormElements/customValueContainer";

const SelectControlled = (props) => {
  const defaultOnChange = (value) => {};

  const {
    control,
    formatGroupLabel,
    formatOptionLabel,
    id = "select",
    isSearchable = false,
    name = "select",
    onChange = defaultOnChange,
    options = {},
    placeholder = "Select...",
  } = props;

  return (
    <Controller
      control={control}
      name={name}
      render={({ onChange, value }) => (
        <Select
          onChange={(value) => {
            onChange(value);
          }}
          className="select-container select-container-sub"
          classNamePrefix="select"
          defaultValue={value}
          styles={customStyles}
          placeholder={placeholder}
          options={options}
          isSearchable={isSearchable}
          formatOptionLabel={formatOptionLabel}
          formatGroupLabel={formatGroupLabel}
          components={{
            ValueContainer: CustomValueContainer,
          }}
          selected={value}
        />
      )}
    />
  );
};

export default SelectControlled;

export const stripeAppearance = {
  labels: "floating",
  theme: "flat",
  variables: {
    colorPrimary: "#0570de",
    colorBackground: "rgb(28, 28, 28)",
    colorDanger: "#df1b41",
    spacingUnit: "2px",
    borderRadius: "0",
    colorLogo: "dark",
    colorText: "rgb(175, 170, 170)",
  },
  rules: {
    ".Label--resting": {
      color: "#535252",
      fontFamily: "Scto Grotesk A",
      fontSize: "15px",
    },
    ".Label--floating": {
      color: "rgb(175, 170, 170)",
      fontFamily: "Scto Grotesk A",
      fontSize: "10px",
      fontWeight: "500",
    },
    ".Input": {
      color: "#ffffff",
      padding: "15px 20px",
    },
    ".Input::placeholder": {
      color: "rgb(175, 170, 170)",
    },
    ".Input:focus": {
      boxShadow: "inset 2px 0 0 0 #666",
      color: "#ffffff",
      outline: 0,
      padding: "15px 20px",
    },
  },
};

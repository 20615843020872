import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import SlidingPane from "react-sliding-pane";
import { UserContext } from "../../contexts/User";
import { REDIRECT_CONFIRM } from "../../constants";
import DownloadModal from "../modal/DownloadModal";
import { useIntercom } from "react-use-intercom";

const DownloadTrack = (props) => {

  const userContext = useContext(UserContext);
  const { hasActiveSubscription, user } = userContext;
  const defaultIcon = hasActiveSubscription(user) ? 'icon-download' : 'icon-add-to-cart';
  // console.log('defaultIcon', defaultIcon);
  // console.log('hasActiveSubscription', hasActiveSubscription(user));

  const {
    artistId,
    artistName,
    className,
    icon = defaultIcon,
    imageURL,
    isDemo,
    isOpen,
    text,
    toggleTooltip,
    trackId,
    trackTitle,
    textLink,
    stem,
    version,
  } = props;
  const history = useHistory();
  const { location } = history;
  const { search } = location || {};

  const { update } = useIntercom();

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(isOpen);

  const downloadTrackURLParam = "downloadTrack";

  const triggerDownloadModal = () => {
    setIsDownloadModalOpen(true);
  };
  const handleDownloadModalClose = () => {
    setIsDownloadModalOpen(false);

    if (search?.includes(downloadTrackURLParam)) {
      const updatedSearch = search.replace(downloadTrackURLParam, "");
      history.replace({
        search: updatedSearch,
      });
    }

    // ping intercom
    update();
  };

  const handleDownload = () => {
    if (toggleTooltip) {
      toggleTooltip();
    }

    triggerDownloadModal();
  };

  return (
    <>
      {textLink ? (
        <a onClick={handleDownload} className={className} href="#">
          <span className={icon}></span>
          {text}
        </a>
      ) : (
        <button onClick={handleDownload} className={className ? className : icon}>
          {text}
        </button>
      )}

      <SlidingPane
        key={`download-modal-${trackId}`}
        isOpen={isDownloadModalOpen}
        onRequestClose={handleDownloadModalClose}
        from={"right"}
        width={"100%"}
        children={
          <DownloadModal
            handleClose={() => {
              handleDownloadModalClose();
            }}
            isDemo={isDemo}
            track={{
              trackId: trackId,
              trackTitle: trackTitle,
              artistId: artistId,
              artistName: artistName,
              albumArt: imageURL,
            }}
            stem={stem}
            version={version}
          />
        }
      />
    </>
  );
};

export default DownloadTrack;

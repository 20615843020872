import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Tooltip } from "react-tippy";
import TechnicalRequirements from "../Links/technicalRequirements";
import RejectedToolTip from "./rejectedToolTip";

class RejectedListItem extends Component {
    constructor(props){
        super(props);
        this.trackResubmitPath = '/dashboard/resubmit-track/' + this.props.trackId
    }

    state = {}

    getRejectionToolTiltes = (track)=>{
        if(track.rejectionReasons){
            let rejectionReasons = JSON.parse(track.rejectionReasons)
            let errors = []
            if(rejectionReasons.content ==="reject"){
                errors.push({title: "Content"})
            }
            if(rejectionReasons.curation ==="reject"){
                errors.push({title: "Curation"})
            }
            if(rejectionReasons.exclusivity ==="reject"){
                errors.push({title: "Exclusivity"})
            }
            if(rejectionReasons.trackQuality === "reject"){
                if(rejectionReasons.virusDetected){
                    errors.push({title: "Virus - Full Track"})
                }
                else{
                    switch(rejectionReasons.reason){
                        case "manual":  
                            errors.push({title: "Audio Quality - Full Track"})
                            break;
                        case "bit_depth":
                            errors.push({title: "Bit Depth - Full Track"})
                            break;
                        case "sample_rate":
                            errors.push({title: "Sample Rate - Full Track"})
                            break;
                        default:
                            errors.push({title: "Audio Quality - Full Track"})
                            break;
                    }
                }
            }
            if(rejectionReasons.versions){
                let rejectedVersions =rejectionReasons.versions.filter(version=>{
                    if(version.approval === "reject"){
                       return true
                    }
                    return false
                })
                let rejectedForVirus = rejectedVersions.filter(version=>version.reason === "virus_detected")
                let rejectedForBitDepth = rejectedVersions.filter(version=>version.reason === "bit_depth")
                let rejectedForSampleRate = rejectedVersions.filter(version=>version.reason === "sample_rate")
                let rejectedManually = rejectedVersions.filter(version=>version.reason === "manual")
                let rejectedOther = rejectedVersions.filter(version=>{
                    if(version.reason !== "virus_detected" && version.reason !== "bit_depth" && version.reason !== "sample_rate" && version.reason !== "manual"){
                        return true
                    }
                    else{
                        return false
                    } 
                })
                if(rejectedForVirus.length >0){
                    errors.push({title: `${rejectedForVirus.length}x Virus - Versions`})

                }
                if(rejectedForBitDepth.length >0){
                    errors.push({title: `${rejectedForBitDepth.length}x Bit Depth - Versions`})
                }
                if(rejectedForSampleRate.length >0){
                    errors.push({title: `${rejectedForSampleRate.length}x Sample Rate - Versions`})

                }
                if(rejectedManually.length >0){
                    errors.push({title: `${rejectedManually.length}x Audio Quality - Versions`})

                }
                if(rejectedOther.length > 0){
                    errors.push({title: `${rejectedOther.length}x Audio Quality - Versions`})
                }
            }
            if(rejectionReasons.stems){
                let rejectedStems = rejectionReasons.stems.filter(stem=>{
                    if(stem.approval === "reject"){
                        return true
                    }
                    return false
                })
                let rejectedForVirus = rejectedStems.filter(stem=>stem.reason === "virus_detected")
                let rejectedForBitDepth = rejectedStems.filter(stem=>stem.reason === "bit_depth")
                let rejectedForSampleRate = rejectedStems.filter(stem=>stem.reason === "sample_rate")
                let rejectedManually = rejectedStems.filter(stem=>stem.reason === "manual")
                let rejectedOther = rejectedStems.filter(stem=>{
                    if(stem.reason !== "virus_detected" && stem.reason !== "bit_depth" && stem.reason !== "sample_rate" && stem.reason !== "manual"){
                        return true
                    }
                    else{
                        return false
                    } 
                })
                if(rejectedForVirus.length >0){
                    errors.push({title: `${rejectedForVirus.length}x Virus - Stems`})

                }
                if(rejectedForBitDepth.length >0){
                    errors.push({title: `${rejectedForBitDepth.length}x Bit Depth - Stems`})
                }
                if(rejectedForSampleRate.length >0){
                    errors.push({title: `${rejectedForSampleRate.length}x Sample Rate - Stems`})

                }
                if(rejectedManually.length >0){
                    errors.push({title: `${rejectedManually.length}x Audio Quality - Stems`})

                }
                if(rejectedOther.length > 0){
                    errors.push({title: `${rejectedOther.length}x Audio Quality - Stems`})
                }

            }
            return errors
        }
    }
    getRejectCount = (track)=>{
        let rejectionCount =0;
        
        if(track.rejectionReasons){
            let rejectionReasons =JSON.parse(track.rejectionReasons)
            if(rejectionReasons.content ==="reject"){
                rejectionCount++
            }
            if(rejectionReasons.curation ==="reject"){
                rejectionCount++
            }
            if(rejectionReasons.exclusivity ==="reject"){
                rejectionCount++
            }
            if(rejectionReasons.trackQuality ==="reject"){
                rejectionCount++
            }
            if(rejectionReasons.versions){
                rejectionReasons.versions.forEach(version=>{
                    if(version.approval === "reject"){
                        rejectionCount++
                    }
                })
            }
            if(rejectionReasons.stems){
                rejectionReasons.stems.forEach(stem=>{
                    if(stem.approval === "reject"){
                        rejectionCount++
                    }
                })
            }
        }
        return rejectionCount
    }
    getPrimary= (track)=>{
        let rejectionPrimary ={
            title: "",
            desc: [],
            descTracker: ""
        };
        
        if(track.rejectionReasons){
            let rejectionReasons =JSON.parse(track.rejectionReasons)
            if(rejectionReasons.content ==="reject"){
                if(!rejectionPrimary.title){
                    rejectionPrimary.title = "Content"
                }
                rejectionPrimary.desc.push(<span>We have determined this track does not meet our content standards. </span>)
            }
            if(rejectionReasons.curation ==="reject"){
                if(!rejectionPrimary.title){
                    rejectionPrimary.title = "Curation"
                }
                rejectionPrimary.desc.push(<span>We have determined this track does not meet our curation standards. </span>)
            }
            if(rejectionReasons.exclusivity ==="reject"){
                if(!rejectionPrimary.title){
                    rejectionPrimary.title = "Exclusivity"
                }
                rejectionPrimary.desc.push(<span>We have determined this track does not meet our exclusivity standards. </span>)
            }
            if(rejectionReasons.content ==="reject" || rejectionReasons.curation ==="reject" || rejectionReasons.content ==="reject"){
                return rejectionPrimary
            }
            
            if(rejectionReasons.trackQuality ==="reject"){
                if(rejectionReasons.virusDetected === true){
                    if(!rejectionPrimary.title){
                        rejectionPrimary.title = "Virus - Full Track"
                    }
                    if(rejectionPrimary.descTracker.indexOf(`<span>One or more files contain a virus. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`) ===-1){
                        rejectionPrimary.desc.push( <span>One or more files contain a virus. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>. </span>)
                        rejectionPrimary.descTracker +=`<span>One or more files contain a virus. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`
                    }
                }
                else{
                    switch(rejectionReasons.reason){
                        case "manual":  
                            if(!rejectionPrimary.title){
                                rejectionPrimary.title = "Audio Quality - Full Track"
                            }
                            if(rejectionPrimary.descTracker.indexOf(`<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`) ===-1){
                                rejectionPrimary.desc.push(<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>. </span>)
                                rejectionPrimary.descTracker +=`<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`
                            }
                            break;
                        case "bit_depth":
                            if(!rejectionPrimary.title){
                                rejectionPrimary.title = "Bit Depth - Full Track"
                            }
                            if(rejectionPrimary.descTracker.indexOf(`<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`) ===-1){
                                rejectionPrimary.desc.push(<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>. </span>)
                                rejectionPrimary.descTracker +=`<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`

                            }
                            break;
                        case "sample_rate":
                            if(!rejectionPrimary.title){
                                rejectionPrimary.title = "Sample Rate - Full Track"
                            }
                            if(rejectionPrimary.descTracker.indexOf(`<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`) ===-1){
                                rejectionPrimary.desc.push(<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>. </span>)
                                rejectionPrimary.descTracker +=`<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`

                            }
                            break;
                        default:
                            if(!rejectionPrimary.title){
                                rejectionPrimary.title = "Audio Quality - Full Track"
                            }
                            if(rejectionPrimary.descTracker.indexOf(`<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`) ===-1){
                                rejectionPrimary.desc.push(<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>. </span>)
                                rejectionPrimary.descTracker +=`<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`

                            }
                            break;
                    }
                }
            }
            if(rejectionReasons.versions){
                rejectionReasons.versions.forEach(version=>{
                    if(version.approval === "reject"){
                        switch(version.reason){
                            case "manual":  
                                if(!rejectionPrimary.title){
                                    rejectionPrimary.title = "Audio Quality - Versions"
                                }
                                if(rejectionPrimary.descTracker.indexOf(`<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`) ===-1){
                                    rejectionPrimary.desc.push(<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>. </span>)
                                    rejectionPrimary.descTracker +=`<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`

                                }
                                break;
                            case "bit_depth":
                                if(!rejectionPrimary.title){
                                    rejectionPrimary.title = "Bit Depth - Versions"
                                }
                                if(rejectionPrimary.descTracker.indexOf(`<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`)===-1){
                                    rejectionPrimary.desc.push(<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>. </span>)
                                    rejectionPrimary.descTracker +=`<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`

                                }
                                break;
                            case "sample_rate":
                                if(!rejectionPrimary.title){
                                    rejectionPrimary.title = "Sample Rate - Versions"
                                }
                                if(rejectionPrimary.descTracker.indexOf(`<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`)===-1){
                                    rejectionPrimary.desc.push(<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>. </span>)
                                    rejectionPrimary.descTracker +=`<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`

                                }
                                break;
                            case "virus_detected":
                                if(!rejectionPrimary.title){
                                    rejectionPrimary.title = "Virus - Versions"
                                }
                                if(rejectionPrimary.descTracker.indexOf(`<span>One or more files contain a virus. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`) ===-1){
                                    rejectionPrimary.desc.push( <span>One or more files contain a virus. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>. </span>)
                                    rejectionPrimary.descTracker +=`<span>One or more files contain a virus. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`
                                }
                                break;
                            default:
                                if(!rejectionPrimary.title){
                                    rejectionPrimary.title = "Audio Quality - Versions"
                                }
                                if(rejectionPrimary.descTracker.indexOf(`<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`) ===-1){
                                    rejectionPrimary.desc.push(<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>. </span>)
                                                                rejectionPrimary.descTracker +=`<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`

                                }
                                break;
                        }
                    }

                })
            }
            if(rejectionReasons.stems){
                rejectionReasons.stems.forEach(stem=>{
                    if(stem.approval === "reject"){
                        switch(stem.reason){
                            case "manual":  
                                if(!rejectionPrimary.title){
                                    rejectionPrimary.title = "Audio Quality - Stems"
                                }
                                if(rejectionPrimary.descTracker.indexOf(`<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`) ===-1){
                                    rejectionPrimary.desc.push(<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>. </span>)
                                                                rejectionPrimary.descTracker +=`<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`

                                }
                                break;
                            case "bit_depth":
                                if(!rejectionPrimary.title){
                                    rejectionPrimary.title = "Bit Depth - Stems"
                                }
                                if(rejectionPrimary.descTracker.indexOf(`<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>` ===-1)){
                                    rejectionPrimary.desc.push(<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>. </span>)
                                                                rejectionPrimary.descTracker +=`<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`

                                }
                                break;
                            case "sample_rate":
                                if(!rejectionPrimary.title){
                                    rejectionPrimary.title = "Sample Rate - Stems"
                                }
                                if(rejectionPrimary.descTracker.indexOf(`<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>` ===-1)){
                                    rejectionPrimary.desc.push(<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>. </span>)
                                                                rejectionPrimary.descTracker +=`<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`

                                }
                                break;
                            case "virus_detected":
                                if(!rejectionPrimary.title){
                                    rejectionPrimary.title = "Virus - Stems"
                                }
                                if(rejectionPrimary.descTracker.indexOf(`<span>One or more files contain a virus. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`) ===-1){
                                    rejectionPrimary.desc.push( <span>One or more files contain a virus. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>. </span>)
                                    rejectionPrimary.descTracker +=`<span>One or more files contain a virus. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`

                                }
                                break;
                            default:
                                if(!rejectionPrimary.title){
                                    rejectionPrimary.title = "Audio Quality - Stems"
                                }
                                if(rejectionPrimary.descTracker.indexOf(`<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`) ===-1){
                                    rejectionPrimary.desc.push(<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>. </span>)
                                                                rejectionPrimary.descTracker +=`<span>Audio does not meet our <TechnicalRequirements underline/>. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</span>`

                                }
                                break;
                        }
                    }
                })
            }
        }

        return rejectionPrimary
    }
    handleClick = (track)=>{
        if(track.trackStatus=== "fullRejected"){
            return
        }
        else{
            window.location.replace(this.trackResubmitPath)
        }
    }
    resolveAlbumPhoto=(albumPhotoKey)=>{
        const key = albumPhotoKey
        if(albumPhotoKey){
            const ext = key.split('.').reverse()[0];
            let sizeMod;
            let width = window.innerWidth
            if(width >= 1280){
                sizeMod = '140x140'
            }
            else if(width >= 1024 && width < 1280){
                sizeMod = '140x140'
            }
            else if(width >= 760 && width < 1024){
                sizeMod = '140x140'
            }
            else{
                sizeMod = '140x140'
            }
            let final = key.replace('.' + ext, '') + '-minified-' + sizeMod + '.' + ext
            return final
        }
    }
    render() {

        return (
            // {/* {# Tracks List #} */}
                <>
                    {this.props.rejectionReasons &&
                        <div className="track-list-card is-unplayable">
                            <div className="track-first-col">
                                <div className="track-image">
                                    {this.props.trackStatus !== "fullRejected" ?
                                        <NavLink to={this.trackResubmitPath}>
                                            <img src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveAlbumPhoto(this.props.imageURL)}`} alt="albumArt"/>
                                        </NavLink>
                                    :
                                        <img src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveAlbumPhoto(this.props.imageURL)}`} alt="albumArt"/>
                                    }
                                </div>
                                <div className="track-title-artist">
                                    {this.props.trackStatus !== "fullRejected" ?
                                        <NavLink to={this.trackResubmitPath}>
                                            <span className="track-title">{ this.props.trackTitle }</span>
                                        </NavLink>
                                    :
                                        <span className="track-title">{ this.props.trackTitle }</span>
                                    }
                                    <NavLink to={'/artists/' + this.props.artistURL}>
                                        <span className="track-artist">{this.props.artistName}</span>    
                                    </NavLink>  
                                </div>
                                {/* <button className="icon-chevron-down"></button> */}
                            </div>
                            <div className="track-notice">
                            {this.getRejectCount(this.props) > 0 ?
                                <Tooltip  
                                      arrow={false}
                                      position={'bottom-center'}
                                      trigger='mouseenter focus'
                                      useContext={true}
                                      html={(
                                            <RejectedToolTip 
                                              className="hidden"
                                              track={{title: this.props.title, errors: this.getRejectionToolTiltes(this.props)}}
                                            />
                                            )}
                                      allowHTML={true}
                                      theme={'dark'}
                                >
                                    <div className="track-notice-total">
                                        {this.getRejectCount(this.props)}
                                    </div>
                                </Tooltip>
                                :

                                <div className="track-notice-total">
                                    {this.getRejectCount(this.props)}
                                </div>
                            }
                                <div className="track-notice-message">
                                   
                                        {this.getRejectCount(this.props) > 0 ?
                                            <>
                                                <p className="track-notice-title">
                                                    {this.getPrimary(this.props).title} {this.getRejectCount(this.props)-1 > 0 && <> +   {this.getRejectCount(this.props) -1}  more issues </>}
                                                </p>
                                                <p className="track-notice-desc">{this.getPrimary(this.props).desc} {this.props.rejectionComment && this.props.rejectionComment}</p>
                                            </>
                                            :
                                            <>
                                                <p className="track-notice-title">Ready to Resubmit</p>
                                                <p className="track-notice-desc">Errors appear to have been fixed. Please <NavLink to={this.trackResubmitPath}><span style={{textDecoration: "underline"}}>resubmit</span></NavLink>.</p>
                                            </>
                                            }
                                </div>
                            </div>
                            <div className="track-last-col">
                                <div className="track-list-icons text-gray-400">
                                    <Tooltip  
            	                        arrow={false}
            	                        open={this.state.showTrackTippy}
            	                        interactive={true}
            	                        position={'bottom-end'}
                                        popperOptions={{
                                            modifiers: {
                                                preventOverflow: { enabled: false },
                                                flip: { enabled: false },
                                                hide: { enabled: false },
                                                addZIndex: {
                                                    enabled: true,
                                                    order: 810,
                                                    fn: data => ({
                                                        ...data,
                                                        styles: {
                                                        ...data.styles,
                                                        zIndex: 10,
                                                        },
                                                    })
                                                }
                                             }
                                        }}
            							trigger={'click'}
            							useContext={true}
            	                        // hideOnClick={true}
            	                        html={(
                                            <div className="dropdown-menu">
                                                <div className="dropdown-group">
                                                    {this.props.trackStatus !== "fullRejected" &&
                                                        <NavLink to={this.trackResubmitPath}>
                                                            <span className="track-title">Revise Track</span>
                                                        </NavLink>
                                                    }
                                                    <a href="/help" ><span ></span>Help</a>
                                                </div>
                                            </div>
            	                        )}
            	                        allowHTML={true}
            	                        onUntrigger={()=>{this.state.showTrackTippy ? this.setState({showTrackTippy: false}): this.setState({showTrackTippy: true})}}
            	                        onRequestClose={()=>{this.state.showTrackTippy ? this.setState({showTrackTippy: false}): this.setState({showTrackTippy: true})}}
    	                            >  
        	                            <button 
                	                        id="trackToolTip"
                	                        className="track-list-more"
                	                        onClick={this.toggleTrackTippy}
                	                        data-tippy-html="#trackToolTip"
                	                        data-tippy-placement="bottom-end"
                	                        type="button"
                	                    >
                	                        <span className="icon-dots-vert"></span>
                	                    </button>
                	                </Tooltip>
                                </div>
                            </div>
                        </div>
                    }  
                </>
            )
        }
        
    }

RejectedListItem.defaultProps ={
  tracks: []
}

export default RejectedListItem
import React, { Component } from "react";
import { Tooltip } from "react-tippy";
import { FavoritesConsumer } from "../../FavoritesContext";
import { PlayerContext } from "../../PlayerContext";
import CircularIndeterminant from "../Loading/circularIndeterminant";
import TrackListItem from "../Track/trackListItem";
import api from "../utils/api";
import CollectionByline from "./CollectionByline";
import CollectionDetailHelmet from "./CollectionDetailHelmet";
import "./fontSize.css";
import PublicCard from "./publicCard";
import PublicCollectionTooltip from "./publicCollectionTooltip";

const collectionAuthor = "Deep Sounds";

class PublicCollection extends Component{
    state={
        collection: {},
        similarCollections:[]
    }
    toggleCollectionTippy = ()=>{

        this.setState( prevState=>({showCollectionTippy: !prevState.showCollectionTippy}))
    }
  getCollectionsData = () => {
    api.getPublicCollection(this.props.match.params.collectionId).then((res) => {
      if (res.data.success) {
        let collection = res.data.collection;
        collection.author = collectionAuthor;
        this.setState({ collection: collection, isLoaded: true }, () => {
          this.context.getTracksInfo(
            collection.tracks.map((track) => track.trackId)
          );
        });
      }
    });
    api.getRelatedCollections(this.props.match.params.collectionId)
      .then((res) => {
        if (res.data.success) {
          this.setState({ similarCollections: res.data.similar });
        }
      });
  };
  componentDidMount = () => {
    this.getCollectionsData();
  };
  componentDidUpdate = (prevProps) => {
    if (
      this.props.match.params.collectionId !== prevProps.match.params.collectionId
    ) {
      document.getElementsByClassName("app-main")[0].scrollTo(0, 0);
      this.getCollectionsData();
    }
  };

    playCollection =()=>{
        let idKey = this.context.mediaPlayer.idKey
        let trackId = parseInt(idKey?.split("-")[1])
        //
        if((idKey?.indexOf("collectionListCard-" + this.state.collection?.id) ||-1)!==-1){
            this.context.handlePlay()
        }
        else{
            let playList = this.state.collection.tracks.map(track=>track.trackId)

            this.context.setPlaylist(playList)
            this.context.loadTrackInMediaPlayer(playList[0], `artist-${this.context.loadedTracks[playList[0]]?.artistId}-album-${this.context.loadedTracks[playList[0]]?.albumId}-track-${playList[0]}-element-collectionListCard-${this.state.collection?.id}`)
            this.context.setAutoPlay(true)
        }

    }
    resolveCoverPhoto=(coverPhotoKey)=>{
        const key = coverPhotoKey
        if(coverPhotoKey){
            const ext = key.split('.').reverse()[0];
            let final = key.replace('.' + ext, '') + '-minified' + '.' + ext
            return final
        }
    }
    resolveCardPhoto=(cardPhotoKey)=>{
        const key = cardPhotoKey
        if(cardPhotoKey){
            const ext = key.split('.').reverse()[0];
            let sizeMod;
            let width = window.innerWidth
                sizeMod = '325x325'

            let final = key.replace('.' + ext, '') + '-minified-' + sizeMod + '.' + ext
            return final
        }
    }
    getThumbnail=(photoKey)=>{
        const key = photoKey
        if(photoKey){
            const ext = key.split('.').reverse()[0];
            let final = key.replace('.' + ext, '') + '-minified-325x325' + '.' + ext
            return final
        }
    }
    playTracks = (tracks, collectionId, idKey)=>{
        if((idKey?.indexOf("collectionListCard-" + collectionId) ||-1)!==-1){
            this.context.handlePlay()
        }
        else{
            let playList = tracks.map(track=>track.trackId)

            this.context.setPlaylist(playList)
            this.context.loadTrackInMediaPlayer(playList[0], `artist-${this.context.loadedTracks[playList[0]]?.artistId}-album-${this.context.loadedTracks[playList[0]]?.albumId}-track-${playList[0]}-element-collectionListCard-${collectionId}`)
            this.context.setAutoPlay(true)
        }
        // if(this.context.mediaPlayer.idKey?.indexOf(idKey) !==-1 || this.context.mediaPlayer.idKey === undefined){
        //     let playList = tracks
        //     playList.forEach((track, index)=>{
        //         playList[index].idKey = "collectionCard-" + this.state.collection?.id + "-trackId-" + track.trackId
        //     })
        //     this.context.setPlaylist(playList)
        //     this.context.loadTrackInMediaPlayer(playList[0].trackId, playList[0].idKey)
        //     this.context.setAutoPlay(true)
        //     // this.setState({playingCollection: collectionId})
        // }
        // else{
        //     this.context.handlePlay()
        // }

    }
    render(){
        return(
            <FavoritesConsumer>
                {({artists, tracks, albums, collections,followCollection, unfollowCollection})=>(
            <>
              {this.state.collection.name &&
                <CollectionDetailHelmet
                  collection={this.state.collection}
                  loadedTracks={this.context.loadedTracks}
                />
              }
                {this.state.isLoaded ? <>
                <div class="hero-bg">
                    <div class="hero-img" style={{backgroundImage: `url('https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveCoverPhoto(this.state.collection.coverArt)}'`}}></div>
                </div>
                <div class="container pt-140 md:pt-300 z-10">
                    {/* {# Collection View Header #} */}
                    <div class="coll-view-header">
                        {/* {# Collection View Info #} */}
                        <div class="coll-view-info">
                            <div class="flex flex-1 mb-30 lg:mb-0">
                                {/* {# Collection Image #} */}
                                <div class="coll-view-img">
                                    <div class="artwork-card-block">
                                        <div class="artwork-card">
                                            <div class="artwork-card-image">
                                                <img src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveCardPhoto(this.state.collection.cardArt)}`}/>
                                                <div class="artwork-card-hover">
                                                        {(this.context.mediaPlayer.idKey?.indexOf("collectionListCard-" + this.state.collection?.id) || -1) !==-1 && (this.context.mediaPlayer.isTrackPlaying || this.context.isVideoPlaying) ? (
                                                            <div
                                                                class="circle-icon-fill bg-white text-black circle-icon-lg"
                                                                onClick={()=>this.context.handlePause()}
                                                            >
                                                                <span class="icon-pause"></span>
                                                            </div>
                                                        ) : (
                                                            <div
                                                                class="circle-icon-fill bg-white text-black circle-icon-lg"
                                                                onClick={()=>this.playCollection()}
                                                            >
                                                                <span class="icon-play"></span>
                                                            </div>
                                                        )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* {# Collection View Details #} */}
                                <div class="coll-view-title">
                                <h1 class="text-h3 lg:text-h2 mb-5">{this.state.collection.name}</h1>
                                <div class="text-base font-medium">
                                <div className="flex items-center fontFix">
                                <CollectionByline
                                  collectionAuthor={collectionAuthor}
                                />
                                  <span class="text-gray-400 mr-3">&nbsp;- </span><span> {this.state.collection?.tracks?.length} Tracks</span> </div>

                                </div>

                                <p class="text-sm md:text-base max-w-xl mb-0 mt-5">{this.state.collection.description}</p>
                                </div>
                            </div>
                            {/* {# Collection View CTA #} */}
                            <div class="coll-view-cta">
                                <div class="grid-row-xs">
                                    {/* {# Play Button #} */}
                                    <div class="col flex-1 lg:flex-initial lg:w-140">
                                    {(this.context.mediaPlayer.idKey?.indexOf("collectionListCard-" + this.state.collection?.id) || -1) !==-1 && (this.context.mediaPlayer.isTrackPlaying || this.context.isVideoPlaying) ?
                                            <button onClick={()=>this.context.handlePause()} class="btn btn-stroke w-full">
                                                <span class="icon-pause"></span>Pause
                                            </button>:
                                            <button onClick={this.playCollection} class="btn btn-stroke w-full">
                                                <span  class="icon-play"></span>Play
                                            </button>
                                        }
                                    </div>
                                    {/* {# Follow Button #} */}
                                    <div class="col flex-1 lg:flex-initial lg:w-140">
                                        {collections?.filter(fav=>fav.collectionId === this.state.collection?.id).length === 1 ?
                                            <button onClick={()=>unfollowCollection(this.state.collection.id, this.state.collection.name)} class="btn btn-primary w-full">
                                                <span class="icon-collections"></span>Unfollow
                                            </button>:
                                            <button onClick={()=>followCollection(this.state.collection.id, this.state.collection.name)} class="btn btn-primary w-full">
                                                <span class="icon-collections"></span>Follow
                                            </button>
                                        }
                                    </div>
                                    {/* {# More Button #} */}
                                    <div class="col flex flex-none items-center">
                                        <Tooltip
                                            className="circle-icon"
                                            arrow={false}
                                            open={this.state.showCollectionTippy}
                                            interactive={true}
                                            useContext={true}
                                            position={'bottom-end'}
                                            trigger={'click'}
                                            html={(<PublicCollectionTooltip
                                                collectionId={this.state.collection.id}
                                                collectionURL={this.state.collection.collectionURL}
                                                toggleCollectionTippy={this.toggleCollectionTippy}
                                            />)}
                                            allowHTML={true}
                                            onUntrigger={()=>{this.toggleCollectionTippy()}}
                                            onRequestClose={()=>{this.setState({showCollectionTippy: false})}}
                                        >
                                            <button
                                                onClick={this.toggleCollectionTippy}
                                                className="circle-icon js-dropdown-toggle"
                                            >
                                                <span className="icon-dots-vert"></span>
                                            </button>
                                        </Tooltip>
                                    </div>
                                </div>
                            </div>
                            {/* {# Collection View Dropdown #} */}
                            {/* {% include "components/dropdowns/track-detail-dropdown.html" %} */}
                        </div>
                    </div>
                    {/* {# Featured Tracks #} */}
                    <div class="mb-50 md:mb-80">
                        <div class="tracks-heading mb-20 md:mb-40">
                            <div class="tracks-heading-title">
                                Collection Tracks
                            </div>
                        </div>
                        {/* {# Tracks #} */}
                        <div class="track-list-container">
                            {this.state.collection.tracks?.map(track=>
                                <TrackListItem {...this.context.loadedTracks[track.trackId]} idKey={`artist-${this.context.loadedTracks[track.trackId]?.artistId}-album-${this.context.loadedTracks[track.trackId]?.albumId}-track-${track.trackId}-element-collectionListCard-${this.state.collection?.id}`} otherTracks={this.state.collection.tracks.map(track=>track.trackId)}/>)}
                        </div>
                    </div>
                    {this.state.similarCollections.length > 0 &&
                        <div class="mb-50 md:mb-80">
                                <div class="tracks-heading mb-20 md:mb-40">
                                    <div class="tracks-heading-title">
                                        Similar Collections
                                    </div>
                                </div>
                            {/* {# Artist Cards #} */}
                            <div class="grid-row">
                                {this.state.similarCollections.map(collection=><div class="col w-full sm:w-1/2 lg:w-1/3 mb-20 md:mb-40">
                                    <PublicCard idKey={"collectionCard-" + collection.id}  key={'featuredCollectionCard-'+collection.id} {...collection} playTracks={this.playTracks}  isPlaying={this.state.playingCollection === collection.id}/>
                                </div>)}
                            </div>
                        </div>
                    }
                </div>
                </>:<div style={{marginTop: "5rem"}}><CircularIndeterminant forceCenter/></div>}
            </>)}
            </FavoritesConsumer>
        )
    }
}
PublicCollection.contextType = PlayerContext
export default PublicCollection

import React, { useState } from "react";
import currencyFormatter from "../../../utils/currencyFormatter";
import DataTable from "../../UI/DataTable";
import { DateTime } from "luxon";

const RoyaltyPaymentsRow = ({ payout }) => {
  console.log("RoyaltyPaymentsRow payout", payout);
  const { artist, id, quarter, payout_date, status, total_payout_amount, assetReportItems } =
    payout || {};

  const [isLoaded, setisLoaded] = useState(false);
  const [showDetail, setShowDetail] = useState(false);

  const toggleDropdown = () => {
    setShowDetail(!showDetail);
  };

  const assetReportItemsFormatted = assetReportItems?.map((item) => {
    // console.log("assetReportItems item", item);
    const { assetReport, asset_title, artist_net_earnings_amount } = item || {};
    const { month, year } = assetReport || {};
    const monthStr = DateTime.fromObject({ month, year }).toFormat("yyyy-MM");
    return {
      month: monthStr,
      asset_title,
      artist_net_earnings_amount: currencyFormatter(artist_net_earnings_amount / 100),
    };
  });

  // console.log("contentIDPayoutItemsFormatted", contentIDPayoutItemsFormatted);

  return (
    <>
      <tr style={{ maxWidth: "100%" }}>
        <td>
          <button className="text-white underline" onClick={toggleDropdown}>
            {artist?.artistName}
          </button>
        </td>
        <td>{currencyFormatter(total_payout_amount / 100)}</td>
        <td>{DateTime.fromISO(payout_date).toFormat("yyyy-MM-dd")}</td>
        <td>{status}</td>
      </tr>

      <tr className={showDetail ? "" : "hidden"}>
        <td colSpan="4">
          <div className="bg-gray-100 pt-20 pb-10 lg:pb-15 mb-40">
            <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
              <div className="flex-1 flex items-center mb-15 md:mb-0">
                <h6 className="text-h6 mr-10">
                  {quarter} - {artist?.artistName} - Details by Asset
                </h6>
              </div>
            </div>
            {assetReportItemsFormatted?.length > 0 ? (
              <DataTable
                data={assetReportItemsFormatted}
                headerTransform={"convertSnakeToTitleCase"}
              />
            ) : null}
          </div>
        </td>
      </tr>
    </>
  );
};

export default RoyaltyPaymentsRow;

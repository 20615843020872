import React, { useContext } from "react";
// import { UserContext } from "../../contexts/User";

const Dashboard = () => {
  // const { user } = useContext(UserContext);
  // const { admin } = user || {};
  return <div>Coming Soon</div>;
};

export default Dashboard;

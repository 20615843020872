import React, { Component } from "react";
import { UserConsumer } from "../../contexts/User";
import AppLayout from "../Layouts/appLayout";
import api from "../utils/api";
const queryString = require("query-string");

class ConfirmEmail extends Component {
  state = {
    confirmed: false,
    complete: false,
  };
  componentDidMount = () => {
    let parsedQuery = queryString.parse(this.props.location.search);

    api.confirmEmail(parsedQuery.enM).then((res) => {
      this.setState({
        confirmed: res.data,
        complete: true,
      });
      this.setState({ confirmed: true });
      // document.cookie = `confirmed=${res.data}; path=/;`
    });
  };
  render() {
    return (
      <AppLayout>
        <UserConsumer>
          {({ user, login, logout }) => (
            <>
              <div className="container">
                {/* {# App Header #} */}
                <div className="view-header">
                  <h1 className="view-title">Email Confirmation</h1>
                </div>

                {this.state.complete && this.state.confirmed ? (
                  <div>
                    <p>Your email has been confirmed!</p>
                    <div className="form-section m-15">
                      <div className="grid-row-sm">
                        <div className="input-group-sm input-inline">
                          <p>
                            <a className="btn btn-primary w-full" href="/music">
                              Browse Music
                            </a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : this.state.complete ? (
                  <div>
                    <p className="mb-30">Sorry, we had trouble confirming your email.</p>
                    <p>
                      Please try again or email contact@deepsounds.com if you're still having
                      issues.
                    </p>
                  </div>
                ) : (
                  <div>
                    <p className="mb-30">Processing...</p>
                  </div>
                )}
              </div>
            </>
          )}
        </UserConsumer>
      </AppLayout>
    );
  }
}

export default ConfirmEmail;

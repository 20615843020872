import React, { useEffect, useState } from 'react'
import api from '../../../utils/api';
import { handleAPIError } from '../../../../utils/handleAPIError';
import { Success } from '../../../Toast';

export default function FeatureTrack(props) {
  const {
    toggleTrackTippy,
    trackId
  } = props;

  // console.log('FeatureTrack props', props);

  const [isFeatured, setIsFeatured] = useState(props.isFeatured);

  const featureTrack = () => {
    const params = {
      trackId,
      isFeatured: !isFeatured
    }
    api.featureTrack(params)
      .then(response => {
        // console.log('featureTrack response', response);
        const { updatedTrack } = response.data?.data || {};
        setIsFeatured(updatedTrack?.isFeatured);
        const message = updatedTrack.isFeatured ? (
          "Track is now featured"
        ): (
          "Track is no longer featured"
          )
        Success({ message })
      })
      .catch(error => {
        handleAPIError(error);
      })
  }

  // useEffect(() => {


  // }, [isFeatured])

  return (
    <a
      href="#"
      onClick={() => {
        toggleTrackTippy();
        featureTrack()
      }}
    >
      {isFeatured ? (
        <><span className="icon-star-fill"></span>Remove Featured</>
      ) : (
        <><span className="icon-star"></span>Feature Track</>
      )}
    </a>
  )
}

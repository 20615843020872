import React, { Component } from "react";
import MicroModal from "react-micro-modal";
import MediaQuery from 'react-responsive';
import { HashLink } from 'react-router-hash-link'; // import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Creatable from 'react-select/creatable';
import Sticky from 'react-sticky-el';
import { Tooltip } from "react-tippy";
import 'react-tippy/dist/tippy.css';
import { toast } from "react-toastify";
import socketIOClient from "socket.io-client";
// import "./toast.css"
import resolveConfig from "tailwindcss/resolveConfig";
import { v4 as uuidv4 } from 'uuid';
import DefaultAvatar from "../../assets/svg/user-avatar.svg";
import { UserConsumer } from "../../contexts/User";
import { confirm } from "../confirmModal/index";
import CharacterCount from "../FormElements/characterCount";
import customStyles from "../FormElements/customStyles";
import customStylesMulti from "../FormElements/customStylesMulti";
import KeywordsInput from '../FormElements/keywordsInput';
import StemsExport from "../Links/stemsExport";
import TechnicalRequirementsLearnMore from "../Links/technicalRequirementsLearnMore";
import TracksExport from "../Links/tracksExport";
import VersionsExport from "../Links/versionsExport";
import CircularDeterminantLabel from "../Loading/circularDeterminantLabel";
import CircularIndeterminant from "../Loading/circularIndeterminant";
import AlbumModal from "../modal/albumModal";
import tailwindConfig from "../../tailwind.config";
import { Error, Success, SuccessRedirect, Warning } from "../Toast/index";
import api from '../utils/api';
import TrackWaveform from "../Waveform/forAdminApproveFullTrack";
import Waveform from "../Waveform/forVersionsAndStems";
import NewTrackToolTip from "./newTrackToolTip";
import formatAlbumLabel from "../FormElements/TrackForm/formatAlbumLabel";
import versionNameOptions from "../FormElements/TrackForm/versionNameOptions"

const tw = resolveConfig(tailwindConfig);

const customStylesCity = {
    option: (provided, state) => {
      console.log(state);
      return {
        // ...provided,
        borderBottom: "1px solid hsla(0, 0%, 100%, .05)",
        backgroundColor: tw.theme.colors.gray[200],
        padding: tw.theme.spacing[15] + " " + tw.theme.spacing[20],
        transition: "color 0.3s",
        cursor: state.isDisabled ? "default" : "pointer",
        color:
          state.isFocused
            ? tw.theme.colors.white || state.isDisabled
            : tw.theme.colors.gray[400]
      };
    },
    control: (provided, state) => {
      return {
        ...provided,
        border: "none",
        backgroundColor: tw.theme.colors.gray[200],
        minHeight: tw.theme.spacing[60],
        borderRadius: "none",
        cursor: "pointer",
        boxShadow: 0
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        marginTop: "0",
        marginBottom: "0",
        backgroundColor: tw.theme.colors.gray[200],
        position: "absolute",
        boxShadow: tw.theme.boxShadow.lg,
        borderRadius: "none"
      };
    },
    menuList: (provided, state) => {
      return {
        ...provided,
        scrollbarColor: "hsla(0, 0%, 100%, .2) transparent",
        scrollbarWidth: "thin",
        "::-webkit-scrollbar": {
          width: "0.375rem"
        },
        "::-webkit-scrollbar-track": {
          backgroundColor: "transparent"
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "hsla(0, 0%, 100%, .2)",
          borderRadius: tw.theme.borderRadius.full
        }
      };
    },
    input: (provided, state) => {
      //   console.log("input ", provided, " ", state);
      return {
        // ...provided,
        color: tw.theme.colors.white,
        lineHeight: tw.theme.lineHeight.snug
      };
    },
    placeholder: (provided, state) => {
      return {
        ...provided,
        color: tw.theme.colors.gray[400],
        margin: "0",
        display: "flex"
      };
    },
    singleValue: (provided, state) => {
      return {
        ...provided,
        color: "white",
        margin: "0",
        lineHeight: tw.theme.lineHeight.snug
      };
    },
    valueContainer: (provided, state) => {
      //   console.log("val ", provided);
      return {
        ...provided,
        alignSelf: "stretch",
        padding: tw.theme.spacing[10] + " " + tw.theme.spacing[20],
        backgroundColor: "#1c1c1c"
      };
    },
    dropdownIndicator: (provided, state) => {
      //   console.log("drop  ", provided);
      return {
        ...provided,
        transition: "all .2s ease",
        transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
        color: tw.theme.colors.gray[500],
        cursor: "pointer",
        backgroundColor: "transparent",
        padding: "0 " + tw.theme.spacing[15],
        ":hover": { color: "white" }
      };
    },
    indicatorSeparator: (provided, state) => {
      return {
        ...provided,
        width: "0",
        height: "0"
      };
    },
    indicatorsContainer: (provided, state) => {
      //   console.log("ind con  ", provided);
      return {
        ...provided,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      };
    }
  };

class ArtistSubmitTrack extends Component {
    state = {
        title: "",
        albumArt: [],
        isAlbumModalOpen: false,
        addedComposers: [{}],
        savedComposers: [],
        composers: [],
        composerOptions: [],
        myTotalShare: 100,
        album: "",
        myAlbums: [ ],
        file: "",
        funFact: "",
        genreOptions: [ ],
        emotionOptions: [ ],
        moodOptions: [ ],
        attributeOptions: [ ],
        instrumentOptions: [ ],
        cityOptions: [],
        bpmOptions: [],
        genres: [ ],
        emotions: [ ],
        moods: [ ],
        attributes: [ ],
        instruments: [ ],
        cities: [],
        trackBPM: "fast",
        additionalSongwriters: "false",
        versionsAvailable: "false",
        stemsAvailable: "false",
        submittable: false,
        newAlbumTitle: "",
        additionalVersions: [],
        stems: [],
        tagStems: [],
        additionalStemNames: [
            {label: "Lead Vocals", value: "Lead Vocals"},
            {label: "Vocals", value: "Vocals"},
            {label: "Background Vocals", value: "Background Vocals"},
            {label: "Choir", value: "Choir"},
            {label: "SFX", value: "SFX"}
        ],
        incompleteTaggedStems: [],
        incompleteCustomStems: [],
        completeTaggedStems: [],
        completeCustomStems: [],
        nowPlaying: "",
        wavURL: "",
        savable: true,
    }
//updating our input fields as the user enters keys
toastClose = ()=>{
    console.log("toast is closed manually")
    window.location.replace("/dashboard/tracks/pending")
}
handleInputChange = event => {
    this.props.handleFormUnsaved(true)
    console.log("event ",event.target)
    const { name, value } = event.target;
    if(name === "versionsAvailable"){
        if(this.state.additionalVersions){
            if(this.state.additionalVersions.length > 0){
                Warning({message: "Must Remove Versions First!"})
                return
            }
            else{
                this.setState({
                    [name]: value,
                })
            }
        }
        else{
            this.setState({
                [name]: value,
            })
        }
    }
    else if (name === "stemsAvailable"){
        if(this.state.completeCustomStems.length > 0 || this.state.completeTaggedStems.length > 0){
            Warning({message: "Must Remove Stems First!"})
            return
        }
        else{
            this.setState({
                [name]: value,
            })
        }
    }
    else if(name === "additionalSongwriters"){

        if(this.state.addedComposers.length >0 || this.state.savedComposers.length >0){
            Warning({message: "Must Remove Songwriters First!"})
            return
        }
        if(value === false){
            this.setState({
                addedComposers: [],
                myTotalShare: 100,
            })
        }
        else{
            this.setState({
                [name]: value,
            })
        }
    }
    else if(name === "funFact") {
        if(value.length > 0 && value.length <= 400) {
            this.setState({
                [name]: value,
                funFactComplete: true,
            })
        }
        else if(value.length > 0 && value.length > 400) {
            this.setState({
                [name]: value,
                funFactComplete: "incomplete",
            })
        }
        else {
            this.setState({
                [name]: value,
                funFactComplete: false,
            })
        }
    }
    else{
        this.setState({
            [name]: value,
        })
    }
}
changeCityInput = (newValue)=>{
    console.log("change city input called ", newValue, this.state.city)
    // if(!newValue && this.state.city?.label){
    //     const inputValue =this.state.city?.label
    //     this.setState({cityInput: inputValue})
    //     return inputValue
    // }
    // else{
        const inputValue =newValue
        this.setState({cityInput: inputValue})
        return inputValue
    // }

}
albumLoadingCB=(isLoading)=>{
    this.setState({albumLoading: isLoading})
}
cityKeyDown = (event)=>{
    console.log("calling key down ", event.keyCode, " ", this.state.cityInput)
    if(event.keyCode === 8 && (this.state.cityInput?.length ===1 || !this.state.cityInput)){
        this.handleCityChange(null)
    }
}
altChangeCityInput = (newValue)=>{
    console.log("change city input clicked")
    // const inputValue =newValue
    // this.setState({cityInput: inputValue})
    // return inputValue
}
handleComposerNameChange = (selectedComposer, index)=>{
    this.props.handleFormUnsaved(true)
    console.log("Selected comp ", selectedComposer)
    let addedComposers = this.state.addedComposers
    let dsCompIndex = this.state.composers.findIndex(comp => comp.artistName === selectedComposer.value)
    console.log("ds compInd ",dsCompIndex)
    let nonDSCompIndex = this.state.composers.findIndex(comp => comp.compRecName === selectedComposer.value)
    if(dsCompIndex !== -1){
        addedComposers[index].songwriterExists = false
        delete addedComposers[index].songwriterExistingName
        addedComposers[index].composerId = this.state.composers[dsCompIndex].composerId
        addedComposers[index].name = selectedComposer.value
        addedComposers[index].value = selectedComposer.value
        addedComposers[index].label = selectedComposer.value
        addedComposers[index].dsComp = true
        addedComposers[index].profilePhoto = this.state.composers[dsCompIndex].profilePhoto
        addedComposers[index].artistId = this.state.composers[dsCompIndex].artistId
        addedComposers[index].composerTrackId = this.state.composers[dsCompIndex].composerTrackId
        delete addedComposers[index].nonDSComp
        delete addedComposers[index].email
        this.setState({
            addedComposers: addedComposers
        })
    }
    else if(nonDSCompIndex !== -1){
        addedComposers[index].songwriterExists = false
        delete addedComposers[index].songwriterExistingName
        addedComposers[index].composerId = this.state.composers[nonDSCompIndex].composerId
        addedComposers[index].name = selectedComposer.value
        addedComposers[index].value = selectedComposer.value
        addedComposers[index].label = selectedComposer.value
        addedComposers[index].composerTrackId = this.state.composers[nonDSCompIndex].composerTrackId
        addedComposers[index].nonDSComp = true
        addedComposers[index].proNum = this.state.composers[nonDSCompIndex].compRecProNum
        addedComposers[index].proOrg = {label: this.state.composers[nonDSCompIndex].compRecProOrg, value: this.state.composers[nonDSCompIndex].compRecProOrg}
        delete addedComposers[index].email
        delete addedComposers[index].dsComp
        delete addedComposers[index].artistId
        delete addedComposers[index].profilePhoto
        this.setState({
            addedComposers: addedComposers
        })
    }
    else{
        addedComposers[index].name = selectedComposer.value
        addedComposers[index].value = selectedComposer.value
        addedComposers[index].label = selectedComposer.value
        delete addedComposers[index].artistId
        delete addedComposers[index].composerId
        delete addedComposers[index].composerTrackId
        delete addedComposers[index].nonDSComp
        delete addedComposers[index].dsComp
        this.setState({
            addedComposers: addedComposers
        })
    }
}
handleComposerEmailChange = (event, index)=>{
    this.props.handleFormUnsaved(true)
    event.preventDefault()
    const {value } = event.target
    let addedComposers = this.state.addedComposers
    addedComposers[index].email = value
    if(value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/))
    {
        api.composerEmailCheck(value)
            .then(res=>{
                console.log(" composer check ",res)
                if(res.data){
                    if(res.data.nonDSArtist){
                        console.log("it exists")
                        Warning({message: "We already had a songwriter that matched this email!"})
                        let nonDSArtist = res.data.nonDSArtist[0]
                        addedComposers[index].nonDSArtistExist = true
                        addedComposers[index].nonDSArtistName = nonDSArtist.artistName
                        addedComposers[index].composerId = nonDSArtist.composerId
                        addedComposers[index].name = nonDSArtist.artistName
                        addedComposers[index].value = nonDSArtist.artistName
                        addedComposers[index].label = nonDSArtist.artistName
                        addedComposers[index].nonDSComp = true
                        addedComposers[index].proNum = nonDSArtist.proNum
                        addedComposers[index].proOrg = {label: nonDSArtist.proOrg, value: nonDSArtist.proOrg}
                    }
                    else{
                        addedComposers[index].songwriterExists = true
                        addedComposers[index].songwriterExistingName = res.data[0].artistName
                    }
                }
                else{
                    addedComposers[index].songwriterExists = false
                    delete addedComposers[index].songwriterExistingName
                }
                this.setState({
                    addedComposers: addedComposers,
                })
            })
            .catch(error=>{
                console.log(error)
            })
        console.log("EMAIL COMPLETE")
    }
    else{
        addedComposers[index].songwriterExists = false
        delete addedComposers[index].songwriterExistingName
        this.setState({
            addedComposers: addedComposers,
        })
    }

}
addComposer = ()=>{
    this.props.handleFormUnsaved(true)
    let addedComposers = this.state.addedComposers;
    addedComposers.push({percentError: false})
    this.setState({
        addedComposers: addedComposers,
        myTotalShare: "N/A",
        totalShareError: true
    })
}

handlePercentChange = (event, index, composersType) =>{
    this.props.handleFormUnsaved(true)
    event.preventDefault();
    const {value} = event.target

    console.log('percent value', value - 100);


    let addedComposers = this.state.addedComposers;
    let savedComposers = this.state.savedComposers;

    let composers = this.state[composersType];

    let totalSongwritersPercent = 0;

    let myTotalShare;
    let totalShareError;

    if(value){
        if(isNaN(value)){
            Warning({message: "Please enter a number 1-99"});
            composers[index].percent = value;
            composers[index].percentError = true;
            composers[index].updated = true;
            myTotalShare = "N/A";
            totalShareError = true;
        }
        else if(value <= 0 || value > 99){
            Warning({message: "Please enter a number 1-99"});
            composers[index].percent = value;
            composers[index].percentError = true;
            myTotalShare = "N/A";
            totalShareError = true;
        }
        else{
            composers[index].percent = value;
            composers[index].updated = true
            addedComposers.forEach(composer=>{
                totalSongwritersPercent += parseFloat(composer.percent)
            })
            savedComposers.forEach(composer=>{
                totalSongwritersPercent += parseFloat(composer.percent)
            })
            totalSongwritersPercent = totalSongwritersPercent.toFixed(2)
            console.log('totalSongwritersPercent', totalSongwritersPercent);

            if(isNaN(totalSongwritersPercent)){
                Warning({message: "One of your percentages is causing an error"});
                myTotalShare = "N/A";
                totalShareError = true;
            }
            else if(totalSongwritersPercent > 99){
                Warning({message: "Your total share is less than 1%"})
                myTotalShare = "N/A";
                totalShareError = true;
            }
            else{
                composers[index].percentError = false;
                myTotalShare = parseFloat(100.00 - totalSongwritersPercent).toFixed(2);
                totalShareError = false;
            }
        }

    }
    else {
        composers[index].percent = value;
        composers[index].updated = true;
        let percentValue = value || 0;
        addedComposers.forEach(composer=>{
            totalSongwritersPercent += parseFloat(percentValue)
        })
        savedComposers.forEach(composer=>{
            totalSongwritersPercent += parseFloat(percentValue)
        })
        totalSongwritersPercent = totalSongwritersPercent.toFixed(2)

        console.log('totalSongwritersPercent', totalSongwritersPercent);

        if(isNaN(totalSongwritersPercent)){
            Warning({message: "One of your percentages is causing an error"})
            myTotalShare = "N/A";
            totalShareError = true;
        }
        else if(totalSongwritersPercent > 99){
            Warning({message: "Your total share is less than 1%"})
            myTotalShare = "N/A";
            totalShareError = true;
        }
        else{
            myTotalShare = "N/A";
            totalShareError = true;
        }
    }
    this.setState({
        [composers]: composers,
        myTotalShare: myTotalShare,
        totalShareError: totalShareError,
    });
}

handleCompPercentChange = (event, index)=>{
    this.handlePercentChange(event, index, "addedComposers");
}
handleSavedPercentChange = (event, index)=>{
    this.handlePercentChange(event, index, "savedComposers");
}

handleCheck = event => {
    this.props.handleFormUnsaved(true)
    const target = event.target
    const value = target.checked
    const name = target.name
    this.setState({
        [name]: value,
    });
    };
handleSelect = (event)=> {
console.log(event)
this.props.handleFormUnsaved(true)
this.setState({album: event.target.value});
}
openAlbumModal = ()=>{
    this.setState({isAlbumModalOpen: true})
}
handleAlbumModalClose= ()=>{
    if(this.state.albumLoading){
        return
    }
    else{
        this.setState({isAlbumModalOpen: false})
    }
}
changeNowPlaying = (event, s3Key)=>{
    console.log(s3Key, " ", this.state.nowPlaying)
    event.preventDefault()
    if(this.state.nowPlaying === s3Key){
        console.log("true")
        this.setState({nowPlaying: "stop"})
    }
    else{
        this.setState({nowPlaying: s3Key})
    }
}
unsetNowPlaying = ()=>{
    console.log("unsetting now playing")
    this.setState({nowPlaying: null})
}
trackFileHandler = (event) =>{
    console.log(event.target.files[0])
    // this.state.additionalVersions[index].error = null
    const socket = socketIOClient();
    socket.on("progress" + this.props.match.params.trackId, data => {
      console.log(data)
      this.updateTrackProgress(data)
    });
    this.setState({
        [`trackFile`]: event.target.files[0], [`trackFileLoading`]: true, localname: event.target.files[0].name, trackFileProgress: 0
    },()=>{
        const fileData = new FormData()
        fileData.append('file', this.state[`trackFile`])
        console.log("data ", fileData)
        api.uploadTrackSource(this.props.match.params.trackId, fileData)
            .then(response=>{
                console.log(response)
                if(response.data.success === true){
                   this.setState({[`trackFileLoading`]: false, wavURL: response.data.trackData.wavURL, localname: response.data.trackData.localname })
                }
            })
            .catch(error =>{
                if(error.response?.data === "File Too Large"){
                    Error({message:"File was rejected because it is over 250 mb."})
                } else if(error.response?.data === "File is longer than 12 minutes"){
                    Error({message:"File was rejected because it is longer than 12 minutes."})
                }
                else{
                    Error({message:"An error occured uploading your file."})
                }
                console.log("error ",error, error.code, error.response)
                this.setState({trackFileLoading: false, })
            })
        })
    }
updateTrackProgress = (progressData)=>{
    let parsedData = JSON.parse(progressData)
    console.log("updating progress ", progressData)
    let progress = Math.round(100 * parsedData.loaded/parsedData.total)
    this.setState({trackFileProgress: progress})
}
removeTagStemFromState = (label)=>{
    this.props.handleFormUnsaved(true)
    let index
    this.setState(({incompleteTaggedStems})=>{
        index = incompleteTaggedStems.findIndex(incTS=>incTS.label.toLowerCase() === label.toLowerCase())
        return({
            incompleteTaggedStems:[
                ...incompleteTaggedStems.slice(0,index),
                {
                    ...incompleteTaggedStems[index],
                    localname: null,
                    loading: false,
                    error: null,
                    file: null,
                    markedToRemove: true,
                    progress: 0
                },
                ...incompleteTaggedStems.slice(index+1)
            ].sort(this.alphaSort)
        })
    })
    // let incompleteCustomStems = this.state.incompleteCustomStems.filter((stem, ind)=>ind !== index)
    // this.setState({incompleteCustomStems: incompleteCustomStems})
}
removeCustomStemFromState = (label)=>{
    this.props.handleFormUnsaved(true)
    let index
    this.setState(({incompleteCustomStems})=>{
        index = incompleteCustomStems.findIndex(incTS=>incTS.label.toLowerCase() === label.toLowerCase())
        return({
            incompleteCustomStems:[
                ...incompleteCustomStems.slice(0,index),
                {
                    ...incompleteCustomStems[index],
                    localname: null,
                    loading: false,
                    error: null,
                    file: null,
                    markedToRemove: true,
                    progress: 0
                },
                ...incompleteCustomStems.slice(index+1)
            ].sort(this.alphaSort)
        })
    })
    // let incompleteCustomStems = this.state.incompleteCustomStems.filter((stem, ind)=>ind !== index)
    // this.setState({incompleteCustomStems: incompleteCustomStems})
}
fullyRemoveCustomStemFromState = (index)=>{
    this.setState(({incompleteCustomStems})=>{
        return({
            incompleteCustomStems: incompleteCustomStems.filter((stem, incIndex)=>incIndex !== index).sort(this.alphaSort)
        })
    })
}
removeComposerFromState = (index)=>{
    this.props.handleFormUnsaved(true)
    let composers = this.state.addedComposers
    composers = composers.filter((composer, ind)=> ind !== index)
    let savedComposers = this.state.savedComposers;
    let totalSWPercent = 0;
    composers.forEach(composer=>{
        totalSWPercent += Number(composer.percent)
    })
    savedComposers.forEach(composer=>{
        totalSWPercent += Number(composer.percent)
    })
    if(isNaN(Number(totalSWPercent))){
        Warning({message: "One of your percentages is causing an error"})
        this.setState({addedComposers: composers, myTotalShare: 100 -totalSWPercent, totalShareError: true})
    }
    else {
        this.setState({addedComposers: composers, myTotalShare: 100 -totalSWPercent, totalShareError: false})
    }
    // this.setState({addedComposers: composers})
}
removeComposer = (index)=>{
    this.props.handleFormUnsaved(true)
    let composers = this.state.savedComposers
    let addedComposers = this.state.addedComposers;
    let totalSWPercent = 0;
    composers = composers.filter((composer, ind)=>{
        if(ind !== index){
            return true
        }else{
            api.removeComposerTrack(composer.composerTrackId)
            .then(res=>{
                console.log(res)
                if(res.data.success){
                    Success({message: "Songwriter Removed!"})
                    return false
                }
                else{
                    Error({message: "An Error Occured!"})
                    return true
                }
            })
            .catch(error=>{
                console.log(error)
                Error({message: "An Error Occured!"})
                return true
            })
        }
    })
    composers.forEach(composer=>{
        totalSWPercent += Number(composer.percent)
    })
    addedComposers.forEach(composer=>{
        totalSWPercent += Number(composer.percent)
    })
    if(isNaN(Number(totalSWPercent))){
        Warning({message: "One of your percentages is causing an error"})
        this.setState({savedComposers: composers, myTotalShare: 100 -totalSWPercent, totalShareError: true})
    }
    else{
        this.setState({savedComposers: composers, myTotalShare: 100 -totalSWPercent})
    }
    // this.setState({savedComposers: composers})
}
removeVersionFromState = (index)=>{
    this.props.handleFormUnsaved(true)
    let additionalVersions = this.state.additionalVersions.filter((version, ind)=>ind !==index)
    this.setState({additionalVersions: additionalVersions})
}
versionsFileHandler = (label, event) =>{
this.props.handleFormUnsaved(true)
console.log(event.target.files[0])
// console.log("index ",index)
let versionName
let file = event.target.files[0]
let index
let notNamed = false
this.setState(({
    additionalVersions
})=>{
    index = additionalVersions.findIndex(vers=>vers.value.toUpperCase() === label.toUpperCase() || vers.label.toUpperCase() === label.toUpperCase())
    const socket = socketIOClient();
    socket.on("progressVersion" +this.props.match.params.trackId +additionalVersions[index]?.value.replace(/^\w/, c => c.toUpperCase()).replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}) , data => {
        console.log(data)
        this.updateVersionProgress(data, index)
    });
    versionName = additionalVersions[index]?.value.replace(/^\w/, c => c.toUpperCase())
    if(versionName === undefined || versionName === "Select Version Name"){
        Error({message: "Select version name before uploading"})
        notNamed = true
        return
    }
    else{
        return({
            additionalVersions:[
                ...additionalVersions.slice(0, index),
                {
                    ...additionalVersions[index],
                    localname: file.name,
                    error: null,
                    loading: true,
                    file: file
                },
                ...additionalVersions.slice(index+1)
            ]
        })
    }
},()=>{
    if(notNamed){
        return
    }
    const fileData = new FormData()
    fileData.append('file', file)
    api.uploadTrackVersion(this.props.match.params.trackId, versionName.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}), fileData)
        .then(res=>{
            if(res.data.success){
                this.setState(({additionalVersions})=>{
                    index = additionalVersions.findIndex(vers=>vers.value.toUpperCase() === label.toUpperCase() || vers.label.toUpperCase() === label.toUpperCase())

                    return({
                        additionalVersions:[
                            ...additionalVersions.slice(0, index),
                            {
                                ...additionalVersions[index],
                                wavURL: res.data.version.wavURL,
                                localname: res.data.version.localname,
                                id: res.data.version.id,
                                loading: false,
                            },
                            ...additionalVersions.slice(index+1)
                        ]
                    })
                })
            }
        })
        .catch(error=>{
            if(error.response?.data.indexOf("Mismatched Length") !==-1){
                Error({message: `Versions must be the same exact length as selected.`})
                this.setState(({additionalVersions})=>({
                    additionalVersions:[
                        ...additionalVersions.slice(0, index),
                        {
                            ...additionalVersions[index],
                            error: <div>Versions must be the same exact length as selected. <VersionsExport underline/>.</div>,
                            loading: false
                        },
                        ...additionalVersions.slice(index+1)
                    ]
                }))
            }
            else if(error?.response?.data.indexOf("File Too Large") !==-1){
                Error({message: `File rejected because it is over 250 mb`})
                this.setState(({additionalVersions})=>({
                    additionalVersions:[
                        ...additionalVersions.slice(0, index),
                        {
                            ...additionalVersions[index],
                            error: "Track file is over 250 mb",
                            loading: false
                        },
                        ...additionalVersions.slice(index+1)
                    ]
                }))
            }
            else if(error?.response?.data.indexOf("File is longer than 12 minutes") !==-1){
                Error({message: "File was rejected because it is longer than 12 minutes."})
                this.setState(({additionalVersions})=>({
                    additionalVersions:[
                        ...additionalVersions.slice(0, index),
                        {
                            ...additionalVersions[index],
                            error: "Track file is longer than 12 minutes",
                            loading: false
                        },
                        ...additionalVersions.slice(index+1)
                    ]
                }))
            }
            else{
                this.setState(({additionalVersions})=>({
                    additionalVersions:[
                        ...additionalVersions.slice(0, index),
                        {
                            ...additionalVersions[index],
                            error: error?.response?.statusText || "Error Uploading Version",
                            loading: false
                        },
                        ...additionalVersions.slice(index+1)
                    ]
                }))
            }
        })
})
// let versionName = this.state.additionalVersions[index].value.replace(/^\w/, c => c.toUpperCase())
// if(versionName === undefined){
//     return
// }
// let additionalVersions = this.state.additionalVersions;
// additionalVersions[index].localname = event.target.files[0].name
// additionalVersions[index].error = null
// additionalVersions[index].loading = true
// additionalVersions[index].file = event.target.files[0]
// this.setState({
//     additionalVersions: additionalVersions
// },()=>{
//     const fileData = new FormData()
//     fileData.append('file', additionalVersions[index].file)
//     console.log("data ", fileData)
//         api.uploadTrackVersion(this.props.match.params.trackId,versionName.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}), fileData)
//             .then(res=>{
//                 console.log(res)
//                 if(res.data.success){
//                     additionalVersions[index].wavURL= res.data.version.wavURL
//                     additionalVersions[index].duration = res.data.version.duration
//                     additionalVersions[index].peaks= res.data.version.peaks
//                     additionalVersions[index].localname = res.data.version.localname
//                     additionalVersions[index].id = res.data.version.id
//                     additionalVersions[index].loading = false
//                     this.setState({additionalVersions: additionalVersions})
//                 }
//             })
//             .catch(error =>{
//                 console.log("error ",error, error.code, error.response)
//                 additionalVersions[index].error=error.response.statusText
//                 additionalVersions[index].loading = false
//                 this.setState({additionalVersions: additionalVersions})
//             })
// })

}
updateVersionProgress = (progressData, index)=>{
    let parsedData = JSON.parse(progressData)
    console.log("updating progress ", progressData)
    let progress = Math.round(100 * parsedData.loaded/parsedData.total)
    this.setState(({additionalVersions})=>{
        if(additionalVersions[index]?.value){
            return({
                additionalVersions:[
                    ...additionalVersions.slice(0,index),
                    {
                        ...additionalVersions[index],
                        progress: progress
                    },
                    ...additionalVersions.slice(index+1)
                ]
            })
        }
    })
}
updateTagStemProgress = (progressData, uuid)=>{
    let parsedData = JSON.parse(progressData)
    console.log("updating progress ", progressData)
    let progress = Math.round(100 * parsedData.loaded/parsedData.total)
    this.setState(({incompleteTaggedStems})=>{
        let index = incompleteTaggedStems.findIndex(incTS=>incTS.uuid === uuid)
        if(incompleteTaggedStems[index]?.value){
            return({
                incompleteTaggedStems:[
                    ...incompleteTaggedStems.slice(0,index),
                    {
                        ...incompleteTaggedStems[index],
                        progress: progress
                    },
                    ...incompleteTaggedStems.slice(index+1)
                ].sort(this.alphaSort)
            })
        }
    })
}
updateCustomStemProgress = (progressData, uuid)=>{
    let parsedData = JSON.parse(progressData)
    console.log("updating progress ", progressData)
    let progress = Math.round(100 * parsedData.loaded/parsedData.total)
    this.setState(({incompleteCustomStems})=>{
        let index = incompleteCustomStems.findIndex(incTS=>incTS.uuid === uuid)

        if(incompleteCustomStems[index]?.label){
            return({
                incompleteCustomStems:[
                    ...incompleteCustomStems.slice(0,index),
                    {
                        ...incompleteCustomStems[index],
                        progress: progress
                    },
                    ...incompleteCustomStems.slice(index+1)
                ].sort(this.alphaSort)
            })
        }
    })
}
stemsFileHandler = (label, event, tagOrCustom, uuid) =>{
this.props.handleFormUnsaved(true)
console.log(event.target.files[0])
    let file = event.target.files[0]
    if(tagOrCustom === "tag"){
        let stemName
        let index
        let notNamed = false
        this.setState(({
           incompleteTaggedStems
        })=>{
            index = incompleteTaggedStems.findIndex(incTS=>incTS.uuid === uuid)
            const socket = socketIOClient();
            socket.on("progressStem" +this.props.match.params.trackId +incompleteTaggedStems[index].uuid , data => {
                console.log(data)

                this.updateTagStemProgress(data, uuid)
            });
            console.log(tagOrCustom)
            stemName = incompleteTaggedStems[index].label
            if(stemName?.length < 1){
                Warning({message: "Please Name Stem First"})
                notNamed = true
                return
            }
        return({
            incompleteTaggedStems:[
                ...incompleteTaggedStems.slice(0,index),
                {
                    ...incompleteTaggedStems[index],
                    name: stemName,
                    localname: file.name,
                    error: null,
                    loading: true,
                    file: file,
                    progress: 0
                },
                ...incompleteTaggedStems.slice(index+1)
            ].sort(this.alphaSort)
        })},()=>{
            if(notNamed){
                return
            }
            const fileData = new FormData()
            fileData.append('file', file)
            console.log("data ", fileData)
            api.uploadTrackStem(this.props.match.params.trackId, stemName, fileData, uuid)
                .then(res=>{
                    console.log(res)
                    if(res.data.success){
                        this.setState(({incompleteTaggedStems, completeTaggedStems})=>{
                            index = incompleteTaggedStems.findIndex(incTS=>incTS.uuid === uuid)
                            if(incompleteTaggedStems[index]?.markedToRemove){
                                this.forceRemoveStem(res.data.stem.id)
                                return({
                                    incompleteTaggedStems:[
                                        ...incompleteTaggedStems.slice(0, index),
                                        {
                                            ...incompleteTaggedStems[index],
                                            markedToRemove: false,
                                            loading: false,
                                            progress: 0
                                        },
                                        ...incompleteTaggedStems.slice(index+1)
                                    ].sort(this.alphaSort)
                                })
                            }
                            else{
                                return({
                                    completeTaggedStems:[
                                        ...completeTaggedStems,
                                        {
                                            wavURL: res.data.stem.wavURL,
                                            localname: res.data.stem.localname,
                                            id: res.data.stem.id,
                                            loading: false,
                                            name: res.data.finalStemName,
                                            value : res.data.stem.name.toUpperCase(),
                                            label : res.data.stem.name.toUpperCase(),
                                            uuid: uuidv4()
                                        }
                                    ].sort(this.alphaSort),
                                    incompleteTaggedStems: incompleteTaggedStems.filter(stem=> stem.uuid !== uuid).sort(this.alphaSort)
                                })
                            }
                        })
                    }
                })
                .catch(error =>{
                        console.log("error ",error, error.code, error.response)
                        if(error.response?.data.indexOf("Mismatched Length") !==-1){
                            Error({message: `Stems must be the same exact length as the Full Track.`})
                            this.setState(({incompleteTaggedStems})=>({
                                incompleteTaggedStems: [
                                    ...incompleteTaggedStems.slice(0, index),
                                    {
                                        ...incompleteTaggedStems[index],
                                        error: <div>Stems must be the same exact length as the Full Track. <StemsExport underline/>.</div>,
                                        loading: false,
                                        progress: 0
                                    },
                                    ...incompleteTaggedStems.slice(index+1)
                                ].sort(this.alphaSort)
                            }))
                        }
                        else if(error.response?.data.indexOf("File Too Large") !==-1){
                            Error({message: `File rejected because it is over 250 mb`})
                            this.setState(({incompleteTaggedStems})=>({
                                incompleteTaggedStems: [
                                    ...incompleteTaggedStems.slice(0, index),
                                    {
                                        ...incompleteTaggedStems[index],
                                        error: "Track file is over 250 mb",
                                        loading: false,
                                        progress: 0
                                    },
                                    ...incompleteTaggedStems.slice(index+1)
                                ].sort(this.alphaSort)
                            }))
                        }
                        else{
                            this.setState(({incompleteTaggedStems})=>({
                                incompleteTaggedStems: [
                                    ...incompleteTaggedStems.slice(0, index),
                                    {
                                        ...incompleteTaggedStems[index],
                                        error: error?.response?.statusText || "Error Uploading Stem",
                                        loading: false,
                                        progress: 0
                                    },
                                    ...incompleteTaggedStems.slice(index+1)
                                ].sort(this.alphaSort)
                            }))
                        }
                })
        })
    }
    else{
        let stemName
        let index
        let notNamed = false

        this.setState(({
            incompleteCustomStems
        })=>{
            index = incompleteCustomStems.findIndex(incTS=>incTS.uuid === uuid)
            const socket = socketIOClient();
            socket.on("progressStem" +this.props.match.params.trackId +incompleteCustomStems[index].uuid , data => {
                console.log(data)
                this.updateCustomStemProgress(data, uuid)
              });
            stemName = label
            console.log("data about inc stems" ,incompleteCustomStems, index, stemName)

            if(stemName.length < 1){
                notNamed = true
                Warning({message: "Name stem before uploading"})
                return
            }
        return({
            incompleteCustomStems:[
                ...incompleteCustomStems.slice(0,index),
                {
                    ...incompleteCustomStems[index],
                    name: stemName,
                    localname: file.name,
                    error: null,
                    loading: true,
                    file: file,
                    progress: 0
                },
                ...incompleteCustomStems.slice(index+1)
            ].sort(this.alphaSort)
        })},()=>{
            if(notNamed){
                return
            }
            const fileData = new FormData()
            fileData.append('file', file)
            console.log("data ", fileData)
            api.uploadTrackStem(this.props.match.params.trackId, stemName, fileData, uuid)
                .then(res=>{
                    console.log(res)
                    if(res.data.success){
                        this.setState(({incompleteCustomStems, completeTaggedStems})=>{
                            index = incompleteCustomStems.findIndex(incTS=>incTS.uuid === uuid)
                            if(incompleteCustomStems[index]?.markedToRemove){
                                this.forceRemoveStem(res.data.stem.id)
                                return({
                                    incompleteCustomStems:[
                                        ...incompleteCustomStems.slice(0, index),
                                        {
                                            ...incompleteCustomStems[index],
                                            markedToRemove: false,
                                            loading: false,
                                            progress: 0
                                        },
                                        ...incompleteCustomStems.slice(index+1)
                                    ].sort(this.alphaSort)
                                })
                            }
                            else{
                                return({
                                    completeTaggedStems:[
                                        ...completeTaggedStems,
                                        {
                                            wavURL: res.data.stem.wavURL,
                                            localname: res.data.stem.localname,
                                            id: res.data.stem.id,
                                            loading: false,
                                            name: res.data.finalStemName,
                                            value : res.data.stem.name.toUpperCase(),
                                            label : res.data.stem.name.toUpperCase(),
                                            uuid: uuidv4()
                                        }
                                    ].sort(this.alphaSort),
                                    incompleteCustomStems: incompleteCustomStems.filter(stem=> stem.uuid !== uuid).sort(this.alphaSort)
                                })
                            }
                        })
                    }
                })
                .catch(error =>{
                    if(error.response?.data.indexOf("Mismatched Length") !==-1){
                        Error({message: `Stems must be the same exact length as the Full Track.`})
                        this.setState(({incompleteCustomStems})=>{
                            index = incompleteCustomStems.findIndex(incTS=>incTS.uuid === uuid)
                            return({
                            incompleteCustomStems: [
                                ...incompleteCustomStems.slice(0, index),
                                {
                                    ...incompleteCustomStems[index],
                                    error: <div>Stems must be the same exact length as the Full Track. <StemsExport underline/>.</div>,
                                    loading: false,
                                    progress: 0
                                },
                                ...incompleteCustomStems.slice(index+1)
                            ].sort(this.alphaSort)
                        })})
                    }
                    else if(error.response?.data.indexOf("File Too Large") !==-1){
                        Error({message: `File rejected because it is over 250 mb`})
                        this.setState(({incompleteCustomStems})=>{
                            index = incompleteCustomStems.findIndex(incTS=>incTS.uuid === uuid)
                                return({
                                    incompleteCustomStems: [
                                        ...incompleteCustomStems.slice(0, index),
                                        {
                                            ...incompleteCustomStems[index],
                                            error: "Track file is over 250 mb",
                                            loading: false,
                                            progress: 0
                                        },
                                        ...incompleteCustomStems.slice(index+1)
                                    ].sort(this.alphaSort)
                        })})
                    }
                    else{
                        console.log("error ",error, error.code, error.response)
                        this.setState(({incompleteCustomStems})=>{
                        index = incompleteCustomStems.findIndex(incTS=>incTS.uuid === uuid)
                        return({
                            incompleteCustomStems: [
                                ...incompleteCustomStems.slice(0, index),
                                {
                                    ...incompleteCustomStems[index],
                                    error: error?.response?.statusText || "Error Uploading Stem",
                                    loading: false,
                                    progress: 0
                                },
                                ...incompleteCustomStems.slice(index+1)
                            ].sort(this.alphaSort)
                        })})
                    }

                })
        })
    }
}
handleGenreChange = selectedGenres =>{
console.log(selectedGenres)
this.props.handleFormUnsaved(true)
if(selectedGenres){
    if(selectedGenres.length > 2){
        return
    }
}

this.setState(state => {
    return {
      genres: selectedGenres
    };
})
}
handleEmotionChange = selectedEmotions =>{
this.props.handleFormUnsaved(true)
if(selectedEmotions){
    if(selectedEmotions.length > 1){
        return
    }
}
this.setState(state => {
    return {
      emotions: selectedEmotions
    };
})
}
handleKeywordChange = selectedKeywords =>{
    // console.log('selectedKeywords', selectedKeywords)
    this.props.handleFormUnsaved(true)
    this.setState(state => {
        return {
          keywords: selectedKeywords
        };
    })
    }
handleMoodChange = selectedMoods =>{
this.props.handleFormUnsaved(true)
if(selectedMoods){
    if(selectedMoods.length > 4){
        return
    }
}
this.setState(state => {
    return {
      moods: selectedMoods
    };
})
}
handleAttributeChange = selectedAttributes =>{
    this.props.handleFormUnsaved(true)
    if(selectedAttributes){
        if(selectedAttributes.length > 8){

            return
        }
    }

    this.setState(state => {
        return {
            attributes: selectedAttributes
        };
})
}
handleInstrumentsChange = selectedInstruments =>{
this.props.handleFormUnsaved(true)
this.setState(({incompleteTaggedStems, completeTaggedStems})=>{
    let patchIncomplete = []
    if(selectedInstruments){
        if(selectedInstruments.length > 0){
            if(completeTaggedStems){
                let removedStemTags = completeTaggedStems.filter(stem=>{
                    let stemIndInTagsOptions = selectedInstruments.findIndex(instTag=> instTag.label === stem.name)
                    let stemIndInInstOptions = this.state.instrumentOptions.findIndex(instTagOption=> instTagOption.label === stem.name)
                    if(stemIndInTagsOptions === -1 && stemIndInInstOptions !==-1){
                        selectedInstruments.push({label: this.state.instrumentOptions[stemIndInInstOptions].label , value: this.state.instrumentOptions[stemIndInInstOptions].value, complete: true})
                        Warning({message: "Remove Stem First"})
                        return true
                    }
                    else{
                        return false
                    }
                })
            }
            selectedInstruments.forEach(instTagDropdown=>{
                let instTagIndComplete = this.state.completeTaggedStems.findIndex(stem=>stem.name === instTagDropdown.label)
                if(instTagIndComplete === -1){
                    patchIncomplete.push({label: instTagDropdown.label, value: instTagDropdown.value, uuid: uuidv4()})
                }
            })
        }
        else{
            selectedInstruments = []
            completeTaggedStems.forEach(stem=>{
                let inst = this.state.instrumentOptions.filter(inst=>inst.label === stem.name)[0]
                if(inst){
                    selectedInstruments.push({label: inst.label, value: inst.value, complete: true})
                    Warning({message: "Remove Stem First"})
                }

            })
            patchIncomplete = []
        }
    }
    else{
        selectedInstruments = []
        completeTaggedStems.forEach(stem=>{
            let inst = this.state.instrumentOptions.filter(inst=>inst.label === stem.name)[0]
            selectedInstruments.push({label: inst.label, value: inst.value, complete: true})
            Warning({message: "Remove Stem First"})
        })
        patchIncomplete = []
    }
    return({
        instruments: selectedInstruments,
        incompleteTaggedStems: patchIncomplete.sort(this.alphaSort)
    })
})
// this.setState(state => {
//     let removedStemTags
//     let incompleteTaggedStems =[]
//     if(selectedInstruments){
//         if(selectedInstruments.length > 0){
//             if(this.state.completeTaggedStems){
//                     removedStemTags = this.state.completeTaggedStems.filter(stem=>{
//                     let stemIndInTagsOptions = selectedInstruments.findIndex(instTag=> instTag.label === stem.name)
//                     if(stemIndInTagsOptions === -1){
//                         selectedInstruments.push({label: stem.name, value: stem.name, complete: true})
//                         Warning({message: "Remove Stem First"})
//                         return true
//                     }
//                     else{
//                         return false
//                     }
//                 })
//             }
//             selectedInstruments.forEach(instTagDropdown =>{
//                 let instTagIndComplete = this.state.completeTaggedStems.findIndex(stem=>stem.name === instTagDropdown.value)
//                 if(instTagIndComplete === -1){
//                     incompleteTaggedStems.push({label: instTagDropdown.label, value: instTagDropdown.value})
//                 }
//             })
//         }
//         else{
//             selectedInstruments = []
//             this.state.completeTaggedStems.forEach(stem=>{
//                 selectedInstruments.push({label: stem.name, value: stem.name, complete: true})
//                 Warning({message: "Remove Stem First"})
//             })
//             incompleteTaggedStems = []
//         }
//     }
//     else{
//         selectedInstruments = []
//         this.state.completeTaggedStems.forEach(stem=>{
//             selectedInstruments.push({label: stem.name, value: stem.name, complete: true})
//             Warning({message: "Remove Stem First"})
//         })
//         incompleteTaggedStems = []
//     }


//     return {
//       instruments: selectedInstruments,
//       incompleteTaggedStems: incompleteTaggedStems
//     };
// })
}
handleCityChange = selectedCity =>{
    this.props.handleFormUnsaved(true)
    if(selectedCity){
        if(selectedCity.length > 1){
            return
        }
    }
    this.setState(state => {
        return {
          city: selectedCity,
          cityInput: selectedCity?.label || ""
        };
    })
}
handleCompProOrgChange = (selectedProOrg, index, status) =>{
    this.props.handleFormUnsaved(true)
    let addedComposers
    if (status === 'saved') {
        addedComposers = this.state.savedComposers
    }
    else {
        addedComposers = this.state.addedComposers
    }
    addedComposers[index].proOrg = selectedProOrg
    this.setState({addedComposers: addedComposers})
}

handleCompProNumChange = (event, index, status) =>{
    this.props.handleFormUnsaved(true)
    let addedComposers
    if (status === 'saved') {
        addedComposers = this.state.savedComposers
    }
    else {
        addedComposers = this.state.addedComposers
    }
    let {value} = event.target
    addedComposers[index].proNum = value
    this.setState({addedComposers: addedComposers})
}

addNewVersionDiv = (event)=>{
this.props.handleFormUnsaved(true)
event.preventDefault()
let additionalVersions = this.state.additionalVersions;
additionalVersions.push({label: "Select Version Name", value: "Select Version Name"})
this.setState({additionalVersions: additionalVersions})
}
addNewStemDiv = (event)=>{
    this.props.handleFormUnsaved(true)
    event.preventDefault()
    // let incompleteCustomStems = this.state.incompleteCustomStems;
    this.setState(({incompleteCustomStems})=>({
        incompleteCustomStems: [
            ...incompleteCustomStems,
            {
                value: "",
                label: "",
                forKey: Math.floor(822394 * Math.random()),
                uuid: uuidv4()
            }
        ].sort(this.alphaSort)
    }))
    // incompleteCustomStems.push({value: "",label:""})
    // this.setState({incompleteCustomStems: incompleteCustomStems})
}
handleVersionNameChange = (index, selectedVersionName) =>{
this.props.handleFormUnsaved(true)
let additionalVersions = this.state.additionalVersions;
let selectedVersionCopy = selectedVersionName;
additionalVersions[index].label = selectedVersionCopy.label;
additionalVersions[index].value = selectedVersionCopy.value;
additionalVersions[index].name = selectedVersionCopy.value;
console.log(additionalVersions)
console.log("version info ",selectedVersionName, " ", index)
this.setState({additionalVersions: additionalVersions})

}

handleStemNameChange = (event, index)=>{
    this.props.handleFormUnsaved(true)
    let stemIsTagged = this.state.instruments.findIndex(instTag=>instTag.label.toLowerCase() === event.label.toLowerCase())
    console.log("stem is tagged ", stemIsTagged)
    let instId = this.state.instrumentOptions.filter(inst=> inst.label.toLowerCase() === event.label.toLowerCase())[0]
    console.log("instrument ", instId)
    if(stemIsTagged !== -1 || instId === undefined){
        console.log("handling stem name change ", this.state.incompleteCustomStems)
        this.setState(({incompleteCustomStems})=>({
            incompleteCustomStems:[
                ...incompleteCustomStems.slice(0, index),
                {
                    ...incompleteCustomStems[index],
                    label: event.label,
                    value: event.value,
                },
                ...incompleteCustomStems.slice(index + 1)
            ].sort(this.alphaSort)
        }))
    }
    else{
        console.log("handling stem name change ", this.state.incompleteCustomStems)
        this.setState(({incompleteCustomStems, instruments})=>({
            incompleteCustomStems:[
                ...incompleteCustomStems.slice(0, index),
                {
                    ...incompleteCustomStems[index],
                    label: event.label,
                    value: event.value,
                },
                ...incompleteCustomStems.slice(index + 1)
            ].sort(this.alphaSort),
            instruments:[
                ...instruments,
                {
                    ...instId
                },
            ]
        }))
    }

    // let incompleteCustomStems = this.state.incompleteCustomStems
    // incompleteCustomStems[index] = {label: value, value: value}
    // this.setState({incompleteCustomStems: incompleteCustomStems})
}
removeVersion = async (versionId)=>{
    if(await confirm("Are you sure you want to remove this version? (This cannot be undone)", "Remove Version")){
        api.removeVersion(versionId)
            .then(res=>{
                console.log(res)
                if(res.data.success){
                    console.log(this.state.additionalVersions)
                    Success({message:"Version Removed!"})
                    let remainingVersions = this.state.additionalVersions.filter(version => {
                        console.log("filtering versions")
                        console.log(version)
                        console.log(res.data.deleted)
                        return version.id !== parseInt(res.data.deleted)})
                    this.setState({additionalVersions: remainingVersions})
                }
            })
    }
    else{
        return
    }
}
deleteTrack = async ()=>{
    if(await confirm("Are you sure you want to remove this track? (This cannot be undone)", "Remove Track")){
        api.removeTrack(this.props.match.params.trackId)
        .then(res=>{
            console.log(res)
            if(res.data.success === true){
                this.props.handleFormUnsaved(false)
                this.props.history.push("/dashboard/tracks/pending")
                SuccessRedirect({message: "Track Removed", onClose: this.successToastSubmit})
            }
        })
    }
    else{
        return
    }

}
deleteTrackSource = async ()=>{
    if(await confirm("Are you sure you want to remove the track audio? (This cannot be undone)", "Remove Track")){
        api.removeSource(this.props.match.params.trackId)
        .then(res=>{
            console.log(res)
            if(res.data.success){
                this.setState({wavURL: null, duration: null, localname: null})
            }
        }).catch(error=>{
            console.log(error)
        })
    }
}
removeStem = async (stemId)=>{
    if(await confirm("Are you sure you want to remove this stem? (This cannot be undone)", "Remove Stem")){
        let incompleteTaggedStems = this.state.incompleteTaggedStems;
        api.removeStem(stemId)
            .then(res=>{
                console.log(res)
                if(res.data.success){
                    this.setState(({incompleteTaggedStems, completeCustomStems, completeTaggedStems})=>{
                        let tempStem= {};
                        return({
                            completeTaggedStems: completeTaggedStems.filter(stem=>{
                                if(stem.id !== parseInt(res.data.deleted)){
                                    return true;
                                }
                                else{
                                    tempStem = {
                                        label: stem.name,
                                        value: stem.name,
                                        uuid: uuidv4()
                                    }
                                    return false
                                }
                            }).sort(this.alphaSort),
                            incompleteTaggedStems:[
                                ...incompleteTaggedStems,
                                {
                                    ...tempStem
                                }
                            ].sort(this.alphaSort),
                            completeCustomStems: completeCustomStems.filter(stem => stem.id !== parseInt(res.data.deleted)).sort(this.alphaSort)
                        })
                    })

                    Success({message:"Stem Removed!"})
                }
                else{
                    Error({message: "An error occurred"})
                }
            })
            .catch(error=>{
                Error({message: "An error occurred"})
            })
    }
    else{
        return
    }
}
forceRemoveStem = (stemId)=>{
    api.removeStem(stemId)
        .then(res=>{
            if(res.data.success){

            }
        })
}

componentDidMount = ( )=>{
this.props.handleFormUnsaved(false)
console.log("should show toast ",this.props.history?.location?.state?.showToast )
let showToast = this.props.history?.location?.state?.showToast
let showErrorToast = this.props.history?.location?.state?.showErrorToast

if(showToast){
    Success({message: "Track Added!"})
    this.props.history.location.state.showToast = false
}
if(showErrorToast){
    Error({message: this.props.history.location.state.errorToastMessage})
    this.props.history.location.state.showErrorToast = false
}
api.getTags()
    .then(res=>{
        console.log(res)
        //filter genre tags
        let genreOptions = []
        let emotionOptions = []
        let moodOptions = []
        let attributeOptions = []
        let instrumentOptions = []
        let cityOptions = []
        let bpmOptions = []
        let allOptions = []
        res.data.forEach(tag=>{
            switch(tag.type){
                case 1: genreOptions.push({value: tag.id, label: tag.name.replace(/^\w/, c => c.toUpperCase()), type: "genre"})
                    break;
                case 2: emotionOptions.push({value: tag.id, label: tag.name.replace(/^\w/, c => c.toUpperCase()), type: "emotion"})
                    break;
                case 3: moodOptions.push({value: tag.id, label: tag.name.replace(/^\w/, c => c.toUpperCase()), type: "mood"})
                    break;
                case 4:  attributeOptions.push({value: tag.id, label: tag.name.replace(/^\w/, c => c.toUpperCase()), type: "attribute"})
                    break;
                case 5: instrumentOptions.push({value: tag.id, label: tag.name, type: "instrument"})
                    break;
                case 6:  cityOptions.push({value: tag.id,label: tag.name.replace(/^\w/, c => c.toUpperCase()), type: "city"})
                    break;
                case 8: bpmOptions.push({value: tag.id, label: tag.name, type: "bpm"})
                    break;
                default:
                    break;
            }
        })
        allOptions = allOptions.concat(genreOptions, emotionOptions, moodOptions, attributeOptions, instrumentOptions, cityOptions, bpmOptions )
        this.setState({
            genreOptions: genreOptions,
            emotionOptions: emotionOptions,
            moodOptions: moodOptions,
            attributeOptions: attributeOptions,
            instrumentOptions: instrumentOptions,
            bpmOptions: bpmOptions,
            allOptions: allOptions
        }, ()=>{
let composers = []
api.getMyComposers()
    .then(res=>{
        console.log("composer data ",res.data)
        composers = res.data
        api.getDSComps()
            .then(resp=>{
                console.log("ds composers " ,resp)
                composers = composers.concat(resp.data.deepComps)
                composers = composers.filter((compRes,index)=> {
                    let firstInd = composers.findIndex((comp=> compRes.composerId === comp.composerId))
                    console.log("composer to filter ", compRes, " ", index, " first inbd ", firstInd)
                    if(firstInd === index){
                        return true
                    }
                    else{
                        return false
                    }
            })
            composers.forEach((comp, index)=>{
                console.log("setting labels for comp ", comp)
                if(comp.artistId){
                    composers[index].label = comp.artistName
                    composers[index].value = comp.artistName
                    // if(comp.profilePhoto){
                    //     composers[index].profilePhoto = comp.profilePhoto
                    // }
                }
                else{
                    console.log("compr rec name ", comp.compRecName)
                    composers[index].label = comp.compRecName
                    composers[index].value = comp.compRecName
                    // composers[index].profilePhoto = 4
                }
            })
            this.setState({composers: composers})
        })

    })

// api.getMyAlbums()
//     .then(res=>{
//         console.log(res)
//         if(res.data.length){
//             this.setState({
//                 myAlbums: res.data.map(album=>{
//                     return{
//                         label: album.title,
//                         value: album.id,
//                         imageUrl: album.imageUrl
//                     }
//                 }),
//             }, ()=>{
    api.getIncompleteTrackById(this.props.match.params.trackId)
    .then( async res=>{
        if(res.data.track.trackStatus === "pending"){
            if(this.props.user.admin){
                window.location.replace("/dashboard/approve-track/" + this.props.match.params.trackId)
            }
            else{
                window.location.replace("/dashboard/pending-track/" + this.props.match.params.trackId)
            }
        }

        let tags = res.data.track.tags

        let genres = []
        let emotions = []
        let moods = []
        let attributes = []
        let instruments = []
        let city;
        let lyrics = {}
        let trackBPM
        tags.forEach(tag=>{
            switch(tag.type){
                case 1: genres.push({value: tag.tagId, label: tag.name})
                    break;
                case 2: emotions.push({value: tag.tagId, label: tag.name})
                    break;
                case 3: moods.push({value: tag.tagId, label: tag.name})
                    break;
                case 4: attributes.push({value: tag.tagId, label: tag.name})
                    break;
                case 5: instruments.push({value: tag.tagId, label: tag.name})
                    break;
                case 6: city = {value: tag.tagId, label: tag.name}
                    break;
                case 7: if(tag.tagId === 172){
                    lyrics.exist = "false"
                    }
                    else if(tag.tagId === 173){
                        lyrics.exist = "true"
                    }
                    else if(tag.tagId === 174){
                        lyrics.explicit = "true"
                    }
                    break;
                case 8: trackBPM = tag.tagId
                    break;
            }
        })
        let keywords = []
        let existingKeywords = []
        if(res.data.track.keywords) {
            console.log('track data keywords', res.data.track.keywords)
            // let trackKeywords = res.data.track.keywords
            existingKeywords = res.data.track.keywords.map(keyword => {
                return{
                    id: keyword.keywordId,
                    label: keyword.name,
                    value: keyword.name
                }
            })
        }
        let trackErrorMessage;
        if(res.data.track.invalidFile){
            switch(res.data.track.invalidFile){
                case "File Type":
                    trackErrorMessage = <div>Tracks must be a wav. <TracksExport underline/>.</div>
                    break;
                case "Too Long":
                    trackErrorMessage = <div>Tracks must be 12 minutes or shorter and under 250MB file size. <TracksExport underline/>.</div>
                    break;
                case "Too Large":
                    trackErrorMessage = <div>Tracks must be 12 minutes or shorter and under 250MB file size. <TracksExport underline/>.</div>
                    break;
            }
        }
        let versions = []
        res.data.track.versions.forEach(version=>{
            versions.push({
                value: version.name,
                label: version.name,
                wavURL: version.mp3URL || version.wavURL || "",
                duration: version.duration,
                peaks: version.peaks,
                id: version.id,
                localname: version.localname
            })
            if(version.wavURL === "pending"){
                this.checkVersion(version.id, versions.length-1)
            }
        })
        //get stem data from response
        let stems = res.data.track.stems
        let completeCustomStems = []
        let completeTaggedStems = []
        let incompleteTaggedStems = []
        //for every stem in the response assign a label and value based on the stem nam
        if(stems){
            if(stems.length){
                completeTaggedStems = stems.filter(stem=>{
                    let stemIndInTagsOptions = this.state.instrumentOptions.findIndex(instTag=> instTag.label === stem.name)
                    if(stemIndInTagsOptions ===-1){
                        return true
                    }
                    else{
                        let stemIsTagged = instruments.findIndex(instTag=>instTag.label === stem.name)
                        let instId = this.state.instrumentOptions.filter(inst=> inst.label === stem.name)[0].value
                        if(stemIsTagged === -1){
                            instruments.push({label: stem.name, value: instId})
                        }
                        return true
                    }
                })
            }
            if(instruments.length > 0){
                instruments.forEach( inst=>{
                    let instTagInSavedStems = stems.findIndex(stem=>stem.name === inst.label)
                    if(instTagInSavedStems === -1){
                        inst.uuid = uuidv4()
                        incompleteTaggedStems.push(inst)
                    }
                })
            }
        }
        else{
            //if no stems were retreieved from the response, set the stems to the instruments
            stems = instruments
        }
        completeTaggedStems = completeTaggedStems.map(stem=>{
            stem.uuid = uuidv4()
            stem.wavURL = stem.mp3URL || stem.wavURL
            return stem
        })
        completeCustomStems = completeCustomStems.map(stem=>{
            stem.wavURL = stem.mp3URL || stem.wavURL
            return stem
        })
        // let existingKeywords
        // if(res.data.track.keywords){
        //     existingKeywords = res.data.track.keywords.split(", ")
        //     existingKeywords = existingKeywords.map(keyword=>{
        //         return{
        //         label: keyword,
        //         value: keyword
        //     }
        //     })
        // }
        let savedComposers = []
        let addedComposers =[]
        let myTotalShare = this.state.myTotalShare
        // let composerOptions  = composers
        if(res.data.composers.length > 0){
            res.data.composers.forEach(composer=>{
                myTotalShare -= (composer.percent * 100).toFixed(0)

                if(composer.artistId){
                    savedComposers.push({composerTrackId: composer.composerTrackId, composerId: composer.composerId, artistId: composer.artistId, profilePhoto: composer.profilePhoto, name: composer.artistName, label: composer.artistName, value: composer.artistName, percent: (composer.percent * 100).toFixed(0), dsComp: true, saved: true, })
                }
                else{
                    savedComposers.push({composerTrackId: composer.composerTrackId, composerId: composer.composerId, name: composer.compRecName, label: composer.compRecName, value: composer.compRecName, proOrg: {label: composer.compRecProOrg, value: composer.compRecProOrg}, proNum: composer.compRecProNum, percent: (composer.percent * 100).toFixed(0), nonDSComp: true, saved: true})
                }
            })
        }
        else{
            savedComposers.push()
        }
       console.log("setting comp state", composers)
       console.log('trackData', res.data);
       if(this.props.user.admin){
        api.getArtistAlbumsForAdmin(res.data.track.artistId)
            .then(albumRes=>{
                console.log(albumRes)
                if(albumRes.data.length){
                    let album = albumRes.data.filter(album=> album.id=== res.data.track.albumId)[0]
                    this.setState({
                        myAlbums: albumRes.data.map(album=>{
                            return{
                                label: album.title,
                                value: album.id,
                                imageUrl: album.imageUrl
                            }
                        }),
                        album: {label: album.title, value: album.id, imageUrl: album.imageUrl}
                    })
                }
            })
        }
        else{
            api.getMyAlbums()
                .then(albumRes=>{
                    console.log(albumRes)
                    if(albumRes.data.length){
                        let album = albumRes.data.filter(album=> album.id=== res.data.track.albumId)[0]
                        this.setState({
                            myAlbums: albumRes.data.map(album=>{
                                return{
                                    label: album.title,
                                    value: album.id,
                                    imageUrl: album.imageUrl
                                }
                            }),
                            album: {label: album.title, value: album.id, imageUrl: album.imageUrl}
                        })
                    }
                })
        }
        this.setState({
            artistId: res.data.track.artistId,
            artistName: res.data.track.artistName,
            addedComposers: addedComposers,
            savedComposers: savedComposers,
            // composers: composers,
            myTotalShare: myTotalShare,
            title: res.data.track.trackTitle,
            localname: res.data.track.localname,
            duration: res.data.track.duration,
            attributes: attributes,
            emotions: emotions,
            genres: genres,
            instruments: instruments,
            stems: stems,
            incompleteTaggedStems: incompleteTaggedStems.sort(this.alphaSort),
            completeTaggedStems: completeTaggedStems.sort(this.alphaSort),
            completeCustomStems: completeCustomStems.sort(this.alphaSort),
            moods: moods,
            city: city,
            trackBPM: trackBPM || "",
            containsLyrics: lyrics ? lyrics.exist === "true" ? "true": "false": "false",
            explicitLyrics: lyrics ? lyrics.explicit === "true" ? "true": "false": "false",
            funFact: res.data.track.funFact,
            additionalVersions: versions,
            additionalSongwriters: savedComposers.length ? "true": this.state.additionalSongwriters,
            versionsAvailable: versions.length ? "true": this.state.versionsAvailable,
            stemsAvailable: res.data.track.stems.length ? "true": this.state.stemsAvailable,
            keywords: existingKeywords.length ? existingKeywords : keywords,
            trackStatus: res.data.track.trackStatus,
            wavURL: res.data.track.mp3URL || res.data.track.wavURL || "",
            peaks: res.data.track.peaks,
            loaded: true,
            trackErrorMessage: trackErrorMessage
        })
    })
    .catch(err => {
        console.log("mounting error ", err)
        this.setState({ error: err.message });
    })

})
})

}
setFileState = (file)=>{
this.props.handleFormUnsaved(true)
console.log("setting file state")
this.setState({
    file: file
})
}
setAlbumArtState = (file)=>{
    this.props.handleFormUnsaved(true)
    console.log("setting file state ", file )
    if(file.error){
        this.setState({albumArtError: "File must be an image in a 1x1 ratio!"})
    }
    else{
        this.setState({
            albumArt: file,
            albumPreview: URL.createObjectURL(file[0]),
            albumArtError: null
        })
    }

}
checkStemName = (name, index)=>{
    let inInstruments = this.state.instrumentOptions.filter(inst=>inst.label?.toLowerCase() === name?.toLowerCase())
    let inIncompleteCustomStems = this.state.incompleteCustomStems.filter((stem, ind)=>ind!== index).filter(stem=>stem.label?.toLowerCase() === name?.toLowerCase())
    let inCompleteCustomStems = this.state.completeCustomStems.filter(stem=>stem.name?.toLowerCase() === name?.toLowerCase())

    if(inInstruments.length > 0){
        Error({message:"Custom stem names cannot be in instrument tags. Please use instument tag instead"})
        return true
    }
    else if(inCompleteCustomStems.length > 0){
        Error({message:"Custom stem name already being used"})
        return true
    }
    else if(inIncompleteCustomStems.length > 0){
        Error({message:"Custom stem name already being used"})
        return true
    }
    else{
        return false
    }
}
closeAlbumModal = async ()=>{
    if(this.state.albumModalOpen){
        if(await confirm("Are you sure you want to exit without saving?", "", this.getAlbumRef)){
            this.setState({albumModalOpen: false, newAlbumTitle: "", albumArt: [], albumPreview: null})
            return true
        }
        else{
            return
        }
    }else{
        return false
    }
}
getCities = (cityInput)=>{
    console.log("city input ", cityInput)
    if(cityInput?.length > 2){
            return api.getCities(cityInput)
                .then(res=>{
                    let cities = res.data.cityTags
                    console.log(cities)
                    let citiesOptions = cities.map(city=>{
                        let cityOption = {
                            label: city.name,
                            value: city.id
                        }
                        return cityOption
                    })
                    console.log("city options ", citiesOptions)
                    return citiesOptions
                })
    }else{
        return [{label: "", value: ""}]
    }


}
getAlbumRef = (ref)=>{
    this.confirmModalAlbum = ref
    // console.log("passed ref ",this.confirmModal)
}

checkSubmittability = ()=>{
    let trackSource;
    let detailsComplete
    let tagsComplete
    let stemsComplete;
    let versionsComplete;
    let termsComplete;
    let funFactComplete;
    let attributes = this.state.attributes || []
    let emotions = this.state.emotions || []
    let genres = this.state.genres || []
    let instruments = this.state.instruments || []
    let moods = this.state.moods || []
    let trackBPM = this.state.trackBPM || ""
    let songWriters = this.state.addedComposers.concat(this.state.savedComposers)
    let songWritersComplete;
    console.log("wav url ", this.state.wavURL)
    if(this.state.wavURL ==="error"){
        console.log("styaying inc")
        trackSource = "incomplete"
    }
    else if(this.state.wavURL === null || this.state.wavURL === ""){
        console.log("emtpy wav")
        trackSource = false
    }
    else{
        console.log("makin true")
        trackSource = true
    }
    if(this.state.title.length > 0 && this.state.album){
        detailsComplete = true
    }
    else{
        detailsComplete = false
    }
    if(attributes.length > 0 && emotions.length > 0 && genres.length > 0 && instruments.length > 0
        && moods.length > 0 && this.state.city && trackBPM > 0){
            tagsComplete = true
    }
    else{
            tagsComplete = false
    }
    if(this.state.funFact?.length && this.state.funFact?.length <= 400) {
        funFactComplete = true
    }
    else if(this.state.funFact?.length > 400) {
        funFactComplete = "incomplete"
    }

    if(this.state.stemsAvailable === "true"){
        if(this.state.incompleteTaggedStems.length > 0){
            stemsComplete = "incomplete"
        }
        else{
            stemsComplete = true
        }
    }
    else{
        stemsComplete = false
    }
    if(this.state.additionalVersions.length > 0){
        versionsComplete= this.state.additionalVersions.filter(version=> version.wavURL===undefined || version.wavURL === "" ).length ? "incomplete": true
    }
    else{
        if(this.state.versionsAvailable === "true"){
            versionsComplete = "incomplete"
        }
        else{
            versionsComplete = false
        }
    }
    if(songWriters.length > 0){
        songWritersComplete = songWriters.filter(songWriter=> {
            if(songWriter.percentError){
                return true
            }
            if(songWriter.artistId === undefined){
                if(songWriter.composerId === undefined){
                    if(songWriter.name === undefined || songWriter.email === undefined){
                        return true
                    }
                }
            }
            return false
        }).length > 0 ? "incomplete" : true
    }
    else{
        songWritersComplete = false
    }

    if(this.state.tosAgree && this.state.exclusiveAgree && this.state.removeAgree && this.state.copyAgree){
        termsComplete = true
    }
    else{
        termsComplete = false
    }
    return {
        trackSource: trackSource,
        detailsComplete: detailsComplete,
        tagsComplete: tagsComplete,
        funFactComplete: funFactComplete,
        stemsComplete: stemsComplete,
        versionsComplete: versionsComplete,
        songWritersComplete: songWritersComplete,
        termsComplete: termsComplete
    }
}
checkSavability = ()=>{
    let versionsComplete
    let stemsComplete
    if(this.state.additionalVersions.length > 0){
        versionsComplete= this.state.additionalVersions.filter(version=> version.wavURL===undefined || version.wavURL === "" ).length ? "incomplete": true
    }
    else{

            versionsComplete = false

    }
    let allStems = this.state.incompleteTaggedStems.concat(this.state.incompleteCustomStems)
    if(allStems.length > 0){
        console.log("all stems while loading ", allStems)
        stemsComplete = allStems.filter(stem=>stem.loading).length > 0 ? "incomplete": true
        console.log("stems complete for save", stemsComplete)
    }
    else{
        stemsComplete = false
    }
    return {
        saveVersions: versionsComplete,
        saveStems: stemsComplete
    }
}
componentDidUpdate = ()=>{
    console.log(this.state)
    let {
        trackSource,
        detailsComplete,
        tagsComplete,
        funFactComplete,
        stemsComplete,
        versionsComplete,
        songWritersComplete,
        termsComplete
    } = this.checkSubmittability()
    let {
        saveVersions,
        saveStems
    }= this.checkSavability()
    console.log("save stems ", saveStems, saveVersions !== "incomplete" && saveStems !== "incomplete")
    console.log('track source ', trackSource)
    console.log("terms complete state ", this.state.termsComplete)
    console.log("terms complete ", termsComplete)

    if(this.state.trackSource !== trackSource ||
       this.state.detailsComplete !== detailsComplete ||
       this.state.tagsComplete !== tagsComplete ||
       this.state.funFactComplete !== funFactComplete ||
       this.state.stemsComplete !== stemsComplete ||
       this.state.versionsComplete !== versionsComplete ||
       this.state.songWritersComplete !== songWritersComplete ||
       this.state.termsComplete !== termsComplete)
    {
        this.setState({
            trackSource: trackSource,
            detailsComplete: detailsComplete,
            tagsComplete: tagsComplete,
            funFactComplete: funFactComplete,
            stemsComplete: stemsComplete,
            versionsComplete: versionsComplete,
            songWritersComplete: songWritersComplete,
            termsComplete: termsComplete,
            saveVersions: saveVersions,
            saveStems: saveStems,
            savable: saveVersions !== "incomplete" && saveStems !== "incomplete",
            submittable: (detailsComplete && tagsComplete && (funFactComplete !== "incomplete") && termsComplete && (versionsComplete !== "incomplete") && (stemsComplete !== "incomplete") && (songWritersComplete !== "incomplete"))})
    }
    else if(this.state.saveVersions !== saveVersions || this.state.saveStems !== saveStems){
        this.setState({
            saveVersions: saveVersions,
            saveStems: saveStems,
            savable: saveVersions !== "incomplete" && saveStems !== "incomplete"
        })
    }

}
successToastSubmit = ()=>{
    toast.dismiss()
}
successToastSave = ()=>{
    toast.dismiss()
    // window.location.replace("/dashboard/tracks/incomplete")
}
warnToast = ()=>{
    toast.dismiss()
}
errorToast = ()=>{
    toast.dismiss()
}

updateAlbumsCB = (newAlbum)=>{
    let myAlbumOptions = [...this.state.myAlbums]
    myAlbumOptions.push(newAlbum)
    this.setState({isAlbumModalOpen: false, myAlbums: myAlbumOptions, album: myAlbumOptions[myAlbumOptions.length-1]})
}
handleAlbumChange = (selectedAlbum)=>{
    this.props.handleFormUnsaved(true)
    this.setState(state =>{
        return{
            album: selectedAlbum
        }
    })
}
checkVersion = (versionId, index)=>{
    console.log("checking version")
    if(this.state.additionalVersions[index]?.retries <3 || this.state.additionalVersions[index]?.retries === undefined){
    setTimeout(()=>{
        api.checkVersion(versionId)
            .then(res=>{
                if(res?.data?.version?.wavURL !== "pending"){
                    this.setState(({additionalVersions})=>({
                        additionalVersions:[
                            ...additionalVersions.slice(0,index),
                            {
                                ...additionalVersions[index],
                                wavURL: res.data.version.wavURL
                            },
                            ...additionalVersions.slice(index +1)
                        ]
                    }))
                }
                else{
                    this.setState(({additionalVersions})=>({
                        additionalVersions:[
                            ...additionalVersions.slice(0,index),
                            {
                                ...additionalVersions[index],
                                retries: additionalVersions[index].retries !==undefined ? additionalVersions[index].retries + 1 : 1,
                            },
                            ...additionalVersions.slice(index +1)
                        ]
                    }), ()=>{this.checkVersion(versionId, index)})

                }
            })
            .catch(error=>{
                this.setState(({additionalVersions})=>({
                    additionalVersions:[
                        ...additionalVersions.slice(0,index),
                        {
                            ...additionalVersions[index],
                            retries: additionalVersions[index].retries!==undefined ? additionalVersions[index].retries + 1 : 1,
                        },
                        ...additionalVersions.slice(index +1)
                    ]
                }), ()=>{this.checkVersion(versionId, index)})
            })
    }, 10000)
    }
    else{
        this.setState(({additionalVersions})=>({
            additionalVersions:[
                ...additionalVersions.slice(0,index),
                {
                    ...additionalVersions[index],
                    error: "Upload Failed. Please try again."
                },
                ...additionalVersions.slice(index +1)
            ]
        }),()=>{        api.removeVersion(versionId)
                        .then(res=>{
                            console.log(res)
                            if(res.data.success){

                            }
                        })})
    }
}
handleFormSubmit = async (submitting) => {
    console.log("submitting ", this.state.submittable)
    if(submitting){
        if(await confirm("Are you sure you want to submit this track? (You will not be able to edit this track until it is reviewed)", "Submit Track")){

        }
        else{
            return
        }
    }
    let keywords = this.state.keywords || []
    let trackInfo = {
        title: this.state.title,
        albumId: this.state.album?.value,
        // keywords: this.state.keywords ? this.state.keywords.map(keyword=> keyword.value).join(", ") : undefined,
        funFact: this.state.funFact,
        trackStatus: submitting && this.state.submittable ? "pending": "incomplete",
        context: submitting && this.state.submittable ? "submitting": "saving",
    }
    let tags = []
    if(this.state.genres){
        this.state.genres.forEach(genre=>{tags.push(genre.value)})
    }
    if(this.state.emotions){
        this.state.emotions.forEach(emotion=>{tags.push(emotion.value)})
    }
    if(this.state.moods){
        this.state.moods.forEach(mood=>{tags.push(mood.value)})
    }
    if(this.state.attributes){
        this.state.attributes.forEach(attribute=>{tags.push(attribute.value)})
    }
    if(this.state.instruments){
        this.state.instruments.forEach(instrument=>{tags.push(instrument.value)})
    }
    if(this.state.city){
        tags.push(this.state.city.value)
    }
    if(this.state.trackBPM){
        tags.push(parseInt(this.state.trackBPM))
    }
    if(this.state.containsLyrics === "true"){
        //need to point to lyrics tag in db
        tags.push(173)
        if(this.state.explicitLyrics === "true"){
            tags.push(174)
        }
    }
    else{
        tags.push(172)
    }
    console.log(tags)
    // let tags = {
    //     genres: this.state.genres ? this.state.genres.map(genre=> genre.value): [],
    //     emotions: this.state.emotions ? this.state.emotions.map(emotion=> emotion.value): [],
    //     moods: this.state.moods ? this.state.moods.map(mood=> mood.value): [],
    //     attributes: this.state.attributes ? this.state.attributes.map(attribute=> attribute.value): [],
    //     instruments: this.state.instruments ? this.state.instruments.map(instrument=> instrument.value): [],
    //     lyrics: {exist: this.state.containsLyrics, explicit: this.state.explicitLyrics},
    //     trackBPM: this.state.trackBPM,
    //     city: this.state.city ? this.state.city.value : undefined
    // }
    let composersSaved = 0
    let updatedComposers = this.state.savedComposers.filter(composer=>composer.updated === true)
    let allAddedComposers = this.state.addedComposers.concat(updatedComposers)
    let checkCompErrors = allAddedComposers.filter(composer=>{
        if(composer.percentError){
            return true
        }
        if(composer.songwriterExists){
            return true
        }
    })
    if(checkCompErrors.length > 0 || this.state.totalShareError){
        Error({message: "Your songwriters have errors. Please correct them before saving"})
        return
    }
    let composersNeededSaving = allAddedComposers.length
    console.log(allAddedComposers)
    if(allAddedComposers.length > 0){
    allAddedComposers.forEach(composer=>{
        if(!composer.updated){
            let composerBody
            if(composer.dsComp){
                composerBody = {percent: (composer.percent*(1/100)).toFixed(2), composerId: composer.composerId}
            }
            else if(composer.nonDSComp){
                composerBody = {percent: (composer.percent*(1/100)).toFixed(2), composerId: composer.composerId}
            }
            else{
                composerBody = {
                  proNum: composer.proNum || "",
                  proOrg: composer.proOrg?.value || "",
                  percent: (composer.percent*(1/100)).toFixed(2),
                  name: composer.name,
                  email: composer.email}
            }
            api.createComposerTrack(this.props.match.params.trackId, composerBody)
            .then(res=>{
                console.log(res)
                composersSaved++
                if(composersNeededSaving === composersSaved){
                    api.editTrackById(this.props.match.params.trackId, trackInfo, submitting ? "submitting": "saving", tags)
                        .then(res=>{
                            console.log(res)
                            if(res.data.success ){
                                this.props.handleFormUnsaved(false)
                                if(submitting){
                                    this.props.history.push("/dashboard/tracks/pending")
                                    SuccessRedirect({message: "Track Submitted" , onClose: this.successToastSubmit})
                                }
                                else{
                                    this.props.history.push("/dashboard/tracks/incomplete")
                                    SuccessRedirect( {onClose: this.successToastSave, message:"Track Saved"})
                                }
                            }
                            else{

                                Error({message:"An error occurred!", onClose: this.errorToast})
                            }
                        }).catch(error=>{
                            console.log(error)

                            Error({message:"An error occurred!", onClose:this.errorToast})
                        })
                }
            })
        }
        else{
            console.log(composer)
            api.editComposerTrack(composer.composerTrackId, {percent: (composer.percent*(1/100)).toFixed(2)})
            .then(res=>{
                console.log(res)
                composersSaved++
                if(composersNeededSaving === composersSaved){
                    api.editTrackById(this.props.match.params.trackId, trackInfo, submitting ? "submitting": "saving", tags)
                        .then(res=>{
                            console.log(res)
                            if(res.data.success ){
                                this.props.handleFormUnsaved(false)
                                if(submitting){
                                    this.props.history.push("/dashboard/tracks/pending")
                                    SuccessRedirect({message: "Track Submitted" , onClose: this.successToastSubmit})
                                }
                                else{
                                    this.props.history.push("/dashboard/tracks/incomplete")

                                    SuccessRedirect( {onClose: this.successToastSave, message:"Track Saved"})
                                }
                            }
                            else{

                                Error({message:"An error occurred!", onClose: this.errorToast})
                            }
                        }).catch(error=>{
                            console.log(error)

                            Error({message:"An error occurred!", onClose:this.errorToast})
                        })
                }
            })
        }
    })}
    else{
        api.editTrackById(this.props.match.params.trackId, trackInfo, submitting ? "submitting": "saving", tags, keywords)
        .then(res=>{
            console.log(res)
            if(res.data.success ){
                this.props.handleFormUnsaved(false)
                if(submitting){
                    this.props.history.push("/dashboard/tracks/pending")
                    SuccessRedirect({message: "Track Submitted", onClose: this.successToastSubmit})
                }
                else{
                    this.props.history.push("/dashboard/tracks/incomplete")

                    SuccessRedirect( {onClose: this.successToastSave, message:"Track Saved"})
                }
            }
            else{

                Error({message:"An error occurred!", onClose: this.errorToast})
            }
        }).catch(error=>{
            console.log(error)

            Error({message:"An error occurred!", onClose:this.errorToast})
        })
    }
}
alphaSort = (a,b)=>{
    if(a.name > b.name ){
        return 1
    }
    else if(a.name < b.name){
        return -1
    }
    else{
        return 0
    }
}
constructor(props) {
    super(props)
    this.newTrackOptions = React.createRef();
}

    render (){
        const albumLabel=({value, label, imageUrl})=>(
            <div className="flex items-center">
                <img
                    src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${imageUrl}`}
                    alt="Album Art"
                    className="w-30 h-30 bg-gray-300 mr-15"
                />
                {label}
            </div>
        )
        const composerLabel=({value, label, profilePhoto, artistId, composerTrackId})=>(
            <div className="flex items-center">
                {artistId &&
                    (profilePhoto ?
                        <img
                            src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${profilePhoto}`}
                            alt="Songwriter Photo"
                            className="w-30 h-30 bg-gray-300 mr-15"
                        />:
                        <img
                            src={DefaultAvatar}
                            alt="Songwriter Photo"
                            className="w-30 h-30 bg-gray-300 mr-15"
                        />)
                }
                {label}
            </div>
        )
        const savedComposerLabel=({value, label, profilePhoto, artistId, composerTrackId})=>(
            <div className="flex items-center">
                {artistId &&
                    (profilePhoto ?
                        <img
                            src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${profilePhoto}`}
                            alt="Songwriter Photo"
                            className="w-30 h-30 bg-gray-300 mr-15"
                        />:
                        <img
                            src={DefaultAvatar}
                            alt="Songwriter Photo"
                            className="w-30 h-30 bg-gray-300 mr-15"
                        />)
                }
                {label}
            </div>
        )

        const proOrgs =[{label: "ASCAP", value: "ASCAP"}, {label: "BMI", value: "BMI"},{label: "SESAC", value: "SESAC"}, {label: "SOCAN", value: "SOCAN"}, {label: "GMR", value: "GMR"}]
        return(
            <UserConsumer>
            {({ user, login, logout})=>(
            <div className="pt-50 lg:pt-0">
                <div className="container">

                    {/* {# App Header #} */}
                    <div className="view-header">
                        <h1  id="tracksource" className="view-title">New Track</h1>
                        <div className="view-header-controls">
                            {this.state.trackStatus === "pending" ?
                                <button type="button" onClick={(event)=>{event.preventDefault();}} className="btn btn-disabled btn-primary">Save and Quit</button>
                            :
                                <button type="button" onClick={(event)=>{event.preventDefault();this.handleFormSubmit(false)}} className="btn btn-primary">Save and Quit</button>
                            }
                            {this.state.submittable ?
                                <button type="button" onClick={(event)=>{event.preventDefault();this.handleFormSubmit(true)}} className="btn btn-stroke">Submit for Approval</button>
                            :
                                <button type="button" disabled onClick={(event)=>{event.preventDefault();}} className="btn btn-disabled btn-stroke">Submit for Approval</button>
                            }

                            <Tooltip
                                arrow={false}
                                visible={this.state.showTippy}
                                interactive={true}
                                position={'bottom-end'}
                                trigger={'click'}
                                useContext={true}
                                html={(<NewTrackToolTip
                                        className="hidden"
                                        delete={this.deleteTrack}>
                                      </NewTrackToolTip>
                                )}
                                allowHTML={true}
                                theme={'dark'}
                                onClickOutside={()=>{this.state.showTippy ? this.setState({showTippy: false}): this.setState({showTippy: true})}}
                            >
                            <button type="button" className="icon-btn js-dropdown-toggle"
                                onClick={()=>{this.state.showTippy ? this.setState({showTippy: false}): this.setState({showTippy: true})}}
                                >
                                <span className="icon-dots-vert"></span>
                            </button>

                            </Tooltip>
                        </div>
                    </div>

                </div>

                    <div className="container">
                        {/* {# Track Preview #} */}
                    <div className="form-section-sm">
                        {(this.state.wavURL === "error" || this.state.wavURL.length < 1) && this.state.loaded ?
                        <div className="input-fieldset has-error">
                        {/* {# File #} */}
                        <div className="upload-item">
                            <div className="upload-item-details">
                                <div className="upload-item-title">{this.state.localname}</div>
                            </div>
                            <div className="upload-item-controls">
                                <input id={'trackFile'} type="file" accept={"audio/wav,audio/x-wav"} style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.trackFileHandler(e)} />
                                <label htmlFor={'trackFile'} className="btn btn-stroke btn-sm">
                                    <span className="icon-plus mr-5"></span>
                                        Re-Upload Track
                                </label>
                            </div>
                        </div>
                        <hr className="divider-sm"/>
                        {/* {# Track Preview #} */}
                        <div  className="track-preview track-preview-sm track-preview-sm">
                            <div className="track-preview-play">
                                {this.state.trackFileLoading &&
                                    <CircularDeterminantLabel thickness={2.5} progress={this.state.trackFileProgress || 0} size={"2rem"}/>
                                }
                            </div>
                            <div className="track-preview-waveform">
                                {/* {% include "components/audio/waveform-lg.html" %} */}
                            </div>
                            <div className="track-preview-duration">
                                {/* 3:12 */}
                            </div>
                        </div>
                        <hr className="divider-sm"/>
                        {/* {# Error Message #} */}
                        <div className="error-message">{this.state.trackErrorMessage? this.state.trackErrorMessage:"Please reupload this file, the quality doesn’t seem to match that of our standards"}</div>
                        </div>:

                        <div className="input-fieldset">
                            {/* {# File #} */}
                            <div className="upload-item">
                                <div className="upload-item-details">
                                    <div className="upload-item-title">{this.state.localname}</div>
                                </div>
                                <div className="upload-item-controls">
                                </div>
                            </div>
                            <hr className="divider-sm"/>
                            {/* {# Track Preview #} */}
                            <div className="track-preview track-preview-sm track-preview-sm">
                                <div className="track-preview-play">
                                    <button type="button" onClick={(event)=>{this.changeNowPlaying(event,this.state.wavURL)}} className="circle-icon-border circle-icon-lg">{this.state.nowPlaying === this.state.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                </div>
                                <div className="track-preview-waveform">
                                {this.state.wavURL ?
                                    <TrackWaveform nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={this.state.wavURL} unsetNowPlaying={this.unsetNowPlaying} ></TrackWaveform>:
                                    <></>
                                }
                                </div>
                                <div className="track-preview-duration">
                                    {this.state.duration}
                                </div>
                            </div>
                        </div>}
                    </div>
                    {/* {# App Main Inner #} */}
                    <div className="app-sublayout">

                        {/* {# App Main Content #} */}
                        <div className="app-sublayout-content mb-50 lg:mb-80">

                            {/* {# Form #} */}
                            <form action="" className="ds-form">

                                {/* {# Track Details #} */}
                                <div className="form-section">
                                    <h2 className="text-label-lg mb-40" id="details">Track Details</h2>

                                    {user.admin && (this.state.artistId !== user.user_id) && this.state.artistName &&
                                        <div className="input-group">
                                            {console.log('userInfo', user)}
                                            <label className="input-label" htmlFor="track_title">
                                                Track Artist
                                            </label>
                                            <input
                                                disabled
                                                id="track_artist"
                                                className="form-input"
                                                value={this.state.artistName}
                                                type="text"
                                                name="artist"
                                            />
                                        </div>
                                    }

                                    <div className="input-group">
                                        <label className="input-label" htmlFor="track_title">
                                            Track Title
                                            <span class="input-label-required">*</span>
                                        </label>
                                        {this.state.trackStatus === "pending" ?
                                            <input
                                                disabled
                                                id="track_title"
                                                className="form-input"
                                                value={this.state.title}
                                                onChange={this.handleInputChange}
                                                type="text"
                                                name="title"
                                                placeholder="Enter track title here..."/>:
                                            <input
                                                id="track_title"
                                                className="form-input"
                                                value={this.state.title}
                                                onChange={this.handleInputChange}
                                                type="text"
                                                name="title"
                                                placeholder="Enter track title here..."/>
                                        }
                                    </div>

                                    {/* {# Album Select #} */}
                                    {user.admin ?
                <MicroModal
                                        open={this.state.isAlbumModalOpen}
                                        closeOnEscapePress={true}
                                        handleClose={()=>this.handleAlbumModalClose()}
                                        closeOnOverlayClick={true}
                                        closeOnAnimationEnd={true}
                                        modalOverlayClassName="modal-overlay"
                                        modalClassName="modal micromodal-slide is-open"
                                        modalOverlayStyles={null}
                                        children={handleClose=><AlbumModal loadingCB={this.albumLoadingCB} handleClose={handleClose} updateAlbumsCB={this.updateAlbumsCB} artistId={this.state.artistId}/>}
                                        containerStyles={{background: "#131313", padding:"asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box"}}
                                    ></MicroModal>:
                                    <MicroModal
                                    open={this.state.isAlbumModalOpen}
                                    closeOnEscapePress={true}
                                    handleClose={()=>this.handleAlbumModalClose()}
                                    closeOnOverlayClick={true}
                                    closeOnAnimationEnd={true}
                                    modalOverlayClassName="modal-overlay"
                                    modalClassName="modal micromodal-slide is-open"
                                    modalOverlayStyles={null}
                                    children={handleClose=><AlbumModal loadingCB={this.albumLoadingCB} handleClose={handleClose} updateAlbumsCB={this.updateAlbumsCB} />}
                                    containerStyles={{background: "#131313", padding:"asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box"}}
                                ></MicroModal>
                    }
                                    <div className="form-section" id="album_select">
                                      <div className="input-group">
                                        <label htmlFor="album" className="input-label">
                                          Link to Album
                                          <span class="input-label-required">*</span>
                                          <span className="input-label-info">Choose an Album</span>
                                        </label>
                                        <div className="grid-row-xs">
                                          <div className="col flex-1">
                                              {this.state.trackStatus === "pending" ?
                                                  <Select
                                                      isDisabled
                                                      name="album"
                                                      className="select-container"
                                                      classNamePrefix="select"
                                                      styles={customStyles}
                                                      placeholder="Select an Album"
                                                      value={this.state.album}
                                                      options={this.state.myAlbums}
                                                      onChange={this.handleAlbumChange}
                                                      formatOptionLabel={albumLabel}
                                                  />:
                                                  <Select
                                                      name="album"
                                                      className="select-container"
                                                      classNamePrefix="select"
                                                      styles={customStyles}
                                                      placeholder="Select an Album"
                                                      value={this.state.album}
                                                      options={this.state.myAlbums}
                                                      onChange={this.handleAlbumChange}
                                                      formatOptionLabel={albumLabel}
                                                  />
                                              }
                                          </div>
                                          <div className="col w-auto flex-initial">
                                              <button
                                                type="button"
                                                onClick={(e)=>{e.preventDefault();console.log("album modal button clicked");this.openAlbumModal()}}
                                                className="btn btn-stroke form-btn w-full"
                                              >
                                                <span className="icon-plus mr-5"></span>
                                                Create
                                              </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                </div>

                                {/* {# Tags #} */}
                                <div className="form-section" id="tags">
                                    <h2 className="text-label-lg mb-40">Tags</h2>

                                    {/* {# Genres #} */}
                                    <div className="input-group">
                                        <label htmlFor="genres" className="input-label">
                                            What Genres best describes this track?
                                            <span class="input-label-required">*</span>
                                            <span className="input-label-info">Choose up to 2</span>
                                        </label>
                                        {this.state.trackStatus === "pending" ?
                                            <Select
                                                isDisabled
                                                name="genres"
                                                styles={customStylesMulti}
                                                classNamePrefix={".Select"}
                                                placeholder="Search for Genres..."
                                                value={this.state.genres}
                                                options={this.state.genreOptions}
                                                onChange={this.handleGenreChange}
                                                isMulti
                                                isSearchable
                                            />:
                                            <Select
                                                name="genres"
                                                styles={customStylesMulti}
                                                classNamePrefix={".Select"}
                                                placeholder="Search for Genres..."
                                                value={this.state.genres}
                                                options={this.state.genreOptions}
                                                onChange={this.handleGenreChange}
                                                isMulti
                                                isSearchable
                                            />
                                        }
                                    </div>

                                    {/* {# Emotions #} */}
                                    <div className="input-group">
                                        <label htmlFor="emotions" className="input-label">
                                            What Primary Emotion does this track evoke?
                                            <span class="input-label-required">*</span>
                                            <span className="input-label-info">Choose 1</span>
                                            </label>
                                        {this.state.trackStatus === "pending" ?
                                            <Select
                                                isDisabled
                                                name="emotions"
                                                styles={customStylesMulti}
                                                placeholder="Search for an Emotion..."
                                                value={this.state.emotions}
                                                options={this.state.emotionOptions}
                                                onChange={this.handleEmotionChange}
                                                isMulti
                                                isSearchable
                                            />:
                                            <Select
                                                name="emotions"
                                                styles={customStylesMulti}
                                                placeholder="Search for an Emotion..."
                                                value={this.state.emotions}
                                                options={this.state.emotionOptions}
                                                onChange={this.handleEmotionChange}
                                                isMulti
                                                isSearchable
                                            />
                                        }
                                    </div>
                                    {/* {# Moods #} */}
                                    <div className="input-group">
                                        <label htmlFor="moods" className="input-label">
                                            What Moods best match this track?  <span class="input-label-required">*</span>
                                            <span className="input-label-info">Choose up to 4</span>
                                        </label>
                                        {this.state.trackStatus === "pending" ?
                                            <Select
                                                isDisabled
                                                name="moods"
                                                styles={customStylesMulti}
                                                placeholder="Search for Moods..."
                                                value={this.state.moods}
                                                options={this.state.moodOptions}
                                                onChange={this.handleMoodChange}
                                                isMulti
                                                isSearchable
                                            />:
                                            <Select
                                                name="moods"
                                                styles={customStylesMulti}
                                                placeholder="Search for Moods..."
                                                value={this.state.moods}
                                                options={this.state.moodOptions}
                                                onChange={this.handleMoodChange}
                                                isMulti
                                                isSearchable
                                            />
                                        }
                                    </div>

                                    {/* {# Attributes #} */}
                                    <div className="input-group">
                                        <label htmlFor="attributes" className="input-label">
                                            What Attributes best match this track?  <span class="input-label-required">*</span>
                                            <span className="input-label-info">Choose up to 8</span>
                                        </label>
                                        {this.state.trackStatus === "pending" ?
                                            <Select
                                                isDisabled
                                                name="attributes"
                                                styles={customStylesMulti}
                                                placeholder="Search for Attributes..."
                                                value={this.state.attributes}
                                                options={this.state.attributeOptions}
                                                onChange={this.handleAttributeChange}
                                                isMulti
                                                isSearchable
                                            />:
                                            <Select
                                                name="attributes"
                                                styles={customStylesMulti}
                                                placeholder="Search for Attributes..."
                                                value={this.state.attributes}
                                                options={this.state.attributeOptions}
                                                onChange={this.handleAttributeChange}
                                                isMulti
                                                isSearchable
                                            />
                                        }
                                    </div>


                                    {/* {# Instruments #} */}
                                    <div className="input-group">
                                        <label htmlFor="instruments" className="input-label">
                                            What Instruments are featured on this track?  <span class="input-label-required">*</span>
                                            <span className="input-label-info">Choose as many as apply</span>
                                        </label>
                                        {this.state.trackStatus === "pending" ?
                                            <Select
                                                isDisabled
                                                name="instruments"
                                                styles={customStylesMulti}
                                                placeholder="Search for Instruments..."
                                                value={this.state.instruments}
                                                options={this.state.instrumentOptions}
                                                onChange={this.handleInstrumentsChange}
                                                isMulti
                                                isSearchable
                                            />:
                                            <Select
                                                name="instruments"
                                                styles={customStylesMulti}
                                                placeholder="Search for Instruments..."
                                                value={this.state.instruments}
                                                options={this.state.instrumentOptions}
                                                onChange={this.handleInstrumentsChange}
                                                isMulti
                                                isSearchable
                                            />
                                    }
                                    </div>
                                <KeywordsInput
                                    keywords={this.state.keywords || []}
                                    customStylesMulti={customStylesMulti}
                                    handleKeywordChange={this.handleKeywordChange}
                                    allOptions={this.state.allOptions}
                                />
                                {/* {# Lyrics #} */}
                                <div className="input-group">
                                    <label htmlFor="containsLyrics" className="input-label">
                                        Does this track contain lyrics? <span class="input-label-required">*</span>
                                    </label>
                                    {/* {# Fieldset #} */}
                                    <fieldset className="input-fieldset">
                                        {this.state.trackStatus === "pending" ?
                                            <div className="grid-row-sm">
                                                <div className="col w-1/2">
                                                    <label className="radio-option">

                                                        <input disabled type="radio" className="form-radio" name="containsLyrics" value="false" checked={this.state.containsLyrics === "false"}/>
                                                        No
                                                    </label>
                                                </div>
                                                <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input disabled type="radio" className="form-radio" name="containsLyrics" value="true" checked={this.state.containsLyrics === "true"}/>
                                                        Yes
                                                    </label>
                                                </div>
                                            </div>:
                                            <div className="grid-row-sm">
                                                <div className="col w-1/2">
                                                    <label className="radio-option">

                                                        <input onChange={this.handleInputChange} type="radio" className="form-radio" name="containsLyrics" value="false" checked={this.state.containsLyrics === "false"}/>
                                                        No
                                                    </label>
                                                </div>
                                                <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input onChange={this.handleInputChange} type="radio" className="form-radio" name="containsLyrics" value="true" checked={this.state.containsLyrics === "true"}/>
                                                        Yes
                                                    </label>
                                                </div>
                                            </div>}
                                        {/* {# Conditional Fields #} */}
                                        {this.state.containsLyrics ==="true" ?
                                        <div className="input-fieldset-cond is-visible">
                                            <hr className="divider-sm"/>
                                            <label htmlFor="explicitLyrics" className="input-label">
                                                Does this track contain explicit lyrics?
                                                <span class="input-label-required">*</span>
                                            </label>
                                            {this.state.trackStatus === "pending" ?
                                                <div className="grid-row-sm">
                                                    <div className="col w-1/2">
                                                        <label className="radio-option">
                                                            <input disabled type="radio" className="form-radio" name="explicitLyrics" value="false" checked={this.state.explicitLyrics === "false"}/>
                                                            No
                                                        </label>
                                                    </div>
                                                    <div className="col w-1/2">
                                                        <label className="radio-option">
                                                            <input disabled type="radio" className="form-radio" name="explicitLyrics" value="true" checked={this.state.explicitLyrics === "true"}/>
                                                            Yes
                                                        </label>
                                                    </div>
                                                </div>:
                                                <div className="grid-row-sm">
                                                    <div className="col w-1/2">
                                                        <label className="radio-option">
                                                            <input onChange={this.handleInputChange} type="radio" className="form-radio" name="explicitLyrics" value="false" checked={this.state.explicitLyrics === "false"}/>
                                                            No
                                                        </label>
                                                    </div>
                                                    <div className="col w-1/2">
                                                        <label className="radio-option">
                                                            <input onChange={this.handleInputChange} type="radio" className="form-radio" name="explicitLyrics" value="true" checked={this.state.explicitLyrics === "true"}/>
                                                            Yes
                                                        </label>
                                                    </div>
                                                </div>
                                            }
                                        </div>: <></>}
                                    </fieldset>
                                </div>
                                <div className="input-group">
                                    <label htmlFor="trackBPM" className="input-label">
                                        What BPM accurately describes this track?
                                        <span class="input-label-required">*</span>
                                    </label>
                                    {this.state.trackStatus === "pending" ?
                                        <div className="grid-row-xs">
                                                <div className="col w-full md:w-1/2 mb-20">
                                                    <label className="radio-option radio-btn">
                                                        <input disabled type="radio" className="form-radio" name="trackBPM" value="fast" checked={this.state.trackBPM === "fast"}/>
                                                        Fast
                                                    </label>
                                                </div>
                                                <div className="col w-full md:w-1/2 mb-20">
                                                    <label className="radio-option radio-btn">
                                                        <input disabled type="radio" className="form-radio" name="trackBPM" value="medium" checked={this.state.trackBPM === "medium"}/>
                                                        Medium
                                                    </label>
                                                </div>
                                                <div className="col w-full md:w-1/2 mb-20">
                                                    <label className="radio-option radio-btn">
                                                        <input disabled type="radio" className="form-radio" name="trackBPM" value="medium-fast" checked={this.state.trackBPM === "medium-fast"}/>
                                                        Medium-Fast
                                                    </label>
                                                </div>
                                                <div className="col w-full md:w-1/2 mb-20">
                                                    <label className="radio-option radio-btn">
                                                        <input disabled type="radio" className="form-radio" name="trackBPM" value="medium-slow" checked={this.state.trackBPM === "medium-slow"}/>
                                                        Medium-Slow
                                                    </label>
                                                </div>
                                                <div className="col w-full md:w-1/2 mb-20">
                                                    <label className="radio-option radio-btn">
                                                        <input disabled type="radio" className="form-radio" name="trackBPM" value="slow" checked={this.state.trackBPM === "slow"}/>
                                                        Slow
                                                    </label>
                                                </div>
                                        </div>
                                        :
                                        <div className="grid-row-xs">
                                                {this.state.bpmOptions.map(bpm=>(
                                                    <div className="col w-full md:w-1/2 mb-20">
                                                    <label className="radio-option radio-btn">
                                                        <input
                                                            onChange={this.handleInputChange}
                                                            type="radio"
                                                            className="form-radio"
                                                            name="trackBPM"
                                                            value={bpm.value}
                                                            checked={parseInt(this.state.trackBPM) === parseInt(bpm.value)}
                                                        />
                                                            {bpm.label}
                                                    </label>
                                                </div>
                                                ))}
                                        </div>
                                    }
                                </div>
                                {/* {# City #} */}
                                <div className="input-group">
                                <label htmlFor="city" className="input-label">
                                    In which City was this track made?
                                    <span class="input-label-required">*</span>
                                    <span className="input-label-info">Choose one</span>
                                </label>
                                <p>We recommend using the closest major city for best results.</p>
                                    {this.state.trackStatus === "pending" ?
                                        <AsyncSelect
                                            isDisabled
                                            name="city"
                                            styles={customStyles}
                                            placeholder="Search for cities..."
                                            value={this.state.city}
                                            options={this.state.cityOptions}
                                            onChange={this.handleCityChange}
                                            isSearchable
                                        />:
                                        <AsyncSelect
                                            key={"citSelect"+this.state.city?.label}
                                            name="city"
                                            styles={customStylesCity}
                                            cacheOptions
                                            // defaultOptions={this.state.city ? [{label: this.state.city.label, value: this.state.city.value }]:[]}
                                            // defaultInputValue={this.state.city?.label}
                                            inputValue={this.state.cityInput}
                                            loadOptions={this.getCities}
                                            onInputChange={this.changeCityInput}
                                            onKeyDown={this.cityKeyDown}
                                            onFocus={()=>{
                                                if(this.state.city?.label && !this.state.cityInput){
                                                    this.changeCityInput(this.state.city?.label)
                                                }
                                            }}
                                            onClick={this.altChangeCityInput}
                                            placeholder="Type to add a City..."
                                            value={this.state.city}
                                            // options={this.state.cityOptions}
                                            noOptionsMessage={() => "Options will be populated as you type"}

                                            onChange={this.handleCityChange}
                                            // isSearchable
                                        />
                                    }
                                </div>
                                </div>
                                {/* {# Fun Fact #} */}
                                <div className="form-section" id="fun_fact">
                                    <h2 className="text-label-lg mb-40">Fun Fact</h2>
                                    <div className="input-group">
                                        <label className="input-label" htmlFor="track_fun_fact">
                                            Tell us about your track (optional).
                                        </label>
                                        <textarea
                                            name="funFact"
                                            value={this.state.funFact}
                                            onChange={this.handleInputChange}
                                            id="track_fun_fact"
                                            className="form-textarea"
                                            rows="6"
                                            placeholder="Do you have any interesting facts about this track? What inspired you to make it? This info will be listed in the About section of the track and can help customers make a connection to your track!"
                                            disabled={this.state.trackStatus === "pending"}
                                        >
                                        </textarea>
                                        <CharacterCount
                                            limit="400"
                                            value={this.state.funFact?.length}
                                        />
                                    </div>
                                </div>


                                <div className="form-section" id="versions">
                                <h2 className="text-label-lg mb-40">Versions</h2>
                                <div className="input-group">
                                    <p className="input-label mb-10">Would you like to add alternate versions of this track?</p>
                                    <p className="mb-20">Alternate versions make your track more attractive to potential customers.</p>

                                    <fieldset className="input-fieldset">
                                        {this.state.trackStatus === "pending" ?
                                            <div className="grid-row-sm">
                                                <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input disabled type="radio" className="form-radio" name="versionsAvailable" value="false" checked={this.state.versionsAvailable === "false"}/>
                                                        No
                                                    </label>
                                                </div>
                                                <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input disabled type="radio" className="form-radio" name="versionsAvailable" value="true" checked={this.state.versionsAvailable === "true"}/>
                                                        Yes
                                                    </label>
                                                </div>
                                            </div>:
                                               <div className="grid-row-sm">
                                               <div className="col w-1/2">
                                                   <label className="radio-option">
                                                       <input onChange={this.handleInputChange} type="radio" className="form-radio" name="versionsAvailable" value="false" checked={this.state.versionsAvailable === "false"}/>
                                                       No
                                                   </label>
                                               </div>
                                               <div className="col w-1/2">
                                                   <label className="radio-option">
                                                       <input onChange={this.handleInputChange} type="radio" className="form-radio" name="versionsAvailable" value="true" checked={this.state.versionsAvailable === "true"}/>
                                                       Yes
                                                   </label>
                                               </div>
                                           </div>
                                        }
                                        {this.state.versionsAvailable === "true" ?
                                        <div className="input-fieldset-cond is-visible">
                                            <hr className="divider-sm"/>
                                            <p className="input-label mb-10">
                                                Upload Versions
                                                <span class="input-label-required">*</span>
                                            </p>
                                            <p className="mb-20">48 kHz &amp; 16-bit stereo uncompressed WAV files only accepted. <TechnicalRequirementsLearnMore red/>.</p>

                                                        {this.state.additionalVersions.map((version, index)=>(
                                                            <div key={"additionalVersion"+version.index} className="input-group-sm">
                                                            {version.wavURL  || version.loading || version.error?
                                                            <>
                                                            {!version.error ?
                                                                <div className="upload-item">
                                                                    <div className="upload-item-pre">
                                                                        {/* {# Replace div with react loader, swap with play button once uploaded #} */}
                                                                        {version.loading ?
                                                                           <CircularDeterminantLabel thickness={2.5} progress={version.progress || 0} size={"2rem"}/>:
                                                                            <button type="button" onClick={(event)=>{this.changeNowPlaying(event, version.wavURL)}} className="circle-icon-border circle-icon-sm">{this.state.nowPlaying === version.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                                                        }

                                                                    </div>
                                                                    <div className="upload-item-details">
                                                                        <span className="upload-item-title">{version.label}</span>
                                                                        <div className="upload-item-file">{version.localname}</div>
                                                                    </div>

                                                                    <div className="upload-item-wave">
                                                                        {version.wavURL &&(
                                                                            version.wavURL === "pending" ?
                                                                        <><div>Upload processing...</div></>:
                                                                            <Waveform unsetNowPlaying={this.unsetNowPlaying} nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={version.wavURL} peaks={version.peaks}/>)
                                                                        }
                                                                        {/* {% include "components/audio/waveform-sm.html" %} */}
                                                                        <span className="upload-item-duration">{version.duration}</span>
                                                                    </div>
                                                                    <div className="upload-item-controls">
                                                                        {/* <button className="circle-icon circle-icon-sm"><span className="icon-download"></span></button> */}
                                                                        {this.state.trackStatus === "pending" ?
                                                                            <button type="button" disabled onClick={(event)=>{event.preventDefault();}}  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>:
                                                                            <button type="button" onClick={(event)=>{event.preventDefault();if(version.loading){this.removeVersionFromState(index)}else{this.removeVersion(version.id)}}}  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                                                        }

                                                                    </div>
                                                                </div>:
                                                                <>
                                                                    <div className="upload-item has-error">
                                                                        <div className="upload-item-pre">
                                                                            <button type="button" className="circle-icon-border circle-icon-sm"><span className="icon-play"></span></button>
                                                                        </div>
                                                                        <div className="upload-item-details">
                                                                        <span className="upload-item-title">{version.label}</span>
                                                                        <div className="upload-item-file">{version.localname}</div>
                                                                        </div>
                                                                        <div className="upload-item-wave">
                                                                            {/* {% include "components/audio/waveform-sm.html" %} */}
                                                                            <span className="upload-item-duration">{version.duration}</span>
                                                                        </div>
                                                                        <div className="col btn-group">
                                                                            {this.state.trackStatus === "pending" ?
                                                                                <input disabled id={'versionFile' + index} type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) =>e.preventDefault()} />:
                                                                                version.label === undefined || version.label === "Select Version Name"?
                                                                                    <button type="button" id={'versionFile' + index} type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onClick={(e)=>{e.preventDefault();Error({message:"Select version name before uploading."})}}  />:
                                                                                    <input  disabled={version.label===undefined || version.label==="Select Version Name"} id={'versionFile' + index} type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.versionsFileHandler(version.label, e)} />
                                                                            }
                                                                            <label htmlFor={'versionFile' + index} className={`btn btn-stroke form-btn ${version.label===undefined || version.label==="Select Version Name" ? "btn-disabled": ""}`}>Reupload</label>
                                                                            <button type="button" onClick={(event)=>{event.preventDefault();this.removeVersionFromState(index)}} className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>

                                                                        </div>
                                                                    </div>
                                                                    {/* {# Error Message #} */}
                                                                    <div className="error-message is-file">{version.error}</div>
                                                                </>
                                                            }
                                                                {/* <div>{version.label}</div>
                                                                <br></br> */}
                                                            </>:
                                                                <div className="grid-row-xs">
                                                                    <div className="col w-auto flex-1" key={index}>
                                                                        {this.state.trackStatus === "pending" ?
                                                                            <Select
                                                                                isDisabled
                                                                                name={"versionName" + index}
                                                                                placeholder="Version Name..."
                                                                                styles={customStyles}
                                                                                value={this.state.additionalVersions[index].name}
                                                                                options={versionNameOptions.filter(version => {
                                                                                    for (let i = 0; i < this.state.additionalVersions.length; i++) {
                                                                                        if (this.state.additionalVersions[i].value === version.value) {
                                                                                            return false
                                                                                        }
                                                                                    }
                                                                                    return true
                                                                                })
                                                                                }
                                                                                onChange={(e) => this.handleVersionNameChange(index, e)}
                                                                            />:
                                                                            <Select
                                                                                name={"versionName" + index}
                                                                                placeholder="Version Name..."
                                                                                styles={customStyles}
                                                                                value={{label: this.state.additionalVersions[index].label, value: this.state.additionalVersions[index].value}}
                                                                                options={versionNameOptions.filter(version => {
                                                                                    for (let i = 0; i < this.state.additionalVersions.length; i++) {
                                                                                        if (this.state.additionalVersions[i].value === version.value || this.state.additionalVersions[i]?.label?.toLowerCase() === version?.value?.toLowerCase()) {
                                                                                            return false
                                                                                        }
                                                                                    }
                                                                                    return true
                                                                                })
                                                                                }
                                                                                onChange={(e) => this.handleVersionNameChange(index, e)}
                                                                            />
                                                                        }
                                                                    </div>
                                                                    <div className="col btn-group">
                                                                        {version.label === undefined || version.label === "Select Version Name" ?
                                                                            <button type="button" id={'versionFile' + index} type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onClick={(e)=>{e.preventDefault();Error({message:"Select version name before uploading."})}} />:
                                                                            <input disabled={version.label===undefined || version.label==="Select Version Name"} id={'versionFile' + index} type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.versionsFileHandler(version.label, e)} />
                                                                        }
                                                                        <label htmlFor={'versionFile' + index} className={`btn btn-stroke form-btn ${version.label===undefined || version.label==="Select Version Name" ? "btn-disabled": ""}`}>Upload Version</label>
                                                                        <button type="button" onClick={(event)=>{event.preventDefault();this.removeVersionFromState(index)}} className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                                                    </div>
                                                                </div>
                                                            }
                                                            </div>
                                                        ))}
                                                    {/* </div> */}

                                                {/* </div> */}

                                                {/* </div> */}
                                            <hr className="divider"></hr>

                                            <div className="input-group ">
                                                {this.state.trackStatus === "pending" ?
                                                    <button type="button" disabled className="btn btn-disabled btn-stroke form-btn w-full"><span className="icon-plus mr-5"></span>Add a Version</button>:
                                                    <button type="button" onClick={this.addNewVersionDiv} className="btn btn-stroke form-btn w-full"><span className="icon-plus mr-5"></span>Add a Version</button>
                                                }
                                            </div>

                                        </div>: <></>}
                                    </fieldset>

                                </div>
                            </div>
                            {/* {# Stems #} */}
                            <div className="form-section" id="stems">
                                <h2 className="text-label-lg mb-40">Stems</h2>
                                <div className="input-group">
                                    <p className="input-label mb-10">Would you like to add individual stems for this track?</p>
                                    <p className="mb-20">Stems make your track more attractive to higher-end productions.</p>

                                    <div className="input-fieldset">
                                        {this.state.trackStatus === "pending" ?
                                            <div className="grid-row-sm">
                                                <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input disabled type="radio" className="form-radio" name="stemsAvailable" value="false" checked={this.state.stemsAvailable === "false"}/>
                                                            No
                                                    </label>
                                                </div>
                                                <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input disabled type="radio" className="form-radio" name="stemsAvailable" value="true" checked={this.state.stemsAvailable === "true"}/>
                                                        Yes
                                                    </label>
                                                </div>
                                            </div>:
                                            <div className="grid-row-sm">
                                                <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input onChange={this.handleInputChange} type="radio" className="form-radio" name="stemsAvailable" value="false" checked={this.state.stemsAvailable === "false"}/>
                                                        No
                                                    </label>
                                                </div>
                                                <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input onChange={this.handleInputChange} type="radio" className="form-radio" name="stemsAvailable" value="true" checked={this.state.stemsAvailable === "true"}/>
                                                        Yes
                                                    </label>
                                                </div>
                                            </div>
                                        }
                                        {/* {# Conditional Fields #} */}
                                        {this.state.stemsAvailable === "true" ?
                                        <div className="input-fieldset-cond is-visible">
                                            <hr className="divider-sm"/>
                                            <p className="input-label mb-10">
                                                Upload Stems
                                                <span class="input-label-required">*</span>
                                            </p>
                                            <p className="mb-10">The instruments below are populated based on the instruments featured in the track that you provided earlier.  You can add multiple stems based on the same instrument (Example: Select "Strings" twice when uploading stems for short strings and another stem for long strings.)</p>
                                            <p className="mb-10">48 kHz &amp; 16-bit stereo uncompressed WAV files only accepted. <TechnicalRequirementsLearnMore red/>.</p>
                                            <p className="text-gray-400 text-sm mb-20">Please make sure all stems are the exact same length to the millisecond or your stem will automatically be rejected.</p>
                                            {this.state.completeTaggedStems.map((stem, index) =>(

                                                <div key={"completeTagStem " +stem.id} className="input-group-sm">

                                                    <div className="upload-item">
                                                        <div className="upload-item-pre">
                                                            {/* {# Replace div with react loader, swap with play button once uploaded #} */}
                                                            {stem.loading ?
                                                                <CircularIndeterminant thickness={40}  size={"2rem"}/>
                                                                :
                                                                <button type="button" onClick={(event)=>{this.changeNowPlaying(event, stem.wavURL)}} className="circle-icon-border circle-icon-sm">{this.state.nowPlaying === stem.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                                            }

                                                        </div>
                                                        <div className="upload-item-details">
                                                            <span className="upload-item-title">{stem.name}</span>
                                                            <div className="upload-item-file">{stem.localname}</div>
                                                        </div>

                                                        <div className="upload-item-wave">
                                                            {stem.wavURL ?
                                                            <Waveform unsetNowPlaying={this.unsetNowPlaying} nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={stem.wavURL} peaks={stem.peaks}/>:
                                                            <></>
                                                            }
                                                            {/* {% include "components/audio/waveform-sm.html" %} */}
                                                            <span className="upload-item-duration">{stem.duration}</span>
                                                        </div>
                                                        <div className="upload-item-controls">
                                                            {/* <button className="circle-icon circle-icon-sm"><span className="icon-download"></span></button> */}
                                                            {this.state.trackStatus === "pending" ?
                                                                <button type="button" disabled  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>:
                                                                <button type="button" onClick={(event)=>{event.preventDefault();this.removeStem(stem.id)}}  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                                ))}
                                            {this.state.completeCustomStems.map((stem, index) =>(
                                                <div  key={"completeCustomStem " +stem.id} className="input-group-sm">
                                                    <div className="upload-item">
                                                        <div className="upload-item-pre">
                                                            <button type="button" onClick={(event)=>{this.changeNowPlaying(event, stem.wavURL)}} className="circle-icon-border circle-icon-sm">{this.state.nowPlaying === stem.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                                        </div>
                                                        <div className="upload-item-details">
                                                            <span className="upload-item-title">{stem.name}</span>
                                                            <div className="upload-item-file">{stem.localname}</div>
                                                        </div>
                                                        <div className="upload-item-wave">
                                                            {stem.wavURL ?
                                                            <Waveform unsetNowPlaying={this.unsetNowPlaying} nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={stem.wavURL} peaks={stem.peaks}/>:
                                                            <></>
                                                            }
                                                            {/* {% include "components/audio/waveform-sm.html" %} */}
                                                            <span className="upload-item-duration">{stem.duration}</span>
                                                        </div>
                                                        <div className="upload-item-controls">
                                                            {/* <button className="circle-icon circle-icon-sm"><span className="icon-download"></span></button> */}
                                                            {this.state.trackStatus === "pending" ?
                                                                <button type="button" disabled  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>:
                                                                <button type="button" onClick={(event)=>{event.preventDefault();this.removeStem(stem.id)}}  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                                ))}
                                                                                            {this.state.incompleteTaggedStems.map((stem, index) =>(

                                                <div key={"incompleteTagStem " +stem.label} className="input-group-sm">
                                                {stem.wavURL  || stem.loading || stem.error?
                                                <>
                                                {!stem.error ?
                                                    <div className="upload-item">
                                                        <div className="upload-item-pre">
                                                            {/* {# Replace div with react loader, swap with play button once uploaded #} */}
                                                            {stem.loading ?
                                                                <CircularDeterminantLabel thickness={2.5} progress={stem.progress || 0} size={"2rem"}/>:
                                                                <button type="button" onClick={(event)=>{this.changeNowPlaying(event, stem.wavURL)}} className="circle-icon-border circle-icon-sm">{this.state.nowPlaying === stem.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                                            }

                                                        </div>
                                                        <div className="upload-item-details">
                                                            <span className="upload-item-title">{stem.name}</span>
                                                            <div className="upload-item-file">{stem.localname}</div>
                                                        </div>

                                                        <div className="upload-item-wave">
                                                            {stem.wavURL ?
                                                            <Waveform unsetNowPlaying={this.unsetNowPlaying} nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={stem.wavURL} peaks={stem.peaks}/>:
                                                            <></>
                                                            }
                                                            {/* {% include "components/audio/waveform-sm.html" %} */}
                                                            <span className="upload-item-duration">{stem.duration}</span>
                                                        </div>
                                                        <div className="upload-item-controls">
                                                            {/* <button className="circle-icon circle-icon-sm"><span className="icon-download"></span></button> */}
                                                            {this.state.trackStatus === "pending" ?
                                                                <button type="button" disabled  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>:
                                                                <button type="button" onClick={(event)=>{event.preventDefault();if(stem.loading){this.removeTagStemFromState(stem.label)}else{this.removeStem(stem.id)}}} className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                                            }
                                                        </div>
                                                    </div>:
                                                    <>
                                                        <div className="upload-item has-error">
                                                            <div className="upload-item-pre">
                                                                <button type="button" className="circle-icon-border circle-icon-sm"><span className="icon-play"></span></button>
                                                            </div>
                                                            <div className="upload-item-details">
                                                            <span className="upload-item-title">{stem.label}</span>
                                                            <div className="upload-item-file">{stem.localname}</div>
                                                            </div>
                                                            <div className="upload-item-wave">
                                                                {/* {% include "components/audio/waveform-sm.html" %} */}
                                                                <span className="upload-item-duration">{stem.duration}</span>
                                                            </div>
                                                            <div className="upload-item-controls">
                                                            <div className="upload-item-controls">
                                                                {(stem.label?.length < 1 || stem.label === undefined )?
                                                                    <>
                                                                        <button type="button" id={`taggedStem${stem.name}`} type="button" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onClick={(e)=>{e.preventDefault();Error({message:"Enter stem name before uploading."})}}/>
                                                                        <label htmlFor={`taggedStem${stem.name}`} className="btn btn-stroke btn-sm btn-disbled">Reupload</label>
                                                                    </>:
                                                                    <>
                                                                        <input id={`taggedStem${stem.name}`} type="file" accept={"audio/wav,audio/x-wav"} style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.stemsFileHandler(stem.name, e, "tag", stem.uuid)} />
                                                                        <label htmlFor={`taggedStem${stem.name}`} className="btn btn-stroke btn-sm">Reupload</label>
                                                                    </>
                                                                }
                                                            </div>
                                                            </div>
                                                        </div>
                                                        {/* {# Error Message #} */}
                                                        <div className="error-message is-file">{stem.error}</div>
                                                    </>
                                                }

                                                </>:
                                                     <div className="grid-row-xs">
                                                     <div className="col w-auto flex-1" key={index}>
                                                            <input
                                                                className="form-input"
                                                                name={"newStemName"}
                                                                placeholder="Stem Name..."
                                                                isDisabled
                                                                value={stem.label}
                                                                // onChange={(e) => this.handleStemNameChange(e, index)}
                                                            />
                                                     </div>
                                                     <div className="col btn-group">
                                                         {(stem.label?.length < 1 || stem.label === undefined )?
                                                            <>
                                                                <button type="button" id={'taggedStem' + index} type="button"  style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onClick={(e)=>{e.preventDefault();Error({message:"Enter stem name before uploading."})}} />
                                                                <label htmlFor={'taggedStem' + index} className="btn btn-stroke form-btn btn-disabled">Upload Stem</label>
                                                            </>:
                                                            <>
                                                                <input id={'taggedStem' + index} type="file" accept={"audio/wav,audio/x-wav"} style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.stemsFileHandler(stem.label, e, "tag", stem.uuid)} />
                                                                <label htmlFor={'taggedStem' + index} className="btn btn-stroke form-btn">Upload Stem</label>
                                                            </>
                                                         }
                                                        <button type="button" disabled style={{opacity: 0}}  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>

                                                     </div>
                                                 </div>
                                                }
                                                </div>
                                ))}
                                {this.state.incompleteCustomStems.filter(stem=>!stem.hide).map((stem, index) =>(
                                    <div key={"incompleteCustomStem " +stem.forKey} className="input-group-sm">
                                    {stem.wavURL  || stem.loading || stem.error?
                                    <>
                                    {!stem.error ?
                                        <div className="upload-item">
                                            <div className="upload-item-pre">
                                                {/* {# Replace div with react loader, swap with play button once uploaded #} */}
                                                {stem.loading ?
                                                    <CircularDeterminantLabel thickness={2.5} progress={stem.progress || 0} size={"2rem"}/>:
                                                    <button type="button" onClick={(event)=>{this.changeNowPlaying(event, stem.wavURL)}} className="circle-icon-border circle-icon-sm">{this.state.nowPlaying === stem.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                                }

                                            </div>
                                            <div className="upload-item-details">
                                                <span className="upload-item-title">{stem.name}</span>
                                                <div className="upload-item-file">{stem.localname}</div>
                                            </div>

                                            <div className="upload-item-wave">
                                                {stem.wavURL ?
                                                <Waveform unsetNowPlaying={this.unsetNowPlaying} nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={stem.wavURL} peaks={stem.peaks}/>:
                                                <></>
                                                }
                                                {/* {% include "components/audio/waveform-sm.html" %} */}
                                                <span className="upload-item-duration">{stem.duration}</span>
                                            </div>
                                            <div className="upload-item-controls">
                                                {/* <button className="circle-icon circle-icon-sm"><span className="icon-download"></span></button> */}
                                                {this.state.trackStatus === "pending" ?
                                                    <button type="button" disabled  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>:
                                                    <button type="button" onClick={(event)=>{event.preventDefault();if(stem.loading){this.removeCustomStemFromState(stem.label)}else{this.removeStem(stem.id)}}}  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                                }
                                            </div>
                                        </div>:
                                        <>
                                            <div className="upload-item has-error">
                                                <div className="upload-item-pre">
                                                    <button type="button" className="circle-icon-border circle-icon-sm"><span className="icon-play"></span></button>
                                                </div>
                                                <div className="upload-item-details">
                                                <span className="upload-item-title">{stem.label}</span>
                                                <div className="upload-item-file">{stem.localname}</div>
                                                </div>
                                                <div className="upload-item-wave">
                                                    {/* {% include "components/audio/waveform-sm.html" %} */}
                                                    <span className="upload-item-duration">{stem.duration}</span>
                                                </div>
                                                <div className="upload-item-controls">
                                                <div className="upload-item-controls">
                                                    {(stem.label?.length < 1 || stem.label === undefined )?
                                                        <>
                                                            <button type="button" id={`customStem${stem.name}`} type="button"  style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onClick={(e)=>{e.preventDefault();Error({message:"Enter stem name before uploading."})}} />
                                                            <label htmlFor={`customStem${stem.name}`} className="btn btn-stroke btn-sm btn-disabled">Reupload</label>
                                                        </>:
                                                        <>
                                                            <input id={`customStem${stem.name}`} type="file" accept={"audio/wav,audio/x-wav"} style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.stemsFileHandler(stem.label, e, "custom", stem.uuid)} />
                                                            <label htmlFor={`customStem${stem.name}`} className="btn btn-stroke btn-sm">Reupload</label>
                                                        </>
                                                    }

                                                </div>
                                                </div>
                                            </div>
                                            {/* {# Error Message #} */}
                                            <div className="error-message is-file">{stem.error}</div>
                                        </>
                                    }

                                    </>:
                                         <div className="grid-row-xs">
                                         <div className="col w-auto flex-1" key={index}>
                                             {this.state.trackStatus !== "pending"?
                                                <Select
                                                name={"stemName" + index}
                                                placeholder="Select Stem"
                                                styles={customStyles}
                                                value={stem.label ? {label: stem.label, value: stem.value}: undefined}
                                                options={this.state.instrumentOptions.concat(this.state.additionalStemNames).sort((a,b)=>{
                                                    if(a.label > b.label ){
                                                        return 1
                                                    }
                                                    else if (a.label < b.label){
                                                        return -1
                                                    }
                                                    else{
                                                        return 0
                                                    }
                                                })
                                                }
                                                onChange={(e) => this.handleStemNameChange(e, index)}
                                            />:
                                            <Select
                                                isDisabled
                                                name={"stemName" + index}
                                                placeholder="Select Stem"
                                                styles={customStyles}
                                                value={{label: stem.label, value: stem.value}}
                                                options={this.state.instruments}
                                                onChange={(e) => this.handleVersionNameChange(index, e)}
                                            />
                                             }
                                         </div>
                                         <div className="col btn-group">
                                            {(stem.label?.length < 1 || stem.label === undefined )?
                                                <>
                                                    <button type="button" id={'customStem' + index} type="button" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onClick={(e)=>{e.preventDefault();Error({message:"Enter stem name before uploading."})}}/>
                                                    <label htmlFor={'customStem' + index} className="btn btn-stroke form-btn btn-disabled">Upload Stem</label>
                                                </>:
                                                <>
                                                    <input id={'customStem' + index} type="file" accept={"audio/wav,audio/x-wav"} style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.stemsFileHandler(stem.label, e, "custom", stem.uuid)} />
                                                    <label htmlFor={'customStem' + index} className="btn btn-stroke form-btn">Upload Stem</label>
                                                </>
                                            }

                                             <button type="button"  onClick={(event)=>{event.preventDefault();this.fullyRemoveCustomStemFromState(index)}}className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                         </div>
                                     </div>
                                    }
                                    </div>
                    ))}
                                            <div className="grid-row-xs">
                                                                    <div className="col w-auto flex-1" >
                                                                        <hr className="divider"></hr>
                                                                        <div className="input-group ">
                                                                            {this.state.trackStatus === "pending" ?
                                                                                <button type="button" disabled className="btn btn-disabled btn-stroke form-btn w-full"><span className="icon-plus mr-5"></span>Add a Stem</button>:
                                                                                <button type="button" onClick={this.addNewStemDiv} className="btn btn-stroke form-btn w-full"><span className="icon-plus mr-5"></span>Add a Stem</button>
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                </div>
                                        </div> : <></>}
                                    </div>

                                </div>
                            </div>
                            {/* Composers */}
                            <div className="form-section" id="songwriters">
                                <h2 className="text-label-lg mb-40">Songwriters</h2>
                                {/* {# Songwriters Add #} */}
                                <div className="input-group-sm">
                                <p className="input-label mb-10">Are there additional songwriters on this track?</p>
                                <p className="mb-20">Add additional songwriters who co-wrote this track with you.</p>
                                <fieldset className="input-fieldset">
                                    {this.state.trackStatus === "pending" ?
                                        <div className="grid-row-sm">
                                            <div className="col w-1/2">
                                                <label className="radio-option">
                                                    <input
                                                        disabled
                                                        type="radio"
                                                        className="form-radio"
                                                        name="additionalSongwriters"
                                                        value="false"
                                                        checked={this.state.additionalSongwriters === "false"}
                                                    />
                                                    No
                                                </label>
                                            </div>
                                            <div className="col w-1/2">
                                                <label className="radio-option">
                                                    <input
                                                        disabled
                                                        type="radio"
                                                        className="form-radio"
                                                        name="additionalSongwriters"
                                                        value="true"
                                                        checked={this.state.additionalSongwriters === "true"}
                                                    />
                                                    Yes
                                                </label>
                                            </div>
                                        </div>
                                    :
                                        <div className="grid-row-sm">
                                            <div className="col w-1/2">
                                            <label className="radio-option">
                                                <input
                                                    onChange={this.handleInputChange}
                                                    type="radio"
                                                    className="form-radio"
                                                    name="additionalSongwriters"
                                                    value="false"
                                                    checked={this.state.additionalSongwriters === "false"}
                                                />
                                                No
                                            </label>
                                            </div>
                                            <div className="col w-1/2">
                                            <label className="radio-option">
                                                <input
                                                    onChange={this.handleInputChange}
                                                    type="radio"
                                                    className="form-radio"
                                                    name="additionalSongwriters"
                                                    value="true"
                                                    checked={this.state.additionalSongwriters === "true"}
                                                />
                                                Yes
                                            </label>
                                            </div>
                                        </div>
                                    }
                                    {/* {# Conditional Fields #} */}
                                    {this.state.additionalSongwriters === "true" &&
                                        <div className="input-fieldset-cond is-visible">
                                            <hr className="divider-sm"/>
                                            {this.state.savedComposers.map((addedComposer, index)=>{
                                                return(
                                                <>
                                                    {/* {# Composer Item #} */}
                                                    <div className="grid-row-xs">
                                                        <div className="col w-auto flex-1">
                                                            <fieldset className="input-fieldset">
                                                                <div className="input-group-sm">
                                                                    <label className="input-label" htmlFor={`composer${index}Name`}>
                                                                        Songwriter Name <span class="input-label-required">*</span>
                                                                    </label>

                                                                    <Creatable
                                                                        name={`composer${index}Name`}
                                                                        isDisabled
                                                                        styles={customStyles}
                                                                        className="select-container"
                                                                        classNamePrefix="select"
                                                                        placeholder="Songwriter name..."
                                                                        value={addedComposer}
                                                                        onChange={(selectedComposer)=>this.handleComposerNameChange(selectedComposer, index)}
                                                                        options= {this.state.composers.filter((composer, index)=>{
                                                                            let foundIndex = this.state.addedComposers.findIndex(addComp=>{
                                                                                if(composer.artistId){

                                                                                    return addComp.name === composer.artistName
                                                                                }
                                                                                else{
                                                                                    return addComp.name === composer.compRecName
                                                                                }
                                                                            })
                                                                            if(foundIndex === -1){
                                                                                console.log("keeping ", composer)
                                                                                return true
                                                                            }
                                                                            else{
                                                                                    return false
                                                                            }
                                                                        })}
                                                                        formatOptionLabel={savedComposerLabel}
                                                                        isSearchable
                                                                    />
                                                                    {!addedComposer.dsComp && !addedComposer.nonDSComp &&
                                                                        <>
                                                                            <label className="input-label" htmlFor={`composer${index}Email`}>Songwriter Email</label>
                                                                            <input  disabled id={`composer${index}Email`} name={`composer${index}Email`} className="form-input" value={this.state.savedComposers[index].email} onChange={(event)=>this.handleComposerEmailChange(event, index)}/>
                                                                        </>
                                                                    }
                                                                </div>
                                                                <div className="input-group-sm">
                                                                    <div className="grid-row-xs">
                                                                        {addedComposer.dsComp ?
                                                                            <div className="col w-1/2 md:w-2/3">
                                                                                <label className="input-label">P.R.O. &amp; IPI/CAE #</label>
                                                                                We have this artist's P.R.O. &amp; IPI/CAE #, please enter the ownership percentage and verify this artist's information on the Artist Page.
                                                                            </div>
                                                                        :
                                                                            <>
                                                                                <div className="col w-full md:w-1/3 mb-20 md:mb-0">
                                                                                    <label className="input-label" htmlFor={`composers${index}ProOrg`}>P.R.O.</label>
                                                                                    <Select
                                                                                        name={`composers${index}ProOrg`}
                                                                                        id={`composers${index}ProOrg`}
                                                                                        styles={customStyles}
                                                                                        value={this.state.savedComposers[index].proOrg}
                                                                                        options={proOrgs}
                                                                                        onChange={(selected)=>this.handleCompProOrgChange(selected, index, "saved")}
                                                                                    />
                                                                                </div>
                                                                                <div className="col w-1/2 md:w-1/3">
                                                                                    <label className="input-label" htmlFor={`composers${index}ProNum`}>IPI/CAE #</label>
                                                                                    <input
                                                                                        name={`composer${index}ProNum`}
                                                                                        onChange={(event)=>this.handleCompProNumChange(event, index, "saved")}
                                                                                        id={`composers${index}ProNum`}
                                                                                        className="form-input"
                                                                                        value={this.state.savedComposers[index].proNum}/>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                        <div className="col w-1/2 md:w-1/3">
                                                                            <label
                                                                                className={"input-label "+ (addedComposer.percentError && "text-primary")}
                                                                                htmlFor={`composers${index}Percent`}
                                                                            >
                                                                                Percentage <span class="input-label-required">*</span>
                                                                            </label>
                                                                            <input
                                                                                onChange={(event)=>this.handleSavedPercentChange(event, index)}
                                                                                id={`composers${index}Percent`}
                                                                                className={"form-input " + (addedComposer.percentError && "text-primary")}
                                                                                value={this.state.savedComposers[index].percent}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                        <div className="col btn-group">
                                                            <button
                                                                type="button"
                                                                onClick={(event)=>{event.preventDefault();this.removeComposer(index)}}
                                                                className="circle-icon circle-icon-sm"
                                                            >
                                                                <span className="icon-trash"></span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <hr className="divider"/>
                                                    {/* {# End Composer Item #} */}

                                                    </>
                                                )
                                            })}

                                            {this.state.addedComposers.map((addedComposer, index)=>{
                                                return(
                                                <>
                                                    {/* {# Composer Item #} */}
                                                    <div className="grid-row-xs">
                                                        <div className="col w-auto flex-1">
                                                            <fieldset className="input-fieldset">
                                                                <div className="input-group-sm">
                                                                    <label className="input-label required" htmlFor={`composer${index}Name`}>
                                                                        Songwriter Name <span class="input-label-required">*</span>
                                                                    </label>
                                                                    <p clasName="mb-20">
                                                                        Select from the list of your previous Songwriters or enter a new name.
                                                                    </p>
                                                                    <Creatable
                                                                        name={`composer${index}Name`}
                                                                        styles={customStyles}
                                                                        className="select-container"
                                                                        classNamePrefix="select"
                                                                        placeholder="Songwriter name..."
                                                                        value={this.state.addedComposers[index]}
                                                                        onChange={(selectedComposer)=>this.handleComposerNameChange(selectedComposer, index)}
                                                                        options= {this.state.composers.filter((composer, index)=>{
                                                                            console.log(" compsoer ", composer)
                                                                            let foundIndex = this.state.savedComposers.findIndex(addComp=>{
                                                                                if(composer.artistId){

                                                                                    return addComp.name === composer.artistName
                                                                                }
                                                                                else{
                                                                                    return addComp.name === composer.compRecName
                                                                                }
                                                                            })
                                                                            let foundUnSavedIndex = this.state.addedComposers.findIndex(addComp=>{
                                                                                if(composer.artistId){

                                                                                    return addComp.name === composer.artistName
                                                                                }
                                                                                else{
                                                                                    return addComp.name === composer.compRecName
                                                                                }
                                                                            })
                                                                            if(foundIndex === -1 && foundUnSavedIndex === -1){
                                                                                console.log("keeping ", composer)
                                                                                return true
                                                                            }
                                                                            else{
                                                                                    return false
                                                                            }
                                                                        })}
                                                                        isSearchable
                                                                        formatOptionLabel={composerLabel}
                                                                    />
                                                                </div>
                                                                {!addedComposer.dsComp && !addedComposer.nonDSComp && addedComposer.name &&
                                                                    <div className="input-group-sm">
                                                                        <label className="input-label" htmlFor={`composer${index}Email`}>
                                                                            Songwriter Email <span class="input-label-required">*</span>
                                                                        </label>
                                                                        {addedComposer.songwriterExists &&
                                                                            <div className={"text-primary"}>
                                                                                A songwriter with that email is already registered with the name {addedComposer.songwriterExistingName}. Enter their name above and we'll handle the rest of their info
                                                                            </div>
                                                                        }
                                                                        <input onKeyDown={(e)=>{if(e.keyCode === 13){return null}}} onSubmit={(e)=>e.preventDefault()}  id={`composer${index}Email`} name={`composer${index}Email`} className="form-input" value={this.state.addedComposers[index].email} onChange={(event)=>this.handleComposerEmailChange(event, index)}/>
                                                                    </div>
                                                                }
                                                                <div className="input-group-sm">
                                                                    <div className="grid-row-xs">
                                                                        {addedComposer.dsComp ?
                                                                            <div className="col w-1/2 md:w-2/3">
                                                                                <label className="input-label">P.R.O. &amp; IPI/CAE #</label>
                                                                                We have this artist's P.R.O. &amp; IPI/CAE #, please enter the ownership percentage and verify this artist's information on the Artist Page.
                                                                            </div>
                                                                        :
                                                                            <>
                                                                                <div className="col w-full md:w-1/3 mb-20 md:mb-0">
                                                                                    <label className="input-label" htmlFor={`composers${index}ProOrg`}>
                                                                                        P.R.O.
                                                                                    </label>
                                                                                    <Select
                                                                                        isDisabled={this.state.addedComposers[index].nonDSComp || this.state.addedComposers[index].songwriterExists}
                                                                                        name={`composers${index}ProOrg`}
                                                                                        id={`composers${index}ProOrg`}
                                                                                        styles={customStyles}
                                                                                        value={this.state.addedComposers[index].proOrg}
                                                                                        options={proOrgs}
                                                                                        onChange={(selected)=>this.handleCompProOrgChange(selected, index)}
                                                                                    />
                                                                                </div>
                                                                                <div className="col w-1/2 md:w-1/3">
                                                                                    <label className="input-label" htmlFor={`composers${index}ProNum`}>
                                                                                        IPI/CAE #
                                                                                    </label>
                                                                                    <input
                                                                                        disabled={this.state.addedComposers[index].nonDSComp || addedComposer.songwriterExists}
                                                                                        name={`composer${index}ProNum`}
                                                                                        onChange={(event)=>this.handleCompProNumChange(event, index)}
                                                                                        id={`composers${index}ProNum`}
                                                                                        className="form-input"
                                                                                        value={this.state.addedComposers[index].proNum}
                                                                                    />
                                                                                </div>
                                                                            </>
                                                                        }
                                                                        <div className="col w-1/2 md:w-1/3">
                                                                            <label
                                                                                className={"input-label "+ (addedComposer.percentError && "text-primary")}
                                                                                htmlFor={`composers${index}Percent`}
                                                                            >
                                                                                    Percentage <span class="input-label-required">*</span>
                                                                                </label>
                                                                            <input disabled={addedComposer.songwriterExists} onChange={(event)=>this.handleCompPercentChange(event, index)} id={`composers${index}Percent`} className={"form-input " + (addedComposer.percentError && "text-primary")} value={this.state.addedComposers[index].percent}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </fieldset>
                                                        </div>
                                                        <div className="col btn-group">
                                                            <button
                                                                type="button"
                                                                onClick={(event)=>{event.preventDefault();this.removeComposerFromState(index)}}
                                                                className="circle-icon circle-icon-sm"
                                                            >
                                                                <span className="icon-trash"></span>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <hr className="divider"/>
                                                    {/* {# End Composer Item #} */}

                                                    </>
                                                )
                                            })}

                                            <div className="input-group-sm">
                                                <button
                                                    type="button"
                                                    onClick={(e)=>{e.preventDefault();this.addComposer()}}
                                                    className="btn btn-stroke form-btn w-full"
                                                >
                                                        <span className="icon-plus"></span> Add a Songwriter
                                                </button>
                                            </div>
                                        </div>
                                    }
                                </fieldset>
                                </div>

                                {/* {# Royalties Remaining #} */}
                                <div className="grid-row-sm items-center">
                                    <div className="col flex flex-col flex-1">
                                        <p className="text-white font-medium mb-10">Your Share of Songwriter Royalties</p>
                                        <p className="mb-0">This is what’s leftover after adding additional songwriters.</p>
                                    </div>
                                    <div className="col flex flex-none">
                                        <span className={(this.state.totalShareError ? "text-primary ":"text-white " )+"font-display text-h3"}>{this.state.myTotalShare}%</span>
                                    </div>
                                </div>

                                <hr className="divider"/>


                            {/* Terms */}
                            <div className="form-section" id="terms">
                                <h2 className="text-label-lg mb-40">Terms</h2>
                                <div className="input-group-xs">
                                    <label className="radio-option items-start" htmlFor="tosAgree">
                                        {this.state.trackStatus === "pending" ?
                                            <input disabled type="checkbox" className="form-checkbox" id="tosAgree" name="tosAgree" checked/>:
                                            <input onChange={this.handleCheck} type="checkbox" className="form-checkbox" id="tosAgree" name="tosAgree" checked={this.state.tosAgree}/>
                                        }
                                        <span className="inline-block">Agree to our <a href="/terms/artist" className="text-link" target="_blank">Artist Terms of Service</a> and <a href="/privacy" className="text-link" target="_blank">Privacy Policy</a></span>.
                                    </label>
                                </div>
                                <div className="input-group-xs">
                                    <label className="radio-option items-start" htmlFor="exclusiveAgree">
                                        {this.state.trackStatus === "pending" ?
                                            <input disabled type="checkbox" className="form-checkbox" id="exclusiveAgree" name="exclusiveAgree" checked/>:
                                            <input onChange={this.handleCheck} type="checkbox" className="form-checkbox" id="exclusiveAgree" name="exclusiveAgree" checked={this.state.exclusiveAgree}/>
                                        }
                                        <span className="inline-block">You agree that this track will be exclusively licensed through Deep Sounds and is not on any other Music Library.</span>
                                    </label>
                                </div>
                                <div className="input-group-xs">
                                    <label className="radio-option items-start" htmlFor="removeAgree">
                                        {this.state.trackStatus === "pending" ?
                                            <input disabled type="checkbox" className="form-checkbox" id="removeAgree" name="removeAgree" checked/>:
                                            <input onChange={this.handleCheck} type="checkbox" className="form-checkbox" id="removeAgree" name="removeAgree" checked={this.state.removeAgree}/>
                                        }
                                        <span className="inline-block">You understand that you won't have the ability to remove this track from the website for 1 year after Deep Sounds approves this track.</span>
                                    </label>
                                </div>
                                <div className="input-group-xs">
                                    <label className="radio-option items-start" htmlFor="copyAgree">
                                    {this.state.trackStatus === "pending" ?
                                        <input disabled type="checkbox" className="form-checkbox" id="copyAgree" name="copyAgree" checked/>:
                                        <input onChange={this.handleCheck} type="checkbox" className="form-checkbox" id="copyAgree" name="copyAgree" checked={this.state.copyAgree}/>
                                    }
                                        <span className="inline-block">You own 100% of the rights to this track and it does not infringe on any other artist’s copyright.</span>
                                    </label>
                                </div>
                            </div>
                            <div className="form-section" id="form_actions">
                                <div className="grid-row-xs justify-end">
                                    <div className="col w-full sm:w-1/2 md:w-auto mb-20">
                                    {this.state.submittable ?
                                        <button type="button" onClick={(event)=>{event.preventDefault();this.handleFormSubmit(true)}} className="btn btn-stroke">Submit for Approval</button>:
                                        <button type="button" disabled onClick={(event)=>{event.preventDefault();}} className="btn btn-disabled btn-stroke">Submit for Approval</button>
                                    }
                                    </div>
                                    <div className="col w-full sm:w-1/2 md:w-auto">
                                        {this.state.trackStatus === "pending" ?
                                            <button type="button" onClick={(event)=>{event.preventDefault()}} className="btn btn-disabled btn-primary">Save and Quit</button>:
                                            this.state.savable ?
                                            <button type="button" onClick={(event)=>{event.preventDefault();this.handleFormSubmit(false)}} className="btn btn-primary">Save and Quit</button>:
                                            <button disabled type="button"  className="btn btn-primary">Save and Quit</button>
                                        }
                                    </div>
                                </div>
                            </div>
                            </div>
                          </form>
                            {/* {# End Form #} */}

                        </div>
                        {/* {# End App Main Content #} */}

                        {/* {# Right Info Sidebar #} */}
                        <div className="app-sublayout-info">
                        {/* {# Form Progress #} */}
                        <MediaQuery minWidth={1024}>
                            <Sticky scrollElement=".app-main" stickyClassName="mt-15">
                                <div className="form-progress">
                                    <div className="form-progress-header">
                                        <h4 className="text-label-lg">Progress</h4>
                                    </div>
                                    <div className="form-progress-details">
                                        <p className="text-sm">You must complete all required sections below before you can submit your track for approval.</p>
                                        {/* {# Progress Details #} */}
                                        <ul className="form-progress-sections">
                                            <li className={(this.state.trackSource === true ?"is-complete":"") + (this.state.trackSource === "incomplete" ?"has-error":"")}>
                                                <HashLink to={{hash: "#tracksource"}} >Track File <span className="form-progress-req">Req.</span></HashLink>
                                            </li>
                                            <li className={this.state.detailsComplete ? "is-complete": ""}>
                                                <HashLink to={{hash: "#details"}}>Track Details <span className="form-progress-req">Req.</span></HashLink>
                                            </li>
                                            <li className={this.state.tagsComplete ? "is-complete": ""}>
                                                <HashLink to={{hash: "#tags"}}>Tags <span className="form-progress-req">Req.</span></HashLink>
                                            </li>
                                            <li className={this.state.funFactComplete === true ? "is-complete" : this.state.funFactComplete === 'incomplete' ? "has-error" : ""}>
                                                <HashLink to={{hash: "#fun_fact"}} href="#fun_fact">Fun Fact</HashLink>
                                            </li>
                                            <li className={(this.state.versionsComplete === true ?"is-complete":"") + (this.state.versionsComplete === "incomplete" ?"has-error":"")}>
                                                <HashLink to={{hash: "#versions"}} >Versions</HashLink>
                                            </li>
                                            <li className={(this.state.stemsComplete ===true ? "is-complete":"") + (this.state.stemsComplete==="incomplete" ? "has-error":"")}>
                                                <HashLink to={{hash: "#stems"}} >Stems</HashLink>
                                            </li>
                                            <li className={(this.state.songWritersComplete === true ?"is-complete":"") + (this.state.songWritersComplete === "incomplete" ? "has-error":"")}>
                                                <HashLink to={{hash: "#songwriters"}} >Songwriters</HashLink>
                                            </li>
                                            {this.state.trackStatus === "pending" ?
                                                <li className={"is-complete"}>
                                                    <HashLink to={{hash: "#terms"}} >
                                                        Terms & Conditions <span className="form-progress-req">Req.</span>
                                                    </HashLink>
                                                </li>:
                                                <li className={this.state.termsComplete ? "is-complete": ""}>
                                                    <HashLink to={{hash: "#terms"}}>
                                                        Terms & Conditions <span className="form-progress-req">Req.</span>
                                                    </HashLink>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </Sticky>
                        </MediaQuery>
                    </div>
                        {/* {# End Info Sidebar #} */}

                    </div>
                    {/* {# End App Main Inner #} */}

                </div>
                {/* {# End Container #} */}


            </div>

            )}
            </UserConsumer>
        )
    }

}

export default ArtistSubmitTrack

import React, { useEffect, useState } from "react";
import ContentIDCode from "./ContentIDCode";
import LoadingButton from "../../FormElements/LoadingButton";
import api from "../../utils/api";
import { handleAPIError } from "../../../utils/handleAPIError";
import LoadingContainer from "../../Loading/LoadingContainer";
import moment from "moment";

export default function ContentIDTab(props) {
  const { licenseNumberDisplay } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [contentIDCode, setContentIDCode] = useState(null);
  const [expiration, setExpiration] = useState(null);

  useEffect(() => {
    const query = {
      licenseNumberDisplay,
    };

    api
      .getContentIDCode(query)
      .then((response) => {
        console.log("getContentIDCode response", response);
        const { data } = response.data || {};
        const { code, expiration } = data;
        const expirationFormatted = moment(expiration).format("MM/DD/YYYY");
        setContentIDCode(code);
        setExpiration(expirationFormatted);
        setIsLoading(false);
      })
      .catch((error) => {
        handleAPIError(error);
      });
  }, []);

  const generateCode = () => {
    setIsLoading(true);

    const postData = {
      licenseNumberDisplay,
    };

    api
      .createContentIDCode(postData)
      .then((response) => {
        console.log("createContentIDCode response", response);
        const { data } = response.data || {};
        const { code, expiration } = data;
        const expirationFormatted = moment(expiration).format("MM/DD/YYYY");
        setContentIDCode(code);
        setExpiration(expirationFormatted);
        setIsLoading(false);
      })
      .catch((error) => {
        handleAPIError(error);
      });

    // setContentIDCode(contentIDPrefix + '-' + licenseNumberDisplay);
    // setExpiration("05/31/2022");
  };

  return (
    <div className="tab-item-content active" id="tab_details">
      <div className="grid-row">
        {/* {# License Number #} */}
        <div className="col mb-60">
          <h3 className="text-label-sm mb-15">Content ID Code</h3>
          <LoadingContainer
            isLoaded={!isLoading}
            style={{
              height: "5.5rem"
            }}
          >
            {contentIDCode ? (
              <ContentIDCode
                contentIDCode={contentIDCode}
                expiration={expiration}
              />
            ) : (
              <LoadingButton
                className="btn btn-primary inline-block"
                isLoading={isLoading}
                onClick={generateCode}
              >
                Generate Code
              </LoadingButton>
            )}
          </LoadingContainer>
          <p className="mt-40">
            <a
              className="text-white underline"
              href="https://help.deepsounds.com/en/articles/5973741-releasing-copyright-claims-on-youtube"
              target="_blank"
            >
              Learn more
            </a>{" "}
            about releasing copyright claims on YouTube.
          </p>
        </div>
      </div>
    </div>
  );
}

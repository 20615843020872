import React, { Component } from "react";
import { NavLink } from "react-router-dom";
class ArtistCardToolTip extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {

            }

    render() {
        return (    
                <div className="dropdown-menu">
                    <div className="dropdown-group">
                        <NavLink to={"/artists/" + this.props.artistURL} >Artist Page</NavLink>
                        <a href="#" onClick={()=>this.props.playTracks(this.props.tracks, this.props.artistId)}>Play Tracks</a>
                        <a href="#" onClick={()=>this.props.shareArtist(this.props.artistId, this.props.artistURL)}>Share Artist</a>
                    </div>
                </div>
        )
    }

}
export default ArtistCardToolTip
import React, { useContext, useEffect } from "react";
import currencyFormatter from "../../utils/currencyFormatter";
import { SubscriptionContext } from "../../contexts/Subscription";
import LoadingContainer from "../Loading/LoadingContainer";
import SubscriptionSwitchText from "../Forms/LicenseForm/SubscriptionSwitchText";

const SubscriptionItem = (props) => {
  const {
    getAlternatePrice,
    toggleSelectedPrice,
  } = useContext(SubscriptionContext);

  const {
    index,
    isComplete,
    selectedPrice
  } = props;

  console.log('selectedPrice', selectedPrice);

  const { interval } = selectedPrice?.recurring || {};

  const alternatePrice = getAlternatePrice(selectedPrice);
  console.log('alternatePrice', alternatePrice);
  const alternateAmount = alternatePrice?.unit_amount / 100;
  const alternateAmountFormatted = currencyFormatter(alternateAmount);
  const subscriptionAlternateInterval = alternatePrice?.recurring.interval;
  const subscriptionType = selectedPrice?.product?.metadata?.plan_type;

  return (
    <LoadingContainer isLoaded={selectedPrice?.id}>
      <div class="py-20" key={"cartItem-" + index}>
        {/* {# Track Item #} */}
        <div class="flex flex-1 items-center justify-between pb-15">
          {/* {# Artwork #} */}
          <div class="license-panel-subscription">
            <span class="text-icon-lg icon-checklist text-gray-400"></span>
          </div>
          {/* {# Details #} */}
          <div class="flex flex-col flex-1 leading-snug pr-20">
            <span class="text-white text-sm md:text-base font-large">
              {selectedPrice?.product?.description}
            </span>
            <span
              class="text-xs text-gray-500 mb-5"
              style={{ textTransform: "capitalize" }}
            >
            </span>

            {!isComplete && (
              <button
                className="link mt-10 text-left"
                onClick={
                  (event) => {
                    event.preventDefault();
                    toggleSelectedPrice();
                  }
                }
              >
                <p>
                  <span className="icon-history mr-5"></span>
                  <SubscriptionSwitchText
                    subscriptionAlternateInterval={subscriptionAlternateInterval}
                    subscriptionAlternatePriceFormatted={alternateAmountFormatted}
                    subscriptionType={subscriptionType}
                  />
                </p>
              </button>
            )}
          </div>
          {/* {# Controls #} */}
          <div class="flex-none flex flex-col items-end">
            {/* {# Sub Price #} */}
            <div class="flex flex-col flex-1 leading-snug">
              <span class="text-sm text-white" style={{ textAlign: "end" }}>
                {currencyFormatter(selectedPrice?.unit_amount / 100)}
              </span>
              <span class="text-xs text-gray-500" style={{ textAlign: "end" }}>
                {interval === "year" ? (
                  <>per Year</>
                ) : (
                  <>
                    per Month
                  </>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
    </LoadingContainer>
  );
};
export default SubscriptionItem;

import React, { useState } from "react";
import { Helmet } from "react-helmet";
import FAQ from "../FAQ";
import PricingCard from "./PricingCard";
import pricingPlans from "./pricingData";
import IntercomContactButton from "../Intercom/IntercomContactButton";
import DownloadTrack from "../Track/DownloadTrack";
import ToggleSwitch from "../UI/ToggleSwitch";

const Pricing = (props) => {
  const [selectedInterval, setSelectedInterval] = useState("year");

  const toggleCallback = () => {
    if (selectedInterval === "month") {
      setSelectedInterval("year");
    }
    if (selectedInterval === "year") {
      setSelectedInterval("month");
    }
  };

  console.log('selectedInterval', selectedInterval);

  return (
    <>
      <Helmet>
        <title>Pricing - Deep Sounds | Music for Creators</title>
        <meta
          name="description"
          content="Find the right plan and get your Unlimited Music Subscription to license royalty-free music for all your projects, or see individual track licensing prices."
        />
      </Helmet>
      <div className="container max-w-4xl text-center leading-tightest pt-100 pb-20 xl:pt-120 xl:pb-40">
        <h1 className="text-h1-sm md:text-h1 mb-10 md:mb-20">Pricing and Plans</h1>
        <div className="max-w-2xl mx-auto text-h4 text-gray-500">
          <p>Browse our subscription pricing plans below.</p>
        </div>
        <div className="text-center mt-30">
          <div className="inline-block">
            <ToggleSwitch
              defaultChecked={true}
              labelA="Monthly"
              labelB={
                <>
                  Yearly
                </>
              }
              toggleCallback={toggleCallback}
            />
          </div>
        </div>
      </div>
      <section className="container max-w-lg md:max-w-screen-3xl mb-60 lg:mb-80">
        {/* {# Cards #} */}
        <div className="grid-row-sm items-stretch mb-80">
          {/* {% for card in pricing_cards %} */}
          {/* {% include "components/cards/pricing-card.html" %} */}
          {pricingPlans.map((pricingPlan) => (
            <PricingCard
              key={pricingPlan.title}
              selectedInterval={selectedInterval}
              {...pricingPlan}
              {...props}
            />
          ))}
          {/* {% endfor %} */}
        </div>

        {/* {# CTA #} */}
        <div className="grid-row">
          <div className="col w-full md:w-1/2">
            <div className="max-w-lg mx-auto text-sm text-center text-gray-400 mb-80">
              <h2 className="font-body normal-case text-h4 text-white mb-5">
                Want to purchase licenses individually?
              </h2>
              <p className="text-h4 text-gray-400 mb-30">
                Click below to see our individual track licensing prices.
              </p>
              <DownloadTrack
                artistId={0}
                artistName={"Use dropdowns below to see pricing"}
                className="btn btn-stroke btn-min-w"
                isDemo={true}
                text={"Licensing Pricing"}
                trackTitle={"Track Licensing Pricing"}
              />
            </div>
          </div>
          <div className="col w-full md:w-1/2">
            <div className="max-w-lg mx-auto text-sm text-center text-gray-400 mb-80">
              <h2 className="font-body normal-case text-h4 text-white mb-5">
                Not seeing the right plan for you?
              </h2>
              <p className="text-h4 text-gray-400 mb-30">
                Please reach out to our support team for more information.
              </p>
              <IntercomContactButton />
            </div>
          </div>
        </div>
      </section>
      {/* {# FAQs #} */}
      <section className="container mb-60 lg:mb-80">
        {/* {# Header #} */}
        <div className="max-w-2xl mb-60">
          <h2>Frequently Asked Questions</h2>
        </div>

        {/* {# FAQ Items #} */}
        <FAQ />
      </section>
    </>
  );
};

export default Pricing;

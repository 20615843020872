import React, { Component } from "react";

class TagStatsSortTooltip extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {

            }

    render() {
        return (    
                <div className="dropdown-menu">
                    <div className="dropdown-group">
                        <a onClick={()=>{this.props.sortStatsBy("MostP");this.props.closeTippy()}} href="#">Most Plays</a>
                        <a onClick={()=>{this.props.sortStatsBy("MostL");this.props.closeTippy()}} href="#">Most Likes</a>
                        <a onClick={()=>{this.props.sortStatsBy("MostD");this.props.closeTippy()}} href="#">Most Downloads</a>
                        <a onClick={()=>{this.props.sortStatsBy("MostPr");this.props.closeTippy()}} href="#">Most Projects</a>
                        <a onClick={()=>{this.props.sortStatsBy("MostLic");this.props.closeTippy()}} href="#">Most Licenses</a>
                    </div>
                </div>
        )
    }

}
export default TagStatsSortTooltip
import React from "react";
import Skeleton from "@material-ui/lab/Skeleton";

export default function TrackListItemSkeleton(props) {
  return (
    <div className={`track-list-card`}>
      <div className={`track-first-col`}>
        <Skeleton
          className="track-image"
          style={{
            backgroundColor: "rgb(210 205 205 / 42%)",
            lineHeight: 0,
          }}
          animation="pulse"
          variant="rect"
          width={"5rem"}
          height={"5rem"}
        >
          <img src="" alt="albumArt" />
        </Skeleton>
        <div className="track-title-artist">
          <Skeleton style={{ backgroundColor: "rgb(210 205 205 / 42%)" }} animation="pulse">
            <a href="#">
              <span className="track-title">Placeholder</span>
            </a>
          </Skeleton>
          {props.listType !== "album" && (
            <a href="#">
              <Skeleton
                style={{
                  backgroundColor: "rgb(210 205 205 / 42%)",
                }}
                variant="text"
                animation="pulse"
                component={"span"}
              >
                <span className="track-artist">Placeholder</span>
              </Skeleton>
            </a>
          )}
        </div>
        <button className={`circle-icon circle-icon-sm icon-chevron-down`}></button>
      </div>
      <div className="track-genres text-gray-400">
        <Skeleton
          style={{ backgroundColor: "rgb(210 205 205 / 42%)" }}
          variant="text"
          animation="pulse"
          component={"span"}
        >
          <div>Placeholder</div>
        </Skeleton>
      </div>

      <div className="track-last-col">
        <Skeleton
          style={{ backgroundColor: "rgb(210 205 205 / 42%)" }}
          variant="text"
          animation="pulse"
          component={"span"}
        >
          <div className="track-wave-time flex items-center">
            <span className={`icons-stems-versions text-gray-400 icon-stems`} />
            <div className="wave-form-vector"></div>
            <span className="track-duration">0:00</span>
          </div>
        </Skeleton>
        <Skeleton
          style={{ backgroundColor: "rgb(210 205 205 / 42%)", margin: '0 3px', padding: '2px' }}
          variant="text"
          animation="pulse"
          component={"span"}
        >
          <div className="track-list-icons text-gray-400">
            <button
              className="circle-icon circle-icon-sm btn-jelly js-tooltip"
              title="Like Track"
              type="button"
            >
              <span className="icon-like"></span>
            </button>
            <button
              className="circle-icon circle-icon-sm js-tooltip hidden lg:flex"
              title="Add to Project"
              type="button"
            >
              <span className="icon-plus"></span>
            </button>
            <button className="circle-icon circle-icon-sm js-tooltip">
              <span className="icon-download" />
            </button>
            <button
              id="trackToolTip"
              className="track-list-more"
              data-tippy-html="#trackToolTip"
              data-tippy-placement="bottom-end"
              type="button"
            >
              <span className="icon-dots-vert"></span>
            </button>
          </div>
        </Skeleton>
      </div>

      <div className="track-dropdown">
        <div className="track-dropdown-content">
          {/* <div className="tabs tabs-sm">
            <ul className="tab-items">
              <li
                onClick={() => {
                  this.setState({ activeTab: "details" });
                }}
                className={`tab-item ${
                  this.state.activeTab === "details" && "active"
                }`}
              >
                <a href="# " className="tab-item-title">
                  Details
                </a>
              </li>
              {(this.state.versions.length > 0 ||
                this.props.versions?.length > 0) && (
                <li
                  onClick={() => {
                    this.setState({ activeTab: "versions" }, () =>
                      this.context.getVersionInfo(
                        this.props.trackId
                      )
                    );
                  }}
                  className={`tab-item ${
                    this.state.activeTab === "versions" && "active"
                  }`}
                >
                  <a href="# " className="tab-item-title">
                    Versions
                  </a>
                </li>
              )}
              {(this.state.stems.length > 0 ||
                this.props.stems?.length > 0) && (
                <li
                  onClick={() => {
                    this.setState({ activeTab: "stems" }, () =>
                      this.context.getStemInfo(this.props.trackId)
                    );
                  }}
                  className={`tab-item ${
                    this.state.activeTab === "stems" && "active"
                  }`}
                >
                  <a href="# " className="tab-item-title">
                    Stems
                  </a>
                </li>
              )}
              <li className="tab-item tab-item-end hidden md:flex">
                <NavLink
                  to={"/tracks/" + this.props.trackURL}
                  className="tab-item-title"
                >
                  View Track Page
                </NavLink>
              </li>
            </ul>
            <div className="tab-content">

            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

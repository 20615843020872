import React, { Component } from "react"
import { Tooltip } from "react-tippy"
import BackdropOverlay from "../LoadingOverlay/backdrop"
import SalesPagination from "../Pagination/salesPagination"
import StatsSortTooltip from "./statsSortTooltip"
import TrackListStatItem from "./trackListStatItem"

class StatsTable extends Component{
    state = {}

    render(){
        return(
<div class="bg-gray-100 p-20 pb-10 lg:p-25 lg:pb-15 mb-40">

{/* {# Table Header #} */}
<div class="flex flex-col md:flex-row items-start md:items-center justify-between mb-15 lg:mb-30">

    {/* {# Title #} */}
    <div class="flex-1 flex items-center mb-15 md:mb-0" id={'trackStatsHeader'}>
        <h3 class="text-label-lg mr-10">Track Stats</h3>
        <span class="flex-none text-xs">Showing {this.props.tracks?.length} of {this.props.totalTracks}</span>
    </div>

    {/* {# Sort #} */}
    <div class="flex-none pr-20">

        <div class="sort-module">
        <span class="text-gray-400 sort-label mr-5">Sort by</span>
        <Tooltip
            arrow={false}
            open={this.props.isStatsSortTippyOpen}
            interactive={true}
            position={'bottom-end'}
            trigger={'click'}
            useContext={true}
            // hideOnClick={true}
            html={(<StatsSortTooltip 
                        sortStatsBy={this.props.sortStatsBy}
                        closeTippy={this.props.closeStatsSortTippy}
                    >
                </StatsSortTooltip>
            )}
            allowHTML={true}
            onUntrigger={this.props.toggleStatsSortTippy}
            onRequestClose={this.props.toggleStatsSortTippy}
        >
            <button class="sort-toggle js-dropdown-toggle"
                onClick={this.props.toggleStatsSortTippy}
                data-tippy-html="#purchase_sort"
                data-tippy-placement="bottom">
                {this.props.sortText}
                <span class="sort-toggle-icon icon-chevron-down"></span>
            </button>
        </Tooltip>
            
           
        </div>

    </div>

</div>

{/* {# Track List #} */}
<div class="track-list-container">
    {!this.props.isLoaded ? 
        <div style={{height: "991px", width: "100%"}}>
            <BackdropOverlay invisible={true} loading={!this.props.isLoaded}/> 

        </div>
        :
        this.props.tracks.map(track=>{
            return(<TrackListStatItem {...track} idKey={"statsTrack-"+track.trackId} otherTracks={this.props.tracks.map(track=>{
                return({
                    trackId: track.trackId,
                    idKey: "statsTrack-"+track.trackId
                })
            })}/>)
        })}
    

    {/* {% endfor %} */}
    
</div>

{/* {# Pagination #} */}
<SalesPagination pageLimit={6} currentPage={this.props.currentPage} totalRecords={this.props.totalTracks} gotoPage={this.props.gotoPage}/>


</div>
        )
    }
}
export default StatsTable
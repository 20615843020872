import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import { PlayerContext } from "../../PlayerContext/index";
import "../Carousels/index.css";
import CircularIndeterminant from "../Loading/circularIndeterminant";
import Pagination from '../Pagination/pagination';
import SearchCollections from "../SearchBar/searchCollections.js";
import SortBar from "../SortBar/results";
import { Error } from "../Toast/index";
import api from "../utils/api";
import PublicCard from "./publicCard";

const collectionFilters= [{filter: "RecentD", displayText: "Recently Added"},{filter: "MostP", displayText: "Most Popular"},
    {filter: "NameA", displayText: "Name - Asc"}, {filter: "NameD", displayText: "Name - Desc"},
   ]
class PublicCollections extends Component{
    state={
        collections: [],
        featuredCollections: [],
        filteredCollections: [],
        sortChoice: "RecentD",
        sortText: "Recently Added",
        filteredArtistOptions: [],
        filteredTrackOptions: [],
        autoFillOptions: [],
        filteredAutofillOptions: [],
        activeOption: -1,
        currentPage: 1
    }
    componentDidMount=()=>{
        api.getAllPublicCollections()
            .then(res=>{
              let collections = res.data.collections
              // console.log('collectionsData', collections);
                let allTrackOptions = []
                let allArtistOptions = []
                let allCollectionOptions = []
                collections.forEach((collection, index)=>{
                    let trackNames = []
                    let artistNames = []

                    collection.tracks.forEach(track=>{
                        if(trackNames.indexOf(track.trackTitle.toLowerCase())===-1){
                            trackNames.push(track.trackTitle.toLowerCase())
                        }
                        if(artistNames.indexOf(track.artistName.toLowerCase())===-1){
                            artistNames.push(track.artistName.toLowerCase())
                        }
                        let artistIndex =allArtistOptions.findIndex(option=>{
                            if(option.label === track.artistName && option.type==="artist"){
                                return true
                            }
                        })
                        if(artistIndex ===-1){
                            allArtistOptions.push({photo: track.profilePhoto, label: track.artistName, value: track.artistName, type: "artist"})
                        }
                        let trackIndex =allTrackOptions.findIndex(option=>{
                            if(option.label === track.trackTitle && option.type==="track"){
                                return true
                            }
                        })
                        if(trackIndex ===-1){
                            allTrackOptions.push({photo: track.imageURL, label: track.trackTitle, value: track.trackTitle, type: "track"})
                        }
                    })
                    collections[index].trackNames = trackNames
                    collections[index].artistNames = artistNames
                    allCollectionOptions.push({photo: collection.cardArt, label: collection.name, value: collection.name, type: "collection", id: collection.id, url: collection.collectionURL})
                })
                let featuredCollections;
                if(collections.length > 0){
                  featuredCollections = collections.filter(collection => collection.featured === 1)
                  featuredCollections = featuredCollections.sort((a,b)=>{
                    const aWeight = a.featuredWeight
                    const bWeight = b.featuredWeight
                    let comparison = 0
                    if(aWeight < bWeight){
                        comparison = -1
                    }
                    else if( aWeight > bWeight){
                        comparison = 1
                    }
                    return comparison
                  })
                }
                this.setState({
                    collections: collections || [],
                    collectionsLoaded: true,
                    featuredCollections: featuredCollections || [],
                    filteredCollections: collections || [],
                    artistSearchOptions: allArtistOptions || [],
                    trackSearchOptions: allTrackOptions || [],
                    collectionSearchOptions: allCollectionOptions || []
                })
            })
    }
    toggleSortTippy = (sortChoice, overRideTippy)=>{
        this.setState({isSortTippyOpen: overRideTippy === false ? false : !this.state.isSortTippyOpen, sortChoice: sortChoice})
    }

    sortBy = (sortChoice, sortText, overRide, tippyStateCB, currentSortChoice, overRideTippy)=>{
        let unsorted = [...this.state.filteredCollections]
        let compare;
        let sorted;

        if(sortChoice !== this.state.sortChoice || overRide){

            switch(sortChoice){
                case "NameA":
                    compare = (a, b)=> {
                        // Use toUpperCase() to ignore character casing
                        const aName = a.name.toUpperCase();
                        const bName = b.name.toUpperCase();

                        let comparison = 0;
                        if (aName < bName) {
                          comparison = -1;
                        } else if (aName > bName) {
                          comparison = 1;
                        }
                        return comparison;
                      }
                    sorted = unsorted.sort(compare)
                    break;
                case "NameD":
                    compare = (a, b)=> {

                        // Use toUpperCase() to ignore character casing
                        const aName = a.name.toUpperCase();
                        const bName = b.name.toUpperCase();

                        let comparison = 0;
                        if (aName < bName) {
                          comparison = -1;
                        } else if (aName > bName) {
                          comparison = 1;
                        }
                        return comparison*-1;
                      }
                    sorted = unsorted.sort(compare)
                    break;
                case "RecentD":
                    compare = (a,b)=>{
                        const aCreated = a.createdOn;
                        const bCreated = b.createdOn;

                        let comparison = 0;
                        if (aCreated < bCreated){
                            comparison = -1
                        }
                        else if (aCreated > bCreated){
                            comparison = 1;
                        }
                        return comparison * -1
                    }
                    sorted = unsorted.sort(compare)
                    break;
                case "MostP":
                    compare = (a,b)=>{
                        const aFollowers = a.followerCount;
                        const bFollowers = b.followerCount;
                        const aCreated = a.createdOn;
                        const bCreated = b.createdOn;
                        let comparison = 0;
                        if(aFollowers < bFollowers){
                            comparison = 1
                        }
                        else if(aFollowers > bFollowers){
                            comparison = -1
                        }
                        else if (aCreated < bCreated){
                            comparison = -1
                        }
                        else if (aCreated > bCreated){
                            comparison = 1;
                        }
                        return comparison
                    }
                    sorted = unsorted.sort(compare)
                    break;
            }
            this.setState({filteredCollections: sorted, sortText: sortText}, ()=>tippyStateCB(sortChoice, overRideTippy))
        }
        else{
            tippyStateCB(sortChoice)
        }
    }
    handleSearchChange= (event)=>{
        if(event){
            let {value} = event[0]
            if(value.length > 2){
                let filteredAutofillOptions = this.state.autoFillOptions.filter(option=>{
                    return (
                        option.label?.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1)
                })
                let optionFix
                if(this.state.activeOption > filteredAutofillOptions.length -1){
                    optionFix = this.state.activeOption -1
                }
                else{
                    optionFix = this.state.activeOption
                }
                this.setState({filteredAutofillOptions: filteredAutofillOptions, activeOption: optionFix})
            }
            else{
                this.setState({filteredAutofillOptions: []})
            }
            this.setState({searchTerm: value, selectSearchTerm: event, currentPage: 1},()=>this.search())
        }
        else{
            this.setState({searchTerm: "", selectSearchTerm: []},()=>{
                this.search()
            })
        }
    }
    search = ()=>{
        this.filterCollections()
    }
    filterCollections = ()=>{
        let filteredCollections
        let allCollections = [...this.state.collections]

        filteredCollections = allCollections.filter(collection=>{

            if(collection.name.toLowerCase().indexOf(this.state.searchTerm.toLowerCase())!==-1){
                return true
            }
            let indexOfSearchInArtistNames = -1
            collection.artistNames.forEach(name=>{
                if(name.indexOf(this.state.searchTerm.toLowerCase()) !==-1){
                    indexOfSearchInArtistNames = 1
                }
            })
            let indexOfSearchInTrackNames = -1
            collection.trackNames.forEach(name=>{
                if(name.indexOf(this.state.searchTerm.toLowerCase()) !==-1){
                    indexOfSearchInTrackNames = 1
                }
            })
            if(indexOfSearchInArtistNames !==-1){
                return true
            }
            if(indexOfSearchInTrackNames !==-1){
                return true
            }
        })
        this.setState({filteredCollections: filteredCollections}, ()=>this.sortBy(this.state.sortChoice, this.state.sortText, true, this.toggleSortTippy,undefined, false))
    }
    onKeyDown = (e)=>{

        const { activeOption, filteredAutofillOptions } = this.state;
        if(filteredAutofillOptions.length > 0){
            if (e.keyCode === 13) {
                e.preventDefault()

                // setValueCB("collaboratorEmail", filteredArtistOptions[activeOption].email)
                this.handleSearchChange({target: {value: filteredAutofillOptions[activeOption].label}})
                this.setState({
                    activeOption: 0,
                    showOptions: false,
                    filteredAutofillOptions: [],
                });
            } else if (e.keyCode === 38) {
                if (activeOption === -1) {
                return;
                }
                e.preventDefault()
                this.setState({ activeOption: activeOption - 1 }, ()=>{
                    let activeElement = document.getElementsByClassName('option-active')[0]
                    if(activeElement){
                        activeElement.parentNode.parentNode.parentNode.scrollTop = activeElement.offsetTop;
                    }

                });
            } else if (e.keyCode === 40) {
                if (activeOption === filteredAutofillOptions.length - 1) {
                    return;
                }
                this.setState({ activeOption: activeOption + 1 },()=>{
                    let activeElement = document.getElementsByClassName('option-active')[0]
                    activeElement.parentNode.parentNode.parentNode.scrollTop = activeElement.offsetTop;
                });
            }
        }
        else if(e.keyCode === 13){
            e.preventDefault()
        }
    }
    onClick= (artistName, type, url)=>{

        if(type==="collection"){
            this.props.history.push({pathname: `/collections/${url}`})
        }
        else{
            this.handleSearchChange({target: {value: artistName}})
            this.setState({
                activeOption: 0,
                showOptions: false,
                filteredAutofillOptions: [],
            })
        }
    }
    onPageChanged = data => {
        if(this.state.initialMount){

            const { filteredCollections } = this.state;
            const { currentPage, totalPages, pageLimit } = data;
            const searchBarEl = document.getElementById("search")
            searchBarEl.scrollIntoView()
            const offset = (currentPage - 1) * pageLimit;

            this.setState({ currentPage, totalPages });
        }
        else{

            const { filteredCollections } = this.state;
            const { currentPage, totalPages, pageLimit } = data;
            const offset = (currentPage - 1) * pageLimit;

            this.setState({ currentPage, totalPages });
        }

      }
    gotoPage = (page, totalPages) => {

        // const { onPageChanged = f => f } = this.props;

        const currentPage = Math.max(0, Math.min(page, totalPages));

        const paginationData = {
          currentPage,
          totalPages: totalPages,
          pageLimit: 12,
          totalRecords: this.state.filteredCollections.length
        };

        this.setState({ currentPage }, () => this.onPageChanged(paginationData));
      }
    playTracks = (tracks, collectionId, idKey)=>{
        if((idKey?.indexOf("collectionListCard-" + collectionId) ||-1)!==-1){
            this.context.handlePlay()
        }
        else{
            let playList = tracks.map(track=>track.trackId)

            this.context.setPlaylist(playList)
            this.context.loadTrackInMediaPlayer(playList[0], `artist-${this.context.loadedTracks[playList[0]]?.artistId}-album-${this.context.loadedTracks[playList[0]]?.albumId}-track-${playList[0]}-element-collectionListCard-${collectionId}`)
            this.context.setAutoPlay(true)
        }
        // if(this.context.mediaPlayer.idKey?.indexOf(idKey) !==-1 || this.context.mediaPlayer.idKey === undefined){
        //     let playList = tracks
        //     playList.forEach((track, index)=>{
        //         playList[index].idKey = "collectionCard-" + collectionId + "-trackId-" + track.trackId
        //     })
        //     this.context.setPlaylist(playList)
        //     this.context.loadTrackInMediaPlayer(playList[0].trackId, playList[0].idKey)
        //     this.context.setAutoPlay(true)
        //     // this.setState({playingCollection: collectionId})
        // }
        // else{
        //     this.context.playPause()
        // }

    }
    changeSearchInput = (newValue)=>{

        // if(!newValue && this.state.city?.label){
        //     const inputValue =this.state.city?.label
        //     this.setState({cityInput: inputValue})
        //     return inputValue
        // }
        // else{
            if(this.state.selectSearchTerm?.length > 0 && newValue){
                Error({message: "Please remove search term first", toastId: `collections-input`})
                return
            }
            else if(this.state.selectSearchTerm?.value && newValue){
                Error({message: "Please remove search term first", toastId: `collections-input`})
                return
            }
            else{

                const inputValue =newValue
                this.setState({searchInput: inputValue})
                return inputValue
            }

        // }

    }
    componentWillUnmount = ()=>{
        this.slider = null
    }
    render(){
        const settings = {
            arrows: false,
            swipeToSlide: true,
            infinite: true,
            variableWidth: true,
            slidesToShow: 2,
            edgeFriction: '.05',
            adaptiveHeight: true,
            draggable: true,
        }
        return(

          <>
            <Helmet>
              <title>Collections - Deep Sounds | Music for Creators</title>
              <meta name="description" content="Discover and support independent artists while you find the perfect song for your project." />
            </Helmet>
                {this.state.collectionsLoaded ?
                <>
                <div className="featured-carousel my-30 lg:my-50 xl:my-80">
                    <div className="container">
                        <div className="grid-row-sm">
                            <div className="col w-full xl:w-1/3">
                                <div className="featured-carousel-header">
                                    <h2 className="featured-title">
                                        <span className="inline-block xl:block">Featured&nbsp;</span>
                                        <span className="inline-block xl:block">Collections</span>
                                    </h2>
                                    <div className="ui-control-arrows flex">
                                        <button onClick={(e)=>{e.preventDefault();this.slider.slickPrev()}}  className="circle-icon-border lg:circle-icon-lg icon-chevron-left mr-15"></button>
                                        <button onClick={(e)=>{e.preventDefault();this.slider.slickNext()}}className="circle-icon-border lg:circle-icon-lg icon-chevron-right"></button>
                                    </div>
                                </div>
                            </div>
                            <div className="col w-full xl:w-2/3">
                                <Slider key="collectionsPageSlider" ref={slider => (this.slider = slider)} {...settings} className="card-carousel">
                                    {this.state.featuredCollections.map(collection=>
                                        <div key={"featuredCon-" + collection.id}  className="carousel-slide" >
                                            <PublicCard idKey={"collectionCard-" + collection.id} key={'featuredCollectionCard-'+collection.id} {...collection} playTracks={this.playTracks}  isPlaying={this.state.playingCollection === collection.id}/>
                                        </div>)}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    {/* {# App Header #} */}
                    <SearchCollections searchTerm={this.state.selectSearchTerm} handleSearchChange={this.handleSearchChange} placeholder={"Search by name, artist, or track..."}
                         activeOption={this.state.activeOption} onClick={this.onClick}  onKeyDown={this.onKeyDown} collectionOptions={this.state.collectionSearchOptions}
                        artistOptions={this.state.artistSearchOptions} trackOptions={this.state.trackSearchOptions} changeSearchInput={this.changeSearchInput} searchInput={this.state.searchInput} />
                    <SortBar sortFilters={collectionFilters} sortBarId={"publishedCollections"} resultsText={`${this.state.filteredCollections.length} Collections`} sortCriteria={this.state.sortText} marginClasses={"mb-30"} toggleSortTippy={this.toggleSortTippy} isSortTippyOpen={this.state.isSortTippyOpen} sortBy={this.sortBy}
                                    sortTippyCB={this.toggleSortTippy}/>
                    {/* {# Collection Grid #} */}

                        {this.state.filteredCollections.length > 0 ?
                            <div className="grid-row mb-40">
                                {this.state.filteredCollections.slice(((this.state.currentPage -1)*12), (((this.state.currentPage - 1) * 12) + 12)).map((collection) => <div className="col w-full sm:w-1/2 lg:w-1/3 mb-20 md:mb-40" key={collection.id}>
                                    <PublicCard idKey={"collectionCard-" + collection.id} key={"featured-"+ collection.id} {...collection} playTracks={this.playTracks} isPlaying={this.state.playingCollection === collection.id}/>
                                </div>)}
                            </div>:
                            <div className="track-list-container mb-40 text-h4 text-white" style={{textAlign: "center", padding: "60px"}}>
                                <p>Sorry, your search resulted in no matches.</p>
                            </div>
                        }


                    <Pagination pageLimit={12} currentPage={this.state.currentPage} totalRecords={this.state.filteredCollections.length} gotoPage={this.gotoPage}/>
                </div>
                </>:
                    <div style={{marginTop: "50px"}}>
                            <CircularIndeterminant forceCenter={true}/>
                    </div>
                    }
            </>
        )
    }
}
PublicCollections.contextType = PlayerContext
export default PublicCollections

import moment from "moment";
import React, { Component, createRef } from "react";
import LoadingOverlay from "react-loading-overlay";
import { WaveSpinner } from "react-spinners-kit";
import WaveSurfer from 'wavesurfer.js';
import { PlayerContext } from "../../PlayerContext";
import api from "../utils/api";

var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment)
// import { PlayerContext } from "../../PlayerContext";

export default class Waveform extends Component {
    state = {
        src: this.props.src || "",
        peaks: this.props.peaks,
        currentTime: "0:00",
        duration: "0:00",
        sendPlay: 0,
        volume: 1
    }
    handleOnChange = (value) => {
      console.log(value)
      this.setState({
        volume: value.x
      })
    }
  setVolume = ()=>{
    console.log("setting volume to ", this.state.volume)
    this.wavesurfer.setVolume(this.state.volume)
  }
  wrapper = createRef()
  componentDidMount() {
  
    this.$el = this.wrapper.current
    this.$waveform = this.$el.querySelector('.wave')
    this.wavesurfer = WaveSurfer.create({
        container: this.$waveform,
        barMinHeight: 1,
        barWidth: 1.5,
        barGap: 2,
        hideScrollbar: true,
        interact: true,
        cursorWidth: 0,
  
        height: 80,
        // barMinHeight: ,
        // barHeight: 10,
        progressColor: "rgba(255,255,255,1)",
        responsive: true,
        waveColor: "rgba(255,255,255,.5)",
        xhr:{ cache: 'default', mode: 'cors', method: 'GET', credentials: 'same-origin', redirect: 'follow', referrer: 'client', headers: [ { key: 'Authorization', value: 'my-token' } ]},
        // fillParent: true
        // fillParent: true,      
        normalize: true  
    })
    this.waveReady = this.wavesurfer.on('ready', ()=>{
      console.log("wave ready")
      this.setState({ready: true})
    })
    // this.wavesurfer.on('finish', ()=>{
    //   this.props.unsetNowPlaying()
    // })
    this.wavesurferError = this.wavesurfer.on('error', (error)=>{console.log("wave error ", error)})
    console.log(typeof this.props.peaks, " ", this.props.peaks)
    api.getAudioFromKey(this.props.s3Key, this.props.trackId)
            .then(res=>{
              console.log(res)
              this.wavesurfer.load(res.data.url)
              this.setState({cloudFront: res.data.url})
            })
  }
  songFinished = ()=>{}
  wavesurferError = ()=>{}
  componentDidUpdate = (prevProps, prevState, snapshot)=>{
        if(prevProps.s3Key !== this.props.s3Key){
          api.getAudioFromKey(this.props.s3Key, this.props.trackId)
            .then(res=>{
              console.log(res)
              this.wavesurfer.load(res.data.url)
              this.setState({cloudFront: res.data.url})
            })
        }
        else{
          if(this.props.nowPlaying === this.props.s3Key && this.props.nowPlaying !== null){
            if(!this.state.cloudFront){
                this.wavesurfer.play()
            }
            else{
              this.wavesurfer.play()
            }
           
          }
          else{
            this.wavesurfer.pause()
          }
        }
        
 }

  componentWillUnmount() {
    this.wavesurfer.pause()

  }
  render() {
    return (
      <div style={{width: "100%"}}>
      <LoadingOverlay
       styles={{
        overlay: (base) => ({
          ...base,
          zIndex: 39
        })
      }}
      active={!this.state.ready}
      spinner={<WaveSpinner size={30} color="#fff" loading={!this.state.ready}></WaveSpinner>}
  >
  <div ref={this.wrapper} className="" style={{width: "100%"}}>

<div style={{backgroundColor: "black", width: "100%" }} className='waveform'>
  <div className='wave'/>
</div>
</div>
</LoadingOverlay>
</div>
    
    )
  }
}

Waveform.defaultProps = {
  src: "",
  peaks: ""
}

Waveform.contextType = PlayerContext
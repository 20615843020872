import React, { Component } from "react";
const moment = require("moment")

class TopPayoutTooltip extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {

            }

    render() {
        return (    
                <div className="dropdown-menu">
                    <div className="dropdown-group">
                        {this.props.status === "pending" && 
                            <a style={{cursor: 'pointer'}} onClick={()=>{this.props.recalculatePayout(this.props.payoutId, this.props.index);this.props.closeTippy()}}>Recalculate Payout</a>
                        }
                        {this.props.status === "pending" && 
                            <a style={{cursor: 'pointer'}} onClick={()=>{this.props.handlePWModalOpen(this.props.payoutId);this.props.closeTippy()}}>Approve Payout</a>
                        }
                        {this.props.status === "transferErrors" && <a style={{cursor: 'pointer'}}   onClick={()=>{this.props.handlePWModalOpenForReapprove(this.props.payoutId);this.props.closeTippy()}}>Retry Payout</a>
}                       {(this.props.status === undefined || this.props.status === "needs calculating") && 
                            <a style={{cursor: 'pointer'}} onClick={()=>{this.props.calculatePayout(this.props.forMonth, this.props.index);this.props.closeTippy()}}>Calculate Payout</a>
                        }
                          {this.props.status === "overdue" && 
                            <a style={{cursor: 'pointer'}} onClick={()=>{this.props.handlePWModalOpenForApproveAndDistribute(this.props.payoutId);this.props.closeTippy()}}>Approve and Distribute Payout</a>
                        }
                        {this.props.status === "approved" && <a style={{cursor: 'pointer'}}   onClick={()=>{this.props.handlePWModalOpenForUnapprove(this.props.payoutId);this.props.closeTippy()}}>Unapprove Payout</a>}        
                    </div>
                </div>
        )
    }

}
export default TopPayoutTooltip
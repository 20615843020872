import React, { Component } from "react";
import MicroModal from "react-micro-modal";
import { NavLink } from "react-router-dom";
import { Tooltip } from "react-tippy";
import { PlayerContext } from "../../PlayerContext";
import SortBar from "../SortBar/results";
import EditSceneModal from "./editSceneModal";
import ProjectListCard from "./projectListCard";
import SceneTooltip from "./sceneTooltip";
class ProjectAccordion extends Component {
  state = {
    expanded: this.props.autoExpand || this.props.isPublicPage || false,
    isSortTippyOpen: false,
    sortChoice: "VotesD",
    isEditSceneModalOpen: false,
    editedSceneName: this.props.name,
  };
  toggleSortTippy = (sortChoice) => {
    this.setState({ isSortTippyOpen: !this.state.isSortTippyOpen, sortChoice: sortChoice });
  };
  toggleSceneTooltip = () => {
    this.setState({ isSceneTooltipOpen: !this.state.isSceneTooltipOpen });
  };
  handleEditSceneModalOpen = () => {
    this.setState({ isEditSceneModalOpen: true, isSceneTooltipOpen: false });
  };

  handleEditSceneModalClose = () => {
    this.props.handleFormUnsaved(false);

    this.setState({ isEditSceneModalOpen: false, editedSceneName: this.props.name });
  };
  handleInputChange = (event) => {
    this.props.handleFormUnsaved(true);
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  close = () => {
    this.setState({ expanded: false });
  };
  // getBoudingClientReact=()=>{
  //
  // }
  render() {
    return (
      <div
        className={"accordion-item " + (this.state.expanded ? "active" : "")}
        key={this.props.name + "-" + this.props.index}
        scenei={this.props.sceneId}
      >
        {/* {# Accordion Header Group #} */}
        <div class="accordion-header-group">
          {/* {# Accordion Header #} */}
          <div
            onClick={() => this.setState({ expanded: !this.state.expanded })}
            class="accordion-header"
            // @click="show = !show"
          >
            {this.props.name}
          </div>
          {/* {# Controls #} */}
          <div class="accordion-controls">
            {/* {# Drag Icon for Edit Mode #} */}
            {!this.props.isPublicPage && (
              <>
                <button class="circle-icon circle-icon-sm">
                  <span class="icon-drag"></span>
                </button>
                <Tooltip
                  arrow={false}
                  open={this.state.isSceneTooltipOpen}
                  interactive={true}
                  position={"bottom-end"}
                  trigger={"click"}
                  useContext={true}
                  // hideOnClick={true}
                  html={
                    <SceneTooltip
                      handleEditSceneModalOpen={this.handleEditSceneModalOpen}
                      removeScene={this.props.removeScene}
                      sceneId={this.props.sceneId}
                      sceneIndex={this.props.index}
                    ></SceneTooltip>
                  }
                  allowHTML={true}
                  onUntrigger={this.toggleSceneTooltip}
                  onRequestClose={this.toggleSceneTooltip}
                >
                  <button
                    class="circle-icon circle-icon-sm"
                    id="sceneToolTip"
                    onClick={this.toggleSceneTooltip}
                    data-tippy-html="#sceneToolTip"
                    data-tippy-placement="bottom-end"
                    type="button"
                  >
                    <span class="icon-dots-vert"></span>
                  </button>
                </Tooltip>
              </>
            )}
            {/* {# More Options #} */}
          </div>
        </div>
        {/* {# Accordion Body #} */}
        <div class="accordion-body">
          <div class="accordion-body-content">
            {this.props.tracks.length > 0 ? (
              <div class="project-scene-tracks">
                <SortBar
                  sortBarId={"sceneTrackSort"}
                  marginClasses={"mb-20 md:mb-30"}
                  sortFilters={this.props.sortFilters}
                  sortBy={this.props.sortBy}
                  sortCriteria={this.props.sortCriteria}
                  toggleSortTippy={this.toggleSortTippy}
                  isSortTippyOpen={this.state.isSortTippyOpen}
                  sortTippyCB={this.toggleSortTippy}
                  resultsText={`${this.props.tracks.length} Track${
                    this.props.tracks.length > 1 ? "s" : ""
                  }`}
                />

                {/* {# Track List #} */}
                <div class="track-list-container">
                  {this.props.tracks.map((track, index) => {
                    return (
                      <ProjectListCard
                        user={this.props.user}
                        triggerLoginModal={this.props.triggerLoginModal}
                        key={"sceneTrack-" + track.sceneTrackId}
                        idKey={`artist-${track.tracks.artistId}-album-${track.tracks.albumId}-track-${track.tracks.trackId}-scene-${this.props.sceneId}-projectListItem`}
                        sceneTrackIndex={track.realInd}
                        submitVote={this.props.submitVote}
                        updateVote={this.props.updateVote}
                        {...this.context.loadedTracks[track.tracks.trackId]}
                        votes={track.sceneTrackVotes}
                        myVote={!this.props.isPublicPage ? track.myVote[0] : null}
                        sceneTrackId={track.sceneTrackId}
                        projectId={this.props.projectId}
                        sceneId={this.props.sceneId}
                        removeTrack={this.props.removeTrack}
                        isOwner={this.props.isOwner}
                        isPublicPage={this.props.isPublicPage}
                        updateFollowing={this.props.updateFollowing}
                        otherTracks={this.props.playlistTracks.map((track) => {
                          let retTrack = { ...track.tracks };
                          retTrack.idKey = `artist-${track.tracks.artistId}-album-${track.tracks.albumId}-track-${track.tracks.trackId}-scene-${track.sceneId}-projectListItem`;
                          return retTrack;
                        })}
                      />
                    );
                  })}
                  {/* {% for track in tracks.slice(0,6) %}
                            {% include "components/tracks/project-list-card.html" %}
                        {% endfor %} */}
                </div>
              </div>
            ) : this.props.isPublicPage ? (
              <div to="/music" class="prompt">
                <div class="prompt-content">
                  {/* {# Icon #} */}
                  <div class="text-icon-xl mb-15">
                    <span class="icon-no-tracks-lg"></span>
                  </div>
                  {/* {# Heading #} */}
                  <h3 class="prompt-title">No tracks added to this scene yet</h3>
                  {/* {# Description #} */}
                  <p class="max-w-sm">
                    Mauris non tempor quam, et lacinia sapien, Mauris accumsan eros eget.
                  </p>
                </div>
              </div>
            ) : (
              <NavLink to="/music" class="prompt cursor-pointer">
                <div class="prompt-content">
                  {/* {# Icon #} */}
                  <div class="text-icon-xl mb-15">
                    <span class="icon-add-track-lg"></span>
                  </div>
                  {/* {# Heading #} */}
                  <h3 class="prompt-title">Add some tracks to your project</h3>
                  {/* {# Description #} */}
                  <p class="max-w-sm">
                    Mauris non tempor quam, et lacinia sapien, Mauris accumsan eros eget.
                  </p>
                </div>
              </NavLink>
            )}
          </div>
        </div>
        <MicroModal
          open={this.state.isEditSceneModalOpen}
          closeOnEscapePress={true}
          handleClose={() => this.handleEditSceneModalClose()}
          closeOnOverlayClick={true}
          closeOnAnimationEnd={true}
          modalOverlayClassName="modal-overlay"
          modalClassName="modal micromodal-slide is-open"
          modalOverlayStyles={null}
          children={(handleClose) => (
            <EditSceneModal
              handleClose={handleClose}
              handleInputChange={this.handleInputChange}
              editedSceneName={this.state.editedSceneName}
              editScene={this.props.editScene}
              sceneIndex={this.props.index}
              sceneId={this.props.sceneId}
              checkIfUsed={this.props.checkIfUsed}
            />
          )}
          containerStyles={{
            background: "#131313",
            padding: "asdf",
            maxWidth: "40.625rem !important",
            maxHeight: "100vh",
            borderRadius: "asdf",
            overflowY: "auto",
            boxSizing: "border-box",
          }}
        ></MicroModal>
      </div>
    );
  }
}
ProjectAccordion.contextType = PlayerContext;
export default ProjectAccordion;

import React, { Component } from "react";
const LEFT_PAGE = "LEFT";
const RIGHT_PAGE = "RIGHT";

/**
 * Helper method for creating a range of numbers
 * range(1, 5) => [1, 2, 3, 4, 5]
 */
const range = (from, to, step = 1) => {
  let i = from;
  const range = [];

  while (i <= to) {
    range.push(i);
    i += step;
  }

  return range;
};

class SalesPagination extends Component {
  //   constructor(props) {
  // super(props);
  //
  state = {
    // totalRecords :null || this.props.totalRecords,
    pageLimit: this.props.pageLimit || 6,
    pageNeighbours: 1,
    // totalPages: Math.ceil(this.totalRecords|| 1 /24),
    // currentPage: this.props.currentPage
  };

  // this.pageLimit = typeof pageLimit === 'number' ? pageLimit : 30;
  // this.totalRecords = typeof totalRecords === 'number' ? totalRecords : 0;

  // // pageNeighbours can be: 0, 1 or 2
  // this.pageNeighbours = typeof pageNeighbours === 'number'
  //   ? Math.max(0, Math.min(pageNeighbours, 2))
  //   : 0;

  // this.totalPages = Math.ceil(this.totalRecords / this.pageLimit);

  // this.state = { currentPage: 1 };
  //   }

  componentDidMount() {
    this.props.gotoPage(
      this.props.currentPage || 1,
      Math.ceil((this.props.totalRecords || 1) / this.state.pageLimit)
    );
  }
  handleClick = (page) => (evt) => {
    evt.preventDefault();
    this.props.gotoPage(page, Math.ceil((this.props.totalRecords || 1) / this.state.pageLimit));
  };

  handleMoveLeft = (evt) => {
    evt.preventDefault();
    this.props.gotoPage(
      this.props.currentPage - 1,
      Math.ceil((this.props.totalRecords || 1) / this.state.pageLimit)
    );
  };

  handleMoveRight = (evt) => {
    evt.preventDefault();
    this.props.gotoPage(
      this.props.currentPage + 1,
      Math.ceil((this.props.totalRecords || 1) / this.state.pageLimit)
    );
  };

  /**
   * Let's say we have 10 pages and we set pageNeighbours to 2
   * Given that the current page is 6
   * The pagination control will look like the following:
   *
   * (1) < {4 5} [6] {7 8} > (10)
   *
   * (x) => terminal pages: first and last page(always visible)
   * [x] => represents current page
   * {...x} => represents page neighbours
   */
  fetchPageNumbers = () => {
    const totalPages = Math.ceil((this.props.totalRecords || 1) / this.state.pageLimit);
    // console.log("totalPages", totalPages);
    const currentPage = this.props.currentPage;
    // console.log("fetchPageNumbers currentPage", currentPage);
    const pageNeighbours = this.state.pageNeighbours;
    // console.log("fetchPageNumbers pageNeighbours", pageNeighbours);

    /**
     * totalNumbers: the total page numbers to show on the control
     * totalBlocks: totalNumbers + 2 to cover for the left(<) and right(>) controls
     */
    const totalNumbers = pageNeighbours * 2 + 3;
    const totalBlocks = totalNumbers + 2;
    // console.log("totalNumbers", totalNumbers);
    // console.log("totalBlocks", totalBlocks);

    if (totalPages > totalBlocks) {
      const startPage = Math.max(2, currentPage - pageNeighbours);
      const endPage = Math.min(totalPages - 1, currentPage + pageNeighbours);
      // console.log("startPage", startPage);
      // console.log("endPage", endPage);

      let pages = range(startPage, endPage);

      /**
       * hasLeftSpill: has hidden pages to the left
       * hasRightSpill: has hidden pages to the right
       * spillOffset: number of hidden pages either to the left or to the right
       */
      const hasLeftSpill = startPage > 2;
      const hasRightSpill = totalPages - endPage > 1;
      const spillOffset = totalNumbers - (pages.length + 1);

      switch (true) {
        // handle: (1) < {5 6} [7] {8 9} (10)
        case hasLeftSpill && !hasRightSpill: {
          const extraPages = range(startPage - spillOffset, startPage - 1);
          pages = [LEFT_PAGE, ...extraPages, ...pages];
          break;
        }

        // handle: (1) {2 3} [4] {5 6} > (10)
        case !hasLeftSpill && hasRightSpill: {
          const extraPages = range(endPage + 1, endPage + spillOffset);
          pages = [...pages, ...extraPages, RIGHT_PAGE];
          break;
        }

        // handle: (1) < {4 5} [6] {7 8} > (10)
        case hasLeftSpill && hasRightSpill:
        default: {
          pages = [LEFT_PAGE, ...pages, RIGHT_PAGE];
          break;
        }
      }

      return [1, ...pages, totalPages];
    }
    // console.log("returning 1 through", totalPages);
    // console.log("range", range(1, totalPages));
    return range(1, totalPages);
  };
  render() {
    let pages = this.fetchPageNumbers();
    // console.log("currentPage in pagination", this.props.currentPage);
    // console.log("pages in pagination", pages);

    if (
      !this.props.totalRecords ||
      this.props.totalPages === 1 ||
      this.props.totalRecords <= this.props.pageLimit
    )
      return null;

    // const { currentPage } = this.state;
    return (
      <div class="flex-1 flex justify-between items-center pt-15 font-medium font-display2 text-xs">
        <div class="flex-none flex items-center">
          {pages.map((page, index) => {
            if (page === LEFT_PAGE)
              return (
                <div className="pagination-separator">–</div>
                // <li key={index} className="page-item">
                //   <a className="page-link" href="#" aria-label="Previous" >
                // 	<span aria-hidden="true">&laquo;</span>
                // 	<span className="sr-only">Previous</span>
                //   </a>
                // </li>
              );
            if (page === RIGHT_PAGE)
              return (
                <div className="pagination-separator">–</div>

                // <li key={index} className="page-item">
                //   <a className="page-link" href="#" aria-label="Next" onClick={this.handleMoveRight}>
                // 	<span aria-hidden="true">&raquo;</span>
                // 	<span className="sr-only">Next</span>
                //   </a>
                // </li>
              );
            return (
              <div
                onClick={this.handleClick(page)}
                className={`inline-block py-10 pr-15 ${
                  this.props.currentPage === page ? "text-primary" : "text-link-gray"
                } `}
              >
                {page}
              </div>
            );
          })}
          {/* <a href="#" class="py-10 pr-15 text-link-gray">2</a>
          <a href="#" class="py-10 pr-15 text-link-gray">3</a>
          <a href="#" class="py-10 pr-15 text-link-gray">4</a> */}
        </div>
        <div class="flex-none flex items-center">
          {/* {# Prev #} */}
          {this.props.currentPage > 1 ? (
            <a href="#" class="py-10 text-link-gray" onClick={this.handleMoveLeft}>
              Prev
            </a>
          ) : (
            <a href="#" class="py-10 text-link-gray" disabled>
              Prev
            </a>
          )}

          <div class="flex-none flex px-15 text-gray-300">/</div>
          {/* {# Next #} */}
          {this.props.totalRecords - this.state.pageLimit * this.props.currentPage > 0 ? (
            <a href="#" class="py-10 text-link-gray" onClick={this.handleMoveRight}>
              Next
            </a>
          ) : (
            <a href="#" class="py-10 text-link-gray" disabled>
              Next
            </a>
          )}
        </div>
      </div>
      // 	<div className="pagination mb-50 lg:mb-80">

      // 	{/* {# Pages #} */}
      // 	<div className="pagination-pages">
      // 		{pages.map((page, index)=>{
      // 			 if (page === LEFT_PAGE) return (
      // 				<div className="pagination-separator">–</div>
      // 				// <li key={index} className="page-item">
      // 				//   <a className="page-link" href="#" aria-label="Previous" >
      // 				// 	<span aria-hidden="true">&laquo;</span>
      // 				// 	<span className="sr-only">Previous</span>
      // 				//   </a>
      // 				// </li>
      // 			  );

      // 			  if (page === RIGHT_PAGE) return (
      // 				<div className="pagination-separator">–</div>

      // 				// <li key={index} className="page-item">
      // 				//   <a className="page-link" href="#" aria-label="Next" onClick={this.handleMoveRight}>
      // 				// 	<span aria-hidden="true">&raquo;</span>
      // 				// 	<span className="sr-only">Next</span>
      // 				//   </a>
      // 				// </li>
      // 			  );
      // 			return(
      // 				<button type="button" onClick={ this.handleClick(page) } className={`pagination-item ${this.props.currentPage === page && "is-active"}`}>{page}</button>
      // 			)
      // 		})}
      // 		{/* <button type="button" className={`pagination-item ${props.currentPage === 2 && "is-active"}`}>2</button>
      // 		<button type="button" className={`pagination-item ${props.currentPage === 3 && "is-active"}`}>3</button>
      // 		<button type="button" className={`pagination-item ${props.currentPage === 4 && "is-active"}`}>4</button>
      // 		<button type="button" className={`pagination-item ${props.currentPage === 5 && "is-active"}`}>5</button> */}
      // 		{/* {# Last #} */}
      // 		{/* <div className="pagination-total">
      // 			<button type="button" className="pagination-item">25</button>
      // 		</div> */}
      // 	</div>

      // </div>
    );
  }
}
export default SalesPagination;

import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { FavoritesConsumer } from "../../FavoritesContext/index";
import { PlayerContext } from "../../PlayerContext";
import { Error, Success } from "../Toast/index";
import StemsTab from "../Track/stemsTab";
import DetailsTab from "../Track/tabDetails";
import VersionsTab from "../Track/versionsTab";
import api from "../utils/api";
import TrackListBrowse from "../Waveform/trackListBrowse";
import TrackListBrowseNoInteract from "../Waveform/trackListBrowseNoInteract";
import TrackIcons from "../Track/TrackIcons";
import ProjectVotes from "./projectVotes";

class ProjectListCard extends Component {
  state = {
    isDropdown: false,
    versions: [],
    stems: [],
    liked: this.props.liked,
    activeTab: "details",
  };
  toggleDropdown = (e) => {
    this.setState({ isDropdown: !this.state.isDropdown });
  };
  toggleTrackTippy = () => {
    this.setState({ showTrackTippy: !this.state.showTrackTippy });
  };

  componentDidMount = () => {
    if (this.props.isPublicPage) {
      this.setState({ activeTab: "details" });
    } else {
      Object.filter = (obj, predicate) =>
        Object.keys(obj)
          .filter((key) => predicate(obj[key]))
          .reduce((res, key) => ((res[key] = obj[key]), res), {});
      let upvotes = Object.filter(this.props.votes, (vote) => vote.voteValue === 1);
      let upvotesArr = [];
      for (const prop in upvotes) {
        //
        upvotesArr.push(upvotes[prop]);
      }
      let downvotes = Object.filter(this.props.votes, (vote) => vote.voteValue === 0);
      let downVotesArr = [];
      for (const prop in downvotes) {
        //
        downVotesArr.push(downvotes[prop]);
      }
      if (upvotesArr.length > 0) {
        this.setState({ activeTab: "upvotes" });
      } else if (downVotesArr.length > 0) {
        this.setState({ activeTab: "downvotes" });
      } else {
        this.setState({ activeTab: "details" });
      }
    }
  };

  updateVote = (voteTrackId, voteValue) => {
    api.updateSceneTrackVote(voteTrackId, { voteValue: voteValue }).then((res) => {
      if (res.data.updatedVote.voteValue === 1) {
        this.setState({ upvoted: true, downvoted: false }, () => {});
      } else if (res.data.updatedVote.voteValue === 0) {
        this.setState({ upvoted: false, downvoted: true });
      }
    });
  };
  voteCB = () => {
    Object.filter = (obj, predicate) =>
      Object.keys(obj)
        .filter((key) => predicate(obj[key]))
        .reduce((res, key) => ((res[key] = obj[key]), res), {});
    let upvotes = Object.filter(this.props.votes, (vote) => vote.voteValue === 1);
    let upvotesArr = [];
    for (const prop in upvotes) {
      //
      upvotesArr.push(upvotes[prop]);
    }
    let downvotes = Object.filter(this.props.votes, (vote) => vote.voteValue === 0);
    let downVotesArr = [];
    for (const prop in downvotes) {
      //
      downVotesArr.push(downvotes[prop]);
    }
    if (this.state.activeTab === "downvotes") {
      if (downVotesArr.length < 1 && upvotesArr.length > 0) {
        this.setState({ activeTab: "upvotes" });
      } else {
        this.setState({ activeTab: "details" });
      }
    } else if (this.state.activeTab === "upvotes") {
      if (upvotesArr.length < 1 && downVotesArr.length > 0) {
        this.setState({ activeTab: "downvotes" });
      } else {
        this.setState({ activeTab: "details" });
      }
    }
  };
  submitVote = (sceneTrackId, voteValue) => {
    api
      .submitSceneTrackVote(sceneTrackId, this.props.projectId, { voteValue: voteValue })
      .then((res) => {
        if (res.data.newVote.voteValue === 1) {
          this.setState({ upvoted: true, downvoted: false, myVote: res.data.newVote.id });
        } else if (res.data.newVote.voteValue === 0) {
          this.setState({ upvoted: false, downvoted: true, myVote: res.data.newVote.id });
        }
      });
  };
  getVoters = (isUp) => {
    Object.filter = (obj, predicate) =>
      Object.keys(obj)
        .filter((key) => predicate(obj[key]))
        .reduce((res, key) => ((res[key] = obj[key]), res), {});
    let filterVal = isUp ? 1 : 0;
    let voters = Object.filter(this.props.votes, (vote) => vote.voteValue === filterVal);
    let votersArr = [];
    for (const prop in voters) {
      //
      votersArr.push(voters[prop]);
    }
    return votersArr;
  };
  getVoteCount = () => {
    Object.filter = (obj, predicate) =>
      Object.keys(obj)
        .filter((key) => predicate(obj[key]))
        .reduce((res, key) => ((res[key] = obj[key]), res), {});
    let positiveVotes = Object.filter(this.props.votes, (vote) => vote.voteValue === 1);
    let negativeVotes = Object.filter(this.props.votes, (vote) => vote.voteValue === 0);
    //
    // return 3
    if (Object.keys(positiveVotes).length === 0 && Object.keys(negativeVotes).length === 0) {
      return "-";
    } else {
      return Object.keys(positiveVotes).length - Object.keys(negativeVotes).length;
    }
  };
  resolveAlbumPhoto = (albumPhotoKey) => {
    const key = albumPhotoKey;
    if (albumPhotoKey) {
      const ext = key.split(".").reverse()[0];
      let final = key.replace("." + ext, "") + "-minified-500x500" + "." + ext;
      return final;
    }
  };
  shareTrack = () => {
    var textArea = document.createElement("textarea");

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = "2em";
    textArea.style.height = "2em";

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = "transparent";

    textArea.value = `${window.location.protocol}//${window.location.host}/tracks/${this.props.trackURL}`;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      if (successful) {
        Success({ message: "Track URL Copied to Clipboard" });
      } else {
        Error({ message: "Couldn't Copy Track URL " });
      }
      var msg = successful ? "successful" : "unsuccessful";
    } catch (err) {}

    document.body.removeChild(textArea);

    this.setState({ showTrackTippy: false });
  };
  play = () => {
    if (
      (this.context.mediaPlayer?.idKey?.indexOf(this.props.trackId) || -1) === -1 ||
      (this.context.mediaPlayer?.idKey?.indexOf("-scene-" + this.props.sceneId) || -1) === -1
    ) {
      let playList = this.props.otherTracks;
      let foundIndex = this.props.otherTracks.findIndex(
        (otherTrack) => otherTrack.idKey === this.props.idKey
      );
      this.context.loadTrackInMediaPlayer(playList[foundIndex].trackId, playList[foundIndex].idKey);
      this.context.setAutoPlay(false);
      this.context.setProjectId(null);
      this.context.setCollectionId(null);
      if (this.props.otherTracks?.length > 0) {
        this.context.setPlaylist(this.props.otherTracks.map((track) => track.trackId));
      }
    } else {
      this.context.handlePlay();
    }
  };
  handleLicenseModalOpen = () => {
    this.setState({ isLicenseModalOpen: true });
  };
  handleLicenseModalClose = () => {
    this.setState({ isLicenseModalOpen: false });
  };
  render() {
    return (
      <div class="project-track-item" key={"sceneTrack-" + this.props.sceneTrackId + "-track"}>
        {/* {# Track List Card #} */}
        <div
          className={`track-list-card${this.state.isDropdown ? " dropdown-open" : ""}${
            (this.context.mediaPlayer?.idKey?.indexOf("-track-" + this.props.trackId) || -1) !==
              -1 &&
            (this.context.mediaPlayer?.idKey?.indexOf("-scene-" + this.props.sceneId) || -1) !==
              -1 &&
            (this.context.mediaPlayer?.isTrackPlaying || this.context.isVideoPlaying)
              ? " is-playing"
              : ""
          }`}
        >
          {/* {# Track Voting #} */}
          {!this.props.isPublicPage && (
            <div class="project-track-voting" x-data="{'vote_status': 'none'}">
              {this.props.myVote !== undefined ? (
                <>
                  {this.props.myVote.voteValue === 1 ? (
                    <button class="project-track-upvote is-active">
                      <span class="icon-chevron-up"></span>
                    </button>
                  ) : (
                    <button
                      class="project-track-upvote"
                      onClick={() =>
                        this.props.updateVote(
                          this.props.myVote.id,
                          true,
                          this.props.sceneTrackIndex,
                          this.voteCB
                        )
                      }
                    >
                      <span class="icon-chevron-up"></span>
                    </button>
                  )}
                  <div class="project-track-vote-count">{this.getVoteCount()}</div>
                  {this.props.myVote.voteValue === 0 ? (
                    <button class="project-track-downvote is-active">
                      <span class="icon-chevron-down"></span>
                    </button>
                  ) : (
                    <button class="project-track-downvote">
                      <span
                        class="icon-chevron-down"
                        onClick={() =>
                          this.props.updateVote(
                            this.props.myVote.id,
                            false,
                            this.props.sceneTrackIndex,
                            this.voteCB
                          )
                        }
                      ></span>
                    </button>
                  )}
                </>
              ) : (
                <>
                  {this.state.upvoted ? (
                    <button class="project-track-upvote is-active">
                      <span class="icon-chevron-up"></span>
                    </button>
                  ) : (
                    <button
                      class="project-track-upvote"
                      onClick={() =>
                        this.props.submitVote(
                          this.props.sceneTrackId,
                          true,
                          this.props.sceneTrackIndex,
                          this.voteCB
                        )
                      }
                    >
                      <span class="icon-chevron-up"></span>
                    </button>
                  )}

                  <div class="project-track-vote-count">{this.getVoteCount()}</div>

                  {this.state.downvoted ? (
                    <button class="project-track-downvote is-active">
                      <span class="icon-chevron-down"></span>
                    </button>
                  ) : (
                    <button class="project-track-downvote">
                      <span
                        class="icon-chevron-down"
                        onClick={() =>
                          this.props.submitVote(
                            this.props.sceneTrackId,
                            false,
                            this.props.sceneTrackIndex,
                            this.voteCB
                          )
                        }
                      ></span>
                    </button>
                  )}
                </>
              )}
            </div>
          )}

          {/* {# First Column #} */}
          <div class="track-first-col">
            <div
              onClick={
                (this.context.mediaPlayer?.idKey?.indexOf("-track-" + this.props.trackId) || -1) !==
                  -1 &&
                (this.context.mediaPlayer?.idKey?.indexOf("-scene-" + this.props.sceneId) || -1) !==
                  -1 &&
                (this.context.mediaPlayer.isTrackPlaying || this.context.isVideoPlaying)
                  ? this.context.handlePause
                  : this.play
              }
              className="track-image"
            >
              <img
                src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${
                  process.env.REACT_APP_S3_SUB_BUCKET
                    ? process.env.REACT_APP_S3_SUB_BUCKET + "/"
                    : ""
                }${this.resolveAlbumPhoto(this.props.imageURL)}`}
                alt="albumArt"
              />
            </div>
            <div className="track-title-artist">
              <NavLink to={"/tracks/" + this.props.trackURL}>
                <span className="track-title">{this.props.trackTitle}</span>
              </NavLink>
              {this.props.listType !== "album" && (
                <NavLink to={"/artists/" + this.props.artistURL}>
                  <span className="track-artist">{this.props.artistName}</span>
                </NavLink>
              )}
            </div>
            <button
              className={`circle-icon circle-icon-sm icon-chevron-down${
                this.state.isDropdown ? " dropdown-open" : ""
              }`}
              onClick={this.toggleDropdown}
            ></button>
          </div>

          {/* {# Genres #} */}
          <div className="track-genres text-gray-400">
            {Array.isArray(this.props.tags) && (
              <>
                {this.props.tags
                  .filter((tag) => tag.type === 2)
                  .map((emotion) =>
                    window.location.pathname.includes("/music") ||
                    /\/artists\/{0}[^\/]*$/.test(window.location.pathname) ? (
                      <div>{emotion.name}</div>
                    ) : (
                      <Link
                        to={{
                          pathname: "/music",
                          search: `?inc_emo=${emotion.tagId}`,
                          state: { from: window.location.pathname },
                        }}
                      >
                        <span>{emotion.name}</span>
                      </Link>
                    )
                  )}
                {this.props.tags.filter((tag) => tag.type === 1).length > 0 &&
                  (window.location.pathname.includes("/music") ||
                  /\/artists\/{0}[^\/]*$/.test(window.location.pathname) ? (
                    <div>, {this.props.tags.filter((tag) => tag.type === 1)[0].name}</div>
                  ) : (
                    <Link
                      to={{
                        pathname: "/music",
                        search: `?inc_gen=${
                          this.props.tags.filter((tag) => tag.type === 1)[0].tagId
                        }`,
                        state: { from: window.location.pathname },
                      }}
                    >
                      <span> </span>
                      <span>{this.props.tags.filter((tag) => tag.type === 1)[0].name}</span>
                    </Link>
                  ))}
              </>
            )}
          </div>

          {/* {# Last Column #} */}
          <div className="track-last-col">
            <div className="track-wave-time flex items-center">
              <span
                className={`icons-stems-versions text-gray-400 icon${
                  this.props.stems?.length ? "-stems" : ""
                }${this.props.versions?.length ? "-versions" : ""}`}
              ></span>
              <div className="wave-form-vector">
                {this.props.peaks && (
                  <div>
                    {this.context.mediaPlayer.idKey === this.props.idKey ? (
                      <TrackListBrowse
                        idKey={this.props.idKey}
                        currentTime={this.state.currentTime}
                        trackId={this.props.trackId}
                        s3Key={this.props.wavURL}
                        peaks={this.props.peaks}
                      />
                    ) : (
                      <TrackListBrowseNoInteract
                        idKey={this.props.idKey}
                        currentTime={this.state.currentTime}
                        trackId={this.props.trackId}
                        s3Key={this.props.wavURL}
                        peaks={this.props.peaks}
                      />
                    )}
                  </div>
                )}
              </div>
              <span className="track-duration">{this.props.duration}</span>
            </div>
            <TrackIcons
              artistId={this.props.artistId}
              artistName={this.props.artistName}
              imageURL={this.props.imageURL}
              removeTrack={this.props.removeTrack}
              sceneTrackId={this.props.sceneTrackId}
              trackId={this.props.trackId}
              trackTitle={this.props.trackTitle}
              trackURL={this.props.trackURL}
            />
          </div>

          {/* {# Track Dropdown #} */}
          <div class="track-dropdown">
            <div class="track-dropdown-content">
              <div class="tabs tabs-sm">
                <ul className="tab-items">
                  {!this.props.isPublicPage && this.getVoters(true).length > 0 && (
                    <li
                      onClick={() => {
                        this.setState({ activeTab: "upvotes" });
                      }}
                      className={`tab-item ${this.state.activeTab === "upvotes" && "active"}`}
                    >
                      <a href="# " className="tab-item-title">
                        Upvotes <span class="ml-3">{this.getVoters(true).length}</span>{" "}
                      </a>
                    </li>
                  )}
                  {!this.props.isPublicPage && this.getVoters(false).length > 0 && (
                    <li
                      onClick={() => {
                        this.setState({ activeTab: "downvotes" });
                      }}
                      className={`tab-item ${this.state.activeTab === "downvotes" && "active"}`}
                    >
                      <a href="# " className="tab-item-title">
                        Downvotes <span class="ml-3">{this.getVoters(false).length}</span>
                      </a>
                    </li>
                  )}
                  <li
                    onClick={() => {
                      this.setState({ activeTab: "details" });
                    }}
                    className={`tab-item ${this.state.activeTab === "details" && "active"}`}
                  >
                    <a href="# " className="tab-item-title">
                      Details
                    </a>
                  </li>
                  {(this.state.versions.length > 0 || this.props.versions?.length > 0) && (
                    <li
                      onClick={() => {
                        this.setState({ activeTab: "versions" }, () =>
                          this.context.getVersionInfo(this.props.trackId)
                        );
                      }}
                      className={`tab-item ${this.state.activeTab === "versions" && "active"}`}
                    >
                      <a href="# " className="tab-item-title">
                        Versions
                      </a>
                    </li>
                  )}
                  {(this.state.stems.length > 0 || this.props.stems?.length > 0) && (
                    <li
                      onClick={() => {
                        this.setState({ activeTab: "stems" }, () =>
                          this.context.getStemInfo(this.props.trackId)
                        );
                      }}
                      className={`tab-item ${this.state.activeTab === "stems" && "active"}`}
                    >
                      <a href="# " className="tab-item-title">
                        Stems
                      </a>
                    </li>
                  )}
                  <li class="tab-item tab-item-end hidden md:flex">
                    <NavLink to={"/tracks/" + this.props.trackURL} className="tab-item-title">
                      View Track Page
                    </NavLink>
                  </li>
                </ul>
                <div class="tab-content">
                  {/* {# Details #} */}
                  {!this.props.isPublicPage && this.state.activeTab === "upvotes" && (
                    <ProjectVotes users={this.getVoters(true)} />
                  )}
                  {!this.props.isPublicPage && this.state.activeTab === "downvotes" && (
                    <ProjectVotes users={this.getVoters(false)} />
                  )}
                  {this.state.activeTab === "details" && (
                    <DetailsTab
                      genres={this.props.tags.filter((tag) => tag.type === 1)}
                      emotion={this.props.tags.filter((tag) => tag.type === 2)}
                      moods={this.props.tags.filter((tag) => tag.type === 3)}
                      attributes={this.props.tags.filter((tag) => tag.type === 4)}
                      instruments={this.props.tags.filter((tag) => tag.type === 5)}
                      city={this.props.tags.filter((tag) => tag.type === 6)}
                      lyrics={this.props.tags.filter((tag) => tag.type === 7)}
                      speed={this.props.tags.filter((tag) => tag.type === 8)}
                    />
                  )}
                  {/* {# Versions #} */}
                  {this.state.activeTab === "versions" && (
                    <VersionsTab
                      currentTime={this.state.currentTime}
                      artistId={this.props.artistId}
                      albumId={this.props.albumId}
                      trackId={this.props.trackId}
                      changeTrack={this.changeVersion}
                      versions={this.props.versions}
                      trackTitle={this.props.trackTitle}
                      artistName={this.props.artistName}
                      imageURL={this.props.imageURL}
                    />
                  )}
                  {/* {# Stems #} */}
                  {this.state.activeTab === "stems" && (
                    <StemsTab
                      currentTime={this.state.currentTime}
                      changeTrack={this.changeStem}
                      artistId={this.props.artistId}
                      albumId={this.props.albumId}
                      trackId={this.props.trackId}
                      stems={this.props.stems}
                      trackTitle={this.props.trackTitle}
                      artistName={this.props.artistName}
                      imageURL={this.props.imageURL}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
ProjectListCard.contextType = PlayerContext;

export default ProjectListCard;

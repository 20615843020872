import React, { Component } from "react";
import { UserConsumer } from "../../contexts/User";
import Footer from '../Footer';
import Navbar from "../Navbar";


class AppLayoutNoContent extends Component {
  state = {

    }

  setCurrentNav = (updatedNav)=>{
    this.setState({currentNav: updatedNav})
  }

  render() {
    return (
      <UserConsumer>
        {({ user, login, logout, currentNav, setCurrentNav})=>(
          <>
            <Navbar pathname={window.location.pathname}></Navbar>
            <div className='app-container'>

              <div className='app-main'>

                    {this.props.children}
                <Footer></Footer>
              </div>
            </div>
          </>
        )}
      </UserConsumer>
    )
  }
}

export default AppLayoutNoContent

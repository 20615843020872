import React, { Component } from "react";
// import tippy from "../../tooltips"

class AdminTrackTooltip extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {

            }
    //updating our input fields as the user enters keys
    handleInputChange = event => {   
    };
    render() {
        return (    
                <div className="dropdown-menu">
                    {/* Removing for now per https://app.clubhouse.io/deep-sounds/story/570/track-review-admin-context-menu
                    <div className="dropdown-group">
                        <a href="# " onClick={this.props.download}>Download Track</a>
                        <a href="# " onClick={this.props.downloadVersions}>Download Versions</a>
                        <a href="# " onClick={this.props.downloadStems}>Download Stems</a>
                        <a href="# " onClick={this.props.downloadAll}>Download All Files</a>
                    </div>
                    */}
                    <div className="dropdown-group">
                        <a href="# " onClick={this.props.delete}><span className="icon-trash"></span>Delete</a>
                    </div>
                </div>
        )
    }

}
export default AdminTrackTooltip

import { Info } from "luxon";
import { useMemo } from "react";

function months(length) {
  const allMonths = Info.months(length);
  console.log("allMonths", allMonths);
  return allMonths;
}

// A custom hook that provides years options
export default function useMonths(length = "2-digit") {
  return useMemo(() => months(length, [length]));
}

import React, { Component } from "react"
import { PlayerConsumer } from "../../../PlayerContext"
import VideoStudioMain from "./main"
import VideoLoader from "./videoLoader"
class VideoStudioComponent extends Component{
    state={
        appMainRef: false
    }
    // componentDidMount = ()=>{
    //     this.setState({appMainRef: false},()=>{
    //         this.setAppMain()

    //     })
    //     
    // }
    // setAppMain = ()=>{
    //     
    //     if(!this.state.appMainRef){
    //         if(document.getElementsByClassName("app-main")[0]){
    //             this.setState({appMainRef: document.getElementsByClassName("app-main")[0]})
    //         }
    //         else{
    //             setTimeout(this.setAppMain, 750)
    //         }
    //     }
    // }
    // componentDidUpdate = ()=>{
    //     
    //     if(this.state.appMainRef !== document.getElementsByClassName("app-main")[0]){
    //         this.setState({appMainRef: document.getElementsByClassName("app-main")[0]})
    //     }
    // }
    render(){
        return(

            <>
            <PlayerConsumer>
                      {({isLoaderOpen, isMainOpen, isDemoOpen, isMini, video, videoURL, isVideoPlaying, videoDurationSeconds, videoDurationFormatted, playedPercent, playedSeconds, playedFormatted, videoVolume, isMuted, videoTimelineWidth, initialLeftCutoff, trackStartTime, videoElement, isLoopEnabled,
                        getVideoTimelineWidth, openVideoLoader, closeVideoLoader, openVideoMain, closeVideoMain, minifyPlayer, maximizePlayer, handlePause, setVideoElement, loadVideo, unloadVideo, playVideo, playVideoFromIn, playVideoLoop, pauseVideo, replayVideo, seekTo, videoSeekTo, setVideoDuration, updateVideoProgress, changeVideoVolume, toggleMute, updateVideoOffset, resetPlayed, toggleLoop,
                        drawVideoStudioTimeline})=>(
                        <>
                            <VideoLoader isLoaderOpen={isLoaderOpen}  closeVideoLoader={closeVideoLoader} loadVideo={loadVideo}/>
                            <VideoStudioMain isMainOpen={isMainOpen || isDemoOpen} isDemoOpen={isDemoOpen} isMini={isMini} openVideoLoader={openVideoLoader} loadVideo={loadVideo} video={video} videoURL={videoURL} 
                                isVideoPlaying={isVideoPlaying} playVideo={playVideo} playVideoFromIn={playVideoFromIn} playVideoLoop={playVideoLoop} handlePause={handlePause} pauseVideo={pauseVideo} setVideoDuration={setVideoDuration} videoDurationSeconds={videoDurationSeconds} videoDurationFormatted={videoDurationFormatted} playedPercent={playedPercent}
                                    playedSeconds={playedSeconds} playedFormatted={playedFormatted} updateVideoProgress={updateVideoProgress} openVideoMain={openVideoMain} closeVideoMain={closeVideoMain} minifyPlayer={minifyPlayer} maximizePlayer={maximizePlayer} changeVideoVolume={changeVideoVolume} videoVolume={videoVolume}
                                    getVideoTimelineWidth={getVideoTimelineWidth} videoTimelineWidth={videoTimelineWidth} unloadVideo={unloadVideo} initialLeftCutoff={initialLeftCutoff} trackStartTime={trackStartTime} updateVideoOffset={updateVideoOffset} resetPlayed={resetPlayed} videoElement={videoElement} setVideoElement={setVideoElement}
                                    replayVideo={replayVideo} videoSeekTo={videoSeekTo} seekTo={seekTo} isLoopEnabled={isLoopEnabled} toggleLoop={toggleLoop} isMuted={isMuted} toggleMute={toggleMute} drawVideoStudioTimeline={drawVideoStudioTimeline}/>
                            
                        </>
                      )}

                </PlayerConsumer>

</>
        )
    }
}

export default VideoStudioComponent
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import SlidingPane from "react-sliding-pane";
import FAQ from "../FAQ";
import IntercomContactButton from "../Intercom/IntercomContactButton";
import LicenseModal from "../modal/LicenseModal";
import DownloadTrack from "../Track/DownloadTrack";

function Help() {
  const [isDemoLicenseModalOpen, setIsDemoLicenseModalOpen] = useState(false);

  const handleLicenseModalOpen = () => {
    setIsDemoLicenseModalOpen(true);
  };

  const handleLicenseModalClose = () => {
    setIsDemoLicenseModalOpen(false);
  };

  return (
    <>
      <Helmet title="Help - Deep Sounds | Music for Creators" />
      <div className="container max-w-4xl text-center leading-tightest pt-100 pb-40 xl:pt-120 xl:pb-40">
        <h1 className="text-h1-sm md:text-h1 mb-10 md:mb-20">
          How can we help?
        </h1>

        {/* <div className="max-w-xl mx-auto text-h4 text-gray-500">
                    <p>We are here to help you through this beautiful journey in finding the perfect track for your project.</p>
                </div> */}
      </div>
      <section className="container max-w-lg md:max-w-screen-3xl mb-60 lg:mb-80">
        <div class="article max-w-2xl mx-auto">
          <p class="text-h4 text-white">
            At Deep Sounds our mission is to make Music Licensing simple. If you
            do find yourself frustrated at all, please click the "Chill Out"
            button below to help you calm down and take a deep breathe. We are
            here for you.
          </p>
          <div class="text-center my-30">
            {/* <PlayerConsumer> */}
              {/* {({ playCollectionURL }) => ( */}
                {/* <button */}
                <a
                  className="btn btn-stroke w-full lg:w-1/2"
                  // onClick={() => playCollectionURL('calm--chill')}
                  href="https://www.deepsounds.com/collections/calm--chill"
                  rel="noreferrer"
                  target="_blank"
                  // type="button"
                >
                  Chill Out
                </a>
                {/* </button> */}
              {/* )} */}
            {/* </PlayerConsumer> */}
          </div>
          <h2 className="py-20 text-center">Support Topics</h2>
          <div className="mb-20">
            <div class="btn-group justify-center flex-wrap">
                <a
                  class="btn btn-stroke mb-10"
                  href="https://help.deepsounds.com/en/collections/2533183-music-licensing"
                  target="_blank"
                  rel="noreferrer"
                >
                  Music Licensing
                </a>
                <a
                  class="btn btn-stroke mb-10"
                  href="https://help.deepsounds.com/en/collections/3338912-subscription-plans"
                  target="_blank"
                  rel="noreferrer"
                >
                  Subscription Plans
                </a>
                <a
                  class="btn btn-stroke mb-10"
                  href="https://help.deepsounds.com/en/collections/3338938-content-id"
                  target="_blank"
                  rel="noreferrer"
                >
                  Content ID
                </a>
                <a
                  class="btn btn-stroke mb-10"
                  href="https://help.deepsounds.com/en/collections/2539419-features"
                  target="_blank"
                  rel="noreferrer"
                >
                  Features
                </a>
            </div>
          </div>
        </div>
      </section>
      <section className="container max-w-lg md:max-w-screen-xl mb-60 lg:mb-80">
        <div class="article max-w-2xl mx-auto">
          <h2 className="py-20 text-center">Pricing &amp; Plans</h2>

          <div class="grid-row">
            <div class="col w-full md:w-1/2">
              <div class="max-w-lg mx-auto text-sm text-center text-gray-400 mb-80">
                <h2 class="font-body normal-case text-h4 text-white mb-5">
                  How much is each license?
                </h2>
                <p class="text-h4 text-gray-400 mb-30">
                  Click below to see our individual track licensing prices.
                </p>
                <DownloadTrack
                artistId={0}
                artistName={"Use dropdowns below to see pricing"}
                className="btn btn-stroke btn-min-w"
                isDemo={true}
                text={"Licensing Pricing"}
                trackTitle={"Track Licensing Pricing"}
              />
              </div>
            </div>
            <div class="col w-full md:w-1/2">
              <div class="max-w-lg mx-auto text-sm text-center text-gray-400 mb-80">
                <h2 class="font-body normal-case text-h4 text-white mb-5">
                  Unlimited Subscription Plans
                </h2>
                <p class="text-h4 text-gray-400 mb-30">
                  View and compare all our unlimited subscription plans.
                </p>
                <a
                  href="/pricing"
                  class="btn btn-stroke btn-min-w"
                >
                  Subscription Plans
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* {# FAQs #} */}
      <section class="container mb-60 lg:mb-80">
        {/* {# Header #} */}
        <div class="max-w-2xl mb-60">
          <h2>Frequently Asked Questions</h2>
        </div>

        {/* {# FAQ Items #} */}
        <FAQ />
      </section>

      <section className="container max-w-lg md:max-w-screen-xl mb-60 lg:mb-80">
        <div class="article max-w-2xl mx-auto">
          <div class="grid-row">
            <div class="col w-full md:w-1/2">
              <div class="max-w-lg mx-auto text-sm text-center text-gray-400 mb-80">
                <h2 class="font-body normal-case text-h4 text-white mb-5">
                  Not seeing what you need?
                </h2>
                <p class="text-h4 text-gray-400 mb-30">
                  Click below to view and search all our support articles.
                </p>
                <a
                  class="btn btn-stroke btn-min-w"
                  href="http://help.deepsounds.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Support Articles
                </a>
              </div>
            </div>
            <div class="col w-full md:w-1/2">
              <div class="max-w-lg mx-auto text-sm text-center text-gray-400 mb-80">
                <h2 class="font-body normal-case text-h4 text-white mb-5">
                  Still need help?
                </h2>
                <p class="text-h4 text-gray-400 mb-30">
                  Please reach out to our support team for more information.
                </p>
                <div class="intercom-contact mr-10">
                  <IntercomContactButton
                    className="btn btn-stroke btn-min-w"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <SlidingPane
        isOpen={isDemoLicenseModalOpen}
        onRequestClose={() => {
          handleLicenseModalClose();
        }}
        from={"right"}
        width={"100%"}
        children={
          <LicenseModal
            handleClose={() => {
              handleLicenseModalClose();
            }}
            isDemo={true}
          />
        }
      />
      {/* <section class="container max-w-lg md:max-w-screen-3xl mb-60 lg:mb-80">
                <div className="max-w-2xl mx-auto text-h4">
                    <div className="grid-row">
                        <p className="text-h4 text-white text-center">How can we help?</p>
                    </div>

                    <div className="btn-group my-20 grid-row text-center">
                        <div className="col w-full md:w-1/2">
                            <a className="btn btn-stroke" href="http://help.deepsounds.com/" target="_blank">Support Articles</a>
                        </div>
                        <div className="col w-full md:w-1/2 intercom-contact text-center">
                            <a className="btn btn-stroke" href="#contact">Contact Us</a>
                        </div>
                    </div>

                </div>
            </section> */}
    </>
  );
}
export default Help;

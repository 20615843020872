import React, { Component } from "react";
import Footer from '../Footer';
import Navbar from "../Navbar";
import SideNav from '../Navbar/sideNav';

class AppLayout extends Component {
  state = {

    }

  setCurrentNav = (updatedNav)=>{
    this.setState({currentNav: updatedNav})
  }

  render() {
    return (
          <>
            <Navbar pathname={window.location.pathname}></Navbar>
            <div className='app-container'>
              {!this.props.noSide &&
                <SideNav pathname={window.location.pathname}></SideNav>
              }
              <div className='app-main'>
                <div className='content'>
                    {this.props.children}
                </div>
                <Footer></Footer>
              </div>
            </div>
          </>
    )
  }
}

export default AppLayout

import React from "react";
import { DateTime } from "luxon";
import AssetReportsRow from "./AssetReportsRow";

const AssetReportsRows = ({ assetReports }) => {
  return assetReports?.map((assetReport) => {
    const { month, year } = assetReport || {};
    const monthStr = DateTime.fromObject({ month, year }).toFormat("yyyy-MM");
    return <AssetReportsRow key={monthStr} monthStr={monthStr} assetReport={assetReport} />;
  });
};

export default AssetReportsRows;

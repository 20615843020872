import React from "react";
import CircularIndeterminateButton from "../../Loading/circularIndeterminantButton";
export default function LoadingButton({
  children,
  isDisabled,
  isLoading,
  className,
  ...inputProps
}) {
  const preparedClassName =
    `loading-button ` + (className || "btn btn-primary") + (isLoading ? " loading" : "");

  return (
    <button className={preparedClassName} disabled={isLoading || isDisabled} {...inputProps}>
      <span className="spinner">
        <CircularIndeterminateButton color="black" size={35} />
      </span>
      <span className="button-text">{children ? children : "Submit"}</span>
    </button>
  );
}

import Skeleton from "@material-ui/lab/Skeleton";
import React, { useContext } from "react";
import { PlayerContext } from "../../PlayerContext";
import { UserConsumer } from "../../contexts/User";
import api from "../utils/api";
import DownloadTrack from "./DownloadTrack";
import VersionWaveform from "../Waveform/versionBrowse";
import VersionWaveformNoInteract from "../Waveform/versionBrowseNoInteract";
import "./versions.css";

const VersionsTab = (props) => {

  console.log('VersionsTab props', props);

  const {
    albumId,
    artistId,
    artistName,
    currentTime,
    imageURL,
    trackId,
    trackTitle,
    versions
  } = props;

  const playerContext = useContext(PlayerContext);

  const {
    handlePause,
    handlePlay,
    isVideoPlaying,
    loadedVersions,
    loadVersionInMediaPlayer,
    mediaPlayer,
    playing
  } = playerContext;

  return (
    <UserConsumer>
      {({ user, triggerLoginModal }) => (
        <div className="tab-item-content py-0 active" id="tab_versions">
          {versions.map((version, index) => (
            <div
              key={"version-" + version}
              className={`audio-item py-10 border-b border-gray-300  ${
                mediaPlayer.trackId === version.id && playing
                  ? "is-playing"
                  : ""
              }`}
            >
              <div className="audio-item-pre">
                <button className="circle-icon circle-icon-sm">
                  {(mediaPlayer?.idKey?.indexOf("version-" + version) || -1) !== -1 &&
                  (mediaPlayer.isTrackPlaying || isVideoPlaying) ? (
                    <span onClick={() => handlePause()} className="icon-pause"></span>
                  ) : (mediaPlayer?.idKey?.indexOf("version-" + version) || -1) ===
                    -1 ? (
                    <span
                      onClick={() => {
                        if (
                          user !== null ||
                          window.location.pathname.indexOf("/projects/public/") !== -1
                        ) {
                          loadVersionInMediaPlayer(
                            trackId,
                            version,
                            `artist-${artistId}-album-${albumId}-track(fromVersion)-${trackId}-version-${version}-versionItem`
                          );
                        } else {
                          triggerLoginModal();
                        }
                      }}
                      className="icon-play"
                    ></span>
                  ) : (
                    <span onClick={() => handlePlay()} className="icon-play"></span>
                  )}
                </button>
                {/* } */}
              </div>
              <div className="audio-item-details">
                {loadedVersions[version].name ? (
                  <div className="audio-item-title">
                    {loadedVersions[version].name}
                  </div>
                ) : (
                  <Skeleton
                    component="div"
                    style={{ backgroundColor: "rgb(210 205 205 / 42%)" }}
                    animation="pulse"
                  >
                    <div className="audio-item-title">Clean Version (No explicit lyrics)</div>
                  </Skeleton>
                )}
              </div>
              <div className="audio-item-wave">
                {/* <svg className="w-full h-40 lg:h-50" viewBox="0 0 910 50" version="1.1" preserveAspectRatio="none"> */}
                <div className="wav-patch">
                  {loadedVersions[version].peaks ? (
                    (mediaPlayer?.idKey?.indexOf(`version-${version}`) || -1) !==
                    -1 ? (
                      <VersionWaveform
                        currentTime={currentTime}
                        trackId={version.id}
                        s3Key={version.wavURL}
                        peaks={loadedVersions[version].peaks}
                        duration={loadedVersions[version].duration}
                        id={version.id}
                        idKey={"version-" + version.id}
                      />
                    ) : (
                      <VersionWaveformNoInteract
                        currentTime={currentTime}
                        trackId={version.id}
                        s3Key={version.wavURL}
                        peaks={loadedVersions[version].peaks}
                        duration={loadedVersions[version].duration}
                        id={version.id}
                        idKey={"version-" + version.id}
                      />
                    )
                  ) : (
                    <Skeleton
                      component="svg"
                      style={{ backgroundColor: "rgb(210 205 205 / 42%)", lineHeight: 0 }}
                      animation="pulse"
                    >
                      <svg
                        class="w-full"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 146 34"
                        preserveAspectRatio="none"
                      >
                        <g transform="translate(-658 -46)" fill="none" fill-rule="evenodd">
                          <rect rx="1" />
                          <path
                            d="M802.296 60.843v5.161m-1.996-3.6v1.192m-1.995-15.91v29.78m-1.996-20.263v10.323m-1.996-14.24v19.852m-2.794-17.907v14.691m-1.996-13.447v13.897m-1.996-16.73V71.51m-1.995-20.276v21.838m-1.996-19.059v16.28m-1.996-8.272v2.382m-1.996-8.709v15.882m-1.996-19.958v20.647m-2.794-15.01v11.913m-1.996-22.236v32.56m-1.995-20.277v7.147m-1.996-6.724v7.147m-1.996-15.472v24.22m-1.996-13.155v.397m-1.996-9.132V71.51m-2.794-14.254v11.912m-1.996-6.79v.397m-1.995-2.74v6.353m-1.996-17.113v26.603m-1.996-20.263v13.5m-1.996-6.737v.397m-1.995-12.653v26.603m-1.996-22.235v17.867m-2.794-16.372v11.912m-1.996-15.062v19.06m-1.996-11.9v5.162m-1.996-11.144v16.28m-1.996-13.117v9.53m-1.995-5.89v4.368m-1.996-9.953v14.691m-2.794-7.518v1.192m-1.996-16.664v32.56m-1.996-25.068v15.882m-1.996-13.831v13.897m-1.996-7.968v1.192m-1.995-4.355v7.941m-1.996-7.186v5.161m-1.996-10.27V71.96m-2.794-17.073V71.96m-1.996-12.335v6.75m-1.996-3.97v1.19m-1.995-8.364v14.691m-1.996-23.797v33.75m-1.996-20.237v7.147m-1.996-16.742v24.22m-2.794-12.23v2.78m-1.996-5.175v7.147m-1.996-5.572v3.574m-1.995-9.186v13.103m-1.996-21.388v31.368m-1.996-16.28v1.192m-1.996-7.902v15.882m-2.794-13.129v9.53m-1.996-20.264v30.574m-1.995-21.018v12.309m-1.996-19.482v25.808m-1.996-25.782v26.603m-1.996-28.23v28.587m-1.996-23.44V71.51m-1.995-18.304v17.47m-2.795-19.058v20.647m-1.995-12.27v5.162m-1.996-7.888v12.309m-1.996-9.94v7.147"
                            stroke="#767474"
                            stroke-width="1.5"
                          />
                        </g>
                      </svg>
                    </Skeleton>
                  )}
                </div>

                {/* </svg> */}
                {loadedVersions[version].duration ? (
                  <span className="audio-item-duration">
                    {loadedVersions[version].duration}
                  </span>
                ) : (
                  <></>
                )}
              </div>
              <div className="audio-item-controls">
                <DownloadTrack
                  artistId={artistId}
                  artistName={artistName}
                  className="circle-icon circle-icon-sm icon-download"
                  imageURL={imageURL}
                  trackId={trackId}
                  trackTitle={trackTitle}
                  version={version}
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </UserConsumer>
  );
};
export default VersionsTab;

import React, { Component } from "react";

class EnterPWModal extends Component{

    render(){
        return(
            <React.Fragment>
              <header class="modal-header">
                <h2 id="share_project_title" class="modal-title">Password Required'</h2>
                {/* <button onClick={this.props.handleClose} aria-label="Close Modal" data-micromodal-close="share_project_modal" class="modal-close" type="button"></button> */}
            </header>

            {/* {# Modal Content #} */}
            <div id="share_project_content" class="modal-content">

                {/* {# Form #} */}
                <form action="" class="ds-form" onSubmit={(e)=>{e.preventDefault();this.props.checkPW()}}>

                    {/* {# Title #} */}
                    <div class="input-group-sm input-inline mb-0">
                        <input id="project_title" class="form-input" placeholder="Password" type="password" onChange={this.props.onChange} value={this.props.password}/>
                        <label class="input-label" for="project_title">Password</label>
                        <div className="error-message">{this.props.passError}</div>
                    </div>
                    <div class="input-group pt-20">
                        <div class="grid-row-sm">
                            <div class="col w-full">
                                <button type="submit" class="btn btn-primary form-btn w-full">Submit</button>
                            </div>
                        </div>
                    </div>
                </form>
                {/* {# End Form #} */}

            </div>
</React.Fragment>
        )
    }
}
export default EnterPWModal
import e from "cors"
import React, { Component } from "react"
import { Helmet } from "react-helmet"
import MicroModal from "react-micro-modal"
import { ReactSortable } from "react-sortablejs"
import wav1 from '../../assets/placeholders/wav-01.png'
import wav2 from '../../assets/placeholders/wav-02.png'
import wav3 from '../../assets/placeholders/wav-03.png'
import wav4 from '../../assets/placeholders/wav-04.png'
import wav5 from '../../assets/placeholders/wav-05.png'
import wav6 from '../../assets/placeholders/wav-06.png'
import { PlayerConsumer } from "../../PlayerContext"
import { ProjectsContext } from "../../ProjectsContext/index"
import { UserConsumer } from "../../contexts/User"
import { confirm } from "../confirmModal/index"
import TrackListItemLicense from "../CustomerLicenses/trackListItemLicense"
import CircularIndeterminant from "../Loading/circularIndeterminant"
import { Error, Success, SuccessRedirect } from "../Toast/index"
import api from "../utils/api";
import AddSceneModal from "./addSceneModal"
import EditProjectModal from "./editProjectModal"
import ProjectAccordion from "./projectAccordion"
import ProjectEditHeader from "./projectEditHeader"
import LicenseSummary from "../Licenses/licenseSummary";
import { handleAPIError } from "../../utils/handleAPIError"
import './styles.css'

const wavList = [wav1, wav2, wav3, wav4, wav5, wav6]
const addSceneModalPromt =(handleOpen)=> <a onClick={handleOpen} href="#" class="prompt cursor-pointer" data-micromodal-trigger="add_scene_modal">
<div class="prompt-content">
    {/* {# Icon #} */}
    <div class="text-icon-xl mb-15">
        <span class="icon-add-scene-lg"></span>
    </div>
    {/* {# Heading #} */}
    <h3 class="prompt-title">Add some scenes to your project</h3>
    {/* {# Description #} */}
    <p class="max-w-sm">Mauris non tempor quam, et lacinia sapien, Mauris accumsan eros eget.</p>
</div>
</a>
const sortFilters= [{filter: "NameA", displayText: "Name - Asc"}, {filter: "NameD", displayText: "Name - Desc"}, {filter: "VotesA", displayText: "Votes - Asc"}
,{filter: "VotesD", displayText: "Votes - Desc"}]

class ProjectDetail extends Component{
    state={
        newSceneName: "",
        scenes: [],
        isProjectDetailTippyOpen: false,
        sortText: "Votes - Desc",
        isSceneModalOpen: false,
        isProjectEditModalOpen: false,
        isShareModalOpen: false,
        projectTypes: [{label: "Commercial", value: 1}, {label: "Movie", value: 2}],
        // previousCollaborators: this.context.myCollaborators,
        filteredCollaborators: [],
        activeOption: 0,
        randomWavIndex: Math.round(Math.random() *5),
        isCropUtilityOpen: false,
        activeTab: "scenes"
    }
    toggleProjectDetailTippy = ()=>{
        this.setState({isProjectDetailTippyOpen: !this.state.isProjectDetailTippyOpen})
    }
    closeProjectDetailTippy = ()=>{
        this.setState({isProjectDetailTippyOpen: false})
    }
    sortBy = (sortChoice, sortText, overRide, tippyStateCB, currentSortChoice)=>{

        let unsortedSceneTracks = [...this.state.sceneTracks]
        let compare;
        let sorted;
        if(sortChoice !== currentSortChoice || overRide){
            switch(sortChoice){
                case "NameA":
                    compare = (a, b)=> {
                        // Use toUpperCase() to ignore character casing
                        const aName = a.tracks.trackTitle.toUpperCase();
                        const bName = b.tracks.trackTitle.toUpperCase();

                        let comparison = 0;
                        if (aName < bName) {
                          comparison = -1;
                        } else if (aName > bName) {
                          comparison = 1;
                        }
                        return comparison;
                      }
                    sorted = unsortedSceneTracks.sort(compare)
                    break;
                case "NameD":
                    compare = (a, b)=> {
                        // Use toUpperCase() to ignore character casing
                        const aName = a.tracks.trackTitle.toUpperCase();
                        const bName = b.tracks.trackTitle.toUpperCase();

                        let comparison = 0;
                        if (aName < bName) {
                          comparison = -1;
                        } else if (aName > bName) {
                          comparison = 1;
                        }
                        return comparison*-1;
                      }
                    sorted = unsortedSceneTracks.sort(compare)
                    break;
                case "VotesD":
                    sorted = unsortedSceneTracks.sort(function(sceneTrack1, sceneTrack2){
                        Object.filter = (obj, predicate) =>
                        Object.keys(obj)
                          .filter( key => predicate(obj[key]) )
                          .reduce( (res, key) => (res[key] = obj[key], res), {} );
                        let sceneTrack1PosVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===1)
                        let sceneTrack1NegVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===0)
                        let sceneTrack2PosVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===1)
                        let sceneTrack2NegVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===0)
                        sceneTrack1.score = Object.keys(sceneTrack1PosVotes).length - Object.keys(sceneTrack1NegVotes).length
                        sceneTrack2.score = Object.keys(sceneTrack2PosVotes).length - Object.keys(sceneTrack2NegVotes).length

                        if(sceneTrack1.score > sceneTrack2.score) return -1
                        if(sceneTrack1.score < sceneTrack2.score) return 1
                        if(sceneTrack1.sceneTrackAdded > sceneTrack2.sceneTrackAdded) return 1
                        if(sceneTrack1.sceneTrackAdded < sceneTrack2.sceneTrackAdded) return -1
                    })
                    break;
                case "VotesA":
                    sorted = unsortedSceneTracks.sort(function(sceneTrack1, sceneTrack2){
                        Object.filter = (obj, predicate) =>
                        Object.keys(obj)
                          .filter( key => predicate(obj[key]) )
                          .reduce( (res, key) => (res[key] = obj[key], res), {} );
                        let sceneTrack1PosVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===1)
                        let sceneTrack1NegVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===0)
                        let sceneTrack2PosVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===1)
                        let sceneTrack2NegVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===0)
                        sceneTrack1.score = Object.keys(sceneTrack1PosVotes).length - Object.keys(sceneTrack1NegVotes).length
                        sceneTrack2.score = Object.keys(sceneTrack2PosVotes).length - Object.keys(sceneTrack2NegVotes).length

                        if(sceneTrack1.score > sceneTrack2.score) return 1
                        if(sceneTrack1.score < sceneTrack2.score) return -1
                        if(sceneTrack1.sceneTrackAdded > sceneTrack2.sceneTrackAdded) return 1
                        if(sceneTrack1.sceneTrackAdded < sceneTrack2.sceneTrackAdded) return -1
                    })
            }
            this.setState({sceneTracks: sorted, sortText: sortText}, ()=>tippyStateCB(sortChoice))
        }
        else{
            tippyStateCB(sortChoice)
        }
    }
    handleInputChange = (event)=>{

        this.props.handleFormUnsaved(true)
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if(name==="collaboratorEmail"){
            if(value.length > 2){
                let filteredOptions = this.context.myCollaborators.filter(collab=>{
                    if(this.state.project.collaborators.filter(collaborator=>collaborator.email === collab.email || collaborator.em === collab.email).length >0)return false
                    return (collab.name.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1 || collab.email.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1)
                })
                this.setState({filteredCollaborators: filteredOptions})
            }
            else{
                this.setState({filteredCollaborators: []})
            }

        }
        this.setState({
            [name]: value,
        });
    }
    onKeyDown = (e, setValueCB)=>{
        const { activeOption, filteredCollaborators } = this.state;
        if(filteredCollaborators.length > 0){
            if (e.keyCode === 13) {
                e.preventDefault()
                setValueCB("collaboratorEmail", filteredCollaborators[activeOption].email)
                this.setState({
                    activeOption: 0,
                    showOptions: false,
                    collaboratorEmail: filteredCollaborators[activeOption].email,
                    filteredCollaborators: [],
                    lastProfilePhoto: filteredCollaborators[activeOption].profilePhoto
                });
            } else if (e.keyCode === 38) {
                if (activeOption === 0) {
                return;
                }
                this.setState({ activeOption: activeOption - 1 }, ()=>{
                    let activeElement = document.getElementsByClassName('option-active')[0]


                    activeElement.scrollIntoView()

                });
            } else if (e.keyCode === 40) {
                if (activeOption === filteredCollaborators.length - 1) {

                return;
                }
                this.setState({ activeOption: activeOption + 1 },()=>{
                    let activeElement = document.getElementsByClassName('option-active')[0]


                    activeElement.scrollIntoView()

                });
            }
        }
    }
    onClick= (email, setValueCB)=>{


        setValueCB("collaboratorEmail", email)
        this.setState({
            activeOption: 0,
            showOptions: false,
            collaboratorEmail: email,
            lastProfilePhoto: this.state.filteredCollaborators.filter(collab=>collab.email===email)[0].profilePhoto,
            filteredCollaborators: [],
        })
    }
    checkIfUsed = (value) => {
        // console.log('checking if scene name used');
        // console.log('this scene name', value);
        const sceneNames = this.state.scenes.map(scene => scene.name);
        // console.log('all sceneNames', sceneNames);

        const isInSceneNames = sceneNames.filter(name => {
            return name.toUpperCase() === value.toUpperCase();
        });

        if (isInSceneNames?.length > 0){
            return true;
        }
        else {
            return false;
        }
    }
    checkIfProjectUsed = (value)=>{
        let existingProjectNames = this.context.myProjects.filter(project=>project.id !== this.state.project.id).map(project=>project.name)

        let isNameTaken = existingProjectNames.filter(name=>name?.toUpperCase()===value?.toUpperCase()).length > 0
        if(isNameTaken){
            return "Project name already used"
        }
        else{
            return true
        }
    }
    editScene = async (trigger, errors, cb, sceneId, editedSceneName, sceneIndex)=>{
        let triggerRes = await trigger()
            .catch(error => {
                console.log(error);
            });

        let sceneBody = {
            name: editedSceneName,
        }

        // console.log('sceneBody', sceneBody);

        if (triggerRes) {
            // console.log('calling editScene api');

            api.editScene(sceneId, sceneBody)
                .then(res=>{
                    if(res.data.success){
                        this.context.editSceneName(parseInt(this.props.match.params.id), sceneId, editedSceneName)
                        this.props.handleFormUnsaved(false)
                        Success({message:"Scene Updated!", onClose: this.successToastSave})
                        let scenes = this.state.scenes
                        scenes[sceneIndex].name = editedSceneName
                        this.setState({scenes: scenes})
                        cb()
                    }
                    else{
                        Error({message:"An error occurred!", onClose: this.errorToast})
                    }
                })
                .catch(error => {
                    console.log('error.response', error.response);
                    const errorMessage = error.response?.data?.message || "An error occurred";
                    Error({
                        message: errorMessage,
                        onClose: this.errorToast
                    })
                })
        }
    }
    updateVote = (voteTrackId, voteValue, sceneTrackIndex, cb)=>{
        api.updateSceneTrackVote(voteTrackId, {voteValue: voteValue})
            .then(res=> {
                if(res.data.updatedVote){
                    this.context.updateLatest(this.state.project.id)
                    let sceneTracks = [...this.state.sceneTracks]
                    sceneTracks[sceneTrackIndex] = {...this.state.sceneTracks[sceneTrackIndex],
                        myVote: [{...this.state.sceneTracks[sceneTrackIndex].myVote[0],
                                voteValue: res.data.updatedVote.voteValue
                            }
                        ],
                        sceneTrackVotes: {...this.state.sceneTracks[sceneTrackIndex].sceneTrackVotes,
                            [voteTrackId]: {...this.state.sceneTracks[sceneTrackIndex].sceneTrackVotes[voteTrackId],
                                voteValue: res.data.updatedVote.voteValue
                            }
                        }
                    }
                    let message;
                    if(voteValue){
                        message = "Upvoted " + sceneTracks[sceneTrackIndex].tracks.trackTitle
                    }
                    else{
                        message = "Downvoted " + sceneTracks[sceneTrackIndex].tracks.trackTitle
                    }
                    if(this.state.sortText === "Votes - Desc"){
                        sceneTracks =sceneTracks.sort(function(sceneTrack1, sceneTrack2){
                            Object.filter = (obj, predicate) =>
                            Object.keys(obj)
                            .filter( key => predicate(obj[key]) )
                            .reduce( (res, key) => (res[key] = obj[key], res), {} );
                            let sceneTrack1PosVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===1)
                            let sceneTrack1NegVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===0)
                            let sceneTrack2PosVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===1)
                            let sceneTrack2NegVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===0)
                            sceneTrack1.score = Object.keys(sceneTrack1PosVotes).length - Object.keys(sceneTrack1NegVotes).length
                            sceneTrack2.score = Object.keys(sceneTrack2PosVotes).length - Object.keys(sceneTrack2NegVotes).length

                            if(sceneTrack1.score > sceneTrack2.score) return -1
                            if(sceneTrack1.score < sceneTrack2.score) return 1
                            if(sceneTrack1.sceneTrackAdded > sceneTrack2.sceneTrackAdded) return 1
                            if(sceneTrack1.sceneTrackAdded < sceneTrack2.sceneTrackAdded) return -1
                        })
                    }
                    else if(this.state.sortText === "Votes - Asc"){
                        sceneTracks =sceneTracks.sort(function(sceneTrack1, sceneTrack2){
                            Object.filter = (obj, predicate) =>
                            Object.keys(obj)
                            .filter( key => predicate(obj[key]) )
                            .reduce( (res, key) => (res[key] = obj[key], res), {} );
                            let sceneTrack1PosVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===1)
                            let sceneTrack1NegVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===0)
                            let sceneTrack2PosVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===1)
                            let sceneTrack2NegVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===0)
                            sceneTrack1.score = Object.keys(sceneTrack1PosVotes).length - Object.keys(sceneTrack1NegVotes).length
                            sceneTrack2.score = Object.keys(sceneTrack2PosVotes).length - Object.keys(sceneTrack2NegVotes).length

                            if(sceneTrack1.score > sceneTrack2.score) return 1
                            if(sceneTrack1.score < sceneTrack2.score) return -1
                            if(sceneTrack1.sceneTrackAdded > sceneTrack2.sceneTrackAdded) return 1
                            if(sceneTrack1.sceneTrackAdded < sceneTrack2.sceneTrackAdded) return -1
                        })
                    }

                    Success({message: message, onClose: this.successToastSave})
                    this.setState({ sceneTracks},()=>cb())

                }
            })
    }
    submitVote = (sceneTrackId, voteValue, sceneTrackIndex, cb)=>{
        api.submitSceneTrackVote(sceneTrackId, this.props.match.params.id, {voteValue: voteValue})
            .then(res=> {
                if(res.data.success){
                    this.context.updateLatest(this.state.project.id)
                    let sceneTracks = [...this.state.sceneTracks]
                    sceneTracks[sceneTrackIndex].myVote = []
                    sceneTracks[sceneTrackIndex].myVote.push(res.data.newVote)
                    sceneTracks[sceneTrackIndex].sceneTrackVotes[res.data.newVote.id] = res.data.newVote
                    let message;

                    if(voteValue){
                        message = "Upvoted " + sceneTracks[sceneTrackIndex].tracks.trackTitle
                    }
                    else{
                        message = "Downvoted " + sceneTracks[sceneTrackIndex].tracks.trackTitle
                    }
                    if(this.state.sortText === "Votes - Desc"){
                        sceneTracks =sceneTracks.sort(function(sceneTrack1, sceneTrack2){
                            Object.filter = (obj, predicate) =>
                            Object.keys(obj)
                            .filter( key => predicate(obj[key]) )
                            .reduce( (res, key) => (res[key] = obj[key], res), {} );
                            let sceneTrack1PosVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===1)
                            let sceneTrack1NegVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===0)
                            let sceneTrack2PosVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===1)
                            let sceneTrack2NegVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===0)
                            sceneTrack1.score = Object.keys(sceneTrack1PosVotes).length - Object.keys(sceneTrack1NegVotes).length
                            sceneTrack2.score = Object.keys(sceneTrack2PosVotes).length - Object.keys(sceneTrack2NegVotes).length

                            if(sceneTrack1.score > sceneTrack2.score) return -1
                            if(sceneTrack1.score < sceneTrack2.score) return 1
                            if(sceneTrack1.sceneTrackAdded > sceneTrack2.sceneTrackAdded) return 1
                            if(sceneTrack1.sceneTrackAdded < sceneTrack2.sceneTrackAdded) return -1
                        })
                    }
                    else if(this.state.sortText === "Votes - Asc"){
                        sceneTracks =sceneTracks.sort(function(sceneTrack1, sceneTrack2){
                            Object.filter = (obj, predicate) =>
                            Object.keys(obj)
                            .filter( key => predicate(obj[key]) )
                            .reduce( (res, key) => (res[key] = obj[key], res), {} );
                            let sceneTrack1PosVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===1)
                            let sceneTrack1NegVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===0)
                            let sceneTrack2PosVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===1)
                            let sceneTrack2NegVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===0)
                            sceneTrack1.score = Object.keys(sceneTrack1PosVotes).length - Object.keys(sceneTrack1NegVotes).length
                            sceneTrack2.score = Object.keys(sceneTrack2PosVotes).length - Object.keys(sceneTrack2NegVotes).length

                            if(sceneTrack1.score > sceneTrack2.score) return 1
                            if(sceneTrack1.score < sceneTrack2.score) return -1
                            if(sceneTrack1.sceneTrackAdded > sceneTrack2.sceneTrackAdded) return 1
                            if(sceneTrack1.sceneTrackAdded < sceneTrack2.sceneTrackAdded) return -1
                        })
                    }


                    Success({message: message, onClose: this.successToastSave})
                    this.setState({sceneTracks},()=>cb())
                }

            })
    }
    handleSceneModalOpen = ()=>{
        this.setState({isSceneModalOpen: true, isProjectDetailTippyOpen: false})
    }

    handleSceneModalClose=()=>{

        this.props.handleFormUnsaved(false)
        this.setState({isSceneModalOpen: false})
    }
    handleProjectEditModalOpen = ()=>{
        this.setState({isProjectEditModalOpen: true, isProjectDetailTippyOpen: false})
    }
    handleProjectEditModalClose=()=>{

        this.props.handleFormUnsaved(false)
        this.setState({isProjectEditModalOpen: false, editedProjectName: this.state.project.name})
    }
    componentDidUpdate = (prevProps) => {
        console.log('projectDetail prevProps', prevProps);
        console.log('projectDetail this.props', this.props);

        if (prevProps.match.params.id !== this.props.match.params.id) {
            api.getProjectById(this.props.match.params.id)
            .then(res=>{
                let projectTypes = []
                api.getAllProjectTypes()
                .then(res=>{
                    res.data.forEach(projType=>{
                        projectTypes.push({label: projType.name, value: projType.id})
                    })
                    this.setState({projectTypes: projectTypes})
                })
                .catch(error => {
                    console.log('error in getAllProjectTypes', error);
                })

                let {scenes, ...project} = res.data
                let sceneTracks = []
                scenes.forEach(scene=>{
                    sceneTracks = sceneTracks.concat(scene.sceneTracks)
                })
                let sceneTrackVotes = []
                let allTracks = []

                sceneTracks = sceneTracks.map(sceneTrack=>{
                    const keyedVotes = {}

                    sceneTrack.sceneTrackVotes =sceneTrack.sceneTrackVotes.reduce((obj, item)=>{
                        return{
                            ...obj,
                            [item.sTVID]: item,
                        };
                    }, keyedVotes)

                    if(allTracks.findIndex(track=>track.trackId === sceneTrack.tracks.trackId) ===-1){
                        allTracks.push({...sceneTrack.tracks})
                    }
                    return sceneTrack

                })
                this.props.updateLoadedTracks(allTracks)

                sceneTracks = sceneTracks.sort(function(sceneTrack1, sceneTrack2){
                    Object.filter = (obj, predicate) =>
                    Object.keys(obj)
                      .filter( key => predicate(obj[key]) )
                      .reduce( (res, key) => (res[key] = obj[key], res), {} );
                    let sceneTrack1PosVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===1)
                    let sceneTrack1NegVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===0)
                    let sceneTrack2PosVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===1)
                    let sceneTrack2NegVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===0)
                    sceneTrack1.score = Object.keys(sceneTrack1PosVotes).length - Object.keys(sceneTrack1NegVotes).length
                    sceneTrack2.score = Object.keys(sceneTrack2PosVotes).length - Object.keys(sceneTrack2NegVotes).length

                    if(sceneTrack1.score > sceneTrack2.score) return -1
                    if(sceneTrack1.score < sceneTrack2.score) return 1
                    if(sceneTrack1.sceneTrackAdded > sceneTrack2.sceneTrackAdded) return 1
                    if(sceneTrack1.sceneTrackAdded < sceneTrack2.sceneTrackAdded) return -1
                })
                let defaultArt = sceneTracks[0]?.tracks.imageURL
                let activeCollabs = project.collaborators.filter(collaborator=> collaborator.status==="accepted")
                if(project.isOwner){
                  const {
                    firstName,
                    lastName,
                    email,
                    profilePhoto
                  } = this.props.user;

                  let myCollab = {
                    firstName,
                    lastName,
                    email,
                    profilePhoto
                  }
                    activeCollabs.push(myCollab)
                    let tempCollab

                    let collaboratorNum = activeCollabs.length

                    switch(collaboratorNum){
                        case 0:
                        case 1:
                            break;
                        case 2:
                            tempCollab = activeCollabs[1]
                            activeCollabs[1] = activeCollabs[collaboratorNum-1]
                            activeCollabs[collaboratorNum-1] = tempCollab
                            break;
                        case 3:
                            tempCollab = activeCollabs[2]
                            activeCollabs[2] = activeCollabs[collaboratorNum-1]
                            activeCollabs[collaboratorNum-1] = tempCollab
                            break;
                        case 4:
                            tempCollab = activeCollabs[3]
                            activeCollabs[3] = activeCollabs[collaboratorNum-1]
                            activeCollabs[collaboratorNum-1] = tempCollab
                            break;
                        default:
                            tempCollab = activeCollabs[2]
                            activeCollabs[2] = activeCollabs[collaboratorNum-1]
                            activeCollabs[collaboratorNum-1] = tempCollab
                            break;
                    }
                    project.activeCollabs = activeCollabs
                }
                else{
                    activeCollabs.splice(0, 0, {firstName: project.projects.ownerFN, lastName: project.projects.ownerLN, email: project.projects.ownerEmail, profilePhoto: project.projects.ownerPhoto})
                    let collaboratorNum = activeCollabs.length
                    let myCollabPosition = activeCollabs.findIndex(collab=>collab.email.toLowerCase() === this.props.user.email.toLowerCase())
                    let tempCollab

                    switch(collaboratorNum){
                        case 0:
                        case 1:
                            break;
                        case 2:
                            tempCollab = activeCollabs[1]
                            activeCollabs[1] = activeCollabs[myCollabPosition]
                            activeCollabs[myCollabPosition] = tempCollab
                            break;
                        case 3:
                            tempCollab = activeCollabs[2]
                            activeCollabs[2] = activeCollabs[myCollabPosition]
                            activeCollabs[myCollabPosition] = tempCollab
                            break;
                        case 4:
                            tempCollab = activeCollabs[3]
                            activeCollabs[3] = activeCollabs[myCollabPosition]
                            activeCollabs[myCollabPosition] = tempCollab
                            break;
                        default:
                            tempCollab = activeCollabs[2]
                            activeCollabs[2] = activeCollabs[myCollabPosition]
                            activeCollabs[myCollabPosition] = tempCollab
                            break;
                    }
                    project.activeCollabs = activeCollabs
                }

                this.setState({
                    project: project,
                    scenes: scenes,
                    sceneTracks,
                    sceneTrackVotes,
                    editedProjectName: project.name,
                    coverArt: project.coverArt,
                    defaultArt: defaultArt,
                    initialCoverArt: project.coverArt,
                    initialRealCoverArt: project.realCoverArt,
                    customerLicenses: project.customerLicenses,
                    isLoaded: true
                })
            })
            .catch(error=>{
                if(error.response.status === 401){
                    this.props.history.push("/dashboard/projects/all")
                }
            })
        }
    }
    componentDidMount = () => {
        api.getProjectById(this.props.match.params.id)
            .then(res => {
                console.log('projectDetail getProjectById res', res);
                let projectTypes = [];
                api.getAllProjectTypes()
                .then(res=>{
                    res.data.forEach(projType=>{
                        projectTypes.push({label: projType.name, value: projType.id})
                    })
                    this.setState({projectTypes: projectTypes})
                })
                .catch(error => {
                    console.log('error in getAllProjectTypes', error);
                })

                let {scenes, ...project} = res.data
                let sceneTracks = []
                let refs = []
                let allTracks = []
                scenes.forEach(scene=>{
                    scene.sceneTracks.forEach((sceneTrack,index)=>{
                        scene.sceneTracks[index].weight = scene.weight
                    })
                    sceneTracks = sceneTracks.concat(scene.sceneTracks)

                    refs.push(React.createRef());
                    this.sceneElements = refs
                })
                let sceneTrackVotes = []
                sceneTracks = sceneTracks.map(sceneTrack=>{
                    const keyedVotes = {}

                    sceneTrack.sceneTrackVotes =sceneTrack.sceneTrackVotes.reduce((obj, item)=>{
                        return{
                            ...obj,
                            [item.sTVID]: item,
                        };
                    }, keyedVotes)

                    if(allTracks.findIndex(track=>track.trackId === sceneTrack.tracks.trackId) ===-1){
                        allTracks.push({...sceneTrack.tracks})
                    }
                    return sceneTrack

                })
                this.props.updateLoadedTracks(allTracks)

                sceneTracks = sceneTracks.sort(function(sceneTrack1, sceneTrack2){
                    Object.filter = (obj, predicate) =>
                    Object.keys(obj)
                      .filter( key => predicate(obj[key]) )
                      .reduce( (res, key) => (res[key] = obj[key], res), {} );
                    let sceneTrack1PosVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===1)
                    let sceneTrack1NegVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===0)
                    let sceneTrack2PosVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===1)
                    let sceneTrack2NegVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===0)
                    sceneTrack1.score = Object.keys(sceneTrack1PosVotes).length - Object.keys(sceneTrack1NegVotes).length
                    sceneTrack2.score = Object.keys(sceneTrack2PosVotes).length - Object.keys(sceneTrack2NegVotes).length

                    if(sceneTrack1.score > sceneTrack2.score) return -1
                    if(sceneTrack1.score < sceneTrack2.score) return 1
                    if(sceneTrack1.sceneTrackAdded > sceneTrack2.sceneTrackAdded) return 1
                    if(sceneTrack1.sceneTrackAdded < sceneTrack2.sceneTrackAdded) return -1
                })
                let defaultArt = sceneTracks[0]?.tracks.imageURL
                let activeCollabs = project.collaborators.filter(collaborator=> collaborator.status==="accepted" || collaborator.status === "accepted-complete")
                if (project.isOwner) {
                  const {
                    firstName,
                    lastName,
                    email,
                    profilePhoto
                  } = this.props.user;

                  let myCollab = {
                    firstName,
                    lastName,
                    email,
                    profilePhoto
                  }

                    activeCollabs.push(myCollab)
                    let tempCollab

                    let collaboratorNum = activeCollabs.length

                    switch(collaboratorNum){
                        case 0:
                        case 1:
                            break;
                        case 2:
                            tempCollab = activeCollabs[1]
                            activeCollabs[1] = activeCollabs[collaboratorNum-1]
                            activeCollabs[collaboratorNum-1] = tempCollab
                            break;
                        case 3:
                            tempCollab = activeCollabs[2]
                            activeCollabs[2] = activeCollabs[collaboratorNum-1]
                            activeCollabs[collaboratorNum-1] = tempCollab
                            break;
                        case 4:
                            tempCollab = activeCollabs[3]
                            activeCollabs[3] = activeCollabs[collaboratorNum-1]
                            activeCollabs[collaboratorNum-1] = tempCollab
                            break;
                        default:
                            tempCollab = activeCollabs[2]
                            activeCollabs[2] = activeCollabs[collaboratorNum-1]
                            activeCollabs[collaboratorNum-1] = tempCollab
                            break;
                    }
                    project.activeCollabs = activeCollabs
                }
                else {
                    activeCollabs.splice(0, 0, {firstName: project.ownerFN, lastName: project.ownerLN, email: project.ownerEmail, profilePhoto: project.ownerPhoto})
                    let collaboratorNum = activeCollabs.length
                    let myCollabPosition = activeCollabs.findIndex(collab=>collab.email.toLowerCase() === this.props.user.email.toLowerCase())
                    let tempCollab

                    switch(collaboratorNum){
                        case 0:
                        case 1:
                            break;
                        case 2:
                            tempCollab = activeCollabs[1]
                            activeCollabs[1] = activeCollabs[myCollabPosition]
                            activeCollabs[myCollabPosition] = tempCollab
                            break;
                        case 3:
                            tempCollab = activeCollabs[2]
                            activeCollabs[2] = activeCollabs[myCollabPosition]
                            activeCollabs[myCollabPosition] = tempCollab
                            break;
                        case 4:
                            tempCollab = activeCollabs[3]
                            activeCollabs[3] = activeCollabs[myCollabPosition]
                            activeCollabs[myCollabPosition] = tempCollab
                            break;
                        default:
                            tempCollab = activeCollabs[2]
                            activeCollabs[2] = activeCollabs[myCollabPosition]
                            activeCollabs[myCollabPosition] = tempCollab
                            break;
                    }
                    project.activeCollabs = activeCollabs
                }

                project.passwordEnabled = project.password === null ? "disabled" : "enabled"

                this.setState({
                    project: project,
                    scenes: scenes,
                    sceneTracks,
                    sceneTrackVotes,
                    editedProjectName: project.name,
                    coverArt: project.coverArt,
                    defaultArt: defaultArt,
                    initialCoverArt: project.coverArt,
                    initialRealCoverArt: project.realCoverArt,
                    customerLicenses: project.customerLicenses,
                    isLoaded: true
                });
            })
            .catch(error => {
                handleAPIError(error, false);
                if(error.response?.status === 401){
                    this.props.history.push("/dashboard/projects/all")
                }
                else{

                }
            })
    }
    handleProjectTypeChange= (selectedType)=>{
        this.setState({editedProjectType: selectedType})
    }
    updateProject = async (trigger, errors, cb)=>{

        let triggerRes = await trigger()


        let projectBody ={
            name: this.state.editedProjectName
        }
        if(this.state.editedProjectName.toUpperCase() === this.state.project.name.toUpperCase()){
            delete projectBody.name
        }

        if(triggerRes){
            this.setState({isLoading: true}, ()=>{
                if(this.state.coverArtUpdated && this.state.realCoverArtUpdated){
                    const data = new FormData()
                    data.append('file', this.state.coverArt[0])
                    api.updateProjectArt(this.props.match.params.id, data)
                        .then(res=>{
                            const coverData = new FormData()
                            coverData.append('file', this.state.realCoverArt[0])
                            api.updateProjectCoverArt(this.props.match.params.id, coverData)
                                .then(res=>{
                                    if(res.data.success === true){
                                        api.updateProjectById(this.props.match.params.id, projectBody)
                                        .then(respo=>{
                                            this.setState({isLoading: false})
                                            if(respo.data.success){
                                                cb()
                                                window.location.reload()
                                                // SuccessRedirect({message: "Project Updated!", onClose: ()=> window.location.reload()})
                                            }
                                            else{
                                                Error({message:"An error occurred!", onClose: this.errorToast})
                                            }
                                        })
                                        .catch(error=>{
                                            this.setState({isLoading: false})
                                            if(error.response.status === 406){
                                                Error({message:"Project name already used", onClose: this.errorToast})
                                            }
                                            else{
                                                Error({message:"An error occurred!", onClose: this.errorToast})
                                            }

                                        })
                                    }
                                    else{
                                        this.setState({isLoading: false})
                                        Error({message:"An error occurred!", onClose: this.errorToast})

                                    }
                                })
                        })
                        .catch(error=>{
                            this.setState({isLoading: false})

                            Error({message:"An error occurred!", onClose: this.errorToast})
                        })
                }
                else if(this.state.coverArtUpdated){
                    const data = new FormData()
                    data.append('file', this.state.coverArt[0])
                    api.updateProjectArt(this.props.match.params.id, data)
                        .then(res=>{
                            if(res.data.success === true){
                                this.context.updateProjectArt(parseInt(this.props.match.params.id), res.data.project?.coverArt, true)

                                api.updateProjectById(this.props.match.params.id, projectBody)
                                .then(respo=>{
                                    let project = this.state.project
                                    project.coverArt = this.state.coverArt[0]
                                    project.name = this.state.editedProjectName || project.name
                                    project.userSet = true
                                    project.wavPlaceholder = wavList[this.state.randomWavIndex]
                                    this.context.updateProjectInfo(this.props.match.params.id, project.name)
                                    this.setState({isLoading: false, project: project, coverArt: res.data.project?.coverArt, initialCoverArt: res.data.project?.coverArt}, ()=>{
                                        if(respo.data.success){
                                            cb()
                                            // window.location.reload()
                                            // SuccessRedirect({message: "Project Updated!", onClose: ()=> window.location.reload()})
                                        }
                                        else{
                                            Error({message:"An error occurred!", onClose: this.errorToast})
                                        }
                                    })
                                })
                                .catch(error=>{
                                    this.setState({isLoading: false})
                                    if(error.response.status === 406){
                                        Error({message:"Project name already used", onClose: this.errorToast})
                                    }
                                    else{
                                        Error({message:"An error occurred!", onClose: this.errorToast})
                                    }

                                })
                            }
                            else{
                                this.setState({isLoading: false})
                                Error({message:"An error occurred!", onClose: this.errorToast})

                            }
                        })
                        .catch(error=>{
                            this.setState({isLoading: false})

                            Error({message:"An error occurred!", onClose: this.errorToast})
                        })
                }
                else if(this.state.realCoverArtUpdated){
                    const data = new FormData()
                    data.append('file', this.state.realCoverArt[0])
                    api.updateProjectCoverArt(this.props.match.params.id, data)
                        .then(res=>{

                            if(res.data.success === true){
                                api.updateProjectById(this.props.match.params.id, projectBody)
                                .then(respo=>{
                                    this.setState({isLoading: false})
                                    if(respo.data.success){
                                        cb()
                                        window.location.reload()
                                        // SuccessRedirect({message: "Project Updated!", onClose: ()=> window.location.reload()})
                                    }
                                    else{
                                        Error({message:"An error occurred!", onClose: this.errorToast})
                                    }
                                })
                                .catch(error=>{
                                    this.setState({isLoading: false})
                                    if(error.response.status === 406){
                                        Error({message:"Project name already used", onClose: this.errorToast})
                                    }
                                    else{
                                        Error({message:"An error occurred!", onClose: this.errorToast})
                                    }

                                })
                            }
                            else{
                                this.setState({isLoading: false})
                                Error({message:"An error occurred!", onClose: this.errorToast})

                            }
                        })
                        .catch(error=>{
                            this.setState({isLoading: false})

                            Error({message:"An error occurred!", onClose: this.errorToast})
                        })
                }
                else{
                    api.updateProjectById(this.props.match.params.id, projectBody)
                    .then(res=>{
                        this.setState({isLoading: false})
                        if(res.data.success){
                            Success({message: "Project Updated!"})
                            this.context.updateLatest(this.state.project.id)

                            let project = {...this.state.project}
                            project.name = projectBody.name || project.name
                            this.context.updateProjectInfo(this.props.match.params.id, project.name)
                            this.setState({project: project}, ()=>cb())
                        }
                        else{
                            Error({message:"An error occurred!", onClose: this.errorToast})
                        }
                    })
                    .catch(error=>{
                        this.setState({isLoading: false})

                        if(error.response?.status === 406){
                            Error({message:"Project name already used", onClose: this.errorToast})
                        }
                        else{
                            Error({message:"An error occurred!", onClose: this.errorToast})
                        }

                    })
                }
            })
        }

    }
    markComplete =()=>{
        this.setState({isProjectDetailTippyOpen: false}, ()=>
            api.markProjectComplete(this.props.match.params.id)
                .then(res=>{

                    if(res.data.success){
                        this.context.removeFromList(this.props.match.params.id)
                        SuccessRedirect({message: "Project Marked as Complete", onClose:()=>{this.props.history.push("/dashboard/projects/completed")}})
                    }
                })
        )
    }
    markIncomplete =()=>{
        this.setState({isProjectDetailTippyOpen: false}, ()=>
            api.markProjectIncomplete(this.props.match.params.id)
                .then(res=>{

                    if(res.data.success){
                        SuccessRedirect({message: "Project Marked as Active", onClose:()=>{this.props.history.push("/dashboard/projects/all")}})
                    }
                })
        )
    }
    setCoverArtState = (file)=>{

        if(file.error){
            this.setState({coverArtError: "File must be at least 250x250"})
        }
        else{
            this.setState({
                coverArt: file.length > 0 ? file: null,
                coverArtPreview: file.length > 0 ? URL.createObjectURL(file[0]): null,
                coverArtError: null,
                coverArtUpdated: file.length > 0 ? true : false
            })
        }

    }
    updateFollowing = (artistId)=>{
        let sceneTracks = [...this.state.sceneTracks]
        sceneTracks.forEach((sceneTrack, index)=>{

            if(sceneTrack.tracks.artistId === artistId){
                sceneTracks[index].tracks.following = sceneTracks[index].tracks.following === 1 ? 0: 1
            }
        })
        this.setState({sceneTracks: sceneTracks})
    }
    removeScene = async(sceneId, sceneIndex)=>{

        if(await confirm("Are you sure you want to remove this scene?", "Remove Scene", true)){
            api.removeScene(sceneId)
                .then(res=>{
                    if(res.data.success){
                        this.context.removeSceneFromContext(parseInt(this.props.match.params.id), sceneId )
                        let scenes = [...this.state.scenes]
                        scenes.splice(sceneIndex, 1)
                        Success({message: "Scene Removed!"})
                        this.context.updateLatest(this.state.project.id)
                        this.setState({scenes: scenes})
                    }
                })
        }
    }
    removeCoverArt =(event)=>{
        // event.preventDefault();
        api.removeProjectArt(this.props.match.params.id)
            .then(res=>{

                if(res.data.success){
                    Success({message: "Cover Art Removed!"})
                    this.context.updateLatest(this.state.project.id)
                    this.setState({
                        coverArt: res.data.project?.coverArt,
                        coverArtPreview: null,
                        coverArtError: null,
                        coverArtUpdated: false,
                        initialCoverArt: res.data.project?.coverArt || null,
                        userSet: false,
                        project: {
                            ...this.state.project,
                            coverArt: res.data.project?.coverArt
                        }
                    },()=>{
                        this.context.updateProjectArt(parseInt(this.props.match.params.id), res.data.project?.coverArt, false)
                    })
                }
            })
    }
    duplicateProject = ()=>{
        this.setState({isProjectDetailTippyOpen: false});
        api.duplicateProject(this.props.match.params.id)
            .then(res=>{

                if(res.data.success){
                    setTimeout(this.props.history.push("/dashboard/projects/edit/"+res.data.duplicatedProject.id), 1250)
                    // SuccessRedirect({message: `${this.state.project.name} Duplicated!`, onClose: ()=>{this.props.history.push("/dashboard/projects/edit/"+res.data.duplicatedProject.id)}})
                }
                else{
                    Error({message: "An Error Occured!"})
                }
            })
            .catch(err=>{
                Error({message: "An Error Occured!"})
            })
    }
    leaveProject = async ()=>{
        if(await confirm("Are you sure you want to leave this project? You will not have access to it unless you are invited back", "Leave Project", true)){
            api.leaveProject(this.props.match.params.id)
                .then(res=>{

                    if(res.data.success){
                        this.context.removeFromList(this.props.match.params.id)
                        SuccessRedirect({message: "Left Project!", onClose: ()=>{this.props.history.push("/dashboard/projects/all")}})
                    }
                })
        }
    }
    deleteProject = async ()=>{
        if(await confirm("Are you sure you want to delete this project? You will no longer have access to it after this is done", "Delete Project", true)){
            api.deleteProject(this.props.match.params.id)
                .then(res=>{

                    if(res.data.success){
                        this.context.removeFromList(this.props.match.params.id)
                        SuccessRedirect({message: "Deleted Project!", onClose: ()=>{this.props.history.push("/dashboard/projects/all")}})
                    }
                    else{
                        Error({message: "There was an error"})
                    }
                })
                .catch(error=>{

                    if(error?.reponse?.status === 401){
                        Error({message: "You cannot delete a project with an associated purchase"})
                    }
                    else if (error?.response?.status === 403){
                        Error({message: error?.response.data})
                    }
                    else{
                        Error({message: "There was an error"})
                    }
                })
        }
    }
    removeCollaborator = async (collaboratorId, projectId)=>{
        if(await confirm("Are you sure you want to remove this collaborator?", "Remove Collaborator", true)){
            api.removeCollaborator(collaboratorId, this.props.match.params.id)
                .then(res=>{
                    if(res.data.success){
                        let project = {...this.state.project}
                        let collaborators = project.collaborators
                        collaborators = collaborators.filter(collaborator=>collaborator.pjCId !== collaboratorId)
                        project.collaborators = collaborators
                        Success({message: "Collaborator Removed"})
                        this.context.updateLatest(this.state.project.id)
                        this.setState({project: project})
                    }
                })
        }
    }
    addCollaboratorToState = (collaboratorEmail, collaboratorId, projectId, setValueCB)=>{
        let project = {...this.state.project}
        let collaborators = project.collaborators
        collaborators.push({email: collaboratorEmail, pjCId: collaboratorId, status: "invited", profilePhoto: this.state.lastProfilePhoto || null})
        // setValueCB("collaboratorEmail", "")
        this.setState({project: project, collaboratorEmail: ""})
    }
    forceClose = (event)=>{


        let originalHeight = event.item.getBoundingClientRect()

        this.sceneElements.forEach((sceneRef, index)=>{

            sceneRef.current.close()

            //

            if(event.oldIndex === index){
                //
            }
        })
        let rect = event.item.getBoundingClientRect()

        if(rect.top !== originalHeight.top ){

            let accordionEl = document.querySelector('.app-main')
            // let scrollDistance = Math.abs(rect.top) + Math.abs(originalHeight)
            accordionEl.scrollTo(0, -(originalHeight.top))
            let rect2 = event.item.getBoundingClientRect()

            if(rect2.top !== originalHeight.top){

                let appMain= document.getElementsByClassName('app-main')[0]
                appMain.setAttribute("style",`padding: ${Math.abs(rect2.top - rect.top)}px 0 0 0`)

                // accordionEl.scrollBy(0, Math.abs(originalHeight.top- rect2.top))
                let rect3 = event.item.getBoundingClientRect()

                accordionEl.scrollBy(0, Math.abs(originalHeight.top- rect3.top))

            }
        }

        // let accordionEl = document.querySelector('.js-accordion')
        // if(accordionEl){
        //     accordionEl.scrollIntoView()
        // }
        // let scenes = [...this.state.scenes]
        // scenes = scenes.map(scene=>{
        //     scene.forceClose = true
        //     return scene
        // })
    }
    removePadding = ()=>{

        let appMain= document.getElementsByClassName('app-main')[0]
        let height = appMain.scrollTop

        if(appMain.style.paddingTop.split("px")[0] > 0){
            appMain.removeAttribute("style" )
            appMain.scrollBy(0, -height)
        }

    }
    removeTrack = (sceneTrackId, trackTitle)=>{
        api.removeTrackFromProject(this.state.project?.id, sceneTrackId)
            .then(res=>{
                if(res.data.success){
                    Success({message: `${trackTitle} removed!`})
                    let sceneTracks = [...this.state.sceneTracks]
                    sceneTracks = sceneTracks.filter(sceneTrack=>sceneTrack.sceneTrackId !== sceneTrackId)
                    this.context.updateLatest(this.state.project.id)
                    this.setState({sceneTracks: sceneTracks})
                }
                else{
                    Error({message: "Error removing track"})
                }
            })
            .catch(error=>{
                Error({message: "Error removing track"})
            })
    }
    generateProjectLink = ()=>{
        api.generateProjectLink(this.state.project?.id)
            .then(res=>{
                if(res.data.success){
                    let projectLinkData = res.data.projectLink
                    let project = {...this.state.project}
                    project.projectLink = projectLinkData.url
                    project.projectLinkActive = projectLinkData.status
                    project.projectLinkId = projectLinkData.id
                    this.setState({project: project})
                }

            })
    }
    enableLink=(isEnabled)=>{
        // e.preventDefault()

        let project = {...this.state.project}
        project.projectLinkActive = isEnabled
        this.setState({project: project})
    }
    enablePassword=(isEnabled)=>{
        let project = {...this.state.project}
        project.passwordEnabled = isEnabled
        this.setState({project: project})
    }
    updateProjectLink= async (projectLinkId)=>{
        let projectBody={
            passwordSubmit: this.state.project.passwordEnabled ==="enabled" && this.state.project.projectLinkActive === "active" ? this.state.project.password : null,
            status: this.state.project.projectLinkActive
        }
        return api.updateProjectLink(projectLinkId, projectBody)
            .then(res=>{

                Success({message: "Share URL Updated!"})
                if(this.state.project.passwordEnabled === "disabled" || this.state.project.projectLinkActive === "disabled"){
                    let project = {...this.state.project}
                    project.password = ""
                    project.passwordEnabled = "disabled"
                    this.setState({project: project})
                }
                return res
            })
    }

    updatePassword=(event, cb)=>{
        event.preventDefault()
        let {name ,value} = event.target
        let project = {...this.state.project}

        project.password = value
        cb(value)
        this.setState({project: project})
    }
    clearPW=()=>{

        if(this.state.project.password === true){
            let project = {...this.state.project}
            project.password = ""
            this.setState({project: project})
        }
    }
    pwBlur=()=>{
        if(this.state.project.password === "" && this.state.project.passwordExists){
            let project = {...this.state.project}
            project.password = true
            this.setState({project: project})
        }
    }
    onSort = (event)=>{

    }
    setSort = (sortable)=>{


        this.updateSceneOrder(sortable)
    }
    updateSceneOrder = (sortable)=>{

        let sceneOrder = sortable.toArray()
        sceneOrder.forEach((sceneId, index)=>{
                api.updateSceneOrder(sceneId, {weight: index})
                    .then(res=>{

                    })
                    .catch(error=>{

                    })
        })
        this.context.updateLatest(this.state.project.id)

    }
    resolveCoverArt=(coverPhotoKey)=>{
        const key = coverPhotoKey
        if(coverPhotoKey){
            const ext = key.split('.').reverse()[0];
            let final = key.replace('.' + ext, '') + '-minified'  + '.' + ext
            return final
        }
    }
    handleCropUtilityClose = (cropSaved)=>{
        this.setState({isCropUtilityOpen: false})

        if(cropSaved){
            cropSaved()
        }
        else{
        }
    }
    playCB=()=>{
        let trackOrder = [...this.state.sceneTracks]
    }
    getThumbnail=(photoKey)=>{
        const key = photoKey

        if(photoKey && typeof photoKey === "string"){
            const ext = key.split('.').reverse()[0];
            let final = key.replace('.' + ext, '') + '-minified-325x325' + '.' + ext
            return final
        }
    }
    updateScenes = (newScene) => {
      let scenes = this.state.scenes;
      scenes.push(newScene);
      this.setState({
        scenes: scenes,
        newSceneName: ""
      });
    }

    render(){
        return(
            <UserConsumer>
                {({ user, login, logout})=>(
                    <>
                        <Helmet
                            title={`${this.state.project?.name} - Deep Sounds | Music for Creators`}
                            meta={[{property: "og:image", content: `https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.getThumbnail(this.state.coverArt)}`}]}
                        />
                        {!this.state.isLoaded ? (
                            <div className="mt-25">
                                <CircularIndeterminant forceCenter/>
                            </div>
                        ) : (
                            <>
                                <div class="hero-bg h-200 lg:h-300">
                                {typeof this.state?.project?.coverArt === "string" ?
                                        typeof this.state.project?.coverArt !== "string" ?
                                            <div class="hero-img bg-blur" style={{backgroundImage: `url('${this.state.project?.coverArt?.preview}')`}}></div>:
                                        <div class="hero-img bg-blur" style={{backgroundImage: `url('https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveCoverArt(this.state.project?.coverArt)}')`}}></div>:
                                    this.state?.defaultArt ?
                                        this.state.project?.coverArt ?
                                        <div class="hero-img bg-blur" style={{backgroundImage: `url('${this.state.project?.coverArt?.preview}')`}}></div>:
                                    <div class="hero-img bg-blur" style={{backgroundImage: `url('https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveCoverArt(this.state.defaultArt)}')`}}></div>:
                                    this.state.project?.coverArt ?
                                    <div class="hero-img bg-blur" style={{backgroundImage: `url('${this.state.project?.coverArt?.preview}')`}}></div>:
                                    <div class="hero-img bg-blur" style={{backgroundImage: `url('${wavList[this.state.randomWavIndex]}')`}}></div>
                                }
                                </div>
                                <div class="container relative pt-80 lg:pt-120 mb-40 lg:mb-80">
                                <ProjectEditHeader coverArt={this.state.project?.coverArt} name={this.state.project?.name} activeCollabs={this.state.project?.activeCollabs} projectArtwork={typeof this.state.coverArt === "string" ? this.state.coverArt : null || this.state.defaultArt} handleSceneModalOpen={this.handleSceneModalOpen} markComplete={this.markComplete} duplicateProject={this.duplicateProject}
                                toggleProjectDetailTippy={this.toggleProjectDetailTippy} isProjectDetailTippyOpen={this.state.isProjectDetailTippyOpen} markIncomplete={this.markIncomplete} leaveProject={this.leaveProject} deleteProject={this.deleteProject}
                                complete={this.state.project?.isOwner ? (this.state.project?.status === "complete" ? true: false) : (this.state.project?.collaboratorStatus === "accepted-complete" ? true: false)} handleProjectEditModalOpen={this.handleProjectEditModalOpen} handleProjectEditModalClose={this.handleProjectEditModalClose} handleInputChange={this.handleInputChange}
                                isOwner={this.state.project?.isOwner } collaborators={this.state.project?.collaborators || []} projectId={this.state.project?.id} removeCollaborator={this.removeCollaborator} addCollaboratorToState={this.addCollaboratorToState}
                                closeProjectDetailTippy={this.closeProjectDetailTippy} projectLink={this.state.project?.projectLink} linkEnabled={this.state.project?.projectLinkActive} passwordEnabled={this.state.project?.passwordEnabled} enableLink={this.enableLink} enablePassword={this.enablePassword} updateProjectLink={this.updateProjectLink}
                                updatePassword={this.updatePassword} projectPassword={this.state.project?.password} projectLinkId={this.state.project?.projectLinkId} clearPW={this.clearPW} pwBlur={this.pwBlur} generateProjectLink={this.generateProjectLink} collaboratorEmail={this.state.collaboratorEmail} filteredCollaborators={this.state.filteredCollaborators}
                                onKeyDown={this.onKeyDown} activeOption={this.state.activeOption} onClick={this.onClick} purchaseId={this.state.project?.purchaseId} updateLatest={this.context.updateLatest} handleFormUnsaved={this.props.handleFormUnsaved} ownerEmail={this.state.project?.ownerEmail} ownerName={this.state.project?.ownerFN + " " +this.state.project?.ownerLN} ownerPhoto={this.state.project?.ownerPhoto}/>
                                {/* {# Prompts #} */}
                                <ul className="tab-items">
                                <li onClick={()=>{this.setState({activeTab: "scenes"})}} className={`tab-item ${this.state.activeTab === "scenes" && 'active'}`}>
                                    <a href="# " className="tab-item-title">Scenes</a>
                                </li>
                                {(this.state.customerLicenses?.length > 0) &&
                                    <li onClick={()=>{this.setState({activeTab: "licenses"})}} className={`tab-item ${this.state.activeTab === "licenses" && 'active'}`}>
                                        <a href="# " className="tab-item-title">Licenses</a>
                                    </li>
                                }
                                </ul>
                                <div class="tab-content">
                                {this.state.activeTab === "scenes" &&
                                (this.state.isLoaded ?
                                <>

                                <div class="project-scenes">
                                    {this.state.scenes.length > 0 ?
                                    <div class="accordion js-accordion mb-30 lg:mb-50">
                                        <ReactSortable
                                            // multiDrag={true}
                                            group="scenes"
                                            animation={200}
                                            handle='.icon-drag'
                                            onChoose={(event)=>{this.forceClose(event)}}
                                            onEnd={(event)=>{this.removePadding()}}
                                            ghostClass='ghost-class'
                                            onUnchoose={(event)=>this.onSort(event)}
                                            forceFallback={true}
                                            delayOnTouchOnly={true}
                                            delay={.9}
                                            dataIdAttr="scenei"
                                            list={this.state.scenes}
                                            setList={newState => this.setState({ scenes: newState })}
                                            store={{
                                                set: (sortable)=>this.setSort(sortable)}
                                            }
                                        >
                                            {this.state.scenes.map((scene, index)=>{
                                                return(
                                                    <ProjectAccordion index={index} name={scene.name} playlistTracks={this.state.sceneTracks.map(sceneTrack=>sceneTrack).sort((a,b)=>a.weight === b.weight ? 0 :a.weight < b.weight ? -1: 1).filter((sceneTrack, index)=>{sceneTrack.realInd=index; return sceneTrack.sceneId === scene.id})} tracks={this.state.sceneTracks.filter((sceneTrack, index)=>{sceneTrack.realInd=index; return sceneTrack.sceneId === scene.id})}
                                                    submitVote={this.submitVote} updateVote={this.updateVote} autoExpand={index===0} projectId={this.state.project.id} sortFilters={sortFilters} sortBy={this.sortBy} sortCriteria={this.state.sortText}
                                                    toggleSortTippy={this.toggleSortTippy} handleEditSceneModalOpen={this.handleEditSceneModalOpen} isEditSceneModalOpen={this.state.isEditSceneModalOpen}  toggleSceneTooltip={this.toggleSceneTooltip} isSceneTooltipOpen={this.state.isSceneTooltipOpen}
                                                    handleFormUnsaved={this.props.handleFormUnsaved} editScene={this.editScene} sceneId={scene.id} removeScene={this.removeScene}  forceClose={scene.forceClose} ref={this.sceneElements?.length ? this.sceneElements[index]: undefined} removeTrack={this.removeTrack}
                                                    isOwner={this.state.project?.isOwner } checkIfUsed={this.checkIfUsed} updateFollowing={this.updateFollowing} data-id={scene.sceneId} isLoading={this.state.isLoading}/>
                                                )
                                            })}
                                                    </ReactSortable>

                                    </div>:
                                    <div onClick={this.handleSceneModalOpen} class="prompt cursor-pointer mb-10 mt-10">
                                        <div class="prompt-content">
                                            {/* {# Icon #} */}
                                            <div class="text-icon-xl mb-15">
                                                <span class="icon-add-track-lg"></span>
                                            </div>
                                            {/* {# Heading #} */}
                                            <h3 class="prompt-title">Add Some Scenes to Your Project</h3>
                                            {/* {# Description #} */}
                                            <p class="max-w-sm">Mauris non tempor quam, et lacinia sapien, Mauris accumsan eros eget.</p>
                                        </div>
                                    </div>}
                                </div>


                                {/* {# Add Scene #} */}
                                <button onClick={this.handleSceneModalOpen} class="btn btn-lg btn-stroke btn-wide" data-micromodal-trigger="add_scene_modal" type="button">
                                                        <span class="icon-add-scene mr-10"></span>
                                                        Add Scene
                                                    </button>
                                <MicroModal
                                    open={this.state.isSceneModalOpen}
                                    closeOnEscapePress={true}
                                    handleClose={()=>this.handleSceneModalClose()}
                                    closeOnOverlayClick={true}
                                    closeOnAnimationEnd={true}
                                    modalOverlayClassName="modal-overlay"
                                    modalClassName="modal micromodal-slide is-open"
                                    modalOverlayStyles={null}
                                    children={
                                      handleClose =>
                                        <AddSceneModal
                                          errorToast={this.errorToast}
                                          handleClose={handleClose}
                                          handleInputChange={this.handleInputChange}
                                          newSceneName={this.state.newSceneName}
                                          checkIfUsed={this.checkIfUsed}
                                          projectId={this.props.match.params.id}
                                          successToastSave={this.successToastSave}
                                          updateScenes={this.updateScenes}
                                      />}
                                    containerStyles={{background: "#131313", padding:"asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box"}}
                                ></MicroModal>
                                <MicroModal
                                    open={this.state.isProjectEditModalOpen}
                                    closeOnEscapePress={true}
                                    handleClose={()=>this.handleProjectEditModalClose()}
                                    closeOnOverlayClick={true}
                                    closeOnAnimationEnd={true}
                                    modalOverlayClassName="modal-overlay"
                                    modalClassName="modal micromodal-slide is-open"
                                    modalOverlayStyles={null}
                                    children={handleClose=><EditProjectModal handleClose={handleClose} handleInputChange={this.handleInputChange} editedProjectName={this.state.editedProjectName}
                                    updateProject={this.updateProject}
                                    setCoverArtState={this.setCoverArtState} coverArt={this.state.coverArt} coverArtPreview={this.state.coverArtPreview} coverArtError={this.state.coverArtError}
                                    removeCoverArt={this.removeCoverArt} defaultArt={this.state.defaultArt} initialCoverArt={this.state.initialCoverArt} checkIfProjectUsed={this.checkIfProjectUsed} initialRealCoverArt={this.state.initialRealCoverArt}
                                    setRealCoverArtState={this.setRealCoverArtState}  realCoverArt={this.state.realCoverArt} realCoverArtPreview={this.state.realCoverArtPreview} realCoverArtError={this.state.realCoverArtError} removeRealCoverArt={this.removeRealCoverArt}
                                    handleCropUtilityClose={this.handleCropUtilityClose} isCropUtilityOpen={this.state.isCropUtilityOpen} openPopout={()=>{this.setState({isCropUtilityOpen: true});}}  isLoading={this.state.isLoading} userSet={this.state.project?.userSet} wavPlaceholder={wavList[this.state.randomWavIndex]}
                                    />}
                                    containerStyles={{background: "#131313", padding:"asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box"}}
                                ></MicroModal>
                                </>:
                                <div className="mt-20">
                                    <CircularIndeterminant size={40} forceCenter={true}/>
                                </div>)}
                                {this.state.activeTab === "licenses" && <>
                                {this.state.customerLicenses?.map(custLic => {
                                let license = JSON.parse(custLic.info).license
                                let licenseSummary = <LicenseSummary license={license} />
                                return(
                                    <TrackListItemLicense
                                        {...custLic}
                                        license={license}
                                        summary={licenseSummary}
                                        idKey={"custLicenseTrack-"+custLic.id} />
                                )
                                })}
                                <MicroModal
                                        open={this.state.isProjectEditModalOpen}
                                        closeOnEscapePress={true}
                                        handleClose={()=>this.handleProjectEditModalClose()}
                                        closeOnOverlayClick={true}
                                        closeOnAnimationEnd={true}
                                        modalOverlayClassName="modal-overlay"
                                        modalClassName="modal micromodal-slide is-open"
                                        modalOverlayStyles={null}
                                        children={handleClose=><EditProjectModal handleClose={handleClose} handleInputChange={this.handleInputChange} editedProjectName={this.state.editedProjectName}
                                        updateProject={this.updateProject}
                                        projectTypes={this.state.projectTypes} setCoverArtState={this.setCoverArtState} coverArt={this.state.coverArt} coverArtPreview={this.state.coverArtPreview} coverArtError={this.state.coverArtError}
                                        removeCoverArt={this.removeCoverArt} defaultArt={this.state.defaultArt} initialCoverArt={this.state.initialCoverArt} checkIfProjectUsed={this.checkIfProjectUsed} initialRealCoverArt={this.state.initialRealCoverArt}
                                        setRealCoverArtState={this.setRealCoverArtState}  realCoverArt={this.state.realCoverArt} realCoverArtPreview={this.state.realCoverArtPreview} realCoverArtError={this.state.realCoverArtError} removeRealCoverArt={this.removeRealCoverArt}
                                        handleCropUtilityClose={this.handleCropUtilityClose} isCropUtilityOpen={this.state.isCropUtilityOpen} openPopout={()=>{this.setState({isCropUtilityOpen: true});}} userSet={this.state.project?.userSet} wavPlaceholder={wavList[this.state.randomWavIndex]}
                                        />}
                                        containerStyles={{background: "#131313", padding:"asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box"}}
                                    ></MicroModal>
                                    </>}
                                </div>
                                </div>
                            </>
                        )}
                    </>
                )}
            </UserConsumer>
        )
    }
}
ProjectDetail.contextType = ProjectsContext
export const ProjectDetailWrapped = (props)=>(
    <PlayerConsumer>
        {({updateLoadedTracks})=>(
            <ProjectDetail {...props} updateLoadedTracks={updateLoadedTracks}/>
        )}
    </PlayerConsumer>
)
// export default ProjectDetail

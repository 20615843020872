import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import ReactHtmlParser from "react-html-parser";
import { useLocation, useParams } from "react-router-dom";
import blogAPI from "../../api/blog";
import { handleAPIError } from "../../utils/handleAPIError";

export default function BlogPage() {
  const [blogAuthor, setBlogAuthor] = useState("Deep Sounds");
  const [blogBody, setBlogBody] = useState(null);
  const [blogTitle, setBlogTitle] = useState("Blog - Deep Sounds | Music for Creators");
  const [blogDescription, setBlogDescription] = useState(
    "Navigate music licensing professionally. Gain insights, trends, and tips for your projects. Elevate creativity with our concise blog. #MusicLicensing #ProfessionalInsights"
  );

  const { slug } = useParams();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname.includes("/blog/tags/")) {
      blogAPI
        .getTagPage({ slug })
        .then((res) => {
          const { blogAuthor, blogTitle, blogDescription, bodyHTML } = res.data || {};
          setBlogAuthor(blogAuthor);
          setBlogBody(bodyHTML);
          setBlogDescription(blogDescription);
          setBlogTitle(blogTitle);
        })
        .catch((error) => {
          handleAPIError(error);
        });
    } else {
      blogAPI
        .getPage({ slug })
        .then((res) => {
          const { blogAuthor, blogTitle, blogDescription, bodyHTML } = res.data || {};
          setBlogAuthor(blogAuthor);
          setBlogBody(bodyHTML);
          setBlogDescription(blogDescription);
          setBlogTitle(blogTitle);
        })
        .catch((error) => {
          handleAPIError(error);
        });
    }
  }, [slug, pathname]);

  return (
    <>
      <Helmet>
        <title>{blogTitle}</title>
        <meta name="description" content={blogDescription} />
        <meta property="og:author" content={blogAuthor} />
        <meta property="og:description" content={blogDescription} />
        <meta property="og:site_name" content="Deep Sounds Blog" />
        <meta property="og:title" content={blogTitle}></meta>
        <meta property="og:type" content="article" />
      </Helmet>

      <section className="container max-w-lg md:max-w-screen-3xl mb-0">
        {ReactHtmlParser(blogBody)}
      </section>
    </>
  );
}

import React from 'react'
import CircularIndeterminant from "./circularIndeterminant"

const LoadingContainer = (props) => {
  const {children, isLoaded, forceCenter, loader, skeleton, style} = props;

  return (
    isLoaded ? (
      children
    ) : (
      <div style={style}>
        {(loader !== "none") && (
          skeleton ? (
            skeleton
          ) : (
            <CircularIndeterminant size = { 40 } forceCenter />
          )
        )}
      </div>
    )
  )
}

export default LoadingContainer;

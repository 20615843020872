import React, { Component } from "react";
import Amex from "../../assets/photos/cardlogos/amex.png";
import Discover from "../../assets/photos/cardlogos/discover.jpg";
import Generic from "../../assets/photos/cardlogos/generic.svg";
import MasterCard from "../../assets/photos/cardlogos/mastercard.svg";
import Visa from "../../assets/photos/cardlogos/visa.png";
import tippy from '../../tooltips.js';
import { UserConsumer, UserContext } from "../../contexts/User";
import CircularIndeterminant from "../Loading/circularIndeterminant";
import api from "../utils/api";
import AddBlock from "./addBlock";
import CardsAndAccounts from "./cardsAndAccounts";
import moment from "moment";

class PaymentMethods extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {
        cards: [],
        defaultCard: false
    }

    loadPaymentMethods = () => {
        api.getCustPaymentMethods()
        .then(res => {


            this.setState({
                defaultCard: res.data.default[0],
                additionalCards: res.data.others,
                paymentMethodsLoaded: true
            })
        })
        .catch(error => {
            this.setState({
                paymentMethodsLoaded: true
            })
        })
    }

    componentDidMount = ()=>{
        this.loadPaymentMethods();
    }
    resolveLogo = (type)=>{
        switch(type){
            case "amex":
                return Amex;
            case "visa":
                return Visa;
            case "mastercard":
                return MasterCard;
            case "discover":
                return Discover;
            default:
                return Generic;
        }
    }

    formatExpiryDate = (card) => {
        const month = card.exp_month;
        const year = card.exp_year;

        const monthFormatted = moment(month, 'MM').format('MM');
        const yearFormatted = moment(year, 'YYYY').format('YY');

        return `${monthFormatted}/${yearFormatted}`;
    }

    formatYear = (year) => {

    }

    render() {
        return (
            <UserConsumer>
                {({ user, login, logout}) => (
                    this.state.paymentMethodsLoaded ?
                        <>
                            <div className="mb-50">
                                <h2 className="text-h3 text-white mb-20">Update Payment Methods</h2>
                                <p className="text-h4 text-gray-400 mb0">Please use the form below to edit your payment methods.</p>
                            </div>

                            {/* {# Section #} */}
                            <div className="form-section pb-20">
                                <h3 className="text-label-lg mb-30">Saved Cards</h3>

                                <div className="grid-row">
                                        {console.log('this.state.additionalCards', this.state.additionalCards)}
                                        {this.state.defaultCard && (
                                            <div className="col w-full md:w-1/2 mb-20 md:mb-40">
                                                <CardsAndAccounts
                                                    primary={true}
                                                    removable={!(this.state.additionalCards?.length > 0) && (!(user.planStatus === "active"))}
                                                    tippy={tippy}
                                                    paymentId={this.state.defaultCard.id}
                                                    cardName={this.state.defaultCard.billing_details.name}
                                                    cardNick={this.state.defaultCard.metadata.nickName || "Primary Card"}
                                                    acctType="Card"
                                                    logo={this.resolveLogo(this.state.defaultCard.card.brand)}
                                                    lastFour={this.state.defaultCard.card.last4}
                                                    exp={this.formatExpiryDate(this.state.defaultCard.card)}
                                                    loadPaymentMethods={this.loadPaymentMethods}
                                                />
                                            </div>
                                        )}

                                        {this.state.additionalCards.map(card => {
                                            return(
                                                <div className="col w-full md:w-1/2 mb-20 md:mb-40" key={card.id}>
                                                    <CardsAndAccounts
                                                        removable={true}
                                                        tippy={tippy}
                                                        paymentId={card.id}
                                                        cardNick={card.metadata.nickName}
                                                        cardName={card.billing_details.name}
                                                        acctType="Card"
                                                        logo={this.resolveLogo(card.card.brand)}
                                                        lastFour={card.card.last4}
                                                        exp={this.formatExpiryDate(card.card)}
                                                        loadPaymentMethods={this.loadPaymentMethods}
                                                    />
                                                </div>
                                            )
                                        })}

                                    <div className="col w-full md:w-1/2 mb-20 md:mb-40">
                                        <AddBlock
                                            text="Add a New Card"
                                            icon_class='icon-plus'
                                        />
                                    </div>
                                </div>

                            </div>

                            {/* {# End Form Container #} */}
                        </>
                    :
                        <CircularIndeterminant forceCenter/>

                )}
            </UserConsumer>
        )
    }

}
PaymentMethods.contextType = UserContext
export default PaymentMethods

import React, { Component } from "react";
import { splitFormProps, useField, useForm } from "react-form";
import CardArtUpload from "../Dropzone/collectionCardUpload";
import CoverArtUpload from "../Dropzone/collectionCoverUpload";
import CircularIndeterminantButton from "../Loading/circularIndeterminantButton";

const NameField = React.forwardRef((props,ref) =>{
    const [field, fieldOptions, rest] = splitFormProps(props)

    const {
        meta: {error, isTouched, isValidating },
        getInputProps,
        setValue
    } = useField(field, fieldOptions);
    return (
          <>
            <input
              onChange={(e)=>props.handleInputChange(e,setValue)}
              id={"newCollectionName"}
              class="form-input"
              placeholder={"Collection Name"}
              value={props.newCollectionName}
              name="newCollectionName"
            />
            {props.children || ''}
            {isValidating ? (
                <em>Validating...</em>
            ) : (isTouched && error && (
                  <em className="error-message">{error}</em>
              )
            )}
          </>
    )
})
const DescriptionField = React.forwardRef((props,ref) =>{
  const [field, fieldOptions, rest] = splitFormProps(props)

  const {
      meta: {error, isTouched, isValidating },
      getInputProps,
      setValue
  } = useField(field, fieldOptions);
  return(
        <>
          <textarea maxLength={225} style={{height: "auto" , paddingTop: "1.6rem"}}  id="newCollectionDescription" name="newCollectionDescription" className="form-input" placeholder="Description" value={props.newCollectionDetail} onChange={(e)=>props.handleInputChange(e,setValue)} rows={4}/>
              {isValidating ?(
                  <em>Validating...</em>
              ) : isTouched && error ?(
                  <em className="error-message">{error}</em>
              ): null}
        </>
  )
})
const CardField = React.forwardRef((props,ref) =>{
  const [field, fieldOptions, rest] = splitFormProps(props)

  const {
      meta: {error, isTouched, isValidating },
      getInputProps,
      setValue,
      runValidation
  } = useField(field, fieldOptions);

  return(
        <>
          <CardArtUpload onChange={props.setCardArtState} coverArt={props.cardArt} coverArtError={isValidating ?("Validating...") :  error ?(error): null} removeCoverArt={props.removeCardArt} validate={runValidation}
           isCropUtilityOpen={props.isCropUtilityOpen} openPopout={props.handleCropUtilityModalOpen} handleCropUtilityClose={props.handleCropUtilityModalClose}               />

        </>
  )
})
const CoverField = React.forwardRef((props,ref) =>{
  const [field, fieldOptions, rest] = splitFormProps(props)

  const {
      meta: {error, isTouched, isValidating },
      getInputProps,
      setValue,
      runValidation
  } = useField(field, fieldOptions);
  return(
        <>
          <CoverArtUpload onChange={props.setCoverArtState} coverArt={props.coverArt} coverArtError={isValidating ?("Validating...") : isTouched && error ?(error): null} removeCoverArt={props.removeCoverArt} validate={runValidation}
                      />

        </>
  )
})
const validateName = (value, instance, props)=>{
  if(value?.length < 3){
    return "Name must be at least 3 characters"
  }
  if(value?.length > 28){
    return "Name must be at less than 28 characters"
  }
  else if(value === undefined){
    return "Name required"
  }
  return false
}
const validateDescription = (value, instance, props)=>{
  if(value?.length < 3){
    return "Description must be at least 3 characters"
  }
  else if(value === undefined){
    return "Description required"
  }
  return false
}
const validateCard = (value, instance, props)=>{
  if(typeof value === "string"){
    return "File must be an image in a 1x1 ratio!"
  }
  else if(!Array.isArray(value)){
    return "Card art required"
  }
  else{
    return false
  }
}
const validateCover = (value, instance, props)=>{
  if(!Array.isArray(value)){
    return "Cover art required"
  }
  else{
    return false
  }
}
const MyForm = (props)=>{
  const {
    Form,
    meta: { isSubmitting, canSubmit }
  } = useForm({
    onSubmit:()=> props.onSubmit(props.handleClose)
  })
  return(
                <Form action="" className="ds-form">
                    {/* {# Title #} */}
                    {/* <div className="input-group-sm input-inline">
                        <input id="project_title" name="newCollectionName" className="form-input" placeholder="Collection Name" value={this.props.newCollectionName} onChange={this.props.handleInputChange} required/>
                        <label className="input-label" htmlFor="newCollectionName" >Collection Name</label>
                        <div className="error-message">{this.props.newCollectionNameError}</div>
                    </div> */}
                    <div className="input-group-sm input-inline">
                      <NameField
                        field="collectionName" {...props}
                        id="newCollectionName"
                        validate={(value, instance) => validateName(value, instance, { ...props })}
                      >
                        <label className="input-label" htmlFor="newCollectionName" >Collection Name</label>
                      </NameField>
                      <div className="my-10 text-sm">
                        Make sure to choose a succinct Collection Name that will fit on a single line.
                      </div>
                    </div>
                    {/* {# Client #} */}
                    <div className="input-group-sm input-inline">
                        {/* <textarea maxLength={225} style={{height: "auto"}}  id="newCollectionDescription" name="newCollectionDescription" className="form-input" placeholder="Description (Optional)" value={props.newCollectionDetail} onChange={props.handleInputChange} rows={4}/> */}
                        <DescriptionField field="collectionDescription" {...props} validate={(value, instance)=>validateDescription(value, instance, {...props})}/>
                        <label className="input-label" htmlFor="newCollectionDescription">Description</label>
                        <div style={{float: "right"}}>
                          <span>{props.newCollectionDescription?.length || 0}</span>
                          <span>/225</span>
                        </div>
                    </div>
                    <div className="input-group">
                        <CardField field="cardField" {...props} validate={(value, instance)=>validateCard(props.cardArt, instance, {...props})}/>
                    </div>
                    <div className="input-group">
                        <CoverField field="coverField" {...props} validate={(value, instance)=>validateCover(value ||props.coverArt, instance, {...props})}/>
                    </div>

                    <div className="input-group pt-20">
                        <div className="grid-row-sm">
                            <div className="col w-1/2 mb-20 sm:mb-0">
                                <button onClick={props.handleClose} className="btn btn-stroke form-btn w-full" type="button" aria-label="Close Modal" data-micromodal-close="create_project_modal">Cancel</button>
                            </div>
                            <div className="col w-1/2">
                                <button type="submit" disabled={!canSubmit || props.isLoading} className="btn btn-primary form-btn w-full">{props.isLoading ? <CircularIndeterminantButton thickness={5.5}/>: "Create"}</button>
                            </div>
                        </div>
                    </div>

                </Form>

  )
}
class CreateCollectionModal extends Component{
    state={
      isCropUtilityOpen: false
    }
    handleCropUtilityModalOpen=()=>{
      this.setState({isCropUtilityOpen: true})
    }
    handleCropUtilityModalClose=()=>{
      this.setState({isCropUtilityOpen: false})
    }
    render(){
        return(

            <React.Fragment>

            <header className="modal-header">
                <h2 id="create_project_title" className="modal-title">Create a Collection</h2>
                <button aria-label="Close Modal" data-micromodal-close="create_project_modal" className="modal-close" type="button" onClick={this.props.handleClose}></button>
            </header>

            {/* {# Modal Content #} */}
            <div id="create_project_content" className="modal-content">
                <MyForm {...this.props} isCropUtilityOpen={this.state.isCropUtilityOpen} handleCropUtilityModalOpen={this.handleCropUtilityModalOpen} handleCropUtilityModalClose={this.handleCropUtilityModalClose}/>


            </div>

</React.Fragment>
        )
    }
}
export default CreateCollectionModal

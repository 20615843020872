import React, { Component } from "react";
import "./style.css";
import currencyFormatter from "../../utils/currencyFormatter";

const moment = require("moment");
class ArtistsPayoutRow extends Component {
  state = {};
  render() {
    return (
      <tr>
        <td>{this.props.artistName}</td>
        <td>{this.props.approvedTracks}</td>
        <td>{currencyFormatter(this.props.subscriptionLicenseRevenue / 100)}</td>
        <td>{this.props.seniorityDays}</td>
        <td>{(this.props.approvedTracksPayRate * 100).toFixed(2)}%</td>
        <td>{(this.props.subscriptionPayRate * 100).toFixed(2)}%</td>
        <td>{(this.props.seniorityPayRate * 100).toFixed(2)}%</td>
        <td>{(this.props.potPercentage * 100).toFixed(2)}%</td>
        {this.props.reversalAmount ? (
          <td>{currencyFormatter((this.props.payoutTotal - this.props.reversalAmount) / 100)}</td>
        ) : (
          <td>{currencyFormatter(this.props.payoutTotal / 100)}</td>
        )}
        <td>
          {this.props.reversalAmount
            ? `Subscription Royalty ${moment(this.props.forMonth).utc().format("MM/YYYY")} ` +
              this.props.description
            : this.props.description}
        </td>
        <td>{this.props.status}</td>
        <td>
          <a
            target="_blank"
            href={`https://dashboard.stripe.com/connect/transfers/${this.props.transferId}`}
            rel="noreferrer"
          >
            {this.props.transferId}
          </a>
        </td>
      </tr>
    );
  }
}
export default ArtistsPayoutRow;

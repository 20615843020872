import React, { Component } from "react";
import { Link } from "react-router-dom";

class DetailsTab extends Component {
  render() {
    return (
      <div className="tab-item-content active" id="tab_details">
        <div className="grid-row">
          {/* {# Genres #} */}
          <div className="col w-1/2 md:w-1/3 mb-30">
            <h3 className="text-label-sm mb-5">Genre</h3>
            {this.props.genres?.map((genre, index) =>
              window.location.pathname.includes("/music") ||
              /\/artists\/{0}[^\/]*$/.test(window.location.pathname) ? (
                <span key={genre + index}>
                  {genre.name}
                  {index === this.props.genres?.length - 1 ? <> </> : <>,&nbsp;</>}
                </span>
              ) : (
                <Link
                  key={genre + index}
                  to={{
                    pathname: "/music",
                    search: `?inc_gen=${genre.tagId}`,
                    state: { from: window.location.pathname },
                  }}
                  className="text-link-white"
                >
                  {genre.name}
                  {index === this.props.genres?.length - 1 ? <> </> : <>,&nbsp;</>}
                </Link>
              )
            )}
          </div>

          {/* {# Speed #} */}
          <div className="col w-1/2 md:w-1/3 mb-30">
            <h3 className="text-label-sm mb-5">Speed</h3>
            {this.props.speed?.map((spd, index) =>
              window.location.pathname.includes("/music") ||
              /\/artists\/{0}[^\/]*$/.test(window.location.pathname) ? (
                <span key={spd + index}>
                  {spd.name}
                  {index === this.props.speed?.length - 1 ? <> </> : <>,&nbsp;</>}
                </span>
              ) : (
                <Link
                  key={spd + index}
                  to={{
                    pathname: `/music`,
                    search: `?inc_bmi=${spd.tagId}`,
                    state: { from: window.location.pathname },
                  }}
                  className="text-link-white"
                >
                  {spd.name}
                  {index === this.props.speed?.length - 1 ? <> </> : <>,&nbsp;</>}
                </Link>
              )
            )}
          </div>

          {/* {# City #} */}
          <div className="col w-1/2 md:w-1/3 mb-30">
            <h3 className="text-label-sm mb-5">City</h3>
            {this.props.city?.map((reg, index) =>
              window.location.pathname.includes("/music") ||
              /\/artists\/{0}[^\/]*$/.test(window.location.pathname) ? (
                <span key={reg + index}>
                  {reg.name}
                  {index === this.props.city?.length - 1 ? <> </> : <>,&nbsp;</>}
                </span>
              ) : (
                <Link
                  key={reg + index}
                  to={{
                    pathname: "/music",
                    search: `?inc_reg=${reg.tagId}`,
                    state: { from: window.location.pathname },
                  }}
                  className="text-link-white"
                >
                  {reg.name}
                  {index === this.props.city.length - 1 ? <> </> : <>,&nbsp;</>}
                </Link>
              )
            )}
          </div>

          {/* {# Type #} */}
          <div className="col w-1/2 md:w-1/3 mb-30">
            <h3 className="text-label-sm mb-5">Type</h3>
            {this.props.lyrics?.map((lyric, index) =>
              window.location.pathname.includes("/music") ||
              /\/artists\/{0}[^\/]*$/.test(window.location.pathname) ? (
                <span key={lyric + index}>
                  {lyric.name}
                  {index === this.props.lyrics.length - 1 ? <> </> : <>,&nbsp;</>}
                </span>
              ) : (
                <Link
                  key={lyric + index}
                  to={{
                    pathname: "/music",
                    search: `?lyrics=${lyric.tagId}`,
                    state: { from: window.location.pathname },
                  }}
                  className="text-link-white"
                >
                  {lyric.name}
                  {index === this.props.lyrics.length - 1 ? <> </> : <>,&nbsp;</>}
                </Link>
              )
            )}
          </div>

          {/* {# Emotion #} */}
          <div className="col w-1/2 md:w-1/3 mb-30">
            <h3 className="text-label-sm mb-5">Emotion</h3>
            {this.props.emotion?.map((emo, index) =>
              window.location.pathname.includes("/music") ||
              /\/artists\/{0}[^\/]*$/.test(window.location.pathname) ? (
                <span key={emo + index}>
                  {emo.name}
                  {index === this.props.emotion.length - 1 ? <> </> : <>,&nbsp;</>}
                </span>
              ) : (
                <Link
                  key={emo + index}
                  to={{
                    pathname: "/music",
                    search: `?inc_emo=${emo.tagId}`,
                    state: { from: window.location.pathname },
                  }}
                  className="text-link-white"
                >
                  {emo.name}
                  {index === this.props.emotion.length - 1 ? <> </> : <>,&nbsp;</>}
                </Link>
              )
            )}
          </div>

          {/* {# Moods #} */}
          <div className="col w-1/2 md:w-1/3 mb-30">
            <h3 className="text-label-sm mb-5">Moods</h3>
            {/* {% for mood in moods.slice(0,4) %}
                        {% if loop.last %}
                            {% set separator = "" %}
                        {% else %}
                            {% set separator = "," %}
                        {% endif %} */}
            {this.props.moods?.map((mood, index) =>
              window.location.pathname.includes("/music") ||
              /\/artists\/{0}[^\/]*$/.test(window.location.pathname) ? (
                <span key={mood + index}>
                  {mood.name}
                  {index === this.props.moods.length - 1 ? <> </> : <>,&nbsp;</>}
                </span>
              ) : (
                <Link
                  key={mood + index}
                  to={{
                    pathname: "/music",
                    search: `?inc_moo=${mood.tagId}`,
                    state: { from: window.location.pathname },
                  }}
                  className="text-link-white"
                >
                  {mood.name}
                  {index === this.props.moods.length - 1 ? <> </> : <>,&nbsp;</>}
                </Link>
              )
            )}

            {/* {% endfor %} */}
          </div>

          {/* {# Instruments #} */}
          <div className="col w-full md:w-1/3 mb-30">
            <h3 className="text-label-sm mb-5">Instruments</h3>
            {/* {% for inst in instruments.slice(0,4) %}
                        {% if loop.last %}
                            {% set separator = "" %}
                        {% else %}
                            {% set separator = "," %}
                        {% endif %} */}
            {this.props.instruments?.map((instrument, index) =>
              window.location.pathname.includes("/music") ||
              /\/artists\/{0}[^\/]*$/.test(window.location.pathname) ? (
                <span key={instrument.name + index}>
                  {instrument.name}
                  {index === this.props.instruments.length - 1 ? <></> : <>,&nbsp;</>}
                </span>
              ) : (
                <Link
                  key={instrument.name + index}
                  to={{
                    pathname: "/music",
                    search: `?inc_ins=${instrument.tagId}`,
                    state: { from: window.location.pathname },
                  }}
                  className="text-link-white"
                >
                  {instrument.name}
                  {index === this.props.instruments.length - 1 ? <> </> : <>,&nbsp;</>}
                </Link>
              )
            )}

            {/* {% endfor %} */}
          </div>

          {/* {# Attributes #} */}
          <div className="col w-full md:w-2/3 mb-30">
            <h3 className="text-label-sm mb-5">Attributes</h3>
            {/* {% for attr in attributes.slice(0,10) %}
                        {% if loop.last %}
                            {% set separator = "" %}
                        {% else %}
                            {% set separator = "," %}
                        {% endif %} */}
            {this.props.attributes?.map((attribute, index) =>
              window.location.pathname.includes("/music") ||
              /\/artists\/{0}[^\/]*$/.test(window.location.pathname) ? (
                <span key={attribute + index}>
                  {attribute.name}
                  {index === this.props.attributes.length - 1 ? <> </> : <>,&nbsp;</>}
                </span>
              ) : (
                <Link
                  key={attribute + index}
                  to={{
                    pathname: "/music",
                    search: `?inc_att=${attribute.tagId}`,
                    state: { from: window.location.pathname },
                  }}
                  className="text-link-white"
                >
                  {attribute.name}
                  {index === this.props.attributes.length - 1 ? <> </> : <>,&nbsp;</>}
                </Link>
              )
            )}

            {/* {% endfor %} */}
          </div>
        </div>
      </div>
    );
  }
}
export default DetailsTab;

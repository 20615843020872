import React, { Component } from "react"
import { UserConsumer } from "../../contexts/User"
import AlbumArtUpload from "../Dropzone/albumArtUpload"
import CircularIndeterminantButton from "../Loading/circularIndeterminantButton"
import { Error, Success } from "../Toast/index"
import api from '../utils/api'

class AlbumModal extends Component {
    state = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        passwordConfirm: "",
        redirectTo: null,
        accountExists: false,
        loginEmail: "",
        loginPassword: "",
        loginMessage: "",
        loginAction: "login",
        pwSuggestions: [],
        isCropUtilityOpen: false,
        // role: "customer", -- TODO decide what role should be set by default
    }
    //updating our input fields as the user enters keys
    handleInputChange = (event)=>{
        // this.props.handleFormUnsaved(true)
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    }
    setAlbumArtState = (file)=>{

        if(file.error){
            this.setState({albumArtError: "File must be at least 1000x1000px"})
        }
        else if(file.length > 0){
            this.setState({
                albumArt: file,
                albumPreview: URL.createObjectURL(file[0]),
                albumArtError: null
            })
        }
        else{
            this.setState({
                albumArt: null,
                albumPreview: null,
                albumArtError: null,
            })
        }

    }
    submitNewAlbum = ()=>{
        if(this.state.newAlbumTitle.length > 1 && this.state.albumArt.length >0){
            let newAlbum = {
                title: this.state.newAlbumTitle
            }
            this.setState({isLoading: true}, ()=>{
                this.props.loadingCB(true)
                if(this.props.artistId){
                    api.createNewAlbumOnBehalfOf(this.props.artistId, newAlbum)
                    .then(response=>{

                        if(response.data.success === true){
                            const data = new FormData()
                            data.append('file', this.state.albumArt[0])
                            api.updateAlbumArt(response.data.album.id, data)
                                .then(res=>{
                                    this.props.loadingCB(false)
                                    this.setState({isLoading: false})

                                    if(res.data.success === true) {
                                        let newAlbum = {
                                            value: res.data.album.id,
                                            label: res.data.album.title,
                                            imageUrl: res.data.album.imageUrl
                                        }
                                        if(this.props.updateAlbumsCB){
                                            this.props.updateAlbumsCB(newAlbum)
                                        }
                                        Success({message:"Album Added!", onClose: this.successAlbumToastSubmit})
                                    }
                                    else{
                                        Error({message:"An error occurred uploading the album art 1!", onClose: this.errorToast})
                                    }
                                })
                                .catch(error=>{
                                    this.props.loadingCB(false)
                                    this.setState({isLoading: false})
                                    Error({message:"An error occurred uploading the album art 2!", onClose: this.errorToast})
                                })
                        }
                        else{
                            this.props.loadingCB(false)
                            this.setState({isLoading: false})
                            Error({message:"An error occurred creating the album!", onClose: this.errorToast})
                        }
                    })
                    .catch(error=>{
                        this.props.loadingCB(false)
                        this.setState({isLoading: false})
                        Error({message:"An error occurred!", onClose: this.errorToast})
                        if(error.response.status === 400){
                            this.setState({albumTitleError: "Album with that title exists!"})
                        }
                        else{
                            this.setState({albumTitleError: error.response.statusText})
                        }

                    })
                }
                else{
                api.createNewAlbum(newAlbum)
                    .then(response=>{

                        if(response.data.success === true){
                            const data = new FormData()
                            data.append('file', this.state.albumArt[0])
                            api.updateAlbumArt(response.data.album.id, data)
                                .then(res=>{
                                    this.props.loadingCB(false)
                                    this.setState({isLoading: false})

                                    if(res.data.success === true) {
                                        let newAlbum = {
                                            value: res.data.album.id,
                                            label: res.data.album.title,
                                            imageUrl: res.data.album.imageUrl
                                        }
                                        if(this.props.updateAlbumsCB){
                                            this.props.updateAlbumsCB(newAlbum)
                                        }
                                        Success({message:"Album Added!", onClose: this.successAlbumToastSubmit})
                                    }
                                    else{
                                        Error({message:"An error occurred uploading the album art 1!", onClose: this.errorToast})
                                    }
                                })
                                .catch(error=>{
                                    this.props.loadingCB(false)
                                    this.setState({isLoading: false})
                                    Error({message:"An error occurred uploading the album art 2!", onClose: this.errorToast})
                                })
                        }
                        else{
                            this.props.loadingCB(false)
                            this.setState({isLoading: false})
                            Error({message:"An error occurred creating the album!", onClose: this.errorToast})
                        }
                    })
                    .catch(error=>{
                        this.props.loadingCB(false)
                        this.setState({isLoading: false})
                        Error({message:"An error occurred!", onClose: this.errorToast})
                        if(error.response.status === 400){
                            this.setState({albumTitleError: "Album with that title exists!"})
                        }
                        else{
                            this.setState({albumTitleError: error.response.statusText})
                        }

                    })
                }
            })
        }
        else{
            let albumTitleError;
            let albumArtError;
            if(this.state.newAlbumTitle.length < 1){
                albumTitleError = "Album Title Must be Valid"
            }
            if(this.state.albumArt.length < 1){
                albumArtError = "Album Art Must be Valid"
            }
            this.setState({
                albumTitleError: albumTitleError,
                albumArtError: albumArtError
            })
        }

    }
    handleCropUtilityClose = (cropSaved)=>{
        this.setState({isCropUtilityOpen: false})

        if(cropSaved){
            cropSaved()
        }
        else{
        }
    }
    render() {
        return (
            <UserConsumer>
                {({ user, login, loginMessage }) => (
                    <>
                                   {/* {# Modal Header #} */}
            <header className="modal-header">
                <h2 id="create_album_title" className="modal-title">Create Album</h2>
                <button onClick={this.props.handleClose} aria-label="Close Modal" data-micromodal-close="create_album_modal" className="modal-close" type="button"></button>
            </header>

            {/* {# Modal Content #} */}
            <div id="create_album_content" className="modal-content">

                {/* {# Form #} */}
                <form action="" className="ds-form">

                    {/* {# Title #} */}
                    <div className="input-group">
                        <label className="input-label" htmlFor="album_title">Album Title</label>
                        {this.state.albumTitleError &&
                            <div className="text-primary">{this.state.albumTitleError}</div>
                        }
                        <input value={this.state.newAlbumTitle} onChange={this.handleInputChange} name="newAlbumTitle" id="newAlbumTitle" className="form-input" placeholder="Enter album title here..."/>
                    </div>

                    {/* {# Album Artwork #} */}
                    <div className="input-group">
                        <label className="input-label">Album Artwork</label>
                        <div className="grid-row-sm input-group-sm">
                            <div className="col flex flex-none">
                                {this.state.albumPreview ?
                                    <img className="w-120 h-120 sm:w-140 sm:h-140" src={this.state.albumPreview} alt='albumArt'/>:
                                    <div className="w-120 h-120 sm:w-140 sm:h-140" ></div>
                                }
                            </div>
                            <div className="col flex flex-col flex-1 items-start">
                                <p className="mb-15">Images should be at least 1000px wide and be a valid file type (jpg, png, etc.)</p>
                                {this.state.albumArtError &&
                                    <p className="text-primary">{this.state.albumArtError }</p>
                                }
                                <AlbumArtUpload
                                    reupload={this.state.albumArt?.length > 0}
                                    onChange={this.setAlbumArtState}
                                    openPopout={()=>{this.setState({isCropUtilityOpen: true});}}
                                    isCropUtilityOpen={this.state.isCropUtilityOpen}
                                    handleCropUtilityClose={this.handleCropUtilityClose}>
                                </AlbumArtUpload>
                            </div>
                        </div>
                        {/* {# Upload Item #} */}
                        <div className="border-t border-b border-gray-300 py-15">
                            {/* {# Upload States ==> layouts/components/forms/upload-items.html #}
                            {{ uploads.upload_image() }} */}
                        </div>
                    </div>

                    {/* {# Buttons #} */}
                    <div className="input-group pt-20">
                        <div className="grid-row-sm">
                            <div className="col w-1/2 mb-20 sm:mb-0">
                                <button disabled={this.state.isLoading} onClick={this.props.handleClose} className="btn btn-stroke form-btn w-full" type="button">Cancel</button>
                            </div>
                            <div className="col w-1/2">
                                {this.state.newAlbumTitle?.length > 1 && this.state.albumArt?.length > 0 ?
                                    <button disabled={this.state.isLoading} onClick={(e)=>{e.preventDefault();this.submitNewAlbum()}} className="btn btn-primary form-btn w-full">{this.state.isLoading ? <CircularIndeterminantButton  thickness={5.5}/>: "Create"}</button>:
                                    <button disabled onClick={(e)=>{e.preventDefault();}} className="btn btn-primary btn-disabled form-btn w-full">Create</button>
                                }
                            </div>
                        </div>
                    </div>

                </form>
                {/* {# End Form #} */}

            </div>
            {/* {# End Modal Content #} */}
                    </>
                )}</UserConsumer>
        )
    }

}
export default AlbumModal

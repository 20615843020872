import React from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import { UserConsumer } from "../../contexts/User";
import { Error, Success } from "../Toast/index";
import LoadingContainer from "../Loading/LoadingContainer";

// const location = useLocation();

// Permissions which can be accessed by anyone
// TODO create a role-based permissions function
const permissionsPublic = ["access pricing", "access browse"];

const permissionsLoggedIn = ["access user features"];

const permissionsArtist = ["access artist features"];

const permissionsAdmin = ["access admin features"];

function getUserPermissions(user) {
  console.log("getting permissions for user", user);

  let permissions = permissionsPublic;

  if (user) {
    permissions = [...permissions, ...permissionsLoggedIn];
  }
  if (user?.admin === true) {
    permissions = [...permissions, ...permissionsAdmin];
    permissions = [...permissions, ...permissionsArtist];
  }
  if (user?.artist === true) {
    permissions = [...permissions, ...permissionsArtist];
  }

  return permissions;
}

// For this very simple version, we check whether we have a logged in user OR are trying to access a public permission
function userHasPermission(permission, user) {
  // console.log('permissionsPublic', permissionsPublic);
  console.log("checking if user has permission to ", permission);
  if (!user) {
    return false;
  }

  const permissions = getUserPermissions(user);

  console.log("user permissions", permissions);

  const userHasPermission = permissions?.includes(permission);

  console.log(`User ${userHasPermission ? "has" : "does not have"} permission to ${permission}`);

  return userHasPermission;
}

const ProtectedRoute = ({ component: Component, confirmReq, permission, props, ...rest }) => {
  // TODO - Create higher level wrapper component that can sit above ProtectedRoute and Route, since confirmEmail is ProtectedRoute and pricing is Route
  const history = useHistory();
  const location = useLocation();
  const showToast = history?.location?.state?.showToast;
  const showErrorToast = history?.location?.state?.showErrorToast;
  const toastMessage = history?.location?.state?.toastMessage;
  const toastAutoClose = history?.location?.state?.toastAutoClose;

  if (showToast) {
    Success({
      message: toastMessage,
      autoClose: toastAutoClose || 3000,
    });
    location.state.showToast = false;
  }
  if (showErrorToast) {
    Error({ message: toastMessage });
    // this.props.history.location.state.showErrorToast = false
  }

  return (
    <UserConsumer>
      {({ user, userLoaded }) => (
        <>
          {console.log("ProtectedRoute userLoaded", userLoaded)}
          {console.log("ProtectedRoute user", user)}
          <LoadingContainer isLoaded={userLoaded}>
            {confirmReq && userHasPermission(permission, user) ? (
              <Route
                render={(props) =>
                  user.confirmed ? (
                    <Component {...props} user={user} />
                  ) : (
                    <Redirect
                      to={{
                        pathname: `/signup/confirm`,
                      }}
                    />
                  )
                }
                {...rest}
              />
            ) : (
              <Route
                render={(props) =>
                  userHasPermission(permission, user) ? (
                    <Component {...props} user={user} />
                  ) : (
                    <>
                      <Redirect
                        to={{
                          pathname: `/login`,
                          search: `?redirect="${props.location.pathname}${props.location.search}"`,
                        }}
                      />
                    </>
                  )
                }
                {...rest}
              />
            )}
          </LoadingContainer>
        </>
      )}
    </UserConsumer>
  );
};

export default ProtectedRoute;

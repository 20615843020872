import CircularProgress from '@material-ui/core/CircularProgress'
import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import "react-sliding-pane/dist/react-sliding-pane.css"
import { Tooltip } from "react-tippy"
import { FavoritesConsumer } from "../../FavoritesContext/index"
import { PlayerContext } from "../../PlayerContext"
import { UserConsumer } from "../../contexts/User/index"
import LicenseSummary from "../Licenses/licenseSummary"
import { Error, Success } from "../Toast/index"
import LicenseTab from "../Track/licenseTab"
import StemsTab from "../Track/stemsTab"
import DetailsTab from "../Track/tabDetails"
import "../Track/versions.css"
import VersionsTab from "../Track/versionsTab"
import api from "../utils/api"
import Waveform from "../Waveform/trackListBrowse"
import TrackListBrowseNoInteract from "../Waveform/trackListBrowseNoInteract"
import ContentIDTab from './ContentIDTab'
import DownloadTooltip from "./downloadTooltip"
import LicenseTooltip from "./licenseTooltip"

const contentDis = require('content-disposition')

class TrackListItemLicense extends Component {
    state = {
        isDropdown : this.props.initiallyOpen ,
        activeTab: "license",
        track:{},
        versions: [],
        stems: [],
        genres: [],
        speed: "",
        city: "",
        emotion: "",
        moods: [],
        instruments: [],
        attributes: [],
        funFact: "",
        isLicenseModalOpen: false,
        downloadProgress: 0
    }
    resolvePhotoLink=(albumPhotoKey)=>{
        console.log("resolving from ket")
        const key = albumPhotoKey
        const ext = key.split('.').reverse()[0];
        let sizeMod;
        console.log(" width ",window.width)
        if(window.innerWidth > 1280){
            sizeMod = '140x140'
        }
        else if(window.innerWidth > 1020 && window.innerWidth < 1280){
            sizeMod = '140x140'
        }
        else if(window.innerWidth > 760 && window.innerWidth < 1020){
            sizeMod = '140x140'
        }
        else{
            sizeMod = '140x140'
        }
        let final = key.replace('.' + ext, '') + '-minified-'+ sizeMod + '.' + ext
        return final
    }
    componentDidMount = ()=>{
    }
    componentDidUpdate = (prevProps)=>{

        var isEqual = function (value, other) {

            // Get the value type
            var type = Object.prototype.toString.call(value);

            // If the two objects are not the same type, return false
            if (type !== Object.prototype.toString.call(other)) return false;

            // If items are not an object or array, return false
            if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

            // Compare the length of the length of the two items
            var valueLen = type === '[object Array]' ? value.length : Object.keys(value).length;
            var otherLen = type === '[object Array]' ? other.length : Object.keys(other).length;
            if (valueLen !== otherLen) return false;

            // Compare two items
            var compare = function (item1, item2) {

                // Get the object type
                var itemType = Object.prototype.toString.call(item1);

                // If an object or array, compare recursively
                if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
                    if (!isEqual(item1, item2)) return false;
                }

                // Otherwise, do a simple comparison
                else {

                    // If the two items are not the same type, return false
                    if (itemType !== Object.prototype.toString.call(item2)) return false;

                    // Else if it's a function, convert to a string and compare
                    // Otherwise, just compare
                    if (itemType === '[object Function]') {
                        if (item1.toString() !== item2.toString()) return false;
                    } else {
                        if (item1 !== item2) return false;
                    }

                }
            };

            // Compare properties
            if (type === '[object Array]') {
                for (var i = 0; i < valueLen; i++) {
                    if (compare(value[i], other[i]) === false) return false;
                }
            } else {
                for (var key in value) {
                    if (value.hasOwnProperty(key)) {
                        if (compare(value[key], other[key]) === false) return false;
                    }
                }
            }

            // If nothing failed, return true
            return true;

        };
        // console.log("all props ",prevProps.otherTracks, this.props.otherTracks, isEqual(prevProps.otherTracks, this.props.otherTracks))

    }
    toggleDropdown = (e) => {
        this.setState({ isDropdown: !this.state.isDropdown })
    }
    toggleTrackTippy = ()=>{
        this.setState({showTrackTippy: !this.state.showTrackTippy})
    }
    toggleDownloadTippy = ()=>{
        this.setState({showDownloadTippy: !this.state.showDownloadTippy})
    }
    downloadWatermarked = ()=>{
        let header
        if(this.context.user === null){
            this.context.triggerLoginModal()
        }
        else{
        //get the watermarked version of the requested file
            fetch('/api/tracks/downloadWatermarked/'+this.props.trackId)
                .then(resp=> {
                        if(resp.ok){
                            header = resp.headers.get('content-disposition');
                            //read the stream as a blob
                            return resp.blob()
                        }
                        else{
                            throw new Error("Error Downloading File")
                        }
                    })
                .then(blob=>{
                    //create a dummy element to download the file from the blob
                    let downloadElem = document.createElement('a')
                    const url = URL.createObjectURL(blob, {type: "audio/mpeg"})
                    let filename
                    if (header) {
                        const parsed = contentDis.parse(header);

                        if (parsed.parameters && parsed.parameters.filename) {
                            filename =parsed.parameters.filename;
                        }
                    }
                    downloadElem.setAttribute('href', url)
                    downloadElem.setAttribute('download', filename || "filename" )
                    downloadElem.style.display = 'none';
                    console.log(downloadElem)
                    document.body.appendChild(downloadElem);

                    downloadElem.click();
                    URL.revokeObjectURL(url)
                    document.body.removeChild(downloadElem);
                })
                .catch(err=>Error({message: "Error Downloading File"}));
        }
        this.setState({showTrackTippy: false})
    }
    shareTrack = ()=>{
        var textArea = document.createElement("textarea");

        // Place in top-left corner of screen regardless of scroll position.
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em';
        textArea.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = 0;

        // Clean up any borders.
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';

        // Avoid flash of white box if rendered for any reason.
        textArea.style.background = 'transparent';


        textArea.value = `${window.location.protocol}//${window.location.host}/tracks/${this.props.trackURL}`;

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          var successful = document.execCommand('copy');
          if(successful){
              Success({message: "Track URL Copied to Clipboard"})
          }
          else{
              Error({message: "Couldn't Copy Track URL "})
          }
          var msg = successful ? 'successful' : 'unsuccessful';
          console.log('Copying text command was ' + msg);
        } catch (err) {
          console.log('Oops, unable to copy');
        }

        document.body.removeChild(textArea);

        this.setState({showTrackTippy: false})

    }
    play = ()=>{
        console.log("playing ", this.context.mediaPlayer.idKey, this.props.idKey)
        if(this.context.mediaPlayer.idKey !== this.props.idKey){
            if(this.props.playCB){
                console.log(this.props.idKey)
                this.props.playCB(this.props.idKey)
            }
            else{
                let track= {trackId: this.props.trackId, idKey: this.props.idKey}
                this.context.loadTrackInMediaPlayer(this.props.trackId, this.props.idKey);
                this.context.setAutoPlay(false)
                this.context.setProjectId(null)
                this.context.setCollectionId(null)
                if(this.props.otherTracks?.length > 0){
                    console.log('setting playlist to ', this.props.otherTracks)
                    this.context.setPlaylist(this.props.otherTracks)
                }
            }
        }
        else{
            this.context.playPause()
        }
    }
    handleLicenseModalOpen=()=>{
        this.setState({isLicenseModalOpen: true})
    }
    handleLicenseModalClose=()=>{
        this.setState({isLicenseModalOpen: false})
    }
    downloadFullOnly=(licenseId)=>{
        this.setState({isDownloading: true})
        let filename = this.props.artistName + "_" + this.props.trackTitle + "_Full.wav"

        api.downloadMainTrack(licenseId, this.progressCB)
            .then(res=>{
                console.log("down res" ,res)

                if(res.data?.url){
                    console.log("down res" ,res)
                    let downloadEl = document.createElement("a")
                    downloadEl.setAttribute("href", res.data.url)
                    downloadEl.setAttribute("download", res.data.filename.split(" ").join(""))
                    downloadEl.style.display = 'none'
                    document.body.appendChild(downloadEl);
                    downloadEl.click();
                    console.log("download el ",downloadEl)
                    this.setState({isDownloading: false})
                }

            })
            .catch(err=>{
                console.log("error ", err)
                this.setState({isDownloading: false})
            })
    }
    downloadFullAndVersions=(licenseId)=>{
        this.setState({isDownloading: true})
        let filename = this.props.artistName + "_" + this.props.trackTitle + ".zip"
        api.downloadAllVersions(licenseId)
            .then(res=>{
                if(res.data?.url){
                    console.log("down res" ,res)
                    let downloadEl = document.createElement("a")
                    downloadEl.setAttribute("href", res.data.url)
                    downloadEl.setAttribute("download", res.data.filename.split(" ").join(""))
                    downloadEl.style.display = 'none'
                    document.body.appendChild(downloadEl);
                    downloadEl.click();
                    console.log("download el ",downloadEl)
                    this.setState({isDownloading: false})
                }
            })
            .catch(err=>{
                console.log("error ", err)
                this.setState({isDownloading: false})
            })
    }

    downloadStems=(licenseId)=>{
        this.setState({isDownloading: true})
        let filename = this.props.artistName + "_" + this.props.trackTitle + ".zip"
        api.downloadAllStems(licenseId)
            .then(res=>{
                if(res.data?.url){
                    console.log("down res" ,res)
                    let downloadEl = document.createElement("a")
                    downloadEl.setAttribute("href", res.data.url)
                    downloadEl.setAttribute("download", res.data.filename.split(" ").join(""))
                    downloadEl.style.display = 'none'
                    document.body.appendChild(downloadEl);
                    downloadEl.click();
                    console.log("download el ",downloadEl)
                    this.setState({isDownloading: false})
                    document.body.removeChild(downloadEl);
                }
            })
            .catch(err=>{
                console.log("error ", err)
                this.setState({isDownloading: false})
            })
    }

    downloadAll= async (licenseId,)=>{
        this.setState({isDownloading: true})
        let filename = this.props.artistName + "_" + this.props.trackTitle + ".zip"
        api.downloadEverything(licenseId)
            .then(res=>{
                if(res.data?.url){
                    console.log("down res" ,res)
                    let downloadEl = document.createElement("a")
                    downloadEl.setAttribute("href", res.data.url)
                    downloadEl.setAttribute("download", res.data.filename.split(" ").join(""))
                    downloadEl.style.display = 'none'
                    document.body.appendChild(downloadEl);
                    downloadEl.click();
                    console.log("download el ",downloadEl)
                    this.setState({isDownloading: false})
                    document.body.removeChild(downloadEl);
                }
            })
            .catch(err=>{
                console.log("error ", err)
                this.setState({isDownloading: false})
            })
    }
    progressCB = (progressData)=>{
        console.log(progressData)
        this.setState({downloadProgress: Math.floor(100 * (progressData.loaded/progressData.total))})
    }

    openLicensePDF = () => {
        window.open(this.props.signedLicensePDFURL);
    }

    render(){
        return(
            <UserConsumer>
                {({user, triggerLoginModal})=>
            <FavoritesConsumer>
                {({artists, tracks, albums, collections, likeTrack, unlikeTrack, followArtist, unfollowArtist})=>(
        <div key={this.props.idKey} style={this.props.status === "ach failed" ? {"border-color":"red", "border-style": "solid", "border-width": "2px"}: {}} className={`track-list-card${this.state.isDropdown ? ' dropdown-open' : ''}${((this.context.mediaPlayer.idKey?.indexOf("-license-"+this.props.id) || -1 ) !== -1 && this.context.mediaPlayer?.isTrackPlaying) ? " is-playing": ""}`}>
            <div className={`track-first-col`}>
                {this.props.listType == "album" ?
                    <div className="track-pre">
                        {/* {# Track Number #} */}
                        <div className="track-number">{this.props.index + 1}</div>
                        {/* {# Track Hover #} */}
                        <div className="track-hover">
                            <button
                                onClick={this.play}
                                className="circle-icon-border circle-icon-sm"
                            >
                                <span
                                    className={`${(this.context.mediaPlayer.idKey?.indexOf("-license-"+this.props.id) || -1 ) !== -1  && this.context.mediaPlayer.isTrackPlaying  ? 'icon-pause' :'icon-play' }`}
                                >
                                </span>
                            </button>
                        </div>
                    </div>
                :
                    <div onClick={this.play}  className="track-image">
                        <img src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolvePhotoLink(this.props.albumArt)}`} alt="albumArt"/>
                    </div>
                }
                <div className="track-title-artist">
                    <NavLink to={"/tracks/" + this.props.trackURL}>
                        <span className="track-title">{this.props.trackTitle}</span>
                    </NavLink>
                    {this.props.listType !== "album" &&
                        <NavLink to={"/artists/" + this.props.artistURL}>
                            <span className="track-artist">{this.props.artistName}</span>
                        </NavLink>
                    }
                    {this.props.status === "ach pending" &&<div>(pending)</div>}
                    {this.props.status === "ach failed" && <div className="error-message mt-0">(payment failed)</div>}
                </div>
                <button className={`circle-icon circle-icon-sm icon-chevron-down${this.state.isDropdown ? ' dropdown-open' : ''}`} onClick={this.toggleDropdown}></button>
            </div>
            <div class="track-license text-gray-400">
                <a href={"/dashboard/projects/edit/" + this.props.projectId} class="text-link-white">
                    {this.props.projectName}
                </a>
                <span title={"License Summary"}>
                    <LicenseSummary license={this.props.license} />
                </span>
            </div>
            <div className="track-last-col">
                <div className="track-wave-time flex items-center">
                    <span className={`icons-stems-versions text-gray-400 icon${this.props.stems?.length ? "-stems":""}${this.props.versions?.length ? "-versions":""}`}></span>
                    <div className="wave-form-vector">
                        {this.props.peaks &&
                        <div>
                            {(this.context.mediaPlayer.idKey?.indexOf("-license-"+this.props.id) || -1 ) !== -1 ?
                                <Waveform idKey={this.props.idKey}   trackId={this.props.trackId} s3Key={this.props.wavURL} peaks={this.props.peaks} />:
                                <TrackListBrowseNoInteract idKey={this.props.idKey}  trackId={this.props.trackId} s3Key={this.props.wavURL} peaks={this.props.peaks} />
                            }
                        </div>
                        }
                    </div>
                    <span className="track-duration">{this.props.duration}</span>
                </div>
                <div className="track-list-icons text-gray-400">
                    {this.props.signedLicensePDFURL && (
                        <button
                            disabled={this.props.status === "ach pending" || this.props.status === "ach failed"}
                            class="track-subscription" type="button"
                            onClick={this.openLicensePDF}
                        >
                            <span class={`icon-checklist ${this.props.status === "ach pending" || this.props.status === "ach failed" ? "cursor-not-allowed" : ""} `}></span>
                        </button>
                    )}
                    {this.state.isDownloading ?
                        <div className="track-download">
                            <CircularProgress
                                style={{color: "#5F5D5D"}}
                                size={"1.5rem"}
                                // value={this.state.downloadProgress}
                                variant={"indeterminate"}
                            />
                        </div>:
                          <button
                            id="trackToolTip"
                            className="track-download"
                            onClick={this.toggleDownloadTippy}
                            data-tippy-html="#trackToolTip"
                            data-tippy-placement="bottom-end"
                            type="button"
                            disabled={this.props.status === "ach failed"}
                          >
                            <Tooltip
                              arrow={false}
                              open={this.props.status === "ach failed" ? false :this.state.showDownloadTippy}
                              interactive={true}
                              position={'bottom-end'}
                              trigger={'click'}
                              // hideOnClick={true}
                              useContext={true}
                              html={(<DownloadTooltip
                                      licenseId={this.props.id}
                                      toggleDownloadTippy={this.toggleDownloadTippy}
                                      hasVersions={this.props.versions?.length > 0}
                                      hasStems={this.props.stems?.length > 0}
                                      downloadFullOnly={this.downloadFullOnly}
                                      downloadFullAndVersions={this.downloadFullAndVersions}
                                      downloadStems={this.downloadStems}
                                      downloadAll={this.downloadAll}
                                      >
                                  </DownloadTooltip>
                              )}
                              allowHTML={true}
                              onUntrigger={()=>{this.state.showDownloadTippy ? this.setState({showDownloadTippy: false}): this.setState({showDownloadTippy: true})}}
                              onRequestClose={()=>{this.state.showDownloadTippy ? this.setState({showDownloadTippy: false}): this.setState({showDownloadTippy: true})}}
                            >

                                <span className={`icon-download ${this.props.status === "ach failed" ? "cursor-not-allowed": ""}`}></span>
                            </Tooltip>
                          </button>
                    }
                    {console.log('all props', this.props)}
                    <Tooltip
                            arrow={false}
                            open={this.props.status === "ach failed" ? false :this.state.showTrackTippy}
                            interactive={true}
                            position={'bottom-end'}
                            trigger={'click'}
                            // hideOnClick={true}
                            useContext={true}
                            html={(<LicenseTooltip
                                    trackId={this.props.trackId}
                                    licenseId={this.props.id}
                                    licenseNumber={this.props.licenseNumber}
                                    license={this.props.license}
                                    licenseSummary={<LicenseSummary license={this.props.license} />}
                                    signedLicensePDFURL={this.props.signedLicensePDFURL}
                                    hostedInvoiceURL={this.props.hostedInvoiceURL}
                                    downloadReceipt={this.downloadReceipt}
                                    shareTrack={this.shareTrack}
                                    toggleTrackTippy={this.toggleTrackTippy}
                                    >
                                </LicenseTooltip>
                            )}
                            allowHTML={true}
                            onUntrigger={()=>{this.state.showTrackTippy ? this.setState({showTrackTippy: false}): this.setState({showTrackTippy: true})}}
                            onRequestClose={()=>{this.state.showTrackTippy ? this.setState({showTrackTippy: false}): this.setState({showTrackTippy: true})}}
                        >
                        <button
                            id="trackToolTip"
                            className="track-list-more"
                            onClick={this.toggleTrackTippy}
                            data-tippy-html="#trackToolTip"
                            data-tippy-placement="bottom-end"
                            type="button"
                            disabled={this.props.status === "ach failed"}
                        >
                            <span className={`icon-dots-vert ${this.props.status === "ach failed" ? "cursor-not-allowed": ""}`}></span>
                        </button>
                    </Tooltip>
                </div>
            </div>

            <div class="track-dropdown">
                <div class="track-dropdown-content">
                    <div class="tabs tabs-sm">
                        <ul className="tab-items">
                            <li onClick={()=>{this.setState({activeTab: "license"})}} className={`tab-item ${this.state.activeTab === "license" && 'active'}`}>
                                <a href="# " className="tab-item-title">License</a>
                            </li>
                            {(this.state.versions.length > 0 || this.props.versions?.length >0) &&
                                <li onClick={()=>{this.setState({activeTab: "versions"},()=>this.context.getVersionInfo(this.props.trackId))}} className={`tab-item ${this.state.activeTab === "versions" && 'active'}`}>
                                    <a href="# " className="tab-item-title">Versions</a>
                                </li>
                            }
                            {(this.state.stems.length > 0 || this.props.stems?.length > 0) &&
                                <li onClick={()=>{this.setState({activeTab: "stems"},()=>this.context.getStemInfo(this.props.trackId))}} className={`tab-item ${this.state.activeTab === "stems" && 'active'}`}>
                                    <a href="# " className="tab-item-title">Stems</a>
                                </li>
                            }
                            <li onClick={()=>{this.setState({activeTab: "details"})}} className={`tab-item ${this.state.activeTab === "details" && 'active'}`}>
                                <a href="# " className="tab-item-title">Details</a>
                            </li>
                            <li
                              className={`tab-item ${this.state.activeTab === "contentID" && 'active'}`}
                            >
                              <button
                                className="tab-item-title"
                                onClick={() => {
                                  this.setState({ activeTab: "contentID" })
                                }}
                              >
                                Content ID
                              </button>
                            </li>

                            <li class="tab-item tab-item-end hidden md:flex">
                                <NavLink to={"/tracks/" + this.props.trackURL} className="tab-item-title">
                                    View Track Page
                                </NavLink>
                            </li>
                        </ul>
                        <div class="tab-content">
                            {/* License */}
                            {this.state.activeTab === "license" &&
                                    <LicenseTab
                                        status={this.props.status}
                                        orderDate={this.props.orderDate}
                                        licenseNumber={this.props.licenseNumber}
                                        licenseNumberDisplay={this.props.licenseNumberDisplay}
                                        license={this.props.license}
                                    />
                            }
                            {/* {# Versions #} */}
                            {this.state.activeTab === "versions" && (
                                <VersionsTab
                                    currentTime={this.state.currentTime}
                                    changeTrack={this.changeVersion}
                                    versions={this.props.versions.map(version=>version.id)}
                                    trackTitle={this.props.trackTitle}
                                    artistName={this.props.artistName}
                                    imageURL={this.props.imageURL}
                                    licenseId={this.props.id}
                                    artistId={this.props.artistId}
                                    albumId={this.props.albumId}
                                    trackId={this.props.trackId}
                                    isLicensesPage={true}
                                />
                            )}

                            {/* {# Stems #} */}
                            {this.state.activeTab === "stems" && (
                                <StemsTab
                                    currentTime={this.state.currentTime}
                                    changeTrack={this.changeStem}
                                    stems={this.props.stems.map(stem=>stem.id)}
                                    trackTitle={this.props.trackTitle}
                                    artistName={this.props.artistName}
                                    imageURL={this.props.imageURL}
                                    licenseId={this.props.id}
                                    artistId={this.props.artistId}
                                    albumId={this.props.albumId}
                                    trackId={this.props.trackId}
                                    isLicensesPage={true}
                                />
                            )}
                            {/* {# Details #} */}
                            {this.state.activeTab === "details" && <DetailsTab genres={this.props.tags.filter(tag=>tag.type===1)}  emotion={this.props.tags.filter(tag=>tag.type===2)} moods={this.props.tags.filter(tag=>tag.type===3)} attributes={this.props.tags.filter(tag=>tag.type===4)}
                               instruments={this.props.tags.filter(tag=>tag.type===5)} city={this.props.tags.filter(tag=>tag.type===6)} lyrics={this.props.tags.filter(tag=>tag.type===7)}   speed={this.props.tags.filter(tag=>tag.type===8)} />}


                            {this.state.activeTab === "contentID" && (
                              <ContentIDTab
                                licenseNumberDisplay={this.props.licenseNumberDisplay}
                              />
                            )}
                        </div>

                    </div>
                </div>
            </div>
        </div>)}
        </FavoritesConsumer>
    }</UserConsumer>

        )
    }
}

TrackListItemLicense.contextType = PlayerContext
export default TrackListItemLicense

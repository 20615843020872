import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config";
const tw = resolveConfig(tailwindConfig);
const customStylesMulti = {
    option: (provided, state) => {
      
      return {
        // ...provided,
        borderBottom: "1px solid hsla(0, 0%, 100%, .05)",
        backgroundColor: tw.theme.colors.gray[200],
        padding: tw.theme.spacing[15] + " " + tw.theme.spacing[20],
        transition: "color 0.3s",
        cursor: state.isDisabled ? "default" : "pointer",
        color:
          state.isSelected || state.isFocused
            ? tw.theme.colors.white || state.isDisabled
            : tw.theme.colors.gray[400]
      };
    },
    control: (provided, state) => {
      return {
        ...provided,
        border: "none",
        backgroundColor: tw.theme.colors.gray[200],
        minHeight: tw.theme.spacing[60],
        cursor: "pointer",
        borderRadius: "none",
        boxShadow: 0
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        marginTop: "0",
        marginBottom: "0",
        backgroundColor: tw.theme.colors.gray[200],
        position: "absolute",
        boxShadow: tw.theme.boxShadow.lg,
        borderRadius: "none",
        zIndex: 9999
      };
    },
    menuList: (provided, state) => {
      return {
        ...provided,
        scrollbarColor: "hsla(0, 0%, 100%, .2) transparent",
        scrollbarWidth: "thin",
        "::-webkit-scrollbar": {
          width: "0.375rem"
        },
        "::-webkit-scrollbar-track": {
          backgroundColor: "transparent"
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "hsla(0, 0%, 100%, .2)",
          borderRadius: tw.theme.borderRadius.full
        }
      };
    },
    input: (provided, state) => {
      //   
      return {
        // ...provided,
        color: tw.theme.colors.white,
        lineHeight: tw.theme.lineHeight.snug
      };
    },
    multiValue: (provided, state) => {
      //   
      return {
        ...provided,
        height: tw.theme.spacing[30],
        borderRadius: 0,
        alignItems: "center",
        border: "solid 2px " + tw.theme.colors.gray[300],
        marginLeft: "0",
        marginTop: tw.theme.spacing[3],
        marginBottom: tw.theme.spacing[3],
        marginRight: tw.theme.spacing[5],
        backgroundColor: "transparent"
      };
    },
    multiValueLabel: (provided, state) => {
      //   
      return {
        ...provided,
        display: "flex",
        alignItems: "center",
        borderRadius: 0,
        color: tw.theme.colors.white,
        fontSize: tw.theme.fontSize.xs,
        fontWeight: tw.theme.fontWeight.bold,
        letterSpacing: tw.theme.letterSpacing.wider,
        margin: "0",
        paddingLeft: tw.theme.spacing[10],
        paddingRight: "0",
        paddingTop: "0.125rem",
        paddingBottom: "0",
        lineHeight: tw.theme.lineHeight.snug,
        backgroundColor: "transparent",
        textTransform: "uppercase"
      };
    },
    multiValueRemove: (provided, state) => {
      //   
      return {
        //...provided,
        width: "auto",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingLeft: tw.theme.spacing[5],
        paddingRight: tw.theme.spacing[5],
        borderRadius: 0,
        color: tw.theme.colors.gray[400],
        cursor: "pointer",
        transition: "color 0.3s",
        backgroundColor: "transparent",
        ":hover": {
          color: tw.theme.colors.white
        }
      };
    },
    placeholder: (provided, state) => {
      return {
        ...provided,
        color: tw.theme.colors.gray[400],
        margin: "0",
        display: "flex"
      };
    },
    valueContainer: (provided, state) => {
      //   
      return {
        ...provided,
        alignSelf: "stretch",
        padding: tw.theme.spacing[10] + " " + tw.theme.spacing[20],
        backgroundColor: "#1c1c1c"
      };
    },
    clearIndicator: (provided, state) => {
      //   
      return {
        ...provided,
        color: tw.theme.colors.gray[400],
        cursor: "pointer",
        backgroundColor: "transparent",
        padding: "0 " + tw.theme.spacing[15],
        ":hover": { color: "white" }
      };
    },
    dropdownIndicator: (provided, state) => {
      //   
      return {
        display: "none"
      };
    },
    indicatorSeparator: (provided, state) => {
      return {
        ...provided,
        width: "0",
        height: "0"
      };
    },
    indicatorsContainer: (provided, state) => {
      //   
      return {
        ...provided,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      };
    }
  };
export default customStylesMulti  
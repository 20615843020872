import React from "react";
import PrivacyPolicy from "../Links/privacyPolicy";
import LicenseTerms from "../Links/licenseTerms";

function SiteTerms() {
  return (
    <div>
      <h6 className="mb-40">Last updated on 09/22/2023</h6>
      <h6 className="pt-10 pb-20">1. Introduction</h6>
      <p>
        Thank you for your interest in the Deep Sounds Platform. The Deep Sounds Platform is one of
        the services, websites and software applications (collectively, &ldquo;Services&rdquo;)
        offered by Deep Sounds, LLC (&ldquo;Deep Sounds,&rdquo; &ldquo;us,&rdquo; &ldquo;our&rdquo;
        or &ldquo;we&rdquo;) to provide users (&ldquo;you&rdquo; or &ldquo;your&rdquo;) with access
        to a variety of music and sound recording licenses. All Services are offered pursuant to
        terms and conditions of the Agreements, as defined herein. These terms of service
        (&ldquo;Deep Sounds Terms&rdquo;) shall be deemed accepted by you effective on the earlier
        of the date that you click the &ldquo;Start Listening&rdquo; button presented with the Deep
        Sounds Terms or that you begin use of the Services (the &ldquo;Effective Date&rdquo;). If
        you do not accept the Deep Sounds Terms, do not click the &ldquo;Start Listening&rdquo;
        button and do not use the Services.
      </p>
      <h6 className="pt-10 pb-20">2. Using Deep Sounds Content</h6>
      <p>
        The Deep Sounds Platform (&ldquo;Platform&rdquo;) is a web-based marketplace providing the
        Services at www.deepsounds.com, or any successor website address(es), at which Deep Sounds
        acts as the representative of an Artist (as defined below) to offer licenses of musical
        compositions and sound recordings (a &ldquo;Track&rdquo;) to Deep Sounds&rsquo; customers
        who wish to purchase such licenses (&ldquo;Licensee Users&rdquo;) under terms and conditions
        established by Deep Sounds, in its sole and exclusive discretion.
      </p>
      <p>
        You must purchase and generate an appropriate license to use or publish a Track. You may
        purchase licenses offered by Deep Sounds either on a one-time basis or, if eligible, through
        a Deep Sounds Subscription. Purchases and generations of licenses on a one-time basis and
        through Deep Sounds subscriptions require you to create a Deep Sounds account, and your
        review and acceptance of these Deep Sounds Terms, the{" "}
        <PrivacyPolicy underline>Deep Sounds Privacy Policy</PrivacyPolicy>, and the{" "}
        <LicenseTerms underline>License Terms</LicenseTerms> for licenses that you purchase and/or
        generate (individually and collectively, the &ldquo;Agreements&rdquo;), all of which are
        incorporated herein. The Agreements form a legal agreement between you and Deep Sounds and
        provide important information about the Platform and we urge you to read them carefully. By
        accepting the terms of the Agreements, you acknowledge that you have read, understood, and
        had the opportunity to review the Agreements with an attorney, and that you agree to be
        bound by them. In the event of any conflict between these Deep Sounds Terms and any other
        part of the Agreements, these Deep Sounds Terms shall govern and control your rights and
        obligations.
      </p>
      <h6 className="pt-10 pb-20">3. License Options</h6>
      <p>
        You may purchase a license from Deep Sounds on either a one-time basis or generate a license
        through a Deep Sounds subscription. Previews of Tracks provided in conjunction with a
        non-subscriber account are water-marked; previews of Tracks provided in conjunction with a
        subscriber account are not water-marked. All previews of Tracks, regardless of whether the
        Track preview is water-marked, are for evaluation purposes only and may not be published or
        used for any other purpose.
      </p>
      <div className="pl-30">
        <ol className="my-20">
          <li>
            <strong>a. One-Time Basis Licenses</strong>
          </li>
        </ol>
        <p>
          If you choose to purchase licenses on a one-time basis, you will provide certain
          information about the licensee and the usage to Deep Sounds to determine the appropriate
          type of license that you wish to purchase. The terms and cost of that license will be
          provided to you on the Platform and you may then choose whether to proceed with your
          purchase of the license you have selected, subject to the terms of the applicable{" "}
          <LicenseTerms underline>License Terms</LicenseTerms>. All free accounts are limited to
          purchases of licenses on a one-time basis.
        </p>

        <ol className="my-20">
          <li>
            <strong>b. Deep Sounds Subscriptions</strong>
          </li>
        </ol>

        <p>
          If you plan on purchasing several licenses from Deep Sounds, you may also choose to
          purchase an annual subscription that will enable you to generate licenses to meet your
          licensing needs. A subscription allows you an unlimited number of licenses for existing
          project needs for the coverage that you select, subject to the terms and conditions of the
          Agreements. As a subscriber, you will receive access to non-watermarked 128kbps mp3
          preview downloads for Tracks, Versions, and Stems and priority customer support, which
          will place your support request above requests made by non-subscribers in the support
          queue. At the time that you purchase a subscription, you must choose the type of
          subscription, your billing plan (if applicable), and provide Deep Sounds with a current,
          valid method of payment acceptable to Deep Sounds (&ldquo;Method of Payment&rdquo;)
          enabling Deep Sounds to bill all subscription fees to your Method of Payment. You further
          agree, warrant and represent that all information that you provide to Deep Sounds about
          you and/or your business is accurate and complete, and you authorize Deep Sounds to share
          all such information and transaction information related to your use of the payment
          processing services provided by Stripe as a condition of Deep Sounds enabling payment
          processing services through Stripe.Your subscription, download, or use of a Track is not
          and shall not constitute a license to any particular Track and does not eliminate the
          requirement that you generate a valid license to use a Track; your subscription provides
          you access to generate licenses within the parameters of your selected subscription plan.
          As a subscriber, you must generate a license for a particular Track to have the right to
          use that Track within the parameters of your subscription and within the scope of the
          license you have chosen for that Track, and all licenses are generated at the time that
          the specific Track is selected, added to your cart, and you proceed with checking out the
          license of the Track in accordance with the terms of your subscription. In the event that
          you have not generated a valid license for a Track at the time that your subscription
          ends, you will not be able to use that Track. You agree that during your subscription, you
          may generate a license within your selected coverage only for a bona fide project that
          exists at the time that you generate the license and that you identify at the time you
          generate the license. Generating licenses in bulk that are not intended for use with an
          existing project is strictly prohibited. Deep Sounds reserves and shall have the right, in
          its sole and exclusive discretion, to investigate any suspected bulk license generation
          and to suspend and/or terminate any account and/or subscription of any subscriber
          suspected of violating this provision or any other provision of the Agreement.
        </p>
        <p>
          There are two types of Deep Sounds subscription plans: (a) Personal Plans or (b)
          Commercial Plans. Personal Plans offer licenses to individuals and for personal,
          household, and/or family uses only, and do not provide licenses for business entities or
          organizations or for any commercial use. Personal Plans may not be used to purchase
          licenses for the Track for the promotion or advertising of a brand or business. All
          licenses purchased through a Personal Plan subscription shall be governed by the terms and
          limitations of the license purchased, and may include limits on the maximum number of
          subscribers to or followers of the licensee&rsquo;s page where the licensed Track is used.{" "}
          <strong>
            By choosing a Personal Plan, you warrant and represent to Deep Sounds that you are not a
            business entity or organization and that the use of any Track licensed pursuant to the
            Personal Plan shall be for personal, household, or family use only and not for any
            business and/or commercial purpose.{" "}
          </strong>
          Notwithstanding the foregoing, advertising revenue earned in conjunction with the display
          of a video incorporating a Track on a platform such as YouTube shall not render an
          otherwise non-commercial use to be commercial, provided that the primary purpose of any
          such video, as determined in Deep Sounds&rsquo; sole and exclusive discretion, is not the
          promotion or advertising of a product or service.
        </p>
        <p>
          If you are a business entity or organization, including a non-profit company or
          organization, or if you require a license for a business or commercial purposes of any
          sort, and you wish to purchase a Deep Sounds subscription, you must purchase a Commercial
          Plan. When you purchase a Commercial Plan, Deep Sounds will require that you provide
          certain information, such as the type of entity purchasing the license, the size of the
          licensing entity, the type of coverage that you want in the license, and other information
          that Deep Sounds may request to determine the appropriate subscription fee.{" "}
          <strong>
            If you provide any false information or make any misrepresentation with regard to your
            use of the Track or provide any false information when purchasing or generating a
            license, Deep Sounds may, in its sole and exclusive discretion, suspend or terminate
            your account and/or subscription, any and all licenses generated by you shall be void
            and ineffective, and you will not be entitled to a refund of any subscription fees paid
            to Deep Sounds.
          </strong>
        </p>

        <ol className="my-20">
          <li>
            <strong>
              c. Purchases of Licenses partially covered or not covered under subscription plan
            </strong>
          </li>
        </ol>
        <p>
          In the event that you wish to purchase a license with terms that are partially, but not
          fully, covered by the scope of your existing subscription, you may do so by purchasing the
          license on a one-time basis. Licenses outside the scope of your existing subscription will
          be priced based upon the difference between the one-time purchase price of the license
          partially covered by your subscription and the one-time purchase price of the license you
          wish to purchase. Deep Sounds paid subscribers will also receive a ten percent (10%)
          discount if they have the Personal Plan or a twenty five percent (25%) discount if they
          have a Commercial Plan on the purchase of the license outside the scope of coverage of
          their existing subscriptions.
        </p>

        <ol className="my-20">
          <li>
            <strong>d. Personal and Commercial Subscription Coverage for Music Licenses</strong>
          </li>
        </ol>
        <p>
          Personal plans will allow you to generate an unlimited number of{" "}
          <strong>Personal Music Licenses </strong>as defined in our{" "}
          <LicenseTerms underline>License Terms</LicenseTerms> during your active subscription term.
        </p>
        <p>
          Commercial plans will allow you to generate an unlimited number of{" "}
          <strong>Commercial Plus Music Licenses </strong>as defined in our{" "}
          <LicenseTerms underline>License Terms</LicenseTerms> during your active subscription term.
        </p>
      </div>
      <a className="offset-anchor" name="subscriptions-autorenewals" />
      <h6 className="pt-10 pb-20">4. Subscriptions and Auto-Renewals</h6>
      <p>
        <strong>
          Deep Sounds Personal and Commercial subscriptions may be purchased for a period of either
          one (1) month or twelve (12) months and will automatically renew for successive (1) month
          or one (12) month periods respectively, unless and until you cancel automatic renewal as
          provided herein.
        </strong>
        At the time that you purchase a subscription, Deep Sounds will provide you with the
        then-applicable subscription cost. Deep Sounds may, in its sole and exclusive discretion,
        offer Commercial Plans under different billing plans, as set forth more fully below.
      </p>
      <p>
        <strong>
          SUBSCRIPTIONS TO ALL PERSONAL PLANS AND ALL COMMERCIAL PLANS, REGARDLESS OF WHETHER YOUR
          COMMERCIAL PLAN WAS PREPAID IN FULL OR IS PAID MONTHLY, SHALL AUTOMATICALLY RENEW AT THE
          THEN-CURRENT RATE APPLICABLE TO YOUR PLAN, AND SHALL NOT BE RENEWED AT ANY PROMOTIONAL
          RATE EXTENDED TO THE PRIOR SUBSCRIPTION PERIOD, UNLESS YOU CANCEL YOUR PLAN AS PROVIDED IN
          THIS DEEP SOUNDS TERMS. YOU MAY CANCEL THE AUTOMATIC RENEWAL OF YOUR APPLICABLE
          SUBSCRIPTION AT ANY TIME DURING YOUR SUBSCRIPTION TERM BY GOING INTO YOUR ACCOUNT SETTINGS
          AND CLICKING THE EDIT BUTTON AND TOGGLE AUTO-RENEW TO OFF. YOU ACKNOWLEDGE AND AGREE THAT
          UNLESS YOU FOLLOW THE PROCEDURE SET FORTH IN THESE DEEP SOUNDS TERMS TO TURN OFF
          AUTO-RENEWAL, AT THE END OF YOUR CURRENT SUBSCRIPTION TERM (INCLUDING BUT NOT LIMITED TO
          ANY RENEWALS THEREOF) YOUR ACCOUNT WILL AUTOMATICALLY RENEW AT THE THEN-CURRENT
          SUBSCRIPTION RATE.
        </strong>
      </p>
      <h6 className="pt-10 pb-20">5. Billing</h6>
      <p>
        Deep Sounds may offer payments for Personal and Commercial Plans with the following billing
        options: (i) prepaying in full for the entire 12-month subscription period at the
        then-current rate applicable for pre-payment; or (ii) authorizing Deep Sounds to charge you
        monthly at the then-current rate applicable for monthly payments. By purchasing a Deep
        Sounds subscription, you hereby authorize Deep Sounds to charge you for your (i) prepaid
        annual plan or (ii) monthly payment plan and continue to charge you monthly at the monthly
        payment plan rate for each month, as applicable and at the then-current rate, plus any and
        all other applicable charges, including but not limited to taxes and transaction fees,
        according to your selected Method of Payment.
      </p>
      <p>
        All prepaid annual plans will be billed in full to the Method of Payment at the inception of
        the applicable subscription and on successive subscription terms.
      </p>
      <p>
        All monthly payment plans will be billed at the monthly rate to the Method of Payment at the
        inception of the applicable subscription and each month thereafter of the same calendar day
        as the day of inception of your subscription. In the event that your subscription began on a
        day that is not included in another month, then Deep Sounds will bill your Method of Payment
        on the day prior to the excluded date, For example, if your subscription began March 31,
        your next billing would occur on April 30.
      </p>
      <p>
        All payment processing services for Deep Sounds (including but not limited to subscriptions
        and single license purchases) are provided by Stripe and are subject to the
        <a
          href="https://stripe.com/connect-account/legal"
          target="_blank"
          rel="noopener noreferrer"
        >
          Stripe Connected Account Agreement
        </a>
        and the
        <a href="https://stripe.com/legal" target="_blank" rel="noopener noreferrer">
          Stripe Terms of Service
        </a>
        (collectively, the &ldquo;Stripe Agreements&rdquo;). By agreeing to the Deep Sounds Terms or
        using the Services, you agree to be bound by the Stripe Agreements, as the same may be
        modified by Stripe from time to time
      </p>
      <p>
        All payments, regardless of payment plan, are{" "}
        <strong>NONREFUNDABLE AND DEEP SOUNDS WILL NOT ISSUE PARTIAL REFUNDS OR CREDITS</strong>.
      </p>
      <a className="offset-anchor" name="cancellations-terminations" />
      <h6 className="pt-10 pb-20">6. Cancellations and Terminations</h6>
      <p>
        Personal Plans and Commercial Plans shall remain in effect for the entirety of the
        applicable one (1) month or twelve (12) months, and{" "}
        <strong>
          YOU SHALL REMAIN LIABLE FOR PAYMENT IN FULL OF ALL COSTS, FEES AND CHARGES INCURRED FOR
          USE DURING THE ENTIRETY OF THE APPLICABLE BILLING PERIOD.
        </strong>
        You may cancel the automatic renewal of your subscription plan at any time during your
        subscription during your subscription term as provided in these Deep Sounds Terms. Except in
        the case of a plan upgrade, Deep Sounds will not provide refunds, credits, or prorate
        charges for any subscription period or for any unused licenses. If your subscription ends
        and you have chosen not to automatically renew the subscription, your account will
        automatically convert to a free account subject to the terms and conditions of the
        Agreements, and all future licenses must be purchased on a one-time basis unless you
        purchase a new subscription.
      </p>
      <p>
        Deep Sounds may, at it&rsquo;s sole and exclusive discretion, terminate your use of the
        Services and/or deactivate your account if we determine that you have (i) violated or acted
        inconsistently with any terms of the Agreements; (ii) failed to make any payment when due;
        (iii) infringed on the intellectual property rights of any third party, including but not
        limited to any Deep Sounds artist. You agree that Deep Sounds termination of your use of the
        Service or deactivation of your account may be without notice to you and that Deep Sounds
        will not not provided any refund (full or prorated), will have no obligation to compensate
        you, and Deep Sounds shall have no liability to you or to any third party in for any reason
        or in any way, including but not limited to direct, indirect, or consequential damages or
        lost profits, arising from such termination or deactivation.
      </p>
      <h6 className="pt-10 pb-20">7. Subscription Plan Upgrades</h6>
      <p>
        You may upgrade your subscription plan at any time. All upgrades shall start a new one (1)
        month or twelve (12) month period upon the effective date of the upgrade. In the event that
        you choose to upgrade your subscription, your upgraded subscription cost will be prorated
        for the unused portion of your prior subscription. For the elimination of doubt, a
        subscription shall be considered an &ldquo;upgrade&rdquo; if its cost is higher than your
        current subscription, not taking account for any promotional rate.{" "}
        <strong>
          If your current subscription was prepaid, you must select the prepaid billing option for
          your upgraded subscription and the monthly payment billing option will not be available
          for the upgraded subscription. If your current subscription is billed pursuant to the
          monthly payment billing option, you may choose either a prepaid or monthly payment billing
          option for your upgraded subscription.
        </strong>
        Subscribers may not downgrade their subscription.
      </p>
      <h6 className="pt-10 pb-20">8. Additional Terms</h6>
      <p>Your use of the Services is further conditioned upon to the following provisions:</p>
      <ol>
        <li className="ml-30 my-20">
          a. To use the Services and to purchase a subscription, you must be at least 18 years of
          age, or the age of majority in your province, territory, or country. By using or
          attempting to use the Services, or transacting any business with Deep Sounds, YOU
          AFFIRMATIVELY REPRESENT AND WARRANT THAT YOU ARE AT LEAST 18 YEARS OF AGE AND THAT YOU
          HAVE THE LEGAL RIGHT TO USE THE DEEP SOUNDS SERVICES IN ACCORDANCE WITH THESE SUBSCRIPTION
          TERMS AND ALL OTHER TERMS AND CONDITIONS.{" "}
        </li>
        <li className="ml-30 my-20">
          b. All content on the Services, and the copyrights, trademarks, service marks, and logos
          on the Services and/or Platform, are owned by or licensed to Deep Sounds and are subject
          to copyright and other intellectual property rights under the law. All Tracks and the
          copyrights in and to the compositions and sound recordings therein shall remain the
          property of their respective owners, and except as to any license granted to a Licensee
          User pursuant to a validly generated license, no right, title or interest in or to any
          Track or any other content shall be transferred to you. The owner of any and all content,
          and not Deep Sounds, shall be solely responsible for that content, and the compliance of
          the content with any applicable law.
        </li>
        <li className="ml-30 my-20">
          c. Your use of the Services and of any Track for which you purchased and generated a
          license may be limited to geographic location where Deep Sounds offers the Services and/or
          in the geographic location permitted by the generated license. Deep Sounds reserves the
          right to use, and you consent to the use of, any technologies that Deep Sounds deems
          appropriate, in its sole and exclusive discretion, to verify your geographic location or
          the geographic location of the use of a Track.
        </li>
        <li className="ml-30 my-20">
          d. You agree not to access subscription content in the Services or on the Platform through
          any technology or means other than as explicitly authorized by Deep Sounds. You further
          agree that you shall not circumvent, disable, or interfere with any security-related
          features of the Services, the Platform, or of any subscription, including but not limited
          to any feature that prohibits or restricts copying or restrictions on the use of any
          content in the Services or on the Platform, including but not limited to Tracks. You
          further agree not to circumvent, alter, decompile, remove, deactivate, degrade,
          manipulate, prevent, hamper or otherwise interfere with any protections of any content
          contained in or provided in conjunction with the use of the Services and.or Platform,
          including but not limited to Tracks, or use any spider, scraper, robot, data mining, data
          gathering, or insertion or extraction method, or any automated means, to access or
          manipulate the subscription service; or decompile, reverse engineer, disassemble, or
          interfere with or manipulate in any way the software, products, or processes offered or
          accessible through the Services or on the Platform. You also agree not to upload, send, or
          transmit any material designed to interrupt, destroy, or limit the functionality
          (including but not limited to the use of any software viruses, trojan horse, or any other
          computer code) of any software, hardware, or network or telecommunications equipment
          associated with the Services and/or Platform.Your use of the Service and Platform shall
          conform to and be in accordance with applicable laws, rules, and regulations, as well as
          any and all restrictions placed on the use of the Services, the Platform, any and all
          subscriptions, and any and all Tracks.
        </li>
        <li className="ml-30 my-20">
          e. Deep Sounds reserves the right, in its sole discretion, to discontinue the Services at
          any time.
        </li>
      </ol>
      <h6 className="pt-10 pb-20">9. WARRANTY DISCLAIMER</h6>
      <p>
        TO THE FULLEST EXTENT PERMITTED BY LAW, THE SERVICE AND PLATFORM ARE PROVIDED “AS IS” AND
        “AS AVAILABLE” AND DEEP SOUNDS MAKES NO WARRANTY, CONDITION OR REPRESENTATION, EXPRESS OR
        IMPLIED, OF ANY KIND, AND DISCLAIMS ALL WARRANTIES, CONDITIONS AND REPRESENTATIONS AS TO
        QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. DEEP
        SOUNDS MAKES NO WARRANTY, CONDITION OR REPRESENTATION THAT THE PLATFORM OR SERVICE WILL BE
        AVAILABLE AT A PARTICULAR TIME OR THAT THE PLATFORM OR SERVICES WILL HAVE ANY MINIMUM UPTIME
        OR AVAILABILITY, OR WILL BE FREE OF MALWARE, VIRUSES OR ANY OTHER HARMFUL CODE OR COMPONENT.
        DEEP SOUNDS DOES NOT WARRANT, GUARANTEE, ENDORSE OR OTHERWISE ASSUME RESPONSIBILITY FOR ANY
        OTHER PRODUCT OR SERVICE OFFERED BY A THIRD PARTY ON, THROUGH OR IN CONJUNCTION WITH THE
        PLATFORM OR SERVICES OR THROUGH ANY LINKED WEBSITE OR APPLICATION. NO STATEMENT, ADVICE,
        TROUBLESHOOTING, OR TECHNICAL SUPPORT OFFERED BY DEEP SOUNDS, WHETHER ORAL OR IN WRITING,
        SHALL BE DEEMED TO ALTER. MODIFY OR PRECLUDE THIS DISCLAIMER OR CONSTITUTE ANY WARRANTY,
        REPRESENTATION OR CONDITION OF BEHALF OF DEEP SOUNDS.
      </p>

      <p>
        You may have statutory rights as a consumer, and this disclaimer does not affect any
        statutory rights, to the extent that they apply.
      </p>

      <p>
        Without limiting the foregoing, you expressly acknowledge and agree that the availability,
        display, and audio quality of content, including the ability to listen to, preview, generate
        a license, or download audio files pursuant to a generated license, may change from time to
        time, from location to location, and from device to device, and may depend on factors such
        as available bandwidth, network issues, or other matters, including but not limited to those
        outside the control of Deep Sounds, and Deep Sounds makes no warranty as to the quality of
        your visual or audio experience using the Services or Platform. You are responsible for all
        Internet access charges and data use charges.
      </p>

      <h6 className="pt-10 pb-20">10. Limitation on Liability</h6>
      <p>
        YOU EXPRESSLY AGREE THAT IN NO EVENT SHALL DEEP SOUNDS&rsquo; LIABILITY TO YOU EXCEED THE
        AMOUNT YOU PAID TO DEEP SOUNDS IN THE 12 MONTHS PRIOR TO THE EVENT GIVING RISE TO YOUR
        CLAIM. TO THE FULLEST EXTENT PERMITTED BY LAW, EXCEPT AS OTHERWISE PROVIDED HEREIN, YOUR
        SOLE AND EXCLUSIVE REMEDY FOR ANY DISSATISFACTION WITH THE SERVICES OR PLATFORM, OR ANY
        THIRD PARTY PRODUCTS OR SERVICES OFFERED ON, THROUGH OR IN CONJUNCTION WITH THE PLATFORM OR
        SERVICES OR THROUGH ANY LINKED WEBSITE OR APPLICATION SHALL BE TO DISCONTINUE USE OF THE
        SERVICES AND PLATFORM.
      </p>
      <p>
        TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL DEEP SOUNDS, ITS OFFICERS,
        DIRECTORS, SHAREHOLDERS, MEMBERS, MANAGERS, EMPLOYEES, AGENTS, SUBSIDIARIES, AFFILIATES,
        SUCCESSORS, ASSIGNS, SUPPLIERS, VENDORS, LICENSORS, BE LIABLE FOR ANY INCIDENTAL,
        CONSEQUENTIAL, EXEMPLARY, SPECIAL OR PUNITIVE DAMAGES, INCLUDING DIRECT OR INDIRECT LOST
        PROFIT, OR ANY LOSS OF USE, DATA, OR BUSINESS, IN ALL CASES REGARDLESS OF HOW CHARACTERIZED
        AND EVEN IF SUCH DEEP SOUNDS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, WHICH
        ARISE FROM THE PERFORMANCE OF THIS AGREEMENT OR IN CONNECTION WITH THIS AGREEMENT, AND
        REGARDLESS OF THE FORM OF ACTION (WHETHER IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR
        OTHERWISE).
      </p>
      <h6 className="pt-10 pb-20">11. Indemnification</h6>
      <p>
        To the fullest extent permitted by law, you agree to indemnify, defend and hold harmless
        Deep Sounds, its officers, directors, members, managers, shareholders, employees, agents,
        representatives, subsidiaries, affiliates, parent companies, successors, assigns, suppliers,
        vendors, and licensors, from any and all claims, liabilities, losses, damages, costs, and
        expenses (including but not limited to reasonable attorneys&rsquo; fees) arising from or
        relating to (a) your breach or alleged breach of this Agreement; (b) any act or omission in
        which you engage by or through your use of the Services or Platform; (c) any infringement or
        alleged infringement of the intellectual property rights of any other party; (d) any
        violation or alleged violation of any law, statute, ordinance, or regulation; (e) your use
        of the Platform or Services; and/or (f) your breach or alleged breach of any third-party
        license.
      </p>
      <h6 className="pt-10 pb-20">12. Integration and Modification</h6>
      <p>
        The Agreements constitute the entire agreement between you and Deep Sounds. No oral
        representations or other agreements have been made by Deep Sounds except as stated in the
        Agreements. The Agreements supersedes all prior and contemporaneous agreements,
        representations and warranties. Deep Sounds reserves the right to modify the terms of the
        Agreements, in its sole and exclusive discretion. In the event that Deep Sounds modifies the
        terms of the Agreements, Deep Sounds will post the revised version and shall send notice of
        the posting of the revised agreement to the email that you provide when creating an account.
        All modifications will take effect immediately upon posting of the revised Agreements,
        except material changes to the terms of the Agreements, which shall become effective thirty
        (30) days after the date of the posting of the revised Agreements. Your continued use of the
        Services and/or Platform after posting of the revised Agreements shall be deemed your
        acceptance of such revised Agreements and agreement to be bound by the revised terms. In the
        event that you do not wish to agree to the revised terms of the Agreements, you must cease
        your use of the Services.
      </p>
      <h6 className="pt-10 pb-20">13. Severability/Waiver</h6>
      <p>
        In the event that any provision or any part of a provision of these Deep Sounds Terms shall
        be finally determined to be superseded, invalid, illegal or otherwise unenforceable pursuant
        to applicable laws by an authority having jurisdiction, such determination shall not impair
        or otherwise affect the validity, legality, or enforceability of the remaining provisions or
        parts of provisions of this Agreement, which shall remain in full force and effect as if the
        unenforceable provision or part were deleted. No term or provision hereof shall be deemed
        waived as a result of any failure by Deep Sounds to enforce this Agreement or any part
        hereof.
      </p>
      <h6 className="pt-10 pb-20">14. Choice of Law</h6>
      <p>
        The Deep Sounds Terms and any dispute arising from or related to your use of the Services
        and/or Platform shall be governed by and construed in accordance with the laws of the State
        of Illinois without regard for its conflict of law provisions.
      </p>
      <h6 className="pt-10 pb-20">15. Arbitration, Jury Waiver and Class Action Waiver</h6>
      <p>
        The parties hereto agree that any and all disputes, claims or controversies arising from or
        relating to the Agreements, and/or your use of the Services and/or Platform, shall be
        settled exclusively by binding arbitration and in accordance with the Commercial Arbitration
        Rules of the American Arbitration Association under the Federal Arbitration Act. Prior to
        instituting arbitration, you agree to provide Deep Sounds with written notice of your claim
        via email, which shall be deemed delivered upon actual receipt by Deep Sounds, setting forth
        the dispute and the relief that you are seeking. You further agree that you will engage in a
        good faith effort with Deep Sounds to resolve the dispute. In the event that the dispute is
        not resolved within thirty (30) days after receipt of the notice of your claim, either you
        or Deep Sounds may initiate arbitration. Deep Sounds and you each agree to attempt to
        resolve the dispute. Such arbitration shall be conducted by a single arbitrator and
        conducted solely in Chicago, Illinois. The parties shall bear their own attorneys&rsquo;
        fees and costs, and shall equally bear the costs of instituting arbitration.{" "}
        <strong>
          EACH OF THE PARTIES HERETO HEREBY IRREVOCABLY WAIVES ANY AND ALL RIGHT TO TRIAL BY JURY IN
          ANY LEGAL PROCEEDING ARISING OUT OF OR RELATED TO THIS AGREEMENT OR THE TRANSACTIONS
          CONTEMPLATED HEREBY. ALL CLAIMS MUST BE BROUGHT BY A PARTY ONLY IN ITS INDIVIDUAL CAPACITY
          AND NOT AS CLASS ACTION LAWSUIT, CLASS-WIDE ARBITRATION, PRIVATE ATTORNEY-GENERAL ACTION,
          OR AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ACTION
        </strong>
        , and, unless the parties agree otherwise in writing, that no arbitrator or judge may
        consolidate more than one person&rsquo;s claims or otherwise preside over any form of a
        representative or class proceeding. The arbitrator may award injunctive relief only in favor
        of the individual party seeking relief and only to the extent necessary to provide relief
        warranted by that party&rsquo;s individual claim. In the event that any part of this section
        or its limitations are determined by a court to be unenforceable pursuant to applicable law
        as to a particular claim for relief, then that claim (and only that claim) must be severed
        from the remainder of the arbitration and may be brought in court. Notwithstanding anything
        in the contrary in this section, nothing in this section shall be deemed to waive or
        preclude the ability of Deep Sounds or you to bring an individual action in a United States
        small claims court.
      </p>
    </div>
  );
}
export default SiteTerms;

import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import MicroModal from "react-micro-modal";
import { confirm } from "../confirmModal/index";
import CropUtilityModal from "../modal/cropUtilityModal";
// import "./style.css"
let fileInserted = false;

function CardArtUpload(props) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    noDrag: true,
    noDragEventsBubbling: true,
    onDrop: acceptedFiles => {
      console.log("dropped ")
      fileInserted = true;
      const files = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      const i = new Image()
        i.onload = ()=>{
          let reader = new FileReader()
          reader.readAsDataURL(files[0])
          reader.onload = ()=>{
            console.log({
              src: files[0].preview,
              width: i.width,
              height: i.height,
              data: reader.result
            })
          }
        
        if(i.width >= 1000 && i.height >= 1000){
          setFiles(files);
          if (props.onChange) {
            console.log("validate ", props.validate)
            props.onChange(files, props.validate);
            props.openPopout()
          }
        }
        else{
          console.log("erroring")
          if (props.onChange) {
            fileInserted =false
            props.onChange({error: "File must be 1x1"}, props.validate);
          }
        }
      }
        i.src = files[0].preview 
    }
  });
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  const removeFile = file => async (event) => {
    event.preventDefault()
    console.log('file ', file)
    if(await confirm("Are you sure you want to remove your project cover art?", "Remove Cover Art", true)){
      console.log('file ',file)

      fileInserted = false
      if(file?.files.length > 0){
        console.log(" file ",file)
        const newFiles = [...files];
        newFiles.splice(newFiles.indexOf(file), 1);
        setFiles(newFiles);
        props.onChange(newFiles, props.validate);
      }
      else{
        props.removeCoverArt(event)
      }
    }
    else{
      return
    }
  };

  const thumbs = files.map(file => (
    <div  key={file.name}>
      <div >
        
        {file.type === "aasdf" ? 
          <img  src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${props.coverArt}`}  alt="" />
          :
          <img  src={file.preview}  alt="" />
        } 
        {/* <embed width="191" height="207" name="plugin" src={file.preview} type="application/pdf"/> */}
      </div>
      {/* <button className={"removeButton"} >Remove File</button> */}
    </div>
  ));
  const saveCrop= (files)=>{
    fileInserted = true;

    let file = files[0]
    console.log("saving crop ", files)
    Object.assign(file, {
      preview: URL.createObjectURL(file)
    })
    setFiles(files);
      if (props.onChange) {
        props.onChange(files, props.validate);
        props.handleCropUtilityClose()
      }

  }
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  console.log("file inserted ", fileInserted)
  if(files.length < 1){
    fileInserted = false
  }
  return (
  
    <>
                            <div className="form-section">
                            <h3 className="text-label-lg mb-30">Card Photo</h3>
                            {props.coverArtError && 
                                    <em className="text-primary">{props.coverArtError }</em>
                                }
    <div className="grid-row-sm mb-20">

                                <div className="col w-120 mb-20 md:mb-0">

                                    <aside >{thumbs}</aside>
                                    {fileInserted === false ?
                                      props.coverArt && typeof props.coverArt === "string" && props.coverArt !== "File must be an image in a 1x1 ratio!"? 
                                        <img  src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${props.coverArt}`}  alt="" />:
                                        props.initialCoverArt ? 
                                        <img style={{width: "100%"}}src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${props.initialCoverArt}`}  alt="" />:
                                        <img style={{width: "100%"}}src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${props.defaultArt}`}  alt="" />
                                        :
                                     <></>
                                    }

                                    {/* <img src="https://pbs.twimg.com/profile_images/953658232517652480/Xp0NB-TL.jpg" className="rounded-full"/> */}
                                </div>
                                <div className="col flex flex-1 flex-col justify-center">
                                    <p className="mb-15">Images must be a 1x1 aspect ratio (square) and should be at least 1000px wide and be a valid file type (jpg, png, etc.)</p>
                                    <ul className="list-disc text-xs">
                                        <li>Images uploaded with text will be removed</li>
                                        <li>Images main focus should always be center</li>
                                    </ul>
                                </div>
                            </div>
                            <MicroModal
                              open={props.isCropUtilityOpen}
                              closeOnEscapePress={true}
                              handleClose={()=>{console.log("handling close");props.handleCropUtilityClose(removeFile({files}))}}
                              closeOnOverlayClick={false}
                              closeOnAnimationEnd={true}
                              modalOverlayClassName="modal-overlay"
                              modalClassName="modal micromodal-slide is-open"
                              modalOverlayStyles={null}
                              children={handleClose=><CropUtilityModal handleClose={handleClose} file={files[0]} saveCrop={saveCrop}/>}
                              containerStyles={{background: "#131313", padding:"asdf", maxWidth: "32rem", maxHeight: "95vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box"}}
                            ></MicroModal>
                            {/* {# Upload Status #} */}
                            <div className="input-group flex w-full flex-col md:flex-row">
                                <div className="flex md:flex-1 border-t border-b border-gray-300 py-15 items-center h-50 mb-20 md:mb-0 md:mr-30">

                                    {files.length > 0 || props.initialCoverArt ? <>
                                      <div className="upload-status-icon flex flex-none items-center mr-10">
                                        <span className="text-icon-md text-white icon-check"></span>
                                    </div>
                                    {files.length > 0 ? 
                                      <span className="flex-1">{files[0].name}</span>:
                                      <span className="flex-1">{typeof props.initialCoverArt ? props.initialCoverArt[0]?.split("/")[props.initialCoverArt[0]?.split("/").length-1] : "default.svg"}</span>}
                                   
                                    {props.initialCoverArt && !Array.isArray(props.coverArt) ?
                                      <>
                                      </>:
                                      <button onClick={removeFile({files})} className="flex px-10 flex-none items-center transition-colors duration-300 text:gray-400 hover:text-white">
                                          <span className="text-icon-md icon-trash"></span>
                                      </button>
                                    }
                                    </>
                                    :
                                    <></>
                                    }
                                </div>
                                 <div {...getRootProps({ className: "btn-dropzone" })}>
                               <input {...getInputProps()} />
                                <button className="btn btn-stroke" onClick={(event)=>{event.preventDefault()}}>Upload Card Art</button>
                                </div>

                                {/* <button className="btn btn-stroke">Upload Photo</button> */}
                            </div>

                            </div>
                            </>
  );
}

export default CardArtUpload;
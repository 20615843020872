const versionNameOptions = [
  { value: "instrumental", label: "Instrumental" },
  { value: "15 seconds", label: "15 Seconds" },
  { value: "30 seconds", label: "30 Seconds" },
  { value: "60 seconds", label: "60 Seconds" },
  { value: "15 seconds alternative", label: "15 Seconds Alternative Cut" },
  { value: "30 seconds alternative", label: "30 Seconds Alternative Cut" },
  { value: "60 seconds alternative", label: "60 Seconds Alternative Cut" },
  { value: "alternative cut", label: "Alternative Cut" },
  { value: "alternative cut 2", label: "Alternative Cut 2" },
  { value: "alternative cut 3", label: "Alternative Cut 3" },
  { value: "clean", label: "Clean Version (No Explicit Lyrics)" },
  { value: "instrumental 15 seconds", label: "Instrumental 15 Seconds" },
  { value: "instrumental 30 seconds", label: "Instrumental 30 Seconds" },
  { value: "instrumental 60 seconds", label: "Instrumental 60 Seconds" },
  { value: "bumper", label: "Bumper" },
  { value: "stinger", label: "Stinger" },
  { value: "loop", label: "Loop" }
];

export default versionNameOptions;

import React, { useState } from "react";
import currencyFormatter from "../../../utils/currencyFormatter";
import DataTable from "../../UI/DataTable";
import numberAbbreviator from "../../NumberFormatters/numberAbbreviator";

const SalesContentIDMonth = ({ contentIDAssetReport, contentIDAssetReportItems }) => {
  const {
    id: assetReportId,
    monthString,
    total_videos_claimed,
    total_views,
    net_earnings,
  } = contentIDAssetReport || {};

  const [showRows, setShowRows] = useState(false);

  const toggleDropdown = () => {
    setShowRows(!showRows);
  };

  const monthAssetReportItems = contentIDAssetReportItems?.filter((item) => {
    const { content_id_asset_report_id } = item || {};
    if (content_id_asset_report_id === assetReportId) {
      return item;
    }
  });

  const monthAssetReportItemsFormatted = monthAssetReportItems?.map((item) => {
    const { net_earnings, asset_title, payout_date, status, total_videos_claimed, total_views } =
      item || {};
    return {
      asset_title,
      total_videos_claimed: numberAbbreviator(total_videos_claimed, "million"),
      total_views: numberAbbreviator(total_views, "million"),
      payout_date,
      status,
      net_earnings: currencyFormatter(net_earnings, true),
    };
  });

  return (
    <>
      <tr style={{ maxWidth: "100%" }}>
        <td>
          <button className="text-white underline" onClick={toggleDropdown}>
            {monthString}
          </button>
        </td>
        <td>{numberAbbreviator(total_videos_claimed, "million")}</td>
        <td>{numberAbbreviator(total_views, "million")}</td>
        <td className="text-right">{currencyFormatter(net_earnings, true)}</td>
      </tr>

      <tr className={showRows ? "" : "hidden"}>
        <td colSpan="4">
          {monthAssetReportItemsFormatted?.length > 0 ? (
            <div className="bg-gray-100 pt-20 pb-10 lg:pb-15 mb-40 px-15">
              <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
                <div className="flex-1 flex items-center mb-15 md:mb-0">
                  <h6 className="text-h6 mr-10">{monthString} - Details by Asset</h6>
                </div>
              </div>
              <DataTable
                data={monthAssetReportItemsFormatted}
                headerTransform={"convertSnakeToTitleCase"}
                lastColumnRight={true}
              />
            </div>
          ) : null}
        </td>
      </tr>
    </>
  );
};

export default SalesContentIDMonth;

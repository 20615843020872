import React from "react";
import { Controller, useForm } from "react-hook-form";

export default function EditSceneModal(props){
        const { control, register, handleSubmit, watch, setError, errors, reset, trigger } = useForm();

        return(
            <React.Fragment>
 {/* {# Modal Header #} */}
            <header class="modal-header">
                <h2 id="add_scene_title" class="modal-title">Edit Scene</h2>
                <button onClick={props.handleClose} aria-label="Close Modal" data-micromodal-close="add_scene_modal" class="modal-close" type="button"></button>
            </header>

            {/* {# Modal Content #} */}
            <div id="add_scene_content" class="modal-content">

                {/* {# Form #} */}
                <form action="" class="ds-form" onSubmit={(e)=>{e.preventDefault(); handleSubmit(props.editScene(trigger, errors, props.handleClose, props.sceneId, props.editedSceneName, props.sceneIndex))}}>

                    {/* {# Title #} */}
                    <div class="input-group-sm input-inline">
                        <Controller 
                            name="editedSceneName" 
                            control={control}
                            id="project_title" 
                            placeholder="Scene Title" 
                            defaultValue={props.editedSceneName}
                            value={props.editedSceneName}
                            render={
                                (pro) => 
                                (
                                    <input 
                                        {...pro} 
                                        className="form-input" 
                                        name={"editedSceneName"}
                                        value={props.editedSceneName}
                                        onChange={
                                            (e) => {
                                                pro.onChange(e);
                                                props.handleInputChange(e);
                                            }} 
                                        ref={register} 
                                    />
                                )}
                            rules={{
                                required: "Scene Name Required",
                                minLength: { message: "Scene Title Must be at Least 3 Characters", value: 3},
                                maxLength: { message: "Scene Title Cannot be More Than 99 Characters", value: 100},
                                // validate: value => props.checkIfUsed(value)
                            }}
                        />
                        <label class="input-label" htmlFor="project_title">Scene Title</label>
                        {errors.editedSceneName && <span className="error-message">{errors.editedSceneName.message}</span>}
                    </div>

                    {/* {# Buttons #} */}
                    <div class="input-group pt-20">
                        <div class="grid-row-sm">
                            <div class="col w-1/2 mb-20 sm:mb-0">
                                <button onClick={props.handleClose} class="btn btn-stroke form-btn w-full" type="button" aria-label="Close Modal" data-micromodal-close="add_scene_modal">Cancel</button>
                            </div>
                            <div class="col w-1/2">
                                <button type="submit" class="btn btn-primary form-btn w-full">Save</button>
                            </div>
                        </div>
                    </div>

                </form>
                {/* {# End Form #} */}

            </div>
</React.Fragment>
        )
    
}

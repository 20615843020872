import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import DefaultCover from "../../assets/photos/default.jpg";
import { confirm } from "../confirmModal/index";
import CircularIndeterminateButton from '../Loading/circularIndeterminantButton';
import { Warning } from "../Toast/index";

let fileInserted = false;

function ArtistCoverPhotoUpload(props) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    noDrag: true,
    onDrop: acceptedFiles => {
      fileInserted = true;
      const files = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      const i = new Image()
      i.onload = ()=>{
        let reader = new FileReader()
        reader.readAsDataURL(files[0])
        reader.onload = ()=>{
          console.log({
            src: files[0].preview,
            width: i.width,
            height: i.height,
            data: reader.result
          })
        }
      

        setFiles(files);
        if (props.onChange) {
          props.onChange(files);
        }

    }
      i.src = files[0].preview 
  }
});
useEffect(
  () => () => {
    // Make sure to revoke the data uris to avoid memory leaks
    files.forEach(file => URL.revokeObjectURL(file.preview));
  },
  [files]
);

  const removeFile = file => async (event) => {
    event.preventDefault()
    console.log('file ',file)
    if(file.files.length === 0 && !props.coverPhoto){
      Warning({message: "Cannot remove default cover photo"})
      return
    }
    if(await confirm("Are you sure you want to remove your cover photo?", "Remove Photo")){
      fileInserted = false
      if(file?.files.length > 0){
        const newFiles = [...files];
        newFiles.splice(newFiles.indexOf(file), 1);
        setFiles(newFiles);
      }
      else{
        props.removeCoverPhoto(event)
      }
    }
    else{
      return
    }
  };

  const thumbs = files.map(file => (
    <div  key={file.name}>
      <div >
        
        {file.type === "aasdf" ? 
          <img   src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${props.profilePhoto}`}  alt="" />
          :
          <img  src={file.preview}  alt="" />
        } 
        {/* <embed width="191" height="207" name="plugin" src={file.preview} type="application/pdf"/> */}
      </div>
      {/* <button className={"removeButton"} >Remove File</button> */}
    </div>
  ));

  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <>
                            <div className="form-section">
                            <h3 className="text-label-lg mb-30">Cover Photo</h3>
                            {props.coverPhotoError && 
                             <div className="text-primary">{props.coverPhotoError}</div>}
    <div className="grid-row-sm mb-20">
                                <div className="col w-full mb-20 md:mb-0 md:w-2/5">

                                    <aside >{thumbs}</aside>
                                    {fileInserted === false ?
                                      props.coverPhoto ? 
                                        <img src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${props.coverPhoto}`}  alt="" />:
                                        <img style={{width: "100%"}}src={DefaultCover}  alt="" />
                                        :
                                     <></>
                                    }
                                </div>
                                <div className="col flex flex-1 flex-col justify-center">
                                <p className="mb-15">
                                      Images must be 1000 x 1000 pixels or larger. <br/>
                                      Accepted file types are jpg, png, gif, svg.
                                    </p>
                                    <ul className="list-disc text-xs">
                                        <li>Images with text are not allowed.</li>
                                        <li>Use wide oriented images for best results.</li>
                                        <li>Use high quality images.</li>
                                    </ul>
                                </div>
                            </div>

                            {/* {# Upload Status #} */}
                            <div className="input-group flex w-full flex-col md:flex-row">
                                <div className="flex md:flex-1 border-t border-b border-gray-300 py-15 items-center h-50 mb-20 md:mb-0 md:mr-30">
                                    {props.coverPhoto &&
                                      <div className="upload-status-icon flex flex-none items-center mr-10">
                                          <span className="text-icon-md text-white icon-check"></span>
                                      </div>
                                    }
                                    {files.length > 0 ? 
                                      <span className="flex-1">{files[0].name}</span>:
                                      <span className="flex-1">
                                        {props.coverPhoto ? 
                                          props.coverPhoto.split("/")[props.coverPhoto.split("/").length-1] 
                                        : 
                                          "Please upload an image."
                                        }
                                      </span>}
                                   
                                    {props.coverPhoto &&
                                      <button type="button" onClick={removeFile({files})} className="flex px-10 flex-none items-center transition-colors duration-300 text:gray-400 hover:text-white">
                                          <span className="text-icon-md icon-trash"></span>
                                      </button>
                                    }
                                </div>
                                 <div {...getRootProps({ className: "btn-dropzone" })}>
                               <input {...getInputProps()} />
                                <button type="button" className="btn btn-stroke" onClick={(event)=>{event.preventDefault()}}>Upload Artwork</button>
                                </div>

                                {/* <button className="btn btn-stroke">Upload Photo</button> */}
                            </div>
                            <button 
                              type="button"
                              className="btn btn-primary w-full"
                              onClick={props.updateCoverPhoto}
                              disabled={props.isLoading}
                            >
                              {props.isLoading ?
                                <CircularIndeterminateButton forceCenter />
                              :
                                (props.buttonText ?
                                  props.buttonText
                                :
                                  'Update Cover Photo'         
                                )
                              }
                              
                            </button>

                            </div>
                            </>
  );
}

export default ArtistCoverPhotoUpload;
import { Box, Typography } from '@material-ui/core';
import React, { Component } from "react";
// import CircluarDeterminate from "../Loading/circularDeterminantLabel";
class ApprovalItem extends Component{
    state={

    }
    render(){
        return(
            <div>
                {/* <div className="input-fieldset"> */}
                    <div class="upload-item">
                        <div class="upload-item-details">
                            <div style={{verticalAlign: "middle"}} class="upload-item-title ">
                                <span className={this.props.disableButton ? "icon-warning text-primary":this.props.statusIcon} ></span>
                                <span className={"ml-3 text-gray-400"}>{this.props.title} - {this.props.step}</span>
                            </div>
                        </div>
                        <div class="upload-item-controls">
                            {this.props.error ?
                            <button disabled={this.props.disableButton} onClick={()=>this.props.version? this.props.retry(this.props.id, this.props.index) :this.props.retry()} class="btn btn-sm btn-stroke">
                                <span class="icon-refresh mr-5"></span>
                                    Retry
                            </button>
                            :
                                <Box minWidth={35}>
                                    <Typography variant="body2" color="white">{`${this.props.progress || 0}%`}</Typography>
                                </Box>
                            }
                        </div>
                    </div>
                    {/* <Box display="flex" alignItems="center">
                        <Box width="100%" mr={1}>
                            <LinearProgress  value={this.props.progress || 0} variant={"determinate"} classes={{colorPrimary: 'colorPrimary', barColorPrimary: 'barColorPrimary'}}/>
                        </Box>
                        <Box minWidth={35}>
                        <Typography variant="body2" color="white">{`${this.props.progress || 0}%`}</Typography>
                        </Box>
                    </Box> */}
                {/* </div> */}
            </div>
        )
    }
}
export default ApprovalItem
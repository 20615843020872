import React, { Component } from "react";
import ReactPasswordStrength from "react-password-strength";
import { Tooltip } from "react-tippy";
import { UserConsumer } from "../../contexts/User";
import api from "../utils/api";
const queryString = require('query-string')

class ResetPassword extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {
                email: "",
                password: "",
                newPassword: "",
                passwordConfirm:"",
                loginMessage: ""
            }
    //updating our input fields as the user enters keys
    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            if (this.state.password && this.state.passwordConfirm && this.state.passwordConfirm === this.state.password){
                this.setState({loginMessage: "Passwords match!"})
            }
            if (this.state.password && this.state.passwordConfirm && this.state.passwordConfirm !== this.state.password){
                this.setState({loginMessage: "Passwords must match!"})
            }
        });
    };

    componentDidMount = ( )=>{
        this.passwordStrengthRef = React.createRef()

        //
        api.validateReset(this.props.match.params.token)
            .then(res=>{

                if(res.data.email){
                    this.setState({
                        email: res.data.email
                    })
                }
                else{
                    this.setState({
                        error: true
                    })
                }
            })

    }
    changeCallback=(state, fullObject)=>{

        if(state.isValid && this.state.passwordError){
            this.setState({passwordError: false})
        }
        this.setState({newPassword: state.password, pwScore: state.score, pwValid: state.isValid, pwSuggestions: fullObject?.feedback?.suggestions || [], pwWarnings: fullObject?.feedback?.warning, fullObject: fullObject})
    }
    focusPW(shouldFocus){
        this.setState({pwFocused: shouldFocus, isPWTippyOpen: shouldFocus})
    }
    handleFormSubmit = event => {
        event.preventDefault();

        if (this.state.newPassword && this.state.pwValid ) {
            let passwordResetBody = {
                password: this.state.newPassword,
                email: this.state.email,
                resetToken: this.props.match.params.token
            }
            api.resetPassword(passwordResetBody)
                .then(res=>{
                    if (res.data?.success){
                        this.setState({
                            loginMessage: "Password successfully updated! Please try logging in again.",
                            password: "",
                            passwordConfirm: "",
                            disableInput: true,
                        })
                        if(this.passwordStrengthRef.current){
                            this.passwordStrengthRef.current.clear()
                        }
                    }
                })
        }
    }
    render() {
        return (
            <UserConsumer>
                {({ user, login, logout})=>(
                    <>
                        <div className="hero-fixed">
                            <div className="hero-img" style={{backgroundImage: `url("${process.env.PUBLIC_URL}/images/hero/login-02.jpg")`}}></div>
                        </div>
                        <div className="onboarding-main container container-xl">

                            <h1 className="ds-title mb-30 lg:mb-40">Password</h1>

                            <form className="onboarding-form" onSubmit={this.handleFormSubmit}>

                                <p>Enter a new password.</p>

                                {this.state.email &&
                                    <div className="input-group-sm input-inline">
                                        Email: {this.state.email}
                                    </div>
                                }

                                {!this.state.disableInput &&
                                     <div className="input-group-sm input-inline">
                                     <Tooltip
                                         arrow={false}
                                         open={this.state.isPWTippyOpen}
                                         position={'top'}
                                                    html={(!this.state.pwValid ? this.state.newPassword?.length > 7 ? <>{this.state.pwWarnings && <> <span className="icon-reject text-primary"></span>{this.state.pwWarnings}</>}
                                                 {this.state.pwSuggestions.length > 0 && <div>Suggestions:</div>}
                                                 {this.state.pwSuggestions.map(suggestion=><div>{suggestion}</div>)}
                                                 </> : <><div>Password must be at least 8 characters</div></>: <><div>Password is good to go!</div></>
                                         )}
                                         allowHTML={true}
                                     >
                                         <>
                                         {this.state.passwordError &&
                                             <div className="error-message">Password not strong enough</div>
                                         }
                                         <ReactPasswordStrength
                                             inputProps={{placeholder: "New Password", type: this.state.pwFocused ? "text":"password", name: "newPassword",
                                                 onFocus: ()=>this.focusPW(true),
                                                 onBlur: ()=>this.focusPW(false),
                                                 className: "form-input",
                                                 id:"password" }}
                                             minScore={3}
                                             minLength={8}
                                             ref={this.passwordStrengthRef}
                                             changeCallback={this.changeCallback}
                                             namespaceClassName={"dspw"}
                                             scoreWords={['ultra weak','very weak', 'weak', 'strong', 'very strong']}
                                         />
                                         </>
                                     </Tooltip>

                                     <label className="input-label" htmlFor="password">Password</label>
                                 </div>
                                }

                                {/* {!this.state.disableInput &&
                                    <div className="input-group-sm input-inline">
                                        <input className="form-input"
                                            value={this.state.passwordConfirm}
                                            onInput={this.handleInputChange}
                                            type="password"
                                            name="passwordConfirm"
                                            placeholder="Confirm Password"
                                        />
                                        <label className="input-label" htmlFor="passwordConfirm">Confirm Password</label>
                                    </div>
                                } */}

                                <div className="input-group-sm input-inline">
                                    <h6 className="text-danger">{this.state.loginMessage}</h6>
                                </div>

                                <div className="input-group-sm">
                                    {!this.state.disableInput ?
                                        <button className="btn btn-primary form-btn w-full"
                                            name="login"
                                            type="submit"
                                            disabled={!this.state.pwValid}
                                        >
                                            Save Password
                                        </button>

                                    :
                                        <a className="btn btn-primary form-btn w-full"
                                            href="/login"
                                        >
                                            Go to Sign In
                                        </a>
                                    }
                                </div>
                                <div className="flex flex-wrap justify-between text-gray-400 text-sm">
                                        <a href="/login" className="text-link-gray py-3">Sign In</a>
                                        <a href="/signup" className="text-link-gray py-3">Don't have an account?</a>
                                    </div>
                            </form>
                        </div>
                    </>
                )}
            </UserConsumer>
        )
    }

}
export default ResetPassword

import React, { useEffect, useState } from "react";
import AssetReportForm from "../../Forms/AssetReportForm";
import AssetReportsRows from "./AssetReportsRows";
import DashboardLayout from "../../Layouts/DashboardLayout";
import ContentIDSubnav from "../ContentIDSubnav";
import contentIDAPI from "../../../api/contentID";
import LoadingContainer from "../../Loading/LoadingContainer";
import { Error } from "../../Toast";

const AssetReports = () => {
  const [assetReports, setAssetReports] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [showForm, setShowForm] = useState(false);

  const HeaderControls = () => {
    return (
      <button
        className="btn btn-primary"
        onClick={() => {
          setShowForm(true);
        }}
      >
        + New Asset Report
      </button>
    );
  };

  const getContentIDAssetReports = () => {
    contentIDAPI
      .getAssetReports()
      .then((res) => {
        console.log("getAssetReports res", res);
        const { assetReports } = res.data || {};
        setIsLoaded(true);
        setAssetReports(assetReports);
      })
      .catch((error) => {
        console.log("getAssetReports error", error);
        setIsLoaded(true);
        Error({ message: "There was an error loading the asset reports." });
      });
  };

  useEffect(() => {
    setIsLoaded(false);
    getContentIDAssetReports();
  }, []);

  return (
    <DashboardLayout title="Asset Reports" HeaderControls={HeaderControls}>
      <ContentIDSubnav />
      {showForm ? (
        <AssetReportForm
          setShowForm={setShowForm}
          getContentIDAssetReports={getContentIDAssetReports}
        />
      ) : null}

      <LoadingContainer isLoaded={isLoaded}>
        <div className="bg-gray-100 p-20 pb-10 lg:p-25 lg:pb-15 mb-40">
          <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
            <div className="flex-1 flex items-center mb-15 md:mb-0">
              <h3 className="text-label-lg mr-10">Asset Reports by Month</h3>
              <span className="flex-none text-xs">Showing {assetReports?.length}</span>
            </div>
          </div>
          <div className="table-wrap ds-scrollbar">
            <table className="stats-table w-full mt-30">
              <thead className="text-left">
                <tr>
                  <th className="text-label-sm">Month</th>
                  <th className="text-label-sm">Total Assets</th>
                  <th className="text-label-sm">Deep Sounds Net Earnings</th>
                  <th className="text-label-sm">Artists Net Earnings</th>
                  <th className="text-label-sm">Actions</th>
                </tr>
              </thead>
              <tbody>
                {assetReports?.length > 0 ? <AssetReportsRows assetReports={assetReports} /> : null}
              </tbody>
            </table>
          </div>
        </div>
      </LoadingContainer>
    </DashboardLayout>
  );
};

export default AssetReports;

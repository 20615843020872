import moment from "moment";
import React, { Component } from "react";
import MicroModal from "react-micro-modal";
import { NavLink } from "react-router-dom";
import SearchBar from "../SearchBar/searchBar.js";
import SortBar from "../SortBar/results";
import { Error, Success } from "../Toast";
import api from "../utils/api";
// import TrackListItem from "../Track/trackListItem";
// import FavArtistCard from "./favArtistCard";
import CollectionsCard from "./collectionsCard";
import CreateCollectionModal from "./createCollectionModal";
import FeaturedList from "./featuredList";
var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment)

  const collectionFilters= [{filter: "NameA", displayText: "Name - Asc"}, {filter: "NameD", displayText: "Name - Desc"}, {filter: "RecentA", displayText: "Date Added- Asc"}
  ,{filter: "RecentD", displayText: "Date Added - Desc"}, {filter: "MostP", displayText: "Most Popular"}]

class AdminCollections extends Component {
    state = {
        collections: [],
        published: [],
        draft: [],
        featured: [],
        filteredPublished: [],
        filteredDraft: [],
        filteredFeatured: [],
        publishedSortChoice: "NameA",
        publishedSortText: "Name - Asc",
        draftSortChoice: "NameA",
        draftSortText: "Name - Asc",
        featuredSortChoice: "NameA",
        featuredSortText: "Name - Asc",
        newCollectionName: "",
        newCollectionDescription: ""
    }
    componentDidMount = ()=>{
        api.getAllAdminCollections()
            .then(res=>{
                let collections = res.data.collections
                let compare = (a, b)=> {
                    // Use toUpperCase() to ignore character casing
                    const aName = a.name.toUpperCase();
                    const bName = b.name.toUpperCase();

                    let comparison = 0;
                    if (aName < bName) {
                      comparison = -1;
                    } else if (aName > bName) {
                      comparison = 1;
                    }
                    return comparison;
                  }
                collections = collections.sort(compare)
                let draftCollections = []
                let publishedCollections = collections.filter(collection=>{
                    if (collection.published === 1){
                        return true
                    }
                    else{
                        draftCollections.push(collection)
                    }
                })
                let featuredCollections = collections.filter(collection=>collection.featured ===1).sort((a,b)=>{
                    const aWeight = a.featuredWeight
                    const bWeight = b.featuredWeight
                    let comparison = 0
                    if(aWeight < bWeight){
                        comparison = -1
                    }
                    else if( aWeight > bWeight){
                        comparison = 1
                    }
                    return comparison
                })
                this.setState({collections: res.data.collections, published: publishedCollections, draft: draftCollections, featured: featuredCollections,
                    filteredPublished: publishedCollections, filteredDraft: draftCollections, filteredFeatured: featuredCollections})
            })
    }
    componentDidUpdate = (prevProps)=>{
        if(prevProps.match.params.collectionsList !== this.props.match.params.collectionsList){
            this.handleSearchChange({target: {value: ""}})
        }
    }
    //get a requested set of tracks from the db
    toggleSortTippy = (sortChoice, overRideTippy)=>{
        switch(this.props.match.params.collectionsList){
            case "published":
                this.setState({isSortTippyOpen: overRideTippy === false ? false : !this.state.isSortTippyOpen, publishedSortChoice: sortChoice})
            case "draft":
                this.setState({isSortTippyOpen: overRideTippy === false ? false : !this.state.isSortTippyOpen, draftSortChoice: sortChoice})
                break;
            case "featured":
                this.setState({isSortTippyOpen: overRideTippy === false ? false : !this.state.isSortTippyOpen, publishedSortChoice: sortChoice})
                break;
        }
    }
    sortBy = (sortChoice, sortText, overRide, tippyStateCB, currentSortChoice, overRideTippy)=>{
        let unsorted
        switch(this.props.match.params.collectionsList){
            case 'published':
                unsorted = [...this.state.filteredPublished]
                break;
            case 'draft':
                unsorted = [...this.state.filteredDraft]
                break;
            case 'featured':
                unsorted = [...this.state.filteredPublished]
                break;
        }
        let compare;
        let sorted;

        let parsedSortChoice
        switch(this.props.match.params.collectionsList){
            case "published":
            case  "featured":
                parsedSortChoice = "published"
                break;
            case "draft":
                parsedSortChoice = "draft"
        }
        if(sortChoice !== this.state[`${parsedSortChoice}SortChoice`] || overRide){

            switch(sortChoice){
                case "NameA":
                    compare = (a, b)=> {
                        // Use toUpperCase() to ignore character casing
                        const aName = a.name.toUpperCase();
                        const bName = b.name.toUpperCase();

                        let comparison = 0;
                        if (aName < bName) {
                          comparison = -1;
                        } else if (aName > bName) {
                          comparison = 1;
                        }
                        return comparison;
                      }
                    sorted = unsorted.sort(compare)
                    break;
                case "NameD":
                    compare = (a, b)=> {

                        // Use toUpperCase() to ignore character casing
                        const aName = a.name.toUpperCase();
                        const bName = b.name.toUpperCase();

                        let comparison = 0;
                        if (aName < bName) {
                          comparison = -1;
                        } else if (aName > bName) {
                          comparison = 1;
                        }
                        return comparison*-1;
                      }
                    sorted = unsorted.sort(compare)
                    break;
                case "RecentA":
                    compare = (a,b)=>{
                        const aCreated = a.createdOn;
                        const bCreated = b.createdOn;

                        let comparison = 0;
                        if (aCreated < bCreated){
                            comparison = -1
                        }
                        else if (aCreated > bCreated){
                            comparison = 1;
                        }
                        return comparison
                    }
                    sorted = unsorted.sort(compare)
                    break;
                case "RecentD":
                    compare = (a,b)=>{
                        const aCreated = a.createdOn;
                        const bCreated = b.createdOn;

                        let comparison = 0;
                        if (aCreated < bCreated){
                            comparison = -1
                        }
                        else if (aCreated > bCreated){
                            comparison = 1;
                        }
                        return comparison * -1
                    }
                    sorted = unsorted.sort(compare)
                    break;
                case "MostP":
                    compare = (a,b)=>{
                        const aFollowers = a.followerCount;
                        const bFollowers = b.followerCount;
                        const aCreated = a.createdOn;
                        const bCreated = b.createdOn;
                        let comparison = 0;
                        if(aFollowers < bFollowers){
                            comparison = 1
                        }
                        else if(aFollowers > bFollowers){
                            comparison = -1
                        }
                        else if (aCreated < bCreated){
                            comparison = -1
                        }
                        else if (aCreated > bCreated){
                            comparison = 1;
                        }
                        return comparison
                    }
                    sorted = unsorted.sort(compare)
                    break;
            }
            switch(this.props.match.params.collectionsList){
                case 'published':
                    this.setState({filteredPublished: sorted, publishedSortText: sortText}, ()=>tippyStateCB(sortChoice, overRideTippy))
                    break;
                case 'draft':
                    this.setState({filteredDraft: sorted, draftSortText: sortText}, ()=>tippyStateCB(sortChoice, overRideTippy))
                    break;
                case 'featured':
                    this.setState({filteredPublished: sorted, publishedSortText: sortText}, ()=>tippyStateCB(sortChoice, overRideTippy))
                    break;
            }
        }
        else{
            tippyStateCB(sortChoice)
        }
    }
    handleInputChange = (event, cb)=>{
        event.preventDefault()

        let {name, value} = event.target
        if(name === "newCollectionDescription"){
            if (value.length > 225){
                return
            }
        }
        cb(value)
        this.setState({[name]: value})
    }
    setCardArtState = (file, validateCB)=>{

        if(file.error){
            this.setState({cardArt: "File must be an image in a 1x1 ratio!"},()=> validateCB())
        }
        else{
            this.setState({
                cardArt: file.length > 0 ? file: null,
                cardArtPreview: file.length > 0 ? URL.createObjectURL(file[0]): null,
                cardArtError: null,
                cardArtUpdated: file.length > 0 ? true : false
            },()=> validateCB())
        }
    }
    removeCardArt =(event)=>{
        // event.preventDefault();
                    this.setState({
                        cardArt: null,
                        cardArtPreview: null,
                        cardArtError: null,
                        cardArtUpdated: false,
                        initialCardArt: null
                    })
    }
    setCoverArtState = (file, validateCB)=>{

        if(file.error){
            this.setState({coverArtError: "File must be an image in a 1x1 ratio!"})
        }
        else{
            this.setState({
                coverArt: file.length > 0 ? file: null,
                coverArtPreview: file.length > 0 ? URL.createObjectURL(file[0]): null,
                coverArtError: null,
                coverArtUpdated: file.length > 0 ? true : false
            }, ()=>validateCB())
        }
    }
    removeCoverArt =(event)=>{
        // event.preventDefault();
                    this.setState({
                        coverArt: null,
                        coverArtPreview: null,
                        coverArtError: null,
                        coverArtUpdated: false,
                        initialCoverArt: null
                    })
    }
    submitNewCollection = (cb) => {
        if(!Array.isArray(this.state.cardArt) || !Array.isArray(this.state.coverArt)){

            if(!Array.isArray(this.state.cardArt)){
                this.setState({cardArtError: "Card art required"})
            }
            if(!Array.isArray(this.state.coverArt)){
                this.setState({coverArtError: "Cover art required"})
            }
            return
        }

        if (this.state.newCollectionName?.length > 28) {
          Error({message: "Name must be at less than 28 characters"})
        }

        if(!this.state.newCollectionNameError && !this.state.newCollectionDescriptionError &&
            !this.state.cardArtError && !this.state.coverArtError){
                this.setState({isLoading: true}, ()=>{

                    let collectionBody = {
                        name: this.state.newCollectionName,
                        description: this.state.newCollectionDescription
                    }

                    api.submitNewCollection(collectionBody)
                        .then(res=>{
                            if(res.data.success) {
                                const cardData = new FormData()
                                cardData.append('file', this.state.cardArt[0])
                                api.updateCollectionCardArt(res.data.collection.id, cardData)
                                    .then(response=>{
                                        if(response.data.success){
                                            const coverData = new FormData()
                                            coverData.append('file', this.state.coverArt[0])
                                            api.updateCollectionCoverArt(res.data.collection.id, coverData)
                                                .then(resp=>{
                                                    this.setState({isLoading: false})
                                                    if(resp.data.success){
                                                        Success({message: "Collection Created!"})
                                                        cb()
                                                        window.location.reload()
                                                    }
                                                    else{
                                                        Error({message: "Error uploading cover photo!"})
                                                    }
                                                })
                                                .catch(error=>{
                                                    this.setState({isLoading: false})

                                                    Error({message: "Error uploading cover photo!"})
                                                })
                                        }
                                        else{
                                            this.setState({isLoading: false})
                                            Error({message: "Error uploading card photo"})
                                        }
                                    })
                                    .catch(error=>{
                                        this.setState({isLoading: false})
                                        Error({message: "Error uploading card photo"})
                                    })
                            }
                            else{
                                this.setState({isLoading: false})
                                Error({message: "Error creating collection"})
                            }
                        })
                        .catch(error=>{
                            this.setState({isLoading: false})
                            if(error.response?.status === 406){
                                Error({message: "Collection name taken!"})
                                this.setState({newCollectionNameError: "Collection name taken"})
                            }
                            else{
                                Error({message: "Error creating collection"})
                            }
                        })
                    })
            }
    }
    handleSearchChange= (event)=>{
        let {value} = event.target
        this.setState({searchTerm: value},()=>this.search())
    }
    search = ()=>{
        this.filterCollections(this.props.match.params.collectionsList)
    }
    filterCollections = (list)=>{
        let filteredCollections
        let allPublished;
        let allDraft
        switch(list){
            case 'published':
                allPublished = [...this.state.published]
                filteredCollections = allPublished.filter(collection=>{
                    if(collection.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())){
                        return true
                    }

                })
                this.setState({filteredPublished: filteredCollections}, ()=>this.sortBy(this.state.publishedSortChoice, this.state.publishedSortText, true, this.toggleSortTippy,undefined, false))
                break;
            case 'draft':
                allDraft = [...this.state.draft]
                filteredCollections = allDraft.filter(collection=>{
                    if(collection.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())){
                        return true
                    }

                })
                this.setState({filteredDraft: filteredCollections}, ()=>this.sortBy(this.state.draftSortChoice, this.state.draftSortText, true, this.toggleSortTippy,undefined, false))
                break;
            case 'featured':
                allPublished = [...this.state.published]
                filteredCollections = allPublished.filter(collection=>{
                    if(collection.name.toLowerCase().includes(this.state.searchTerm.toLowerCase())){
                        return true
                    }

                })
                this.setState({filteredPublished: filteredCollections}, ()=>this.sortBy(this.state.publishedSortChoice, this.state.publishedSortText, true, this.toggleSortTippy,undefined, false))
                break;
        }
    }
    playAlbum = (albumId)=>{
        let album = this.state.albums.filter(album=>album.albumId === albumId)[0]
        let trackList = album.tracks.map(track=>track.trackId)
        this.context.setPlaylist(trackList)
        this.context.loadTrackInMediaPlayer(trackList[0].trackId, trackList[0].idKey)
    }
    updateCollection = (collectionId, data)=>{
        let collections = [...this.state.collections]
        collections.forEach((collection, index)=>{
            if(collection.id === collectionId){
                collection.name = data.name || collection.name
                collection.cardArt = data.cardArt || collection.cardArt
                collection.coverArt = data.coverArt || collection.coverArt
                collection.description = data.description || collection.description
                collection.featured = data.featured === undefined ? collection.featured: data.featured
                collection.featuredWeight = data.featuredWeight || collection.featuredWeight
                collection.published = data.published === undefined ? collection.published: data.published
                collections[index]= collection
            }
        })
        let compare = (a, b)=> {
            // Use toUpperCase() to ignore character casing
            const aName = a.name.toUpperCase();
            const bName = b.name.toUpperCase();

            let comparison = 0;
            if (aName < bName) {
              comparison = -1;
            } else if (aName > bName) {
              comparison = 1;
            }
            return comparison;
          }
        collections = collections.sort(compare)
        let draftCollections = []
        let publishedCollections = collections.filter(collection=>{
            if (collection.published === 1){
                return true
            }
            else{
                draftCollections.push(collection)
            }
        })
        let featuredCollections = collections.filter(collection=>collection.featured ===1).sort((a,b)=>{
            const aWeight = a.featuredWeight
            const bWeight = b.featuredWeight
            let comparison = 0
            if(aWeight < bWeight){
                comparison = -1
            }
            else if( aWeight > bWeight){
                comparison = 1
            }
            return comparison
        })
        this.setState({collections: collections, published: publishedCollections, draft: draftCollections, featured: featuredCollections,
            filteredPublished: publishedCollections, filteredDraft: draftCollections, filteredFeatured: featuredCollections}, ()=>this.handleSearchChange({target: {value: ""}})
            )
    }
    rearrangeFeatured=(newOrder)=>{
        this.setState({filteredFeatured: newOrder})
    }
    makeFeatured = (collectionId)=>{
        api.makeFeatured(collectionId)
            .then(res=>{
                if(res.data.success){
                    this.updateCollection(collectionId, {featured: 1, featuredWeight: res.data.featuredWeight})
                }
            })
    }
    makeUnfeatured = (collectionId)=>{
        api.makeUnfeatured(collectionId)
            .then(res=>{
                if(res.data.success){
                    this.updateCollection(collectionId, {featured: 0})
                }
            })
    }
    publish = (collectionId)=>{
        api.publishCollection(collectionId)
            .then(res=>{
                if(res.data.success){
                    this.updateCollection(collectionId, {published: 1})
                }
            })
            .catch(error=>{
                if(error.response.status = 406){
                    Error({message: "Collection needs at least 5 tracks"})
                }
                else{
                    Error({message: "Error publishing collection"})
                }
            })
    }
    unpublish = (collectionId)=>{
        api.unpublishCollection(collectionId)
            .then(res=>{
                if(res.data.success){
                    this.updateCollection(collectionId, {published: 0, featured: 0})
                }
            })
    }
    setSort = (sortable)=>{


        this.updateSceneOrder(sortable)
    }
    updateSceneOrder = (sortable)=>{

        let featuredOrder = sortable.toArray()
        featuredOrder.forEach((collectionId, index)=>{
                api.updateFeaturedOrder(collectionId, {featuredWeight: index})
                    .then(res=>{

                    })
                    .catch(error=>{

                    })
        })
    }
    render() {
        return (
                        <div className="container">

                            {/* {# App Header #} */}
                            <div class="view-header">
                                <h1 class="view-title">Collections</h1>
                                <div class="view-header-controls">
                                    <MicroModal
                                        trigger={handleOpen => <button
                                                                    onClick={handleOpen}
                                                                    class="btn btn-primary"
                                                                    type="button"
                                                                    data-micromodal-trigger="new_coll_modal">
                                                                    <span class="icon-plus mr-5"></span> New Collection
                                                                </button>}
                                          closeOnOverlayClick={true}
                                          closeOnAnimationEnd={true}
                                          modalOverlayClassName="modal-overlay"
                                          modalClassName="modal micromodal-slide is-open"
                                          modalOverlayStyles={null}
                                          children={handleClose=><CreateCollectionModal handleClose={()=>{handleClose()}} handleInputChange={this.handleInputChange} newCollectionName={this.state.newCollectionName} newCollectionDescription={this.state.newCollectionDescription}
                                                submitNewProject={this.submitNewProject} newCollectionNameError={this.state.newCollectionNameError} setCoverArtState={this.setCoverArtState}  coverArt={this.state.coverArt} coverArtPreview={this.state.coverArtPreview} coverArtError={this.state.coverArtError}
                                                removeCoverArt={this.removeCoverArt} setCardArtState={this.setCardArtState}  cardArt={this.state.cardArt} cardArtPreview={this.state.cardArtPreview} cardArtError={this.state.cardArtError}
                                                removeCardArt={this.removeCardArt} onSubmit={this.submitNewCollection} isLoading={this.state.isLoading}/>}
                                          containerStyles={{background: "#131313", padding:"asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box"}}
                                    />

                                </div>
                            </div>

                            {/* {# Tabs #} */}
                            {/* {% import "components/tracks/tracks-tabs.html" as nav %}
                                    {{ nav.active('Approved') }} */}
                            <nav className="tabs mb-40">
                                <ul className="tab-items">
                                    <li className={`tab-item ${this.props.match.params.collectionsList.toLowerCase() === "published" ? "active" : ""}`}>
                                        <NavLink to="/dashboard/collections/published" className='tab-item-title' >Published</NavLink>
                                    </li>
                                    <li className={`tab-item ${this.props.match.params.collectionsList.toLowerCase() === "draft" ? "active" : ""}`}>
                                        <NavLink to="/dashboard/collections/draft" className="tab-item-title">Draft</NavLink>
                                    </li>
                                    <li className={`tab-item ${this.props.match.params.collectionsList.toLowerCase() === "featured" ? "active" : ""}`}>
                                        <NavLink to="/dashboard/collections/featured" className="tab-item-title">Featured</NavLink>
                                    </li>
                                </ul>
                            </nav>
                                {this.props.match.params.collectionsList==="featured" &&
                                    <FeaturedList collections={this.state.filteredFeatured} makeUnfeatured={this.makeUnfeatured} rearrangeFeatured={this.rearrangeFeatured} setSort={this.setSort}/>
                                }

                                <SearchBar value={this.state.searchTerm} onChange={this.handleSearchChange} placeholder={"Search by name..."} />
                                {(this.props.match.params.collectionsList === "published" || this.props.match.params.collectionsList === "featured") &&
                                   <SortBar sortFilters={collectionFilters} sortBarId={"publishedCollections"} resultsText={`${this.state.filteredPublished.length} Collections`} sortCriteria={this.state.publishedSortText} marginClasses={"mb-30"} toggleSortTippy={this.toggleSortTippy} isSortTippyOpen={this.state.isSortTippyOpen} sortBy={this.sortBy}
                                    sortTippyCB={this.toggleSortTippy}/>
                                }
                                {this.props.match.params.collectionsList === "draft" &&
                                   <SortBar sortFilters={collectionFilters} sortBarId={"draftCollections"} resultsText={`${this.state.filteredDraft.length} Collections`} sortCriteria={this.state.draftSortText} marginClasses={"mb-30"} toggleSortTippy={this.toggleSortTippy} isSortTippyOpen={this.state.isSortTippyOpen} sortBy={this.sortBy}
                                    sortTippyCB={this.toggleSortTippy}/>
                                }
                                {/* {this.props.match.params.collectionsList === "featured" &&
                                   <SortBar sortFilters={collectionFilters} sortBarId={"featuredCollections"} resultsText={`${this.state.filteredFeatured.length} Collections`} sortCriteria={this.state.featuredSortText} marginClasses={"mb-30"} toggleSortTippy={this.toggleSortTippy} isSortTippyOpen={this.state.isSortTippyOpen} sortBy={this.sortBy}
                                    sortTippyCB={this.toggleSortTippy}/>
                                } */}


                            {/* {# Tracks List #} */}

                                {/* // {# Track List Card #} */}
                                {this.props.match.params.collectionsList==="published" &&
                                    <div class="grid-row">
                                        {this.state.filteredPublished.map(collection=>
                                        <div key={"publishedCollection-" + collection.id} className="col w-full  sm:w-1/2 md:w-1/3 mb-20 md:mb-40">
                                            <CollectionsCard  key={"publishedCollectionCard-" + collection.id}{...collection} unpublish={this.unpublish} updateCollection={this.updateCollection}/>
                                        </div>)}
                                    </div>
                                }
                                {this.props.match.params.collectionsList==="draft" &&
                                    <div class="grid-row">
                                        {this.state.filteredDraft.map(collection=>
                                        <div key={"draftCollection-" + collection.id} className="col w-full  sm:w-1/2 md:w-1/3 mb-20 md:mb-40">
                                            <CollectionsCard  key={"draftCollectionCard-" + collection.id}{...collection} publish={this.publish} updateCollection={this.updateCollection}/>
                                        </div>)}
                                    </div>
                                }
                                {this.props.match.params.collectionsList==="featured" &&
                                    <div class="grid-row">
                                        {this.state.filteredPublished.filter(collection=>collection.featured !==1).map(collection=>
                                        <div key={"featuredCollection-" + collection.id} className="col w-full  sm:w-1/2 md:w-1/3 mb-20 md:mb-40">
                                            <CollectionsCard  key={"featuredCollectionCard-" + collection.id}{...collection} forFeatured={true} makeFeatured={this.makeFeatured} unpublish={this.unpublish} updateCollection={this.updateCollection}/>
                                        </div>)}
                                    </div>
                                }
                                    </div>
                    )
    }

}

export default AdminCollections

import React from "react";
import AppLayout from "./appLayout";

const DashboardLayout = ({ children, HeaderControls = null, title = "" }) => {
  return (
    <AppLayout>
      {/* {# App Main #} */}
      <div className="app-main">
        <div className="container">
          {/* {# App Header #} */}
          <div className="view-header">
            <h1 className="view-title">{title}</h1>
            {HeaderControls ? (
              <div className="view-header-controls">
                <HeaderControls />
              </div>
            ) : null}
          </div>

          {children}
        </div>
      </div>
    </AppLayout>
  );
};

export default DashboardLayout;

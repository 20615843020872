import { useEffect } from 'react';
import { Error } from '../Toast';

function useFormErrorsToast(errors) {
  // console.log(errors);

  useEffect(() => {
    // Show all errors as Toastify
    Object.entries(errors).map((error) => (
        Error({
            message: error[1].message,
            toastId: error[0]
        })
    ))
  },[errors]);

  return true;
}

export {
  useFormErrorsToast
}

import React from "react";
import { Helmet } from "react-helmet";
import MicroModal from "react-micro-modal";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { IntercomProvider } from "react-use-intercom";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";
import { CartProvider } from "./contexts/Cart";
import About from "./components/About";
import Account from "./components/Account";
import AdminRoute from "./components/AdminRoute";
import ArtistAlbums from "./components/Albums/artistAlbums";
import Artist from "./components/Artist";
import ArtistRoute from "./components/ArtistRoute";
import ArtistSubmission from "./components/ArtistSubmission";
import ArtistTrackList from "./components/ArtistTrackList";
import Blog from "./components/Blog";
import BlogPage from "./components/Blog/page";
import BrowseArtists from "./components/Browse/artists";
import BrowseTracks from "./components/Browse/tracks";
import Checkout from "./components/Checkout";
import CheckoutComplete from "./components/Checkout/Complete";
import AdminCollections from "./components/Collections/adminMain";
import EditCollection from "./components/Collections/editCollection";
import PublicCollection from "./components/Collections/publicCollection";
import PublicCollections from "./components/Collections/publicCollections";
import ConfirmEmail from "./components/confirmEmail";
import CustomerLicenses from "./components/CustomerLicenses";
import Dashboard from "./components/Dashboard";
import { Favs as Favorites } from "./components/Favorites";
import Help from "./components/Help";
import { HomepageWrapped as Homepage } from "./components/Homepage/index.js";
import Intercom from "./components/Intercom";
import AppLayout from "./components/Layouts/appLayout";
import AppLayoutNoContent from "./components/Layouts/appLayoutNoContent";
import ArtistDetailLayout from "./components/Layouts/artistDetailLayout";
import AssetReports from "./components/ContentID/AssetReports/AssetReports.js";
import RoyaltyPayments from "./components/ContentID/RoyaltyPayments/RoyaltyPayments.js";
import BrowseLayout from "./components/Layouts/browseLayout";
import CheckoutLayout from "./components/Layouts/checkoutLayout";
import ContentID from "./components/ContentID/index.js";
import FormLayout from "./components/Layouts/formLayout";
import OnboardingLayout from "./components/Layouts/onboardingLayout";
import PublicDetailLayout from "./components/Layouts/publicDetailLayout";
import SignUpLayout from "./components/Layouts/signUpLayout";
import Login from "./components/Login";
import Logout from "./components/Logout";
import LoginModal from "./components/modal/loginModal";
import VideoStudioComponent from "./components/modal/videoStudio";
import MobileBottomNav from "./components/Navbar/mobileBottomNav";
import NoSubRoute from "./components/NoSubRoute";
import NoMatch from "./components/NotFound";
import PendingTracks from "./components/pendingTracks";
import Player from "./components/Player";
import Pricing from "./components/Pricing";
import Privacy from "./components/Privacy";
import { ProjectsWrapped as Projects } from "./components/Projects/index.js";
import { ProjectDetailWrapped as ProjectDetail } from "./components/Projects/projectDetail";
import PublicPage from "./components/Projects/publicPage";
import ProtectedRoute from "./components/ProtectedRoute";
import PublicRoute from "./components/PublicRoute";
import SignupRoute from "./components/SignupRoute";
import ArtistSales from "./components/Sales/SalesSummary";
import SalesContentID from "./components/Sales/ContentID/SalesContentID.js";
import Searches from "./components/Searches";
import SignUp from "./components/SignUp";
import SignUpUser from "./components/SignUp/signUpUser";
import SignUpUserConfirm from "./components/SignUp/signUpUserConfirm";
import SignUpUserDetails from "./components/SignUp/signUpUserDetails";
import ArtistStats from "./components/Stats";
import StartStripeOnboard from "./components/stripeOnboard";
import CompleteStripeOnboard from "./components/stripeOnboard/complete";
import AdminApproveTrack from "./components/SubmitTrack/adminApproval";
import ArtistPendingTrack from "./components/SubmitTrack/artistPending";
import ArtistResubmitTrack from "./components/SubmitTrack/artistResubmit";
import ArtistSubmitTrack from "./components/SubmitTrack/artistSubmit";
import SubscriptionPayouts from "./components/SubscriptionPayouts/index";
import SubscriptionPayoutDetail from "./components/SubscriptionPayouts/payoutDetail";
import Tags from "./components/Tags";
import Terms from "./components/Terms";
import DMCA from "./components/DMCA";
import LicenseTerms from "./components/Terms/license";
import Details from "./components/Track/details";
import QuoteRequestComplete from "./components/QuoteRequests/QuoteRequestComplete";
import ResetPassword from "./components/userOptions/resetPassword";
import Users from "./components/Users";
import UserEdit from "./components/Users/userEdit";
import SitemapGenerator from "./components/Sitemap/index";
import { FavoritesProvider } from "./FavoritesContext";
import { PlayerProvider } from "./PlayerContext";
import { ProjectsProvider } from "./ProjectsContext";
import { SubscriptionProvider } from "./contexts/Subscription";
import { UserConsumer, UserProvider } from "./contexts/User";
import { LicenseProvider } from "./contexts/License";
import DashboardLayout from "./components/Layouts/DashboardLayout.js";
import SalesSubnav from "./components/Sales/SalesSubnav.js";
import SalesLicenses from "./components/Sales/Licenses/SalesLicenses.js";
import SalesSubscriptionPayouts from "./components/Sales/SubscriptionPayouts/SalesSubscriptionPayouts.js";
import SalesTransfers from "./components/Sales/Transfers/SalesTransfers.js";
import Payouts from "./components/ContentID/Payouts/Payouts.js";

const gtmParams = {
  id: process.env.REACT_APP_GTM_ID,
  environment: {
    gtm_auth: process.env.REACT_APP_GTM_AUTH,
    gtm_preview: process.env.REACT_APP_GTM_PREVIEW,
  },
};
const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_ID;

// Close Button
const CloseButton = ({ closeToast }) => (
  <button className="toast-close circle-icon circle-icon-sm text-icon-sm" onClick={closeToast}>
    <span className="icon-close" />
  </button>
);
function App(props) {
  return (
    <>
      <Helmet
        title="Deep Sounds | Music for Creators"
        meta={[
          {
            property: "og:image",
            content: "https://deepsounds-site-photos.s3.us-east-2.amazonaws.com/deepsounds-og.jpg",
          },
        ]}
      />
      <Router>
        <GTMProvider state={gtmParams}>
          <IntercomProvider appId={INTERCOM_APP_ID}>
            <UserProvider>
              <ProjectsProvider>
                <PlayerProvider>
                  <FavoritesProvider>
                    <CartProvider>
                      <SubscriptionProvider>
                        <LicenseProvider>
                          <Switch>
                            <PublicRoute
                              exact
                              path="/"
                              component={(props) => (
                                <PublicDetailLayout>
                                  <Homepage {...props} />
                                </PublicDetailLayout>
                              )}
                            />
                            <PublicRoute
                              exact
                              path="/blog"
                              component={(props) => (
                                <AppLayoutNoContent>
                                  <Blog {...props} />
                                </AppLayoutNoContent>
                              )}
                            />
                            <PublicRoute
                              exact
                              path="/blog/tags/:slug"
                              component={(props) => (
                                <AppLayoutNoContent>
                                  <BlogPage {...props} />
                                </AppLayoutNoContent>
                              )}
                            />
                            <PublicRoute
                              exact
                              path="/blog/:slug"
                              component={(props) => (
                                <AppLayoutNoContent>
                                  <BlogPage {...props} />
                                </AppLayoutNoContent>
                              )}
                            />
                            <PublicRoute
                              exact
                              path="/pricing"
                              component={(props) => (
                                <AppLayoutNoContent>
                                  <Pricing {...props} />
                                </AppLayoutNoContent>
                              )}
                            />
                            <PublicRoute
                              exact
                              path="/terms"
                              component={() => (
                                <PublicDetailLayout>
                                  <Terms />
                                </PublicDetailLayout>
                              )}
                            />
                            <PublicRoute
                              exact
                              path="/terms/license"
                              component={() => (
                                <PublicDetailLayout>
                                  <LicenseTerms />
                                </PublicDetailLayout>
                              )}
                            />
                            <PublicRoute
                              exact
                              path="/terms/:termsType"
                              component={() => (
                                <PublicDetailLayout>
                                  <Terms />
                                </PublicDetailLayout>
                              )}
                            />
                            <PublicRoute
                              exact
                              path="/about-us"
                              component={() => (
                                <AppLayoutNoContent>
                                  <About />
                                </AppLayoutNoContent>
                              )}
                            />
                            <PublicRoute
                              exact
                              path="/artist-submissions"
                              component={() => (
                                <AppLayoutNoContent>
                                  <ArtistSubmission />
                                </AppLayoutNoContent>
                              )}
                            />
                            <PublicRoute
                              exact
                              path="/privacy"
                              component={() => (
                                <PublicDetailLayout>
                                  <Privacy />
                                </PublicDetailLayout>
                              )}
                            />
                            <PublicRoute
                              exact
                              path="/help"
                              component={() => (
                                <AppLayoutNoContent>
                                  <Help />
                                </AppLayoutNoContent>
                              )}
                            />
                            <PublicRoute
                              exact
                              path="/dmca"
                              component={() => (
                                <AppLayoutNoContent>
                                  <DMCA />
                                </AppLayoutNoContent>
                              )}
                            />
                            <PublicRoute path="/login" component={Login} />
                            <PublicRoute exact path="/logout" component={Logout} />
                            <PublicRoute path="/login/forgotpassword" component={Login} />
                            <PublicRoute
                              path="/user/resetPassword/:token"
                              component={(props) => (
                                <OnboardingLayout>
                                  <ResetPassword {...props} />
                                </OnboardingLayout>
                              )}
                            />
                            {/* <Route path="/user/updatePassword" component={UpdatePassword}/> */}
                            <PublicRoute
                              exact
                              path="/signup"
                              component={(props) => (
                                <OnboardingLayout>
                                  <SignUpUser {...props} />
                                </OnboardingLayout>
                              )}
                            />
                            {/* <Route exact path="/signup" component={(props)=> (<OnboardingLayout><SignUpUser {...props} /></OnboardingLayout>)}/> */}
                            <PublicRoute
                              exact
                              path="/signup/user"
                              component={(props) => (
                                <OnboardingLayout>
                                  <SignUpUserDetails {...props} />
                                </OnboardingLayout>
                              )}
                            />
                            <ProtectedRoute
                              exact
                              path="/signup/confirm"
                              permission="access user features"
                              component={(props) => (
                                <OnboardingLayout>
                                  <SignUpUserConfirm {...props} />
                                </OnboardingLayout>
                              )}
                            />
                            <SignupRoute
                              exact
                              path="/signup/:step"
                              permission="access user features"
                              component={(props) => (
                                <SignUpLayout>
                                  <SignUp {...props} />
                                </SignUpLayout>
                              )}
                            />
                            <PublicRoute
                              path="/music"
                              component={(props) => (
                                <BrowseLayout>
                                  <BrowseTracks {...props} />
                                </BrowseLayout>
                              )}
                            />
                            <PublicRoute
                              exact
                              path="/artists"
                              component={(props) => (
                                <BrowseLayout>
                                  <BrowseArtists {...props} />
                                </BrowseLayout>
                              )}
                            />
                            <PublicRoute
                              path="/artists/:artistURL"
                              component={(props) => (
                                <ArtistDetailLayout>
                                  <Artist {...props} />
                                </ArtistDetailLayout>
                              )}
                            />
                            {/* <Route path="/testtrackform/:trackId" component={TestTrackForm}/> */}
                            <PublicRoute
                              path="/tracks/:trackURL"
                              component={(props) => (
                                <PublicDetailLayout>
                                  <Details {...props} />
                                </PublicDetailLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access artist features"
                              artistRequired={true}
                              exact
                              path="/stripeOnboard/start"
                              component={StartStripeOnboard}
                            />
                            <ProtectedRoute
                              permission="access artist features"
                              artistRequired={true}
                              exact
                              path="/stripeOnboard/complete"
                              component={CompleteStripeOnboard}
                            />
                            <ProtectedRoute
                              permission="access artist features"
                              path="/dashboard/tracks/:status"
                              component={(props) => (
                                <FormLayout>
                                  <ArtistTrackList {...props} />
                                </FormLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access user features"
                              exact
                              path="/dashboard"
                              confirmReq={false}
                              component={(props) => (
                                <DashboardLayout title="Dashboard">
                                  <Dashboard {...props} />
                                </DashboardLayout>
                              )}
                            />
                            <PublicRoute
                              exact
                              path="/collections"
                              component={(props) => (
                                <AppLayoutNoContent>
                                  <PublicCollections {...props} />
                                </AppLayoutNoContent>
                              )}
                            />
                            <PublicRoute
                              exact
                              path="/collections/:collectionId"
                              component={(props) => (
                                <PublicDetailLayout>
                                  <PublicCollection {...props} />
                                </PublicDetailLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access artist features"
                              artistRequired={true}
                              path="/dashboard/new-track/:trackId"
                              confirmReq={true}
                              component={(props) => (
                                <FormLayout>
                                  <ArtistSubmitTrack {...props} />
                                </FormLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access artist features"
                              artistRequired={true}
                              path="/dashboard/resubmit-track/:trackId"
                              confirmReq={true}
                              component={(props) => (
                                <FormLayout>
                                  <ArtistResubmitTrack {...props} />
                                </FormLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access artist features"
                              artistRequired={true}
                              path="/dashboard/pending-track/:trackId"
                              confirmReq={true}
                              component={ArtistPendingTrack}
                            />
                            <ProtectedRoute
                              permission="access artist features"
                              artistRequired={true}
                              path="/dashboard/approve-track/:trackId"
                              confirmReq={true}
                              component={(props) => (
                                <FormLayout>
                                  <AdminApproveTrack {...props} />
                                </FormLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access user features"
                              path="/dashboard/favorites/:favoriteList"
                              confirmReq={true}
                              component={(props) => (
                                <AppLayout>
                                  <Favorites {...props} />
                                </AppLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access artist features"
                              path="/pendingtracks"
                              confirmReq={true}
                              component={PendingTracks}
                            />
                            <ProtectedRoute
                              permission="access artist features"
                              artistRequired={true}
                              path="/dashboard/albums/:status"
                              confirmReq={true}
                              component={(props) => (
                                <AppLayout>
                                  <ArtistAlbums {...props} />
                                </AppLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access user features"
                              path="/dashboard/projects/edit/:id"
                              confirmReq={true}
                              component={(props) => (
                                <FormLayout>
                                  <ProjectDetail {...props} />
                                </FormLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access user features"
                              exact
                              path="/dashboard/licenses/"
                              confirmReq={true}
                              component={(props) => (
                                <AppLayout>
                                  <CustomerLicenses {...props} />
                                </AppLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access admin features"
                              exact
                              path="/dashboard/content-id/"
                              confirmReq={true}
                              component={(props) => <ContentID {...props} />}
                            />
                            <ProtectedRoute
                              permission="access admin features"
                              exact
                              path="/dashboard/content-id/asset-reports"
                              confirmReq={true}
                              component={(props) => <AssetReports {...props} />}
                            />
                            <ProtectedRoute
                              permission="access admin features"
                              exact
                              path="/dashboard/content-id/royalty-payments"
                              confirmReq={true}
                              component={(props) => <RoyaltyPayments {...props} />}
                            />
                            {[
                              "/dashboard/content-id/payouts",
                              "/dashboard/content-id/payouts/:page",
                            ].map((path) => (
                              <ProtectedRoute
                                permission="access admin features"
                                exact
                                path={path}
                                confirmReq={true}
                                component={(props) => (
                                  <DashboardLayout title="Payouts">
                                    <Payouts {...props} />
                                  </DashboardLayout>
                                )}
                              />
                            ))}
                            <PublicRoute
                              path="/projects/public/:url"
                              confirmReq={true}
                              component={(props) => (
                                <ArtistDetailLayout>
                                  <PublicPage {...props} />
                                </ArtistDetailLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access user features"
                              path="/dashboard/projects/:filter"
                              confirmReq={true}
                              component={(props) => (
                                <FormLayout>
                                  <Projects {...props} />
                                </FormLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access admin features"
                              path="/dashboard/collections/edit/:collectionId"
                              component={(props) => (
                                <AppLayout>
                                  <EditCollection {...props} />
                                </AppLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access admin features"
                              path="/dashboard/collections/:collectionsList"
                              component={(props) => (
                                <AppLayout>
                                  <AdminCollections {...props} />
                                </AppLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access admin features"
                              path="/dashboard/collections/edit/:collectionId"
                              component={(props) => (
                                <AppLayout>
                                  <AdminCollections {...props} />
                                </AppLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access admin features"
                              exact
                              path="/dashboard/tags"
                              component={() => (
                                <AppLayout>
                                  <Tags />
                                </AppLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access user features"
                              path="/user/confirmEmail"
                              confirmReq={false}
                              component={ConfirmEmail}
                            />
                            <ProtectedRoute
                              permission="access user features"
                              exact
                              path="/checkout/complete/:orderNumber"
                              confirmReq={false}
                              component={(props) => (
                                <CheckoutLayout>
                                  <CheckoutComplete {...props} />
                                </CheckoutLayout>
                              )}
                            />
                            <PublicRoute
                              exact
                              path="/checkout/subscription/:type/:recurring"
                              confirmReq={false}
                              component={(props) => (
                                <CheckoutLayout>
                                  <Checkout {...props} />
                                </CheckoutLayout>
                              )}
                            />
                            <PublicRoute
                              exact
                              path="/checkout"
                              confirmReq={true}
                              component={(props) => (
                                <CheckoutLayout>
                                  <Checkout {...props} />
                                </CheckoutLayout>
                              )}
                            />
                            {/* <NoSubRoute
                            exact
                            path="/subscription-checkout"
                            component={(props) => (
                              <CheckoutLayout>
                                <SubscriptionCheckout {...props} />
                              </CheckoutLayout>
                            )}
                          />
                          <ProtectedRoute
                            permission="access user features"
                            exact
                            path="/subscription-upgrade-checkout"
                            component={(props) => (
                              <CheckoutLayout>
                                <SubscriptionUpgradeCheckout {...props} />
                              </CheckoutLayout>
                            )}
                          /> */}
                            {/* <ProtectedRoute
                            permission="access user features"
                            path="/subscription-checkout/complete/:orderNumber"
                            component={(props) => (
                              <CheckoutLayout>
                                <CompleteSubscription {...props} />
                              </CheckoutLayout>
                            )}
                          /> */}
                            {/* <ProtectedRoute
                            permission="access user features"
                            path="/cart"
                            confirmReq={true}
                            component={Cart}
                          /> */}
                            <ProtectedRoute
                              permission="access admin features"
                              exact
                              path="/dashboard/users/:status"
                              component={(props) => (
                                <FormLayout>
                                  <Users {...props} />
                                </FormLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access admin features"
                              exact
                              path="/dashboard/users/edit/:id"
                              component={(props) => (
                                <FormLayout>
                                  <UserEdit {...props} />
                                </FormLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access user features"
                              path="/dashboard/account/:accountSub"
                              component={(props) => (
                                <FormLayout>
                                  <Account {...props} />
                                </FormLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access artist features"
                              exact
                              path="/dashboard/sales"
                              confirmReq={true}
                              component={() => (
                                <DashboardLayout title="Sales & Payouts">
                                  <SalesSubnav />
                                  <ArtistSales />
                                </DashboardLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access artist features"
                              exact
                              path="/dashboard/sales/licenses"
                              confirmReq={true}
                              component={() => (
                                <DashboardLayout title="Single Track Licenses">
                                  <SalesSubnav />
                                  <SalesLicenses />
                                </DashboardLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access artist features"
                              exact
                              path="/dashboard/sales/licenses/:page"
                              confirmReq={true}
                              component={() => (
                                <DashboardLayout title="Single Track Licenses">
                                  <SalesSubnav />
                                  <SalesLicenses />
                                </DashboardLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access artist features"
                              exact
                              path="/dashboard/sales/subscription-royalties"
                              confirmReq={true}
                              component={() => (
                                <DashboardLayout title="Subscription Royalties">
                                  <SalesSubnav />
                                  <SalesSubscriptionPayouts />
                                </DashboardLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access artist features"
                              exact
                              path="/dashboard/sales/subscription-royalties/:page"
                              confirmReq={true}
                              component={() => (
                                <DashboardLayout title="Subscription Royalties">
                                  <SalesSubnav />
                                  <SalesSubscriptionPayouts />
                                </DashboardLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access artist features"
                              exact
                              path="/dashboard/sales/content-id"
                              confirmReq={true}
                              component={() => (
                                <DashboardLayout title="Content ID Royalties">
                                  <SalesSubnav />
                                  <SalesContentID />
                                </DashboardLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access artist features"
                              exact
                              path="/dashboard/sales/content-id/:page"
                              confirmReq={true}
                              component={() => (
                                <DashboardLayout title="Content ID Royalties">
                                  <SalesSubnav />
                                  <SalesContentID />
                                </DashboardLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access artist features"
                              exact
                              path="/dashboard/sales/bonus-earnings"
                              confirmReq={true}
                              component={() => (
                                <DashboardLayout title="Bonus Earnings">
                                  <SalesSubnav />
                                  <SalesTransfers />
                                </DashboardLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access artist features"
                              exact
                              path="/dashboard/sales/bonus-earnings/:page"
                              confirmReq={true}
                              component={() => (
                                <DashboardLayout title="Bonus Earnings">
                                  <SalesSubnav />
                                  <SalesTransfers />
                                </DashboardLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access artist features"
                              path="/dashboard/stats"
                              confirmReq={true}
                              component={() => (
                                <AppLayout>
                                  <ArtistStats />
                                </AppLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access admin features"
                              exact
                              path="/dashboard/sitemap"
                              component={(props) => (
                                <FormLayout>
                                  <SitemapGenerator {...props} />
                                </FormLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access admin features"
                              exact
                              path="/dashboard/artist-payouts"
                              component={(props) => (
                                <AppLayout>
                                  <SubscriptionPayouts {...props} />
                                </AppLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access admin features"
                              exact
                              path="/dashboard/artist-payouts/:payoutId"
                              component={(props) => (
                                <AppLayout>
                                  <SubscriptionPayoutDetail {...props} />
                                </AppLayout>
                              )}
                            />
                            <ProtectedRoute
                              permission="access admin features"
                              path="/dashboard/searches"
                              confirmReq={true}
                              component={(props) => (
                                <AppLayout>
                                  <Searches {...props} />
                                </AppLayout>
                              )}
                            />
                            <PublicRoute
                              exact
                              path="/quote/complete/:quoteNumber/:email"
                              component={() => (
                                <AppLayoutNoContent>
                                  <QuoteRequestComplete />
                                </AppLayoutNoContent>
                              )}
                            />
                            <PublicRoute
                              component={(props) => (
                                <AppLayout noSide={true}>
                                  <NoMatch />
                                </AppLayout>
                              )}
                            />
                          </Switch>
                          <VideoStudioComponent />
                          <Intercom />

                          {/* modal should be rendered here */}
                          <div className="app-dock">
                            {/* player context wrapper */}
                            <Player></Player>
                            <MobileBottomNav></MobileBottomNav>
                          </div>
                          {/* <div className="toast-container" style={{zIndex: 7003}}> */}
                          <ToastContainer
                            className="toast-container"
                            closeButton={CloseButton}
                            style={{ zIndex: 7003 }}
                          />
                          {/* </div> */}
                          <UserConsumer>
                            {({ isLoginModalOpen, handleLoginModalClose }) => (
                              <>
                                <MicroModal
                                  open={isLoginModalOpen}
                                  closeOnEscapePress={true}
                                  handleClose={() => handleLoginModalClose()}
                                  closeOnOverlayClick={true}
                                  closeOnAnimationEnd={true}
                                  modalOverlayClassName="modal-overlay"
                                  modalClassName="modal micromodal-slide is-open"
                                  modalOverlayStyles={null}
                                  children={(handleClose) => (
                                    <LoginModal handleClose={handleClose} />
                                  )}
                                  containerStyles={{
                                    background: "#131313",
                                    padding: "asdf",
                                    maxWidth: "28rem",
                                    maxHeight: "95vh",
                                    borderRadius: "asdf",
                                    overflowY: "auto",
                                    boxSizing: "border-box",
                                  }}
                                />
                              </>
                            )}
                          </UserConsumer>
                        </LicenseProvider>
                      </SubscriptionProvider>
                    </CartProvider>
                  </FavoritesProvider>
                </PlayerProvider>
                {/* )} */}
                {/* </UserConsumer>   */}
              </ProjectsProvider>
            </UserProvider>
          </IntercomProvider>
        </GTMProvider>
      </Router>
    </>
  );
}

export default App;

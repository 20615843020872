import buildBaseURL from "./buildBaseURL";

const buildCollectionURL = (collectionURL) => {
  const baseURL = buildBaseURL();

  let collectionsPathPrefix = 'collections';

  const fullURL = baseURL + '/' + collectionsPathPrefix + '/' + collectionURL;

  return fullURL;
}
export default buildCollectionURL;

import React, { Component } from "react";
import { components } from "react-select";
// import { val } from "objection";
// import { select } from "@tailwindcss/custom-forms/src/defaultOptions";
import { ProjectsContext } from "../../ProjectsContext";
import { Error, Success, SuccessRedirect } from "../Toast/index";
import api from "../utils/api";

class CheckoutAddToProject extends Component{

    state = {
        projectTypes: [{label: "Commercial", value: 1}, {label: "Movie", value: 2}],
        myProjects: [],
        sharedProjects: [],
        projectsOptions: [],
        currentProject: [],
        sceneOptions: [],
        newSceneTitle: "Scene 1",
        newProjectTitle: ""
    }

    handleProjectChange= (selectedProject)=>{
        let sceneOptions =[]
        let selectedProjInfo = this.state.allProjects.filter(project=>project.id === selectedProject.value)
        selectedProjInfo[0].scenes.forEach(scene=>{
          sceneOptions.push({value: scene.id, label: scene.name})
        })
        this.setState({currentProject: selectedProject, sceneOptions: sceneOptions, currentScene: sceneOptions[0]})
    }
    handleSceneChange = (selectedScene)=>{
      this.setState({currentScene: selectedScene})
    }
    handleProjectTypeChange= (selectedType)=>{
      if(selectedType){
        this.setState({newProjectType: selectedType, projectTypeError: ""})
      }
    }
    handleInputChange = (event)=>{
      let {name, value} = event.target

      if(name === "newProjectTitle"){
        let existingProjectNames = this.state.myProjects.map(project=>project.name)
        let isNameTaken = existingProjectNames.filter(name=>name?.toUpperCase()===value?.toUpperCase()).length > 0

        if(isNameTaken){
            this.setState({newProjectNameError: "Project name already used. Please choose a different name"})
        }
        else if(value.length < 1){
            this.setState({newProjectNameError: "Project name required"})
        }
        else{
            this.setState({newProjectNameError: ""})
        }
      }
      if(name === "newSceneTitle"){
       if(value.length < 1){
            this.setState({newProjectSceneError: "Scene name required"})
        }
        else{
            let error = this.checkIfUsed(value)
            if(error){
              this.setState({newProjectSceneError: error})
            }
            else{
              this.setState({newProjectSceneError: ""})
            }
        }
      }
      this.setState({[name]: value})
    }
    submitNewProject = (cb)=>{
        let projectBody = {
            name: this.state.newProjectName,
            client: this.state.newProjectClient,
            projectType: this.state.newProjectType.value,
        }
        api.submitNewProject(projectBody)
            .then(res=>{
                if(res.data.success){
                    SuccessRedirect({message:"Project Created!", onClose: ()=>this.successToastSubmit(res.data.project.id)})
                }
                else{
                    Error({message:"An error occurred!", onClose: this.errorToast})
                }
                cb()
            })
            .catch(error=>{
                Error({message:"An error occurred!", onClose: this.errorToast})
            })
    }
    checkIfUsed = (value)=>{
      if(this.state.newProject){
        return false
      }
      let sceneNames = this.state.sceneOptions.map(scene=>scene.label)
      let isInSceneNames =sceneNames.filter(name=>name.toUpperCase() === value.toUpperCase()).length > 0
      if(isInSceneNames){
          return "Scene name already used"
      }
      else{
          return false
      }
  }
    addToProject = (handleClose) => {
          if(!this.state.newProjectNameError){
            let existingProjectNames = this.state.myProjects.map(project=>project.name)
            let isNameTaken = existingProjectNames.filter(name=>name===this.state.newProjectTitle).length > 0
            let errors
            if(isNameTaken){
              this.setState({newProjectNameError: "Project name already used. Please choose a different name"})
              errors = true
            }
            else if(this.state.newProjectTitle.length < 1){
              this.setState({newProjectNameError: "Project name required"})
              errors = true
            }

            if(errors){
               return true
            }
            let projectBody = {
              name: this.state.newProjectTitle,
              client: "",
              projectType: 15,
              sceneName: "Scene 1",
            }
            api.addTrackToNewProject(projectBody, this.props.trackId)
              .then(res=>{
                if(res.data.success){
                  Success({message: `${this.props.trackTitle} added to ${this.state.newProjectTitle}`})
                  this.props.associateProjectToCartItem(this.props.currentItemIndex, res.data.project.id, res.data.project.name)
                  handleClose()
                  document.cookie = `latestProject=${res.data.project.id}; path=/`
                  document.cookie = `latestScene=${res.data.project.scenes[0].id}; path=/`
                  this.context.addToList(res.data.project.id)
                }
                else{
                  Error({message: "Error adding track!"})
                }
              })
              .catch(error=>{
                if(error.response?.data === "Project Name Already Exists"){
                  Error({message: "Project Name Already Exists"})
                }
                else if(error.response?.data){
                  Error({message: error.response.data})
                }
                else{
                  Error({message: "Error adding track!"})

                }

              })
          }
          else{
            Error({message:"An error occurred!", onClose: this.errorToast})
            if(!this.state.newProjectType){
                this.setState({projectTypeError: "Please select a project type"})
            }
            if(!this.state.newProjectTitle){
              this.setState({newProjectNameError: "Project name required"})
            }
            if(!this.state.newSceneTitle){
              this.setState({newProjectSceneError: "Scene name required"})
            }
        }

    }

    componentDidMount = ()=>{
        if(this.props.toggleTrackTippy){
          this.props.toggleTrackTippy()
        }
        let projectTypes = []

        this.setState(this.context)
    }

    render(){
        return(
            <React.Fragment>
                <form onSubmit={(e)=>{e.preventDefault();this.addToProject(this.props.handleClose)}} action="" className="ds-form">
                                {/* {# New Project Fields #} */}

                                      {/* {# Project Title #} */}
                                      <div className="input-group input-inline">
                                        <input
                                          autoFocus
                                          ref={function(input) {
                                            if (input != null) {
                                              input.focus();
                                            }
                                          }}
                                          id="newProjectTitle"
                                          name='newProjectTitle'
                                          onChange={this.handleInputChange}
                                          className="form-input"
                                          placeholder="Project Title"
                                        />
                                          <label className="input-label" for="newProjectTitle">Project Title</label>
                                          <div className="error-message">{this.state.newProjectNameError}</div>
                                      </div>
                                {/* {# End New Project Fields #} */}

                    {/* {# End Project Input Group #} */}

                    {/* {# End Input Group #} */}

                    {/* {# Modal Buttons #} */}
                    <div className="input-group pt-15">
                        <button type="submit" disabled={this.state.newProjectNameError} onClick={(e)=>{e.preventDefault();this.addToProject(this.props.handleClose)}} className="btn btn-primary form-btn w-full">Create New Project</button>
                    </div>

                </form>
                {/* {# End Form #} */}

            </React.Fragment>
        )
    }
}
CheckoutAddToProject.contextType = ProjectsContext
export default CheckoutAddToProject

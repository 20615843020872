const currencyFormatter = (number)=>{
    try{
        if(typeof number === "number"){
            return number.toLocaleString('en')
        }
        else{
            return Number(number).toLocaleString('en')
        }
    }
    catch(error){
        return number
    }
}
export default currencyFormatter;
import React, { Component } from "react";
import api from "../utils/api";
const contentDis = require("content-disposition");

class SitemapGenerator extends Component {
  state = {
    sitemapData: [],
  };
  componentDidMount = () => {
    api.getDefaultXML().then((res) => {
      const sitemapData = res.data;
      console.log("sitemapData", sitemapData);
      this.setState({ sitemapData });
    });
  };
  downloadXMLMap = (e) => {
    e.preventDefault();

    let header;

    fetch("/api/xml/sitemap.xml")
      .then((resp) => {
        header = resp.headers.get("content-disposition");

        return resp.blob();
      })
      .then((blob) => {
        console.log("download blob", blob);
        //create a dummy element to download the file from the blob
        let downloadElem = document.createElement("a");
        const url = URL.createObjectURL(blob, { type: "xml" });
        let filename;
        if (header) {
          const parsed = contentDis.parse(header);

          if (parsed.parameters && parsed.parameters.filename) {
            filename = parsed.parameters.filename;
          }
        }
        downloadElem.setAttribute("href", url);
        downloadElem.setAttribute("download", filename || "filename");
        downloadElem.style.display = "none";
        console.log(downloadElem);
        document.body.appendChild(downloadElem);

        downloadElem.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(downloadElem);
      })
      .catch((err) => console.log(err));
  };
  render() {
    return (
      <div className="m-30">
        <button className="btn btn-primary mb-30" onClick={this.downloadXMLMap}>
          Download XML Map
        </button>
        <div className="mb-30">
          <h4>URLs</h4>
          {this.state.sitemapData?.map((item) => (
            <div key={item.url}>
              <div>
                {item.url} | Priority: {item.priority} | refresh: {item.changefreq}
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
export default SitemapGenerator;

import React, { useState } from "react";
import ProForm from '../Account/proForm';
import CircularIndeterminate from '../Loading/circularIndeterminant';
import CircularIndeterminateButton from '../Loading/circularIndeterminantButton';
import { Error, SuccessRedirect } from "../Toast/index";
import api from '../utils/api';

export default function Pro(props) {
    const [isLoadingSkip, setisLoadingSkip] = useState(false);

    const skipToNext = (event) => {
        event.preventDefault()
        setisLoadingSkip(true)
        let userData = props.details || {};
        
        userData.signupStatus = 6;
        api.updateSignupStatus(userData)
            .then(signupStatusRes => {
                
                if (signupStatusRes?.data?.signupStatus === 6) {
                    if (props.nextStep) {
                        SuccessRedirect({message: "You can update your P.R.O. later on your Account page.", onClose: ()=>window.location.replace(props.nextStep)})
                    }
                }
            })
            .catch(error=>{
                Error({message: "Error updating your account"})
            })
    }

    return (
        !props.loaded ?
            <CircularIndeterminate forceCenter />
        :
            <>
                <div className="mb-50">
                    <p className="mb-0">
                        Please add your Performance Rights Organization information.
                    </p>
                </div>
                <ProForm 
                    pro={props.pro}
                    nextStep={props.nextStep}
                    buttonText="Save and Continue" 
                />
                <button 
                    className="btn btn-stroke w-full mt-20" 
                    onClick={(event) => skipToNext(event)}
                    disabled={isLoadingSkip}
                >
                    {isLoadingSkip ?
                        <CircularIndeterminateButton forceCenter />
                    :
                        'Skip For Now'
                    }
                </button>
            </>
    )

}
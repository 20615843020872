import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import customStyles from "../FormElements/customStyles";
import LicenseSummary from "../Licenses/licenseSummary";
import CompleteLicenseItem from "./completeLicenseItem";
import purchasesAPI from "../../api/purchases";
import { Error } from "../Toast";
import CustomValueContainer from "../FormElements/customValueContainer";
import currencyFormatter from "../../utils/currencyFormatter";
import LoadingContainer from "../Loading/LoadingContainer";
import { handleAPIError } from "../../utils/handleAPIError";
import SubscriptionItem from "./SubscriptionItem";
import downloadsAPI from "../../api/downloads";
import downloadURL from "../../utils/downloadURL";
const queryString = require('query-string');

const Complete = (props) => {
  const { orderNumber } = props?.match?.params || {};

  const location = useLocation();
  // console.log('location', location);
  const params = queryString.parse(location.search);
  // console.log('params', params);
  const { download, stem, version } = params;


  const downloadStemFull = ({ stemId, trackId }) => {
    downloadsAPI
      .downloadStemFull({ stemId, trackId })
      .then((res) => {
        // console.log("down res", res)
        const { filename, url } = res?.data || {};
        if (url) {
          downloadURL({ filename, url });
        }
      })
      .catch((err) => {
        console.log("error ", err);
      });
  }

  const downloadVersionFull = ({ trackId, versionId }) => {
    downloadsAPI
      .downloadVersionFull({ trackId, versionId })
      .then((res) => {
        // console.log("down res", res)
        const { filename, url } = res?.data || {};
        if (url) {
          downloadURL({ filename, url });
        }
      })
      .catch((err) => {
        console.log("error ", err);
      });
  };

  const downloadTrackFull = (licenseId) => {
    downloadsAPI
      .downloadMainTrack({ id: licenseId })
      .then((res) => {
        // console.log("down res", res)
        const { filename, url } = res?.data || {};
        if (url) {
          downloadURL({ filename, url });
        }
      })
      .catch((err) => {
        console.log("error ", err);
      });
  };

  const [purchase, setPurchase] = useState(null);
  const [licenses, setLicenses] = useState(null);
  const [cardInfo, setCardInfo] = useState(null);
  const [bankInfo, setBankInfo] = useState(null);
  const [isLoaded, setIsLoaded] = useState(null);

  useEffect(() => {
    purchasesAPI
      .getByOrderNumber(orderNumber)
      .then((res) => {
        if (res?.data?.success) {
          const { bankInfo, cardInfo, purchase } = res.data;

          let licenses = [];
          if (purchase?.customerLicenses) {
            purchase?.customerLicenses.forEach((customerLicense) => {
              console.log('customerLicense', customerLicense);
              licenses.push(JSON.parse(customerLicense.info));
              console.log('stem', stem);
              // console.log('version', version);
              if (download) {
                if (stem) {
                  downloadStemFull({
                    trackId: customerLicense.trackId,
                    stemId: stem
                  });
                }
                else if (version) {
                  downloadVersionFull({
                    trackId: customerLicense.trackId,
                    versionId: version
                  });
                }
                else {
                  downloadTrackFull(customerLicense.id);
                }
              }
            });
          }

          if (!purchase.actualTotal) {
            purchase.actualTotal = purchase.finalPrice;
          }

          console.log('purchase fetched', purchase);
          console.log('purchase cardInfo', cardInfo);

          setPurchase(purchase);
          setLicenses(licenses);
          setCardInfo(cardInfo);
          setBankInfo(bankInfo);

          setIsLoaded(true);
        } else {
          Error({ message: "Could not find your purchase" });
        }
      })
      .catch((error) => {
        handleAPIError(error);
      });


  }, []);

  return (
    <LoadingContainer isLoaded={isLoaded}>
      <div class="container">
        {/* {# Checkout Grid #} */}
        <div class="grid-row justify-between">
          {/* {# Checkout Left #} */}
          <div class="col w-full md:flex-1 xl:w-1/2 xl:flex-initial">
            {/* {# Payment Method #} */}
            {purchase?.actualTotal === 0 ? (
              <>
                <h3>Order Processing</h3>
                <p className="pb-20 mb-20 border-b">
                  Your licenses are being created.
                </p>
              </>
            ) : (
              <>
                <h3>Order Processing</h3>
                <p className="pb-20 mb-20 mt-20">
                  Your payment is being processed. You will receive an email confirmation.
                </p>
                  {download && (
                    <p className="pb-20 mb-20 mt-20">
                      <span className="font-bold">Your track(s) should download automatically.</span> If they don't appear, click the button below.
                    </p>
                  )}
                <div className="border-b"></div>

              </>
            )}
            <div className="text-label-sm mt-20 mb-30"></div>
            {purchase?.type === "license" && (
              <a
                class="btn btn-primary w-full form-btn"
                href={
                  "/dashboard/licenses?order=" +
                  orderNumber
                }
              >
                View License &amp; Download Tracks
              </a>
            )}
            {purchase?.type === "subscription" && (
              <a
                class="btn btn-primary w-full form-btn"
                href={
                  "/dashboard/account/subscriptions"
                }
              >
                View Subscription
              </a>
            )}
          </div>
          {/* {# End Checkout Left #} */}

          {/* {# Cart #} */}
          <div class="col w-full md:flex-none mt-40 md:w-350 xl:w-5/12 md:mt-0">
            {/* {# Tracks in Cart #} */}
            <div class="mb-40 lg:mb-60">
              <h3 class="text-h3 mb-10">
                Order #{purchase?.orderNumberDisplay}
              </h3>

            {(purchase?.type === 'license' && (licenses?.length > 0)) && (
              licenses.map((licenseItem, index) => {
                // let track=license.track
                let license = licenseItem.license;
                let licenseSummary = <LicenseSummary
                  hasDescription={false}
                  license={license}
                />;

                // console.log('licenseItem', licenseItem);

                return (
                  <CompleteLicenseItem
                    subscriber={licenseItem.subscriber}
                    licenseSummary={licenseSummary}
                    track={licenseItem.track}
                    license={license}
                    index={index}
                    project={licenseItem.project}
                  />
                );
              })
            )}
            {(purchase?.type === 'subscription' && purchase?.selectedPrice) && (
              <SubscriptionItem
                isComplete={true}
                selectedPrice={purchase.selectedPrice}
              />
            )}

            </div>


            {(purchase?.subscriptionDiscount > 0 ||
              purchase?.membershipDiscount > 0 ||
              purchase?.promoDiscount > 0) && (
              <>
                {/* {# Sub Total #} */}
                <div class="flex justify-between items-center  border-gray-200">
                  <div class="text-label-sm">Sub Total</div>
                  <div class="text-sm text-white">
                    {currencyFormatter(purchase?.originalTotal / 100)}
                  </div>
                </div>
                {(purchase?.subscriptionDiscount > 0 ||
                purchase?.membershipDiscount > 0) && (
                  <div class="flex justify-between items-center  border-gray-200">
                    <div class="text-label-sm">Subscriber Discount</div>
                    <div class="text-sm text-white">
                      -{currencyFormatter((purchase?.subscriptionDiscount + purchase?.membershipDiscount) / 100)}
                    </div>
                  </div>
                )}
              </>
            )}
            {/* {purchase?.membershipDiscount !==0 &&
                        <div class="flex justify-between items-center  border-gray-200">
                            <div class="text-label-sm">Membership Discount</div>
                            <div class="text-sm text-white">-${currencyFormatterFromString((this.state.purchase?.membershipDiscount/100).toFixed(2))}</div>
                        </div>
                    } */}
            {(purchase?.promoDiscount > 0) && (
              <div class="flex justify-between items-center  border-gray-200">
                <div class="text-label-sm">Promo Discount</div>
                <div class="text-sm text-white">
                  -{currencyFormatter(purchase?.promoDiscount / 100)}
                </div>
              </div>
            )}
            {(purchase?.discount > 0) && (
              <div class="flex justify-between items-center  border-gray-200">
                <div class="text-label-sm">Discount</div>
                <div class="text-sm text-white">
                  -{currencyFormatter(purchase?.discount / 100)}
                </div>
              </div>
            )}
            {/* {# Total #} */}
            <div class="flex justify-between border-t items-start py-20">
              <div class="text-label-sm">Total</div>
              <div class="text-h4 text-white" id="checkout-total">
                {currencyFormatter((purchase?.actualTotal / 100) || 0)}
              </div>
            </div>
            {purchase?.purchaseOrderNumber && (
              <div class="flex justify-between border-t items-start py-20">
                <div class="text-label-sm">Purchase Order Number</div>
                <div class=" text-white">
                  {purchase?.purchaseOrderNumber}
                </div>
              </div>
            )}
            {purchase?.vatNumber && (
              <div class="flex justify-between border-t items-start py-20">
                <div class="text-label-sm">VAT Number</div>
                <div class=" text-white">{purchase?.vatNumber}</div>
              </div>
            )}
            <div class="grid-row-xs">
              {!(purchase?.actualTotal > 0) ? (
                <div class="col w-full ">
                  <div className="input-group-xs input-inline">
                    <div className="text-h4 text-white">
                      Covered by your Subscription
                    </div>
                  </div>
                </div>
              ) : (
                (cardInfo?.last4 || bankInfo?.last4) ? (
                  <div class="col w-full ">
                    <div className="input-group-xs input-inline">
                      <label className="input-label" htmlFor="paymentCard">
                        Payment Method
                      </label>
                      <Select
                        name="selectedCard"
                        styles={customStyles}
                        dontShowDropI={true}
                        className="select-container"
                        classNamePrefix="select"
                        placeholder="Payment Method"
                        id="paymentCard"
                        isDisabled={true}
                        value={
                          cardInfo
                            ? {
                                label:
                                  cardInfo?.issuer +
                                  " - " +
                                  cardInfo?.last4,
                              }
                            : {
                                label:
                                  bankInfo?.bankName +
                                  " - " +
                                  bankInfo?.type +
                                  " - " +
                                  bankInfo?.last4,
                              }
                        }
                        components={{
                          ValueContainer: CustomValueContainer,
                        }}
                      />
                    </div>
                  </div>
                ) : null
              )}

              {purchase?.receipt_url && (
                <div class="col w-full">
                  <a
                    class="btn btn-stroke w-full form-btn"
                    href={purchase?.receipt_url}
                    target="_blank" rel="noreferrer"
                  >
                    Download Receipt
                  </a>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </LoadingContainer>
  );
};

export default Complete;

import React, { useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import FormGroup from '@material-ui/core/FormGroup';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';

const AntSwitch = withStyles((theme) => ({
  root: {
    width: 60,
    height: 30,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 2,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(29px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: "rgb(235 25 45/1)",
        borderColor: "rgb(235 25 45/1)",
      },
    },
  },
  thumb: {
    width: 26,
    height: 26,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 36 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

export default function ToggleSwitch(props) {

  const {
    defaultChecked = false,
    labelA = "Off",
    labelB = "On",
    toggleCallback
  } = props || {};

  const [isChecked, setIsChecked] = useState(defaultChecked);

  const handleChange = () => {
    setIsChecked(!isChecked);
    if (toggleCallback) {
      toggleCallback();
    }
  };

  return (
    <FormGroup>
      {/* <Typography component="div"> */}
        <Grid component="label" container alignItems="center" spacing={1}>
          <Grid item><h6 className="text-h6 inline">{labelA}</h6></Grid>
          <Grid item>
            <AntSwitch checked={isChecked} onChange={handleChange} name="checked" />
          </Grid>
          <Grid item><h6 className="text-h6 inline">{labelB}</h6></Grid>
        </Grid>
      {/* </Typography> */}
    </FormGroup>
  );
}

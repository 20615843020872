import React from "react";

const LicenseSummary = (props) => {
  // console.log("licenseSummary props", props);

  const { hasDescription, license } = props;

  const { licenseMain, product } = license || {};
  const { label: productLabel, name: productName, desc: productDesc } = product || {};
  const {
    label: licenseMainLabel,
    name: licenseMainName,
    desc: licenseMainDesc,
  } = licenseMain || {};

  return (
    <>
      <strong>{productLabel || productName || licenseMainLabel || licenseMainName}</strong>
      <br />
      {hasDescription !== false && (productDesc || licenseMainDesc)}
    </>
  );
};

export default LicenseSummary;

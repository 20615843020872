import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import MicroModal from "react-micro-modal";
import { confirm } from "../confirmModal/index";
import CropUtilityModal from "../modal/cropUtilityModal";
// import "./style.css"
let fileInserted = false;

function CoverArtUpload(props) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    noDrag: true,
    onDrop: acceptedFiles => {
      fileInserted = true;
      console.log("dropped")
      const files = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      console.log("files ", files)
      const i = new Image()
        i.src = files[0].preview 

        i.onload = ()=>{
          console.log('loaded')
          let reader = new FileReader()
          reader.readAsDataURL(files[0])
          reader.onload = ()=>{
            console.log({
              src: files[0].preview,
              width: i.width,
              height: i.height,
              data: reader.result
            })
          }
        
        if(i.width >= 250 && i.height > 250){
          setFiles(files);
          if (props.onChange) {
            props.onChange(files);
            props.openPopout()

          }
        }
        else{
          console.log("erroring")
          if (props.onChange) {
            fileInserted =false
            props.onChange({error: "File must be at least 250x250"});
          }
        }
      }
    }
  });
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  const removeFile = file => async (event) => {
    if(event){
      event.preventDefault()

    }
    console.log('file ', file)
    if(await confirm("Are you sure you want to remove your project cover art?", "Remove Cover Art", true)){
      console.log('file ',file)

      fileInserted = false
      if(file?.files.length > 0){
        console.log(" file ",file)
        const newFiles = [...files];
        newFiles.splice(newFiles.indexOf(file), 1);
        setFiles(newFiles);
        props.onChange(newFiles);
      }
      else{
        props.removeCoverArt(event)
      }
    }
    else{
      return
    }
  };
  const removeFileNoCon = file => async (event) => {
    if(event){
      event.preventDefault()

    }
    console.log('file ', file)

      console.log('file ',file)

      fileInserted = false
      if(file?.files.length > 0){
        console.log(" file ",file)
        const newFiles = [...files];
        newFiles.splice(newFiles.indexOf(file), 1);
        setFiles(newFiles);
        props.onChange(newFiles);
      }
      else{
        props.removeCoverArt(event)
      }

  };
  const thumbs = files.map(file => (
    <div  key={file.name}>
      <div >
        
        {file.type === "aasdf" ? 
          <img  src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${props.coverArt}`}  alt="" />
          :
          <img  src={file.preview}  alt="" />
        } 
        {/* <embed width="191" height="207" name="plugin" src={file.preview} type="application/pdf"/> */}
      </div>
      {/* <button className={"removeButton"} >Remove File</button> */}
    </div>
  ));
  const saveCrop= (files)=>{
    fileInserted = true;

    let file = files[0]
    console.log("saving crop ", files)
    Object.assign(file, {
      preview: URL.createObjectURL(file)
    })
    setFiles(files);
      if (props.onChange) {
        props.onChange(files);
        props.handleCropUtilityClose()
      }

  }
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );
  console.log("file inserted ", fileInserted)
  if(files.length < 1){
    fileInserted = false
  }
  return (
  
    <>
                            <div className="form-section">
                            <h3 className="text-label-lg mb-30">Card Photo</h3>
                            {props.coverArtError && 
                                    <em className="text-primary">{props.coverArtError }</em>
                                }
    <div className="grid-row-sm mb-20">

                                <div className="col w-120 mb-20 md:mb-0">

                                    <aside >{thumbs}</aside>
                                    {fileInserted === false ?
                                      props.coverArt && typeof props.coverArt === "string"? 
                                        <img  src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${props.coverArt}`}  alt="" />:
                                        props.initialCoverArt ? 
                                        <img style={{width: "100%"}}src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${props.initialCoverArt}`}  alt="" />:
                                        props.defaultArt ? 
                                        <img style={{width: "100%"}}src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${props.defaultArt}`}  alt="" />:
                                        <img style={{width: "100%"}}src={props.wavPlaceholder}  alt="" />
                                        :
                                     <></>
                                    }

                                    {/* <img src="https://pbs.twimg.com/profile_images/953658232517652480/Xp0NB-TL.jpg" className="rounded-full"/> */}
                                </div>
                                <div className="col flex flex-1 flex-col justify-center">
                                    <p className="mb-15">Images must be 250 x 250 pixels or larger. Accepted file types are jpg, png, gif, svg.</p>

                                </div>
                            </div>
                            <MicroModal
                              open={props.isCropUtilityOpen}
                              closeOnEscapePress={true}
                              handleClose={()=>{console.log("handling close");props.handleCropUtilityClose(removeFileNoCon({files}))}}
                              closeOnOverlayClick={false}
                              closeOnAnimationEnd={true}
                              modalOverlayClassName="modal-overlay"
                              modalClassName="modal micromodal-slide is-open"
                              modalOverlayStyles={null}
                              children={handleClose=><CropUtilityModal handleClose={handleClose} file={files[0]} saveCrop={saveCrop}/>}
                              containerStyles={{background: "#131313", padding:"asdf", maxWidth: "32rem", maxHeight: "95vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box"}}
                            ></MicroModal>
                            {/* {# Upload Status #} */}
                            <div className="input-group flex w-full flex-col md:flex-row">
                                <div className="flex md:flex-1 border-t border-b border-gray-300 py-15 items-center h-50 mb-20 md:mb-0 md:mr-30">

                                    {files.length > 0 || props.initialCoverArt ? <>
                                      <div className="upload-status-icon flex flex-none items-center mr-10">
                                        <span className="text-icon-md text-white icon-check"></span>
                                    </div>
                                    {files.length > 0 ? 
                                      <span className="flex-1">{files[0].name || props.coverArt?.split("/")[props.coverArt.split("/").length-1]}</span>:
                                      <span className="flex-1">{typeof props.initialCoverArt ? props.initialCoverArt.split("/")[props.initialCoverArt.split("/").length-1] : "default.svg"}</span>}
                                    {props.userSet ?
                                      <button type="button" onClick={removeFile({files})} className="flex px-10 flex-none items-center transition-colors duration-300 text:gray-400 hover:text-white">
                                          <span className="text-icon-md icon-trash"></span>
                                      </button>:
                                      <></>
                                    }
                                    </>
                                    :
                                    <></>
                                    }
                                </div>
                                 <div {...getRootProps({ className: "btn-dropzone" })}>
                               <input {...getInputProps()} />
                                <button type="button" className="btn btn-stroke" onClick={(event)=>{event.preventDefault()}}>Upload Cover Art</button>
                                </div>

                                {/* <button className="btn btn-stroke">Upload Photo</button> */}
                            </div>

                            </div>
                            </>
  );
}

export default CoverArtUpload;
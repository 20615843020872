import debounce from "lodash.debounce";
import React from "react";
import LoadingContainer from "../../components/Loading/LoadingContainer";
import signupSteps from "../../components/SignUp/signupSteps";
import { Success } from "../../components/Toast";
import api from "../../components/utils/api";
import { handleAPIError } from "../../utils/handleAPIError";
import usersAPI from "../../api/users";

const UserContext = React.createContext({});

function signupComplete(userData) {
  // which paths are not locked down
  let allowedPaths = [
    "/login",
    "/logout",
    "/music",
    "/artists",
    "/collections",
    "/pricing",
    "/subscription-checkout",
  ];
  if (userData.artist) {
    allowedPaths.push(
      "/signup/details",
      "/signup/profile",
      "/signup/cover",
      "/signup/bio",
      "/signup/pro",
      "/signup/deposits",
      "/signup/complete"
    );
  } else {
    allowedPaths.push("/signup/user", "/signup/confirm", "/dashboard/account/settings");
  }
  // check if current path is in allowed paths
  //
  const allowedPath = Boolean(
    allowedPaths.find(function (path) {
      return window.location.pathname.includes(path, 0);
    })
  );
  //
  // if user has not finished signup and this is not an allowed path, redirect

  if (userData.incomplete && !allowedPath) {
    return false;
  }

  return true;
}

function signupRedirect(userInfo) {
  if (userInfo.artist) {
    let currentStep = signupSteps.filter((step) => step.step === userInfo.signupStatus)[0];
    if (currentStep?.path) {
      window.location.replace(`/signup/${currentStep.path}`);
    } else {
      window.location.replace(`/signup/details`);
    }
  } else if (
    userInfo.firstName &&
    userInfo.lastName &&
    userInfo.bestDescribed &&
    !userInfo.emailConfirmed
  ) {
    window.location.replace(`/signup/confirm`);
  } else {
    window.location.replace(`/signup/user`);
  }
}

class UserProvider extends React.Component {
  state = {
    user: null,
    currentNav: window.location.pathname,
    loginMessage: "",
    isLoginModalOpen: false,
    userLoaded: false,
  };

  componentDidMount() {
    window.addEventListener("click", this.onClick);
    window.addEventListener("focus", this.onFocus);

    api.checkSession().then((res) => {
      if (res?.data?.session === false) {
        this.setState({ user: null, userLoaded: true });
      } else if (res?.data?.info) {
        if (window.location.pathname === "/logout") {
          this.logout();
          return;
        }
        const { info } = res.data || {};
        // console.log('UserContext mount info', info);

        // TODO move cartCount into checkSession API call and delete all this
        const sessionCartInfo = sessionStorage.getItem("cart");
        let cartCount;
        if (sessionCartInfo) {
          // console.log('sessionCartInfo', sessionCartInfo);
          const cart = JSON.parse(sessionCartInfo);
          cartCount = cart?.length || 0;

          this.setState((prevState) => ({
            ...prevState,
            user: {
              ...prevState.user,
              cartCount,
            },
          }));
        } else if (info.id) {
          api.getMyActiveCart().then((res) => {
            if (res.data?.cart) {
              const { info } = res?.data?.cart || {};
              const licenses = JSON.parse(info || "{}");
              // console.log('licenses', licenses);
              cartCount = licenses.length;

              this.setState((prevState) => ({
                ...prevState,
                user: {
                  ...prevState.user,
                  cartCount,
                },
              }));
            }
          });
        }

        // console.log('cartCount', cartCount);

        if (signupComplete(info)) {
          this.setState((prevState) => ({
            ...prevState,
            user: {
              ...prevState.user,
              user_id: info.id,
              artistName: info.artistName,
              firstName: info.firstName,
              lastName: info.lastName,
              email: info.email,
              role: info.role,
              incomplete: info.incomplete === 1,
              customer: info.customer === 1,
              rep: info.rep === 1,
              manager: info.manager === 1,
              artist: info.artist === 1,
              admin: info.admin === 1,
              stripeConnected: info.stripeConnected,
              confirmed: info.emailConfirmed === 1 ? true : false,
              signupStatus: info.signupStatus,
              achEnabled: info.achEnabled === 1,
              createdOn: info.createdOn,
              userHash: info.userHash,
              subscription: info.subscription,
              planStatus: info.planStatus,
              since: info.since,
              profilePhoto: info.profilePhoto,
              subscriptionLearnMoreType: info.subscriptionLearnMoreType,
              subscriptionLearnMorePrice: info.subscriptionLearnMorePrice,
              subscriptionLearnMoreClicks: info.subscriptionLearnMoreClicks,
              subscriptionLearnMoreImpressions: info.subscriptionLearnMoreImpressions,
              subscriptionLearnMoreLastClicked: info.subscriptionLearnMoreLastClicked,
              subscriptionLearnMoreTrackId: info.subscriptionLearnMoreTrackId,
              subscriptionLearnMoreTrackPriceId: info.subscriptionLearnMoreTrackPriceId,
              subscriptionLearnMoreTrackPriceAmount: info.subscriptionLearnMoreTrackPriceAmount,
            },
            userLoaded: true,
          }));
        } else {
          signupRedirect(info);
        }
      }
    });
  }
  componentDidUpdate(prevProps, prevState) {
    // console.log('userContext componentDidUpdate');
    if (prevState.currentNav !== this.state.currentNav) {
      if (this.state.user) {
        api.checkSession().then((res) => {
          // console.log('UserContext componentDidUpdate checkSession', res);
          if (res.data.session === false) {
            this.setState({ user: null, userLoaded: true }, () => {
              if (window.location.pathname !== "/login") {
                setTimeout(() => {
                  window.location.replace(
                    `${
                      window.location.pathname
                        ? "/login" +
                          (window.location.pathname.indexOf("/login") === -1
                            ? '?redirect="' + window.location.pathname + '"'
                            : "")
                        : "/login"
                    }`
                  );
                }, 0);
              }
            });
          } else if (res.data.info && window.location.pathname !== "/signup/details") {
            if (window.location.pathname === "/logout") {
              this.logout();
              return;
            }
            let info = res.data.info;
            console.log("componentDidUpdate res.data.info", res.data.info);

            const sessionCartInfo = sessionStorage.getItem("cart");
            let cartCount;
            if (sessionCartInfo) {
              // console.log('sessionCartInfo', sessionCartInfo);
              const cart = JSON.parse(sessionCartInfo);
              cartCount = cart?.length || 0;

              this.setState((prevState) => ({
                ...prevState,
                user: {
                  ...prevState.user,
                  cartCount,
                },
              }));
            } else {
              api.getMyActiveCart().then((res) => {
                if (res.data?.cart) {
                  let cart = res.data.cart;
                  let licenses = JSON.parse(cart.info);
                  // console.log('licenses', licenses);
                  cartCount = licenses.length;

                  this.setState((prevState) => ({
                    ...prevState,
                    user: {
                      ...prevState.user,
                      cartCount,
                    },
                  }));
                }
              });
            }

            if (signupComplete(info)) {
              this.setState((prevState) => ({
                ...prevState,
                user: {
                  ...prevState.user,
                  user_id: info.id,
                  artistName: info.artistName,
                  firstName: info.firstName,
                  lastName: info.lastName,
                  email: info.email,
                  role: info.role,
                  incomplete: info.incomplete === 1,
                  customer: info.customer === 1,
                  rep: info.rep === 1,
                  manager: info.manager === 1,
                  artist: info.artist === 1,
                  admin: info.admin === 1,
                  stripeConnected: info.stripeConnected,
                  confirmed: info.emailConfirmed === 1 ? true : false,
                  signupStatus: info.signupStatus,
                  achEnabled: info.achEnabled === 1,
                  createdOn: info.createdOn,
                  userHash: info.userHash,
                  subscription: info.subscription,
                  planStatus: info.planStatus,
                  since: info.since,
                  profilePhoto: info.profilePhoto,
                  subscriptionLearnMoreType: info.subscriptionLearnMoreType,
                  subscriptionLearnMorePrice: info.subscriptionLearnMorePrice,
                  subscriptionLearnMoreClicks: info.subscriptionLearnMoreClicks,
                  subscriptionLearnMoreImpressions: info.subscriptionLearnMoreImpressions,
                  subscriptionLearnMoreLastClicked: info.subscriptionLearnMoreLastClicked,
                  subscriptionLearnMoreTrackId: info.subscriptionLearnMoreTrackId,
                  subscriptionLearnMoreTrackPriceId: info.subscriptionLearnMoreTrackPriceId,
                  subscriptionLearnMoreTrackPriceAmount: info.subscriptionLearnMoreTrackPriceAmount,
                },
                userLoaded: true,
              }));
            } else {
              signupRedirect(info);
            }
          }
        });
      }
      // console.log('userContext componentDidUpdate no user');
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener("focus", this.onFocus);
    window.removeEventListener("click", this.onClick);
  };
  onFocus = () => {
    if (this.state.user) {
      api.checkSession().then((res) => {
        if (res.data.session === false) {
          this.setState({ user: null, userLoaded: true }, () => {
            if (window.location.pathname !== "/login") {
              setTimeout(() => {
                window.location.replace(
                  `${
                    window.location.pathname
                      ? "/login" +
                        (window.location.pathname.indexOf("/login") === -1
                          ? '?redirect="' + window.location.pathname + '"'
                          : "")
                      : "/login"
                  }`
                );
              }, 0);
            }
          });
        } else if (res.data.info) {
          if (window.location.pathname === "/logout") {
            this.logout();
            return;
          }
          let info = res.data.info;
          //

          if (signupComplete(info)) {
            this.setState((prevState) => ({
              ...prevState,
              user: {
                ...prevState.user,
                user_id: info.id,
                artistName: info.artistName,
                firstName: info.firstName,
                lastName: info.lastName,
                email: info.email,
                role: info.role,
                incomplete: info.incomplete === 1,
                customer: info.customer === 1,
                rep: info.rep === 1,
                manager: info.manager === 1,
                artist: info.artist === 1,
                admin: info.admin === 1,
                stripeConnected: info.stripeConnected,
                signupStatus: info.signupStatus,
                confirmed: info.emailConfirmed === 1 ? true : false,
                achEnabled: info.achEnabled === 1,
                createdOn: info.createdOn,
                userHash: info.userHash,
                subscription: info.subscription,
                planStatus: info.planStatus,
                since: info.since,
                profilePhoto: info.profilePhoto,
                subscriptionLearnMoreType: info.subscriptionLearnMoreType,
                subscriptionLearnMorePrice: info.subscriptionLearnMorePrice,
                subscriptionLearnMoreClicks: info.subscriptionLearnMoreClicks,
                subscriptionLearnMoreImpressions: info.subscriptionLearnMoreImpressions,
                subscriptionLearnMoreLastClicked: info.subscriptionLearnMoreLastClicked,
                subscriptionLearnMoreTrackId: info.subscriptionLearnMoreTrackId,
                subscriptionLearnMoreTrackPriceId: info.subscriptionLearnMoreTrackPriceId,
                subscriptionLearnMoreTrackPriceAmount: info.subscriptionLearnMoreTrackPriceAmount,
              },
              userLoaded: true,
            }));
          } else {
            signupRedirect(info);
          }
        }
      });
    }
  };
  onClick = debounce(
    () => {
      if (this.state.user) {
        api.checkSession().then((res) => {
          if (res.data.session === false) {
            this.setState({ user: null, userLoaded: true }, () => {
              if (window.location.pathname !== "/login") {
                setTimeout(() => {
                  window.location.replace(
                    `${
                      window.location.pathname
                        ? "/login" +
                          (window.location.pathname.indexOf("/login") === -1
                            ? '?redirect="' + window.location.pathname + '"'
                            : "")
                        : "/login"
                    }`
                  );
                }, 0);
              }
            });
          } else if (res.data.info) {
            if (window.location.pathname === "/logout") {
              this.logout();
              return;
            }
            let info = res.data.info;
            //

            if (signupComplete(info)) {
              this.setState((prevState) => ({
                ...prevState,
                user: {
                  ...prevState.user,
                  user_id: info.id,
                  artistName: info.artistName,
                  firstName: info.firstName,
                  lastName: info.lastName,
                  email: info.email,
                  role: info.role,
                  incomplete: info.incomplete === 1,
                  customer: info.customer === 1,
                  rep: info.rep === 1,
                  manager: info.manager === 1,
                  artist: info.artist === 1,
                  admin: info.admin === 1,
                  stripeConnected: info.stripeConnected,
                  signupStatus: info.signupStatus,
                  confirmed: info.emailConfirmed === 1 ? true : false,
                  achEnabled: info.achEnabled === 1,
                  createdOn: info.createdOn,
                  userHash: info.userHash,
                  subscription: info.subscription,
                  planStatus: info.planStatus,
                  since: info.since,
                  profilePhoto: info.profilePhoto,
                  subscriptionLearnMoreType: info.subscriptionLearnMoreType,
                  subscriptionLearnMorePrice: info.subscriptionLearnMorePrice,
                  subscriptionLearnMoreClicks: info.subscriptionLearnMoreClicks,
                  subscriptionLearnMoreImpressions: info.subscriptionLearnMoreImpressions,
                  subscriptionLearnMoreLastClicked: info.subscriptionLearnMoreLastClicked,
                  subscriptionLearnMoreTrackId: info.subscriptionLearnMoreTrackId,
                  subscriptionLearnMoreTrackPriceId: info.subscriptionLearnMoreTrackPriceId,
                  subscriptionLearnMoreTrackPriceAmount: info.subscriptionLearnMoreTrackPriceAmount,
                },
                userLoaded: true,
              }));
            } else {
              signupRedirect(info);
            }
          }
        });
      }
    },
    1000,
    { leading: true }
  );
  //function to call when 401 error is caught

  login = (userCredentials, redirect, callback = function () {}, message) => {
    // console.log('UserContext login, then callback', callback)

    const sessionCartInfo = this.getSessionCartInfo();

    if (window.location.pathname === "/checkout") {
      redirect = "/checkout";
    }

    usersAPI
      .login({
        userCredentials,
        redirect,
        sessionCartInfo,
      })
      .then((res) => {
        const {
          achEnabled,
          admin,
          artist,
          artistName,
          code,
          createdOn,
          customer,
          email,
          emailConfirmed: confirmed,
          error,
          firstName,
          id: user_id,
          incomplete,
          lastName,
          loginRedirect,
          manager,
          planStatus,
          profilePhoto,
          rep,
          role,
          session,
          since,
          signupStatus,
          stripeConnected,
          subscription,
          userHash,
          userURL,
          subscriptionLearnMoreType,
          subscriptionLearnMorePrice,
          subscriptionLearnMoreClicks,
          subscriptionLearnMoreImpressions,
          subscriptionLearnMoreLastClicked,
          subscriptionLearnMoreTrackId,
          subscriptionLearnMoreTrackPriceId,
          subscriptionLearnMoreTrackPriceAmount,
        } = res?.data || {};

        if (error) {
          this.setState({
            loginMessage: error,
          });
        } else if (code === "ECONNREFUSED") {
          this.setState({
            loginMessage: "System error. Please try again.",
          });
        } else if (session) {
          this.setState(
            {
              user: {
                user_id,
                email,
                name: `${firstName} ${lastName}`,
                firstName,
                lastName,
                confirmed,
                signupStatus,
                admin: admin === 1 ? true : false,
                manager: manager === 1 ? true : false,
                artist: artist === 1 ? true : false,
                customer: customer === 1 ? true : false,
                incomplete: incomplete === 1 ? true : false,
                rep: rep === 1 ? true : false,
                role,
                userURL,
                artistName,
                redirectToReferrer: true,
                stripeConnected,
                achEnabled: achEnabled === 1,
                subscription,
                planStatus,
                profilePhoto,
                createdOn,
                userHash,
                since,
                subscriptionLearnMoreType,
                subscriptionLearnMorePrice,
                subscriptionLearnMoreClicks,
                subscriptionLearnMoreImpressions,
                subscriptionLearnMoreLastClicked,
                subscriptionLearnMoreTrackId,
                subscriptionLearnMoreTrackPriceId,
                subscriptionLearnMoreTrackPriceAmount,
              },
              userLoaded: true,
            },
            () => callback()
          );

          if (email) {
            this.handleLoginModalClose();
            this.clearSessionCart();

            // try setting redirectPath to passed in redirect value
            let redirectPath = loginRedirect || redirect || false;

            // if not, set redirectPath based on conditions
            if (!redirectPath) {
              if (window.location.pathname !== "/login" && window.location.pathname !== "/logout") {
                redirectPath = window.location.pathname;
              } else {
                if (role === "admin" || role === "artist") {
                  redirectPath = "/dashboard/tracks/approved";
                } else {
                  redirectPath = "/music";
                }
              }
            }

            console.log("UserContext redirect", redirectPath);

            if (redirectPath !== "noredirect") {
              setTimeout(() => {
                window.location.replace(redirectPath);
              }, 100);
            }
          }
          if (message) {
            Success({
              message: message,
              toastId: "login-message",
            });
          }
        }
      })
      .catch((error) => {
        if (error?.response?.status === 401) {
          this.setState({
            loginMessage: error?.response?.data,
          });
        }
        handleAPIError(error);
      });
  };

  // handleSessionCart = () => {
  //   console.log('handling sessionCart')

  //   // if (this.state.user?.user_id) {
  //     cartsAPI
  //       .sync({ sessionCartInfo })
  //       .then((response) => {
  //         const { redirect } = response?.data || {};

  //         console.log('cartsAPI.sync response', response);

  //         this.clearSessionCart();

  //         if (redirect) {
  //           setTimeout(() => {
  //             window.location.replace(redirect);
  //           }, 100);
  //         }
  //       })
  //       .catch((error) => {
  //         handleAPIError(error);
  //       });
  //   // }
  //   // else {
  //   //   console.log('user not logged in; cannot handleSessionCart');
  //   // }
  // }

  getSessionCartInfo = () => {
    return JSON.parse(sessionStorage.getItem("cart") || "[]");
  };

  clearSessionCart = () => {
    sessionStorage.removeItem("cart");
  };

  handleLoginModalOpen = () => {
    this.setState({ isLoginModalOpen: true });
  };

  handleLoginModalClose = () => {
    this.setState({ isLoginModalOpen: false });
  };
  updateProfilePhotoContext = (newPicture) => {
    document.cookie = `profilePhoto=${newPicture}; path=/;`;
  };

  hasActiveSubscription = (user) => {
    return user?.planStatus === "active";
  };

  logout = () => {
    api.logoutUser().then((res) => {
      this.setState({ user: null, userLoaded: true });
      sessionStorage.removeItem("cart");

      if (window.location.pathname !== "/login") {
        setTimeout(() => {
          window.location.replace(`/login${window.location.search || ""}`);
        }, 0);
      }
    });
  };
  setCurrentNav = (updatedNav) => {
    this.setState({ currentNav: updatedNav });
  };
  triggerLoginModal = () => {
    this.setState({ isLoginModalOpen: true });
  };

  render() {
    return (
      <UserContext.Provider
        value={{
          user: this.state.user,
          hasActiveSubscription: this.hasActiveSubscription,
          isLoginModalOpen: this.state.isLoginModalOpen,
          handleLoginModalOpen: this.handleLoginModalOpen,
          handleLoginModalClose: this.handleLoginModalClose,
          login: this.login,
          loginCheckout: this.loginCheckout,
          updateProfilePhotoContext: this.updateProfilePhotoContext,
          logout: this.logout,
          currentNav: this.state.currentNav,
          setCurrentNav: this.setCurrentNav,
          loginMessage: this.state.loginMessage,
          triggerLoginModal: this.triggerLoginModal,
          userLoaded: this.state.userLoaded,
        }}
      >
        <LoadingContainer isLoaded={this.state.userLoaded} loader={"none"}>
          {React.cloneElement(this.props.children, {
            user: this.state.user,
            triggerLoginModal: this.triggerLoginModal,
            isLoginModalOpen: this.state.isLoginModalOpen,
          })}
        </LoadingContainer>
      </UserContext.Provider>
    );
  }
}

const UserConsumer = UserContext.Consumer;

export { UserProvider, UserConsumer, UserContext };

import axios from "axios";

const blogAPI = {
  getIndex: function () {
    return axios({
      method: "get",
      url: "/api/blogs/",
    });
  },
  getPage: function ({ slug }) {
    return axios({
      method: "get",
      url: "/api/blogs/" + slug,
    });
  },
  getTagPage: function ({ slug }) {
    return axios({
      method: "get",
      url: "/api/blogs/tags/" + slug,
    });
  },
};
export default blogAPI;

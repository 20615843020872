import React, { useContext, useState } from 'react';
import { Tooltip } from "react-tippy";
import AddToProjectModalLink from "../../Projects/AddToProjectModalLink";
import { Error, Success } from "../../Toast/index";
import TrackTooltip from "../trackTooltip";
import { FavoritesContext } from '../../../FavoritesContext';
import { UserContext } from '../../../contexts/User';
import DownloadTrack from '../DownloadTrack';

const TrackIcons = (props) => {
  const {
    artistId,
    artistName,
    imageURL,
    isFeatured,
    itemType,
    remove,
    sceneTrackId,
    removeTrack,
    trackId,
    trackTitle,
    trackURL
  } = props;

  const favoritesContext = useContext(FavoritesContext);
  const userContext = useContext(UserContext);

  const {
    artists,
    tracks,
    likeTrack,
    unlikeTrack,
    followArtist,
    unfollowArtist,
  } = favoritesContext;

  const {
    hasActiveSubscription,
    triggerLoginModal,
    user
  } = userContext;

  const isLiked = (tracks?.filter(
    (fav) => fav.trackId === trackId
  ).length === 1);

  const [showTrackTippy, setShowTrackTippy] = useState(false);

  const toggleTrackTippy = () => {
    setShowTrackTippy(!showTrackTippy);
  }


  const shareTrack = () => {
    var textArea = document.createElement("textarea");

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = "2em";
    textArea.style.height = "2em";

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = "transparent";

    textArea.value = `${window.location.protocol}//${window.location.host}/tracks/${trackURL}`;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      if (successful) {
        Success({ message: "Track URL Copied to Clipboard" });
      } else {
        Error({ message: "Couldn't Copy Track URL " });
      }
      // const message = successful ? "successful" : "unsuccessful";
    }
    catch (error) {
      console.log(error);
    }

    document.body.removeChild(textArea);

    setShowTrackTippy(false);
  };

  return (
    <>
      <div className={`track-${itemType || 'list'}-icons text-gray-400`}>
        <div className={`${(itemType === 'grid') ? 'btn-group-shrink' : 'flex'}`}>
          {isLiked ? (
            <button
              onClick={() =>
                unlikeTrack(
                  trackId,
                  trackTitle
                )
              }
              className="circle-icon circle-icon-sm btn-jelly js-tooltip is-liked hover:text-primary"
              title="Like Track"
              type="button"
            >
              <span className="icon-like-fill"></span>
            </button>
          ) : (
            <button
              onClick={() => likeTrack(trackId)}
              className="circle-icon circle-icon-sm btn-jelly js-tooltip"
              title="Like Track"
              type="button"
            >
              <span className="icon-like"></span>
            </button>
          )}

          <AddToProjectModalLink
            className="circle-icon circle-icon-sm js-tooltip hidden lg:flex"
            trackId={trackId}
            trackTitle={trackTitle}
            triggerLoginModal={triggerLoginModal}
            user={user}
          />

          <DownloadTrack
            artistId={artistId}
            artistName={artistName}
            className={`circle-icon circle-icon-sm js-tooltip ${hasActiveSubscription(user) ? 'icon-download' : 'icon-add-to-cart'}`}
            imageURL={imageURL}
            trackId={trackId}
            trackTitle={trackTitle}
          />

        <button
          id="trackToolTip"
          className={`track-list-more${itemType === 'grid' ? ' circle-icon' : ''}`}
          onClick={toggleTrackTippy}
          data-tippy-html="#trackToolTip"
          data-tippy-placement="bottom-end"
          type="button"
        >
          <Tooltip
            arrow={false}
            open={showTrackTippy}
            interactive={true}
            position={"bottom-end"}
            popperOptions={{
              modifiers: {
                preventOverflow: { enabled: false },
                flip: { enabled: false },
                hide: { enabled: false },
                addZIndex: {
                  enabled: true,
                  order: 810,
                  fn: (data) => ({
                    ...data,
                    styles: {
                      ...data.styles,
                      zIndex: 10,
                    },
                  }),
                },
              },
            }}
            trigger={"click"}
            useContext={true}
            html={
              <TrackTooltip
                trackId={trackId}
                trackTitle={trackTitle}
                artistId={artistId}
                artistName={artistName}
                imageURL={imageURL}
                isFeatured={isFeatured}
                liked={
                  tracks?.filter(
                    (fav) => fav.trackId === trackId
                  ).length
                }
                likeTrack={likeTrack}
                unlikeTrack={unlikeTrack}
                following={
                  artists?.filter(
                    (fav) => fav.artistId === artistId
                  ).length === 1
                }
                followArtist={followArtist}
                unfollowArtist={unfollowArtist}
                sceneTrackId={sceneTrackId}
                removeTrack={removeTrack}
                shareTrack={shareTrack}
                toggleTrackTippy={toggleTrackTippy}
                user={user}
                triggerLoginModal={triggerLoginModal}
              />
            }
            allowHTML={true}
            onUntrigger={toggleTrackTippy}
            onRequestClose={toggleTrackTippy}
          >
            <span className="icon-dots-vert flex"></span>
          </Tooltip>
          </button>
          </div>
      </div>

    </>
  )
}
export default React.memo(TrackIcons);

import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { UserConsumer } from '../../contexts/User';
import { Error, Success } from "../Toast/index";
import NotFound from '../NotFound';
import ProtectedRoute from "../ProtectedRoute";
import signupSteps from "../SignUp/signupSteps";


const SignupRoute = (componentData) => {

  const paramsStep = componentData?.computedMatch?.params?.step;

  const currentStep = signupSteps.filter(step => step.path === paramsStep)[0];
  const isValidSignupStep = currentStep;

  // console.log('signupSteps', signupSteps);
  // console.log('currentStep', currentStep);
  // console.log('paramsStep', paramsStep);
  // console.log('history', history);
  // console.log('location', location);
  console.log('signupRoute componentData', componentData);
  // console.log('signupRoute rest', rest);
  // console.log('signupRoute props', props);

  const {component, props, ...rest} = componentData;

  console.log('signupRoute component', component);

  return (
    isValidSignupStep ? (
      <ProtectedRoute
        component={component}
        props={props}
        {...rest}
      >
      </ProtectedRoute>
    ) : (
      <>
        <NotFound />
      </>
    )
  )
}

export default SignupRoute

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import MicroModal from "react-micro-modal";
import moment from "moment";
import api from "../../../utils/api";
import ChannelWhitelistModal from "../Modal";
import LoadingButton from "../../../FormElements/LoadingButton";
import { useFormErrorsToast } from "../../../Forms/formHooks";
import { handleAPIError } from "../../../../utils/handleAPIError";



export default function ChannelSearch(props) {
  const { errors, register, handleSubmit, reset } = useForm({
    mode: "onChange",
  });

  const [isLoading, setisLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [youtubeChannelData, setYoutubeChannelData] = useState(null);

  useFormErrorsToast(errors);

  const onSubmit = ({ channelID }, e) => {
    setisLoading(true);
    console.log("formData", channelID);

    const params = {
      id: channelID
    }

    if (params.id) {
      api.getYoutubeChannel(params)
        .then(response => {
          console.log('response?.data', response?.data);
          const responseData = response?.data?.data;

          responseData.publishedAt = moment(responseData.publishedAt).format("MM/DD/YYYY");
          responseData.subscriberCount = Number(responseData.subscriberCount).toLocaleString();
          responseData.videoCount = Number(responseData.videoCount).toLocaleString();

          setYoutubeChannelData(responseData);
          setIsModalOpen(true);
          setisLoading(false);
        })
        .catch(error => {
          setYoutubeChannelData(null);
          console.log(error);
          handleAPIError(error);
          setisLoading(false);
        })
    }

    setisLoading(false);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setisLoading(false);
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className="ds-form my-30 col w-full md:w-1/2 xl:w-1/3">
        <div class="flex items-center">
          <div class="flex-1 pr-20">
            <div className="input-group input-inline mb-0">
              <input
                autoComplete="off"
                name="channelID"
                id="channel_id"
                className="form-input"
                placeholder="YouTube Channel ID"
                defaultValue=''
                ref={
                  register({
                    required: 'Please enter a Channel ID'
                  })
                }
              />
              <label className="input-label" htmlFor="channel_id">
                YouTube Channel ID
                <span className="input-label-required">*</span>
              </label>
            </div>
          </div>
          <div class="flex-none">
            <LoadingButton
              className="w-full btn btn-primary"
              isLoading={isLoading}
              type="submit"
            >
              Search
            </LoadingButton>
          </div>
        </div>
      </form>

      <MicroModal
        open={isModalOpen}
        handleClose={handleModalClose}
        closeOnEscapePress={true}
        closeOnOverlayClick={true}
        closeOnAnimationEnd={true}
        modalOverlayClassName="modal-overlay"
        modalClassName="modal micromodal-slide is-open"
        modalOverlayStyles={null}
        children={handleClose => (
          <ChannelWhitelistModal
            handleClose={handleModalClose}
            youtubeChannelData={youtubeChannelData}
          />
        )}
        containerStyles={{
            background: "#131313",
            padding: "asdf",
            maxWidth: "40.625rem !important",
            maxHeight: "100vh",
            borderRadius: "asdf",
            overflowY: "auto",
            boxSizing: "border-box"
        }}
      />
    </>
  );
}

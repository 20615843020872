const describeOptions = [
    { value: "Content Creator", label: "Content Creator" },
    { value: "Business/Brand", label: "Business/Brand" },
    { value: "Non-Profit", label: "Non-Profit" },
    { value: "Wedding Filmmaker", label: "Wedding Filmmaker" },
    { value: "Production Company/Post-House", label: "Production Company/Post-House" },
    { value: "Publisher", label: "Publisher" },
    { value: "Studio", label: "Studio" },
];

export default describeOptions;
import axios from "axios";

const subscriptionsAPI = {
  get: function (planType) {
    return axios({
      method: 'get',
      url: `/api/subscriptions/${planType && '?planType=' + planType}`
    });
  },
  getUserActiveSubscription: function () {
    return axios({
      method: 'get',
      url: `/api/users/me`
    });
  },
  enableAutorenew: function(){
    return axios({
        method: 'put',
        url: '/api/customerSubscriptions/enableAutorenew'
    })
  },
  disableAutorenew: function(){
      return axios({
          method: 'put',
          url: '/api/customerSubscriptions/disableAutorenew'
      })
  },
}
export default subscriptionsAPI;

import React from "react";
import SalesContentIDMonth from "./SalesContentIDMonth";

const SalesContentIDMonths = ({ contentIDAssetReports, contentIDAssetReportItems }) => {
  return contentIDAssetReports?.map((contentIDAssetReport) => {
    const { monthString } = contentIDAssetReport || {};
    return (
      <SalesContentIDMonth
        key={monthString}
        contentIDAssetReport={contentIDAssetReport}
        contentIDAssetReportItems={contentIDAssetReportItems}
      />
    );
  });
};

export default SalesContentIDMonths;

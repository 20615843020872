import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { PlayerContext } from "../../PlayerContext";
import { UserConsumer } from "../../contexts/User";
import OnboardingLayout from "../Layouts/onboardingLayout";
import api from "../utils/api";

class Login extends Component {
  //the state for the login component keeps track fo the email and password inputs
  state = {
    email: "",
    password: "",
    loginMessage: "",
    loginAction: "login",
  };

  //updating our input fields as the user enters keys
  handleInputChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };
  forgotPassword = (event) => {
    this.setState({
      loginAction: event,
    });
  };
  componentDidMount = () => {
    this.context.hidePlayer();
  };

  // calling the usersAPI.login method
  handleFormSubmit = (loginFunc) => (event) => {
    event.preventDefault();

    const queryString = require("query-string");
    const params = queryString.parse(window.location.search);

    if (this.state.loginAction === "reset" && this.state.email) {
      api.forgotPassword({ email: this.state.email }).then((res) => {
        this.setState({
          loginMessage: "Password reset email was sent.",
          passwordResetSent: true,
        });
      });
    } else if (this.state.loginAction === "login" && this.state.email && this.state.password) {
      loginFunc(
        {
          email: this.state.email,
          password: this.state.password,
        },
        params?.redirect?.replace(/['"]+/g, "")
      );
    } else {
      this.setState({
        loginMessage: "Please enter a valid email and password.",
      });
    }
  };
  forceRedirect = (user) => {
    const queryString = require("query-string");
    let parsedQuery = queryString.parse(this.props.history.location.search);
    if (!parsedQuery?.redirect) {
      switch (user?.role) {
        case "customer":
          this.props.history.replace("/music");
          break;
        case "admin":
          this.props.history.replace("/dashboard/tracks/approved");
          break;
        case "artist":
          this.props.history.replace("/dashboard/tracks/approved");
          break;
        default:
          this.props.history.replace("/music");
          break;
      }
    }
  };
  render() {
    return (
      <OnboardingLayout>
        <UserConsumer>
          {({ user, login, logout, loginMessage }) => (
            <>
              <Helmet title="Login - Deep Sounds | Music for Creators" />
              <div>
                <div className="hero-fixed">
                  <div
                    className="hero-img"
                    style={{
                      backgroundImage: `url("${process.env.PUBLIC_URL}/images/hero/login-02.jpg")`,
                    }}
                  ></div>
                </div>
                <div className="onboarding-main container container-xl">
                  <h1 className="ds-title mb-30 lg:mb-50">Sign In</h1>
                  <div className="onboarding-form">
                    <form
                      onSubmit={this.handleFormSubmit(
                        this.state.loginAction === "login" ? login : "reset"
                      )}
                      className="ds-form"
                    >
                      {this.state.loginAction === "reset" ? (
                        <h3 className="mb-40">Password Reset</h3>
                      ) : (
                        ""
                      )}

                      <div className="grid-row-sm">
                        <div className="col w-full">
                          {/* <div className="input-group-prepend">
                                                    <span className="pt-2 border-bottom border-dark rounded-0"><i className="fas fa-envelope fa-lg"></i></span>
                                                </div> */}
                          <div className="input-group-sm input-inline">
                            <input
                              className="form-input"
                              aria-describedby="emailBlock"
                              value={this.state.email}
                              onChange={this.handleInputChange}
                              type="text"
                              name="email"
                              placeholder="Email Address"
                            />
                            <label className="input-label" htmlFor="email">
                              Email Address
                            </label>
                          </div>
                        </div>
                      </div>
                      {this.state.loginAction === "login" ? (
                        <div className="grid-row-sm">
                          <div className="col w-full">
                            {/* <div className="input-group-prepend">
                                                    <span className="pt-2 border-bottom border-dark rounded-0"><i className="fas fa-lock fa-lg"></i></span>
                                                    </div> */}
                            <div className="input-group-sm input-inline">
                              <input
                                className="form-input"
                                value={this.state.password}
                                onChange={this.handleInputChange}
                                type="password"
                                name="password"
                                placeholder="Password"
                              />
                              <label className="input-label" htmlFor="password">
                                Password
                              </label>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="input-group-sm">
                        {this.state.loginAction === "reset" ? (
                          this.state.passwordResetSent ? (
                            <button
                              type="button"
                              className="btn btn-stroke form-btn w-full"
                              disabled
                            >
                              Password Reset Sent
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary form-btn w-full"
                              name="reset"
                              type="submit"
                            >
                              Send Password Reset
                            </button>
                          )
                        ) : (
                          <button
                            className="btn btn-primary form-btn w-full"
                            name="login"
                            type="submit"
                          >
                            Sign In
                          </button>
                        )}
                      </div>
                    </form>
                    <div className="flex flex-wrap justify-between text-gray-400 text-sm">
                      {this.state.loginAction === "reset" ? (
                        <a
                          href="# "
                          onClick={(event) => {
                            event.preventDefault();
                            this.forgotPassword("login");
                          }}
                          className="text-link-gray py-3"
                        >
                          Back to Sign In
                        </a>
                      ) : (
                        <a
                          href="# "
                          onClick={(event) => {
                            event.preventDefault();
                            this.forgotPassword("reset");
                          }}
                          className="text-link-gray py-3"
                        >
                          Forgot Password?
                        </a>
                      )}
                      <a href="/signup" className="text-link-gray py-3">
                        Don't have an account?
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </UserConsumer>
      </OnboardingLayout>
    );
  }
}
Login.contextType = PlayerContext;
export default Login;

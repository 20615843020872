import React, { useContext, useState } from "react";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { CartContext } from "../../contexts/Cart";
import { UserContext } from "../../contexts/User";
import LicenseModal from "../modal/LicenseModal";
import LicenseSummary from "../Licenses/licenseSummary";
import currencyFormatter from "../../utils/currencyFormatter";
import buildAlbumArtURL from "../../utils/buildAlbumArtURL";
import ProjectSelect from "../Projects/ProjectSelect";

const LicenseItem = (props) => {
  const {
    associateProjectToCartItem,
    hideControls,
    hideEditDelete,
    index,
    license,
    project,
    setSelectedProject,
    track,
  } = props;

  const { promo, removeFromCart } = useContext(CartContext);

  const { user } = useContext(UserContext);
  console.log("user in licenseItem", user);

  const albumArtURL = buildAlbumArtURL(track);
  const albumArtAltTag = `${track?.trackTitle} by ${track?.artistName}`;

  const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false);

  const handleLicenseModalOpen = () => {
    setIsLicenseModalOpen(true);
  };
  const handleLicenseModalClose = () => {
    setIsLicenseModalOpen(false);
  };

  const isDiscount = license?.originalPrice !== license?.finalPrice;
  const isPromoDiscount = isDiscount && promo?.discount;
  const isSubscriberDiscount = isDiscount && user?.subscriber;

  const discountAmount = license?.finalPrice * (promo?.discount / 100 || 0);
  const priceAfterDiscount = license?.finalPrice - discountAmount;

  return (
    <>
      <div class="py-20" key={"cartItem-" + index}>
        {/* {# Track Item #} */}
        <div class="flex flex-1 items-center justify-between pb-15">
          {hideControls ? (
            <></>
          ) : (
            <>
              {/* {# Artwork #} */}
              <figure class="license-panel-artwork mr-15">
                <img src={albumArtURL} alt={albumArtAltTag} />
              </figure>
              {/* {# Details #} */}
              <div class="flex flex-col flex-1 leading-snug pr-20">
                <span class="text-white text-sm md:text-base font-medium mb-3">
                  {track?.trackTitle}
                </span>
                <span class="text-xs text-gray-500 mb-10">{track?.artistName}</span>
                <span class="text-xs text-gray-500">
                  <LicenseSummary hasDescription={false} license={license} />
                </span>
              </div>

              {/* {# Controls #} */}
              <div class="flex-none flex flex-col self-stretch items-end">
                {!hideEditDelete ? (
                  <>
                    <div class="flex text-gray-400 mb-3">
                      {/* {# Delete #} */}
                      <button
                        onClick={() => removeFromCart(index)}
                        class="circle-icon circle-icon-xs"
                        type="button"
                      >
                        <span class="icon-trash"></span>
                      </button>
                    </div>
                  </>
                ) : null}

                {/* {# Price #} */}
                {isDiscount && (
                  <div class="text-sm text-gray-400 line-through mb-3">
                    {currencyFormatter(license.originalPrice / 100)}
                  </div>
                )}
                {/* {# Sub Price #} */}
                <div class="flex items-center">
                  <span
                    class={`${
                      isSubscriberDiscount && "icon-checklist"
                    } text-gray-400 text-icon-sm mr-5`}
                  ></span>
                  <span class="text-sm text-white pt-3">
                    {currencyFormatter(priceAfterDiscount / 100)}
                  </span>
                </div>
              </div>
            </>
          )}
        </div>

        {(hideEditDelete) ? (
          <></>
        ) : (
          <>
            <ProjectSelect
              associateProjectToCartItem={associateProjectToCartItem}
              index={index}
              project={project}
              track={track}
            />
          </>
        )}
      </div>
    </>
  );
};

export default LicenseItem;

import React from "react"

const CityGroup = (props)=>
    <li className={`filter-group ${props.isOpen ? " is-open": ""}`} >
        <button className="filter-header" type="button" onClick={props.toggleOpen}>
            {props.groupTitle}
        </button>
        <ul className="filter-subgroup"
            x-ref="subgroup_container"
            // style="open == 1 ? 'max-height: ' + $refs.subgroup_container.scrollHeight + 'px' : ''"
        >
        {/* {% for item in data %}
            {% if add_classes == true %}
                {% if loop.index == 3 %}
                    {% set add_class = "is-active" %}
                {% elif loop.index == 6 %}
                    {% set add_class = "is-ignored" %}
                {% else %}
                    {% set add_class = "" %}
                {% endif %}
            {% else %}
                {% set add_class = "" %}
            {% endif %} */}
            {props.filterOptions.map(option=>
            <li className={`filter-tag ${props.selectedSearchOptions.filter(selectedOption => selectedOption.value === option.value ).length > 0 &&  (props.selectedSearchOptions.filter(selectedOption => selectedOption.value === option.value )[0].include ? "is-active": "is-ignored")}
            `}  key={"filter" + props.type + option.label}>
                <div onClick={()=>props.addSearchOption(option.label, option.value, option.type, option.id)} className="filter-tag-label">{option.label}</div>
                <div className="filter-tag-actions">
                  <button
                    type="button"
                    className="circle-icon circle-icon-sm text-icon-sm icon-plus"
                    onClick={() => props.addSearchOption(option.label, option.value, props.type, option.id)}
                  />
                  <button
                    type="button"
                    className="circle-icon circle-icon-sm text-icon-sm icon-ignore"
                    onClick={() => props.excludeSearchOption(option.label, option.value, props.type, option.id)}
                  />
                </div>
            </li>
            )}
            {props.totalCities > 20 &&
            <>
                {props.showMoreCities ?
                    <li className={'filter-tag is-open'} onClick={()=>props.toggleShowMoreCities(false)} ><div  className="text-link-white" style={{fontSize: "0.76rem"}}>View Less  <span className="icon-chevron-up"></span></div></li>:
                    <li className={'filter-tag'} onClick={()=>props.toggleShowMoreCities(true)} ><div  className="text-link-white" style={{fontSize: "0.76rem"}}>View More <span className="icon-chevron-down"></span></div></li>
                }
            </>}
    </ul>
    </li>

export default CityGroup

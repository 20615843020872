import React from 'react';
import dsLogo from "../../../assets/photos/dsLogo.png";

export default function CollectionByline({ collectionAuthor }) {
  return (
    <>
      <img className="rounded-full w-20 h-20 md:w-30 md:h-30 mr-5" src={dsLogo} /><span class="text-gray-400 mr-3">by</span><a href="/collections" class="text-link-gray">{collectionAuthor}</a>
    </>
  )
}

import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import Helmet from "react-helmet";
import { CartContext } from "../../contexts/Cart";
import { SubscriptionContext } from "../../contexts/Subscription";
import { UserContext } from "../../contexts/User";
import { Error } from "../Toast/index";
import DisabledCheckoutOverlay from "./DisabledCheckoutOverlay";
import LoadingContainer from "../Loading/LoadingContainer";
import LoginSignup from "./LoginSignup";
import SubscriptionItem from "./SubscriptionItem";
import CheckoutForm from "../Forms/CheckoutForm";
import LicenseItem from "./LicenseItem";

const Checkout = (props) => {
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location || {};
  const pathnameParts = pathname.split("/");
  const checkoutType = pathnameParts[2] || "license";

  // console.log("location", location);

  const { isCartLoaded, getSessionCartInfo, userCartInfo } = useContext(CartContext) || {};

  const sessionCartInfo = getSessionCartInfo();

  const {
    // checkoutType,
    getSubscriptionPrices,
    selectedPrice,
    // setCheckoutType,
    subscriptionType,
  } = useContext(SubscriptionContext);

  const { user, hasActiveSubscription, userLoaded } = useContext(UserContext);

  useEffect(() => {
    if (checkoutType === "subscription") {
      getSubscriptionPrices();
    }
  }, []);

  const [isCheckoutEnabled, setIsCheckoutEnabled] = useState(true);

  // setCheckoutType(defaultCheckoutType);
  // const checkoutType = defaultCheckoutType;

  console.log("checkoutData isCartLoaded", isCartLoaded);
  // console.log("checkoutData userLoaded", userLoaded);
  // console.log("checkoutData checkoutType", checkoutType);
  // console.log("checkoutData userCartInfo", userCartInfo);
  console.log("checkoutData selectedPrice", selectedPrice);

  let currentCartInfo = [];
  if (userCartInfo?.length > 0) {
    currentCartInfo = userCartInfo;
  } else if (sessionCartInfo?.length > 0) {
    currentCartInfo = sessionCartInfo;
  }

  console.log("checkoutData userCartInfo", userCartInfo);
  console.log("checkoutData sessionCartInfo", sessionCartInfo);
  console.log("checkoutData currentCartInfo", currentCartInfo);

  if (
    userLoaded &&
    isCartLoaded &&
    checkoutType === "license" &&
    !currentCartInfo?.length > 0 &&
    !selectedPrice?.id
  ) {
    history.push("/music");
    Error({
      message: "Your cart is empty",
    });
  }

  if (checkoutType === "subscription" && userLoaded && hasActiveSubscription(user)) {
    history.push("/dashboard/account/subscriptions");
    Error({
      message: "You already have a subscription. You can manage it here.",
    });
  }

  return (
    <>
      <Helmet title="Checkout - Deep Sounds | Music for Creators" />
      {!isCheckoutEnabled && <DisabledCheckoutOverlay />}
      <div class="container">
        <LoadingContainer
          isLoaded={
            checkoutType && ((currentCartInfo?.length > 0 && isCartLoaded) || selectedPrice?.id)
          }
        >
          {!user ? (
            <div className="grid-row justify-between">
              <div class="col w-full md:flex-1 md:w-1/2 xl:flex-initial">
                <LoginSignup checkoutType={checkoutType} />
              </div>
              <div class="col w-full md:flex-none md:w-1/2">
                {/* {# Tracks in Cart #} */}
                <div class="mb-40 lg:mb-60">
                  <h3 class="text-h3 mb-10">
                    {checkoutType === "subscription" && `Subscription `}
                    Checkout
                  </h3>
                  {checkoutType === "subscription" ? (
                    <SubscriptionItem selectedPrice={selectedPrice} />
                  ) : (
                    <></>
                  )}
                  {checkoutType === "license" ? (
                    currentCartInfo.map((licenseItem, index) => {
                      let license = licenseItem.license;

                      return (
                        <LicenseItem
                          hideEditDelete={true}
                          track={licenseItem.track}
                          license={license}
                          index={index}
                        />
                      );
                    })
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          ) : (
            <CheckoutForm
              checkoutType={checkoutType}
              currentCartInfo={currentCartInfo}
              selectedPrice={selectedPrice}
            />
          )}
        </LoadingContainer>
      </div>
    </>
  );
};

export default Checkout;

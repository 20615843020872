import React from "react";
import AsyncSelect from 'react-select/async';
import customStylesMulti from "../FormElements/customStylesMulti";
import { Error } from "../Toast/index";
import MobileArtistFilters from "./mobileArtistFilters";




const SearchArtists = (props)=>{
  const genreOptions = props.searchOptions.filter(option=> option.type === "genre")
  const moodOptions = props.searchOptions.filter(option=> option.type === "mood")
  const emotionOptions = props.searchOptions.filter(option=> option.type === "emotion")
  const attributeOptions = props.searchOptions.filter(option=> option.type === "attribute")
  const lyricOptions = props.searchOptions.filter(option=> option.type === "lyric")
  const bpmOptions = props.searchOptions.filter(option=> option.type === "bpm")
  const cityOptions = props.searchOptions.filter(option=> option.type === "city")
  const instrumentOptions = props.searchOptions.filter(option=> option.type === "instrument")

const groupedOptions = [
  {
    label: "Genres",
    options: genreOptions
  },
  {
    label: "Moods",
    options: moodOptions
  },
  {
    label: "emotions",
    options: emotionOptions
  },
  {
    label: "attributes",
    options: attributeOptions
  },
  {
    label: "lyrics",
    options: lyricOptions
  },
  {
    label: "bpm",
    options: bpmOptions
  },
  {
    label: "instruments",
    options: instrumentOptions
  },
  {
    label: "cities",
    options: cityOptions
  },
  {
    label: "tracks",
    options: props.trackOptions
  },
  {
    label: "artists",
    options: props.artistOptions
  },

  {
    label: "collections",
    options: props.collectionOptions
  },
  {
    label: "keywords",
    options: props.keywordOptions
  }
]
const filterOption = (option, inputValue) => {

  const { label, value} = option;
  // const otherKey = options.filter(
  //   opt => opt.label === label && opt.value.includes(inputValue)
  // );
  try{
      if(inputValue.length > 2 && option.data.type === "artist"){
        props.getArtistOptions(inputValue.toLowerCase())
        return inputValue.length > 1 ? (label ? label.toLowerCase().match(new RegExp('^' + inputValue.toLowerCase())): false ): false
      }
      if(inputValue.length > 2 && option.data.type === "track"){
        props.getTrackOptions(inputValue.toLowerCase())
        return inputValue.length > 1 ? (label ? label.toLowerCase().match(new RegExp('^' + inputValue.toLowerCase())): false ): false
      }
      if(inputValue.length > 2 && option.data.type === "collection"){
        props.getCollectionOptions(inputValue.toLowerCase())
        return inputValue.length > 1 ? (label ? label.toLowerCase().match(new RegExp('^' + inputValue.toLowerCase())): false ): false
      }
      if(inputValue.length > 2 && !option.data.type){
        return inputValue.length > 1 ? (label ? label.toLowerCase().includes(inputValue.toLowerCase()): false ): false
      }
      return inputValue.length > 1 ? (label ? label.toLowerCase().match(new RegExp('^' + inputValue.toLowerCase())): false ): false
  }
  catch(error){
    return false
  }
};
const resolveProfilePhoto = (profilePhotoKey)=>{
  if(profilePhotoKey){
      const key = profilePhotoKey
      const ext = key.split('.').reverse()[0];
      let final = key.replace('.' + ext, '') + '-minified-60x60' + '.' + ext
      return final
  }
}
const resolveAlbumPhoto = (albumPhotoKey)=>{
  if(albumPhotoKey){
      const key = albumPhotoKey
      const ext = key.split('.').reverse()[0];
      let final = key.replace('.' + ext, '') + '-minified-140x140' + '.' + ext
      return final
  }
}
const resolveCollectionPhoto = (collectionPhotoKey)=>{
  if(collectionPhotoKey){
      const key = collectionPhotoKey
      const ext = key.split('.').reverse()[0];
      let final = key.replace('.' + ext, '') + '-minified-140x140' + '.' + ext
      return final
  }
}
const artistLabel=(value, artistName, profilePhoto)=>(
  <div className="flex items-center" style={value==="placeholder"? {display: "none"}: {}}>
      <img
          src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${resolveProfilePhoto(profilePhoto)}`}
          alt={`${artistName} picture`}
          className="w-20 h-20 bg-gray-300 mr-15"
      />
      {artistName}
  </div>
)
const trackLabel=(value, artistName, profilePhoto)=>(
  <div className="flex items-center" style={value==="placeholder"? {display: "none"}: {}}>
      <img
          src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${resolveAlbumPhoto(profilePhoto)}`}
          alt={`${artistName} picture`}
          className="w-20 h-20 bg-gray-300 mr-15"
      />
      {artistName}
  </div>
)
const collectionLabel=(value, artistName, profilePhoto)=>(
  <div className="flex items-center" style={value==="placeholder"? {display: "none"}: {}}>
      <img
          src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${resolveCollectionPhoto(profilePhoto)}`}
          alt={`${artistName} picture`}
          className="w-20 h-20 bg-gray-300 mr-15"
      />
      {artistName}
  </div>
)
const trackLengthLabel=(label, value, include)=>{
  let upperBound = parseInt(label.split("-")[1].split(":")[0])
  if(upperBound === 12){
    return(
    <div className="flex items-center" style={{color: include || include === undefined ? "" : "red"}}>
      {label + "+"}
    </div>)
  }
  else{
    return(
    <div className="flex items-center" style={{color: include || include === undefined ? "" : "red"}}>
      {label}
    </div>)
  }

}
const defaultLabel=(label, value, include)=>(
  <div className="flex items-center" style={{color: include || include === undefined ? "" : "red"}}>
      {label}
  </div>
)
const keywordLabel=(label, value)=>(
  <div className="flex items-center">
      "{label}"

  </div>
)
const stemLabel =()=>(
  <div className="flex items-center">
    Has Stems
  </div>
)
const createLabel = ({label, value})=>(
  <></>
)
let currentInputVal = ""

const preventSubmit= (e, values)=>{
  e.preventDefault()
  // if(currentInputVal.length < 2){
  //   Error({message: "Search query must be at least two characters"})
  // }
  // else{
  //   Error({message: "Search query can only contain alphanumeric characters"})
  // }
  Error({message: "Please select a valid tag."})
}
return (
  <form className="ds-form mb-20 md:mb-30" onSubmit={preventSubmit}>
    <div className="browse-search" id="trackSearch">
      <div className="flex-1">
        {/* {# Search Input #} */}
        <label className="hidden" htmlFor="track_search" >Search Tracks</label>
        <AsyncSelect
          cacheOptions
          loadOptions={props.loadOptions}
          name="search"
          // allowCreateWhileLoading
          styles={customStylesMulti}
          classNamePrefix="select"
          className="select-search select2-container"
          placeholder={"Search anything..."}
          value={props.selectedSearchOptions}
          // defaultOptions={groupedOptions}
          create={false}
          isValidNewOption={(inputValue, selectValue, selectOptions)=>{
            let allOptions = []
            selectOptions.filter(option=>{
              return option.label !== "artists" && option.label !== "tracks" && option.label !== "collections" && option.label !== "keywords"
            }).forEach(option=>{
              let optionsArr = option.options
              if(optionsArr){
                allOptions = allOptions.concat(optionsArr.map(opt=>opt.label.toLowerCase()))
              }
            })

            currentInputVal = inputValue
            if(allOptions.indexOf(inputValue.toLowerCase()) ===-1){
              if(/^[a-zA-Z0-9\s]+$/.test(inputValue)){
                return true
              }
              else{

                return false
              }
            }
            else{
              return false
            }

          }}
          onChange={props.handleSearchChange}
          createOptionPosition={false}
          formatCreateLabel={userInput=>`Search "${userInput}"`}
          isMulti
          isSearchable
          // filterOption={filterOption}
          // formatCreateLabel={(option)=>createLabel(option)}
          formatOptionLabel={(option)=>{
            switch(option.type){
              case "artist":
                  return artistLabel(option.value, option.label, option.profilePhoto)
                  break;
              case "track":
                  return trackLabel(option.value, option.value, option.profilePhoto)
                  break;
              case "collection":
                  return collectionLabel(option.value, option.label, option.profilePhoto)
                  break;
              case "keyword":
                  return keywordLabel(option.value, option.value)
                  break;
              case "stems":
                  return stemLabel()
                  break;
              case "trackLength":
                  return trackLengthLabel(option.value, option.label, )
              default:
                  return defaultLabel(option.label, option.value, option.include)
            }}}
          // onCreateOption={props.handleSearchChange}
          noOptionsMessage={() => null}
          key={`my_unique_select_key__${props.selectedSearchOptions}`}
        />
      </div>
      <div className="browse-filters-toggle pl-10">
        <button
          className="circle-icon circle-icon-lg"
                  type="button"
                  onClick={props.toggleFilterMenu}
          // @click="open = true"
        >
          <span className="icon-filter"></span>
        </button>
      </div>

      {/* {# Mobile Filter Toggle #} */}


      {/* {# Mobile Filter Menu #} */}
      <div
        className={`filters-panel ${props.filterMenuOpen ? "is-open": 'is-closed'}`}
      >
        <div className="filters-panel-header">
          <div className="text-label-lg">Filters</div>
          <button
            className="text-label-lg text-link h-full"
                      type="button"
                      onClick={props.toggleFilterMenu}
            // @click="open = false"
          >
            Done
          </button>
        </div>
        <MobileArtistFilters filters={props.filters} toggleMobileFilter={props.toggleMobileFilter} isGenresOpen={props.isGenresOpen} isMoodsOpen={props.isMoodsOpen} isEmotionOpen={props.isEmotionOpen} isAttributesOpen={props.isAttributesOpen}
            isLyricsOpen={props.isLyricsOpen} isBPMOpen={props.isBPMOpen} isCitiesOpen={props.isCitiesOpen} isVersionsOpen={props.isVersionsOpen} addSearchOption={props.addSearchOption} excludeSearchOption={props.excludeSearchOption} selectedSearchOptions={props.selectedSearchOptions}
            trackBrowse={props.trackBrowse} changeStem={props.changeStem} stemsRequired={props.stemsRequired} isInstrumentsOpen={props.isInstrumentsOpen}
            lengthFilterValue={props.lengthFilterValue} lengthFilterMin={props.lengthFilterMin} lengthFilterMax={props.lengthFilterMax} onSliderChange={props.onSliderChange} enableLengthRange={props.enableLengthRange} isLengthRangeEnabled={props.isLengthRangeEnabled} sliderFinished={props.sliderFinished}
          />
      </div>
    </div>
  </form>
)}

export default SearchArtists

import axios from "axios";

const purchasesAPI = {
  create: function ({ purchaseData }) {
    return axios({
      method: "post",
      url: "/api/purchases",
      data: { purchaseData }
    });
  },
  getByOrderNumber: function (orderNumber) {
    return axios({
      method: "get",
      url: "/api/purchases/byOrderNumber/" + orderNumber
    });
  }
};
export default purchasesAPI;

import moment from "moment";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import DefaultAvatar from "../../assets/svg/user-avatar.svg";
import { FavoritesConsumer } from "../../FavoritesContext";
import { PlayerContext } from "../../PlayerContext";
import CircularIndeterminant from "../Loading/circularIndeterminant";
import NotFound from "../NotFound";
import { Error, Success } from "../Toast/index";
import TrackListItem from "../Track/trackListItem";
import api from "../utils/api";
import AlbumCard from "./albumCard";
import ArtistDetailHelmet from "./ArtistDetailHelmet";
import ArtistTooltip from "./artistTooltip";
import Event from "./event";
import SimilarArtists from "./similarArtists";

const queryString = require("query-string");

const contentDis = require("content-disposition");
class Artist extends Component {
  state = {
    activeTab: "about",
    likedTracks: [],
    track: {},
    artist: {},
    tracks: [],
    featuredTracks: [],
    albums: [],
    versions: [],
    genres: [],
    speed: "",
    city: "",
    emotion: "",
    moods: [],
    instruments: [],
    attributes: [],
    funFact: "",
    stems: [],
    moreTracks: [],
    moreFromArtistDis: "list",
    relatedTrackDis: "grid",
    events: [],
    similarArtists: [],
  };
  resolveCoverPhoto = (coverPhotoKey) => {
    const key = coverPhotoKey;
    if (coverPhotoKey) {
      const ext = key.split(".").reverse()[0];
      let final = key.replace("." + ext, "") + "-minified" + "." + ext;
      return final;
    }
  };
  resolveArtistPhoto = (artistPhotoKey) => {
    const key = artistPhotoKey;
    if (artistPhotoKey) {
      const ext = key.split(".").reverse()[0];
      let sizeMod;
      let width = window.innerWidth;
      if (width > 1280) {
        sizeMod = "325x325";
      } else {
        sizeMod = "140x140";
      }
      let final = key.replace("." + ext, "") + "-minified-" + sizeMod + "." + ext;
      return final;
    }
  };
  resolveAlbumPhoto = (albumPhotoKey) => {
    const key = albumPhotoKey;
    if (albumPhotoKey) {
      const ext = key.split(".").reverse()[0];
      let sizeMod;
      let width = window.innerWidth;
      if (width > 1024) {
        sizeMod = "500x500";
      } else {
        sizeMod = "140x140";
      }
      let final = key.replace("." + ext, "") + "-minified-" + sizeMod + "." + ext;
      return final;
    }
  };
  componentDidMount = () => {
    api
      .getArtistForDetailPage(this.props.match.params.artistURL)
      .then((res) => {
        if (res.data) {
          let artist = res.data.artist;
          let tracks = res.data.artist.tracks;
          let albums = [];
          tracks.forEach((track, index) => {
            let albumIndex = tracks.findIndex((tra) => {
              return track.albumId === tra.albumId;
            });
            if (albumIndex === index) {
              albums.push({
                id: track.albumId,
                albumTitle: track.albumTitle,
                artWork: track.imageURL,
                liked: track.albumLiked,
              });
            }
          });
          let featuredTracks = [...tracks.concat().slice(0, 5)];
          // featuredTracks = featuredTracks.map((track, index)=> {
          //     track.idKey =`artist-${this.context.loadedTracks[track.trackId].artistId}-album-${this.context.loadedTracks[track.trackId].albumId}-track-${track.trackId}-element-browseTrackListItem`
          //     track.artistURL= this.props.match.params.artistURL
          //     return track
          // })

          let eventRes = res.data.artist.events;
          eventRes.forEach((event, index) => {
            let datetime = event.date;
            eventRes[index].month = moment(datetime).format("MMM");
            eventRes[index].day = moment(datetime).get("date");
            eventRes[index].year = moment(datetime).get("year");
            eventRes[index].time = moment(datetime).format("hh:mm A");
            eventRes[index].dayOfWeek = moment(datetime).format("dddd");
          });

          let parsedQuery = queryString.parse(this.props.location.search);
          this.context.getTracksInfo(tracks.map((track) => track.trackId));
          this.setState(
            {
              artist: artist,
              tracks: tracks,
              albums: albums,
              featuredTracks: featuredTracks,
              events: eventRes,
              notFound: false,
              following: res.data.artist.following === 1 ? true : false,
              isLoaded: true,
              similarArtists: res.data.similarArtists,
            },
            () => {
              if (parsedQuery.autoplay === "true") {
                this.playFeaturedTracks();
              }
              if (this.props.location.hash.length > 0) {
                const albumEl = document.getElementById(this.props.location.hash.substring(1));
                //
                if (albumEl) {
                  albumEl.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });

                  // document.querySelector(".app-main").scrollTo({top: (albumEl.offsetTop -80), left: albumEl.offsetLeft, behavior: "smooth"})
                }
              }
            }
          );
        }
      })
      .catch((error) => {
        if (error?.response?.status === 404) {
          this.setState({ notFound: true, isLoaded: true });
        }
      });
  };
  componentDidUpdate = (prevProps) => {
    if (prevProps.match.params.artistURL !== this.props.match.params.artistURL) {
      document.getElementsByClassName("app-main")[0].scrollTo(0, 0);

      this.setState({ isLoaded: false }, () => {
        api
          .getArtistForDetailPage(this.props.match.params.artistURL)
          .then((res) => {
            if (res.data) {
              let artist = res.data.artist;
              let tracks = res.data.artist.tracks;
              let albums = [];
              tracks.forEach((track, index) => {
                let albumIndex = tracks.findIndex((tra) => {
                  return track.albumId === tra.albumId;
                });
                if (albumIndex === index) {
                  albums.push({
                    id: track.albumId,
                    albumTitle: track.albumTitle,
                    artWork: track.imageURL,
                    liked: track.albumLiked,
                  });
                }
              });
              let featuredTracks = [...tracks.concat().slice(0, 5)];
              // featuredTracks = featuredTracks.map((track, index)=> {
              //     track.idKey ="featured"+track.artistId+"TrackListGridItem-"+track.trackId
              //     track.artistURL= this.props.match.params.artistURL
              //     return track
              // })
              let eventRes = res.data.artist.events;
              eventRes.forEach((event, index) => {
                let datetime = event.date;
                eventRes[index].month = moment(datetime).format("MMM");
                eventRes[index].day = moment(datetime).get("date");
                eventRes[index].year = moment(datetime).get("year");
                eventRes[index].time = moment(datetime).format("hh:mm A");
                eventRes[index].dayOfWeek = moment(datetime).format("dddd");
              });

              let parsedQuery = queryString.parse(this.props.location.search);
              this.context.getTracksInfo(tracks.map((track) => track.trackId));

              this.setState(
                {
                  artist: artist,
                  tracks: tracks,
                  albums: albums,
                  featuredTracks: featuredTracks,
                  events: eventRes,
                  isLoaded: true,
                  notFound: false,
                  following: res.data.artist.following === 1 ? true : false,
                  similarArtists: res.data.similarArtists,
                },
                () => {
                  if (parsedQuery.autoplay === "true") {
                    this.playFeaturedTracks();
                  }
                  if (this.props.location.hash.length > 0) {
                    const albumEl = document.getElementById(this.props.location.hash.substring(1));
                    //
                    if (albumEl) {
                      albumEl.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                        inline: "nearest",
                      });

                      // document.querySelector(".app-main").scrollTo({top: (albumEl.offsetTop -80), left: albumEl.offsetLeft, behavior: "smooth"})
                    }
                  }
                }
              );
            }
          })
          .catch((error) => {
            if (error?.response?.status === 404) {
              this.setState({ notFound: true, isLoaded: true });
            }
          });
      });
    } else if (prevProps.location.hash !== this.props.location.hash) {
      if (this.props.location.hash.length > 0) {
        const albumEl = document.getElementById(this.props.location.hash.substring(1));
        //
        if (albumEl) {
          albumEl.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });

          // document.querySelector(".app-main").scrollTo({top: (albumEl.offsetTop -80), left: albumEl.offsetLeft, behavior: "smooth"})
        }
      } else {
        document.getElementsByClassName("app-main")[0].scrollTo(0, 0);
      }
    }
  };
  shareArtist = () => {
    var textArea = document.createElement("textarea");

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = "fixed";
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = "2em";
    textArea.style.height = "2em";

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = "none";
    textArea.style.outline = "none";
    textArea.style.boxShadow = "none";

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = "transparent";

    textArea.value = window.location.href;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand("copy");
      if (successful) {
        Success({ message: "Artist URL Copied to Clipboard" });
      } else {
        Error({ message: "Couldn't Copy Artist URL " });
      }
      var msg = successful ? "successful" : "unsuccessful";
    } catch (err) {}

    document.body.removeChild(textArea);

    this.setState({ showArtistTippy: false });
  };

  likeTrack = (trackId) => {
    api.likeTrack(trackId).then((res) => {
      if (res.data.success) {
        let tracks = this.state.tracks.concat();
        tracks.forEach((track, index) => {
          if (track.trackId === trackId) {
            tracks[index].liked = 1;
          }
        });
        // likedTracks.push(trackId.toString())

        this.setState({ tracks: tracks });
      }
    });
  };
  unlikeTrack = (trackId) => {
    api.unlikeTrack(trackId).then((res) => {
      if (res.data.success) {
        let tracks = this.state.tracks.concat();
        tracks.forEach((track, index) => {
          if (track.trackId === trackId) {
            tracks[index].liked = 0;
          }
        });
        // likedTracks.push(trackId.toString())
        this.setState({ tracks: tracks });
      }
    });
  };
  likeAlbum = (albumId) => {
    api.likeAlbum(albumId).then((res) => {
      if (res.data.success) {
        let albums = this.state.albums.concat();
        albums.forEach((album, index) => {
          if (album.id === albumId) {
            albums[index].liked = 1;
          }
        });
        this.setState({ albums: albums });
      }
    });
  };
  unlikeAlbum = (albumId) => {
    api.unlikeAlbum(albumId).then((res) => {
      if (res.data.success) {
        let albums = this.state.albums.concat();
        albums.forEach((album, index) => {
          if (album.id === albumId) {
            albums[index].liked = 0;
          }
        });
        this.setState({ albums: albums });
      }
    });
  };

  playFeaturedTracks = () => {
    //check if track in featured tracklist
    let trackId = parseInt(this.context.mediaPlayer.idKey?.split("-")[1]);
    if (
      (this.context.mediaPlayer.idKey?.indexOf("featuredTracks-" + this.state.artist.id) || -1) !==
      -1
    ) {
      this.context.handlePlay();
    } else {
      let playList = this.state.featuredTracks.map((track) => track.trackId);
      this.context.setPlaylist(playList);
      this.context.setAutoPlay(true);
      this.context.loadTrackInMediaPlayer(
        this.state.featuredTracks[0].trackId,
        `artist-${
          this.context.loadedTracks[this.state.featuredTracks[0].trackId]?.artistId
        }-album-${this.context.loadedTracks[this.state.featuredTracks[0].trackId]?.albumId}-track-${
          this.state.featuredTracks[0].trackId
        }-element-featuredTracks-${
          this.context.loadedTracks[this.state.featuredTracks[0].trackId]?.artistId
        }`
      );
      this.setState({ showArtistTippy: false });
    }
  };
  playAlbum = (e, albumId, albumTitle) => {
    // if(this.context.mediaPlayer.idKey?.indexOf("album") !== -1 && this.context.mediaPlayer.idKey?.indexOf("featured") !==undefined ){
    //     this.context.handlePause()
    // }
    if ((this.context.mediaPlayer.idKey?.indexOf("albumTrackItem-" + albumId) || -1) === -1) {
      let playList = [...this.state.tracks.filter((track) => track.albumId === albumId)]
        .concat()
        .map((track) => track.trackId);
      this.context.setPlaylist(playList);
      const albumEl = document.getElementById(this.props.location.hash.substring(1));

      e.preventDefault();
      if (albumEl) {
        albumEl.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
      }
      // document.querySelector(".app-main").scrollTo({top: albumEl.offsetTop -200, left: albumEl.offsetLeft, behavior: "smooth"})
      this.props.history.push("#album-" + albumTitle.replace(/\s/g, "-"));
      this.context.loadTrackInMediaPlayer(
        playList[0],
        `artist-${this.context.loadedTracks[playList[0]]?.artistId}-album-${albumId}-track-${
          playList[0]
        }-element-albumTrackItem-${albumId}`
      );
      this.context.setAutoPlay(true);
    } else {
      this.context.handlePlay();
    }
  };
  viewAlbums = (e) => {
    const featuredEl = document.querySelector("#albumsSection");
    featuredEl.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
    e.preventDefault();
    this.setState({ showArtistTippy: false });
  };
  viewEvents = (e) => {
    this.setState({ activeTab: "shows", showArtistTippy: false });
    e.preventDefault();
    const featuredEl = document.querySelector("#aboutShowsSection");
    featuredEl.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  };
  downloadWatermarked = () => {
    let header;
    //get the watermarked version of the requested file
    fetch("/api/tracks/downloadWatermarked/" + this.state.track.trackId)
      .then((resp) => {
        header = resp.headers.get("content-disposition");

        //read the stream as a blob
        return resp.blob();
      })
      .then((blob) => {
        //create a dummy element to download the file from the blob
        let downloadElem = document.createElement("a");
        const url = URL.createObjectURL(blob, { type: "audio/mpeg" });
        let filename;
        if (header) {
          const parsed = contentDis.parse(header);

          if (parsed.parameters && parsed.parameters.filename) {
            filename = parsed.parameters.filename;
          }
        }
        downloadElem.setAttribute("href", url);
        downloadElem.setAttribute("download", filename || "filename");
        downloadElem.style.display = "none";

        document.body.appendChild(downloadElem);

        downloadElem.click();
        URL.revokeObjectURL(url);
        document.body.removeChild(downloadElem);
      })
      .catch((err) => console.err(err));
  };

  render() {
    return (
      <FavoritesConsumer>
        {({
          artists,
          tracks,
          albums,
          collections,
          likeTrack,
          unlikeTrack,
          followArtist,
          unfollowArtist,
          likeAlbum,
          unlikeAlbum,
        }) => (
          <>
            {this.state.artist.artistName && (
              <ArtistDetailHelmet
                artist={this.state.artist}
                artistURL={this.props.match.params.artistURL}
                featuredTracks={this.state.featuredTracks}
              />
            )}
            {/* {# Hero #} */}
            {this.state.isLoaded ? (
              <>
                {this.state.notFound ? (
                  <NotFound />
                ) : (
                  <>
                    <div className="hero-bg">
                      <div
                        className="hero-img"
                        style={{
                          backgroundImage: `url("https://${
                            process.env.REACT_APP_S3_PHOTO_BUCKET
                          }.s3.us-east-2.amazonaws.com/${
                            process.env.REACT_APP_S3_SUB_BUCKET
                              ? process.env.REACT_APP_S3_SUB_BUCKET + "/"
                              : ""
                          }${this.resolveCoverPhoto(this.state.artist?.coverPhoto)}")`,
                        }}
                      ></div>
                      :
                    </div>

                    <div className="container pt-140 md:pt-300 z-10">
                      {/* {# Artist View Header #} */}
                      <div className="artist-view-header">
                        {/* {# Artist View Info #} */}
                        <div className="artist-view-info">
                          <div className="flex flex-1 items-center mb-30 lg:mb-0">
                            {/* {# Artist Image #}                     */}
                            <div className="artist-view-img">
                              {this.state.artist.profilePhoto ? (
                                <img
                                  className="rounded-full"
                                  src={`https://${
                                    process.env.REACT_APP_S3_PHOTO_BUCKET
                                  }.s3.us-east-2.amazonaws.com/${
                                    process.env.REACT_APP_S3_SUB_BUCKET
                                      ? process.env.REACT_APP_S3_SUB_BUCKET + "/"
                                      : ""
                                  }${this.resolveArtistPhoto(this.state.artist.profilePhoto)}`}
                                  alt=""
                                />
                              ) : (
                                <img className="rounded-full" src={DefaultAvatar} alt="" />
                              )}
                            </div>

                            {/* {# Artist View Details #} */}
                            <div className="artist-view-title">
                              <h1 className="text-h3 xl:text-h1 mb-5">
                                {this.state.artist.artistName}
                              </h1>
                              <ul className="text-base xl:text-h4">
                                <Link
                                  to={{
                                    pathname: "/music",
                                    search: `?inc_gen=${this.state.artist.primaryGenre}`,
                                    state: { from: window.location.pathname },
                                  }}
                                  className="text-link-dark"
                                >
                                  {this.state.artist.primaryGenreName}
                                </Link>
                                {this.state.artist.secondaryGenre && (
                                  <>
                                    {" "}
                                    &{" "}
                                    <Link
                                      to={{
                                        pathname: "/music",
                                        search: `?inc_gen=${this.state.artist.secondaryGenre}`,
                                        state: { from: window.location.pathname },
                                      }}
                                      className="text-link-dark"
                                    >
                                      {this.state.artist.secondaryGenreName}
                                    </Link>
                                  </>
                                )}
                              </ul>
                              <div className="text-white mb-5">
                                {this.state.tracks.length}
                                {this.state.tracks.length > 1 ? ` Tracks` : ` Track`}
                              </div>
                            </div>
                          </div>

                          {/* {# Artist View CTA #} */}
                          <div className="artist-view-cta">
                            <div className="grid-row-xs">
                              <div className="col flex-1 md:flex-initial md:w-140">
                                {this.context.mediaPlayer.idKey?.indexOf(
                                  `featuredTracks-${this.state.artist.id}`
                                ) !== -1 &&
                                (this.context.mediaPlayer.isTrackPlaying ||
                                  this.context.isVideoPlaying) ? (
                                  <button
                                    onClick={this.context.handlePause}
                                    className="btn btn-stroke w-full"
                                  >
                                    <span className="icon-pause"></span>Pause
                                  </button>
                                ) : (
                                  <button
                                    onClick={this.playFeaturedTracks}
                                    className="btn btn-stroke w-full"
                                  >
                                    <span className="icon-play"></span>Play
                                  </button>
                                )}
                              </div>
                              <div className="col flex-1 md:flex-initial md:w-140">
                                {artists?.filter((fav) => fav.artistId === this.state.artist?.id)
                                  .length === 1 ? (
                                  <button
                                    onClick={() =>
                                      unfollowArtist(
                                        this.state.artist.id,
                                        this.state.artist.artistName
                                      )
                                    }
                                    className="btn btn-primary w-full"
                                  >
                                    <span className="icon-follow-heart"></span>Unfollow
                                  </button>
                                ) : (
                                  <button
                                    onClick={() =>
                                      followArtist(
                                        this.state.artist.id,
                                        this.state.artist.artistName
                                      )
                                    }
                                    className="btn btn-primary w-full"
                                  >
                                    <span className="icon-follow-heart"></span>Follow
                                  </button>
                                )}
                              </div>
                              <div className="col flex flex-none items-center">
                                <Tooltip
                                  arrow={false}
                                  open={this.state.showArtistTippy}
                                  interactive={true}
                                  position={"bottom-end"}
                                  popperOptions={{
                                    modifiers: {
                                      preventOverflow: { enabled: false },
                                      flip: { enabled: false },
                                      hide: { enabled: false },
                                      addZIndex: {
                                        enabled: true,
                                        order: 810,
                                        fn: (data) => ({
                                          ...data,
                                          styles: {
                                            ...data.styles,
                                            zIndex: 10,
                                          },
                                        }),
                                      },
                                    },
                                  }}
                                  trigger={"click"}
                                  useContext={true}
                                  // hideOnClick={true}
                                  html={
                                    <ArtistTooltip
                                      // className="hidden"
                                      artistId={this.state.artist?.id}
                                      artistName={this.state.artist?.artistName}
                                      following={
                                        artists?.filter(
                                          (fav) => fav.artistId === this.state.artist?.id
                                        ).length === 1
                                      }
                                      followArtist={followArtist}
                                      unfollowArtist={unfollowArtist}
                                      playFeatured={this.playFeaturedTracks}
                                      viewAlbums={this.viewAlbums}
                                      shareArtist={this.shareArtist}
                                      eventsCount={this.state.events.length}
                                      viewEvents={this.viewEvents}
                                    ></ArtistTooltip>
                                  }
                                  allowHTML={true}
                                  onUntrigger={() => {
                                    this.state.showArtistTippy
                                      ? this.setState({ showArtistTippy: false })
                                      : this.setState({ showArtistTippy: true });
                                  }}
                                  onRequestClose={() => {
                                    this.state.showArtistTippy
                                      ? this.setState({ showArtistTippy: false })
                                      : this.setState({ showArtistTippy: true });
                                  }}
                                >
                                  <button
                                    id={"artistTippy"}
                                    className="circle-icon js-dropdown-toggle"
                                    onClick={() => {
                                      this.state.showArtistTippy
                                        ? this.setState({ showArtistTippy: false })
                                        : this.setState({ showArtistTippy: true });
                                    }}
                                    data-tippy-html="#track_detail_options"
                                    data-tippy-placement="bottom-end"
                                    type="button"
                                  >
                                    <span className="icon-dots-vert"></span>
                                  </button>
                                </Tooltip>
                              </div>
                            </div>
                          </div>

                          {/* {# Track Details Dropdown #} */}
                          {/* {% include "components/dropdowns/track-detail-dropdown.html" %} */}
                        </div>

                        {/* {# Artist Tabs #} */}
                        <div className="mb-50">
                          <div className="tabs">
                            <ul className="tab-items">
                              <div className="scroll-offset-parent">
                                <div id={"aboutShowsSection"} className="scroll-offset-el" />
                              </div>
                              <li
                                className={`tab-item ${
                                  this.state.activeTab === "about" && "active"
                                }`}
                              >
                                <button
                                  onClick={() => {
                                    this.setState({ activeTab: "about" });
                                  }}
                                  className="tab-item-title"
                                  click="tab = 'about'"
                                >
                                  About
                                </button>
                              </li>
                              {this.state.events.length > 0 && (
                                <li
                                  className={`tab-item ${
                                    this.state.activeTab === "shows" && "active"
                                  }`}
                                >
                                  <button
                                    className="tab-item-title"
                                    onClick={() => {
                                      this.setState({ activeTab: "shows" });
                                    }}
                                  >
                                    Shows
                                  </button>
                                </li>
                              )}
                            </ul>
                            <div className="tab-content">
                              {/* {# About #} */}
                              {this.state.activeTab === "about" && (
                                <div className="tab-item-content lg:pb-30 active">
                                  <div className="grid-row-lg">
                                    {/* {# Bio #} */}
                                    <div className="col w-full md:w-8/12 mb-30 md:mb-0">
                                      <h3 className="text-label-sm mb-5">Artist Bio</h3>
                                      <div className="text-block text-white xl:text-h4">
                                        {this.state.artist.bio &&
                                          this.state.artist.bio
                                            .split(/\n/g)
                                            .map((bioSec) => <p>{bioSec}</p>)}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              {/* {# shows #} */}
                              {this.state.activeTab === "shows" && (
                                <div className="tab-item-content active">
                                  {/* {# Audio Items ==> layouts/components/ui/event-items.html #} */}
                                  <div className="grid-row -mx-40 xl:-mx-20">
                                    {/* {% for item in events %} */}
                                    {this.state.events.map((event) => (
                                      <Event {...event} key={event.evenTitle}></Event>
                                    ))}

                                    {/* {% include "components/ui/event-item.html" %} */}
                                    {/* </div> */}
                                    {/* {% endfor %} */}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* {# Featured Tracks #} */}
                      <div className="mb-50 md:mb-80">
                        <div className="tracks-heading mb-20 md:mb-40">
                          <h3 className="tracks-heading-title" id={"featuredTracksTitle"}>
                            Featured Tracks
                            <div
                              onClick={this.playFeaturedTracks}
                              className="circle-icon-border ml-15"
                            >
                              <span className="icon-chevron-right"></span>
                            </div>
                          </h3>
                        </div>

                        {/* {# Tracks #} */}
                        <div className="track-list-container">
                          {this.state.featuredTracks.map((track) => (
                            <TrackListItem
                              {...this.context.loadedTracks[track.trackId]}
                              key={"featuredListItem-" + track.trackId}
                              idKey={`artist-${
                                this.context.loadedTracks[track.trackId]?.artistId
                              }-album-${this.context.loadedTracks[track.trackId]?.albumId}-track-${
                                track.trackId
                              }-element-featuredTracks-${
                                this.context.loadedTracks[track.trackId]?.artistId
                              }`}
                              likedTracks={this.state.likedTracks}
                              likeTrack={this.likeTrack}
                              unlikeTrack={this.unlikeTrack}
                              currentTime={this.state.currentTime}
                              changeTrack={this.changeTrack}
                              artistName={this.state.artist.artistName}
                              artistURL={this.props.match.params.artistURL}
                              otherTracks={this.state.featuredTracks.map((track) => track.trackId)}
                            ></TrackListItem>
                          ))}
                        </div>
                      </div>

                      {/* {# Albums #} */}
                      <div className="artist-view-albums">
                        <div className="scroll-offset-parent">
                          <div id={"albumsSection"} className="scroll-offset-el" />
                        </div>
                        {/* element for scroll offset */}

                        <div className="tracks-heading mb-20 md:mb-40">
                          <a href="#" className="tracks-heading-title">
                            Albums
                          </a>
                        </div>

                        {/* {# All Albums #} */}
                        <div className="album-grid mb-30 lg:mb-40">
                          <div className="grid-row">
                            {this.state.albums.map((album) => (
                              <AlbumCard
                                key={"albumArt-" + album.id}
                                albumTitle={album.albumTitle}
                                artWork={album.artWork}
                                albumId={album.id}
                                playAlbum={this.playAlbum}
                              />
                            ))}
                          </div>
                        </div>

                        {/* {/* {# Album Playlist #} */}
                        {this.state.albums.map((album) => (
                          // {# Album Playlist Item #}
                          <>
                            {/* element for scroll offset */}

                            <div
                              key={"album-" + album.id}
                              className="album-playlist mb-50 lg:mb-80"
                            >
                              <div className="scroll-offset-parent">
                                <div
                                  id={"album-" + album.albumTitle.replace(/\s/g, "-")}
                                  className="scroll-offset-el"
                                />
                              </div>

                              {/* {# Album Header #} */}
                              <div className="album-header">
                                {/* {# Artwork #} */}
                                <div className="album-header-artwork">
                                  <img
                                    src={`https://${
                                      process.env.REACT_APP_S3_PHOTO_BUCKET
                                    }.s3.us-east-2.amazonaws.com/${
                                      process.env.REACT_APP_S3_SUB_BUCKET
                                        ? process.env.REACT_APP_S3_SUB_BUCKET + "/"
                                        : ""
                                    }${this.resolveAlbumPhoto(album.artWork)}`}
                                    alt="albumArt"
                                  />
                                </div>

                                {/* {# Details #} */}
                                <div className="album-header-details">
                                  {/* <div className="text-label album-header-release">{{ item.year }}</div> */}
                                  <h3 className="album-header-title">{album.albumTitle}</h3>
                                </div>

                                {/* {# Actions #} */}
                                <div className="album-header-actions">
                                  <div className="btn-group" x-data="{ liked: false }">
                                    {/* {# Like Button #} */}
                                    {albums?.filter((fav) => fav.albumId === album.id).length ===
                                    1 ? (
                                      <button
                                        onClick={() => unlikeAlbum(album.id)}
                                        className="circle-icon circle-icon-sm js-tooltip btn-jelly is-liked hover:text-primary"
                                        title="Like Album"
                                      >
                                        <span className="icon-like-fill"></span>
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() => likeAlbum(album.id)}
                                        className="circle-icon circle-icon-sm js-tooltip btn-jelly"
                                        title="Like Album"
                                      >
                                        <span className="icon-like"></span>
                                      </button>
                                    )}

                                    {/* <button
                                        className="track-list-fav btn-jelly js-tooltip is-liked hover:text-primary"
                                        title="Like Track"
                                        type="button">
                                        <span className="icon-like-fill"></span>
                                    </button>   :
                                    <button
                                        className="track-list-fav btn-jelly js-tooltip"
                                        title="Like Track"
                                        type="button">
                                        <span className="icon-like"></span>
                                    </button>

                                } */}
                                    {/* {# More #} */}
                                    <button className="circle-icon circle-icon-sm">
                                      <span className="icon-dots-vert"></span>
                                    </button>
                                  </div>
                                </div>
                              </div>

                              {/* {# Current Album Tracks #} */}
                              <div className="track-list-container">
                                {this.state.tracks
                                  .filter((track) => track.albumId === album.id)
                                  .map((track, index) => (
                                    <TrackListItem
                                      {...this.context.loadedTracks[track.trackId]}
                                      key={"albumListTracks-" + track.trackId}
                                      listType={"album"}
                                      idKey={`artist-${
                                        this.context.loadedTracks[track.trackId]?.artistId
                                      }-album-${album.id}-track-${
                                        track.trackId
                                      }-element-albumTrackItem-${album.id}`}
                                      index={index}
                                      likedTracks={this.state.likedTracks}
                                      likeTrack={this.likeTrack}
                                      unlikeTrack={this.unlikeTrack}
                                      currentTime={this.state.currentTime}
                                      changeTrack={this.changeTrack}
                                      artistName={this.state.artist.artistName}
                                      artistURL={this.props.match.params.artistURL}
                                      otherTracks={[
                                        ...this.state.tracks
                                          .concat()
                                          .filter((track) => track.albumId === album.id)
                                          .map((track) => track.trackId),
                                      ]}
                                    ></TrackListItem>
                                  ))}
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                      {this.state.similarArtists.length > 0 && (
                        <div className="mb-30 md:mb-40">
                          {/* {# Heading Group ==> layouts/components/heading-groups/view-more.html #} */}
                          {/* {{ heading_group.arrow(
                            'Similar Artists',
                            'none'
                        ) }} */}
                          <div className="tracks-heading mb-20 md:mb-40">
                            <h3 className="tracks-heading-title" id={"similarArtists"}>
                              Similar Artists
                              <div className="circle-icon-border ml-15">
                                <span className="icon-chevron-right"></span>
                              </div>
                            </h3>
                          </div>
                          {/* {# Artist Cards #} */}
                          <div className="grid-row">
                            {this.state.similarArtists.map((artist) => (
                              <SimilarArtists
                                {...artist}
                                followArtist={followArtist}
                                unfollowArtist={unfollowArtist}
                                following={
                                  artists?.filter((fav) => fav.artistId === artist.artistId)
                                    .length === 1
                                }
                              />
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </>
            ) : (
              <div style={{ marginTop: "20%" }}>
                <CircularIndeterminant forceCenter />
              </div>
            )}
          </>
        )}
      </FavoritesConsumer>
    );
  }
}

Artist.contextType = PlayerContext;
export default Artist;

import React, { useContext, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory, useLocation } from "react-router";
import MicroModal from "react-micro-modal";
import Select, { components } from "react-select";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import Skeleton from "@material-ui/lab/Skeleton";
import { CartContext } from "../../../contexts/Cart";
import { ProjectsContext } from "../../../ProjectsContext";
import { SubscriptionContext } from "../../../contexts/Subscription";
import { UserContext } from "../../../contexts/User";
import api from "../../utils/api";
import AutoRenewalPolicy from "../../Links/autoRenewalPolicy";
import CancellationPolicy from "../../Links/cancellationPolicy";
import LicenseTerms from "../../Links/licenseTerms";
import BackdropOverlayFull from "../../LoadingOverlay/backdropFull";
import CheckoutAddToProjectModal from "../../modal/checkoutAddToProjectModal";
import currencyFormatter from "../../../utils/currencyFormatter";
import customStyles from "../../FormElements/customStyles";
import CustomValueContainer from "../../FormElements/customValueContainer";
import { Error } from "../../Toast";
import LoadingContainer from "../../Loading/LoadingContainer";
import SubscriptionItem from "../../Checkout/SubscriptionItem";
import TermsAndConditions from "../../Links/termsAndConditions";
import purchasesAPI from "../../../api/purchases";
import usersAPI from "../../../api/users";
import { handleAPIError } from "../../../utils/handleAPIError";
import StripeElementsWrapper from "./StripeElements/StripeElementsWrapper";
import stripeAPI from "../../../api/stripe";
import LicenseItem from "../../Checkout/LicenseItem";
import { Tooltip } from "react-tippy";

const CheckoutForm = (props) => {
  const {
    checkoutType,
    invoicePrices,
    currentCartInfo,
    paymentIntent,
    selectedPrice,
  } = props;

  console.log("checkoutForm selectedPrice", selectedPrice);

  const currentPlanInterval = selectedPrice?.recurring.interval + 'ly' || 'monthly';

  const history = useHistory();

  const {
    applyPromo,
    associateProjectToCartItem,
    clearCart,
    licenses,
    licensePromo,
    subscriptionPromo,
    removeFromCart,
    removePromo,
  } = useContext(CartContext);

  const { allProjects } = useContext(ProjectsContext);

  const {
    subscriptionType
  } = useContext(SubscriptionContext);

  const promo = (checkoutType === 'license') ? licensePromo : subscriptionPromo;
  console.log('promo applied', promo);

  const { user } = useContext(UserContext);

  // const [stripeOptions, setStripeOptions] = useState({});
  const stripe = useStripe();
  const elements = useElements();

  const [isLoaded, setIsLoaded] = useState(false);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [isNewProjectModalOpen, setIsNewProjectModalOpen] = useState(false);
  const [currentTrackId, setCurrentTrackId] = useState(null);
  const [currentTrackTitle, setCurrentTrackTitle] = useState(null);
  const [currentItemIndex, setCurrentItemIndex] = useState(null);
  const [paymentMethodType, setPaymentMethodType] = useState("card");
  const [isUsingNewCard, setIsUsingNewCard] = useState(false);
  const [paymentCardOptions, setPaymentCardOptions] = useState([]);
  const [cardError, setCardError] = useState(null);
  const [selectedCard, setSelectedCard] = useState(null);
  const [canSubmit, setCanSubmit] = useState(false);
  const [promoCode, setPromoCode] = useState(promo?.code);
  const [promoError, setPromoError] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [isOpenPOVAT, setIsOpenPOVAT] = useState(false);
  const [isInvalidStripe, setIsInvalidStripe] = useState(false);
  const [isResubmit, setIsResubmit] = useState(false);

  // const [originalPrice, setOriginalPrice] = useState(null);


  console.log("invoicePrices", invoicePrices);

  const originalCartPrice = invoicePrices.reduce(
    (partialSum, item) => partialSum + item.originalPrice,
    0
  );
  console.log('originalCartPrice', originalCartPrice);
  // const defaultFinalCartPrice = invoicePrices.reduce(
  //   (partialSum, item) => partialSum + item.finalPrice,
  //   0
  // );

  const [finalCartPrice, setFinalCartPrice] = useState(originalCartPrice);

  const defaultDiscountAmount = originalCartPrice - finalCartPrice;
  const defaultDiscountRate = (defaultDiscountAmount / originalCartPrice);
  const [discountAmount, setDiscountAmount] = useState(defaultDiscountAmount);
  const [discountRate, setDiscountRate] = useState(defaultDiscountRate);

  const promoDiscountPercentage = (promo?.discount / 100) || 0;
  const defaultPromoDiscountAmount = promoDiscountPercentage * (originalCartPrice - defaultDiscountAmount);
  const [promoDiscountAmount, setPromoDiscountAmount] = useState(defaultPromoDiscountAmount);

  useEffect(() => {
    let updatedPromoDiscountAmount = 0;

    const updatedFinalCartPrice = invoicePrices.reduce(
      (partialSum, item) => partialSum + item.finalPrice,
      0
    );
    const updatedDiscountAmount = originalCartPrice - updatedFinalCartPrice;
    const updatedDiscountRate = (updatedDiscountAmount / originalCartPrice);
    setDiscountAmount(updatedDiscountAmount);
    setDiscountRate(updatedDiscountRate);
    if (promoDiscountPercentage > 0) {
      updatedPromoDiscountAmount = (promoDiscountPercentage * updatedFinalCartPrice.toFixed());
    }
    setPromoDiscountAmount(updatedPromoDiscountAmount);
    console.log('originalCartPrice', updatedFinalCartPrice);
    console.log('updatedDiscountAmount', updatedDiscountAmount);
    console.log('updatedPromoDiscountAmount', updatedPromoDiscountAmount);
    setFinalCartPrice(originalCartPrice.toFixed() - updatedDiscountAmount.toFixed() - updatedPromoDiscountAmount.toFixed());
  }, [invoicePrices]);

  useEffect(() => {
    if (user) {
      usersAPI.checkStripeId().then((res) => {
        if (res.data.stripeValid) {
          usersAPI.getCustomerPaymentMethods().then(
            (res) => {
              usersAPI.getLastUsedPaymentMethod().then((resp) => {
                const lastUsed =
                  resp?.data?.lastUsedPaymentMethod?.paymentMethod;
                let paymentCardOptions = [];
                let defaultCard = res.data.default[0] || undefined;
                let otherCards = res.data.others;

                if (defaultCard) {
                  paymentCardOptions.push({
                    label:
                      (defaultCard?.metadata?.nickName
                        ? defaultCard?.metadata?.nickName + " - "
                        : "") +
                      defaultCard?.card?.brand +
                      " - " +
                      defaultCard?.card?.last4,
                    value: defaultCard?.id,
                  });
                }
                otherCards.forEach((card) => {
                  let cardObject = {
                    label:
                      (card.metadata?.nickName
                        ? card.metadata?.nickName + " - "
                        : "") +
                      card.card.brand +
                      " - " +
                      card.card.last4,
                    value: card.id,
                  };
                  paymentCardOptions.push(cardObject);
                });
                console.log("paymentCardOptions", paymentCardOptions);
                if (paymentCardOptions?.length < 1) {
                  setIsUsingNewCard(true);
                }
                else {
                  setSelectedCard(paymentCardOptions[0]);
                  setPaymentCardOptions(paymentCardOptions);
                }
                setIsLoaded(true);
              });
            },
            (error) => {}
          );
        } else {
          setIsInvalidStripe(true);
          setIsLoaded(true);
        }
      });
    }
  }, []);

  const forceRedirect = () => {
    Error({
      message:
        "You have an existing subscription. You may upgrade on this page.",
    });
    history.push("/dashboard/account/subscriptions");
  };

  const handleCardSelect = (selectedCardValue) => {
    setSelectedCard(selectedCardValue);
  };

  const handleNewCardOpen = () => {
    setIsUsingNewCard(true);
    setSelectedCard(false);
    setCanSubmit(false);
  };

  const handleNewCardClose = () => {
    setIsUsingNewCard(false);
    setCanSubmit(false);
    setSelectedCard(paymentCardOptions[0]);
    // setIsStripeLoaded(false);
    // TODO reset payment elements form fields
  };

  const handleNewProjectModalClose = () => {
    setIsNewProjectModalOpen(false);
  };

  const handleNewProjectModalOpen = (
    currentTrackId,
    currentTrackTitle,
    index
  ) => {
    setIsNewProjectModalOpen(true);
    setCurrentTrackId(currentTrackId);
    setCurrentTrackTitle(currentTrackTitle);
    setCurrentItemIndex(index);
  };

  const reapplyPromo = () => {
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.length ? decodedCookie.split(";") : [];
    let promoCookie = ca.filter(
      (cookieItem) => cookieItem.indexOf(`${checkoutType}Promo`) !== -1
    );
    if (promo) {
      setPromoCode(promo?.code);
    } else if (promoCookie?.length > 0) {
      let parsedPromoCookie = promoCookie[0].substring(
        promoCookie[0].indexOf("=") + 1
      );
      setPromoCode(parsedPromoCookie);
    }
  };

  // const submitPayment = () => {};

  // const resubmitPayment = () => {};

  const { register, handleSubmit, watch } = useForm({
    mode: "onChange",
    reValidateMode: "onTouched",
    defaultValues: {
      promoCode
    },
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const watchFields = watch();

  useEffect(() => {
    console.log("watchFields", watchFields);
    const updatedCanSubmit = checkIfCanSubmit();
    setCanSubmit(updatedCanSubmit);
  }, [watchFields]);

  const checkIfCanSubmit = () => {
    let canSubmit = false;

    let paymentComplete = false;

    if (paymentMethodType === "card") {
      if (selectedCard?.value || isUsingNewCard) {
        paymentComplete = true;
      }
    } else if (paymentMethodType === "coveredBySubscription") {
      paymentComplete = true;
    } else {
      paymentComplete = false;
    }

    console.log('paymentComplete?', paymentComplete);
    canSubmit = paymentComplete;

    if (checkoutType === "license") {
      let cartItemsHaveProjects = true;
      // console.log('checking currentCartInfo', currentCartInfo )
      currentCartInfo?.forEach((license) => {
        if (!license?.project?.id) {
          cartItemsHaveProjects = false;
        }
      });

      console.log('cartItemsHaveProjects?', cartItemsHaveProjects);
      canSubmit = canSubmit && cartItemsHaveProjects;

      const agreedToTerms = (
        watchFields.agreeTermsOfUse === "true"
      );

      console.log('agreedToTerms?', agreedToTerms);
      canSubmit = canSubmit && agreedToTerms;
    }

    if (checkoutType === "subscription") {
      canSubmit = (
        canSubmit &&
        watchFields.agreeTermsOfUse === "true"
      )
    }

    // console.log(
    //   'watchFields.agreeToSubscriptionTerms === "true"',
    //   watchFields.agreeToSubscriptionTerms === "true"
    // );

    // canSubmit = finalPrice !== null;

    console.log('canSubmit', canSubmit);
    return canSubmit;
  };

  const onSubmit = (data, e) => {
    if (canSubmit && elements) {
      console.log('elements on submit', elements);
      console.log("submitting");
      setIsPaymentProcessing(true);

      const { purchaseOrder, vatNumber } = data;

      const purchaseData = {
        finalPrice: finalCartPrice,
        invoicePrices,
        originalPrice: originalCartPrice,
        paymentIntent,
        paymentMethodType,
        promo,
        promoDiscountAmount,
        purchaseOrder,
        selectedCard,
        type: checkoutType,
        vatNumber,
      };

      const returnURL = window.location.href;
          if (!isUsingNewCard && selectedCard) {
            purchaseData.paymentMethod = selectedCard.value;

            // stripeAPI
            //   .updateSetupIntent({
            //     id: paymentIntent?.id,
            //     payment_method: selectedCard?.value
            //    })
            //   .then((res2) => {
            //     stripe
            //       .confirmSetup({
            //         //`Elements` instance that was used to create the Payment Element
            //         elements,
            //         confirmParams: {
            //           return_url: returnURL,
            //         },
            //       })
            //       .then((confirmCardResult) => {
            //         console.log("confirmCardResult", confirmCardResult);
            //         if (confirmCardResult?.paymentIntent?.status === "succeeded") {
            //           window.location.replace(returnURL);
            //         } else {
            //           Error({ message: "There was an error" });
            //           setIsPaymentProcessing(false);
            //         }
            //       })
            //       .catch((error) => {
            //         const { message } = error || {};
            //         Error({ message });
            //       });
            //   })
            //   .catch((error) => {
            //     handleAPIError(error);
            //     setIsPaymentProcessing(false);
            //   });
            // Save purchase data to our db
            purchasesAPI
              .create({ purchaseData })
                .then((res) => {
                  const { orderNumber } = res?.data?.purchase;
                  console.log("purchase res", res);
                  const completeURL =
                    window.location.origin + "/checkout/complete/" + orderNumber;
                  console.log("paymentIntent", paymentIntent);
                  if (res.data.success) {
                    removePromo(checkoutType);
                    window.location.replace(completeURL);
                  }
                  else {
                    Error({ message: "Could not complete purchase" })
                  }
                })
                .catch((error) => {
                  handleAPIError(error);
                  setIsPaymentProcessing(false);
                });
          }
          else if (elements) {
            stripe
              .confirmSetup({
                //`Elements` instance that was used to create the Payment Element
                elements,
                confirmParams: {
                  return_url: returnURL,
                },
                redirect: "if_required"
              })
              .then((res) => {
                console.log('confirmSetup res', res);
                if (res.error) {
                  const error = res.error;
                  throw error;
                }

                purchaseData.paymentMethod = res.setupIntent?.payment_method;
                // Save purchase data to our db
                purchasesAPI
                  .create({ purchaseData })
                  .then((res) => {
                    const { orderNumber } = res?.data?.purchase;
                    console.log("purchase res", res);
                    const completeURL =
                      window.location.origin + "/checkout/complete/" + orderNumber;
                    console.log("paymentIntent", paymentIntent);
                    if (res.data.success) {
                      removePromo(checkoutType);
                      window.location.replace(completeURL);
                    }
                    else {
                      Error({ message: "Could not complete purchase" })
                    }
                  })
                  .catch((error) => {
                    handleAPIError(error);
                    setIsPaymentProcessing(false);
                  });
              })
              .catch((error) => {
                handleAPIError(error);
                setIsPaymentProcessing(false);
              });
          }
          else {
            Error({ message: "Please select or enter a valid payment method" });
          }

      // stripe.confirmPayment({
      //   elements,
      //   confirmParams: {
      //     amount: finalPrice,
      //     // Return URL where the customer should be redirected after the PaymentIntent is confirmed.
      //     return_url: returnURL
      //   },
      // })
      // .then(function(result) {
      //   if (result.error) {
      //     // Inform the customer that there was an error.
      //   }
      // });
    } else {
      Error({ message: "Please complete required fields" });
    }
  };

  const promoCodeValue = watch('promoCode');

  useEffect(() => {
    setPromoCode(promoCodeValue);
  }, [promoCodeValue])

  // useEffect(() => {
  //   if (promoCode) {
  //     submitPromo();
  //   }
  // }, [promoCode]);

  const submitPromo = () => {
    setPromoError(false);
    if (checkoutType === 'subscription') {
      api
        .getSubscriptionPromo(promoCode, selectedPrice?.product?.id)
        .then((res) => {
          if (res.data.success) {
            applyPromo({
              promo: res.data.coupon,
              checkoutType
            });
          } else {
            Error({ message: res.data.reason });
            setPromoError(res.data.reason);
            removePromo(checkoutType);
          }
        });
    }
    else if (checkoutType === 'license') {
      api
        .getPromo(promoCode)
        .then(res => {
            if(res.data.success) {
              applyPromo({
                promo: res.data.coupon,
                checkoutType
              });
              const { discount } = res.data.coupon;
              const discountPercentage = discount / 100;
              setPromoDiscountAmount(discountPercentage * (originalCartPrice - discountAmount));
            }
            else{
                Error({message: res.data.reason})
                setPromoError(res.data.reason)
            }
        })
    }
  };

  return (
    <LoadingContainer isLoaded={isLoaded}>
      {/* {# Checkout Form #} */}
      <>
        {/* {# Checkout Grid #} */}
        <div className="grid-row justify-between">
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="ds-form mb-30 grid-row justify-between"
          >
            {isPaymentProcessing && (
              <BackdropOverlayFull loading={isPaymentProcessing} size={40} />
            )}
            {/* {# Cart #} */}
            <div className="col w-full md:flex-1 md:w-1/2 xl:flex-initial">
              {(user?.plan && (checkoutType === "subscription")) ? (
                forceRedirect()
              ) : (
                (finalCartPrice > 0) ? (
                  <>
                    {/* {# Payment Method #} */}

                    {/* {# Payment Details #} */}
                      {paymentMethodType === "card" && (
                        <>
                        <div className="checkout-payment mb-40 lg:mb-60">
                          {/* {# Heading #}    */}
                          <div className="max-w-xl mb-30 lg:mb-40">
                            <h3 className="text-h3 mb-20">Credit or Debit Card</h3>
                          </div>

                          {/* {# Add / Select Card #} */}
                          {!isUsingNewCard && paymentCardOptions.length > 0 && (
                            <>
                              {/* {# Subheading #} */}
                              {paymentCardOptions.length > 0 && (
                                <>
                                  <h4 className="text-label mb-20">
                                    Add or Select a Card{" "}
                                    <span className="input-label-required">
                                      *
                                    </span>
                                  </h4>
                                  {cardError && (
                                    <div className="col w-full justify-center pb-10">
                                      <div className="error-message text-center mt-0">
                                        {cardError}
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}

                              <div className="grid-row-xs">
                                <div className="col flex-1">
                                  <div className="input-group-xs input-inline">
                                    <label
                                      className="input-label"
                                      htmlFor="paymentCard"
                                    >
                                      Payment Method
                                    </label>
                                    <Select
                                      name="selectedCard"
                                      styles={customStyles}
                                      className="select-container"
                                      classNamePrefix="select"
                                      placeholder="Payment Method"
                                      options={paymentCardOptions}
                                      id="paymentCard"
                                      onChange={handleCardSelect}
                                      value={selectedCard}
                                      components={{
                                        ValueContainer: CustomValueContainer,
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col flex-none">
                                  <button
                                    className="circle-icon-border circle-icon-lg text-icon-md"
                                    type="button"
                                    onClick={handleNewCardOpen}
                                  >
                                    <span className="icon-plus"></span>
                                  </button>
                                </div>
                              </div>
                            </>
                          )}
                          {/* {# Show / Hide New Card Form #} :className="{ 'block' : new_card , 'hidden' : !new_card }" */}

                          {(isUsingNewCard || paymentCardOptions.length < 1) && (
                            <>
                              {/* <Elements
                                stripe={stripePromise}
                                options={stripeOptions}
                              > */}
                              {/* {# Heading #} */}
                              <div className="flex items-start justify-between">
                                <h4 className="text-label mb-20">
                                  Enter Payment Details
                                  <span className="input-label-required">*</span>
                                </h4>
                                {paymentCardOptions.length > 0 && (
                                  <button
                                    onClick={handleNewCardClose}
                                    type="button"
                                    className="text-link-gray text-label-sm"
                                  >
                                    Cancel
                                  </button>
                                )}
                              </div>

                              {/* // {# Fields #} */}
                              <div className="grid-row-xs">
                                {cardError && (
                                  <div className="col w-full justify-center pb-10">
                                    <div className="error-message text-center mt-0">
                                      {cardError}
                                    </div>
                                  </div>
                                )}

                                {/* {# Name #} */}
                                <div className="col w-full">
                                  <PaymentElement />
                                </div>
                              </div>
                              {/* </Elements> */}
                            </>
                          )}

                          {/* {# End Credit Card #} */}
                        </div>
                      </>
                    )}
                  </>
                ) : (
                  (checkoutType === 'license') ? (
                    <div className="max-w-xl mb-30 lg:mb-40">
                      <h3 className="text-h3 mb-20">Covered Under Subscription Plan</h3>
                      <p>No payment necessary.  This license is covered under your unlimited subscription plan. Please continue checkout to generate your Licenses.</p>
                        </div>
                  ) : (
                    <p>There was an error setting up payment.</p>
                  )
                )
              )}

              {/* {# End Payment #} */}
            </div>
            <div className="col w-full md:flex-none md:w-5/12">
              {/* {# Items in Cart #} */}
              <div className="mb-40 lg:mb-20">
                {checkoutType === "license" && (
                  <>
                    <h3 className="text-h3 mb-10">Tracks in Cart</h3>
                    {currentCartInfo.map((licenseItem, index) => {
                      let license = licenseItem.license;

                      return (
                        <LicenseItem
                          allProjects={allProjects}
                          associateProjectToCartItem={associateProjectToCartItem}
                          track={licenseItem.track}
                          license={license}
                          index={index}
                          removeFromCart={removeFromCart}
                          handleNewProjectModalOpen={handleNewProjectModalOpen}
                          project={licenseItem.project}
                        />
                      );
                    })}
                  </>
                )}
                {checkoutType === "subscription" && (
                  <>
                    <h3 className="text-h3 mb-10">Subscription Checkout</h3>
                    <SubscriptionItem
                      reapplyPromo={reapplyPromo}
                      selectedPrice={selectedPrice}
                    />
                  </>
                )}
              </div>
              {subscriptionType === 'personal' && (
                <div className="col w-full md:flex-none mb-40">
                  <p className="text-white">
                    Need unlimited tracks for Commercial use?
                  </p>
                  <a href={`/checkout/subscription/commercial/${currentPlanInterval}`} className="btn btn-white btn-sm clear">
                    <span className="icon-arrow-right mr-5"></span>
                    Switch to a Commercial Plan
                  </a>
                </div>
              )}
              {user ? (
                <>
                  {user?.plan ? (
                    <></>
                  ) : (
                    <>
                      {(promo?.discount > 0) ? (
                        <div className="flex items-center border-b border-gray-200 pb-20">
                          <div className="flex-1 pr-20">
                            <div className="input-group input-inline mb-0 input-endcap">
                              <input
                                value={promo?.code}
                                disabled
                                name="promoCode"
                                id="promoCode"
                                className="form-input"
                                placeholder="Promo Code"
                              />
                              <label className="input-label" htmlFor="promoCode">
                                Promo Code
                              </label>
                              <div
                                className="input-endcap-text"
                                style={{ fontSize: ".6rem" }}
                              >
                                {promo?.discount + "% OFF"}
                              </div>
                            </div>
                          </div>
                          <div className="flex-none">
                            <button
                              onClick={() => removePromo(checkoutType)}
                              type="button"
                              className="btn btn-primary form-btn"
                            >
                              Remove
                            </button>
                          </div>
                        </div>
                      ) : (
                        <div className="border-b border-gray-200 pb-20">
                          <div className="flex items-center">
                            <div className="flex-1 pr-20">
                              <div className="input-group input-inline mb-0 ">
                                <input
                                  ref={register}
                                  name="promoCode"
                                  // onChange={this.handleInputChange}
                                  onKeyDown={(e) => {
                                    if (e.keyCode === 13) {
                                      e.preventDefault();
                                      submitPromo();
                                    }
                                  }}
                                  id="promoCode"
                                  className="form-input"
                                  placeholder="Promo Code"
                                />
                                <label className="input-label" htmlFor="promoCode">
                                  Promo Code
                                </label>
                              </div>
                            </div>
                            <div className="flex-none">
                              <button
                                onClick={submitPromo}
                                type="button"
                                className="btn btn-primary form-btn"
                              >
                                Apply
                              </button>
                            </div>
                          </div>
                          {promoError && (
                            <div className="error-message block">
                              <p className="mb-0">{promoError}</p>
                            </div>
                          )}
                        </div>
                      )}


                      {/* {# Sub Total #} */}
                      {((discountAmount > 0) || (promoDiscountAmount > 0)) && (
                        <>
                          <div className="flex justify-between items-center border-b border-gray-200 py-20">
                            <div className="text-label-sm">
                              Sub Total
                            </div>
                            <div className="text-sm text-white">
                              {currencyFormatter(originalCartPrice / 100)}
                            </div>
                          </div>
                          {discountAmount > 0 && (
                            <div className="flex justify-between items-start py-20">
                              <div className="text-label-sm">
                                Subscription Discount
                              </div>
                              <div className="text-sm text-white">
                                -{currencyFormatter(discountAmount / 100)}
                              </div>
                            </div>
                          )}
                        </>

                      )}
                      {(promo && (promoDiscountAmount > 0)) && (
                        <div className="flex justify-between items-start py-20">
                            <div className="text-label-sm">{promo.name} ({promoDiscountPercentage * 100}% off)</div>
                          <div className="text-sm text-white">
                            -
                            {currencyFormatter(
                              (promoDiscountAmount +
                                0.00000001) /
                                100
                            )}
                          </div>
                        </div>
                      )}
                      {/* {# Total #} */}
                      <div className="flex justify-between items-start py-20">
                        <div className="text-label-sm">Total Due Today</div>

                          <div className="text-h4 text-white">
                            {console.log('finalCartPrice', finalCartPrice)}
                          {(finalCartPrice !== null) ? (

                            currencyFormatter(finalCartPrice / 100)
                          ) : (
                            <Skeleton
                              component="span"
                              style={{
                                backgroundColor: "rgb(210 205 205 / 42%)",
                              }}
                              animation="pulse"
                            >
                              <span>$00.00</span>
                            </Skeleton>
                          )}
                        </div>
                      </div>

                      {/* {# Terms #} */}
                      <div className="mb-20">
                        <label className="checkbox-option items-start text-sm text-gray-400 py-5">
                          <input
                            ref={register}
                            type="checkbox"
                            className="form-checkbox"
                            name="agreeTermsOfUse"
                            value={true}
                            // checked={this.state.doAgreeToSubTerms}
                          />
                          <span>
                            I agree to the{" "}
                            <TermsAndConditions underline>
                              Terms of Use
                            </TermsAndConditions>
                              {checkoutType === "license" ? (
                                <> and <LicenseTerms underline />.</>
                              ) : null}
                              {checkoutType === "subscription" ? (
                                <> and have read and acknowledge that subscription plans automatically renew unless cancelled.{' '}
                                  <Tooltip
                                    arrow={false}
                                    position={'top'}
                                    html={(<div style={{textAlign: "left", padding: "8px"}}>Cancel anytime. Your subscription stays active until the current term ends. You won't lose out on any time you've already paid for.</div>)}
                                    allowHTML={true}
                                  >
                                    <span className="icon-help"></span>
                                  </Tooltip>
                                </>
                              ) : null}
                            </span>
                        </label>
                      </div>

                      <div className="mb-40 lg:mb-60">
                        {isResubmit ? (
                          <button
                            disabled={!canSubmit}
                            className="btn btn-primary w-full form-btn resubmit"
                            type="submit"
                          >
                            Resubmit Payment
                          </button>
                        ) : (
                          <button
                            disabled={!canSubmit}
                            className="btn btn-primary w-full form-btn submit"
                            type="submit"
                          >
                            {checkoutType === "subscription"
                              ? "Purchase Subscription"
                              : "Purchase Licenses"}
                          </button>
                        )}
                        {/* )} */}
                      </div>
                      {/* {# Purchase Details #} */}

                      {/* {# Promo Code #} */}
                    </>
                  )}
                </>
              ) : (
                <>
                  {/* {# Sub Total #} */}
                  {promo && (
                    <>
                      <div className="flex justify-between items-center border-b border-gray-200 py-20">
                        <div className="text-label-sm">Sub Total</div>
                        <div className="text-sm text-white">
                          {currencyFormatter(
                            selectedPrice?.originalCartPrice?.toFixed(2)
                          )}
                        </div>
                      </div>

                      <div className="flex justify-between items-start py-20">
                          <div className="text-label-sm">{promo.name} ({promoDiscountPercentage}% off)</div>
                        <div className="text-sm text-white ws-nw">
                            -
                          {console.log('discount', promo?.discount)}
                          {currencyFormatter(
                            (
                              ((promo?.discount / 100) *
                                originalCartPrice) /
                              100
                            )?.toFixed(2)
                          )}
                        </div>
                      </div>
                      {/* {# Total #} */}
                      <div className="flex justify-between items-start py-20">
                        <div className="text-label-sm">
                          Total
                          {selectedPrice?.term === "month" && " Due Today"}
                        </div>
                        <div className="text-h4 text-white">
                          {currencyFormatter(
                            selectedPrice?.finalCartPrice?.toFixed(2)
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </form>
        </div>

        <MicroModal
          open={isNewProjectModalOpen}
          closeOnEscapePress={true}
          closeOnOverlayClick={true}
          closeOnAnimationEnd={true}
          modalOverlayClassName="modal-overlay"
          modalClassName="modal micromodal-slide is-open"
          modalOverlayStyles={null}
          handleClose={handleNewProjectModalClose}
          children={(handleClose) => (
            <CheckoutAddToProjectModal
              associateProjectToCartItem={associateProjectToCartItem}
              handleClose={handleClose}
              trackId={currentTrackId}
              trackTitle={currentTrackTitle}
              currentItemIndex={currentItemIndex}
            />
          )}
          containerStyles={{
            background: "#131313",
            padding: "asdf",
            maxWidth: "40.625rem !important",
            maxHeight: "100vh",
            borderRadius: "asdf",
            overflowY: "auto",
            boxSizing: "border-box",
          }}
        />
      </>
    </LoadingContainer>
  );
};

export default function StripeCheckoutForm(props) {
  console.log("in StripeCheckoutForm");

  const { checkoutType, currentCartInfo, selectedPrice } = props;

  const { clearCart } = useContext(CartContext);

  const [paymentIntent, setPaymentIntent] = useState(null);

  let invoicePrices = [];
  let cartError = false;
  // console.log("invoicePrices selectedPrice", selectedPrice);
  // console.log('currentCartInfo', JSON.stringify(currentCartInfo));

  if (checkoutType === "license" && currentCartInfo?.length > 0) {
    currentCartInfo.forEach((item) => {
      // console.log('currentCartInfo licenseItem', item);
      const { license } = item || {};
      const { product } = license || {};
      const { priceId } = product || {};
      // console.log('currentCartInfo licenseItem license', license);
      // console.log('currentCartInfo licenseItem product', product);
      // console.log('currentCartInfo licenseItem priceId', priceId);
      if (!priceId) {
        cartError = true;
        return;
      }
      const {
        finalPrice,
        originalPrice
      } = license || {};
      invoicePrices.push({
        id: item.license?.product?.priceId,
        finalPrice,
        originalPrice
      });
    });
  }
  else if (checkoutType === "subscription" && selectedPrice) {
    selectedPrice.finalPrice = selectedPrice.unit_amount;
    selectedPrice.originalPrice = selectedPrice.unit_amount;
    invoicePrices = [selectedPrice];
  }
  console.log("invoicePrices", invoicePrices);

  const setupPaymentIntent = () => {
    if (cartError) {
      clearCart();
      Error({
        message:
          "There was an error with your cart. Please add your items again.",
      });
      return;
    }
    // console.log('invoicePrices for paymentIntent', invoicePrices);
    stripeAPI
      .createSetupIntent()
      .then((res) => {
        console.log("setupIntent response", res);
        const paymentIntent = res?.data;
        setPaymentIntent(paymentIntent);
      });
  }

  useEffect(() => {
    // if (!(invoicePrices.length > 0)) {
    //   return;
    // }

    setupPaymentIntent();
  }, []);

  return (
    <LoadingContainer isLoaded={paymentIntent?.clientSecret}>
      <StripeElementsWrapper paymentIntent={paymentIntent}>
        <CheckoutForm
          checkoutType={checkoutType}
          invoicePrices={invoicePrices}
          currentCartInfo={currentCartInfo}
          paymentIntent={paymentIntent}
          selectedPrice={selectedPrice}
        />
      </StripeElementsWrapper>
    </LoadingContainer>
  );
}

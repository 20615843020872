import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const ContentIDSubnav = (props) => {
  const location = useLocation();
  const { pathname } = location || {};

  return (
    <div className="tabs">
      <ul className="tab-items">
        <li
          className={`tab-item ${
            pathname === "/dashboard/content-id/asset-reports" ? "active" : ""
          }`}
        >
          <NavLink to="/dashboard/content-id/asset-reports" className="tab-item-title">
            Asset Reports
          </NavLink>
        </li>
        <li
          className={`tab-item ${
            pathname === "/dashboard/content-id/royalty-payments" ? "active" : ""
          }`}
        >
          <NavLink to="/dashboard/content-id/royalty-payments" className="tab-item-title">
            Royalty Payments
          </NavLink>
        </li>
        <li className={`tab-item ${pathname === "/dashboard/content-id/payouts" ? "active" : ""}`}>
          <NavLink to="/dashboard/content-id/payouts" className="tab-item-title">
            Payouts
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default ContentIDSubnav;

import React, { Component } from "react";
// import tippy from "../../tooltips"

class ArtistTooltip extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {

            }

    render() {
        return (    
                <div className="dropdown-menu">
                    <div className="dropdown-group">
                            {this.props.following ? 
                                <a href="# "  onClick={()=>this.props.unfollowArtist(this.props.artistId, this.props.artistName)}><span className="icon-follow"></span>Unfollow Artist</a>:
                                <a href="# "  onClick={()=>this.props.followArtist(this.props.artistId, this.props.artistName)}><span className="icon-follow"></span>Follow Artist</a>
                            }
                            <a href="# "  onClick={()=>this.props.playFeatured()}><span className="icon-play"></span>Play featured</a>
                            <a href="# "  onClick={(e)=>this.props.viewAlbums(e)}><span className="icon-albums"></span>View Albums</a>
                            <a href="# "  onClick={()=>this.props.shareArtist()}><span className="icon-artists"></span>Share Artist</a>
                            {this.props.eventsCount.length > 0 &&
                                <a href="# "  onClick={(e)=>this.props.viewEvents(e)}><span className="icon-events"></span>View Events</a>
                            }
                    </div>
                </div>
        )
    }

}
export default ArtistTooltip
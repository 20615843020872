import React, { useContext, useEffect, useState } from "react";
import currencyFormatter from "../../../utils/currencyFormatter";
import { SubscriptionContext } from "../../../contexts/Subscription";
import QuoteContactForm from "../QuoteContactForm";
import SubscriptionLearnMore from "./SubscriptionLearnMore";
import SubscriptionSwitchText from "./SubscriptionSwitchText";
import BestDeal from "../../UI/BestDeal";

const LicensePrice = (props) => {
  const {
    activeTab,
    demoSubscription,
    handleClose,
    handleLicenseFormSubmit,
    isDemo,
    license,
    onSubmit,
    setFormFinalPrice,
    setFormOriginalPrice,
    tabItem,
    track,
  } = props;

  const { defaultSubscriptionPrices, getAlternatePrice } = useContext(SubscriptionContext);

  // console.log("defaultSubscriptionPrices", defaultSubscriptionPrices);

  const subscriptionUpsell = license?.product?.subscriptionUpsell;
  // console.log("subscriptionUpsell", subscriptionUpsell);

  const [cartData, setCartData] = useState(null);

  const [originalPriceFormatted, setOriginalPriceFormatted] = useState(null);
  const [finalPrice, setFinalPrice] = useState(null);
  const [finalPriceFormatted, setFinalPriceFormatted] = useState(null);
  const [finalPriceSubscriptionFormatted, setFinalPriceSubscriptionFormatted] = useState(null);
  const [subscriptionDiscountAmount, setSubscriptionDiscountAmount] = useState(null);
  const [subscriptionDiscountAmountFormatted, setSubscriptionDiscountFormatted] = useState(null);
  const [subscriptionDiscountRate, setSubscriptionDiscountRate] = useState(null);
  const [subscriptionDiscountRateFormatted, setSubscriptionDiscountRateFormatted] = useState(null);
  const [membershipDiscountAmount, setMembershipDiscountAmount] = useState(null);
  const [membershipDiscountAmountFormatted, setMembershipDiscountAmountFormatted] = useState(null);
  // const [membershipDiscountRate, setMembershipDiscountRate] = useState(null);
  const [membershipDiscountRateFormatted, setMembershipDiscountRateFormatted] = useState(null);
  const [priceError, setPriceError] = useState(false);
  const defaultCheckoutPath = "subscription";
  const [checkoutPath, setCheckoutPath] = useState(defaultCheckoutPath);
  const [isCustomLicense, setIsCustomLicense] = useState(false);
  // const isLicenseLoaded = licenseHasData(license);
  const [isLoaded, setIsLoaded] = useState(true);

  const defaultSubscriptionInterval = "month";
  const defaultSubscriptionAlternateInterval = "year";
  const [subscriptionInterval, setSubscriptionInterval] = useState(defaultSubscriptionInterval);
  const [subscriptionAlternateInterval, setSubscriptionAlternateInterval] = useState(defaultSubscriptionAlternateInterval);

  const defaultSubscriptionPrice =
    defaultSubscriptionPrices[subscriptionUpsell]?.[defaultSubscriptionInterval];

  // console.log("defaultSubscriptionPrice", defaultSubscriptionPrice);

  const [subscriptionPrice, setSubscriptionPrice] = useState(null);

  useEffect(() => {
    setSubscriptionPrice(defaultSubscriptionPrice);
  }, [defaultSubscriptionPrice]);

  // console.log("subscriptionPrice", subscriptionPrice);

  const defaultSubscriptionAlternatePrice = defaultSubscriptionPrices[subscriptionUpsell]?.[defaultSubscriptionAlternateInterval];
  const [subscriptionAlternatePrice, setSubscriptionAlternatePrice] = useState(null);
  const [subscriptionAlternatePriceFormatted, setSubscriptionAlternatePriceFormatted] = useState(null);

  useEffect(() => {
    setSubscriptionAlternatePrice(defaultSubscriptionAlternatePrice);
    setSubscriptionAlternatePriceFormatted(currencyFormatter(defaultSubscriptionAlternatePrice / 100))
  }, [defaultSubscriptionAlternatePrice]);

  const subscriptionPricePersonalMonthly = defaultSubscriptionPrices?.personal?.month;
  const subscriptionPricePersonalMonthlyFormatted = `${currencyFormatter(subscriptionPricePersonalMonthly / 100)}/month`;
  const subscriptionPricePersonalYearly = defaultSubscriptionPrices.personal?.year;
  const subscriptionPricePersonalYearlyFormatted = `${currencyFormatter(subscriptionPricePersonalYearly / 100)}/year`;
  const subscriptionPriceCommercialMonthly = defaultSubscriptionPrices?.commercial?.month;
  const subscriptionPriceCommercialMonthlyFormatted = `${currencyFormatter(subscriptionPriceCommercialMonthly / 100)}/month`;
  const subscriptionPriceCommercialYearly = defaultSubscriptionPrices.commercial.year;
  const subscriptionPriceCommercialYearlyFormatted = `${currencyFormatter(subscriptionPriceCommercialYearly / 100)}/year`;

  const toggleSubscriptionInterval = (event) => {
    event.preventDefault();
    if (subscriptionInterval === "year") {
      setSubscriptionPrice(defaultSubscriptionPrices[subscriptionUpsell]?.month);
      setSubscriptionInterval("month");
      setSubscriptionAlternatePrice(defaultSubscriptionPrices[subscriptionUpsell]?.year);
      setSubscriptionAlternatePriceFormatted(currencyFormatter(defaultSubscriptionPrices[subscriptionUpsell]?.year / 100))
      setSubscriptionAlternateInterval("year");
    }
    if (subscriptionInterval === "month") {
      setSubscriptionPrice(defaultSubscriptionPrices[subscriptionUpsell]?.year);
      setSubscriptionInterval("year");
      setSubscriptionAlternatePrice(defaultSubscriptionPrices[subscriptionUpsell]?.month);
      setSubscriptionAlternatePriceFormatted(currencyFormatter(defaultSubscriptionPrices[subscriptionUpsell]?.month / 100))
      setSubscriptionAlternateInterval("month");
    }
  };

  const setFormData = ({
    originalPrice,
    finalPrice,
    membershipDiscountAmount,
    membershipDiscountRate,
    subscriptionDiscountAmount,
    subscriptionDiscountRate,
  }) => {
    if (originalPrice === "custom") {
      setIsCustomLicense(true);
      setOriginalPriceFormatted("Custom");
      setFinalPriceFormatted("Contact Us");
      setIsLoaded(true);
    } else {
      setIsCustomLicense(false);

      const originalPriceDollars = originalPrice / 100;
      const originalPriceFormatted = currencyFormatter(originalPriceDollars);

      setOriginalPriceFormatted(originalPriceFormatted);

      if (finalPrice < 0) {
        setPriceError(true);
      }

      const finalPriceDollars = finalPrice / 100;
      const finalPriceFormatted = currencyFormatter(finalPriceDollars);

      const subscriptionDiscountDollars = subscriptionDiscountAmount / 100;
      const subscriptionDiscountFormatted = currencyFormatter(subscriptionDiscountDollars);

      const membershipDiscountDollars = membershipDiscountAmount / 100;
      const membershipDiscountFormatted = currencyFormatter(membershipDiscountDollars);

      const subscriptionDiscountRateFormatted = (subscriptionDiscountRate * 100).toFixed(0);
      const membershipDiscountRateFormatted = (membershipDiscountRate * 100).toFixed(0);

      setFinalPrice(finalPrice);
      setFinalPriceFormatted(finalPriceFormatted);
      setSubscriptionDiscountAmount(subscriptionDiscountAmount);
      setSubscriptionDiscountFormatted(subscriptionDiscountFormatted);
      setSubscriptionDiscountRate(subscriptionDiscountRate);
      setSubscriptionDiscountRateFormatted(subscriptionDiscountRateFormatted);
      setMembershipDiscountAmount(membershipDiscountAmount);
      setMembershipDiscountAmountFormatted(membershipDiscountFormatted);
      // setMembershipDiscountRate(membershipDiscountRate);
      setMembershipDiscountRateFormatted(membershipDiscountRateFormatted);

      if (setFormOriginalPrice) {
        setFormOriginalPrice(originalPrice);
      }
      if (setFormFinalPrice) {
        setFormFinalPrice(finalPrice);
      }
    }
  };

  useEffect(() => {
    if (!license) {
      return;
    }

    setCheckoutPath(defaultCheckoutPath);

    const {
      originalPrice,
      finalPrice,
      licenseMain,
      product,
      subscriptionDiscountAmount,
      subscriptionDiscountRate,
      membershipDiscountAmount,
      membershipDiscountRate,
    } = license;

    setCartData({
      originalPrice,
      finalPrice,
      licenseMain,
      product,
    });

    setFormData({
      originalPrice,
      finalPrice,
      subscriptionDiscountAmount,
      subscriptionDiscountRate,
      membershipDiscountAmount,
      membershipDiscountRate,
    });

    setIsLoaded(true);
  }, [license?.product?.id]);

  useEffect(() => {
    setCheckoutPath(defaultCheckoutPath);
  }, [activeTab]);

  const handleCheckoutPath = (event) => {
    // console.log('checkoutPath event', event);
    const { target } = event || {};
    const { value } = target || {};
    // console.log('checkoutPath target', target);
    // console.log('checkoutPath value', value);
    setCheckoutPath(value);
  }

  return (
    <>
      {isDemo && demoSubscription && (
        <div className="mb-20">
          <div className="text-label-sm" style={{ fontWeight: "100" }}>
            Your cost for this license with the{" "}
            <span style={{ fontWeight: "800" }}>
              {demoSubscription?.product?.metadata?.subTitle}
            </span>{" "}
            subscription would be:
          </div>
        </div>
      )}

      {(finalPrice > 0 || isCustomLicense) && finalPriceFormatted && (
        <>
          <div className="input-group-xs input-inline mb-30">
            <p className="text-white"> </p>
            <label
              htmlFor={`checkoutPath-subscription-${tabItem}`}
              className="radio-option radio-btn mb-5"
            >
              <input
                onChange={handleCheckoutPath}
                type="radio"
                checked={checkoutPath === "subscription"}
                className="form-radio"
                id={`checkoutPath-subscription-${tabItem}`}
                name={`checkoutPath-${tabItem}`}
                value={"subscription"}
              />
              <div className="flex-1 pr-15">
                <p className="text-white mb-2">
                  Unlimited Music{' '}
                  <BestDeal />
                </p>
                <div className="text-xs text-gray-500">
                  {subscriptionUpsell === "personal" && (
                    <>
                      {subscriptionPricePersonalMonthlyFormatted}{" or "}
                      {subscriptionPricePersonalYearlyFormatted}{" for "}
                      Unlimited Music for Personal Use
                    </>
                  )}
                  {subscriptionUpsell === "commercial" && (
                    <>
                      {subscriptionPriceCommercialMonthlyFormatted}{" or "}
                      {subscriptionPriceCommercialYearlyFormatted}{" for "}
                      Unlimited Music for Commercial Use
                    </>
                  )}
                  {subscriptionUpsell === "custom" && (
                    <>Contact Us for a Custom Subscription Plan</>
                  )}
                </div>
              </div>
            </label>

            <label
              htmlFor={`checkoutPath-license-${tabItem}`}
              className="radio-option radio-btn mb-5"
            >
              <input
                onChange={handleCheckoutPath}
                type="radio"
                checked={checkoutPath === "license"}
                className="form-radio"
                id={`checkoutPath-license-${tabItem}`}
                name={`checkoutPath-${tabItem}`}
                value={"license"}
              />
              <div className="flex-1 pr-15">
                <div className="text-white mb-2">Single Track</div>
                <div className="text-xs text-gray-500">
                  {finalPriceFormatted} for a Single Track License
                </div>
              </div>
            </label>
          </div>
        </>
      )}

      {(checkoutPath === "license" || finalPrice === 0) &&
        (isCustomLicense ? (
          <>
            <QuoteContactForm
              requestType={`License - ${license?.product?.label}`}
              priceId={license?.product?.priceId}
              quoteType="license"
              trackArtist={track?.artistName}
              trackTitle={track?.trackTitle}
            />
            <div className="mb-30"></div>
          </>
        ) : (
          <>
            {finalPrice !== null && (subscriptionDiscountAmount || membershipDiscountAmount) ? (
              <div className="pt-10 mb-20">
                {/* {# Summary Item #} */}
                <div className="flex items-center mb-3 text-gray-400 font-medium text-sm">
                  <span className="icon-projects mr-5"></span>
                  <span className="mr-5">License Cost:</span>
                  <span className="text-gray-500">{originalPriceFormatted}</span>
                </div>

                {subscriptionDiscountAmount > 0 && (
                  <div className="flex items-center mb-3 text-gray-400 font-medium text-sm">
                    <span className="icon-checklist mr-5"></span>
                    <span className="mr-5">Subscriber Discount:</span>
                    <span className="text-gray-500">
                      -{subscriptionDiscountAmountFormatted}
                      {` `}({subscriptionDiscountRateFormatted}%)
                    </span>
                  </div>
                )}

                {membershipDiscountAmount !== 0 && finalPrice && (
                  <div className="flex items-center mb-3 text-gray-400 font-medium text-sm">
                    <span className="icon-checklist mr-5"></span>
                    <span className="mr-5">
                      Subscriber Extra {membershipDiscountRateFormatted}% Discount:
                    </span>
                    <span className="text-gray-500">-{membershipDiscountAmountFormatted} </span>
                  </div>
                )}
              </div>
            ) : (
              <></>
            )}

            <div className="mb-30">
              <div className="text-label-sm">Total</div>
              <div className="text-h3 text-white font-display leading-tight" id="license-price">
                {priceError ? <>"Price Error"</> : <>{finalPriceFormatted}</>}
              </div>
            </div>

            <div className="mb-30">
              {isDemo ? (
                <div className="mb-40">
                  <button
                    onClick={() => handleClose()}
                    className="btn btn-primary form-btn w-full"
                    type="button"
                  >
                    Back
                  </button>
                </div>
              ) : (
                <button
                  disabled={priceError}
                  className="btn btn-primary form-btn w-full"
                  id="add-to-cart"
                  type="button"
                  onClick={handleLicenseFormSubmit(onSubmit({ cartData }))}
                >
                  <span className="icon-add-to-cart"></span> Checkout
                </button>
              )}
            </div>
          </>
        ))}

      {checkoutPath === "subscription" && (
        <>
          {subscriptionUpsell === "custom" ? (
            <QuoteContactForm
              priceId={license?.product?.priceId}
              requestType="Subscription - Upsell"
              quoteType="subscription"
              trackArtist={track?.artistName}
              trackTitle={track?.trackTitle}
            />
          ) : (
            <>
              <div className="mb-40">
                <div className="text-label-sm">Total</div>
                <div className="text-h3 text-white font-display leading-tight" id="license-price">
                  {priceError ? (
                    <>"Price Error"</>
                  ) : (
                    <>
                      {currencyFormatter(subscriptionPrice / 100)}/
                      {subscriptionInterval}
                    </>
                  )}
                </div>
                <button className="" onClick={toggleSubscriptionInterval}>
                  <span className="icon-history mr-5"></span>
                    <SubscriptionSwitchText
                      subscriptionAlternateInterval={subscriptionAlternateInterval}
                      subscriptionAlternatePriceFormatted={subscriptionAlternatePriceFormatted}
                      subscriptionType={subscriptionUpsell}
                    />
                </button>
              </div>
              <button
                disabled={priceError}
                className="btn btn-primary form-btn w-full"
                id="continue-to-subscription"
                onClick={handleLicenseFormSubmit(
                  onSubmit({
                    redirectPath: `/checkout/subscription/${subscriptionUpsell}/${subscriptionInterval}`,
                  })
                )}
                type="button"
              >
                <span className="icon-add-to-cart"></span>{" "}
                {subscriptionUpsell === "personal" && "Purchase Personal Plan"}
                {subscriptionUpsell === "commercial" && "Purchase Commercial Plan"}
              </button>
            </>
          )}
          <SubscriptionLearnMore
            handleClose={handleClose}
            subscriptionPriceAmount={subscriptionPrice}
            subscriptionType={subscriptionUpsell}
            trackId={track.trackId}
            trackPriceId={license?.product?.priceId}
            trackPriceAmount={finalPrice}
          >
            Learn more about {subscriptionUpsell} plans
          </SubscriptionLearnMore>
        </>
      )}
    </>
  );
};

export default LicensePrice;

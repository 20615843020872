import React from 'react'

const FilterDisabled = (props) => {

  const { 
    isMobile,
    option,
    type
  } = props;

  let addButtonClasses = "icon-plus";
  let excludeButtonClasses = "icon-ignore";
  const mobileButtonClasses = "circle-icon circle-icon-sm text-icon-sm ";

  if (isMobile) {
    addButtonClasses = mobileButtonClasses + addButtonClasses;
    excludeButtonClasses = mobileButtonClasses + excludeButtonClasses;
  }

  // TODO copy this component to make FilterDisabled
  return (
    <li
      className={``}
      key={"filter" + type + option.label}
    >
      <div
        className="filter-tag-label line-through py-1"
      >
        {option.label}
      </div>
      {/* <div className="filter-tag-actions">
        <button
          type="button"
          className={addButtonClasses}
        />
        <button
          type="button"
          className={excludeButtonClasses}
        />
      </div> */}
    </li>
  )
}

export default FilterDisabled
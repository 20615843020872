import React, { Component } from "react";
import { Tooltip } from "react-tippy";
import 'react-tippy/dist/tippy.css';
import { UserConsumer } from "../../contexts/User";
import api from "../utils/api";
// import tippy from "../../tooltips"
import CardOptionsToolTip from "./cardOptionsToolTip";
import { Error } from "../Toast";
class PaymentCardOptions extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {
                email: "",
                oldPassword: "",
                newPassword: "",
                newPasswordConfirm:"",
                loginMessage: "",
                showTippy: false
            }
    //updating our input fields as the user enters keys
    handleInputChange = event => {
    }

    render() {
        return (
            <UserConsumer>
                {({ user, login, logout})=>(
                    <div className="payment-card-controls">
                        {this.props.loginLink ?
                            <a
                                href={this.props.loginLink.url}
                                className="btn form-btn btn-stroke payment-card-btn"
                            >
                                Go to Stripe Dashboard
                            </a>
                        :
                        <>
                            <button
                                onClick={this.props.toggleEdit}
                                className="btn form-btn btn-stroke payment-card-btn"
                            >
                                Edit Details
                            </button>
                            {this.props.removable &&
                                <Tooltip
                                    arrow={false}
                                    open={this.state.showTippy}
                                    trigger={'click'}
                                    interactive={true}
                                    maxWidth={'180'}
                                    html={(
                                        <CardOptionsToolTip
                                            paymentId={this.props.id}
                                            primary={this.props.primary}
                                            removable={this.props.removable}
                                        />
                                    )}
                                    allowHTML={true}
                                    onUntrigger={()=>{this.setState({showTippy: !this.state.showTippy})}}
                                    onRequestClose={()=>{this.setState({showTippy: !this.state.showTippy})}}
                                >
                                    <button id={this.props.paymentId} className="tn form-btn btn-stroke payment-card-more icon-btn js-dropdown-toggle"
                                        // data-tippy-html={"#card_options"}
                                        data-tippy-placement="bottom-end"
                                        onClick={()=>{this.setState({showTippy: !this.state.showTippy})}}
                                    >
                                        <span className="icon-dots-vert"></span>
                                    </button>

                                </Tooltip>
                            }
                        </>
                        }

                    </div>
                         )}
            </UserConsumer>
        )
    }

}
export default PaymentCardOptions

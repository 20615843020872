import React, { Component } from "react";
import MicroModal from "react-micro-modal";
import MediaQuery from 'react-responsive';
import { HashLink } from 'react-router-hash-link'; // import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import Sticky from 'react-sticky-el';
import { Tooltip } from "react-tippy";
import 'react-tippy/dist/tippy.css';
import { toast } from "react-toastify";
import socketIOClient from "socket.io-client";
import resolveConfig from "tailwindcss/resolveConfig";
import { v4 as uuidv4 } from 'uuid';
import { UserConsumer } from "../../contexts/User";
import { confirm } from "../confirmModal/index";
import CharacterCount from "../FormElements/characterCount";
import customStyles from "../FormElements/customStyles";
import customStylesMulti from "../FormElements/customStylesMulti";
import KeywordsInput from '../FormElements/keywordsInput';
import StemsExport from "../Links/stemsExport";
import TechnicalRequirements from "../Links/technicalRequirements";
import TechnicalRequirementsLearnMore from "../Links/technicalRequirementsLearnMore";
import VersionsExport from "../Links/versionsExport";
// import socketIOClient from "socket.io-client";
import CircularDeterminantLabel from "../Loading/circularDeterminantLabel";
import CircularIndeterminant from "../Loading/circularIndeterminant";
import AlbumModal from "../modal/albumModal";
import tailwindConfig from "../../tailwind.config";
import { Error, Success, SuccessRedirect, Warning } from "../Toast/index";
import api from '../utils/api';
import TrackWaveform from "../Waveform/forAdminApproveFullTrack";
import Waveform from "../Waveform/forVersionsAndStems";
import NewTrackToolTip from "./newTrackToolTip";
import formatAlbumLabel from "../FormElements/TrackForm/formatAlbumLabel";
import versionNameOptions from "../FormElements/TrackForm/versionNameOptions"

const tw = resolveConfig(tailwindConfig);



// Styles for Single Select
const customStylesCity = {
    option: (provided, state) => {

      return {
        // ...provided,
        borderBottom: "1px solid hsla(0, 0%, 100%, .05)",
        backgroundColor: tw.theme.colors.gray[200],
        padding: tw.theme.spacing[15] + " " + tw.theme.spacing[20],
        transition: "color 0.3s",
        cursor: state.isDisabled ? "default" : "pointer",
        color:
          state.isFocused
            ? tw.theme.colors.white || state.isDisabled
            : tw.theme.colors.gray[400]
      };
    },
    control: (provided, state) => {
      return {
        ...provided,
        border: "none",
        backgroundColor: tw.theme.colors.gray[200],
        minHeight: tw.theme.spacing[60],
        borderRadius: "none",
        cursor: "pointer",
        boxShadow: 0
      };
    },
    menu: (provided, state) => {
      return {
        ...provided,
        marginTop: "0",
        marginBottom: "0",
        backgroundColor: tw.theme.colors.gray[200],
        position: "absolute",
        boxShadow: tw.theme.boxShadow.lg,
        borderRadius: "none"
      };
    },
    menuList: (provided, state) => {
      return {
        ...provided,
        scrollbarColor: "hsla(0, 0%, 100%, .2) transparent",
        scrollbarWidth: "thin",
        "::-webkit-scrollbar": {
          width: "0.375rem"
        },
        "::-webkit-scrollbar-track": {
          backgroundColor: "transparent"
        },
        "::-webkit-scrollbar-thumb": {
          backgroundColor: "hsla(0, 0%, 100%, .2)",
          borderRadius: tw.theme.borderRadius.full
        }
      };
    },
    input: (provided, state) => {
      //
      return {
        // ...provided,
        color: tw.theme.colors.white,
        lineHeight: tw.theme.lineHeight.snug
      };
    },
    placeholder: (provided, state) => {
      return {
        ...provided,
        color: tw.theme.colors.gray[400],
        margin: "0",
        display: "flex"
      };
    },
    singleValue: (provided, state) => {
      return {
        ...provided,
        color: "white",
        margin: "0",
        lineHeight: tw.theme.lineHeight.snug
      };
    },
    valueContainer: (provided, state) => {
      //
      return {
        ...provided,
        alignSelf: "stretch",
        padding: tw.theme.spacing[10] + " " + tw.theme.spacing[20],
        backgroundColor: "#1c1c1c"
      };
    },
    dropdownIndicator: (provided, state) => {
      //
      return {
        ...provided,
        transition: "all .2s ease",
        transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : null,
        color: tw.theme.colors.gray[500],
        cursor: "pointer",
        backgroundColor: "transparent",
        padding: "0 " + tw.theme.spacing[15],
        ":hover": { color: "white" }
      };
    },
    indicatorSeparator: (provided, state) => {
      return {
        ...provided,
        width: "0",
        height: "0"
      };
    },
    indicatorsContainer: (provided, state) => {
      //
      return {
        ...provided,
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      };
    }
  };

class ArtistResubmitTrack extends Component {
    state = {
        title: "",
        isAlbumModalOpen: false,
        album: "",
        myAlbums: [ ],
        file: "",
        funFact: "",
        genreOptions: [ ],
        emotionOptions: [ ],
        moodOptions: [ ],
        attributeOptions: [ ],
        instrumentOptions: [ ],
        cityOptions: [],
        genres: [ ],
        emotions: [ ],
        moods: [ ],
        attributes: [ ],
        instruments: [ ],
        cities: [],
        containsLyrics: "true",
        explicitLyrics: "false",
        trackBPM: "fast",
        versionsAvailable: "false",
        rejectionReasons: {},
        stemsAvailable: "false",
        additionalVersions: [],
        incompleteTaggedStems: [],
        completeTaggedStems: [],
        incompleteCustomStems: [],
        completeCustomStems: [],
        stems: [],
        additionalStemNames: [
            {label: "Lead Vocals", value: "Lead Vocals"},
            {label: "Vocals", value: "Vocals"},
            {label: "Background Vocals", value: "Background Vocals"},
            {label: "Choir", value: "Choir"},
            {label: "SFX", value: "SFX"}
        ],
    }
//updating our input fields as the user enters keys
handleInputChange = event => {
this.props.handleFormUnsaved(true)
let detailsComplete
const { name, value } = event.target;
this.setState({
    [name]: value,
}, ()=>{
    if(this.state.title.length > 0 && this.state.album){
        detailsComplete = true
    }
    else{
        detailsComplete = false
    }
    this.setState({detailsComplete: detailsComplete})
});
};
handleSelect = (event)=> {
this.props.handleFormUnsaved(true)

this.setState({album: event.target.value});
}
changeNowPlaying = (event, s3Key)=>{

    event.preventDefault()
    if(this.state.nowPlaying === s3Key){

        this.setState({nowPlaying: "stop"})
    }
    else{
        this.setState({nowPlaying: s3Key})
    }
}
unsetNowPlaying = ()=>{

    this.setState({nowPlaying: null})
}
reuploadTrack = (event) =>{

    const socket = socketIOClient();
    socket.on("progress" + this.props.match.params.trackId, data => {

      this.updateTrackProgress(data)
    });
    this.setState({
        [`trackFile`]: event.target.files[0], [`trackFileLoading`]: true, localname: event.target.files[0].name
    },()=>{
        const fileData = new FormData()
        fileData.append('file', this.state[`trackFile`])

        api.reuploadTrackSource(this.props.match.params.trackId, fileData)
            .then(response=>{

                if(response.data.success === true){
                   this.setState({[`trackFileLoading`]: false, wavURL: response.data.trackData.wavURL, localname: response.data.trackData.localname, trackQuality: "reuploaded" })
                }
            })
            .catch(error =>{
                if(error.response?.data === "File Too Large"){
                    Error({message:"File was rejected because it is over 250 mb."})
                }
                else if(error.response?.data === "File is longer than 12 minutes"){
                    Error({message:"File was rejected because it is longer than 12 minutes."})
                }
                else{
                    Error({message:"An error occured uploading your file."})
                }

                this.setState({trackFileLoading: false})
            })
        })
    }
updateTrackProgress = (progressData)=>{
    let parsedData = JSON.parse(progressData)

    let progress = Math.round(100 * parsedData.loaded/parsedData.total)
    this.setState({trackFileProgress: progress})
}
albumLoadingCB=(isLoading)=>{
    this.setState({albumLoading: isLoading})
}
versionsFileHandler = (label, event) =>{
    this.props.handleFormUnsaved(true)

    //
    let versionName
    let file = event.target.files[0]
    let index
    let notNamed = false
    this.setState(({
        additionalVersions
    })=>{
        index = additionalVersions.findIndex(vers=>vers.value.toUpperCase() === label.toUpperCase() || vers.label.toUpperCase() === label.toUpperCase())
        const socket = socketIOClient();
        socket.on("progressVersion" +this.props.match.params.trackId +additionalVersions[index]?.value.replace(/^\w/, c => c.toUpperCase()).replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}) , data => {

            this.updateVersionProgress(data, index)
        });
        versionName = additionalVersions[index]?.value.replace(/^\w/, c => c.toUpperCase())
        if(versionName === undefined || versionName === "Select Version Name"){
            Error({message: "Select version name before uploading"})
            notNamed = true
            return
        }
        else{
            return({
                additionalVersions:[
                    ...additionalVersions.slice(0, index),
                    {
                        ...additionalVersions[index],
                        localname: file.name,
                        error: null,
                        loading: true,
                        file: file
                    },
                    ...additionalVersions.slice(index+1)
                ]
            })
        }
    },()=>{
        if(notNamed){
            return
        }
        const fileData = new FormData()
        fileData.append('file', file)
        api.uploadTrackVersion(this.props.match.params.trackId, versionName.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}), fileData)
            .then(res=>{
                if(res.data.success){
                    this.setState(({additionalVersions})=>{
                        index = additionalVersions.findIndex(vers=>vers.value.toUpperCase() === label.toUpperCase() || vers.label.toUpperCase() === label.toUpperCase())

                        return({
                            additionalVersions:[
                                ...additionalVersions.slice(0, index),
                                {
                                    ...additionalVersions[index],
                                    wavURL: res.data.version.wavURL,
                                    localname: res.data.version.localname,
                                    id: res.data.version.id,
                                    loading: false,
                                },
                                ...additionalVersions.slice(index+1)
                            ]
                        })
                    })
                }
            })
            .catch(error=>{
                if(error.response?.data.indexOf("Mismatched Length") !==-1){
                    Error({message: `Versions must be the same exact length as selected.`})
                    this.setState(({additionalVersions})=>({
                        additionalVersions:[
                            ...additionalVersions.slice(0, index),
                            {
                                ...additionalVersions[index],
                                error: <div>Versions must be the same exact length as selected. <VersionsExport underline/>.</div>,
                                reason: <div>Versions must be the same exact length as selected. <VersionsExport underline/>.</div>,
                                loading: false
                            },
                            ...additionalVersions.slice(index+1)
                        ]
                    }))
                }  else if(error?.response?.data.indexOf("File Too Large") !==-1){
                    Error({message: `File rejected because it is over 250 mb`})
                    this.setState(({additionalVersions})=>({
                        additionalVersions:[
                            ...additionalVersions.slice(0, index),
                            {
                                ...additionalVersions[index],
                                error: "Track file is over 250 mb",
                                reason: "Track file is over 250 mb",
                                loading: false
                            },
                            ...additionalVersions.slice(index+1)
                        ]
                    }))
                }
                else if(error?.response?.data.indexOf("File is longer than 12 minutes") !==-1){
                    Error({message: `File rejected because it is longer than 12 minutes`})
                    this.setState(({additionalVersions})=>({
                        additionalVersions:[
                            ...additionalVersions.slice(0, index),
                            {
                                ...additionalVersions[index],
                                error: "Track file is longer than 12 minutes",
                                reason: "Track file is longer than 12 minutes",
                                loading: false
                            },
                            ...additionalVersions.slice(index+1)
                        ]
                    }))
                }
                else{
                    this.setState(({additionalVersions})=>({
                        additionalVersions:[
                            ...additionalVersions.slice(0, index),
                            {
                                ...additionalVersions[index],
                                error: error?.response?.statusText || "Error Uploading Version",
                                loading: false
                            },
                            ...additionalVersions.slice(index+1)
                        ]
                    }))
                }
            })
    })
    // let versionName = this.state.additionalVersions[index].value.replace(/^\w/, c => c.toUpperCase())
    // if(versionName === undefined){
    //     return
    // }
    // let additionalVersions = this.state.additionalVersions;
    // additionalVersions[index].localname = event.target.files[0].name
    // additionalVersions[index].error = null
    // additionalVersions[index].loading = true
    // additionalVersions[index].file = event.target.files[0]
    // this.setState({
    //     additionalVersions: additionalVersions
    // },()=>{
    //     const fileData = new FormData()
    //     fileData.append('file', additionalVersions[index].file)
    //
    //         api.uploadTrackVersion(this.props.match.params.trackId,versionName.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}), fileData)
    //             .then(res=>{
    //
    //                 if(res.data.success){
    //                     additionalVersions[index].wavURL= res.data.version.wavURL
    //                     additionalVersions[index].duration = res.data.version.duration
    //                     additionalVersions[index].peaks= res.data.version.peaks
    //                     additionalVersions[index].localname = res.data.version.localname
    //                     additionalVersions[index].id = res.data.version.id
    //                     additionalVersions[index].loading = false
    //                     this.setState({additionalVersions: additionalVersions})
    //                 }
    //             })
    //             .catch(error =>{
    //
    //                 additionalVersions[index].error=error.response.statusText
    //                 additionalVersions[index].loading = false
    //                 this.setState({additionalVersions: additionalVersions})
    //             })
    // })

    }
handleCheck = event => {
    this.props.handleFormUnsaved(true)
    const target = event.target
    const value = target.checked
    const name = target.name
    this.setState({
        [name]: value,
    });
    };
versionsReupload2 = (index, versionId, event) =>{


    let versionName = this.state.additionalVersions[index].value.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();})
    let additionalVersions = this.state.additionalVersions;
    additionalVersions[index].localname = event.target.files[0].name
    additionalVersions[index].error = null
    additionalVersions[index].loading = true
    additionalVersions[index].file = event.target.files[0]
    this.setState({
        additionalVersions: additionalVersions
    },()=>{
        const fileData = new FormData()
        fileData.append('file', additionalVersions[index].file)

            api.reuploadTrackVersion(this.props.match.params.trackId,versionName, versionId, fileData)
                .then(res=>{

                    if(res.data.success){
                            let additionalVersions = this.state.additionalVersions
                            // state[`versionFile${index}Loading`] = false
                            additionalVersions[index].wavURL= res.data.version.wavURL
                            additionalVersions[index].duration = res.data.version.duration
                            additionalVersions[index].peaks= res.data.version.peaks
                            additionalVersions[index].localname = res.data.version.localname
                            additionalVersions[index].loading = false
                            this.setState({additionalVersions: additionalVersions})
                    }
                })
                .catch(error =>{

                    additionalVersions[index].error=error.response.statusText
                    additionalVersions[index].loading = false
                    this.setState({additionalVersions: additionalVersions})
                })
    })
}
versionsReupload = (label, versionId, event) =>{
    this.props.handleFormUnsaved(true)

    //
    let versionName
    let file = event.target.files[0]
    let index
    let notNamed = false
    this.setState(({
        additionalVersions
    })=>{
        index = additionalVersions.findIndex(vers=>vers.label.toUpperCase() === label.toUpperCase())
        const socket = socketIOClient();
        socket.on("progressVersion" +this.props.match.params.trackId +additionalVersions[index]?.label.replace(/^\w/, c => c.toUpperCase()).replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}) , data => {

            this.updateVersionProgress(data, index)
        });
        versionName = additionalVersions[index]?.label.replace(/^\w/, c => c.toUpperCase())
        if(versionName === undefined || versionName === "Select Version Name"){
            Error({message: "Select version name before uploading"})
            notNamed = true
            return
        }
        else{
            return({
                additionalVersions:[
                    ...additionalVersions.slice(0, index),
                    {
                        ...additionalVersions[index],
                        localname: file.name,
                        error: null,
                        loading: true,
                        file: file
                    },
                    ...additionalVersions.slice(index+1)
                ]
            })
        }
    },()=>{
        if(notNamed){
            return
        }
        const fileData = new FormData()
        fileData.append('file', file)
        api.reuploadTrackVersion(this.props.match.params.trackId, versionName.replace(/\w\S*/g, function(txt){return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();}), versionId, fileData)
            .then(res=>{
                if(res.data.success){
                    this.setState(({additionalVersions})=>{
                        index = additionalVersions.findIndex(vers=>vers.label.toUpperCase() === label.toUpperCase())

                        return({
                            additionalVersions:[
                                ...additionalVersions.slice(0, index),
                                {
                                    ...additionalVersions[index],
                                    wavURL: res.data.version.wavURL,
                                    localname: res.data.version.localname,
                                    id: res.data.version.id,
                                    loading: false,
                                },
                                ...additionalVersions.slice(index+1)
                            ]
                        })
                    })
                }
            })
            .catch(error=>{
                if(error.response?.data.indexOf("Mismatched Length") !==-1){
                    this.setState(({additionalVersions})=>{
                        Error({message: `Versions must be the same exact length as selected.`})
                        return({
                        additionalVersions:[
                            ...additionalVersions.slice(0, index),
                            {
                                ...additionalVersions[index],
                                error: <div>Versions must be the same exact length as selected. <VersionsExport underline/>.</div>,
                                reason: <div>Versions must be the same exact length as selected. <VersionsExport underline/>.</div>,
                                loading: false
                            },
                            ...additionalVersions.slice(index+1)
                        ]
                    })})
                }
                else if(error?.response?.data.indexOf("File Too Large") !==-1){
                    Error({message: `File rejected because it is over 250 mb`})
                    this.setState(({additionalVersions})=>({
                        additionalVersions:[
                            ...additionalVersions.slice(0, index),
                            {
                                ...additionalVersions[index],
                                error: "Track file is over 250 mb",
                                reason: "Track file is over 250 mb",
                                loading: false
                            },
                            ...additionalVersions.slice(index+1)
                        ]
                    }))
                }
                else if(error?.response?.data.indexOf("File is longer than 12 minutes") !==-1){
                    Error({message: "File was rejected because it is longer than 12 minutes."})
                    this.setState(({additionalVersions})=>({
                        additionalVersions:[
                            ...additionalVersions.slice(0, index),
                            {
                                ...additionalVersions[index],
                                error: "Track file is longer than 12 minutes",
                                reason: "Track file is longer than 12 minutes",
                                loading: false
                            },
                            ...additionalVersions.slice(index+1)
                        ]
                    }))
                }
                else{
                    this.setState(({additionalVersions})=>({
                        additionalVersions:[
                            ...additionalVersions.slice(0, index),
                            {
                                ...additionalVersions[index],
                                error: error?.response?.statusText || "Error Uploading Version",
                                loading: false
                            },
                            ...additionalVersions.slice(index+1)
                        ]
                    }))
                }
            })
    })
}
updateVersionProgress = (progressData, index)=>{
    let parsedData = JSON.parse(progressData)

    let progress = Math.round(100 * parsedData.loaded/parsedData.total)
    this.setState(({additionalVersions})=>{
        if(additionalVersions[index]?.value){
            return({
                additionalVersions:[
                    ...additionalVersions.slice(0,index),
                    {
                        ...additionalVersions[index],
                        progress: progress
                    },
                    ...additionalVersions.slice(index+1)
                ]
            })
        }
    })
}
addNewStemDiv = (event)=>{
    this.props.handleFormUnsaved(true)
    event.preventDefault()
    // let incompleteCustomStems = this.state.incompleteCustomStems;
    this.setState(({incompleteCustomStems})=>({
        incompleteCustomStems: [
            ...incompleteCustomStems,
            {
                value: "",
                label: "",
                forKey: Math.floor(822394 * Math.random()),
                uuid: uuidv4()
            }
        ]
    }))
    // incompleteCustomStems.push({value: "",label:""})
    // this.setState({incompleteCustomStems: incompleteCustomStems})
}
stemsFileHandler = (label, event, tagOrCustom, uuid) =>{
    this.props.handleFormUnsaved(true)

        let file = event.target.files[0]
        if(tagOrCustom === "tag"){
            let stemName
            let index
            let notNamed = false
            this.setState(({
               incompleteTaggedStems
            })=>{
                index = incompleteTaggedStems.findIndex(incTS=>incTS.uuid === uuid)
                const socket = socketIOClient();
                socket.on("progressStem" +this.props.match.params.trackId +incompleteTaggedStems[index].uuid , data => {


                    this.updateTagStemProgress(data, uuid)
                });

                stemName = incompleteTaggedStems[index].label
                if(stemName?.length < 1){
                    Warning({message: "Please Name Stem First"})
                    notNamed = true
                    return
                }
            return({
                incompleteTaggedStems:[
                    ...incompleteTaggedStems.slice(0,index),
                    {
                        ...incompleteTaggedStems[index],
                        name: stemName,
                        localname: file.name,
                        error: null,
                        loading: true,
                        file: file,
                        progress: 0
                    },
                    ...incompleteTaggedStems.slice(index+1)
                ]
            })},()=>{
                if(notNamed){
                    return
                }
                const fileData = new FormData()
                fileData.append('file', file)

                api.uploadTrackStem(this.props.match.params.trackId, stemName, fileData, uuid)
                    .then(res=>{

                        if(res.data.success){
                            this.setState(({incompleteTaggedStems, completeTaggedStems})=>{
                            index = incompleteTaggedStems.findIndex(incTS=>incTS.uuid === uuid)

                            return({
                                completeTaggedStems:[
                                    ...completeTaggedStems,
                                    {
                                        wavURL: res.data.stem.wavURL,
                                        localname: res.data.stem.localname,
                                        id: res.data.stem.id,
                                        loading: false,
                                        name: res.data.stem.name,
                                        value : res.data.stem.name.toUpperCase(),
                                        label : res.data.stem.name.toUpperCase(),
                                        uuid: uuidv4()
                                    }
                                ],
                                incompleteTaggedStems: incompleteTaggedStems.filter(stem=> stem.uuid !== uuid)
                            })})
                        }
                    })
                    .catch(error =>{

                        if(error.response?.data.indexOf("Mismatched Length") !==-1){
                            Error({message: `Stems must be the same exact length as the Full Track.`})
                            this.setState(({incompleteTaggedStems})=>({
                                incompleteTaggedStems: [
                                    ...incompleteTaggedStems.slice(0, index),
                                    {
                                        ...incompleteTaggedStems[index],
                                        error: <div>Stems must be the same exact length as the Full Track. <StemsExport underline/>.</div>,
                                        loading: false,
                                        progress: 0
                                    },
                                    ...incompleteTaggedStems.slice(index+1)
                                ]
                            }))
                        }
                        else if(error.response?.data.indexOf("File Too Large") !==-1){
                            Error({message: `File rejected because it is over 250 mb`})
                            this.setState(({incompleteTaggedStems})=>({
                                incompleteTaggedStems: [
                                    ...incompleteTaggedStems.slice(0, index),
                                    {
                                        ...incompleteTaggedStems[index],
                                        error: "Track file is over 250 mb",
                                        loading: false,
                                        progress: 0
                                    },
                                    ...incompleteTaggedStems.slice(index+1)
                                ]
                            }))
                        }
                        else{

                            this.setState(({incompleteTaggedStems})=>({
                                incompleteTaggedStems: [
                                    ...incompleteTaggedStems.slice(0, index),
                                    {
                                        ...incompleteTaggedStems[index],
                                        error: error?.response?.statusText || "Error Uploading Stem",
                                        loading: false,
                                        progress: 0
                                    },
                                    ...incompleteTaggedStems.slice(index+1)
                                ]
                            }))
                        }

                    })
            })
        }
        else{
            let stemName
            let index
            let notNamed = false

            this.setState(({
                incompleteCustomStems
            })=>{
                index = incompleteCustomStems.findIndex(incTS=>incTS.uuid === uuid)
                const socket = socketIOClient();
                socket.on("progressStem" +this.props.match.params.trackId +incompleteCustomStems[index].uuid , data => {

                    this.updateCustomStemProgress(data, uuid)
                  });
                stemName = label


                if(stemName.length < 1){
                    notNamed = true
                    Warning({message: "Name stem before uploading"})
                    return
                }
            return({
                incompleteCustomStems:[
                    ...incompleteCustomStems.slice(0,index),
                    {
                        ...incompleteCustomStems[index],
                        name: stemName,
                        localname: file.name,
                        error: null,
                        loading: true,
                        file: file,
                        progress: 0
                    },
                    ...incompleteCustomStems.slice(index+1)
                ]
            })},()=>{
                if(notNamed){
                    return
                }
                const fileData = new FormData()
                fileData.append('file', file)

                api.uploadTrackStem(this.props.match.params.trackId, stemName, fileData, uuid)
                    .then(res=>{

                        if(res.data.success){
                            this.setState(({incompleteCustomStems, completeTaggedStems})=>({

                                completeTaggedStems:[
                                    ...completeTaggedStems,
                                    {
                                        wavURL: res.data.stem.wavURL,
                                        localname: res.data.stem.localname,
                                        id: res.data.stem.id,
                                        loading: false,
                                        name: res.data.stem.name,
                                        value : res.data.stem.name.toUpperCase(),
                                        label : res.data.stem.name.toUpperCase(),
                                        uuid: uuidv4()
                                    }
                                ],
                                incompleteCustomStems: incompleteCustomStems.filter(stem=> stem.uuid !== uuid)
                            }))
                        }
                    })
                    .catch(error =>{
                        if(!error.response?.data){
                            error.response={
                                data: "Unknown"
                            }
                        }
                        if(error.response?.data.indexOf("Mismatched Length") !==-1){
                            Error({message: `Stems must be the same exact length as the Full Track.`})
                            this.setState(({incompleteCustomStems})=>{
                                index = incompleteCustomStems.findIndex(incTS=>incTS.uuid === uuid)
                                return({
                                incompleteCustomStems: [
                                    ...incompleteCustomStems.slice(0, index),
                                    {
                                        ...incompleteCustomStems[index],
                                        error: <div>Stems must be the same exact length as the Full Track. <StemsExport underline/>.</div>,
                                        loading: false,
                                        progress: 0
                                    },
                                    ...incompleteCustomStems.slice(index+1)
                                ]
                            })})
                        }
                        else if(error.response?.data.indexOf("File Too Large") !==-1){
                            Error({message: `File rejected because it is over 250 mb`})
                            this.setState(({incompleteCustomStems})=>{
                                index = incompleteCustomStems.findIndex(incTS=>incTS.uuid === uuid)
                                    return({
                                        incompleteCustomStems: [
                                            ...incompleteCustomStems.slice(0, index),
                                            {
                                                ...incompleteCustomStems[index],
                                                error: "Track file is over 250 mb",
                                                loading: false,
                                                progress: 0
                                            },
                                            ...incompleteCustomStems.slice(index+1)
                                        ]
                            })})
                        }
                        else{

                            this.setState(({incompleteCustomStems})=>{
                            index = incompleteCustomStems.findIndex(incTS=>incTS.uuid === uuid)
                            return({
                                incompleteCustomStems: [
                                    ...incompleteCustomStems.slice(0, index),
                                    {
                                        ...incompleteCustomStems[index],
                                        error: error?.response?.statusText || "Error Uploading Stem",
                                        loading: false,
                                        progress: 0
                                    },
                                    ...incompleteCustomStems.slice(index+1)
                                ]
                            })})
                        }

                    })
            })
        }
    }
stemsReupload2 = (name, stemId, event) =>{


    let stemName = name

    let stems = this.state.stems
    stems.forEach(stem=>{
        if(stem.id === stemId){
            stem.error = null
            stem.localname = event.target.files[0].name
        }
    })


    this.setState({
        [`stemFile${stemName}`]: event.target.files[0], [`stemFile${stemName}Loading`]: true, stems: stems
    },()=>{
        const fileData = new FormData()
        fileData.append('file', this.state[`stemFile${stemName}`])


            api.reuploadTrackStem(this.props.match.params.trackId, stemName, stemId, fileData)
                .then(res=>{

                    if(res.data.success){
                        this.setState(state=>{
                            let stems = state.stems

                            // state[`versionFile${index}Loading`] = false
                            stems.forEach(stem=>{

                                if (stem.id === stemId){
                                    stem.wavURL = res.data.stem.wavURL
                                    stem.duration = res.data.stem.duration
                                    stem.peak = res.data.stem.peaks
                                    stem.localname = res.data.localname
                                }
                            })

                            return{
                                stems,
                                [`stemFile${stemName}Loading`]: false
                            }
                        })
                    }
                })
                .catch(error =>{

                    this.setState(state=>{
                        let stems =state.stems
                        if(error.response?.data){

                            stems.forEach(stem=>{
                                if(stem.name === stemName){
                                    stem.error = error.response.statusText
                                }
                            })
                        }else{
                            stems.forEach(stem=>{
                                if(stem.id === stemId){
                                    stem.error = "An unknown error occured"
                                }
                            })
                        }
                        return{
                            stems,
                            [`stemFile${stemName}Loading`]: false
                        }
                    })
                })
    })

    }
    updateTagStemProgress = (progressData, uuid)=>{
        let parsedData = JSON.parse(progressData)
        let progress = Math.round(100 * parsedData.loaded/parsedData.total)
        this.setState(({incompleteTaggedStems})=>{
            let index = incompleteTaggedStems.findIndex(incTS=>incTS.uuid === uuid)

            if(incompleteTaggedStems[index]?.value){
                return({
                    incompleteTaggedStems:[
                        ...incompleteTaggedStems.slice(0,index),
                        {
                            ...incompleteTaggedStems[index],
                            progress: progress
                        },
                        ...incompleteTaggedStems.slice(index+1)
                    ]
                })
            }
        })
    }
    updateCompleteTagStemProgress = (progressData, stemId)=>{
        let parsedData = JSON.parse(progressData)
        //
        let progress = Math.round(100 * parsedData.loaded/parsedData.total)
        this.setState(({completeTaggedStems})=>{
            let index = completeTaggedStems.findIndex(incTS=>incTS.id === stemId)
            if(completeTaggedStems[index]?.name){
                return({
                    completeTaggedStems:[
                        ...completeTaggedStems.slice(0,index),
                        {
                            ...completeTaggedStems[index],
                            progress: progress
                        },
                        ...completeTaggedStems.slice(index+1)
                    ]
                })
            }
        })
    }
    updateCustomStemProgress = (progressData, uuid)=>{
        let parsedData = JSON.parse(progressData)

        let progress = Math.round(100 * parsedData.loaded/parsedData.total)
        this.setState(({incompleteCustomStems})=>{
            let index = incompleteCustomStems.findIndex(incTS=>incTS.uuid === uuid)

            if(incompleteCustomStems[index]?.value){
                return({
                    incompleteCustomStems:[
                        ...incompleteCustomStems.slice(0,index),
                        {
                            ...incompleteCustomStems[index],
                            progress: progress
                        },
                        ...incompleteCustomStems.slice(index+1)
                    ]
                })
            }
        })
    }
    updateCompleteCustomStemProgress = (progressData, stemId)=>{
        let parsedData = JSON.parse(progressData)

        let progress = Math.round(100 * parsedData.loaded/parsedData.total)
        this.setState(({completeCustomStems})=>{
            let index = completeCustomStems.findIndex(incTS=>incTS.id === stemId)
            if(completeCustomStems[index]?.name){
                return({
                    completeCustomStems:[
                        ...completeCustomStems.slice(0,index),
                        {
                            ...completeCustomStems[index],
                            progress: progress
                        },
                        ...completeCustomStems.slice(index+1)
                    ]
                })
            }
        })
    }
generateRejectionText =(reason, localname)=>{
    let rejectionText;
    switch(reason){
        case "manual":
            rejectionText = <div>{localname} doesn’t meet our <TechnicalRequirements underline/>. Please upload a corrected file.</div>
            break;
        case "invalid_file":
            rejectionText = <div>The file type of {localname} was incorrect.Please upload a corrected file that meets our <TechnicalRequirements underline/>.</div>
            break;
        case "sample_rate":
            rejectionText = <div>The sample rate of {localname} doesn’t meet our <TechnicalRequirements underline/>. Please upload a corrected file.</div>
            break;
        case "bit_depth":
            rejectionText = <div>The bit depth of {localname} doesn’t meet our <TechnicalRequirements underline/>. Please upload a corrected file.</div>
            break;
        case "virus_detected":
            rejectionText = <div>Our system has determined there is a virus in {localname}. Please upload a corrected file.</div>
            break;
        default:
            rejectionText = <div>{localname} doesn’t meet our <TechnicalRequirements underline/>. Please upload a corrected file.</div>
            break;
    }
    return rejectionText
}
stemsReupload = (label, stemId, event, tagOrCustom, uuid) =>{
        this.props.handleFormUnsaved(true)

            let file = event.target.files[0]
            if(tagOrCustom === "tag"){
                let stemName
                let index
                let notNamed = false
                this.setState(({
                   completeTaggedStems
                })=>{
                    index = completeTaggedStems.findIndex(incTS=>incTS.id === stemId)
                    const socket = socketIOClient();
                    socket.on("progressStem" +this.props.match.params.trackId +uuid , data => {


                        this.updateCompleteTagStemProgress(data, stemId)
                    });

                    stemName = completeTaggedStems[index].name
                    if(stemName?.length < 1){
                        Warning({message: "Please Name Stem First"})
                        notNamed = true
                        return
                    }
                return({
                    completeTaggedStems:[
                        ...completeTaggedStems.slice(0,index),
                        {
                            ...completeTaggedStems[index],
                            name: stemName,
                            localname: file.name,
                            error: null,
                            loading: true,
                            file: file,
                            progress: 0
                        },
                        ...completeTaggedStems.slice(index+1)
                    ]
                })},()=>{
                    if(notNamed){
                        return
                    }
                    const fileData = new FormData()
                    fileData.append('file', file)

                    api.reuploadTrackStem(this.props.match.params.trackId, stemName, stemId, fileData, uuid)
                        .then(res=>{

                            if(res.data.success){
                                this.setState(({completeTaggedStems})=>{
                                index = completeTaggedStems.findIndex(incTS=>incTS.id === stemId)

                                return({
                                    completeTaggedStems:[
                                        ...completeTaggedStems.slice(0,index),
                                        {
                                            ...completeTaggedStems[index],
                                            wavURL: res.data.stem.wavURL,
                                            localname: res.data.stem.localname,
                                            id: res.data.stem.id,
                                            loading: false,
                                            name: res.data.stem.name,
                                            value : res.data.stem.name.toUpperCase(),
                                            label : res.data.stem.name.toUpperCase()
                                        },
                                        ...completeTaggedStems.slice(index+1)
                                    ],
                                })})
                            }
                        })
                        .catch(error =>{
                            if(error.response?.data.indexOf("Mismatched Length") !==-1){
                                Error({message: `Stems must be the same exact length as the Full Track.`})

                                this.setState(({completeTaggedStems})=>({
                                    completeTaggedStems: [
                                        ...completeTaggedStems.slice(0, index),
                                        {
                                            ...completeTaggedStems[index],
                                            error: <div>Stems must be the same exact length as the Full Track. <StemsExport underline/>.</div>,
                                            reason:<div>Stems must be the same exact length as the Full Track. <StemsExport underline/>.</div>,
                                            loading: false,
                                            progress: 0
                                        },
                                        ...completeTaggedStems.slice(index+1)
                                    ]
                                }))
                            }
                            else if(error.response?.data.indexOf("File Too Large") !==-1){
                                Error({message: `File rejected because it is over 250 mb`})
                                this.setState(({completeTaggedStems})=>({
                                    completeTaggedStems: [
                                        ...completeTaggedStems.slice(0, index),
                                        {
                                            ...completeTaggedStems[index],
                                            error: "Track file is over 250 mb",
                                            reason: "Track file is over 250 mb",
                                            loading: false,
                                            progress: 0
                                        },
                                        ...completeTaggedStems.slice(index+1)
                                    ]
                                }))
                            }
                            else{
                                this.setState(({completeTaggedStems})=>({
                                    completeTaggedStems: [
                                        ...completeTaggedStems.slice(0, index),
                                        {
                                            ...completeTaggedStems[index],
                                            error: error?.response?.statusText || "Error Uploading Stem",
                                            loading: false,
                                            progress: 0
                                        },
                                        ...completeTaggedStems.slice(index+1)
                                    ]
                                }))
                            }
                        })
                })
            }
            else{
                let stemName
                let index
                let notNamed = false

                this.setState(({
                    completeCustomStems
                })=>{
                    index = completeCustomStems.findIndex(incTS=>incTS.id === stemId)
                    const socket = socketIOClient();
                    socket.on("progressStem" +this.props.match.params.trackId +uuid , data => {

                        this.updateCompleteCustomStemProgress(data, stemId)
                      });
                    stemName = label


                    if(stemName.length < 1){
                        notNamed = true
                        Warning({message: "Name stem before uploading"})
                        return
                    }
                return({
                    completeCustomStems:[
                        ...completeCustomStems.slice(0,index),
                        {
                            ...completeCustomStems[index],
                            name: stemName,
                            localname: file.name,
                            error: null,
                            loading: true,
                            file: file,
                            progress: 0
                        },
                        ...completeCustomStems.slice(index+1)
                    ]
                })},()=>{
                    if(notNamed){
                        return
                    }
                    const fileData = new FormData()
                    fileData.append('file', file)

                    api.reuploadTrackStem(this.props.match.params.trackId, stemName, stemId, fileData, uuid)
                        .then(res=>{

                            if(res.data.success){
                                this.setState(({completeCustomStems})=>({
                                    completeCustomStems:[
                                        ...completeCustomStems.slice(0,index),
                                        {
                                            ...completeCustomStems[index],
                                            wavURL: res.data.stem.wavURL,
                                            localname: res.data.stem.localname,
                                            id: res.data.stem.id,
                                            loading: false,
                                            name: res.data.stem.name,
                                            value : res.data.stem.name.toUpperCase(),
                                            label : res.data.stem.name.toUpperCase()
                                        },
                                        ...completeCustomStems.slice(index+1)
                                    ],
                                }))
                            }
                        })
                        .catch(error =>{
                            if(error.response?.data.indexOf("Mismatched Length") !==-1){
                                Error({message: `Stems must be the same exact length as the Full Track.`})

                                this.setState(({completeCustomStems})=>{
                                index = completeCustomStems.findIndex(incTS=>incTS.id === stemId)
                                return({
                                    completeCustomStems: [
                                        ...completeCustomStems.slice(0, index),
                                        {
                                            ...completeCustomStems[index],
                                            error: <div>Stems must be the same exact length as the Full Track. <StemsExport underline/>.</div>,
                                            reason:<div>Stems must be the same exact length as the Full Track. <StemsExport underline/>.</div>,
                                            loading: false,
                                            progress: 0
                                        },
                                        ...completeCustomStems.slice(index+1)
                                    ]
                                })})
                            }
                            else if(error.response?.data.indexOf("File Too Large") !==-1){
                                Error({message: `File rejected because it is over 250 mb`})
                                this.setState(({completeCustomStems})=>{
                                    index = completeCustomStems.findIndex(incTS=>incTS.id === stemId)
                                    return({
                                            completeCustomStems: [
                                                ...completeCustomStems.slice(0, index),
                                                {
                                                    ...completeCustomStems[index],
                                                    error: "Track file is over 250 mb",
                                                    reason: "Track file is over 250 mb",
                                                    loading: false,
                                                    progress: 0
                                                },
                                                ...completeCustomStems.slice(index+1)
                                            ]
                                })})
                            }
                            else if(error?.response?.data.indexOf("File is longer than 12 minutes") !==-1){
                                Error({message: "File was rejected because it is longer than 12 minutes."})
                                this.setState(({additionalVersions})=>({
                                    additionalVersions:[
                                        ...additionalVersions.slice(0, index),
                                        {
                                            ...additionalVersions[index],
                                            error: "Track file is longer than 12 minutes",
                                            reason: "Track file is longer than 12 minutes",
                                            loading: false
                                        },
                                        ...additionalVersions.slice(index+1)
                                    ]
                                }))
                            }
                            else{
                                this.setState(({completeCustomStems})=>{
                                index = completeCustomStems.findIndex(incTS=>incTS.id === stemId)
                                return({
                                    completeCustomStems: [
                                        ...completeCustomStems.slice(0, index),
                                        {
                                            ...completeCustomStems[index],
                                            error: error?.response?.statusText || "Error Uploading Stem",
                                            loading: false,
                                            progress: 0
                                        },
                                        ...completeCustomStems.slice(index+1)
                                    ]
                                })})
                            }
                        })
                })
            }
        }
handleGenreChange = selectedGenres =>{
this.props.handleFormUnsaved(true)

if(selectedGenres){
    if(selectedGenres.length > 2){
        return
    }
}

this.setState(state => {
    return {
      genres: selectedGenres
    };
})
}
handleEmotionChange = selectedEmotions =>{
this.props.handleFormUnsaved(true)
if(selectedEmotions){
    if(selectedEmotions.length > 1){
        return
    }
}
this.setState(state => {
    return {
      emotions: selectedEmotions
    };
})
}
handleKeywordChange = selectedKeywords =>{
    this.props.handleFormUnsaved(true)
    this.setState(state => {
        return {
          keywords: selectedKeywords
        };
    })
    }
handleMoodChange = selectedMoods =>{
    this.props.handleFormUnsaved(true)
if(selectedMoods){
    if(selectedMoods.length > 4){
        return
    }
}
this.setState(state => {
    return {
      moods: selectedMoods
    };
})
}
handleAttributeChange = selectedAttributes =>{
    this.props.handleFormUnsaved(true)
    if(selectedAttributes.length > 8){

        return
    }
    this.setState(state => {
        return {
            attributes: selectedAttributes
        };
})
}
handleInstrumentsChange = selectedInstruments =>{
    this.props.handleFormUnsaved(true)
    this.setState(({incompleteTaggedStems, completeTaggedStems})=>{
        let patchIncomplete = []
        if(selectedInstruments){
            if(selectedInstruments.length > 0){
                if(completeTaggedStems){
                    let removedStemTags = completeTaggedStems.filter(stem=>{
                        let stemIndInTagsOptions = selectedInstruments.findIndex(instTag=> instTag.label === stem.name)
                        let stemIndInInstOptions = this.state.instrumentOptions.findIndex(instTagOption=> instTagOption.label === stem.name)
                        if(stemIndInTagsOptions === -1  && stemIndInInstOptions !==-1){
                            selectedInstruments.push({label: this.state.instrumentOptions[stemIndInInstOptions].label , value: this.state.instrumentOptions[stemIndInInstOptions].value, complete: true})
                            Warning({message: "Remove Stem First"})
                            return true
                        }
                        else{
                            return false
                        }
                    })
                }
                selectedInstruments.forEach(instTagDropdown=>{
                    let instTagIndComplete = this.state.completeTaggedStems.findIndex(stem=>stem.name === instTagDropdown.label)
                    if(instTagIndComplete === -1){
                        patchIncomplete.push({label: instTagDropdown.label, value: instTagDropdown.value, uuid: uuidv4()})
                    }
                })
            }
            else{
                selectedInstruments = []
                completeTaggedStems.forEach(stem=>{
                    let inst = this.state.instrumentOptions.filter(inst=>inst.label === stem.name)[0]
                    selectedInstruments.push({label: inst.label, value: inst.value, complete: true})
                    Warning({message: "Remove Stem First"})
                })
                patchIncomplete = []
            }
        }
        else{
            selectedInstruments = []
            completeTaggedStems.forEach(stem=>{
                let inst = this.state.instrumentOptions.filter(inst=>inst.label === stem.name)[0]
                selectedInstruments.push({label: inst.label, value: inst.value, complete: true})
                Warning({message: "Remove Stem First"})
            })
            patchIncomplete = []
        }
        return({
            instruments: selectedInstruments,
            incompleteTaggedStems: patchIncomplete
        })
    })

    }
    handleCityChange = selectedCity =>{
        this.props.handleFormUnsaved(true)
        if(selectedCity){
            if(selectedCity.length > 1){
                return
            }
        }
        this.setState(state => {
            return {
              city: selectedCity,
              cityInput: selectedCity?.label || ""
            };
        })
    }

addNewVersionDiv = (event)=>{
this.props.handleFormUnsaved(true)
event.preventDefault()
let additionalVersions = this.state.additionalVersions;
additionalVersions.push({name: ""})
this.setState({additionalVersions: additionalVersions})
}
handleVersionNameChange = (index, selectedVersionName) =>{
let additionalVersions = this.state.additionalVersions;
additionalVersions[index] = selectedVersionName;


this.setState(state=>{
    return{
        additionalVersions:additionalVersions
    }
})

}


removeVersion = (versionId)=>{
    api.removeVersion(versionId)
        .then(res=>{

            if(res.data.success){
                let remainingVersions = this.state.additionalVersions.filter(version => version.versionId !== res.data.deleted)
                this.setState({additionalVersions: remainingVersions})
            }
        })
}

removeStem = (stemId)=>{
    api.removeStem(stemId)
        .then(res=>{

            if(res.data.success){
                let remainingStems = this.state.instruments.filter(stem => stem.stemId !== res.data.deleted)
                this.setState({instruments: remainingStems})
            }
        })
}
componentDidMount = ( )=>{
this.props.handleFormUnsaved(false)
api.getTags()
    .then(res=>{

        //filter genre tags
        let genreOptions = []
        let emotionOptions = []
        let moodOptions = []
        let attributeOptions = []
        let instrumentOptions = []
        let cityOptions = []
        let bpmOptions = []
        let allOptions = []
        res.data.forEach(tag=>{
            switch(tag.type){
                case 1: genreOptions.push({value: tag.id, label: tag.name.replace(/^\w/, c => c.toUpperCase()), type: "genre"})
                    break;
                case 2: emotionOptions.push({value: tag.id, label: tag.name.replace(/^\w/, c => c.toUpperCase()), type: "emotion"})
                    break;
                case 3: moodOptions.push({value: tag.id, label: tag.name.replace(/^\w/, c => c.toUpperCase()), type: "mood"})
                    break;
                case 4:  attributeOptions.push({value: tag.id, label: tag.name.replace(/^\w/, c => c.toUpperCase()), type: "attribute"})
                    break;
                case 5: instrumentOptions.push({value: tag.id, label: tag.name, type: "instrument"})
                    break;
                case 6:  cityOptions.push({value: tag.id,label: tag.name.replace(/^\w/, c => c.toUpperCase()), type: "city"})
                    break;
                case 8: bpmOptions.push({value: tag.id, label: tag.name, type: "bpm"})
                    break;
                default:
                    break;
            }
        })
        allOptions = allOptions.concat(genreOptions, emotionOptions, moodOptions, attributeOptions, instrumentOptions, cityOptions, bpmOptions )
        this.setState({
            genreOptions: genreOptions,
            emotionOptions: emotionOptions,
            moodOptions: moodOptions,
            attributeOptions: attributeOptions,
            instrumentOptions: instrumentOptions,
            bpmOptions: bpmOptions,
            allOptions: allOptions
        }, ()=>{

        api.getTrackById(this.props.match.params.trackId)
            .then(res=>{
                let tags = res.data.track.tags
                let genres = []
                let emotions = []
                let moods = []
                let attributes = []
                let instruments = []
                let city;
                let lyrics = {}
                let trackBPM
                tags.forEach(tag=>{
                    switch(tag.type){
                        case 1: genres.push({value: tag.tagId, label: tag.name})
                            break;
                        case 2: emotions.push({value: tag.tagId, label: tag.name})
                            break;
                        case 3: moods.push({value: tag.tagId, label: tag.name})
                            break;
                        case 4: attributes.push({value: tag.tagId, label: tag.name})
                            break;
                        case 5: instruments.push({value: tag.tagId, label: tag.name})
                            break;
                        case 6: city = {value: tag.tagId, label: tag.name}
                            break;
                        case 7: if(tag.tagId === 172){
                            lyrics.exist = "false"
                            }
                            else if(tag.tagId === 173){
                                lyrics.exist = "true"
                            }
                            else if(tag.tagId === 174){
                                lyrics.explicit = "true"
                            }
                            break;
                        case 8: trackBPM = tag.tagId
                            break;
                    }
                })
                let keywords = []
                let existingKeywords = []
                if(res.data.track.keywords) {

                    // let trackKeywords = res.data.track.keywords
                    existingKeywords = res.data.track.keywords.map(keyword => {
                        return{
                            id: keyword.keywordId,
                            label: keyword.name,
                            value: keyword.name
                        }
                    })
                }

                let versions = []
                res.data.versions.forEach(version=>{
                    versions.push({
                        value: version.name,
                        label: version.name,
                        wavURL: version.mp3URL || version.wavURL || "",
                        duration: version.duration,
                        peaks: version.peaks,
                        id: version.id,
                        localname: version.localname
                    })
                })

                let rejectionReasons = JSON.parse(res.data.track.rejectionReasons)
                versions.forEach(version=>{
                    if(rejectionReasons.versions.length >0){
                        let rejectedVersion = rejectionReasons.versions.filter(rejectedVersion=> rejectedVersion.id === version.id)
                        if(rejectedVersion.length > 0){
                            if (rejectedVersion[0].approval === "reject"){
                                version.error = "This track does not meet our technical standards. Please reupload your version."
                                version.reject = true
                                version.reason = this.generateRejectionText(rejectedVersion[0].reason, version.localname)
                            }
                        }
                    }

                    // if(rejectionReasons.versions.length > 0){
                    //     rejectionReasons.versions.forEach(rejectedVersion=>{
                    //         if(rejectedVersion.id == version.versionId){
                    //             if(rejectedVersion.approval === "reject"){
                    //                 version.error = "This track does not meet our quality standards. Please reupload your version."
                    //             }
                    //         }
                    //     })
                    // }
                })
                let stems = res.data.stems
                let completeCustomStems = []
                let completeTaggedStems = []
                let incompleteTaggedStems = []
                if(stems){
                    if(stems.length){

                        completeTaggedStems = stems.filter(stem=>{
                            let stemIndInTagsOptions = this.state.instrumentOptions.findIndex(instTag=> instTag.label === stem.name)
                            if(stemIndInTagsOptions ===-1){
                                return true
                            }
                            else{
                                let stemIsTagged = instruments.findIndex(instTag=>instTag.label === stem.name)
                                let instId = this.state.instrumentOptions.filter(inst=> inst.label === stem.name)[0].value
                                if(stemIsTagged === -1){
                                    instruments.push({label: stem.name, value: instId})
                                }
                                return true
                            }
                        }).map(stem=>{
                            stem.uuid = uuidv4()
                            return stem
                        })

                    }
                    if(instruments.length > 0){
                        instruments.forEach( inst=>{
                            let instTagInSavedStems = stems.findIndex(stem=>stem.name === inst.label)
                            if(instTagInSavedStems === -1){
                                inst.uuid = uuidv4()

                                incompleteTaggedStems.push(inst)
                            }
                        })
                    }
                }
                else{
                    //if no stems were retreieved from the response, set the stems to the instruments
                    stems = instruments
                }

                completeTaggedStems.sort(this.alphaSort)
                completeTaggedStems.forEach((stem, index)=>{
                    if(rejectionReasons.stems.length > 0){
                        let rejectedStem = rejectionReasons.stems.filter(rejectedStem=> rejectedStem.id === stem.id)
                        if(rejectedStem.length > 0){
                            if(rejectedStem[0].approval === "reject"){
                                completeTaggedStems[index].error = "This track does not meet our technical standards. Please reupload your stem."
                                completeTaggedStems[index].reject = true
                                completeTaggedStems[index].reason = this.generateRejectionText(rejectedStem[0].reason, stem.localname)
                            }
                        }
                    }

                    // if(rejectionReasons.stems.length > 0){
                    //     rejectionReasons.stems.forEach(rejectedStem=>{
                    //         if(rejectedStem.id == stem.id){
                    //             if(rejectedStem.approval ===)
                    //             stem.error = "This track does not meet our quality standards. Please reupload your version."
                    //         }
                    //     })
                    // }
                })
                completeCustomStems.forEach((stem, index)=>{
                    if(rejectionReasons.stems.length > 0){
                        let rejectedStem = rejectionReasons.stems.filter(rejectedStem=> rejectedStem.id === stem.id)
                        if(rejectedStem.length > 0){
                            if(rejectedStem[0].approval === "reject"){
                                completeCustomStems[index].error = "This track does not meet our technical standards. Please reupload your stem."
                                completeCustomStems[index].reject = true
                                completeCustomStems[index].reason = this.generateRejectionText(rejectedStem[0].reason, stem.localname)
                            }
                        }
                    }
                })
                completeTaggedStems = completeTaggedStems.map(stem=>{
                    stem.uuid = uuidv4()
                    stem.wavURL = stem.mp3URL || stem.wavURL
                    return stem
                })
                completeCustomStems = completeCustomStems.map(stem=>{
                    stem.wavURL = stem.mp3URL || stem.wavURL
                    return stem
                })

                instruments.forEach(instrument =>{
                    res.data.stems.forEach(stem=>{

                        if (instrument.value === stem.name){
                            instrument.key = stem.wavURL
                            instrument.duration = stem.duration
                            instrument.peaks = stem.peaks
                            instrument.stemId = stem.id
                        }
                    })
                })

                if(this.props.user.admin){
                    api.getArtistAlbumsForAdmin(res.data.track.artistId)
                    .then(albumRes=>{

                        if(albumRes.data.length){
                            let album = albumRes.data.filter(album=> album.id=== res.data.track.albumId)[0]
                            this.setState({
                              myAlbums: albumRes.data.map(album => {
                                  return {
                                      label: album.title,
                                      value: album.id,
                                      imageUrl: album.imageUrl
                                  }
                              }),
                              album: {
                                label: album.title,
                                value: album.id,
                                imageUrl: album.imageUrl
                              }
                            })
                        }
                    })
                }
                else{
                    api.getMyAlbums()
                    .then(albumRes=>{

                        if(albumRes.data.length){
                            let album = albumRes.data.filter(album=> album.id=== res.data.track.albumId)[0]
                            this.setState({
                                myAlbums: albumRes.data.map(album=>{
                                    return{
                                        label: album.title,
                                        value: album.id,
                                        imageUrl: album.imageUrl
                                    }
                                }),
                                album: {label: album.title, value: album.id, imageUrl: album.imageUrl}
                            })
                        }
                    })
                }
                this.setState({
                    artistId: res.data.track.artistId,
                    title: res.data.track.trackTitle,
                    attributes: attributes,
                    emotions: emotions,
                    genres: genres,
                    instruments: instruments,
                    stems: stems,
                    incompleteTaggedStems: incompleteTaggedStems,
                    completeTaggedStems: completeTaggedStems,
                    completeCustomStems: completeCustomStems,
                    moods: moods,
                    city: city,
                    trackBPM: trackBPM || "",
                    containsLyrics: lyrics ? lyrics.exist === "true" ? "true": "false": "false",
                    explicitLyrics: lyrics ? lyrics.explicit === "true" ? "true": "false": "false",
                    funFact: res.data.track.funFact,
                    additionalVersions: versions,
                    versionsAvailable: versions.length ? "true": this.state.versionsAvailable,
                    stemsAvailable: res.data.track.stems.length ? "true": this.state.stemsAvailable,
                    keywords: existingKeywords.length ? existingKeywords : keywords,
                    rejectionReasons: {
                        content: rejectionReasons.content,
                        curation: rejectionReasons.curation,
                        exclusivity: rejectionReasons.exclusivity,
                    },
                    rejectionComment: res.data.track.rejectionComment,
                    mainRejectionReason: rejectionReasons.reason,
                    virusDetected: rejectionReasons.virusDetected,
                    trackQuality: rejectionReasons.trackQuality,
                    localname: res.data.track.localname,
                    wavURL: res.data.track.mp3URL || res.data.track.wavURL,
                    duration: res.data.track.duration,
                    peaks: res.data.track.peaks,
                    pageLoaded: true

                }, ()=>{
                    let detailsComplete;
                    let tagsComplete

                    let versionsComplete;
                    if(this.state.additionalVersions.length > 0){
                        versionsComplete= this.state.additionalVersions.filter(version=> version.wavURL.length < 0 ).length ? false: true
                    }
                    else{
                        versionsComplete = false
                    }
                    let stemsComplete;
                    if(this.state.instruments.length > 0){
                        stemsComplete= this.state.instruments.filter(instrument=> instrument.key === undefined).length ? false: true
                    }
                    else{
                        stemsComplete = false
                    }
                    if(this.state.title.length > 0 && this.state.album){
                        detailsComplete = true
                    }
                    else{
                        detailsComplete = false
                    }

                    if(this.state.attributes.length > 0 && this.state.emotions.length > 0 && this.state.genres.length > 0 && this.state.instruments.length > 0
                        && this.state.moods.length > 0 && this.state.city && this.state.trackBPM.length > 0){
                            tagsComplete = true
                        }
                        else{
                            tagsComplete = false
                        }
                    this.setState({
                        detailsComplete: detailsComplete,
                        tagsComplete: tagsComplete,
                        versionsComplete: versionsComplete,
                        stemsComplete: stemsComplete
                    })
                })
            })
            .catch(err => {
                this.setState({ error: err.message });
            })
        })
    })

// api.getTags()
//     .then(res=>{
//
//         //filter genre tags
//         let genreOptions = []
//         let emotionOptions = []
//         let moodOptions = []
//         let attributeOptions = []
//         let instrumentOptions = []
//         let cityOptions = []
//         let bpmOptions = []
//         let allOptions = []
//         res.data.forEach(tag=>{
//             switch(tag.type){
//                 case 1: genreOptions.push({value: tag.id, label: tag.name.replace(/^\w/, c => c.toUpperCase()), type: "genre"})
//                     break;
//                 case 2: emotionOptions.push({value: tag.id, label: tag.name.replace(/^\w/, c => c.toUpperCase()), type: "emotion"})
//                     break;
//                 case 3: moodOptions.push({value: tag.id, label: tag.name.replace(/^\w/, c => c.toUpperCase()), type: "mood"})
//                     break;
//                 case 4:  attributeOptions.push({value: tag.id, label: tag.name.replace(/^\w/, c => c.toUpperCase()), type: "attribute"})
//                     break;
//                 case 5: instrumentOptions.push({value: tag.id, label: tag.name, type: "instrument"})
//                     break;
//                 case 6:  cityOptions.push({value: tag.id,label: tag.name.replace(/^\w/, c => c.toUpperCase()), type: "city"})
//                     break;
//                 case 8: bpmOptions.push({value: tag.id, label: tag.name, type: "bpm"})
//                     break;
//                 default:
//                     break;
//             }
//         })
//         allOptions = allOptions.concat(genreOptions, emotionOptions, moodOptions, attributeOptions, instrumentOptions, cityOptions, bpmOptions )
//         this.setState({
//             genreOptions: genreOptions,
//             emotionOptions: emotionOptions,
//             moodOptions: moodOptions,
//             attributeOptions: attributeOptions,
//             instrumentOptions: instrumentOptions,
//             bpmOptions: bpmOptions,
//             allOptions: allOptions,
//         })
//     })

}

alphaSort = (a,b)=>{
    if(a.name > b.name ){
        return 1
    }
    else if(a.name < b.name){
        return -1
    }
    else{
        return 0
    }
}
setFileState = (file)=>{

this.setState({
    file: file
})
}

handleAlbumChange = (selectedAlbum)=>{
    this.props.handleFormUnsaved(true)
    this.setState(state =>{
        return{
            album: selectedAlbum
        }
    })
}
handleStemNameChange = (event, index)=>{
    this.props.handleFormUnsaved(true)
    let stemIsTagged = this.state.instruments.findIndex(instTag=>instTag.label.toLowerCase() === event.label.toLowerCase())

    let instId = this.state.instrumentOptions.filter(inst=> inst.label.toLowerCase() === event.label.toLowerCase())[0]

    if(stemIsTagged !== -1 || instId === undefined){

        this.setState(({incompleteCustomStems})=>({
            incompleteCustomStems:[
                ...incompleteCustomStems.slice(0, index),
                {
                    ...incompleteCustomStems[index],
                    label: event.label,
                    value: event.value,
                },
                ...incompleteCustomStems.slice(index + 1)
            ]
        }))
    }
    else{

        this.setState(({incompleteCustomStems, instruments})=>({
            incompleteCustomStems:[
                ...incompleteCustomStems.slice(0, index),
                {
                    ...incompleteCustomStems[index],
                    label: event.label,
                    value: event.value,
                },
                ...incompleteCustomStems.slice(index + 1)
            ],
            instruments:[
                ...instruments,
                {
                    ...instId
                },
            ]
        }))
    }

    // let incompleteCustomStems = this.state.incompleteCustomStems
    // incompleteCustomStems[index] = {label: value, value: value}
    // this.setState({incompleteCustomStems: incompleteCustomStems})
}
checkSubmittability = ()=>{
    let detailsComplete
    let trackFile;
    let tagsComplete
    let funFactComplete
    let stemsComplete;
    let versionsComplete;
    let termsComplete;
    let attributes = this.state.attributes || []
    let emotions = this.state.emotions || []
    let genres = this.state.genres || []
    let instruments = this.state.instruments || []
    let moods = this.state.moods || []
    let trackBPM = this.state.trackBPM || ""
    let versionsErrorFree
    let stemsErrorFree
    if(this.state.title.length > 0 && this.state.album){
        detailsComplete = true
    }
    else{
        detailsComplete = false
    }
    if(this.state.trackQuality !== "reject"){
        trackFile = true
    }
    else{
        trackFile = false
    }
    if(attributes.length > 0 && emotions.length > 0 && genres.length > 0 && instruments.length > 0
        && moods.length > 0 && this.state.city && trackBPM > 0){
            tagsComplete = true
    }
    else{
            tagsComplete = false
    }
    if(this.state.funFact?.length && this.state.funFact?.length <= 400) {
        funFactComplete = true
    }
    else if(this.state.funFact?.length > 400) {
        funFactComplete = "incomplete"
    }

    if(this.state.stemsAvailable === "true"){
        if(this.state.incompleteTaggedStems?.length > 0){
            stemsComplete = "incomplete"
        }
        else{

            stemsComplete = (this.state.completeTaggedStems.filter(stem=> stem.wavURL === undefined || stem.wavURL === "" || stem.error?.length > 0).length ||
            this.state.completeCustomStems.filter(stem=> stem.wavURL === undefined || stem.wavURL === "" || stem.error?.length > 0).length) ? "incomplete": true
        }
    }
    else{
        stemsComplete = false
    }
    stemsErrorFree = stemsComplete !== "incomplete"

    if(this.state.additionalVersions.length > 0){
        versionsComplete= this.state.additionalVersions.filter(version=> version.wavURL===undefined || version.wavURL === "" || version.error?.length > 0).length ? "incomplete": true

    }
    else{
        if(this.state.versionsAvailable === "true"){
            versionsComplete = "incomplete"
        }
        else{
            versionsComplete = false
        }
    }
    versionsErrorFree = versionsComplete !== "incomplete"

    if(this.state.tosAgree && this.state.exclusiveAgree && this.state.removeAgree && this.state.copyAgree){
        termsComplete = true
    }
    else{
        termsComplete = false
    }
    return {
      detailsComplete: detailsComplete,
      trackFile: trackFile,
      tagsComplete: tagsComplete,
      funFactComplete: funFactComplete,
      stemsComplete: stemsComplete,
      stemsErrorFree: stemsErrorFree,
      versionsErrorFree: versionsErrorFree,
      versionsComplete: versionsComplete,
      termsComplete: termsComplete
    }
}
getPrimary= (rejectionReasons)=>{
    let rejectionPrimary ={
        title: "Poor Audio Quality",
        desc: <span>Audio does not meet technical requirements. Please re-upload. Please export your file(s) to have a sample rate of 48 kHz and 24 Bit Depth.</span>
    };
    if(rejectionReasons){

        if(rejectionReasons.content ==="reject"){
            rejectionPrimary = {
                title: "Content",
                desc: "We have determined this track does not meet our content standards."
            }
            return rejectionPrimary
        }
        if(rejectionReasons.curation ==="reject"){
            rejectionPrimary = {
                title: "Curation",
                desc: "We have determined this track does not meet our curation standards."
            }
            return rejectionPrimary
        }
        if(rejectionReasons.exclusivity ==="reject"){
            rejectionPrimary = {
                title: "Exclusivity",
                desc: "We have determined this track does not meet our exclusivity standards."
            }
            return rejectionPrimary
        }
        if(this.state.virusDetected === true){
            rejectionPrimary = {
                title: "Virus Detected",
                desc: <span>We detected a virus in one or more of your files. Please re-export a clean version and re-upload.</span>
            }
            return rejectionPrimary
        }
        if(this.state.trackQuality ==="reject"){
            rejectionPrimary = {
                title: "Poor Audio Quality",
                desc: <span>Audio does not meet technical requirements. Please re-upload. Please export your file(s) to have a sample rate of 48 kHz and 24 Bit Depth. </span>
            }
            return rejectionPrimary
        }
        if(rejectionReasons.versions){
            rejectionReasons.versions.forEach(version=>{
                if(version.approval === "reject"){
                    rejectionPrimary = {
                        title: "Poor Audio Quality",
                        desc: <span>Audio does not meet technical requirements. Please re-upload. Please export your file(s) to have a sample rate of 48 kHz and 24 Bit Depth. </span>
                    }
                    return rejectionPrimary
                }
            })
        }
        if(rejectionReasons.stems){
            rejectionReasons.stems.forEach(stem=>{
                if(stem.approval === "reject"){
                    rejectionPrimary = {
                        title: "Poor Audio Quality",
                        desc: <span>Audio does not meet technical requirements. Please re-upload. Please export your file(s) to have a sample rate of 48 kHz and 24 Bit Depth. </span>
                    }
                    return rejectionPrimary
                }
            })
        }
    }
    return rejectionPrimary
}
componentDidUpdate = ()=>{

    let {detailsComplete, trackFile, tagsComplete, funFactComplete: funFactComplete, stemsComplete, stemsErrorFree, versionsComplete, versionsErrorFree, termsComplete} = this.checkSubmittability()



    if(this.state.detailsComplete !== detailsComplete || this.state.trackFile !== trackFile || this.state.tagsComplete !== tagsComplete ||
        this.state.funFactComplete !== funFactComplete || this.state.stemsComplete !== stemsComplete || this.state.stemsErrorFree !== stemsErrorFree ||
        this.state.versionsComplete !== versionsComplete || this.state.versionsErrorFree !== versionsErrorFree ||this.state.termsComplete !== termsComplete){
        this.setState({
          detailsComplete: detailsComplete,
          trackFile: trackFile,
          tagsComplete: tagsComplete,
          funFactComplete: funFactComplete,
          stemsComplete: stemsComplete,
          stemsErrorFree: stemsErrorFree,
          versionsComplete: versionsComplete,
          versionsErrorFree: versionsErrorFree,
          termsComplete: termsComplete,
          submittable: (detailsComplete && tagsComplete  && termsComplete && trackFile && (stemsComplete !== "incomplete") && (versionsComplete !== "incomplete") )})
    }

}
successToastSubmit = ()=>{
    toast.dismiss()
    // window.location.replace("/dashboard/tracks/pending")
}
successToastSave = ()=>{
    toast.dismiss()
    // window.location.replace("/dashboard/tracks/incomplete")
}
errorToast = ()=>{
    toast.dismiss()
}
handleFormSubmit = async (submitting) => {

    if(submitting){
        if(await confirm("Are you sure you want to submit this track? (You will not be able to edit this track until it is reviewed)", "Submit Track")){

        }
        else{
            return
        }
    }
    let keywords = this.state.keywords || []
    let trackInfo = {
        title: this.state.title,
        albumId: this.state.album?.value,
        // keywords: this.state.keywords ? this.state.keywords.map(keyword=> keyword.value).join(", ") : undefined,
        funFact: this.state.funFact,
        trackStatus: submitting && this.state.submittable ? "pending": "rejected"
    }
    let tags = []
    if(this.state.genres){
        this.state.genres.forEach(genre=>{tags.push(genre.value)})
    }
    if(this.state.emotions){
        this.state.emotions.forEach(emotion=>{tags.push(emotion.value)})
    }
    if(this.state.moods){
        this.state.moods.forEach(mood=>{tags.push(mood.value)})
    }
    if(this.state.attributes){
        this.state.attributes.forEach(attribute=>{tags.push(attribute.value)})
    }
    if(this.state.instruments){
        this.state.instruments.forEach(instrument=>{tags.push(instrument.value)})
    }
    if(this.state.city){
        tags.push(this.state.city.value)
    }
    if(this.state.trackBPM){
        tags.push(parseInt(this.state.trackBPM))
    }
    if(this.state.containsLyrics === "true"){
        //need to point to lyrics tag in db
        tags.push(173)
        if(this.state.explicitLyrics === "true"){
            tags.push(174)
        }
    }
    else{
        tags.push(172)
    }

    api.editTrackById(this.props.match.params.trackId, trackInfo, submitting, tags, keywords)
        .then(res=>{

            if(res.data.success ){
                this.props.handleFormUnsaved(false)
                if(submitting){
                    api.resetApprovalSteps(this.props.match.params.trackId)
                    .then(res=>{
                        if(res.data.success){
                            this.props.history.push("/dashboard/tracks/pending")
                            SuccessRedirect({message:"Track Submitted!", onClose: this.successToastSubmit,  autoClose: 3000})
                        }
                    })
                }
                else{
                    this.props.history.push("/dashboard/tracks/rejected")
                    Success( {onClose: this.successToastSave, message:"Track Saved"})
                }
            }
            else{

                Error({message:"An error occurred!", onClose: this.errorToast})
            }
        }).catch(error=>{


            Error({message:"An error occurred!", onClose:this.errorToast})
        })

    }
    getCities = (cityInput)=>{

        if(cityInput?.length > 2){
                return api.getCities(cityInput)
                    .then(res=>{
                        let cities = res.data.cityTags

                        let citiesOptions = cities.map(city=>{
                            let cityOption = {
                                label: city.name,
                                value: city.id
                            }
                            return cityOption
                        })

                        return citiesOptions
                    })
        }else{
            return [{label: "", value: ""}]
        }


    }
    changeCityInput = (newValue)=>{

        // if(!newValue && this.state.city?.label){
        //     const inputValue =this.state.city?.label
        //     this.setState({cityInput: inputValue})
        //     return inputValue
        // }
        // else{
            const inputValue =newValue
            this.setState({cityInput: inputValue})
            return inputValue
        // }

    }
    cityKeyDown = (event)=>{

        if(event.keyCode === 8 && (this.state.cityInput?.length ===1 || !this.state.cityInput)){
            this.handleCityChange(null)
        }
    }
    altChangeCityInput = (newValue)=>{

        // const inputValue =newValue
        // this.setState({cityInput: inputValue})
        // return inputValue
    }
    checkStemName = (name, index)=>{
        let inInstruments = this.state.instrumentOptions.filter(inst=>inst.label?.toLowerCase() === name?.toLowerCase())
        let inIncompleteCustomStems = this.state.incompleteCustomStems.filter((stem, ind)=>ind!== index).filter(stem=>stem.label?.toLowerCase() === name?.toLowerCase())
        let inCompleteCustomStems = this.state.completeCustomStems.filter(stem=>stem.name?.toLowerCase() === name?.toLowerCase())

        if(inInstruments.length > 0){
            Error({message:"Custom stem names cannot be in instrument tags. Please use instument tag instead"})
            return true
        }
        else if(inCompleteCustomStems.length > 0){
            Error({message:"Custom stem name already being used"})
            return true
        }
        else if(inIncompleteCustomStems.length > 0){
            Error({message:"Custom stem name already being used"})
            return true
        }
        else{
            return false
        }
    }
    updateAlbumsCB = (newAlbum)=>{
        let myAlbumOptions = [...this.state.myAlbums]
        myAlbumOptions.push(newAlbum)
        this.setState({isAlbumModalOpen: false, myAlbums: myAlbumOptions, album: myAlbumOptions[myAlbumOptions.length-1]})
    }
    openAlbumModal = ()=>{
        this.setState({isAlbumModalOpen: true})
    }
    handleAlbumModalClose= ()=>{
        if(this.state.albumLoading){
            return
        }
        else{
            this.setState({isAlbumModalOpen: false})
        }
    }
    removeVersionFromState = (index)=>{
        this.props.handleFormUnsaved(true)
        let additionalVersions = this.state.additionalVersions.filter((version, ind)=>ind !==index)
        this.setState({additionalVersions: additionalVersions})
    }
    downloadFullTrack=()=>{

        this.setState({isDownloading: true})
        let filename = this.props.artistName + "_" + this.props.trackTitle + "_Full.wav"

        api.adminMainTrackDownload(this.props.match.params.trackId)
            .then(res=>{


                if(res.data?.url){

                    let downloadEl = document.createElement("a")
                    downloadEl.setAttribute("href", res.data.url)
                    downloadEl.setAttribute("download", res.data.filename.split(" ").join(""))
                    downloadEl.style.display = 'none'
                    document.body.appendChild(downloadEl);
                    downloadEl.click();

                    this.setState({isDownloading: false})
                }

            })
            .catch(err=>{

                this.setState({isDownloading: false})
            })
    }
    downloadVersion=(versionId)=>{

        this.setState({isDownloading: true})
        let filename = this.props.artistName + "_" + this.props.trackTitle + "_Full.wav"

        api.adminDownloadFullVersion(versionId)
            .then(res=>{


                if(res.data?.url){

                    let downloadEl = document.createElement("a")
                    downloadEl.setAttribute("href", res.data.url)
                    downloadEl.setAttribute("download", res.data.filename.split(" ").join(""))
                    downloadEl.style.display = 'none'
                    document.body.appendChild(downloadEl);
                    downloadEl.click();

                    this.setState({isDownloading: false})
                }

            })
            .catch(err=>{

                this.setState({isDownloading: false})
            })
    }
    downloadStem=(stemId)=>{

        this.setState({isDownloading: true})
        let filename = this.props.artistName + "_" + this.props.trackTitle + "_Full.wav"

        api.adminDownloadStem(stemId)
            .then(res=>{


                if(res.data?.url){

                    let downloadEl = document.createElement("a")
                    downloadEl.setAttribute("href", res.data.url)
                    downloadEl.setAttribute("download", res.data.filename.split(" ").join(""))
                    downloadEl.style.display = 'none'
                    document.body.appendChild(downloadEl);
                    downloadEl.click();

                    this.setState({isDownloading: false})
                }

            })
            .catch(err=>{

                this.setState({isDownloading: false})
            })
    }
    render () {
        return(
            <UserConsumer>
            {({ user, login, logout})=>(
            <div className="pt-50 lg:pt-0">
                {!this.state.pageLoaded?
                <div style={{height: "100vh"}}>
                    <div style={{margin: "25% auto", width: "50%"}}>
                        <CircularIndeterminant size={40} forceCenter={true}/>
                    </div>
                </div>:
                <>
                <div className="container">
                    {user.admin ?
                <MicroModal
                                        open={this.state.isAlbumModalOpen}
                                        closeOnEscapePress={true}
                                        handleClose={()=>this.handleAlbumModalClose()}
                                        closeOnOverlayClick={true}
                                        closeOnAnimationEnd={true}
                                        modalOverlayClassName="modal-overlay"
                                        modalClassName="modal micromodal-slide is-open"
                                        modalOverlayStyles={null}
                                        children={handleClose=><AlbumModal loadingCB={this.albumLoadingCB} handleClose={handleClose} updateAlbumsCB={this.updateAlbumsCB} artistId={this.state.artistId}/>}
                                        containerStyles={{background: "#131313", padding:"asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box"}}
                                    ></MicroModal>:
                                    <MicroModal
                                    open={this.state.isAlbumModalOpen}
                                    closeOnEscapePress={true}
                                    handleClose={()=>this.handleAlbumModalClose()}
                                    closeOnOverlayClick={true}
                                    closeOnAnimationEnd={true}
                                    modalOverlayClassName="modal-overlay"
                                    modalClassName="modal micromodal-slide is-open"
                                    modalOverlayStyles={null}
                                    children={handleClose=><AlbumModal loadingCB={this.albumLoadingCB} handleClose={handleClose} updateAlbumsCB={this.updateAlbumsCB} />}
                                    containerStyles={{background: "#131313", padding:"asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box"}}
                                ></MicroModal>
                    }
                    {/* {# App Header #} */}
                    <div className="view-header">
                        <h1 id="tracksource" className="view-title">Resubmit Track</h1>
                        <div className="view-header-controls">
                            <button type="button" onClick={(event)=>{event.preventDefault();this.handleFormSubmit(false)}} className="btn btn-primary">Save and Quit</button>
                            <button type="button" disabled={!this.state.submittable} onClick={(event)=>{event.preventDefault();this.handleFormSubmit(true)}} className={`btn btn-stroke ${!this.state.submittable && 'btn-disabled'}`}>Submit for Approval</button>
                            <Tooltip
                                useContext={true}
                                arrow={false}
                                visible={this.state.showTippy}
                                interactive={true}
                                position={'bottom-end'}
                                trigger={'click'}
                                html={(<NewTrackToolTip
                                        className="hidden"
                                        delete={this.deleteTrack}>
                                    </NewTrackToolTip>
                                )}
                                allowHTML={true}
                                theme={'dark'}
                                onClickOutside={()=>{this.state.showTippy ? this.setState({showTippy: false}): this.setState({showTippy: true})}}
                            >
                            <button type="button" className="icon-btn js-dropdown-toggle"
                                onClick={()=>{this.state.showTippy ? this.setState({showTippy: false}): this.setState({showTippy: true})}}
                                >
                                <span className="icon-dots-vert"></span>
                            </button>

                            </Tooltip>
                        </div>
                    </div>
                    </div>

                    <div className="container">
                    <div className="form-section-sm">
                        {this.state.trackQuality === "reject" ?
                        <div className={`input-fieldset has-error`}>
                            {/* {# File #} */}
                            <div className="upload-item">
                                <div className="upload-item-details">
                                    <div className="upload-item-title">{this.state.localname}</div>
                                </div>
                                <div className="upload-item-controls">

                                    <input id={'trackFile'} type="file" accept={"audio/wav,audio/x-wav"} style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.reuploadTrack(e)} />
                                    <label htmlFor={'trackFile'} className="btn btn-stroke btn-sm">
                                        <span className="icon-plus mr-5"></span>
                                            Re-Upload Track
                                    </label>
                                    <button type="button" onClick={this.downloadFullTrack} class="circle-icon circle-icon-sm"><span class="icon-download"></span></button>

                                </div>
                            </div>
                            <hr className="divider-sm"/>
                            {/* {# Track Preview #} */}
                            <div  className="track-preview track-preview-sm track-preview-sm">
                                <div className="track-preview-play">
                                    {this.state.trackFileLoading ?
                                        <CircularDeterminantLabel thickness={2.5} progress={this.state.trackFileProgress || 0} size={"2rem"}/>
                                                                  :
                                    <button onClick={(event)=>{this.changeNowPlaying(event,this.state.wavURL)}} className="circle-icon-border circle-icon-lg">{this.state.nowPlaying === this.state.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>}
                                </div>
                                {this.state.trackFileLoading ? <></>:<>
                                <div className="track-preview-waveform">
                                    {this.state.wavURL ?
                                        <TrackWaveform unsetNowPlaying={this.unsetNowPlaying} nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={this.state.wavURL} peaks={this.state.peaks}></TrackWaveform>:
                                        <></>
                                    }
                                </div>
                                <div className="track-preview-duration">
                                    {this.state.duration}
                                </div>
                                </>}
                            </div>
                            <hr className="divider-sm"/>
                            {/* {# Error Message #} */}
                            <div className="error-message">{this.generateRejectionText(this.state.virusDetected ? "virus_detected" : this.state.mainRejectionReason, this.state.localname)}</div>
                        </div>:
                        <div className="input-fieldset">
                            {/* {# File #} */}
                            <div className="upload-item">
                                <div className="upload-item-details">
                                    <div className="upload-item-title">{this.state.localname}</div>
                                </div>
                                <div className="upload-item-controls">
                                </div>
                            </div>
                            <hr className="divider-sm"/>
                            {/* {# Track Preview #} */}
                            <div  className="track-preview track-preview-sm track-preview-sm">
                                <div className="track-preview-play">
                                    <button onClick={(event)=>{this.changeNowPlaying(event,this.state.wavURL)}} className="circle-icon-border circle-icon-lg">{this.state.nowPlaying === this.state.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                </div>
                                <div className="track-preview-waveform">
                                {this.state.wavURL ?
                                    <TrackWaveform unsetNowPlaying={this.unsetNowPlaying} nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={this.state.wavURL} peaks={this.state.peaks}></TrackWaveform>:
                                    <></>
                                }
                                </div>
                                <div className="track-preview-duration">
                                    {this.state.duration}
                                </div>
                            </div>
                            <hr className="divider-sm"/>
                            {/* {# Error Message #} */}
                            {/* <div>File upload successful. Ready for re-submission.</div> */}
                        </div>}
                    </div>
                    {/* {# App Main Inner #} */}
                    <div className="app-sublayout">

                        {/* {# App Main Content #} */}
                        <div className="app-sublayout-content mb-50 lg:mb-80">

                            {/* {# Form #} */}
                            <form action="" className="ds-form">
                            {/* {# Error #} */}
                            {this.state.rejectionReasons ?
                            <div className="form-section" id="viability">
                                 <div className={`input-fieldset mb-20 ${(!this.state.trackFile || !this.state.stemsErrorFree || !this.state.versionsErrorFree ) && 'has-error'}`}>
                                    <h3 className={`text-default font-medium mb-5 ${(!this.state.trackFile || !this.state.stemsErrorFree || !this.state.versionsErrorFree ) && 'text-primary'}`}>Rejection Comment</h3>
                                    <p className="mb-0">{this.state.rejectionComment && this.state.rejectionComment}</p>
                                    <br></br>
                                    <div className="mb-0">
                                        {(!this.state.trackFile || !this.state.stemsErrorFree || !this.state.versionsErrorFree ) ?
                                            <p>
                                                <span className='icon-reject'></span>&nbsp;
                                                {` Fix Track Errors before you can resubmit.`}
                                            </p>
                                        :
                                            <p>
                                                <span className='icon-check-active'></span>
                                                {` Errors appear to have been fixed. Please resubmit.`}
                                            </p>
                                        }
                                    </div>

                                </div>
                                <h2 className="text-label-lg mb-40">Viability Checklist</h2>

                                {/* {# Error #} */}
                                {this.state.rejectionReasons.content === "reject" ?
                                    <div className="input-fieldset mb-20 has-error">
                                        <h3 className="text-default font-medium text-primary mb-5">Content</h3>
                                        <p className="mb-0">The track does not contain any inappropriate content.</p>
                                    </div>:
                                    <div className="input-fieldset mb-20">
                                        <h3 className="text-default font-medium mb-5">Content</h3>
                                        <p className="mb-0">The track does not contain any inappropriate content.</p>
                                    </div>
                                }

                                {/* {# Error #} */}
                                {this.state.rejectionReasons.curation === "reject" ?
                                    <div className="input-fieldset mb-20 has-error">
                                        <h3 className="text-default font-medium text-primary mb-5">Curation</h3>
                                        <p className="mb-0">The track fits within Deep Sounds' curation standards.</p>
                                    </div>:
                                    <div className="input-fieldset mb-20">
                                        <h3 className="text-default font-medium mb-5">Curation</h3>
                                        <p className="mb-0">The track fits within Deep Sounds' curation standards.</p>
                                    </div>
                                }

                                {/* {# Error #} */}
                                {this.state.rejectionReasons.exclusivity === "reject" ?
                                    <div className="input-fieldset has-error">
                                        <h3 className="text-default font-medium text-primary mb-5">Exclusivity</h3>
                                        <p className="mb-0">The track is not available for sync licensing on any other music library.</p>
                                    </div>:
                                    <div className="input-fieldset">
                                        <h3 className="text-default font-medium mb-5">Exclusivity</h3>
                                        <p className="mb-0">The track is not available for sync licensing on any other music library.</p>
                                    </div>
                                }
                            </div>:
                                <></>
                            }

                                {/* {# Track Details #} */}
                                {this.state.title &&
                                <>
                                {this.state.trackStatus !== "fullRejected"  &&
                                <>
                                <div className="form-section">
                                    <h2 className="text-label-lg mb-40" id="details">Track Details</h2>

                                    <div className="input-group">
                                        <label className="input-label" htmlFor="track_title">
                                            Track Title
                                            <span class="input-label-required">*</span>
                                        </label>
                                        {user.admin || user.manager ?
                                            <input
                                                id="track_title"
                                                className="form-input"
                                                value={this.state.title}
                                                onChange={this.handleInputChange}
                                                type="text"
                                                name="title"
                                                placeholder="Enter track title here..."/>
                                                :
                                                <input
                                                id="track_title"
                                                className="form-input"
                                                value={this.state.title}
                                                disabled
                                                type="text"
                                                name="title"
                                                placeholder="Enter track title here..."/>
                                        }
                                    </div>

                                    {/* {# Album Select #} */}
                                    <div className="form-section" id="album_select">
                                      <div className="input-group">
                                        <label htmlFor="album" className="input-label">
                                          Link to Album
                                          <span class="input-label-required">*</span>
                                          <span className="input-label-info">Choose an Album</span>
                                        </label>
                                        <div className="grid-row-xs">
                                          <div className="col flex-1">
                                              {!(user.admin || user.manager) ?
                                                  <Select
                                                      isDisabled
                                                      name="album"
                                                      className="select-container"
                                                      classNamePrefix="select"
                                                      styles={customStyles}
                                                      placeholder="Select an Album"
                                                      value={this.state.album}
                                                      options={this.state.myAlbums}
                                                      onChange={this.handleAlbumChange}
                                                      formatOptionLabel={formatAlbumLabel}
                                            />
                                            :
                                                  <Select
                                                      name="album"
                                                      className="select-container"
                                                      classNamePrefix="select"
                                                      styles={customStyles}
                                                      placeholder="Select an Album"
                                                      value={this.state.album}
                                                      options={this.state.myAlbums}
                                                      onChange={this.handleAlbumChange}
                                                      formatOptionLabel={formatAlbumLabel}
                                                  />
                                              }
                                          </div>
                                          <div className="col w-auto flex-initial">
                                              <button type="button"
                                                onClick={(e)=>{e.preventDefault();this.openAlbumModal()}}
                                                className="btn btn-stroke form-btn w-full"
                                              >
                                                <span className="icon-plus mr-5"></span>
                                                Create
                                              </button>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                </div>

                                {/* {# Tags #} */}
                                <div className="form-section" id="tags">
                                    <h2 className="text-label-lg mb-40">Tags</h2>

                                    {/* {# Genres #} */}
                                    <div className="input-group">
                                        <label htmlFor="genres" className="input-label">
                                            What Genres best describes this track? <span className="input-label-info">Choose up to 2</span>
                                            <span class="input-label-required">*</span>
                                        </label>
                                        {!(user.admin || user.manager) ?
                                        <Select
                                            name="genres"
                                            styles={customStylesMulti}
                                            placeholder="Search for Genres..."
                                            value={this.state.genres}
                                            options={this.state.genreOptions}
                                            isDisabled
                                            isMulti
                                            isSearchable
                                        />:
                                        <Select
                                            name="genres"
                                            styles={customStylesMulti}
                                            placeholder="Search for Genres..."
                                            value={this.state.genres}
                                            options={this.state.genreOptions}
                                            onChange={this.handleGenreChange}
                                            isMulti
                                            isSearchable
                                        />
                                        }
                                    </div>

                                    {/* {# Emotions #} */}
                                    <div className="input-group">
                                        <label htmlFor="emotions" className="input-label">
                                            What Primary Emotion does this track evoke?
                                            <span class="input-label-required">*</span>
                                            <span className="input-label-info">Choose 1</span>
                                        </label>
                                        {!(user.admin || user.manager) ?
                                         <Select
                                         name="emotions"
                                         styles={customStylesMulti}
                                         placeholder="Search for an Emotion..."
                                         value={this.state.emotions}
                                         options={this.state.emotionOptions}
                                         isDisabled
                                         isMulti
                                         isSearchable
                                     />:
                                        <Select
                                            name="emotions"
                                            styles={customStylesMulti}
                                            placeholder="Search for an Emotion..."
                                            value={this.state.emotions}
                                            options={this.state.emotionOptions}
                                            onChange={this.handleEmotionChange}
                                            isMulti
                                            isSearchable
                                        />
                                    }
                                    </div>
                                    {/* {# Moods #} */}
                                    <div className="input-group">
                                        <label htmlFor="moods" className="input-label">
                                            What Moods best match this track?
                                            <span class="input-label-required">*</span>
                                            <span className="input-label-info">Choose up to 4</span>
                                        </label>
                                        {!(user.admin || user.manager) ?
                                         <Select
                                         name="moods"
                                         styles={customStylesMulti}
                                         placeholder="Search for Moods..."
                                         value={this.state.moods}
                                         options={this.state.moodOptions}
                                         isDisabled
                                         isMulti
                                         isSearchable
                                     />:
                                        <Select
                                            name="moods"
                                            styles={customStylesMulti}
                                            placeholder="Search for Moods..."
                                            value={this.state.moods}
                                            options={this.state.moodOptions}
                                            onChange={this.handleMoodChange}
                                            isMulti
                                            isSearchable
                                        />
                                        }
                                    </div>

                                    {/* {# Attributes #} */}
                                    <div className="input-group">
                                        <label htmlFor="attributes" className="input-label">
                                            What Attributes best match this track?
                                            <span class="input-label-required">*</span>
                                            <span className="input-label-info">Choose up to 8</span>
                                        </label>
                                        {!(user.admin || user.manager) ?
                                        <Select
                                        name="attributes"
                                        styles={customStylesMulti}
                                        placeholder="Search for Attributes..."
                                        value={this.state.attributes}
                                        options={this.state.attributeOptions}
                                        isDisabled
                                        isMulti
                                        isSearchable
                                    />:
                                        <Select
                                            name="attributes"
                                            styles={customStylesMulti}
                                            placeholder="Search for Attributes..."
                                            value={this.state.attributes}
                                            options={this.state.attributeOptions}
                                            onChange={this.handleAttributeChange}
                                            isMulti
                                            isSearchable
                                        />
    }
                                    </div>


                                    {/* {# Instruments #} */}
                                    <div className="input-group">
                                        <label htmlFor="instruments" className="input-label">
                                            What Instruments are featured on this track?
                                            <span class="input-label-required">*</span>
                                            <span className="input-label-info">Choose as many as apply</span>
                                        </label>
                                        {!(user.admin || user.manager) ?
                                         <Select
                                         name="instruments"
                                         styles={customStylesMulti}
                                         placeholder="Search for Instruments..."
                                         value={this.state.instruments}
                                         options={this.state.instrumentOptions}
                                         isDisabled
                                         isMulti
                                         isSearchable
                                     />:
                                        <Select
                                            name="instruments"
                                            styles={customStylesMulti}
                                            placeholder="Search for Instruments..."
                                            value={this.state.instruments}
                                            options={this.state.instrumentOptions}
                                            onChange={this.handleInstrumentsChange}
                                            isMulti
                                            isSearchable
                                        />
    }
                                    </div>
                                <KeywordsInput
                                    keywords={this.state.keywords}
                                    customStylesMulti={customStylesMulti}
                                    handleKeywordChange={this.handleKeywordChange}
                                    allOptions={this.state.allOptions}
                                    isDisabled={!(user.admin || user.manager)}
                                />
                                {/* {# Lyrics #} */}
                                <div className="input-group">
                                    <p className="input-label">
                                        Does this track contain lyrics?
                                        <span class="input-label-required">*</span>
                                    </p>
                                    {/* {# Fieldset #} */}
                                    <fieldset className="input-fieldset">
                                        <div className="grid-row-sm">
                                            {!(user.admin || user.manager) ?
                                            <>
                                            <div className="col w-1/2">
                                                <label className="radio-option">
                                                    <input disabled type="radio" className="form-radio" name="containsLyrics" value="false" checked={this.state.containsLyrics === "false"}/>
                                                    No
                                                </label>
                                            </div>
                                            <div className="col w-1/2">
                                                <label className="radio-option">
                                                    <input disabled type="radio" className="form-radio" name="containsLyrics" value="true" checked={this.state.containsLyrics === "true"}/>
                                                    Yes
                                                </label>
                                            </div>
                                            </>:
                                            <>
                                            <div className="col w-1/2">
                                                <label className="radio-option">
                                                    <input onChange={this.handleInputChange} type="radio" className="form-radio" name="containsLyrics" value="false" checked={this.state.containsLyrics === "false"}/>
                                                    No
                                                </label>
                                            </div>
                                            <div className="col w-1/2">
                                                <label className="radio-option">
                                                    <input onChange={this.handleInputChange} type="radio" className="form-radio" name="containsLyrics" value="true" checked={this.state.containsLyrics === "true"}/>
                                                    Yes
                                                </label>
                                            </div>
                                            </>
    }
                                        </div>
                                        {/* {# Conditional Fields #} */}
                                        {this.state.containsLyrics ==="true" ?
                                        <div className="input-fieldset-cond is-visible">
                                            <hr className="divider-sm"/>
                                            <p className="input-label">
                                                Does this track contain explicit lyrics?
                                                <span class="input-label-required">*</span>
                                            </p>
                                            {!(user.admin || user.manager) ?
                                             <div className="grid-row-sm">
                                             <div className="col w-1/2">
                                                 <label className="radio-option">
                                                     <input disabled type="radio" className="form-radio" name="explicitLyrics" value="false" checked={this.state.explicitLyrics === "false"}/>
                                                     No
                                                 </label>
                                             </div>
                                             <div className="col w-1/2">
                                                 <label className="radio-option">
                                                     <input disabled type="radio" className="form-radio" name="explicitLyrics" value="true" checked={this.state.explicitLyrics === "true"}/>
                                                     Yes
                                                 </label>
                                             </div>
                                         </div>:
                                            <div className="grid-row-sm">
                                                <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input onChange={this.handleInputChange} type="radio" className="form-radio" name="explicitLyrics" value="false" checked={this.state.explicitLyrics === "false"}/>
                                                        No
                                                    </label>
                                                </div>
                                                <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input onChange={this.handleInputChange} type="radio" className="form-radio" name="explicitLyrics" value="true" checked={this.state.explicitLyrics === "true"}/>
                                                        Yes
                                                    </label>
                                                </div>
                                            </div>
    }
                                        </div>: <></>}
                                    </fieldset>
                                </div>
                                <div className="input-group">
                                    <p className="input-label">
                                        What BPM accurately describes this track?
                                        <span class="input-label-required">*</span>
                                    </p>
                                    <div className="grid-row-xs">
                                      {this.state.bpmOptions?.map(bpm=>(
                                          <div className="col w-full md:w-1/2 mb-20">
                                               {!(user.admin || user.manager) ?
                                               <label className="radio-option radio-btn">

                                               <input
                                                   disabled
                                                   type="radio"
                                                   className="form-radio"
                                                   name="trackBPM"
                                                   value={bpm.value}
                                                   checked={parseInt(this.state.trackBPM) === parseInt(bpm.value)}
                                               />
                                                   {bpm.label}
                                           </label>:
                                          <label className="radio-option radio-btn">

                                              <input
                                                  onChange={this.handleInputChange}
                                                  type="radio"
                                                  className="form-radio"
                                                  name="trackBPM"
                                                  value={bpm.value}
                                                  checked={parseInt(this.state.trackBPM) === parseInt(bpm.value)}
                                              />
                                                  {bpm.label}
                                          </label>
                                        }
                                      </div>
                                      ))}
                                    </div>
                                </div>
                                {/* {# City #} */}
                                <div className="input-group">
                                <label htmlFor="city" className="input-label">
                                    In which City was this track made?
                                    <span class="input-label-required">*</span>
                                    <span className="input-label-info">Choose one</span>
                                </label>
                                <p>We recommend using the closest major city for best results.</p>
                                {!(user.admin || user.manager) ?
                                    <AsyncSelect
                                        key={"citSelect"+this.state.city?.label}
                                        name="city"
                                        styles={customStylesCity}
                                        cacheOptions
                                        // defaultOptions={this.state.city ? [{label: this.state.city.label, value: this.state.city.value }]:[]}
                                        // defaultInputValue={this.state.city?.label}
                                        inputValue={this.state.cityInput}
                                        loadOptions={this.getCities}
                                        onInputChange={this.changeCityInput}
                                        onKeyDown={this.cityKeyDown}
                                        onFocus={()=>{
                                            if(this.state.city?.label && !this.state.cityInput){
                                                this.changeCityInput(this.state.city?.label)
                                            }
                                        }}
                                        onClick={this.altChangeCityInput}
                                        placeholder="Type to add a City..."
                                        value={this.state.city}
                                        // options={this.state.cityOptions}
                                        noOptionsMessage={() => "Options will be populated as you type"}
                                        isDisabled
                                        // isSearchable
                                    />:
                                    <AsyncSelect
                                            key={"citSelect"+this.state.city?.label}
                                            name="city"
                                            styles={customStylesCity}
                                            cacheOptions
                                            // defaultOptions={this.state.city ? [{label: this.state.city.label, value: this.state.city.value }]:[]}
                                            // defaultInputValue={this.state.city?.label}
                                            inputValue={this.state.cityInput}
                                            loadOptions={this.getCities}
                                            onInputChange={this.changeCityInput}
                                            onKeyDown={this.cityKeyDown}
                                            onFocus={()=>{
                                                if(this.state.city?.label && !this.state.cityInput){
                                                    this.changeCityInput(this.state.city?.label)
                                                }
                                            }}
                                            onClick={this.altChangeCityInput}
                                            placeholder="Type to add a City..."
                                            value={this.state.city}
                                            // options={this.state.cityOptions}
                                            noOptionsMessage={() => "Options will be populated as you type"}

                                            onChange={this.handleCityChange}
                                            // isSearchable
                                        />
    }
                                    {/* <label className="input-label" htmlFor="city_select">Which city best represents this song?</label>
                                    <select value={this.state.city} name={"city"} onChange={this.handleCityChange} id="city_select" className="form-select js-form-select" data-placeholder="Select a City">
                                        {/* {% for item in city %} */}
                                            {/* <option value="chicago">Chicago</option>
                                            <option value="brooklyn">Brooklyn</option>
                                            <option value="new york">New York</option>
                                            <option value="austin">Austin</option>
                                            <option value="nashville">Nashville</option>
                                            <option value="uk">UK</option> */}
                                        {/* {% endfor %} */}
                                    {/* </select> */}
                                </div>
                                </div>
                                {/* {# Fun Fact #} */}
                                <div className="form-section" id="fun_fact">
                                    <h2 className="text-label-lg mb-40">Fun Fact</h2>
                                    {!(user.admin || user.manager) ?
                                        <div className="input-group">
                                        <label className="input-label" htmlFor="track_fun_fact">
                                            Tell us about your track (optional).
                                        </label>
                                        <textarea
                                            name="funFact"
                                            value={this.state.funFact}
                                            disabled
                                            id="track_fun_fact"
                                            className="form-textarea"
                                            rows="6"
                                            placeholder="Do you have any interesting facts about this track? What inspired you to make it? This info will be listed in the About section of the track and can help customers make a connection to your track!">
                                        </textarea>
                                        <CharacterCount
                                            limit="400"
                                            value={this.state.funFact?.length}
                                        />
                                        </div>:
                                    <div className="input-group">
                                        <label className="input-label" htmlFor="track_fun_fact">Tell us about your track</label>
                                        <textarea
                                          name="funFact"
                                          value={this.state.funFact}
                                          onChange={this.handleInputChange}
                                          id="track_fun_fact"
                                          className="form-textarea"
                                          rows="6"
                                          placeholder="Do you have any interesting facts about this track? What inspired you to make it? This info will be listed in the About section of the track and can help customers make a connection to your track!">
                                        </textarea>
                                        <CharacterCount
                                            limit="400"
                                            value={this.state.funFact?.length}
                                        />
                                    </div>
                                    }
                                </div>


                                    <div className="form-section" id="versions">
                                <h2 className="text-label-lg mb-40">Versions</h2>
                                <div className="input-group">
                                    <p className="input-label mb-10">Would you like to add alternate versions of this track?</p>
                                    <p className="mb-20">Alternate versions make your track more attractive to potential customers.</p>

                                    <fieldset className="input-fieldset">
                                        <div className="grid-row-sm">
                                        <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input onChange={this.handleInputChange} type="radio" className="form-radio" name="versionsAvailable" value="false" checked={this.state.versionsAvailable === "false"}/>
                                                        No
                                                    </label>
                                                </div>
                                                <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input onChange={this.handleInputChange} type="radio" className="form-radio" name="versionsAvailable" value="true" checked={this.state.versionsAvailable === "true"}/>
                                                        Yes
                                                    </label>
                                                </div>

                                        </div>
                                        {this.state.versionsAvailable === "true" ?
                                        <div className="input-fieldset-cond is-visible">
                                            <hr className="divider-sm"/>
                                            <p className="input-label mb-10">
                                                Upload Versions
                                                <span class="input-label-required">*</span>
                                            </p>
                                            <p className="mb-20">48 kHz &amp; 16-bit stereo uncompressed WAV files only accepted. <TechnicalRequirementsLearnMore red/>.</p>

                                                        {this.state.additionalVersions.map((version, index)=>(
                                                            <div className="input-group-sm">
                                                            {version.wavURL  || version.loading || version.error?
                                                            <>
                                                            {!version.error ?
                                                                <div className="upload-item">
                                                                    <div className="upload-item-pre">
                                                                        {/* {# Replace div with react loader, swap with play button once uploaded #} */}
                                                                        {version.loading ?
                                                                            <CircularDeterminantLabel thickness={2.5} progress={version.progress || 0} size={"2rem"}/>:
                                                                            <button type="button" onClick={(event)=>{this.changeNowPlaying(event, version.wavURL)}} className="circle-icon-border circle-icon-sm">{this.state.nowPlaying === version.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                                                        }

                                                                    </div>
                                                                    <div className="upload-item-details">
                                                                        <span className="upload-item-title">{version.label}</span>
                                                                        <div className="upload-item-file">{version.localname}</div>
                                                                    </div>

                                                                    <div className="upload-item-wave">
                                                                        {version.wavURL &&
                                                                            <Waveform unsetNowPlaying={this.unsetNowPlaying} nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={version.wavURL} peaks={version.peaks}/>
                                                                        }
                                                                        {/* {% include "components/audio/waveform-sm.html" %} */}
                                                                        <span className="upload-item-duration">{version.duration}</span>
                                                                    </div>
                                                                    <div className="upload-item-controls">
                                                                        {/* <button type="button" className="circle-icon circle-icon-sm"><span className="icon-download"></span></button> */}

                                                                        {this.state.trackStatus === "pending" ?
                                                                            <button type="button" disabled onClick={(event)=>{event.preventDefault();}}  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>:
                                                                            <button type="button" onClick={(event)=>{event.preventDefault();this.removeVersion(version.id)}}  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                                                        }
                                                                                                                                                      <button type="button" onClick={()=>this.downloadVersion(version.id)} class="circle-icon circle-icon-sm"><span class="icon-download"></span></button>

                                                                    </div>
                                                                </div>:
                                                                <>
                                                                    <div className="upload-item has-error">
                                                                        <div className="upload-item-pre">
                                                                            <button type="button" className="circle-icon-border circle-icon-sm"><span className="icon-play"></span></button>
                                                                        </div>
                                                                        <div className="upload-item-details">
                                                                        <span className="upload-item-title">{version.label}</span>
                                                                        <div className="upload-item-file">{version.localname}</div>
                                                                        </div>
                                                                        <div className="upload-item-wave">
                                                                            {/* {% include "components/audio/waveform-sm.html" %} */}
                                                                            <span className="upload-item-duration">{version.duration}</span>
                                                                        </div>
                                                                        <div className="col btn-group">

                                                                            {this.state.trackStatus === "pending" ?
                                                                                <input disabled id={'versionFile' + index} type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) =>e.preventDefault()} />:
                                                                                <input id={'versionFile' + index} type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.versionsReupload(version.label, version.id, e)} />
                                                                            }
                                                                            <label htmlFor={'versionFile' + index} className="btn btn-stroke btn-sm">Reupload</label>
                                                                            <button type="button" type="button" onClick={(event)=>{event.preventDefault();this.removeVersionFromState(index)}} className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                                                            <button type="button" onClick={()=>this.downloadVersion(version.id)} class="circle-icon circle-icon-sm"><span class="icon-download"></span></button>

                                                                        </div>
                                                                    </div>
                                                                    {/* {# Error Message #} */}
                                                                    <div className="error-message is-file">{version.reason}</div>
                                                                </>
                                                            }
                                                                {/* <div>{version.label}</div>
                                                                <br></br> */}
                                                            </>:
                                                                <div className="grid-row-xs">
                                                                    <div className="col w-auto flex-1" key={index}>
                                                                        {this.state.trackStatus === "pending" ?
                                                                            <Select
                                                                                isDisabled
                                                                                name={"versionName" + index}
                                                                                placeholder="Version Name..."
                                                                                styles={customStyles}
                                                                                value={this.state.additionalVersions[index].name}
                                                                                options={versionNameOptions.filter(version => {
                                                                                    for (let i = 0; i < this.state.additionalVersions.length; i++) {
                                                                                        if (this.state.additionalVersions[i].value === version.value || this.state.additionalVersions[i]?.label?.toLowerCase() === version?.value?.toLowerCase()) {
                                                                                            return false
                                                                                        }
                                                                                    }
                                                                                    return true
                                                                                })
                                                                                }
                                                                                onChange={(e) => this.handleVersionNameChange(index, e)}
                                                                            />:
                                                                            <Select
                                                                                name={"versionName" + index}
                                                                                placeholder="Version Name..."
                                                                                styles={customStyles}
                                                                                value={{label: this.state.additionalVersions[index].label, value: this.state.additionalVersions[index].value}}
                                                                                options={versionNameOptions.filter(version => {
                                                                                    for (let i = 0; i < this.state.additionalVersions.length; i++) {
                                                                                        if (this.state.additionalVersions[i].value === version.value || this.state.additionalVersions[i]?.label?.toLowerCase() === version?.value?.toLowerCase()) {
                                                                                            return false
                                                                                        }
                                                                                    }
                                                                                    return true
                                                                                })
                                                                                }
                                                                                onChange={(e) => this.handleVersionNameChange(index, e)}
                                                                            />
                                                                        }
                                                                    </div>
                                                                    <div className="col btn-group">
                                                                        <input id={'versionFile' + index} type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.versionsFileHandler(version.label, e)} />
                                                                        <label htmlFor={'versionFile' + index} className="btn btn-stroke form-btn">Upload Version</label>
                                                                        <button type="button" onClick={(event)=>{event.preventDefault();this.removeVersionFromState(index)}} className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                                                    </div>
                                                                </div>
                                                            }
                                                            </div>
                                                        ))}
                                                    {/* </div> */}

                                                {/* </div> */}

                                                {/* </div> */}
                                            <hr className="divider"></hr>

                                            <div className="input-group ">
                                                {this.state.trackStatus === "pending" ?
                                                    <button type="button" disabled className="btn btn-disabled btn-stroke form-btn w-full"><span className="icon-plus mr-5"></span>Add a Version</button>:
                                                    <button type="button" onClick={this.addNewVersionDiv} className="btn btn-stroke form-btn w-full"><span className="icon-plus mr-5"></span>Add a Version</button>
                                                }
                                            </div>

                                        </div>: <></>}
                                    </fieldset>

                                </div>
                            </div>
                            {/* {# Stems #} */}
                            <div className="form-section" id="stems">
                                <h2 className="text-label-lg mb-40">Stems</h2>
                                <div className="input-group">
                                    <p className="input-label mb-10">Would you like to add individual stems for this track?</p>
                                    <p className="mb-20">Stems make your track more attractive to higher-end productions.</p>

                                    <div className="input-fieldset">
                                        <div className="grid-row-sm">
                                        <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input onChange={this.handleInputChange} type="radio" className="form-radio" name="stemsAvailable" value="false" checked={this.state.stemsAvailable === "false"}/>
                                                        No
                                                    </label>
                                                </div>
                                                <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input onChange={this.handleInputChange} type="radio" className="form-radio" name="stemsAvailable" value="true" checked={this.state.stemsAvailable === "true"}/>
                                                        Yes
                                                    </label>
                                                </div>
                                        </div>
                                        {/* {# Conditional Fields #} */}
                                        {this.state.stemsAvailable === "true" ?
                                        <div className="input-fieldset-cond is-visible">
                                            <hr className="divider-sm"/>
                                            <p className="input-label mb-10">
                                                Upload Stems
                                                <span class="input-label-required">*</span>
                                            </p>
                                            <p className="mb-10">The instruments below are populated based on the instruments featured in the track that you provided earlier.  You can add multiple stems based on the same instrument (Example: Select "Strings" twice when uploading stems for short strings and another stem for long strings.)</p>
                                            <p className="mb-10">48 kHz &amp; 16-bit stereo uncompressed WAV files only accepted. <TechnicalRequirementsLearnMore red/>.</p>
                                            <p className="text-gray-400 text-sm mb-20">Please make sure all stems are the exact same length to the millisecond or your stem will automatically be rejected.</p>

                                {this.state.completeCustomStems.map((stem, index) =>(

                                                <div key={"completeCustomStem " +index} className="input-group-sm">
                                                    {!stem.error ?
                                                        <div className="upload-item">
                                                            <div className="upload-item-pre">
                                                                {/* {# Replace div with react loader, swap with play button once uploaded #} */}
                                                                {stem.loading ?
                                                                    <CircularDeterminantLabel thickness={2.5} progress={stem.progress || 0} size={"2rem"}/>:
                                                                    <button type="button" onClick={(event)=>{this.changeNowPlaying(event, stem.wavURL)}} className="circle-icon-border circle-icon-sm">{this.state.nowPlaying === stem.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                                                }

                                                            </div>
                                                            <div className="upload-item-details">
                                                                <span className="upload-item-title">{stem.name}</span>
                                                                <div className="upload-item-file">{stem.localname}</div>
                                                            </div>

                                                            <div className="upload-item-wave">
                                                                {stem.wavURL ?
                                                                <Waveform unsetNowPlaying={this.unsetNowPlaying} nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={stem.wavURL} peaks={stem.peaks}/>:
                                                                <></>
                                                                }
                                                                {/* {% include "components/audio/waveform-sm.html" %} */}
                                                                <span className="upload-item-duration">{stem.duration}</span>
                                                            </div>
                                                            <div className="upload-item-controls">

                                                                {/* <button type="button" className="circle-icon circle-icon-sm"><span className="icon-download"></span></button> */}
                                                                {this.state.trackStatus === "pending" ?
                                                                    <button type="button" disabled  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>:
                                                                    <button type="button" onClick={(event)=>{event.preventDefault();this.removeStem(stem.id)}}  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                                                }
                                                                                                                            <button type="button" onClick={()=>this.downloadStem(stem.id)} class="circle-icon circle-icon-sm"><span class="icon-download"></span></button>

                                                            </div>
                                                        </div>:
                                                        <>
                                                            <div className="upload-item has-error">
                                                                <div className="upload-item-pre">
                                                                    <button type="button" onClick={(event)=>{this.changeNowPlaying(event, stem.wavURL)}} className="circle-icon-border circle-icon-sm">{this.state.nowPlaying === stem.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                                                </div>
                                                                <div className="upload-item-details">
                                                                    <span className="upload-item-title">{stem.name}</span>
                                                                    <div className="upload-item-file">{stem.localname}</div>
                                                                </div>
                                                                <div className="upload-item-wave">
                                                                    <Waveform unsetNowPlaying={this.unsetNowPlaying} nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={stem.wavURL} peaks={stem.peaks}/>
                                                                    <span className="upload-item-duration">{stem.duration}</span>
                                                                </div>
                                                                <div className="upload-item-controls">
                                                                    <div className="upload-item-controls">

                                                                        <input id={`stemFile${stem.name}`} type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.stemsReupload(stem.name, stem.id, e, "custom", stem.uuid)} />
                                                                        <label htmlFor={`stemFile${stem.name}`} className="btn btn-stroke btn-sm">Reupload</label>
                                                                        <button type="button" onClick={()=>this.downloadStem(stem.id)} class="circle-icon circle-icon-sm"><span class="icon-download"></span></button>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                                {/* {# Error Message #} */}
                                                            <div className="error-message is-file">{stem.reason}</div>
                                                        </>
                                                    }

                                                </div>
                                                ))}
                                                                            {this.state.completeTaggedStems.sort(this.alphaSort).map((stem, index) =>(

                                                <div key={"completeTagStem " +index} className="input-group-sm">
                                                    {!stem.error ?
                                                        <div className="upload-item">
                                                            <div className="upload-item-pre">
                                                                {/* {# Replace div with react loader, swap with play button once uploaded #} */}
                                                                {stem.loading ?
                                                                    <CircularDeterminantLabel thickness={2.5} progress={stem.progress || 0} size={"2rem"}/>:
                                                                    <button type="button" onClick={(event)=>{this.changeNowPlaying(event, stem.wavURL)}} className="circle-icon-border circle-icon-sm">{this.state.nowPlaying === stem.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                                                }

                                                            </div>
                                                            <div className="upload-item-details">
                                                                <span className="upload-item-title">{stem.name}</span>
                                                                <div className="upload-item-file">{stem.localname}</div>
                                                            </div>

                                                            <div className="upload-item-wave">
                                                                {stem.wavURL ?
                                                                <Waveform unsetNowPlaying={this.unsetNowPlaying} nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={stem.wavURL} peaks={stem.peaks}/>:
                                                                <></>
                                                                }
                                                                {/* {% include "components/audio/waveform-sm.html" %} */}
                                                                <span className="upload-item-duration">{stem.duration}</span>
                                                            </div>
                                                            <div className="upload-item-controls">

                                                                {/* <button type="button" className="circle-icon circle-icon-sm"><span className="icon-download"></span></button> */}
                                                                {this.state.trackStatus === "pending" ?
                                                                    <button type="button" disabled  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>:
                                                                    <button type="button" onClick={(event)=>{event.preventDefault();this.removeStem(stem.id)}}  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                                                }
                                                                                                                            <button type="button" onClick={()=>this.downloadStem(stem.id)} class="circle-icon circle-icon-sm"><span class="icon-download"></span></button>

                                                            </div>
                                                        </div>:
                                                        <>
                                                            <div className="upload-item has-error">
                                                                <div className="upload-item-pre">
                                                                    <button type="button" onClick={(event)=>{this.changeNowPlaying(event, stem.wavURL)}} className="circle-icon-border circle-icon-sm">{this.state.nowPlaying === stem.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                                                </div>
                                                                <div className="upload-item-details">
                                                                    <span className="upload-item-title">{stem.name}</span>
                                                                    <div className="upload-item-file">{stem.localname}</div>
                                                                </div>
                                                                <div className="upload-item-wave">
                                                                    <Waveform unsetNowPlaying={this.unsetNowPlaying} nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={stem.wavURL} peaks={stem.peaks}/>
                                                                    <span className="upload-item-duration">{stem.duration}</span>
                                                                </div>
                                                                <div className="upload-item-controls">
                                                                    <div className="upload-item-controls">

                                                                        <input id={`stemFile${stem.name}`} type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.stemsReupload(stem.name, stem.id, e, "tag", stem.uuid)} />
                                                                        <label htmlFor={`stemFile${stem.name}`} className="btn btn-stroke btn-sm">Reupload</label>
                                                                    </div>
                                                                    <button type="button" onClick={()=>this.downloadStem(stem.id)} class="circle-icon circle-icon-sm"><span class="icon-download"></span></button>

                                                                </div>
                                                            </div>
                                                                {/* {# Error Message #} */}
                                                            <div className="error-message is-file">{stem.reason}</div>
                                                        </>
                                                    }

                                                </div>
                                                ))}
                                                                                            {this.state.incompleteTaggedStems.map((stem, index) =>(

                                                <div key={"incompleteTagStem " +stem.label} className="input-group-sm">
                                                {stem.wavURL  || stem.loading || stem.error?
                                                <>
                                                {!stem.error ?
                                                    <div className="upload-item">
                                                        <div className="upload-item-pre">
                                                            {/* {# Replace div with react loader, swap with play button once uploaded #} */}
                                                            {stem.loading ?
                                                                <CircularDeterminantLabel thickness={2.5} progress={stem.progress || 0} size={"2rem"}/>:
                                                                <button type="button" onClick={(event)=>{this.changeNowPlaying(event, stem.wavURL)}} className="circle-icon-border circle-icon-sm">{this.state.nowPlaying === stem.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                                            }

                                                        </div>
                                                        <div className="upload-item-details">
                                                            <span className="upload-item-title">{stem.name}</span>
                                                            <div className="upload-item-file">{stem.localname}</div>
                                                        </div>

                                                        <div className="upload-item-wave">
                                                            {stem.wavURL ?
                                                            <Waveform unsetNowPlaying={this.unsetNowPlaying} nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={stem.wavURL} peaks={stem.peaks}/>:
                                                            <></>
                                                            }
                                                            {/* {% include "components/audio/waveform-sm.html" %} */}
                                                            <span className="upload-item-duration">{stem.duration}</span>
                                                        </div>
                                                        <div className="upload-item-controls">
                                                            {/* <button type="button" className="circle-icon circle-icon-sm"><span className="icon-download"></span></button> */}
                                                            {this.state.trackStatus === "pending" ?
                                                                <button type="button" disabled  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>:
                                                                <button type="button" onClick={(event)=>{event.preventDefault();this.removeStem(stem.id)}}  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                                            }
                                                        </div>
                                                    </div>:
                                                    <>
                                                        <div className="upload-item has-error">
                                                            <div className="upload-item-pre">
                                                                <button type="button" className="circle-icon-border circle-icon-sm"><span className="icon-play"></span></button>
                                                            </div>
                                                            <div className="upload-item-details">
                                                            <span className="upload-item-title">{stem.label}</span>
                                                            <div className="upload-item-file">{stem.localname}</div>
                                                            </div>
                                                            <div className="upload-item-wave">
                                                                {/* {% include "components/audio/waveform-sm.html" %} */}
                                                                <span className="upload-item-duration">{stem.duration}</span>
                                                            </div>
                                                            <div className="upload-item-controls">
                                                            <div className="upload-item-controls">
                                                                {(stem.label?.length < 1 || stem.label === undefined )?
                                                                    <>
                                                                        <button type="button" id={`taggedStem${stem.name}`} type="button" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onClick={(e)=>{e.preventDefault();Error({message:"Enter stem name before uploading."})}}/>
                                                                        <label htmlFor={`taggedStem${stem.name}`} className="btn btn-stroke btn-sm btn-disbled">Reupload</label>
                                                                    </>:
                                                                    <>
                                                                        <input id={`taggedStem${stem.name}`} type="file" accept={"audio/wav,audio/x-wav"} style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.stemsFileHandler(stem.name, e, "tag", stem.uuid)} />
                                                                        <label htmlFor={`taggedStem${stem.name}`} className="btn btn-stroke btn-sm">Reupload</label>
                                                                    </>
                                                                }
                                                            </div>
                                                            </div>
                                                        </div>
                                                        {/* {# Error Message #} */}
                                                        <div className="error-message is-file">{stem.error}</div>
                                                    </>
                                                }

                                                </>:
                                                     <div className="grid-row-xs">
                                                     <div className="col w-auto flex-1" key={index}>
                                                            <input
                                                                className="form-input"
                                                                name={"newStemName"}
                                                                placeholder="Stem Name..."
                                                                isDisabled
                                                                value={stem.label}
                                                                // onChange={(e) => this.handleStemNameChange(e, index)}
                                                            />
                                                     </div>
                                                     <div className="col btn-group">
                                                         {(stem.label?.length < 1 || stem.label === undefined )?
                                                            <>
                                                                <button type="button" id={'taggedStem' + index} type="button"  style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onClick={(e)=>{e.preventDefault();Error({message:"Enter stem name before uploading."})}} />
                                                                <label htmlFor={'taggedStem' + index} className="btn btn-stroke form-btn btn-disabled">Upload Stem</label>
                                                            </>:
                                                            <>
                                                                <input id={'taggedStem' + index} type="file" accept={"audio/wav,audio/x-wav"} style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.stemsFileHandler(stem.label, e, "tag", stem.uuid)} />
                                                                <label htmlFor={'taggedStem' + index} className="btn btn-stroke form-btn">Upload Stem</label>
                                                            </>
                                                         }
                                                        <button type="button" type="button" disabled style={{opacity: 0}}  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>

                                                     </div>
                                                 </div>
                                                }
                                                </div>
                                ))}
                                {this.state.incompleteCustomStems.filter(stem=>!stem.hide).map((stem, index) =>(
                                                <div key={"incompleteCustomStem " +stem.forKey} className="input-group-sm">
                                                {stem.wavURL  || stem.loading || stem.error?
                                                <>
                                                {!stem.error ?
                                                    <div className="upload-item">
                                                        <div className="upload-item-pre">
                                                            {/* {# Replace div with react loader, swap with play button once uploaded #} */}
                                                            {stem.loading ?
                                                                <CircularDeterminantLabel thickness={2.5} progress={stem.progress || 0} size={"2rem"}/>:
                                                                <button type="button" onClick={(event)=>{this.changeNowPlaying(event, stem.wavURL)}} className="circle-icon-border circle-icon-sm">{this.state.nowPlaying === stem.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                                            }

                                                        </div>
                                                        <div className="upload-item-details">
                                                            <span className="upload-item-title">{stem.name}</span>
                                                            <div className="upload-item-file">{stem.localname}</div>
                                                        </div>

                                                        <div className="upload-item-wave">
                                                            {stem.wavURL ?
                                                            <Waveform unsetNowPlaying={this.unsetNowPlaying} nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={stem.wavURL} peaks={stem.peaks}/>:
                                                            <></>
                                                            }
                                                            {/* {% include "components/audio/waveform-sm.html" %} */}
                                                            <span className="upload-item-duration">{stem.duration}</span>
                                                        </div>
                                                        <div className="upload-item-controls">
                                                            {/* <button type="button" className="circle-icon circle-icon-sm"><span className="icon-download"></span></button> */}
                                                            {this.state.trackStatus === "pending" ?
                                                                <button type="button" disabled  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>:
                                                                <button type="button" onClick={(event)=>{event.preventDefault();this.removeStem(stem.id)}}  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                                            }
                                                        </div>
                                                    </div>:
                                                    <>
                                                        <div className="upload-item has-error">
                                                            <div className="upload-item-pre">
                                                                <button type="button" className="circle-icon-border circle-icon-sm"><span className="icon-play"></span></button>
                                                            </div>
                                                            <div className="upload-item-details">
                                                            <span className="upload-item-title">{stem.label}</span>
                                                            <div className="upload-item-file">{stem.localname}</div>
                                                            </div>
                                                            <div className="upload-item-wave">
                                                                {/* {% include "components/audio/waveform-sm.html" %} */}
                                                                <span className="upload-item-duration">{stem.duration}</span>
                                                            </div>
                                                            <div className="upload-item-controls">
                                                            <div className="upload-item-controls">
                                                                {(stem.label?.length < 1 || stem.label === undefined )?
                                                                    <>
                                                                        <button type="button" id={`customStem${stem.name}`} type="button"  style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onClick={(e)=>{e.preventDefault();Error({message:"Enter stem name before uploading."})}} />
                                                                        <label htmlFor={`customStem${stem.name}`} className="btn btn-stroke btn-sm btn-disabled">Reupload</label>
                                                                    </>:
                                                                    <>
                                                                        <input id={`customStem${stem.name}`} type="file" accept={"audio/wav,audio/x-wav"} style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.stemsFileHandler(stem.label, e, "custom", stem.uuid)} />
                                                                        <label htmlFor={`customStem${stem.name}`} className="btn btn-stroke btn-sm">Reupload</label>
                                                                    </>
                                                                }

                                                            </div>
                                                            </div>
                                                        </div>
                                                        {/* {# Error Message #} */}
                                                        <div className="error-message is-file">{stem.error}</div>
                                                    </>
                                                }

                                                </>:
                                                     <div className="grid-row-xs">
                                                     <div className="col w-auto flex-1" key={index}>
                                                         {this.state.trackStatus !== "pending"?
                                                            <Select
                                                            name={"stemName" + index}
                                                            placeholder="Select Stem"
                                                            styles={customStyles}
                                                            value={stem.label ? {label: stem.label, value: stem.value}: undefined}
                                                            options={this.state.instrumentOptions.concat(this.state.additionalStemNames).sort((a,b)=>{
                                                                if(a.label > b.label ){
                                                                    return 1
                                                                }
                                                                else if (a.label < b.label){
                                                                    return -1
                                                                }
                                                                else{
                                                                    return 0
                                                                }
                                                            })
                                                            }
                                                            onChange={(e) => this.handleStemNameChange(e, index)}
                                                        />:
                                                        <Select
                                                            isDisabled
                                                            name={"stemName" + index}
                                                            placeholder="Stem Name..."
                                                            styles={customStyles}
                                                            value={{label: stem.label, value: stem.value}}
                                                            options={this.state.instruments}
                                                            onChange={(e) => this.handleVersionNameChange(index, e)}
                                                        />
                                                         }
                                                     </div>
                                                     <div className="col btn-group">
                                                        {(stem.label?.length < 1 || stem.label === undefined )?
                                                            <>
                                                                <button type="button" id={'customStem' + index} type="button" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onClick={(e)=>{e.preventDefault();Error({message:"Enter stem name before uploading."})}}/>
                                                                <label htmlFor={'customStem' + index} className="btn btn-stroke form-btn btn-disabled">Upload Stem</label>
                                                            </>:
                                                            <>
                                                                <input id={'customStem' + index} type="file" accept={"audio/wav,audio/x-wav"} style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.stemsFileHandler(stem.label, e, "custom", stem.uuid)} />
                                                                <label htmlFor={'customStem' + index} className="btn btn-stroke form-btn">Upload Stem</label>
                                                            </>
                                                        }

                                                         <button type="button"  onClick={(event)=>{event.preventDefault();this.removeStemFromState(index)}}className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                                     </div>
                                                 </div>
                                                }
                                                </div>
                                ))}
                                            {this.state.stems.map((stem, index) =>(
                                                            <></>
                                                            // <div className="input-group-sm">
                                                            // {stem.wavURL  || this.state[`stemFile${stem.name}Loading`] || stem.error?
                                                            // <>
                                                            // {!stem.error ?
                                                            //     <div className="upload-item">
                                                            //         <div className="upload-item-pre">
                                                            //             {/* {# Replace div with react loader, swap with play button once uploaded #} */}
                                                            //             {this.state[`stemFile${stem.name}Loading`] ?
                                                            //
                                                            //         </div>
                                                            //         <div className="upload-item-details">
                                                            //             <span className="upload-item-title">{stem.name}</span>
                                                            //             <div className="upload-item-file">{stem.localname}</div>
                                                            //         </div>

                                                            //         <div className="upload-item-wave">
                                                            //             <Waveform unsetNowPlaying={this.unsetNowPlaying} nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={stem.wavURL} peaks={stem.peaks}/>
                                                            //             {/* {% include "components/audio/waveform-sm.html" %} */}
                                                            //             <span className="upload-item-duration">{stem.duration}</span>
                                                            //         </div>
                                                            //         <div className="upload-item-controls">
                                                            //             {/* <button type="button" className="circle-icon circle-icon-sm"><span className="icon-download"></span></button> */}
                                                            //             <button type="button" onClick={(event)=>{event.preventDefault();this.removestem(stem.stemId)}}  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                                            //         </div>
                                                            //     </div>:
                                                            //     <>
                                                            //         <div className="upload-item has-error">
                                                            //             <div className="upload-item-pre">
                                                            //                 <button type="button" onClick={(event)=>{this.changeNowPlaying(event, stem.wavURL)}} className="circle-icon-border circle-icon-sm">{this.state.nowPlaying === stem.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                                            //             </div>
                                                            //             <div className="upload-item-details">
                                                            //             <span className="upload-item-title">{stem.name}</span>
                                                            //             <div className="upload-item-file">{stem.localname}</div>
                                                            //             </div>
                                                            //             <div className="upload-item-wave">
                                                            //                 <Waveform unsetNowPlaying={this.unsetNowPlaying} nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={stem.wavURL} peaks={stem.peaks}/>
                                                            //                 <span className="upload-item-duration">{stem.duration}</span>
                                                            //             </div>
                                                            //             <div className="upload-item-controls">
                                                            //             <div className="upload-item-controls">
                                                            //                 <input id={`stemFile${stem.name}`} type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.stemsReupload(stem.name, stem.id, e)} />
                                                            //                 <label htmlFor={`stemFile${stem.name}`} className="btn btn-stroke btn-sm">Reupload</label>
                                                            //             </div>
                                                            //             </div>
                                                            //         </div>
                                                            //         {/* {# Error Message #} */}
                                                            //         <div className="error-message is-file">{stem.error}</div>
                                                            //     </>
                                                            // }
                                                            //     {/* <div>{stem.label}</div>
                                                            //     <br></br> */}
                                                            // </>:
                                                            //     <></>
                                                            // }
                                                            // </div>
                                            ))}
                                            {/* <div className="grid-row-xs">
                                                                    <div className="col w-auto flex-1" >
                                                                        <Creatable
                                                                            name={"newStemName"}
                                                                            placeholder="Stem Name..."
                                                                            styles={customStyles}
                                                                            value={this.state.newStemName}
                                                                            options={this.state.instruments.filter(instrument => {
                                                                                for (let i = 0; i < this.state.instruments.length; i++) {
                                                                                    // for (j =0; j < this.state.stems.length; j++){
                                                                                        if (this.state.stems[i].name === instrument.value) {
                                                                                            return false
                                                                                        // }
                                                                                    }
                                                                                }
                                                                                return true
                                                                            })
                                                                            }
                                                                            onChange={(e) => this.handleStemNameChange(e)}
                                                                            isSearchable
                                                                        />
                                                                    </div>
                                                                    <div className="col w-auto flex-initial">
                                                                        {this.state.newStemName ? <>
                                                                            <input id={`stemFile${this.state.newStemName.value}`} type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.stemsFileHandler(this.state.newStemName.value, e)} />
                                                                            <label htmlFor={`stemFile${this.state.newStemName.value}`} className="btn btn-stroke form-btn">Upload Stem</label>
                                                                        </>:<>
                                                                            <input disabled  type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} onChange={(e) => this.stemsFileHandler(this.state.newStemName.value, e)} />
                                                                            <label  className="btn btn-stroke btn-disabled form-btn">Upload Stem</label>
                                                                        </>}
                                                                    </div>
                                                                </div> */}
                                                                 <div className="grid-row-xs">
                                                                    <div className="col w-auto flex-1" >
                                                                        <hr className="divider"></hr>
                                                                        <div className="input-group ">
                                                                            {this.state.trackStatus === "pending" ?
                                                                                <button type="button" disabled className="btn btn-disabled btn-stroke form-btn w-full"><span className="icon-plus mr-5"></span>Add a Stem</button>:
                                                                                <button type="button" onClick={this.addNewStemDiv} className="btn btn-stroke form-btn w-full"><span className="icon-plus mr-5"></span>Add a Stem</button>
                                                                            }
                                                                        </div>

                                                                    </div>
                                                                </div>
                                        </div> : <></>}
                                    </div>

                                </div>
                            </div>
                                                                        </>}
                                                                        </>}
                                                                                                    {/* Terms */}
                            <div className="form-section" id="terms">
                                <h2 className="text-label-lg mb-40">Terms</h2>
                                <div className="input-group-xs">
                                    <label className="radio-option items-start" htmlFor="tosAgree">
                                        {this.state.trackStatus === "pending" ?
                                            <input disabled type="checkbox" className="form-checkbox" id="tosAgree" name="tosAgree" checked/>:
                                            <input onChange={this.handleCheck} type="checkbox" className="form-checkbox" id="tosAgree" name="tosAgree" checked={this.state.tosAgree}/>
                                        }
                                         <span className="inline-block">Agree to our <a href="/terms/artist" className="text-link" target="_blank">Artist Terms of Service</a> and <a href="/privacy" className="text-link" target="_blank">Privacy Policy</a></span>.
                                    </label>
                                </div>
                                <div className="input-group-xs">
                                    <label className="radio-option items-start" htmlFor="exclusiveAgree">
                                        {this.state.trackStatus === "pending" ?
                                            <input disabled type="checkbox" className="form-checkbox" id="exclusiveAgree" name="exclusiveAgree" checked/>:
                                            <input onChange={this.handleCheck} type="checkbox" className="form-checkbox" id="exclusiveAgree" name="exclusiveAgree" checked={this.state.exclusiveAgree}/>
                                        }
                                        <span className="inline-block">You agree that this track will be exclusively licensed through Deep Sounds and is not on any other Music Library.</span>
                                    </label>
                                </div>
                                <div className="input-group-xs">
                                    <label className="radio-option items-start" htmlFor="removeAgree">
                                        {this.state.trackStatus === "pending" ?
                                            <input disabled type="checkbox" className="form-checkbox" id="removeAgree" name="removeAgree" checked/>:
                                            <input onChange={this.handleCheck} type="checkbox" className="form-checkbox" id="removeAgree" name="removeAgree" checked={this.state.removeAgree}/>
                                        }
                                        <span className="inline-block">You understand that you won't have the ability to remove this track from the website for 1 year after Deep Sounds approves this track.</span>
                                    </label>
                                </div>
                                <div className="input-group-xs">
                                    <label className="radio-option items-start" htmlFor="copyAgree">
                                    {this.state.trackStatus === "pending" ?
                                        <input disabled type="checkbox" className="form-checkbox" id="copyAgree" name="copyAgree" checked/>:
                                        <input onChange={this.handleCheck} type="checkbox" className="form-checkbox" id="copyAgree" name="copyAgree" checked={this.state.copyAgree}/>
                                    }
                                        <span className="inline-block">You own 100% of the rights to this track and it does not infringe on any other artist’s copyright.</span>
                                    </label>
                                </div>
                            </div>
                            <div className="form-section" id="form_actions">
                                <div className="grid-row-xs justify-end">
                                    <div className="col w-full sm:w-1/2 md:w-auto mb-20">
                                    {this.state.submittable ?
                                        <button type="button" type="button" onClick={(event)=>{event.preventDefault();this.handleFormSubmit(true)}} className="btn btn-stroke">Submit for Approval</button>:
                                        <button type="button" type="button" disabled onClick={(event)=>{event.preventDefault();}} className="btn btn-disabled btn-stroke">Submit for Approval</button>
                                    }
                                    </div>
                                    <div className="col w-full sm:w-1/2 md:w-auto">
                                            <button type="button" type="button" onClick={(event)=>{event.preventDefault();this.handleFormSubmit(false)}} className="btn btn-primary">Save and Quit</button>
                                    </div>
                                </div>
                            </div>
                          </form>
                            {/* {# End Form #} */}

                        </div>
                        {/* {# End App Main Content #} */}

                        {/* {# Right Info Sidebar #} */}
                        <div className="app-sublayout-info">
                        {/* {# Form Progress #} */}
                        <MediaQuery minWidth={1024}>
                          <Sticky scrollElement=".app-main" stickyClassName="mt-15">
                              <div className="form-progress">
                                  <div className="form-progress-header">
                                      <h4 className="text-label-lg">Progress</h4>
                                  </div>
                                  <div className="form-progress-details">
                                      <p className="text-sm">You must complete all required sections below before you can submit your track for approval.</p>
                                      {/* {# Progress Details #} */}
                                      <ul className="form-progress-sections">
                                          <li className={"is-complete"}><HashLink to={{hash: "#viability"}} >Viability<span className="form-progress-req"></span></HashLink></li>
                                          <li className={(this.state.trackQuality !== "reject" ? "is-complete" : "") + (this.state.trackQuality ==="reject" ?"has-error":"")}><HashLink to={{hash: "#tracksource"}}>Track File <span className="form-progress-req">Req.</span></HashLink></li>
                                          <li className={this.state.detailsComplete ? "is-complete": "has-error"}><HashLink to={{hash: "#details"}}>Track Details <span className="form-progress-req">Req.</span></HashLink></li>
                                          <li className={this.state.tagsComplete ? "is-complete": ""}><HashLink to={{hash: "#tags"}} >Tags <span className="form-progress-req">Req.</span></HashLink></li>
                                          <li className={this.state.funFactComplete === true ? "is-complete" : this.state.funFactComplete === 'incomplete' ? "has-error" : ""}>
                                              <HashLink to={{hash: "#fun_fact"}} >Fun Fact</HashLink>
                                          </li>
                                          <li className={this.state.versionsComplete === true ? "is-complete": this.state.versionsComplete === "incomplete" ? "has-error": ""}><HashLink to={{hash: "#versions"}} >Versions</HashLink></li>
                                          <li className={this.state.stemsComplete === true ? "is-complete": this.state.stemsComplete === "incomplete" ? "has-error": ""}><HashLink to={{hash: "#stems"}}>Stems</HashLink></li>
                                          <li className=""><HashLink to={{hash: "#composers"}}>Composers</HashLink></li>
                                          <li className={this.state.termsComplete ? "is-complete": ""}><HashLink to={{hash: "#terms"}}>Terms & Conditions <span className="form-progress-req">Req.</span></HashLink></li>
                                      </ul>
                                  </div>
                              </div>
                          </Sticky>
                        </MediaQuery>
                    </div>
                        {/* {# End Info Sidebar #} */}

                    </div>
                    {/* {# End App Main Inner #} */}

                </div>
                </>
                }
                {/* {# End Container #} */}

            </div>

            )}
            </UserConsumer>
        )
    }
}
export default ArtistResubmitTrack

import React from "react";

const PricingCardFeatures = ({ excluded, included }) => {
  return (
    <div className="pricing-card-features mb-30">
      {included.map((item, index) => (
        <div className="flex items-center py-5 text-white" key={`included-${index}`}>
          <span className="text-icon-sm transform -translate-y-1 mr-5 icon-check"></span>
          <div
            className=""
            data-tooltipped=""
            aria-describedby="tippy-tooltip-2"
            style={{ display: "inline" }}
          >
            <span className="text-sm">{item}</span>
          </div>
          <div></div>
        </div>
      ))}
      {excluded.map((item, index) => (
        <div className="flex items-center py-5 text-gray-400" key={`included-${index}`}>
          <span className="text-icon-sm transform -translate-y-1 mr-5 icon-close"></span>
          <span className="text-sm">{item}</span>
        </div>
      ))}
    </div>
  );
};

export default PricingCardFeatures;

import React, { useEffect, useState } from "react";
import CheckPasswordForm from "../Password/CheckPasswordForm";
import ChangeEmailForm from "./ChangeEmailForm";
import ConfirmationCodeForm from "./ConfirmationCodeForm";
// import passwordTokenValid from "../Password/tokenValid"

const ChangeEmailModal = (props) => {
  const [passwordToken, setPasswordToken] = useState();
  const [pendingEmail, setPendingEmail] = useState();
  const [confirmed, setConfirmed] = useState(false);

  return (
    <React.Fragment>
      {/* {# Modal Header #} */}
      <header class="modal-header">
        <h2 id="add_scene_title" class="modal-title">
          Change Email
        </h2>
        <button
          onClick={props.handleClose}
          aria-label="Close Modal"
          data-micromodal-close="change_email_modal"
          class="modal-close"
          type="button"
        />
      </header>
      {/* {# Modal Content #} */}
      <div id="change_email_content" class="modal-content">
        {!passwordToken && (
          <CheckPasswordForm 
            setPasswordToken={setPasswordToken}
          />
        )}
        {(passwordToken && !pendingEmail) && (
          <ChangeEmailForm 
            email={props.email}
            setPendingEmail={setPendingEmail}
            handleFormUnsaved={props.handleFormUnsaved}
          />
        )}
        {(pendingEmail && !confirmed) && (
          <ConfirmationCodeForm
            setConfirmed={setConfirmed}
            passwordToken={passwordToken}
            setEmail={props.setEmail}
            handleClose={props.handleClose}
            handleFormUnsaved={props.handleFormUnsaved}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default ChangeEmailModal;

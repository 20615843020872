import React, { useState, useRef } from "react";
import CircularIndeterminateButton from "../../Loading/circularIndeterminantButton";
import { LabelOptionalVisible } from "../../FormElements/labels";
import MicroModal from "react-micro-modal";
import ChangeEmailModal from "../ChangeEmail/ChangeEmailModal";

export default function ChangeEmailField(props) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState(props.email)

  const handleModalOpen = () => {
    setIsLoading(true);
    setIsDisabled(true);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsLoading(false);
    setIsDisabled(false);
    setIsModalOpen(false);
  };

  const inputRef = useRef();

  return (
    <>
      <div className="form-section">
        <h3 className="text-label-lg mb-30">Change Email Address</h3>
        <div className="col w-full">
          <div className="input-group input-inline">
            <input
              // type="email"
              name="email"
              id="email"
              disabled
              className="form-input"
              value={email}
              ref={inputRef}
            />
            <LabelOptionalVisible for="email">
              Current Email Address
            </LabelOptionalVisible>
          </div>
        </div>
        <div className="col w-full">
          <button
            type="submit"
            disabled={isDisabled}
            className="btn btn-primary form-btn w-full"
            onClick={() => handleModalOpen()}
          >
            {isLoading ? (
              <CircularIndeterminateButton size={45} thickness={5.5} />
            ) : (
              "Change Email Address"
            )}
          </button>
        </div>
      </div>
      <MicroModal
        open={isModalOpen}
        closeOnEscapePress={true}
        handleClose={() => handleModalClose()}
        closeOnOverlayClick={true}
        closeOnAnimationEnd={true}
        modalOverlayClassName="modal-overlay"
        modalClassName="modal micromodal-slide is-open"
        modalOverlayStyles={null}
        children={(handleClose) => (
          <ChangeEmailModal 
            handleFormUnsaved={props.handleFormUnsaved}
            handleClose={handleClose} 
            email={email}
            setEmail={setEmail}
          />
        )}
        containerStyles={{
          background: "#131313",
          padding: "asdf",
          maxWidth: "40.625rem !important",
          maxHeight: "100vh",
          borderRadius: "asdf",
          overflowY: "auto",
          boxSizing: "border-box",
        }}
      />
    </>
  );
}

import React, { Component } from 'react';
import { NavLink } from "react-router-dom";
import { UserConsumer } from "../../contexts/User";
class SideNavIncompleteSub extends Component {
    state={

    }

    render(){
        return(
			<UserConsumer>
				{({user, login, logout, currentNav, setCurrentNav})=>(
						<div className="subnav">
			<NavLink to="/logout" onClick={logout} className="subnav-link"><span className="icon-log-out"></span> Log Out</NavLink>
		</div>
				)}

			</UserConsumer>
        )
    }
}
export default SideNavIncompleteSub


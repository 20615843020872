import React, { Component } from "react"
import { Tooltip } from "react-tippy"
import MainContent from "./mainContent"
import VSTooltip from "./vsTooltip"

class Main extends Component{
    state={
        isVSTippyOpen: false
    } 
    toggleVSTippy = ()=>{
        this.setState({isVSTippyOpen: !this.state.isVSTippyOpen})
    }
    render (){
        return(
            // {# VS Container #}
<div  onTransitionEnd={(e)=>{console.log("transition ended ", e, " ", e.propertyName); if(e.propertyName === "transform" || e.propertyName === "max-width"){this.props.drawVideoStudioTimeline(true)}}} className={`vs-main ${this.props.isMainOpen ? 'is-open': 'is-closed'}  ${this.props.isMini ? 'is-mini' : 'is-full'}`} style={{zIndex:"7000"}}> 

    {/* // {# VS Panel #} */}
    {/* classname here toggles between is-mini and is-full */}
    <div   className={`vs-panel`} >

        {/* // {# VS Panel Header #} */}
        <div className="vs-panel-header">

            {/* {# Title #} */}
            <div className="flex-1">
                <h4 className="vs-panel-title">Video Studio</h4>
            </div>

            {/* {# Controls #} */}
            <div className="btn-group flex-none">
                {/* {# Options #} */}
                <Tooltip
                            trigger={'manual'}
                            className={"test"}
                            arrow={false}
                            open={this.state.isVSTippyOpen}
                            interactive={true}
                            useContext={true}
                            position={'bottom-end'}
                            html={(<VSTooltip
                                toggleVSTippy={this.toggleVSTippy}
                                unloadVideo={this.props.unloadVideo}
                            />)}
                            style={{zIndex: "10001"}}
                            allowHTML={true}
                            onUntrigger={()=>{this.toggleVSTippy()}}
                            onRequestClose={()=>{this.setState({isVSTippyOpen: false})}}
                        >
                           <button
                                onClick={this.toggleVSTippy}
                                className="circle-icon circle-icon-sm icon-dots-vert" type="button">
                            </button>
                        </Tooltip>
               
                {/* {# Mini Player Toggle #} */}
                <button
                    type="button"
                    onClick={()=>this.props.isMini ? this.props.maximizePlayer() : this.props.minifyPlayer()}
                //    class toggles between icon-video-studio-mini and icon-video-studio-full
                    className={`circle-icon circle-icon-sm ${this.props.isMini ? "icon-video-studio-full" : "icon-video-studio-mini"}`}
                >
                </button>
                {/* {# Close #} */}
                <button
                    className="circle-icon circle-icon-sm icon-chevron-down" type="button"
                    onClick={this.props.closeVideoMain}
                />
            </div>

        </div>

        {/* // {# VS Panel Content #} */}
        <div className="vs-panel-content">
            <MainContent {...this.props}/>
            {/* {% include "components/video-studio/vs-main-content.html" %} */}

        </div>
        {/* {# End VS Panel Content #} */}

    </div>
    {/* {# End VS Panel #} */}

    {/* {# Overlay #} */}
    <div className="vs-overlay" ></div>

</div>
        )
    }
}
export default Main
import React, { createContext, useEffect, useState } from 'react';
import api from '../components/utils/api';
import moment from 'moment';
import { handleAPIError } from '../utils/handleAPIError';
// import { getUserChannelData } from '../components/Account/ChannelWhitelist/hooks';

export const ContentIDContext = createContext();

export const ContentIDProvider = ({ children }) => {

// function getUserChannelData() {
  const [userChannelData, setUserChannelData] = useState({});
  const [updateUserChannelData, setUpdateUserChannelData] = useState(false);

  useEffect(() => {
    api.getContentIDChannelByUser()
      .then(response => {
        const responseData = response?.data?.data;
        console.log('getContentIDChannelByUser responseData', responseData);

        if (responseData?.channel_id) {
          const params = {
            id: responseData.channel_id
          }
          api.getYoutubeChannel(params)
            .then(googleResponse => {
              console.log('googleResponse?.data', googleResponse?.data);
              const googleResponseData = googleResponse?.data?.data;

              googleResponseData.publishedAt = moment(googleResponseData.publishedAt).format("MM/DD/YYYY");
              googleResponseData.subscriberCount = Number(googleResponseData.subscriberCount).toLocaleString();
              googleResponseData.videoCount = Number(googleResponseData.videoCount).toLocaleString();

              const userChannelData = googleResponseData;
              userChannelData.status = responseData.status;
              userChannelData.isLoaded = true;
              // console.log('userChannelData', userChannelData);

              setUserChannelData(userChannelData);
              setUpdateUserChannelData(false);
              // setIsModalOpen(true);
              // setisDisabled(false);
              // setisLoaded(true);
            })
            .catch(error => {
              setUserChannelData({ isLoaded: true });
              setUpdateUserChannelData(false);
              console.log(error);
              // handleAPIError(error);
              // setisDisabled(false);
              // setisLoaded(true);
            })
        }
        else {
          // responseData.isLoaded = true;
          setUserChannelData({ isLoaded: true });
          setUpdateUserChannelData(false);
        }
        // setIsModalOpen(true);
        // setisDisabled(false);
        // setisLoaded(true);
      })
      .catch(error => {
        setUserChannelData({ isLoaded: true });
        setUpdateUserChannelData(false);
        console.log(error);
        handleAPIError(error);
        // setisDisabled(false);
        // setisLoaded(true);
      })

    // return () => {
    //   second
    // }
  }, [updateUserChannelData]);

  // return userChannelData;
// }

  // const userChannelData = getUserChannelData();
  console.log("userChannelData", userChannelData);

  const value = {
    userChannelData,
    setUpdateUserChannelData
  }

  return (
    <ContentIDContext.Provider
      value={value}
    >
      {children}
    </ContentIDContext.Provider>)
}

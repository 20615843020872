import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { PlayerContext } from "../../PlayerContext";
import BackdropOverlay from "../LoadingOverlay/backdrop";
import Pagination from "../Pagination/pagination";
import SearchLicenses from "../SearchBar/searchLicenses.js";
import SortBar from "../SortBar/results";
import { Error } from "../Toast/index";
import api from "../utils/api";
import TrackListItemLicense from "./trackListItemLicense";
const queryStringer = require("query-string");
const licenseFilters = [
  { filter: "RecentA", displayText: "Recently Purchased" },
  { filter: "Type", displayText: "Type" },
  { filter: "Project", displayText: "Project" },
];
class CustomerLicenses extends Component {
  state = {
    autocompleteOptions: [],
    artistSearchOptions: [],
    projectSearchOptions: [],
    trackSearchOptions: [],
    orderNumberOptions: [],
    licenseNumberOptions: [],
    filteredAutocompleteOptions: [],
    custLicenses: [],
    currentPage: 1,
    filteredCustLicenses: { 1: [] },
    sortChoice: "RecentA",
    sortText: "Recently Purchased",
    offset: 0,
    myTotalLicenses: 0,
    activeOption: -1,
  };
  componentDidMount = () => {
    let query =
      this.props.location.search.length > 0 ? this.props.location.search.substring(1) : "";
    let parsedQuery = queryStringer.parse(query);
    let countQuery = "";
    if (parsedQuery.order) {
      countQuery = parsedQuery.order;
      let selectSearchTerm = [];
      selectSearchTerm.push({ label: countQuery, value: countQuery });
      this.setState({ searchTerm: countQuery, selectSearchTerm: selectSearchTerm });
    } else if (parsedQuery.licenseNumber) {
      countQuery = parsedQuery.licenseNumber;
      let selectSearchTerm = [];
      selectSearchTerm.push({ label: countQuery, value: countQuery });
      this.setState({ searchTerm: countQuery, selectSearchTerm: selectSearchTerm });
    } else if (parsedQuery.trackTitle) {
      countQuery = parsedQuery.trackTitle;
      this.setState({ searchTerm: countQuery });
    }
    api.getLicenseAutoComplete().then((res) => {
      let autocompleteOptions = [];
      let artistSearchOptions = [];
      let trackSearchOptions = [];
      let projectSearchOptions = [];
      let orderNumberOptions = [];
      let licenseNumberOptions = [];
      if (res.data.artistNames) {
        res.data.artistNames.forEach((artistName) => {
          let artistNameOption = {
            photo: artistName.profilePhoto,
            label: artistName.artistName || "",
            value: artistName.artistName || "",
            type: "artist",
          };
          artistSearchOptions.push(artistNameOption);
        });
      }
      if (res.data.projectNames) {
        res.data.projectNames.forEach((projectName) => {
          let projectNameOption = {
            photo: projectName.coverArt,
            label: projectName.name || "",
            value: projectName.name || "",
            type: "project",
          };
          projectSearchOptions.push(projectNameOption);
        });
      }
      if (res.data.trackTitles) {
        res.data.trackTitles.forEach((trackTitle) => {
          let trackTitleOption = {
            photo: trackTitle.albumArt,
            value: trackTitle.trackTitle || "",
            type: "track",
          };
          trackSearchOptions.push(trackTitleOption);
        });
      }
      if (res.data.orderNumbers) {
        res.data.orderNumbers.forEach((orderNumber) => {
          let orderNumberOption = {
            label: orderNumber.orderNumber || "",
            value: orderNumber.orderNumber || "",
            type: "orderNumber",
          };
          orderNumberOptions.push(orderNumberOption);
        });
      }
      if (res.data.licenseNumbers) {
        res.data.licenseNumbers.forEach((licenseNumber) => {
          let licenseNumberOption = {
            label: licenseNumber.licenseNumber || "",
            value: licenseNumber.licenseNumber || "",
            type: "licenseNumber",
          };
          licenseNumberOptions.push(licenseNumberOption);
        });
      }
      if (parsedQuery.trackTitle) {
        let selectSearchTerm = [];
        selectSearchTerm.push(
          trackSearchOptions.filter(
            (track) => track.value.toLowerCase() === parsedQuery.trackTitle.toLocaleLowerCase()
          )[0]
        );
        this.setState({
          artistSearchOptions,
          trackSearchOptions,
          projectSearchOptions,
          orderNumberOptions,
          licenseNumberOptions,
          selectSearchTerm,
        });
      } else {
        this.setState({
          artistSearchOptions,
          trackSearchOptions,
          projectSearchOptions,
          orderNumberOptions,
          licenseNumberOptions,
        });
      }
    });
    api.getMyCustLicenseCount(countQuery).then((res) => {
      if (res.data) {
        if (res.data.totalLicenses.length > 0) {
          this.setState({ totalLicenses: res.data.totalLicenses[0].total }, () => {
            if (parsedQuery.order) {
              api.getMyCustLicenses(this.state.offset, parsedQuery.order).then((res) => {
                if (res.data) {
                  let trackInfo = res.data.licenses.map((license) => {
                    let oneTrack = {
                      albumId: license.albumId,
                      albumTitle: license.albumTitle,
                      artistId: license.artistId,
                      artistName: license.artistName,
                      artistURL: license.artistURL,
                      duration: license.duration,
                      imageURL: license.albumArt,
                      mp3URL: license.mp3URL,
                      peaks: license.peaks,
                      hostedInvoiceURL: license.hostedInvoiceURL,
                      retreivedAt: Date.now(),
                      signedLicensePDFURL: license.signedLicensePDFURL,
                      stems: license.stems,
                      tags: license.tags,
                      trackId: license.trackId,
                      trackTitle: license.trackTitle,
                      trackURL: license.trackURL,
                      versions: license.versions,
                    };
                    return oneTrack;
                  });
                  this.context.updateLoadedTracks(trackInfo);
                  this.setState({
                    loaded: true,
                    custLicenses: res.data.licenses,
                    filteredCustLicenses: { 1: res.data.licenses },
                    openOrder: parsedQuery.order || parsedQuery.licenseNumber,
                  });
                }
              });
            } else if (parsedQuery.licenseNumber) {
              api.getMyCustLicenses(this.state.offset, parsedQuery.licenseNumber).then((res) => {
                if (res.data) {
                  let trackInfo = res.data.licenses.map((license) => {
                    let oneTrack = {
                      albumId: license.albumId,
                      albumTitle: license.albumTitle,
                      artistId: license.artistId,
                      artistName: license.artistName,
                      artistURL: license.artistURL,
                      duration: license.duration,
                      imageURL: license.albumArt,
                      mp3URL: license.mp3URL,
                      peaks: license.peaks,
                      hostedInvoiceURL: license.hostedInvoiceURL,
                      retreivedAt: Date.now(),
                      signedLicensePDFURL: license.signedLicensePDFURL,
                      stems: license.stems,
                      tags: license.tags,
                      trackId: license.trackId,
                      trackTitle: license.trackTitle,
                      trackURL: license.trackURL,
                      versions: license.versions,
                    };
                    return oneTrack;
                  });
                  this.context.updateLoadedTracks(trackInfo);
                  this.setState({
                    loaded: true,
                    custLicenses: res.data.licenses,
                    filteredCustLicenses: { 1: res.data.licenses },
                    openOrder: parsedQuery.order || parsedQuery.licenseNumber,
                  });
                }
              });
            } else if (parsedQuery.trackTitle) {
              api.getMyCustLicenses(this.state.offset, parsedQuery.trackTitle).then((res) => {
                if (res.data) {
                  let trackInfo = res.data.licenses.map((license) => {
                    let oneTrack = {
                      albumId: license.albumId,
                      albumTitle: license.albumTitle,
                      artistId: license.artistId,
                      artistName: license.artistName,
                      artistURL: license.artistURL,
                      duration: license.duration,
                      imageURL: license.albumArt,
                      mp3URL: license.mp3URL,
                      peaks: license.peaks,
                      hostedInvoiceURL: license.hostedInvoiceURL,
                      retreivedAt: Date.now(),
                      signedLicensePDFURL: license.signedLicensePDFURL,
                      stems: license.stems,
                      tags: license.tags,
                      trackId: license.trackId,
                      trackTitle: license.trackTitle,
                      trackURL: license.trackURL,
                      versions: license.versions,
                    };
                    return oneTrack;
                  });
                  this.context.updateLoadedTracks(trackInfo);
                  this.setState({
                    loaded: true,
                    custLicenses: res.data.licenses,
                    filteredCustLicenses: { 1: res.data.licenses },
                    openOrder: parsedQuery.order || parsedQuery.licenseNumber,
                  });
                }
              });
            } else {
              api.getMyCustLicenses(this.state.offset, "").then((res) => {
                if (res.data) {
                  let trackInfo = res.data.licenses.map((license) => {
                    let oneTrack = {
                      albumId: license.albumId,
                      albumTitle: license.albumTitle,
                      artistId: license.artistId,
                      artistName: license.artistName,
                      artistURL: license.artistURL,
                      duration: license.duration,
                      imageURL: license.albumArt,
                      mp3URL: license.mp3URL,
                      peaks: license.peaks,
                      hostedInvoiceURL: license.hostedInvoiceURL,
                      retreivedAt: Date.now(),
                      signedLicensePDFURL: license.signedLicensePDFURL,
                      stems: license.stems,
                      tags: license.tags,
                      trackId: license.trackId,
                      trackTitle: license.trackTitle,
                      trackURL: license.trackURL,
                      versions: license.versions,
                    };
                    return oneTrack;
                  });
                  this.context.updateLoadedTracks(trackInfo);
                  this.setState({
                    loaded: true,
                    custLicenses: res.data.licenses,
                    filteredCustLicenses: { 1: res.data.licenses },
                    openOrder: parsedQuery.order || parsedQuery.licenseNumber,
                  });
                }
              });
            }
          });
        } else {
          this.setState({ loaded: true, totalLicenses: 0 });
        }
      }
    });
  };
  search = () => {
    this.setState({ offset: 0, currentPage: 1 }, () =>
      api.getMyCustLicenseCount(this.state.searchTerm).then((res) => {
        if (res.data) {
          if (res.data.totalLicenses.length > 0) {
            this.setState({ totalLicenses: res.data.totalLicenses[0].total }, () => {
              api.getMyCustLicenses(this.state.offset, this.state.searchTerm).then((res) => {
                if (res.data) {
                  let trackInfo = res.data.licenses.map((license) => {
                    let oneTrack = {
                      albumId: license.albumId,
                      albumTitle: license.albumTitle,
                      artistId: license.artistId,
                      artistName: license.artistName,
                      artistURL: license.artistURL,
                      duration: license.duration,
                      imageURL: license.albumArt,
                      mp3URL: license.mp3URL,
                      peaks: license.peaks,
                      retreivedAt: Date.now(),
                      signedLicensePDFURL: license.signedLicensePDFURL,
                      stems: license.stems,
                      tags: license.tags,
                      trackId: license.trackId,
                      trackTitle: license.trackTitle,
                      trackURL: license.trackURL,
                      versions: license.versions,
                    };
                    return oneTrack;
                  });
                  this.context.updateLoadedTracks(trackInfo);
                  this.setState({
                    loaded: true,
                    custLicenses: res.data.licenses,
                    filteredCustLicenses: { 1: res.data.licenses },
                    openOrder: null,
                  });
                }
              });
            });
          } else {
            this.setState({ totalLicenses: 0, loaded: true, filteredCustLicenses: { 1: [] } });
          }
        }
      })
    );
  };
  toggleSortTippy = (sortChoice, overRideTippy) => {
    this.setState({
      isSortTippyOpen: overRideTippy === false ? false : !this.state.isSortTippyOpen,
      sortChoice: sortChoice,
    });
  };
  onPageChanged = (data) => {
    const { filteredCollections } = this.state;
    const { currentPage, totalPages, pageLimit } = data;
    const searchBarEl = document.getElementById("search");
    searchBarEl.scrollIntoView();
    const offset = (currentPage - 1) * pageLimit;

    this.setState({ currentPage, totalPages });
  };
  gotoPage = (page, totalPages) => {
    // const { onPageChanged = f => f } = this.props;

    const currentPage = Math.max(0, Math.min(page, totalPages));

    const paginationData = {
      currentPage,
      totalPages: totalPages,
      pageLimit: 12,
      totalRecords: this.state.totaLicenses,
    };

    if (this.state.filteredCustLicenses[currentPage]?.length > 0) {
      this.setState({ currentPage }, () => this.onPageChanged(paginationData));
    } else if (this.state.loaded) {
      this.setState({ loaded: false });
      api.getMyCustLicenses((currentPage - 1) * 12, this.state.searchTerm || "").then((res) => {
        if (res.data) {
          let trackInfo = res.data.licenses.map((license) => {
            let oneTrack = {
              albumId: license.albumId,
              albumTitle: license.albumTitle,
              artistId: license.artistId,
              artistName: license.artistName,
              artistURL: license.artistURL,
              duration: license.duration,
              imageURL: license.albumArt,
              mp3URL: license.mp3URL,
              peaks: license.peaks,
              hostedInvoiceURL: license.hostedInvoiceURL,
              retreivedAt: Date.now(),
              signedLicensePDFURL: license.signedLicensePDFURL,
              stems: license.stems,
              tags: license.tags,
              trackId: license.trackId,
              trackTitle: license.trackTitle,
              trackURL: license.trackURL,
              versions: license.versions,
            };
            return oneTrack;
          });
          this.context.updateLoadedTracks(trackInfo);
          this.setState(
            (prevState) => ({
              ...prevState,
              filteredCustLicenses: {
                ...prevState.filteredCustLicenses,
                [currentPage]: res.data.licenses,
              },
            }),
            () => {
              this.setState({ currentPage, loaded: true }, () =>
                this.onPageChanged(paginationData)
              );
            }
          );
        }
      });
    } else {
    }
  };
  sortBy = (sortChoice, sortText, overRide, tippyStateCB, currentSortChoice, overRideTippy) => {
    let unsorted = [...this.state.filteredCustLicenses];
    let compare;
    let sorted;
    if (sortChoice !== this.state.sortChoice || overRide) {
      switch (sortChoice) {
        case "NameA":
          compare = (a, b) => {
            // Use toUpperCase() to ignore character casing
            const aName = a.name.toUpperCase();
            const bName = b.name.toUpperCase();

            let comparison = 0;
            if (aName < bName) {
              comparison = -1;
            } else if (aName > bName) {
              comparison = 1;
            }
            return comparison;
          };
          sorted = unsorted.sort(compare);
          break;
        case "NameD":
          compare = (a, b) => {
            // Use toUpperCase() to ignore character casing
            const aName = a.name.toUpperCase();
            const bName = b.name.toUpperCase();

            let comparison = 0;
            if (aName < bName) {
              comparison = -1;
            } else if (aName > bName) {
              comparison = 1;
            }
            return comparison * -1;
          };
          sorted = unsorted.sort(compare);
          break;
        case "RecentA":
          compare = (a, b) => {
            const aCreated = a.createdOn;
            const bCreated = b.createdOn;

            let comparison = 0;
            if (aCreated < bCreated) {
              comparison = -1;
            } else if (aCreated > bCreated) {
              comparison = 1;
            }
            return comparison;
          };
          sorted = unsorted.sort(compare);
          break;
        case "RecentD":
          compare = (a, b) => {
            const aCreated = a.createdOn;
            const bCreated = b.createdOn;

            let comparison = 0;
            if (aCreated < bCreated) {
              comparison = -1;
            } else if (aCreated > bCreated) {
              comparison = 1;
            }
            return comparison * -1;
          };
          sorted = unsorted.sort(compare);
          break;
        case "MostP":
          compare = (a, b) => {
            const aFollowers = a.followerCount;
            const bFollowers = b.followerCount;
            const aCreated = a.createdOn;
            const bCreated = b.createdOn;
            let comparison = 0;
            if (aFollowers < bFollowers) {
              comparison = 1;
            } else if (aFollowers > bFollowers) {
              comparison = -1;
            } else if (aCreated < bCreated) {
              comparison = -1;
            } else if (aCreated > bCreated) {
              comparison = 1;
            }
            return comparison;
          };
          sorted = unsorted.sort(compare);
          break;
      }
    } else {
      tippyStateCB(sortChoice);
    }
  };
  handleSearchChange = (event) => {
    if (event) {
      let { value } = event[0];
      this.setState({ loaded: false });
      if (this.searchTimout) {
        clearTimeout(this.searchTimout);
      }
      if (value.length > 2) {
        let filteredAutocompleteOptions = this.state.autocompleteOptions.filter((option) => {
          return option.label?.toLowerCase().indexOf(value.toLowerCase()) > -1;
        });
        let optionFix;

        if (this.state.activeOption > filteredAutocompleteOptions.length - 1) {
          optionFix = this.state.activeOption - 1;
        } else {
          optionFix = this.state.activeOption;
        }
        this.setState({
          filteredAutocompleteOptions: filteredAutocompleteOptions,
          activeOption: optionFix,
        });
      } else {
        this.setState({ filteredAutocompleteOptions: [] });
      }
      this.setState(
        { searchTerm: value, selectSearchTerm: event, searchInput: event.label || "" },
        () => {
          this.search();
        }
      );
    } else {
      this.setState({ loaded: false });
      if (this.searchTimout) {
        clearTimeout(this.searchTimout);
      }

      this.setState({ searchTerm: "", selectSearchTerm: [] }, () => {
        this.search();
      });
    }
  };
  onKeyDown = (e) => {
    e.preventDefault();

    const { activeOption, filteredAutocompleteOptions } = this.state;
    if (filteredAutocompleteOptions.length > 0) {
      if (e.keyCode === 13) {
        e.preventDefault();

        // setValueCB("collaboratorEmail", filteredArtistOptions[activeOption].email)
        this.handleSearchChange({
          target: { value: filteredAutocompleteOptions[activeOption].label },
        });
        this.setState({
          activeOption: 0,
          showOptions: false,
          filteredAutocompleteOptions: [],
        });
      } else if (e.keyCode === 38) {
        if (activeOption === -1) {
          return;
        }
        e.preventDefault();
        this.setState({ activeOption: activeOption - 1 }, () => {
          let activeElement = document.getElementsByClassName("option-active")[0];
          if (activeElement) {
            activeElement.parentNode.parentNode.parentNode.parentNode.scrollTop =
              activeElement.offsetTop;
          }
        });
      } else if (e.keyCode === 40) {
        if (activeOption === filteredAutocompleteOptions.length - 1) {
          return;
        }
        this.setState({ activeOption: activeOption + 1 }, () => {
          let activeElement = document.getElementsByClassName("option-active")[0];
          activeElement.parentNode.parentNode.parentNode.parentNode.scrollTop =
            activeElement.offsetTop;
        });
      }
    } else if (e.keyCode === 13) {
      e.preventDefault();
    }
  };
  onClick = (artistName, type, id) => {
    this.handleSearchChange({ target: { value: artistName } });
    this.setState({
      activeOption: 0,
      showOptions: false,
      filteredAutocompleteOptions: [],
    });
  };
  changeSearchInput = (newValue) => {
    // if(!newValue && this.state.city?.label){
    //     const inputValue =this.state.city?.label
    //     this.setState({cityInput: inputValue})
    //     return inputValue
    // }
    // else{
    if (this.state.selectSearchTerm?.length > 0 && newValue) {
      Error({ message: "Please remove search term first" });
      return;
    } else if (this.state.selectSearchTerm?.value && newValue) {
      Error({ message: "Please remove search term first" });
      return;
    } else {
      const inputValue = newValue;
      this.setState({ searchInput: inputValue });
      return inputValue;
    }

    // }
  };
  searchKeyDown = (event) => {
    //
    // if(event.keyCode === 8 && (this.state.searchInput?.length ===1 || !this.state.searchInput)){
    //     this.handleSearchChange(null)
    // }
  };
  altChangeSearchInput = (newValue) => {
    // const inputValue =newValue
    // this.setState({cityInput: inputValue})
    // return inputValue
  };
  render() {
    return (
      <div class="container mb-40 lg:mb-80">
        {/* {# App Header #} */}
        <div class="view-header">
          <h1 class="view-title">Licenses</h1>
        </div>

        {/* {# Search #} */}
        <SearchLicenses
          searchTerm={this.state.selectSearchTerm}
          handleSearchChange={this.handleSearchChange}
          placeholder={
            window.screen.width > 600
              ? "Search by track, artist, project, license number, etc..."
              : "Search by track, artist, Or Project..."
          }
          projectOptions={this.state.projectSearchOptions}
          orderNumberOptions={this.state.orderNumberOptions}
          licenseNumberOptions={this.state.licenseNumberOptions}
          activeOption={this.state.activeOption}
          altChangeSearchInput={this.altChangeSearchInput}
          onClick={this.onClick}
          searchKeyDown={this.searchKeyDown}
          onKeyDown={this.onKeyDown}
          artistOptions={this.state.artistSearchOptions}
          trackOptions={this.state.trackSearchOptions}
          changeSearchInput={this.changeSearchInput}
          searchInput={this.state.searchInput}
        />
        <SortBar
          sortFilters={licenseFilters}
          sortBarId={"custLicenses"}
          resultsText={`Showing ${
            this.state.filteredCustLicenses[this.state.currentPage].length
          } Licenses of ${this.state.totalLicenses}`}
          sortCriteria={this.state.sortText}
          marginClasses={"mb-30"}
          toggleSortTippy={this.toggleSortTippy}
          isSortTippyOpen={this.state.isSortTippyOpen}
          sortBy={this.sortBy}
          sortTippyCB={this.toggleSortTippy}
        />

        {/* {# Sort Bar #} */}
        {/* {{ sort.results(
                    'Date Approved',
                    '8 Tracks'
                ) }} */}

        {/* // {# Track List #} */}
        <div class="track-list-container mb-40">
          {this.state.custLicenses.length > 0 || !this.state.loaded ? (
            <>
              {this.state.loaded ? (
                this.state.filteredCustLicenses[this.state.currentPage]?.length > 0 ? (
                  this.state.filteredCustLicenses[this.state.currentPage].map((custLic) => {
                    let license = JSON.parse(custLic.info).license;
                    //

                    return (
                      <TrackListItemLicense
                        key={"custLicenseTrack-" + custLic.id}
                        {...custLic}
                        license={license}
                        idKey={`artist-${custLic.artistId}-album-${custLic.albumId}-track-${custLic.trackId}-license-${custLic.id}-element-customerLicenseItem`}
                        orderDate={custLic.createdOn}
                        initiallyOpen={
                          this.state.openOrder === custLic.orderNumber ||
                          this.state.openOrder === custLic.licenseNumber
                        }
                      />
                    );
                  })
                ) : (
                  <div
                    className="text-h4 text-white"
                    style={{ textAlign: "center", padding: "60px" }}
                  >
                    <p>Sorry, your search resulted in no matches.</p>
                  </div>
                )
              ) : (
                <div style={{ height: "300px" }}>
                  <BackdropOverlay loading={!this.state.loaded} size={40} />
                </div>
              )}
            </>
          ) : (
            <NavLink to="/music" class="prompt cursor-pointer">
              <div class="prompt-content">
                {/* {# Icon #} */}
                <div class="text-icon-xl mb-15">
                  <span class="icon-add-track-lg"></span>
                </div>
                {/* {# Heading #} */}
                <h3 class="prompt-title">You have no licenses</h3>
                {/* {# Description #} */}
                <p class="max-w-sm">
                  Once you purchase a license for a track it will appear here along with the ability
                  to download the full uncompressed tracks.
                </p>
              </div>
            </NavLink>
          )}
          {/* // {% for track in tracks.slice(0,8) %}
                    //     {% include "components/tracks/list-card-license.html" %}
                    // {% endfor %} */}
        </div>
        <Pagination
          pageLimit={12}
          currentPage={this.state.currentPage}
          totalRecords={this.state.totalLicenses}
          gotoPage={this.gotoPage}
        />
        {/* // {# Pagination #} */}
        {/* // {% include "components/ui/pagination.html" %} */}
      </div>
    );
  }
}
CustomerLicenses.contextType = PlayerContext;
export default CustomerLicenses;

import React, { useEffect, useState } from "react";
import { 
    LabelRequiredVisible, 
    LabelOptionalVisible 
} from '../FormElements/labels';
import { Controller, useForm } from "react-hook-form";
import { joiResolver } from '@hookform/resolvers/joi';
import InputMask from "react-input-mask";
import { Error, Success } from "../Toast/index";
import LoadingOverlay from 'react-loading-overlay'
import { WaveSpinner } from "react-spinners-kit"
import BackdropOverlayComponent from "../LoadingOverlay/backdropComponent"
import api from "../utils/api";
import { handleAPIError } from "../../utils/handleAPIError.js"

const Joi = require('joi')
    .extend(require('@joi/date'));

const fullYear = new Date().getFullYear();
const shortYear = Number(fullYear?.toString().substr(-2));

const schema = Joi.object({
    cardName: Joi.string()
        .required()
        .messages({
            "any.required": "Please enter the name on the card",
            "string.empty": "Please enter the name on the card",
        }),
    // cardCvc: Joi.string()
    //     .min(3)
    //     .max(4)
    //     .messages({
    //         "any.required": "Please enter the CVC",
    //         "number.empty": "Please enter the CVC",
    //     })
    //     .label('CVC'),
    cardExpiry: Joi.date()
        .greater('now')
        .format("MM/YY")
        .messages({
            "date.greater": "Expiration date must be in the future.",
            "date.format": "Expiration date must be in MM/YY format."
        }),
    cardNick: Joi.any()
});

export default function EditBlock(props) {
    const { control, register, handleSubmit, formState, setError, errors } = useForm({
        mode: "onSubmit",
        reValidateMode: 'onChange',
        defaultValues: {
            cardName: props.cardName,
            cardNick: props.cardNick,
            expDate: props.exp
        },
        resolver: joiResolver(schema),
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: true,
    });

    const [isLoading, setisLoading] = useState(false);
    const [isDisabled, setisDisabled] = useState(false);

    useEffect(() => {
        // Show all errors as Toastify
        // console.log(Object.entries(errors));
        Object.entries(errors).map((error) => (
            Error({
                message: error[1].message,
                toastId: error[0]
            })
        ))
    },[errors]);

    const onSubmit = (data, event) => {
    	setisLoading(true);
    	setisDisabled(true);

        const updateCardData = {
            paymentMethodId: props.paymentId,
            cardName: data.cardName,
            cardExpiry: data.cardExpiry,
            cardNickName: data.cardNick
        }

        api.updateCard(updateCardData)
        .then(res => {
            if (res.data?.success) {
                // window.location.reload()
                Success({
                    message: res.data?.message
                })
                props.toggleEdit();
               // 
            }
        })
        .catch(error => {
            handleAPIError(error);
            setisLoading(false);
            setisDisabled(false);
        })
    }

    // console.log('errors', errors);

    return(
        <LoadingOverlay
            className={"app-root"}
            active={false}
            spinner={
                <WaveSpinner 
                    size={30} 
                    color="#fff" 
                    loading={false}
                />
            }
        >
            <div className="bg-gray-100 h-full rounded-lg p-20 md:p-30">
                
                <form onSubmit={handleSubmit(onSubmit)} className="ds-form">
                    <div className="grid-row-xs">
                        {isLoading &&
                            <BackdropOverlayComponent loading={true} size={40}/>
                        }

                        <div className="col w-full">
                            <div className="input-group input-inline mb-20">
                                <input 
                                    id="card_number" 
                                    name="cardNumber"
                                    disabled
                                    className="form-input stripe-card" 
                                    value={`•••• •••• •••• ${props.lastFour}`}
                                />
                                <LabelOptionalVisible for="card_number">
                                    Card Number
                                </LabelOptionalVisible>
                            </div>
                        </div>
                        
                        <div className="col w-full">
                            <div className="input-group input-inline mb-20">
                                <input 
                                    id="card_name" 
                                    name="cardName"
                                    ref={register}
                                    className="form-input stripe-card" 
                                    placeholder="John Smith"
                                />
                                <LabelRequiredVisible for="card_name">
                                    Name on Card
                                </LabelRequiredVisible>
                            </div>
                        </div>

                        <div className="col w-full">
                            <div className="input-group input-inline mb-20">
                                <Controller
                                    as={InputMask}
                                    control={control}
                                    mask="99/99"
                                    maskChar=""
                                    alwaysShowMask={false}
                                    name="cardExpiry"
                                    id="card_expiry" 
                                    className="form-input stripe-card" 
                                    placeholder="MM/YY"
                                    defaultValue={props.exp}
                                />
                                <LabelRequiredVisible for="card_exp">
                                    Card Expiration
                                </LabelRequiredVisible>
                            </div>
                        </div>

                        <div className="col w-full">
                            <div className="input-group input-inline mb-20">
                                <input 
                                    name="cardNick"
                                    id="card_nick" 
                                    ref={register}
                                    className="form-input stripe-card" 
                                    placeholder="John's Credit Card"
                                />
                                <LabelOptionalVisible for="card_nick">
                                    Card Nickname
                                </LabelOptionalVisible>
                            </div>
                        </div>

                        <div className="col w-full sm:w-1/2">
                            <div className="input-group input-inline mb-20 sm:mb-0">
                                <button 
                                    type="button" 
                                    onClick={props.toggleEdit}
                                    className="w-full btn form-btn btn-stroke"
                                >
                                    Discard
                                </button>
                            </div>
                        </div>

                        <div className="col w-full sm:w-1/2">
                            <div className="input-group input-inline mb-10">
                                <button 
                                    type="button" 
                                    className="w-full btn form-btn btn-primary" 
                                    onClick={handleSubmit(onSubmit)}
                                    disabled={isDisabled}
                                >
                                    Save Card
                                </button>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        </LoadingOverlay>
    );
}
import axios from "axios";
let ajaxReq = null;

export default {
  //user routes
  logoutUser: function () {
    return axios({
      method: "put",
      url: "/api/users/logout",
    });
  },
  checkSession: function () {
    return axios({
      method: "get",
      url: "/api/users/checkSession",
    });
  },
  checkPassword: function (password) {
    return axios({
      method: "put",
      url: "/api/users/checkPassword",
      data: {
        password: password,
      },
    });
  },
  getUsers: function () {
    return axios({
      method: "get",
      url: "/api/users/",
    });
  },
  getArtistsByName: function (name) {
    return axios({
      method: "get",
      url: "/api/users/artistName/" + name,
    });
  },
  getArtistPhotoFromURL: function (artistURL) {
    return axios({
      method: "get",
      url: "/api/users/photoFromURL/" + artistURL,
    });
  },
  getMyDetails: function (fields) {
    return axios({
      method: "get",
      url: "/api/users/me",
      params: fields,
    });
  },
  confirmEmail: function (encryptedEmail) {
    return axios({
      method: "put",
      url: "/api/users/confirmEmail/" + encryptedEmail,
    });
    // const Crypter = require('cryptr');
    // const cryptr = new Crypter(process.env.CRYPTER_SECRET);
    // let decryptedEmail = cryptr.decrypt(encryptedEmail)
    //
  },
  signUp: function (userData) {
    return axios({
      method: "post",
      url: "/api/users",
      data: userData,
    });
  },
  signUpComplete: function (userData) {
    return axios({
      method: "post",
      url: "/api/users/signUpComplete",
      data: userData,
    });
  },
  resendConfirmEmail: function (userData) {
    return axios({
      method: "post",
      url: "/api/users/resendConfirmEmail",
      data: userData,
    });
  },
  forgotPassword: function (email) {
    return axios({
      method: "put",
      url: "/api/users/forgotPW",
      data: email,
    });
  },
  validateReset: function (token) {
    return axios({
      method: "get",
      url: "/api/users/validateReset/" + token,
    });
  },
  resetPassword: function (newPasswordReq) {
    return axios({
      method: "put",
      url: "/api/users/resetPassword",
      data: newPasswordReq,
    });
  },
  updatePassword: function (newPasswordReq) {
    return axios({
      method: "put",
      url: "/api/users/updatePassword",
      data: newPasswordReq,
    });
  },
  stripeOnboardStart: function () {
    return axios({
      method: "get",
      url: "/api/users/stripe/onboard/start",
    });
  },
  stripeOnboardComplete: function () {
    return axios({
      method: "put",
      url: "/api/users/stripe/onboard/complete",
    });
  },
  getCustPaymentMethods: function () {
    return axios({
      method: "get",
      url: "/api/users/stripe/getMethods",
    });
  },
  updateMyDetails: function (details) {
    return axios({
      method: "put",
      url: "/api/users/me",
      data: details,
    });
  },
  updateProfilePhoto: function (photo) {
    return axios({
      method: "put",
      url: "/api/users/uploadProfilePhoto",
      data: photo,
    });
  },
  removeProfilePhoto: function (photo) {
    return axios({
      method: "put",
      url: "/api/users/removeProfilePhoto",
    });
  },
  updateCoverPhoto: function (photo) {
    return axios({
      method: "put",
      url: "/api/users/uploadCoverPhoto",
      data: photo,
    });
  },
  removeCoverPhoto: function (photo) {
    return axios({
      method: "put",
      url: "/api/users/removeCoverPhoto",
    });
  },
  updateBillingAddress: function (newAddress) {
    return axios({
      method: "put",
      url: "/api/users/updateBillingAddress",
      data: newAddress,
    });
  },
  changeEmail: function (emailRequest) {
    return axios({
      method: "put",
      url: "/api/users/changeEmail",
      data: emailRequest,
    });
  },
  confirmEmailChange: function (emailChangeData) {
    return axios({
      method: "put",
      url: "/api/users/confirmEmailChange",
      data: emailChangeData,
    });
  },
  updateSignupStatus: function (userData) {
    return axios({
      method: "put",
      url: "/api/users/updateSignupStatus",
      data: userData,
    });
  },
  addProRecord: function (proRequest) {
    return axios({
      method: "post",
      url: "/api/pros/",
      data: proRequest,
    });
  },
  editProRecord: function (proId, proRequest) {
    return axios({
      method: "put",
      url: "/api/pros/" + proId,
      data: proRequest,
    });
  },
  getMyPublisher: function () {
    return axios({
      method: "get",
      url: "/api/pros/myPublisher",
    });
  },
  updateMyPro: function (proRequest) {
    return axios({
      method: "post",
      url: "/api/pros/myPro",
      data: proRequest,
    });
  },
  getArtistByURL: function (artistURL) {
    return axios({
      method: "get",
      url: "/api/users/byArtistURL/" + artistURL,
    });
  },
  getArtistForDetailPage: function (artistURL) {
    return axios({
      method: "get",
      url: "/api/users/artistDetail/" + artistURL,
    });
  },
  getAllArtists: function (query) {
    return axios({
      method: "get",
      url: "/api/users/allArtists?" + query,
    });
  },
  getArtistsByIds: function (ids) {
    return axios({
      method: "get",
      url: "/api/users/multiple/" + ids,
    });
  },
  myFavorites: function () {
    return axios({
      method: "get",
      url: "/api/users/myFavorites",
    });
  },
  //invite routes
  getInvites: function () {
    return axios({
      method: "get",
      url: "/api/invites",
    });
  },
  getInvite: function (token) {
    return axios({
      method: "get",
      url: "/api/invites/" + token,
      data: {
        token: token,
      },
    });
  },
  getInviteByUser: function (user_id) {
    return axios({
      method: "get",
      url: "/api/invites/byUser/" + user_id,
      data: {
        user_id: user_id,
      },
    });
  },
  inviteUsers: function (emails, role) {
    return axios({
      method: "post",
      url: "/api/invites",
      data: {
        emails: emails,
        role: role,
      },
    });
  },
  //composer routes
  getMyComposers: function () {
    return axios({
      method: "get",
      url: "/api/composers/my",
    });
  },
  getDSComps: function () {
    return axios({
      method: "get",
      url: "/api/composers/deepsoundsComps",
    });
  },
  createComposerTrack: function (trackId, composerBody) {
    return axios({
      method: "post",
      url: "/api/composers/composerTrack/" + trackId,
      data: composerBody,
    });
  },
  removeComposerTrack: function (cTId) {
    return axios({
      method: "put",
      url: "/api/composers/composerTrack/remove/" + cTId,
    });
  },
  editComposerTrack: function (cTId, composerBody) {
    return axios({
      method: "put",
      url: "/api/composers/composerTrack/" + cTId,
      data: composerBody,
    });
  },
  composerEmailCheck: function (email) {
    if (ajaxReq) {
      ajaxReq.cancel();
    }
    ajaxReq = axios.CancelToken.source();
    return axios({
      method: "get",
      url: "/api/composers/emailCheck/" + email,
      cancelToken: ajaxReq.token,
    });
  },

  //album routes
  getMyAlbums: function () {
    return axios({
      method: "get",
      url: "/api/albums/my",
    });
  },
  getMyAlbumsFull: function () {
    return axios({
      method: "get",
      url: "/api/albums/myFull",
    });
  },
  getArtistAlbumsForAdmin: function (artistId) {
    return axios({
      method: "get",
      url: "/api/albums/artistAlbums/" + artistId,
    });
  },
  getAlbumsByArtist: function (artistId) {
    return axios({
      method: "get",
      url: "/api/albums/artist/" + artistId,
    });
  },
  createNewAlbum: function (newAlbum) {
    return axios({
      method: "post",
      url: "/api/albums/",
      data: newAlbum,
    });
  },
  createNewAlbumOnBehalfOf: function (artistId, newAlbum) {
    return axios({
      method: "post",
      url: "/api/albums/onBehalf/" + artistId,
      data: newAlbum,
    });
  },
  updateAlbumArt: function (albumId, albumArt) {
    return axios({
      method: "put",
      url: "/api/albums/uploadAlbumArt/" + albumId,
      data: albumArt,
    });
  },
  likeAlbum: function (albumId) {
    return axios({
      method: "put",
      url: "/api/albums/likeAlbum/" + albumId,
    });
  },
  unlikeAlbum: function (albumId) {
    return axios({
      method: "put",
      url: "/api/albums/unlikeAlbum/" + albumId,
    });
  },
  getAlbumsByIds: function (albumIds) {
    return axios({
      method: "get",
      url: "/api/albums/multiple/" + albumIds,
    });
  },
  //track routes
  getTracks: function (query) {
    return axios({
      method: "get",
      url: "/api/tracks?" + query,
    });
  },
  // getAllTracks: function(query){
  //     return axios({
  //         method: 'get',
  //         url: '/api/tracks/allTracks?' + query
  //     })
  // },
  getAllTracks: function (query) {
    return axios({
      method: "get",
      url: "/api/tracks?" + query,
    });
  },
  getTracksByName: function (name) {
    return axios({
      method: "get",
      url: "/api/tracks/trackTitle/" + name,
    });
  },
  editTrackById: function (trackId, trackBody, context, tags, keywords) {
    return axios({
      method: "put",
      url: "/api/tracks/" + trackId,
      data: { ...trackBody, context, tags: tags, keywords: keywords },
    });
  },
  getTrackById: function (trackId) {
    return axios({
      method: "get",
      url: "/api/tracks/" + trackId,
    });
  },
  getTrackForMediaPlayer: function (trackId) {
    return axios({
      method: "get",
      url: "/api/tracks/forMediaPlayer/" + trackId,
    });
  },
  getIncompleteTrackById: function (trackId) {
    return axios({
      method: "get",
      url: "/api/tracks/incomplete/" + trackId,
    });
  },
  getTrackByTrackUrl: function (trackURL) {
    return axios({
      method: "get",
      url: "/api/tracks/trackURL/" + trackURL,
    });
  },
  getTrackForDetailPage: function (trackURL) {
    return axios({
      method: "get",
      url: "/api/tracks/trackDetailPage/" + trackURL,
    });
  },
  getTracksByIds: function (trackIds) {
    return axios({
      method: "get",
      url: "/api/tracks/multiple/" + trackIds,
    });
  },
  getTracksByArtist: function (artistId) {
    return axios({
      method: "get",
      url: "/api/tracks/artist/" + artistId,
    });
  },
  getTracksByAlbum: function (albumId) {
    return axios({
      method: "get",
      url: "/api/tracks/album/" + albumId,
    });
  },
  getMyTracks: function () {
    return axios({
      method: "get",
      url: "/api/tracks/my",
    });
  },
  getMyTracksWithFilter: function (status, query, sort, offset) {
    return axios({
      method: "get",
      url: "/api/tracks/my/" + status + "/" + query + "/" + sort + "/" + offset,
    });
  },
  getMyStats: function (offset, sort) {
    return axios({
      method: "get",
      url: "/api/tracks/myStats/" + offset + "/" + sort,
    });
  },
  getMyTagStats: function (sort) {
    return axios({
      method: "get",
      url: "/api/tracks/myTagStats/" + sort,
    });
  },
  getAudio: function (source, trackId) {
    return axios({
      method: "get",
      url: "/api/tracks/getAudio/" + source + "/" + trackId,
    });
  },
  getVersionAudio: function (source, versionId) {
    return axios({
      method: "get",
      url: "/api/tracks/getVersionAudio/" + source + "/" + versionId,
    });
  },
  checkVersion: function (id) {
    return axios({
      method: "get",
      url: "/api/tracks/checkVersion/" + id,
    });
  },
  getStemAudio: function (source, stemId) {
    return axios({
      method: "get",
      url: "/api/tracks/getStemAudio/" + source + "/" + stemId,
    });
  },
  submitTrackInfo: function (trackData) {
    return axios({
      method: "post",
      url: "/api/tracks/",
      data: trackData,
    });
  },
  uploadTrackSource: function (trackId, trackFile) {
    return axios({
      method: "put",
      url: "/api/tracks/uploadSource/" + trackId,
      data: trackFile,
      responseType: "string",
    });
  },
  reuploadTrackSource: function (trackId, trackFile) {
    return axios({
      method: "put",
      url: "/api/tracks/reuploadSource/" + trackId,
      data: trackFile,
    });
  },
  uploadTrackVersion: function (trackId, versionName, trackFile) {
    return axios({
      method: "put",
      url: "/api/tracks/uploadVersion/" + trackId + "/" + versionName,
      data: trackFile,
    });
  },
  reuploadTrackVersion: function (trackId, versionName, versionId, trackFile) {
    return axios({
      method: "put",
      url: "/api/tracks/reuploadVersion/" + trackId + "/" + versionName + "/" + versionId,
      data: trackFile,
    });
  },
  uploadTrackStem: function (trackId, stemName, trackFile, uuid) {
    return axios({
      method: "put",
      url: "/api/tracks/uploadStem/" + trackId + "/" + stemName + "/" + uuid,
      data: trackFile,
    });
  },
  reuploadTrackStem: function (trackId, stemName, stemId, trackFile, uuid) {
    return axios({
      method: "put",
      url: "/api/tracks/reuploadStem/" + trackId + "/" + stemName + "/" + stemId + "/" + uuid,
      data: trackFile,
    });
  },
  increasePlayCount: function (trackId) {
    return axios({
      method: "put",
      url: "/api/tracks/increasePlayCount/" + trackId,
    });
  },
  getAudioFromKey: function (s3Key, trackId) {
    return axios({
      method: "get",
      url: "/api/tracks/getAudioFromKey/" + trackId + "/" + s3Key,
    });
  },
  approveTrack: function (trackId, trackInfo) {
    return axios({
      method: "put",
      url: "/api/tracks/approveTrack/" + trackId,
      data: trackInfo,
    });
  },
  createVersionMp3: function (versionId) {
    return axios({
      method: "put",
      url: "/api/tracks/createVersionMp3/" + versionId,
    });
  },
  createStemMp3: function (stemId) {
    return axios({
      method: "put",
      url: "/api/tracks/createStemMp3/" + stemId,
    });
  },
  rejectTrack: function (trackId, rejectionBody, trackBody, rejectionComment) {
    return axios({
      method: "put",
      url: "/api/tracks/rejectTrack/" + trackId,
      data: {
        rejectionBody: rejectionBody,
        trackBody: trackBody,
        rejectionComment: rejectionComment,
      },
    });
  },
  removeTrack: function (trackId) {
    return axios({
      method: "put",
      url: "/api/tracks/removeTrack/" + trackId,
    });
  },
  removeSource: function (trackId) {
    return axios({
      method: "put",
      url: "/api/tracks/removeTrackSource/" + trackId,
    });
  },
  getComposersByTrack: function (trackId) {
    return axios({
      method: "get",
      url: "/api/composers/track/" + trackId,
    });
  },
  likeTrack: function (trackId) {
    return axios({
      method: "put",
      url: "/api/tracks/likeTrack/" + trackId,
    });
  },
  unlikeTrack: function (trackId) {
    return axios({
      method: "put",
      url: "/api/tracks/unlikeTrack/" + trackId,
    });
  },

  downloadWatermarked: function (trackId) {
    return axios({
      method: "get",
      url: "/api/tracks/downloadWatermarked/" + trackId,
    });
  },
  getMyTotalApprovedTracks: function () {
    return axios({
      method: "get",
      url: "/api/tracks/getMyTotalApprovedTracks",
    });
  },
  featureTrack: function ({ trackId, isFeatured }) {
    return axios({
      method: "post",
      url: `/api/tracks/${trackId}/feature`,
      data: { isFeatured },
    });
  },
  //tag routes
  getTags: function () {
    return axios({
      method: "get",
      url: "/api/tags/",
    });
  },
  getTagsByType: function () {
    return axios({
      method: "get",
      url: "/api/tags/groupedByType",
    });
  },
  getUsedTags: function () {
    return axios({
      method: "get",
      url: "/api/tags/used",
    });
  },
  getCities: function (entry) {
    return axios({
      method: "get",
      url: "/api/tags/cities/" + entry,
    });
  },
  getTagsByName: function (tagName) {
    return axios({
      method: "get",
      url: "/api/tags/byName/" + tagName,
    });
  },
  updateTag: function (tagId) {
    return axios({
      method: "put",
      url: "/api/tags/" + tagId,
    });
  },
  //keyword routes
  getKeywords: function (entry) {
    return axios({
      method: "get",
      url: "/api/keywords/" + entry,
    });
  },
  //project routes
  submitNewProject: function (projectData) {
    return axios({
      method: "post",
      url: "/api/projects",
      data: projectData,
    });
  },
  getProjectById: function (projectId) {
    return axios({
      method: "get",
      url: "/api/projects/" + projectId,
    });
  },
  getProjectByShareUrl: function (shareUrl) {
    return axios({
      method: "get",
      url: "/api/projects/shareUrl/" + shareUrl,
    });
  },
  updateProjectById: function (projectId, projectBody) {
    return axios({
      method: "put",
      url: "/api/projects/" + projectId,
      data: projectBody,
    });
  },
  getMyProjects: function () {
    return axios({
      method: "get",
      url: "/api/projects/my",
    });
  },
  submitSceneTrackVote: function (sceneTrackId, projectId, voteValue) {
    return axios({
      method: "post",
      url: "/api/projects/submitVote/" + sceneTrackId + "/" + projectId,
      data: voteValue,
    });
  },
  updateSceneTrackVote: function (voteTrackId, voteValue) {
    return axios({
      method: "put",
      url: `/api/projects/updateVote/${voteTrackId}`,
      data: voteValue,
    });
  },
  addSceneToProject: function (projectId, sceneBody) {
    return axios({
      method: "post",
      url: "/api/projects/addScene/" + projectId,
      data: sceneBody,
    });
  },
  markProjectComplete: function (projectId) {
    return axios({
      method: "put",
      url: "/api/projects/markComplete/" + projectId,
    });
  },
  markProjectIncomplete: function (projectId) {
    return axios({
      method: "put",
      url: "/api/projects/markIncomplete/" + projectId,
    });
  },
  updateProjectArt: function (projectId, projectArt) {
    return axios({
      method: "put",
      url: "/api/projects/updateCoverArt/" + projectId,
      data: projectArt,
    });
  },
  updateProjectCoverArt: function (projectId, projectArt) {
    return axios({
      method: "put",
      url: "/api/projects/updateRealCoverArt/" + projectId,
      data: projectArt,
    });
  },
  removeProjectArt: function (projectId) {
    return axios({
      method: "put",
      url: "/api/projects/removeCoverArt/" + projectId,
    });
  },
  removeProjectCoverArt: function (projectId) {
    return axios({
      method: "put",
      url: "/api/projects/removeRealCoverArt/" + projectId,
    });
  },
  duplicateProject: function (projectId) {
    return axios({
      method: "post",
      url: "/api/projects/duplicateProject/" + projectId,
    });
  },
  leaveProject: function (projectId) {
    return axios({
      method: "put",
      url: "/api/projects/leaveProject/" + projectId,
    });
  },
  inviteCollaborator: function (projectId, email) {
    return axios({
      method: "put",
      url: "/api/projects/inviteCollaborator/" + projectId,
      data: email,
    });
  },
  confirmInvite: function (encryptedEmail) {
    return axios({
      method: "get",
      url: "/api/projects/confirmInvite/" + encryptedEmail,
    });
  },
  respondToInvite: function (projectId, accepted) {
    return axios({
      method: "put",
      url: "/api/projects/respondToInvite/" + projectId,
      data: accepted,
    });
  },
  removeCollaborator: function (collaboratorId, projectId) {
    return axios({
      method: "put",
      url: "/api/projects/removeCollaborator/" + collaboratorId + "/" + projectId,
    });
  },
  addTrackToExistingProject: function (projectId, sceneId, trackId) {
    return axios({
      method: "post",
      url: "/api/projects/addTrack/" + projectId + "/" + sceneId + "/" + trackId,
    });
  },
  addTrackToNewProject: function (projectBody, trackId) {
    return axios({
      method: "post",
      url: "/api/projects/addTrackAndCreateProj/" + trackId,
      data: projectBody,
    });
  },
  removeTrackFromProject: function (projectId, sceneTrackId) {
    return axios({
      method: "put",
      url: "/api/projects/removeTrack/" + projectId + "/" + sceneTrackId,
    });
  },
  deleteProject: function (projectId) {
    return axios({
      method: "put",
      url: "/api/projects/deleteProject/" + projectId,
    });
  },
  latestProjects: function () {
    return axios({
      method: "get",
      url: "/api/projects/latest",
    });
  },
  //projectLinkRoutes
  generateProjectLink: function (projectId) {
    return axios({
      method: "post",
      url: "/api/projects/generateProjectLink/" + projectId,
    });
  },
  updateProjectLink: function (projectLinkId, projectLinkBody) {
    return axios({
      method: "put",
      url: "/api/projects/updateProjectLink/" + projectLinkId,
      data: projectLinkBody,
    });
  },
  checkProjectPW: function (projectLink, password) {
    return axios({
      method: "put",
      url: "/api/projects/shareURL/auth/" + projectLink,
      data: { password: password },
    });
  },
  //scene routes
  editScene: function (sceneId, sceneBody) {
    return axios({
      method: "put",
      url: "/api/scenes/editScene/" + sceneId,
      data: sceneBody,
    });
  },
  removeScene: function (sceneId) {
    return axios({
      method: "delete",
      url: "/api/scenes/removeScene/" + sceneId,
    });
  },
  updateSceneOrder: function (sceneId, weight) {
    return axios({
      method: "put",
      url: "/api/scenes/updateSceneOrder/" + sceneId,
      data: weight,
    });
  },
  //payment intent routes
  submitPaymentIntent: function () {
    return axios({
      method: "post",
      url: "/api/paymentIntents/create",
    });
  },
  setUpIntent: function (paymentId) {
    return axios({
      method: "post",
      url: "/api/paymentIntents/addCard",
      data: { paymentId: paymentId },
    });
  },
  submitCardPayment: function (purchaseDetails) {
    return axios({
      method: "post",
      url: "/api/paymentIntents/submitCardPayment",
      data: { purchaseDetails },
    });
  },
  submitACHPayment: function (purchaseDetails) {
    return axios({
      method: "post",
      url: "/api/paymentIntents/submitACHPayment",
      data: { purchaseDetails },
    });
  },
  getApplePayPrice: function (licenses, licenseeInfo, promoCode) {
    return axios({
      method: "post",
      url: "/api/paymentIntents/applePayPrice",
      data: { licenses: licenses, licenseeInfo: licenseeInfo, promoCode: promoCode },
    });
  },
  getApplePayPaymentIntent: function (licenses, licenseeInfo, purchaseOrder, vatNumber, promoCode) {
    return axios({
      method: "post",
      url: "/api/paymentIntents/applePayPaymentIntent",
      data: {
        licenses: licenses,
        licenseeInfo: licenseeInfo,
        purchaseOrder: purchaseOrder,
        vatNumber: vatNumber,
        promoCode: promoCode,
      },
    });
  },
  submitSubscriptionPayment: function (purchaseDetails) {
    return axios({
      method: "post",
      url: "/api/paymentIntents/submitSubscriptionPayment",
      data: { purchaseDetails },
    });
  },
  getPromo: function (promoCode) {
    return axios({
      method: "get",
      url: "/api/paymentIntents/getPromo/" + promoCode,
    });
  },
  getSubscriptionPromo: function (promoCode, productId) {
    return axios({
      method: "get",
      url: "/api/paymentIntents/getPromoForSubscription/" + promoCode + "/" + productId,
    });
  },
  submitCardPaymentForSubscription: function (
    paymentId,
    subscriptionChoice,
    billingInfo,
    purchaseOrder,
    vatNumber,
    promoCode
  ) {
    return axios({
      method: "post",
      url: "/api/paymentIntents/submitCardPaymentSubscription",
      data: {
        paymentId: paymentId,
        subscriptionChoice: subscriptionChoice,
        billingInfo: billingInfo,
        purchaseOrder: purchaseOrder,
        vatNumber: vatNumber,
        promoCode: promoCode,
      },
    });
  },
  previewProration: function (subscriptionId) {
    return axios({
      method: "post",
      url: "/api/paymentIntents/previewProration",
      data: { subscriptionId: subscriptionId },
    });
  },
  previewProrationWithPromo: function (subscriptionId, couponId) {
    return axios({
      method: "post",
      url: "/api/paymentIntents/previewProrationWithPromo",
      data: { subscriptionId: subscriptionId, couponId: couponId },
    });
  },
  upgradeSubscription: function (
    paymentId,
    upgradeId,
    prorationDate,
    originalPrice,
    finalPrice,
    vatNumber,
    purchaseOrder
  ) {
    return axios({
      method: "put",
      url: "/api/paymentIntents/upgradeSubscription",
      data: {
        paymentId: paymentId,
        upgradeId: upgradeId,
        prorationDate: prorationDate,
        originalPrice: originalPrice,
        finalPrice: finalPrice,
        vatNumber: vatNumber,
        purchaseOrder: purchaseOrder,
      },
    });
  },
  removePaymentMethod: function (methodId) {
    return axios({
      method: "put",
      url: "/api/users/stripe/detachMethod/" + methodId,
    });
  },
  updateCard: function (updateCardData) {
    return axios({
      method: "post",
      url: "/api/users/stripe/updatePaymentMethod/" + updateCardData.paymentMethodId,
      data: { updateCardData },
    });
  },
  makePrimary: function (methodId) {
    return axios({
      method: "put",
      url: "/api/users/stripe/makePrimary/" + methodId,
    });
  },
  getStripePublishableKey: function () {
    return axios({
      method: "get",
      url: "/api/users/stripe/getStripePublishableKey",
    });
  },
  getStripeAccount: function () {
    return axios({
      method: "get",
      url: "/api/users/stripe/getStripeAccount",
    });
  },
  getBankAccount: function () {
    return axios({
      method: "get",
      url: "/api/users/stripe/getBankAccount",
    });
  },
  removeVersion: function (versionId) {
    return axios({
      method: "delete",
      url: "/api/tracks/removeVersion/" + versionId,
    });
  },
  removeStem: function (stemId) {
    return axios({
      method: "delete",
      url: "/api/tracks/removeStem/" + stemId,
    });
  },
  getMyLikedTracks: function () {
    return axios({
      method: "get",
      url: "/api/playlists/getLiked",
    });
  },
  //event routes
  addNewEvent: function (eventData) {
    return axios({
      method: "post",
      url: "/api/events/",
      data: eventData,
    });
  },
  getMyEvents: function () {
    return axios({
      method: "get",
      url: "/api/events/my",
    });
  },
  editEvent: function (eventData, eventId) {
    return axios({
      method: "put",
      url: "/api/events/" + eventId,
      data: eventData,
    });
  },
  removeEvent: function (eventId) {
    return axios({
      method: "put",
      url: "/api/events/remove/" + eventId,
    });
  },
  //follower routes
  followArtist: function (artistId, followBody) {
    return axios({
      method: "put",
      url: "/api/followers/follow/" + artistId,
      data: followBody,
    });
  },
  getFollowing: function () {
    return axios({
      method: "get",
      url: "/api/followers",
    });
  },
  getFollowingByID: function (artistId) {
    return axios({
      method: "get",
      url: "/api/followers/" + artistId,
    });
  },
  //search Routes
  saveNewSearch: function (searchBody) {
    return axios({
      method: "post",
      url: "/api/searches",
      data: searchBody,
    });
  },
  saveActionToSearch: function (searchId, action) {
    return axios({
      method: "put",
      url: "/api/searches/" + searchId,
      data: action,
    });
  },
  getAllSearches: function () {
    return axios({
      method: "get",
      url: "/api/searches",
    });
  },
  //project types
  getAllProjectTypes: function () {
    return axios({
      method: "get",
      url: "/api/projectTypes",
    });
  },
  //collection routes
  getAllAdminCollections: function () {
    return axios({
      method: "get",
      url: "/api/collections/",
    });
  },
  getAllPublicCollections: function () {
    return axios({
      method: "get",
      url: "/api/collections/public",
    });
  },
  getFeaturedCollections: function () {
    return axios({
      method: "get",
      url: "/api/collections/featured",
    });
  },
  getFreeCollection: function () {
    return axios({
      method: "get",
      url: "/api/collections/free",
    });
  },
  getAdminCollection: function (collectionId) {
    return axios({
      method: "get",
      url: "/api/collections/admin/" + collectionId,
    });
  },
  getPublicCollection: function (collectionId) {
    return axios({
      method: "get",
      url: "/api/collections/public/" + collectionId,
    });
  },
  getRelatedCollections: function (collectionId) {
    return axios({
      method: "get",
      url: "/api/collections/related/" + collectionId,
    });
  },
  submitNewCollection: function (collectionData) {
    return axios({
      method: "post",
      url: "/api/collections/",
      data: collectionData,
    });
  },
  updateCollection: function (collectionId, collectionData) {
    return axios({
      method: "put",
      url: "/api/collections/" + collectionId,
      data: collectionData,
    });
  },
  updateCollectionCardArt: function (collectionId, cardArt) {
    return axios({
      method: "put",
      url: "/api/collections/updateCardArt/" + collectionId,
      data: cardArt,
    });
  },
  updateCollectionCoverArt: function (collectionId, removeCoverArt) {
    return axios({
      method: "put",
      url: "/api/collections/updateCoverArt/" + collectionId,
      data: removeCoverArt,
    });
  },
  addTrackToNewCollection: function (collectionBody, trackId) {
    return axios({
      method: "post",
      url: "/api/collections/addTrackAndCreateColl/" + trackId,
      data: collectionBody,
    });
  },
  addTrackToExistingCollection: function (collectionId, trackId) {
    return axios({
      method: "post",
      url: "/api/collections/addTrack/" + collectionId + "/" + trackId,
    });
  },
  removeTrackFromCollection: function (collectionTrackId) {
    return axios({
      method: "delete",
      url: "/api/collections/removeTrack/" + collectionTrackId,
    });
  },
  makeFeatured: function (collectionId) {
    return axios({
      method: "put",
      url: "/api/collections/makeFeatured/" + collectionId,
    });
  },
  makeUnfeatured: function (collectionId) {
    return axios({
      method: "put",
      url: "/api/collections/makeUnfeatured/" + collectionId,
    });
  },
  updateFeaturedOrder: function (featuredId, featuredWeight) {
    return axios({
      method: "put",
      url: "/api/collections/updateFeaturedOrder/" + featuredId,
      data: featuredWeight,
    });
  },
  updateCollectionTrackOrder: function (collectionTrackId, weight) {
    return axios({
      method: "put",
      url: "/api/collections/updateTrackOrder/" + collectionTrackId,
      data: weight,
    });
  },
  publishCollection: function (collectionId) {
    return axios({
      method: "put",
      url: "/api/collections/publish/" + collectionId,
    });
  },
  unpublishCollection: function (collectionId) {
    return axios({
      method: "put",
      url: "/api/collections/unpublish/" + collectionId,
    });
  },
  followCollection: function (collectionId) {
    return axios({
      method: "put",
      url: "/api/collections/follow/" + collectionId,
    });
  },
  unfollowCollection: function (collectionId) {
    return axios({
      method: "put",
      url: "/api/collections/unfollow/" + collectionId,
    });
  },
  getCollectionsByIds: function (collectionIds) {
    return axios({
      method: "get",
      url: "/api/collections/public/multiple/" + collectionIds,
    });
  },
  getCollectionsByName: function (collectionName) {
    return axios({
      method: "get",
      url: "/api/collections/collectionName/" + collectionName,
    });
  },
  getAllLicensesForCheckout: function () {
    return axios({
      method: "get",
      url: "/api/licenseMain/",
    });
  },
  // getMostRecentMethod: function(){
  //     return axios({
  //         method: 'get',
  //         url: '/api/purchases/mostRecentMethod'
  //     })
  // },
  //cart
  getMyActiveCart: function () {
    return axios({
      method: "get",
      url: "/api/carts/myActive",
    });
  },
  removePromo: function () {
    return axios({
      method: "put",
      url: "/api/carts/removePromo",
    });
  },
  plaidCreateLinkToken: function () {
    return axios({
      method: "post",
      url: "/api/plaid/createLinkToken",
    });
  },
  plaidExchangeToken: function (plaidData) {
    return axios({
      method: "post",
      url: "/api/plaid/exchangeLinkToken",
      data: plaidData,
    });
  },
  getCompletePurchase: function (orderNumber) {
    return axios({
      method: "get",
      url: "/api/purchases/completePurchase/" + orderNumber,
    });
  },
  //customer licenses
  getCustLicense: function (licenseNumber) {
    return axios({
      method: "get",
      url: "/api/customerLicenses/find/" + licenseNumber,
    });
  },
  getMyCustLicenses: function (offset, query) {
    return axios({
      method: "get",
      url: "/api/customerLicenses/my/" + offset + "/" + query,
    });
  },
  getMyCustLicenseCount: function (query) {
    return axios({
      method: "get",
      url: "/api/customerLicenses/totalLicenseCount/" + query,
    });
  },
  getLicenseAutoComplete: function () {
    return axios({
      method: "get",
      url: "/api/customerLicenses/licenseAutoComplete",
    });
  },
  getMySales: function (offset) {
    return axios({
      method: "get",
      url: "/api/customerLicenses/mySales/" + offset,
    });
  },
  getMyTotalSales: function () {
    return axios({
      method: "get",
      url: "/api/customerLicenses/myTotalSales",
    });
  },
  getCustLicenseByTrack: function (trackId) {
    return axios({
      method: "get",
      url: "/api/customerLicenses/byTrack/" + trackId,
    });
  },
  //download routes
  downloadMainTrack: function (licenseId) {
    return axios({
      method: "get",
      url: "/api/downloads/mainTrack/" + licenseId,
    });
  },
  adminMainTrackDownload: function (trackId) {
    return axios({
      method: "get",
      url: "/api/downloads/adminMainTrack/" + trackId,
    });
  },
  downloadAllVersions: function (licenseId) {
    return axios({
      method: "get",
      url: "/api/downloads/allVersions/" + licenseId,
    });
  },
  downloadAllStems: function (licenseId) {
    return axios({
      method: "get",
      url: "/api/downloads/allStems/" + licenseId,
    });
  },
  downloadEverything: function (licenseId) {
    return axios({
      method: "get",
      url: "/api/downloads/everything/" + licenseId,
    });
  },
  downloadVersionWAV: function (versionId) {
    return axios({
      method: "get",
      url: "/api/downloads/versionWAV/" + versionId,
    });
  },
  downloadVersionMP3: function (versionId) {
    return axios({
      method: "get",
      url: "/api/downloads/versionMP3/" + versionId,
    });
  },
  adminDownloadFullVersion: function (versionId) {
    return axios({
      method: "get",
      url: "/api/downloads/adminFullVersion/" + versionId,
    });
  },
  adminDownloadStem: function (stemId) {
    return axios({
      method: "get",
      url: "/api/downloads/adminFullStem/" + stemId,
    });
  },
  downloadStemWAV: function (stemId) {
    return axios({
      method: "get",
      url: "/api/downloads/stemWAV/" + stemId,
    });
  },
  downloadStemMP3: function (stemId) {
    return axios({
      method: "get",
      url: "/api/downloads/stemMP3/" + stemId,
    });
  },
  //stripe account routes
  stripeBalance: function () {
    return axios({
      method: "get",
      url: "/api/users/myStripeBalance",
    });
  },
  deepsoundsStripeBalance: function () {
    return axios({
      method: "get",
      url: "/api/users/deepsoundsStripeBalance",
    });
  },
  populateTags: function () {
    return axios({
      method: "post",
      url: "/api/tags/insertCityTags",
    });
  },
  //approval route
  initiateApproval: function (trackId, trackBody, tags, keywords) {
    return axios({
      method: "put",
      url: "/api/tracks/initiateApproval/" + trackId,
      data: { ...trackBody, tags: tags, keywords: keywords },
    });
  },
  mainTrackFileFilter: function (trackId) {
    return axios({
      method: "put",
      url: "/api/tracks/mainTrackFileFilter/" + trackId,
    });
  },
  mainTrackVirusScan: function (trackId) {
    return axios({
      method: "put",
      url: "/api/tracks/mainTrackVirusScan/" + trackId,
    });
  },
  mainTrackWaterMark: function (trackId) {
    return axios({
      method: "put",
      url: "/api/tracks/mainTrackWatermark/" + trackId,
    });
  },
  mainTrackMP3Encode: function (trackId) {
    return axios({
      method: "put",
      url: "/api/tracks/mainTrackMP3Encode/" + trackId,
    });
  },
  mainTrackMP3Preview: function (trackId) {
    return axios({
      method: "put",
      url: "/api/tracks/mainTrackMP3Preview/" + trackId,
    });
  },
  mainTrackGenerateWaveform: function (trackId) {
    return axios({
      method: "put",
      url: "/api/tracks/mainTrackGenerateWaveform/" + trackId,
    });
  },
  versionFileFilter: function (trackId, versionId) {
    return axios({
      method: "put",
      url: "/api/tracks/versionFileFilter/" + trackId + "/" + versionId,
    });
  },
  versionVirusScan: function (trackId, versionId) {
    return axios({
      method: "put",
      url: "/api/tracks/versionVirusScan/" + trackId + "/" + versionId,
    });
  },
  versionMP3Encode: function (trackId, versionId) {
    return axios({
      method: "put",
      url: "/api/tracks/versionMP3Encode/" + trackId + "/" + versionId,
    });
  },
  versionWatermark: function (trackId, versionId) {
    return axios({
      method: "put",
      url: "/api/tracks/versionWatermark/" + trackId + "/" + versionId,
    });
  },
  versionWaveform: function (trackId, versionId) {
    return axios({
      method: "put",
      url: "/api/tracks/versionWaveform/" + trackId + "/" + versionId,
    });
  },
  stemFileFilter: function (trackId, stemId) {
    return axios({
      method: "put",
      url: "/api/tracks/stemFileFilter/" + trackId + "/" + stemId,
    });
  },
  stemVirusScan: function (trackId, stemId) {
    return axios({
      method: "put",
      url: "/api/tracks/stemVirusScan/" + trackId + "/" + stemId,
    });
  },
  stemMP3Encode: function (trackId, stemId) {
    return axios({
      method: "put",
      url: "/api/tracks/stemMP3Encode/" + trackId + "/" + stemId,
    });
  },
  stemWaveform: function (trackId, stemId) {
    return axios({
      method: "put",
      url: "/api/tracks/stemWaveform/" + trackId + "/" + stemId,
    });
  },
  rejectForInvalidFileType: function (trackId, rejectionComment, rejectionBody) {
    return axios({
      method: "put",
      url: "/api/tracks/rejectForInvalidFile/" + trackId,
      data: { rejectionBody: rejectionBody, rejectionComment: rejectionComment },
    });
  },
  rejectForVirusDetected: function (trackId, rejectionComment, rejectionBody) {
    return axios({
      method: "put",
      url: "/api/tracks/rejectForVirusDetected/" + trackId,
      data: { rejectionBody: rejectionBody, rejectionComment: rejectionComment },
    });
  },
  restartApproval: function (trackId) {
    return axios({
      method: "put",
      url: "/api/tracks/restartApproval/" + trackId,
    });
  },
  finalizeApproval: function (trackId) {
    return axios({
      method: "put",
      url: "/api/tracks/finalizeApproval/" + trackId,
    });
  },
  setTrackBroken: function (trackId) {
    return axios({
      method: "put",
      url: "/api/tracks/setTrackBroken/" + trackId,
    });
  },
  resetApprovalSteps: function (trackId) {
    return axios({
      method: "put",
      url: "/api/tracks/resetApprovalSteps/" + trackId,
    });
  },
  sendLogToBackend: function (log) {
    return axios({
      method: "put",
      url: "/api/paymentIntents/testApplePay/",
      data: { log: log },
    });
  },
  getDefaultXML: function () {
    return axios({
      method: "get",
      url: "/api/xml/default",
    });
  },
  //subscriptions
  getSubscriptionOptions: function () {
    return axios({
      method: "get",
      url: "/api/subscriptions/options",
    });
  },
  getCompleteSubscription: function (orderNumber) {
    return axios({
      method: "get",
      url: "/api/subscriptionPurchases/complete/" + orderNumber,
    });
  },
  generateSubscriptionPayout: function (month) {
    return axios({
      method: "post",
      url: "/api/subscriptionPayouts/generatePayoutFor/" + month,
    });
  },
  recalculatePayoutFor: function (payoutId) {
    return axios({
      method: "post",
      url: "/api/subscriptionPayouts/recalculatePayoutFor/" + payoutId,
    });
  },
  getAllSubscriptionPayouts: function () {
    return axios({
      method: "get",
      url: "/api/subscriptionPayouts/",
    });
  },
  getSubscriptionPayout: function (payoutId) {
    return axios({
      method: "get",
      url: "/api/subscriptionPayouts/" + payoutId,
    });
  },
  getArtistSubscriptionPayouts: function (subscriptionPayoutId) {
    return axios({
      method: "get",
      url: "/api/subscriptionPayouts/artistSubscriptionPayouts/" + subscriptionPayoutId,
    });
  },
  checkoutAvailable: function () {
    return axios({
      method: "get",
      url: "/api/paymentIntents/checkoutAvailable",
    });
  },
  checkStripeId: function () {
    return axios({
      method: "get",
      url: "/api/users/checkStripeId",
    });
  },
  approveSubscriptionPayout: function (payoutId, password) {
    return axios({
      method: "put",
      url: "/api/subscriptionPayouts/approvePayout/" + payoutId,
      data: { password: password },
    });
  },
  unapproveSubscriptionPayout: function (payoutId, password) {
    return axios({
      method: "put",
      url: "/api/subscriptionPayouts/unapprovePayout/" + payoutId,
      data: { password: password },
    });
  },
  approveArtistSubscriptionPayouts: function (payoutId) {
    return axios({
      method: "put",
      url: "/api/subscriptionPayouts/approveArtistSubscriptionPayout/" + payoutId,
    });
  },
  retrySubscriptionPayout: function (payoutId, password) {
    return axios({
      method: "put",
      url: "/api/subscriptionPayouts/retryPayout/" + payoutId,
      data: { password: password },
    });
  },
  retryAllArtistSubscriptionPayouts: function (payoutId) {
    return axios({
      method: "put",
      url: "/api/subscriptionPayouts/retryAllArtistSubscriptionPayout/" + payoutId,
    });
  },
  getMySubscriptionPayouts: function () {
    return axios({
      method: "get",
      url: "/api/subscriptionPayouts/my",
    });
  },
  approvePayoutAndDistribute: function (payoutId) {
    return axios({
      method: "put",
      url: "/api/subscriptionPayouts/distributeArtistFeesImmediately/" + payoutId,
    });
  },
  getFeaturedArtists: function () {
    return axios({
      method: "get",
      url: "/api/users/featured",
    });
  },
  getTrackInfo: function (trackIds) {
    return axios({
      method: "get",
      url: "/api/tracks/getTrackInfo?trackIds=" + encodeURIComponent(JSON.stringify(trackIds)),
    });
  },
  getVersionInfo: function (versionIds) {
    return axios({
      method: "get",
      url:
        "/api/tracks/getVersionInfo?versionIds=" + encodeURIComponent(JSON.stringify(versionIds)),
    });
  },
  getStemInfo: function (stemIds) {
    return axios({
      method: "get",
      url: "/api/tracks/getStemInfo?stemIds=" + encodeURIComponent(JSON.stringify(stemIds)),
    });
  },
  createQuoteRequest: function (data) {
    return axios({
      method: "post",
      url: "/api/quoteRequests",
      data,
    });
  },
  getYoutubeChannel: function (params) {
    return axios({
      method: "get",
      url: "/api/google/youtube_channels",
      params,
    });
  },
  createChannelWhitelist: function (data) {
    return axios({
      method: "post",
      url: "/api/contentID/youtube_whitelists",
      data,
    });
  },
  getContentIDChannelByUser: function () {
    return axios({
      method: "get",
      url: "/api/contentID/youtube_whitelists/currentUser",
    });
  },
  createContentIDCode: function (data) {
    return axios({
      method: "post",
      url: "/api/contentID/content_id_codes",
      data,
    });
  },
  getContentIDCode: function (params) {
    return axios({
      method: "get",
      url: "/api/contentID/content_id_codes/currentUser",
      params,
    });
  },
};

import moment from "moment";
import React, { Component, createRef } from "react";
import LoadingOverlay from "react-loading-overlay";
import { WaveSpinner } from "react-spinners-kit";
import WaveSurfer from 'wavesurfer.js';
import { PlayerContext } from "../../PlayerContext";


var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment)
// import { PlayerContext } from "../../PlayerContext";
let nowPlaying;
class TrackListBrowseNoInteract extends Component {
    state = {
        src: this.props.src || "",
        peaks: this.props.peaks,
        currentTime: "0:00",
        duration: "0:00",
        sendPlay: 0,
        volume: 1,
        ready: false
    }


  waveReady = ()=>{}

  wrapper = createRef()
  componentDidMount() {
    nowPlaying=this.context.mediaPlayer.trackId
    this.$el = this.wrapper.current
    this.$waveform = this.$el.querySelector('.wave')
    this.wavesurfer = WaveSurfer.create({
        container: this.$waveform,
        normalize: true,
        barMinHeight: 1,
        barWidth: 1,
        barGap: 2,
        hideScrollbar: true,
        interact: false,
        cursorWidth: 0,
        backend: "MediaElement",
        fillParent: true,
        height: 2.1875 * (parseFloat(getComputedStyle(document.documentElement).fontSize)),
        // barHeight: 3,
        progressColor: "rgba(255,255,255,1)",
        responsive: true,
        waveColor: "rgba(255,255,255,.4)",
        xhr:{ cache: 'default', mode: 'cors', method: 'GET', credentials: 'same-origin', redirect: 'follow', referrer: 'client', headers: [ { key: 'Authorization', value: 'my-token' } ]}
        // fillParent: true
        // fillParent: true,
    })
    this.waveReady = this.wavesurfer.on('ready', ()=>{
      console.log("wave ready")
      this.setState({ready: true})
    })
    this.wavesurferError = this.wavesurfer.on('error', (error)=>{console.log("wave error ", error)})
    this.wavesurfer.backend.peaks = JSON.parse(this.props.peaks)
    this.wavesurfer.drawBuffer()
    this.setState({ready: true})
  }
  songFinished = ()=>{}
  songLoaded = ()=>{}
  wavesurferError = ()=>{}
  currentTimeInt = ()=>{}
  componentDidUpdate = (prevProps, prevState, snapshot)=>{
 }

  componentWillUnmount() {

  }
  render() {
    return (
      <div style={{width: "100%"}}>

  <div ref={this.wrapper} className="" style={{width: "100%"}}>

<div style={{backgroundColor: "rgba(255, 0, 0, 0.0)", width: "100%" }} className='waveform' onClick={()=>{if(this.props.user ===null && window.location.pathname.indexOf('/projects/public/') ===-1)this.props.triggerLoginModal()}}>
  <div className='wave'/>
</div>
</div>
</div>

    )
  }
}

TrackListBrowseNoInteract.defaultProps = {
  src: "",
  peaks: "",
  currentTime: 0
}

TrackListBrowseNoInteract.contextType = PlayerContext

export default TrackListBrowseNoInteract

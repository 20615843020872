import React, { Component } from "react";
import PendingListItem from "./pendingListItem";

class PendingTracks extends Component {
    state = {
        myAlbums: [],
        trackTitle: "",
        trackList: [],
        mediaUrl: "",
        trackId: null,
        sourceToGet: "preview",
        peaks: "[]"
    }
    toggleTrackTippy = ()=>{
        this.setState({showTrackTippy: !this.state.showTrackTippy})
    }

    render() {
        return (
            // {/* {# Tracks List #} */}
                this.props.tracks?.length > 0 ?
                this.props.tracks.map( (track) => (
                       <PendingListItem {...track} user={this.props.user}/>
                )):
                <p style={{textAlign: "center"}} className="mt-25">Sorry, your search resulted in no matches.</p>

        )
      }
        
}

PendingTracks.defaultProps ={
  tracks: []
}

export default PendingTracks
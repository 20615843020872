import axios from "axios";

const contentIDAPI = {
  getAssetReports: function () {
    return axios({
      method: "get",
      url: "/api/contentID/asset_reports",
    });
  },
  getPayouts: function () {
    return axios({
      method: "get",
      url: "/api/contentID/payouts",
    });
  },
  getContentIDArtistPayouts: function () {
    return axios({
      method: "get",
      url: "/api/contentID/artistPayouts",
    });
  },
  approveAssetReport: function ({ id }) {
    return axios({
      method: "post",
      url: "/api/contentID/asset_reports/" + id + "/approve",
    });
  },
  cancelAssetReport: function ({ id }) {
    return axios({
      method: "post",
      url: "/api/contentID/asset_reports/" + id + "/cancel",
    });
  },
  approvePayout: function ({ quarter }) {
    return axios({
      method: "post",
      url: "/api/contentID/payouts/" + quarter + "/approve",
    });
  },
  cancelPayout: function ({ id }) {
    return axios({
      method: "post",
      url: "/api/contentID/payouts/" + id + "/cancel",
    });
  },
  saveAssetReport: function ({ assetReport }) {
    return axios({
      method: "post",
      url: "/api/contentID/asset_reports",
      data: { assetReport },
    });
  },
};
export default contentIDAPI;

import React, { useState } from "react";
import { useForm } from "react-hook-form";
import CharacterCount from "../FormElements/characterCount";
import CircularIndeterminateButton from '../Loading/circularIndeterminantButton';
import { Error, Success, SuccessRedirect } from "../Toast/index";
import api from '../utils/api';

export default function BioForm(props) {
    const [formUnsaved, setFormUnsaved] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [isDisabled, setisDisabled] = useState(false);

    const { register, handleSubmit, watch,} = useForm();

    const onSubmit = (data, e) => {
    	setisLoading(true);
    	setisDisabled(false);
    	

        if(data.bio.length >= 170 && data.bio.length <= 750){
            api.updateMyDetails({bio: data.bio})
                .then(res=>{
                    
                    let userData = res.data;
                    
                    userData.signupStatus = 5;
                    api.updateSignupStatus(userData)
                        .then(signupStatusRes => {
                            
                            if (signupStatusRes?.data?.signupStatus === 5) {
                                if (props.nextStep) {
                                    SuccessRedirect({message: "Artist Bio saved", onClose: ()=>window.location.replace(props.nextStep) })
                                }
                                else {
                                    Success({message: "Artist Bio updated"})
                                }
                            }
                        })
                        .catch(error=>{
                            Error({message: "Error updating your account"})
                        })
                    setFormUnsaved(false)
                })
                .catch(error=>{
                    Error({message: "Error updating Artist Bio"})
                })
        }
        else{
            
            if(data.bio.length < 170){
                Error({
                    message: "Artist Bio too short",
                    toastId: 'bio-short'
                })
            }
            else if (data.bio.length > 750) {
                Error({
                    message: "Artist Bio too long",
                    toastId: 'bio-long'
                })
            }
        }

    };

    const watchBio = watch("bio");
    // 

    return (
        <form onSubmit={handleSubmit(onSubmit)} className="ds-form">
            <div className="form-section">
                <div className="input-group input-inline">
                    <label className="input-label" htmlFor="bio">Bio</label>
                    
                    <textarea 
                        defaultValue={props.bio} 
                        name="bio" 
                        id="text_area" 
                        className="form-textarea" 
                        rows="5" 
                        placeholder="Enter your artist bio here..."
                        ref={register}
                    >
                    </textarea>
                    <CharacterCount
                        min="170"
                        limit="750"
                        value={watchBio?.length}
                    />
                </div>
                <button 
                    className="btn btn-primary w-full"
                    type="submit"
                    disabled={isLoading}
                >
                    {isLoading ?
                        <CircularIndeterminateButton forceCenter />
                        :
                        (props.buttonText ?
                            props.buttonText
                        :
                            'Update Artist Bio'         
                        )
                    }
                    
                </button>
            </div>
        </form>
    )
}
import React, { useState } from "react";
import contentIDAPI from "../../../api/contentID";
import currencyFormatter from "../../../utils/currencyFormatter";
import DataTable from "../../UI/DataTable";
import LoadingButton from "../../FormElements/LoadingButton";
import { Success, Error } from "../../Toast";
import { confirm } from "../../confirmModal";
const AssetReportsRow = ({ monthStr, assetReport }) => {
  const {
    id,
    status: defaultStatus,
    total_assets,
    total_net_earnings_artists,
    total_net_earnings_deepsounds,
    assetReportItems,
  } = assetReport || {};
  // console.log("assetReportItems", assetReportItems);

  const [isLoaded, setIsLoaded] = useState(true);
  const [status, setStatus] = useState(defaultStatus);
  const [showDetail, setShowDetail] = useState(false);

  const toggleDropdown = () => {
    setShowDetail(!showDetail);
  };

  const approveAssetReport = async () => {
    setIsLoaded(false);
    if (
      await confirm(
        `Are you sure you want to approve ${monthStr} and send notices to artists?`,
        "Approve"
      )
    ) {
      contentIDAPI
        .approveAssetReport({ id })
        .then((response) => {
          console.log("approveAssetReport response", response);
          const { assetReport, message, success } = response?.data || {};
          const { status } = assetReport || {};
          setStatus(status);
          setIsLoaded(true);
          success ? Success({ message }) : Error({ message });
        })
        .catch((error) => {
          console.log("approveAssetReport error", error);
          setIsLoaded(true);
          Error({ message: "There was an error approving the Asset Report." });
        });
    } else {
      setIsLoaded(true);
    }
  };

  const cancelAssetReport = async () => {
    setIsLoaded(false);
    if (
      await confirm(
        `Are you sure you want to cancel ${monthStr}? Artists may be confused.`,
        "Cancel"
      )
    ) {
      contentIDAPI
        .cancelAssetReport({ id })
        .then((response) => {
          console.log("cancelAssetReport response", response);
          const { status } = response?.data?.contentIDPayout || {};
          setStatus(status);
          setIsLoaded(true);
          Success({ message: "Asset Report cancelled." });
        })
        .catch((error) => {
          console.log("cancelAssetReport error", error);
          setIsLoaded(true);
          Error({ message: "There was an error cancelling the Asset Report." });
        });
    } else {
      setIsLoaded(true);
    }
  };

  const assetReportItemsFormatted = assetReportItems?.map((item) => {
    return {
      asset_title: item.asset_title,
      writer: item.writer,
      composition_ad_earnings: currencyFormatter(item.composition_ad_earnings / 100),
      composition_subscription_earnings: currencyFormatter(
        item.composition_subscription_earnings / 100
      ),
      sound_recording_ad_earnings: currencyFormatter(item.sound_recording_ad_earnings / 100),
      total_videos_claimed: item.total_videos_claimed,
      total_views: item.total_views,
      total_gross_earnings: currencyFormatter(item.total_gross_earnings / 100),
      rate: item.rate / 100,
      deep_sounds_net_earnings: currencyFormatter(item.total_net_earnings / 100),
      artist_rate: item.artist_rate / 100,
      artist_net_earnings: currencyFormatter(item.artist_net_earnings_amount / 100),
    };
  });

  // console.log("assetReportItemsFormatted", assetReportItemsFormatted);

  return (
    <>
      <tr style={{ maxWidth: "100%" }}>
        <td className="text-white underline">
          <button onClick={toggleDropdown}>{monthStr}</button>
        </td>
        <td>{total_assets}</td>
        <td>{currencyFormatter(total_net_earnings_deepsounds / 100)}</td>
        <td>{currencyFormatter(total_net_earnings_artists / 100)}</td>
        <td>
          <LoadingButton
            className={status === "approved" ? "btn btn-stroke  w-1/2" : "btn btn-primary w-1/2"}
            isLoading={!isLoaded}
            isDisabled={status === "approved"}
            onClick={status === "approved" ? null : approveAssetReport}
          >
            {status === "approved" ? (
              <>
                <span className="icon-check"></span> Approved
              </>
            ) : (
              "Approve"
            )}
          </LoadingButton>
        </td>
      </tr>

      <tr className={showDetail ? "" : "hidden"}>
        <td colSpan="5">
          <div className="bg-gray-100 pt-20 pb-10 lg:pb-15 mb-40">
            <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
              <div className="flex-1 flex items-center mb-15 md:mb-0">
                <h3 className="text-label-lg mr-10">{monthStr} - Details by Asset</h3>
              </div>
            </div>
            {assetReportItemsFormatted?.length > 0 ? (
              <DataTable
                data={assetReportItemsFormatted}
                headerTransform={"convertSnakeToTitleCase"}
              />
            ) : null}
          </div>
        </td>
      </tr>
    </>
  );
};

export default AssetReportsRow;

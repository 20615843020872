import React, { useContext } from 'react';
import { NavLink } from "react-router-dom";
import DefaultAvatar from "../../assets/svg/user-avatar.svg";
import { UserContext } from "../../contexts/User";

function AccountName({ user }) {

  const { artistName, firstName, lastName, role } = user || {};
  const fullName = `${firstName} ${lastName}`;

  const name = (role === "artist" ? artistName : fullName);

	let fontFactor = name?.length || 15;
	if (name?.length < 15) {
		fontFactor = 15;
	}
	return (
		<span style={{fontSize: (15 / fontFactor * 95) + "%"}}>
			{name}
		</span>
	)
}

function SideNavAccount() {

    function resolvePhotoLink(userPhotoKey) {

        const key = userPhotoKey
        const ext = key.split('.').reverse()[0];
        let final = key.replace('.' + ext, '') + '-minified-60x60.' + ext
        return final
    }

	const userData = useContext(UserContext);
	const user = userData?.user;

        return(

			<div className="user-account">

				<NavLink to={user?.userURL ? user.userURL : "/dashboard"} className="user-account-link">
					<img className="user-account-img" src={user ? (user.profilePhoto ? `https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${resolvePhotoLink(user.profilePhoto)}`: DefaultAvatar) : DefaultAvatar} alt=""/>
					<div className="user-account-info">
						<div className="user-account-name">
							<AccountName user={user} />
						</div>
						<div className="user-account-type">
							{user ? user.role?.charAt(0).toUpperCase() + user.role?.slice(1) : ""}
						</div>
					</div>
				</NavLink>
			</div>
        )
}
export default SideNavAccount;

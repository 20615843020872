import React, { Component } from 'react';
import DefaultAvatar from '../../assets/svg/user-avatar.svg';

class ProjectVotes extends Component{
    resolveProfilePhoto=(profilePhotoKey)=>{
        const key = profilePhotoKey
        if(profilePhotoKey){
            const ext = key.split('.').reverse()[0];
            let final = key.replace('.' + ext, '') + '-minified-60x60'  + '.' + ext
            return final
        }
    }   
    render(){
        return(
            <div class="tab-item-content pt-20 pb-0 active">
                <div class="grid-row-sm">
                {/* {% for user in users.slice(0,5) %} */}
                    {this.props.users.map(user=>{
                        return(
                            <div class="col">
                            <div class="user-account">
                                {user.profilePhoto ?
                                    <img class="user-account-img" src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveProfilePhoto(user.profilePhoto)}`} alt={user.firstName +  " "  + user.lastName}/>:
                                    <img class="user-account-img" src={DefaultAvatar} alt={user.firstName +  " "  + user.lastName}/>
                                }
                                <div class="user-account-info">
                                    <div class="user-account-name">{user.firstName +  " "  + user.lastName}</div>
                                </div>
                            </div>
                        </div>
                        )
                    })}
                   
                {/* {% endfor %} */}
            </div>
        </div>
        )
    }
}

export default ProjectVotes;
import throttle from "lodash.throttle";
import moment from "moment";
import React, { Component, createRef } from "react";
import "react-sliding-pane/dist/react-sliding-pane.css";
import 'react-tippy/dist/tippy.css';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/src/plugin/regions/index.js';
import { PlayerContext } from "../../PlayerContext";
import { Error, Success } from "../Toast/index";
import "./videoStudioDetails.css";



const userAgent = window.navigator.userAgent.toLowerCase();
const isMobileSafari = (/iphone/.test(userAgent) || /ipad/.test(userAgent) || (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2));
// console.log('user agent ', userAgent)
// console.log("is mobile safari ", isMobileSafari)
const contentDis = require('content-disposition')


var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment)
// import { PlayerContext } from "../../PlayerContext";

class VideoStudioMediaPlayer extends Component {
    state = {
        src: this.props.src || "",
        peaks: this.props.peaks,
        currentTime: "0:00",
        duration: "0:00",
        // sendPlay: 0,
        volume: 1,
        fadeStyles:{
          content: "",
          background: "rgba(19,19,19,.85)",
          width: "14.5%",
          display: "block",
          position: "absoulte",
          top: "0",
          zIndex: "99",
          height: "100%"
        }
    }
    handleOnChange = (value) => {
      console.log(value)
      this.setState({
        volume: value.x
      }, ()=> this.wavesurfer.setVolume(this.state.volume)
      )
    }
  setVolume = ()=>{
    console.log("setting volume to ", this.state.volume)
  }
  toggleTrackTippy = ()=>{
    this.setState({showTrackTippy: !this.state.showTrackTippy})
}
  downloadWatermarked = ()=>{
    if(this.props.user === null){
      this.context.triggerLoginModal()
    }
    else{
      let header
        fetch('/api/tracks/downloadWatermarked/'+this.context.mediaPlayer.trackId)
              .then(resp=> {
                      if(resp.ok){
                          header = resp.headers.get('content-disposition');
                          //read the stream as a blob
                          return resp.blob()
                      }
                      else{
                          throw new Error("Error Downloading File")
                      }
                  })
              .then(blob=>{
                  //create a dummy element to download the file from the blob
                  let downloadElem = document.createElement('a')
                  const url = URL.createObjectURL(blob, {type: "audio/mpeg"})
                  let filename
                  if (header) {
                      const parsed = contentDis.parse(header);

                      if (parsed.parameters && parsed.parameters.filename) {
                          filename =parsed.parameters.filename;
                      }
                  }
                  downloadElem.setAttribute('href', url)
                  downloadElem.setAttribute('download', filename || "filename" )
                  downloadElem.style.display = 'none';
                  console.log(downloadElem)
                  document.body.appendChild(downloadElem);

                  downloadElem.click();
                  URL.revokeObjectURL(url)
                  document.body.removeChild(downloadElem);
              })
              .catch(err=>Error({message: "Error Downloading File"}));
    }
    this.setState({showTrackTippy: false})
  }

  wrapper = createRef()
  componentDidMount() {
    this.$el = this.wrapper.current
    this.$waveform = this.$el.querySelector('.wave')
    console.log(this.$waveform)


    this.wavesurfer = WaveSurfer.create({
        container: this.$waveform,
        normalize: true,
        barMinHeight: 1,
        plugins: [
          RegionsPlugin.create({
            // plugin options ...
          })
        ],
        barWidth: 1,
        barGap: 2,
        hideScrollbar: true,
        interact: true,
        cursorWidth: 0,
        backend: "MediaElement",
        height: 80,
        progressColor: "rgba(255,255,255,1)",
        responsive: true,
        waveColor: "rgba(255,255,255,.5)",
        xhr:{ cache: 'default', mode: 'cors', method: 'GET', credentials: 'same-origin', redirect: 'follow', referrer: 'client', headers: [ { key: 'Authorization', value: 'my-token' } ]}
        // fillParent: true
        // fillParent: true,
    })

    // console.log(this.props.peaks)
    console.log(this.wavesurfer)
    this.wavesurfer.on('ready', ()=>{
      this.setState({ready: true})
    })

    console.log("current peaks ", this.context.mediaPlayer.peaks)

    // this.wavesurfer.drawer.removeEventListener('click', (e, progress) => {
    //   setTimeout(() => this.seekTo(progress), 0);
    // })
    console.log("click handlers ", this.wavesurfer.drawer.handlers.click)
    this.wavesurfer.drawer.un('click', this.wavesurfer.drawer.handlers.click[0]);
    this.wavesurfer.drawer.on('click', (e, progress)=>{
      e.preventDefault()
      let regionKey = Object.keys(this.context.waveFormEl?.regions?.list)[0]
      let regionRef = this.context.waveFormEl?.regions?.list[regionKey]
      let regionStart = regionRef.start
      let regionEnd = regionRef.end
      if(progress *this.context.waveFormEl.getDuration() > regionStart && progress *this.context.waveFormEl.getDuration() < regionEnd){
        // this.props.emitToOthers(progress * this.wavesurfer.getDuration(), "track", this.context.mediaPlayer.idKey);
        this.props.seekTo(progress, this.context.waveFormEl.getDuration(), regionStart, regionEnd)
        this.context.waveFormEl.seekTo(progress)
      }else{
        return
      }
    })
    if(this.context.waveFormEl?.regions?.list){

      let regionKey = Object.keys(this.context.waveFormEl?.regions?.list)[0]
      if(regionKey){
        let regionRef = this.context.waveFormEl?.regions?.list[regionKey]
        let regionStart = regionRef.start
        let regionEnd = regionRef.end
        let leftOffset = regionStart/this.context.waveFormEl.getDuration()
        let rightOffset = (this.context.waveFormEl.getDuration()-regionEnd)/this.context.waveFormEl.getDuration()
        let waveContainer = document.getElementById('trackDetailsWaveform')
        waveContainer.style.setProperty('--leftwidth', `${leftOffset * 100}%`)
        waveContainer.style.setProperty('--rightwidth', `${rightOffset * 100}%`)
      }

    }

    // this.playerClicked = this.wavesurfer.on('click', (e, progress)=>{
    //   console.log("waveform clicked ", e , " ", progress)
    // })
    this.playerSeek = this.wavesurfer.on('seek',(seekTime)=>{
      if(seekTime <= 0.02 && this.context.mediaPlayer.currentTime/this.wavesurfer.getDuration() > 0.02){
        // this.props.emitToOthers(0, "track", this.context.mediaPlayer.idKey);
        if(this.wavesurfer.getCurrentTime() !==0){
          this.wavesurfer.seekTo(0)
        }
      }
      else if(seekTime - this.context.mediaPlayer.currentTime/this.wavesurfer.getDuration() > .02 || seekTime - this.context.mediaPlayer.currentTime/this.wavesurfer.getDuration() < -.02 ){
        let regionKey = Object.keys(this.context.waveFormEl?.regions?.list)[0]
        let regionRef = this.context.waveFormEl?.regions?.list[regionKey]
        let regionStart = regionRef.start
        let regionEnd = regionRef.end
        if(seekTime *this.context.waveFormEl.getDuration() > regionStart && seekTime *this.context.waveFormEl.getDuration() < regionEnd){
          // this.props.emitToOthers(seekTime * this.wavesurfer.getDuration(), "track", this.context.mediaPlayer.idKey);
          // this.wavesurfer.seekTo(seekTime)
        }else{

        }

      }
    })

    // console.log('src ', this.props.src, ' peaks ', this.props.peaks, ' peaks length ', JSON.parse(this.props.peaks).length)
    if(this.props.peaks.length){
      this.wavesurfer.load(this.props.src, JSON.parse(this.props.peaks))
    }
    if(this.props.src){
      // this.wavesurfer.playPause()
    }

  }
  audioProcessFunction = throttle(()=>{
    this.context.updateTime(this.wavesurfer.getCurrentTime())
    // this.props.emitToOthers(this.wavesurfer.getCurrentTime(), "track", this.context.mediaPlayer.idKey);
  }, 50)
  trackPlayTime = ()=>{}
  mobileTrackPlayTime = ()=>{}
  trackFinished = ()=>{}
  regionFinished = ()=>{}
  songLoaded = ()=>{}
  wavesurferError = ()=>{}
  currentTimeInt = ()=>{}
  componentDidUpdate = (prevProps, prevState, snapshot)=>{
    if(this.context.waveFormEl?.regions?.list){
      let regionKey = Object.keys(this.context.waveFormEl?.regions?.list)[0]
      if(regionKey){
        let regionRef = this.context.waveFormEl?.regions?.list[regionKey]
        let regionStart = regionRef.start
        let regionEnd = regionRef.end
        let leftOffset = regionStart/this.context.waveFormEl.getDuration()
        let rightOffset = (this.context.waveFormEl.getDuration()-regionEnd)/this.context.waveFormEl.getDuration()
        let waveContainer = document.getElementById('trackDetailsWaveform')
        waveContainer.style.setProperty('--leftwidth', `${leftOffset * 100}%`)
        waveContainer.style.setProperty('--rightwidth', `${rightOffset * 100}%`)
      }
    }
    let duration;
    if(this.context.waveFormEl){
      duration = this.context.waveFormEl.getDuration()
    }
      this.updateWaveformFromMain()
 }
 updateWaveformFromMain =()=>{
  if(this.context.waveFormEl){
    let duration = this.context.waveFormEl.getDuration()
    if(!isNaN(duration)){
      if(this.context.user){
        if(this.context.mediaPlayer.currentTime/duration < 1 && this.context.mediaPlayer.currentTime/duration > 0){
          let seekToValue = Number(Math.round(this.context.mediaPlayer.currentTime/duration +'e5')+'e-5')
          console.log("seeking to in details wave ", seekToValue)
          if(this.wavesurfer?.getDuration()){
            this.wavesurfer.seekTo(seekToValue)
          }
        }
        else if (this.context.mediaPlayer.currentTime/duration === 0){
          this.wavesurfer.seekTo(0)
        }
      }
      else{
        if(this.context.mediaPlayer.currentTime/duration < 1 && this.context.mediaPlayer.currentTime/duration > 0){
          console.log("seeking to in details wave", this.context.mediaPlayer?.currentTime/duration)
          let seekToValue = Number(Math.round(this.context.mediaPlayer.ratioCurrentTime/duration +'e5')+'e-5')
          console.log("seeking to in details wave ", seekToValue)
          if(this.wavesurfer?.getDuration()){
            this.wavesurfer.seekTo(seekToValue)
          }
        }
        else if (this.context.mediaPlayer.currentTime/duration === 0){
          this.wavesurfer.seekTo(0)
        }
      }
    }
  }


}
  play= ()=>{
    console.log("playing from root")
    if(Object.keys(this.context.waveFormEl?.regions?.list).length > 0){
      let regionKey = Object.keys(this.context.waveFormEl?.regions?.list)[0]
      let regionRef = this.context.waveFormEl?.regions?.list[regionKey]
      this.props.playVideo()
      // regionRef.play(regionRef.start)
    }
    else{
      this.props.playVideo()
      // this.wavesurfer.play()
    }
    this.setState({waiting: true})

  }
  // playFromTrackIn= ()=>{
  //   console.log("playing from track in")

  //     // this.props.playVideoFromIn()
  //     // this.wavesurfer.play()

  //   this.setState({ waiting: true})

  // }
  pause = ()=>{

        // this.wavesurfer.pause()
        this.props.pauseVideo()
        this.context.pauseRegion()

  }


  shareTrack = ()=>{

      var textArea = document.createElement("textarea");

      // Place in top-left corner of screen regardless of scroll position.
      textArea.style.position = 'fixed';
      textArea.style.top = 0;
      textArea.style.left = 0;

      // Ensure it has a small width and height. Setting to 1px / 1em
      // doesn't work as this gives a negative w/h on some browsers.
      textArea.style.width = '2em';
      textArea.style.height = '2em';

      // We don't need padding, reducing the size if it does flash render.
      textArea.style.padding = 0;

      // Clean up any borders.
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';

      // Avoid flash of white box if rendered for any reason.
      textArea.style.background = 'transparent';


      textArea.value = window.location.protocol + "//" +window.location.hostname + (window.location.port ? ":" + window.location.port : "") +"/" + "tracks/" + this.context.mediaPlayer.trackURL;

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand('copy');
        if(successful){
            Success({message: "Track URL Copied to Clipboard"})
        }
        else{
            Error({message: "Couldn't Copy Track URL "})
        }
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copying text command was ' + msg);
      } catch (err) {
        console.log('Oops, unable to copy');
      }

      document.body.removeChild(textArea);

      this.setState({showTrackTippy: false})


  }
  mobileShareTrack = ()=>{
  if (navigator.share) {
    navigator.share({
      title: 'Share Track',
      text: this.context.mediaPlayer.trackTitleDisplay,
      url: window.location.protocol + "//" +window.location.hostname + "/" + "tracks/" + this.context.mediaPlayer.trackURL,
    })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
  }
  else{
    var textArea = document.createElement("textarea");

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';


    textArea.value = window.location.protocol + "//" +window.location.hostname + (window.location.port ? ":" + window.location.port : "") +"/" + "tracks/" + this.context.mediaPlayer.trackURL;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      if(successful){
          Success({message: "Track URL Copied to Clipboard"})
      }
      else{
          Error({message: "Couldn't Copy Track URL "})
      }
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Copying text command was ' + msg);
    } catch (err) {
      console.log('Oops, unable to copy');
    }

    document.body.removeChild(textArea);

    this.setState({showTrackTippy: false})
  }

  }
  componentWillUnmount() {
    // this.context.pause()
    this.context.pauseRegion()
    this.context.setTrackOffsetStart()
  }
  resolveAlbumPhoto=(albumPhotoKey)=>{
    const key = albumPhotoKey
    if(albumPhotoKey){
        const ext = key.split('.').reverse()[0];
        let sizeMod
        const width = window.innerWidth
        if(width >= 1280){
            sizeMod = '140x140'
        }
        else {
            sizeMod = '140x140'
        }
        let final = key.replace('.' + ext, '') + '-minified-' + sizeMod  + '.' + ext
        return final
    }
  }
  resolveMobileAlbumPhoto=(albumPhotoKey)=>{
    const key = albumPhotoKey
    if(albumPhotoKey){
        const ext = key.split('.').reverse()[0];
        let sizeMod = '500x500'
        let final = key.replace('.' + ext, '') + '-minified-' + sizeMod  + '.' + ext
        return final
    }
  }
  handleLicenseModalOpen=()=>{
    this.setState({isLicenseModalOpen: true})
  }
  handleLicenseModalClose=()=>{
      this.setState({isLicenseModalOpen: false})
  }
  render() {
    return (
      <div style={{width: "100%"}} id="trackDetailsWaveform" className="fade-edges-details" data-leftwidth={"14.5%"} data-rightwidth={"50%"}>

  <div ref={this.wrapper} className="" style={{width: "100%"}}>

<div style={{backgroundColor: "rgba(255, 0, 0, 0.0);", width: "100%" }} className='waveform' onClick={()=>{if(this.props.user ===null)this.props.triggerLoginModal()}}>
  <div className='wave'/>
</div>
</div>
</div>

    )
  }
}

VideoStudioMediaPlayer.defaultProps = {
  src: "",
  peaks: ""
}

VideoStudioMediaPlayer.contextType = PlayerContext
export default VideoStudioMediaPlayer

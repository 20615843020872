import React, { useState } from "react";
import DataTable from "../UI/DataTable";
import { joiResolver } from "@hookform/resolvers/joi";
import { DateTime } from "luxon";
import Papa from "papaparse";
import { useForm } from "react-hook-form";
import DSForm from "./Common/DSForm";
import SelectControlled from "./Common/SelectControlled";
import useMonths from "../../hooks/useMonths";
import useYears from "../../hooks/useYears";
import contentIDAPI from "../../api/contentID";
import { Error, Success } from "../Toast";

const Joi = require("joi").extend(require("@joi/date"));

const schema = Joi.object({
  month: Joi.any(),
  year: Joi.any(),
  // month: Joi.date().required().format("MM").messages({
  //   "any.required": "Please enter the month",
  //   "date.format": "Month must be in MM format.",
  // }),
  // year: Joi.date().required().format("YYYY").messages({
  //   "any.required": "Please enter the year",
  //   "date.format": "Year must be in YYYY format.",
  // }),
});

const AssetReportForm = ({ getContentIDAssetReports, setShowForm }) => {
  const [csvDataFormatted, setCsvDataFormatted] = useState([]);
  const [csvData, setCsvData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(true);

  const defaultValues = {};

  const months = useMonths() || [];

  const monthOptions = months.map((month) => ({
    value: month,
    label: month,
  }));

  const years = useYears({ count: 3, startYear: DateTime.year }) || [];
  const yearOptions = years.map((year) => ({
    value: year,
    label: year,
  }));

  const papaParseConfig = {
    header: true,
    skipEmptyLines: true,
    complete: (results) => {
      // console.log("results.data", results.data);
      setCsvData(results.data);
    },
    transformHeader: (header) => header.toLowerCase().replaceAll(" ", "_"),
  };

  const { control, handleSubmit } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    resolver: joiResolver(schema),
    defaultValues,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = (data, e) => {
    e.preventDefault();
    setIsLoaded(false);
    console.log("data", data);
    const { month, year } = data || {};
    if (!csvData) {
      setIsLoaded(true);
      Error({ message: "Please upload a CSV file" });
      return;
    }
    const assetReport = {
      month: month.value,
      year: year.value,
      assetReportItems: csvData,
    };
    contentIDAPI
      .saveAssetReport({ assetReport })
      .then((res) => {
        console.log("res", res);
        Success({ message: "Asset Report Submitted" });
        setIsLoaded(true);
        setShowForm(false);
        getContentIDAssetReports();
      })
      .catch((error) => {
        console.log("error", error);
        Error({ message: "There was an error submitting the asset report." });
        setIsLoaded(true);
      });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const csvText = e.target.result;
        Papa.parse(csvText, papaParseConfig);
        formatCSV(csvText);
      };

      reader.readAsText(file);
    }
  };

  const formatCSV = (csvText) => {
    const lines = csvText.split("\n");
    const headers = lines[0].split(",");
    const formattedData = [];

    for (let i = 1; i < lines.length; i++) {
      const currentLine = lines[i].split(",");

      if (currentLine.length === headers.length) {
        const row = {};
        for (let j = 0; j < headers.length; j++) {
          row[headers[j].trim()] = currentLine[j].trim();
        }
        formattedData.push(row);
      }
    }

    setCsvDataFormatted(formattedData);
  };

  return (
    <>
      <h5 className="text-h5 my-20">Upload New Asset Report</h5>
      <DSForm
        formId="asset-report-form"
        handleSubmit={handleSubmit}
        isLoaded={isLoaded}
        onSubmit={onSubmit}
      >
        <div className="col w-1/2">
          <div className="input-group-xs input-inline">
            <SelectControlled
              control={control}
              id="month"
              name="month"
              options={monthOptions}
              placeholder="Month"
            />
          </div>
          <div className="input-group-xs input-inline">
            <SelectControlled
              control={control}
              id="year"
              name="year"
              options={yearOptions}
              placeholder="Year"
            />
          </div>
          {/* <div className="input-group input-inline w-1/2">
            <input
              name="month"
              id="month"
              className="form-input"
              placeholder="YYYY-MM"
              value={month}
              onChange={handleMonthChange}
              defaultValue={moment().format("Month")}
              type="month"
            />
            <label className="input-label" htmlFor="month">
              Month
            </label>
          </div> */}
        </div>
        <div className="col w-1/2">
          <div className="input-group input-inline">
            <input
              accept=".csv"
              className="form-input"
              id="csvInput"
              name="file"
              onChange={handleFileChange}
              style={{ paddingTop: "2.5rem", paddingBottom: "2.5rem" }}
              type="file"
            />
            <label className="input-label" htmlFor="csvInput">
              Asset Report CSV File
            </label>
          </div>
        </div>
      </DSForm>
      <div className="bg-gray-100 p-20 pb-10 lg:p-25 lg:pb-15 mb-40 mt-40">
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
          <div className="flex-1 flex items-center mb-15 md:mb-0">
            <h3 className="text-label-lg mr-10">Data Preview</h3>
            <span className="flex-none text-xs">Showing {csvDataFormatted?.length}</span>
          </div>
        </div>
        <DataTable data={csvDataFormatted} />
      </div>
    </>
  );
};

export default AssetReportForm;

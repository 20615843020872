import moment from "moment";
import { useMemo } from "react";

function years(count, startYear) {
  const yearList = [];
  const year = startYear || moment().year("YYYY");
  for (let i = 0; i < count; i += 1) {
    const currentYear = moment(year).subtract(i, "years");
    const currentYearFormatted = moment(currentYear).format("YYYY");
    yearList.push(currentYearFormatted);
  }
  return yearList.sort((a, b) => b - a);
}

const currentYear = moment().year("YYYY");

// A custom hook that provides years options
export default function useQuery({ count = 3, startYear = currentYear }) {
  return useMemo(() => years(count, startYear), [count, startYear]);
}

import React, { Component } from "react"
import AddToProject from "./addToProject"


class AddToProjectModal extends Component{
    
    render(){
        return(
            <React.Fragment>
                {/* <div id="add_to_project_modal" aria-hidden="true" className="modal micromodal-slide"> */}

                    {/* {# Modal Overlay #} */}
                    {/* <div tabindex="-1" data-micromodal-close="add_to_project_modal" className="modal-overlay"> */}

                        {/* {# Modal Container #} */}
                            {/* <div role="dialog" aria-modal="true" aria-labelledby="add_scene_title" className="modal-container"> */}

                                {/* {# Modal Header #} */}
                                <header className="modal-header">
                                    <h2 id="add_scene_title" className="modal-title">Add Track to Project</h2>
                                    <button aria-label="Close Modal" data-micromodal-close="add_to_project_modal" className="modal-close" type="button" onClick={this.props.handleClose}></button>
                                </header>

                                {/* {# Modal Content #} */}
                                <div id="add_scene_content" className="modal-content">
                                    <h6 className="track-title mb-30">{this.props.trackTitle}</h6>
                                    <AddToProject trackId={this.props.trackId} trackTitle={this.props.trackTitle} toggleTrackTippy={this.props.toggleTrackTippy} handleClose={this.props.handleClose}/>

                                </div>
                                {/* {# End Modal Content #} */}

                            {/* </div> */}
                        {/* {# End Modal Container #} */}
                    {/* </div> */}
                    {/* {# End Modal Overlay #} */}
                {/* </div> */}
            </React.Fragment>
        )
    }
}
export default AddToProjectModal
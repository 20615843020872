import React, { Component } from "react";
import { PlayerContext } from "../../PlayerContext";
import ArtistTrackListItem from "./artistTrackListItem";


class IncompleteTracks extends Component {
    state = {
        myAlbums: [],
        trackTitle: "",
        trackList: [],
        mediaUrl: "",
        trackId: null,
        sourceToGet: "preview",
        peaks: "[]"
    }

    render() {
        return (
            // {/* {# Tracks List #} */}
                this.props.tracks?.length > 0 ?
                this.props.tracks.map( (track) => (
                        <ArtistTrackListItem track={track} trackId={track.trackId} removeTrackCB={this.props.removeTrackCB} />
                )):
                <p style={{textAlign: "center"}} className="mt-25">Sorry, your search resulted in no matches.</p>

        )
      }
        
}

IncompleteTracks.defaultProps ={
  tracks: []
}
IncompleteTracks.contextType = PlayerContext
export default IncompleteTracks
import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { UserConsumer } from "../../contexts/User";



class AccountNav extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {

            }



    render() {
        return (
            <UserConsumer>
                {({ user, login, logout})=>(
                    <div className="tabs mb-50">
                        <ul className="tab-items js-tabs-scroll">
                            <li className={`tab-item ${this.props.path === "/dashboard/account/details" ? "active" : ""}`}>
                                <NavLink to="/dashboard/account/details" className="tab-item-title">Details</NavLink>
                            </li>
                            <li className={`tab-item ${this.props.path === "/dashboard/account/settings" ? "active" : ""}`}>
                                <NavLink to="/dashboard/account/settings" className="tab-item-title">settings</NavLink>
                            </li>
                            <li className={`tab-item ${this.props.path === "/dashboard/account/payment-methods" ? "active" : ""}`}>
                                <NavLink to="/dashboard/account/payment-methods" className="tab-item-title">Payment Methods</NavLink>
                            </li>
                            {(user?.artist || user?.admin) &&
                                <li className={`tab-item ${this.props.path === "/dashboard/account/deposit-account" ? "active" : ""}`}>
                                    <NavLink to="/dashboard/account/deposit-account" className="tab-item-title">Deposit Account</NavLink>
                                </li>
                            }
                            <li className={`tab-item ${this.props.path === "/dashboard/account/notifications" ? "active" : ""}`}>
                                <NavLink to="/dashboard/account/notifications" className="tab-item-title">Notifications</NavLink>
                            </li>
                            <li className={`tab-item ${this.props.path === "/dashboard/account/subscriptions" ? "active" : ""}`}>
                            <NavLink to="/dashboard/account/subscriptions" className="tab-item-title">Subscriptions</NavLink>
                            </li>
                        </ul>
                    </div>
                )}
            </UserConsumer>
        )
    }

}
export default AccountNav


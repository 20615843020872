import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import { PlayerContext } from "../../PlayerContext";
import numberAbbreviator from "../NumberFormatters/numberAbbreviator";
import AdminCollectionTrackTooltip from "./adminCollectionTrackTooltip";

class AdminCollectionTrack extends Component {
  state = {
    myAlbums: [],
    trackTitle: "",
    trackList: [],
    mediaUrl: "",
    trackId: null,
    sourceToGet: "preview",
    peaks: "[]",
  };
  formatNumber = (num) => {
    if (num === undefined) num = 0;
    switch (num.toString().length || 0) {
      case 0:
      case 1:
      case 2:
      case 3:
        return num;
      case 4:
        return num.toString()[0] + "." + num.toString()[1] + "k";
      case 5:
        return num.toString().substr(0, 2) + "k";
      case 6:
        return num.toString().substr(0, 3) + "k";
      default:
        return num;
    }
  };
  toggleCollectionTrackTippy = () => {
    this.setState({ showCollectionTrackTippy: !this.state.showCollectionTrackTippy });
  };
  resolveAlbumPhoto = (albumPhotoKey) => {
    const key = albumPhotoKey;
    if (albumPhotoKey) {
      const ext = key.split(".").reverse()[0];
      let sizeMod;
      let width = window.innerWidth;
      if (width >= 1280) {
        sizeMod = "140x140";
      } else if (width >= 1024 && width < 1280) {
        sizeMod = "140x140";
      } else if (width >= 760 && width < 1024) {
        sizeMod = "140x140";
      } else {
        sizeMod = "140x140";
      }
      let final = key.replace("." + ext, "") + "-minified-" + sizeMod + "." + ext;
      return final;
    }
  };
  render() {
    return (
      <div
        className={`track-list-card ${
          this.context.mediaPlayer.trackId === this.props.trackId && this.context.playing
            ? "is-playing"
            : ""
        }`}
        key={"collTrack-" + this.props.collectionTrackId}
        tracki={this.props.collectionTrackId}
      >
        {/* {# Left #} */}
        <div className="track-first-col">
          <div
            onClick={() => {
              this.context.loadTrackInMediaPlayer(this.props.trackId, this.props.idKey);
              this.context.setAutoPlay(false);
            }}
            className="track-image"
            style={{
              backgroundImage: `url('https://${
                process.env.REACT_APP_S3_PHOTO_BUCKET
              }.s3.us-east-2.amazonaws.com/${
                process.env.REACT_APP_S3_SUB_BUCKET ? process.env.REACT_APP_S3_SUB_BUCKET + "/" : ""
              }${this.resolveAlbumPhoto(this.props.imageURL)}')`,
            }}
          ></div>
          <div className="track-title-artist">
            <NavLink to={"/tracks/" + this.props.trackURL}>
              {" "}
              <span className="track-title">{this.props.trackTitle}</span>
            </NavLink>
            <NavLink to={"/artists/" + this.props.artistURL}>
              <span className="track-artist">{this.props.artistName}</span>
            </NavLink>
          </div>
          {/* <button className="icon-chevron-down"></button> */}
        </div>

        <div className="track-stats">
          <div className="track-stat-item js-tooltip" title="Plays">
            <Tooltip useContext={true} animation="shift" arrow="true" title="Plays" position="top">
              <div>
                <span className="icon-play"></span> {numberAbbreviator(this.props.plays)}
              </div>
            </Tooltip>
          </div>
          <div className="track-stat-item js-tooltip" title="Downloads">
            <Tooltip animation="shift" arrow="true" title="Downloads" position="top">
              <div>
                <span className="icon-download"></span> {numberAbbreviator(this.props.downloads)}
              </div>
            </Tooltip>
          </div>
          <div className="track-stat-item js-tooltip" title="Favorites">
            <Tooltip animation="shift" arrow="true" title="Favorites" position="top">
              <div>
                <span className="icon-like"></span> {numberAbbreviator(this.props.likes)}
              </div>
            </Tooltip>
          </div>
          <div className="track-stat-item js-tooltip" title="Projects">
            <Tooltip animation="shift" arrow="true" title="Projects" position="top">
              <div>
                <span className="icon-projects"></span> {numberAbbreviator(this.props.projects)}
              </div>
            </Tooltip>
          </div>
          <div className="track-stat-item js-tooltip" title="Licenses">
            <Tooltip animation="shift" arrow="true" title="Licenses" position="top">
              <div>
                <span className="icon-download"></span> {numberAbbreviator(this.props.purchases)}
              </div>
            </Tooltip>
          </div>
        </div>
        <div className="track-last-col">
          <div className="track-list-icons text-gray-400">
            <button class="circle-icon circle-icon-sm">
              <span class="icon-drag"></span>
            </button>
            <Tooltip
              className="circle-icon"
              arrow={false}
              open={this.state.showCollectionTrackTippy}
              interactive={true}
              useContext={true}
              position={"bottom-end"}
              trigger={"click"}
              html={
                <AdminCollectionTrackTooltip
                  collectionTrackId={this.props.collectionTrackId}
                  toggleCollectionTrackTippy={this.toggleCollectionTrackTippy}
                  removeTrack={this.props.removeTrack}
                />
              }
              allowHTML={true}
              onUntrigger={() => {
                this.state.showCollectionTrackTippy
                  ? this.setState({ showCollectionTrackTippy: false })
                  : this.setState({ showCollectionTrackTippy: true });
              }}
              onRequestClose={() => {
                this.state.showCollectionTrackTippy
                  ? this.setState({ showCollectionTrackTippy: false })
                  : this.setState({ showCollectionTrackTippy: true });
              }}
            >
              <button onClick={this.toggleCollectionTrackTippy} class="track-list-more">
                <span class="icon-dots-vert"></span>
              </button>
            </Tooltip>
          </div>
        </div>
      </div>
    );
  }
}

AdminCollectionTrack.defaultProps = {
  tracks: [],
};
AdminCollectionTrack.contextType = PlayerContext;
export default AdminCollectionTrack;

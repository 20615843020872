import { CardCvcElement, CardExpiryElement, CardNumberElement, Elements, ElementsConsumer } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { Component, useEffect, useState } from "react";
import LoadingOverlay from 'react-loading-overlay';
import { WaveSpinner } from "react-spinners-kit";
import { UserConsumer } from "../../contexts/User";
import BackdropOverlayComponent from "../LoadingOverlay/backdropComponent";
import api from "../utils/api";
import { LabelRequiredVisible, LabelOptionalVisible } from "../FormElements/labels"
import { CardField, CardExpiry, CardCvc } from "../FormElements/paymentCardElements"
import { Error } from '../Toast';
class AddBlock extends Component{
    state={
        isBlockOpen: false,
        cardNick: "",
        cardName: "",
        loading: false
    }
    handleInputChange = event => {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    };
    handleSubmit = async (event) => {
        event.preventDefault();
        this.setState({loading: true});
        const {stripe, elements} = this.props;

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
          }
          const cardElement = elements.getElement(CardNumberElement, CardExpiryElement, CardCvcElement);

          const {error, paymentMethod} = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details:{
                name: this.state.cardName
            },
            metadata:{
                nickName: this.state.cardNick
            }
          });

          if (error) {
            console.log('stripe.createPaymentMethod error', error);
            Error({
                message: error.message
            })
            this.setState({loading: false});
          }
          else {
            api.setUpIntent(paymentMethod.id)
                .then(res => {
                    // console.log('setUpIntent res', res);
                    if (res.data?.success){
                        window.location.reload()
                    }
                    else if (res.data?.failure && res.data?.declineReason) {
                        Error({
                            message: res.data?.declineReason
                        })
                        this.setState({loading: false});
                    }
                })
                .catch(error => {
                    console.log('setUpIntent error');
                })
          }
        };
    openBlock = ()=>{
        this.setState ({isBlockOpen: true}, ()=>{

        })
    }
    closeBlock = ()=>{
        this.setState({isBlockOpen: false, cardNick: "", cardName: "", loading: false})
    }
    render(){
        return(
            this.state.isBlockOpen ?
                <LoadingOverlay
                    className={"app-root"}
                    active={false}
                    spinner={<WaveSpinner size={30} color="#fff" loading={false}></WaveSpinner>}
                >
                    <div className="bg-gray-100 h-full rounded-lg p-20 md:p-30">
                        <form action="" className="ds-form">
                            <div className="grid-row-xs">
                                {this.state.loading &&
                                    <BackdropOverlayComponent
                                        loading={true}
                                        size={40}
                                    />
                                }
                                <div className="col w-full">
                                    <div className="input-group input-inline mb-20">
                                        <input
                                            id="card_name"
                                            name="cardName"
                                            className="form-input stripe-card"
                                            placeholder="John Smith"
                                            onChange={this.handleInputChange}
                                            value={this.state.cardName}
                                                />
                                        <LabelRequiredVisible for="card_name">
                                            Name on Card
                                        </LabelRequiredVisible>
                                    </div>
                                </div>

                                <CardField
                                    onChange={(e) => {
                                        this.setState({
                                            error: e.error,
                                            cardComplete: e.complete,
                                        });
                                    }}
                                />

                                <CardExpiry></CardExpiry>

                                <CardCvc></CardCvc>

                                <div className="col w-full">
                                    <div className="input-group input-inline mb-20">
                                        <input
                                            name="cardNick"
                                            id="cardNick"
                                            onChange={this.handleInputChange}
                                            value={this.state.cardNick}
                                            className="form-input stripe-card"
                                            placeholder="John's Credit Card"/>

                                        <LabelOptionalVisible for="cardNick">
                                            Card Nickname
                                        </LabelOptionalVisible>
                                    </div>
                                </div>
                                <div className="col w-full sm:w-1/2">
                                    <div className="input-group input-inline mb-20 sm:mb-0">
                                        <button type="button" onClick={this.closeBlock} className="w-full btn form-btn btn-stroke">Discard</button>
                                    </div>
                                </div>

                                <div className="col w-full sm:w-1/2">
                                    <div className="input-group input-inline mb-10">
                                        <button type="button" className="w-full btn form-btn btn-primary" onClick={this.handleSubmit}>Save Card</button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </LoadingOverlay>
                        :
            <button type="button" onClick={this.openBlock} className="add-block">
                <div className="add-block-text">
                    {this.props.text}
                </div>
                <span className="add-block-icon">
                    <span className={`${this.props.icon_class} text-icon-md`}></span>
                </span>
            </button>
        )
    }
}
export default function Injected(props=this.props) {
    const [stripe, setStripe] = useState(null)


    useEffect(() => {
        api.getStripePublishableKey()
        .then(res =>{
            //
            let stripePublishableKey = res.data.stripeKey
            loadStripe(stripePublishableKey)
            .then(res => {
                //
                setStripe(res)
            })
            .catch((error) => {
            // handle errors
        })
        })
        .catch((error) => {
            // handle errors
        })
    }, [])

    return (
        <UserConsumer>
            {({user, login, logout})=>(
                <Elements stripe={stripe}>
                    <ElementsConsumer >
                        {({stripe, elements}) => (
                            <AddBlock
                                stripe={stripe}
                                elements={elements}
                                user={user}
                                text={props.text}
                                icon_class={props.icon_class}
                            />
                        )}
                    </ElementsConsumer>
                </Elements>
            )}
        </UserConsumer>
    );
}

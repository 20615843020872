import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import MicroModal from "react-micro-modal";
import CropUtilityModal from "../modal/cropUtilityModal";
let fileInserted = false;

function AlbumArtUpload(props) {
  const [files, setFiles] = useState([]);
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    multiple: false,
    onDrop: acceptedFiles => {
      const files = acceptedFiles.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
      );
      console.log('props', props)
      const i = new Image()
        i.onload = ()=>{
          let reader = new FileReader()
          reader.readAsDataURL(files[0])
          reader.onload = () => {
            console.log({
              src: files[0].preview,
              width: i.width,
              height: i.height,
              data: reader.result
            })
          }

          if(i.width >= 1000 &&  i.height >=1000){
            setFiles(files);
            if (props.onChange) {
              props.onChange(files);
              props.openPopout()
            }
          }
          else{
            if (props.onChange) {
              props.onChange({error: "File must be at least 1000x1000px"});
            }
          }
        }
        i.src = files[0].preview
      
      
    }
  });
  const saveCrop = (files) => {
    if (files.length) {
        let file = files[0]
        console.log("saving crop ", files)
        Object.assign(file, {
          preview: URL.createObjectURL(file)
        })
        setFiles(files);
          if (props.onChange) {
            props.onChange(files);
            props.handleCropUtilityClose()
          }
    }
    else {
      console.log('saveCrop failed - no files found')
    }
    // const i = new Image()
    // i.onload = ()=>{
    //   let reader = new FileReader()
    //   reader.readAsDataURL(files[0])
    //   reader.onload = ()=>{
    //     console.log({
    //       src: files[0].preview,
    //       width: i.width,
    //       height: i.height,
    //       data: reader.result
    //     })
    //   }
      
    // }
    //   i.src = file.preview 

  }
  const removeFileNoCon = file => async (event) => {
    if(event){
      event.preventDefault()

    }
    console.log('file ', file)

      console.log('file ',file)

      fileInserted = false
      if(file?.files.length > 0){
        console.log(" file ",file)
        const newFiles = [...files];
        newFiles.splice(newFiles.indexOf(file), 1);
        setFiles(newFiles);
        props.onChange(newFiles);
      }
      else{
        props.removeCoverArt(event)
      }

  };
  useEffect(
    () => () => {
      // Make sure to revoke the data uris to avoid memory leaks
      files.forEach(file => URL.revokeObjectURL(file.preview));
    },
    [files]
  );

  return (
    <>
            <MicroModal
              open={props.isCropUtilityOpen}
              closeOnEscapePress={true}
              handleClose={()=>{console.log("handling close");props.handleCropUtilityClose(removeFileNoCon({files}))}}
              closeOnOverlayClick={true}
              closeOnAnimationEnd={true}
              modalOverlayClassName="modal-overlay"
              modalClassName="modal micromodal-slide is-open"
              modalOverlayStyles={null}
              children={handleClose=><CropUtilityModal handleClose={handleClose} file={files[0]} saveCrop={saveCrop}/>}
              containerStyles={{background: "#131313", padding:"asdf", maxWidth: "36rem", maxHeight: "95vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box"}}
            ></MicroModal>
            <div onClick={(event)=>{event.preventDefault()}} {...getRootProps({className: "btn btn-stroke"})}>{props.reupload ? "Change Artwork" : "Upload Artwork"}
                <input {...getInputProps()} />
            </div>
                            </>
  );
}

export default AlbumArtUpload;
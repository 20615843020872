import React, { Component } from "react"
import { UserConsumer, UserContext } from "../../contexts/User"
import ModalWrapper from '../modal/modalWrapper'
import SignUpForm from '../SignUp/signUpForm'
import api from '../utils/api'

class LoginModal extends Component {
    state = {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        passwordConfirm: "",
        redirectTo: null,
        accountExists: false,
        loginEmail: "",
        loginPassword: "",
        loginMessage: "",
        loginAction: "login",
        pwSuggestions: []
        // role: "customer", -- TODO decide what role should be set by default
    }
    //updating our input fields as the user enters keys
    handleInputChange = event => {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        }, () => {
            if (this.state.password && this.state.passwordConfirm && this.state.passwordConfirm === this.state.password) {
                this.setState({ loginMessage: "Passwords match!" })
            }
            if (this.state.password && this.state.passwordConfirm && this.state.passwordConfirm !== this.state.password) {
                this.setState({ loginMessage: "Passwords must match!" })
            }
        });
    };
    changeCallback=(state, fullObject)=>{

        if(state.isValid && this.state.passwordError){
            this.setState({passwordError: false})
        }
        this.setState({password: state.password, pwScore: state.score, pwValid: state.isValid, pwSuggestions: fullObject?.feedback?.suggestions || [], pwWarnings: fullObject?.feedback?.warning, fullObject: fullObject})
    }
    toggleLogin = (value) => {
        this.setState({ accountExists: value, loginMessage: "" })
    }
    forgotPassword = event => {
        this.setState({
            loginAction: event
        });
    }
    handleLogin = loginFunc => event => {
        event.preventDefault();

        const queryString = require('query-string')
        const params = queryString.parse(window.location.search)

        if (this.state.loginAction === "reset" && this.state.loginEmail) {
            api.forgotPassword({email: this.state.loginEmail})
            .then(res=>{
                this.setState({
                    loginMessage: "Password reset email was sent.",
                    passwordResetSent: true
                });
            })
        }
        else if (this.state.loginAction === "login" && this.state.loginEmail && this.state.loginPassword) {
            loginFunc({email: this.state.loginEmail, password: this.state.loginPassword}, params?.redirect?.replace(/['"]+/g, ''))
        }
        else {
            this.setState({
                loginMessage: "Please enter a valid email and password."
            });
        }
    }

    // calling the usersAPI.login method
    handleFormSubmit = loginFunc => event => {
        event.preventDefault();

        if (this.state.email && this.state.password && this.state.pwValid) {
            let newUserBody = {
                email: this.state.email,
                password: this.state.password,
                password_confirm: this.state.password,
            }

            api.signUp(newUserBody)
              .then(res => {
                console.log('signup res', res);
                    if (res.data?.success) {
                        loginFunc({ email: this.state.email, password: this.state.password }, '/signup/details')
                    }
                })
                .catch(error=>{
                    if(error.response?.status === 409){
                        this.setState({loginMessage: "An account with that email already exists!"})
                    }
                    else{
                        this.setState({loginMessage: "An error occurred!"})
                    }
                })
        }
        if(!this.state.pwValid){
            this.setState({passwordError: true})
        }
        else{
            this.setState({passwordError: false})
        }
    }
    focusPW(shouldFocus){
        this.setState({pwFocused: shouldFocus, isPWTippyOpen: shouldFocus})
    }
    render() {
        return (
            <UserConsumer>
                {({ user, login, loginMessage }) => (
                    <>
                        <ModalWrapper handleClose={this.props.handleClose}>
                            <>
                                {this.state.accountExists ? (
                                    <>
                                        <h1 class="modal-title mb-20">Sign In</h1>

                                        {/* {# Form #} */}
                                        <form onSubmit={this.handleLogin(this.state.loginAction === "login" ? login : "reset")} >
                                            {this.state.loginAction === "reset" ?
                                                <h3 className="">Password Reset</h3>

                                                :
                                                ""
                                            }

                                            <div className="grid-row-sm">
                                                <div className="col w-full">
                                                    {/* <div className="input-group-prepend">
                                                <span className="pt-2 border-bottom border-dark rounded-0"><i className="fas fa-envelope fa-lg"></i></span>
                                            </div> */}
                                                    <div className="input-group-sm input-inline">
                                                        <input className="form-input"
                                                            aria-describedby="emailBlock"
                                                            value={this.state.loginEmail}
                                                            onChange={this.handleInputChange}
                                                            type="text"
                                                            name="loginEmail"
                                                            placeholder="Email Address"
                                                        />
                                                        <label className="input-label" htmlFor="email">Email Address</label>
                                                    </div>
                                                </div>
                                            </div>
                                            {this.state.loginAction === "login" ?
                                                <div className="grid-row-sm">
                                                    <div className="col w-full">
                                                        {/* <div className="input-group-prepend">
                                                <span className="pt-2 border-bottom border-dark rounded-0"><i className="fas fa-lock fa-lg"></i></span>
                                                </div> */}
                                                        <div className="input-group-sm input-inline">
                                                            <input className="form-input"
                                                                value={this.state.loginPassword}
                                                                onChange={this.handleInputChange}
                                                                type="password"
                                                                name="loginPassword"
                                                                placeholder="Password"
                                                            />
                                                            <label className="input-label" htmlFor="password">Password</label>
                                                        </div>
                                                    </div>
                                                </div>
                                                : ""}
                                            <div className="input-group-sm">
                                                {this.state.loginAction === "reset" ?
                                                    this.state.passwordResetSent ?
                                                        <button type="button" className="btn btn-stroke form-btn w-full"
                                                            disabled
                                                        >Password Reset Sent</button>
                                                        :
                                                        <button className="btn btn-primary form-btn w-full"
                                                            name="reset"
                                                            type="submit"
                                                        >Send Password Reset</button>

                                                    :
                                                    <button className="btn btn-primary form-btn w-full"
                                                        name="login"
                                                        type="submit"
                                                    >Sign In</button>
                                                }
                                            </div>
                                            <div className="flex flex-wrap justify-between text-gray-400 text-sm">
                                                {this.state.loginAction === "reset" ?
                                                    <a href="# " onClick={(event) => { event.preventDefault(); this.forgotPassword("login") }} className="text-link-gray py-3">Back to Sign In</a>
                                                    :
                                                    <a href="# " onClick={(event) => { event.preventDefault(); this.forgotPassword("reset") }} className="text-link-gray py-3">Forgot Password?</a>
                                                }
                                                <div
                                                    onClick={()=>this.toggleLogin(false)}
                                                    className="text-link-gray py-3"
                                                >
                                                    Don't have an account?
                                                </div>
                                            </div>
                                        </form>
                                        {/* {# End Form #} */}
                                    </>
                                ) : (
                                    <>
                                        <h1 class="modal-title mb-20">Sign Up</h1>
                                        <SignUpForm
                                            headerText={<p class="mb-30 max-w-sm">Create your free account today to get access to full previews, great features, and more!</p>}
                                            loaded={true}
                                            role={this.state.role}
                                            token={this.state.token}
                                            email={this.state.email}
                                            buttonText={
                                                this.state.artist ?
                                                    "Agree & Continue"
                                                :
                                                    "Start Listening"
                                            }
                                            handleFormSubmit={this.handleFormSubmit}
                                            login={login}
                                            signUpLocation="loginModal"
                                        />

                                        <div className="text-gray-400 text-sm pt-20">
                                            <p>Already have an account? <a href="#" onClick={()=>this.toggleLogin(true)} className="text-link-gray text-gray-500 py-3">Sign In.</a></p>
                                        </div>
                                    </>
                                )}
                            </>
                        </ModalWrapper>
                    </>
                )}</UserConsumer>
        )
    }

}
LoginModal.contextType = UserContext;
export default LoginModal;

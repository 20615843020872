import React, { useContext, useEffect, useState } from "react";
import { useIntercom } from "react-use-intercom";
import { UserContext } from "../../contexts/User";
import getUserProps from './getUserProps';

const IntercomContactButton = (props) => {
  const { boot, showNewMessages } = useIntercom();
  const handleNewMessages = () => showNewMessages();

  const { children, type, className, ...inputProps } = props;

  const userData = useContext(UserContext);
  const { user, userLoaded } = userData || {};

  const defaultUserProps = getUserProps(user) || {};
  const [userProps, setUserProps] = useState(defaultUserProps);

  useEffect(() => {
    const updatedUserProps = getUserProps(user) || {};
    setUserProps(updatedUserProps);
  }, [userLoaded]);

  const handleOnClick = () => {
    boot(userProps);
    handleNewMessages();
  };

  return type === "link" ? (
    <button
      onClick={handleOnClick}
      className={className || "cursor-pointer link"}
      {...inputProps}
    >
      {children ? children : "Contact Us"}
    </button>
  ) : (
    <button
      onClick={handleOnClick}
      className={className || "btn btn-stroke btn-min-w"}
      {...inputProps}
    >
      {children ? children : "Contact Us"}
    </button>
  );
};

export default IntercomContactButton;

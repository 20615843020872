import React, { Component } from "react";
import ReactDOM from 'react-dom';
import WaveSurfer from 'wavesurfer.js';

export default class Waveform extends Component {
    state = {
        src: this.props.src || "",
        peaks: this.props.peaks
        
    }

  componentDidMount() {
    this.$el = ReactDOM.findDOMNode(this)
    this.$waveform = this.$el.querySelector('.wave')
    this.wavesurfer = WaveSurfer.create({
        container: this.$waveform,
        barWidth: 1.5,
        barGap: 2,
        hideScrollbar: true,
        interact: this.props.src !== "" ? true: false,
        cursorWidth: 0,
        backend: "MediaElement",
        height: 80,
        progressColor: "#fff",
        responsive: true,
        waveColor: "#626060",
        // fillParent: true,        
    })
    // 
    // 
    this.wavesurfer.loadBuffer(this.props.src, JSON.parse(this.props.peaks), JSON.parse(this.props.peaks).length)
    if(this.props.src){
      this.wavesurfer.playPause()
    }
  }
  // componentDidUpdate = (prevProps)=>{
  //       
  //       // if(prevProps.trackTitle !== this.props.trackTitle){
  //           this.wavesurfer.loadBuffer(this.props.src, this.props.peaks, this.props.peaks.length)
  //           // this.wavesurfer.playPause()
  //       // }
  //   }
  playSong = ()=>{
    this.wavesurfer.playPause()
  }
  componentWillUnmount() {

  }
  componentDidUpdate(prev){
    if (prev.peaks !== this.props.peaks){
      this.wavesurfer.loadBuffer(this.props.src, JSON.parse(this.props.peaks), JSON.parse(this.props.peaks).length)
    }
  }
  render() {
    return (
      // <div style={{width: 300}}>
      <div style={{ width:146 }} className='waveform' >
        <div className='wave'/>
        {/* <button onClick={this.playSong}>Play Song</button> */}
        {/* <div>{this.props.trackTitle}</div> */}
      </div>
      // </div>
    )
  }
}

Waveform.defaultProps = {
  src: "",
  peaks: ""
}
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { DateTime } from "luxon";
import { Tooltip } from "react-tippy";
import transfersAPI from "../../../api/transfers";
import currencyFormatter from "../../../utils/currencyFormatter";
import { handleAPIError } from "../../../utils/handleAPIError";
import ContentIDSubnav from "../ContentIDSubnav";
import LoadingContainer from "../../Loading/LoadingContainer";
import paginate from "../../../utils/paginate";
import SalesPagination from "../../Pagination/salesPagination";
import { Success } from "../../Toast";
import LoadingButton from "../../FormElements/LoadingButton";

const Payouts = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isButtonLoaded, setIsButtonLoaded] = useState(true);
  const [transfers, setTransfers] = useState([]);
  const [currentPageTransfers, setCurrentPageTransfers] = useState(null);
  const [totalPending, setTotalPending] = useState(null);
  const [pendingCount, setPendingCount] = useState(null);
  const history = useHistory();
  const currentPage = Number(history.location.pathname.split("/").pop()) || 1;
  const perPage = 12;
  const setCurrentPage = (pageNumber) => {
    if (pageNumber !== currentPage) {
      history.push(`/dashboard/content-id/payouts/${pageNumber}`);
    }
  };

  const getTransfers = (page) => {
    transfersAPI
      .getContentIDPayouts(page)
      .then((res) => {
        // console.log("getTransfers res", res);
        setTransfers(res?.data?.transfers);
        const paginatedTransfers = paginate(res?.data?.transfers, perPage) || [];
        const paginatedTransfersPage = paginatedTransfers[currentPage - 1];
        setCurrentPageTransfers(paginatedTransfersPage);
        const totalPendingFormatted = currencyFormatter(res?.data?.totalPending, true);
        setTotalPending(totalPendingFormatted);
        setPendingCount(res?.data?.pendingCount);
        setIsLoaded(true);
      })
      .catch((error) => {
        handleAPIError(error);
      });
  };

  useEffect(() => {
    getTransfers(currentPage);

    return () => {
      // cleanup
    };
  }, [currentPage]);

  const sendPendingPayouts = () => {
    setIsButtonLoaded(false);
    transfersAPI
      .sendPendingContentID()
      .then((res) => {
        console.log("sendPendingContentID res", res);
        const { message } = res?.data;
        Success({ message });
        getTransfers();
        setIsButtonLoaded(true);
      })
      .catch((error) => {
        handleAPIError(error);
        setIsButtonLoaded(true);
      });
  };

  return (
    <>
      <ContentIDSubnav />
      <LoadingContainer isLoaded={isLoaded}>
        <div class="bg-gray-100 p-20 pb-10 lg:p-25 lg:pb-15 mb-40">
          {/* {# Table Header #} */}
          <div class="flex flex-col md:flex-row items-stretch justify-between bg-gray-100 mb-40">
            <div className="flex-1 flex items-top mb-15 md:mb-0">
              <h3 class="text-label-lg mr-10">
                Payouts
                <Tooltip
                  useContext={true}
                  animation="shift"
                  arrow="true"
                  position="top"
                  html={
                    <>
                      <div className="price-tool-title mb-10">Payouts</div>
                      <div className="text-gray-400 price-tool-desc">
                        An admin view of all pending and paid payouts{" "}
                      </div>
                    </>
                  }
                >
                  &nbsp;<span className="icon-help"></span>
                </Tooltip>
              </h3>
              <span className="flex-none text-xs mt-5">
                Showing
                {/* {perPage < licenseSales?.length ? perPage : licenseSales?.length} of */}{" "}
                {transfers?.length}
              </span>
            </div>
            {/* {# Summary #} */}
            <div class="flex-none flex flex-col sm:flex-row">
              {/* {# Payout Summary #} */}
              <div class="flex flex-col flex-end pr-30 lg:pr-60 leading-snug mb-30 md:mb-0">
                <span class="text-label-sm mb-3 text-right">Total Pending</span>
                <span class="text-h3 text-white font-display leading-tight text-right">
                  {totalPending}
                </span>
              </div>
            </div>
            {/* {# Button #} */}
            <div class="flex-none flex flex-col leading-snug">
              <span class="text-label-sm mb-3">&nbsp;</span>
              <LoadingButton
                disabled={pendingCount < 1}
                isLoading={!isButtonLoaded}
                onClick={sendPendingPayouts}
              >
                {pendingCount < 1 ? "All Payouts Sent" : "Send Pending Payouts"}
              </LoadingButton>
            </div>

            {/* {# Sort #} */}
            <div class="flex-none pr-20"></div>
          </div>

          {/* {# Table #} */}
          <div class="table-wrap ds-scrollbar">
            <table class="stats-table w-full">
              <thead class="text-left">
                <tr>
                  <th class="text-label-sm">Date</th>
                  <th class="text-label-sm">Transaction ID</th>
                  <th class="text-label-sm">From</th>
                  <th class="text-label-sm">To</th>
                  <th class="text-label-sm">Description</th>
                  <th class="text-label-sm">Status</th>
                  <th class="text-label-sm text-right" style={{ minWidth: "14rem" }}>
                    Payout Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentPageTransfers?.length > 0 ? (
                  currentPageTransfers?.map((payout) => {
                    const {
                      fromName,
                      date,
                      stripeTransferId,
                      stripeTransferIdLink,
                      status,
                      description,
                      payoutAmount,
                      artistName,
                    } = payout;
                    const ISOString = new Date(date).toISOString();
                    const payedOnFormatted = DateTime.fromISO(ISOString).toFormat("yyyy.MM.dd");
                    return (
                      <tr>
                        <td>{payedOnFormatted}</td>
                        <td>
                          <a
                            className="underline"
                            href={stripeTransferIdLink}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {stripeTransferId}
                          </a>
                        </td>
                        <td>{fromName}</td>
                        <td>{artistName}</td>
                        <td>{description}</td>
                        <td>{status}</td>
                        <td className="text-right">{currencyFormatter(payoutAmount / 100)}</td>
                      </tr>
                    );
                  })
                ) : (
                  <p className="mt-30">No results to display.</p>
                )}
              </tbody>
            </table>
          </div>
          <SalesPagination
            pageLimit={perPage}
            currentPage={currentPage}
            totalRecords={transfers?.length}
            gotoPage={setCurrentPage}
          />
        </div>
      </LoadingContainer>
    </>
  );
};

export default Payouts;

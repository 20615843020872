import React, { Component } from "react";
import MicroModal from "react-micro-modal";
import { UserConsumer } from "../../contexts/User";
import BackdropOverlay from "../LoadingOverlay/backdrop";
import currencyFormatter from "../NumberFormatters/currencyFormatter";
import StripeSetupPrompt from "../Stripe/stripeSetupPrompt";
import api from "../utils/api";
import ArtistsPayoutTable from "./artistsPayoutTable";
import EnterPWModal from "./enterPWModal";

const queryStringer = require("query-string");
const licenseFilters = [
  { filter: "RecentA", displayText: "Recently Purchased" },
  { filter: "Type", displayText: "Type" },
  { filter: "Project", displayText: "Project" },
];
class SubscriptionPayoutDetail extends Component {
  state = {
    filteredSales: { 1: [] },
    sortChoice: "RecentA",
    sortText: "Recently Purchased",
    payoutTimeFrame: "All Time",
    balance: 0,
    displaySales: 0,
    currentSalesPage: 1,
    accountLoginLinkLoaded: false,
    areSalesLoaded: false,
    subscriptionPayouts: [],
    artistSubscriptionPayouts: [],
    isPWModalOpen: false,
  };
  componentDidMount = () => {
    api.getArtistSubscriptionPayouts(this.props.match.params.payoutId).then((res) => {
      this.setState(({ artistSubscriptionPayouts }) => ({
        artistSubscriptionPayouts: res.data.artistSubscriptionPayouts,
      }));
    });
    api.getSubscriptionPayout(this.props.match.params.payoutId).then((res) => {
      this.setState({ subscriptionPayout: res.data.subscriptionPayout });
      if (res.data?.subscriptionPayout?.status === "approving") {
        this.checkSubscriptionPayout = setInterval(() => {
          api.getArtistSubscriptionPayouts(this.props.match.params.payoutId).then((res) => {
            this.setState(({ artistSubscriptionPayouts }) => ({
              artistSubscriptionPayouts: res.data.artistSubscriptionPayouts,
            }));
            api.getSubscriptionPayout(this.props.match.params.payoutId).then((response) => {
              this.setState({ subscriptionPayout: response.data.subscriptionPayout });
            });
          });
        }, 10000);
      }
    });

    api
      .deepsoundsStripeBalance()
      .then((res) => {
        if (res.data) {
          this.setState({
            availableBalance: res.data.availableBalance / 100,
            pendingBalance: res.data.pendingBalance / 100,
            balanceLoaded: true,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
        } else if (err.request) {
          // client never received a response, or request never left
        } else {
          // anything else
        }
      });
  };
  componentDidUpdate = (prevProps, prevState) => {
    if (
      prevState.subscriptionPayout?.status === "approving" &&
      this.state.subscriptionPayout?.status !== "approving"
    ) {
      clearInterval(this.checkSubscriptionPayout);
    }
  };

  updatePayouts = (index, payout) => {
    this.setState(
      ({ subscriptionPayouts }) => {
        return {
          subscriptionPayouts: [
            ...subscriptionPayouts.slice(0, index),
            {
              ...payout,
            },
            ...subscriptionPayouts.slice(index + 1),
          ],
        };
      },
      () => {
        this.getArtistSubscriptionPayouts(payout.id);
      }
    );
  };
  handlePWModalOpen = (payoutId) => {
    this.setState({
      isPWModalOpen: true,
      currentSubscriptionPayoutId: payoutId,
    });
  };
  handlePWModalClose = () => {
    this.setState({
      isPWModalOpen: false,
    });
  };
  approvePayout = () => {
    api
      .approveSubscriptionPayout(this.state.currentSubscriptionPayoutId, this.state.password)
      .then((res) => {
        if (res.data.success) {
          api
            .approveArtistSubscriptionPayouts(this.state.currentSubscriptionPayoutId)
            .then((resp) => {})
            .catch((err) => {});
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            this.setState({ passError: "Invalid Password" });
          }
        }
      });
  };
  onChange = (event) => {
    let { value } = event.target;
    this.setState({ password: value });
  };
  render() {
    return (
      <UserConsumer>
        {({ user, login, logout }) => (
          <div class="container mb-40 lg:mb-80">
            {/* {# App Header #} */}
            <div class="view-header">
              <h1 class="view-title">Sales & Payouts</h1>
            </div>
            <MicroModal
              open={this.state.isPWModalOpen}
              handleClose={() => this.handlePWModalClose()}
              closeOnAnimationEnd={true}
              closeOnEscapePress={false}
              closeOnOverlayClick={false}
              modalOverlayClassName="modal-overlay"
              modalClassName="modal micromodal-slide is-open"
              modalOverlayStyles={null}
              children={(handleClose) => (
                <EnterPWModal
                  handleClose={handleClose}
                  onChange={this.onChange}
                  password={this.state.password}
                  approvePayout={this.approvePayout}
                  passError={this.state.passError}
                />
              )}
              containerStyles={{
                background: "#131313",
                padding: "asdf",
                maxWidth: "40.625rem !important",
                maxHeight: "100vh",
                borderRadius: "asdf",
                overflowY: "auto",
                boxSizing: "border-box",
              }}
            />
            {this.state.balanceLoaded ? (
              <>
                <div class="flex flex-col md:flex-row items-stretch justify-between bg-gray-100 p-20 lg:p-25 mb-40">
                  {/* {# Summary #} */}
                  <div class="flex-none flex flex-col sm:flex-row">
                    {/* {# Payout Summary #} */}
                    <div class="flex flex-col flex-none pr-30 lg:pr-60 leading-snug mb-30 md:mb-0">
                      <span class="text-label-sm mb-3">Pending Balance</span>
                      <span class="text-h3 text-white font-display leading-tight">
                        ${currencyFormatter(this.state.pendingBalance.toFixed(2))}
                      </span>
                    </div>
                    {/* {# Payout Summary #} */}
                    <div class="flex flex-col flex-none pr-30 lg:pr-60 leading-snug mb-30 md:mb-0">
                      <span class="text-label-sm mb-3">Available Balance</span>
                      <span class="text-h3 text-white font-display leading-tight">
                        ${`${currencyFormatter(this.state.availableBalance.toFixed(2))}`}
                      </span>
                    </div>
                  </div>
                </div>

                <ArtistsPayoutTable
                  artistSubscriptionPayouts={this.state.artistSubscriptionPayouts || []}
                  currentSubscriptionPayout={this.state.subscriptionPayout}
                />
              </>
            ) : this.state.accountLoginLinkLoaded ? (
              <StripeSetupPrompt />
            ) : (
              <BackdropOverlay loading={!this.state.accountLoginLinkLoaded} />
            )}
          </div>
        )}
      </UserConsumer>
    );
  }
}
export default SubscriptionPayoutDetail;

import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { PlayerContext } from "../../PlayerContext";
import Waveform from "../Waveform/trackListBrowse";
import TrackListBrowseNoInteract from "../Waveform/trackListBrowseNoInteract";
import LicensesTab from "./licensesTab";
import StemsTab from "./stemsTab";
import DetailsTab from "./tabDetails";
import TrackListItemSkeleton from "./TrackListItemSkeleton";
import TrackIcons from "./TrackIcons";
import "./versions.css";
import VersionsTab from "./versionsTab";
class TrackListItem extends Component {
  state = {
    isDropdown: false,
    activeTab: "details",
    track: {},
    versions: [],
    stems: [],
    genres: [],
    speed: "",
    city: "",
    emotion: "",
    moods: [],
    instruments: [],
    licenses: [],
    attributes: [],
    funFact: "",
    isLicenseModalOpen: false,
  };
  resolvePhotoLink = (albumPhotoKey) => {
    const key = albumPhotoKey;
    const ext = key.split(".").reverse()[0];
    let sizeMod;
    if (window.innerWidth > 1280) {
      sizeMod = "140x140";
    } else if (window.innerWidth > 1020 && window.innerWidth < 1280) {
      sizeMod = "140x140";
    } else if (window.innerWidth > 760 && window.innerWidth < 1020) {
      sizeMod = "140x140";
    } else {
      sizeMod = "140x140";
    }
    let final = key.replace("." + ext, "") + "-minified-" + sizeMod + "." + ext;
    return final;
  };
  componentDidMount = () => {
    // api.getCustLicenseByTrack(this.props.trackId).then((res) => {
    //   if (res.data?.licenses.length > 0) {
    //     this.setState({ licenses: res.data.licenses });
    //   }
    // });
  };
  componentDidUpdate = (prevProps) => {
    var isEqual = function (value, other) {
      // Get the value type
      var type = Object.prototype.toString.call(value);

      // If the two objects are not the same type, return false
      if (type !== Object.prototype.toString.call(other)) return false;

      // If items are not an object or array, return false
      if (["[object Array]", "[object Object]"].indexOf(type) < 0) return false;

      // Compare the length of the length of the two items
      var valueLen = type === "[object Array]" ? value.length : Object.keys(value).length;
      var otherLen = type === "[object Array]" ? other.length : Object.keys(other).length;
      if (valueLen !== otherLen) return false;

      // Compare two items
      var compare = function (item1, item2) {
        // Get the object type
        var itemType = Object.prototype.toString.call(item1);

        // If an object or array, compare recursively
        if (["[object Array]", "[object Object]"].indexOf(itemType) >= 0) {
          if (!isEqual(item1, item2)) return false;
        }

        // Otherwise, do a simple comparison
        else {
          // If the two items are not the same type, return false
          if (itemType !== Object.prototype.toString.call(item2)) return false;

          // Else if it's a function, convert to a string and compare
          // Otherwise, just compare
          if (itemType === "[object Function]") {
            if (item1.toString() !== item2.toString()) return false;
          } else {
            if (item1 !== item2) return false;
          }
        }
      };

      // Compare properties
      if (type === "[object Array]") {
        for (var i = 0; i < valueLen; i++) {
          if (compare(value[i], other[i]) === false) return false;
        }
      } else {
        for (var key in value) {
          if (value.hasOwnProperty(key)) {
            if (compare(value[key], other[key]) === false) return false;
          }
        }
      }

      // If nothing failed, return true
      return true;
    };
    //
  };
  toggleDropdown = (e) => {
    this.setState({ isDropdown: !this.state.isDropdown });
  };
  // toggleTrackTippy = () => {
  //   this.setState({ showTrackTippy: !this.state.showTrackTippy });
  // };

  play = () => {
    const trackId = this.props.trackId;
    // console.log("trying to play trackId", trackId);

    const artistId = this.context.loadedTracks[this.props.trackId]?.artistId;
    const albumId = this.context.loadedTracks[this.props.trackId]?.albumId;

    let idKey = `artist-${artistId}-album-${albumId}-track-${trackId}-project-(projectId)-collection-(collectionId)-element-(elementId)`;

    // console.log("context", this.context);
    // console.log("context mediaPlayer idKey", this.context.mediaPlayer?.idKey);

    const trackLoadedInPlayer = this.context.mediaPlayer?.idKey?.includes("track-" + trackId);

    if (!trackLoadedInPlayer) {
      // console.log('track is not loaded in player. proceeding...');

      if (this.props.playCB) {
        // console.log('found callback function from props. calling it...');
        // console.log('idKey', this.props.idKey);
        this.props.playCB(this.props.idKey, trackId);
      } else {
        let track = {
          trackId: this.props.trackId,
          idKey: this.props.idKey,
          artistURL: this.props.artistURL,
          peaks: this.props.peaks,
          imageURL: this.props.imageURL,
          duration: this.props.duration,
          trackTitle: this.props.trackTitle,
          artistName: this.props.artistName,
          liked: this.props.liked,
          trackURL: this.props.trackURL,
          albumTitle: this.props.albumTitle,
          versions: this.props.versions,
          stems: this.props.stems,
        };
        this.context.setAutoPlay(false);
        this.context.loadTrackInMediaPlayer(this.props.trackId, this.props.idKey);
        if (this.props.otherTracks?.length > 0) {
          this.context.setPlaylist(this.props.otherTracks);
        }
      }
    } else {
      if (this.context.mediaPlayer?.isTrackPlaying || this.context.isVideoPlaying) {
        this.context.handlePause();
      } else {
        this.context.handlePlay();
      }
    }
  };
  handleLicenseModalOpen = () => {
    this.setState({ isLicenseModalOpen: true });
  };
  handleLicenseModalClose = () => {
    this.setState({ isLicenseModalOpen: false });
  };
  render() {
    return (
      <>
        {!this.context.areTracksLoading ? (
          <div
            className={`track-list-card${this.state.isDropdown ? " dropdown-open" : ""}${
              (this.context.mediaPlayer.idKey?.indexOf("track-" + this.props.trackId) || -1) !==
                -1 &&
              (this.context.mediaPlayer.isTrackPlaying || this.context.isVideoPlaying)
                ? " is-playing"
                : ""
            }`}
          >
            <div className={`track-first-col`}>
              {this.props.listType == "album" ? (
                <div className="track-pre">
                  {/* {# Track Number #} */}
                  <div className="track-number">{this.props.index + 1}</div>
                  {/* {# Track Hover #} */}
                  <div className="track-hover">
                    <button onClick={this.play} className="circle-icon-border circle-icon-sm">
                      <span
                        className={`${
                          (this.context.mediaPlayer.idKey?.indexOf("track-" + this.props.trackId) ||
                            -1) !== -1 &&
                          (this.context.mediaPlayer.isTrackPlaying || this.context.isVideoPlaying)
                            ? "icon-pause"
                            : "icon-play"
                        }`}
                      ></span>
                    </button>
                  </div>
                </div>
              ) : (
                <div onClick={this.play} className="track-image">
                  <img
                    src={`https://${
                      process.env.REACT_APP_S3_PHOTO_BUCKET
                    }.s3.us-east-2.amazonaws.com/${
                      process.env.REACT_APP_S3_SUB_BUCKET
                        ? process.env.REACT_APP_S3_SUB_BUCKET + "/"
                        : ""
                    }${this.resolvePhotoLink(this.props.imageURL || "")}`}
                    alt="albumArt"
                  />
                </div>
              )}
              <div className="track-title-artist">
                <NavLink to={"/tracks/" + this.props.trackURL}>
                  <span className="track-title">{this.props.trackTitle}</span>
                </NavLink>
                {this.props.listType !== "album" && (
                  <NavLink to={"/artists/" + this.props.artistURL}>
                    <span className="track-artist">{this.props.artistName}</span>
                  </NavLink>
                )}
              </div>
              <button
                className={`circle-icon circle-icon-sm icon-chevron-down${
                  this.state.isDropdown ? " dropdown-open" : ""
                }`}
                onClick={this.toggleDropdown}
              ></button>
            </div>
            <div className="track-genres text-gray-400">
              {Array.isArray(this.props?.tags) && (
                <>
                  {this.props?.tags
                    ?.filter((tag) => tag.type === 2)
                    .map((emotion) =>
                      window.location.pathname.includes("/music") ||
                      /\/artists\/{0}[^\/]*$/.test(window.location.pathname) ? (
                        <div key={emotion?.tagId}>{emotion.name}</div>
                      ) : (
                        <Link
                          to={{
                            pathname: "/music",
                            search: `?inc_emo=${emotion.tagId}`,
                            state: { from: window.location.pathname },
                          }}
                          key={emotion?.tagId}
                        >
                          <span>{emotion.name}</span>
                        </Link>
                      )
                    )}
                  {this.props?.tags?.filter((tag) => tag.type === 1).length > 0 &&
                    (window.location.pathname.includes("/music") ||
                    /\/artists\/{0}[^\/]*$/.test(window.location.pathname) ? (
                      <>
                        <span> </span>
                        <div key="secondaryGenre">
                          {" "}
                          {this.props?.tags?.filter((tag) => tag.type === 1)[0].name}
                        </div>
                      </>
                    ) : (
                      <Link
                        to={{
                          pathname: "/music",
                          search: `?inc_gen=${
                            this.props?.tags?.filter((tag) => tag.type === 1)[0].tagId
                          }`,
                          state: { from: window.location.pathname },
                        }}
                        key="secondaryGenre"
                      >
                        <span> </span>
                        <span>{this.props?.tags?.filter((tag) => tag.type === 1)[0].name}</span>
                      </Link>
                    ))}
                </>
              )}
            </div>
            <div className="track-last-col">
              <div className="track-wave-time flex items-center">
                <span
                  className={`icons-stems-versions text-gray-400 icon${
                    this.props.stems?.length ? "-stems" : ""
                  }${this.props.versions?.length ? "-versions" : ""}`}
                ></span>
                <div className="wave-form-vector">
                  {this.props.peaks && (
                    <div>
                      {(this.context.mediaPlayer.idKey?.indexOf("track-" + this.props.trackId) ||
                        -1) !== -1 ? (
                        <Waveform
                          idKey={this.props.idKey}
                          trackId={this.props.trackId}
                          s3Key={this.props.wavURL}
                          peaks={this.props.peaks}
                        />
                      ) : (
                        <TrackListBrowseNoInteract
                          idKey={this.props.idKey}
                          trackId={this.props.trackId}
                          s3Key={this.props.wavURL}
                          peaks={this.props.peaks}
                        />
                      )}
                    </div>
                  )}
                </div>
                <span className="track-duration">{this.props.duration}</span>
              </div>

              <TrackIcons
                artistId={this.props.artistId}
                artistName={this.props.artistName}
                isFeatured={this.props.isFeatured}
                imageURL={this.props.imageURL}
                itemType="list"
                trackId={this.props.trackId}
                trackTitle={this.props.trackTitle}
                trackURL={this.props.trackURL}
              />
            </div>

            <div className="track-dropdown">
              <div className="track-dropdown-content">
                <div className="tabs tabs-sm">
                  <ul className="tab-items">
                    <li className={`tab-item ${this.state.activeTab === "details" && "active"}`}>
                      <button
                        className="tab-item-title cursor-pointer"
                        onClick={() => {
                          this.setState({ activeTab: "details" });
                        }}
                      >
                        Details
                      </button>
                    </li>
                    {(this.state.versions.length > 0 || this.props.versions?.length > 0) && (
                      <li className={`tab-item ${this.state.activeTab === "versions" && "active"}`}>
                        <button
                          onClick={() => {
                            this.setState({ activeTab: "versions" }, () =>
                              this.context.getVersionInfo(this.props.trackId)
                            );
                          }}
                          className="tab-item-title cursor-pointer"
                        >
                          Versions
                        </button>
                      </li>
                    )}
                    {(this.state.stems.length > 0 || this.props.stems?.length > 0) && (
                      <li className={`tab-item ${this.state.activeTab === "stems" && "active"}`}>
                        <button
                          onClick={() => {
                            this.setState({ activeTab: "stems" }, () =>
                              this.context.getStemInfo(this.props.trackId)
                            );
                          }}
                          className="tab-item-title cursor-pointer"
                        >
                          Stems
                        </button>
                      </li>
                    )}
                    <li className="tab-item tab-item-end hidden md:flex">
                      <NavLink to={"/tracks/" + this.props.trackURL} className="tab-item-title">
                        View Track Page
                      </NavLink>
                    </li>
                    {this.state.licenses.length > 0 && (
                      <li
                        onClick={() => {
                          this.setState({ activeTab: "licenses" });
                        }}
                        className={`tab-item ${this.state.activeTab === "licenses" && "active"}`}
                      >
                        <a href="# " className="tab-item-title">
                          Licenses
                        </a>
                      </li>
                    )}
                  </ul>
                  <div className="tab-content">
                    {/* {# Details #} */}
                    {this.state.activeTab === "details" && (
                      <DetailsTab
                        genres={this.props?.tags?.filter((tag) => tag.type === 1)}
                        emotion={this.props?.tags?.filter((tag) => tag.type === 2)}
                        moods={this.props?.tags?.filter((tag) => tag.type === 3)}
                        attributes={this.props?.tags?.filter((tag) => tag.type === 4)}
                        instruments={this.props?.tags?.filter((tag) => tag.type === 5)}
                        city={this.props?.tags?.filter((tag) => tag.type === 6)}
                        lyrics={this.props?.tags?.filter((tag) => tag.type === 7)}
                        speed={this.props?.tags?.filter((tag) => tag.type === 8)}
                      />
                    )}
                    {/* {# Versions #} */}
                    {this.state.activeTab === "versions" && (
                      <VersionsTab
                        currentTime={this.state.currentTime}
                        changeTrack={this.changeVersion}
                        versions={this.props.versions}
                        trackTitle={this.props.trackTitle}
                        artistName={this.props.artistName}
                        artistId={this.props.artistId}
                        albumId={this.props.albumId}
                        trackId={this.props.trackId}
                        imageURL={this.props.imageURL}
                      />
                    )}
                    {/* {# Stems #} */}
                    {this.state.activeTab === "stems" && (
                      <StemsTab
                        currentTime={this.state.currentTime}
                        changeTrack={this.changeStem}
                        stems={this.props.stems}
                        trackTitle={this.props.trackTitle}
                        artistName={this.props.artistName}
                        artistId={this.props.artistId}
                        albumId={this.props.albumId}
                        trackId={this.props.trackId}
                        imageURL={this.props.imageURL}
                      />
                    )}
                    {/* Licenses */}
                    {this.state.activeTab === "licenses" && (
                      <LicensesTab
                        licenses={this.state.licenses}
                        trackTitle={this.props.trackTitle}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <TrackListItemSkeleton listType={this.props.listType} />
        )}
      </>
    );
  }
}

TrackListItem.contextType = PlayerContext;
export default TrackListItem;

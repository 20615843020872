import React, { useState, useEffect } from "react";
import {
  Chart as ChartJS,
  Colors,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

const SalesChart = ({
  allMonths,
  allLicenseSales,
  allSales,
  allSubscriptionPayouts,
  allContentIDSales,
  allTransfers,
}) => {
  ChartJS.register(
    CategoryScale,
    Colors,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Filler,
    Legend
  );

  const licensesDatapoints = [];
  const subscriptionDatapoints = [];
  const contentIDDatapoints = [];
  const transfersTipsDatapoints = [];

  const options = {
    bezierCurve: true,
    layout: { padding: { top: 0 } },
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        labels: {
          padding: 10,
        },
      },
      title: {
        display: true,
        text: "Earnings Overview",
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            let label = context.dataset.label || "";

            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(context.parsed.y);
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: "white",
        },
      },
      y: {
        ticks: {
          // Include a dollar sign in the ticks
          callback: function (value, index, ticks) {
            return "$" + value;
          },
          color: "white",
        },
        min: 0,
      },
    },
  };

  const labels = allMonths;

  // const dataSetOptions = {
  //   tension: 0.5,
  //   scales: {
  //     y: {
  //       ticks: {
  //         // Include a dollar sign in the ticks
  //         callback: function (value, index, ticks) {
  //           return "$" + value;
  //         },
  //       },
  //     },
  //   },
  // };

  const tension = 0.4;

  const data = {
    labels,
    datasets: [
      {
        fill: false,
        label: "Single Track Licenses",
        data: allLicenseSales?.totalsByMonth,
        borderColor: "rgba(255, 255, 255)",
        backgroundColor: "rgba(255, 255, 255, 0.5)",
        tension,
      },
      {
        fill: false,
        label: "Subscription Royalties",
        data: allSubscriptionPayouts?.totalsByMonth,
        borderColor: "rgba(101, 161, 232)",
        backgroundColor: "rgba(101, 161, 232, 0.5)",
        tension,
      },
      {
        fill: false,
        label: "Content ID",
        data: allContentIDSales?.totalsByMonth,
        borderColor: "rgb(224, 99, 130)",
        backgroundColor: "rgba(224, 99, 130, 0.5)",
        tension,
      },

      {
        fill: false,
        label: "Bonus Earnings",
        data: allTransfers?.totalsByMonth,
        borderColor: "rgba(128,128,128)",
        backgroundColor: "rgba(128, 128, 128, 0.5)",
        tension,
      },
    ],
  };

  return (
    <div style={{ height: "500px" }}>
      <div style={{ background: "rgb(19 19 19)", padding: "30px", height: "100%" }}>
        <Line options={options} data={data} />
      </div>
    </div>
  );
};

export default SalesChart;

import React from "react";
import { Helmet } from "react-helmet";
import buildAlbumArtURL from "../../Albums/AlbumArt";
import buildCollectionURL from "../../../utils/buildCollectionURL";
import formatListArray from "../../../utils/formatListArray";
export default function CollectionDetailHelmet({
  collection,
  loadedTracks
}) {
  const getThumbnail = (photoKey) => {
    const key = photoKey;
    if (photoKey) {
      const ext = key.split(".").reverse()[0];
      let final = key.replace("." + ext, "") + "-minified-325x325" + "." + ext;
      return final;
    }
  };

  const {
    author,
    cardArt,
    collectionURL,
    description: collectionDescription,
    name
  } = collection;

  const thumbnailURL = getThumbnail(cardArt);
  const photoURL = buildAlbumArtURL(thumbnailURL);
  const pageURL = buildCollectionURL(collectionURL);

  // TODO - add metatags for all tracks; finesse the code below
  // let tracksMetatags;
  // const tracks = Object.entries(loadedTracks);
  // if (tracks?.length) {
  //   tracksMetatags = formatListArray(tracks.map(trackElement => {
  //     const trackID = trackElement[0];
  //     const trackData = trackElement[1];
  //     return (
  //       <meta property="og:music:song" content={trackData.trackTitle} />
  //     )
  //   }));
  // }

  // console.log("tracks", tracks);

  const description = `${name} is a collection of tracks by Deep Sounds. ${collectionDescription}`;

  return (
    <Helmet>
      <title>{`${name} - Deep Sounds | Music for Creators`}</title>
      <meta name="description" content={description} />
      <meta property="og:title" content={`${name} - Deep Sounds | Music for Creators`} />
      <meta property="og:image" content={photoURL} />
      <meta property="og:type" content="music.playlist" />
      <meta property="og:music:creator" content={author} />
      <meta property="og:url" content={pageURL} />
      <meta property="og:description" content={description} />
      <link rel="canonical" href={pageURL} />
    </Helmet>
  );
}

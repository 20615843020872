import React, { Component } from "react"
import VideoStudioLearnMore from "../../Links/videoStudioLearnMore"
import { Error } from "../../Toast/index"
import "./style.css"
class VideoLoader extends Component {
    state={

    }
    componentDidMount = ()=>{
        //if non sub account
        //check number of video loads left
    }
    handleVideoLoad = (event)=>{

        let {target} = event
        this.setState({videoLoadError: false, video: null}, ()=>{
            if (target.files) {
                let video = target.files[0];
                let allowedVideoTypes = ['video/mp4'];
                if (allowedVideoTypes.includes(video.type)) {
                    this.props.loadVideo(video)
                    target.files = null;
                    target.value = null;
                }
                else {
                    Error({message: "Error:  Video Format Not Supported"});
                    this.setState({videoLoadError: "Video Format Not Supported."});
                }
            }
            else {
                Error({message: "Error Loading Video, Please Try Again."});
                this.setState({videoLoadError: "Error Loading Video."});
            }
        })

    }
    render(){
        return(
            <>

                <div className={`vs-main ${this.props.isLoaderOpen ? 'is-open': 'is-closed'} `} style={{zIndex:"7000"}}>

{/* {# VS Panel #} */}
<div className="vs-panel">

    {/* {# VS Panel Header #} */}
    <div className="vs-panel-header">

        <div className="flex-1">
            <h4 className="vs-panel-title">Video Studio</h4>
        </div>

        <div className="flex-none flex items-center">
            <button
                onClick={this.props.closeVideoLoader}
                className="circle-icon icon-chevron-down" type="button"
                >
            </button>
        </div>

    </div>

    {/* {# VS Panel Content #} */}
    <div className="vs-panel-content pb-20">

        {/* {# Load Video Prompt #} */}
        <div className="prompt border-none ">
	<div className="prompt-content">

		{/* {# Icon #} */}
		<div className="text-icon-xl mb-15">
			<span className="icon-playlist-add-lg"></span>
		</div>

		{/* {# Heading #} */}
		<h3 className="prompt-title">Please load a video to audition tracks</h3>

		{/* {# Description #} */}
		<div className="max-w-sm">
            <p>Video is hosted locally on your device and not uploaded to our server so your content is 100% secure.</p>
        </div>

        {/* {# Button #} */}
        <div className="my-30">

            <label
                className="btn btn-primary w-200"

            >
                            <input onChange={this.handleVideoLoad} type="file" accept={'video/mp4,video/quicktime'} className="hide-input"/>

                Load Video
            </label>
        {this.state.videoLoadError && <div className="error-message">{this.state.videoLoadError}</div>}
        </div>
        <div className="max-w-sm text-xs">
            <p className="font-bold mb-0">MP4 file format required</p>
            <p className="mb-0"><VideoStudioLearnMore red/></p>
        </div>


	</div>
</div>

    </div>

</div>
{/* {# End VS Panel #} */}

{/* {# Overlay #} */}
<div className="vs-overlay" onClick={this.props.closeVideoLoader}></div>

</div>
            </>
        )
    }
}
export default VideoLoader

import moment from "moment";
import { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import React from "react";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config.js";
import FilterGroup from "./filterGroup";

const tw = resolveConfig(tailwindConfig);
var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment)

const MobileArtistFilters = (props)=><ul className="browse-filters">
	{/* {# Emotions #} */}
    <FilterGroup selectedSearchOptions={props.selectedSearchOptions} groupTitle="Emotion" filterOptions={props.filters.filter(filterOpt=>filterOpt.type === "emotion")} toggleOpen={()=>props.toggleMobileFilter("Emotion")} isOpen={props.isEmotionOpen} addSearchOption={props.addSearchOption} excludeSearchOption={props.excludeSearchOption} removeSearchOption={props.removeSearchOption} isMobile />
    {/* Genres */}
    <FilterGroup selectedSearchOptions={props.selectedSearchOptions} groupTitle="Genres" filterOptions={props.filters.filter(filterOpt=>filterOpt.type === "genre")} toggleOpen={()=>props.toggleMobileFilter("Genres")} isOpen={props.isGenresOpen} addSearchOption={props.addSearchOption} excludeSearchOption={props.excludeSearchOption} removeSearchOption={props.removeSearchOption} isMobile />
	{/* {# Mood #} */}
    <FilterGroup selectedSearchOptions={props.selectedSearchOptions} groupTitle="Mood" filterOptions={props.filters.filter(filterOpt=>filterOpt.type === "mood")} toggleOpen={()=>props.toggleMobileFilter("Moods")} isOpen={props.isMoodsOpen} addSearchOption={props.addSearchOption} excludeSearchOption={props.excludeSearchOption} removeSearchOption={props.removeSearchOption} isMobile />
	    {/* {# Attributes #} */}
    <FilterGroup selectedSearchOptions={props.selectedSearchOptions} groupTitle="Attributes" filterOptions={props.filters.filter(filterOpt=>filterOpt.type === "attribute")} toggleOpen={()=>props.toggleMobileFilter("Attributes")} isOpen={props.isAttributesOpen} addSearchOption={props.addSearchOption} excludeSearchOption={props.excludeSearchOption} removeSearchOption={props.removeSearchOption} isMobile />
	{/* {# Lyrics #} */}
    <FilterGroup selectedSearchOptions={props.selectedSearchOptions} groupTitle="Lyrics" filterOptions={props.filters.filter(filterOpt=>filterOpt.type === "lyric")} toggleOpen={()=>props.toggleMobileFilter("Lyrics")} isOpen={props.isLyricsOpen} addSearchOption={props.addSearchOption} excludeSearchOption={props.excludeSearchOption} removeSearchOption={props.removeSearchOption} isMobile />
    {/* {# BPM #} */}
    <FilterGroup selectedSearchOptions={props.selectedSearchOptions} groupTitle="BPM" filterOptions={props.filters.filter(filterOpt=>filterOpt.type === "bpm")} toggleOpen={()=>props.toggleMobileFilter("BPM")} isOpen={props.isBPMOpen} addSearchOption={props.addSearchOption} excludeSearchOption={props.excludeSearchOption} removeSearchOption={props.removeSearchOption} isMobile />
	{/* Instuments */}
	<FilterGroup selectedSearchOptions={props.selectedSearchOptions} groupTitle="Instruments" type="instrument"  filterOptions={props.filters.filter(filterOpt=>filterOpt.type === "instrument")} toggleOpen={()=>props.toggleMobileFilter("Instruments")} isOpen={props.isInstrumentsOpen} addSearchOption={props.addSearchOption} excludeSearchOption={props.excludeSearchOption} isMobile />
	{/* {# City #} */}
    <FilterGroup selectedSearchOptions={props.selectedSearchOptions} groupTitle="City" filterOptions={props.filters.filter(filterOpt=>filterOpt.type === "city")} toggleOpen={()=>props.toggleMobileFilter("Cities")} isOpen={props.isCitiesOpen} addSearchOption={props.addSearchOption} excludeSearchOption={props.excludeSearchOption} removeSearchOption={props.removeSearchOption} isMobile />
	{/* {# Versions #} */}
	{props.trackBrowse &&
		<li className="filter-group">
			{/* Filter Header */}
			<div className="filter-header filter-header-toggle">
				Track Length
				{/* Form Switch */}
				<label className="form-switch">
				<input type="checkbox"  onChange={props.enableLengthRange} checked={props.isLengthRangeEnabled} />
				<span className="form-switch-slider"></span>
				</label>
			</div>
			{/* Filter Conditional - toggle .block and .hidden */}
			{props.isLengthRangeEnabled &&
			<div className="block">
				<div className="py-25 pl-40 pr-30">
				{/* Slider Block */}
				<div className="flex items-center">
					{/* Value */}
					<div className="flex-none">
					<div className="w-50 text-gray-400 text-sm leading-snug">
					{moment.duration(Math.floor(props.lengthFilterValue[0]), 'seconds').format("m:ss",{forceLength: true, trim: false})}
					</div>
					</div>
					{/* Slider */}
					<div className="flex-1">
					<Range
						min={props.lengthFilterMin}
						max={props.lengthFilterMax}
						value={props.lengthFilterValue}
						onChange={props.onSliderChange}
						onAfterChange={props.sliderFinished}
						step={15}
						count={2}
						railStyle={{
						height: 1,
						background: tw.theme.colors.gray[300]
						}}
						handleStyle={[
						{
							height: 12,
							width: 12,
							marginLeft: -6,
							marginTop: -6,
							backgroundColor: tw.theme.colors.white,
							border: 0,
							outline: "none",
							boxShadow: "none",
						},
						{
							height: 12,
							width: 12,
							marginLeft: 0,
							marginTop: -6,
							backgroundColor: tw.theme.colors.white,
							border: 0,
							outline: "none",
							boxShadow: "none",
						}
						]}
						trackStyle={[
						{
							background: "red",
							height: 1
						}
						]}
					/>
					</div>
					<div className="flex-none">
					<div className="w-50 text-gray-400 text-sm text-right leading-snug">
						{moment.duration(Math.floor(props.lengthFilterValue[1]), 'seconds').format("m:ss",{forceLength: true, trim: false})}
					</div>
					</div>
				</div>
				</div>
			</div>
			}
			</li>
		}
    {props.trackBrowse === true && <li className="filter-group">
		<div className="filter-header filter-header-toggle">
			Has Stems
			<label className="form-switch">
				<input type="checkbox" onChange={props.changeStem} checked={props.stemsRequired || false}/>
				<span className="form-switch-slider"></span>
			</label>
		</div>
	</li>}

</ul>

export default MobileArtistFilters

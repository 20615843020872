import React, { Component } from "react";
import MicroModal from "react-micro-modal";
import { NavLink } from "react-router-dom";
import { toast } from "react-toastify";
import { PlayerContext } from "../../PlayerContext";
import { UserConsumer } from "../../contexts/User";
import { confirm } from "../confirmModal/index";
import CircularIndeterminant from "../Loading/circularIndeterminant";
import AlbumModal from "../modal/albumModal";
import SortBar from "../SortBar/results";
import { Error, Success } from "../Toast/index";
import api from "../utils/api";
import AlbumListItem from "./albumListItem";

// TODO: Refactor this to use modal components so it doesn't duplicate code from ArtisTrackList

const albumFilters= [
    {filter: "RecentD", displayText: "Recently Added"},
    {filter: "MostP", displayText: "Most Plays"},
    {filter: "NameA", displayText: "Name - Asc"},
    {filter: "NameD", displayText: "Name - Desc"},
]

class ArtistAlbums extends Component {
    state = {
        myAlbums: [],
        myPublicAlbums: [],
        myPrivateAlbums: [],
        currentAlbums: [],
        newAlbumTitle: "",
        albumArt: [],
        sortChoice: "RecentD",
        sortText: "Recently Added",
        isAlbumModalOpen: false,
    }

    componentDidMount = () => {

        api.getMyAlbumsFull()
            .then(res=>{

                if(res.data.length){
                    var myAlbums = res.data.map(album =>{
                        let totalPlays = album.plays || 0;
                        let totalDownloads = 0;
                        let totalLikes = album.likes || 0;
                        let totalProjects = 0;
                        let totalLicenses = 0;
                        let approvedTracks =0;
                        let tracksCount = 0;
                        album.tracks.forEach(track=>{
                            totalPlays += track.plays;
                            totalDownloads += track.downloads;
                            totalLikes +=track.likes;
                            totalProjects +=track.projects;
                            totalLicenses +=track.purchases;
                            if(track.status === "approved"){
                                approvedTracks ++;
                            }
                            tracksCount++
                        })
                        album.plays = totalPlays;
                        album.downloads = totalDownloads;
                        album.likes = totalLikes;
                        album.projects = totalProjects;
                        album.purchases = totalLicenses;
                        album.approvedTracks = approvedTracks;
                        album.tracksCount = tracksCount
                        return album
                    })
                    this.setState({
                        myAlbums: myAlbums,
                        currentAlbums: myAlbums,
                        isAlbumModalOpen: false,
                        isLoaded: true
                    })
                }
                else{
                    this.setState({isLoaded: true})
                }
            })

    }

    openAlbumModal = ()=>{
        this.setState({isAlbumModalOpen: true})
    }
    handleAlbumModalClose= ()=>{
        if(this.state.albumLoading){
            return
        }
        else{
            this.setState({isAlbumModalOpen: false})
        }
    }
    albumLoadingCB=(isLoading)=>{
        this.setState({albumLoading: isLoading})
    }
    closeAlbumModal = async ()=>{
        if(this.state.isAlbumModalOpen){
            if(await confirm("Are you sure you want to exit without saving?", "", this.getAlbumRef)){
                this.setState({isAlbumModalOpen: false, newAlbumTitle: "", albumArt: [], albumPreview: null})
                return true
            }
            else{
                return
            }
        }else{
            return false
        }
    }

    updateAlbumsCB = (newAlbum) => {

        let currentAlbums = this.state.currentAlbums
        currentAlbums.unshift({
            id: newAlbum.id,
            title: newAlbum.label,
            createdOn: new Date(Date.now()).toISOString(),
            imageURL: newAlbum.artwork,
            artistURL: currentAlbums[0]?.artistURL,
            tracks: [],
            plays: 0,
            downloads: 0,
            likes: 0,
            projects: 0,
            purchases: 0,
            tracksCount: 0,
            approvedTracks: 0
        })

        this.setState({
            currentAlbums: currentAlbums,
            isAlbumModalOpen: false
        })
    }

    getAlbumRef = (ref)=>{
        this.confirmModalAlbum = ref
        //
    }

    handleInputChange = (event)=>{
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });
    }

    handleModalClick = async (e)=>{


        if(this.state.trackModalOpen && !this.state.submitting){
            if(this.confirmModalAlbum){

                if(this.confirmModalAlbum.contains(e.target) && (e.target.id !=="modal-cancel" || e.target.id !=="modal-confirm")){

                    return
                }

            }
            if(this.confirmModalTrack){

                if(this.confirmModalTrack.contains(e.target) && (e.target.id !=="modal-cancel" || e.target.id !=="modal-confirm")){

                    return
                }

            }

            if(this.albumnode.contains(e.target) && e.target.className !=="modal-overlay"){

                return
            }
            if(await this.closeAlbumModal()){

                return
            }
            if(this.node.contains(e.target) && e.target.className !== "modal-overlay"){

                return
            }
            if(!this.state.albumModalOpen){
                this.handleModalClose()
            }
        }
    }

    setAlbumArtState = (file)=>{

        if(file.error){
            this.setState({albumArtError: "File must be an image in a 1x1 ratio!"})
        }
        else{
            this.setState({
                albumArt: file,
                albumPreview: URL.createObjectURL(file[0]),
                albumArtError: null
            })
        }

    }

    submitNewAlbum = ()=>{
        if(this.state.newAlbumTitle.length > 1 && this.state.albumArt.length >0){
            let newAlbum = {
                title: this.state.newAlbumTitle
            }
            api.createNewAlbum(newAlbum)
                .then(response=>{

                    if(response.data.success === true){
                        const data = new FormData()
                        data.append('file', this.state.albumArt[0])
                        api.updateAlbumArt(response.data.album.id, data)
                            .then(res=>{

                                if(res.data.success === true){
                                    let myAlbums = this.state.myAlbums
                                    myAlbums.push({
                                        id: res.data.album.id,
                                        title: res.data.album.title,
                                        imageURL: res.data.album.imageURL
                                    })
                                    Success({message:"Album Added!", onClose: this.successAlbumToastSubmit})
                                    this.setState({albumModalOpen: false, myAlbums: myAlbums, newTrackAlbum: myAlbums[myAlbums.length-1]})
                                }
                                else{
                                    Error({message:"An error occurred!", onClose: this.errorToast})
                                }
                            })
                            .catch(error=>{
                                Error({message:"An error occurred!", onClose: this.errorToast})
                            })
                    }
                })
        }
    }
    successAlbumToastSubmit = ()=>{
        toast.dismiss()
    }
    successToastSubmit = ()=>{
        toast.dismiss()
        this.setState({trackModalOpen: false})
        window.location.replace('/dashboard/new-track/'+this.state.newTrackId)
    }
    errorToastSubmit = ()=>{
        toast.dismiss()
        this.setState({trackModalOpen: false})
        window.location.replace('/dashboard/new-track/'+this.state.newTrackId)
    }
    errorToastSave = ()=>{
        toast.dismiss()
        api.getTracks()
        .then(res => {

            this.setState({ trackList: res.data })
        })
    }
    errorToast = ()=>{
        toast.dismiss()
    }
    successToastSave = ()=>{
        toast.dismiss()
        // change to get MY tracks for artist
        api.getTracks()
        .then(res => {

            this.setState({ trackList: res.data })
        })
    }

    toggleSortTippy = (sortChoice, overRideTippy)=>{
        this.setState({isSortTippyOpen: overRideTippy === false ? false : !this.state.isSortTippyOpen, sortChoice: sortChoice})
    }

    sortBy = (sortChoice, sortText, overRide, tippyStateCB, currentSortChoice, overRideTippy)=>{
        let unsorted = this.state.currentAlbums
        let compare;
        let sorted;

        if(sortChoice !== this.state.sortChoice || overRide){

            switch(sortChoice){
                case "NameA":
                    compare = (a, b)=> {
                        // Use toUpperCase() to ignore character casing
                        const aName = a.title.toUpperCase();
                        const bName = b.title.toUpperCase();

                        let comparison = 0;
                        if (aName < bName) {
                          comparison = -1;
                        } else if (aName > bName) {
                          comparison = 1;
                        }
                        return comparison;
                      }
                    sorted = unsorted.sort(compare)
                    break;
                case "NameD":
                    compare = (a, b)=> {

                        // Use toUpperCase() to ignore character casing
                        const aName = a.title.toUpperCase();
                        const bName = b.title.toUpperCase();

                        let comparison = 0;
                        if (aName < bName) {
                          comparison = -1;
                        } else if (aName > bName) {
                          comparison = 1;
                        }
                        return comparison*-1;
                      }
                    sorted = unsorted.sort(compare)
                    break;
                case "RecentD":
                    sorted = unsorted.sort((a, b) => a.createdOn < b.createdOn ? 1 : -1)
                    break;
                case "MostP":
                    compare = (a,b)=>{
                        const aPlays = a.plays;
                        const bPlays = b.plays;
                        const aCreated = a.createdOn;
                        const bCreated = b.createdOn;
                        let comparison = 0;
                        if(aPlays < bPlays){
                            comparison = 1
                        }
                        else if(aPlays > bPlays){
                            comparison = -1
                        }
                        else if (aCreated < bCreated){
                            comparison = -1
                        }
                        else if (aCreated > bCreated){
                            comparison = 1;
                        }
                        return comparison
                    }
                    sorted = unsorted.sort(compare)
                    break;
                default:
                    sorted = unsorted.sort((a, b) => a.createdOn < b.createdOn ? 1 : -1)
                    break;
            }
            this.setState({currentAlbums: sorted, sortText: sortText}, ()=>tippyStateCB(sortChoice, overRideTippy))
        }
        else{
            tippyStateCB(sortChoice)
        }
    }

    render () {
        let currentAlbums = []
        //
        if (this.props.match.params.status === "all") {
            currentAlbums = this.state.currentAlbums
        }
        else if (this.props.match.params.status === "public") {
            currentAlbums = this.state.currentAlbums.filter(album => album.approvedTracks > 0)
        }
        else if (this.props.match.params.status === "private") {
            currentAlbums = this.state.currentAlbums.filter(album => !album.approvedTracks )
        }
        else {
            currentAlbums = this.state.myAlbums
        }
        return (
            <UserConsumer>
                {({ user, login, logout})=>(
                    <>
                        <div className="container">
                            {/* {# App Header #} */}
                            <div className="view-header">
                                <h1 className="view-title">Albums</h1>
                                <div className="view-header-controls">
                                    <MicroModal
                                        open={this.state.isAlbumModalOpen}
                                        closeOnEscapePress={true}
                                        handleClose={()=>this.handleAlbumModalClose()}
                                        closeOnOverlayClick={true}
                                        closeOnAnimationEnd={true}
                                        modalOverlayClassName="modal-overlay"
                                        modalClassName="modal micromodal-slide is-open"
                                        modalOverlayStyles={null}
                                        children={ handleClose =>
                                          <AlbumModal
                                            handleClose={handleClose}
                                            updateAlbumsCB={this.updateAlbumsCB}
                                            loadingCB={this.albumLoadingCB}
                                          />
                                        }
                                        containerStyles={{background: "#131313", padding:"asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box"}}
                                    ></MicroModal>

                                    <button
                                        onClick={(e)=>{e.preventDefault();this.openAlbumModal()}}
                                        className="btn btn-primary"
                                    >
                                        <span className="icon-plus mr-5"></span>Create Album
                                    </button>
                                </div>
                            </div>
                            {/* {# Subnav #} */}
                            <div className="tabs mb-30">
                                <ul className="tab-items">
                                    <li className={`tab-item ${this.props.match.params.status.toLowerCase() === "all" ? "active" : ""}`}>
                                        <NavLink to="/dashboard/albums/all" className='tab-item-title' >All</NavLink>
                                    </li>
                                    <li className={`tab-item ${this.props.match.params.status.toLowerCase() === "public" ? "active" : ""}`}>
                                        <NavLink to="/dashboard/albums/public" className="tab-item-title">Public</NavLink>
                                    </li>
                                    <li className={`tab-item ${this.props.match.params.status.toLowerCase() === "private" ? "active" : ""}`}>
                                        <NavLink to="/dashboard/albums/private" className="tab-item-title">Private</NavLink>
                                    </li>
                                </ul>
                            </div>
                            <SortBar
                                resultsText={currentAlbums.length !== 1 ? `${currentAlbums.length} Albums` : `${currentAlbums.length} Album`}
                                trackStatus={this.props.match.params.status}
                                sortCriteria={this.state.sortText}
                                sortFilters={albumFilters}
                                sortBarId={"album_sort"}
                                marginClasses={"mb-20 md:mb-30"}
                                toggleSortTippy={this.toggleSortTippy}
                                isSortTippyOpen={this.state.isSortTippyOpen}
                                sortTippyCB={this.toggleSortTippy}
                                sortBy={this.sortBy}
                            />
                            {this.state.isLoaded ?
                            <div className="album-list-container dashboard">
                                {/* Albums */}
                                {
                                    currentAlbums.map((album, index)=>{
                                        return(
                                            <AlbumListItem album={album} key={`albumListItem-${album.id}`} />
                                        )
                                    })
                                }
                            </div>:
                            <CircularIndeterminant forceCenter/>
                            }
                        </div>

                    </>
                )}
            </UserConsumer>
        );
    }


}
ArtistAlbums.contextType = PlayerContext
export default ArtistAlbums

import React, { Component } from "react";
// import tippy from "../../tooltips"

class NewTrackToolTip extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {

            }
    //updating our input fields as the user enters keys
    handleInputChange = event => {   
    };

    render() {
        return (    
                <div className="dropdown-menu">
                    <div className="dropdown-group">
                        {this.props.deleteDisabled ? 
                            <a href="# "  onClick={this.props.delete}><span className="icon-trash"></span>No Options</a>:
                            <a href="# "  onClick={this.props.delete}><span className="icon-trash"></span>Delete</a>
                        }
                    </div>
                </div>
        )
    }

}
export default NewTrackToolTip
import React from 'react';
import { Helmet } from "react-helmet";
export default function DMCA() {
  return (
    <>
      <Helmet title="DMCA Policy - Deep Sounds | Music for Creators" />
      <div className="container max-w-4xl text-center leading-tightest pt-100 pb-40 xl:pt-120 xl:pb-40">
          <h1 className="text-h1-sm md:text-h1 mb-10 md:mb-20">DMCA Policy</h1>
      </div>

      <section className="container max-w-lg md:max-w-screen-3xl mb-60 lg:mb-80">
        <div class="article max-w-2xl mx-auto pb-100">

          <p>Deep Sounds treats copyright infringement as a serious matter. If you believe your copyright has been infringed by material appearing on the Deep Sounds’ website, please submit a notice by emailing us at dmca@deepsounds.com or by mail to:</p>

          <p>
            Copyright Department<br/>
            Deep Sounds LLC<br/>
            2041 W. Carroll Ave C-229<br/>
            Chicago, IL 60612
          </p>

          <p>Any and all notifications of alleged copyright infringement must be sent Deep Sounds’ designated copyright agent as listed above and include the following information:</p>

          <ol className='list-decimal pl-30 pb-30'>

            <li>A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>

            <li>Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site.</li>

            <li>Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled, and information reasonably sufficient to permit the service provider to locate the material.</li>

            <li>Information reasonably sufficient to permit the service provider to contact the complaining party, such as an address, telephone number, and, if available, an electronic mail address at which the complaining party may be contacted.</li>

            <li>A statement that the complaining party has a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law.</li>

            <li>A statement that the information in the notification is accurate, and under penalty of perjury, that the complaining party is authorized to act on behalf of the owner of an exclusive right that is allegedly infringed.</li>

          </ol>

          <p>It is the policy of Deep Sounds to terminate the account of repeat infringers, in appropriate circumstances.</p>
        </div>
      </section>
    </>
  );
}

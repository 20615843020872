import React, { Component } from "react"
import MicroModal from "react-micro-modal"
import { NavLink } from "react-router-dom"
import { Tooltip } from "react-tippy"
import 'react-tippy/dist/tippy.css'
import wav1 from '../../assets/placeholders/wav-01.png'
import wav2 from '../../assets/placeholders/wav-02.png'
import wav3 from '../../assets/placeholders/wav-03.png'
import wav4 from '../../assets/placeholders/wav-04.png'
import wav5 from '../../assets/placeholders/wav-05.png'
import wav6 from '../../assets/placeholders/wav-06.png'
import DefaultAvatar from "../../assets/svg/user-avatar.svg"
import { ProjectsContext } from "../../ProjectsContext/index"
import { confirm } from "../confirmModal/index"
import EditProjectModal from "../Projects/editProjectModal"
import ProjectTooltip from "../Projects/projectTooltip"
import { Error, Success, SuccessRedirect } from "../Toast/index"
import api from "../utils/api"

const wavList = [wav1, wav2, wav3, wav4, wav5, wav6]
class ProjectCard extends Component {
    state = {
        tracks: [],
        isProjectEditModalOpen: false,
        editedProjectType: {label: this.props.projectTypes?.name, value: this.props.projectTypes?.id},
        editedProjectName: this.props.name,
        editedClientName: this.props.client,
        coverArt: this.props.coverArt,
        defaultArt: this.props.defaultArt,
        initialCoverArt: this.props.coverArt,
        initialRealCoverArt: this.props.realCoverArt,
        randomWavIndex: Math.round(Math.random() *5),
        isCropUtilityOpen: false
    }
    componentDidMount = ()=>{
        let sceneTracks = []
        this.props.scenes.forEach(scene=>{
            sceneTracks = sceneTracks.concat(scene.sceneTracks)
        })
        let projectTypes = []
        api.getAllProjectTypes()
            .then(res=>{
                res.data.forEach(projType=>{
                    projectTypes.push({label: projType.name, value: projType.id})
                })
                this.setState({projectTypes: projectTypes})
            })
        sceneTracks = sceneTracks.sort(function(sceneTrack1, sceneTrack2){
            try{
            Object.filter = (obj, predicate) =>
            Object.keys(obj)
            .filter( key => predicate(obj[key]) )
            .reduce( (res, key) => (res[key] = obj[key], res), {} );
            let sceneTrack1PosVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===1)
            let sceneTrack1NegVotes = Object.filter(sceneTrack1.sceneTrackVotes, vote=>vote.voteValue ===0)
            let sceneTrack2PosVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===1)
            let sceneTrack2NegVotes = Object.filter(sceneTrack2.sceneTrackVotes, vote=>vote.voteValue ===0)
            sceneTrack1.score = Object.keys(sceneTrack1PosVotes).length - Object.keys(sceneTrack1NegVotes).length
            sceneTrack2.score = Object.keys(sceneTrack2PosVotes).length - Object.keys(sceneTrack2NegVotes).length
            if(sceneTrack1.score > sceneTrack2.score) return -1
            if(sceneTrack1.score < sceneTrack2.score) return 1
            if(sceneTrack1.sceneTrackAdded > sceneTrack2.sceneTrackAdded) return 1
            if(sceneTrack1.sceneTrackAdded < sceneTrack2.sceneTrackAdded) return -1
            }
            catch(err){
                return 0
            }
        })

        let tracks = sceneTracks?.map(sceneTrack => sceneTrack?.tracks)
        let defaultArt = sceneTracks[0]?.tracks?.imageURL
        this.setState({tracks, defaultArt})
    }
    componentDidUpdate = (prevProps)=>{
        if(this.props.coverArt !== this.state.coverArt && (typeof this.state.coverArt === "string" || !this.state.coverArt)){
            this.setState({coverArt: this.props.coverArt})
        }
    }
    toggleProjectTippy = ()=>{
        this.setState({isProjectTippyOpen: !this.state.isProjectTippyOpen})
    }
    handleProjectEditModalOpen = ()=>{
        this.toggleProjectTippy()
        this.setState({isProjectEditModalOpen: true, isProjectTippyOpen: false})
    }
    handleProjectEditModalClose=()=>{
        // this.props.handleFormUnsaved(false)
        this.setState({isProjectEditModalOpen: false, editedProjectType: {label: this.props.projectTypes?.name, value: this.props.projectTypes?.id},
            editedProjectName: this.props.name,
            editedClientName: this.props.client,
            coverArt: this.props.coverArt,
            defaultArt: this.props.defaultArt,
            initialCoverArt: this.props.coverArt})
    }
    handleInputChange = (event)=>{
        // this.props.handleFormUnsaved(true)
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
            [name]: value,
        });
    }
    handleProjectTypeChange= (selectedType)=>{
        this.setState({editedProjectType: selectedType})
    }
    setCoverArtState = (file)=>{
        if(file.error){
            this.setState({coverArtError: "File must be at least 250x250"})
        }
        else{
            this.setState({
                coverArt: file.length > 0 ? file: null,
                coverArtPreview: file.length > 0 ? URL.createObjectURL(file[0]): null,
                coverArtError: null,
                coverArtUpdated: file.length > 0 ? true : false
            })
        }

    }
    setRealCoverArtState = (file)=>{
        if(file.error){
            this.setState({realCoverArtError: "File must be at least 250x250"})
        }
        else{
            this.setState({
                realCoverArt: file.length > 0 ? file: null,
                realCoverArtPreview: file.length > 0 ? URL.createObjectURL(file[0]): null,
                realCoverArtError: null,
                realCoverArtUpdated: file.length > 0 ? true : false
            })
        }

    }
    removeCoverArt =(event)=>{
        // event.preventDefault();
        api.removeProjectArt(this.props.id)
            .then(res=>{
                if(res.data.success){
                    Success({message: "Cover Art Removed!"})
                    this.setState({
                        coverArt: res.data.project?.coverArt,
                        coverArtPreview: null,
                        coverArtError: null,
                        coverArtUpdated: false,
                        initialCoverArt: null
                    },()=>{
                        this.context.updateProjectArt(this.props.id, res.data.project?.coverArt, false)
                    })
                }
            })
    }

    updateProject = async (trigger, errors, cb)=>{

        let triggerRes = await trigger()

        let projectBody ={
            name: this.state.editedProjectName,
            client: this.state.editedClientName,
            projectType: this.state.editedProjectType.value
        }
        if(this.state.editedProjectName.toUpperCase() === this.props.name.toUpperCase()){
            delete projectBody.name
        }

        if(triggerRes){
            this.setState({isLoading: true}, ()=>{
                if(this.state.coverArtUpdated && this.state.realCoverArtUpdated){
                    const data = new FormData()
                    data.append('file', this.state.coverArt[0])
                    api.updateProjectArt(this.props.id, data)
                        .then(res=>{
                            const coverData = new FormData()
                            coverData.append('file', this.state.realCoverArt[0])
                            api.updateProjectCoverArt(this.props.id, coverData)
                                .then(res=>{
                                    if(res.data.success === true){
                                        api.updateProjectById(this.props.id, projectBody)
                                        .then(respo=>{
                                            this.setState({isLoading: false})
                                            if(respo.data.success){
                                                this.context.updateProjectInfo(this.props.id, projectBody.name || this.props.name,  projectBody.client, "",  projectBody.projectType )
                                                cb()
                                                SuccessRedirect({message: "Project Updated!", onClose: ()=> window.location.reload()})
                                                // SuccessRedirect({message: "Project Updated!", onClose: ()=> window.location.reload()})
                                            }
                                            else{
                                                Error({message:"An error occurred!", onClose: this.errorToast})
                                            }
                                        })
                                        .catch(error=>{
                                            this.setState({isLoading: false})
                                            if(error.response.status === 406){
                                                Error({message:"Project name already used", onClose: this.errorToast})
                                            }
                                            else{
                                                Error({message:"An error occurred!", onClose: this.errorToast})
                                            }

                                        })
                                    }
                                    else{
                                        this.setState({isLoading: false})
                                        Error({message:"An error occurred!", onClose: this.errorToast})

                                    }
                                })
                        })
                        .catch(error=>{
                            this.setState({isLoading: false})
                            Error({message:"An error occurred!", onClose: this.errorToast})
                        })
                }
                else if(this.state.coverArtUpdated){
                    const data = new FormData()
                    data.append('file', this.state.coverArt[0])
                    api.updateProjectArt(this.props.id, data)
                        .then(res=>{
                            if(res.data.success === true){
                                this.context.updateProjectArt(this.props.id, res.data.project?.coverArt, true)
                                api.updateProjectById(this.props.id, projectBody)
                                .then(respo=>{
                                    this.setState({isLoading: false})
                                    if(respo.data.success){
                                        this.context.updateProjectInfo(this.props.id, projectBody.name || this.props.name,  projectBody.client, "",  projectBody.projectType )
                                        cb()
                                        SuccessRedirect({message: "Project Updated!", onClose: ()=> window.location.reload()})
                                        // SuccessRedirect({message: "Project Updated!", onClose: ()=> window.location.reload()})
                                    }
                                    else{
                                        Error({message:"An error occurred!", onClose: this.errorToast})
                                    }
                                })
                                .catch(error=>{
                                    this.setState({isLoading: false})
                                    if(error.response.status === 406){
                                        Error({message:"Project name already used", onClose: this.errorToast})
                                    }
                                    else{
                                        Error({message:"An error occurred!", onClose: this.errorToast})
                                    }

                                })
                            }
                            else{
                                this.setState({isLoading: false})
                                Error({message:"An error occurred!", onClose: this.errorToast})

                            }
                        })
                        .catch(error=>{
                            this.setState({isLoading: false})
                            Error({message:"An error occurred!", onClose: this.errorToast})
                        })
                }
                else if(this.state.realCoverArtUpdated){
                    const data = new FormData()
                    data.append('file', this.state.realCoverArt[0])
                    api.updateProjectCoverArt(this.props.id, data)
                        .then(res=>{
                            if(res.data.success === true){
                                api.updateProjectById(this.props.id, projectBody)
                                .then(respo=>{
                                    this.setState({isLoading: false})
                                    if(respo.data.success){
                                        this.context.updateProjectInfo(this.props.id, projectBody.name || this.props.name,  projectBody.client, "",  projectBody.projectType )
                                        cb()
                                        SuccessRedirect({message: "Project Updated!", onClose: ()=> window.location.reload()})
                                        // SuccessRedirect({message: "Project Updated!", onClose: ()=> window.location.reload()})
                                    }
                                    else{
                                        Error({message:"An error occurred!", onClose: this.errorToast})
                                    }
                                })
                                .catch(error=>{
                                    this.setState({isLoading: false})
                                    if(error.response.status === 406){
                                        Error({message:"Project name already used", onClose: this.errorToast})
                                    }
                                    else{
                                        Error({message:"An error occurred!", onClose: this.errorToast})
                                    }

                                })
                            }
                            else{
                                this.setState({isLoading: false})
                                Error({message:"An error occurred!", onClose: this.errorToast})

                            }
                        })
                        .catch(error=>{
                            this.setState({isLoading: false})
                            Error({message:"An error occurred!", onClose: this.errorToast})
                        })
                }
                else{
                    api.updateProjectById(this.props.id, projectBody)
                    .then(res=>{
                        if(res.data.success){
                            this.setState({isLoading: false})
                            this.context.updateProjectInfo(this.props.id, projectBody.name || this.props.name,  projectBody.client, "",  projectBody.projectType )
                            // this.props.handleFormUnsaved(false)
                            Success({message: "Project Updated!"})
                            let name = this.state.editedProjectName || this.props.name
                            let client = this.state.editedClientName || this.props.client
                            let projectType = this.state.editedProjectType || this.props.projectTypes
                            // this.setState({project: project})
                            cb()
                        }
                        else{
                            this.setState({isLoading: false})
                            Error({message:"An error occurred!", onClose: this.errorToast})
                        }
                    })
                    .catch(error=>{
                        this.setState({isLoading: false})
                        if(error.response.status === 406){
                            Error({message:"Project name already used", onClose: this.errorToast})
                        }
                        else{
                            Error({message:"An error occurred!", onClose: this.errorToast})
                        }

                    })
                }
            })
        }

    }
    checkIfProjectUsed = (value)=>{
        let existingProjectNames = this.context.myProjects.filter(project=>project.id !== this.props.id).map(project=>project.name)
        let isNameTaken = existingProjectNames.filter(name=>name?.toUpperCase()===value?.toUpperCase()).length > 0
        if(isNameTaken){
            return "Project name already used"
        }
        else{
            return true
        }
    }
    markComplete =()=>{
        this.toggleProjectTippy()
        this.setState({isProjectDetailTippyOpen: false}, ()=>
            api.markProjectComplete(this.props.id)
                .then(res=>{
                    if(res.data.success){
                        Success({message: "Project Marked as Complete"})
                        this.context.removeFromList(this.props.id)
                    }
                })
        )
    }
    markIncomplete =()=>{
        this.setState({isProjectDetailTippyOpen: false}, ()=>
            api.markProjectIncomplete(this.props.id)
                .then(res=>{
                    if(res.data.success){
                        Success({message: "Project Marked as Active"})
                    }
                })
        )
    }
    duplicateProject = ()=>{
        this.toggleProjectTippy()
        this.setState({isProjectDetailTippyOpen: false});
        api.duplicateProject(this.props.id)
            .then(res=>{
                if(res.data.success){
                    setTimeout(window.location.replace("/dashboard/projects/edit/"+res.data.duplicatedProject.id), 1000)

                    // this.props.history.push("/dashboard/projects/edit/"+res.data.duplicatedProject.id)
                    // SuccessRedirect({message: `${this.state.project.name} Duplicated!`, onClose: ()=>{this.props.history.push("/dashboard/projects/edit/"+res.data.duplicatedProject.id)}})
                }
                else{
                    Error({message: "An Error Occured!"})
                }
            })
            .catch(err=>{
                Error({message: "An Error Occured!"})
            })
    }
    leaveProject = async ()=>{
        this.toggleProjectTippy()
        if(await confirm("Are you sure you want to leave this project? You will not have access to it unless you are invited back", "Leave Project", true)){
            api.leaveProject(this.props.id)
                .then(res=>{
                    if(res.data.success){
                        // SuccessRedirect({message: "Left Project!", onClose: ()=>{this.props.history.push("/dashboard/projects/all")}})
                        this.context.removeFromList(this.props.id)
                        Success({message: `Left ${this.props.name}`})

                    }
                    else{
                        Error({message: "There was an error"})
                    }
                })
                .catch(error=>{
                    Error({message: "There was an error"})
                })
        }
    }
    deleteProject = async ()=>{
        this.toggleProjectTippy()
        if(await confirm("Are you sure you want to delete this project? You will no longer have access to it after this is done", "Delete Project", true)){
            api.deleteProject(this.props.id)
                .then(res=>{
                    if(res.data.success){
                        this.context.removeFromList(this.props.id)
                        Success({message: `${this.props.name} was deleted`})
                    }
                    else{
                        Error({message: "There was an error"})
                    }
                })
                .catch(error=>{
                    if(error?.response?.status === 401){
                        Error({message: "You cannot delete a project with an associated purchase"})
                    }
                    else if (error?.response?.status === 403){
                        Error({message: error?.response.data})
                    }
                    else{
                        Error({message: "There was an error"})
                    }
                })
        }
    }
    resolveCardPhoto=(cardPhotoKey)=>{
        const key = cardPhotoKey
        if(cardPhotoKey){
            try{
                const ext = key?.split('.').reverse()[0];
                let sizeMod;
                let width = window.innerWidth
                if(width >= 1024){
                    sizeMod = '500x500'
                }
                else{
                    sizeMod = '325x325'
                }
                let final = key.replace('.' + ext, '') + '-minified-' + sizeMod + '.' + ext
                return final
            }
            catch(error){
            }
        }
    }
    resolveCardPhotoAlt=(cardPhotoKey)=>{
        const key = cardPhotoKey
        if(cardPhotoKey){
            const ext = key.split('.').reverse()[0];
            let final = key.replace('.' + ext, '') + '-minified-500x500' + '.' + ext
            return final
        }
    }
    resolveProfilePhoto = (profilePhotoKey)=>{
        if(profilePhotoKey){
            const key = profilePhotoKey
            const ext = key.split('.').reverse()[0];
            let final = key.replace('.' + ext, '') + '-minified-60x60' + '.' + ext
            return final
        }
    }
    handleCropUtilityClose = (cropSaved)=>{
        this.setState({isCropUtilityOpen: false})
        if(cropSaved){
            cropSaved()
        }
        else{
        }
    }
    render=()=>{
    return(
        <div className="artwork-card">
            <div className="artwork-card-image">
                {!this.state.coverArt && !this.state.defaultArt ?
                    <img src={wavList[this.state.randomWavIndex]} alt={this.props.name}/>:
                    <img src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveCardPhoto(this.state.coverArt) || this.resolveCardPhotoAlt(this.state.defaultArt)}`} alt={this.props.name}/>
                }
                <div className="artwork-card-hover">
                    {this.state.tracks.length > 0 &&
                        <>
                            {this.props.isPlaying  ?
                                <div onClick={this.props.handlePause} className="circle-icon-fill bg-white text-black circle-icon-lg">
                                    <span  className="icon-pause"></span>
                                </div>:
                                (this.props.contextIdKey?.indexOf(`projectListCard-${this.props.id}`) || -1 )!==-1 ?
                                <div onClick={this.props.handlePlay} className="circle-icon-fill bg-white text-black circle-icon-xl">
                    <span  className="icon-play"></span>
                </div>:
                                <div onClick={()=>{this.props.playTracks(this.props.playOrder, this.props.id)}} className="circle-icon-fill bg-white text-black circle-icon-lg">
                                    <span  className="icon-play"></span>
                                </div>
                            }
                        </>
                    }
                </div>
            </div>
            <div className="artwork-card-content">
                <div className="artwork-card-title"><NavLink to={"/dashboard/projects/edit/"+this.props.id}>{this.props.name}</NavLink></div>
                <div className="artwork-card-subtext">{this.props?.allTracks?.length} Track{(this.props?.allTracks?.length > 1 || this.props?.allTracks?.length === 0) && "s" }</div>
                <div className="img-summary mt-10 cursor-default">
                    {this.props.activeCollabs?.length < 5 ?
                        this.props.activeCollabs?.slice(0,4).map(collaborator=>
                            <div key={"projectCollaborator"+(collaborator.firstName) + (collaborator.lastName)} className="img-summary-item rounded-full js-tooltip" title={(collaborator.firstName) + " " + (collaborator.lastName)}>
                                <img src={`${collaborator.profilePhoto ? 'https://'+ process.env.REACT_APP_S3_PHOTO_BUCKET +'.s3.us-east-2.amazonaws.com/' +  (process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" ) +this.resolveProfilePhoto(collaborator.profilePhoto) : DefaultAvatar}`} alt={(collaborator.firstName) + " " + (collaborator.lastName)}/>
                            </div>
                        )
                        :
                        this.props.activeCollabs?.slice(0,3).map(collaborator=>
                            <div key={"projectCollaborator"+(collaborator.firstName) + (collaborator.lastName)} className="img-summary-item rounded-full js-tooltip" title={(collaborator.firstName) + " " + (collaborator.lastName)}>
                                <img src={`${collaborator.profilePhoto ? 'https://' + process.env.REACT_APP_S3_PHOTO_BUCKET +'.s3.us-east-2.amazonaws.com/' + (process.env.REACT_APP_S3_SUB_BUCKET ? process.env.REACT_APP_S3_SUB_BUCKET + "/": "" ) + this.resolveProfilePhoto(collaborator.profilePhoto) : DefaultAvatar}`} alt={(collaborator.firstName) + " " + (collaborator.lastName)}/>
                            </div>
                        )
                    }
                        {this.props.activeCollabs?.length > 4 && <div className="img-summary-more rounded-full">+{this.props.activeCollabs.length- 3}</div> }

                </div>

                <div className="circle-icon text-gray-500">
                <Tooltip
                        arrow={false}
                        open={this.state.isProjectTippyOpen}
                        interactive={true}
                        position={'bottom-end'}
                        popperOptions={{
                            modifiers: {
                                preventOverflow: { enabled: false },
                                flip: { enabled: false },
                                hide: { enabled: false },
                                addZIndex: {
                                    enabled: true,
                                    order: 810,
                                    fn: data => ({
                                        ...data,
                                        styles: {
                                        ...data.styles,
                                        zIndex: 10,
                                        },
                                    })
                                }
                             }
                        }}
                        trigger={'click'}
                        useContext={true}
                        // hideOnClick={true}
                        html={(<ProjectTooltip
                                    projectId={this.props.id}
                                    markComplete={this.markComplete}
                                    markIncomplete={this.markIncomplete}
                                    handleProjectEditModalOpen={this.handleProjectEditModalOpen}
                                    complete={this.props.complete}
                                    artistBrowse={this.props.artistBrowse}
                                    artistTrackList={this.props.artistTrackList}
                                    trackStatus={this.props.trackStatus}
                                    duplicateProject={this.duplicateProject}
                                    leaveProject={this.leaveProject}
                                    isOwner={this.props.isOwner}
                                    purchaseId={this.props.purchaseId}
                                    deleteProject={this.deleteProject}
                                    projectLink={true}
                                >
                            </ProjectTooltip>
                        )}
                        allowHTML={true}
                        onUntrigger={this.toggleProjectTippy}
                        onRequestClose={this.toggleProjectTippy}
                    >
                        <button className="circle-icon js-dropdown-toggle"
                            id="project_edit_options"
                            onClick={this.toggleProjectTippy}
                            data-tippy-html="#project_edit_options"
                            data-tippy-placement="bottom-end"
                            type="button"
                        >
                            <span className="icon-dots-vert"></span>
                        </button>
                    </Tooltip>

                </div>
            </div>
            <MicroModal
                                        open={this.state.isProjectEditModalOpen}
                                        closeOnEscapePress={true}
                                        handleClose={()=>this.handleProjectEditModalClose()}
                                        closeOnOverlayClick={true}
                                        closeOnAnimationEnd={true}
                                        modalOverlayClassName="modal-overlay"
                                        modalClassName="modal micromodal-slide is-open"
                                        modalOverlayStyles={null}
                                        children={handleClose=><EditProjectModal handleClose={handleClose} handleInputChange={this.handleInputChange} editedProjectName={this.state.editedProjectName}
                                            editedClientName={this.state.editedClientName} editedProjectType={this.state.editedProjectType} handleProjectTypeChange={this.handleProjectTypeChange} updateProject={this.updateProject}
                                            projectTypes={this.state.projectTypes} setCoverArtState={this.setCoverArtState} coverArt={this.state.coverArt} coverArtPreview={this.state.coverArtPreview} coverArtError={this.state.coverArtError}
                                            removeCoverArt={this.removeCoverArt} defaultArt={this.state.defaultArt} initialCoverArt={this.state.coverArt} checkIfProjectUsed={this.checkIfProjectUsed} initialRealCoverArt={this.state.initialRealCoverArt}
                                            setRealCoverArtState={this.setRealCoverArtState}  realCoverArt={this.state.realCoverArt} realCoverArtPreview={this.state.realCoverArtPreview} realCoverArtError={this.state.realCoverArtError} removeCoverArt={this.removeCoverArt}
                                            handleCropUtilityClose={this.handleCropUtilityClose} isCropUtilityOpen={this.state.isCropUtilityOpen} openPopout={()=>{this.setState({isCropUtilityOpen: true});}} isLoading={this.state.isLoading} wavPlaceholder={wavList[this.state.randomWavIndex]}
                                            userSet={this.props.userSet}
                                        />}
                                        containerStyles={{background: "#131313", padding:"asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box"}}
                            ></MicroModal>
        </div>
    )}
}
ProjectCard.contextType = ProjectsContext
export default ProjectCard

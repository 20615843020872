import axios from "axios";

const stripeAPI = {
  getAccountLink: function () {
    return axios({
      method: "get",
      url: "/api/stripe/accountLink",
    });
  },
  getTransactionsLink: function () {
    return axios({
      method: "get",
      url: "/api/stripe/transactionsLink",
    });
  },
  getPublishableKey: function () {
    return axios({
      method: "get",
      url: "/api/users/stripe/getStripePublishableKey",
    });
  },
  createPaymentIntent: function ({ checkoutType, invoicePrices }) {
    return axios({
      method: "post",
      url: "/api/paymentIntents",
      data: { checkoutType, invoicePrices },
    });
  },
  createSetupIntent: function (finalPrice) {
    return axios({
      method: "post",
      url: "/api/paymentIntents/setupIntent",
      data: finalPrice,
    });
  },
  updateSetupIntent: function ({ id, payment_method }) {
    return axios({
      method: "post",
      url: "/api/paymentIntents/setupIntent/" + id,
      data: { id, payment_method },
    });
  },
};
export default stripeAPI;

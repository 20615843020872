import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select, { components } from "react-select";
import customStyles from "../FormElements/customStyles";
import CircularIndeterminateButton from "../Loading/circularIndeterminantButton";
import { Error, Success } from "../Toast/index";
import api from "../utils/api";




// Inline Select Value
const { ValueContainer, Placeholder } = components;

const CustomValueContainer = ({ children, ...props }) => {
  return (
    <ValueContainer {...props}>
      <Placeholder {...props} isFocused={props.isFocused}>
        {props.selectProps.placeholder}
      </Placeholder>
      {React.Children.map(children, (child) =>
        child && child.type !== Placeholder ? child : null
      )}
    </ValueContainer>
  );
};

const roleOptions = [
    { value: "customer", label: "Customer" },
    { value: "artist", label: "Artist" },    
    { value: "manager", label: "Manager" },
];

export default function UserInviteForm() {
    const { control, register, handleSubmit, watch, formState, setError, errors, reset } = useForm({
        mode: "onChange",
        reValidateMode: 'onChange',
        defaultValues: {inviteRole: ''},
        resolver: undefined,
        context: undefined,
        criteriaMode: "firstError",
        shouldFocusError: true,
        shouldUnregister: true,
    });

    const onSubmit = (data, e) => {
    	setisLoading(true);
    	setisDisabled(false);
    	
    	let emails = data.inviteEmails;
		if (emails) {
		    emails = emails.replace(/\s+/g, '').split(",");
		}
		let role = data.inviteRole.value;

		api.inviteUsers(emails, role)
            .then(res=>{
                
                //User(s) successfully invited
                if (res.data === 'success') {
                    setisLoading(false);
                    setisDisabled(true);
                    setuserInviteText("Invites Sent");
                    Success({message: "Invites Sent"})
                    e.target.reset();
                    setisDisabled(false);
                    setuserInviteText("Send Invites");
                }
                else if (res.data.error) {
	                setisLoading(false);
                    let errorMessage = ""
                    if (res.data.error == "UserExists") {
                        errorMessage = "User already exists."
                    }
                    else if (res.data.error == "InviteExists") {
                        errorMessage = "Email has already been invited."   
                    }
                    else {
                        errorMessage = "Could not send the invite."  
                    }
	         	    setError("inviteEmails", {
	         	    	type: "manual", 
	         	    	message: errorMessage
	         	    });
	                setisDisabled(false);
                    Error({message: errorMessage})
                }
                
            })
            .catch(err => {
                
                setisLoading(false);
                setuserInviteText("Invites Not Sent");
                setisDisabled(true);
                setFormError(err.message);
                Error({message: err.message})
            })
    };

    const [userInviteText, setuserInviteText] = useState('Send Invites');
    const [isLoading, setisLoading] = useState(false);
    const [isDisabled, setisDisabled] = useState(false);
    const [formError, setFormError] = useState(false);
    const [shouldBlockNavigation, setshouldBlockNavigation] = useState(true);

    return (
		<form onSubmit={handleSubmit(onSubmit)} class="ds-form">
	        <div class="form-section">                            
	            <div class="mb-30">
	                <h3 class="text-label-lg mb-5">Send Invites by Email</h3>
	                <p>Separate emails with a comma.</p>
	                
	                {formError &&
	                    <div className="error-message">
	                        
	                        {formError}
	                    </div>
	                }
	            </div>
	            <div class="grid-row-sm">    
	                <div class="col w-full md:w-8/12">
	                    <div class="input-group-sm input-inline">
	                        <input 
	                            type="input" 
	                            name="inviteEmails"
	                            id="invite_email" 
	                            class="form-input" 
	                            placeholder="Enter Email Addresses" 
	                            autocomplete="off" 
	                            style={{backgroundImage: "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAkCAYAAADo6zjiAAAAAXNSR0IArs4c6QAAAbNJREFUWAntV8FqwkAQnaymUkpChB7tKSfxWCie/Yb+gbdeCqGf0YsQ+hU95QNyDoWCF/HkqdeiIaEUqyZ1ArvodrOHxanQOiCzO28y781skKwFW3scPV1/febP69XqarNeNTB2KGs07U3Ttt/Ozp3bh/u7V7muheQf6ftLUWyYDB5yz1ijuPAub2QRDDunJsdGkAO55KYYjl0OUu1VXOzQZ64Tr+IiPXedGI79bQHdbheCIAD0dUY6gV6vB67rAvo6IxVgWVbFy71KBKkAFaEc2xPQarXA931ot9tyHphiPwpJgSbfe54Hw+EQHMfZ/msVEEURjMfjCjbFeG2dFxPo9/sVOSYzxmAwGIjnTDFRQLMQAjQ5pJAQkCQJ5HlekeERxHEsiE0xUUCzEO9AmqYQhiF0Oh2Yz+ewWCzEY6aYKKBZCAGYs1wuYTabKdNNMWWxnaA4gp3Yry5JBZRlWTXDvaozUgGTyQSyLAP0dbb3DtQlmcan0yngT2ekE9ARc+z4AvC7nauh9iouhpcGamJeX8XF8MaClwaeROWRA7nk+tUnyzGvZrKg0/40gdME/t8EvgG0/NOS6v9NHQAAAABJRU5ErkJggg==')", backgroundRepeat: "no-repeat", backgroundAttachment: "scroll", backgroundSize: "16px 18px", backgroundPosition: "98% 50%"}} 
	                            ref={register({ 
	                            		required: "Email is required.",
                                        pattern: {
                                            value: /^(\s?[^\s,]+@[^\s,]+\.[^\s,]+\s?,)*(\s?[^\s,]+@[^\s,]+\.[^\s,]+)$/,
                                            message: "Email format is invalid."
                                        }
	                            	})}
	                        />
	                        <label class="input-label" for="inviteEmails">Email Addresses</label>
	                        {errors.inviteEmails && <span className="error-message">{errors.inviteEmails.message}</span>}
	                    </div>
	                </div>
	                
	                <div class="col w-full md:w-4/12">
	                    <div class="input-group-sm input-inline">
	                        <label class="input-label" for="invite_role">Role</label>
	                        <Controller
	                            name="inviteRole"
	                            as={Select}
	                            styles={customStyles}
	                            className="select-container" 
	                            classNamePrefix="select"
	                            placeholder="Role"
	                            options={roleOptions}
	                            id="invite_role"
	                            control={control}
	                            rules={{ required: "Role is required." }}
                                components={{
                                  ValueContainer: CustomValueContainer
                                }}
	                        />
	                        {errors.inviteRole && <span className="error-message">{errors.inviteRole.message}</span>}
	                    </div>
	                </div>

	            </div>
                    <div class="input-group-sm">
                        <button
                            name="submit"
                            disabled={(isDisabled)? "disabled" : ""}
                            class={`btn ${isDisabled ? 'btn-disabled' : 'btn-primary'} form-btn w-full`} 
                            type="submit">
                                {isLoading ? <CircularIndeterminateButton  size={45}  thickness={5.5}/> : userInviteText}
                        </button>
                    </div>	               
	        </div>
	    </form>
	)
}
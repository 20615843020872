import React, { Component } from "react"
import Slider from "react-slick"
import "../Carousels/index.css"
import TrackGridItem from "../Track/trackGridItem"
import PendingProjectHeader from './pendingProjectHeader'

class PendingProjectItem extends Component{

    render(){

        const settings = {
            arrows: false,
            swipeToSlide: true,
            variableWidth: true,
            slidesToShow: 2,
            edgeFriction: '.05',
            adaptiveHeight: true,
            draggable: true,
        }
      
        return(
            <div class="project-item">
                {/* {# Project Summary Heading #}
                {% set project_title = "New Film Project" %}
                {% set shared_project = true %}
                {% include "components/heading-groups/project-summary-heading.html" %} */}
                {/* {# Grid #} */}
                <PendingProjectHeader name={this.props.name} projectId={this.props.projectId} collaborators={this.props.collaborators} acceptInvitation={this.props.acceptInvitation} declineInvitation={this.props.declineInvitation}/>
                {/* <div class="card-carousel"
                    data-slick='{
                        "slidesToShow": 3
                    }'> */}
                    {/* {% for track in tracks.slice(0,5) %} */}
                    {this.props.tracks.length > 0 ?
                        <Slider ref={slider => (this.slider = slider)} {...settings} className="card-carousel">
                            {/* {% include "components/tracks/grid-card.html" %} */}
                            
                            {this.props.tracks.map(track=>{
                                return(
                                    <div key={"track"+track.title} class="carousel-slide">
                                        <TrackGridItem {...track}/>
                                    </div>
                                )
                            })}
                        </Slider>
                    :
                        <div class="prompt">
                            <div class="prompt-content">
                                <div class="text-icon-xl mb-15"><span class="icon-no-tracks-lg"></span></div>
                                <h3 class="prompt-title">There are no tracks in this project yet.</h3>
                            </div>
                        </div>
                    }
                    {/* {% endfor %} */}
                {/* </div> */}
            </div>
        )
    }
}
export default PendingProjectItem
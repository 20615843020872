import React from "react";

function ArtistTerms() {
  return (
    <div>
      <h6 className="mb-40">Last updated on 02/07/2024</h6>

      <h6 className="pt-10 pb-20">1. INTRODUCTION</h6>
      <p>
        Thank you for your interest in the Deep Sounds Platform. The Deep Sounds Platform is one of
        the services, websites and software applications (&ldquo;Services&rdquo;) offered by Deep
        Sounds, LLC (&ldquo;Deep Sounds,&rdquo; &ldquo;us,&rdquo; &ldquo;our&rdquo; or
        &ldquo;we&rdquo;). All Services are offered pursuant to terms and conditions of the
        Agreements, as defined herein. These terms and conditions (&ldquo;Deep Sounds Terms&rdquo;)
        constitute a binding agreement between Deep Sounds and you (&ldquo;you&rdquo; or
        &ldquo;your&rdquo;) and shall be deemed accepted by you effective on the earlier of the date
        that your click the &ldquo;accept&rdquo; presented with the Deep Sounds Terms or that you
        begin use of the Services (the &ldquo;Effective Date&rdquo;). If you do not accept the Deep
        Sounds Terms, do not click the &ldquo;accept&rdquo; button and do not use the Services.
      </p>
      <h6 className="pt-10 pb-20">2. BECOMING A DEEP SOUNDS ARTIST</h6>
      <p>
        The Deep Sounds Platform (&ldquo;Platform&rdquo;) is a web-based marketplace providing the
        Services at{" "}
        <a
          className="underline"
          href="https://www.deepsounds.com/"
          target="_blank"
          rel="noopener noreferrer"
        >
          www.deepsounds.com
        </a>
        , or any successor website address(es), at which Deep Sounds acts as the exclusive
        representative of an Artist (as defined below) to offer synchronization and electrical
        transcription licenses of musical compositions and sound recordings (a &ldquo;Track&rdquo;)
        to Deep Sounds&rsquo; customers who wish to purchase such synchronization and electrical
        transcription licenses (&ldquo;Licensee Users&rdquo;) under terms and conditions established
        by Deep Sounds, in its sole and exclusive discretion.
      </p>
      <p>
        Deep Sounds may invite you to join its artist roster or you may apply to Deep Sounds to be
        added to the artist roster. Any and all invitations to you to join Deep Sounds&rsquo; roster
        and/or acceptance of your application to join Deep Sounds&rsquo; roster of artists shall be
        made in Deep Sound&rsquo;s sole and exclusive discretion. You may become an
        &ldquo;Artist&rdquo; and use the Platform only after you have created an Artist Account
        which requires you to review, accept, and indicate your acceptance of these Deep Sounds
        Terms,{" "}
        <a className="underline" href="/privacy" target="_blank" rel="noopener">
          Privacy Policy
        </a>
        ,{" "}
        <a className="underline" href="/terms" target="_blank" rel="noopener">
          Terms of Use
        </a>{" "}
        (individually and collectively, the &ldquo;Agreements,&rdquo; each of which is incorporated
        by reference herein). The Agreements provide important information about the Platform and we
        urge you to read them carefully. You acknowledge that you have read, understood, and had the
        opportunity to review the Agreements with an attorney, and that you agree to be bound by
        them.
      </p>
      <p>
        To be added to the Deep Sound&rsquo;s artist roster, in addition to accepting all
        Agreements, as set forth above, you must also fully and accurately supply all information
        relating to the Artist requested by Deep Sounds (&ldquo;Artist Information&rdquo;) and
        promptly provide any and all updates or changes to Artist Information. All Artists must be
        registered with a performance rights organization (such as ASCAP, BMI or SESAC)
        (&ldquo;PRO&rdquo;) at the time of application to join Deep Sounds&rsquo; artist roster and
        fully and accurately provide all information requested regarding their membership.
      </p>
      <p>
        Deep Sounds reserves the right to accept, reject, suspend, remove, or delete an Artist
        and/or an Artist Account from the Platform for any reason at any time.
      </p>
      <h6 className="pt-10 pb-20">3. UPLOADING TRACKS</h6>
      <p>
        All Tracks must be approved by Deep Sounds for inclusion on the Platform. To submit a Track
        to be considered for inclusion on the Platform, you are required to:
      </p>
      <ul className="list-disc ml-20 mb-20">
        <li>
          provide the Track in the{" "}
          <a
            className="underline"
            href="http://help.deepsounds.com/en/articles/4813154-audio-technical-requirements"
            target="_blank"
            rel="noopener noreferrer"
          >
            format
          </a>{" "}
          required by Deep Sounds;
        </li>
        <li>
          provide any and all information, descriptive tags, metadata, or data relating to the Track
          (&ldquo;Track Information&rdquo;) requested by Deep Sounds and promptly provide any and
          all update or changes to Track Information
        </li>
        <li>
          link the Track to an album (i.e. a titled collection of Tracks) that you create or that
          was previously created by you;
        </li>
        <li>provide a name to the Track; and</li>
        <li>
          provide cover art for the album at the time that album is created (&ldquo;Album
          Art&rdquo;) in the format required by Deep Sounds
        </li>
      </ul>
      <p>
        Deep Sounds reserves all rights to approve, reject, suspend, remove, or delete, on an
        individual basis and for any reason and at any time, each Track to be included and/or remain
        on the Platform. Tracks must not use the Content ID system (defined below). Deep Sounds may
        determine in its sole and exclusive discretion that Track Information is inaccurate,
        unreliable, or insufficiently descriptive and you acknowledge and agree that Deep Sounds may
        exercise its right, but does not have an obligation, to add, change, edit, modify or delete
        any Track Information at any time, for any reason. Deep Sounds will endeavor to provide
        notice of any action it takes with regard to a Track, but Deep Sounds makes no promise,
        representation or warranty as to the time in which a submitted Track will be approved or
        rejected for inclusion on the Platform, and you acknowledge and agree that Deep Sounds shall
        have no liability to you or to any third party for any delay in approving or rejecting a
        Track, or delay or failure to provide you notice of any action taken with regard to a Track,
        Artist Information, Track Information, Album Art, and/or Artist Likeness (as defined below)
        including, without limitation, that a Track has been approved, rejected, suspended, removed,
        or deleted from the Platform or Services. Without limiting the foregoing, you understand and
        agree that in order to accurately account for performances of Tracks by the PRO in which
        Artist is a member, Deep Sounds shall rename all Tracks to identify the Track as being
        offered for license on the Platform. By way of example only, Deep Sounds may append
        &ldquo;DS&rdquo; to the end of the Track name provided by Artist.
      </p>
      <p>
        Upon Deep Sound&rsquo;s approval of a Track for inclusion on the Platform, the Track will be
        available on the Platform on the date of approval (&ldquo;Approval Date&rdquo;). In the
        event that Deep Sounds rejects a Track for inclusion on the Platform, or suspends, removes
        or deletes a previously approved Track from the Platform, Deep Sounds shall have no
        obligation, or further obligation (as the case may be), to act as your representative in any
        capacity or to provide any Services with regard to the Track.
      </p>

      <h6 className="pt-10 pb-20">4. APPOINTMENT OF DEEP SOUNDS AS REPRESENTATIVE</h6>
      <p>
        By uploading a Track to be considered for inclusion on the Platform, you agree that
        beginning immediately upon upload and continuing throughout the Term (as defined below)
        applicable to that Track, you hereby appoint Deep Sounds, and Deep Sounds accepts such
        appointment, to be your exclusive worldwide representative to promote, market, advertise and
        offer the Track for synchronization and electrical transcription license(s), for any and all
        media, including but not limited to films, television productions, commercials, digital
        broadcasts and streaming, video games, virtual reality media, and web applications, all in
        any form, channel, or medium now known or hereafter created. All licenses shall be made
        under terms and conditions established and/or agreed to by Deep Sounds in its sole and
        exclusive discretion. Artist further hereby irrevocably appoints Deep Sounds as Artist's
        true and lawful attorney-in-fact during the Term to (a) sign, make, execute and deliver in
        the name of Artist any and all contracts with Licensee Users relating solely to the
        synchronization and electrical transcription licensing of any and all of Artist's Tracks,
        without further notice to or approval by Artist, to administer all licenses and rights, and
        collect, accept and distribute all payments related thereto; (b) to submit Tracks and
        provide all information necessary to include the composition in the Track in the catalog of
        the PRO in which Artist is a member, and to collect, accept and distribute all payments to
        the publisher of the composition in the Track from such PRO; and (c) to register
        Artist&rsquo;s Tracks with the Content ID system and to collect, accept, and distribute all
        payments derived from the use of Artist&rsquo;s Tracks in the Content ID system. During the
        Term, Deep Sounds shall be exclusively authorized to administer Tracks within the Content ID
        system, including but not limited to the approval of the use of Tracks within the Content ID
        system, and all such approvals shall be deemed licenses under this Agreement, subject to all
        of the terms and conditions applicable to licenses. Artist gives to Deep Sounds as Artist's
        attorney-in-fact, full power and authority to do and perform all and every act and thing
        necessary to be done for the purposes set forth in this Paragraph 4 as fully as Artist might
        or could do if personally present and ratifies and confirms all that Deep Sounds shall
        lawfully do or cause to be done by virtue of the grant of such power.
      </p>
      <p>
        You further agree that by uploading a Track to be considered for inclusion on the Platform,
        beginning immediately upon the upload of the Track and continuing throughout the Term
        applicable to that Track, you grant Deep Sounds a non-exclusive, transferable,
        sub-licensable, royalty-free, fully paid, worldwide license during the Term to use (in whole
        and/or in part), reproduce, display, perform, publish, distribute, modify, and create
        derivative works (including but not limited to time edits, watermarking, and format
        conversions) from the Track, Artist Information, Track Information, Album Art, Artist
        Likeness (as defined below) and any and all information that you provide to us in
        conjunction with your use of the Services and/or the Platform (collectively &ldquo;User
        Content&rdquo;) in connection with providing the Service, Deep Sounds&rsquo; business, use
        of the Platform and future applications, the promotion, marketing and advertising of the
        Services and/or your Tracks, and the promotion, marketing and advertising of Deep
        Sounds&rsquo; business, in any and all formats, channels and media now known or hereafter
        created. As used herein, &ldquo;Artist Likeness&rdquo; shall mean your name, professional or
        stage name, band name and any service marks or trademarks affiliated therewith, likeness,
        photograph, and/or biographical information.
      </p>
      <p>
        As an Artist, when you upload a Track, you may elect to include one or more additional
        versions of the Track with varying run times, one or more &ldquo;Stems&rdquo; featuring
        isolated instruments or vocals, or instrumental or vocal only versions of the Track. Such
        additional versions and/or Stems are not required but may make your Track more desirable for
        license. All licenses of Tracks to Licensee Users shall include any and all versions and
        Stems attendant to that Track and shall be treated as a single Track for all purposes.
      </p>
      <p>
        Ownership in and to all Tracks, Artist Information, Track Information, Album Art, Artists
        Likeness, and User Content shall at all times remain with you and any co-owner thereof, as
        may be applicable, except that any additions, edits, or modifications to, or derivative
        works created based upon, any of the foregoing by Deep Sounds or on behalf of Deep Sounds by
        its agents, employees, or affiliates, is and shall remain the property of Deep Sounds.
      </p>
      <p>
        Deep Sounds will endeavor, but makes no promise, representation or warranty, that it shall
        be successful in granting any synchronization or electrical transcription license in or to
        any Track to any Licensee or that Deep Sounds will display User Content, including but not
        limited to Artist Information or Track Information on any promotional, marketing, or
        advertising material, and you understand and agree that any failure to do so by Deep Sounds,
        notwithstanding any notice by you, shall not constitute a breach of the Agreements.
      </p>
      <p>
        The &ldquo;Term&rdquo; as applied to each individual Track shall begin immediately upon the
        upload of that individual Track and shall continue in perpetuity unless and until either (i)
        Deep Sounds rejects, suspends, removes, or deletes that individual Track from the Platform
        or (ii) the individual Track is removed or deleted from the Platform pursuant to your
        request for removal of that individual Track from the Platform as provided herein. You
        understand and agree that notwithstanding the end of a Term, Deep Sounds shall not be
        required to remove, delete, or modify any User Content (including without limitation, Track,
        Artist Information, Track Information, Album Art, Artist Likeness) comprising or included in
        any promotional, advertising, or marketing material related to the Platform, Services and/or
        Deep Sounds&rsquo; business, including but not limited to use on any social media, published
        before the end of the Term and that all licenses granted to Licensee Users with regard to
        that Track shall remain in full force and effect and shall survive the termination of the
        Term and/or Agreements for any and all reasons and/or the removal, suspension or deletion of
        the Track from the Platform for any and all reasons, regardless of any breach of the
        Agreements by any party. In addition to the foregoing, a Licensee User who has purchased a
        Track prior to the end of the Term for that Track shall have a right to purchase an upgraded
        license of that same Track for a period of five (5) years from the end of the Term for that
        Track, and all terms and conditions of the Agreements shall apply to any such purchase of a
        license upgrade. The Approval Date, Term, and the end of a Term is calculated and applied on
        an individual Track basis and the Approval Date, Term, or the end of a Term applied to any
        individual Track shall not affect the Approval Date, Term, or end of a Term of any other
        individual Track. Deep Sounds reserves the right to suspend or terminate the Services or
        terminate the Agreements, at any time, without notice.
      </p>

      <h6 className="pt-10 pb-20">5. TRACK REMOVAL</h6>
      <p>
        You may request removal of an individual Track from the Platform and termination of the Term
        related to that Track at any time after one (1) year from the Approval Date of that Track by
        providing sixty (60) days&rsquo; notice to Deep Sounds in the form then provided on the
        Platform website. If you fail to provide notice of your request for removal of the Track
        from the Platform in the form required, your notice shall be ineffective. During such sixty
        (60) day period, which shall begin immediately upon Deep Sounds&rsquo; receipt of proper
        notice of your request for the removal of the Track from the Platform, the Track shall
        remain active on the Platform and all licenses, rights, restrictions, rules and requirements
        shall continue to apply in full to the Track. For the elimination of doubt, Deep Sounds will
        not remove or delete a Track based upon your request, and the Term applicable to that Track
        shall not terminate, until after a minimum of 425 days from the Approval Date. Artist agrees
        that Artist shall not re-upload a Track that Artist has previously requested be removed from
        the Platform.
      </p>
      <p>
        In the event that a Track is removed or deleted from the Platform, Deep Sounds shall retain
        a copy of the Track, Album Art, Artist Information, and Track Information solely for the
        benefit of any and all Licensee Users who purchased a license to use the Track prior to the
        removal of the Track from the Platform or who wish to purchase an upgraded license to the
        Track. In addition, any and all right or license to exploit, use, reproduce, distribute,
        display, perform in any media, create derivative works based upon Artist Information, Track
        Information, Artist Likeness, Album Art and/or other information associated or related to
        the Track that is included prior to the end of the Term for that Track in any advertising,
        promotional material, or marketing material used or offered by Deep Sounds, its affiliates,
        sublicensees, and/or a Licensee User of the Track shall survive any termination of the
        Agreements or removal of the Track from the Platform for any reason.
      </p>

      <h6 className="pt-10 pb-20">6. PRICING AND PAYMENT</h6>
      <p>
        As your exclusive representative to license synchronization and electrical transcription
        rights to your Track(s), Deep Sounds reserves the right to set, revoke, change, or amend all
        pricing for licenses of Track offered to Licensee Users. Deep Sounds shall also have the
        right, but not the obligation, to create different and varied subscriptions, packages, and
        pricing models for the use of the Services and the purchase of licenses to Tracks by
        Licensee Users, and set prices and discounts for licenses of Tracks accordingly.
      </p>
      <p>
        Deep Sounds shall use Stripe or related Stripe services (&ldquo;Stripe&rdquo;) to distribute
        any and all payments, payment information and payment documentation, including but not
        limited to 1099 tax forms, due to you by Deep Sounds. To receive such payment, you must
        create and maintain a Stripe account under the terms and conditions provided by the supplier
        of the Stripe service. Deep Sounds makes no promises, representations or warranties with
        regard to the performance, accuracy, or availability of the Stripe service or any
        information provided in conjunction with the Stripe service.
      </p>
      <p>You will be paid for the grant of a license of a Track to Licensee Users as follows:</p>

      <ul className="list-disc ml-20 mb-20">
        <li>
          Licenses granted to Licensee Users using a Free Account, a Deep Pass Membership Account,
          or making a purchase outside of the coverage provided by any unlimited subscription plan:
          Net revenues (after deduction and recoupment by Deep Sounds of credit card processing
          fees, ACH fees, Stripe fees and any and all other payment processing fees, collectively
          &ldquo;Payment Processing Costs&rdquo;) shall be paid 50% to you and 50% to Deep Sounds.
          You will be notified by email upon the purchase of an individual Track license and payment
          shall be made to you via your Stripe account within seven (7) business days after the
          purchase is completed and payment has been received by Deep Sounds. Deep Sounds is not
          responsible for and shall have no obligation or liability with regard to any failure or
          delay in the availability or transfer of any funds from your Stripe account to any bank
          account or other account that you may have linked thereto.
        </li>
        <li>
          Licenses granted to Licensee Users using a Unlimited Subscription: As consideration for
          licenses to Tracks purchased by a Licensee User through an unlimited subscription plan,
          Artists shall be eligible to receive a Subscription Royalty on a monthly basis if and only
          if, during that month, Artist (a) has uploaded no less than one Track that Deep Sounds has
          approved or (b) a Licensee User using an Unlimited Subscription has purchased at least one
          license that is covered under their Unlimited Subscription Plan of a Track by Artist. The
          calculation of Subscription Royalties shall be made based upon a proprietary formula
          taking into consideration and weighting the number of Tracks by the Artist on the
          Platform, the length of time that Artists has been on the Platform, and the number and
          type of licenses sold for Artist&rsquo;s Tracks. Subscription Royalties will be paid in
          the second month after an Artist is eligible to receive Subscription Royalties; for
          example, Artists who meet the requirements to receive Subscription Royalties in January
          will be paid the Royalties in March. All Artists shall be subject to the same formula for
          purposes of calculating the Subscription Royalties.&nbsp;
        </li>
      </ul>
      <p>
        You agree that Deep Sounds shall be entitled to (a) 50% of the publisher&rsquo;s share of
        any and all fees paid by the PRO in which Artist is a member for the performance of the
        composition in each Track, but in no case less than 25% of the total amount paid the PRO for
        the performance of the composition in each Track and (b) 50% of Deep Sounds&rsquo; net
        receipts from any licenses issued by Deep Sounds pursuant to the Content ID system as
        currently utilized by YouTube, Facebook, and Instagram, or any successor music detection and
        payment system. As used herein, &ldquo;net receipts&rdquo; shall mean all sums received by
        Deep Sounds from the Content ID system, less any third party rights management administrator
        fees or costs of collection.&nbsp; You further agree to execute all documents to facilitate
        the payment of such fees directly from your PRO or Content ID system to Deep Sounds. Deep
        Sounds&rsquo; rights to receive such payments for performance or other use of a Track shall
        survive the termination of the Agreements and/or the Term relative to the Track and/or the
        removal or deletion of the Track from the Platform or Services. Deep Sounds shall account
        for and pay over to you your portion of fees paid by the PRO and net receipts paid by the
        Content ID system and received by Deep Sounds during each calendar quarter within 180 days
        of the end of each such quarter. In the event the amount shown due to you during a calendar
        quarter is less than $10.00, payment will be deferred to the next accounting period in which
        the aggregate payment shown due to you is $10.00 or more.
      </p>

      <h6 className="pt-10 pb-20">7. ARTIST REPRESENTATIONS AND WARRANTIES</h6>
      <p>
        You hereby represent and warrant to Deep Sounds, on a present and continuing basis, that:
      </p>
      <ul className="list-none ml-20 mb-20">
        <li className="mb-10">
          a. As to each of your Track(s), you are the owner, or co-owner, of all right, title and
          interest, including but not limited to all copyrights, in and to both the musical
          composition and sound recording contained within and comprising the Track(s), that you
          have all rights to administer all licenses in and to the Track, including but not limited
          to and all rights in and to the Track, that the Track does not use the Content ID system
          or any subsequent program providing substantially the same function by any other company
          on any other platform,&nbsp; and that you have all rights necessary to retain, hire and
          appoint Deep Sounds to act as the exclusive representative and agent to grant licenses in
          and to the Track and to grant all licenses to Deep Sounds contained in the Agreements.
          Without limiting the foregoing, you represent and warrant that there is no other person,
          firm or entity, including but not limited to a publisher or co-owner of any right, title
          or interest in or to the Track, including any and all copyrights therein (whether in the
          rights in or to the musical composition or the sound recording contained in or comprising
          the Track), whose approval, affirmance, consent, permission, or agreement is necessary
          appoint Deep Sounds as the exclusive representative to grant licenses in and to the Track
          and to grant all licenses to Deep Sounds contained in the Agreements.
        </li>
        <li className="mb-10">
          b. As to all other User Content, including but not limited to Artist Information, Artist
          Likeness, Track Information, and Album Art, that you are the owner or co-owner of all User
          Content, with the right to provide or post all such material and to grant all rights and
          licenses to Deep Sounds contained in the Agreements.
        </li>
        <li className="mb-10">
          c. That all rights and licenses of the Track(s) and User Content to Deep Sounds, and Deep
          Sounds use of the Tracks and User Content contemplated by the Agreements, including but
          not limited to the grant of licenses in Tracks to Licensee Users, shall not be encumbered
          and shall be free of any and all claims, shall not violate the Agreements, any applicable
          laws, statutes, ordinances, regulations or administrative guidelines, the rules or
          regulations of any guilds, unions or collectives, or the rights or interests of any third
          party, including but not limited to intellectual property rights, rights of publicity or
          privacy, or contractual rights, or constitute a breach of any agreement with any third
          party, or defame, harass, or invade the privacy of any person, firm or entity.
        </li>
      </ul>

      <p>
        You understand, acknowledge and agree that you shall be solely responsible and liable to
        determine, account for, and pay any and all royalties, fees, or other monies due to any
        co-owner on any right, title, or interest in or to any of your Tracks and/or User Content.
        You represent and warrant that Deep Sounds shall not be responsible to pay any royalties,
        fees, or other monies to any third party to exercise any license, approval, or grant of any
        right made by you in the Agreements or otherwise arising from or in connection with Deep
        Sounds&rsquo; exercise, in whole or in part, of any right, license or grant as contemplated
        under the Agreements.
      </p>

      <h6 className="pt-10 pb-20">8. WARRANTY DISCLAIMER</h6>
      <p>
        TO THE FULLEST EXTENT PERMITTED BY LAW, THE SERVICE AND PLATFORM ARE PROVIDED &ldquo;AS
        IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; AND DEEP SOUNDS MAKES NO WARRANTY, CONDITION OR
        REPRESENTATION, EXPRESS OR IMPLIED, OF ANY KIND, AND DISCLAIMS ALL WARRANTIES, CONDITIONS
        AND REPRESENTATIONS AS TO QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
        NON-INFRINGEMENT. DEEP SOUNDS MAKES NO WARRANTY, CONDITION OR REPRESENTATION THAT THE
        PLATFORM OR SERVICE WILL BE AVAILABLE AT A PARTICULAR TIME OR THAT THE PLATFORM OR SERVICES
        WILL HAVE ANY MINIMUM UPTIME OR AVAILABILITY, OR WILL BE FREE OF MALWARE, VIRUSES OR ANY
        OTHER HARMFUL CODE OR COMPONENT. DEEP SOUNDS DOES NOT WARRANT, GUARANTEE, ENDORSE OR
        OTHERWISE ASSUME RESPONSIBILITY FOR ANY OTHER PRODUCT OR SERVICE OFFERED BY A THIRD PARTY
        ON, THROUGH OR IN CONJUNCTION WITH THE PLATFORM OR SERVICES OR THROUGH ANY LINKED WEBSITE OR
        APPLICATION. NO STATEMENT, ADVICE, TROUBLESHOOTING, OR TECHNICAL SUPPORT OFFERED BY DEEP
        SOUNDS, WHETHER ORAL OR IN WRITING, SHALL BE DEEMED TO ALTER. MODIFY OR PRECLUDE THIS
        DISCLAIMER OR CONSTITUTE ANY WARRANTY, REPRESENTATION OR CONDITION OF BEHALF OF DEEP SOUNDS.
      </p>
      <p>
        You may have statutory rights as a consumer, and this disclaimer does not affect any
        statutory rights, to the extent that they apply.
      </p>
      <h6 className="pt-10 pb-20">9. LIMITATION ON LIABILITY</h6>
      <p>
        TO THE FULLEST EXTENT PERMITTED BY LAW, EXCEPT AS OTHERWISE PROVIDED HEREIN, YOUR SOLE AND
        EXCLUSIVE REMEDY FOR ANY DISSATISFACTION WITH THE SERVICES OR PLATFORM, OR ANY THIRD PARTY
        PRODUCTS OR SERVICES OFFERED ON, THROUGH OR IN CONJUNCTION WITH THE PLATFORM OR SERVICES OR
        THROUGH ANY LINKED WEBSITE OR APPLICATION SHALL BE TO DISCONTINUE USE OF THE SERVICES AND
        PLATFORM. NOTWITHSTANDING THE FOREGOING, IN THE EVENT THAT DEEP SOUNDS FAILS TO MAKE PAYMENT
        TO AN ARTIST FOR THE ARTIST&rsquo;S SHARE OF ANY LICENSE FEES OWED TO ARTIST AS A RESULT OF
        THE LICENSE OF THE ARTIST&rsquo;S TRACK, DEEP SOUNDS LIABILITY SHALL BE LIMITED TO THE
        AMOUNT OF ARTIST&rsquo;S SHARE OF FEES OWED TO THE ARTIST FOR SUCH LICENSE.
      </p>
      <p>
        TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL DEEP SOUNDS, ITS OFFICERS,
        DIRECTORS, SHAREHOLDERS, MEMBERS, MANAGERS, EMPLOYEES, AGENTS, SUBSIDIARIES, AFFILIATES,
        SUCCESSORS, ASSIGNS, SUPPLIERS, VENDORS, LICENSORS, BE LIABLE FOR ANY INCIDENTAL,
        CONSEQUENTIAL, EXEMPLARY, SPECIAL OR PUNITIVE DAMAGES, INCLUDING DIRECT OR INDIRECT LOST
        PROFIT, OR ANY LOSS OF USE, DATA, OR BUSINESS, IN ALL CASES REGARDLESS OF HOW CHARACTERIZED
        AND EVEN IF SUCH DEEP SOUNDS HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, WHICH
        ARISE FROM THE PERFORMANCE OF THIS AGREEMENT OR IN CONNECTION WITH THIS AGREEMENT, AND
        REGARDLESS OF THE FORM OF ACTION (WHETHER IN CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR
        OTHERWISE).
      </p>
      <h6 className="pt-10 pb-20">10. INDEMNIFICATION</h6>
      <p>
        To the fullest extent permitted by law, you agree to indemnify, defend and hold harmless
        Deep Sounds, its officers, directors, members, managers, shareholders, employees, agents,
        representatives, subsidiaries, affiliates, parent companies, successors, assigns, suppliers,
        vendors, and licensors,from any and all claims, liabilities, losses, damages, costs, and
        expenses (including but not limited to reasonable attorneys&rsquo; fees) arising from or
        relating to (a) your breach or alleged breach of this Agreement; (b) any act or omission in
        which you engage by or through your use of the Services or Platform; (c) any infringement or
        alleged infringement of the intellectual property rights of any other party; (d) any
        violation or alleged violation of any law, statute, ordinance, or regulation; (e) your use
        of the Platform or Services; and/or (f) your breach or alleged breach of any third-party
        license.
      </p>
      <h6 className="pt-10 pb-20">11. INTEGRATION AND MODIFICATION</h6>
      <p>
        This Agreement constitutes the entire agreement between you and Deep Sounds. No oral
        representations or other agreements have been made by Deep Sounds except as stated in the
        Agreement. This Agreement supersedes all prior and contemporaneous agreements,
        representations and warranties. Deep Sounds reserves the right to modify the terms of this
        Agreement, in its sole and exclusive discretion. In the event that Deep Sounds modifies the
        terms of the Agreement, Deep Sounds will post the revised version of this Agreement and
        shall send notice of the posting of the revised agreement to the email that you provide with
        your Artist Information. All modifications will take effect immediately upon posting of the
        revised Agreement, except material changes to the terms of the Agreement, which shall become
        effective thirty (30) days after the date of the posting of the revised Agreement. Your
        continued use of the Services and/or Platform after posting of the revised Agreement shall
        be deemed your acceptance of such revised Agreement and agreement to be bound by the revised
        terms. In the event that you do not wish to agree to the revised terms of the Agreement, you
        must remove any and all Tracks to which you do not wish the revised Agreement to apply as
        set forth above in section 5, and the revised terms of the Agreement shall not apply to such
        Tracks.
      </p>
      <h6 className="pt-10 pb-20">12. SEVERABILITY/WAIVER</h6>
      <p>
        In the event that any provision or any part of a provision of this Agreement shall be
        finally determined to be superseded, invalid, illegal or otherwise unenforceable pursuant to
        applicable laws by an authority having jurisdiction, such determination shall not impair or
        otherwise affect the validity, legality, or enforceability of the remaining provisions or
        parts of provisions of this Agreement, which shall remain in full force and effect as if the
        unenforceable provision or part were deleted. No term or provision hereof shall be deemed
        waived as a result of any failure by Deep Sounds to enforce this Agreement or any part
        hereof.
      </p>
      <h6 className="pt-10 pb-20">13. CHOICE OF LAW</h6>
      <p>
        The Agreements and any dispute arising from or related to your use of the Services and/or
        Platform shall be governed by and construed in accordance with the laws of the State of
        Illinois without regard for its conflict of law provisions.
      </p>
      <h6 className="pt-10 pb-20">14. ARBITRATION, JURY WAIVER AND CLASS ACTION WAIVER</h6>
      <p>
        The parties hereto agree that any and all disputes, claims or controversies arising from or
        relating to the Agreements, and/or your use of the Services and/or Platform, shall be
        settled exclusively by binding arbitration and in accordance with the Commercial Arbitration
        Rules of the American Arbitration Association under the Federal Arbitration Act. Prior to
        instituting arbitration, you agree to provide Deep Sounds with written notice of your claim
        via email to help@deepsounds.com, which shall be deemed delivered upon actual receipt by
        Deep Sounds, setting forth the dispute and the relief that you are seeking. You further
        agree that you will engage in a good faith effort with Deep Sounds to resolve the dispute.
        In the event that the dispute is not resolved within thirty (30) days after receipt of the
        notice of your claim, either you or Deep Sounds may initiate arbitration. Deep Sounds and
        you each agree to attempt to resolve the dispute. Such arbitration shall be conducted by a
        single arbitrator and conducted solely in Chicago, Illinois. The parties shall bear their
        own attorneys&rsquo; fees and costs, and shall equally bear the costs of instituting
        arbitration. EACH OF THE PARTIES HERETO HEREBY IRREVOCABLY WAIVES ANY AND ALL RIGHT TO TRIAL
        BY JURY IN ANY LEGAL PROCEEDING ARISING OUT OF OR RELATED TO THIS AGREEMENT OR THE
        TRANSACTIONS CONTEMPLATED HEREBY. ALL CLAIMS MUST BE BROUGHT BY A PARTY ONLY IN ITS
        INDIVIDUAL CAPACITY AND NOT AS CLASS ACTION LAWSUIT, CLASS-WIDE ARBITRATION, PRIVATE
        ATTORNEY-GENERAL ACTION, OR AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
        REPRESENTATIVE ACTION, and, unless the parties agree otherwise in writing, that no
        arbitrator or judge may consolidate more than one person&rsquo;s claims or otherwise preside
        over any form of a representative or class proceeding. The arbitrator may award injunctive
        relief only in favor of the individual party seeking relief and only to the extent necessary
        to provide relief warranted by that party&rsquo;s individual claim. In the event that any
        part of this section or its limitations are determined by a court to be unenforceable
        pursuant to applicable law as to a particular claim for relief, then that claim (and only
        that claim) must be severed from the remainder of the arbitration and may be brought in
        court. Notwithstanding anything in the contrary in this section, nothing in this section
        shall be deemed to waive or preclude the ability of Deep Sounds or you to bring an
        individual action in a United States small claims court.
      </p>
    </div>
  );
}
export default ArtistTerms;

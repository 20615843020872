import React from "react";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
export default function ChannelSummary(props) {
  const { youtubeChannelData } = props;

  let { status } = youtubeChannelData;
  let statusText;
  let statusIcon;
  let statusIconFormatted;
  if (status) {
    switch (status) {
      case 'processing':
        statusIcon = 'time';
        statusText = 'Whitelist Processing';
        break;
      case 'success':
        statusIcon = 'success';
        statusText = 'Whitelist Active';
        break;
      default:
        statusIcon = false;
    }
    if (statusIcon) {
      statusIconFormatted = <>
        <span className={`inline text-gray-500 icon-${statusIcon}`}></span>{' '}
      </>;
    }
  }

  return (
    <>
      {youtubeChannelData && (
        <div className="youtube-channel-data">
          <div className="col w-full mb-10">
            <img
              className="float-left mr-15"
              src={youtubeChannelData.thumbnails?.default?.url}
              alt="Channel Thumbnail"
            />
            <h4>{youtubeChannelData.title}</h4>
            <div className="text-sm">
              {status ? (
                <>{statusIconFormatted}{statusText}</>
              ) : (
                <>
                  since { youtubeChannelData.publishedAt }
                </>)
              }
            </div>
            <div className="mt-10 text-sm">
              <span className="font-bold text-white">Subscribers:</span>{" "}
              {youtubeChannelData.subscriberCount}
            </div>
            <div className="text-sm">
              <span className="font-bold text-white">Videos:</span>{" "}
              {youtubeChannelData.videoCount}
            </div>
            {!status && (
              <p className="pt-15">
                Confirm this is your channel. You will not be able to change your
                channel once you have submitted.{" "}
                <a
                  className="text-white underline"
                  href={`https://www.youtube.com/channel/${youtubeChannelData.id}`}
                  target='_blank'
                >
                  View channel
                </a>.
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
}

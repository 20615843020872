import React from 'react';

const SubscriptionSwitchText = ({
  subscriptionAlternateInterval,
  subscriptionAlternatePriceFormatted,
  subscriptionType
}) => {

  console.log('subscriptionAlternateInterval', subscriptionAlternateInterval);

  return (
    <>
      Switch to {subscriptionAlternateInterval}{"ly "}
      {subscriptionAlternateInterval === 'year' ? (
        <>
          {(subscriptionType === 'commercial') && (<>and save 60%</>)}
          {(subscriptionType === 'personal') && (<>and save 40%</>)}
          </>
      ) : (
          <>
            at{" "}
            {subscriptionAlternatePriceFormatted}/
            {subscriptionAlternateInterval}
          </>
      )}
    </>
  )
}

export default SubscriptionSwitchText

import React from "react";
import ArtistFilters from "./artistFilters";

const SideBar = (props) => (
  <div className="app-sidebar">
    {/* // {# Browse Filter Accordions #} */}
    {/* // {% include "components/sort-filters/artist-filters.html" %} */}
    <ArtistFilters
      selectedSearchOptions={props.selectedSearchOptions}
      filters={props.filters}
      disabledFilters={props.disabledFilters}
      toggleFilter={props.toggleFilter}
      isGenresOpen={props.isGenresOpen}
      isMoodsOpen={props.isMoodsOpen}
      isEmotionOpen={props.isEmotionOpen}
      isAttributesOpen={props.isAttributesOpen}
      isInstrumentsOpen={props.isInstrumentsOpen}
      isLyricsOpen={props.isLyricsOpen}
      isBPMOpen={props.isBPMOpen}
      isCitiesOpen={props.isCitiesOpen}
      isVersionsOpen={props.isVersionsOpen}
      addSearchOption={props.addSearchOption}
      excludeSearchOption={props.excludeSearchOption}
      trackBrowse={props.trackBrowse}
      changeStem={props.changeStem}
      stemsRequired={props.stemsRequired}
      lengthFilterValue={props.lengthFilterValue}
      lengthFilterMax={props.lengthFilterMax}
      lengthFilterMin={props.lengthFilterMin}
      onSliderChange={props.onSliderChange}
      enableLengthRange={props.enableLengthRange}
      isLengthRangeEnabled={props.isLengthRangeEnabled}
      sliderFinished={props.sliderFinished}
      showMoreCities={props.showMoreCities}
      toggleShowMoreCities={props.toggleShowMoreCities}
    />
  </div>
);

export default SideBar;

import React, { Component } from "react";
import { NavLink, Switch } from 'react-router-dom';
import 'react-tippy/dist/tippy.css';
import { UserConsumer } from "../../contexts/User";
import ApprovedUsers from "./approvedUsers";
import InvitedUsers from "./invitedUsers";
import InviteUsers from "./inviteUsers";

class Users extends Component {
    state = {
        userList: [],
        loaded:false
    }


    render() {
        // let currentUsers = this.state.userList

        // let currentUsers = this.state.userList.filter(user => user.status === "1")
        //
        return (
            <UserConsumer>
                {({ user, login, logout }) => (
                    <>
                        <div className="container mb-40 lg:mb-80">

                            {/* {# App Header #} */}
                            <div className="view-header">
                                <h1 className="view-title">Users</h1>
                            </div>

                            {/* {# Tabs #} */}
                            {/* {% import "components/tracks/tracks-tabs.html" as nav %}
                                    {{ nav.active('Approved') }} */}
                            <nav className="tabs mb-40">
                                <ul className="tab-items">
                                    <li className={`tab-item ${this.props.match.params.status.toLowerCase() === "approved" ? "active" : ""}`}>

                                        <NavLink to="/dashboard/users/approved" className='tab-item-title' >Approved</NavLink>
                                    </li>
                                    <li className={`tab-item ${this.props.match.params.status.toLowerCase() === "incomplete" ? "active" : ""}`}>

                                        <NavLink to="/dashboard/users/incomplete" className='tab-item-title' >In Progress</NavLink>
                                    </li>
                                    <li className={`tab-item ${this.props.match.params.status.toLowerCase() === "invited" ? "active" : ""}`}>

                                        <NavLink to="/dashboard/users/invited" className='tab-item-title' >Invited</NavLink>
                                    </li>
                                    <li className={`tab-item ${this.props.match.params.status.toLowerCase() === "invite" ? "active" : ""}`}>

                                        <NavLink to="/dashboard/users/invite" className='tab-item-title' >Invite Users</NavLink>
                                    </li>
                                    {/* <li className={`tab-item ${this.props.match.params.status.toLowerCase() === "incomplete" ? "active" : ""}`}>
                                        <NavLink to="/dashboard/tracks/incomplete" className="tab-item-title">Incomplete</NavLink>
                                    </li>
                                    <li className={`tab-item ${this.props.match.params.status.toLowerCase() === "pending" ? "active" : ""}`}>
                                        <NavLink to="/dashboard/tracks/pending" className="tab-item-title">Pending</NavLink>
                                    </li>
                                    <li className={`tab-item ${this.props.match.params.status.toLowerCase() === "rejected" ? "active" : ""}`}>
                                        <NavLink to="/dashboard/tracks/rejected" className="tab-item-title">Rejected</NavLink>
                                    </li>
                                    <li className={`tab-item ${this.props.match.params.status.toLowerCase() === "add-tracks" ? "active" : ""}`}>
                                        <NavLink to="/dashboard/tracks/add-tracks" className="tab-item-title">Add Tracks</NavLink>
                                    </li> */}
                                </ul>
                            </nav>

                            <Switch>
                                {(this.props.match.params.status === "approved") &&
                                    <ApprovedUsers details={this.state} handleFormUnsaved={this.props.handleFormUnsaved}/>
                                }
                                {(this.props.match.params.status === "invited") &&
                                    <InvitedUsers details={this.state} handleFormUnsaved={this.props.handleFormUnsaved}/>
                                }
                                {(this.props.match.params.status === "invite") &&
                                    <InviteUsers details={this.state} handleFormUnsaved={this.props.handleFormUnsaved}/>
                                }
                             </Switch>
                        </div>
                    </>
                )}
            </UserConsumer>
        )
    }
}

export default Users

import React, { useEffect, useState } from "react";
import { UserConsumer } from "../../contexts/User";
import api from "../utils/api";
import CircularIndeterminateInline from '../Loading/circularIndeterminantInline';

const Notifications = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [notifications, setNotifications] = useState();

    useEffect(() => {
        const fields = {fields: 'notifications'}
        api.getMyDetails(fields)
        .then((res) => {
            // console.log('getMyDetails res', res);
            setNotifications(res.data?.notifications);
            setIsLoaded(true);
        })
    }, [])

    const toggleNotifications = event => {
        event.preventDefault();
        setIsLoaded(false);
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value
        const name = target.name;
        // console.log('checked value: ', value);
        api.updateMyDetails({notifications: value})
            .then(res => {
                // console.log('update res', res);
                setNotifications(value => !value);
                setIsLoaded(true);
            })



    };

    const updateNotifications = event => {
        event.preventDefault();

    }


    return (
        <>
            <div className="mb-50">
                <h2 className="text-h3 text-white mb-20">Notifications</h2>
                <p className="text-h4 text-gray-400 mb0">Review and adjust your notifications settings below.</p>
            </div>
            {/* {# Form Container #} */}

            <div className="w-full xl:w-2/3 mb-50 lg:mb-80">

                {/* {# Section #} */}
                <div className="form-section">
                        <h3 className="text-label-lg mb-30">Email Notifications</h3>
                        <div className="input-group input-inline">
                            {isLoaded ? (
                                <label class="form-switch mr-10">
                                    <input
                                        type="checkbox"
                                        name="notifications"
                                        onChange={toggleNotifications}
                                        checked={notifications}
                                    />
                                    <span class="form-switch-slider"></span>
                                </label>
                            )
                            : (
                                <span style={{marginLeft: '5px', marginRight: '20px'}}>
                                    <CircularIndeterminateInline size={15} />
                                </span>
                            )}
                            Marketing Emails are {notifications ? (
                                    "ON"
                                ) : (
                                    "OFF"
                                )}
                        </div>
                </div>
            </div>
        </>
    )
}

export default Notifications

import CircularProgress from '@material-ui/core/CircularProgress';
import React from "react";
import "./loading.css";
export default function CircularIndeterminate(props){
    
    return(
        <CircularProgress
            thickness={props.thickness || 3.6}
            style={{color: "white"}}
            size={props.size || 40}
            variant={"indeterminate"}
        /> 
    )
}


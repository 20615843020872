const buildAlbumArtURL = (imageUrl) => {
  if (!imageUrl) return;

  const baseURL = `https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/`;
  let pathPrefix = "";
  if (process.env.REACT_APP_S3_SUB_BUCKET) {
    pathPrefix = process.env.REACT_APP_S3_SUB_BUCKET + "/";
  }

  const fullURL = baseURL + pathPrefix + imageUrl;
  return fullURL;
}
export default buildAlbumArtURL;

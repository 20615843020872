import React, { useState } from "react";
import ArtistCoverPhotoUpload from "../Dropzone/artistCoverPhotoUpload";
import CircularIndeterminate from '../Loading/circularIndeterminant';
import CircularIndeterminateButton from '../Loading/circularIndeterminantButton';
import { Error, SuccessRedirect } from "../Toast/index";
import api from '../utils/api';

export default function ProfilePhoto(props) {
    const [isCropUtilityOpen, setIsCropUtilityOpen] = useState(false);
    const [coverPhotoError, setCoverPhotoError] = useState('')
    const [formUnsaved, setFormUnsaved] = useState(false);
    const [coverPhotoFile, setCoverPhotoFile] = useState(null);
    const [isLoading, setisLoading] = useState(false);
    const [isLoadingSkip, setisLoadingSkip] = useState(false);

    const updateCoverPhoto = (event)=>{
        event.preventDefault()
        setisLoading(true)
        if(Array.isArray(coverPhotoFile)){
            const data = new FormData()
            data.append('file', coverPhotoFile[0])
            
            api.updateCoverPhoto(data)
                .then(res => {
                    let userData = res.data;
                    
                    userData.signupStatus = 4;
                    api.updateSignupStatus(userData)
                        .then(signupStatusRes => {
                            
                            if (signupStatusRes?.data?.signupStatus === 4) {
                                if (props.nextStep) {
                                    SuccessRedirect({message: "Cover Photo updated", onClose: ()=>window.location.replace(props.nextStep) })
                                }
                                else {
                                    SuccessRedirect({message: "Cover Photo updated", onClose: () => window.location.reload() })
                                }
                            }
                        })
                        .catch(error=>{
                            Error({message: "Error updating your account"})
                        })
                })
                .catch(error=>{
                    Error({message: "Error Updating Cover Photo"})
                })
        }
        else if (props.currentUser?.user.coverPhoto) {
            SuccessRedirect({message: "Cover Photo saved", onClose: () => window.location.replace(props.nextStep) })
        }
        else{
            Error({message: "Error updating Cover Photo"})
            return
        } 
    }

    const setCoverPhotoState = (file) => {
        if(file.error){
            setCoverPhotoError('File must be an image in a 1x1 ratio');
        }
        else{
            setFormUnsaved(true);
            setCoverPhotoFile(file);
            setCoverPhotoError(false);
        }

    }

    const removeCoverPhoto = (event)=>{
        event.preventDefault()
        api.removeCoverPhoto()
        .then(res=>{
            SuccessRedirect({message: "Cover Photo Removed", onClose: ()=>window.location.reload() })
        })
        .catch(error=>{
            Error({message: "Error Removing Cover Photo"})
        })
    }

    const skipToNext = (event) => {
        event.preventDefault()
        setisLoadingSkip(true)
        let userData = props.details;
        
        userData.signupStatus = 4;
        api.updateSignupStatus(userData)
            .then(signupStatusRes => {
                
                if (signupStatusRes?.data?.signupStatus === 4) {
                    if (props.nextStep) {
                        SuccessRedirect({message: "You can update your Cover Photo later on your Account page.", onClose: ()=>window.location.replace(props.nextStep) })
                    }
                    else {
                        SuccessRedirect({message: "You can update your Cover Photo later on your Account page.", onClose: () => window.location.reload() })
                    }
                }
            })
            .catch(error=>{
                Error({message: "Error updating your account"})
            })
    }

    return (
        !props.details.loaded ?
            <CircularIndeterminate forceCenter />
        :
            <>
                <div className="mb-50">
                    <p className="mb-0">
                        Please add a cover photo.  This will display in the background of your artist page.
                    </p>
                </div>
                <ArtistCoverPhotoUpload 
                    removeCoverPhoto={(event) => removeCoverPhoto(event)} 
                    onChange={setCoverPhotoState} 
                    coverPhoto={props.details.coverPhoto} 
                    updateCoverPhoto={(event) => updateCoverPhoto(event)} 
                    coverPhotoError={coverPhotoError}
                    skip={true}
                    isLoading={isLoading}
                    buttonText="Save and Continue"
                />
                <button 
                    className="btn btn-stroke w-full mt-20" 
                    onClick={(event) => skipToNext(event)}
                    disabled={isLoadingSkip}
                >
                    {isLoadingSkip ?
                        <CircularIndeterminateButton forceCenter />
                    :
                        'Skip For Now'
                    }
                </button>
            </>
    )

}
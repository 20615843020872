import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Error, Success } from "../Toast";
// import {NavLink} from "react-router-dom"
class PublicCollectionTolltip extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {

            }
    shareCollection=()=>{
        var textArea = document.createElement("textarea");

        // Place in top-left corner of screen regardless of scroll position.
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em';
        textArea.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = 0;

        // Clean up any borders.
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';

        // Avoid flash of white box if rendered for any reason.
        textArea.style.background = 'transparent';


        textArea.value = window.location.protocol + "//" +window.location.hostname + (window.location.port ? ":" + window.location.port : "") +"/" + "collections/" + this.props.collectionURL;

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          var successful = document.execCommand('copy');
          if(successful){
              Success({message: "Collection URL Copied to Clipboard"})
          }
          else{
              Error({message: "Couldn't Copy Collection URL "})
          }
          var msg = successful ? 'successful' : 'unsuccessful';
          
        } catch (err) {
          
        }

        document.body.removeChild(textArea);
    }
    render() {
        return (

                <div className="dropdown-menu">
                    <div className="dropdown-group">
                        {this.props.includeLink &&
                            <NavLink to={`/collections/${this.props.collectionURL}`} onClick={this.props.toggleCollectionTippy}>View Collection</NavLink>
                        }
                            <a href="#" onClick={()=>{this.shareCollection();this.props.toggleCollectionTippy()}}>Share Collection</a>
                    </div>                
                 
                </div>

        )
    }

}
export default PublicCollectionTolltip
import React, { Component } from "react";
import tippy from '../../tooltips.js';
import { UserConsumer } from "../../contexts/User";
import CircularIndeterminant from "../Loading/circularIndeterminant";
import api from "../utils/api";
import CardsAndAccounts from "./cardsAndAccounts";

class PaymentMethods extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {
                email: "",
                oldPassword: "",
                newPassword: "",
                newPasswordConfirm:"",
                loginMessage: "",
                cards: [],
                defaultCard: false
            }
    //updating our input fields as the user enters keys
    handleInputChange = event => {
        const { name, value } = event.target;
        if(!this.state.disableInput){
            this.setState({
                [name]: value
            }, ()=>{
                if (this.state.newPassword && this.state.newPasswordConfirm && this.state.newPasswordConfirm !== this.state.newPassword){
                    this.setState({loginMessage: "Passwords must match!"})
                }
                else{
                    this.setState({loginMessage: ""})
                }
            });
        }

    };
    componentDidMount = ()=>{
        api.getBankAccount()
        .then(
            res=>{

                this.setState({
                    bankAccount: res.data,
                    bankAccountFetched: true
                })
            },
            (error) => {
                this.setState({
                    bankAccountFetched: true
                })
            }
        )

    }
    render() {
        return (
            <UserConsumer>
                {({ user, login, logout})=>(
                    this.state.bankAccountFetched ?
                    <>
                        <div className="mb-50">
                            <h2 className="text-h3 text-white mb-20">Update Deposit Account</h2>
                            <p className="text-h4 text-gray-400 mb0">Please use the form below to edit your payout deposit account.</p>
                        </div>

                        {/* {# Section #} */}
                        {(user?.artist || user?.admin) &&
                            <div className="form-section pb-20">
                                <h3 className="text-label-lg mb-30">Bank Account</h3>

                                <div className="grid-row">
                                    <div className="col w-full md:w-1/2 mb-20 md:mb-40">
                                        {this.state.bankAccountFetched && this.state.bankAccount ?
                                            <CardsAndAccounts tippy={tippy} paymentId={1} accountName={this.state.bankAccount.bank_name}
                                            bank={true} acctType="Checking" logo={'https://www.53.com/content/dam/fifth-third/brand/fifth-third-logo-shield.png'}
                                            lastFour={this.state.bankAccount.last4} routingNumber={this.state.bankAccount.routing_number}
                                            loginLink={this.state.bankAccount.loginLink}></CardsAndAccounts>
                                        : this.state.bankAccountFetched &&
                                            <a href={`/stripeOnboard/start`} className="add-block">
                                                <div className="add-block-text">Add a Bank Acccount</div>
                                                <span className="add-block-icon">
                                                    <span className="icon-plus text-icon-md"></span>
                                                </span>
                                            </a>
                                        }
                                    </div>
                                </div>

                            </div>
                        }



                                {/* {# End Form Container #} */}
                         </>:
                         <CircularIndeterminant forceCenter/>
                         )}
            </UserConsumer>
        )
    }

}
export default PaymentMethods

import React from "react";
import convertSnakeToTitleCase from "../../utils/convertSnakeToTitleCase";

const DataTable = ({ data, headerTransform = null, lastColumnRight = false }) => {
  const headers = data?.length > 0 ? Object.keys(data[0]) : [];

  return (
    <>
      {data?.length === 0 ? (
        <p className="my-20">No data available.</p>
      ) : (
        <div className="table-wrap ds-scrollbar">
          <table className="stats-table w-full mt-30">
            <thead className="text-left">
              <tr>
                {headers?.map((header, index) => {
                  let headerText = header || "";
                  if (
                    headerTransform === "convertSnakeToTitleCase" &&
                    typeof headerText === "string"
                  ) {
                    headerText = convertSnakeToTitleCase(headerText);
                  }
                  const className =
                    index === headers?.length - 1 && lastColumnRight
                      ? "text-label-sm text-right"
                      : "text-label-sm";
                  return (
                    <th key={index} className={className}>
                      {headerText}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data?.map((row, index) => (
                <tr key={index}>
                  {headers?.map((header, columnIndex) => (
                    <td
                      key={columnIndex}
                      className={
                        columnIndex === headers?.length - 1 && lastColumnRight ? "text-right" : null
                      }
                    >
                      {row[header]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default DataTable;

import React from "react";
import DashboardLayout from "../Layouts/DashboardLayout";
import ContentIDSubnav from "./ContentIDSubnav";

const ContentID = (props) => {
  return (
    <DashboardLayout title="Content ID">
      <ContentIDSubnav />
    </DashboardLayout>
  );
};

export default ContentID;

import React from "react";
import AsyncCreatableSelect from 'react-select/async-creatable';
import Creatable from 'react-select/creatable';
import resolveConfig from "tailwindcss/resolveConfig";
import customStylesMulti from "../FormElements/customStylesMulti";
import tailwindConfig from "../../tailwind.config";
import { Error } from "../Toast/index";
import api from '../utils/api';

const tw = resolveConfig(tailwindConfig);

// Styles for Multi-Select

// search keywords for AsyncCreatable autocomplete select
function getKeywords(keywordInput, options) {
    
    if(keywordInput?.trim().length > 0){
        // 
            return api.getKeywords(keywordInput)
                .then(res=>{
                    let keywords = res.data.keywords
                    
                    let keywordsOptions = keywords.map(keyword => {
                        let keywordOption = {
                            id: keyword.id,
                            label: keyword.name,
                            value: keyword.name
                        }
                        return keywordOption
                    })
                    
                    return keywordsOptions
                })
    } 
    else {
        return [{label: "", value: ""}]
    }
}

function KeywordsInput(props) {
    return (
        <div className="input-group">
            <label htmlFor="keywords" className="input-label">
                What Keywords are associated with this track? 
                <span className="input-label-info">Add as many as apply</span>
            </label>
            {props.trackStatus === "pending" || props.isDisabled ? 
                <Creatable 
                    isDisabled
                    name="keywords"
                    styles={customStylesMulti}
                    placeholder="Type to add Keywords..."
                    value={props.keywords}
                    onChange={props.handleKeywordChange}
                    // options={this.state.existingKeywords}
                    isMulti
                    isSearchable
                /> 
            :
                <AsyncCreatableSelect 
                    cacheOptions
                    noOptionsMessage={() => "Options will be populated as you type"}
                    allowCreateWhileLoading
                    loadOptions={getKeywords}
                    name="keywords"
                    styles={customStylesMulti}
                    placeholder="Type to add Keywords..."
                    value={props.keywords || []}
                    onChange={props.handleKeywordChange}
                    
                    isValidNewOption={(inputValue, selectValue, selectOptions)=>{
                        
                        if(inputValue.length > 0 && props.allOptions){
                            let indexInAllOption = props.allOptions.findIndex(
                                option => option.label.toLowerCase() === inputValue.toLowerCase()
                            )
                            if(indexInAllOption === -1){
                                return true
                            }
                            else{
                                Error({message: `This is already a system attribute. Please add it in the ${props.allOptions[indexInAllOption].type} field.`, toastId: `keywords-input-${inputValue}`})
                            }
                        }
                    }}
                    // options={this.state.existingKeywords}
                    isMulti
                    isSearchable
                />
            }
        </div> 
    )
}
export default KeywordsInput;
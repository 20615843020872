import React, { Component } from "react";
import { Switch } from 'react-router-dom';
import { UserConsumer } from "../../contexts/User";
import api from "../utils/api";
import AccountNav from "./accountNav";
import DepositAccount from "./depositAccount";
import Details from "./details";
import Notifications from "./notifications";
import PaymentMethods from "./paymentMethods";
import Settings from "./settings";
import Subscriptions from "./subscriptions";

class Account extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {
        loaded:false
    }
    componentDidMount = ()=>{
        api.getMyDetails()
          .then(res => {
            console.log('getMyDetails res', res);
            api.getTags()
            .then(genreRes=>{
                //filter genre tags
                let genres = genreRes.data.filter(tag => tag.type === 1)

                let address = JSON.parse(res.data.billingAddress) || {line1: "", line2: ""}


                this.setState({
                    loaded: true,
                    email: res.data.email,
                    artistName: res.data.artistName,
                    firstName: res.data.firstName,
                    lastName: res.data.lastName,
                    phone: res.data.phone,
                    secondaryPhone: res.data.secondaryPhone,
                    company: res.data.company,
                    position: res.data.position,
                    line1: address.line1,
                    line2: address.line2,
                    city: address.city,
                    state: address.state,
                    postal_code: address.postal_code,
                    country: address.country,
                    bestDescribed: res.data.bestDescribed,
                    primaryGenre: genres.filter(genre=>genre.id ===res.data.primaryGenre)[0],
                    secondaryGenre: genres.filter(genre=>genre.id ===res.data.secondaryGenre)[0],
                    coverPhoto: res.data.coverPhoto,
                    bio: res.data.bio,
                    genreOptions: genres,
                    selfPro: res.data.selfPro,
                    publisherPro: res.data.publisherPro || {id: null, publisherName: "", org: null, number: null},
                    subscription: res.data.subscription || {amount: null, status: null, name: null, interval: null, benefit: null, currentPeriodEnd: null}
                })
            })

        })

    }

    handleFormSubmit = event => {
        event.preventDefault();

        if (this.state.newPassword === this.state.newPasswordConfirm) {
            let passwordResetBody = {
                oldPassword: this.state.oldPassword,
                newPassword: this.state.newPassword,
                new_password_confirm: this.state.newPasswordConfirm,
                email: this.state.email,
            }

                api.updatePassword(passwordResetBody)
                    .then(res=>{

                        if (res.data?.success){
                            this.setState({
                                loginMessage: "Password successfully updated!",
                                newPassword: "",
                                newPasswordConfirm: "",
                                oldPassword: "",
                                disableInput: true
                            })
                        }
                        else{
                            this.setState({
                                loginMessage: res.data
                            })
                        }
                    })
                    .catch(err=>{

                    })


        }
    }
    render() {
        return (
                <UserConsumer>
                    {({ user, login, logout})=>(
                         <div className="container">

                             {/* {# App Header #} */}
                             <div className="view-header">
                                 <h1 className="view-title">Account</h1>
                             </div>

                             {/* {# Tabs #} */}
                             <AccountNav path={this.props.location.pathname} role={user?.role}/>
                            {/* react router to switch between settings sections */}
                             {/* {# Tab Section Header #} */}
                                 <Switch>
                                     {(this.props.match.params.accountSub === "settings") && <Settings details={this.state} loaded={this.state.loaded} handleFormUnsaved={this.props.handleFormUnsaved}/>}
                                     {(this.props.match.params.accountSub === "details") && <Details details={this.state} loaded={this.state.loaded} handleFormUnsaved={this.props.handleFormUnsaved}/>}
                                     {(this.props.match.params.accountSub === "payment-methods") && <PaymentMethods details={this.state} handleFormUnsaved={this.props.handleFormUnsaved}/>}
                                     {(this.props.match.params.accountSub === "deposit-account"  && (user?.artist || user?.admin)) && <DepositAccount details={this.state} handleFormUnsaved={this.props.handleFormUnsaved}/>}
                                     {(this.props.match.params.accountSub === "subscriptions") && <Subscriptions details={this.state} handleFormUnsaved={this.props.handleFormUnsaved} history={this.props.history}/>}
                                     {(this.props.match.params.accountSub === "notifications") && <Notifications details={this.state} loaded={this.state.loaded} handleFormUnsaved={this.props.handleFormUnsaved}/>}
                                     {this.state.loaded &&
                                        <div className="container">Account Section not found.</div>
                                     }
                                 </Switch>

                             {/* {# End Form Container #} */}

                         </div>

                        //  {/* {# Footer #} */}
                        //  {/* {% include "includes/footer.html" %} */}
                             )}
                </UserConsumer>
        )
    }

}
export default Account

import React from "react"

export default function ModalWrapper(props) {
    return(
        <div id="add_scene_content" class="modal-content">
            <button 
                onClick={props.handleClose} 
                aria-label="Close Modal" 
                data-micromodal-close="add_scene_modal" 
                class="modal-close modal-close-absolute circle-icon icon-close" 
                type="button"
            />
            {props.children}
        </div>
    )
}





import React from "react";
import { Helmet } from "react-helmet";
import IntercomContactButton from "../Intercom/IntercomContactButton"

export default function About() {
  return (
    <>
      <Helmet title="About Us - Deep Sounds | Music for Creators" />
      <div className="container max-w-4xl text-center leading-tightest pt-100 pb-40 xl:pt-120 xl:pb-40">
        <h1 className="text-h1-sm md:text-h1 mb-10 md:mb-20">About Us</h1>
      </div>

      <section className="container max-w-lg md:max-w-screen-3xl mb-0">
        <div class="article max-w-2xl mx-auto pb-100">
          <p>
            Deep Sounds is a platform for content creators to easily license
            music from independent artists. We offer the ability to simply
            purchase a music license to use the artists music as a background
            track to your content.
          </p>

          <h2 className="text-h3-sm md:text-h3 mb-10 md:mb-20">
            Music Licenses
          </h2>

          <p>
            You can either purchase a single music license or sign up for an
            unlimited subscription to generate as many music licenses as you
            need via our Personal, Commercial, or Custom plans.
          </p>
          <div className="">
          <a href="/pricing" class="btn btn-stroke btn-min-w">
            View Pricing
            </a>
            </div>

          <h2 className="text-h3-sm md:text-h3 mb-10 md:mb-20">Custom Music</h2>
          <p>
            Need a custom track for your project? Deep Sounds also offers
            recording, production services, and post-production services to help
            you create custom tracks for your audio, video or film program.
            Click on the button below to let us know how we can help you with
            your custom music needs, discuss your plans with us, and receive a
            quote.
          </p>

          <IntercomContactButton
            type="button"
          >
            Get in Touch
          </IntercomContactButton>


          <h2 className="text-h3-sm md:text-h3 mb-10 md:mb-20">
            Our Mission
          </h2>

          <p>
            Founded in 2019 by David Huzieran and Shane Simmons, the platform's
            main mission is to create a financially sustainable environment for
            artists and to simplify the often-confusing aspect of music
            licensing for content creators.
          </p>
          <p>
            In the years prior to its formation, David, who ran a video
            production company, saw a disturbing trend in the creator economy.
            "Cost consulting" companies were bragging about how they were able
            to cut production and post-production costs industry wide. This
            mostly hurt small independently run companies and creators for the
            benefit of corporate giants.
          </p>
          <p>
            While some other music libraries force artists to hand over
            ownership of their music for a small up-front fee, Deep Sounds
            allows the artist to retain 100% ownership of their music while
            being paid a royalty for every license that is purchased or
            generated.
          </p>
          <p>
            Deep Sounds is invested in continuing the mission to enhance the
            creator economy forward, because they believe all artists and
            creators have a right to financial prosperity.
          </p>
        </div>
      </section>
    </>
  );
}

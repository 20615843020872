import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { useForm } from "react-hook-form";
import { Tooltip } from "react-tippy";
import zxcvbn from "zxcvbn";
import { LabelRequired } from "../FormElements/labels";
import CircularIndeterminate from "../Loading/circularIndeterminant";
import CircularIndeterminateButton from "../Loading/circularIndeterminantButton";
import { Error } from "../Toast/index";
import api from "../utils/api";
import ArtistTerms from "../Links/ArtistTerms";
import TermsAndConditions from "../Links/termsAndConditions";
import PrivacyPolicy from "../Links/privacyPolicy";

// Minimum zxcvbn password strength score
const minPasswordScore = 3;
const minPasswordLength = 8;
const scoreWords = ["ultra weak", "very weak", "weak", "strong", "very strong"];

const schema = Joi.object({
  firstName: Joi.string().required().messages({
    "any.required": "Please enter a First Name",
    "string.empty": "Please enter a First Name",
  }),
  lastName: Joi.string().required().messages({
    "any.required": "Please enter a Last Name",
    "string.empty": "Please enter a Last Name",
  }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "any.required": "Please enter an Email Address",
      "string.empty": "Please enter an Email Address",
      "string.email": "Please enter a valid Email Address",
    }),
  password: Joi.string()
    .min(minPasswordLength)
    .required()
    .custom((value, helper) => {
      const zxcvbnResult = zxcvbn(value);

      if (zxcvbnResult?.score < minPasswordScore) {
        return helper.message("Please enter a strong Password");
      } else {
        return true;
      }
    })
    .messages({
      "string.empty": "Please enter a Password",
      "string.min": `Password must be at least ${minPasswordLength} characters long`,
    }),
});

export default function SignUpForm(props) {
  const { search } = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(search);
  let returnPath = query.get("return");
  // console.log('history', history);
  if (returnPath === null) {
    returnPath = history?.location?.pathname + history?.location?.search;
  }
  const confirmRedirect = returnPath || "music";
  // console.log('confirmRedirect', confirmRedirect);
  // Form validation & error messages

  const { control, register, watch, handleSubmit, formState, setError, errors } = useForm({
    mode: "onSubmit",
    reValidateMode: "onTouched",
    defaultValues: {
      email: props.email,
    },
    resolver: joiResolver(schema),
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const [buttonText, setButtonText] = useState(props.buttonText || "Start Listening");
  const [isLoading, setisLoading] = useState(false);
  const [isDisabled, setisDisabled] = useState(false);
  const [formError, setFormError] = useState(false);

  const [passwordFocused, setPasswordFocused] = useState(false);
  const [passwordTippyOpen, setPasswordTippyOpen] = useState(false);
  const [passwordValid, setPasswordValid] = useState(false);
  const [passwordLength, setPasswordLength] = useState(0);
  const [passwordWarnings, setPasswordWarnings] = useState(false);
  const [passwordSuggestions, setPasswordSuggestions] = useState([]);
  const [passwordScore, setPasswordScore] = useState(0);
  const [passwordStrengthDesc, setPasswordStrengthDesc] = useState(false);
  const [passwordValidClass, setPasswordValidClass] = useState("invalid");

  function focusPassword(shouldFocus) {
    setPasswordFocused(shouldFocus);
    setPasswordTippyOpen(shouldFocus);
  }

  const watchPassword = watch("password", false);
  //

  React.useEffect(() => {
    //
    // Show all errors as Toastify
    Object.entries(errors).map((error) =>
      Error({
        message: error[1].message,
        toastId: error[0],
      })
    );
  }, [errors]);

  React.useEffect(() => {
    // Update password strength indicators
    if (watchPassword) {
      //
      const zxcvbnResult = zxcvbn(watchPassword);
      //

      if (zxcvbnResult.score >= minPasswordScore) {
        setPasswordValid(true);
        setPasswordValidClass("is-password-valid");
      } else if (zxcvbnResult?.password?.length > 0) {
        setPasswordValid(false);
        setPasswordValidClass("is-password-invalid");
        setPasswordStrengthDesc("Too Short");
      }
      setPasswordWarnings(zxcvbnResult.feedback?.warning || "");
      setPasswordSuggestions(zxcvbnResult.feedback?.suggestions);
      setPasswordLength(zxcvbnResult?.password?.length || 0);

      if (zxcvbnResult.score) {
        setPasswordStrengthDesc(scoreWords[zxcvbnResult.score]);
        setPasswordScore(zxcvbnResult.score);
      } else {
        setPasswordScore(0);
      }
    }
  }, [watchPassword]); // use entire formState object as optional array arg in useEffect, not individual properties of it

  const onSubmit = (data, e) => {
    setisLoading(true);
    setisDisabled(true);

    // props.handleFormSubmit(data);
    const loginFunc = props.login;

    let newUserBody = {
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      password: watchPassword,
      password_confirm: watchPassword,
      signUpLocation: props.signUpLocation,
      confirmRedirect,
    };

    if (props.token) {
      newUserBody.token = props.token;
    }

    api
      .signUp(newUserBody)
      .then((res) => {
        console.log("signup res", res);
        let redirect = props.redirect || false;
        if (!redirect) {
          if (props.role === "artist") {
            redirect = "/signup/details";
          } else {
            redirect = "/signup/confirm";
          }
        }
        if (res.data?.success) {
          loginFunc(
            {
              email: data.email,
              password: watchPassword,
            },
            redirect,
            props.loginCallback
          );
        } else {
          Error({
            message: "There was an error signing up",
          });
        }
      })
      .catch((error) => {
        if (error.response?.status === 409) {
          Error({
            message: "An account with that email already exists!",
            toastId: "duplicate-email",
          });
          setisLoading(false);
          setisDisabled(false);
        } else {
          Error({
            message: "An error occured!",
            toastId: "error",
          });
          setisLoading(false);
          setisDisabled(false);
        }
      });
  };

  return !props.loaded ? (
    <CircularIndeterminate forceCenter />
  ) : // Don't allow a signed in user to access this form, unless they just submitted it
  props.user?.email && !isLoading ? (
    <>
      <div className="py-50">
        <CircularIndeterminate forceCenter />
      </div>
      <div className="hidden">
        {setTimeout(() => {
          window.location.replace("/music");
        }, 100)}
      </div>
    </>
  ) : (
    <>
      {props.headerText}
      <form onSubmit={handleSubmit(onSubmit)} class="ds-form">
        <div className="grid-row-sm">
          <div className="col w-1/2">
            <div className="input-group-sm input-inline">
              <input
                name="firstName"
                ref={register}
                className="form-input"
                aria-describedby="firstName"
                type="text"
                placeholder="First Name *"
                autoComplete="given-name"
                data-lpignore="true"
                required
              />
              <LabelRequired for="firstName">First Name</LabelRequired>
            </div>
          </div>
          <div className="col w-1/2">
            <div className="input-group-sm input-inline">
              <input
                name="lastName"
                ref={register}
                className="form-input"
                aria-describedby="lastName"
                type="text"
                placeholder="Last Name *"
                autoComplete="family-name"
                data-lpignore="true"
                required
              />
              <LabelRequired for="lastName">Last Name</LabelRequired>
            </div>
          </div>
        </div>

        <div className="input-group-sm input-inline">
          <input
            className="form-input"
            name="email"
            ref={register}
            readOnly={props.token}
            type="text"
            name="email"
            placeholder="Email Address *"
            autoComplete="email"
            data-lpignore="true"
            required
          />
          <LabelRequired for="email">Email Address</LabelRequired>
        </div>

        <div className="input-group-sm input-inline">
          <Tooltip
            arrow={false}
            open={passwordTippyOpen}
            position={"top"}
            html={
              !passwordValid ? (
                passwordLength >= minPasswordLength ? (
                  <div className="text-left">
                    {passwordWarnings && (
                      <>
                        <span className="icon-reject text-primary"></span>
                        {` ${passwordWarnings}.`}
                      </>
                    )}
                    {passwordSuggestions?.map((suggestion, index) => (
                      <div key={index}>{suggestion}</div>
                    ))}
                  </div>
                ) : (
                  <div>Password must be at least {minPasswordLength} characters.</div>
                )
              ) : (
                <>
                  <div>Password is good to go!</div>
                </>
              )
            }
            allowHTML={true}
          >
            {/* {passwordWeak &&
                                    <div className="error-message">Password not strong enough</div>
                                } */}
            <div className={`dspw is-strength-${passwordScore}`}>
              <input
                className={`form-input dspw-input ${passwordValidClass}`}
                name="password"
                ref={register}
                type="password"
                placeholder="Password *"
                autoComplete="new-password"
                aria-label="New password"
                data-lpignore="true"
                onBlur={() => focusPassword(false)}
                onFocus={() => focusPassword(true)}
                required
              />
              <LabelRequired for="password">Password</LabelRequired>
              {passwordLength > 0 && (
                <>
                  <div className={`dspw-strength-bar`} />
                  <span className={`dspw-strength-desc`}>{passwordStrengthDesc}</span>
                </>
              )}
            </div>
          </Tooltip>
        </div>

        <label class="checkbox-option mb-20">
          <span className="text-sm text-gray-400">
            By clicking on {buttonText}, you agree to the Deep Sounds <span> </span>
            {props.role === "artist" && (
              <>
                <ArtistTerms underline>Artist Terms</ArtistTerms>,{" "}
              </>
            )}
            <TermsAndConditions underline>Terms of Use</TermsAndConditions>
            {props.role === "artist" ? ", " : " "}
            and <PrivacyPolicy underline> Privacy Policy</PrivacyPolicy>.
          </span>
        </label>

        <div className="input-group-sm">
          <button
            className="btn btn-primary form-btn w-full"
            name="login"
            type="submit"
            value="signup"
            disabled={isDisabled}
          >
            {isLoading ? <CircularIndeterminateButton /> : buttonText}
          </button>
        </div>
      </form>
    </>
  );
}

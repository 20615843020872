import React from 'react';
import { Helmet } from "react-helmet";

function Privacy() {
    return (
        <div className="container pt-80 md:pt-100 z-10">
            <Helmet title="Privacy Policy - Deep Sounds | Music for Creators" />

            <div className="view-header">
                <h1 className="view-title" style={{margin: '0 auto'}}>Privacy Policy</h1>
            </div>

            <h6 className="mb-40">Last updated on 11/23/2021</h6>

            <p>
                Through <strong>Deep Sounds, LLC’s</strong> website, artists can upload content and users can purchase licenses for music using filters to search for specific types of music.  We refer to the music available on our website as a “Track” or “Tracks.”  This privacy policy (“Policy”) applies to our www.deepsounds.com website, the visitors to and users of that site, and our account holders and subscribers.   Use of information that we collect from users is described in this Policy.

            </p>

            <h5 className="pt-10 pb-20">Information We Collect</h5>
            <p>
                We collect information from you when you provide it to us and/or when you visit, use or navigate our website.  Visitors are free to navigate our website without providing us with any personal information; see <strong>Log Files and Cookies That Collect Information Autonomously</strong> for information that we collect without you affirmatively providing it to us.  If you want to create an account or upload Tracks, purchase a subscription or a license, or use other features of our website, we will ask you for some or all of the personal information described below.
            </p>
            <p>
                For artists who apply to be included in the Deep Sounds roster on our platform, we will ask for your full name, name you are known under as an artist, email address, mailing address, phone number, biography, genre of your work, P.R.O. society and account number, and links to examples of your work.   We will add you to our mailing list and will  also store this information to keep track of submission, regardless of whether you are asked to join our artists’ roster.
            </p>
            <p>
                For artists accepted for inclusion in our roster, we will publicly display your artist name, biography, music genre and your P.R.O. information.  Your artist name and P.R.O  information will also appear on the license we generate and send to licensees as well as being listed publicly on our website.
            </p>
            <p>
                We use a third party to bill for subscriptions and licenses and distribute royalties to artists and other rights holders.  They will collect the information from you that they need to provide these services and perform related functions (e.g., reporting payments to taxing authorities).  As a result, they will ask you for information which may include bank or debit card number, bank account information, including bank name, account number and/or routing number, and bank account type, SSN, FEIN and business name and information needed for tax and other reporting (“Payment Related Information”).  Stripe is our current payment processor and we use them for billing licensees and subscribers and making distributions to artists.  The Payment Related Information you provide is stored on servers owned or controlled by them and not Deep Sounds.
            </p>
            <p>
                If you purchase a license or a subscription, in addition to the information described above, we will collect certain information as to the type of license, the licensee(s) (for example name/company name and address) and how the Track will be used, as well as any other information necessary to determine the appropriate type of license and its cost.  We will also use that information to create a legally-binding license agreement with terms you will have to agree to as part of the purchasing process.
            </p>
            <p>
                Subscribers may also be asked for additional information, which may include your name, the social media channels where you will be hosting the Track(s) and your user name for the channel.  This allows our rights management service provider to preclear potential copyright infringements on platforms such as YouTube, Facebook, Instagram, or Tik-Tok.
            </p>
            <p>
                Licenses and subscriptions (i.e., purchases made through our website) require a credit or debit card.  Our payment processor will collect the credit/debit card information, such as account holder’s name, account number, expiration date, CVV, billing address/zip code associated with the card and email address.  Our payment processor collects payments and bills for recurring subscriptions, and will also make payments to artists.  While  we do not store Payment Related Information, we do track subscription renewal and other payment dates and advise our payment processor when to process payments and recurring charges for subscriptions.
            </p>

            <h5 className="pt-10 pb-20">Log Files and Cookies That Collect Information Autonomously</h5>

            <p>“Cookies” refer to software code that is placed on your phone, computer or other device in the course of your visiting, using or navigating our website (and websites generally), and when you upload or download information.  We, and/or third parties that provide us with website analytics and other services, use cookies and other technologies to track your use and navigation of our website and in some cases the particular pages and products or services that you view.  </p>

            <p>The information collected automatically from your phone, computer or other device may include information about the browser, device and the Internet Service Provider (ISP) you use, the Internet Protocol (IP) addresses involved in your connection to the website, the URL you were at before coming to our website, details about your visit (e.g., what you view, how long you view it or what you click on), your location, and date and time.  We and our third party service providers may access and collect this information when it is available even though you have not affirmatively provided it to us.</p>

            <p>We and our third party service providers use this information to administer the website, to analyze how visitors navigate the website,  to provide users with more relevant content, to improve the usability, functionality and content of our website, and also to provide technical and customer service.  Some of this information is anonymized and is not personally identifiable to you by us.  We and our third party service providers may correlate some information to our users, account holders and/or subscribers and use it in the ways we describe in this Policy.  </p>

            <p>The information is stored on servers that we or our third party service providers maintain. </p>

            <h5 className="pt-10 pb-20">Tracking Options and Do Not Track Disclosures</h5>

            <p>You can block cookies by setting Internet browser settings to stop accepting new cookies, to receive notice when a new cookie is received, to disable existing cookies, and to omit images (which will disable pixel tags).   More detailed information about cookie management with specific web browsers can be found at the browsers’ respective websites. Blocking or disabling cookies may affect the functionality of our website.  The website may not respond to Do Not Track requests or headers from some or all browsers. </p>

            <h5 className="pt-10 pb-20">How We Use The Information We Collect</h5>

            <p>We use the information that you provide to us and/or our third party service providers (e.g. our payment processor or our customer service provider) for the purpose for which it was provided.  When you create an account, your email address will be used as your username to login to the platform.   Additionally, we will use your email address to send you important automated emails, receipts, reminders or account alerts, for administrative purposes or to authenticate your account.  We also use your email to send you marketing and promotional emails about Deep Sounds, our platform, our artists and products and services that may be of interest to you, and to tailor communications for you.</p>

            <p>For billing, record keeping, legal, compliance and auditing purposes, we may retain and use your information after we have responded to your request, your purchase(s) has been completed and/or you have closed your account or stopped your subscription.  </p>

            <p>Some of the information we may ask you for is optional and/or not required to use all of the features of our website or to license Tracks.  Providing such information may help us understand what you are searching for, offer better suggestions for potential licenses and otherwise enhance your use of the website.  The information we collect may be used internally to help us gather more data and segment our customer base and learn more about our users, artists and subscribers.</p>

            <p>Our website has a “Chat” link where a visitor can send a message and chat with a Deep Sounds representative.  Visitors need to provide a name and email address to use this feature.  If you provide your email address via the Chat function, we will use the information to reply or otherwise respond to your request and provide you with support.   We will also add you to our mailing list that we use for marketing and promotional purposes.</p>

            See <strong>Opting</strong> Out below if you want to change the communications we may send you.

            <h5 className="pt-10 pb-20">Third Party Access To Your Information</h5>

            <p>We may share your information with third parties and third parties, generally our service providers, may share information they have collected about you with us.</p>

            <p>We may share information including email addresses, names, transaction history, and customer support records with Intercom, a company that provides customer support and marketing services.   Intercom may also collect personal information from you, including your email, information you provide when seeking support, the timing and content of your prior communications with us and/or them, and information collected autonomously (e.g. browser type, website navigation, location, etc.).  Information collected by Intercom is not stored by Deep Sounds, but we have access to it and can query, view and use the information.  For information on Intercom’s privacy and data policies, please go to <a className="underline" href="https://www.intercom.com/legal/privacy" target="_blank">https://www.intercom.com/legal/privacy</a>. </p>

            <p>As described above, Stripe, our payment processor, collects information to process payments, distribute payments to artists based on license transactions and to comply with reporting obligations to tax and other governmental authorities and regulatory bodies.     </p>

            <p>In some cases, your information may be aggregated with other information in a way that does not include any information that would be individually identifiable to you.  We and/or the third parties we contract with may use this information to administer the website, to analyze how visitors navigate the website,  to provide visitors with more relevant content, to improve the usability, functionality and content of our website, to provide customer service, or to otherwise provide technical or customer support for the website.</p>

            <p>We use third parties to host and administer our website and in the course of the work they do for us, or to prevent fraud or misuse of our website, these third parties may access your information.</p>

            <p>If a third party were to purchase us or merge our company with theirs, or in the course of negotiating a purchase or merger of our business, we may need to disclose your personal information to the purchaser or prospective purchaser and to transfer your information to the purchaser if such a purchase or merger is concluded.  You consent to such disclosure and transfer.</p>

            <p>If we are required by law, legal process or in response to requests from law enforcement, or if in our sole determination it is necessary for the protection of our interests or the interests of an artist(s), subscriber(s), or licensee(s), we reserve the right to use and disclose your personal information. </p>

            <p>Except as described in this Policy, we do not sell or otherwise share your personal information with third parties.</p>

            <h5 className="pt-10 pb-20">Links To Other Websites</h5>

            <p>We may include links on our website to websites that are not affiliated with us, do not provide us with products or services related to our business, or are not involved in our website administration.  We also allow artists included in our roster to display links to their websites or social media channels.  </p>

            <p>These links are solely for your information and we have no responsibility for the content or information collection policies of the persons or entities that operate those sites.   </p>

            <h5 className="pt-10 pb-20">Opting Out</h5>

            <p>You can indicate or change your communication preferences in your account settings.  You can also “unsubscribe” from our communications by using that feature in communications where it is included.</p>

            <h5 className="pt-10 pb-20">Online Privacy Policy Only</h5>

            <p>This Policy applies only to our website and the information collected there. It does not apply to any information collected offline or via channels other than our website, except as expressly described above.</p>

            <h5 className="pt-10 pb-20">Consent</h5>

            <p>By creating an account, purchasing a license or a subscription, communicating with us or by visiting our website, you agree to our collecting information and using it as we’ve described in this Policy.  You can always change your mind and you may have other rights that relate to your information that is in our possession as described in this Policy.  </p>

            <h5 className="pt-10 pb-20">Storing Your Personal Information</h5>

            <p>We store the information we collect as long as we determine it is reasonably necessary for the purposes described in this Policy, taking into consideration what you have indicated as your preferences related to receiving communications from us.  We will not delete any stored information we are legally required to keep for a longer period by applicable U.S. law.  When information is aggregated and can no longer be associated with you personally, it may be included in data that we or our service providers retain and use indefinitely.</p>

            <h5 className="pt-10 pb-20">Keeping Your Information Safe</h5>

            <p>We use one or more third parties to administer and host our website and that administrator(s) is obligated to use industry standard protocols and software to reduce the risk of loss, unauthorized access, disclosure, or misuse of your Information. However, no data is 100% secure, and we are not able to provide any assurances that the information that we and/or our third party service providers collect and store will be secure or that it will not be subject to loss, unauthorized access, use or disclosure.</p>

            <h5 className="pt-10 pb-20">Use by Children</h5>

            <p>Our website is intended for people 18 years or older who are U.S. residents.  We do not intend for our website to be used by anyone under the age of 13 or for anyone under the age of 13 to become a subscriber or to upload Tracks. If you are the parent or guardian of a child under the age of 13 and believe we may have collected information about your child, please contact us at the email or mailing address below. In such a case, you may review and request deletion of your child’s information as well as prohibit any use of that data.</p>

            <p>California users under the age of 18 may request the removal of any publicly available content or information they have uploaded to our website or mobile applications by sending us an email. Please note that it may not be possible to remove all such content when it is stored and controlled by third parties.</p>

            <h5 className="pt-10 pb-20">Updates and Revisions to this Policy</h5>

            <p>We may change or update this Policy from time to time.  We encourage you to check for updates.   </p>

            <h5 className="pt-10 pb-20">Notice To California Residents</h5>

            <p>California law gives California residents the right to request certain information relating to what we have collected (and are storing) from them.  If you are a California resident and would like to request the information as described in California Civil Code Section 1798.83, please send us a written request (see How to Contact Us, below).</p>

            <h5 className="pt-10 pb-20">How To Contact Us</h5>

            <p>If you have any questions, comments, or complaints about this Policy or if you believe that we have collected information in a way that you did not intend, please email us at <a  className="underline" href="mailto:help@deepsounds.com" target="_blank">help@deepsounds.com</a> or write us at:</p>

            <p className="pl-30">
                PRIVACY<br/>
                Deep Sounds, LLC.<br/>
                2041 W. Carroll Ave C-229<br/>
                Chicago, IL 60612<br/>
            </p>

            <p>If you want to change the information that you receive from us or the permissions you have previously given us, see <strong>Opting Out</strong> above. </p>
        </div>
    );
}
export default Privacy;

import React, { Component } from 'react';
import DefaultAvatar from "../../assets/svg/user-avatar.svg";
import './autocomplete.css';

export class Autocomplete extends Component {




  render() {

    let optionList;
    if (true) {
      if (this.props.options.length) {
        optionList = (
          <ul className="options">
            {this.props.options.map((option, index) => {
              return (
                <li key={option.email} onClick={()=>this.props.onClick(option.email, this.props.setValueCB)}>
                    <div className={"autocomplete-option " + (this.props.activeOption === index ? "option-active" : "" )}>
                        <div className="flex items-center">
                            {option.profilePhoto ? 
                                <img 
                                    src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${option.profilePhoto}`} 
                                    alt={`${option.name} picture`}
                                    className="w-30 h-30 bg-gray-300 mr-15" 
                                />
                                :
                                <img 
                                    src={DefaultAvatar} 
                                    alt={`${option.name} picture`}
                                    className="w-30 h-30 bg-gray-300 mr-15" 
                                />
                            }
                            {option.name ? option.name + " (" + option.email + ")": option.email}
                        </div>
                    </div>
                </li>
              );
            })}
          </ul>
        );
      } else {
        optionList = (
<></>
        );
      }
    }
    return (
      <React.Fragment>
            <div className="autocomplete-menu">
            <div className="autocomplete-list">
                {optionList}
            </div>
          </div>

      </React.Fragment>
    );
  }
}

export default Autocomplete;

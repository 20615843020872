import React from "react";
import { Controller, useForm } from "react-hook-form";
import { components } from "react-select";
import CoverArtUpload from "../Dropzone/projectCoverUpload";
import CircularIndeterminantButton from "../Loading/circularIndeterminantButton";


  const { ValueContainer, Placeholder } = components;

  const CustomValueContainer = ({ children, ...props }) => {
    return (
      <ValueContainer {...props}>
        <Placeholder {...props} isFocused={props.isFocused}>
          {props.selectProps.placeholder}
        </Placeholder>
        {React.Children.map(children, (child) =>
          child && child.type !== Placeholder ? child : null
        )}
      </ValueContainer>
    );
  };

export default function EditProjectModal(props){
        const { control, register, handleSubmit, watch, setError, errors, reset, trigger,  } = useForm();
        return(
            <React.Fragment>
 {/* {# Modal Header #} */}
            <header class="modal-header">
                <h2 id="add_scene_title" class="modal-title">Edit Project</h2>
                <button onClick={props.handleClose} aria-label="Close Modal" data-micromodal-close="add_scene_modal" class="modal-close" type="button"></button>
            </header>

            {/* {# Modal Content #} */}
            <div id="add_scene_content" class="modal-content">

                {/* {# Form #} */}
                <form action="" class="ds-form" onSubmit={(e)=>{e.preventDefault(); handleSubmit(props.updateProject(trigger, errors, props.handleClose))}}>

                    {/* {# Title #} */}
                    <div class="input-group-sm input-inline">
                        <Controller
                            name="editedProjectName"
                            control={control}
                            id="project_name"
                            placeholder="Project Name"
                            value={props.editedProjectName}
                            defaultValue={props.editedProjectName}
                            render={pro=> <input  {...pro} class="form-input" name={"editedProjectName"}
                                value={props.editedProjectName} onChange={(e)=>{pro.onChange(e);props.handleInputChange(e);}} ref={register}/>}
                            rules={{
                                required: "Project Title Required",
                                minLength: { message: "Scene Title Must be at Least 3 Characters", value: 3},
                                maxLength: { message: "Scene Title Cannot be More Than 99 Characters", value: 100},
                                validate: value => props.checkIfProjectUsed(value)
                            }
                            }
                        />
                        <label class="input-label" for="project_title">Project Title</label>
                        {errors.editedProjectName && <span className="error-message">{errors.editedProjectName.message}</span>}
                    </div>
                    <div className="input-group">
                        <CoverArtUpload reupload={props.albumArt?.length > 0} onChange={props.setCoverArtState} coverArt={props.coverArt}
                            coverArtError={props.coverArtError} removeCoverArt={props.removeCoverArt} defaultArt={props.defaultArt} userSet={props.userSet}
                            initialCoverArt={props.initialCoverArt} handleCropUtilityClose={props.handleCropUtilityClose} openPopout={props.openPopout} isCropUtilityOpen={props.isCropUtilityOpen} wavPlaceholder={props.wavPlaceholder}/>
                    </div>

                    {/* {# Buttons #} */}
                    <div class="input-group pt-20">
                        <div class="grid-row-sm">
                            <div class="col w-1/2 mb-20 sm:mb-0">
                                <button onClick={props.handleClose} class="btn btn-stroke form-btn w-full" type="button" aria-label="Close Modal" data-micromodal-close="add_scene_modal">Cancel</button>
                            </div>
                            <div class="col w-1/2">
                                <button disabled={props.isLoading} type="submit"  class="btn btn-primary form-btn w-full">{props.isLoading ? <CircularIndeterminantButton thickness={5.5}/> : "Save"}</button>
                            </div>
                        </div>
                    </div>

                </form>
                {/* {# End Form #} */}

            </div>
</React.Fragment>
        )

}

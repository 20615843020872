import React from "react";
import RoyaltyPaymentsRow from "./RoyaltyPaymentsRow";

const RoyaltyPaymentsRows = ({ payouts }) => {
  return (
    <div className="bg-gray-100 p-20 pb-10 lg:p-25 lg:pb-15 mb-40">
      <div className="flex flex-col md:flex-row items-start md:items-center justify-between">
        <div className="flex-1 flex items-center mb-15 md:mb-0">
          <h3 className="text-label-lg mr-10">Royalty Payments</h3>
          <span className="flex-none text-xs">Showing {payouts?.length}</span>
        </div>
      </div>
      <div className="table-wrap ds-scrollbar">
        <table className="stats-table w-full mt-30">
          <thead className="text-left">
            <tr>
              <th className="text-label-sm">Artist</th>
              <th className="text-label-sm">Total Payout</th>
              <th className="text-label-sm">Payout Date</th>
              <th className="text-label-sm">Status</th>
            </tr>
          </thead>
          <tbody>
            {payouts?.map((payout) => {
              const { id } = payout || {};
              return <RoyaltyPaymentsRow key={id} payout={payout} />;
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RoyaltyPaymentsRows;

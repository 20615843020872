import axios from "axios";

const usersAPI = {
  checkStripeId: function () {
    return axios({
      method: "get",
      url: "/api/users/checkStripeId",
    });
  },
  getSalesSummary: function () {
    return axios({
      method: "get",
      url: "/api/users/current/sales",
    });
  },
  getLicenseSales: function ({ currentPage, perPage }) {
    return axios({
      method: "get",
      url: "/api/users/current/sales/licenses/" + currentPage + "/" + perPage,
    });
  },
  getSubscriptionPayouts: function ({ currentPage, perPage }) {
    return axios({
      method: "get",
      url: "/api/users/current/sales/subscriptionPayouts/" + currentPage + "/" + perPage,
    });
  },
  getTransfers: function ({ currentPage, perPage }) {
    return axios({
      method: "get",
      url: "/api/users/current/sales/transfers/" + currentPage + "/" + perPage,
    });
  },
  getCustomerPaymentMethods: function () {
    return axios({
      method: "get",
      url: "/api/users/stripe/getMethods",
    });
  },
  getLastUsedPaymentMethod: function () {
    return axios({
      method: "get",
      url: "/api/purchases/lastUsedPaymentMethod",
    });
  },
  login: function ({ userCredentials, redirect, sessionCartInfo }) {
    return axios({
      method: "put",
      url: "/api/users/login",
      data: {
        email: userCredentials.email,
        password: userCredentials.password,
        redirect,
        sessionCartInfo,
      },
    });
  },
  saveSubscriptionLearnMore: function (data) {
    return axios({
      method: "post",
      url: "/api/users/saveSubscriptionLearnMore",
      data,
    });
  },
};
export default usersAPI;

import React from "react";

const SlidingPaneWrapper = ({
  children,
  handleClose,
  title
}) => {
  return (
    <>
      <div className="license-panel is-open" style={{ maxWidth: "100%" }}>
        <div class="license-panel-container">
          <div class="license-panel-header">
            <h3 class="text-label-lg text-white flex-1">
              {title}
            </h3>
            <button
              onClick={handleClose}
              class="circle-icon -mr-10"
              type="button"
            >
              <span class="icon-close"></span>
            </button>
          </div>

          <div class="license-panel-content">
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default SlidingPaneWrapper;

import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import { LabelRequired } from "../../FormElements/labels";
import CircularIndeterminateButton from "../../Loading/circularIndeterminantButton";
import { Error, Success } from "../../Toast/index";
import api from "../../utils/api";
import BackdropOverlayComponent from "../../LoadingOverlay/backdropComponent";

const schema = Joi.object({
  newEmail: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.email": "Please enter a valid email address",
      "string.empty": "Please enter an email address",
      "any.required": "Please enter an email address",
    }),
});

export default function ChangeEmailForm(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const { register, handleSubmit, setError, formState, errors } = useForm({
    mode: "onSubmit",
    defaultValues: {},
    resolver: joiResolver(schema),
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const newEmailRef = useRef(null);
  useEffect(() => {
    if (newEmailRef.current) {
      register(newEmailRef.current);
      newEmailRef.current.focus();
    }
  }, []);

  useEffect(() => {
    // console.log('formState', formState);
    // Show all errors as Toastify
    Object.entries(errors).map((error) =>
      Error({
        message: error[1].message,
        toastId: error[0],
      })
    );
  }, [errors]);

  const onSubmit = (data, e) => {
    setIsLoading(true);
    setIsDisabled(true);

    api
      .changeEmail({ newEmail: data.newEmail })
      .then((res) => {
        // console.log("updateEmail res", res);

        if (res.status === 200) {
          Success({ message: "Email pending--please enter confirmation code" });
          props.setPendingEmail(data.newEmail);
          setIsLoading(false);
          setIsDisabled(false);
          props.handleFormUnsaved(false);
          //   document.cookie = `pendingEmail=${data.email}; path=/;`;
        } else {
          Error({ message: "Error updating email." });
          setIsLoading(false);
          setIsDisabled(false);
          props.handleFormUnsaved(false);
        }
      })
      .catch((error) => {
        //   console.log('updateEmail error', error.response);
          if (error?.response?.data) {
            Error({
                message: error.response.data
            }); 
          }
        setIsDisabled(false);
        setIsLoading(false);
      });
    // console.log("formData", data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} class="ds-form">
      <div className="grid-row-xs">
        {isLoading && <BackdropOverlayComponent loading={true} size={40} />}

        <div className="col w-full mb-20">
          Your current email is <strong>{props.email}</strong>. What would you
          like to change it to?
        </div>

        <div className="col w-full">
          <div className="input-group input-inline mb-20">
            <input
              name="newEmail"
              id="update_email"
              autoComplete="username"
              data-lpignore="true"
              className="form-input"
              placeholder={"New Email Address *"}
              ref={newEmailRef}
            />
            <LabelRequired for="newEmail">New Email Address</LabelRequired>
          </div>

          <div className="col w-full">
            <div className="input-group input-inline mb-10">
              <button
                type="button"
                className="w-full btn form-btn btn-primary"
                onClick={handleSubmit(onSubmit)}
                disabled={isDisabled}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

import React, { Component } from "react";
import { ReactSortable } from "react-sortablejs";
import Sortable from "sortablejs";
import FeaturedListItem from "./featuredListItem";
class FeaturedList extends Component{
    componentDidMount=()=>{
        Sortable.utils.toggleClass()
    }
    render() {
        return(
            <div class="mb-30">
                <h2 class="text-label-lg mb-20 lg:mb-30">Currently Featured</h2>

                {/* {# Featured List #} */}
                <div class="featured-list">
                    {/* <div class="grid-row-xs"> */}

                        {/* {% for item in collections %} */}
                        <ReactSortable
                            // multiDrag={true}
                            className="grid-row-xs"
                            group="scenes"
                            animation={200}
                            handle='.icon-drag'
                            // onChoose={(event)=>{this.forceClose(event)}}
                            // onEnd={(event)=>{this.removePadding()}}
                            ghostClass='ghost-class'
                            // onUnchoose={(event)=>this.onSort(event)}
                            forceFallback={true}
                            delayOnTouchOnly={true}
                            delay={.9}
                            dataIdAttr="colli"
                            list={this.props.collections}
                            setList={newState => this.props.rearrangeFeatured(newState)}
                            forceFallback={true}
                            fallbackClass="col w-full md:w-1/2 xxl:w-1/3 mb-10 md:mb-20"
                            store={{
                                set: (sortable)=>this.props.setSort(sortable)}
                            }
                        >
                            {this.props.collections.map(collection=>{
                                return(
                                    <FeaturedListItem {...collection} makeUnfeatured={this.props.makeUnfeatured}/>
                                )
                            })}
                        </ReactSortable>
                    {/* </div> */}
                </div>

            </div>
    )}
}

export default FeaturedList
import React, { useEffect, useState } from 'react';
import ChannelSummary from '../ChannelSummary';
import LoadingContainer from "../../../Loading/LoadingContainer";
import SubmitChannel from '../SubmitChannel';

export default function ChannelWhitelistModal(props) {
  const {
    handleClose,
    youtubeChannelData
  } = props;

  return (
    <>
      <header className="modal-header">
        <h2 id="new_track_modal_title" className="modal-title">
          Channel Whitelist
        </h2>
        <button
          type="button"
          onClick={handleClose}
          aria-label="Close Modal"
          className="modal-close"
        />
      </header>
      <div id="channel_whitelist_modal_content" className="modal-content">
        <LoadingContainer isLoaded={youtubeChannelData}>
          <ChannelSummary
            youtubeChannelData={youtubeChannelData}
          />
          <SubmitChannel
            handleClose={handleClose}
            youtubeChannelData={youtubeChannelData}
          />
        </LoadingContainer>
      </div>
    </>
  )
}

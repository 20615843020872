import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import RejectedListItem from "./rejectedListItem";
class RejectedTracks extends Component {
    state = {
    }

    getRejectionToolTiltes = (track)=>{
        if(track.rejectionReasons){
            let rejectionReasons = JSON.parse(track.rejectionReasons)
            let errors = []
            if(rejectionReasons.content ==="reject"){
                errors.push({title: "Content"})
            }
            if(rejectionReasons.curation ==="reject"){
                errors.push({title: "Curation"})
            }
            if(rejectionReasons.exclusivity ==="reject"){
                errors.push({title: "Exclusivity"})
            }
            if(rejectionReasons.trackQuality === "reject"){
                errors.push({title: "Track Audio Quality"})
            }
            if(rejectionReasons.versions){
                let rejectedVersions =rejectionReasons.versions.filter(version=>{
                    if(version.approval === "reject"){
                       return true
                    }
                    return false
                })
                if(rejectedVersions.length > 0){
                    errors.push({title: "Version Audio Quality"})
                }
            }
            if(rejectionReasons.stems){
                let rejectedStems = rejectionReasons.stems.filter(stem=>{
                    if(stem.approval === "reject"){
                        return true
                    }
                    return false
                })
                if(rejectedStems.length > 0){
                    errors.push({title: "Stem Audio Quality"})
                }
            }
            return errors
        }
    }
    getRejectCount = (track)=>{
        let rejectionCount =0;
        
        if(track.rejectionReasons){
            let rejectionReasons =JSON.parse(track.rejectionReasons)
            if(rejectionReasons.content ==="reject"){
                rejectionCount++
            }
            if(rejectionReasons.curation ==="reject"){
                rejectionCount++
            }
            if(rejectionReasons.exclusivity ==="reject"){
                rejectionCount++
            }
            if(rejectionReasons.trackQuality ==="reject"){
                rejectionCount++
            }
            if(rejectionReasons.versions){
                rejectionReasons.versions.forEach(version=>{
                    if(version.approval === "reject"){
                        rejectionCount++
                    }
                })
            }
            if(rejectionReasons.stems){
                rejectionReasons.stems.forEach(stem=>{
                    if(stem.approval === "reject"){
                        rejectionCount++
                    }
                })
            }
        }
        return rejectionCount
    }
    getPrimary= (track)=>{
        let rejectionPrimary ={
            title: "",
            desc: ""
        };
        
        if(track.rejectionReasons){
            let rejectionReasons =JSON.parse(track.rejectionReasons)
            if(rejectionReasons.content ==="reject"){
                rejectionPrimary = {
                    title: "Content",
                    desc: "We have determined this track does not meet our content standards."
                }
                return rejectionPrimary
            }
            if(rejectionReasons.curation ==="reject"){
                rejectionPrimary = {
                    title: "Curation",
                    desc: "We have determined this track does not meet our curation standards."
                }
                return rejectionPrimary
            }
            if(rejectionReasons.exclusivity ==="reject"){
                rejectionPrimary = {
                    title: "Exclusivity",
                    desc: "We have determined this track does not meet our exclusivity standards."
                }
                return rejectionPrimary
            }
            if(rejectionReasons.trackQuality ==="reject"){
                rejectionPrimary = {
                    title: "Poor Audio Quality",
                    desc: <span>Audio does not meet quality standards. Please <NavLink to={"/dashboard/resubmit-track/" + track.trackId}><span style={{textDecoration: "underline"}}>re-upload.</span></NavLink></span>
                }
                return rejectionPrimary
            }
            if(rejectionReasons.versions){
                rejectionReasons.versions.forEach(version=>{
                    if(version.approval === "reject"){
                        rejectionPrimary = {
                            title: "Poor Audio Quality",
                            desc: <span>Audio does not meet quality standards. Please <NavLink to={"/dashboard/resubmit-track/" + track.trackId}><span style={{textDecoration: "underline"}}>re-upload.</span></NavLink></span>
                        }
                        return rejectionPrimary
                    }
                })
            }
            if(rejectionReasons.stems){
                rejectionReasons.stems.forEach(stem=>{
                    if(stem.approval === "reject"){
                        rejectionPrimary = {
                            title: "Poor Audio Quality",
                            desc: <span>Audio does not meet quality standards. Please <NavLink to={"/dashboard/resubmit-track/" + track.trackId}><span style={{textDecoration: "underline"}}>re-upload.</span></NavLink></span>
                        }
                        return rejectionPrimary
                    }
                })
            }
        }
        return rejectionPrimary
    }
    handleClick = (track)=>{
        if(track.trackStatus=== "fullRejected"){
            return
        }
        else{
            window.location.replace("/dashboard/resubmit-track/"+track.trackId)
        }
    }
    render() {
        return (
            // {/* {# Tracks List #} */}
                this.props.tracks?.length > 0 ?
                this.props.tracks.map( (track) => (
                   <RejectedListItem {...track}/>
                )):
                <p style={{textAlign: "center"}} className="mt-25">Sorry, your search resulted in no matches.</p>

        )
      }
        
}

RejectedTracks.defaultProps ={
  tracks: []
}

export default RejectedTracks
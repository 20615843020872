import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import React from "react";
export default function CircluarDeterminate(props){
    
    return(
        <Box position="relative" display="inline-flex">
            <CircularProgress
                thickness={props.thickness || 3.6}
                style={{color: "white"}}
                size={props.size || 40}
                value={props.progress || 0}
                variant={"determinate"}
            /> 
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography style={{fontSize: ".6rem"}}   variant="caption" component="div" >{`${props.progress || 0}%`}</Typography>
            </Box>
        </Box>
    )
}


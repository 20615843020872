import React from "react";
import currencyFormatter from "../../utils/currencyFormatter";
import buildAlbumArtURL from "../../utils/buildAlbumArtURL";

const CompleteLicenseItem = (props) => {
  const albumArtURL = buildAlbumArtURL(props.track);
  const isDiscount = props.license.originalPrice !== props.license.finalPrice;
  const showSubscriberIcon = isDiscount && props.subscriber;
  const originalPriceFormatted = currencyFormatter(
    props.license.originalPrice / 100
  );
  const finalPriceFormatted = currencyFormatter(props.license.finalPrice / 100);

  return (
    <div class="py-20" key={"cartItem-" + props.index}>
      {/* {# Track Item #} */}
      <div class="flex flex-1 items-center justify-between pb-15">
        {/* {# Artwork #} */}
        <figure class="license-panel-artwork mr-15">
          <img
            src={albumArtURL}
            alt={`${props.track?.trackTitle} by ${props.track?.artistName}`}
          />
        </figure>
        {/* {# Details #} */}
        <div class="flex flex-col flex-1 leading-snug pr-20">
          <span class="text-white text-sm md:text-base font-medium mb-3">
            {props.track?.trackTitle}
          </span>
          <span class="text-xs text-gray-500 mb-10">
            {props.track?.artistName}
          </span>
          <span class="text-xs text-gray-500">{props.licenseSummary}</span>
        </div>
        {/* {# Controls #} */}
        <div class="flex-none flex flex-col self-stretch items-end">
          {/* {# Price #} */}
          {isDiscount && (
            <div class="text-sm text-gray-400 line-through mb-3">
              {originalPriceFormatted}
            </div>
          )}
          {/* {# Sub Price #} */}
          <div class="flex items-center">
            <span
              class={`${
                showSubscriberIcon && "icon-checklist"
              } text-gray-400 text-icon-sm mr-5`}
            ></span>
            <span class="text-sm text-white pt-3">{finalPriceFormatted}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompleteLicenseItem;

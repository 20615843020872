import React, { Component } from "react";

class SortToolTip extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {

            }

    render() {
        return (
                <div className="dropdown-menu">
                {this.props.artistTrackList === true ? (
                    <>
                    {this.props.trackStatus === "approved" &&
                    <div className="dropdown-group">
                      <a href="#" onClick={()=>this.props.sortBy("Date Approved - Asc", false)}>Date Approved - Asc</a>
                      <a href="#" onClick={() => this.props.sortBy("Date Approved - Desc", false)}>Date Approved - Desc</a>
                      <a href="#" onClick={()=>this.props.sortBy("Most Played", false)}>Most Played</a>
                      <a href="#" onClick={() => this.props.sortBy("Most Downloaded", false)}>Most Downloaded</a>
                      <a href="#" onClick={() => this.props.sortBy("Most Liked", false)}>Most Liked</a>
                      <a href="#" onClick={() => this.props.sortBy("Most Projects", false)}>Most Projects</a>
                      <a href="#" onClick={()=>this.props.sortBy("Most Licensed", false)}>Most Licensed</a>
                    </div>
                    }
                    {(this.props.trackStatus === "incomplete" || this.props.trackStatus === "pending" || this.props.trackStatus === "rejected") &&
                    <div className="dropdown-group">
                        <a href="#" onClick={()=>this.props.sortBy("Recently Added - Asc", false)}>Recently Added - Asc</a>
                        <a href="#" onClick={()=>this.props.sortBy("Recently Added - Desc", false)}>Recently Added - Desc</a>
                        <a href="#" onClick={()=>this.props.sortBy("Name - Asc", false)}>Name - Asc</a>
                        <a href="#" onClick={()=>this.props.sortBy("Name - Desc", false)}>Name - Desc</a>
                    </div>}
                    </>
                  ) : (
                    <div className="dropdown-group">
                        <a href="#" onClick={()=>this.props.sortBy("Recommended", false)}>Recommended</a>
                        <a href="#" onClick={() => this.props.sortBy("Most Relevant", false)}>Most Relevant</a>
                        <a href="#" onClick={()=>this.props.sortBy("Recently Added", false)}>Recently Added</a>
                        <a href="#" onClick={()=>this.props.sortBy("Name - Asc", false)}>Name - Asc</a>
                        <a href="#" onClick={()=>this.props.sortBy("Name - Desc", false)}>Name - Desc</a>
                        {this.props.artistBrowse && <a href="#" onClick={()=>this.props.sortBy("Followers - Asc", false)}>Followers - Asc</a>}
                        {this.props.artistBrowse && <a href="#" onClick={()=>this.props.sortBy("Followers - Desc", false)}>Followers - Desc</a>}
                    </div>
                  )
                }

                </div>
        )
    }

}
export default SortToolTip

import React, { Component } from "react";

class CollaboratorTooltip extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {

            }

    render() {
        return (    
                <div className="dropdown-menu">
                    <div className="dropdown-group">
                        {/* <a href="#" onClick={()=>this.props.handleEditSceneModalOpen()}>Edit Scene Name</a> */}
                        <a href="#" onClick={()=>{this.props.toggleCollaboratorTooltip(this.props.collabIndex); this.props.removeCollaborator(this.props.collaboratorId, this.props.projectId)}}>Remove Collaborator</a>
                    </div>                   
                </div>
        )
    }

}
export default CollaboratorTooltip
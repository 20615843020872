import React, { useContext, useEffect, useState } from "react";
import Foco from "react-foco";
import Select from "react-select";
import api from "../utils/api";
import customStyles from "../FormElements/customStyles";
import CustomValueContainer from "../FormElements/customValueContainer";
import { SuccessRedirect } from "../Toast";
import { ProjectsContext } from "../../ProjectsContext";
import { setupProjectOptions } from "./utils";
import { handleAPIError } from "../../utils/handleAPIError";

const ProjectSelect = (props) => {
  const { associateProjectToCartItem, index, project, track } = props;

  const { allProjects } = useContext(ProjectsContext);

  const [isProjectMenuOpen, setIsProjectMenuOpen] = useState(false);
  const [projectOptions, setProjectOptions] = useState(setupProjectOptions(allProjects, track));
  const [showAddProject, setShowAddProject] = useState(false);
  const [newProjectName, setNewProjectName] = useState(null);
  const [projectsData, setProjectsData] = useState(allProjects);

  const projectOutlineStyle = { border: "2px solid rgb(235,25,45)" };

  const formatProjectLabel = ({ label }) => (
    <div className="flex items-center">
      <span className="text-icon-sm mr-10 icon-projects"></span>
      <span className="pt-2">{label}</span>
    </div>
  );
  const formatGroupLabel = ({ message }) => (
    <div className="flex items-center justify-between">
      <div className="text-xs text-gray-400">{message}</div>
      <div className="flex items-center flex-none text-icon-md text-gray-400">
        <span className="icon-checklist"></span>
      </div>
    </div>
  );

  const handleProjectMenuOpen = () => {
    setIsProjectMenuOpen(true);
  };

  const handleProjectChange = (selectedProject) => {
    associateProjectToCartItem(index, selectedProject.value, selectedProject.label);
    setIsProjectMenuOpen(false);
  };

  useEffect(() => {
    const allProjectOptions = setupProjectOptions(allProjects, track);
    let sortedProjectOptions = allProjectOptions;
    if (project) {
      console.log("selected project", project);
      const filteredProjectOptions = allProjectOptions.filter((projectToFilter) => {
        // console.log('projectToFilter', projectToFilter);
        return projectToFilter.value !== project.id;
      });
      const selectedProjectOption = allProjectOptions.find((projectToFind) => {
        // console.log('projectToFilter', projectToFind);
        return projectToFind.value === project.id;
      });
      filteredProjectOptions.unshift(selectedProjectOption);
      // console.log('filteredProjectOptions', filteredProjectOptions);
      sortedProjectOptions = filteredProjectOptions;
    }
    setProjectOptions(sortedProjectOptions);
  }, [projectsData]);

  const handleClickOutsideMenu = (event) => {
    const className = String(event.target.className) || "";
    if (!className?.includes(".select__menu")) {
      setIsProjectMenuOpen(false);
    }
  };

  const triggerShowAddProject = () => {
    associateProjectToCartItem(index, null, null);
    setShowAddProject(true);
  };

  const triggerHideShowAddProject = (event) => {
    event.preventDefault();
    setNewProjectName(null);
    setShowAddProject(false);
  };

  const handleNewProjectName = (event) => {
    const { value } = event.target;
    setNewProjectName(value);
  };

  const handleSaveProject = (event) => {
    event.preventDefault();
    const projectBody = {
      name: newProjectName
    };
    api
      .submitNewProject(projectBody)
      .then((res) => {
        console.log("newProject res", res)
        if (res?.data?.success && res?.data?.project?.id) {
          associateProjectToCartItem(index, res?.data?.project?.id, res?.data?.project?.name);
          const updatedProjectsData = projectsData.unshift(res?.data?.project);
          setProjectsData(updatedProjectsData);
          setShowAddProject(false);
          SuccessRedirect({
            message: "Project Created!"
          });
        }
      })
      .catch((error) => {
        // Error({message:"An error occurred!", onClose: this.errorToast})
        handleAPIError(error);
      });
  }

  const ProjectMenuList = (props) => {
    return (
      <>
        <Foco onClickOutside={handleClickOutsideMenu}>
          <div
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.preventDefault();
              triggerShowAddProject();
              setIsProjectMenuOpen(false);
            }}
            className="bg-gray-300 text-white leading-snug transition-colors duration-200 hover:bg-gray-100"
          >
            <a className="flex items-center px-20 py-15">
              <span className="icon-plus text-icon-sm mr-10"></span>
              <span className="pt-2">Add a Project</span>
            </a>
          </div>
          {props.children}
        </Foco>
      </>
    );
  };

  return (
    <>
      {/* {# Select Project #} */}
      {showAddProject ? (
        <>
          <div className="input-group-sm mb-0">
            <div className="grid-row-xs m-auto">
              <div className="col flex-1">
                {/* {# Project Name #} */}
                <div className="input-inline mb-0 pb-0">
                  <input
                    autoFocus={true}
                    id="project-name"
                    name="newProjectName"
                    className="form-input"
                    placeholder="Project Name"
                    value={newProjectName}
                    onChange={handleNewProjectName}
                    required
                    style={!project?.id ? projectOutlineStyle : {}}
                    onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}
                  />
                  <label className="input-label" htmlFor="newProjectName">
                    Project Name
                  </label>
                </div>
              </div>
              <div className="col flex-none flex items-center justify-center">
                {/* {# Save Project Button #} */}
                <button
                  type="button"
                  className="circle-icon-border circle-icon-md"
                  onClick={handleSaveProject}
                >
                  <span className="icon-check"></span>
                </button>
                {/* {# End New Project Button #} */}
              </div>
              <div className="col flex-none flex items-center justify-center">
                {/* {# Cancel Project Button #} */}
                <button
                  type="button"
                  className="circle-icon-border circle-icon-md"
                  onClick={triggerHideShowAddProject}
                >
                  <span className="icon-trash"></span>
                </button>
                {/* {# End Cancel Project Button #} */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div
            className="input-group w-full input-inline mb-0"
            style={!project?.id ? projectOutlineStyle : {}}
          >
            <label htmlFor="project_select" className="input-label">
              Select a Project<span className="input-label-required">*</span>
            </label>
            <div>
              <Select
                name="project_select"
                className="select-container select-container-sub"
                classNamePrefix="select"
                styles={customStyles}
                placeholder="Select a Project *"
                options={projectOptions}
                blurInputOnSelect={false}
                isSearchable={false}
                onChange={handleProjectChange}
                value={
                  project
                    ? {
                        label: project?.name,
                        value: project?.id,
                      }
                    : undefined
                }
                formatOptionLabel={formatProjectLabel}
                formatGroupLabel={formatGroupLabel}
                menuIsOpen={isProjectMenuOpen}
                onMenuOpen={handleProjectMenuOpen}
                // openMenuOnFocus={true}
                // openMenuOnClick={true}
                // onFocus={()=>this.setState({menuIsOpen: true})}
                closeMenuOnSelect={false}
                // onBlur={()=>{
                components={{
                  MenuList: ProjectMenuList,
                  ValueContainer: CustomValueContainer,
                }}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ProjectSelect;

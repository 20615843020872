import React, { Component } from "react";
import { UserConsumer } from "../../contexts/User";
import AppLayout from "../Layouts/appLayout";
import api from "../utils/api";
const queryString = require('query-string');

class CompleteStripeOnboard extends Component {
    state = {
        confirmed: false,
        complete: false
    }
    componentDidMount = ()=>{

        let parsedQuery = queryString.parse(this.props.location.search)
        api.stripeOnboardComplete(parsedQuery.code)
            .then(res=>{

                if (res.data.status === 'complete') {
                    this.setState({
                        confirmed: true,
                        complete: true
                    })
                }
            })
    }
    render(){
        return(
            <AppLayout>
                <UserConsumer>
                    {({user, login, logout})=>(
                        <>
                            <div className="container">

                                {/* {# App Header #} */}
                                <div className="view-header">
                                    <h1 className="view-title">Stripe Account Setup</h1>
                                </div>
                                {this.state.complete && this.state.confirmed ?
                                    <p>Your stripe account has been created!</p>
                                :
                                    this.state.complete && !this.state.confirmed ?
                                        <p>Sorry we had trouble confirming your stripe account</p>
                                    :
                                        <p>Processing...</p>
                                }
                            </div>
                        </>
                    )}
                </UserConsumer>
            </AppLayout>
        )
    }
}

export default CompleteStripeOnboard

import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import SlidingPane from "react-sliding-pane";
import { Tooltip } from "react-tippy";
import QuoteContactForm from "../Forms/QuoteContactForm";
import SlidingPaneWrapper from "../SlidingPanes";
import "./tooltip.css";

const PricingCard = (props) => {

  const {
    buttonClass,
    cardClass,
    description,
    featuresClass,
    includedFeatures,
    notIncluded,
    price,
    priceSubtext = {},
    priceType,
    selectedInterval,
    textClass,
    title
  } = props;

  const [isPlanModalOpen, setIsPlanModalOpen] = useState(false);

  const handlePlanModalOpen = () => {
    setIsPlanModalOpen(true);
  }

  const handlePlanModalClose = () => {
    setIsPlanModalOpen(false);
  }

  const handleContactFormOpen = (isContactFormOpen) => {
    const currentPlanType = isContactFormOpen && "contact";
  }

  // console.log('selectedInterval', selectedInterval);

  return (
    <>
      <div className="col flex items-stretch w-full md:w-1/3 mb-30">
        <div className={`pricing-card w-full ${cardClass}`}>
          {/* {# Header #} */}
          <div className="pricing-card-header mb-0">
            <h2 className={`pricing-card-title ${textClass}`}>
              {title}
            </h2>
            <div className="pricing-card-price-block">
              <h3 className="mr-10">{price[selectedInterval]}</h3>
              {/* {% if card.price_subtext %} */}
              <span className="pricing-card-price-subtext">
                {priceSubtext[selectedInterval]}
              </span>
              {/* {% endif %} */}
            </div>
            <p className="text-gray-500">{description}</p>
          </div>

          {/* {# Content #} */}
          <div className="pricing-card-content">
            {/* {# Features #} */}
            <div
              className={`pricing-card-features${
                featuresClass ? ` ${featuresClass}` : ""
              }`}
            >
              {/* {% for feature in card.features %} */}
              {/* {# Is included #} */}
              {/* {% if feature.included == true %} */}
              {includedFeatures.map((feature) => (
                <div className="flex items-center py-5 text-white" key={feature.description}>
                  <span className="text-icon-sm transform -translate-y-1 mr-5 icon-check"></span>
                  <Tooltip
                    useContext={true}
                    animation="shift"
                    arrow="true"
                    position="top"
                    html={
                      <>
                        <div className="price-tool-title mb-10">
                          {feature.name}
                        </div>
                        <div className="text-gray-500 price-tool-desc">
                          {feature.description}
                        </div>
                      </>
                    }
                  >
                    <span className="text-sm">{feature.name}</span>
                  </Tooltip>
                </div>
              ))}

              {/* {# Is not included #} */}
              {/* {% else %} */}
              {notIncluded.map((feature, index) => (
                <div className="flex items-center py-5 text-gray-400" key={feature.description || index}>
                  <span className="text-icon-sm transform -translate-y-1 mr-5 icon-close"></span>

                  <span className="text-sm">{feature.name}</span>
                </div>
              ))}

              {/* {% endif %} */}
              {/* {% endfor %} */}
            </div>

            {/* {# Button #} */}
            <SlidingPane
              isOpen={isPlanModalOpen}
              onRequestClose={() => handlePlanModalClose()}
              from={"right"}
              width={"100%"}
              children={
                <SlidingPaneWrapper
                  handleClose={handlePlanModalClose}
                  title="Let's Find Your Plan"
                >
                  <QuoteContactForm
                    quoteType="subscription"
                    requestType="Subscription - Custom"
                  />
                </SlidingPaneWrapper>
              }
            />
            <div className="btn-group">
              {priceType === "custom" ? (
                <button
                  type="button"
                  className={`btn form-btn w-full ${buttonClass}`}
                  onClick={() => {
                    handlePlanModalOpen();
                  }}
                >
                  Get In Touch
                </button>
              ) : (
                <NavLink
                  to={`/checkout/subscription/${priceType}/${selectedInterval}`}
                  className={`btn form-btn w-full ${buttonClass}`}
                >
                  {"Sign Up Now"}
                </NavLink>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PricingCard;

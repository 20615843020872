import React, { Component } from "react";
import { splitFormProps, useField, useForm } from "react-form";
import { Error, Success } from "../Toast";
const InputField = React.forwardRef((props,ref) =>{
    const [field, fieldOptions, rest] = splitFormProps(props)
    
    const {
        meta: {error, isTouched, isValidating },
        getInputProps,
        setValue
    } = useField(field, fieldOptions);
    return(
        props.passwordEnabled ==="enabled" && props.linkEnabled === "active" ?
        <>
            
            <input disabled={props.passwordEnabled==="disabled"} type="password" onChange={(e)=>props.updatePassword(e,setValue, props.projectId)} id="projectPassword" class="form-input" placeholder="Password" value={props.password === true ? "123456789" : props.password} 
                onFocus={()=>props.clearPW(props.projectId)} onBlur={()=>props.pwBlur(props.projectId)} defaultValue={props.password === true ? "123456789" : props.password}  onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} />{" "}
                {isValidating ?(
                    <em>Validating...</em>
                ) : isTouched && error ?(
                    <em className="error-message">{error}</em>
                ): null}
        </>:
        <></>
    )
})
function validatePW(value, instance, props){
    
    if(!value && props.passwordEnabled === "enabled" && props.linkEnabled === "active"){
        if(props.password !== true){
            return "Password required!"
        }
    }
    return false
}
const MyForm = (props)=>{
    const {
        Form,
        meta: { isSubmitting, canSubmit }
      } = useForm({
        onSubmit: async (values, instance) => {
            
          // onSubmit (and everything else in React Form)
          // has async support out-of-the-box
            
            let res = await props.updateProjectLink(props.projectLinkId, props.projectId)
            
            if(res){
                if(res.data.success){
                    props.handleClose()
                }
            }
          
        }      
    });
    
    return(
        <Form>
            {props.isOwner ?
            <div className="input-group sm">
                        {/* {# Share Url Toggle #} */}
                        <div class="flex">

                            <div class="flex-1 pr-10">
                                {/* {# Label #} */}
                                <label
                                    class="input-label cursor-pointer mb-5"
                                    for="link_enabled"
                                    // @click="enabled = !enabled"
                                >
                                    Enable Share Link
                                </label>
                                {/* {# Description #} */}
                                <p class="text-sm text-gray-400 mb-0">Share links are read-only and wont have collaborative features such as voting and adding tracks. <a onClick={()=>{props.handleManageCollaboratorsModalOpen();props.handleClose();}} class="text-link font-medium">Add collaborators</a> to have those features.</p>
                            </div>

                            <div class="flex-none">
                                {/* {# Form Switch #} */}
                                <label class="form-switch">
                                    <input onChange={(e)=>{props.linkEnabled === "active" ? props.enableLink("disabled", props.projectId):props.enableLink("active", props.projectId) }} type="checkbox" id="link_enabled" name="link_enabled" checked={props.linkEnabled === "active"}/>
                                    <span
                                        class="form-switch-slider"
                                    >
                                    </span>
                                </label>
                            </div>

                        </div>
                        <div class={`mt-15 ${props.linkEnabled ==="active" ? 'block': 'hidden'}`}>
                            <div class="flex items-center">
                                <div class="flex-1 pr-10">
                                    {/* {# Share Url #} */}
                                    <div class="input-inline">
                                        <input ref={(input)=>{props.setElement(input)}} autoFocus={true}  id="projectLink" onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }} class="form-input" placeholder="Share Link" value={`${window.location.protocol}//${window.location.host}/projects/public/${props.projectLink}`}/>
                                        <label class="input-label" for="project_title">Share Link</label>
                                    </div>
                                </div>

                                <div class="flex-none">
                                    {/* {# Copy Password #} */}
                                    <button type="button" class="circle-icon text-icon-md js-tooltip" onClick={props.shareProject}>
                                        <span class="icon-copy"></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>:
                    <div className="input-group sm">
                    {/* {# Share Url Toggle #} */}
                    <div class={`mt-15 block`}>
                        <div class="flex items-center">
                            <div class="flex-1 pr-10">
                                {/* {# Share Url #} */}
                                <div class="input-inline">
                                    <input ref={(input)=>{props.setElement(input)}} autoFocus={true}  id="projectLink" class="form-input" placeholder="Share Url" value={`${window.location.protocol}//${window.location.host}/projects/public/${props.projectLink}`}/>
                                    <label class="input-label" for="project_title">Share Link</label>
                                </div>
                            </div>

                            <div class="flex-none">
                                {/* {# Copy Password #} */}
                                <button type="button" class="circle-icon text-icon-md js-tooltip" onClick={props.shareProject}>
                                    <span class="icon-copy"></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                }
               
                    {/* <div class="input-group-sm input-inline mb-30">
                        <input ref={(input)=>{props.setElement(input)}} autoFocus={true}  id="projectLink" class="form-input" placeholder="Share Url" value={`${window.location.protocol}//${window.location.host}/public/${props.projectLink}`}/>
                        <label class="input-label" for="projectLink">Share UrL</label>
                    </div> */}
                    {props.isOwner ? <>
                    {props.linkEnabled === "active" &&  
                        <div className="input-group-sm block">
                             <div class="flex">
                                <div class="flex-1 pr-10">
                                    {/* {# Label #} */}
                                    <label
                                        class="input-label cursor-pointer mb-0"
                                        for="password_share"
                                    >
                                        Enable Password
                                    </label>
                                </div>

                                <div class="flex-none">
                                    {/* {# Form Switch #} */}
                                    <label class="form-switch">
                                        <input type="checkbox" onChange={(e)=>{props.passwordEnabled === "enabled" ? props.enablePassword("disabled", props.projectId):props.enablePassword("enabled", props.projectId) }} id="password_share" name="password_share" checked={props.passwordEnabled === "enabled"}/>
                                        <span
                                            class="form-switch-slider">
                                        </span>
                                    </label>
                                </div>

                                </div>      
                                <div class="input-inline mt-15">
                        <InputField field="password" {...props} validate={(value, instance)=>validatePW(value, instance, {...props})}/>
                        {/* <input disabled={props.passwordEnabled==="disabled"} type="password" onChange={props.updatePassword}id="projectPassword" class="form-input" placeholder="Password" value={props.password === true ? "123456789" : props.password} 
                            onFocus={props.clearPW} onBlur={props.pwBlur}/> */}
                        <label class="input-label" for="project_title">Password</label>
                    </div>                     
                        </div>
                    }
                   
                    <div class="input-group pt-20">
                        <div class="grid-row-sm">
                            <div class="col w-1/2 mb-20 sm:mb-0">
                                <button onClick={props.handleClose} class="btn btn-stroke form-btn w-full" type="button" aria-label="Close Modal" data-micromodal-close="add_scene_modal">Cancel</button>
                            </div>
                            <div class="col w-1/2">
                                <button type="submit" disabled={!canSubmit} class="btn btn-primary form-btn w-full">Save</button>
                            </div>
                        </div>
                    </div>
                    </>:<></>}
        </Form>
    )
}

class ShareProjectModal extends Component{
    componentDidMount = ()=>{
      
       
    }
    shareProject = ()=>{
        if(this.props.projectLink !== null && this.props.projectLink !== undefined){
            this.shareUrl.focus()
            this.shareUrl.select()
            try {
                let successful = document.execCommand('copy');
                if(successful){
                    Success({message: "Share Link Copied to Clipboard"})
                }
                else{
                    Error({message: "Couldn't Copy Share Link"})
                }
                let msg = successful ? 'successful' : 'unsuccessful';
                
              } catch (err) {
                
              }  
        }
    }
    setElement=(element)=>{
        this.shareUrl= element
    }
    render(){
      
        return(
            <React.Fragment>
              <header class="modal-header">
                <h2 id="share_project_title" class="modal-title">Share Link</h2>
                <button onClick={this.props.handleClose} aria-label="Close Modal" data-micromodal-close="share_project_modal" class="modal-close" type="button"></button>
            </header>

            {/* {# Modal Content #} */}
            <div id="share_project_content" class="modal-content">
                <MyForm {...this.props} setElement={this.setElement} shareProject={this.shareProject}/>
               

            </div>
</React.Fragment>
        )
    }
}
export default ShareProjectModal
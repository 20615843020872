import React from "react";
import Video from "../Video/video";

export default function FeatureBlockIcon (props) {
    const list = [
        {
            "icon": "icon-projects",
            "text": "Create Projects to Save Tracks to"
        },
        {
            "icon": "icon-video-studio",
            "text": "Create Multiple Scenes in a Project"
        },
        {
            "icon": "icon-follow",
            "text": "Invite Collaborators to Vote on Tracks"
        }
    ]
    return (
        props.alt ?

            <article className="feature-block grid-row items-center">
                {/* {# Text #} */}
                <div className="col w-full md:w-1/2 order-2 md:order-1">
                    <div className="lg:px-40 xl:px-80 xxl:px-100 max-w-xl">
                        <h2 className="mb-20">{props.title || 'The Perfect Track'}</h2>
                        <p className="text-h4 mb-25 lg:mb-40">
                            {props.desc || 'Search for Music using our many filters to narrow down the exact vibe you are going for.'}
                        </p>

                        {/* {# Icon List #} */}
                        <ul>
                            {list.map((listItem) =>
                                <li className="flex items-center mb-15" key={listItem.icon}>
                                    <span className={listItem.icon +" text-icon-md text-gray-400 mr-10"}></span>
                                    <span className="text-white font-medium">{listItem.text}</span>
                                </li>
                            )}
                        </ul>

                    </div>

                </div>
                {/* {# Media #} */}
                <div className="col w-full md:w-1/2 order-1 md:order-2">
                    {props.mp4 ?
                        <div className="shadow-md mb-40 md:mb-0">
                            <Video
                                image={props.poster}
                                mp4={props.mp4}
                                webm={props.webm}
                            />
                        </div>
                        :
                        <figure className="shadow-md mb-40 md:mb-0">
                            <img src={ props.poster || `${process.env.PUBLIC_URL}/images/home/feature-01.jpg`} alt=""/>
                        </figure>
                    }
                </div>
        </article>:
        <article className="feature-block grid-row items-center">
        {/* {# Media #} */}
        <div className="col w-full md:w-1/2">
            {props.mp4 ?
                <div className="shadow-md mb-40 md:mb-0">
                    <Video
                        image={props.poster}
                        mp4={props.mp4}
                        webm={props.webm}
                    />
                </div>
                :
                <figure className="shadow-md mb-40 md:mb-0">
                    <img src={ props.poster || `${process.env.PUBLIC_URL}/images/home/feature-01.jpg`} alt=""/>
                </figure>
            }
        </div>
        {/* {# Text #} */}
        <div className="col w-full md:w-1/2">
            <div className="lg:px-40 xl:px-80 xxl:px-100 max-w-xl">
                <h2 className="mb-20">{props.title || 'The Perfect Track'}</h2>
                <p className="text-h4 mb-25 lg:mb-40">
                    {props.desc || 'Search for Music using our many filters to narrow down the exact vibe you are going for.'}
                </p>

                {/* {# Icon List #} */}
                <ul>
                    {list.map(listItem=>
                        <li className="flex items-center mb-15">
                            <span className={listItem.icon +" text-icon-md text-gray-400 mr-10"}></span>
                            <span className="text-white font-medium">{listItem.text}</span>
                        </li>
                    )}
                </ul>

            </div>

        </div>

    </article>
    )
}

import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { useIntercom } from "react-use-intercom";
import { UserContext } from "../../contexts/User";
import getUserProps from './getUserProps';

export default function Intercom(props) {
  const { boot, update, shutdown } = useIntercom();
  const userData = useContext(UserContext);
  const { user, userLoaded } = userData || {};

  const defaultUserProps = getUserProps(user) || {};
  const [userProps, setUserProps] = useState(defaultUserProps);

  useEffect(() => {
    const updatedUserProps = getUserProps(user) || {};
    if (updatedUserProps.name) {
      setUserProps(updatedUserProps);
    }
  }, [userLoaded]);

  const isMobile = useMediaQuery({ maxWidth: 759 });
  const bootMobile = () => {
    // console.log('booting mobile with userprops', userProps);
    boot({
      hideDefaultLauncher: true,
      ...userProps
    });
  }
  const bootWithProps = () => {
    // console.log('booting non-mobile with userprops', userProps);
    boot(userProps);
  };

  const location = useLocation();
  // console.log('intercom location', location);

  // "Ping" on every page change
  // See https://www.intercom.com/help/en/articles/170-integrate-intercom-in-a-single-page-app
  useEffect(() => {
    const { pathname } = location || {};

    if (pathname === '/logout') {
      shutdown();
      // boot();
    }
    else {
      if (userProps?.name) {
        userProps.lastRequestAt = parseInt(new Date().getTime() / 1000);
        update(userProps);
      }
    }
  }, [location]);

  if (isMobile) {
    return <>{bootMobile()}</>;
  } else {
    return <>{bootWithProps()}</>;
  }
}

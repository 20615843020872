import moment from "moment"
import React, { Component } from "react"
import ReactPlayer from "react-player/file"
import { PlayerContext } from "../../../PlayerContext"
import VideoStudioWaveform from "../../Waveform/videoStudio"
import Controls from "./controls"
import FullTrackItem from "./fullTrackItem"
import StemsItem from "./stemsItem"
import TrackInfo from "./trackInfo"
import VersionsItem from "./versionsItem"
import VolumeMute from "./volumeMute"
import VolumeSlider from "./volumeSlider"
var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment)
const userAgent = window.navigator.userAgent.toLowerCase();
const isMobileSafari = (/iphone/.test(userAgent) || /ipad/.test(userAgent) || (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2));
class MainContent extends Component{
    state={
        activeTab: "volume",
        baseTrack: {}
    }
    componentDidMount= ()=>{

        // this.props.getVideoTimelineWidth()


        // this.props.getVideoTimelineWidth.flush()
        window.addEventListener('resize', this.context.drawVideoStudioTimeline)
    }
    componentDidUpdate = ()=>{
        if(this.state.baseTrack?.idKey !== this.context.mediaPlayer.idKey){
            let baseTrack = {
                trackId: this.context.mediaPlayer.trackId,
                artistURL: this.context.mediaPlayer.artistURL,
                peaks: this.context.mediaPlayer.peaks,
                imageURL: this.context.mediaPlayer.imageURL,
                artistName: this.context.mediaPlayer.artistNameDisplay,
                artistId: this.context.mediaPlayer.artistId,
                trackURL: this.context.mediaPlayer.trackURL,
                albumId: this.context.mediaPlayer.albumId,
                idKey: this.context.mediaPlayer.idKey,
            }
            this.setState({baseTrack: baseTrack, activeTab: this.state.baseTrack?.trackId !== baseTrack.trackId ? "volume": this.state.activeTab})
        }

    }
    componentWillUnmount = ()=>{
        // this.props.getVideoTimelineWidth.cancel();
        window.removeEventListener('resize', this.context.drawVideoStudioTimeline);
    }
    changeVideoTime = (event)=>{
        console.log(event)
        console.log("change time event ",event.target)
        console.log("change time value ", event.target.value)
        this.props.videoSeekTo(event.target.value)
    }
    initialDurationSet = ()=>{

        console.log("initial duration set ", this.player, " urk ", this.props.videoURL)
        if(this.player && !this.context.videoElement){
            this.props.setVideoElement(this.player)
            // let videoDuration = this.player.getDuration();
            // let regionList = this.context.waveFormEl?.regions?.list
            // let regionSet
            // if(regionList){
            //     regionSet = Object.keys(regionList).length > 0
            // }
            // else{
            //     regionSet = false
            // }
            // console.log("video duration from init ", this.player.getDuration() )
            // if(videoDuration !== this.props.videoDurationSeconds || !regionSet && this.props.isMainOpen){
            //     console.log("setting video duration")
            //     if(this.context.waveFormEl !== null && videoDuration !== null){
            //         console.log("actually setting duration")
            //         this.props.setVideoDuration(videoDuration);
            //         let startFormatted = moment.duration(Math.floor(0), 'seconds').format("m:ss",{forceLength: true, trim: false})
            //         let end = videoDuration
            //         if (end > this.context.waveFormEl.getDuration()){
            //             end = this.context.waveFormEl.getDuration()
            //           }
            //         let endFormatted = moment.duration(Math.floor(end), 'seconds').format("m:ss",{forceLength: true, trim: false})
            //         if(endFormatted !== null){
            //         let region = {
            //             start: 0,
            //             end: videoDuration,
            //             startFormatted: startFormatted,
            //             endFormatted: endFormatted
            //         }
            //         this.props.setVideoElement(this.player)
            //         this.props.getVideoTimelineWidth()
            //         console.log("2 set region")
            //         this.context.setRegion(region)
            //         }
            //         else{
            //             setTimeout(this.initialDurationSet, 100)
            //         }
            //     }
            //     else{
            //         setTimeout(this.initialDurationSet, 100)
            //     }
            // }
        }
        else{
            if(!this.context.videoElement){
                setTimeout(this.initialDurationSet, 100)
            }
        }


    }
    playVideo = ()=>{
        this.context.handlePlay()
    }
    pauseVideo = ()=>{
        this.context.handlePause()
    }
    videoEnded = ()=>{
        console.log("video ended")
        // this.props.pauseVideo()
        if(this.props.isLoopEnabled){

            this.context.handlePause()
            this.props.playVideoLoop()


        }
        else{
          this.props.resetPlayed()
          this.context.handlePause()
        }
    }
    render (){
        return(
            <div className="vs-panel-row">

            {/* {# Left #} */}
            <div className="vs-panel-left">

                {/* {# Video Container #} */}
                {/* <div className="vs-video-container aspect-w-16 aspect-h-9"> */}
                    {this.context.isMainOpen &&
                    <ReactPlayer
                        onReady={()=>{console.log("ready ");this.initialDurationSet()}}
                        onDuration={(duration)=>{this.props.setVideoDuration(duration)}}
                        playing={this.props.isVideoPlaying}
                        ref={(player) => { this.player = player }}
                        url={this.props.videoURL}
                        onProgress={(progress)=>this.props.updateVideoProgress(progress)}
                        volume={parseFloat(this.props.videoVolume)}
                        className="vs-video-container aspect-w-16 aspect-h-9"
                        width={"unset"}
                        height={"unset"}
                        onEnded={this.videoEnded}
                        progressInterval={50}
                        muted={this.props.isMuted}
                        id="videoStudioSource"
                    />
                    }
                {/* </div> */}

                {/* {# Video Controls #} */}
                <Controls pauseRegion={this.context.pauseRegion} replayVideo={this.props.replayVideo} handlePause={this.context.handlePause} isVideoPlaying={this.props.isVideoPlaying} playVideo={this.playVideo} pauseVideo={this.pauseVideo} videoDurationFormatted={this.props.videoDurationFormatted} playedFormatted={this.props.playedFormatted} playedPercent={this.props.playedPercent} changeVideoTime={this.changeVideoTime} getVideoTimelineWidth={this.props.getVideoTimelineWidth}/>

                {/* {# Video Wav #} */}
                <div className="vs-video-wav" style={{overflow: "hidden"}}>
                    {(this.context.mediaPlayer.peaks?.length > 0 && this.props.isMainOpen) &&
                    <VideoStudioWaveform idKey={this.context.mediaPlayer.idKey}   trackId={this.context.mediaPlayer.trackId}  peaks={this.context.mediaPlayer.peaks || []} isMainOpen={this.props.isMainOpen} getVideoTimelineWidth={this.props.getVideoTimelineWidth} videoTimelineWidth={this.props.videoTimelineWidth}
                        videoDuration={this.props.videoDurationSeconds} initialLeftCutoff={this.props.initialLeftCutoff} updateVideoOffset={this.props.updateVideoOffset} trackStartTime={this.props.trackStartTime} playedSeconds={this.props.playedSeconds} isVideoPlaying={this.props.isVideoPlaying} pauseVideo={this.props.pauseVideo} playVideo={this.props.playVideo} isVideoPlaying={this.props.isVideoPlaying} playVideoFromIn={this.props.playVideoFromIn} isMini={this.props.isMini}></VideoStudioWaveform>
                    }
                    {/* {% include "components/audio/waveform-vs.html" %} */}
                </div>

            </div>
            {/* {# End Left #} */}

            {/* {# Right #} */}
            <div className="vs-panel-right">
                    {/* {# VS Tabs #} */}
                    <div className="tabs tabs-secondary tabs-no-scroll">
                        {/* {# Tab Items #} */}
                        <ul className="tab-items">
                            {/* {# Volume Tab #} */}
                            <li onClick={()=>this.setState({activeTab: "volume"})} className={`tab-item ${this.state.activeTab === "volume" && "active"}`} >
                                <button className="tab-item-title">Volume</button>
                            </li>
                            {/* {# Versions Tab #} */}
                            <li onClick={()=>this.setState({activeTab: "versions"},()=>this.context.getVersionInfo(this.context.mediaPlayer.trackId))} className={`tab-item ${this.state.activeTab === "versions" && "active"}`}>
                                <button className="tab-item-title" >Versions</button>
                            </li>
                            {/* {# Stems Tab #} */}
                            {this.context.loadedTracks[this.context.mediaPlayer.trackId]?.stems?.length > 0 &&
                                <li onClick={()=>this.setState({activeTab: "stems"},()=>this.context.getStemInfo(this.context.mediaPlayer.trackId))} className={`tab-item ${this.state.activeTab === "stems" && "active"}`}>
                                    <button className="tab-item-title" >Stems</button>
                                </li>
                            }
                        </ul>

                    {/* {# Tab Content #} */}
                    <div className="tab-content">
                        <div className="tab-item-content active" >
                            {
                                {
                                    "volume": <>
                                            {/* {# Volume #} */}
                                            {!isMobileSafari ? <>
                                                <VolumeSlider label={"Video Volume"} value={this.props.videoVolume} onChange={this.props.changeVideoVolume} max={1} step={.05}/>
                                                <VolumeSlider label={"Music Volume"} value={this.context.volume} onChange={this.context.setVolume} max={1} step={.05}/>
                                            </>:
                                                <VolumeMute label={"Video Volume"} toggleMute={this.props.toggleMute} isMuted={this.props.isMuted} />
                                            }
                                            </>,
                                    "versions":<>
                                        {/* {# Versions #} */}
                                            <FullTrackItem baseTrack={this.context.loadedTracks[this.context.mediaPlayer.trackId]} mediaPlayer={this.context.mediaPlayer}  pauseVideo={this.pauseVideo} playVideo={this.playVideo} playing={this.props.isVideoPlaying} playPause={this.context.playPause} changeCurrentTrack={this.context.loadTrackInMediaPlayer} setAutoPlay={this.context.setAutoPlay}/>
                                        {this.context.loadedTracks[this.context.mediaPlayer?.trackId]?.versions?.map(version=>
                                            <VersionsItem key={`vs-version-${version}`} {...this.context.loadedVersions[version] || {}} mediaPlayer={this.context.mediaPlayer} loadVersionInMediaPlayer={this.context.loadVersionInMediaPlayer} pauseVideo={this.pauseVideo} playVideo={this.playVideo} playing={this.props.isVideoPlaying} playPause={this.context.playPause} />
                                        )}
                                        </>,
                                    "stems":<>
                                        {/* {# Stems #} */}
                                        {this.context.loadedTracks[this.context.mediaPlayer.trackId]?.stems?.map(stem=>
                                            <StemsItem key={`vs-stem-${stem}`} {...this.context.loadedStems[stem] || {}} mediaPlayer={this.context.mediaPlayer} pauseVideo={this.pauseVideo} playVideo={this.playVideo} playing={this.props.isVideoPlaying} playPause={this.context.playPause} loadStemInMediaPlayer={this.context.loadStemInMediaPlayer}/>)}
                                        </>,
                                }[this.state.activeTab]
                            }

                        </div>

                    </div>
                </div>

                {/* {# VS Track Info #} */}
                <TrackInfo {...this.context.mediaPlayer} region={this.context.region} toggleRegion={this.context.toggleRegion} isLoopEnabled={this.props.isLoopEnabled} toggleLoop={this.props.toggleLoop}/>
            </div>
            {/* {# End Right #} */}

        </div>

        )
    }
}
MainContent.contextType = PlayerContext
export default MainContent

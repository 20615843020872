import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import 'react-tippy/dist/tippy.css';
import api from "../utils/api";

class ApprovedUsers extends Component {
    state = {
        loaded: false,
        userList: [],
    }

    componentDidMount = () => {
        api.getUsers()
            .then(res => {
                
                this.setState({ 
                    loaded: true,
                    userList: res.data 
                })
            })
    }
    resolveProfilePhoto=(profilePhotoKey)=>{
        const key = profilePhotoKey
        if(profilePhotoKey){
            const ext = key.split('.').reverse()[0];
            let final = key.replace('.' + ext, '') + '-minified-140x140'  + '.' + ext
            return final
        }
    }
    render() {
        
        return (
            <div className="grid-row-xs mb-40">
                {/* {# Users List #} */}
                {this.state.loaded && this.state.userList.map( (user) => (
                    <div className="col w-full md:w-1/2 xl:w-1/3 mb-10 sm:mb-20" >
                        <div className={`user-card-sm` }>
                            {/* {# Left #} */}
                            <NavLink to={"/dashboard/users/edit/" + user.id}>
                                <div className="user-card-content">
                                    <div 
                                        className="user-card-image"
                                        title={`${user.firstName} ${user.lastName}`}
                                        style={{backgroundImage: `url('https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveProfilePhoto(user.profilePhoto)}')`}}
                                    >
                                        {/*
                                        {user.premium? &&
                                            <div class="user-card-badge js-tooltip" title="Tier Name">
                                                <span class="icon-premium"></span>
                                            </div>
                                        }
                                        */}
                                    </div>
                                    <div className="user-card-name ml-15">
                                        <span className="user-card-heading">
                                            {user.firstName} {user.lastName}
                                        </span>
                                        <div class="user-card-meta">
                                            <span>{user.role.charAt(0).toUpperCase() + user.role.slice(1)}</span>
                                            <span class="mx-5 text-gray-400">•</span>
                                            <span>0 Tracks</span>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                    
                            
                            <button className="circle-icon circle-icon-sm icon-dots-vert"></button>
                            {/* <button className="track-list-selected text-white"><span className="icon-check-active"></span></button> */}

                        </div>
                    </div>
                ))}
            </div>
        )
      }
        
}

export default ApprovedUsers
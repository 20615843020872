import React, { Component, createRef } from "react";
import LoadingOverlay from "react-loading-overlay";
import { WaveSpinner } from "react-spinners-kit";
import WaveSurfer from 'wavesurfer.js';
import { PlayerContext } from "../../PlayerContext";

// import { PlayerContext } from "../../PlayerContext";

export default class Waveform extends Component {
    state = {
        src: this.props.src || "",
        peaks: this.props.peaks,
        currentTime: "0:00",
        duration: "0:00",
        sendPlay: 0,
        volume: 1
    }
    handleOnChange = (value) => {
      console.log(value)
      this.setState({
        volume: value.x
      })
    }
  setVolume = ()=>{
    console.log("setting volume to ", this.state.volume)
    this.wavesurfer.setVolume(this.state.volume)
  }
  wrapper = createRef()
  componentDidMount() {
  
    this.$el = this.wrapper.current
    this.$waveform = this.$el.querySelector('.wave')
    this.wavesurfer = WaveSurfer.create({
        container: this.$waveform,
        barMinHeight: 1,
        barWidth: 1.5,
        barGap: 2,
        hideScrollbar: true,
        interact: true,
        cursorWidth: 0,
        // backend: "MediaElement",
        height: 80,
        // barMinHeight: ,
        // barHeight: 10,
        progressColor: "rgba(255,255,255,1)",
        responsive: true,
        waveColor: "rgba(255,255,255,.5)",
        // xhr:{ cache: 'default', mode: 'cors', method: 'GET', credentials: 'same-origin', redirect: 'follow', referrer: 'client', headers: [ { key: 'Authorization', value: 'my-token' } ]},
        // fillParent: true
        // fillParent: true,      
        normalize: true  
    })
    this.waveReady = this.wavesurfer.on('ready', ()=>{
      console.log("wave ready")
      this.props.emitPlayable()
      this.setState({ready: true})
    })
    this.wavesurferError = this.wavesurfer.on('error', (error)=>{console.log("wave error ", error)})
    this.wavesurfer.loadBlob(this.props.src)
  }
  songFinished = ()=>{}
  wavesurferError = ()=>{}
  componentDidUpdate = (prevProps, prevState, snapshot)=>{

        if(this.props.nowPlaying){
          this.wavesurfer.play()
        }
        else{
          this.wavesurfer.pause()
        }
 }

  componentWillUnmount() {

  }
  render() {
    return (
      <div style={{backgroundColor: "#131313", width: "100%" }}>
      <LoadingOverlay
       styles={{
        overlay: (base) => ({
          ...base,
          zIndex: 39
        })
      }}
      active={!this.state.ready}
      styles={{
        overlay: (base) => ({
          ...base,
          background: 'rgba(255, 0, 0, 0.0)'
        })
      }}
      spinner={<WaveSpinner size={30} color="#fff" loading={!this.state.ready}></WaveSpinner>}
  >
  <div ref={this.wrapper} className="" style={{width: "100%",backgroundColor: "#131313"}}>

<div style={{backgroundColor: "#131313", width: "100%" }} className='waveform'>
  <div className='wave'/>
</div>
</div>
</LoadingOverlay>
</div>
    
    )
  }
}

Waveform.defaultProps = {
  src: "",
  peaks: ""
}

Waveform.contextType = PlayerContext
import moment from "moment";
import React, { Component } from "react";
import { deviceDetect } from "react-device-detect";
import { Helmet } from "react-helmet";
import { NavLink } from "react-router-dom";
import Slider from "react-slick";
import 'react-tippy/dist/tippy.css';
import { PlayerContext } from "../../PlayerContext";
import { ProjectsConsumer } from "../../ProjectsContext";
import "../Carousels/index.css";
import PublicCard from "../Collections/publicCard";
import Footer from '../Footer';
import LoadingContainer from "../Loading/LoadingContainer";
import Pagination from "../Pagination/pagination";
import { Error, Success } from "../Toast/index";
import TrackGridItem from '../Track/trackGridItem';
import TrackListItem from '../Track/trackListItem';
import api from "../utils/api";
import ProjectCard from "./projectCard";
import SearchArtists from './searchArtists';
import SideBar from "./sideBar";


var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment)
const queryStringer = require('query-string');


// TODO: Refactor this to use modal components so it doesn't duplicate code from ArtisTrackList
const isSmallScreen = (window.screen.width < 600);
const defaultSortType = "Recommended";
const alphaSort = (a,b)=>{
  if (a.label > b.label) {
      return 1;
  }
  if (b.label > a.label) {
      return -1;
  }
  return 0;
}

const countSort = ((a,b)=>{
  if(a.count < b.count){
      return 1
  }
  else if( a.count > b.count){
      return -1
  }
  else {
      return 0
  }
})

const versionOptions = [
  {include: true, type: "version", value: "Instrumental", label:"Instrumental"},
  {include: true, type: "version", value: "15 Seconds", label: "15 Seconds"},
  {include: true, type: "version", value: "30 Seconds", label: "30 Seconds"},
  {include: true, type: "version", value: "60 Seconds", label: "60 Seconds"},
  {include: true, type: "version", value: "Alternative Cut", label: "Alternative Cut"},
  {include: true, type: "version", value: "Alternative Cut 2", label: "Alternative Cut 2"},
  {include: true, type: "version", value: "Alternative Cut 3", label: "Alternative Cut 3"},
  {include: true, type: "version", value: "Clean", label: "Clean Version (No Explicit Lyrics)"},
  {include: true, type: "version", value: "Instrumental 15 Seconds", label: "Instrumental 15 Seconds"},
  {include: true, type: "version", value: "Instrumental 30 Seconds", label: "Instrumental 30 Seconds"},
  {include: true, type: "version", value: "Instrumental 60 Seconds", label: "Instrumental 60 Seconds"}
]

class BrowseTracks extends Component {

    state = {
        artists: [],
        featuredArtists: [],
        filteredTracks: {1:[]},
        featuredCollections: [],
        filterMenuOpen: false,
        allFilters: [],
        disabledFilters: [],
        genreOptions: [],
        emotionOptions: [],
        moodOptions: [],
        trackOptions: [{"type":"track",}],
        collectionOptions: [{"type":"collection",}],
        attributeOptions: [],
        instrumentOptions: [],
        keywordOptions: [],
        lastKeywordSearched: "",
        cityOptions: [],
        searchOptions: [],
        view: 'list',
        sortCriteria: defaultSortType,
        playingArtistId: undefined,
        currentPage: 1,
        projects: [],
        tracksLoaded: false,
        lengthFilterValue: [0,720],
        isLengthRangeEnabled: false,
        showMoreCities: false
    }
    changeView = (view)=>{
        if(view !== this.state.view){

            this.setState({view: view})
        }
    }
    toggleShowMoreCities= (doShow)=>{
        this.setState({showMoreCities: doShow})
    }

    setEligibleFilters(eligibleFilters, disabledFilters) {
      let genreOptions = []
      let emotionOptions = []
      let moodOptions = []
      let attributeOptions = []
      let instrumentOptions = []
      let cityOptions = []
      let lyricOptions = []
      let bmpOptions = []
      eligibleFilters.forEach(trackTag => {
        let tag = trackTag.tag
        switch(tag.type){
            case 1:
                genreOptions.push({
                    type: "genre",
                    value: tag.id,
                    label: tag.name,
                    id: tag.id,
                    include: true
            })
                break;
            case 2:
                emotionOptions.push({
                    type: "emotion",
                    value: tag.id,
                    label: tag.name,
                    id: tag.id,
                    include: true
                })
                break;
            case 3:
                moodOptions.push({
                    type: "mood",
                    value: tag.id,
                    label: tag.name,
                    id: tag.id,
                    include: true
                })
                break;
            case 4:
                attributeOptions.push({
                    type: "attribute",
                    value: tag.id,
                    label: tag.name,
                    id: tag.id,
                    include: true
                })
                break;
            case 5:
                instrumentOptions.push({
                    type: "instrument",
                    value: tag.id,
                    label: tag.name,
                    id: tag.id,
                    include: true
                })
                break;
            case 6:
                cityOptions.push({
                    type: "city",
                    value: tag.id,
                    label: tag.name,
                    id: tag.id,
                    include: true,
                    count: trackTag.usedCount
                })

                break;
            case 7:
                lyricOptions.push({
                    type: 'lyric',
                    value: tag.id,
                    label: tag.name,
                    id: tag.id,
                    include: true
                })
                break;
            case 8:
                bmpOptions.push({
                    type: 'bpm',
                    value: tag.id,
                    label: tag.name,
                    id: tag.id,
                    include: true
                })
                break;
            default:
                break;
        }
      })

      const { disabledFiltersOptions } = this.prepDisabledFilters(disabledFilters);

      this.setState({
        genreOptions: genreOptions.sort(alphaSort),
        emotionOptions: emotionOptions.sort(alphaSort),
        cityOptions: cityOptions.sort(countSort),
        moodOptions: moodOptions.sort(alphaSort),
        attributeOptions: attributeOptions.sort(alphaSort),
        instrumentOptions: instrumentOptions.sort(alphaSort),
        cityOptions: cityOptions,
        versionOptions: versionOptions.sort(alphaSort),
        allFilters: genreOptions.concat(emotionOptions.sort(alphaSort),
          moodOptions.sort(alphaSort),
          attributeOptions.sort(alphaSort),
          instrumentOptions.sort(alphaSort),
          cityOptions.sort(countSort),
          lyricOptions.sort(alphaSort),
          bmpOptions.sort(alphaSort),
          versionOptions
        ),
        disabledFilters: disabledFiltersOptions
      })
    }

    prepDisabledFilters(disabledFilters) {
      let genreOptions = []
      let emotionOptions = []
      let moodOptions = []
      let attributeOptions = []
      let instrumentOptions = []
      let cityOptions = []
      let lyricOptions = []
      let bmpOptions = []
      disabledFilters.forEach(trackTag => {
        let tag = trackTag.tag
        switch(tag.type){
            case 1:
                genreOptions.push({
                    type: "genre",
                    value: tag.id,
                    label: tag.name,
                    id: tag.id,
                    include: true
            })
                break;
            case 2:
                emotionOptions.push({
                    type: "emotion",
                    value: tag.id,
                    label: tag.name,
                    id: tag.id,
                    include: true
                })
                break;
            case 3:
                moodOptions.push({
                    type: "mood",
                    value: tag.id,
                    label: tag.name,
                    id: tag.id,
                    include: true
                })
                break;
            case 4:
                attributeOptions.push({
                    type: "attribute",
                    value: tag.id,
                    label: tag.name,
                    id: tag.id,
                    include: true
                })
                break;
            case 5:
                instrumentOptions.push({
                    type: "instrument",
                    value: tag.id,
                    label: tag.name,
                    id: tag.id,
                    include: true
                })
                break;
            case 6:
                cityOptions.push({
                    type: "city",
                    value: tag.id,
                    label: tag.name,
                    id: tag.id,
                    include: true,
                    count: trackTag.usedCount
                })

                break;
            case 7:
                lyricOptions.push({
                    type: 'lyric',
                    value: tag.id,
                    label: tag.name,
                    id: tag.id,
                    include: true
                })
                break;
            case 8:
                bmpOptions.push({
                    type: 'bpm',
                    value: tag.id,
                    label: tag.name,
                    id: tag.id,
                    include: true
                })
                break;
            default:
                break;
        }
      })

      return {
        disabledFiltersOptions: genreOptions.sort(alphaSort)
          .concat(
            emotionOptions.sort(alphaSort),
            moodOptions.sort(alphaSort),
            attributeOptions.sort(alphaSort),
            instrumentOptions.sort(alphaSort),
            cityOptions.sort(countSort),
            lyricOptions.sort(alphaSort),
            bmpOptions.sort(alphaSort)
          )
      }
    }

    loadOptions = (searchInput, options)=>{
        if(searchInput?.trim().length > 2){
            let regex = new RegExp('^' + searchInput.toLowerCase())
          let filteredFilters = this.state.eligibleFilters?.length ? (
            this.state.eligibleFilters.filter(option => regex.test(option.label.toLowerCase()))
          ) : (
            this.state.allFilters.filter(option => regex.test(option.label.toLowerCase()))
          )
            let genreOptions = filteredFilters.filter(option=> option.type === "genre")
            let moodOptions = filteredFilters.filter(option=> option.type === "mood")
            let emotionOptions = filteredFilters.filter(option=> option.type === "emotion")
            let attributeOptions = filteredFilters.filter(option=> option.type === "attribute")
            let lyricOptions = filteredFilters.filter(option=> option.type === "lyric")
            let bpmOptions = filteredFilters.filter(option=> option.type === "bpm")
            let cityOptions = filteredFilters.filter(option=> option.type === "city")
            let instrumentOptions = filteredFilters.filter(option=> option.type === "instrument")
            return (

                // ()=>{
                    api.getTracksByName(searchInput)
                        .then(res=>{
                            let trackOptions = []
                            let collectionOptions =[]
                            let artistOptions = []
                            let keywordOptions = []
                            if(res.data.success){
                                if(res.data.tracks?.length > 0){
                                    trackOptions = res.data.tracks.map(track=>{
                                        let trackOption = {
                                            label: track.trackTitle,
                                            value: track.trackTitle,
                                            include: true,
                                            type: "track",
                                            profilePhoto: track.imageURL,
                                            trackURL: track.trackURL
                                        }
                                        return trackOption
                                    })
                                }
                            }
                                return api.getCollectionsByName(searchInput)
                                    .then(res=>{
                                        if(res.data.success){
                                            if(res.data.collections?.length > 0){
                                                collectionOptions = res.data.collections.map(collection=>{
                                                    let collectionOption = {
                                                        label: collection.name,
                                                        value: collection.id,
                                                        include: true,
                                                        type: "collection",
                                                        profilePhoto: collection.cardArt,
                                                        collectionURL: collection.collectionURL
                                                    }
                                                    return collectionOption
                                                })
                                            }
                                            }
                                            return api.getArtistsByName(searchInput)
                                            .then(res=>{
                                                if(res.data.success){
                                                    if(res.data.artists?.length > 0){
                                                        artistOptions = res.data.artists.map(artist=>{
                                                            let artistOption ={
                                                                label: artist.artistName,
                                                                value: artist.artistURL,
                                                                include: true,
                                                                type: "artist",
                                                                profilePhoto: artist.profilePhoto
                                                            }
                                                            return artistOption
                                                        })
                                                    }

                                                }
                                                //    return  api.getKeywords(searchInput)
                                                // .then(res=>{
                                                //     if(res.data?.keywords?.length > 0){
                                                //         keywordOptions = res.data.keywords.map(keyword=>{
                                                //             let keywordOption ={
                                                //                 label: keyword.name,
                                                //                 value: keyword.name,
                                                //                 include: true,
                                                //                 type: "keyword",
                                                //             }
                                                //             return keywordOption
                                                //         })
                                                //         // this.setState({keywordOptions: keywordOptions.length > 0 ? keywordOptions: [{"include":true,"type":"keyword",}] , lastKeywordSearched: input})
                                                //     }
                                                return( [
                                                  { label: "Emotions", options: emotionOptions },
                                                  { label: "Genres", options: genreOptions },
                                                  { label: "Moods", options: moodOptions },
                                                  {
                                                    label: "attributes",
                                                    options: attributeOptions
                                                  },
                                                  {
                                                    label: "lyrics",
                                                    options: lyricOptions
                                                  },
                                                  {
                                                    label: "bpm",
                                                    options: bpmOptions
                                                  },
                                                  {
                                                    label: "instruments",
                                                    options: instrumentOptions
                                                  },
                                                  {
                                                    label: "cities",
                                                    options: cityOptions
                                                  },
                                                  // {
                                                  //   label: "keywords",
                                                  //   options: keywordOptions
                                                  // },
                                                  { label: "tracks", options: trackOptions },
                                                  {
                                                    label: "collections",
                                                    options: collectionOptions
                                                  },
                                                  {
                                                    label: "artists",
                                                    options: artistOptions
                                                  }

                                                ])
                                            // })
                                            })

                                            // this.setState({collectionOptions: collectionOptions.length > 0 ? collectionOptions: [{"type":"collection",}] , lastCollectionSearched: name})

                                    })






                                // this.setState({trackOptions: trackOptions.length > 0 ? trackOptions: [{"type":"track",}] , lastTrackSearched: name})

                        })
                    // api.getCollectionsByName(searchInput)
                    //     .then(res=>{

                    //     })
                    // }
            )
        }
    }
    getTrackOptions = (name)=>{
        if(name=== this.state.lastTrackSearched){
            return
        }
        let trackOptions = []
        api.getTracksByName(name)
            .then(res=>{
                if(res.data.success){
                    if(res.data.tracks?.length > 0){
                        res.data.tracks.forEach(track=>{
                            trackOptions.push({
                                label: track.trackTitle,
                                value: track.trackTitle,
                                include: true,
                                type: "track",
                                profilePhoto: track.imageURL,
                                trackURL: track.trackURL
                            })
                        })
                    }

                    this.setState({trackOptions: trackOptions.length > 0 ? trackOptions: [{"type":"track",}] , lastTrackSearched: name})
                }
            })
    }
    getCollectionOptions = (name)=>{
        if(name=== this.state.lastCollectionSearched){
            return
        }
        let collectionOptions = []
        api.getCollectionsByName(name)
            .then(res=>{
                if(res.data.success){
                    if(res.data.collections?.length > 0){
                        res.data.collections.forEach(collection => {
                          console.log('collection option', collection);

                            collectionOptions.push({
                                label: collection.name,
                                value: collection.id,
                                include: true,
                                type: "collection",
                                profilePhoto: collection.cardArt,
                                collectionURL: collection.collectionURL
                            })
                        })
                    }

                    this.setState({collectionOptions: collectionOptions.length > 0 ? collectionOptions: [{"type":"collection",}] , lastCollectionSearched: name})
                }
            })
    }
    getArtistOptions = (name)=>{
        if(name=== this.state.lastNameSearched){
            return
        }
        let artistOptions = []
        api.getArtistsByName(name)
            .then(res=>{
                if(res.data.success){
                    if(res.data.artists?.length > 0){
                        res.data.artists.forEach(artist=>{
                            artistOptions.push({
                                label: artist.artistName,
                                value: artist.artistName,
                                include: true,
                                type: "artist",
                                profilePhoto: artist.profilePhoto
                            })
                        })
                    }

                    this.setState({artistOptions: artistOptions.length > 0 ? artistOptions: [{"type":"artist",}] , lastNameSearched: name})
                }
            })
    }
    getKeywordOptions = (input)=>{
        if(input=== this.state.lastKeywordSearched){
            return
        }
        let keywordOptions = []
        keywordOptions.push({

            include: true,
            type: "keyword",
        })
        api.getKeywords(input)
            .then(res=>{
                if(res.data?.keywords?.length > 0){
                    res.data.keywords.forEach(keyword=>{
                        keywordOptions.push({
                            label: keyword.name,
                            value: keyword.name,
                            include: true,
                            type: "keyword",
                        })
                    })
                    this.setState({keywordOptions: keywordOptions.length > 0 ? keywordOptions: [{"include":true,"type":"keyword",}] , lastKeywordSearched: input})
                }
            })
    }
    componentDidMount = ()=>{
        let  trackOptions = []
        trackOptions.push({

            type: "track",
        })
        // let parsedQuery = queryString.parse(this.props.location.search)
        //
        let query = this.props.location.search.length > 0 ? this.props.location.search.substring(1) : ""
        let parsedQuery = queryStringer.parse(query);
        let searchOptions =[]
        let stemsRequired = false
        let isLengthRangeEnabled = false
        let trackSearched
        let genreOptions = []
        let emotionOptions = []
        let moodOptions = []
        let attributeOptions = []
        let instrumentOptions = []
        let cityOptions = []
        let lyricOptions = []
        let bmpOptions = []
        let artistIncluded;
        let artistQuery

        // TKTKTK
        api.getUsedTags()
        .then(res=>{
            if(res.data){
                res.data.forEach(trackTag=>{
                    let tag = trackTag.tag
                    switch(tag.type){
                        case 1:
                            genreOptions.push({
                                type: "genre",
                                value: tag.id,
                                label: tag.name,
                                id: tag.id,
                                include: true
                        })
                            break;
                        case 2:
                            emotionOptions.push({
                                type: "emotion",
                                value: tag.id,
                                label: tag.name,
                                id: tag.id,
                                include: true
                            })
                            break;
                        case 3:
                            moodOptions.push({
                                type: "mood",
                                value: tag.id,
                                label: tag.name,
                                id: tag.id,
                                include: true
                            })
                            break;
                        case 4:
                            attributeOptions.push({
                                type: "attribute",
                                value: tag.id,
                                label: tag.name,
                                id: tag.id,
                                include: true
                            })
                            break;
                        case 5:
                            instrumentOptions.push({
                                type: "instrument",
                                value: tag.id,
                                label: tag.name,
                                id: tag.id,
                                include: true
                            })
                            break;
                        case 6:
                            cityOptions.push({
                                type: "city",
                                value: tag.id,
                                label: tag.name,
                                id: tag.id,
                                include: true,
                                count: trackTag.usedCount
                            })

                            break;
                        case 7:
                            lyricOptions.push({
                                type: 'lyric',
                                value: tag.id,
                                label: tag.name,
                                id: tag.id,
                                include: true
                            })
                            break;
                        case 8:
                            bmpOptions.push({
                                type: 'bpm',
                                value: tag.id,
                                label: tag.name,
                                id: tag.id,
                                include: true
                            })
                            break;
                        default:
                            break;
                    }
                })
            }


        // console.log("parsedQuery", parsedQuery);

        const searchCriteriaKeys = Object.keys(parsedQuery).filter(key => {
          return key !== 'page';
        })

        // console.log("searchCriteriaKeys", searchCriteriaKeys);

        if (searchCriteriaKeys.length) {
          let criteria;
          if (isSmallScreen) {
            criteria = "Relevant";
          }
          else {
              criteria = "Most Relevant";
          }
          this.setState({sortCriteria: criteria});
        }

        for (const key in parsedQuery) {
            switch(key) {
                case "inc_gen":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "genre", label: genreOptions.filter(genre=>genre.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key]), include: true})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "genre", label: genreOptions.filter(genre=>genre.id=== parseInt(q))[0].label, value: parseInt(q), include: true})
                        })
                    }
                }
                break;
                case "exc_gen":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "genre", label: genreOptions.filter(genre=>genre.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key])})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "genre", label: genreOptions.filter(genre=>genre.id=== parseInt(q))[0].label, value: parseInt(q)})
                        })
                    }
                }
                break;
                case "inc_moo":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "mood", label: moodOptions.filter(mood=>mood.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key]), include: true})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "mood",label: moodOptions.filter(mood=>mood.id=== parseInt(q))[0].label, value: parseInt(q), include: true})
                        })
                    }
                }
                break;
                case "exc_moo":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "mood", label: moodOptions.filter(mood=>mood.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key])})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "mood", label: moodOptions.filter(mood=>mood.id=== parseInt(q))[0].label,  value: parseInt(q)})
                        })
                    }
                }
                break;
                case "inc_emo":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "emotion", label: emotionOptions.filter(emotion=>emotion.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key]), include: true})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "emotion",label: emotionOptions.filter(emotion=>emotion.id=== parseInt(q))[0].label, value: parseInt(q), include: true})
                        })
                    }
                }
                break;
                case "exc_emo":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "emotion", label: emotionOptions.filter(emotion=>emotion.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key])})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "emotion", label: emotionOptions.filter(emotion=>emotion.id=== parseInt(q))[0].label,  value: parseInt(q)})
                        })
                    }
                }
                break;
                case "inc_att":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "attribute", label: attributeOptions.filter(attribute=>attribute.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key]), include: true})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "attribute", label: attributeOptions.filter(attribute=>attribute.id=== parseInt(q))[0].label,  value: parseInt(q), include: true})
                        })
                    }
                }
                break;
                case "exc_att":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "attribute", label: attributeOptions.filter(attribute=>attribute.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key])})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "attribute",label: attributeOptions.filter(attribute=>attribute.id=== parseInt(q))[0].label,  value: parseInt(q)})
                        })
                    }
                }
                break;
                case "lyrics":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "lyric", label: lyricOptions.filter(lyric=>lyric.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key]), include: true})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "lyric", label: lyricOptions.filter(lyric=>lyric.id=== parseInt(q))[0].label, value: parseInt(q), include: true})
                        })
                    }
                }
                break;
                case "exc_lyrics":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "lyric", label: lyricOptions.filter(lyric=>lyric.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key])})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "lyric", label: lyricOptions.filter(lyric=>lyric.id=== parseInt(q))[0].label,  value: parseInt(q)})
                        })
                    }
                }
                break;
                case "inc_bmi":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "bmi", label: bmpOptions.filter(bmpOption=>bmpOption.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key]), include: true})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "bmi", label: bmpOptions.filter(bmpOption=>bmpOption.id=== parseInt(q))[0].label,  value: parseInt(q), include: true})
                        })
                    }
                }
                break;
                case "exc_bmi":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "bmi", label: bmpOptions.filter(bmpOption=>bmpOption.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key]),})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "bmi", label: bmpOptions.filter(bmpOption=>bmpOption.id=== parseInt(q))[0].label,  value: parseInt(q)})
                        })
                    }
                }
                break;
                case "inc_ins":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "ins", label: instrumentOptions.filter(insOption=>insOption.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key]), include: true})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "ins", label: instrumentOptions.filter(insOption=>insOption.id=== parseInt(q))[0].label,  value: parseInt(q), include: true})
                        })
                    }
                }
                break;
                case "exc_ins":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "ins", label: instrumentOptions.filter(insOption=>insOption.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key]),})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "ins", label: instrumentOptions.filter(insOption=>insOption.id=== parseInt(q))[0].label,  value: parseInt(q)})
                        })
                    }
                }
                break;
                case "inc_reg":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "city", label: cityOptions.filter(city=>city.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key]), include: true})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "city", label: cityOptions.filter(city=>city.id=== parseInt(q))[0].label,  value: parseInt(q), include: true})
                        })
                    }
                }
                break;
                case "exc_reg":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "city", label: cityOptions.filter(city=>city.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key])})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "city", label: cityOptions.filter(city=>city.id=== parseInt(q))[0].label,  value: parseInt(q)})
                        })
                    }
                }
                break;
                case "inc_key":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "keyword", label: parsedQuery[key], value: parsedQuery[key], include: true})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "keyword", label: q, value: q, include: true})
                        })
                    }
                }
                break;
                case "vers":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "vers", label: parsedQuery[key], value: parsedQuery[key], include: true})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "vers", label: q, value: q, include: true})
                        })
                    }
                }
                break;
                case "stems":{
                    stemsRequired = parsedQuery[key] === "true" ? true: false
                    searchOptions.push({type: "stems", required: true, include: true})
                }
                break;
                case "trackLength":{
                    isLengthRangeEnabled = true
                    let lowerBound = parsedQuery[key].split("-")[0]
                    let upperBound = parsedQuery[key].split("-")[1]
                    // let parsedLowerBound = moment.duration(Math.floor(lowerBound), 'seconds').format("m:ss",{forceLength: true, trim: false})
                    // let parsedUpperBound = moment.duration(Math.floor(upperBound), 'seconds').format("m:ss",{forceLength: true, trim: false})
                    let lowerBoundToSecs = parseInt(lowerBound.split(":")[0]) * 60 + parseInt(lowerBound.split(":")[1])
                    let upperBoundToSecs = parseInt(upperBound.split(":")[0]) * 60 + parseInt(upperBound.split(":")[1])
                    this.setState({lengthFilterValue: [lowerBoundToSecs, upperBoundToSecs]})
                    searchOptions.push({type: "trackLength", label:`${lowerBound}-${upperBound}`, value: `${lowerBound}-${upperBound}`, include: true })
                    // this.setState({is})
                }
                break;
                case "track":{
                   trackSearched = true
                }
                case "artist":{

                    artistIncluded = true
                    artistQuery =  parsedQuery[key]

                }
                case "sort":{
                    let criteria;
                    switch(parsedQuery[key]){
                        case "Rel":
                            if(isSmallScreen){
                                criteria = "Relevant"
                            }
                            else{
                                criteria = "Most Relevant"
                            }

                            break;
                        case "Rec":
                            if(isSmallScreen){
                                criteria = "Recent"
                            }
                            else{
                                criteria = "Recently Added"
                            }
                            break;
                        case "A-Z":
                            if(isSmallScreen){
                                criteria = "Name - A"
                            }
                            else{
                                criteria = "Name - Asc"
                            }
                            break;
                        case "Z-A":
                            if(isSmallScreen){
                                criteria = "Name - D"
                            }
                            else{
                                criteria = "Name - Desc"
                            }
                            break;
                    }
                    this.setState({sortCriteria: criteria})
                }
                break;
            }
        }

        this.setState({genreOptions: genreOptions.sort(alphaSort), emotionOptions: emotionOptions.sort(alphaSort), cityOptions: cityOptions.sort(countSort), moodOptions: moodOptions.sort(alphaSort), attributeOptions: attributeOptions.sort(alphaSort),
        instrumentOptions: instrumentOptions.sort(alphaSort), cityOptions: cityOptions, versionOptions: versionOptions.sort(alphaSort), allFilters: genreOptions.concat(emotionOptions.sort(alphaSort), moodOptions.sort(alphaSort), attributeOptions.sort(alphaSort), instrumentOptions.sort(alphaSort), cityOptions.sort(countSort),lyricOptions.sort(alphaSort), bmpOptions.sort(alphaSort), versionOptions)})
        })

        api.getFeaturedCollections()
            .then(res=>{
                if(res.data.collections.length > 0){
                    this.setState({featuredCollections: res.data.collections, initialFeaturedLoaded: true})
                }
            })
            let artistOptions = []
            artistOptions.push({
                type: "artist",
            })
            let keywordOptions = []
            keywordOptions.push({

                include: true,
                type:"keyword",

            })
        //
        api.getAllTracks(query)
            .then(res => {
                if(res.data.success){

                  let tracks = [...res.data.tracks]
                  if (res.data.eligibleFilters?.length) {
                    const eligibleFilters = [...res.data.eligibleFilters];
                    const disabledFilters = [...res.data.disabledFilters];
                    this.setEligibleFilters(eligibleFilters, disabledFilters);
                  }

                    this.context.getTracksInfo(tracks)
                    if(trackSearched){
                        searchOptions.push({
                            include: true,
                            label: tracks[0].trackTitle,
                            profilePhoto: tracks[0].imageURL,
                            type: "track",
                            value: tracks[0].trackTitle
                        })
                    }
                    let indexOfArtistTagInQ = query.indexOf('artist=')

                    this.context.setPlaylist(tracks);





                    if(artistIncluded){
                        let artistPhoto
                        let artistName
                        api.getArtistPhotoFromURL(artistQuery)
                            .then(resp=>{
                                if(resp.data.success){
                                    artistPhoto = resp.data?.artist?.profilePhoto
                                    artistName = resp.data?.artist?.artistName
                                }
                                searchOptions.unshift({type: "artist", label: artistName, value: artistQuery, include: true, profilePhoto: artistPhoto})
                                let temp = searchOptions[0]
                                let indexOfArtistSearchOption = searchOptions.findIndex(serOp=>{
                                    return serOp.type === "artist"})
                                if(indexOfArtistSearchOption !== -1 && indexOfArtistSearchOption !== 0){
                                    searchOptions[0] = searchOptions[indexOfArtistSearchOption]
                                    searchOptions[indexOfArtistSearchOption] = temp
                                    }
                                    this.setState({tracks: [...tracks], filteredTracks: {1:[...tracks]}, totalMatchingTracks: res.data.totalMatchingTracks, tracksLoaded: true, searchOptions: searchOptions, stemsRequired: stemsRequired, isLengthRangeEnabled: isLengthRangeEnabled, trackOptions: trackOptions, artistOptions: artistOptions, keywordOptions: keywordOptions}, ()=>{
                                    })
                                })

                    }
                    else{
                        this.setState({tracks: [...tracks], filteredTracks: {1:[...tracks]}, totalMatchingTracks: res.data.totalMatchingTracks, tracksLoaded: true, searchOptions: searchOptions, stemsRequired: stemsRequired, isLengthRangeEnabled: isLengthRangeEnabled, trackOptions: trackOptions, artistOptions: artistOptions, keywordOptions: keywordOptions}, ()=>{
                        })
                    }
                }
            })

    }
    playTracks = (tracks, projectId)=>{
        if((this.context?.mediaPlayer.idKey?.indexOf(`projectListCard-${projectId}`) || -1) ===-1){
            let playList = tracks
            this.context.setPlaylist(playList)
            this.context.loadTrackInMediaPlayer(playList[0], `artist-${this.context.loadedTracks[playList[0]]?.artistId}-album-${this.context.loadedTracks[playList[0]]?.albumId}-track-${playList[0]}-element-projectListCard-${projectId}`)
            this.context.setAutoPlay(true)
            let query = this.props.location.search.length > 0 ? this.props.location.search.substring(1) : ""
            let parsedQuery = queryStringer.parse(query)
            if(parsedQuery.searchId){
                let searchBody = {
                    type: "playTrack",
                    trackId: playList[0]
                }
                api.saveActionToSearch(parseInt(parsedQuery.searchId), {action: searchBody})
                    .then(res=>{})
            }
        }
        else{
            this.context.playPause()
        }
    }
    pauseTrack = ()=>{
        this.context.playPause();
    }

    toggleFilterMenu = ()=>{
        this.setState({filterMenuOpen: !this.state.filterMenuOpen})
    }
    toggleMobileFilter = (filter)=>{
        this.setState({[`isMobile${filter}Open`]: !this.state[`isMobile${filter}Open`]})
    }
    toggleFilter = (filter)=>{
        this.setState({[`is${filter}Open`]: !this.state[`is${filter}Open`]})
    }
    toggleSortTippy = ()=>{
        this.setState({isSortTippyOpen: !this.state.isSortTippyOpen})
    }
    toggleArtistCardTippy = (artistId)=>{
        this.setState({[`isArtistCardTippyOpen${artistId}`]: !this.state[`isArtistCardTippyOpen${artistId}`]})
    }
    toggleFeaturedCardTippy = (artistId)=>{
        this.setState({[`isFeaturedCardTippyOpen${artistId}`]: !this.state[`isFeaturedCardTippyOpen${artistId}`]})
    }
    shareArtist = (artistId, artistURL)=>{
        var textArea = document.createElement("textarea");

        // Place in top-left corner of screen regardless of scroll position.
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em';
        textArea.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = 0;

        // Clean up any borders.
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';

        // Avoid flash of white box if rendered for any reason.
        textArea.style.background = 'transparent';

        if(window.location.port){
            textArea.value = window.location.protocol + window.location.hostname + ":" + window.location.port + "/artists/" +artistURL;
        }
        else{
            textArea.value = window.location.protocol + window.location.hostname + "/artists/" +artistURL;
        }

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          var successful = document.execCommand('copy');
          if(successful){
              Success({message: "Artist URL Copied to Clipboard"})
          }
          else{
              Error({message: "Couldn't Copy Artist URL "})
          }
          var msg = successful ? 'successful' : 'unsuccessful';
        } catch (err) {
        }

        document.body.removeChild(textArea);

        this.setState({[`isArtistCardTippyOpen${artistId}`]: false})

    }
    shareFeatured = (artistId, artistURL)=>{
        var textArea = document.createElement("textarea");

        // Place in top-left corner of screen regardless of scroll position.
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em';
        textArea.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = 0;

        // Clean up any borders.
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';

        // Avoid flash of white box if rendered for any reason.
        textArea.style.background = 'transparent';

        if(window.location.port){
            textArea.value = window.location.protocol + window.location.hostname + ":" + window.location.port + "/artists/" +artistURL;
        }
        else{
            textArea.value = window.location.protocol + window.location.hostname + "/artists/" +artistURL;
        }

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          var successful = document.execCommand('copy');
          if(successful){
              Success({message: "Artist URL Copied to Clipboard"})
          }
          else{
              Error({message: "Couldn't Copy Artist URL "})
          }
          var msg = successful ? 'successful' : 'unsuccessful';
        } catch (err) {
        }

        document.body.removeChild(textArea);

        this.setState({[`isFeaturedCardTippyOpen${artistId}`]: false})

    }
    // sortBy = (criteria, overRide) => {
    //     if (criteria !== this.state.sortCriteria || overRide) {
    //         let unsortedArtist = []
    //         let sorted
    //         let compare
    //         let query = this.props.location.search.length > 0 ? this.props.location.search.substring(1) : ""
    //         query = query.replace(/\&sort\=Rel/g, "")
    //         query = query.replace(/sort\=Rel/g, "")
    //         query = query.replace(/\&sort\=Rec/g, "")
    //         query = query.replace(/sort\=Rec/g, "")
    //         query = query.replace(/\&sort\=A-Z/g, "")
    //         query = query.replace(/sort\=A-Z/g, "")
    //         query = query.replace(/\&sort\=Z-A/g, "")
    //         query = query.replace(/sort\=Z-A/g, "")
    //       switch (criteria) {
    //         case "Recommended":
    //           if(query.length > 0){
    //             query = query.replace(/\&sort\=Recommended/g, "")
    //             query = query.replace(/sort\=Recommended/g, "")
    //             if(query.length > 0){
    //               query+= "&sort=" + "Recommended"
    //             }
    //             else{
    //               query+= "sort=" + "Recommended"
    //             }
    //           }
    //           else {
    //             query+= "sort=" + "Recommended"
    //           }
    //           break;
    //         case "Most Relevant":
    //         case "Relevant":
    //             if(query.length > 0){
    //                 query = query.replace(/\&sort\=Rel/g, "")
    //                 query = query.replace(/sort\=Rel/g, "")
    //                 if(query.length > 0){
    //                     query+= "&sort=" + "Rel"
    //                 }
    //                 else{
    //                     query+= "sort=" + "Rel"
    //                 }
    //             }
    //             else{
    //                 query+= "sort=" + "Rel"
    //             }

    //             if(isSmallScreen){
    //                 criteria = "Relevant"
    //             }
    //             break;
    //         case "Recently Added":
    //         case "Recent":
    //             if(query.length > 0){
    //                 query = query.replace(/\&sort\=Rec/g, "")
    //                 query = query.replace(/sort\=Rec/g, "")
    //                 if(query.length > 0){
    //                     query+= "&sort=" + "Rec"
    //                 }
    //                 else{
    //                     query+= "sort=" + "Rec"
    //                 }
    //             }
    //             else{
    //                 query+= "sort=" + "Rec"
    //             }

    //             if(isSmallScreen){
    //                 criteria = "Recent"
    //             }
    //             break;
    //         case "Name - Asc":
    //         case "Name - A":
    //             if(query.length > 0){
    //                 query = query.replace(/\&sort\=A-Z/g, "")
    //                 query = query.replace(/sort\=A-Z/g, "")
    //                 if(query.length > 0){
    //                     query+= "&sort=" + "A-Z"
    //                 }
    //                 else{
    //                     query+= "sort=" + "A-Z"
    //                 }
    //             }
    //             else{
    //                 query+= "sort=" + "A-Z"
    //             }

    //             if(isSmallScreen){
    //                 criteria = "Name - A"
    //             }
    //             break;
    //         case "Name - Desc":
    //         case "Name - D":
    //             if(query.length > 0){
    //                 query = query.replace(/\&sort\=Z-A/g, "")
    //                 query = query.replace(/sort\=Z-A/g, "")
    //                 if(query.length > 0){
    //                     query+= "&sort=" + "Z-A"
    //                 }
    //                 else{
    //                     query+= "sort=" + "Z-A"
    //                 }
    //             }
    //             else{
    //                 query+= "sort=" + "Z-A"
    //             }

    //             if(isSmallScreen){
    //                 criteria = "Name - D"
    //             }
    //             break;
    //         default:
    //             if(query.length > 0){
    //                 query = query.replace(/\&sort\=Rel/g, "")
    //                 query = query.replace(/sort\=Rel/g, "")
    //                 if(query.length > 0){
    //                     query+= "&sort=" + "Recommended"
    //                 }
    //                 else{
    //                     query+= "sort=" + "Recommended"
    //                 }
    //             }
    //             else{
    //                 query+= "sort=" + "Recommended"
    //             }
    //             break;
    //         }
    //         query = query.replace(/\&page\=\d/g, "")
    //         query = query.replace(/page\=\d/g, "")
    //         if(query.length > 0){
    //             query+= "&page=" + 1
    //         }
    //         else{
    //             query+= "page=" + 1
    //         }
    //         this.props.history.push({
    //             search: query,
    //         })
    //         this.setState({
    //           sortCriteria: criteria,
    //           isSortTippyOpen: false,
    //           currentPage: 1,
    //           tracksLoaded: false
    //         }, () => {
    //             api.getAllTracks(query)
    //             .then(res=>{
    //                 if(res.data){
    //                     let tracks = res.data.tracks
    //                     this.context.getTracksInfo(tracks)
    //                     this.context.setPlaylist(tracks)
    //                     this.setState({filteredTracks: {1: tracks}, totalMatchingTracks: res.data.totalMatchingTracks, tracksLoaded: true})
    //                 }
    //             })
    //         })
    //     }
    //     else {
    //         this.setState({isSortTippyOpen: false, currentPage: 1})
    //     }
    // }
    addSearchOption = (label, value, type, id)=>{
      let searchOptions = [...this.state.searchOptions];
      switch (type) {
        case "lyric":
            let existingLyric  = searchOptions.findIndex(searchOption=> searchOption.type === "lyric" && searchOption.include)
            if(existingLyric === -1){
                let removeIndex = searchOptions.findIndex(searchOption => searchOption.value === value)
                searchOptions.splice(removeIndex, 1)
                searchOptions.push({ type: type, label: label, value: value, include: true})
                this.handleSearchChange(searchOptions)
            }
            else{
                if(searchOptions[existingLyric].include && value === searchOptions[existingLyric].value){
                    return
                }
                else{
                    searchOptions.splice(existingLyric, 1, {type: type, label: label, value: value, include: true})
                    let removeIndex = searchOptions.findIndex(searchOption => searchOption.value === value && !searchOption.include)
                    if(removeIndex !== -1){
                        searchOptions.splice(removeIndex, 1)
                    }
                    this.handleSearchChange(searchOptions)
                }
            }
        break;
        case "bpm":
            let existingBpm  = searchOptions.findIndex(searchOption=> searchOption.type === "bpm" && searchOption.include)
            if(existingBpm === -1){
                let removeIndex = searchOptions.findIndex(searchOption => searchOption.value === value)
                if(removeIndex !==-1){
                    searchOptions.splice(removeIndex, 1)
                }
                searchOptions.push({ type: type, label: label, value: value, include: true})
                this.handleSearchChange(searchOptions)
            }
            else{
                if(searchOptions[existingBpm].include && value === searchOptions[existingBpm].value){
                    return
                }
                else{
                    searchOptions.splice(existingBpm, 1, {type: type, label: label, value: value, include: true})
                    let removeIndex = searchOptions.findIndex(searchOption => searchOption.value === value && !searchOption.include)
                    if(removeIndex !== -1){
                        searchOptions.splice(removeIndex, 1)
                    }
                    this.handleSearchChange(searchOptions)
                }
            }
        break;
        case "city":
            //find the index of an included city
            let existingCity  = searchOptions.findIndex(searchOption=> (searchOption.type === "city" && searchOption.include))
            //if there is no included city
            if(existingCity === -1){
                //see if the specific city is already tagged
                let removeIndex = searchOptions.findIndex(searchOption => searchOption.value === value)
                //if so, remove it from the search
                if(removeIndex !== -1){
                    searchOptions.splice(removeIndex, 1)
                }
                searchOptions.push({ type: type, label: label, value: value, include: true})
                this.handleSearchChange(searchOptions)
            }
            else{
                //else test to see if the existing included city was just selected
                if(searchOptions[existingCity].include && value === searchOptions[existingCity].value){
                    return
                }
                //if not
                else{
                    //
                    searchOptions.splice(existingCity, 1, {type: type, label: label, value: value, include: true})
                    let removeIndex = searchOptions.findIndex(searchOption => searchOption.value === value && !searchOption.include)
                    if(removeIndex !== -1){
                        searchOptions.splice(removeIndex, 1)
                    }
                    this.handleSearchChange(searchOptions)
                }
            }
        break;

        case "artist":
            let existingArtist = searchOptions.findIndex(searchOption=> (searchOption.type === "artist"))
            if(existingArtist === -1){
                searchOptions.push({ type: type, label: label, value: value, include: true})
                this.handleSearchChange(searchOptions)
            }else{
                searchOptions.splice(existingArtist, 1, { type: type, label: label, value: value, include: true})
                this.handleSearchChange(searchOptions)
            }
        break;
        case "stems":
            let existingStem = searchOptions.findIndex(searchOption=> searchOption.type === "stems")
            if(existingStem === -1){
                searchOptions.push({type: "stems", required: true})
                this.handleSearchChange(searchOptions)
            }
            else{
                return
            }
            break
        case "trackLength":
                let existingTrackLength = searchOptions.findIndex(searchOption=> searchOption.type === "trackLength")
                if(existingTrackLength === -1){
                    searchOptions.push({type: "trackLength", label: label, value: value, include: true})
                    this.handleSearchChange(searchOptions)
                }
                else{
                    searchOptions[existingTrackLength].value = value;
                    searchOptions[existingTrackLength].label =label;
                    this.handleSearchChange(searchOptions)
                    return
                }
                break
        case "track":
            let trackURL = this.state.trackOptions.filter(opt=> opt.value === value)
            break;
        default:
            let existingIndex = searchOptions.findIndex(searchOption=> searchOption.value === value)

            if(existingIndex === -1){
                searchOptions.push({ type: type, label: label, value: value, include: true})
                this.handleSearchChange(searchOptions)
            }
            else{
                if(searchOptions[existingIndex].include){
                    return
                }
                else{
                    searchOptions[existingIndex].include = true
                    this.handleSearchChange(searchOptions)
                }
            }
      }
    }
    excludeSearchOption = (label, value, type, id)=>{
        let searchOptions = [...this.state.searchOptions];
        let existingIndex = searchOptions.findIndex(searchOption=> searchOption.value === value)
        if(existingIndex === -1){
            searchOptions.push({ type: type, label: label, value: value, include: false})
            this.handleSearchChange(searchOptions)
        }
        else{
            if(!searchOptions[existingIndex].include){
                return
            }
            else{
                searchOptions[existingIndex].include = false
                this.handleSearchChange(searchOptions)
            }
        }
    }

    changeStem = (event)=>{
        if(event.target.checked){
            this.setState({stemsRequired: event.target.checked }, ()=> this.addSearchOption("Has Stems", true, "stems"))

        }
        else{
            this.setState({stemsRequired: event.target.checked }, ()=> this.handleSearchChange(this.state.searchOptions.filter(option=>option.type!=="stems"), true))
        }
    }

    enableLengthRange = (event) => {
        if(event.target.checked){
            this.setState({isLengthRangeEnabled: true},()=>{
                let lowerBound = this.state.lengthFilterValue[0]
                let upperBound = this.state.lengthFilterValue[1]

                let parsedLowerBound = moment.duration(Math.floor(lowerBound), 'seconds').format("m:ss",{forceLength: true, trim: false})
                let parsedUpperBound
                if(upperBound === 720){
                    parsedUpperBound = moment.duration(Math.floor(upperBound), 'seconds').format("m:ss",{forceLength: true, trim: false})
                }
                else{
                    parsedUpperBound = moment.duration(Math.floor(upperBound), 'seconds').format("m:ss",{forceLength: true, trim: false})
                }
                this.addSearchOption(`${parsedLowerBound}-${parsedUpperBound}`, `${parsedLowerBound}-${parsedUpperBound}`,'trackLength')
            })
        }
        else{
            this.setState({isLengthRangeEnabled: false},()=>{
                this.handleSearchChange(this.state.searchOptions.filter(option=>option.type!=="trackLength"))
            })
        }
    }

    handleSearchChange = (selectedSearchOptions) => {
      if (selectedSearchOptions) {
        if (selectedSearchOptions.length > 0) {
          selectedSearchOptions.forEach((option, index)=>{
              if (option.__isNew__){
                selectedSearchOptions[index].type = "keyword";
                selectedSearchOptions[index].include = true;
              }
          })
          let queryString;

          let keywords = selectedSearchOptions.filter((searchOption => searchOption.__isNew__));

          // console.log('Object.keys(selectedSearchOptions)', Object.keys(selectedSearchOptions));

          queryString = Object.keys(selectedSearchOptions).map(key => {
              switch(selectedSearchOptions[key].type) {
                  case "genre":
                      return  (selectedSearchOptions[key].include ? "inc_gen=" : "exc_gen=") + encodeURIComponent(selectedSearchOptions[key].value)
                      break;
                  case "mood":
                      return  (selectedSearchOptions[key].include ? "inc_moo=" : "exc_moo=") + encodeURIComponent(selectedSearchOptions[key].value)
                      break
                  case "emotion":
                      return  (selectedSearchOptions[key].include ? "inc_emo=" : "exc_emo=") + encodeURIComponent(selectedSearchOptions[key].value)
                      break
                  case "attribute":
                      return  (selectedSearchOptions[key].include ? "inc_att=" : "exc_att=") + encodeURIComponent(selectedSearchOptions[key].value)
                      break
                  case "lyric":
                      return  (selectedSearchOptions[key].include ? "lyrics=" : "exc_lyrics=") + encodeURIComponent(selectedSearchOptions[key].value)
                      break
                  case "bpm":
                      return  (selectedSearchOptions[key].include ? "inc_bmi=" : "exc_bmi=") + encodeURIComponent(selectedSearchOptions[key].value)
                      break
                  case "instrument":
                      return  (selectedSearchOptions[key].include ? "inc_ins=" : "exc_ins=") + encodeURIComponent(selectedSearchOptions[key].value)
                      break
                  case "city":
                      return  (selectedSearchOptions[key].include ? "inc_reg=" : "exc_reg=") + encodeURIComponent(selectedSearchOptions[key].value)
                  case "version":
                      return  "vers=" + encodeURIComponent(selectedSearchOptions[key].value)
                      break
                  case "keyword":
                      return "inc_key="+ encodeURIComponent(selectedSearchOptions[key].value)
                      break
                  case "stems":
                      return "stems=true"
                      break
                  case "trackLength":
                      return "trackLength="+encodeURIComponent(selectedSearchOptions[key].value)
                      break;
                  case "track":
                      this.props.history.push({
                          pathname: "/tracks/"+ selectedSearchOptions[key].trackURL
                      })
                      return "track=" + encodeURIComponent(selectedSearchOptions[key].value)
                  case "collection":
                      // if (queryString?.length > 0) {
                      //     queryString = queryString.replace(/\&page\=\d/g, "")
                      //     queryString = queryString.replace(/page\=\d/g, "")
                      //     if(queryString.length > 0){
                      //         queryString+= "&page=" + 1
                      //     }
                      //     else{
                      //         queryString+= "page=" + 1
                      //     }
                      // }
                      // else{
                      //     queryString+= "page=" + 1
                      // }
                      this.props.history.push({
                          pathname: "/collections/" + selectedSearchOptions[key].collectionURL
                      })
                      return "collection=" + encodeURIComponent(selectedSearchOptions[key].collectionURL)
                      break
                  case "artist":
                      return "artist=" + encodeURIComponent(selectedSearchOptions[key].value)
                      break;
                  default:
                    break;
              }
          }).join('&')
          if (queryString.indexOf('track=') !==-1){
              return
          }
          else if (queryString.indexOf('collection=') !==-1){
              return
          }
          else {
              if(queryString.length > 0){
                  queryString = queryString.replace(/\&page\=\d/g, "")
                  queryString = queryString.replace(/page\=\d/g, "")
                  if(queryString.length > 0){
                      queryString+= "&page=" + 1
                  }
                  else{
                      queryString+= "page=" + 1
                  }
              }
              else{
                  queryString+= "page=" + 1
              }
              this.props.history.push({
                  search:queryString
              })
          }
          if (queryString.indexOf("track=") !== -1) {
              return
          }
          // selectedSearchOptions = selectedSearchOptions.filter(option=> option.type !== "stems")

          const stemsRequired = selectedSearchOptions.filter(option => option.type==="stems").length > 0;
          const isLengthRangeEnabled = selectedSearchOptions.filter(option=>option.type==="trackLength").length > 0;

          this.setState({
            searchOptions: selectedSearchOptions,
            tracksLoaded: false,
            stemsRequired,
            isLengthRangeEnabled
          })

          api.getAllTracks(queryString)
              .then(res => {
                let sortCriteria;

                if (selectedSearchOptions.length > 1) {
                    let query = this.props.location.search.length > 0 ? this.props.location.search.substring(1) : ""
                    let parsedQuery = queryStringer.parse(query)
                    let searchBody={
                        tagsIncluded: selectedSearchOptions.filter(selectedOption=>selectedOption.include).map(selected=>selected.value),
                        tagsExcluded: selectedSearchOptions.filter(selectedOption=>!selectedOption.include).map(selected=>selected.value),
                        keywords: keywords.map(key=>key.value),
                        results: res.data.tracks.map(track=>track.trackId),
                        previousSearch: parsedQuery.searchId ? parseInt(parsedQuery.searchId) : null,
                        url: queryString,
                        deviceInfo: deviceDetect(),
                        referrer: this.props.location.state ? this.props.location.state.from : this.props.location.pathname
                    }
                    api.saveNewSearch(searchBody)
                    .then(searchRes=>{
                        if (searchRes.data.success){
                            queryString +=`&searchId=${searchRes.data.search.id}`

                            if(queryString.indexOf('track=') !==-1){
                                api.getTrackInfo(res.data.tracks[0] + res.data.tracks[0])
                                .then(resp=>{
                                    let trackURL = resp.data.tracks[0].trackURL
                                    this.props.history.push({
                                        pathname: "/tracks/"+ trackURL
                                    })
                                })

                            }
                            else{
                                this.props.history.push({
                                    search:queryString,
                                })
                            }
                        }
                    })
                }
                else {
                    if (queryString.indexOf('track=') !== -1) {

                        // api.getTrackInfo(res.data.tracks[0])
                        //         .then(resp=>{
                                    let trackURL = this.state.trackOptions[0].trackURl
                                    this.props.history.push({
                                        pathname: "/tracks/" + trackURL
                                    })
                                // })
                    }
                    else {
                        this.props.history.push({
                            search:queryString,
                        })
                    }
                }

                let tracks = res.data.tracks
                this.context.getTracksInfo(tracks)

                let filteredTracks = {1: tracks}
                this.context.setPlaylist(tracks);

                sortCriteria = defaultSortType;

                this.setState({
                  searchOptions: selectedSearchOptions,
                  filteredTracks: filteredTracks,
                  totalMatchingTracks: res.data.totalMatchingTracks,
                  tracksLoaded: true,
                  currentPage: 1,
                  sortCriteria
                }, () => {
                  if (res.data.eligibleFilters?.length) {
                    const eligibleFilters = [...res.data.eligibleFilters];
                    const disabledFilters = [...res.data.disabledFilters];
                    this.setEligibleFilters(eligibleFilters, disabledFilters);
                  }
                })
              })

        }
        else {
          if(this.state.stemsRequired){
              this.setState({searchOptions: selectedSearchOptions, tracksLoaded: false})
              window.history.pushState([], "Artist Browse","browse?")

              api.getAllTracks()
              .then(res=>{
                  let initialIndexOfNowPlaying = [...this.state.filteredTracks].findIndex(track=>track.trackId === this.context.mediaPlayer.trackId)
                  let initialValue = [...this.state.filteredTracks][initialIndexOfNowPlaying]
                  let tracks = res.data.tracks
                  this.context.getTracksInfo(tracks)

                  let filteredTracks = {1: tracks}
                  this.context.setPlaylist(tracks)
                  this.setState({ filteredTracks: filteredTracks , totalMatchingTracks: res.data.totalMatchingTracks, tracksLoaded: true, currentPage: 1, stemsRequired: false, isLengthRangeEnabled: selectedSearchOptions.filter(option=>option.type==="trackLength").length > 0})
              })
          }
          else {
              selectedSearchOptions = []
              this.setState({searchOptions: selectedSearchOptions, isLengthRangeEnabled: selectedSearchOptions.filter(option=>option.type==="trackLength").length > 0, tracksLoaded: false})
              let queryString = ""
              if(queryString.length > 0){
                  queryString = queryString.replace(/\&page\=\d/g, "")
                  queryString = queryString.replace(/page\=\d/g, "")
                  if(queryString.length > 0){
                      queryString+= "&page=" + 1
                  }
                  else{
                      queryString+= "page=" + 1
                  }
              }
              else{
                  queryString+= "page=" + 1
              }
              this.props.history.push({
                  search:queryString,
              })
              window.history.pushState([], "Artist Browse","browse?")
              api.getAllTracks()
              .then(res => {
                  let tracks = res.data.tracks
                  this.context.getTracksInfo(tracks)

                  this.context.setPlaylist(res.data.tracks)
                  let filteredTracks = {1: res.data.tracks}

                  let sortCriteria = defaultSortType;

                  this.setState({
                    filteredTracks: filteredTracks,
                    totalMatchingTracks: res.data.totalMatchingTracks,
                    tracksLoaded: true,
                    currentPage: 1,
                    sortCriteria
                  })
              })
          }
        }
      }
      else {
          if (this.state.stemsRequired) {
              selectedSearchOptions = []
              this.setState({searchOptions: selectedSearchOptions, tracksLoaded: false})
              window.history.pushState([], "Arist Browse","browse?")
              api.getAllTracks()
              .then(res=>{
                  let tracks = res.data.tracks
                  this.context.getTracksInfo(tracks)
                  let initialIndexOfNowPlaying = [...res.data.tracks].findIndex(track=>track.trackId === this.context.mediaPlayer.trackId)
                  let initialValue = [...res.data.tracks][initialIndexOfNowPlaying]

                  this.context.setPlaylist(tracks)
                  let filteredTracks = {1: tracks}
                  this.setState({filteredTracks: filteredTracks, totalMatchingTracks: res.data.totalMatchingTracks, tracksLoaded: true, currentPage: 1, stemsRequired: false,isLengthRangeEnabled: selectedSearchOptions.filter(option=>option.type==="trackLength").length > 0})
              })
          }
          else {
              selectedSearchOptions = [];
              this.setState({searchOptions: selectedSearchOptions, tracksLoaded: false, isLengthRangeEnabled: selectedSearchOptions.filter(option=>option.type==="trackLength").length > 0})
              window.history.pushState([], "Arist Browse","browse?")
              let queryString =""
                  if(queryString.length > 0){
                      queryString = queryString.replace(/\&page\=\d/g, "")
                      queryString = queryString.replace(/page\=\d/g, "")
                      if(queryString.length > 0){
                          queryString+= "&page=" + 1
                      }
                      else{
                          queryString+= "page=" + 1
                      }
                  }
                  else{
                      queryString+= "page=" + 1
                  }
                  this.props.history.push({
                      search:queryString,
                  })
              api.getAllTracks()
              .then(res=>{
                  let tracks = res.data.tracks
                  this.context.getTracksInfo(tracks)

                  if (res.data.eligibleFilters?.length) {
                    const eligibleFilters = [...res.data.eligibleFilters];
                    const disabledFilters = [...res.data.disabledFilters];
                    this.setEligibleFilters(eligibleFilters, disabledFilters);
                  }

                  this.context.setPlaylist(tracks)
                  let filteredTracks = {1: tracks}

                  let sortCriteria;
                  if (isSmallScreen) {
                    sortCriteria = "Relevant";
                  }
                  else {
                    sortCriteria = "Most Relevant";
                  }

                  this.setState({
                    filteredTracks: filteredTracks,
                    totalMatchingTracks: res.data.totalMatchingTracks,
                    tracksLoaded: true,
                    currentPage: 1,
                    sortCriteria
                  })
              })
          }
      }
    }
    playCB=(idKey, trackId)=>{

        let playList = [...this.state.filteredTracks[this.state.currentPage]]

        let foundIndex = playList.findIndex(filteredTrack=>filteredTrack.idKey === idKey )
        this.context.loadTrackInMediaPlayer(trackId, idKey)
        this.context.setAutoPlay(false)
        this.context.setPlaylist(playList)
    }
    onPageChanged = data => {
        const { filteredTracks } = this.state;
        const { currentPage, totalPages, pageLimit } = data;
        const searchBarEl = document.getElementById("trackSearch")
        searchBarEl.scrollIntoView()
        const offset = (currentPage - 1) * pageLimit;
        // const currentFilteredTracks = filteredTracks.slice(offset, offset + pageLimit);
        this.context.setPlaylist(this.state.filteredTracks[currentPage])
        this.setState({ currentPage,  totalPages });
      }
    gotoPage = (page, totalPages) => {
        // const { onPageChanged = f => f } = this.props;

        const currentPage = Math.max(0, Math.min(page, totalPages));
        let query = this.props.location.search.length > 0 ? this.props.location.search.substring(1) : ""
        if(query.length > 0){
            query = query.replace(/\&page\=\d/g, "")
            query = query.replace(/page\=\d/g, "")
            if(query.length > 0){
                query+= "&page=" + page
            }
            else{
                query+= "page=" + page
            }
        }
        else{
            query+= "page=" + page
        }
        this.props.history.push({
            search: query,
        })
        const paginationData = {
          currentPage,
          totalPages: totalPages,
          pageLimit: 20,
          totalRecords: this.state.totalMatchingTracks
        };
        if(this.state.filteredTracks[currentPage]?.length > 0){
            this.setState({ currentPage }, () => this.onPageChanged(paginationData));
        }
        else{
            this.setState({tracksLoaded: false}, ()=>{
                api.getAllTracks(query)
                    .then(res=>{
                        if(res.data){
                            this.context.getTracksInfo(res.data.tracks)
                            this.setState(prevState=>({...prevState, filteredTracks: {...prevState.filteredTracks, [currentPage]: res.data.tracks}, totalMatchingTracks: res.data.totalMatchingTracks, tracksLoaded: true}),()=>{
                                this.setState({ currentPage }, () => this.onPageChanged(paginationData));
                            })
                        }
                    })
            })
        }
      }


    playCollectionTracks = (tracks, collectionId)=>{
            let playList = tracks.map(track=>track.trackId)
            this.context.setPlaylist(playList)
            this.context.loadTrackInMediaPlayer(playList[0],`artist-${this.context.loadedTracks[playList[0]]?.artistId}-album-${this.context.loadedTracks[playList[0]]?.albumId}-track-${playList[0]}-element-collectionListCard-${collectionId}`)
            this.context.setAutoPlay(true)
    }
    onSliderChange = (value) => {
        this.setState({
          lengthFilterValue: value
        });
      };
    sliderFinished = ()=>{
        let lowerBound = this.state.lengthFilterValue[0]
        let upperBound = this.state.lengthFilterValue[1]
        let parsedLowerBound = moment.duration(Math.floor(lowerBound), 'seconds').format("m:ss",{forceLength: true, trim: false})
        let parsedUpperBound = moment.duration(Math.floor(upperBound), 'seconds').format("m:ss",{forceLength: true, trim: false})
        this.addSearchOption(`${parsedLowerBound}-${parsedUpperBound}`, `${parsedLowerBound}-${parsedUpperBound}`,'trackLength')
    }
    fixSlider =()=>{
        if(this.slider){
            setTimeout( this.slider.slickGoTo(0),1500)
        }
        else{
            setTimeout(this.fixSlider, 100)
        }
    }
    componentWillUnmount = ()=>{
        this.slider = null
    }
    render () {

        const settings = {
          arrows: false,
          swipeToSlide: true,
          variableWidth: true,
          slidesToShow: 2,
          edgeFriction: '.05',
          adaptiveHeight: false,
          draggable: true,
          rows: 1,
          infinite: false
            // arrows: false,
            // swipeToSlide: true,
            // variableWidth: true,
            // edgeFriction: '.05',
            // adaptiveHeight: true,
            // draggable: true,
            // // lazyLoad: true,
            // initialSlide: 0,
            // beforeChange: (current, next)=> {
            //     console.log("current ", current," ", next)
            //     if(next < 0){
            //         this.fixSlider()
            //     }
            // }
            // onInit: ()=>{
            //         this.fixSlider()
            // }
        }
        // const settingsProjects = {
        //     arrows: false,
        //     swipeToSlide: true,
        //     variableWidth: true,
        //     slidesToShow: 2,
        //     edgeFriction: '.05',
        //     adaptiveHeight: true,
        //     draggable: true,
        //     infinite: false,
        // }
        //
      return (
        <>

          <Helmet>
            <title>Browse Tracks - Deep Sounds | Music for Creators</title>
            <meta name="description" content="Browse our curated library of royalty-free music for sync licensing." />
          </Helmet>

                            {/* <div className="app-container"> */}
                            <SideBar
                              filters={this.state.allFilters}
                              disabledFilters={this.state.disabledFilters}
                              filterMenuOpen={this.state.filterMenuOpen} toggleFilterMenu={this.toggleFilterMenu} toggleFilter={this.toggleFilter} isGenresOpen={this.state.isGenresOpen} isMoodsOpen={this.state.isMoodsOpen} isAttributesOpen={this.state.isAttributesOpen}
                                    isEmotionOpen={this.state.isEmotionOpen} isInstrumentsOpen={this.state.isInstrumentsOpen} isLyricsOpen={this.state.isLyricsOpen} isBPMOpen={this.state.isBPMOpen} isCitiesOpen={this.state.isCitiesOpen} isVersionsOpen={this.state.isVersionsOpen} addSearchOption={this.addSearchOption} excludeSearchOption={this.excludeSearchOption} selectedSearchOptions={this.state.searchOptions} trackBrowse={true} changeStem={this.changeStem}
                                    stemsRequired={this.state.stemsRequired} lengthFilterValue={this.state.lengthFilterValue} lengthFilterMin={0} lengthFilterMax={720} onSliderChange={this.onSliderChange} enableLengthRange={this.enableLengthRange} isLengthRangeEnabled={this.state.isLengthRangeEnabled} sliderFinished={this.sliderFinished} showMoreCities={this.state.showMoreCities} toggleShowMoreCities={this.toggleShowMoreCities}/>

                            <div className="app-main">
                            {/* <VideoStudioComponent/> */}
                            {/* <LoadingContainer isLoaded={userLoaded}> */}
                            <ProjectsConsumer>
                              {({ allProjects, isLoaded }) => (
                                <LoadingContainer
                                  isLoaded={isLoaded}
                                  style={{
                                    margin: "100px auto",
                                    width: "50%"
                                  }}
                                >
                                  {/* {# Featured Collections #} */}
                                    <div className="featured-carousel my-30 lg:my-50 xl:my-80">
                                        <div className="container">
                                            <div className="grid-row-sm">
                                                <div className="col w-full xl:w-1/3">
                                                    <div className="featured-carousel-header">
                                                                  {allProjects.length > 0 ?
                                                                      <h2 className="featured-title">
                                                                          <span className="inline-block xl:block">Your&nbsp;</span>
                                                                          <span className="inline-block xl:block">Projects</span>
                                                                      </h2>:
                                                                      <h2 className="featured-title">
                                                                          <span className="inline-block xl:block">Featured&nbsp;</span>
                                                                          <span className="inline-block xl:block">Collections</span>
                                                                      </h2>
                                                                  }
                                                                  {allProjects.length > 0 &&
                                                                      <NavLink to={"/dashboard/projects/all"} className="featured-link">Go to all Projects</NavLink>
                                                                  }

                                                          <div className="ui-control-arrows flex">
                                                              <button onClick={(e)=>{e.preventDefault();this.slider.slickPrev()}}  className="circle-icon-border lg:circle-icon-lg icon-chevron-left mr-15"></button>
                                                              <button onClick={(e)=>{e.preventDefault();this.slider.slickNext()}}className="circle-icon-border lg:circle-icon-lg icon-chevron-right"></button>
                                                          </div>
                                                    </div>
                                                </div>
                                                <div className="col w-full xl:w-2/3">
                                                    {/* <div className="card-carousel"
                                                        data-slick='{
                                                            "slidesToShow": 2
                                                    }'> */}

                                                                {isLoaded &&
                                                                    <>
                                                                        {allProjects.length > 0 ?
                                                                            <Slider
                                                                                key="browsePageSlider"
                                                                                ref={slider => (this.slider = slider)}
                                                                                className="card-carousel"
                                                                                slidesToShow={2}
                                                                                infinite={allProjects?.length >= 2}
                                                                                {...settings}
                                                                            >
                                                                              {allProjects.filter((project, index) => index < 10)
                                                                                .map((project, index) =>
                                                                                  <div
                                                                                    key={"projectCon-" + project.id}
                                                                                    className="carousel-slide"
                                                                                  >
                                                                                    <ProjectCard
                                                                                      key={"projectCard-" + project.id}
                                                                                      idKey={"browseProjectCard-" + project.id} {...project}
                                                                                      playTracks={this.playTracks}
                                                                                      isPlaying={this.context.mediaPlayer.idKey?.indexOf("projectListCard-" + project.id) !== -1 && (this.context.mediaPlayer.isTrackPlaying || this.context.isVideoPlaying)}
                                                                                      handlePause={this.context.handlePause}
                                                                                      handlePlay={this.context.handlePlay}
                                                                                    />
                                                                                  </div>
                                                                              )}
                                                                            </Slider>
                                                                            :
                                                                            this.state.initialFeaturedLoaded && (
                                                                              <Slider
                                                                                  key="browsePageSliderColl"
                                                                                  ref={slider => (this.slider = slider)}
                                                                                  className="card-carousel"
                                                                                  slidesToShow={2}
                                                                                  infinite={this.state.featuredCollections?.length >= 2}
                                                                                  {...settings}
                                                                              >
                                                                                {this.state.featuredCollections.map((collection) => {
                                                                                  const {
                                                                                    mediaPlayer,
                                                                                    isVideoPlaying
                                                                                  } = this.context;
                                                                                  const { isTrackPlaying } = mediaPlayer;

                                                                                  const mediaPlayerIdKey = this.context.mediaPlayer.idKey;
                                                                                  const cardId = `collectionListCard-${collection.id}`;
                                                                                  const isPlaying = mediaPlayerIdKey?.includes(cardId) && (isTrackPlaying || isVideoPlaying)

                                                                                  return (
                                                                                    <div
                                                                                      key={`featuredCollection-${collection.id}`}
                                                                                      className="carousel-slide"
                                                                                    >
                                                                                      <PublicCard
                                                                                        key={`featuredCollectionsCard-${collection.id}`}
                                                                                        idKey={`browseCollectionCard-${collection.id}`}
                                                                                        {...collection}
                                                                                        playTracks={this.playCollectionTracks}
                                                                                        isPlaying={isPlaying}
                                                                                        handlePause={this.context.handlePause}
                                                                                        handlePlay={this.context.handlePlay}
                                                                                      />
                                                                                    </div>
                                                                                  )
                                                                                })}
                                                                              </Slider>
                                                                            )
                                                                        }
                                                                    </>
                                                                }
                                                    {/* </div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                      </LoadingContainer>
                                    )}
                                  </ProjectsConsumer>
                            {/* </LoadingContainer> */}
                                  <div className="container">
                                  {/* // {# Search #} */}
                                  <SearchArtists
                                    loadOptions={this.loadOptions}
                                    filters={this.state.allFilters}
                                    filterMenuOpen={this.state.filterMenuOpen} toggleFilterMenu={this.toggleFilterMenu} toggleMobileFilter={this.toggleMobileFilter} isGenresOpen={this.state.isMobileGenresOpen} isMoodsOpen={this.state.isMobileMoodsOpen} isAttributesOpen={this.state.isMobileAttributesOpen}
                                      isEmotionOpen={this.state.isMobileEmotionOpen} isInstrumentsOpen={this.state.isMobileInstrumentsOpen} isLyricsOpen={this.state.isMobileLyricsOpen} isBPMOpen={this.state.isMobileBPMOpen} isCitiesOpen={this.state.isMobileCitiesOpen} isVersionsOpen={this.state.isMobileVersionsOpen} selectedSearchOptions={this.state.searchOptions} searchOptions={this.state.allFilters} handleSearchChange={this.handleSearchChange}
                                      addSearchOption={this.addSearchOption} excludeSearchOption={this.excludeSearchOption} artistOptions={this.state.artistOptions} getArtistOptions={this.getArtistOptions} keywordOptions={this.state.keywordOptions} getKeywordOptions={this.getKeywordOptions}  trackBrowse={true} changeStem={this.changeStem} stemsRequired={this.state.stemsRequired} getTrackOptions={this.getTrackOptions} getCollectionOptions={this.getCollectionOptions} trackOptions={this.state.trackOptions} collectionOptions={this.state.collectionOptions}
                                      lengthFilterValue={this.state.lengthFilterValue} lengthFilterMin={0} lengthFilterMax={720} onSliderChange={this.onSliderChange} enableLengthRange={this.enableLengthRange} isLengthRangeEnabled={this.state.isLengthRangeEnabled} sliderFinished={this.sliderFinished} totalMatchingTracks={this.state.totalMatchingTracks}/>

                                  {/* {# Sort Bar #} */}
                                  {/* <SortBar
                                    sortCriteria={this.state.sortCriteria}
                                    resultsText={window.screen.width > 600 ? (
                                      `${this.state.totalMatchingTracks} Tracks`
                                    ) : (`${this.state.totalMatchingTracks} Tracks`
                                    )}
                                    sortBarId={"artist_sort"}
                                    marginClasses={"mb-20 md:mb-30"}
                                    toggleSortTippy={this.toggleSortTippy}
                                    isSortTippyOpen={this.state.isSortTippyOpen}
                                    sortBy={this.sortBy}
                                    trackBrowse={true}
                                    changeView={this.changeView}
                                    view={this.state.view}
                                  /> */}
                                  {/* // {# Artist Cards #} */}
                                  {this.state.view === "list" ? (
                                    <LoadingContainer
                                      isLoaded={this.state.tracksLoaded}
                                    >
                                      <>
                                        {this.state.filteredTracks[1]?.length > 0 ? (
                                          <div className="track-list-container mb-40" >
                                            {this.state.filteredTracks[this.state.currentPage]?.map((track, index) =>
                                              <div key={`filteredArtist-${track}`}>
                                                <TrackListItem
                                                  key={`browseTrackListItem-${track.trackId}`}
                                                  {...this.context.loadedTracks[track]}
                                                  idKey={`artist-${this.context.loadedTracks[track]?.artistId}-album-${this.context.loadedTracks[track]?.albumId}-track-${track}-element-browseTrackListItem`}
                                                  playCB={this.playCB}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        ) : (
                                          this.state.tracksLoaded && (
                                            <div
                                              className="track-list-container mb-40 text-h4 text-white"
                                              style={{
                                                textAlign: "center",
                                                padding: "60px"
                                              }}>
                                                <p>Sorry, your search resulted in no matches.</p>
                                            </div>
                                          )
                                        )}
                                      </>
                                  </LoadingContainer>
                                ) : (
                                  <LoadingContainer
                                    isLoaded={this.state.tracksLoaded}
                                  >
                                    <>
                                      {this.state.filteredTracks[1]?.length > 0?
                                          <div className="grid-row mb-40">
                                      {this.state.filteredTracks[this.state.currentPage].map((track, index)=>
                                        // <div key={"filteredArtist" + track?.title + index} >
                                        <div
                                          className="col w-1/2 lg:w-1/3 mb-20 md:mb-40"
                                          key={`filteredGrid-`+track.trackId +"-" + index}
                                        >
                                          <TrackGridItem key={"browseTrackGridItem-"+track.trackId}
                                              {...this.context.loadedTracks[track]}
                                              idKey={`artist-${this.context.loadedTracks[track]?.artistId}-album-${this.context.loadedTracks[track]?.albumId}-track-${track}-element-trackGridItem`}
                                              playCB={this.playCB}
                                          />
                                        </div>
                                      // </div>
                                      )}</div>: this.state.tracksLoaded ?<div className="track-list-container mb-40 text-h4 text-white" style={{textAlign: "center", padding: "60px"}}>
                                              <p>Sorry, your search resulted in no matches.</p>
                                          </div>: <></>}
                                      </>
                                    </LoadingContainer>
                                  )}

                                  {/* // {# Pagination #} */}
                                      <Pagination pageLimit={20} currentPage={this.state.currentPage} totalRecords={this.state.totalMatchingTracks} gotoPage={this.gotoPage}/>

                                  </div>
                                  <Footer/>

                            </div>
                        {/* </div> */}
            </>
          );
    }

}
BrowseTracks.contextType = PlayerContext

export default BrowseTracks

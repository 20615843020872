import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { PlayerContext } from "../../PlayerContext";
import { UserConsumer } from "../../contexts/User";
import OnboardingLayout from "../Layouts/onboardingLayout";

class Login extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {
                email: "",
                password: "",
                loginMessage: "",
                loginAction: "login",
            }

    //updating our input fields as the user enters keys

    render() {
        return (
            <OnboardingLayout>
                <UserConsumer>
                    {({ user, login, logout, loginMessage})=>(
                        <>
                        <Helmet title="Logout - Deep Sounds | Music for Creators" />
                        <div>
                            <div className="hero-fixed">
                                <div className="hero-img" style={{backgroundImage:  `url("${process.env.PUBLIC_URL}/images/hero/login-02.jpg")`}}></div>
                            </div>
                            <div className="onboarding-main container container-xl">
                                <h1 className="ds-title mb-30 lg:mb-50">Signing out..</h1>

                            </div>
                        </div>
                        </>
                    )}
                </UserConsumer>
            </OnboardingLayout>
        )
    }

}
Login.contextType = PlayerContext
export default Login

import React, { Component } from "react";
import { NavLink } from 'react-router-dom';
// import tippy from "../../tooltips"

class FavAlbumCardTooltip extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {

            }

    render() {
        return (    
                <div className="dropdown-menu">
                    <div className="dropdown-group">
                        <NavLink to={`/artists/${this.props.artistURL}#album-${this.props.albumTitle?.replace(/\s/g, "-")}`}><span class="icon-albums"></span>View Album</NavLink>  
                        <NavLink to={`/artists/${this.props.artistURL}`}><span class="icon-user"></span>View Artist</NavLink>    
                        <a href="# " onClick={this.props.shareAlbum}><span class="icon-share"></span>Share Album</a>   
                        <a href="# " onClick={()=>this.props.unlikeAlbum(this.props.albumId, this.props.albumTitle)}><span class="icon-follow"></span>Unlike Album</a>   
                    </div>
                </div>
        )
    }

}
export default FavAlbumCardTooltip
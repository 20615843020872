import React from "react";

export default function CTALarge(props) {

  const {
    desc,
    title,
    subtitle,
    buttonPath,
    buttonText
  } = props;

    return (
        <section className="py-100 bg-gray-100">
            <div className="container">
                <div className="grid-row items-end justify-between">

                    {/* {# Heading #} */}
                    <div className="col w-full lg:w-7/12 xl:w-1/2">
                        <h2 className="text-h2 md:text-h1 leading-tighter mb-20 lg:mb-0">{title}</h2>
                    </div>

                    {/* {# Text #} */}
                    <div className="col w-full lg:w-5/12 xl:w-5/12">
                        <div className="max-w-md lg:pb-15">
                            <h3 className="font-display2 text-h5 text-white leading-snug mb-15">{subtitle}</h3>
                            <p className="text-h4 mb-30">{desc}</p>
                            <a href={`/${buttonPath || 'signup'}`} className="btn btn-primary">{buttonText}</a>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
class FeaturedList extends Component{
    resolveCardPhoto=(cardPhotoKey)=>{
        const key = cardPhotoKey
        if(cardPhotoKey){
            const ext = key.split('.').reverse()[0];
            let final = key.replace('.' + ext, '') + '-minified-140x140'  + '.' + ext
            return final
        }
    }    
    render() {
        return(

            <div className="col w-full md:w-1/2 xxl:w-1/3 mb-10 md:mb-20" key={"coll-"+this.props.id} colli={this.props.id}>
                <div class="featured-list-item flex items-center">

                    <div class="featured-list-img">
                        <img src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveCardPhoto(this.props.cardArt)}`} alt={this.props.name}/>
                    </div>

                    <div class="featured-list-content">
                        <NavLink to={`/dashboard/collections/edit/${this.props.id}`}><div class="featured-list-title">{this.props.name}</div></NavLink>
                        <div class="featured-list-meta">
                            <span class="mr-3">{this.props.tracks?.length} </span>
                            Tracks {this.props.followerCount > 0 &&  this.props.followerCount + ` Follower${this.props.followerCount > 1 ? 's':''}`}
                        </div>
                    </div>

                    <div class="featured-list-controls">
                        <button class="circle-icon circle-icon-sm">
                            <span class="icon-drag"></span>
                        </button>
                        <button onClick={()=>this.props.makeUnfeatured(this.props.id)}class="circle-icon circle-icon-sm">
                            <span class="icon-close"></span>
                        </button>
                    </div>

                </div>
            </div>
                                
    )}
}

export default FeaturedList
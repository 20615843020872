import React, { Component } from "react";
import { Prompt } from 'react-router';
import { UserConsumer } from "../../contexts/User";
import Footer from '../Footer';
import Navbar from "../Navbar";
import SideNav from '../Navbar/sideNav';

class FormLayout extends Component {
  state = {
        shouldBlockNavigation: false
    }

  setCurrentNav = (updatedNav)=>{
    this.setState({currentNav: updatedNav})
  }
  //this function will be triggered when a user inputs on a form and when a user saves, the function accepts a flag that tells use wether to block navigation or not
  handleFormUnsaved = (saved)=>{

    this.setState({shouldBlockNavigation: saved})
  }


  render() {
    //
    return (
      <UserConsumer>
        {({ user, login, logout, currentNav, setCurrentNav})=>(
          <>
            <Navbar pathname={window.location.pathname}></Navbar>
            <div className='app-container'>
              {!window.location.pathname.includes("/projects/public/") &&
                <SideNav pathname={window.location.pathname}></SideNav>
              }
              <div className='app-main'>
                <div className='content'>

                    <Prompt when={this.state.shouldBlockNavigation}
                    message={(location)=> window.location.pathname === location.pathname ? true: 'You have unsaved changes, are you sure you want to leave?'}></Prompt>
                    {React.cloneElement(this.props.children, { handleFormUnsaved: this.handleFormUnsaved})}

                </div>
                <Footer></Footer>
              </div>
            </div>
          </>
        )}
      </UserConsumer>
    )
  }
}

export default FormLayout

import throttle from "lodash.throttle";
import moment from "moment";
import React, { Component, createRef } from "react";
import Slider from 'react-input-slider';
import MicroModal from "react-micro-modal";
import { NavLink } from "react-router-dom";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { Tooltip } from "react-tippy";
import 'react-tippy/dist/tippy.css';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/src/plugin/regions/index.js';
import { FavoritesConsumer } from "../../FavoritesContext/index";
import { PlayerContext } from "../../PlayerContext";
import AddToProjectModal from "../Projects/addToProjectModal";
import { Error, Success } from "../Toast/index";
import TrackTooltip from "../Track/trackTooltip";
import "./videoStudioMediaPlayer.css";
import DownloadTrack from "../Track/DownloadTrack";


const userAgent = window.navigator.userAgent.toLowerCase();
const isMobileSafari = (/iphone/.test(userAgent) || /ipad/.test(userAgent) || (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2));
// console.log('user agent ', userAgent)
// console.log("is mobile safari ", isMobileSafari)
const contentDis = require('content-disposition')


var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment)
// import { PlayerContext } from "../../PlayerContext";

class VideoStudioMediaPlayer extends Component {
    state = {
        src: this.props.src || "",
        peaks: this.props.peaks,
        currentTime: "0:00",
        duration: "0:00",
        // sendPlay: 0,
        volume: 1
    }
    handleOnChange = (value) => {
      console.log(value)
      this.setState({
        volume: value.x
      }, ()=> this.wavesurfer.setVolume(this.state.volume)
      )
    }
  setVolume = ()=>{
    console.log("setting volume to ", this.state.volume)
  }
  toggleTrackTippy = ()=>{
    this.setState({showTrackTippy: !this.state.showTrackTippy})
}


  wrapper = createRef();

  componentDidMount() {
    this.context.setWaveFormEl(null)
    this.$el = this.wrapper.current
    this.$waveform = this.$el.querySelector('.wave')
    console.log(this.$waveform)
    this.$mobileWaveform = this.$el.querySelector('.mobile-wave')
    console.log("mobile wave ",this.$mobileWaveform)

    this.wavesurfer = WaveSurfer.create({
        container: this.$waveform,
        normalize: true,
        barMinHeight: 1,
        plugins: [
          RegionsPlugin.create({
            // plugin options ...
          })
        ],
        barWidth: 1,
        barGap: 2,
        hideScrollbar: true,
        interact: true,
        cursorWidth: 0,
        backend: "MediaElement",
        height: 80,
        progressColor: "rgba(255,255,255,1)",
        responsive: true,
        waveColor: "rgba(255,255,255,.5)",
        xhr:{ cache: 'default', mode: 'cors', method: 'GET', credentials: 'same-origin', redirect: 'follow', referrer: 'client', headers: [ { key: 'Authorization', value: 'my-token' } ]}
        // fillParent: true
        // fillParent: true,
    })
    this.mobileWavesurfer = WaveSurfer.create({
      container: this.$mobileWaveform,
      normalize: true,
      barMinHeight: 1,
      barWidth: 1,
      barGap: 2,
      hideScrollbar: true,
      interact: true,
      cursorWidth: 0,
      backend: "MediaElement",
      height: 3.125 * (parseFloat(getComputedStyle(document.documentElement).fontSize)),
      progressColor: "rgba(255,255,255,1)",
      responsive: true,
      waveColor: "rgba(255,255,255,.5)",
      xhr:{ cache: 'default', mode: 'cors', method: 'GET', credentials: 'same-origin', redirect: 'follow', referrer: 'client', headers: [ { key: 'Authorization', value: 'my-token' } ]}
      // fillParent: true
      // fillParent: true,
  })
    // console.log(this.props.peaks)
    console.log(this.wavesurfer)
    this.context.setWaveFormEl(this.wavesurfer);

    console.log("movile wavesurf ",this.mobileWavesurfer)
    this.wavesurfer.on('ready', ()=>{
      if(this.context.volume ===.75){
        this.wavesurfer.setVolume(.5)
        let audioEl = document.getElementsByTagName("audio")[0]
        if(audioEl){
          if(audioEl.volume === 1){
            this.setState({isIpad: true})
          }
          this.wavesurfer.setVolume(.75)
        }
      }
      else{
        this.wavesurfer.setVolume(.5)
        let audioEl = document.getElementsByTagName("audio")[0]
        if(audioEl){
          if(audioEl.volume === 1){
            this.setState({isIpad: true})
          }
          this.wavesurfer.setVolume(this.context.volume)
        }
      }
      this.setState({ready: true})
    })
    this.mobileWavesurfer.on('ready', ()=>{
      this.setState({mobileready: true})

    })
    console.log("current peaks ", this.context.mediaPlayer.peaks)
    this.mobileWavesurfer.backend.peaks = JSON.parse(this.context.mediaPlayer.peaks.length > 0 ? this.context.mediaPlayer.peaks:   "[]")
    this.mobileWavesurfer.drawBuffer()
    this.onInteract = this.mobileWavesurfer.on('seek', (seekTime)=>{
      if(seekTime - this.wavesurfer.getCurrentTime()/this.context.waveFormEl.getDuration() > .02 || seekTime - this.wavesurfer.getCurrentTime()/this.context.waveFormEl.getDuration() < -.02 ){
        console.log("gonna change main track")
        this.context.waveFormEl.seekTo(seekTime)
        if(!this.context.mediaPlayer?.isTrackPlaying){
          this.context.updateTime(seekTime)
        }
      }
    })
    this.trackFinished = this.wavesurfer.on('finish', ()=>{
      console.log("loop ended")
      if(this.props.isLoopEnabled){
        // this.props.pauseVideo()
        this.context.pauseRegion()
          this.props.playVideoLoop()
      }
      // this.context.playNext()
      // this.context.pauseRegion()
    })
    this.regionFinished = this.wavesurfer.on('region-out', ()=>{
      if(this.context.mediaPlayer.currentTime > this.context?.region?.start){
        console.log("loop ended")
        if(this.props.isLoopEnabled){
          // this.props.pauseVideo()
          this.context.pauseRegion()

            // this.props.playVideoLoop()

        }
      }

      // this.context.playNext()
      // this.context.pauseRegion()
    })
    this.trackPlayTime = this.wavesurfer.on('audioprocess', ()=>{
      // console.log("audio process called")
      this.audioProcessFunction()
    })
    // this.wavesurfer.drawer.removeEventListener('click', (e, progress) => {
    //   setTimeout(() => this.seekTo(progress), 0);
    // })
    console.log("click handlers ", this.wavesurfer.drawer.handlers.click)
    this.wavesurfer.drawer.un('click', this.wavesurfer.drawer.handlers.click[0]);
    this.wavesurfer.drawer.on('click', (e, progress)=>{
      e.preventDefault()
      console.log("drawer clicked ", e, progress)
      let regionKey = Object.keys(this.context.waveFormEl?.regions?.list)[0]
      let regionRef = this.context.waveFormEl?.regions?.list[regionKey]
      let regionStart = regionRef.start
      let regionEnd = regionRef.end
      console.log("seek info ", progress *this.context.waveFormEl.getDuration() , " ", regionStart, " ", regionEnd )
      if(progress *this.context.waveFormEl.getDuration() > regionStart && progress *this.context.waveFormEl.getDuration() < regionEnd){
        console.log("should seek")
        // this.props.emitToOthers(progress * this.wavesurfer.getDuration(), "track", this.context.mediaPlayer.idKey);
        this.props.seekTo(progress, this.context.waveFormEl.getDuration(), regionStart, regionEnd)
        this.wavesurfer.seekTo(progress)
        if(!this.context.mediaPlayer?.isTrackPlaying){
          this.context.updateTime(progress * this.context.waveFormEl.getDuration())
        }
      }else{
        return
      }
    })

    // this.playerClicked = this.wavesurfer.on('click', (e, progress)=>{
    //   console.log("waveform clicked ", e , " ", progress)
    // })
    this.playerSeek = this.wavesurfer.on('seek',(seekTime)=>{
      console.log("seeking media player waveform")
      if(seekTime <= 0.02 && this.context.mediaPlayer.currentTime/this.wavesurfer.getDuration() > 0.02){
        // this.props.emitToOthers(0, "track", this.context.mediaPlayer.idKey);
        if(this.wavesurfer.getCurrentTime() !==0){
          this.wavesurfer.seekTo(0)
        }
      }
      else if(seekTime - this.context.mediaPlayer.currentTime/this.wavesurfer.getDuration() > .02 || seekTime - this.context.mediaPlayer.currentTime/this.wavesurfer.getDuration() < -.02 ){
        let regionKey = Object.keys(this.context.waveFormEl?.regions?.list)[0]
        let regionRef = this.context.waveFormEl?.regions?.list[regionKey]
        let regionStart = regionRef.start
        let regionEnd = regionRef.end
        console.log("seek info ", seekTime *this.context.waveFormEl.getDuration() , " ", regionStart, " ", regionEnd )
        if(seekTime *this.context.waveFormEl.getDuration() > regionStart && seekTime *this.context.waveFormEl.getDuration() < regionEnd){
          console.log("should seek")
          // this.props.emitToOthers(seekTime * this.wavesurfer.getDuration(), "track", this.context.mediaPlayer.idKey);
          // this.wavesurfer.seekTo(seekTime)
        }else{

        }

      }
    })

    // console.log('src ', this.props.src, ' peaks ', this.props.peaks, ' peaks length ', JSON.parse(this.props.peaks).length)
    if(this.context.mediaPlayer?.peaks?.length && this.context.mediaPlayer?.cloudFrontURL){
      this.wavesurfer.load(this.context.mediaPlayer?.cloudFrontURL, JSON.parse(this.context.mediaPlayer?.peaks))
    }
    else{
    }
    if(this.props.src){
      // this.wavesurfer.playPause()
    }

  }

  audioProcessFunction = throttle(()=>{
    this.context.updateTime(this.wavesurfer.getCurrentTime())
    // this.props.emitToOthers(this.wavesurfer.getCurrentTime(), "track", this.context.mediaPlayer.idKey);
  }, 50)
  trackPlayTime = ()=>{}
  mobileTrackPlayTime = ()=>{}
  trackFinished = ()=>{}
  regionFinished = ()=>{}
  songLoaded = ()=>{}
  wavesurferError = ()=>{}
  currentTimeInt = ()=>{}
  componentDidUpdate = (prevProps, prevState, snapshot)=>{
        let duration;
        if(this.state.isMobilePanelOpen){
          duration = this.context.waveFormEl.getDuration()
          if( this.wavesurfer.getCurrentTime()/duration < 1){
            console.log("seeking")
            this.mobileWavesurfer.seekTo( this.wavesurfer.getCurrentTime()/duration)
            }
        }

        if(prevProps.peaks !== this.props.peaks || prevProps.mediaPlayer?.idKey !== this.props.mediaPlayer?.idKey || prevProps.currentTrack?.url !== this.props.currentTrack?.url ){
            console.log("peaks dif")
            if(this.props.peaks){
              // this.props.pauseVideo()
              // this.context.pauseRegion()
              // this.wavesurfer.load(this.context.mediaPlayer?.cloudFrontURL, JSON.parse(this.context.mediaPlayer.peaks))
              // // this.wavesurfer.params.duration = 300
              // this.mobileWavesurfer.backend.peaks = JSON.parse(this.context.mediaPlayer.peaks.length > 0 ? this.context.mediaPlayer.peaks:   "[]")
              // this.mobileWavesurfer.drawBuffer()
              // this.mobileWavesurfer.load(this.props.src, JSON.parse(this.props.peaks))
              // this.songFinished = this.wavesurfer.on('finish', ()=>{
              //   console.log("finished!")
              //   clearInterval(this.currentTimeInt)
              // })
              // let regionKey = Object.keys(this.context.waveFormEl?.regions?.list)[0]
              // let regionRef = this.context.waveFormEl?.regions?.list[regionKey]
              // let regionStart = regionRef.start
              // let regionEnd = regionRef.end
              // let startFormatted = moment.duration(Math.floor(regionStart), 'seconds').format("m:ss",{forceLength: true, trim: false})
              // let endFormatted = moment.duration(Math.floor(regionEnd), 'seconds').format("m:ss",{forceLength: true, trim: false})
              // let region ={
              //   start: regionStart,
              //   end: regionEnd,
              //   startFormatted: startFormatted,
              //   endFormatted: endFormatted
              // }
              // this.context.setRegion(region)
              this.wavesurferError = this.wavesurfer.on('error', (error)=>{
                console.log(error)
              })
              this.songLoaded = this.wavesurfer.on('ready', ()=>{
                // this.playFromTrackIn()
              })
            }
            else{
              // this.wavesurfer.load(this.props.src)
              this.wavesurferError = this.wavesurfer.on('error', (error)=>{
                console.log(error)
              })
              this.songLoaded = this.wavesurfer.on('ready', ()=>{
                // this.play()
              })
            }


        }
        else if(this.context.playing !== this.context.regionPlaying && !this.state.waiting){


        }

    }
  play= ()=>{
    console.log("playing from root")
    if(Object.keys(this.context.waveFormEl?.regions?.list).length > 0){
      let regionKey = Object.keys(this.context.waveFormEl?.regions?.list)[0]
      let regionRef = this.context.waveFormEl?.regions?.list[regionKey]
      this.context.handlePlay()
      // regionRef.play(regionRef.start)
    }
    else{
      this.context.handlePlay()
      // this.wavesurfer.play()
    }
    this.setState({waiting: true})

  }
  // playFromTrackIn= ()=>{
  //   console.log("playing from track in")

  //     // this.props.playVideoFromIn()
  //     // this.wavesurfer.play()

  //   this.setState({ waiting: true})

  // }
  pause = ()=>{

        // this.wavesurfer.pause()
        this.props.pauseVideo()
        this.context.pauseRegion()

  }


  shareTrack = ()=>{

      var textArea = document.createElement("textarea");

      // Place in top-left corner of screen regardless of scroll position.
      textArea.style.position = 'fixed';
      textArea.style.top = 0;
      textArea.style.left = 0;

      // Ensure it has a small width and height. Setting to 1px / 1em
      // doesn't work as this gives a negative w/h on some browsers.
      textArea.style.width = '2em';
      textArea.style.height = '2em';

      // We don't need padding, reducing the size if it does flash render.
      textArea.style.padding = 0;

      // Clean up any borders.
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';

      // Avoid flash of white box if rendered for any reason.
      textArea.style.background = 'transparent';


      textArea.value = window.location.protocol + "//" +window.location.hostname + (window.location.port ? ":" + window.location.port : "") +"/" + "tracks/" + this.context.mediaPlayer.trackURL;

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand('copy');
        if(successful){
            Success({message: "Track URL Copied to Clipboard"})
        }
        else{
            Error({message: "Couldn't Copy Track URL "})
        }
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copying text command was ' + msg);
      } catch (err) {
        console.log('Oops, unable to copy');
      }

      document.body.removeChild(textArea);

      this.setState({showTrackTippy: false})


  }
  mobileShareTrack = ()=>{
  if (navigator.share) {
    navigator.share({
      title: 'Share Track',
      text: this.context.mediaPlayer.trackTitleDisplay,
      url: window.location.protocol + "//" +window.location.hostname + "/" + "tracks/" + this.context.mediaPlayer.trackURL,
    })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
  }
  else{
    var textArea = document.createElement("textarea");

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';


    textArea.value = window.location.protocol + "//" +window.location.hostname + (window.location.port ? ":" + window.location.port : "") +"/" + "tracks/" + this.context.mediaPlayer.trackURL;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      if(successful){
          Success({message: "Track URL Copied to Clipboard"})
      }
      else{
          Error({message: "Couldn't Copy Track URL "})
      }
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Copying text command was ' + msg);
    } catch (err) {
      console.log('Oops, unable to copy');
    }

    document.body.removeChild(textArea);

    this.setState({showTrackTippy: false})
  }

  }
  componentWillUnmount() {
    // this.context.pause()
    // this.context.handlePause()
    // this.context.setTrackOffsetStart()
    // this.context.waveFormEl.pause()
    // this.wavesurfer.destroy()

    // this.audioProcessFunction.flush()
    // this.audioProcessFunction.cancel()
    // this.audioProcessFunction.cancel()
    // this.audioProcessFunction.cancel()
    // this.audioProcessFunction.cancel()
    // this.audioProcessFunction.cancel()

    // this.audioProcessFunction.cancel()

    console.log("calling reload player from public")
    // this.context.reloadPlayerFromPublic(this.audioProcessFunction)
  }
  resolveAlbumPhoto=(albumPhotoKey)=>{
    const key = albumPhotoKey
    if(albumPhotoKey){
        const ext = key.split('.').reverse()[0];
        let sizeMod
        const width = window.innerWidth
        if(width >= 1280){
            sizeMod = '140x140'
        }
        else {
            sizeMod = '140x140'
        }
        let final = key.replace('.' + ext, '') + '-minified-' + sizeMod  + '.' + ext
        return final
    }
  }
  resolveMobileAlbumPhoto=(albumPhotoKey)=>{
    const key = albumPhotoKey
    if(albumPhotoKey){
        const ext = key.split('.').reverse()[0];
        let sizeMod = '500x500'
        let final = key.replace('.' + ext, '') + '-minified-' + sizeMod  + '.' + ext
        return final
    }
  }

  render() {
    let {volume} = this.state
    return (
      <FavoritesConsumer>
      {({artists, tracks, albums, collections, likeTrack, unlikeTrack, followArtist, unfollowArtist})=>(
      <div ref={this.wrapper} className="playbar-container" >
	<div className="playbar">
		<div className="playbar-track-info flex items-center" onClick={()=>this.setState({isMobilePanelOpen: true})}>
			<div className="playbar-image">
				<NavLink onClick={()=>this.setState({isMobilePanelOpen: false})} className="playbar-title" to={"/tracks/" + this.context.mediaPlayer.trackURL}>
          <img src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveAlbumPhoto(this.context.mediaPlayer?.imageURL)}`} alt="album-art"/>
        </NavLink>
      </div>
			<div className="playbar-info-content ml-10 flex flex-col">
				<NavLink onClick={()=>this.setState({isMobilePanelOpen: false})} className="playbar-title" to={"/tracks/" + this.context.mediaPlayer.trackURL}>{this.context.mediaPlayer.trackTitleDisplay}</NavLink>
				<NavLink onClick={()=>this.setState({isMobilePanelOpen: false})} className="playbar-artist" to={"/artists/" + this.context.mediaPlayer.artistURL}>{this.context.mediaPlayer.artistNameDisplay}</NavLink>
			</div>
		</div>
		<div className="playbar-actions-one">
			<button onClick={this.context.playPrev} className="icon-rewind hidden"></button>
      {/* if playing render pause button, else render play button */}
      {this.context.isVideoPlaying ?
        <button onClick={this.context.handlePause} className="icon-pause" ></button>
        :
        <button onClick={()=>this.context.handlePlay()} className="icon-play" ></button>
}
			<button onClick={this.context.playNext}className="icon-fast-forward hidden"></button>
		</div>
		<div className="playbar-track-duration">
      <span>{this.context.mediaPlayer.formattedCurrentTime}</span>
      <div style={{backgroundColor: "#131313" }} className='waveform'>
        <div id='trackWave'className='wave'/>
      </div>
      <span>{this.context.mediaPlayer?.durationDisplay}</span>

       </div>
       <div className="playbar-actions-two">
          {tracks?.filter(fav=>fav.trackId === this.context.mediaPlayer.trackId).length === 1 ?
            <button onClick={()=>unlikeTrack(this.context.mediaPlayer.trackId)} className={`icon-like-fill js-tooltip is-liked btn-jelly`} title="Like track">
            </button>:
            <button onClick={()=>likeTrack(this.context.mediaPlayer.trackId)} className="icon-like js-tooltip btn-jelly" title="Like track">
            </button>
          }
          <MicroModal
                                trigger={handleOpen =>
                                   <button onClick={this.props.user ? handleOpen: this.context.triggerLoginModal} className="icon-plus"></button>}
                                closeOnEscapePress={true}
                                closeOnOverlayClick={true}
                                closeOnAnimationEnd={true}
                                modalOverlayClassName="modal-overlay"
                                modalClassName="modal micromodal-slide is-open"
                                modalOverlayStyles={null}
                                children={handleClose=>
                                    <AddToProjectModal
                                        handleClose={handleClose}
                                        trackId={this.context.mediaPlayer.trackId}
                                        trackTitle={this.context.mediaPlayer.trackTitleDisplay}
                                    />
                                }
                                containerStyles={{
                                    background: "#131313",
                                    padding:"asdf",
                                    maxWidth: "40.625rem !important",
                                    maxHeight: "100vh",
                                    borderRadius: "asdf",
                                    overflowY: "auto",
                                    boxSizing: "border-box",
                                    zIndex: 7002
                                }}
                            />
                                			<button onClick={this.context.videoURL? this.context.openVideoMain :this.context.openVideoLoader} className="icon-video-studio js-tooltip" title="Video Studio"></button>



                                <DownloadTrack
                                  artistId={this.context.mediaPlayer.artistId}
                                  artistName={this.context.mediaPlayer.artistNameDisplay}
                                  imageURL={this.context.mediaPlayer?.imageURL}
                                  trackId={this.context.mediaPlayer.trackId}
                                  trackTitle={this.context.mediaPlayer.trackTitleDisplay}
                                  triggerLoginModal={this.context.triggerLoginModal}
                                />

		</div>
		<div className="playbar-volume-options">
      {( !isMobileSafari) &&
        <div className="volume-controls">
          <button className="icon-volume"></button>
          {/* <div className="volume-controller"></div> */}
          <Slider
          styles={{
            track: {
              backgroundColor: '#2F2F2F',
              height: 1,
              width: "5vw",
              marginLeft: "5px"
            },
            active: {
              backgroundColor: '#fff'
            },
            thumb: {
              width: 10,
              height: 10
            },
            disabled: {
              opacity: 0.5
            }}}
            xmin={0}
            xmax={1}
            xstep={.05}
            x={this.context.volume}
            axis="x"
            onChange={this.context.setVolume}
            />
        </div>
      }
			<button className="playbar-more-options">
        <Tooltip
              open={this.state.showTrackTippy && !this.state.isMobilePanelOpen}
              useContext={true}
              arrow={false}
              interactive={true}
              offset={-100}
              position={'top'}
              trigger={'click'}
              maxWidth={'180'}
              allowHTML={true}
              onUntrigger={()=>{this.state.showTrackTippy ? this.setState({showTrackTippy: false}): this.setState({showTrackTippy: true})}}
              onRequestClose={()=>{this.state.showTrackTippy ? this.setState({showTrackTippy: false}): this.setState({showTrackTippy: true})}}
              html={<TrackTooltip
                trackId={this.context.mediaPlayer.trackId}
                trackTitle={this.context.mediaPlayer.trackTitleDisplay}
                artistId={this.context.mediaPlayer.artistId}
                artistName={this.context.mediaPlayer.artistNameDisplay}
                imageURL={this.context.mediaPlayer.imageURL}
                liked={tracks?.filter(fav=>fav.trackId === this.context.mediaPlayer.trackId).length}
                likeTrack={likeTrack}
                unlikeTrack={unlikeTrack}
                following={artists?.filter(fav=>fav.artistId === this.context.mediaPlayer.artistId).length === 1}
                followArtist={followArtist}
                unfollowArtist={unfollowArtist}
                shareTrack={this.shareTrack}
                toggleTrackTippy={this.toggleTrackTippy}
                triggerLoginModal={this.context.triggerLoginModal}
                user={this.props.user}
                >
            </TrackTooltip>}
              allowHTML={true}
              theme={'dark'}
          >
            <button
                onClick={this.toggleTrackTippy}
                className="icon-btn js-dropdown-toggle"
                data-tippy-placement="top"
            >
                <span className="icon-dots-vert"></span>
            </button>

          </Tooltip>
			</button>
		</div>
	</div>
  <div
		class={`mobile-player-panel ${this.state.isMobilePanelOpen ? "is-open": "is-closed"}`}

	>
		{/* {# Header #} */}
		<div class="mobile-player-header">
			<div class="font-medium text-gray-400">Now Playing</div>

			{/* {# Close Button #} */}
			<button
        onClick={()=>this.setState({isMobilePanelOpen: false})}
				class="circle-icon circle-icon-sm circle-icon-border"
				type="button"
			>
				<span class="text-icon-sm icon-chevron-down"></span>
			</button>
		</div>
  <div class="mobile-player-content">

{/* {# Top #} */}
<div class="mobile-player-top">
  {/* {# Track Artwork #} */}
  <div class="mobile-player-hero">

    {/* {# Artwork Image #} */}
        <img src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveMobileAlbumPhoto(this.props.albumArt)}`} class="mobile-player-artwork"/>

    {/* {# Hero BG #} */}
    <div class="hero-fixed hero-square hero-overlay-blur">
            <div class="hero-img" style={{backgroundImage: `url("https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveMobileAlbumPhoto(this.props.albumArt)}")`}}></div>
        </div>
  </div>

  {/* {# Mobile Player Track Info #} */}
  <div class="mobile-player-track">
  <NavLink onClick={()=>this.setState({isMobilePanelOpen: false})} className="mobile-player-title" to={"/tracks/" + this.context.mediaPlayer.trackURL}>{this.context.mediaPlayer.trackTitleDisplay}</NavLink>
    <div class="mobile-player-artist">
      <NavLink onClick={()=>this.setState({isMobilePanelOpen: false})} className="text-link-gray" to={"/artists/" + this.context.mediaPlayer.artistURL}>{this.context.mediaPlayer.artistNameDisplay}</NavLink>
    </div>
    <div class="mobile-player-album">
      <NavLink onClick={()=>this.setState({isMobilePanelOpen: false})} className="text-link-gray" to={`/artists/${this.context.mediaPlayer.artistURL}#album-${this.context.mediaPlayer.albumTitle?.replace(/\s/g, "-")}`}>{this.context.mediaPlayer.albumTitle}</NavLink>
    </div>
  </div>
</div>

{/* {# Track Duration #} */}
<div class="mobile-player-duration">
  <span class="mobile-player-duration-time">{moment.duration(Math.floor(this.context.mediaPlayer.currentTime), 'seconds').format("m:ss",{forceLength: true, trim: false})}</span>
  <div class="mobile-player-waveform">
    {/* {% include "components/audio/waveform-sm.html" %} */}
    <div className='waveform' >
        <div  id='mobileWave' className='mobile-wave'/>
      </div>
  </div>
  <span class="mobile-player-duration-time">{this.context.mediaPlayer?.durationDisplay}</span>
</div>

{/* {# Mobile Player Controls #} */}
<div class="mobile-player-controls" >

  {/* {# Like #} */}
  {tracks?.filter(fav=>fav.trackId === this.context.mediaPlayer.trackId).length === 1 ?
    <button onClick={()=>unlikeTrack(this.context.mediaPlayer.trackId)} className={`circle-icon circle-icon-lg btn-jelly is-liked`} title="Like track">
                  <span className="icon-like-fill" ></span>
    </button>:
    <button onClick={()=>likeTrack(this.context.mediaPlayer.trackId)} className={`circle-icon circle-icon-lg btn-jelly`} title="Like track">
            <span className="icon-like" ></span>

    </button>
  }
        {/* <button
        class="circle-icon circle-icon-lg btn-jelly"
            type="button"
        >
            <span ></span>
        </button> */}

  {/* {# Prev #} */}
  <button onClick={this.context.playPrev} type="button" class="circle-icon circle-icon-lg">
    <span class="text-icon-lg icon-rewind"></span>
  </button>

  {/* {# Play #} */}
  {this.context.playing ?
        <button onClick={this.pause} className="circle-icon-fill circle-icon-xl" >
              <span className="icon-pause" ></span>
        </button>
        :
        <button onClick={this.play} className="circle-icon-fill circle-icon-xl" >
          <span className="icon-play" ></span>
        </button>
}


  {/* {# Prev #} */}
  <button onClick={this.context.playNext} type="button" class="circle-icon circle-icon-lg">
    <span class="text-icon-lg icon-fast-forward"></span>
  </button>

  {/* {# More #} */}
                  <button className="circle-icon circle-icon-lg">
  <Tooltip
              open={this.state.showTrackTippy && this.state.isMobilePanelOpen}
              useContext={true}
              arrow={false}
              interactive={true}
              offset={-100}
              position={'top'}
              trigger={'click'}
              maxWidth={'180'}
              allowHTML={true}
              onUntrigger={()=>{this.state.showTrackTippy ? this.setState({showTrackTippy: false}): this.setState({showTrackTippy: true})}}
              onRequestClose={()=>{this.state.showTrackTippy ? this.setState({showTrackTippy: false}): this.setState({showTrackTippy: true})}}
              html={<TrackTooltip
                trackId={this.context.mediaPlayer.trackId}
                trackTitle={this.context.mediaPlayer.trackTitleDisplay}
                artistId={this.context.mediaPlayer.artistId}
                artistName={this.context.mediaPlayer.artistNameDisplay}
                imageURL={this.context.mediaPlayer.imageURL}
                liked={tracks?.filter(fav=>fav.trackId === this.context.mediaPlayer.trackId).length}
                likeTrack={likeTrack}
                unlikeTrack={unlikeTrack}
                following={artists?.filter(fav=>fav.artistId === this.context.mediaPlayer.artistId).length === 1}
                followArtist={followArtist}
                unfollowArtist={unfollowArtist}
                shareTrack={this.mobileShareTrack}
                toggleTrackTippy={this.toggleTrackTippy}
                triggerLoginModal={this.context.triggerLoginModal}
                user={this.props.user}
                >
            </TrackTooltip>}
              allowHTML={true}
              theme={'dark'}
          >
            <button
                onClick={this.toggleTrackTippy}
                className="icon-btn js-dropdown-toggle"
                data-tippy-placement="top"
            >
                <span className="icon-dots-vert"></span>
            </button>

          </Tooltip>
			</button>


</div>
</div>
</div>
</div>)}</FavoritesConsumer>
    )
  }
}

VideoStudioMediaPlayer.defaultProps = {
  src: "",
  peaks: ""
}

VideoStudioMediaPlayer.contextType = PlayerContext
export default VideoStudioMediaPlayer

import React, { Component } from "react";
import { Tooltip } from "react-tippy";
import BackdropOverlay from "../LoadingOverlay/backdrop";
import SalesPagination from "../Pagination/salesPagination";
import TagStatsSortTooltip from "./tagStatsSortTooltip";
import TagTypeTooltip from "./tagTypeTooltip";

class TagStatsTable extends Component {
  state = {};

  render() {
    return (
      <div class="bg-gray-100 p-20 pb-10 lg:p-25 lg:pb-15 mb-40">
        {/* {# Table Header #} */}
        <div class="flex flex-col md:flex-row items-start md:items-center justify-between mb-30 lg:mb-40">
          {/* {# Title #} */}
          <div class="flex-1 flex items-center mb-15 md:mb-0">
            <div class="sort-module text-label-lg mr-30">
              <span class="text-gray-400 sort-label uppercase mr-5">Stats for</span>
              <Tooltip
                arrow={false}
                open={this.props.isTagTypeTippyOpen}
                interactive={true}
                position={"bottom-end"}
                trigger={"click"}
                useContext={true}
                // hideOnClick={true}
                html={
                  <TagTypeTooltip
                    changeTagType={this.props.changeTagType}
                    closeTippy={this.props.closeTagTypeTippy}
                  ></TagTypeTooltip>
                }
                allowHTML={true}
                onUntrigger={this.props.toggleTagTypeTippy}
                onRequestClose={this.props.toggleTagTypeTippy}
              >
                <button
                  class="sort-toggle uppercase js-dropdown-toggle"
                  onClick={this.props.toggleTagTypeTippy}
                  data-tippy-html="#attr_sort"
                  data-tippy-placement="bottom"
                >
                  {this.props.selectedTagType}
                  <span class="sort-toggle-icon icon-chevron-down"></span>
                </button>
              </Tooltip>
            </div>
            <span class="flex-none text-xs">Showing {this.props.tags?.length}</span>
          </div>

          {/* {# Sort #} */}
          <div class="flex-none pr-20">
            <div class="sort-module">
              <span class="text-gray-400 sort-label mr-5">Sort by</span>
              <Tooltip
                arrow={false}
                open={this.props.isStatsSortTippyOpen}
                interactive={true}
                position={"bottom-end"}
                trigger={"click"}
                useContext={true}
                // hideOnClick={true}
                html={
                  <TagStatsSortTooltip
                    sortStatsBy={this.props.sortStatsBy}
                    closeTippy={this.props.closeStatsSortTippy}
                  ></TagStatsSortTooltip>
                }
                allowHTML={true}
                onUntrigger={this.props.toggleStatsSortTippy}
                onRequestClose={this.props.toggleStatsSortTippy}
              >
                <button
                  class="sort-toggle js-dropdown-toggle"
                  onClick={this.props.toggleStatsSortTippy}
                  data-tippy-html="#purchase_sort"
                  data-tippy-placement="bottom"
                >
                  {this.props.sortText}
                  <span class="sort-toggle-icon icon-chevron-down"></span>
                </button>
              </Tooltip>
            </div>
          </div>
        </div>

        {/* // {# Table #} */}
        <div class="table-wrap ds-scrollbar">
          <table class="stats-table w-full">
            <thead class="text-left">
              <tr>
                <th class="text-label-sm">Name</th>
                <th class="text-label-sm">
                  <span className="hidden md:block">Plays</span>
                  <div class="block md:hidden track-stat-item js-tooltip" title="Plays">
                    <span class="icon-play"></span>
                  </div>
                </th>
                <th class="text-label-sm">
                  <span className="hidden md:block">Likes</span>
                  <div class="block md:hidden track-stat-item js-tooltip" title="Favorites">
                    <span class="icon-like"></span>
                  </div>
                </th>
                <th class="text-label-sm">
                  <span className="hidden md:block">Downloads</span>
                  <div class="block md:hidden track-stat-item js-tooltip" title="Downloads">
                    <span class="icon-download"></span>
                  </div>
                </th>
                <th class="text-label-sm">
                  <span className="hidden md:block">Projects</span>
                  <div class="block md:hidden track-stat-item js-tooltip" title="Projects">
                    <span class="icon-projects"></span>
                  </div>
                </th>
                <th class="text-label-sm">
                  <span className="hidden md:block">Licenses</span>
                  <div class="block md:hidden track-stat-item js-tooltip" title="Licenses">
                    <span class="icon-download"></span>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody>
              {!this.props.isLoaded ? (
                <tr style={{ height: "475px", width: "100%" }}>
                  <td style={{ height: "475px" }} colSpan={6}>
                    <BackdropOverlay invisible={true} loading={!this.props.isLoaded} />
                  </td>
                </tr>
              ) : (
                this.props.tags.map((tag) => (
                  <tr>
                    <td>{tag.name}</td>
                    <td>{tag.plays}</td>
                    <td>{tag.likes}</td>
                    <td>{tag.downloads}</td>
                    <td>{tag.projects}</td>
                    <td>{tag.licenses}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>

        {/* // {# Pagination #} */}
        <SalesPagination
          pageLimit={6}
          currentPage={this.props.currentPage}
          totalRecords={this.props.totalTags}
          gotoPage={this.props.goToPage}
        />
      </div>
    );
  }
}
export default TagStatsTable;

import React, { Component } from "react";


const CreateProjectModal = (props) => {

  const {
    handleClose,
    handleInputChange,
    newProjectName,
    newProjectNameError,
    submitNewProject
  } = props;

  return (

      <React.Fragment>
        {/* <div id="create_project_modal" aria-hidden="false" className="modal micromodal-slide is-open"> */}

        {/* {# Modal Overlay #} */}
        {/* <div tabindex="-1" data-micromodal-close="create_project_modal" className="modal-overlay"> */}

        {/* {# Modal Container #} */}
        {/* <div role="dialog" aria-modal="true" aria-labelledby="create_project_title" className="modal-container"> */}

        {/* {# Modal Header #} */}
        <header className="modal-header">
          <h2 id="create_project_title" className="modal-title">Create a Project</h2>
          <button type="button" aria-label="Close Modal" data-micromodal-close="create_project_modal" className="modal-close" type="button" onClick={handleClose}></button>
        </header>

        {/* {# Modal Content #} */}
        <div id="create_project_content" className="modal-content">

          {/* {# Form #} */}
          <form action="" className="ds-form">

            {/* {# Title #} */}
            <div className="input-group-sm input-inline">
              <input
                autoFocus={true}
                id="project_title"
                name="newProjectName"
                className="form-input"
                placeholder="Project Name"
                value={newProjectName}
                onChange={handleInputChange}
                required
              />
              <label className="input-label" htmlFor="newProjectName" >Project Title</label>
              <div className="error-message">{newProjectNameError}</div>
            </div>

            {/* {# Buttons #} */}
            <div className="input-group pt-20">
              <div className="grid-row-sm">
                <div className="col w-full">
                  <button type="button" onClick={(e) => { e.preventDefault(); submitNewProject(handleClose) }} className="btn btn-primary form-btn w-full">Create</button>
                </div>
              </div>
            </div>

          </form>
          {/* {# End Form #} */}

        </div>
        {/* {# End Modal Content #} */}

        {/* </div> */}
        {/* {# End Modal Container #} */}
        {/* </div> */}
        {/* {# End Modal Overlay #} */}
        {/* </div> */}
      </React.Fragment>
  )
}
export default CreateProjectModal;

import React, { Component } from "react";
// import {NavLink} from "react-router-dom"
class AdminCollectionTrackTooltip extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {

            }

    render() {
        return (

                <div className="dropdown-menu">
                    <div className="dropdown-group">
                            <a href="#" onClick={()=>{this.props.removeTrack(this.props.collectionTrackId);this.props.toggleCollectionTrackTippy()}}>Remove Track</a>
                    </div>                
                 
                </div>

        )
    }

}
export default AdminCollectionTrackTooltip
import React, { Component } from "react";

class StatsSortTooltip extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {

            }

    render() {
        return (    
                <div className="dropdown-menu">
                    <div className="dropdown-group">
                        <a onClick={()=>{this.props.sortStatsBy("MostE");this.props.closeTippy()}} href="#">Most Earned</a>
                        <a onClick={()=>{this.props.sortStatsBy("MostP");this.props.closeTippy()}} href="#">Most Played</a>
                        <a onClick={()=>{this.props.sortStatsBy("MostL");this.props.closeTippy()}} href="#">Most Liked</a>
                        <a onClick={()=>{this.props.sortStatsBy("MostD");this.props.closeTippy()}} href="#">Most Downloaded</a>
                        <a onClick={()=>{this.props.sortStatsBy("MostPr");this.props.closeTippy()}} href="#">Most Projects</a>
                        <a onClick={()=>{this.props.sortStatsBy("MostLic");this.props.closeTippy()}} href="#">Most Licensed</a>
                    </div>
                </div>
        )
    }

}
export default StatsSortTooltip
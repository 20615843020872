import { useEffect, useRef, useState } from "react";
import productsAPI from "../../api/products";
import { handleAPIError } from "../../utils/handleAPIError";

export const useGetLicenseProductData = ({ key }) => {
  const cache = useRef({});
  const [status, setStatus] = useState("idle");
  const [data, setData] = useState(null);

  // console.log('useGetLicenseProductData cache', cache);

  useEffect(() => {
    if (!key) return;

    setStatus("fetching");

    if (cache.current[key]) {
      const data = cache.current[key];
      setData(data);
      setStatus("fetched");
    } else {
      productsAPI
        .getProductByKey({ key })
        .then((response) => {
          // console.log('useGetLicenseProductData response', response);
          const fetchedData = response?.data;
          cache.current[key] = fetchedData;
          setData(fetchedData);
          setStatus("fetched");
        })
        .catch((error) => {
          handleAPIError(error);
          setStatus("fetched");
        });
    }
  }, [key]);

  return { data, status };
};

import React from "react";
import { DateTime } from "luxon";
import { Tooltip } from "react-tippy";
import currencyFormatter from "../../../utils/currencyFormatter";

const SalesSubscriptionPayoutsRow = ({
  forMonth,
  payedOn,
  transferId,
  status,
  description,
  reversalAmount,
  payoutTotal,
}) => {
  const ISOString = new Date(payedOn).toISOString();
  const payedOnFormatted = DateTime.fromISO(ISOString).toFormat("yyyy-MM-dd");

  let forMonthFormatted = new Date(forMonth).toISOString();
  forMonthFormatted = DateTime.fromISO(forMonthFormatted).toFormat("LLL yyyy");
  return (
    <tr>
      <td>{forMonthFormatted}</td>
      <td>{transferId}</td>
      {status === "ineligible" ? (
        <td>
          <Tooltip
            useContext={true}
            animation="shift"
            arrow="true"
            position="top"
            html={
              <>
                <div className="price-tool-title mb-10">Not Eligible</div>
                <div className="text-gray-400 price-tool-desc">
                  You did not meet the minimum requirements to be eligible for your monthly Artist
                  Fee.
                </div>
              </>
            }
          >
            {`${description} `}
            <span className="icon-help"></span>
          </Tooltip>
        </td>
      ) : (
        <td>{reversalAmount ? `Transfer ` + description : description}</td>
      )}
      <td>{payedOnFormatted}</td>
      {reversalAmount ? (
        <td className="text-right">{currencyFormatter((payoutTotal - reversalAmount) / 100)}</td>
      ) : (
        <td className="text-right">{currencyFormatter(payoutTotal / 100)}</td>
      )}
    </tr>
  );
};

export default SalesSubscriptionPayoutsRow;

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { CartConsumer } from "../../contexts/Cart";
import { FavoritesConsumer } from "../../FavoritesContext/index";
import { PlayerContext } from "../../PlayerContext";
import { UserConsumer } from "../../contexts/User";
import CircularIndeterminant from "../Loading/circularIndeterminant";
import LicenseModal from "../modal/LicenseModal";
import { Error, Success } from "../Toast/index";
import api from "../utils/api";
import BrowseWaveform from "../Waveform/trackBrowse";
import BrowseWaveformNoInteract from "../Waveform/trackBrowseNoInteract";
import VideoStudioMediaPlayer from '../Waveform/videoStudioTrackDetails';
import AboutTab from "./aboutTab";
import LicensesTab from "./licensesTab";
import StemsTab from "./stemsTab";
import DetailsTab from "./tabDetails";
import TrackDetailHead from "./TrackDetailHelmet";
import TrackIcons from "./TrackIcons";
import TrackGridItem from "./trackGridItem";
import TrackListItem from "./trackListItem";
import VersionsTab from "./versionsTab";
import DownloadTrack from "./DownloadTrack";

const queryString = require('query-string');

class Details extends Component{
    state={
        activeTab: "details",
        following: "",
        track:{},
        versions: [],
        genres: [],
        speed: [],
        city: [],
        lyrics: [],
        emotion: [],
        moods: [],
        instruments: [],
        attributes: [],
        funFact: "",
        stems: [],
        moreTracks: [],
        similarTracks: [],
        moreFromArtistDis: "list",
        relatedTrackDis: "grid"
    }
  componentDidMount = () => {

    let isDownloadTrackOpen = false;

    const parsedQuery = queryString.parse(this.props.location.search)
    const parsedQueryKeys = Object.keys(parsedQuery);
    if (parsedQueryKeys.includes('downloadTrack')) {
      isDownloadTrackOpen = true;
    }
    console.log('parsedQuery keys', parsedQueryKeys);


        //
        // let appMainEl = document.getElementsByClassName('app-main')[0]
        // appMainEl.addEventListener('scroll', this.handleScroll)
        api.getTrackForDetailPage(this.props.match.params.trackURL)
        .then(res=>{


            let track
            if(res.data.track){
                track =res.data.track
                track.idKey = "mainTrack-"+track.trackId
            }
            else{
                track = {}
            }
            let tags
            if(track.tags){
                tags = res.data.track.tags
            }
            else{
                tags = []
            }
            let composers =[]
            if(track.composers){
                composers = track.composers
            }
            let moreTracks = res.data.moreTrackIds?.map(track=>track.trackId) || []
            let similarTracks = res.data.similarTrackIds?.map(track=>track.trackId) || []
                    // moreTracks = response.data
            this.context.getTracksInfo([track.trackId, ...moreTracks, ...similarTracks])
            this.setState({
                track: track,
                genres: (tags.filter(genre=>genre.type ===1) || []),
                speed: (tags.filter(speed=>speed.type===8) || []),
                city: tags.filter(city=>city.type===6) || [],
                emotion: (tags.filter(emotion=>emotion.type ===2) || []),
                moods: (tags.filter(mood=>mood.type===3) || []),
                instruments: (tags.filter(instrument=>instrument.type===5) || []),
                attributes: tags.filter(attribute=>attribute.type ===4) || [],
                lyrics: tags.filter(lyric=>lyric.type===7) || [],
                funFact: (track.funFact || ""),
                composers: composers,
                versions: track.versions || [],
                stems: track.stems || [],
                moreTracks: moreTracks,
              similarTracks: similarTracks,
              isDownloadTrackOpen
            },()=>{
                api.getCustLicenseByTrack(track.trackId)
                .then(res=>{
                    if(res.data?.licenses.length > 6){
                        this.setState({
                            licenses: res.data.licenses.slice(0, 5),
                            moreLicenses: encodeURI('/dashboard/licenses/?trackName=' + track.trackTitle)
                        })
                    }
                    else if (res.data?.licenses.length > 0){
                        this.setState({
                            licenses: res.data.licenses,
                            moreLicenses: false
                        })
                    }
                })
            })
            // api.getFollowingByID(res.data.track.artistId)
            //     .then(res=>{
            //         let following = res.data.following
            //         this.setState({
            //             following: following
            //         })
            //     })
        })


    }
    componentDidUpdate = (prevProps)=>{
        if(prevProps.match.params.trackURL !== this.props.match.params.trackURL){
            this.setState({
                track:{
                    wavURL: null,
                },
                isLoaded: false
            },()=>{


                document.getElementsByClassName('app-main')[0].scrollTo(0,0)
                api.getTrackForDetailPage(this.props.match.params.trackURL)
                .then(res=>{


                    let track
                    if(res.data.track){
                        track =res.data.track
                        track.idKey = "mainTrack-"+track.trackId
                    }
                    else{
                        track = {}
                    }
                    let tags
                    if(track.tags){
                        tags = res.data.track.tags
                    }
                    else{
                        tags = []
                    }
                    let composers =[]
                    if(track.composers){
                        composers = track.composers
                    }
                    let moreTracks = res.data.moreTrackIds?.map(track=>track.trackId) || []
                    let similarTracks = res.data.similarTrackIds?.map(track=>track.trackId) || []
                            // moreTracks = response.data
                    this.context.getTracksInfo([track.trackId, ...moreTracks, ...similarTracks])
                    this.setState({
                        track: track,
                        genres: (tags.filter(genre=>genre.type ===1) || []),
                        speed: (tags.filter(speed=>speed.type===8) || []),
                        city: tags.filter(city=>city.type===6) || [],
                        emotion: (tags.filter(emotion=>emotion.type ===2) || []),
                        moods: (tags.filter(mood=>mood.type===3) || []),
                        instruments: (tags.filter(instrument=>instrument.type===5) || []),
                        attributes: tags.filter(attribute=>attribute.type ===4) || [],
                        lyrics: tags.filter(lyric=>lyric.type===7) || [],
                        funFact: (track.funFact || ""),
                        composers: composers,
                        versions: track.versions || [],
                        stems: track.stems || [],
                        moreTracks: moreTracks,
                        similarTracks: similarTracks,
                    },()=>{
                        api.getCustLicenseByTrack(track.trackId)
                        .then(res=>{
                            if(res.data?.licenses.length > 6){
                                this.setState({
                                    licenses: res.data.licenses.slice(0, 5),
                                    moreLicenses: encodeURI('/dashboard/licenses/?trackName=' + track.trackTitle)
                                })
                            }
                            else if (res.data?.licenses.length > 0){
                                this.setState({
                                    licenses: res.data.licenses,
                                    moreLicenses: false
                                })
                            }
                        })
                    })
                    // api.getFollowingByID(res.data.track.artistId)
                    //     .then(res=>{
                    //         let following = res.data.following
                    //         this.setState({
                    //             following: following
                    //         })
                    //     })
                })

                })
        }
    }
    changeTrack = (track) => {
        this.context.showPlayer()


        if (track.trackId) {
            if(this.context.mediaPlayer.trackId !== track.trackId){
                api.getAudio("mp3", track.trackId)
                .then(res => {

                    track.cloudfrontURL=res.data.url
                    this.context.loadTrackInMediaPlayer(track.trackId, track.idKey)
                    this.context.setAutoPlay(false)

                })
            }
            else{
                this.context.playPause()
            }

        }
    }

    addToProject = (trackId) => {
        Error({message: "Projects don't exist yet."})
        this.setState({showTrackTippy: false})
    }

    likeTrack = (trackId)=>{
        api.likeTrack(trackId)
            .then(res=>{

                if(res.data.success){
                    if(trackId === this.context.loadedTracks[this.state.track.trackId]?.trackId){
                        let track = this.state.track

                        track.liked = 1
                        this.setState({track: track})
                    }else{
                        let tracks = this.state.moreTracks.concat()
                        tracks.forEach((track, index)=>{
                            if(track.trackId === trackId){
                                tracks[index].liked = 1
                            }
                        })
                        this.setState({moreTracks: tracks})
                    }
                }
            })
    }
    unlikeTrack = (trackId)=>{
        api.unlikeTrack(trackId)
            .then(res=>{
                if(res.data.success){
                    if(trackId === this.context.loadedTracks[this.state.track.trackId]?.trackId){
                        let track = this.state.track

                        track.liked = 0
                        this.setState({track: track})
                    }else{
                        let tracks = this.state.moreTracks.concat()
                        tracks.forEach((track, index)=>{
                            if(track.trackId === trackId){
                                tracks[index].liked = 0
                            }
                        })
                        this.setState({moreTracks: tracks})
                    }
                }
            })
    }

    shareTrack = ()=>{
        var textArea = document.createElement("textarea");

        // Place in top-left corner of screen regardless of scroll position.
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em';
        textArea.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = 0;

        // Clean up any borders.
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';

        // Avoid flash of white box if rendered for any reason.
        textArea.style.background = 'transparent';


        textArea.value = window.location.href;

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          var successful = document.execCommand('copy');
          if(successful){
              Success({message: "Track URL Copied to Clipboard"})
          }
          else{
              Error({message: "Couldn't Copy Track URL "})
          }
          var msg = successful ? 'successful' : 'unsuccessful';

        } catch (err) {

        }

        document.body.removeChild(textArea);

        this.setState({showTrackTippy: false})

    }
    toggleTrackTippy = ()=>{
        this.setState({showTrackTippy: !this.state.showTrackTippy})
    }
    // componentWillUnmount = ()=>{
    //     let appMainEl = document.getElementsByClassName('app-main')[0]
    //     appMainEl.removeEventListener('scroll', this.handleScroll)
    // }
    resolveAlbumPhoto=(albumPhotoKey)=>{
        const key = albumPhotoKey
        if(albumPhotoKey){
            const ext = key.split('.').reverse()[0];
            let final = key.replace('.' + ext, '') + '-minified-500x500'  + '.' + ext
            return final
        }
    }
    resolveCoverPhoto=(coverPhotoKey)=>{
        const key = coverPhotoKey
        if(coverPhotoKey){
            const ext = key.split('.').reverse()[0];
            let final = key.replace('.' + ext, '') + '-minified' + '.' + ext
            return final
        }
    }
    handleLicenseModalOpen=()=>{
        this.setState({isLicenseModalOpen: true})
    }
    handleLicenseModalClose=()=>{
        this.setState({isLicenseModalOpen: false})
    }

  render() {
    const currentTrack = this.context.loadedTracks[this.state.track.trackId];
    const {
      genres,
      emotion,
      moods,
      speed,
      attributes,
      instruments
    } = this.state;
    return (
      <UserConsumer>
        {({user, hasActiveSubscription, triggerLoginModal})=>
          <FavoritesConsumer>
            {({artists, tracks, albums, collections, likeTrack, unlikeTrack, followArtist, unfollowArtist}) => (
              <>
                {currentTrack?.trackTitle &&
                  <TrackDetailHead
                    currentTrack={currentTrack}
                    genres={genres}
                    emotion={emotion}
                    moods={moods}
                    speed={speed}
                    attributes={attributes}
                    instruments={instruments}
                  />
                }
                {currentTrack?.peaks ? <>
                <div className="hero-bg">
                    <div className="hero-img" style={{backgroundImage: `url("https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveCoverPhoto(this.state.track?.coverPhoto)}")`}}></div>
                </div>
                <div className="container pt-100 md:pt-300 z-10">

{/* // {# Track View Header #} */}
<div className="track-view-header mb-50 md:mb-80">

    {/* {# Track View Cover #}                     */}
    <div className="track-view-cover" style={{width: "18.75rem"}}>

        {/* {# Add class 'is-playing' to make pause overlay persistent #} */}
        <div className={`audio-cover ${((this.context.mediaPlayer.idKey?.indexOf("track-" + this.context.loadedTracks[this.state.track.trackId]?.trackId) ||-1)  !== -1 && (this.context.mediaPlayer.isTrackPlaying || this.context.isVideoPlaying)) ? "is-playing": ""}`}>

            {/* {# Artwork #} */}

            <img className="audio-cover-img" src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveAlbumPhoto(this.context.loadedTracks[this.state.track.trackId]?.imageURL)}`} alt="albumArt"/>

            {/* {# Overlay Controls #} */}
            <div className={`audio-cover-controls `}>
                {!((this.context.mediaPlayer.idKey?.indexOf("track-" + this.context.loadedTracks[this.state.track.trackId]?.trackId) ||-1)  !== -1 && (this.context.mediaPlayer.isTrackPlaying || this.context.isVideoPlaying)) ?
                <button style={{display: "flex"}} onClick={()=>{(this.context.mediaPlayer.idKey?.indexOf("track-"+ this.context.loadedTracks[this.state.track.trackId]?.trackId) || -1) !== -1 ?
                    this.context.handlePlay():
                    this.context.loadTrackInMediaPlayer(this.context.loadedTracks[this.state.track.trackId]?.trackId, `artist-${this.context.loadedTracks[this.state.track.trackId]?.artistId}-album-${this.context.loadedTracks[this.state.track.trackId]?.albumId}-track-${this.state.track.trackId}-element-trackDetailItem`);this.context.setAutoPlay(false)}} className="circle-icon-fill circle-icon-xl bg-white icon-play text-black" type="button">
                </button>:
                <button onClick={()=>this.context.handlePause()} className="circle-icon-fill circle-icon-xl bg-white icon-pause text-black" type="button">
                </button>
                }
            </div>

        </div>

    </div>

    {/* {# Track View Info #} */}
    <div className="track-view-info">
        <div className="grid-row">

            {/* {# Track View Details #} */}
            <div className="col w-full sm:w-auto sm:flex-1 mb-25">
                <div className="track-view-title">
                    <h1 className="text-h3 md:text-h2 mb-5">{this.context.loadedTracks[this.state.track.trackId]?.trackTitle}</h1>
                    <ul className="text-h4">
                        <NavLink to={`/artists/${this.context.loadedTracks[this.state.track.trackId]?.artistURL}`} className="text-link-white">{this.context.loadedTracks[this.state.track.trackId]?.artistName}</NavLink>
                    </ul>
                    <div>
                        <NavLink to={`/artists/${this.context.loadedTracks[this.state.track.trackId]?.artistURL}#album-${this.context.loadedTracks[this.state.track.trackId]?.albumTitle?.replace(/\s/g, "-")}`} className="text-link-dark">{this.context.loadedTracks[this.state.track.trackId]?.albumTitle}</NavLink>
                    </div>
                </div>
            </div>

            {/* {# Track View CTA #} */}
            <div className="col w-full sm:w-auto sm:flex-none mb-25">
                <div className="track-view-cta">
                    <DownloadTrack
                      artistId={currentTrack.artistId}
                      artistName={currentTrack.artistName}
                      className="btn btn-primary flex-1 mr-20 sm:mr-0 sm:flex-none sm:mb-20"
                                imageURL={currentTrack.imageURL}
                                isOpen={this.state.isDownloadTrackOpen}
                      text={hasActiveSubscription(user) ? (
                        'Download Track'
                      ) : (
                        <><span className="icon-add-to-cart"></span>{' '}License Track</>
                      )}
                      trackId={currentTrack.trackId}
                      trackTitle={currentTrack.trackTitle}
                    />
                    <CartConsumer>
                        {({handleAddedToCartModalClose}) =>(
                    <SlidingPane
                        isOpen={this.state.isLicenseModalOpen}
                        onRequestClose={()=>{this.handleLicenseModalClose();handleAddedToCartModalClose()}}
                        // className={"test"}
                        from={"right"}
                        width={"100%"}
                        children={<LicenseModal handleClose={()=>{this.handleLicenseModalClose();handleAddedToCartModalClose()}} track={{trackId: this.context.loadedTracks[this.state.track.trackId]?.trackId, trackTitle: this.context.loadedTracks[this.state.track.trackId]?.trackTitle, artistId: this.context.loadedTracks[this.state.track.trackId]?.artistId, artistName: this.context.loadedTracks[this.state.track.trackId]?.artistName, albumArt: this.context.loadedTracks[this.state.track.trackId]?.imageURL}} />}
                    >
                    </SlidingPane>
                      )}</CartConsumer>

                      <TrackIcons
                        artistId={currentTrack.artistId}
                        artistName={currentTrack.artistName}
                        isFeatured={currentTrack.isFeatured}
                        imageURL={currentTrack.imageURL}
                        itemType='grid'
                        remove={['license']}
                        trackId={currentTrack.trackId}
                        trackTitle={currentTrack.trackTitle}
                        trackURL={currentTrack.trackURL}
                      />
                </div>
            </div>

            {/* {# Track Details Dropdown #} */}

            {/* {% include "components/dropdowns/track-detail-dropdown.html" %} */}

        </div>

        {/* {# Track Waveform #} */}
        <div className="track-preview track-preview-sm mb-40">
            <div className="track-preview-waveform">
            {this.context.loadedTracks[this.state.track.trackId] ?
                this.context.mediaPlayer.idKey?.indexOf("track-" +this.context.loadedTracks[this.state.track.trackId]?.trackId) !==-1 ?
                    <>
                        {this.context.isMainOpen ?
                            <VideoStudioMediaPlayer history={this.props.history} user={user} playVideo={this.context.playVideo} playVideoFromIn={this.context.playVideoFromIn} playVideoLoop={this.context.playVideoLoop} isVideoPlaying={this.context.isVideoPlaying} pauseVideo={this.context.pauseVideo} currentTrack={this.context.mediaPlayer} triggerLoginModal={triggerLoginModal}  playing={this.context?.playing} artistName={this.context.mediaPlayer.artistNameDisplay} trackTitle={this.context?.mediaPlayer?.trackTitle} duration={this.context.mediaPlayer?.duration} setCurrentTime={this.setCurrentTime} play={this.state.play} src={this.context?.mediaPlayer?.cloudfrontURL} peaks={this.context.mediaPlayer?.peaks} videoElement={this.context.videoElement} seekTo={this.context.seekTo}
                            isLoopEnabled={this.context.isLoopEnabled}></VideoStudioMediaPlayer>:
                            <BrowseWaveform idKey={(this.context.mediaPlayer.idKey?.indexOf("track-" + this.context.loadedTracks[this.state.track.trackId]?.trackId) || -1) !==-1 ? this.context.mediaPlayer.idKey : this.context.loadedTracks[this.state.track.trackId]?.idKey}   trackId={this.context.loadedTracks[this.state.track.trackId]?.trackId} s3Key={this.context.loadedTracks[this.state.track.trackId]?.wavURL} peaks={(this.context.loadedTracks[this.state.track.trackId]?.peaks)}/>
                        }
                    </>:
                    <BrowseWaveformNoInteract idKey={(this.context.mediaPlayer.idKey?.indexOf("track-" + this.context.loadedTracks[this.state.track.trackId]?.trackId) || -1) !==-1 ? this.context.mediaPlayer.idKey : this.context.loadedTracks[this.state.track.trackId]?.idKey}   trackId={this.context.loadedTracks[this.state.track.trackId]?.trackId} s3Key={this.context.loadedTracks[this.state.track.trackId]?.wavURL} peaks={(this.context.loadedTracks[this.state.track.trackId]?.peaks)}/>:
                                    <></>
            }
                {/* {% include "components/audio/waveform-xl.html" %} */}
            </div>
            <div className="track-preview-duration">
                {this.context.loadedTracks[this.state.track.trackId]?.duration}
            </div>
        </div>

        {/* {# Track Tabs #} */}
        {/* {% include "components/tabs/track-view-tabs.html" %} */}
        <div className="tabs js-tabs">
    <ul className="tab-items">
        <li onClick={()=>{this.setState({activeTab: "details"})}} className={`tab-item ${this.state.activeTab === "details" && 'active'}`}>
            <a href="# " className="tab-item-title">Details</a>
        </li>
        <li onClick={()=>{this.setState({activeTab: "about"})}} className={`tab-item ${this.state.activeTab === "about" && 'active'}`}>
            <a href="# " className="tab-item-title">About</a>
        </li>
        {this.context.loadedTracks[this.state.track.trackId]?.versions?.length > 0 &&
            <li onClick={()=>{this.setState({activeTab: "versions"},()=>this.context.getVersionInfo(this.context.loadedTracks[this.state.track.trackId]?.trackId))}} className={`tab-item ${this.state.activeTab === "versions" && 'active'}`}>
                <a href="# " className="tab-item-title">Versions</a>
            </li>
        }
        {this.context.loadedTracks[this.state.track.trackId]?.stems?.length > 0 &&
            <li onClick={()=>{this.setState({activeTab: "stems"},()=>this.context.getStemInfo(this.context.loadedTracks[this.state.track.trackId]?.trackId))}} className={`tab-item ${this.state.activeTab === "stems" && 'active'}`}>
                <a href="# " className="tab-item-title">Stems</a>
            </li>
        }
        {this.context.loadedTracks[this.state.track.trackId]?.videoLink &&
            <li onClick={()=>{this.setState({activeTab: "video"})}} className={`tab-item ${this.state.activeTab === "video" && 'active'}`}>
                <a href="# " className="tab-item-title">Video</a>
            </li>
        }
        {this.state.licenses?.length > 0 &&
           <li onClick={()=>{this.setState({activeTab: "licenses"})}} className={`tab-item ${this.state.activeTab === "licenses" && 'active'}`}>
                <a href="# " className="tab-item-title">Licenses</a>
            </li>
        }
    </ul>
    <div className="tab-content">
        {/* {# Details #} */}
        {this.state.activeTab === "details" &&
        <DetailsTab genres={this.state.genres} speed={this.state.speed} city={this.state.city} emotion={this.state.emotion} moods={this.state.moods}
        instruments={this.state.instruments} attributes={this.state.attributes} lyrics={this.state.lyrics}/>
        }
        {/* {# About #} */}
        {this.state.activeTab === "about" && <AboutTab funFact={this.state.funFact} artist={{artistName: this.context.loadedTracks[this.state.track.trackId]?.artistName, profilePhoto: this.context.loadedTracks[this.state.track.trackId]?.profilePhoto}} artists={this.state.composers}/>}
        {/* {# Versions #} */}
        {this.state.activeTab === "versions" && <VersionsTab currentTime={this.state.currentTime} versions={this.context.loadedTracks[this.context.loadedTracks[this.state.track.trackId]?.trackId].versions} trackTitle={this.context.loadedTracks[this.state.track.trackId]?.trackTitle} artistName={this.context.loadedTracks[this.state.track.trackId]?.artistName} imageURL={this.context.loadedTracks[this.state.track.trackId]?.imageURL} artistId={this.context.loadedTracks[this.state.track?.trackId]?.artistId} albumId={this.context.loadedTracks[this.state.track?.trackId]?.albumId} trackId={this.state.track?.trackId}/>}
        {/* {# Stems #} */}
        {this.state.activeTab === "stems" && <StemsTab currentTime={this.state.currentTime}  stems={this.context.loadedTracks[this.context.loadedTracks[this.state.track.trackId]?.trackId].stems} trackTitle={this.context.loadedTracks[this.state.track.trackId]?.trackTitle} artistName={this.context.loadedTracks[this.state.track.trackId]?.artistName} imageURL={this.context.loadedTracks[this.state.track.trackId]?.imageURL} artistId={this.context.loadedTracks[this.state.track?.trackId]?.artistId} albumId={this.context.loadedTracks[this.state.track?.trackId]?.albumId} trackId={this.state.track?.trackId}/>}
        {this.state.activeTab === "video" && <></>}
        {/* Licenses */}
        {this.state.activeTab === "licenses" && <LicensesTab trackTitle={this.context.loadedTracks[this.state.track.trackId]?.trackTitle} licenses={this.state.licenses} moreLicenses={this.state.moreLicenses} />}
    </div>
</div>
    </div>

</div>

{/* // {# More from Artist #}  */}
{this.state.moreTracks.length > 0 &&
    <div className="mb-50 md:mb-80">
        <div className="tracks-heading mb-20 md:mb-40">
            <NavLink className="tracks-heading-title" to={`/artists/${this.context.loadedTracks[this.state.track.trackId]?.artistURL}`}>
                More From Artist
                <div className="circle-icon-border ml-15"><span className="icon-chevron-right"></span></div>
            </NavLink>
            <div className="btn-group">
                <button onClick={()=>this.setState({moreFromArtistDis: "list"})} className="circle-icon circle-icon-sm">
                    <span className={`icon-list ${this.state.moreFromArtistDis === "list" && 'text-white'}`}
                        // {% if layout == "list" %}text-white{% endif %}
                    ></span>
                </button>
                <button onClick={()=>this.setState({moreFromArtistDis: "grid"})} className="circle-icon circle-icon-sm">
                    <span className={`icon-grid ${this.state.moreFromArtistDis === "grid" && 'text-white'}`}
                        // {% if layout == "grid" %}text-white{% endif %}
                    ></span></button>
            </div>
        </div>
        <div className="track-list-container">
            {this.state.moreFromArtistDis === "list" &&
                this.state.moreTracks.map(track => <TrackListItem
                    key={"browseTrackListGridItemKey-"+track}
                    idKey={`artist-${this.context.loadedTracks[track]?.artistId}-album-${this.context.loadedTracks[track]?.albumId}-track-${track}-element-trackListItemMoreTracks`}
                    likeTrack={this.likeTrack}
                    unlikeTrack={this.unlikeTrack}
                    {...this.context.loadedTracks[track]}
                    otherTracks={this.state.moreTracks}
                    currentTime={this.state.currentTime}
                    changeTrack={this.changeTrack}>
                </TrackListItem>)
            }
            {this.state.moreFromArtistDis === "grid" &&
                <div className="grid-row">
                    {this.state.moreTracks.map(track=><div className="col w-full sm:w-1/2 md:w-1/3 mb-20 md:mb-40"><TrackGridItem
                        key={"browseTrackListGridItemKey-"+track}
                        idKey={`artist-${this.context.loadedTracks[track]?.artistId}-album-${this.context.loadedTracks[track]?.albumId}-track-${track}-element-trackGridItemMoreTracks`}
                        likeTrack={this.likeTrack}
                        unlikeTrack={this.unlikeTrack}
                        {...this.context.loadedTracks[track]}
                        otherTracks={this.state.moreTracks}
                        currentTime={this.state.currentTime}
                        changeTrack={this.changeTrack}>
                    </TrackGridItem></div>)}
                </div>
            }
        </div>
    </div>
}
{/* // {# Similar Tracks #} */}
{this.state.similarTracks.length > 0 &&
<div className="mb-30 md:mb-40">

    <div className="tracks-heading mb-20 md:mb-40">
            <a href="# " className="tracks-heading-title">
                Similar Tracks
            </a>
            <div className="btn-group">
                <button onClick={()=>this.setState({relatedTrackDis: "list"})} className="circle-icon circle-icon-sm">
                    <span className={`icon-list ${this.state.relatedTrackDis === "list" && 'text-white'}`}
                        // {% if layout == "list" %}text-white{% endif %}
                    ></span>
                </button>
                <button onClick={()=>this.setState({relatedTrackDis: "grid"})} className="circle-icon circle-icon-sm">
                    <span className={`icon-grid ${this.state.relatedTrackDis === "grid" && 'text-white'}`}
                        // {% if layout == "grid" %}text-white{% endif %}
                    ></span></button>
            </div>
        </div>
        <div className="track-list-container">
            {this.state.relatedTrackDis === "list" &&
                this.state.similarTracks.map(track=><TrackListItem key={"browseTrackListGridItemRelated-"+track}  idKey={`artist-${this.context.loadedTracks[track]?.artistId}-album-${this.context.loadedTracks[track]?.albumId}-track-${track}-element-trackListItemRelated`}                                                    likeTrack={this.likeTrack} unlikeTrack={this.unlikeTrack} {...this.context.loadedTracks[track]} currentTime={this.state.currentTime} changeTrack={this.changeTrack}
                otherTracks={this.state.similarTracks}></TrackListItem>)
            }
            {this.state.relatedTrackDis === "grid" &&
                <div className="grid-row">
                    {this.state.similarTracks.map(track=><div className="col w-full sm:w-1/2 md:w-1/3 mb-20 md:mb-40"><TrackGridItem key={"browseTrackListGridItem-"+track}  likeTrack={this.likeTrack}
                                    otherTracks={this.state.similarTracks} idKey={`artist-${this.context.loadedTracks[track]?.artistId}-album-${this.context.loadedTracks[track]?.albumId}-track-${track}-element-trackListItemRelated`}
                                    unlikeTrack={this.unlikeTrack} {...this.context.loadedTracks[track]} currentTime={this.state.currentTime} changeTrack={this.changeTrack}></TrackGridItem></div>)}
                </div>
            }
        </div>
    </div>
}

</div>
</>: <div style={{marginTop: "20%"}}><CircularIndeterminant forceCenter/></div>}
</>
                    )}
</FavoritesConsumer>}</UserConsumer>
        )
    }
}

Details.contextType = PlayerContext
export default Details

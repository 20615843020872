import React, { Component } from "react"
import Autocomplete from "../Collections/autocomplete"
class SearchBar extends Component{
    render (){
        return(
            <form action="" class="ds-form mb-20 md:mb-30">
            <div class="form-search">
                {this.props.useAutocomplete || this.props.useCustLicAutocomplete ?
                    <input name="search" type="text" id="search" class="form-input" placeholder={this.props.placeholder} value={this.props.value} onChange={this.props.onChange}
                        onKeyDown={(e)=>this.props.onKeyDown(e)}/>
                    :
                    <input name="search" type="text" id="search" class="form-input" placeholder={this.props.placeholder} value={this.props.value} onChange={this.props.onChange}
                       />
                }
                {this.props.useAutocomplete && 
                    <Autocomplete options={this.props.options} activeOption={this.props.activeOption} onClick={this.props.onClick}/>}
                {/* {this.props.useCustLicAutocomplete && 
                    <CustLicenseAutocomplete options={this.props.options} activeOption={this.props.activeOption} onClick={this.props.onClick}/>} */}
            </div>
        </form>
        )
    }
}
export default SearchBar
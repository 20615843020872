import React, { Component } from "react";

class SortToolTip extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {

            }

    render() {
        return (    
                <div className="dropdown-menu">
                    <div className="dropdown-group">
                        {this.props.sortFilters.map(sortFilter=>{
                            return(
                                <a href="#" onClick={()=>this.props.sortBy(sortFilter.filter, sortFilter.displayText, false, this.props.sortTippyCB, this.props.sortChoice)}>{sortFilter.displayText}</a>
                            )
                        })}
                        {/* <a href="#" onClick={()=>this.props.sortBy("Date Approved - Asc", false)}>Date Approved - Asc</a>
                        <a href="#" onClick={()=>this.props.sortBy("Date Approved - Desc", false)}>Date Approved - Desc</a>
                        <a href="#" onClick={()=>this.props.sortBy("Name - Asc", false)}>Name - Asc</a>
                        <a href="#" onClick={()=>this.props.sortBy("Name - Desc", false)}>Name - Desc</a>
                        <a href="#" onClick={()=>this.props.sortBy("Likes - Asc", false)}>Likes - Asc</a>
                        <a href="#" onClick={()=>this.props.sortBy("Likes - Desc", false)}>Likes - Desc</a>
                        <a href="#" onClick={()=>this.props.sortBy("Downloads - Asc", false)}>Downloads - Asc</a>
                        <a href="#" onClick={()=>this.props.sortBy("Downloads - Desc", false)}>Downloads - Desc</a>
                        <a href="#" onClick={()=>this.props.sortBy("Plays - Asc", false)}>Plays - Asc</a>
                        <a href="#" onClick={()=>this.props.sortBy("Plays - Desc", false)}>Plays - Desc</a>
                        <a href="#" onClick={()=>this.props.sortBy("Projects - Asc", false)}>Projects - Asc</a>
                        <a href="#" onClick={()=>this.props.sortBy("Projects - Desc", false)}>Projects - Desc</a>
                        <a href="#" onClick={()=>this.props.sortBy("Licenses - Asc", false)}>Licenses - Asc</a>
                        <a href="#" onClick={()=>this.props.sortBy("Licenses - Desc", false)}>Licenses - Desc</a> */}
                    </div>
                </div>
        )
    }

}
export default SortToolTip
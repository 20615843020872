import React from "react";
export default function VideoStudioLearnMore(props){
    let linkText = props.children || 'Learn more'
    return(
        <a
            data-preventopen={true}
            className={props.red ? "text-link": ""}
            target="_blank"
            href={"http://help.deepsounds.com/en/articles/5026156-video-studio"}
        >
            {props.underline ?
                <span style={{textDecoration: "underline"}}>{linkText}</span>
            :
                <span >{linkText}</span>
            }
        </a>
    )
}

import React from "react";
import { Controller, useForm } from "react-hook-form";
import { Error, Success } from "../Toast/index";
import api from "../utils/api";
import Autocomplete from "./autocomplete";

export default function CollaboratorEmailInput(props){
    const { control, register, handleSubmit, watch, setError, setValue, errors, reset, trigger } = useForm();
    const onSubmit = (data, e)=>{
        
        e.preventDefault()
        let emailBody = {email: data.collaboratorEmail, name: props.projectName}
        api.inviteCollaborator(props.projectId, emailBody)
            .then(res=>{
                
                if(res.data.success){
                    Success({message: data.collaboratorEmail + " Invited"})
                    // e.target.reset()
                    setValue("collaboratorEmail", "")
                    props.updateLatest(props.projectId)
                    if(res.data.notUser){
                        props.addCollaboratorToState(data.collaboratorEmail, res.data.invitedUser.projectCollaborators[0].id, props.projectId)
                    }
                    else if(res.data.newCollab){
                        props.addCollaboratorToState(data.collaboratorEmail, res.data.invitedUser.projectCollaborators[0].id, props.projectId)
                    }
                    else{
                        props.addCollaboratorToState(data.collaboratorEmail, res.data.invitedUser.id, props.projectId)
                    }
                }
            })
            .catch(error=>{
                
                if(error.response.status === 406){
                    Error({message: "User Already Invited!"})
                    setError("collaboratorEmail",{message: "User already invited"})
                }
                else if(error.response.status === 401){
                    Error({message: "Unauthorized!"})
                }
                else{
                    Error({message: "An error occured"})
                }
            })
    }

    
     
    return(
        <form onSubmit={handleSubmit(onSubmit)}>
              {/* {# Invite by Email #} */}
              <div class="input-inline input-endcap">
                    <Controller
                        control={control}
                        id="add_collab"
                        name="collaboratorEmail"
                        value={props.collaboratorEmail} 
                        render={pro => <input {...pro} class="form-input"  name="collaboratorEmail" onChange={(e)=>{pro.onChange(e);props.handleInputChange(e, props.projectId)}} onKeyDown={(e)=>props.onKeyDown(e, setValue)}/>} 
                        placeholder="Add by Name or Email"
                        rules={{
                            required: "Email is required",
                            pattern: {
                                value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g,
                                message: "Email format is invalid."
                            }
                        }}
                        />
                        {props.options?.length > 0 &&
                            <Autocomplete options={props.options} activeOption={props.activeOption} onClick={props.onClick} setValueCB={setValue}/>
                        }
                    <label class="input-label" for="artist_name">Add by Name or Email</label>
                    {errors.collaboratorEmail && <span className="error-message">{errors.collaboratorEmail.message}</span>}

                    {/* {# Endcap Button #} */}
                    <button class="icon-btn btn-primary input-endcap-btn" type="submit" name="submit">
                        <span class="icon-plus"></span>
                    </button>
                </div>
        </form>
    )
}
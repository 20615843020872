import React, { Component } from "react";
// import tippy from "../../tooltips"

class DownloadTooltip extends Component {
    //the state for the login component keeps track fo the email and password inputs
    state = {

            }
    notLoggedInOpen = ()=>{
        this.props.triggerLoginModal()
        this.props.toggleDownloadTippy()
    }
    render() {
        return (    
                <div className="dropdown-menu">
                    <div className="dropdown-group">
                        <a href="#" onClick={()=>{this.props.toggleDownloadTippy();this.props.downloadFullOnly(this.props.licenseId)}}><span className="icon-download"></span>Download Full Track</a>
                        {this.props.hasVersions &&
                            <a href="#" onClick={()=>{this.props.toggleDownloadTippy();this.props.downloadFullAndVersions(this.props.licenseId)}}><span className="icon-download"></span>Download Versions</a>
                        }
                        {this.props.hasStems &&
                            <a href="#" onClick={()=>{this.props.toggleDownloadTippy();this.props.downloadStems(this.props.licenseId)}}><span className="icon-download"></span>Download Stems</a>
                        }
                        {(this.props.hasVersions || this.props.hasStems) &&
                            <a href="#" onClick={()=>{this.props.toggleDownloadTippy();this.props.downloadAll(this.props.licenseId)}}><span className="icon-download"></span>Download All</a>
                        }
                    </div>
                </div>
        )
    }

}
export default DownloadTooltip
import React from "react"
import Creatable from 'react-select/creatable'
import customStylesMulti from "../FormElements/customStylesMulti"
import { Error } from "../Toast/index"




// Styles for Single Select


const SearchCollections = (props)=>{

const groupedOptions = [
  {
    label: "artists",
    options: props.artistOptions
  },
  {
    label: 'tracks',
    options: props.trackOptions
  },
  {
    label: 'collections',
    options: props.collectionOptions
  }
]
const filterOption = (option, inputValue) => {
  
  const { label, value} = option;
  // const otherKey = options.filter(
  //   opt => opt.label === label && opt.value.includes(inputValue)
  // );
  try{
    
    if(inputValue.length > 2){
      if(!option.data?.type){
        return inputValue.length > 1 ? (label ? label.toLowerCase().includes(inputValue.toLowerCase()): false ): false

      }
      else{
        return inputValue.length > 1 ? (label ? label.toLowerCase().match(new RegExp('^' + inputValue.toLowerCase())): false ): false
      }
     
    }
    else{
      return false
    }
  }
  catch(error){
    return false
  }
};
const resolveProfilePhoto = (profilePhotoKey)=>{
  if(profilePhotoKey){
      const key = profilePhotoKey
      const ext = key.split('.').reverse()[0];
      let final = key.replace('.' + ext, '') + '-minified-60x60' + '.' + ext
      return final
  }
}
const resolveAlbumPhoto = (albumPhotoKey)=>{
  if(albumPhotoKey){
      const key = albumPhotoKey
      const ext = key.split('.').reverse()[0];
      let final = key.replace('.' + ext, '') + '-minified-140x140' + '.' + ext
      return final
  }
}
const resolveCollectionPhoto = (collectionPhotoKey)=>{
  if(collectionPhotoKey){
      const key = collectionPhotoKey
      const ext = key.split('.').reverse()[0];
      let final = key.replace('.' + ext, '') + '-minified-140x140' + '.' + ext
      return final
  }
}
const artistLabel=(value, artistName, profilePhoto)=>(
  <div className="flex items-center" style={value==="placeholder"? {display: "none"}: {}}>
      <img 
          src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${resolveProfilePhoto(profilePhoto)}`} 
          alt={`${artistName} picture`}
          className="w-20 h-20 bg-gray-300 mr-15" 
      />
      {artistName}
  </div>
)
const trackLabel=(value, artistName, profilePhoto)=>(
  <div className="flex items-center" style={value==="placeholder"? {display: "none"}: {}}>
      <img 
          src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${resolveAlbumPhoto(profilePhoto)}`} 
          alt={`${artistName} picture`}
          className="w-20 h-20 bg-gray-300 mr-15" 
      />
      {artistName}
  </div>
)
const collectionLabel=(value, artistName, profilePhoto, url)=>(
  <div onClick={()=>props.onClick(artistName, "collection", url)} className="flex items-center" style={value==="placeholder"? {display: "none"}: {}}>
      <img 
          src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${resolveCollectionPhoto(profilePhoto)}`} 
          alt={`${artistName} picture`}
          className="w-20 h-20 bg-gray-300 mr-15" 
      />
      {artistName}
  </div>
)

const defaultLabel=(label, value, include)=>(
  <div className="flex items-center" style={{color: include || include === undefined ? "" : "red"}}>
      {label}
  </div>
)
const keywordLabel=(label, value)=>(
  <div className="flex items-center">
      "{label}"
 
  </div>
)

const createLabel = ({label, value})=>(
  <></>
)
let currentInputVal = ""

const preventSubmit= (e, values)=>{
  e.preventDefault()
  
  if(currentInputVal.length < 2){
    Error({message: "Search query must be at least two characters"})
  }
  else{
    Error({message: "Search query can only contain alphanumeric characters"})
  }
}
return(
<form  className="ds-form mb-20 md:mb-30" onSubmit={preventSubmit}>
	<div className="browse-search">
		
		{/* {# Search Input #} */}
		<label className="hidden" htmlFor="track_search">Search Tracks</label>
        <div className="form-section" style={{marginBottom: 0, paddingBottom: 0}}>
            <div className="input-group">
                <Creatable
                    name="search"
                    styles={customStylesMulti}
                    classNamePrefix="select"
                    className="select-search" 
                    placeholder={props.placeholder}
                    value={props.searchTerm}
                    options={groupedOptions}
                    id="search"
                    isValidNewOption={(inputValue, selectValue, selectOptions)=>{
                      
                      let allOptions = []
                      selectOptions.filter(option=>{
                        return option.label !== "artists" && option.label !== "tracks" && option.label !== "collections"
                      }).forEach(option=>{
                        let optionsArr = option.options
                        if(optionsArr){
                          allOptions = allOptions.concat(optionsArr.map(opt=>opt.label.toLowerCase()))
                        }
                      })

                      currentInputVal = inputValue
                      if(allOptions.indexOf(inputValue.toLowerCase()) ===-1){
                        if(/^[a-zA-Z0-9\s]+$/.test(inputValue)){
                          return true
                        }
                        else{
               
                          return false
                        }
                      }
                      else{
                        return false
                      }
                   
                    }}
                    onKeyDown={props.searchKeyDown}
                    onInputChange={props.changeSearchInput}
                    //   onFocus={()=>{
                    //     
                    //     if(props.searchTerm?.value && !props.searchInput){
                    //         props.changeSearchInput(props.searchTerm?.value)
                    //     }
                    // }}
                    onClick={props.altChangeSearchInput}

                    inputValue={props.searchInput || ""}
                    onChange={props.handleSearchChange}
                    createOptionPosition={'first'}
                    formatCreateLabel={userInput=>`Search "${userInput}"`}
                    isMulti
                    isSearchable
                    filterOption={filterOption}
                    // formatCreateLabel={(option)=>createLabel(option)}
                    formatOptionLabel={(option)=>{ 
                      switch(option.type){
                        case "artist":
                            return artistLabel(option.value, option.value, option.photo)
                            break;
                        case "track": 
                            return trackLabel(option.value, option.value, option.photo)
                            break;
                        case "collection": 
                            return collectionLabel(option.value, option.value, option.photo, option.url)
                            break;
                        case "keyword":
                            return keywordLabel(option.value, option.value)
                            break;
                        default: 
                            return defaultLabel(option.label, option.value, option.include)
                      }}}
                    // onCreateOption={props.handleSearchChange}
                    noOptionsMessage={() => null}
                    key={`my_unique_select_key__${props.selectedSearchOptions}`}
                />
            </div>
        </div>
	    {/* {# Mobile Filter Toggle #} */}
	
		{/* {# Mobile Filter Menu #} */}
		<div
			className={`filters-panel ${props.filterMenuOpen ? "is-open": 'is-closed'}`}
		>
			<div className="filters-panel-header">
				<div className="text-label-lg">Filters</div>
				<button 
					className="text-label-lg text-link h-full"
                    type="button"
                    onClick={props.toggleFilterMenu}
					// @click="open = false"
				>Done</button>
			</div>
		</div>

	</div>
</form>)}

export default SearchCollections
import React from "react";

export default function Video (props) {

    return (
        <video
            className="w-full h-auto bg-gray-100"
            preload="true"
            muted
            loop
            autoPlay
            muted={true}
            playsInline
            poster={props.image}
        >
            {props.mp4 && <source src={props.mp4} type="video/mp4" />}
            {props.webm && <source src={props.webm} type="video/webm" />}
        </video>
    )
}

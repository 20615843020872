import React from "react";
import "./style.css";
import DownloadForm from "../../Forms/DownloadForm";
import buildAlbumArtURL from "../../Albums/AlbumArt";
import LicensePanel from "./LicensePanel";

const DownloadModal = (props) => {
  // console.log('in downloadmodal')

  const {
    demoSubscription,
    demoSubscriptionTitle,
    handleClose,
    index,
    isDemo,
    isEdit,
    project,
    stem,
    track,
    version,
  } = props;

  // console.log('trackInfo', track);

  const albumArtURL = buildAlbumArtURL(track?.albumArt);

  let licenseTitle = track?.trackTitle;
  let licenseSubtitle = track?.artistName;
  let licenseArtwork = <img src={albumArtURL} alt="" />;
  if (isDemo) {
    licenseArtwork = <span class="text-icon-lg icon-download text-gray-400"></span>;
    licenseTitle = demoSubscriptionTitle ? demoSubscriptionTitle : "Track Licensing Pricing";
    licenseSubtitle = "Use dropdowns below to see pricing";
  }

  return (
    <>
      <LicensePanel
        handleClose={handleClose}
        headerText="Music License"
        licenseArtwork={licenseArtwork}
        licenseSubtitle={licenseSubtitle}
        licenseTitle={licenseTitle}
      >
        <DownloadForm
          demoSubscription={demoSubscription}
          handleClose={handleClose}
          index={index}
          isEdit={isEdit}
          isDemo={isDemo}
          project={project}
          stem={stem}
          track={track}
          version={version}
        />
      </LicensePanel>
    </>
  );
};

export default React.memo(DownloadModal);

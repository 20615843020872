
export default function getLicenseCategoryOptions(
  options,
  licensesIncluded = "[]",
  licensesHidden = "[]",
  subscriber
) {
  // console.log('licensesHidden', licensesHidden);
  licensesHidden = JSON.parse(licensesHidden);
  // console.log('licensesHidden', licensesHidden);
  // console.log('getLicenseCategoryOptions options', options);

  const licenseOptions = [
    {
      options: options
        .filter((item) => !licensesHidden.includes(item.key))
        .map((item) => {
          let option = {
            key: item.key,
            label: item.name,
            desc: item.description,
            rerouteKey: item.rerouteKey,
            rerouteMessage: item.rerouteMessage,
            ...item
          };
          if (licensesIncluded) {
            option.licensesIncluded = JSON.parse(licensesIncluded);
          }
          return option;
        }),
    },
  ];
  if (subscriber) {
    licenseOptions[0].message = "Covered by your subscription";
  }
  return licenseOptions;
}

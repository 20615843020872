import React from "react"
import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js';
import { LabelRequiredVisible, LabelOptionalVisible } from '../FormElements/labels';

export const CardNumberOptions = {
    style:{
        base:{
            color: "#fff",
            fontFamily: `"Scto Grotesk A", -apple-system, BlinkMacSystemFont, Helvetica, sans-serif`,
            fontWeight: 400,
            '::placeholder': {
                fontFamily: `"Scto Grotesk A", -apple-system, BlinkMacSystemFont, Helvetica, sans-serif`,
                fontWeight: 400,
                color: "#767474"
            }
        },
        complete: {
            color: "#fff",
        },
        empty: {
            color: "#fff",
        },
        focus: {
            color: "#fff",
        },
        invalid: {
            color: "red",
        },
        webkitAutoFill: {
            color: "#fff",
        }
    },
    classes: {
        base: "form-input stripe-card",
        complete: "form-input stripe-card",
        empty: "form-input stripe-card",
        focus: "form-input stripe-card",
        invalid: "form-input stripe-card",
        webkitAutoFill: "form-input stripe-card"
    },
}
export const CardField = React.forwardRef((props, ref) => (
    <div className="col w-full">
        <div className="input-group input-inline mb-20">
            <CardNumberElement 
                onChange={props.onChange}
                options={CardNumberOptions} 
                ref={ref}
            />
            <LabelRequiredVisible for="card_number">
                Card Number
            </LabelRequiredVisible>
        </div>
    </div>
));
export const CardExpiry = React.forwardRef((props, ref) => (
    <div className="col w-full">
        <div className="input-group input-inline mb-20">
            <CardExpiryElement 
                onChange={props.onChange}
                options={CardNumberOptions}
                ref={ref}
            />
            <LabelRequiredVisible for="card_exp">
                Card Exp
            </LabelRequiredVisible>
        </div>
    </div>
));
export const CardCvc = React.forwardRef((props, ref) => (
    <div className="col w-full">
        <div className="input-group input-inline mb-20">
            <CardCvcElement 
                onChange={props.onChange}
                options={CardNumberOptions} 
                ref={ref}
            />
            <LabelRequiredVisible for="card_cvc">
                CVC
            </LabelRequiredVisible>
        </div>
    </div>
));
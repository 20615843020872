import React, { Component } from "react";
import Select, { components } from "react-select";
// import { val } from "objection";
// import { select } from "@tailwindcss/custom-forms/src/defaultOptions";
import { ProjectsContext } from "../../ProjectsContext";
import customStyles from "../FormElements/customStyles";
import customStylesSmallMenu from "../FormElements/customStylesSmallMenu";
import CustomValueContainer from "../FormElements/customValueContainer";
import CircularIndeterminateButton from "../Loading/circularIndeterminantButton";
import { Error, Success, SuccessRedirect } from "../Toast/index";
import { handleAPIError } from "../../utils/handleAPIError";
import api from "../utils/api";


class AddToProject extends Component{
    state = {
        myProjects: [],
        sharedProjects: [],
        projectsOptions: [],
        currentProject: [],
        sceneOptions: [],
        newProjectTitle: ""
    }

    handleProjectChange= (selectedProject)=>{
        let sceneOptions =[]
        let selectedProjInfo = this.state.allProjects.filter(project=>project.id === selectedProject.value)
        selectedProjInfo[0].scenes.forEach(scene=>{
          sceneOptions.push({value: scene.id, label: scene.name})
        })
        this.setState({currentProject: selectedProject, sceneOptions: sceneOptions, currentScene: sceneOptions[0]})
    }
    handleSceneChange = (selectedScene)=>{
      this.setState({currentScene: selectedScene})
    }
    handleInputChange = (event)=>{
      let {name, value} = event.target

      if(name === "newProjectTitle"){
        let existingProjectNames = this.state.myProjects.map(project=>project.name)
        let isNameTaken = existingProjectNames.filter(name=>name?.toUpperCase()===value?.toUpperCase()).length > 0

        if(isNameTaken){
            this.setState({newProjectNameError: "Project name already used. Please choose a different name"})
        }
        else if(value.length < 1){
            this.setState({newProjectNameError: "Project name required"})
        }
        else{
            this.setState({newProjectNameError: ""})
        }
      }
      if(name === "newSceneTitle"){
       if(value.length < 1){
            this.setState({newProjectSceneError: "Scene name required"})
        }
        else{
            let error = this.checkIfUsed(value)
            if(error){
              this.setState({newProjectSceneError: error})
            }
            else{
              this.setState({newProjectSceneError: ""})
            }
        }
      }
      this.setState({[name]: value})
    }
    submitNewProject = (cb)=>{
        let projectBody = {
            name: this.state.newProjectName,
            client: this.state.newProjectClient,
        }
        api.submitNewProject(projectBody)
            .then(res=>{
                if(res.data.success){
                    SuccessRedirect({message:"Project Created!", onClose: ()=>this.successToastSubmit(res.data.project.id)})
                }
                else{
                    // Error({message:"An error occurred!", onClose: this.errorToast})
                    // handleAPIError()
                }
                cb()
            })
            .catch(error=>{
                // Error({message:"An error occurred!", onClose: this.errorToast})
              handleAPIError(error);
            })
    }
    checkIfUsed = (value)=>{
      if(this.state.newProject){
        return false
      }
      let sceneNames = this.state.sceneOptions.map(scene=>scene.label)
      let isInSceneNames =sceneNames.filter(name=>name.toUpperCase() === value.toUpperCase()).length > 0
      if(isInSceneNames){
          return "Scene name already used"
      }
      else{
          return false
      }
  }
    addToProject = (handleClose) => {
        if(this.state.newProject){
          if(!this.state.newProjectNameError && !this.state.newProjectSceneError){
            let existingProjectNames = this.state.myProjects.map(project=>project.name)
            let isNameTaken = existingProjectNames.filter(name=>name===this.state.newProjectTitle).length > 0
            let errors
            if(isNameTaken){
              this.setState({newProjectNameError: "Project name already used. Please choose a different name"})
              errors = true
            }
            else if(this.state.newProjectTitle.length < 1){
              this.setState({newProjectNameError: "Project name required"})
              errors = true
            }
            if(this.state.newSceneTitle.length < 1){
              this.setState({newProjectSceneError: "Scene name required"})
              errors = true
            }

            if(errors){
               return true
            }
            let projectBody = {
              name: this.state.newProjectTitle,
              client: this.state.newProjectClient,
              sceneName: this.state.newSceneTitle,
            }
            this.setState({isLoading: true}, ()=>{
              api.addTrackToNewProject(projectBody, this.props.trackId)
                .then(res=>{
                  this.setState({isLoading: false})
                  if(res.data.success){
                    Success({message: `${this.props.trackTitle} added to ${this.state.newProjectTitle}`})
                    handleClose()
                    document.cookie = `latestProject=${res.data.project.id}; path=/`
                    document.cookie = `latestScene=${res.data.project.scenes[0].id}; path=/`
                    this.context.addToList(res.data.project.id)
                  }
                  else{
                    Error({message: "Error adding track!"})
                  }
                })
                .catch(error=>{
                  this.setState({isLoading: false})
                  Error({message: "Error adding track!"})
                })
            })
          }
          else{
            // Error({message:"An error occurred!", onClose: this.errorToast})
            if(!this.state.newProjectTitle){
              this.setState({newProjectNameError: "Project name required"})
            }
            if(!this.state.newSceneTitle){
              this.setState({newProjectSceneError: "Scene name required"})
            }
        }
        }
        else{
          if (this.state.newScene){
            let sceneBody = {
              name: this.state.newSceneTitle
            }
            if (sceneBody?.name === "") {
              Error({
                message: "Please enter a scene title"
              });
            }
            else (
              this.setState({isLoading: true},()=>{
                api.addSceneToProject(this.state.currentProject.value, sceneBody)
                  .then(sceneRes=>{
                    if(sceneRes.data.success){
                        // this.context.updateLatest(this.state.currentProject.value)
                        // this.context.addSceneToProject(this.state.currentProject.value, sceneRes.data.newScene)
                        let scenes = this.state.sceneOptions
                        scenes.push({label: sceneRes.data.newScene.name, value: sceneRes.data.newScene.id})
                        this.setState({sceneOptions: scenes})
                        api.addTrackToExistingProject(this.state.currentProject.value, sceneRes.data.newScene.id, this.props.trackId)
                        .then(res=>{

                          this.setState({isLoading: false})
                          if(res.data.success){
                            this.context.updateLatestWithScene(this.state.currentProject.value, sceneRes.data.newScene, res.data.fullTrack, res.data.updatedProject)
                            Success({message: `${this.props.trackTitle} added to ${this.state.currentProject.label}`})
                            handleClose()
                            document.cookie = `latestProject=${this.state.currentProject.value}; path=/`
                            document.cookie = `latestScene=${this.state.currentScene.value}; path=/`
                          }
                          else{


                            Error({message: "Error adding track!"})
                          }
                        })
                        .catch(error=>{
                          this.setState({isLoading: false})
                          if(error.response?.status === 406){
                            Error({message: "Track already in scene"})
                          }
                          else if(error.response?.status === 413){
                            Error({message: "Track Limit Reached"})
                          }
                          else{

                            Error({message: "Error adding track!"})
                          }
                        })
                    }
                    else{
                        this.setState({isLoading: false})
                        // Error({message:"An error occurred!", onClose: this.errorToast})
                    }
                  })
                .catch(error=>{
                    this.setState({isLoading: false})
                    handleAPIError(error);
                    // Error({message:"An error occurred!", onClose: this.errorToast})
                })
              })
            )
          }
          else{
            this.setState({isLoading: true}, ()=>{
              api.addTrackToExistingProject(this.state.currentProject.value, this.state.currentScene.value, this.props.trackId)
                .then(res=>{
                  this.setState({isLoading: false})

                  if(res.data.success){
                    this.context.updateLatestWithTrack(this.state.currentProject.value, parseInt(this.state.currentScene.value),res.data.fullTrack, res.data.updatedProject )
                    Success({message: `${this.props.trackTitle} added to ${this.state.currentProject.label}`})
                    handleClose()
                    document.cookie = `latestProject=${this.state.currentProject.value}; path=/`
                    document.cookie = `latestScene=${this.state.currentScene.value}; path=/`
                  }
                  else{

                    Error({message: "Error adding track!"})
                  }
                })
                .catch(error=>{
                  this.setState({isLoading: false})
                  if(error.response?.status === 406){
                    Error({message: "Track already in scene"})
                  }
                  else if(error.response?.status === 413){
                    Error({message: "Track Limit Reached"})
                  }
                  else{
                    Error({message: "Error adding track!"})
                  }
                })
              })
              }

        }
        // Error({message: "Not ready yet."})
        // this.setState({showTrackTippy: false})
    }

    componentDidMount = ()=>{
        if(this.props.toggleTrackTippy){
          this.props.toggleTrackTippy()
        }

        this.setState(this.context)
    }

    render(){
        return(
            <React.Fragment>
                <form action="" className="ds-form">

                        <div className="input-group-sm">
                        <div className="grid-row-xs">
                            <div className="col flex-1">
                                {/* {# Existing Project Dropdown #} */}
                                {!this.state.newProject &&
                                  <div className="block">
                                      <div className="input-inline">
                                          <label className="input-label" htmlFor="existing_project">Choose Existing Project</label>
                                          <Select
                                              name={"existingProject"}
                                              id="existing_project"
                                              placeholder="Choose Existing Project"
                                              className="select-container"
                                              classNamePrefix="select"
                                              isSearchable={false}
                                              styles={customStyles}
                                              options={this.state.projectsOptions}
                                              onChange={(e) => this.handleProjectChange(e)}
                                              value={this.state.currentProject}
                                              components={{
                                                ValueContainer: CustomValueContainer
                                              }}
                                              menuPortalTarget={document.body}
                                          />
                                      </div>
                                  </div>
                                }
                                {/* {# End Existing Project Dropdown #} */}

                                {/* {# New Project Fields #} */}
                                {this.state.newProject && (
                      <div className="input-inline">
                        {/* {# Project Title #} */}
                                          <input
                                            autoFocus
                                            // ref={function(input) {
                                            //   if (input != null) {
                                            //     input.focus();
                                            //   }
                                            // }}
                                            id="newProjectTitle"
                                            name='newProjectTitle'
                                            onChange={this.handleInputChange}
                                            className="form-input"
                                            placeholder="Project Title"
                                          />
                                          <label className="input-label" for="newProjectTitle">Project</label>
                                          {/* <div className="error-message">{this.state.newProjectNameError}</div> */}
                                      </div>
                                )}
                                {/* {# End New Project Fields #} */}

                            </div>
                            {this.state.newProject ?
                             <div className="col flex-none">
                              {/* {# New Project Button #} */}
                              <button
                                  type="button"
                                  className="circle-icon-border circle-icon-lg"
                                  onClick={(e)=>{e.preventDefault();this.setState({newProject: false})}}
                              >
                                  <span className="icon-trash"></span>
                              </button>
                              {/* {# End New Project Button #} */}
                            </div>:
                            <div className="col flex-none">
                              {/* {# New Project Button #} */}
                              <button
                                  type="button"
                                  className="circle-icon-border circle-icon-lg"
                                  onClick={(e)=>{e.preventDefault();this.setState({newProject: true})}}
                              >
                                  <span className="icon-plus"></span>
                              </button>
                              {/* {# End New Project Button #} */}
                            </div> }

                        </div>
                    </div>
                    {/* {# End Project Input Group #} */}

                    {/* {# Scene Input Group #} */}
                    <div className="input-group-sm">
                        <div className="grid-row-xs">
                            <div className="col flex-1">
                                {!this.state.newProject  ?
                                  // {/* {# Existing Scene Dropdown #} */}
                                  !this.state.newScene ?
                                    <div className="block">
                                        <div className="input-inline">
                                            <label className="input-label" htmlFor="existing_scene">Choose Scene</label>
                                            <Select
                                                id="existing_scene"
                                                placeholder="Choose Scene"
                                                data-dropdown-parent="#add_to_project_modal"
                                                isSearchable={false}
                                                styles={customStylesSmallMenu}
                                                options={this.state.sceneOptions}
                                                onChange={(e)=>this.handleSceneChange(e)}
                                                value={this.state.currentScene}
                                                className="select-container"
                                                classNamePrefix="select"
                                                components={{
                                                  ValueContainer: CustomValueContainer
                                                }}
                                                menuPortalTarget={document.body}
                                                disabled={!this.state?.currentProject?.value}
                                            />
                                        </div>
                                    </div>
                                    :
                                      <div className="block">
                                        <div className="input-inline">
                                              <input
                                                autoFocus
                                                // ref={function(input) {
                                                //   if (input != null) {
                                                //     input.focus();
                                                //   }
                                                // }}
                                                id="newSceneTitle"
                                                name="newSceneTitle"
                                                className="form-input"
                                                placeholder="New Scene Title"
                                                onChange={this.handleInputChange}
                                                required
                                                defaultValue={""}
                                                value={this.state.newSceneTitle}
                                                disabled={!this.state?.currentProject?.value}
                                            />
                                            <label className="input-label" htmlFor="newSceneTitle">New Scene Title</label>
                                            {/* <div className="error-message">{this.state.newProjectSceneError}</div> */}
                                        </div>
                                      </div>

                                  :
                                  // {/* {# New Scene Field #} */}
                                  <div className="block">
                                      <div className="input-inline">
                                          <input
                                              id="newSceneTitle"
                                              name="newSceneTitle"
                                              className="form-input"
                                              placeholder="New Scene Title"
                                              onChange={this.handleInputChange}
                                              required
                                              defaultValue={""}
                                              value={this.state.newSceneTitle}
                                              // disabled={!this.state?.currentProject?.value}
                                          />
                                          <label className="input-label" htmlFor="newSceneTitle">New Scene Title</label>
                                          {/* <div className="error-message">{this.state.newProjectSceneError}</div> */}
                                      </div>
                                  </div>
                                }
                            </div>

                            {this.state.newProject || this.state.newScene ?
                             <div className="col flex-none">
                              {/* {# New Project Button #} */}
                              <button
                                  type="button"
                                  className="btn-disabled circle-icon-border circle-icon-lg "
                                  onClick={(e)=>{e.preventDefault();this.setState({newScene: false, newSceneTitle: "Scene 1"})}}
                                  disabled={this.state.newProject}
                                  style={{cursor: 'not-allowed'}}
                              >
                                  <span className="icon-trash"></span>
                              </button>
                              {/* {# End New Project Button #} */}
                            </div>:
                            <div className="col flex-none">
                              {/* {# New Project Button #} */}
                              <button
                                  type="button"
                                  className="circle-icon-border circle-icon-lg"
                                  onClick={(e)=>{e.preventDefault();this.setState({newScene: true, newSceneTitle: ""})}}
                                  disabled={!this.state?.currentProject?.value}
                              >
                                  <span className="icon-plus"></span>
                              </button>
                              {/* {# End New Project Button #} */}
                            </div> }

                        </div>
                    </div>
                    {/* {# End Input Group #} */}

                    {/* {# Modal Buttons #} */}
                    <div className="input-group pt-15">
                <button
                  type="button"
                  disabled={
                    (!this.state.newProject && !this.state?.currentProject?.value) ||
                    (this.state.newProject && !this.state.newProjectTitle) ||
                    (this.state.newProject && !this.state.newSceneTitle) ||
                    this.state.newProjectSceneError ||
                    this.state.newProjectNameError ||
                    this.state.isLoading
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    this.addToProject(this.props.handleClose);
                  }}
                  className="btn btn-primary form-btn w-full"
                >
                  {this.state.isLoading ?
                    <CircularIndeterminateButton />
                    :
                    "Add Track to Project"
                  }
                </button>
              </div>
            </form>
            {/* {# End Form #} */}
          </React.Fragment>
        )
    }
}
AddToProject.contextType = ProjectsContext
export default AddToProject

import moment from "moment"
import React, { Component } from "react"
import { NavLink } from 'react-router-dom'
import { UserConsumer } from "../../contexts/User"
import api from "../utils/api"

class Searches extends Component {
    state={
        searches: []
    }
    componentDidMount = ()=>{
        api.getAllSearches()
            .then(res=>{

                this.setState({searches: res.data.searches})
            })
    }
    populateTags = ()=>{
        api.populateTags()
            .then(res=>{

            })
    }
    render (){
        return(
            <UserConsumer>
            {({ user, login, logout})=>(
                <div style={{overflowX: "scroll", width: "100%"}}>
                    <button onClick={this.populateTags}>Populate Tags</button>
                    <table style={{display: 'block', overflow: "auto", width: "2000px", border: "1px solid #ddd"}} >
                        <thead>
                            <tr>
                                <th style={{ border: "1px solid #ddd"}}>ID</th>
                                <th style={{ border: "1px solid #ddd"}}>Tags Inc</th>
                                <th style={{ border: "1px solid #ddd"}}>Tags Exc</th>
                                <th style={{ border: "1px solid #ddd"}}>Keywords</th>
                                <th style={{ border: "1px solid #ddd"}}>Results</th>
                                <th style={{ border: "1px solid #ddd"}}>Time</th>
                                <th style={{ border: "1px solid #ddd"}}>User</th>
                                <th style={{ border: "1px solid #ddd"}}>IP</th>
                                <th style={{ border: "1px solid #ddd"}}>Prev Search</th>
                                <th style={{ border: "1px solid #ddd"}}>Actions</th>
                                <th style={{ border: "1px solid #ddd"}}>Device Type</th>
                                <th style={{ border: "1px solid #ddd"}}>Browser</th>
                                <th style={{ border: "1px solid #ddd"}}>Referrer</th>


                            </tr>
                        </thead>
                        <tbody >
                        {this.state.searches.map(search=><tr key={search.id}>
                            <td style={{ border: "1px solid #ddd"}}>{search.id}</td>
                            <td style={{ border: "1px solid #ddd"}}>{search.tags.filter(tag=>tag.include).map(tag=>tag.name + ", ")}</td>
                            <td style={{ border: "1px solid #ddd"}}>{search.tags.filter(tag=>!tag.include).map(tag=>tag.name + ", ")}</td>
                            <td style={{ border: "1px solid #ddd"}}>{search.keywords}</td>
                            <td style={{ border: "1px solid #ddd"}}>{search.tracks.map(track=><NavLink to={'/tracks/' +track.trackURL}>{track.title}</NavLink>)}</td>
                            <td style={{ border: "1px solid #ddd"}}>{moment(search.createdOn).format("MM-DD-YYYY hh:mm")}</td>
                            <td style={{ border: "1px solid #ddd"}}>{search.userId}</td>
                            <td style={{ border: "1px solid #ddd"}}>{search.ip}</td>
                            <td style={{ border: "1px solid #ddd"}}>{search.previousSearch}</td>
                            <td style={{ border: "1px solid #ddd"}}>
                                <thead>
                                    <th style={{ border: "1px solid #ddd"}}>Time</th>
                                    <th style={{ border: "1px solid #ddd"}}>Action</th>
                                    <th style={{ border: "1px solid #ddd"}}>Id</th>
                                </thead>
                                {search.actions && JSON.parse(search.actions).map(action=>{
                                    let {timestamp, type, ...rest} = action

                                    return(
                                        <tr>
                                            <td style={{ border: "1px solid #ddd"}}>{moment(timestamp).format("MM-DD-YYYY hh:mm")}</td>
                                            <td style={{ border: "1px solid #ddd"}}>{type}</td>
                                            <td style={{ border: "1px solid #ddd"}}>{Object.keys(rest).map(key=>rest[key])}</td>
                                        </tr>
                                    )
                                }
                                )}
                            </td>
                            <td style={{ border: "1px solid #ddd"}}>{search.deviceType}</td>
                            <td style={{ border: "1px solid #ddd"}}>{search.browser}</td>
                            <td style={{ border: "1px solid #ddd"}}>{search.referrer}</td>

                        </tr>)}
                        </tbody>
                    </table>
                </div>
            )}
            </UserConsumer>
        )
    }
}

export default Searches

import React, { useState } from "react";
import { Error, Success } from "../../../Toast";

export default function ContentIDCode({ contentIDCode, expiration }) {
  const [contentIDCodeElement, setcontentIDCodeElement] = useState(null);

  const copyCode = () => {
    contentIDCodeElement.focus();
    contentIDCodeElement.select();
    try {
      let successful = document.execCommand("copy");
      if (successful) {
        Success({ message: "Content ID Code Copied to Clipboard" });
      } else {
        Error({ message: "Couldn't Copy Content ID Code" });
      }
    }
    catch (error) {

    }
  };

  const setElement = (element) => {
    setcontentIDCodeElement(element);
  }

  return (
    <>
      <div class="flex items-center">
        <div class="flex-1 pr-10">
          <div class="input-inline">
            <input
              autoFocus={true}
              id="contentIDCode"
              className="form-input"
              onClick={copyCode}
              placeholder="Content ID Code"
              readOnly
              ref={(input) => {setElement(input)}}
              size="25"
              value={contentIDCode}
            />
            <label class="input-label" for="project_title">
              Click to copy
            </label>
          </div>
        </div>
        <div class="flex-none">
          <button
            class="circle-icon text-icon-md js-tooltip"
            onClick={copyCode}
            type="button"
          >
            <span class="icon-copy"></span>
          </button>
        </div>
      </div>
      {expiration && (
        <p className="py-5 text-sm">This code expires on {expiration}.</p>
      )}
    </>
  );
}

import React, { Component } from "react"
import { UserConsumer } from "../../contexts/User"

// Styles for Single Select
class EditSubscriptionModal extends Component {
    render() {
        return (
            <UserConsumer>
                {({ user, login, loginMessage }) => (
                    <>
                                   {/* {# Modal Header #} */}
                                   <header className="modal-header">
<h2 id="new_track_modal_title" className="modal-title">Edit Subscription</h2>
<button type="button"  onClick={this.props.handleClose} aria-label="Close Modal"  className="modal-close"></button>
</header>

<div id="new_track_modal_content" className="modal-content">

{/* {# Form #} */}
<form action="" className="ds-form">


 {/* {# Title #} */}
 <div className="input-group">
 <div class="flex">

<div class="flex-1 pr-10">
    {/* {# Label #} */}
    <label
        class="input-label cursor-pointer mb-5"
        for="link_enabled"
        // @click="enabled = !enabled"
    >
        Auto-Renew
    </label>
    {/* {# Description #} */}
    <p class="text-sm text-gray-400 mb-0">
        {this.props.autorenewStatus ? (
            `Your subscription is set to automatically renew on ${this.props.autorenewDate}.`
        ) : (
            `Your subscription will expire on ${this.props.expireDate}.`
        )
        }
    </p>
</div>

<div class="flex-none">
    {/* {# Form Switch #} */}
    <label class="form-switch">
        <input
            onChange={(e)=>{this.props.toggleAutoRenew()}}
            type="checkbox"
            id="link_enabled"
            name="link_enabled"
            checked={this.props.autorenewStatus}
        />
        <span
            class="form-switch-slider"
        >
        </span>
    </label>
</div>

</div>
 </div>

</form>
 </div>
                    </>
                )}</UserConsumer>
        )
    }

}
export default EditSubscriptionModal

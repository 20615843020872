import React, { Component } from "react";
import { Tooltip } from "react-tippy";
import "react-tippy/dist/tippy.css";
import { PlayerContext } from "../../PlayerContext";
import numberAbbreviator from "../NumberFormatters/numberAbbreviator";

class TrackStats extends Component {
  state = {};
  formatNumber = (num) => {
    if (num === undefined) num = 0;
    switch (num.toString().length || 0) {
      case 0:
      case 1:
      case 2:
      case 3:
        return num;
      case 4:
        return num.toString()[0] + "." + num.toString()[1] + "k";
      case 5:
        return num.toString().substr(0, 2) + "k";
      case 6:
        return num.toString().substr(0, 3) + "k";
      default:
        return num;
    }
  };

  render() {
    return (
      <div className="track-stats">
        <div className="track-stat-item js-tooltip" title="Plays">
          <Tooltip useContext={true} animation="shift" arrow="true" title="Plays" position="top">
            <div>
              <span className="icon-play"></span> {numberAbbreviator(this.props.plays)}
            </div>
          </Tooltip>
        </div>
        <div className="track-stat-item js-tooltip" title="Downloads">
          <Tooltip animation="shift" arrow="true" title="Downloads" position="top">
            <div>
              <span className="icon-download"></span> {numberAbbreviator(this.props.downloads)}
            </div>
          </Tooltip>
        </div>
        <div className="track-stat-item js-tooltip" title="Favorites">
          <Tooltip animation="shift" arrow="true" title="Favorites" position="top">
            <div>
              <span className="icon-like"></span> {numberAbbreviator(this.props.likes)}
            </div>
          </Tooltip>
        </div>
        <div className="track-stat-item js-tooltip" title="Projects">
          <Tooltip animation="shift" arrow="true" title="Projects" position="top">
            <div>
              <span className="icon-projects"></span> {numberAbbreviator(this.props.projects)}
            </div>
          </Tooltip>
        </div>
        <div className="track-stat-item js-tooltip" title="Licenses">
          <Tooltip animation="shift" arrow="true" title="Licenses" position="top">
            <div>
              <span className="icon-download"></span> {numberAbbreviator(this.props.purchases)}
            </div>
          </Tooltip>
        </div>
      </div>
    );
  }
}

TrackStats.defaultProps = {
  track: [],
};
TrackStats.contextType = PlayerContext;
export default TrackStats;

import React, { Component } from "react";
import MicroModal from "react-micro-modal";
import { UserConsumer } from "../../contexts/User";
import BackdropOverlay from "../LoadingOverlay/backdrop";
import currencyFormatter from "../NumberFormatters/currencyFormatter";
import StripeSetupPrompt from "../Stripe/stripeSetupPrompt";
import { Success } from "../Toast/index";
import api from "../utils/api";
import ArtistsPayoutTable from "./artistsPayoutTable";
import EnterPWModal from "./enterPWModal";
import PayoutTable from "./payoutTable";
const moment = require("moment");

const queryStringer = require("query-string");
const licenseFilters = [
  { filter: "RecentA", displayText: "Recently Purchased" },
  { filter: "Type", displayText: "Type" },
  { filter: "Project", displayText: "Project" },
];
class SubscriptionPayouts extends Component {
  state = {
    filteredSales: { 1: [] },
    sortChoice: "RecentA",
    sortText: "Recently Purchased",
    payoutTimeFrame: "All Time",
    balance: 0,
    displaySales: 0,
    currentSalesPage: 1,
    accountLoginLinkLoaded: false,
    arePayoutsLoaded: false,
    subscriptionPayouts: [],
    artistSubscriptionPayouts: {},
    isPWModalOpen: false,
    isPWReapproveModalOpen: false,
  };
  componentDidMount = () => {
    api.getAllSubscriptionPayouts().then((res) => {
      this.setState(
        { subscriptionPayouts: res.data.subscriptionPayouts, arePayoutsLoaded: true },
        () => {
          let shouldShowLatest;
          let latestMonth = moment().startOf("month").subtract(1, "month").format("YYYY-MM-DD");
          let currentMonth15th = moment()
            .utc()
            .startOf("month")
            .add(14, "day")
            .utc()
            .format("YYYY-MM-DD");

          let pastThe15th = moment().utc().isAfter(currentMonth15th);
          let latestMonthIndexPayouts = this.state.subscriptionPayouts.findIndex((subPayout) => {
            return moment(subPayout.forMonth).utc().format("YYYY-MM-DD") === latestMonth;
          });
          let latestMonthInPayouts = latestMonthIndexPayouts !== -1;
          shouldShowLatest = pastThe15th && !latestMonthInPayouts;
          this.setState({ shouldShowLatest: shouldShowLatest, latestForMonth: latestMonth });
        }
      );
    });

    api
      .deepsoundsStripeBalance()
      .then((res) => {
        if (res.data) {
          this.setState({
            availableBalance: res.data.availableBalance / 100,
            pendingBalance: res.data.pendingBalance / 100,
            balanceLoaded: true,
          });
        }
      })
      .catch((err) => {
        if (err.response) {
        } else if (err.request) {
          // client never received a response, or request never left
        } else {
          // anything else
        }
      });
  };
  onPageChanged = (data) => {
    const { filteredCollections } = this.state;
    const { currentPage, totalPages, pageLimit } = data;
    // const searchBarEl = document.getElementById("search")
    // searchBarEl.scrollIntoView()
    const offset = (currentPage - 1) * pageLimit;

    this.setState({ currentPage, totalPages });
  };

  getArtistSubscriptionPayouts = (subscriptionPayoutId) => {
    this.setState({ areArtistFeesLoading: true }, () => {
      let currentSubscriptionPayout = this.state.subscriptionPayouts.filter(
        (subscriptionPayout) => subscriptionPayout.id === subscriptionPayoutId
      );
      if (this.state.artistSubscriptionPayouts[subscriptionPayoutId]) {
        this.setState({
          currentSubscriptionPayoutId: subscriptionPayoutId,
          currentSubscriptionPayout: currentSubscriptionPayout[0],
          areArtistFeesLoading: false,
        });
      } else {
        api.getArtistSubscriptionPayouts(subscriptionPayoutId).then((res) => {
          this.setState(({ artistSubscriptionPayouts }) => ({
            artistSubscriptionPayouts: {
              ...artistSubscriptionPayouts,
              [subscriptionPayoutId]: res.data.artistSubscriptionPayouts,
            },
            currentSubscriptionPayoutId: subscriptionPayoutId,
            currentSubscriptionPayout: currentSubscriptionPayout[0],
            areArtistFeesLoading: false,
          }));
        });
      }
    });
  };
  toggleSortTippy = (sortChoice, overRideTippy) => {
    this.setState({
      isSortTippyOpen: overRideTippy === false ? false : !this.state.isSortTippyOpen,
      sortChoice: sortChoice,
    });
  };
  sortBy = (sortChoice, sortText, overRide, tippyStateCB, currentSortChoice, overRideTippy) => {
    let unsorted = [...this.state.filteredSales];
    let compare;
    let sorted;
    if (sortChoice !== this.state.sortChoice || overRide) {
      switch (sortChoice) {
        case "NameA":
          compare = (a, b) => {
            // Use toUpperCase() to ignore character casing
            const aName = a.name.toUpperCase();
            const bName = b.name.toUpperCase();

            let comparison = 0;
            if (aName < bName) {
              comparison = -1;
            } else if (aName > bName) {
              comparison = 1;
            }
            return comparison;
          };
          sorted = unsorted.sort(compare);
          break;
        case "NameD":
          compare = (a, b) => {
            // Use toUpperCase() to ignore character casing
            const aName = a.name.toUpperCase();
            const bName = b.name.toUpperCase();

            let comparison = 0;
            if (aName < bName) {
              comparison = -1;
            } else if (aName > bName) {
              comparison = 1;
            }
            return comparison * -1;
          };
          sorted = unsorted.sort(compare);
          break;
        case "RecentA":
          compare = (a, b) => {
            const aCreated = a.createdOn;
            const bCreated = b.createdOn;

            let comparison = 0;
            if (aCreated < bCreated) {
              comparison = -1;
            } else if (aCreated > bCreated) {
              comparison = 1;
            }
            return comparison;
          };
          sorted = unsorted.sort(compare);
          break;
        case "RecentD":
          compare = (a, b) => {
            const aCreated = a.createdOn;
            const bCreated = b.createdOn;

            let comparison = 0;
            if (aCreated < bCreated) {
              comparison = -1;
            } else if (aCreated > bCreated) {
              comparison = 1;
            }
            return comparison * -1;
          };
          sorted = unsorted.sort(compare);
          break;
        case "MostP":
          compare = (a, b) => {
            const aFollowers = a.followerCount;
            const bFollowers = b.followerCount;
            const aCreated = a.createdOn;
            const bCreated = b.createdOn;
            let comparison = 0;
            if (aFollowers < bFollowers) {
              comparison = 1;
            } else if (aFollowers > bFollowers) {
              comparison = -1;
            } else if (aCreated < bCreated) {
              comparison = -1;
            } else if (aCreated > bCreated) {
              comparison = 1;
            }
            return comparison;
          };
          sorted = unsorted.sort(compare);
          break;
      }
    } else {
      tippyStateCB(sortChoice);
    }
  };
  handleSearchChange = (event) => {
    let { value } = event.target;
    this.setState({ searchTerm: value }, () => this.search());
  };
  toggleTimeFrameTippy = () => {
    this.setState({ isTimeFrameTippyOpen: !this.state.isTimeFrameTippyOpen });
  };
  changeSalesTimeFrame = (timeFrame) => {
    let payoutTimeFrame;
    let displaySales;
    switch (timeFrame) {
      case "week":
        payoutTimeFrame = "This Week";
        displaySales = this.state.weeklySales;
        break;
      case "month":
        payoutTimeFrame = "This Month";
        displaySales = this.state.monthlySales;
        break;
      case "year":
        payoutTimeFrame = "This Year";
        displaySales = this.state.yearlySales;
        break;
      case "all":
        payoutTimeFrame = "All Time";
        displaySales = this.state.allSales;
        break;
    }
    this.setState({ payoutTimeFrame: payoutTimeFrame, displaySales: displaySales });
  };
  setPayoutLoading = (index, isLoading) => {
    if (index === undefined) {
      this.setState({ isLatestLoading: true });
    } else {
      this.setState(({ subscriptionPayouts }) => {
        return {
          subscriptionPayouts: [
            ...subscriptionPayouts.slice(0, index),
            {
              ...subscriptionPayouts[index],
              isLoading: isLoading,
            },
            ...subscriptionPayouts.slice(index + 1),
          ],
        };
      });
    }
  };
  updatePayouts = (index, payout) => {
    if (index === undefined) {
      this.setState(
        ({ subscriptionPayouts }) => {
          return {
            subscriptionPayouts: [
              ...subscriptionPayouts.slice(0, index),
              {
                ...payout,
                isLoading: false,
              },
              ...subscriptionPayouts.slice(index + 1),
            ],
            shouldShowLatest: false,
            isLatestLoading: false,
          };
        },
        () => {
          this.getArtistSubscriptionPayouts(payout.id);
        }
      );
    } else {
      this.setState(
        ({ subscriptionPayouts }) => {
          return {
            subscriptionPayouts: [
              ...subscriptionPayouts.slice(0, index),
              {
                ...payout,
                isLoading: false,
              },
              ...subscriptionPayouts.slice(index + 1),
            ],
          };
        },
        () => {
          this.getArtistSubscriptionPayouts(payout.id);
        }
      );
    }
  };
  handlePWModalOpen = (payoutId) => {
    this.setState({
      isPWModalOpen: true,
      currentSubscriptionPayoutId: payoutId,
    });
  };
  handlePWModalOpenForReapprove = (payoutId) => {
    this.setState({
      isPWReapproveModalOpen: true,
      currentSubscriptionPayoutId: payoutId,
    });
  };
  handlePWModalOpenForUnapprove = (payoutId) => {
    this.setState({
      isPWUnapproveModalOpen: true,
      currentSubscriptionPayoutId: payoutId,
    });
  };
  handlePWModalOpenForApproveAndDistribute = (payoutId) => {
    this.setState({
      isPWApproveAndDistributeOpen: true,
      currentSubscriptionPayoutId: payoutId,
    });
  };
  handlePWModalClose = () => {
    this.setState({
      isPWModalOpen: false,
    });
  };
  handlePWModalCloseForReapprove = () => {
    this.setState({
      isPWReapproveModalOpen: false,
    });
  };
  handlePWModalCloseForUnapprove = (payoutId) => {
    this.setState({
      isPWUnapproveModalOpen: false,
      currentSubscriptionPayoutId: payoutId,
    });
  };
  handlePWModalCloseForApproveAndDistribute = (payoutId) => {
    this.setState({
      isPWApproveAndDistributeOpen: false,
      currentSubscriptionPayoutId: payoutId,
    });
  };
  approvePayout = () => {
    this.setState({ isApprovingPayout: true }, () => {
      api
        .approveSubscriptionPayout(this.state.currentSubscriptionPayoutId, this.state.password)
        .then((res) => {
          if (res.data.success) {
            Success({ message: "Payout Approved!" });
            this.setState({ isApprovingPayout: false }, () => {
              let index = this.state.subscriptionPayouts.findIndex(
                (subPayout) => subPayout.id === this.state.currentSubscriptionPayoutId
              );
              this.updatePayouts(index, res.data.updateSubPayout);
              this.handlePWModalClose();
            });
          } else {
            this.setState({ isApprovingPayout: false });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.setState({ passError: "Invalid Password", isApprovingPayout: false });
            }
          }
        });
    });
  };
  approvePayoutAndDistribute = () => {
    this.setState({ isApprovingPayout: true }, () => {
      api
        .approveSubscriptionPayout(this.state.currentSubscriptionPayoutId, this.state.password)
        .then((res) => {
          if (res.data.success) {
            Success({ message: "Payout Approved!" });
            if (res.data.success) {
              api
                .approvePayoutAndDistribute(this.state.currentSubscriptionPayoutId)
                .then((resp) => {
                  if (resp?.data.success) {
                    this.props.history.push(
                      "/dashboard/artist-payouts/" + this.state.currentSubscriptionPayoutId
                    );
                  }
                  this.setState({ isApprovingPayout: false });
                })
                .catch((err) => {
                  this.setState({ isApprovingPayout: false });
                });
            } else {
              this.setState({ isApprovingPayout: false });
            }
          } else {
            this.setState({ isApprovingPayout: false });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.setState({ passError: "Invalid Password", isApprovingPayout: false });
            }
          }
        });
    });
  };
  unapprovePayout = () => {
    this.setState({ isApprovingPayout: true }, () => {
      api
        .unapproveSubscriptionPayout(this.state.currentSubscriptionPayoutId, this.state.password)
        .then((res) => {
          if (res.data.success) {
            Success({ message: "Payout Unapproved!" });
            this.setState({ isApprovingPayout: false }, () => {
              let index = this.state.subscriptionPayouts.findIndex(
                (subPayout) => subPayout.id === this.state.currentSubscriptionPayoutId
              );
              this.updatePayouts(index, res.data.updateSubPayout);
              this.handlePWModalCloseForUnapprove();
            });
          } else {
            this.setState({ isApprovingPayout: false });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.setState({ passError: "Invalid Password", isApprovingPayout: false });
            }
          }
        });
    });
  };
  retryPayout = () => {
    this.setState({ isApprovingPayout: true }, () => {
      api
        .retrySubscriptionPayout(this.state.currentSubscriptionPayoutId, this.state.password)
        .then((res) => {
          if (res.data.success) {
            api
              .retryAllArtistSubscriptionPayouts(this.state.currentSubscriptionPayoutId)
              .then((resp) => {
                if (resp?.data.success) {
                  this.props.history.push(
                    "/dashboard/artist-payouts/" + this.state.currentSubscriptionPayoutId
                  );
                }
                this.setState({ isApprovingPayout: false });
              })
              .catch((err) => {
                this.setState({ isApprovingPayout: false });
              });
          } else {
            this.setState({ isApprovingPayout: false });
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              this.setState({ passError: "Invalid Password", isApprovingPayout: false });
            }
          }
        });
    });
  };
  onChange = (event) => {
    let { value } = event.target;
    this.setState({ password: value });
  };
  render() {
    return (
      <UserConsumer>
        {({ user, login, logout }) => (
          <div class="container mb-40 lg:mb-80">
            {/* {# App Header #} */}
            <div class="view-header">
              <h1 class="view-title">Subscription Royalty Payouts</h1>
            </div>
            <MicroModal
              open={this.state.isPWModalOpen}
              handleClose={() => this.handlePWModalClose()}
              closeOnAnimationEnd={true}
              closeOnEscapePress={false}
              closeOnOverlayClick={false}
              modalOverlayClassName="modal-overlay"
              modalClassName="modal micromodal-slide is-open"
              modalOverlayStyles={null}
              children={(handleClose) => (
                <EnterPWModal
                  isLoading={this.state.isApprovingPayout}
                  handleClose={handleClose}
                  onChange={this.onChange}
                  password={this.state.password}
                  approvePayout={this.approvePayout}
                  passError={this.state.passError}
                  action={"approve"}
                />
              )}
              containerStyles={{
                background: "#131313",
                padding: "asdf",
                maxWidth: "40.625rem !important",
                maxHeight: "100vh",
                borderRadius: "asdf",
                overflowY: "auto",
                boxSizing: "border-box",
              }}
            />
            <MicroModal
              open={this.state.isPWReapproveModalOpen}
              handleClose={() => this.handlePWModalCloaseForReapprove()}
              closeOnAnimationEnd={true}
              closeOnEscapePress={false}
              closeOnOverlayClick={false}
              modalOverlayClassName="modal-overlay"
              modalClassName="modal micromodal-slide is-open"
              modalOverlayStyles={null}
              children={(handleClose) => (
                <EnterPWModal
                  isLoading={this.state.isApprovingPayout}
                  handleClose={handleClose}
                  onChange={this.onChange}
                  password={this.state.password}
                  approvePayout={this.retryPayout}
                  retry={true}
                  passError={this.state.passError}
                  action={"reapprove"}
                />
              )}
              containerStyles={{
                background: "#131313",
                padding: "asdf",
                maxWidth: "40.625rem !important",
                maxHeight: "100vh",
                borderRadius: "asdf",
                overflowY: "auto",
                boxSizing: "border-box",
              }}
            />
            <MicroModal
              open={this.state.isPWUnapproveModalOpen}
              handleClose={() => this.handlePWModalCloseForUnapprove()}
              closeOnAnimationEnd={true}
              closeOnEscapePress={false}
              closeOnOverlayClick={false}
              modalOverlayClassName="modal-overlay"
              modalClassName="modal micromodal-slide is-open"
              modalOverlayStyles={null}
              children={(handleClose) => (
                <EnterPWModal
                  isLoading={this.state.isApprovingPayout}
                  handleClose={handleClose}
                  onChange={this.onChange}
                  password={this.state.password}
                  approvePayout={this.unapprovePayout}
                  retry={true}
                  passError={this.state.passError}
                  action={"unapprove"}
                />
              )}
              containerStyles={{
                background: "#131313",
                padding: "asdf",
                maxWidth: "40.625rem !important",
                maxHeight: "100vh",
                borderRadius: "asdf",
                overflowY: "auto",
                boxSizing: "border-box",
              }}
            />
            <MicroModal
              open={this.state.isPWApproveAndDistributeOpen}
              handleClose={() => this.handlePWModalCloseForApproveAndDistribute()}
              closeOnAnimationEnd={true}
              closeOnEscapePress={false}
              closeOnOverlayClick={false}
              modalOverlayClassName="modal-overlay"
              modalClassName="modal micromodal-slide is-open"
              modalOverlayStyles={null}
              children={(handleClose) => (
                <EnterPWModal
                  isLoading={this.state.isApprovingPayout}
                  handleClose={handleClose}
                  onChange={this.onChange}
                  password={this.state.password}
                  approvePayout={this.approvePayoutAndDistribute}
                  passError={this.state.passError}
                  action={"approve"}
                />
              )}
              containerStyles={{
                background: "#131313",
                padding: "asdf",
                maxWidth: "40.625rem !important",
                maxHeight: "100vh",
                borderRadius: "asdf",
                overflowY: "auto",
                boxSizing: "border-box",
              }}
            />
            {this.state.balanceLoaded ? (
              <>
                <div class="flex flex-col md:flex-row items-stretch justify-between bg-gray-100 p-20 lg:p-25 mb-40">
                  {/* {# Summary #} */}
                  <div class="flex-none flex flex-col sm:flex-row">
                    {/* {# Payout Summary #} */}
                    <div class="flex flex-col flex-none pr-30 lg:pr-60 leading-snug mb-30 md:mb-0">
                      <span class="text-label-sm mb-3">Pending Balance</span>
                      <span class="text-h3 text-white font-display leading-tight">
                        ${currencyFormatter(this.state.pendingBalance.toFixed(2))}
                      </span>
                    </div>
                    {/* {# Payout Summary #} */}
                    <div class="flex flex-col flex-none pr-30 lg:pr-60 leading-snug mb-30 md:mb-0">
                      <span class="text-label-sm mb-3">Available Balance</span>
                      <span class="text-h3 text-white font-display leading-tight">
                        ${`${currencyFormatter(this.state.availableBalance.toFixed(2))}`}
                      </span>
                    </div>
                  </div>
                  {/* {# Button #} */}
                </div>
                <PayoutTable
                  arePayoutsLoaded={this.state.arePayoutsLoaded}
                  subscriptionPayouts={this.state.subscriptionPayouts}
                  sales={this.state.filteredSales}
                  currentPage={this.state.currentSalesPage}
                  totalSales={this.state.totalSales}
                  getArtistSubscriptionPayouts={this.getArtistSubscriptionPayouts}
                  updatePayouts={this.updatePayouts}
                  handlePWModalOpen={this.handlePWModalOpen}
                  handlePWModalOpenForReapprove={this.handlePWModalOpenForReapprove}
                  handlePWModalOpenForUnapprove={this.handlePWModalOpenForUnapprove}
                  handlePWModalOpenForApproveAndDistribute={
                    this.handlePWModalOpenForApproveAndDistribute
                  }
                  shouldShowLatest={this.state.shouldShowLatest}
                  latestForMonth={this.state.latestForMonth}
                  setPayoutLoading={this.setPayoutLoading}
                  isLatestLoading={this.state.isLatestLoading}
                />
                {(this.state.artistSubscriptionPayouts[this.state.currentSubscriptionPayoutId]
                  ?.length > 0 ||
                  this.state.areArtistFeesLoading) && (
                  <>
                    {this.state.areArtistFeesLoading ? (
                      <BackdropOverlay loading={this.state.areArtistFeesLoading} />
                    ) : (
                      <ArtistsPayoutTable
                        artistSubscriptionPayouts={
                          this.state.artistSubscriptionPayouts[
                            this.state.currentSubscriptionPayoutId
                          ] || []
                        }
                        currentSubscriptionPayout={this.state.currentSubscriptionPayout}
                        handlePWModalOpen={this.handlePWModalOpen}
                        handlePWModalOpenForReapprove={this.handlePWModalOpenForReapprove}
                        handlePWModalOpenForApproveAndDistribute={
                          this.handlePWModalOpenForApproveAndDistribute
                        }
                      />
                    )}
                  </>
                )}
              </>
            ) : this.state.accountLoginLinkLoaded ? (
              <StripeSetupPrompt />
            ) : (
              <BackdropOverlay loading={!this.state.accountLoginLinkLoaded} />
            )}
          </div>
        )}
      </UserConsumer>
    );
  }
}
export default SubscriptionPayouts;

import React from "react";
import Video from "../Video/video";
export default function FeatureBlock (props) {

    return (
        props.alt ?
        <article className={`feature-block grid-row items-center`}>
            {/* {# Text #} */}
            <div className="col w-full md:w-1/2 order-2 md:order-1">
                <div className="lg:pr-40 xl:pr-80 xxl:pr-100 max-w-xl">
                    <h2 className="mb-20">{props.title || 'The Perfect Track'}</h2>
                            <p className="text-h4 mb-25 lg:mb-40">
                                { props.desc || 'Search for Music using our many filters to narrow down the exact vibe you are going for.'}
                            </p>
                            {props.buttonText &&
                                props.buttonAction ? 
                                    <button onClick={props.buttonAction} type="button" className={`btn btn-stroke hidden ${props.isVideoStudio && 'sm:block'}`}>{props.buttonText}</button>:
                                    <a href={props.link || '#' } className="btn btn-stroke">{props.buttonText }</a>
                            }
                        
                </div>
            </div>
            {/* {# Media #} */}
            <div className="col w-full md:w-1/2 order-1 md:order-2">
                {props.mp4 ? 
                <div className="shadow-md mb-40 md:mb-0">
                    <Video 
                        image={props.poster}
                        mp4={props.mp4} 
                        webm={props.webm} 
                    />
                </div>
                :
                <figure className="shadow-md mb-40 md:mb-0">
                    <img src={ props.poster ||`${process.env.PUBLIC_URL}/images/home/feature-01.jpg`} alt=""/>
                </figure>
                }            
            </div>
        </article>
        :
        <article className={`feature-block grid-row items-center`}>
            {/* {# Media #} */}
            <div className="col w-full md:w-1/2">
                {props.mp4 ? 
                 <div className="shadow-md mb-40 md:mb-0">
                    <Video 
                        image={props.poster} 
                        mp4={props.mp4} 
                        webm={props.webm} 
                    />
                </div>
                :
                <figure className="shadow-md mb-40 md:mb-0">
                    <img src={ props.poster ||`${process.env.PUBLIC_URL}/images/home/feature-01.jpg`} alt=""/>
                </figure>
                }            
            </div>
            {/* {# Text #} */}
            <div className="col w-full md:w-1/2">
                <div className="lg:px-40 xl:px-80 xxl:px-100 max-w-xl">
                    <h2 className="mb-20">{props.title || 'The Perfect Track'}</h2>
                            <p className="text-h4 mb-25 lg:mb-40">
                                { props.desc || 'Search for Music using our many filters to narrow down the exact vibe you are going for.'}
                            </p>
                            {props.buttonText &&
                                props.buttonAction ? 
                                    <button onClick={props.buttonAction} type="button" className={`btn btn-stroke hidden ${props.isVideoStudio && 'sm:block'}`}>{props.buttonText}</button>:
                                    <a href={props.link || '#' } className="btn btn-stroke">{props.buttonText }</a>
                            }
                          
                </div>
            </div>
        </article>
    )
} 

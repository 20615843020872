import React from 'react'

const NoSubscriptionIndicator = ({ label, desc }) => (
  <div className="flex items-center">
    <div className="flex-1">
      <div className="text-white item-label">{label}</div>
      <div className="text-xs text-gray-500 font-medium item-description">{desc}</div>
    </div>
  </div>
);

export default NoSubscriptionIndicator;

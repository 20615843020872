import moment from "moment";
import React, { Component, createRef } from "react";
import Slider from 'react-input-slider';
import LoadingOverlay from "react-loading-overlay";
import MicroModal from "react-micro-modal";
import { NavLink } from "react-router-dom";
import { ScaleLoader } from "react-spinners";
import { Tooltip } from "react-tippy";
import 'react-tippy/dist/tippy.css';
import WaveSurfer from 'wavesurfer.js';
import { FavoritesConsumer } from "../../FavoritesContext/index";
import { PlayerContext } from "../../PlayerContext";
import AddToProjectModal from "../Projects/addToProjectModal";
import { Error, Success } from "../Toast/index";
import TrackTooltip from "../Track/trackTooltip";
import DownloadTrack from "../Track/DownloadTrack";

const contentDis = require('content-disposition')
const userAgent = window.navigator.userAgent.toLowerCase();
const isMobileSafari = (/iphone/.test(userAgent) || /ipad/.test(userAgent) || (navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints && navigator.maxTouchPoints > 2));

// console.log('user agent ', userAgent)
// console.log("is mobile safari ", isMobileSafari)
var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment)
// import { PlayerContext } from "../../PlayerContext";

class WaveformLoggedOut extends Component {
    state = {
        src: this.props.src || "",
        peaks: this.props.peaks,
        currentTime: "0:00",
        duration: "0:00",
        // sendPlay: 0,
        volume: 1
    }
    handleOnChange = (value) => {
      console.log(value)
      this.setState({
        volume: value.x
      },()=> this.wavesurfer.setVolume(this.state.volume)
      )
    }
  setVolume = ()=>{
    console.log("setting volume to ", this.state.volume)
  }
  toggleTrackTippy = ()=>{
    this.setState({showTrackTippy: !this.state.showTrackTippy})
}

  wrapper = createRef()
  componentDidMount() {
    this.$el = this.wrapper.current
    this.$waveform = this.$el.querySelector('.wave')
    console.log(this.$waveform)
    this.$mobileWaveform = this.$el.querySelector('.mobile-wave')
    console.log("mobile wave ",this.$mobileWaveform);

    this.wavesurfer = WaveSurfer.create({
        container: this.$waveform,
        normalize: true,
        barMinHeight: 1,
        barWidth: 1,
        barGap: 2,
        hideScrollbar: true,
        interact: false,
        cursorWidth: 0,
        backend: "MediaElement",
        height: 80,
        progressColor: "rgba(255,255,255,1)",
        responsive: true,
        waveColor: "rgba(255,255,255,.5)",
        xhr:{ cache: 'default', mode: 'cors', method: 'GET', credentials: 'same-origin', redirect: 'follow', referrer: 'client', headers: [ { key: 'Authorization', value: 'my-token' } ]}
    })
    this.mobileWavesurfer = WaveSurfer.create({
      container: this.$mobileWaveform,
      normalize: true,
      barMinHeight: 1,
      barWidth: 1,
      barGap: 2,
      hideScrollbar: true,
      interact: false,
      cursorWidth: 0,
      backend: "MediaElement",
      height: 3.125 * (parseFloat(getComputedStyle(document.documentElement).fontSize)),
      progressColor: "rgba(255,255,255,1)",
      responsive: true,
      waveColor: "rgba(255,255,255,.5)",
      xhr:{ cache: 'default', mode: 'cors', method: 'GET', credentials: 'same-origin', redirect: 'follow', referrer: 'client', headers: [ { key: 'Authorization', value: 'my-token' } ]}
  })
    console.log('forPlayerLoggedOut this.wavesurfer', this.wavesurfer);
    this.context.setWaveFormEl(this.wavesurfer)
    this.wavesurfer.on('ready', ()=>{
      if(this.context.volume ===.75){
        this.wavesurfer.setVolume(.5)
        let audioEl = document.getElementsByTagName("audio")[0]
        if(audioEl.volume === 1){
          this.setState({isIpad: true})
        }
        this.wavesurfer.setVolume(.75)
      }
      else{
        this.wavesurfer.setVolume(.5)
        let audioEl = document.getElementsByTagName("audio")[0]
        if(audioEl.volume === 1){
          this.setState({isIpad: true})
        }
        this.wavesurfer.setVolume(this.context.volume)
      }
      this.context.setWaveFormEl(this.wavesurfer);
      this.setState({ready: true})
    })
    this.mobileWavesurfer.on('ready', ()=>{

      this.setState({mobileready: true})
    })
    this.mobileWavesurfer.backend.peaks = JSON.parse(this.context.mediaPlayer?.peaks?.length > 0 ? this.context.mediaPlayer?.peaks:   "[]")
    this.mobileWavesurfer.drawBuffer()
    this.onInteract = this.mobileWavesurfer.on('seek', (seekTime)=>{
      if(seekTime - this.wavesurfer.getCurrentTime()/this.context.waveFormEl.getDuration() > .02 || seekTime - this.wavesurfer.getCurrentTime()/this.context.waveFormEl.getDuration() < -.02 ){
        console.log("gonna change main track")
        this.context.waveFormEl.seekTo(seekTime)
      }
    })

    this.songFinished = this.wavesurfer.on('finish', ()=>{
      this.props.triggerLoginModal()
      // this.context.playPause()
    })
    this.trackPlayTime = this.wavesurfer.on('audioprocess', ()=>{
      this.audioProcessFunction()
    })



  }
  audioProcessFunction = () => {
    // console.log('audioProcessFunction ran');
    let ratio = 30/(60 * parseInt(this.context.mediaPlayer.durationDisplay?.split(":")[0]) + parseInt(this.context.mediaPlayer.durationDisplay?.split(":")[1]))
    this.context.updateTime(this.wavesurfer.getCurrentTime(), ratio);
    let fullWavWidth = window.getComputedStyle(this.$waveform?.firstChild)?.width
    let progressFullWidth = fullWavWidth.split("px")[0] * ratio
    let progressCurrent = this.wavesurfer.getCurrentTime()/30 * progressFullWidth
    this.$waveform.firstChild.firstChild.style.width = progressCurrent + "px"
    if(this.state.isMobilePanelOpen){
      let mobileFullWavWidth = window.getComputedStyle(this.$mobileWaveform?.firstChild)?.width
      let mobileProgressFullWidth = mobileFullWavWidth.split("px")[0] * ratio
      let mobileProgressCurrent = this.wavesurfer.getCurrentTime()/30 * mobileProgressFullWidth
      this.$mobileWaveform.firstChild.firstChild.style.width = mobileProgressCurrent + "px"
    }
  }
  trackPlayTime = ()=>{}
  mobileTrackPlayTime = ()=>{}
  songFinished = ()=>{}
  songLoaded = ()=>{}
  wavesurferError = ()=>{}
  currentTimeInt = ()=>{}
  componentDidUpdate = (prevProps, prevState, snapshot)=>{

        if(prevProps.peaks !== this.context.mediaPlayer?.peaks){
          this.mobileWavesurfer.backend.peaks = JSON.parse(this.context.mediaPlayer?.peaks?.length > 0 ? this.context.mediaPlayer.peaks:   "[]")
          this.mobileWavesurfer.drawBuffer()

        }
    }
  play= ()=>{
    if(this.context.offsetTrackStart){
      // this.wavesurfer.play(this.context.offsetTrackStart)
      // this.context.resetTrackOffsetStart()
    }
    else{
      // this.wavesurfer.play()
    }

  this.setState({playing: true, waiting: true})

  }
  pause = ()=>{
    console.log("pausing")
    if(this.context.playing){
      this.context.playPause()
    }

    this.setState({playing: false})
    this.wavesurfer.pause()
  }


  shareTrack = ()=>{

      var textArea = document.createElement("textarea");

      // Place in top-left corner of screen regardless of scroll position.
      textArea.style.position = 'fixed';
      textArea.style.top = 0;
      textArea.style.left = 0;

      // Ensure it has a small width and height. Setting to 1px / 1em
      // doesn't work as this gives a negative w/h on some browsers.
      textArea.style.width = '2em';
      textArea.style.height = '2em';

      // We don't need padding, reducing the size if it does flash render.
      textArea.style.padding = 0;

      // Clean up any borders.
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';

      // Avoid flash of white box if rendered for any reason.
      textArea.style.background = 'transparent';


      textArea.value = window.location.protocol + "//" +window.location.hostname + (window.location.port ? ":" + window.location.port : "") +"/" + "tracks/" + this.context.mediaPlayer.trackURL;

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand('copy');
        if(successful){
            Success({message: "Track URL Copied to Clipboard"})
        }
        else{
            Error({message: "Couldn't Copy Track URL "})
        }
        var msg = successful ? 'successful' : 'unsuccessful';
        console.log('Copying text command was ' + msg);
      } catch (err) {
        console.log('Oops, unable to copy');
      }

      document.body.removeChild(textArea);

      this.setState({showTrackTippy: false})


  }
  mobileShareTrack = ()=>{
  if (navigator.share) {
    navigator.share({
      title: 'Share Track',
      text: this.context.mediaPlayer.trackTitleDisplay,
      url: window.location.protocol + "//" +window.location.hostname + "/" + "tracks/" + this.context.mediaPlayer.trackURL,
    })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing', error));
  }
  else{
    var textArea = document.createElement("textarea");

    // Place in top-left corner of screen regardless of scroll position.
    textArea.style.position = 'fixed';
    textArea.style.top = 0;
    textArea.style.left = 0;

    // Ensure it has a small width and height. Setting to 1px / 1em
    // doesn't work as this gives a negative w/h on some browsers.
    textArea.style.width = '2em';
    textArea.style.height = '2em';

    // We don't need padding, reducing the size if it does flash render.
    textArea.style.padding = 0;

    // Clean up any borders.
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';

    // Avoid flash of white box if rendered for any reason.
    textArea.style.background = 'transparent';


    textArea.value = window.location.protocol + "//" +window.location.hostname + (window.location.port ? ":" + window.location.port : "") +"/" + "tracks/" + this.context.mediaPlayer.trackURL;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      var successful = document.execCommand('copy');
      if(successful){
          Success({message: "Track URL Copied to Clipboard"})
      }
      else{
          Error({message: "Couldn't Copy Track URL "})
      }
      var msg = successful ? 'successful' : 'unsuccessful';
      console.log('Copying text command was ' + msg);
    } catch (err) {
      console.log('Oops, unable to copy');
    }

    document.body.removeChild(textArea);

    this.setState({showTrackTippy: false})
  }

  }
  componentWillUnmount() {
    // this.context.reloadPlayer()
    this.context.waveFormEl.pause()
    this.wavesurfer.destroy()

    // this.audioProcessFunction.flush()
    // this.audioProcessFunction.cancel()
    // this.audioProcessFunction.cancel()
    // this.audioProcessFunction.cancel()
    // this.audioProcessFunction.cancel()
    // this.audioProcessFunction.cancel()

    // this.audioProcessFunction.cancel()

    console.log("calling reload player from public")
    this.context.reloadPlayerFromPublic(this.audioProcessFunction)
  }
  resolveAlbumPhoto=(albumPhotoKey)=>{
    const key = albumPhotoKey
    if(albumPhotoKey){
        const ext = key.split('.').reverse()[0];
        let sizeMod
        const width = window.innerWidth
        if(width >= 1280){
            sizeMod = '140x140'
        }
        else {
            sizeMod = '140x140'
        }
        let final = key.replace('.' + ext, '') + '-minified-' + sizeMod  + '.' + ext
        return final
    }
  }
  resolveMobileAlbumPhoto=(albumPhotoKey)=>{
    const key = albumPhotoKey
    if(albumPhotoKey){
        const ext = key.split('.').reverse()[0];
        let sizeMod = '500x500'
        let final = key.replace('.' + ext, '') + '-minified-' + sizeMod  + '.' + ext
        return final
    }
  }
  render() {
    let {volume} = this.state
    return (
      <FavoritesConsumer>
      {({artists, tracks, albums, collections, likeTrack, unlikeTrack, followArtist, unfollowArtist})=>(
      <div ref={this.wrapper} className="playbar-container">
	<div className="playbar">
		<div className="playbar-track-info flex items-center" onClick={()=>this.setState({isMobilePanelOpen: true})}>
			<div className="playbar-image">
      {this.context.mediaPlayer?.imageURL ?
				<NavLink onClick={()=>this.setState({isMobilePanelOpen: false})} className="playbar-title" to={"/tracks/" + this.context.mediaPlayer.trackURL}>
          <img src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveAlbumPhoto(this.context?.mediaPlayer?.imageURL)}`} alt="album-art"/>
        </NavLink>:
        <></>}
      </div>
			<div className="playbar-info-content ml-10 flex flex-col">
				<NavLink onClick={()=>this.setState({isMobilePanelOpen: false})} className="playbar-title" to={"/tracks/" + this.context.mediaPlayer.trackURL}>{this.context.mediaPlayer.trackTitleDisplay}</NavLink>
				<NavLink onClick={()=>this.setState({isMobilePanelOpen: false})} className="playbar-artist" to={"/artists/" + this.context.mediaPlayer.artistURL}>{this.context.mediaPlayer.artistNameDisplay}</NavLink>
			</div>
		</div>
		<div className="playbar-actions-one">
			<button onClick={()=>{this.context.playPrev();this.props.triggerLoginModal()}} className="icon-rewind hidden"></button>
      {/* if playing render pause button, else render play button */}
      {this.context.mediaPlayer.isTrackPlaying ?
        <button onClick={this.context.handlePause} className="icon-pause" ></button>
        :
        <button onClick={this.context.handlePlay} className="icon-play" ></button>
}
			<button onClick={()=>{this.context.playNext();this.props.triggerLoginModal()}}className="icon-fast-forward hidden"></button>
		</div>
		<div className="playbar-track-duration">
			<span>{this.context.mediaPlayer.formattedCurrentTime}</span>
      <div style={{backgroundColor: "#131313" }} className='waveform' onClick={()=>{this.props.triggerLoginModal()}}>

        <div style={{visibility: !this.context.mediaPlayer.isLoading ? "visible": "hidden"}} id='trackWave'className='wave'/>


      </div>
      <span>{this.context.mediaPlayer.durationDisplay}</span>

       </div>
       <div className="playbar-actions-two">

          {tracks?.filter(fav=>fav.trackId === this.context.mediaPlayer.trackId).length === 1 ?
            <button onClick={()=>unlikeTrack(this.context.mediaPlayer.trackId)} className={`icon-like-fill js-tooltip is-liked btn-jelly`} title="Like track">
            </button>:
            <button onClick={()=>likeTrack(this.context.mediaPlayer.trackId)} className="icon-like js-tooltip btn-jelly" title="Like track">
            </button>
          }
          <MicroModal
                                trigger={handleOpen =>
                                   <button onClick={this.context.triggerLoginModal} className="icon-plus"></button>}
                                closeOnEscapePress={true}
                                closeOnOverlayClick={true}
                                closeOnAnimationEnd={true}
                                modalOverlayClassName="modal-overlay"
                                modalClassName="modal micromodal-slide is-open"
                                modalOverlayStyles={null}
                                children={handleClose=>
                                    <AddToProjectModal
                                        handleClose={handleClose}
                                        trackId={this.context.mediaPlayer.trackId}
                                        trackTitle={this.context.mediaPlayer.trackTitleDisplay}
                                    />
                                }
                                containerStyles={{
                                    background: "#131313",
                                    padding:"asdf",
                                    maxWidth: "40.625rem !important",
                                    maxHeight: "100vh",
                                    borderRadius: "asdf",
                                    overflowY: "auto",
                                    boxSizing: "border-box"
                                }}
                            />
          <button onClick={this.context.triggerLoginModal} className="icon-video-studio js-tooltip" title="Video Studio"></button>

                                      <DownloadTrack
                                        artistId={this.context.mediaPlayer.artistId}
                                        artistName={this.context.mediaPlayer.artistNameDisplay}
                                        imageURL={this.context.mediaPlayer?.imageURL}
                                        trackId={this.context.mediaPlayer.trackId}
                                        trackTitle={this.context.mediaPlayer.trackTitleDisplay}
                                        triggerLoginModal={this.context.triggerLoginModal}
                                      />

		</div>
		<div className="playbar-volume-options">
      {!isMobileSafari &&
        <div className="volume-controls">
          <button className="icon-volume"></button>
          {/* <div className="volume-controller"></div> */}
          <Slider
          styles={{
            track: {
              backgroundColor: '#2F2F2F',
              height: 1,
              width: "5vw",
              marginLeft: "5px"
            },
            active: {
              backgroundColor: '#fff'
            },
            thumb: {
              width: 10,
              height: 10
            },
            disabled: {
              opacity: 0.5
            }}}
            xmin={0}
            xmax={1}
            xstep={.05}
            x={this.context.volume}
            axis="x"
            onChange={this.context.setVolume}
            />
        </div>
      }
			<div className="playbar-more-options">
        <Tooltip
              open={this.state.showTrackTippy && !this.state.isMobilePanelOpen}
              useContext={true}
              arrow={false}
              interactive={true}
              offset={-100}
              position={'top'}
              trigger={'click'}
              maxWidth={'180'}
              allowHTML={true}
              onUntrigger={()=>{this.state.showTrackTippy ? this.setState({showTrackTippy: false}): this.setState({showTrackTippy: true})}}
              onRequestClose={()=>{this.state.showTrackTippy ? this.setState({showTrackTippy: false}): this.setState({showTrackTippy: true})}}
              html={<TrackTooltip
                trackId={this.context.mediaPlayer.trackId}
                trackTitle={this.context.mediaPlayer.trackTitleDisplay}
                artistId={this.context.mediaPlayer.artistId}
                artistName={this.context.mediaPlayer.artistNameDisplay}
                imageURL={this.context?.mediaPlayer?.imageURL}
                liked={tracks?.filter(fav=>fav.trackId === this.context.mediaPlayer.trackId).length}
                likeTrack={likeTrack}
                unlikeTrack={unlikeTrack}
                following={artists?.filter(fav=>fav.artistId === this.context.mediaPlayer.artistId).length === 1}
                followArtist={followArtist}
                unfollowArtist={unfollowArtist}
                shareTrack={this.shareTrack}
                toggleTrackTippy={this.toggleTrackTippy}
                triggerLoginModal={this.context.triggerLoginModal}
                >
            </TrackTooltip>}
              allowHTML={true}
              theme={'dark'}
          >
            <button
                onClick={this.toggleTrackTippy}
                className="icon-btn js-dropdown-toggle"
                data-tippy-placement="top"
            >
                <span className="icon-dots-vert"></span>
            </button>

          </Tooltip>
			</div>
		</div>
	</div>
  <div
		className={`mobile-player-panel ${this.state.isMobilePanelOpen ? "is-open": "is-closed"}`}

	>
		{/* {# Header #} */}
		<div className="mobile-player-header">
			<div className="font-medium text-gray-400">Now Playing</div>

			{/* {# Close Button #} */}
			<button
        onClick={()=>this.setState({isMobilePanelOpen: false})}
				className="circle-icon circle-icon-sm circle-icon-border"
				type="button"
			>
				<span className="text-icon-sm icon-chevron-down"></span>
			</button>
		</div>
  <div className="mobile-player-content">

{/* {# Top #} */}
<div className="mobile-player-top">
  {/* {# Track Artwork #} */}
  <div className="mobile-player-hero">

    {/* {# Artwork Image #} */}
        <img src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveMobileAlbumPhoto(this.context?.mediaPlayer?.imageURL)}`} className="mobile-player-artwork"/>

    {/* {# Hero BG #} */}
    <div className="hero-fixed hero-square hero-overlay-blur">
            <div className="hero-img" style={{backgroundImage: `url("https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveMobileAlbumPhoto(this.context?.mediaPlayer?.imageURL)}")`}}></div>
        </div>
  </div>

  {/* {# Mobile Player Track Info #} */}
  <div className="mobile-player-track">
  <NavLink onClick={()=>this.setState({isMobilePanelOpen: false})} className="mobile-player-title" to={"/tracks/" + this.context.mediaPlayer.trackURL}>{this.context.mediaPlayer.trackTitleDisplay}</NavLink>
    <div className="mobile-player-artist">
      <NavLink onClick={()=>this.setState({isMobilePanelOpen: false})} className="text-link-gray" to={"/artists/" + this.context.mediaPlayer.artistURL}>{this.context.mediaPlayer.artistNameDisplay}</NavLink>
    </div>
    <div className="mobile-player-album">
      <NavLink onClick={()=>this.setState({isMobilePanelOpen: false})} className="text-link-gray" to={`/artists/${this.context.mediaPlayer.artistURL}#album-${this.context.mediaPlayer.albumTitle?.replace(/\s/g, "-")}`}>{this.context.mediaPlayer.albumTitle}</NavLink>
    </div>
  </div>
</div>

{/* {# Track Duration #} */}
<div className="mobile-player-duration">
  <span className="mobile-player-duration-time">{this.context.mediaPlayer.formattedCurrentTime}</span>
  <div className="mobile-player-waveform">
    {/* {% include "components/audio/waveform-sm.html" %} */}
    <div className='waveform' onClick={()=>this.props.triggerLoginModal()}>

        <div style={{visibility: !this.context.mediaPlayer.isLoading ? "visible": "hidden"}}  id='mobileWave' className='mobile-wave'/>

      </div>
  </div>
  <span className="mobile-player-duration-time">{this.context.mediaPlayer.durationDisplay}</span>
</div>

{/* {# Mobile Player Controls #} */}
<div className="mobile-player-controls" >

  {/* {# Like #} */}
  {tracks?.filter(fav=>fav.trackId === this.context.mediaPlayer.trackId).length === 1 ?
    <button onClick={()=>unlikeTrack(this.context.mediaPlayer.trackId)} className={`circle-icon circle-icon-lg btn-jelly is-liked`} title="Like track">
                  <span className="icon-like-fill" ></span>
    </button>:
    <button onClick={()=>likeTrack(this.context.mediaPlayer.trackId)} className={`circle-icon circle-icon-lg btn-jelly`} title="Like track">
            <span className="icon-like" ></span>

    </button>
  }
        {/* <button
        className="circle-icon circle-icon-lg btn-jelly"
            type="button"
        >
            <span ></span>
        </button> */}

  {/* {# Prev #} */}
  <button onClick={()=>{this.context.playPrev();this.props.triggerLoginModal()}} type="button" className="circle-icon circle-icon-lg">
    <span className="text-icon-lg icon-rewind"></span>
  </button>

  {/* {# Play #} */}
  {this.context.mediaPlayer.isTrackPlaying ?
        <button onClick={this.context.handlePause} className="circle-icon-fill circle-icon-xl" >
              <span className="icon-pause" ></span>
        </button>
        :
        <button onClick={this.context.handlePlay} className="circle-icon-fill circle-icon-xl" >
          <span className="icon-play" ></span>
        </button>
}


  {/* {# Prev #} */}
  <button onClick={()=>{this.context.playNext(); this.props.triggerLoginModal()}} type="button" className="circle-icon circle-icon-lg">
    <span className="text-icon-lg icon-fast-forward"></span>
  </button>

  {/* {# More #} */}
  <button
    className="circle-icon circle-icon-lg icon-btn js-dropdown-toggle"
    onClick={this.toggleTrackTippy}
    data-tippy-placement="top"
  >
    <Tooltip
      open={this.state.showTrackTippy && this.state.isMobilePanelOpen}
      useContext={true}
      arrow={false}
      interactive={true}
      offset={-100}
      position={'top'}
      trigger={'click'}
      maxWidth={'180'}
      allowHTML={true}
      onUntrigger={()=>{this.state.showTrackTippy ? this.setState({showTrackTippy: false}): this.setState({showTrackTippy: true})}}
      onRequestClose={()=>{this.state.showTrackTippy ? this.setState({showTrackTippy: false}): this.setState({showTrackTippy: true})}}
      html={<TrackTooltip
        trackId={this.context.mediaPlayer.trackId}
        trackTitle={this.context.mediaPlayer.trackTitleDisplay}
        artistId={this.context.mediaPlayer.artistId}
        artistName={this.context.mediaPlayer.artistNameDisplay}
        liked={tracks?.filter(fav=>fav.trackId === this.context.mediaPlayer.trackId).length}
        likeTrack={likeTrack}
        unlikeTrack={unlikeTrack}
        following={artists?.filter(fav=>fav.artistId === this.context.mediaPlayer.artistId).length === 1}
        followArtist={followArtist}
        unfollowArtist={unfollowArtist}
        shareTrack={this.mobileShareTrack}
        toggleTrackTippy={this.toggleTrackTippy}
        triggerLoginModal={this.context.triggerLoginModal}
        >
    </TrackTooltip>}
      allowHTML={true}
      theme={'dark'}
    >
      <span className="icon-dots-vert"></span>
    </Tooltip>
  </button>


</div>
</div>
</div>
</div>)}</FavoritesConsumer>
    )
  }
}

WaveformLoggedOut.defaultProps = {
  src: "",
  peaks: ""
}

WaveformLoggedOut.contextType = PlayerContext
export default WaveformLoggedOut

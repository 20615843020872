import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import Joi from "joi";
import api from "../../utils/api";
import { LabelRequired } from "../../FormElements/labels";
import { Success, Error } from "../../Toast/index";
import BackdropOverlayComponent from "../../LoadingOverlay/backdropComponent";

const schema = Joi.object({
  password: Joi.string().required().messages({
    "any.required": "Please enter your password",
    "string.empty": "Please enter your password",
  }),
});

const CheckPasswordForm = (props) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, setError, formState, errors } = useForm({
    mode: "onSubmit",
    defaultValues: {},
    resolver: joiResolver(schema),
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  useEffect(() => {
    // console.log('formState', formState);
    // Show all errors as Toastify
    Object.entries(errors).map((error) =>
      Error({
        message: error[1].message,
        toastId: error[0],
      })
    );
  }, [errors]);

  const passwordRef = useRef(null);
  useEffect(() => {
    if (passwordRef.current) {
      register(passwordRef.current);
      passwordRef.current.focus();
    }
  }, []);

  const onSubmit = (data, e) => {
    setIsDisabled(true);
    setIsLoading(true);
    api
      .checkPassword(data.password)
      .then((res) => {
        console.log("res data", res);
        if (res.data?.success && res.data?.data?.passwordCheckToken) {
          props.setPasswordToken(res.data?.data?.passwordCheckToken);
          Success({
            message: "Password confirmed",
          });
        } else if (res.data) {
          Error({
            message: res.data.message,
          });
          setIsDisabled(false);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="ds-form">
      <div className="grid-row-xs">
        {isLoading && <BackdropOverlayComponent loading={true} size={40} />}

        <div className="col w-full mb-20">
          Please enter your current password:
        </div>

        <div className="col w-full">
          <div className="input-group input-inline mb-20">
            <input
              id="current_password"
              name="password"
              type="password"
              className="form-input password"
              autoComplete="current-password"
              ref={passwordRef}
            />
            <LabelRequired for="passwordCheck">Current Password</LabelRequired>
          </div>
        </div>

        <div className="col w-full">
          <div className="input-group input-inline mb-10">
            <button
              type="button"
              className="w-full btn form-btn btn-primary"
              onClick={handleSubmit(onSubmit)}
              disabled={isDisabled}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default CheckPasswordForm;

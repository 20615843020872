import React, { Component } from "react";
import Slider from "react-slick";
import { PlayerContext } from "../../PlayerContext/index.js";
import FeaturedArtistCard from "../Browse/featuredArtistCard.js";
import "../Carousels/index.css";
import { Error, Success } from "../Toast/index";

class FeaturedArtists extends Component{
    state={
            
    }
    playFeaturedTracks = (tracks, artistId)=>{

        this.props.closeVideoMain() 
        this.props.unloadVideo(true)
        let playList = tracks
        playList.forEach((track, index)=>{
            playList[index].idKey = "browseFeaturedArtistCard-" + artistId + "-trackId-" + track.trackId
        })
        this.context.setPlaylist(playList)
        this.context.loadTrackInMediaPlayer(playList[0].trackId, playList[0].idKey)
        this.context.setAutoPlay(true)
        this.setState({showArtistTippy: false, playingArtistId: artistId})
    }
    toggleFeaturedCardTippy = (artistId)=>{
        this.setState({[`isFeaturedCardTippyOpen${artistId}`]: !this.state[`isFeaturedCardTippyOpen${artistId}`]})
    }
    shareFeatured = (artistId, artistURL)=>{
        var textArea = document.createElement("textarea");

        // Place in top-left corner of screen regardless of scroll position.
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em';
        textArea.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = 0;

        // Clean up any borders.
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';

        // Avoid flash of white box if rendered for any reason.
        textArea.style.background = 'transparent';

        if(window.location.port){
            textArea.value = window.location.protocol + window.location.hostname + ":" + window.location.port + "/artists/" +artistURL;
        }
        else{
            textArea.value = window.location.protocol + window.location.hostname + "/artists/" +artistURL;
        }

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          var successful = document.execCommand('copy');
          if(successful){
              Success({message: "Artist URL Copied to Clipboard"})
          }
          else{
              Error({message: "Couldn't Copy Artist URL "})
          }
          var msg = successful ? 'successful' : 'unsuccessful';
        } catch (err) {
        }

        document.body.removeChild(textArea);

        this.setState({[`isFeaturedCardTippyOpen${artistId}`]: false})

        
    }
    play=(tracks, idKey)=>{
        if(this.context.isMainOpen || this.context.isDemoOpen){
            this.context.closeVideoMain() 
            setTimeout(()=>{
                this.context.loadTrackInMediaPlayer(tracks[0].trackId, idKey )
                this.context.setPlaylist(tracks.map(track=>track.trackId))
                this.context.setAutoPlay(true)
            },50)
            // this.props.unloadVideo(true)
        }
        else{
            this.context.loadTrackInMediaPlayer(tracks[0].trackId, idKey )
            this.context.setPlaylist(tracks.map(track=>track.trackId))
        }

    }
    render(){
        const settings = {
            arrows: false,
            swipeToSlide: true,
            infinite: true,
            variableWidth: true,
            slidesToShow: 2,
            edgeFriction: '.05',
            adaptiveHeight: true,
            draggable: true,
        }
        return(
            <div className="featured-carousel">
                <div className="container">
                    <div className="grid-row-sm">
                        <div className="col w-full xl:w-1/3">
                            <div className="featured-carousel-header">
                                <div className="flex flex-col mb-30">
                                    <h2 className="text-h2 mb-20">Discover Artists</h2>
                                    <p className="text-h4 max-w-md mb-0">Discover and support independent artists while you find the perfect song for your project.</p>
                                </div>
                                <div className="mb-40"><a href="/artists" className="btn btn-primary">Browse Artists</a></div>
                                <div className="ui-control-arrows flex">
                                    <button onClick={(e)=>{e.preventDefault();this.slider.slickPrev()}}  className="circle-icon-border lg:circle-icon-lg icon-chevron-left mr-15"></button>
                                    <button onClick={(e)=>{e.preventDefault();this.slider.slickNext()}} className="circle-icon-border lg:circle-icon-lg icon-chevron-right"></button>
                                </div>
                            </div>
                        </div>
                        <div className="col w-full xl:w-2/3">
                            <Slider ref={slider => (this.slider = slider)} {...settings} className="card-carousel">
                                {this.props.featuredArtists.map((artist, index)=>
                                    <div key={"featuredArtist" + artist.artistName}  className="carousel-slide" >
                                        <FeaturedArtistCard {...artist} idKey={`artist-${artist.artistId}-album-${this.context.loadedTracks[artist.tracks[0]?.trackId]?.albumId}-track-${artist.tracks[0]?.trackId}-element-featuredArtistItemCard-${artist.artistId}`}  playTracks={this.playFeaturedTracks} pauseTrack={this.context.playPause}  isPlaying={(this.context.mediaPlayer.isTrackPlaying || this.context.isVideoPlaying)}
                                            contextIdKey={this.context.mediaPlayer.idKey} toggleFeaturedTippy={this.toggleFeaturedCardTippy} play={this.play} isArtistTippyOpen={this.state[`isFeaturedCardTippyOpen${artist.artistId}`]} shareArtist={this.shareFeatured} handlePlay={this.context.handlePlay} handlePause={this.context.handlePause} isMainOpen={this.context.isMainOpen} isDemoOpen={this.context.isDemoOpen} />
                                    </div>
                                )}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
FeaturedArtists.contextType = PlayerContext
export default FeaturedArtists
import React, { Component } from "react";
import Navbar from "../Navbar";

class BrowseLayout extends Component {
  state = {};

  render() {
    return (
      <>
        <Navbar pathname={window.location.pathname}></Navbar>
        <div className="app-container">{this.props.children}</div>
      </>
    );
  }
}

export default BrowseLayout;

import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { LICENSE_MAIN_CATEGORIES, PERSONAL_LICENSE_OPTIONS } from "../../../constants";
import customStyles from "../../FormElements/customStyles";
import CustomValueContainer from "../../FormElements/customValueContainer";
import { CartContext } from "../../../contexts/Cart";
import { UserContext } from "../../../contexts/User";
import { handleAPIError } from "../../../utils/handleAPIError";
import getLicenseCategoryOptions from "./Options/getLicenseCategoryOptions";
import FormatGroupLabel from "./FormatGroupLabel";
import SubscriptionIndicator from "./SubscriptionIndicator";
import NoSubscriptionIndicator from "./NoSubscriptionIndicator";
import licenseProductsAPI from "../../../api/licenseProducts";
import LicensePrice from "./LicensePrice";
import productsAPI from "../../../api/products";
import LoadingContainer from "../../Loading/LoadingContainer";

const LicenseForm = (props) => {
  const {
    activeTab,
    demoSubscription,
    handleClose,
    isDemo,
    licenseMainSelected,
    tabItem,
    track,
  } = props;

  const history = useHistory();

  const { addToCart, updateCartItem } = useContext(CartContext);

  // grab userContext data
  const currentUser = useContext(UserContext);
  // console.log("currentUser", currentUser);
  // console.log("props", props);
  const plan = isDemo ? demoSubscription?.product : currentUser?.user?.subscription?.plan;
  const subscriber = isDemo ? demoSubscription : currentUser?.user?.planStatus === "active";
  // console.log("subscriber", subscriber);
  const demoLicensesIncluded = plan?.metadata?.licenses_included || "[]";
  const defaultLicensesIncluded =
    (isDemo ? demoLicensesIncluded : plan?.metadata?.licenses_included) || null;
  const [licensesIncluded, setLicensesIncluded] = useState(defaultLicensesIncluded);

  let licensesHidden = plan?.metadata?.licenses_hidden;
  // console.log("plan", plan);
  // console.log("licensesIncluded", licensesIncluded);

  const [originalPrice, setOriginalPrice] = useState(false);
  const [finalPrice, setFinalPrice] = useState(false);
  const [priceIsLoaded, setPriceIsLoaded] = useState(false);
  const [productOptions, setProductOptions] = useState([]);

  const [selectedProductType, setSelectedProductType] = useState(null);
  const [selectedLicense, setSelectedLicense] = useState(false);
  const [licenseIsLoaded, setLicenseIsLoaded] = useState(false);
  const [showPrice, setShowPrice] = useState(false);

  const handleLicenseMain = (selectedCategory) => {
    setLicenseIsLoaded(false);
    setShowPrice(false);

    if (formState?.dirtyFields?.licenseMain) {
      handleFormReset("licenseMain");
    }

    setSelectedProductType(selectedCategory?.label?.toLowerCase());

    // console.log("licenseMain selectedCategory", selectedCategory);
    const licenseMainValue = selectedCategory.key;

    setupProductOptions(licenseMainValue);
    // console.log('licenseProducts', licenseProducts);
  };

  const setupProductOptions = (licenseMainValue) => {
    licenseProductsAPI
      .get(licenseMainValue)
      .then((res) => {
        // console.log("getLicenseProducts res", res);
        const { licenseProductOptions } = res?.data || {};
        // Remove Commercial Standard because it has its own tab
        const licenseProductOptionsFiltered = licenseProductOptions.filter(
          (item) => item.key !== "X2"
        );
        // console.log('licenseProductOptionsFiltered', licenseProductOptionsFiltered);
        if (licenseProductOptionsFiltered?.length > 1) {
          const productOptions = getLicenseCategoryOptions(
            licenseProductOptionsFiltered,
            licensesIncluded,
            licensesHidden,
            subscriber
          );
          setProductOptions(productOptions);
        } else if (licenseProductOptionsFiltered?.length === 1) {
          const selectedCategory = licenseProductOptionsFiltered[0];
          const { key } = selectedCategory || {};

          setShowPrice(true);

          productsAPI
            .getProductByKey({ key })
            .then((response) => {
              console.log("getProductByKey response", response);
              const fetchedData = response?.data;
              fetchedData.product = selectedCategory;
              setSelectedLicense(fetchedData);
              setLicenseIsLoaded(true);
            })
            .catch((error) => {
              handleAPIError(error);
              setLicenseIsLoaded(true);
            });
        }
      })
      .catch((error) => {
        handleAPIError(error);
      });
  };

  const handleProduct = (selectedCategory) => {
    // console.log("handleProduct selectedCategory", selectedCategory);

    if (selectedCategory?.key) {
      console.log("setting selected license", selectedCategory);

      const { key } = selectedCategory || {};
      setLicenseIsLoaded(false);
      setShowPrice(true);

      productsAPI
        .getProductByKey({ key })
        .then((response) => {
          console.log("getProductByKey response", response);
          const fetchedData = response?.data;
          fetchedData.product = selectedCategory;
          setSelectedLicense(fetchedData);
          setLicenseIsLoaded(true);
        })
        .catch((error) => {
          handleAPIError(error);
          setLicenseIsLoaded(true);
        });
    }
  };

  const defaultValues = {
    licenseMain: licenseMainSelected || "",
  };

  const { control, formState, getValues, handleSubmit, reset, setValue } = useForm({
    mode: "onSubmit",
    reValidateMode: "onSubmit",
    defaultValues,
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  let licenseMainCategories = LICENSE_MAIN_CATEGORIES;

  if (subscriber && plan?.metadata?.plan_type === "commercial") {
    // Remove Personal main category because
    // user has active Commercial subscription
    licenseMainCategories = licenseMainCategories.filter((plan) => plan.value !== "A");
  }

  const licenseMainOptions = getLicenseCategoryOptions(
    licenseMainCategories,
    licensesIncluded,
    licensesHidden,
    subscriber
  );
  const personalConfirmOptions = getLicenseCategoryOptions(
    PERSONAL_LICENSE_OPTIONS,
    licensesIncluded,
    licensesHidden,
    subscriber
  );

  const values = getValues();

  const handleFormReset = (currentCategory, currentOption = []) => {
    // console.log('resetting form');
    setFinalPrice(false);
    setSelectedLicense(null);
    setSelectedProductType(null);
    setProductOptions(null);
    setPriceIsLoaded(false);
    setProductOptions([]);
    reset();
  };

  useEffect(() => {
    handleFormReset();
  }, [activeTab]);

  const submitCart = (data) => {
    if (!isNaN(finalPrice)) {
      // let discountNan = isNaN(currentDiscount);

      const licenseInfo = {
        track: {
          trackId: track.trackId,
          trackTitle: track.trackTitle,
          artistId: track.artistId,
          artistName: track.artistName,
          albumArt: track.albumArt,
        },
        license: {
          licenseMain: data?.licenseMain,
          price: data?.priceId,
          product: selectedLicense?.product,
          originalPrice: originalPrice,
          finalPrice: finalPrice,
        },
        subscriber: subscriber,
      };

      addToCart(licenseInfo);
    } else {
      Error({ message: "There was an error adding to your cart" });
    }
  };

  const onSubmit = (submitParams) => (data, e) => {
    e.preventDefault();
    const { redirectPath } = submitParams || {};
    // console.log('formdata', data);
    // console.log('submitParams', submitParams);
    if (!isDemo) {
      submitCart(data);
    }
    if (redirectPath) {
      handleClose();
      history.push(redirectPath);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit({}))} className="ds-form" id="license-form">
        <>
          <div className="pt-20">
            {/* {# Track Usage #} */}
            <div className="input-group-xs input-inline">
              <Controller
                control={control}
                name="licenseMain"
                render={(props) => (
                  <Select
                    onChange={(value) => {
                      props.onChange(value);
                      handleLicenseMain(value);
                    }}
                    className="select-container select-container-sub"
                    classNamePrefix="select"
                    styles={customStyles}
                    placeholder="What is this track for?"
                    options={licenseMainOptions}
                    isSearchable={false}
                    formatOptionLabel={subscriber ? SubscriptionIndicator : NoSubscriptionIndicator}
                    formatGroupLabel={subscriber ? FormatGroupLabel : undefined}
                    components={{
                      ValueContainer: CustomValueContainer,
                    }}
                  />
                )}
              />
            </div>

            {productOptions.length > 0 && (
              <div className="input-group-xs input-inline">
                <Controller
                  control={control}
                  name="product"
                  render={(props) => (
                    <Select
                      onChange={(value) => {
                        props.onChange(value);
                        handleProduct(value);
                      }}
                      value={props.value}
                      className="select-container select-container-sub"
                      classNamePrefix="select"
                      styles={customStyles}
                      placeholder="License Type"
                      options={productOptions}
                      isSearchable={false}
                      formatOptionLabel={
                        subscriber ? SubscriptionIndicator : NoSubscriptionIndicator
                      }
                      formatGroupLabel={subscriber ? FormatGroupLabel : undefined}
                      components={{
                        ValueContainer: CustomValueContainer,
                      }}
                    />
                  )}
                />
              </div>
            )}

            {/* {console.log('selectedLicense', selectedLicense)} */}
            {showPrice && (
              <LoadingContainer isLoaded={licenseIsLoaded}>
                <LicensePrice
                  license={selectedLicense}
                  subscriber={subscriber}
                  isDemo={isDemo}
                  demoSubscription={demoSubscription}
                  handleClose={handleClose}
                  handleLicenseFormSubmit={handleSubmit}
                  onSubmit={onSubmit}
                  tabItem={tabItem}
                  setFormOriginalPrice={setOriginalPrice}
                  setFormFinalPrice={setFinalPrice}
                  track={track}
                />
              </LoadingContainer>
            )}
          </div>
        </>
      </form>
    </>
  );
};

export default LicenseForm;

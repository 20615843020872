import React from 'react';
// import { version } from 'moment'
import { Error, Success, SuccessUndo } from "../components/Toast/index";
import api from "../components/utils/api";
import { handleAPIError } from '../utils/handleAPIError';
const queryStringer = require('query-string');

const FavoritesContext = React.createContext()

class FavoritesProvider extends React.Component {
  state = {
    artists: [],
    tracks:[],
    albums: [],
    collections: []
  }
  componentDidMount = () => {
    if (this.props?.user?.user_id) {
      api.myFavorites()
        .then(res => {
          console.log('myFavorites res', res);
          const { data } = res || {};
          const { myFollowedArtists } = data || [];
          const { myFavoriteAlbums } = data || [];
          const { myFavoriteCollections } = data || [];
          const { myFavoriteTracks } = data || [];

          const artists = [];
          const tracks = [];
          const albums = [];
          const collections = [];

          myFollowedArtists.forEach(fav => {
            artists.push({ artistId: fav.artistId, followedOn: fav.updatedOn })
          });
          myFavoriteTracks?.forEach(track => {
            tracks.push({ trackId: track.id, likedOn: track.likedOn })
          });
          myFavoriteAlbums?.forEach(album => {
            albums.push({ albumId: album.albumId, likedOn: album.likedOn })
          });
          myFavoriteCollections?.forEach(collection => {
            collections.push({ collectionId: collection.id, likedOn: collection.likedOn })
          });

          this.setState({ tracks, albums, artists, collections })
        })
        .catch(error => {
          handleAPIError(error);
        });
    }
  }
  likeTrack = (trackId)=>{
    if(this.props.user === null){
      this.props.triggerLoginModal()
    }
    else{
      let tracks = [...this.state.tracks]
      tracks.push({trackId: trackId, likedOn: "0000-00-00T00:00:00.000Z"})
      this.setState({tracks: tracks}, ()=>{
        api.likeTrack(trackId)
          .then(res=>{

              if(res.data.success){
                  tracks[tracks.length-1].likedOn = res.data.like.updatedOn
                  let query = window.location.search?.length > 0 ? window.location.search.substring(1) : ""
                  let parsedQuery = queryStringer.parse(query)
                  if(parsedQuery.searchId){
                      let searchBody = {
                          type: "likeTrack",
                          trackId: trackId
                      }
                      api.saveActionToSearch(parseInt(parsedQuery.searchId), {action: searchBody})
                          .then(res=>console.log(res))
                  }
              }
              else{
                if(res.data.alreadyLiked){
                  return;
                }
                else{
                  tracks.splice(-1, 1)
                }
              }
              this.setState({tracks: tracks})
          })
          .catch(error=>{
            Error({message: "Error liking track!"})
            tracks.splice(-1,1)
            this.setState({tracks: tracks})
          })
        })
      }
  }
  unlikeTrack = (trackId, trackTitle)=>{
    let tracks = [...this.state.tracks]
    tracks = tracks.filter(fav=>fav.trackId !== trackId)
    this.setState({tracks: tracks}, ()=>{
      api.unlikeTrack(trackId)
        .then(res=>{

            if(res.data.success){
              if(window.location.pathname.includes("dashboard/favorites")){
                SuccessUndo({message: `No Longer Following ${trackTitle}`, undoClick: ()=>this.likeTrack(trackId)})
              }
            }
        })
        .catch(error=>{
          Error({message: "Error unliking track!"})
        })
      })
  }
  followArtist = (artistId, artistName)=>{
    if(this.props.user === null){
      this.props.triggerLoginModal()
    }
    else{
      let artists = [...this.state.artists]
      artists.push({artistId: artistId, followedOn: "0000-00-00T00:00:00.000Z"})
      this.setState({artists: artists}, ()=>{
        api.followArtist(artistId, {following: true})
          .then(res=>{
            if(res.data.success){
              let query = window.location.search?.length > 0 ? window.location.search.substring(1) : ""
              let parsedQuery = queryStringer.parse(query)
              if(parsedQuery.searchId){
                  let searchBody = {
                      type: "followArtist",
                      artistId: artistId
                  }
                  api.saveActionToSearch(parseInt(parsedQuery.searchId), {action: searchBody})
                      .then(res=>console.log(res))
              }
              Success({message: `Following ${artistName}`})
            }
            else{
              Error({message: "Unable to follow Artist"})
              artists.splice(-1,1)
            }
            this.setState({artists: artists})
          })
          .catch(error=>{
            Error({message: "Unable to follow Artist"})
            artists.splice(-1,1)
            this.setState({artists: artists})
          })
      })
    }
  }
  unfollowArtist = (artistId, artistName)=>{
    let artists = [...this.state.artists]
    artists = artists.filter(fav=>fav.artistId !== artistId)
    this.setState({artists: artists}, ()=>{
      api.followArtist(artistId, {following: false})
        .then(res=>{
          if(res.data.success){
            if(window.location.pathname.includes("dashboard/favorites")){
              SuccessUndo({message: `No Longer Following ${artistName}`, undoClick: ()=>this.followArtist(artistId, artistName)})
            }
            else{
              Success({message: `No Longer Following ${artistName}`})
            }
          }
          else{
            Error({message: "Unable to unfollow Artist"})
          }
        })
    })
  }
  likeAlbum = (albumId)=>{
    if(this.props.user === null){
      this.props.triggerLoginModal()
    }
    else{
      let albums = [...this.state.albums]
      albums.push({albumId: albumId, likedOn: "0000-00-00T00:00:00.000Z" })
      this.setState({albums: albums}, ()=>{
        api.likeAlbum(albumId)
          .then(res=>{

              if(res.data.success){
                  albums[albums.length-1].likedOn = res.data.like.updatedOn
                  let query = window.location.search?.length > 0 ? window.location.search.substring(1) : ""
                  let parsedQuery = queryStringer.parse(query)
                  if(parsedQuery.searchId){
                      let searchBody = {
                          type: "likeAlbum",
                          albumId: albumId
                      }
                      api.saveActionToSearch(parseInt(parsedQuery.searchId), {action: searchBody})
                          .then(res=>console.log(res))
                  }
              }
              else{
                if(res.data.alreadyLiked){
                  return;
                }
                else{
                  albums.splice(-1, 1)
                }
              }
              this.setState({albums: albums})
          })
          .catch(error=>{
            Error({message: "Error liking album!"})
            albums.splice(-1,1)
            this.setState({albums: albums})
          })
        })
      }
  }
  unlikeAlbum = (albumId, albumName)=>{
    let albums = [...this.state.albums]

    albums = albums.filter(fav=>fav.albumId !== albumId)
    this.setState({albums: albums}, ()=>{

      api.unlikeAlbum(albumId)
        .then(res=>{
          if(res.data.success){
            if(window.location.pathname.includes("dashboard/favorites")){
              SuccessUndo({message: `No Longer Following ${albumName}`, undoClick: ()=>this.likeAlbum(albumId)})
            }
          }
          else{
            Error({message: "Error unliking album!"})
          }
        })
        .catch(error=>{
          Error({message: "Error unliking album!"})
        })
    })
  }
  followCollection = (collectionId, collectionName)=>{
    if(this.props.user === null){
      this.props.triggerLoginModal()
    }
    else{
      let collections = [...this.state.collections]
      collections.push({collectionId: collectionId, likedOn: "0000-00-00T00:00:00.000Z" })
      this.setState({collections: collections}, ()=>{
        api.followCollection(collectionId)
          .then(res=>{

              if(res.data.success){
                  collections[collections.length-1].likedOn = res.data.like.updatedOn
                  Success({message: `Following ${collectionName}`})
              }
              else{
                if(res.data.alreadyLiked){
                  return;
                }
                else{
                  collections.splice(-1, 1)
                }
              }
              this.setState({collections: collections})
          })
          .catch(error=>{
            Error({message: "Error following collection!"})
            collections.splice(-1,1)
            this.setState({collections: collections})
          })
        })
      }
  }
  unfollowCollection = (collectionId, collectionName)=>{
    let collections = [...this.state.collections]
    collections = collections.filter(fav=>fav.collectionId !== collectionId)
    this.setState({collections: collections}, ()=>{
      api.unfollowCollection(collectionId)
        .then(res=>{
          if(res.data.success){
            if(window.location.pathname.includes("dashboard/favorites")){
              SuccessUndo({message: `No Longer Following ${collectionName}`, undoClick: ()=>this.followCollection(collectionId, collectionName)})
            }
          }
          else{
            Error({message: "Error unliking album!"})
          }
        })
        .catch(error=>{
          Error({message: "Error unliking album!"})
        })
    })
  }
  render() {
    return (
        <FavoritesContext.Provider
          value={{
            artists: this.state.artists,
            tracks: this.state.tracks,
            albums: this.state.albums,
            collections: this.state.collections,
            likeTrack: this.likeTrack,
            unlikeTrack: this.unlikeTrack,
            followArtist: this.followArtist,
            unfollowArtist: this.unfollowArtist,
            likeAlbum: this.likeAlbum,
            unlikeAlbum: this.unlikeAlbum,
            followCollection: this.followCollection,
            unfollowCollection: this.unfollowCollection
          }}
        >
          {this.props.children}
        </FavoritesContext.Provider>
    )
  }
}

const FavoritesConsumer = FavoritesContext.Consumer

export { FavoritesProvider, FavoritesConsumer, FavoritesContext };


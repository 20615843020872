import React, { useEffect, useState } from "react";
import stripeAPI from "../../api/stripe";

const StripeTransactionsLink = () => {
  const [stripeTransactionsLink, setStripeTransactionsLink] = useState(null);
  useEffect(() => {
    let isMounted = true;
    stripeAPI
      .getTransactionsLink()
      .then((res) => {
        // console.log("transactionsLink res", res);
        if (isMounted) setStripeTransactionsLink(res?.data?.transactionsLink);
      })
      .catch((err) => {
        console.log(err);
      });
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <a
      href={stripeTransactionsLink}
      className="btn btn-primary mb-15"
      target="_blank"
      rel="noreferrer"
    >
      View Payouts
    </a>
  );
};

export default StripeTransactionsLink;

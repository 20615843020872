import React, { Component } from "react"
import MicroModal from "react-micro-modal"
import { NavLink } from "react-router-dom"
import CreateProjectModal from "../Projects/createProjectModal"
import api from "../utils/api"
class ProjTooltip extends Component {
    state ={
        newProjectName: "",
        newProjectClient: "",
        projectTypes: this.props.projectTypes
    }
    handleInputChange = (event)=>{
        const target = event.target;
        const value = target.value;
        const name = target.name;
        if(name === "newProjectName"){
            let existingProjectNames = this.props.myProjects.map(project=>project.name)
            let isNameTaken = existingProjectNames.filter(name=>name?.toUpperCase()===value?.toUpperCase()).length > 0

            if(isNameTaken){
                this.setState({newProjectNameError: "Project name already used. Please choose a different name"})
            }
            else if(value.length < 1){
                this.setState({newProjectNameError: "Project name required"})
            }
            else{
                this.setState({newProjectNameError: ""})
            }
        }
        this.setState({
            [name]: value,
        });
    }
    handleProjectTypeChange= (selectedType)=>{
        if(selectedType){
            this.setState({newProjectType: selectedType, projectTypeError: ""})
        }
    }
    submitNewProject = (cb)=>{
        if(!this.state.newProjectNameError && this.state.newProjectName.length > 0){
            let projectBody = {
                name: this.state.newProjectName
            }
            api.submitNewProject(projectBody)
                .then(res=>{
                    if(res.data.success){
                        window.location.replace('/dashboard/projects/edit/'+ res.data.project.id)
                    }
                    else{
                        Error({message:"An error occurred!", onClose: this.errorToast})
                    }
                    cb()
                })
                .catch(error=>{
                    Error({message:"An error occurred!", onClose: this.errorToast})
                })
        }
        else{
            Error({message:"An error occurred!", onClose: this.errorToast})
            if(!this.state.newProjectType){
                this.setState({projectTypeError: "Please select a project type"})
            }
            if(this.state.newProjectName.length < 1){
                this.setState({newProjectNameError: "Project name required"})
            }
        }
    }
    render(){
        return(
            <div id="navbar_add_project">
                <div class="dropdown-menu">
                    <div class="dropdown-group">
                        <span class="text-label-sm">Latest Projects</span>
                        {this.props.latestProjects.map(project =>
                          <NavLink
                            key={`project-${project.id}`}
                            to={"/dashboard/projects/edit/" + project.id}
                          >
                              {project.name}
                          </NavLink>
                        )}
                        {/* <a href="#">Hostess Cake Commercial</a> */}
                    </div>
                    <div class="dropdown-group">
                        <MicroModal
                            trigger={handleOpen =>   <a onClick={()=>{handleOpen();this.props.toggleAddProjectTippy()}} href="#">
                                        <span class="icon-plus"></span>Add New Project
                                    </a>}
                            closeOnEscapePress={true}
                            closeOnOverlayClick={true}
                            closeOnAnimationEnd={true}
                            modalOverlayClassName="modal-overlay"
                            modalClassName="modal micromodal-slide is-open"
                            modalOverlayStyles={null}
                            children={handleClose=><CreateProjectModal handleClose={handleClose} handleInputChange={this.handleInputChange} newProjectName={this.state.newProjectName} clientName={this.state.newProjectName}
                                newProjectType={this.state.newProjectType} projectTypes={this.state.projectTypes} handleProjectTypeChange={this.handleProjectTypeChange} submitNewProject={this.submitNewProject} newProjectNameError={this.state.newProjectNameError}
                                projectTypeError={this.state.projectTypeError}/>}
                            containerStyles={{background: "#131313", padding:"asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box"}}
                        />
                        {(this.props.user?.admin || this.props.user?.artist) &&
                            <NavLink to={"/dashboard/tracks/add-tracks"}><span class="icon-plus"></span>Add New Track</NavLink>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default ProjTooltip

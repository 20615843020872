import React from "react";
import { cssTransition, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.minimal.css";
import "./style.css";


    const defaultAutoClose = 5000;

    // Transition
    const Slide = cssTransition({
        enter: "slideIn",
        exit: "slideOut",
        duration: 300
    });

    // Success Message
    const successMsg = ({ closeToast, message }) => (
        <div className="flex items-center">
            <span className="toast-icon icon-success" />
            {message}
        </div>
    );
    // Success Message - Undo
    const successUndo = ({ closeToast, message, undoClick }) => (
        <div className="flex items-center">
            <span className="toast-icon icon-success" />
            {message}
            <div className="text-link-white" onClick={undoClick}>Undo</div>
        </div>
    );
    // Warning Message
    const warningMsg = ({ closeToast, message }) => (
        <div className="flex items-center">
            <span className="toast-icon icon-warning text-gray-500" />
            {message}
        </div>
    );

    // Error Message
    const errorMsg = ({ closeToast, message }) => (
        <div className="flex items-center">
            <span className="toast-icon icon-warning text-primary" />
            {message}
        </div>
    );


    // Success Toast
    const Success = (props) =>
        toast(successMsg(props), {
            transition: Slide,
            className: "toast-item",
            bodyClassName: "toast-body text-white",
            progressClassName: "toast-progress",
            onClose: props.onClose,
            autoClose: props.autoClose || defaultAutoClose
        });
    const SuccessRedirect = (props)=>
    toast(successMsg(props), {
        transition: Slide,
        className: "toast-item",
        bodyClassName: "toast-body text-white",
        progressClassName: "toast-progress",
        onClose: props.onClose,
        autoClose: props.autoClose || 3000
    });
    const SuccessUndo = (props)=>
    toast(successUndo(props), {
        transition: Slide,
        className: "toast-item",
        bodyClassName: "toast-body text-white",
        progressClassName: "toast-progress",
        onClose: props.onClose,
        autoClose: defaultAutoClose
    });
    // Success Toast
    const Warning = (props) =>
        toast(warningMsg(props), {
            transition: Slide,
            className: "toast-item",
            bodyClassName: "toast-body",
            progressClassName: "toast-progress",
            onClose: props.onClose
        });

    // Error Toast
    const Error = (props) =>
        toast(errorMsg(props), {
            transition: Slide,
            className: "toast-item",
            bodyClassName: "toast-body text-white",
            progressClassName: "toast-progress",
            onClose: props.onClose,
            autoClose: props.autoClose || defaultAutoClose,
            toastId: props.toastId || Math.random()* 90922
        });


export { Success, SuccessRedirect, SuccessUndo, Warning, Error };

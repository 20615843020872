import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const SalesSubnav = (props) => {
  const location = useLocation();
  const { pathname } = location || {};

  return (
    <div className="tabs">
      <ul className="tab-items">
        <li className={`tab-item ${pathname === "/dashboard/sales" ? "active" : ""}`}>
          <NavLink to="/dashboard/sales" className="tab-item-title">
            Summary
          </NavLink>
        </li>
        <li
          className={`tab-item ${pathname.startsWith("/dashboard/sales/licenses") ? "active" : ""}`}
        >
          <NavLink to="/dashboard/sales/licenses" className="tab-item-title">
            Single Track Licenses
          </NavLink>
        </li>
        <li
          className={`tab-item ${
            pathname.startsWith("/dashboard/sales/subscription-royalties") ? "active" : ""
          }`}
        >
          <NavLink to="/dashboard/sales/subscription-royalties" className="tab-item-title">
            Subscription Royalties
          </NavLink>
        </li>
        <li
          className={`tab-item ${
            pathname.startsWith("/dashboard/sales/content-id") ? "active" : ""
          }`}
        >
          <NavLink to="/dashboard/sales/content-id" className="tab-item-title">
            Content ID Royalties
          </NavLink>
        </li>
        <li
          className={`tab-item ${
            pathname.startsWith("/dashboard/sales/bonus-earnings") ? "active" : ""
          }`}
        >
          <NavLink to="/dashboard/sales/bonus-earnings" className="tab-item-title">
            Bonus Earnings
          </NavLink>
        </li>
      </ul>
    </div>
  );
};

export default SalesSubnav;

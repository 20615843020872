import React, { useContext, useEffect, useState } from "react";
import CircularIndeterminateButton from "../../Loading/circularIndeterminantButton";
import { Error } from "../../Toast";
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from "joi";
import { useForm } from "react-hook-form";
import api from "../../utils/api";
import { UserContext } from "../../../contexts/User";

const queryString = require('query-string');

const schema = Joi.object({

  "firstName": Joi.string()
    .required()
    .messages({
        "any.required": "Please enter a First Name",
        "string.empty": "Please enter a First Name",
    }),
  "lastName": Joi.string()
    .required()
    .messages({
        "any.required": "Please enter a Last Name",
        "string.empty": "Please enter a Last Name",
    }),
  // "q4_name[first]": Joi.string()
  //     .required()
  //     .messages({
  //         "any.required": "Please enter a First Name",
  //         "string.empty": "Please enter a First Name",
  //     }),
  // "q4_name[last]": Joi.string()
  //     .required()
  //     .messages({
  //         "any.required": "Please enter a Last Name",
  //         "string.empty": "Please enter a Last Name",
  //     }),
  "q3_email": Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
          "any.required": "Please enter an Email Address",
          "string.empty": "Please enter an Email Address",
          "string.email": "Please enter a valid Email Address",
      }),
  "q4_name": Joi.object(),
  "q5_companyName": Joi.any(),
  "q9_userId9": Joi.any(),
  "cardinalOrderNumber": Joi.any(),
  "q7_requestType": Joi.any(),
  "q8_priceId": Joi.any(),
  "q6_additionalInformation": Joi.any(),
  "q12_trackName": Joi.any(),
  "q13_artistName": Joi.any(),
  "formID": Joi.any(),
  "JWTContainer": Joi.any(),
  "cardinalOrderNumber": Joi.any(),
  "website": Joi.any(),
  "simple_spc": Joi.any(),
});


export default function QuoteContactForm(props) {
  const [isLoading, setisLoading] = useState(false);
  const [isDisabled, setisDisabled] = useState(false);

  const {
    priceId,
    quoteType,
    requestType,
    trackArtist,
    trackTitle
  } = props || {};

  console.log('useContext(UserContext)', useContext(UserContext));

  const { user } = useContext(UserContext) || {};

  const {
    user_id: userId,
    email,
    lastName,
    firstName,
    company
  } = user || {};
  // const userId = props.user?.user_id;

  console.log("q7_requestType", requestType);
  console.log("q8_priceId", priceId);
  // console.log('props.user', props.user);


  const { errors, handleSubmit, register, watch } = useForm({
    mode: "onSubmit",
    reValidateMode: 'onChange',
    defaultValues: {
      "q3_email": email,
      firstName,
      lastName,
      "q5_companyName": company,
      "q7_requestType": requestType,
      "q8_priceId": priceId,
      "q9_userId9": userId,
      q12_trackName: trackTitle,
      q13_artistName: trackArtist
    },
    resolver: joiResolver(schema),
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  console.log('watch', watch());

  useEffect(() => {
    // Show all errors as Toastify
    Object.entries(errors).map((error) => (
        Error({
            message: error[1].message,
            toastId: error[0]
        })
    ))
  },[errors]);

  const onSubmit = (data) => {
    setisLoading(true);
    setisDisabled(true);
    console.log('submitting data', data);
    let preparedData = data;
    preparedData["q4_name[first]"] = data.firstName;
    preparedData["q4_name[last]"] = data.lastName;

    api.createQuoteRequest(preparedData)
      .then(apiResponse => {
        console.log('apiResponse', apiResponse);

        const { quoteNumber, email } = apiResponse.data?.quoteRequest || {};
        preparedData["q11_quoteNumber"] = quoteNumber;

        const stringified = queryString.stringify(preparedData);

        if (apiResponse.data?.success) {
          fetch('https://submit.jotform.com/submit/220665702220041/',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: stringified
          })
          .then(fetchResponse => {
            console.log('fetchResponse', fetchResponse);
            if (fetchResponse.ok && quoteNumber) {
              console.log('all ok!');
              const url = `/quote/complete/${quoteNumber}/${email}`;
              window.location.replace(url);
              // props.history.push();
            }
          })
          .catch(error => {
            console.log(error);
            setisLoading(false);
            setisDisabled(false);
            Error({
              message: "There was an error submitting your message. Please try again later."
            })
          })
        }
        else {
          console.log(apiResponse);
          setisLoading(false);
          setisDisabled(false);
        }
      })
      .catch(error => {
        console.log(error);
        setisLoading(false);
        setisDisabled(false);
        Error({
          message: "There was an error submitting your message. Please try again later."
        })
      })
  }

  return (
    <>
      {/* {# Modal Content #} */}
      <div id="plan_contact">

        <form
          className="jotform-form ds-form"
          onSubmit={handleSubmit(onSubmit)}
          // action=""
          /* Creating a form that will submit data to the same page (without reloading the page). */
          // method="post"
          name="form_220665702220041"
          id="220665702220041"
          acceptCharset="utf-8"
          autoComplete="on"
        >
          <input ref={register} type="hidden" name="formID" value="220665702220041" />
          <input ref={register} type="hidden" name="JWTContainer" id="JWTContainer" value="" />
          <input ref={register} type="hidden" name="cardinalOrderNumber" id="cardinalOrderNumber" value="" />
          <input ref={register} type="hidden" name="q12_trackName" id="q12_trackName" value={trackTitle} />
          <input ref={register} type="hidden" name="q13_artistName" id="q13_artistName" value={trackArtist} />
          <div role="main" className="form-all">
            <ul className="form-section page-section">
              <li id="cid_1" className="form-input-wide" data-type="control_head">
                <div className="form-header-group  header-large">
                  <div className="header-text httal htvam">
                    <p>We'll be in touch to find you the perfect {quoteType ? quoteType : "plan"}.</p>
                  </div>
                </div>
              </li>
              <li
                className={`${email && "hidden "}input-group mb-0 form-line`}
                data-type="control_email" id="id_3"
              >
                <label
                  className="hidden input-label form-label form-label-top form-label-auto"
                  id="label_3"
                  htmlFor="input_3"
                >
                  {" "}
                  Email{" "}
                </label>
                <div id="cid_3" className="grid-row-sm form-input-wide" data-layout="half">
                  <div
                    className="col w-full form-sub-label-container"
                    style={{ verticalAlign: "top" }}
                  >
                    <div className="input-group-sm input-inline">
                      <input
                        ref={register}
                        type={"email"}
                        id="input_3"
                        name="q3_email"
                        placeholder="Email Address *"
                        className="form-input form-textbox validate[Email]"
                        data-defaultvalue=""
                        size="310"
                        data-component="email"
                        aria-labelledby="label_3 sublabel_input_3"
                      />
                      <label
                        className="input-label form-sub-label"
                        htmlFor="input_3"
                        id="sublabel_input_3"
                        aria-hidden="false"
                      >
                        Email Address *
                      </label>
                    </div>
                  </div>
                </div>
              </li>
              <li className={`${email && "hidden "}input-group mb-0 form-line`} data-type="control_fullname" id="id_4">
                <label
                  className="hidden input-label form-label form-label-top form-label-auto"
                  id="label_4"
                  htmlFor="first_4"
                >
                  {" "}
                  Name{" "}
                </label>
                <div id="cid_4" className="form-input-wide" data-layout="full">
                  <div class="grid-row-sm" data-wrapper-react="true">
                    <div
                      className="col w-1/2 form-sub-label-container"
                      style={{verticalAlign:"top"}}
                      data-input-type="first"
                    >
                      <div className="input-group-sm input-inline">
                        <input
                          ref={register}
                          type="text"
                          id="first_4"
                          name="firstName"
                          className="form-input form-textbox"
                          placeholder="First Name *"
                          autoComplete="section-input_4 given-name"
                          size="10"
                          data-component="first"
                          aria-labelledby="label_4 sublabel_4_first"
                        />
                        <label
                          className="input-label form-sub-label"
                          htmlFor="first_4"
                          id="sublabel_4_first"
                          aria-hidden="false"
                        >
                          First Name *
                        </label>
                      </div>
                    </div>
                    <div
                      className="col w-1/2 form-sub-label-container"
                      style={{verticalAlign:"top"}}
                      data-input-type="last"
                    >
                      <div className="input-group-sm input-inline">
                        <input
                          ref={register}
                          type="text"
                          id="last_4"
                          name="lastName"
                          placeholder="Last Name *"
                          className="form-input form-textbox"
                          autoComplete="section-input_4 family-name"
                          size="15"
                          data-component="last"
                          aria-labelledby="label_4 sublabel_4_last"
                        />
                        <label
                          className="input-label form-sub-label"
                          htmlFor="last_4"
                          id="sublabel_4_last"
                          aria-hidden="false"
                        >
                          Last Name *
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li className="hidden form-line" data-type="control_textbox" id="id_7">
                <label
                  className="input-label form-label form-label-top form-label-auto"
                  id="label_7"
                  htmlFor="input_7"
                >
                  {" "}
                  Request Type{" "}
                </label>
                <div id="cid_7" className="form-input-wide" data-layout="half">
                  <input
                    ref={register}
                    type="hidden"
                    id="input_7"
                    name="q7_requestType"
                    data-type="input-textbox"
                    className="form-input form-textbox"
                    size="310"
                    data-component="textbox"
                    aria-labelledby="label_7"
                  />
                </div>
              </li>
              <li className="hidden form-line" data-type="control_textbox" id="id_8">
                <label
                  className="input-label form-label form-label-top form-label-auto"
                  id="label_8"
                  htmlFor="input_8"
                >
                  {" "}
                  Price ID{" "}
                </label>
                <div id="cid_8" className="form-input-wide" data-layout="half">
                  <input
                    ref={register}
                    type="hidden"
                    id="input_8"
                    name="q8_priceId"
                    data-type="input-textbox"
                    className="form-input form-textbox"
                    size="310"
                    data-component="textbox"
                    aria-labelledby="label_8"
                  />
                </div>
              </li>
              <li className="hidden form-line" data-type="control_textbox" id="id_9">
                <label
                  className="input-label form-label form-label-top form-label-auto"
                  id="label_9"
                  htmlFor="input_9"
                >
                  User ID
                </label>
                <div id="cid_9" className="form-input-wide" data-layout="half">
                  <input
                    ref={register}
                    type="hidden"
                    id="input_9"
                    name="q9_userId9"
                    data-type="input-textbox"
                    className="form-input form-textbox"
                    size="310"
                    data-component="textbox"
                    aria-labelledby="label_9"
                  />
                </div>
              </li>
              <li className="input-group mb-0 form-line" data-type="control_textbox" id="id_5">
                <label
                  className="hidden input-label form-label form-label-top form-label-auto"
                  id="label_5"
                  htmlFor="input_5"
                >
                  {" "}
                  Company Name{" "}
                </label>
                <div id="cid_5" className="grid-row-sm form-input-wide" data-layout="half">
                  <div
                    className="col w-full form-sub-label-container"
                    style={{ verticalAlign: "top" }}
                  >
                    <div className="input-group-sm input-inline">
                      <input
                        ref={register}
                        type="text"
                        id="input_5"
                        name="q5_companyName"
                        placeholder="Company Name"
                        data-type="input-textbox"
                        className="form-input form-textbox"
                        size="310"
                        data-component="textbox"
                        aria-labelledby="label_5"
                      />
                      <label
                        className="input-label form-label form-label-top form-label-auto"
                        id="label_5"
                        htmlFor="input_5"
                      >
                        Company Name
                      </label>
                    </div>
                  </div>
                </div>
              </li>
              <li className="input-group mb-0 form-line" data-type="control_textarea" id="id_6">
                <label
                  className="hidden input-label form-label form-label-top form-label-auto"
                  id="label_6"
                  htmlFor="input_6"
                >
                  Additional Information
                </label>
                <div id="cid_6" className="grid-row-sm form-input-wide" data-layout="full">
                  <div
                    className="col w-full "
                    style={{ verticalAlign: "top" }}
                  >
                    <div className="input-group-sm input-inline">
                      <textarea
                        id="input_6"
                        className="form-input form-textarea"
                        name="q6_additionalInformation"
                        placeholder="Any additional information?"
                        data-component="textarea"
                        aria-labelledby="label_6"
                        ref={register}
                        style={{
                          height: 85,
                          paddingTop: '2rem'
                        }}
                      ></textarea>
                      <label
                        className="input-label form-label form-label-top form-label-auto"
                        id="label_6"
                        htmlFor="input_6"
                      >
                        Additional Information
                      </label>
                    </div>
                  </div>
                </div>
              </li>
              <li className="form-line mb-0" data-type="control_button" id="id_2">
                <div id="cid_2" className="form-input-wide" data-layout="full">
                  <div
                    data-align="auto"
                    className="form-buttons-wrapper form-buttons-auto jsTest-button-wrapperField"
                  >
                    <button
                      id="input_2"
                      type="button"
                      onClick={handleSubmit(onSubmit)}
                      disabled={isDisabled}
                      className="form-submit-button submit-button jf-form-buttons jsTest-submitField btn btn-primary form-btn w-full"
                      data-component="button"
                      data-content=""
                    >
                      {isLoading ? <CircularIndeterminateButton /> : "Submit"}
                    </button>
                  </div>
                </div>
              </li>
              <li style={{ display: "none" }}>
                Should be Empty:
                <input type="text" name="website" ref={register} />
              </li>
            </ul>
          </div>
          <input
            ref={register}
            type="hidden"
            className="simple_spc"
            id="simple_spc"
            name="simple_spc"
            value="220665702220041-220665702220041"
          />
          <div className="formFooter-heightMask"></div>
        </form>
      </div>
    </>
  );
}

import React from "react";

export default function Controls(props){
    return (
      <div id={"videoStudioControls"} className="vs-video-controls">
        {/* // {# Buttons #} */}
        <div className="flex-none btn-group -ml-10">
          {/* // {# Play #} */}
          {props.isVideoPlaying ? (
            <button onClick={props.pauseVideo} className="circle-icon circle-icon-sm" type="button">
              <span className="icon-pause"></span>
            </button>
          ) : (
            <button onClick={props.playVideo} className="circle-icon circle-icon-sm" type="button">
              <span className="icon-play"></span>
            </button>
          )}

          {/* // {# Begining #} */}
          <button
            onClick={() => {
              props.replayVideo();
              props.handlePause();
            }}
            className="circle-icon circle-icon-sm"
            type="button"
          >
            <span className="icon-rewind"></span>
          </button>
        </div>

        {/* // {# Playbar #} */}
        <div className="flex-1 flex items-center text-sm pl-20">
          <div className="flex-1 flex items-center">
            <input
              style={{ cursor: "pointer" }}
              id="video-timeline"
              onInput={props.changeVideoTime}
              type="range"
              min="1"
              max="100"
              step={1}
              onChange={(e) => {e.preventDefault()}}
              value={Math.floor(100 * props.playedPercent)}
              className="input-range  py-10 lg:py-15"
            />
          </div>
        </div>

        {/* // {# Duration #} */}
        <div
          id={"videoStudioDuration"}
          className="w-80 flex-none tracking-wide text-xs text-gray-400 text-right"
        >
          <span>{props.playedFormatted}</span> / <span>{props.videoDurationFormatted}</span>
        </div>
      </div>
    );

}

export const setupProjectOptions = (allProjects, track) => {
  let projectOptions = [];

  allProjects?.forEach((project) => {
    projectOptions.push({
      label: project.name,
      value: project.id,
      allTracks: project.allTracks,
    });
  });

  projectOptions = projectOptions.sort((a, b) => {
    let aProjIncludesTrack = false;
    let bProjIncludesTrack = false;
    if (a.allTracks?.length > 0) {
      a.allTracks.forEach((projTrack) => {
        if (projTrack.trackId === track?.trackId) {
          aProjIncludesTrack = true;
        }
      });
    }
    if (b.allTracks?.length > 0) {
      b.allTracks.forEach((projTrack) => {
        if (projTrack.trackId === track?.trackId) {
          bProjIncludesTrack = true;
        }
      });
    }
    if (aProjIncludesTrack && bProjIncludesTrack) {
      return 0;
    } else if (aProjIncludesTrack && !bProjIncludesTrack) {
      return -1;
    } else if (!aProjIncludesTrack && bProjIncludesTrack) {
      return 1;
    } else {
      return 0;
    }
  });
  return projectOptions;
};

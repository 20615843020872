import React, { Component } from "react"
import MicroModal from "react-micro-modal"
import { NavLink } from 'react-router-dom'
import { Tooltip } from "react-tippy"
import wav1 from '../../assets/placeholders/wav-01.png'
import wav2 from '../../assets/placeholders/wav-02.png'
import wav3 from '../../assets/placeholders/wav-03.png'
import wav4 from '../../assets/placeholders/wav-04.png'
import wav5 from '../../assets/placeholders/wav-05.png'
import wav6 from '../../assets/placeholders/wav-06.png'
import DefaultAvatar from "../../assets/svg/user-avatar.svg"
import ManageCollaboratorsModal from "./manageCollaboratorsModal"
import ProjectDetailTooltip from "./projectDetailTooltip"
import ShareProjectModal from "./shareProjectModal"

const wavList = [wav1, wav2, wav3, wav4, wav5, wav6]
class ProjectEditHeader extends Component{
    state={
        isShareModalOpen: false,
        randomWavIndex: Math.round(Math.random() *5),
        isManageCollaboratorsModalOpen: false
    }
    componentDidMount=()=>{
        
    }
    handleShareModalOpen = ()=>{
        this.props.closeProjectDetailTippy()
        if(this.props.projectLink === null && this.props.isOwner){
            this.props.generateProjectLink(this.props.projectId)
        }
        this.setState({isShareModalOpen: true})
    }
    handleShareModalClose = ()=>{
        this.props.handleFormUnsaved(false)
        this.setState({isShareModalOpen: false})
    }
    handleManageCollaboratorsModalOpen = ()=>{
        this.props.closeProjectDetailTippy()
        this.setState({isManageCollaboratorsModalOpen: true})
    }
    handleManageCollaboratorsModalClose = ()=>{
        this.props.handleFormUnsaved(false)
        this.setState({isManageCollaboratorsModalOpen: false})
    }
    resolveProfilePhoto=(profilePhotoKey)=>{
        const key = profilePhotoKey
        if(profilePhotoKey){
            const ext = key.split('.').reverse()[0];
            let final = key.replace('.' + ext, '') + '-minified-60x60'  + '.' + ext
            return final
        }
    }
    resolveProjectPhoto=(projectPhotoKey)=>{
        const key = projectPhotoKey
        if(key){
            const ext = key.split('.').reverse()[0];
            if(key.indexOf('albumArt/') !==-1){
                let sizeMod= '140x140'
                let final = key.replace('.' + ext, '') + '-minified-' + sizeMod + '.' + ext
                return final
            }
            else{
                let sizeMod;
                sizeMod = '140x140'
                let final = key.replace('.' + ext, '') + '-minified-' + sizeMod + '.' + ext
                return final
            }
            
        }
    }    
    handlePhotoNotFound=(projectPhotoKey)=>{
        
        const key = projectPhotoKey
        if(!this.state.fallbackImageAttempted){
            if(key){
                const ext = key.split('.').reverse()[0];
                let imageElement = document.getElementById('projectHeaderImage')
                    if(imageElement){
                        imageElement.setAttribute('src', `https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${projectPhotoKey}`)
                    }
            }
            this.setState({fallbackImageAttempted: true})
        }
    }
    render(){
        return(
<>
<NavLink to="/dashboard/projects/all" class="view-header-back">
	<span class="icon-chevron-left"></span>
	<span class="text-link-gray text-label">All Projects</span>
</NavLink>

{/* // {# View Header #} */}
<div class="view-header view-header-nowrap mt-0 project-header">
	{/* {# Header Content #} */}
    <div class="flex flex-1 items-center">
        <div class="flex-none">
            {this.props.projectArtwork ? 
                typeof this.props.coverArt === "string" ? 
                <img id="projectHeaderImage" onError={()=>this.state.fallbackImageAttempted ? null : this.handlePhotoNotFound(this.props.projectArtwork)} src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveProjectPhoto(this.props.projectArtwork)}`} class="w-80 h-80 xl:w-100 xl:h-100 mr-10 xl:mr-15"/>:
                <img  src={this.props.coverArt?.preview} class="w-80 h-80 xl:w-100 xl:h-100 mr-10 xl:mr-15"/>:
                this.props.coverArt?.preview ?
                <img  src={this.props.coverArt?.preview} class="w-80 h-80 xl:w-100 xl:h-100 mr-10 xl:mr-15"/>: 
                <img  src={wavList[this.state.randomWavIndex]} alt={this.props.name} class="w-80 h-80 xl:w-100 xl:h-100 mr-10 xl:mr-15"/>
            }
        </div>
            <div class="flex flex-col flex-1">
            <h1 class="view-title">{this.props.name}</h1>
            <div class="inline-block">
                <span class="text-gray-500">{this.props.client}</span>
                {this.props.client && <span class="text-gray-400 mx-5">-</span> }
                <span class="text-gray-500">{this.props.projectType}</span>
            </div>
        </div>
    </div>
    <div class="view-header-controls heading-icon-section">
        <div class="btn-group btn-group-lg">
            {/* {# Share #} */}
            {((this.props.projectLink && this.props.linkEnabled === "active" )|| this.props.isOwner) ?
            <>
            <button onClick={this.handleShareModalOpen} class="circle-icon-border icon-share hidden md:flex" data-micromodal-trigger="share_project_modal">
                <span class=""></span>
            </button>
            <MicroModal
                        open={this.state.isShareModalOpen}
                        closeOnEscapePress={true}
                        closeOnOverlayClick={true}
                        closeOnAnimationEnd={true}
                        handleClose={()=>this.handleShareModalClose()}
                        modalOverlayClassName="modal-overlay"
                        modalClassName="modal micromodal-slide is-open"
                        modalOverlayStyles={null}
                        children={handleClose => <ShareProjectModal handleClose={handleClose} projectLink={this.props.projectLink} projectLinkId={this.props.projectLinkId} linkEnabled={this.props.linkEnabled} enableLink={this.props.enableLink} passwordEnabled={this.props.passwordEnabled} enablePassword={this.props.enablePassword}
                            updateProjectLink={this.props.updateProjectLink} updatePassword={this.props.updatePassword} password={this.props.projectPassword} projectLinkId={this.props.projectLinkId} clearPW={this.props.clearPW} pwBlur={this.props.pwBlur} isOwner={this.props.isOwner} handleManageCollaboratorsModalOpen={this.handleManageCollaboratorsModalOpen}/>}
                        containerStyles={{ background: "#131313", padding: "asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box" }}
                    />
                    </>:<></>}
            <div class="users-block">
                {this.props.activeCollabs?.length < 5 ? 
                                this.props.activeCollabs?.slice(0,4).map(collaborator=> <div class="user-block-image-container">
                                    {collaborator.profilePhoto ? 
                                        <div class="user-image js-tooltip" title={(collaborator.firstName) + " " + (collaborator.lastName)} style={{backgroundImage: `url(https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveProfilePhoto(collaborator.profilePhoto)})`}}></div>:
                                        <div class="user-image js-tooltip" title={(collaborator.firstName) + " " + (collaborator.lastName)} style={{backgroundImage: `url(${DefaultAvatar})`}}></div>}
                                    </div> )
                                        :
                                this.props.activeCollabs?.slice(0,3).map(collaborator=> <div class="user-block-image-container">
                                    {collaborator.profilePhoto ? 
                                        <div class="user-image js-tooltip" title={(collaborator.firstName) + " " + (collaborator.lastName)} style={{backgroundImage: `url(https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveProfilePhoto(collaborator.profilePhoto)})`}}></div>:
                                        <div class="user-image js-tooltip" title={(collaborator.firstName) + " " + (collaborator.lastName)} style={{backgroundImage: `url(${DefaultAvatar})`}}></div>}
                                    </div> )
                            }
                        
                            {/* {% endfor %} */}

                            {/* {# Additional Users Indicator #} */}
                            {this.props.activeCollabs?.length > 4 &&  <span class="circle-icon-fill bg-gray-300 text-gray-500 additional-users">+{this.props.activeCollabs?.length -3}</span>}
                            <button onClick={this.handleManageCollaboratorsModalOpen} class="circle-icon-border" data-micromodal-trigger="collaborators_modal">
                                <span class="icon-follow"></span>
                            </button>
                            <MicroModal
                                open ={this.state.isManageCollaboratorsModalOpen}
                                handleClose={this.handleManageCollaboratorsModalClose}
                                closeOnEscapePress={true}
                                closeOnOverlayClick={true}
                                closeOnAnimationEnd={true}
                                modalOverlayClassName="modal-overlay"
                                modalClassName="modal micromodal-slide is-open"
                                modalOverlayStyles={null}
                                children={handleClose => <ManageCollaboratorsModal handleClose={handleClose} collaborators={this.props.collaborators} projectId={this.props.projectId} projectName={this.props.name} 
                                    removeCollaborator={this.props.removeCollaborator} addCollaboratorToState={this.props.addCollaboratorToState} isOwner={this.props.isOwner} handleShareModalOpen={()=>{handleClose();this.handleShareModalOpen();}}
                                    projectLink={this.props.projectLink} linkEnabled={this.props.linkEnabled} collaboratorEmail={this.props.collaboratorEmail} handleInputChange={this.props.handleInputChange} filteredCollaborators={this.props.filteredCollaborators} onKeyDown={this.props.onKeyDown} activeOption={this.props.activeOption}
                                    onClick={this.props.onClick} updateLatest={this.props.updateLatest} handleFormUnsaved={this.props.handleFormUnsaved} owner={{email:this.props.ownerEmail,name:this.props.ownerName, ownerPhoto: this.props.ownerPhoto }}/>}
                                containerStyles={{ background: "#131313", padding: "asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box" }}
                />
            </div>
            
            {/* {# More Dropdown #} */}
            <Tooltip
                useContext={true}
                arrow={false}
                open={this.props.isProjectDetailTippyOpen}
                interactive={true}
                position={'bottom-end'}
                trigger={'click'}
                // hideOnClick={true}
                html={(<ProjectDetailTooltip 
                            handleSceneModalOpen={this.props.handleSceneModalOpen}
                            markComplete={this.props.markComplete}
                            markIncomplete={this.props.markIncomplete}
                            handleProjectEditModalOpen={this.props.handleProjectEditModalOpen}
                            complete={this.props.complete}
                            artistBrowse={this.props.artistBrowse}
                            artistTrackList={this.props.artistTrackList}
                            trackStatus={this.props.trackStatus}
                            duplicateProject={this.props.duplicateProject}
                            leaveProject={this.props.leaveProject}
                            isOwner={this.props.isOwner}
                            handleShareModalOpen={this.handleShareModalOpen}
                            handleManageCollaboratorsModalOpen={this.handleManageCollaboratorsModalOpen}
                            projectLink={this.props.projectLink} 
                            linkEnabled={this.props.linkEnabled}
                            purchaseId={this.props.purchaseId}
                            deleteProject={this.props.deleteProject}
                        >
                    </ProjectDetailTooltip>
                )}
                allowHTML={true}
                onUntrigger={this.props.toggleProjectDetailTippy}
                onRequestClose={this.props.toggleProjectDetailTippy}
            >
                <button class="circle-icon js-dropdown-toggle"
                    id="project_edit_options"
                    onClick={this.props.toggleProjectDetailTippy}
                    data-tippy-html="#project_edit_options"
                    data-tippy-placement="bottom-end"
                    type="button"
                >
                    <span class="icon-dots-vert"></span>
                </button>
            </Tooltip>
        </div>
    </div>
</div>

        </>)
    }
}

export default ProjectEditHeader;
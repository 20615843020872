import React, { Component } from "react";
import MediaQuery from 'react-responsive';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import Sticky from 'react-sticky-el';
import { Tooltip } from "react-tippy";
import 'react-tippy/dist/tippy.css';
import { UserConsumer } from "../../contexts/User";
import CharacterCount from "../FormElements/characterCount";
import customStyles from "../FormElements/customStyles";
import customStylesMulti from "../FormElements/customStylesMulti";
import KeywordsInput from '../FormElements/keywordsInput';
import AppLayout from "../Layouts/appLayout";
import TechnicalRequirementsLearnMore from "../Links/technicalRequirementsLearnMore";
import CircularIndeterminant from "../Loading/circularIndeterminant";
import api from '../utils/api';
import TrackWaveform from "../Waveform/forAdminApproveFullTrack";
import Waveform from "../Waveform/forVersionsAndStems";
import NewTrackToolTip from "./newTrackToolTip";
import versionNameOptions from "../FormElements/TrackForm/versionNameOptions"

// Styles for Multi-Select


// Styles for Single Select




class ArtistPendingTrack extends Component {
    state = {
        title: "",
        album: "",
        myAlbums: [ ],
        file: "",
        funFact: "",
        genreOptions: [ ],
        emotionOptions: [ ],
        moodOptions: [ ],
        attributeOptions: [ ],
        instrumentOptions: [ ],
        cityOptions: [],
        genres: [ ],
        emotions: [ ],
        moods: [ ],
        attributes: [ ],
        instruments: [ ],
        cities: [],
        trackBPM: "fast",
        versionsAvailable: "false",
        stemsAvailable: "false",
        submittable: false,
        additionalVersions: [],
        stems: [],
    }



changeNowPlaying = (event, s3Key)=>{

    event.preventDefault()
    if(this.state.nowPlaying === s3Key){

        this.setState({nowPlaying: "stop"})
    }
    else{
        this.setState({nowPlaying: s3Key})
    }
}

componentDidMount = ( )=>{

api.getTrackById(this.props.match.params.trackId)
    .then(res=>{


        //


        let tags = res.data.track.tags || {}

        // let myAlbums = res.data.myAlbums.map(album=>{
        //     return{
        //         label: album.albumTitle,
        //         value: album.albumId,
        //         artwork: album.imageURL
        //     }
        // })
        // let album = myAlbums.filter(album=> album.value === res.data.track.albumId)[0]
        let genres = []
        let emotions = []
        let moods = []
        let attributes = []
        let instruments = []
        let city;
        let lyrics = {}
        let trackBPM

        tags.forEach(tag=>{
            switch(tag.type){
                case 1: genres.push({value: tag.tagId, label: tag.name})
                    break;
                case 2: emotions.push({value: tag.tagId, label: tag.name})
                    break;
                case 3: moods.push({value: tag.tagId, label: tag.name})
                    break;
                case 4: attributes.push({value: tag.tagId, label: tag.name})
                    break;
                case 5: instruments.push({value: tag.tagId, label: tag.name})
                    break;
                case 6: city = {value: tag.tagId, label: tag.name}
                    break;
                case 7: if(tag.tagId === 172){
                    lyrics.exist = "false"
                    }
                    else if(tag.tagId === 173){
                        lyrics.exist = "true"
                    }
                    else if(tag.tagId === 174){
                        lyrics.explicit = "true"
                    }
                    break;
                case 8: trackBPM = tag.tagId
                    break;
            }
        })

        //

        let versions = []
        res.data.versions.forEach(version=>{
            versions.push({
                value: version.name,
                label: version.name.toUpperCase(),
                wavURL: version.mp3URL || version.wavURL || "",
                duration: version.duration,
                peaks: version.peaks,
                id: version.id,
                localname: version.localname
            })
        })
        let stems = res.data.stems

        instruments.forEach(instrument =>{
            res.data.stems.forEach(stem=>{

                if (instrument.value === stem.name){
                    instrument.key = stem.mp3URL || stem.wavURL
                    instrument.duration = stem.duration
                    instrument.peaks = stem.peaks
                    instrument.id = stem.id
                }
            })
        })
        let existingKeywords = []
        if(res.data.track.keywords) {

            // let trackKeywords = res.data.track.keywords
            existingKeywords = res.data.track.keywords.map(keyword => {
                return{
                    id: keyword.keywordId,
                    label: keyword.name,
                    value: keyword.name
                }
            })
        }
        if(this.props.user.admin){
            api.getArtistAlbumsForAdmin(res.data.track.artistId)
                .then(albumRes=>{

                    if(albumRes.data.length){
                        let album = albumRes.data.filter(album=> album.id=== res.data.track.albumId)[0]
                        this.setState({
                            myAlbums: albumRes.data.map(album=>{
                                return{
                                    label: album.title,
                                    value: album.id,
                                    artwork: album.imageURL
                                }
                            }),
                            album: {label: album.title, value: album.id, artwork: album.imageURL}
                        })
                    }
                })
        }
        else{
            api.getMyAlbums()
                .then(albumRes=>{

                    if(albumRes.data.length){
                        let album = albumRes.data.filter(album=> album.id=== res.data.track.albumId)[0]
                        this.setState({
                            myAlbums: albumRes.data.map(album=>{
                                return{
                                    label: album.title,
                                    value: album.id,
                                    artwork: album.imageURL
                                }
                            }),
                            album: {label: album.title, value: album.id, artwork: album.imageURL}
                        })
                    }
                })
        }
        this.setState({
            artistName: res.data.track.artistName,
            title: res.data.track.trackTitle,
            localname: res.data.track.localname,
            attributes: attributes,
            emotions: emotions,
            genres: genres,
            instruments: instruments,
            stems: stems,
            moods: moods,
            city: city,
            trackBPM: trackBPM,
            containsLyrics: lyrics ? lyrics.exist === "true" ? "true": "false": "false",
            explicitLyrics: lyrics ? lyrics.explicit === "true" ? "true": "false": "false",
            funFact: res.data.track.funFact,
            additionalVersions: versions,
            versionsAvailable: versions.length ? "true": this.state.versionsAvailable,
            stemsAvailable: res.data.stems.length ? "true": this.state.stemsAvailable,
            keywords: existingKeywords,
            trackStatus: res.data.track.trackStatus,
            peaks: res.data.track.peaks,
            duration: res.data.track.duration,
            wavURL: res.data.track.mp3URL || res.data.track.wavURL
        })
    })
api.getTags()
    .then(res=>{

        //filter genre tags
        let genres = res.data.filter(tag => tag.type === 1)
        let genreOptions = []
        genres.forEach(genre => {
            genreOptions.push({
                value: genre.name,
                label: genre.name.replace(/^\w/, c => c.toUpperCase())
            })
        });
        //filter emotion tags
        let emotions = res.data.filter(tag => tag.type === 2)
        let emotionOptions = []
        emotions.forEach(emotion => {
            emotionOptions.push({
                value: emotion.name,
                label: emotion.name.replace(/^\w/, c => c.toUpperCase())
            })
        });
        //filter mood tags
        let moods = res.data.filter(tag => tag.type === 3)
        let moodOptions = []
        moods.forEach(mood => {
            moodOptions.push({
                value: mood.name,
                label: mood.name.replace(/^\w/, c => c.toUpperCase())
            })
        });
        //filter attribute tags
        let attributes = res.data.filter(tag => tag.type === 4)
        let attributeOptions = []
        attributes.forEach(attribute => {
            attributeOptions.push({
                value: attribute.name,
                label: attribute.name.replace(/^\w/, c => c.toUpperCase())
            })
        });
        //filter instrument tags
        let instruments = res.data.filter(tag => tag.type === 5)
        let instrumentOptions = []
        instruments.forEach(instrument => {
            instrumentOptions.push({
                value: instrument.name,
                label: instrument.name.replace(/^\w/, c => c.toUpperCase())
            })
        });
        //city city tags
        let cities = res.data.filter(tag => tag.type === 6)
        let cityOptions = []
        cities.forEach(city => {
            cityOptions.push({
                value: city.name,
                label: city.name.replace(/^\w/, c => c.toUpperCase())
            })
        });


        this.setState({
            genreOptions: genreOptions,
            emotionOptions: emotionOptions,
            moodOptions: moodOptions,
            attributeOptions: attributeOptions,
            instrumentOptions: instrumentOptions,
            cityOptions: cityOptions
        })
    })
}

    render (){
        const formatOptionLabel=({vaue, label, artwork})=>(
            <div className="flex items-center">
                <img
                    src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${artwork}`}
                    alt="Album Art"
                    className="w-30 h-30 bg-gray-300 mr-15"
                />
                {label}
            </div>
        )

        return(
            <AppLayout>
            <UserConsumer>
            {({ user, login, logout})=>(
            <div className="pt-50 lg:pt-0">
                <div className="container">

                    {/* {# App Header #} */}
                    <div className="view-header">
                        <h1 className="view-title">New Track</h1>
                        <div className="view-header-controls">
                                <button type="button" type="button" onClick={(event)=>{event.preventDefault();}} className="btn btn-disabled btn-primary">Save and Quit</button>
                                <button type="button" type="button" disabled onClick={(event)=>{event.preventDefault();}} className="btn btn-disabled btn-stroke">Submit for Approval</button>
                                <Tooltip
                                arrow={false}
                                visible={this.state.showTippy}
                                interactive={true}
                                position={'bottom-end'}
                                trigger={'click'}
                                useContext={true}
                                html={(<NewTrackToolTip
                                        className="hidden"
                                        deleteDisabled={true}
                                        delete={()=>{return}}>
                                    </NewTrackToolTip>
                                )}
                                allowHTML={true}
                                theme={'dark'}
                                onClickOutside={()=>{this.state.showTippy ? this.setState({showTippy: false}): this.setState({showTippy: true})}}
                            >
                            <button type="button" type="button" className="icon-btn js-dropdown-toggle"
                                onClick={()=>{this.state.showTippy ? this.setState({showTippy: false}): this.setState({showTippy: true})}}
                                >
                                <span className="icon-dots-vert"></span>
                            </button>

                            </Tooltip>
                        </div>
                    </div>

                </div>



                    <div className="container">
                        {/* {# Track Preview #} */}
                    <div className="form-section-sm">
                        <div className="input-fieldset">
                            {/* {# File #} */}
                            <div className="upload-item">
                                <div className="upload-item-details">
                                    <div className="upload-item-title">{this.state.localname}</div>
                                </div>
                            </div>
                            <hr className="divider-sm"/>
                            {/* {# Track Preview #} */}
                            <div className="track-preview track-preview-sm track-preview-sm">
                                <div className="track-preview-play">
                                    <button type="button" onClick={(event)=>{this.changeNowPlaying(event,this.state.wavURL)}} className="circle-icon-border circle-icon-lg">{this.state.nowPlaying === this.state.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                </div>
                                <div className="track-preview-waveform">
                                {this.state.wavURL ?
                                    <TrackWaveform nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={this.state.wavURL} peaks={this.state.peaks} ></TrackWaveform>:
                                    <></>
                                }
                                </div>
                                <div className="track-preview-duration">
                                    {this.state.duration}
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* {# App Main Inner #} */}
                    <div className="app-sublayout">

                        {/* {# App Main Content #} */}
                        <div className="app-sublayout-content mb-50 lg:mb-80">

                            {/* {# Form #} */}
                            <form action="" className="ds-form">

                                {/* {# Track Details #} */}
                                <div className="form-section">
                                    <h2 className="text-label-lg mb-40" id="details">Track Details</h2>

                                    {this.state.artistId !== user.id &&
                                        <div className="input-group">
                                            <label className="input-label" htmlFor="track_title">
                                                Track Artist
                                            </label>
                                            <input
                                                disabled
                                                id="track_artist"
                                                className="form-input"
                                                value={this.state.artistName}
                                                type="text"
                                                name="artist"
                                            />
                                        </div>
                                    }


                                    <div className="input-group">
                                        <label className="input-label" htmlFor="track_title">Track Title</label>
                                            <input
                                                disabled
                                                id="track_title"
                                                className="form-input"
                                                value={this.state.title}
                                                type="text"
                                                name="title"
                                                placeholder="Enter track title here..."/>

                                    </div>

                                    {/* {# Album Select #} */}
                                    <div className="form-section" id="album_select">
                                        <div className="input-group">
                                            <label htmlFor="album" className="input-label">
                                              Link to Album
                                              <span className="input-label-info">Choose an Album</span>
                                            </label>
                                            <Select
                                                isDisabled
                                                name="album"
                                                className="select-container"
                                                classNamePrefix="select"
                                                styles={customStyles}
                                                placeholder="Select an Album"
                                                value={this.state.album}
                                                options={this.state.myAlbums}
                                                onChange={this.handleAlbumChange}
                                                formatOptionLabel={formatOptionLabel}
                                            />
                                        </div>
                                    </div>

                                </div>

                                {/* {# Tags #} */}
                                <div className="form-section" id="tags">
                                    <h2 className="text-label-lg mb-40">Tags</h2>

                                    {/* {# Genres #} */}
                                    <div className="input-group">
                                        <label htmlFor="genres" className="input-label">What Genres best describes this track? <span className="input-label-info">Choose up to 2</span></label>
                                            <Select
                                                isDisabled
                                                name="genres"
                                                styles={customStylesMulti}
                                                classNamePrefix={".Select"}
                                                placeholder="Search for Genres..."
                                                value={this.state.genres}
                                                options={this.state.genreOptions}
                                                isMulti
                                                isSearchable
                                            />
                                    </div>

                                    {/* {# Emotions #} */}
                                    <div className="input-group">
                                        <label htmlFor="emotions" className="input-label">What Primary Emotion does this track evoke? <span className="input-label-info">Choose 1</span></label>
                                            <Select
                                                isDisabled
                                                name="emotions"
                                                styles={customStylesMulti}
                                                placeholder="Search for an Emotion..."
                                                value={this.state.emotions}
                                                options={this.state.emotionOptions}
                                                isMulti
                                                isSearchable
                                            />

                                    </div>
                                    {/* {# Moods #} */}
                                    <div className="input-group">
                                        <label htmlFor="moods" className="input-label">What Moods best match this track? <span className="input-label-info">Choose up to 4</span></label>
                                            <Select
                                                isDisabled
                                                name="moods"
                                                styles={customStylesMulti}
                                                placeholder="Search for Moods..."
                                                value={this.state.moods}
                                                options={this.state.moodOptions}
                                                isMulti
                                                isSearchable
                                            />
                                    </div>

                                    {/* {# Attributes #} */}
                                    <div className="input-group">
                                        <label htmlFor="attributes" className="input-label">What Attributes best match this track? <span className="input-label-info">Choose up to 8</span></label>

                                            <Select
                                                isDisabled
                                                name="attributes"
                                                styles={customStylesMulti}
                                                placeholder="Search for Attributes..."
                                                value={this.state.attributes}
                                                options={this.state.attributeOptions}
                                                isMulti
                                                isSearchable
                                            />
                                    </div>
                                    {/* {# Instruments #} */}
                                    <div className="input-group">
                                        <label htmlFor="instruments" className="input-label">What Instruments are featured on this track? <span className="input-label-info">Choose as many as apply</span></label>

                                            <Select
                                                isDisabled
                                                name="instruments"
                                                styles={customStylesMulti}
                                                placeholder="Search for Instruments..."
                                                value={this.state.instruments}
                                                options={this.state.instrumentOptions}
                                                isMulti
                                                isSearchable
                                            />
                                    </div>
                                <KeywordsInput
                                    keywords={this.state.keywords}
                                    customStylesMulti={customStylesMulti}
                                    handleKeywordChange={this.handleKeywordChange}
                                    allOptions={this.state.allOptions}
                                />
                                {/* {# Lyrics #} */}
                                <div className="input-group">
                                    <p className="input-label">Does this track contain lyrics?</p>
                                    {/* {# Fieldset #} */}
                                    <fieldset className="input-fieldset">
                                            <div className="grid-row-sm">
                                                <div className="col w-1/2">
                                                    <label className="radio-option">

                                                        <input disabled type="radio" className="form-radio" name="containsLyrics" value="false" checked={this.state.containsLyrics === "false"}/>
                                                        No
                                                    </label>
                                                </div>
                                                <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input disabled type="radio" className="form-radio" name="containsLyrics" value="true" checked={this.state.containsLyrics === "true"}/>
                                                        Yes
                                                    </label>
                                                </div>
                                            </div>
                                        {/* {# Conditional Fields #} */}
                                        {this.state.containsLyrics ==="true" ?
                                        <div className="input-fieldset-cond is-visible">
                                            <hr className="divider-sm"/>
                                            <p className="input-label">Does this track contain explicit lyrics?</p>
                                                <div className="grid-row-sm">
                                                    <div className="col w-1/2">
                                                        <label className="radio-option">
                                                            <input disabled type="radio" className="form-radio" name="explicitLyrics" value="false" checked={this.state.explicitLyrics === "false"}/>
                                                            No
                                                        </label>
                                                    </div>
                                                    <div className="col w-1/2">
                                                        <label className="radio-option">
                                                            <input disabled type="radio" className="form-radio" name="explicitLyrics" value="true" checked={this.state.explicitLyrics === "true"}/>
                                                            Yes
                                                        </label>
                                                    </div>
                                                </div>
                                        </div>: <></>}
                                    </fieldset>
                                </div>
                                <div className="input-group">
                                    <p className="input-label">What BPM accurately describes this track?</p>
                                        <div className="grid-row-xs">
                                                <div className="col w-full md:w-1/2 mb-20">
                                                    <label className="radio-option radio-btn">
                                                        <input disabled type="radio" className="form-radio" name="trackBPM" value="fast" checked={this.state.trackBPM === "fast"}/>
                                                        Fast
                                                    </label>
                                                </div>
                                                <div className="col w-full md:w-1/2 mb-20">
                                                    <label className="radio-option radio-btn">
                                                        <input disabled type="radio" className="form-radio" name="trackBPM" value="medium" checked={this.state.trackBPM === "medium"}/>
                                                        Medium
                                                    </label>
                                                </div>
                                                <div className="col w-full md:w-1/2 mb-20">
                                                    <label className="radio-option radio-btn">
                                                        <input disabled type="radio" className="form-radio" name="trackBPM" value="medium-fast" checked={this.state.trackBPM === "medium-fast"}/>
                                                        Medium-Fast
                                                    </label>
                                                </div>
                                                <div className="col w-full md:w-1/2 mb-20">
                                                    <label className="radio-option radio-btn">
                                                        <input disabled type="radio" className="form-radio" name="trackBPM" value="medium-slow" checked={this.state.trackBPM === "medium-slow"}/>
                                                        Medium-Slow
                                                    </label>
                                                </div>
                                                <div className="col w-full md:w-1/2 mb-20">
                                                    <label className="radio-option radio-btn">
                                                        <input disabled type="radio" className="form-radio" name="trackBPM" value="slow" checked={this.state.trackBPM === "slow"}/>
                                                        Slow
                                                    </label>
                                                </div>
                                        </div>
                                </div>
                                {/* {# City #} */}
                                <div className="input-group">
                                <label htmlFor="city" className="input-label">
                                    In which City was this track made?
                                    <span class="input-label-required">*</span>
                                    <span className="input-label-info">Choose one</span>
                                </label>
                                <p>We recommend using the closest major city for best results.</p>
                                        <Select
                                            isDisabled
                                            name="city"
                                            styles={customStyles}
                                            placeholder="Search for cities..."
                                            value={this.state.city}
                                            options={this.state.cityOptions}
                                            isSearchable
                                        />
                                </div>
                                </div>
                                {/* {# Fun Fact #} */}
                                <div className="form-section" id="fun_fact">
                                    <h2 className="text-label-lg mb-40">Fun Fact</h2>
                                    <div className="input-group">
                                        <label className="input-label" htmlFor="track_fun_fact">
                                            Tell us about your track (optional).
                                        </label>
                                        <textarea name="funFact"
                                            value={this.state.funFact}
                                            disabled id="track_fun_fact"
                                            className="form-textarea"
                                            rows="6"
                                            placeholder="Do you have any interesting facts about this track? What inspired you to make it? This info will be listed in the About section of the track and can help customers make a connection to your track!">
                                        </textarea>
                                        <CharacterCount
                                            limit="400"
                                            value={this.state.funFact?.length}
                                        />
                                    </div>
                                </div>


                                <div className="form-section" id="versions">
                                <h2 className="text-label-lg mb-40">Versions</h2>
                                <div className="input-group">
                                    <p className="input-label mb-10">Would you like to add alternate versions of this track?</p>
                                    <p className="mb-20">Alternate versions make your track more attractive to potential customers.</p>

                                    <fieldset className="input-fieldset">
                                            <div className="grid-row-sm">
                                                <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input disabled type="radio" className="form-radio" name="versionsAvailable" value="false" checked={this.state.versionsAvailable === "false"}/>
                                                        No
                                                    </label>
                                                </div>
                                                <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input disabled type="radio" className="form-radio" name="versionsAvailable" value="true" checked={this.state.versionsAvailable === "true"}/>
                                                        Yes
                                                    </label>
                                                </div>
                                            </div>
                                        {this.state.versionsAvailable === "true" ?
                                        <div className="input-fieldset-cond is-visible">
                                            <hr className="divider-sm"/>
                                            <p className="input-label mb-10">Upload Versions</p>
                                            <p className="mb-20">48 kHz &amp; 16-bit stereo uncompressed WAV files only accepted. <TechnicalRequirementsLearnMore red/>.</p>

                                                        {this.state.additionalVersions.map((version, index)=>(
                                                            <div className="input-group-sm">
                                                            {version.wavURL  || this.state[`versionFile${index}Loading`] || version.error?
                                                            <>
                                                            {!version.error ?
                                                                <div className="upload-item">
                                                                    <div className="upload-item-pre">
                                                                        {/* {# Replace div with react loader, swap with play button once uploaded #} */}
                                                                        {this.state[`versionFile${index}Loading`] ?
                                                                            <CircularIndeterminant thickness={2.5}  size={40}/>
                                                                            :
                                                                            <button type="button" onClick={(event)=>{this.changeNowPlaying(event, version.wavURL)}} className="circle-icon-border circle-icon-sm">{this.state.nowPlaying === version.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                                                        }

                                                                    </div>
                                                                    <div className="upload-item-details">
                                                                        <span className="upload-item-title">{version.label}</span>
                                                                        <div className="upload-item-file">{version.localname}</div>
                                                                    </div>

                                                                    <div className="upload-item-wave">
                                                                        <Waveform nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={version.wavURL} peaks={version.peaks}/>
                                                                        {/* {% include "components/audio/waveform-sm.html" %} */}
                                                                        <span className="upload-item-duration">{version.duration}</span>
                                                                    </div>
                                                                    <div className="upload-item-controls">
                                                                            <button type="button" disabled onClick={(event)=>{event.preventDefault();}}  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                                                    </div>
                                                                </div>:
                                                                <>
                                                                    <div className="upload-item has-error">
                                                                        <div className="upload-item-pre">
                                                                            <button type="button" className="circle-icon-border circle-icon-sm"><span className="icon-play"></span></button>
                                                                        </div>
                                                                        <div className="upload-item-details">
                                                                        <span className="upload-item-title">{version.label}</span>
                                                                        <div className="upload-item-file">{version.localname}</div>
                                                                        </div>
                                                                        <div className="upload-item-wave">
                                                                            {/* {% include "components/audio/waveform-sm.html" %} */}
                                                                            <span className="upload-item-duration">{version.duration}</span>
                                                                        </div>
                                                                        <div className="upload-item-controls">
                                                                                <input disabled id={'versionFile' + index} type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} />
                                                                            <label htmlFor={'versionFile' + index} className="btn btn-stroke btn-sm">Reupload</label>
                                                                        </div>
                                                                    </div>
                                                                    {/* {# Error Message #} */}
                                                                    <div className="error-message is-file">{version.error}</div>
                                                                </>
                                                            }
                                                                {/* <div>{version.label}</div>
                                                                <br></br> */}
                                                            </>:
                                                                <div className="grid-row-xs">
                                                                    <div className="col w-auto flex-1" key={index}>
                                                                            <Select
                                                                                isDisabled
                                                                                name={"versionName" + index}
                                                                                placeholder="Version Name..."
                                                                                styles={customStyles}
                                                                                value={this.state.additionalVersions[index].name}
                                                                                options={versionNameOptions.filter(version => {
                                                                                    for (let i = 0; i < this.state.additionalVersions.length; i++) {
                                                                                        if (this.state.additionalVersions[i].value === version.value) {
                                                                                            return false
                                                                                        }
                                                                                    }
                                                                                    return true
                                                                                })
                                                                                }

                                                                            />
                                                                    </div>
                                                                    <div className="col w-auto flex-initial">
                                                                        <input id={'versionFile' + index} type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }}  />
                                                                        <label htmlFor={'versionFile' + index} className="btn btn-stroke form-btn">Upload Version</label>
                                                                    </div>
                                                                </div>
                                                            }
                                                            </div>
                                                        ))}
                                            <hr className="divider"></hr>

                                            <div className="input-group ">
                                                    <button type="button" disabled className="btn btn-disabled btn-stroke form-btn w-full"><span className="icon-plus mr-5"></span>Add a Version</button>
                                            </div>

                                        </div>: <></>}
                                    </fieldset>

                                </div>
                            </div>
                            {/* {# Stems #} */}
                            <div className="form-section" id="stems">
                                <h2 className="text-label-lg mb-40">Stems</h2>
                                <div className="input-group">
                                    <p className="input-label mb-10">Would you like to add individual stems for this track?</p>
                                    <p className="mb-20">Stems make your track more attractive to higher-end productions.</p>

                                    <div className="input-fieldset">
                                            <div className="grid-row-sm">
                                                <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input disabled type="radio" className="form-radio" name="stemsAvailable" value="false" checked={this.state.stemsAvailable === "false"}/>
                                                            No
                                                    </label>
                                                </div>
                                                <div className="col w-1/2">
                                                    <label className="radio-option">
                                                        <input disabled type="radio" className="form-radio" name="stemsAvailable" value="true" checked={this.state.stemsAvailable === "true"}/>
                                                        Yes
                                                    </label>
                                                </div>
                                            </div>
                                        {/* {# Conditional Fields #} */}
                                        {this.state.stemsAvailable === "true" ?
                                        <div className="input-fieldset-cond is-visible">
                                            <hr className="divider-sm"/>
                                            <p className="input-label mb-10">Upload Stems</p>
                                            <p className="mb-10">The instruments below are populated based on the instruments featured in the track that you provided earlier.  You can add multiple stems based on the same instrument (Example: Select "Strings" twice when uploading stems for short strings and another stem for long strings.)</p>
                                            <p className="mb-10">48 kHz &amp; 16-bit stereo uncompressed WAV files only accepted. <TechnicalRequirementsLearnMore red/>.</p>
                                            <p className="text-gray-400 text-sm mb-20">Please make sure all stems are the exact same length to the millisecond or your stem will automatically be rejected.</p>
                                            {this.state.stems.map((stem, index) =>(

                                                            <div className="input-group-sm">
                                                            {stem.wavURL  || this.state[`stemFile${stem.name}Loading`] || stem.error?
                                                            <>
                                                            {!stem.error ?
                                                                <div className="upload-item">
                                                                    <div className="upload-item-pre">
                                                                        {/* {# Replace div with react loader, swap with play button once uploaded #} */}
                                                                        {this.state[`stemFile${stem.name}Loading`] ?
                                                                            <CircularIndeterminant thickness={2.5}  size={40}/>
                                                                            :
                                                                            <button type="button" onClick={(event)=>{this.changeNowPlaying(event, stem.wavURL)}} className="circle-icon-border circle-icon-sm">{this.state.nowPlaying === stem.wavURL ?<span className="icon-pause"></span>:<span className="icon-play"></span>}</button>
                                                                        }

                                                                    </div>
                                                                    <div className="upload-item-details">
                                                                        <span className="upload-item-title">{stem.name}</span>
                                                                        <div className="upload-item-file">{stem.localname}</div>
                                                                    </div>

                                                                    <div className="upload-item-wave">
                                                                        {stem.wavURL ?
                                                                        <Waveform nowPlaying={this.state.nowPlaying} trackId={this.props.match.params.trackId} s3Key={stem.wavURL} peaks={stem.peaks}/>:
                                                                        <></>
                                                                        }
                                                                        {/* {% include "components/audio/waveform-sm.html" %} */}
                                                                        <span className="upload-item-duration">{stem.duration}</span>
                                                                    </div>
                                                                    <div className="upload-item-controls">
                                                                            <button type="button" disabled  className="circle-icon circle-icon-sm"><span className="icon-trash"></span></button>
                                                                    </div>
                                                                </div>:
                                                                <>
                                                                    <div className="upload-item has-error">
                                                                        <div className="upload-item-pre">
                                                                            <button type="button" className="circle-icon-border circle-icon-sm"><span className="icon-play"></span></button>
                                                                        </div>
                                                                        <div className="upload-item-details">
                                                                        <span className="upload-item-title">{stem.label}</span>
                                                                        <div className="upload-item-file">{stem.localname}</div>
                                                                        </div>
                                                                        <div className="upload-item-wave">
                                                                            {/* {% include "components/audio/waveform-sm.html" %} */}
                                                                            <span className="upload-item-duration">{stem.duration}</span>
                                                                        </div>
                                                                        <div className="upload-item-controls">
                                                                        <div className="upload-item-controls">
                                                                            <input id={`stemFile${stem.name}`} type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} />
                                                                            <label htmlFor={`stemFile${stem.name}`} className="btn btn-stroke btn-sm">Reupload</label>
                                                                        </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* {# Error Message #} */}
                                                                    <div className="error-message is-file">{stem.error}</div>
                                                                </>
                                                            }

                                                            </>:
                                                                <></>
                                                            }
                                                            </div>
                                            ))}
                                            <div className="grid-row-xs">
                                                                    <div className="col w-auto flex-1" >
                                                                            <Creatable
                                                                                isDisabled
                                                                                name={"newStemName"}
                                                                                placeholder="Stem Name..."
                                                                                styles={customStyles}
                                                                                value={this.state.newStemName}
                                                                                options={this.state.instruments.filter(instrument => {
                                                                                    for (let i = 0; i < this.state.stems.length; i++) {
                                                                                            if (this.state.stems[i].name === instrument.value) {
                                                                                                return false
                                                                                            }
                                                                                    }
                                                                                    return true
                                                                                })
                                                                                }
                                                                                isSearchable
                                                                            />
                                                                    </div>
                                                                    <div className="col w-auto flex-initial">
                                                                        {this.state.newStemName ? <>
                                                                                <input disabled id={`stemFile${this.state.newStemName.value}`} type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }} />
                                                                            <label htmlFor={`stemFile${this.state.newStemName.value}`} className="btn btn-stroke form-btn">Upload Stem</label>
                                                                        </>:<>
                                                                            <input disabled  type="file" style={{ width: "0.1px", height: "0.1px", opacity: 0, overflow: "hidden", position: 'absolute', zIndex: -1 }}  />
                                                                            <label  className="btn btn-stroke btn-disabled form-btn">Upload Stem</label>
                                                                        </>}
                                                                    </div>
                                                                </div>
                                        </div> : <></>}
                                    </div>

                                </div>
                            </div>
                            {/* Terms */}
                            <div className="form-section" id="terms">
                                <h2 className="text-label-lg mb-40">Terms</h2>
                                <div className="input-group-xs">
                                    <label className="radio-option items-start" htmlFor="tosAgree">
                                            <input disabled type="checkbox" className="form-checkbox" id="tosAgree" name="tosAgree" checked/>
                                            <span className="inline-block">Agree to our <a href="/terms/artist" className="text-link" target="_blank">Artist Terms of Service</a> and <a href="/privacy" className="text-link" target="_blank">Privacy Policy</a></span>.
                                    </label>
                                </div>
                                <div className="input-group-xs">
                                    <label className="radio-option items-start" htmlFor="exclusiveAgree">
                                            <input disabled type="checkbox" className="form-checkbox" id="exclusiveAgree" name="exclusiveAgree" checked/>
                                        <span className="inline-block">You agree that this track will be exclusively licensed through Deep Sounds and is not on any other Music Library.</span>
                                    </label>
                                </div>
                                <div className="input-group-xs">
                                    <label className="radio-option items-start" htmlFor="removeAgree">
                                            <input disabled type="checkbox" className="form-checkbox" id="removeAgree" name="removeAgree" checked/>
                                        <span className="inline-block">You understand that you won't have the ability to remove this track from the website for 1 year after Deep Sounds approves this track.</span>
                                    </label>
                                </div>
                                <div className="input-group-xs">
                                    <label className="radio-option items-start" htmlFor="copyAgree">
                                        <input disabled type="checkbox" className="form-checkbox" id="copyAgree" name="copyAgree" checked/>
                                        <span className="inline-block">You own 100% of the rights to this track and it does not infringe on any other artist’s copyright.</span>
                                    </label>
                                </div>
                            </div>
                            <div className="form-section" id="form_actions">
                                <div className="grid-row-xs justify-end">
                                    <div className="col w-full sm:w-1/2 md:w-auto mb-20">
                                        <button type="button" type="button" disabled onClick={(event)=>{event.preventDefault();}} className="btn btn-disabled btn-stroke">Submit for Approval</button>
                                    </div>
                                    <div className="col w-full sm:w-1/2 md:w-auto">
                                            <button type="button" type="button" onClick={(event)=>{event.preventDefault()}} className="btn btn-disabled btn-primary">Save and Quit</button>
                                    </div>
                                </div>
                            </div>
                          </form>
                            {/* {# End Form #} */}

                        </div>
                        {/* {# End App Main Content #} */}

                        {/* {# Right Info Sidebar #} */}
                        <div className="app-sublayout-info">
                        {/* {# Form Progress #} */}
                        <MediaQuery minWidth={1024}>
                            <Sticky scrollElement=".app-main" stickyClassName="mt-15">
                                <div className="form-progress">
                                    <div className="form-progress-header">
                                        <h4 className="text-label-lg">Progress</h4>
                                    </div>
                                    <div className="form-progress-details">
                                        <p className="text-sm">You must complete all required sections below before you can submit your track for approval.</p>
                                        {/* {# Progress Details #} */}
                                        <ul className="form-progress-sections">
                                            <li className={this.state.detailsComplete ? "is-complete": ""}><a href="#details">Track Details <span className="form-progress-req">Req.</span></a></li>
                                            <li className={this.state.tagsComplete ? "is-complete": ""}><a href="#tags">Tags <span className="form-progress-req">Req.</span></a></li>
                                            <li className={this.state.funFactComplete === true ? "is-complete" : this.state.funFactComplete === 'incomplete' ? "has-error" : ""}>
                                                <a href="#fun_fact">Fun Fact</a>
                                            </li>
                                            <li className={this.state.versionsComplete ? "is-complete": ""}><a href="#versions">Versions</a></li>
                                            <li className={this.state.stemsComplete ? "is-complete": ""}><a href="#stems">Stems</a></li>
                                            <li className=""><a href="#composers">Composers</a></li>
                                            <li className=""><a href="#publisher">Publisher</a></li>
                                            {this.state.trackStatus === "pending" ?
                                                <li className={"is-complete"}><a href="#terms">Terms & Conditions <span className="form-progress-req">Req.</span></a></li>:
                                                <li className={this.state.termsComplete ? "is-complete": ""}><a href="#terms">Terms & Conditions <span className="form-progress-req">Req.</span></a></li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </Sticky>
                        </MediaQuery>
                    </div>
                        {/* {# End Info Sidebar #} */}

                    </div>
                    {/* {# End App Main Inner #} */}

                </div>
                {/* {# End Container #} */}

            </div>

            )}
            </UserConsumer>
            </AppLayout>
        )
    }

}

export default ArtistPendingTrack

import React from "react";

const TabItemContent = ({ activeTab, children, name }) => {
  return (
    <div className={`tab-item-content pt-10${activeTab === name ? " active" : ""}`}>
      {children}
    </div>
  );
};

const Tabs = ({ activeTab, children, setActiveTab, tabItems }) => {

  const TabItem = ({ name }) => {
    return (
      <li className={`tab-item${activeTab === name ? " active" : ""}`}>
        <button className="tab-item-title" onClick={() => setActiveTab(name)}>
          {name}
        </button>
      </li>
    );
  };

  return (
    <div className="tabs mt-15">
      <ul className="tab-items">
        {tabItems.map((tabItem, index) => (
          <TabItem
            activeTab={activeTab}
            key={index}
            name={tabItem}
            setActiveTab={setActiveTab}
          />
        ))}
      </ul>
      <div className="tab-content">{children}</div>
    </div>
  );
};

export {
  TabItemContent,
  Tabs
};

import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import LoadingContainer from "../../Loading/LoadingContainer";
// import SalesPagination from "../../Pagination/salesPagination";
import SalesLicenseRow from "./SalesLicenseRow";
import usersAPI from "../../../api/users";
import paginate from "../../../utils/paginate";
import SalesPagination from "../../Pagination/salesPagination";
import currencyFormatter from "../../../utils/currencyFormatter";

const SalesLicenses = () => {
  const history = useHistory();
  const currentPage = Number(history.location.pathname.split("/").pop()) || 1;
  const perPage = 12;
  const setCurrentPage = (pageNumber) => {
    if (pageNumber !== currentPage) {
      history.push(`/dashboard/sales/licenses/${pageNumber}`);
    }
  };

  const [isLoaded, setIsLoaded] = useState(true);
  const [licenseSales, setLicenseSales] = useState(null);
  const [currentPageLicenseSales, setCurrentPageLicenseSales] = useState(null);
  const [totalPaid, setTotalPaid] = useState(null);

  useEffect(() => {
    setIsLoaded(false);
    usersAPI.getLicenseSales({ currentPage, perPage }).then((res) => {
      console.log("getLicenseSales res", res);
      const paginatedLicenseSales = paginate(res?.data?.licenseSales, perPage) || [];
      const paginatedLicenseSalesPage = paginatedLicenseSales[currentPage - 1];
      setCurrentPageLicenseSales(paginatedLicenseSalesPage);
      setLicenseSales(res?.data?.licenseSales);
      const totalPaidFormatted = currencyFormatter(res?.data?.totalPaid, true);
      setTotalPaid(totalPaidFormatted);
      setIsLoaded(true);
    });
  }, [currentPage]);

  return (
    <LoadingContainer isLoaded={isLoaded}>
      <div class="bg-gray-100 p-20 pb-10 lg:p-25 lg:pb-15 mb-40">
        {/* {# Table Header #} */}
        <div class="flex flex-col md:flex-row items-stretch justify-between bg-gray-100 mb-40">
          <div className="flex-1 flex items-top mb-15 md:mb-0">
            <h3 class="text-label-lg mr-10">Single Track Licenses</h3>
            <span className="flex-none text-xs mt-5">
              Showing
              {/* {perPage < licenseSales?.length ? perPage : licenseSales?.length} of */}{" "}
              {licenseSales?.length}
            </span>
          </div>
          {/* {# Summary #} */}
          <div class="flex-none flex flex-col sm:flex-row">
            {/* {# Payout Summary #} */}
            <div class="flex flex-col flex-end pr-30 lg:pr-60 leading-snug mb-30 md:mb-0">
              <span class="text-label-sm mb-3 text-right">Total Paid</span>
              <span class="text-h3 text-white font-display leading-tight text-right">
                {totalPaid}
              </span>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-start md:items-center justify-between"></div>
        {/* // {# Table #} */}
        <div class="table-wrap ds-scrollbar">
          <table class="stats-table w-full">
            <thead class="text-left">
              <tr>
                <th class="text-label-sm">Date Earned</th>
                <th class="text-label-sm">Track Title</th>
                <th class="text-label-sm">Type</th>
                <th class="text-label-sm">License</th>
                <th class="text-label-sm text-right" style={{ minWidth: "14rem" }}>
                  Total Earnings
                </th>
              </tr>
            </thead>
            <tbody>
              {/* {% for item in purchases %} */}
              {currentPageLicenseSales?.map((sale) => {
                const { info: license } = sale || {};
                return <SalesLicenseRow {...sale} license={license} key={sale?.licenseNumber} />;
              })}
              {/* {% endfor %} */}
            </tbody>
          </table>
        </div>
        <SalesPagination
          pageLimit={perPage}
          currentPage={currentPage}
          totalRecords={licenseSales?.length}
          gotoPage={setCurrentPage}
        />

        {/* // {# Pagination #}
    // {% include "components/tables/table-pagination.html" %} */}
      </div>
    </LoadingContainer>
  );
};

export default SalesLicenses;

import React from "react";
import { useHistory } from "react-router";

const SignupButton = () => {
  const history = useHistory();
  const { pathname } = history?.location;
  // console.log('pathname', pathname)
  const returnPath = pathname || "music";

  return (
    <a href={`/signup?return=${returnPath}`} className="navbar-signup btn btn-sm btn-white">
      Sign Up
    </a>
  );
};

export default SignupButton;

import React, { Component } from "react"
import { NavLink } from "react-router-dom"
import { Tooltip } from "react-tippy"
import { PlayerContext } from "../../PlayerContext"
import { Error, Success } from "../Toast/index"
import FavAlbumCardTooltip from "./favAlbumCardTooltip"
class FavAlbumCard extends Component {
    state={
        showAlbumTippy: false
    }
    toggleAlbumTippy = ()=>{
        this.setState({showAlbumTippy: !this.state.showAlbumTippy})
    }
    shareAlbum = ()=>{
        var textArea = document.createElement("textarea");

        // Place in top-left corner of screen regardless of scroll position.
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em';
        textArea.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = 0;

        // Clean up any borders.
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';

        // Avoid flash of white box if rendered for any reason.
        textArea.style.background = 'transparent';


        textArea.value = `${window.location.protocol}//${window.location.hostname}${window.location.port ? ":" + window.location.port : ""}/artists/${this.props.artistURL}#album-${this.props.albumTitle?.replace(/\s/g, "-")}`;

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            if(successful){
                Success({message: "Album URL Copied to Clipboard"})
            }
            else{
                Error({message: "Couldn't Copy Artist URL "})
            }
            var msg = successful ? 'successful' : 'unsuccessful';
            
        } catch (err) {
            
        }

        document.body.removeChild(textArea);

        this.setState({showArtistTippy: false})
    }
    resolveAlbumPhoto=(albumPhotoKey)=>{
        const key = albumPhotoKey
        if(albumPhotoKey){
            const ext = key.split('.').reverse()[0];
            let sizeMod;
            sizeMod = '500x500'

            let final = key.replace('.' + ext, '') + '-minified-' + sizeMod + '.' + ext
            return final
        }
    }   
    render() {
        return (
            <div class="artwork-card">
            {/* {# Card Image #} */}
            <div class="artwork-card-image">
                <img src={`https://${process.env.REACT_APP_S3_PHOTO_BUCKET}.s3.us-east-2.amazonaws.com/${(process.env.REACT_APP_S3_SUB_BUCKET ?  process.env.REACT_APP_S3_SUB_BUCKET + "/": "" )}${this.resolveAlbumPhoto(this.props.imageURL)}`}/>
                <div class="artwork-card-hover">
                    <div class="circle-icon-fill bg-white text-black circle-icon-lg">
                        {this.context?.mediaPlayer.idKey === this.props.idKey && this.context.playing ? 
                            <span  onClick={()=>this.context.playPause(this.props.albumId)} class="icon-pause"></span>:                 
                            <span  onClick={()=>this.props.playAlbum(this.props.albumId)} class="icon-play"></span>}
                    </div>
                </div>
            </div>

            {/* {# Card Content #} */}
            <div class="artwork-card-content">

                {/* {# Details #} */}
                <div class="artwork-card-title"><NavLink to={`/artists/${this.props.artistURL}#album-${this.props.albumTitle?.replace(/\s/g, "-")}`}>{this.props.albumTitle}</NavLink></div>
                <div class="artwork-card-subtext"><NavLink to={`/artists/${this.props.artistURL}`} class="text-link-gray">{this.props.artistName}</NavLink></div>

                {/* {# Track Count #} */}
                <div class="flex -ml-3 mt-15 items-center text-sm">
                    <div class="flex items-end leading-snug mr-10">
                        <span class="icon-tracks text-gray-500 text-icon-sm mr-3"></span>
                        <NavLink to={`/artists/${this.props.artistURL}#album-${this.props.albumTitle?.replace(/\s/g, "-")}`}><span class="text-gray-500">
                            {this.props.totalTracks} <span class="hidden md:inline">Tracks</span>
                        </span>
                        </NavLink>
                    </div>
                    <div class="flex items-end leading-snug">
                        <span class="icon-like text-gray-500 text-icon-sm mr-3"></span>
                        <NavLink to={{pathname:'tracks', state:{fromAlbum: this.props.albumTitle}}}>
                            <span class="text-gray-500">
                                {this.props.likedTracks} <span class="hidden md:inline">Likes</span>
                            </span>
                        </NavLink>
                    </div>
                </div>

                {/* {# More Dots #} */}
                <Tooltip
                className="circle-icon"
                    arrow={false}
                    open={this.state.showAlbumTippy}
                    interactive={true}
                    useContext={true}
                    trigger={'click'}
                    position={'bottom-end'}
                    html={(<FavAlbumCardTooltip
                                artistId={this.props.artistId}
                                albumTitle={this.props.albumTitle}
                                albumId={this.props.albumId}
                                artistURL={this.props.artistURL}
                                artistName={this.props.artistName}
                                shareAlbum={this.shareAlbum}
                                unlikeAlbum={this.props.unlikeAlbum}
                    />)}
                    allowHTML={true}
                    onUntrigger={()=>{this.state.showAlbumTippy ? this.setState({showAlbumTippy: false}): this.setState({showAlbumTippy: true})}}
                    onRequestClose={()=>{this.state.showAlbumTippy ? this.setState({showAlbumTippy: false}): this.setState({showAlbumTippy: true})}}
                >
                    <button 
                        onClick={this.toggleAlbumTippy} 
                        class="circle-icon text-gray-500"
                    >
                        <span class="icon-dots-vert"></span>
                    </button>
                </Tooltip>
            </div>

            </div>
        )
    }
}
FavAlbumCard.contextType = PlayerContext
export default FavAlbumCard
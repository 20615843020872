import moment from "moment";
import React, { Component, createRef } from "react";
import LoadingOverlay from "react-loading-overlay";
import { WaveSpinner } from "react-spinners-kit";
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/src/plugin/regions/index.js';
import { PlayerContext } from "../../PlayerContext";
import "./videoStudio.css";
var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment)
// import { PlayerContext } from "../../PlayerContext";

class VideoStudioWaveForm extends Component {
    state = {
        src: this.props.src || "",
        peaks: this.props.peaks,
        currentTime: "0:00",
        duration: "0:00",
        sendPlay: 0,
        volume: 1,
        ready: false,
        waveWidth: 0,
        videoTimelineWidth: 0,
        leftOffset: this.props.initialLeftCutoff,
        currentPeaks: [],
        isMini: false,
        isMainOpen: false,
    }
    handleOnChange = (value) => {
      console.log(value)
      this.setState({
        volume: value.x
      })
    }
  setVolume = ()=>{
    console.log("setting volume to ", this.state.volume)
    this.wavesurfer.setVolume(this.state.volume)
  }

  waveReady = ()=>{}

  wrapper = createRef()
  componentDidMount() {
    // this.context.setRedrawCallback(this.redrawCallback)
    // this.context.drawVideoStudioTimeline()

    console.log("wave mounted")
    this.$el = this.wrapper.current
    this.$waveform = this.$el.querySelector('.wave')

    this.wavesurfer = WaveSurfer.create({
        container: this.$waveform,
        normalize: true,
        barMinHeight: 1,
        barWidth: 2,
        barGap: 2,
        hideScrollbar: true,
        interact: false,
        plugins: [
          RegionsPlugin.create({
            // plugin options ...
          })
        ],
        cursorWidth: 0,
        backend: "MediaElement",
        fillParent: true,
        height: 2.5 * (parseFloat(getComputedStyle(document.documentElement).fontSize)),
        // barHeight: 3,
        progressColor: "rgba(255,255,255,1)",
        responsive: true,
        waveColor: "rgba(255,255,255,0.5)",
        xhr:{ cache: 'default', mode: 'cors', method: 'GET', credentials: 'same-origin', redirect: 'follow', referrer: 'client', headers: [ { key: 'Authorization', value: 'my-token' } ]}
        // fillParent: true
        // fillParent: true,        
    })

  
    this.waveReady = this.wavesurfer.on('ready', ()=>{
      console.log("wave ready")

      this.setState({ready: true})
    })
    this.wavesurferError = this.wavesurfer.on('error', (error)=>{console.log("wave error ", error)})
    this.wavesurfer.backend.peaks = JSON.parse(this.context.mediaPlayer.peaks)
    this.wavesurfer.drawBuffer()
    this.context.setVideoStudioWaveform(this.wavesurfer)
    this.setState({currentPeaks: JSON.parse(this.context.mediaPlayer.peaks)})
    this.setState({ready: true})
   
  }
  regionUpdated = ()=>{}
  songFinished = ()=>{}
  songLoaded = ()=>{}
  wavesurferError = ()=>{}
  currentTimeInt = ()=>{}
  updateWaveformFromMain =()=>{
    if(this.context.waveFormEl){
      let duration = this.context.waveFormEl.getDuration()
      if(!isNaN(duration)){
        if(this.state.dragging){
          this.wavesurfer.seekTo(0)
        }
        else{
            if(this.context.mediaPlayer.currentTime/duration < 1 && this.context.mediaPlayer.currentTime/duration > 0){
              this.wavesurfer.seekTo(this.context.mediaPlayer.currentTime/duration)
            }
          
          
        }
      }
    }
    

  }
  componentDidUpdate = (prevProps, prevState, snapshot)=>{
    if(this.props.isMainOpen){
    let duration;
    this.updateWaveformFromMain()

    if(this.context.waveFormEl){
      if(this.props.isMainOpen && Object.keys(this.context.waveFormEl?.regions ? this.context.waveFormEl.regions.list: {})?.length < 1){
        this.context.toggleRegion()
      }
      duration = this.context.waveFormEl.getDuration()
    }

    if(this.context.mediaPlayer.idKey === this.props.idKey && this.context.user !== null){
      if(!this.wavesurfer.params.interact){
      }
  }
  else{
    if(this.wavesurfer.params.interact === true){
      this.wavesurfer.toggleInteraction()
      this.wavesurfer.seekTo(0)
    }
  }
  // if(this.props.videoTimelineWidth !== this.state.videoTimelineWidth){
  //   this.setState({videoTimelineWidth: this.props.videoTimelineWidth},()=>{
  //     if(this.context.waveFormEl){
  //       let ratio = this.context.waveFormEl.getDuration()/this.props.videoDuration
  //       console.log("track to video ratio ", ratio)
  //       let waveWidth = ratio * this.state.videoTimelineWidth
  //       this.setState({waveWidth: waveWidth},()=>{
  //         this.wavesurfer.backend.peaks = JSON.parse(this.context.mediaPlayer?.peaks)
  //         this.wavesurfer.drawBuffer()
  //       })
  //     }
  //   })
  // }
  if(prevProps.idKey !== this.props.idKey){
    console.log("updating width here")

  }
  // if(this.props.isMini !== this.state.isMini){
  //   this.setState({isMini: this.props.isMini},()=>{
  //     if(!this.props.isMini){
  //       if(this.context.waveFormEl){
  //       let ratio = this.context.waveFormEl.getDuration()/this.props.videoDuration
  //       console.log("track to video ratio ", ratio, " timline widthg ", this.context.videoTimelineWidth)
  //       let waveWidth = ratio * this.context.videoTimelineWidth
  //       this.setState({waveWidth: waveWidth},()=>{
  //         this.wavesurfer.backend.peaks = JSON.parse(this.context.mediaPlayer?.peaks)
  //         this.wavesurfer.drawBuffer()
  //       })
  //     }
  //     }
  //   })
  // }
  // if(this.props.isMainOpen !== this.state.isMainOpen){
    
  //     if(this.props.isMainOpen && this.context.waveFormEl){
  //       console.log("video duration ", this.props.videoDuration)
  //       let ratio = this.context.waveFormEl.getDuration()/this.props.videoDuration
  //       console.log("track to video ratio after reopening ", ratio)
  //       let waveWidth = ratio * this.state.videoTimelineWidth
  //       this.setState({waveWidth: waveWidth, isMainOpen: this.props.isMainOpen},()=>{
  //         this.wavesurfer.backend.peaks = JSON.parse(this.context.mediaPlayer?.peaks)
  //         this.wavesurfer.drawBuffer()
  //       })
  //     }
      
  // }
  // if(this.props.playedSeconds >= this.props.trackStartTime && !this.context.regionPlaying && this.props.isVideoPlaying && !this.state.dragging){
  //   let offset = this.props.playedSeconds - this.props.trackStartTime
  //   console.log("is video playing ", this.props.isVideoPlaying)
  //   this.context.playRegion(offset || 0)
  // }
  if(!this.state.dragging && !this.state.touchDragging){
    if(this.context.updatedLeftOffset !== this.state.leftOffset && !this.state.preventContext){
      this.setState({leftOffset: this.context.updatedLeftOffset, preventContext: false})
    }
    else{
      if(this.state.preventContext){
        setTimeout(()=>this.setState({preventContext: false}), 150)
        
      }
    }
  }
  if (this.state.dragging && !prevState.dragging) {
    document.addEventListener('mousemove', this.onMouseMove)
    document.addEventListener('mouseup', this.onMouseUp)

  } else if (!this.state.dragging && prevState.dragging) {
    document.removeEventListener('mousemove', this.onMouseMove)
    document.removeEventListener('mouseup', this.onMouseUp)

  }
  if (this.state.touchDragging && !prevState.touchDragging) {
    document.addEventListener('touchmove', this.onTouchMove)
    document.addEventListener('touchend', this.onTouchEnd)
  } else if (!this.state.touchDragging && prevState.touchDragging) {
    document.removeEventListener('touchmove', this.onTouchMove)
    document.removeEventListener('touchend', this.onTouchEnd)
  }

}

}
  onMouseDown = (e)=> {
    if(this.props.isVideoPlaying){
      // this.props.pauseVideo()
      this.context.handlePause()
      this.context.waveFormEl.seekTo(0)
      this.context.updateTime(0)
      // this.context.pause()
      this.setState({forcePaused: true})
    }
    else{
      this.context.waveFormEl.seekTo(0)
      this.context.updateTime(0)
    }

    // only left mouse button
    console.log("just e ", e)
    console.log("button pressed ", e.button)
    if (e.button !== 0) return
    let computedStyle = window.getComputedStyle(this.wrapper.current); 
    let pos = { 
      left: parseInt(computedStyle.left) 
    };
    this.setState({
      dragging: true,
      rel: {
        x: e.pageX - pos.left,
      }
    })
    e.stopPropagation() 
    e.preventDefault()
  }
  onTouchStart = (e)=>{
    if(this.context.isVideoPlaying){
      // this.props.pauseVideo()
      this.context.handlePause()
      this.wavesurfer.seekTo(0)
      // this.context.waveFormEl.seekTo(0)
      // this.context.pause()
      this.setState({forcePaused: true})
    }

    // only left mouse button
    console.log("just e ", e)
    console.log("button pressed ", e.button)
    let computedStyle = window.getComputedStyle(this.wrapper.current); 
    let pos = { 
      left: parseInt(computedStyle.left) 
    };
    this.setState({
      touchDragging: true,
      rel: {
        x: e.changedTouches[0]?.pageX - pos.left,
      }
    })
    e.stopPropagation() 
    e.preventDefault()
  }
 onMouseUp = (e) => {
  this.setState({dragging: false, preventContext: true})
  this.props.updateVideoOffset(this.state.leftOffset)
  let changeInPos = this.context.initialLeftCutoff - this.state.leftOffset;
  if(changeInPos < 0){
    changeInPos = 0
  }
  let waveWidth = this.context.videoStudioWaveWidth || this.state.waveWidth;
  let ratio = changeInPos/waveWidth
  let newStart = ratio  * this.context.waveFormEl.getDuration();
  console.log("video duration ", this.context.videoDurationSeconds, " ", this.context.trackStartTime)
  let newEnd = newStart + this.context.videoDurationSeconds - this.context.trackStartTime;
  if (newEnd > this.context.waveFormEl.getDuration()){
    newEnd = this.context.waveFormEl.getDuration()
  }
  let startFormatted = moment.duration(newStart, 'seconds').format("m:ss",{forceLength: true, trim: false})
  let endFormatted = moment.duration(Math.floor(newEnd), 'seconds').format("m:ss",{forceLength: true, trim: false})
  let region ={
    start: newStart,
    end: newEnd,
    startFormatted: startFormatted,
    endFormatted: endFormatted
  }
  console.log("6 set region")
  let updatedOffset = this.state.leftOffset
  this.context.updateRegionNoRedraw(region, updatedOffset)
  if(this.state.forcePaused){
    this.context.handlePlay()
    this.setState({forcePaused: false})
  }
  e.stopPropagation()
  e.preventDefault()
}
onTouchEnd = (e) => {
  this.setState({touchDragging: false, preventContext: true})
  this.props.updateVideoOffset(this.state.leftOffset)
  let changeInPos = this.context.initialLeftCutoff - this.state.leftOffset;
  if(changeInPos < 0){
    changeInPos = 0
  }
  let waveWidth = this.context.videoStudioWaveWidth || this.state.waveWidth;
  let ratio = changeInPos/waveWidth
  let newStart = ratio  * this.context.waveFormEl.getDuration();
  let newEnd = newStart + this.context.videoDurationSeconds - this.context.trackStartTime;
  console.log("video duration ", this.context.videoDurationSeconds, " ", this.context.trackStartTime)

  if (newEnd > this.context.waveFormEl.getDuration()){
    newEnd = this.context.waveFormEl.getDuration()
  }
  let startFormatted = moment.duration(newStart, 'seconds').format("m:ss",{forceLength: true, trim: false})
  let endFormatted = moment.duration(Math.floor(newEnd), 'seconds').format("m:ss",{forceLength: true, trim: false})
  let region ={
    start: newStart,
    end: newEnd,
    startFormatted: startFormatted,
    endFormatted: endFormatted
  }
  let updatedOffset = this.state.leftOffset
  this.context.updateRegionNoRedraw(region, updatedOffset)
  if(this.state.forcePaused){
    this.context.handlePlay()
    this.setState({forcePaused: false})
  }
  e.stopPropagation()
  e.preventDefault()
}
onMouseMove = (e) => {
  if (!this.state.dragging) return
  console.log("page x ", e.pageX, " rel x ", this.state.rel.x)
  console.log("moving element ", e.pageX - this.state.rel.x,( e.pageX - this.state.rel.x) > 117 )
  if((e.pageX - this.state.rel.x) >= this.props.initialLeftCutoff){
    
    console.log("dont move too far right")
    this.setState({
      pos: {
        x: e.pageX - this.state.rel.x,
      },
      leftOffset: e.pageX - this.state.rel.x
    })

    e.stopPropagation()
    e.preventDefault()
  }
  else{
    this.setState({
      pos: {
        x: e.pageX - this.state.rel.x,
      },
      leftOffset: e.pageX - this.state.rel.x
    })
    e.stopPropagation()
    e.preventDefault()
  }
 
}
onTouchMove = (e)=>{
  if (!this.state.touchDragging) return
  console.log("page x ", e.changedTouches[0]?.pageX, " rel x ", this.state.rel.x)
  console.log("moving element ", e.changedTouches[0]?.pageX - this.state.rel.x,( e.pageX - this.state.rel.x) > 117 )
  if((e.pageX - this.state.rel.x) >= this.props.initialLeftCutoff){
    console.log("dont move too far right")
    this.setState({
      pos: {
        x: e.changedTouches[0]?.pageX - this.state.rel.x,
      },
      leftOffset: e.changedTouches[0]?.pageX - this.state.rel.x
    })

    e.stopPropagation()
    e.preventDefault()
  }
  else{
    this.setState({
      pos: {
        x: e.changedTouches[0]?.pageX - this.state.rel.x,
      },
      leftOffset: e.changedTouches[0]?.pageX - this.state.rel.x
    })
    e.stopPropagation()
    e.preventDefault()
  }
}
  componentWillUnmount() {
    // this.props.pauseVideo()
  }

  render() {
    return (
      <>
        <div style={{width: "100%"}} className="fade-edges">
          <LoadingOverlay
            styles={{
              overlay: (base) => ({
                ...base,
                zIndex: 39
              })
            }}
            active={!this.state.ready}
            spinner={<WaveSpinner size={30} color="#fff" loading={!this.state.ready}></WaveSpinner>}
          >
            <div ref={this.wrapper} className="" style={{width: "100%", position: "absolute", left: (this.state.leftOffset || this.props.initialLeftCutoff) + "px"}} onTouchStart={this.onTouchStart}  onMouseDown={this.onMouseDown}>

              <div style={{backgroundColor: "rgba(255, 0, 0, 0.0);", width: "100%" }} className='waveform' >
                <div className='wave' style={{width: this.context.videoStudioWaveWidth || this.state.waveWidth + "px", cursor: "ew-resize"}}/>
              </div>
            </div>
          </LoadingOverlay>
        </div>
      </>
    )
  }
}

VideoStudioWaveForm.defaultProps = {
  src: "",
  peaks: ""
}

VideoStudioWaveForm.contextType = PlayerContext
export default VideoStudioWaveForm
import React, { createContext, useContext, useState } from "react";
import { withRouter } from "react-router-dom";
import { useGetLicenseProductData } from "../../components/Licenses/useGetLicenseProductData";

export const LicenseContext = createContext();

export const LicenseProvider = withRouter(({ children, props }) => {
  let licenseProductDataPersonal;
  let licenseProductDataCommercial;

  if (!licenseProductDataPersonal?.product) {
    const { data } = useGetLicenseProductData({ key: "W2" });
    licenseProductDataPersonal = data;
  }

  if (!licenseProductDataCommercial?.product) {
    const { data } = useGetLicenseProductData({ key: "X2" });
    licenseProductDataCommercial = data;
  }

  // console.log('licenseProductDataPersonal', licenseProductDataPersonal);
  // console.log('licenseProductDataCommercial', licenseProductDataCommercial);

  return (
    <LicenseContext.Provider
      value={{
        licenseProductDataPersonal,
        licenseProductDataCommercial
      }}
    >
      {children}
    </LicenseContext.Provider>
  );
});

export const LicenseConsumer = LicenseContext.Consumer;

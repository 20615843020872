import React, { Component } from "react";
import { deviceDetect } from "react-device-detect";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import 'react-tippy/dist/tippy.css';
import { FavoritesConsumer } from "../../FavoritesContext/index";
import { PlayerContext } from "../../PlayerContext";
import "../Carousels/index.css";
import Footer from '../Footer';
import CircularIndeterminant from "../Loading/circularIndeterminant";
import Pagination from "../Pagination/pagination";
import { Error, Success } from "../Toast/index";
import api from "../utils/api";
import ArtistCard from "./artistCard";
import FeaturedArtistCard from "./featuredArtistCard";
import SearchArtists from './searchArtists';
import SideBar from "./sideBar";
import SortBar from "./sortBar";

const queryStringer = require('query-string');


// TODO: Refactor this to use modal components so it doesn't duplicate code from ArtisTrackList

class BrowseArtists extends Component {
    state = {
        artists: [],
        featuredArtists: [],
        filteredArtists: {1:[]},
        filterMenuOpen: false,
        allFilters: [],
        genreOptions: [],
        emotionOptions: [],
        moodOptions: [],
        attributeOptions: [],
        instrumentOptions: [],
        cityOptions: [],
        searchOptions: [],
        keywordOptions: [],
        lastKeywordSearched: "",
        sortCriteria:  window.screen.width > 600 ? "Most Relevant": "Relevant",
        playingArtistId: undefined,
        currentPage: 1,
        artistsLoaded: false,
        totalResults: 0
    }
    loadOptions = (searchInput, options)=>{
        if(searchInput?.trim().length > 2){
            let regex = new RegExp('^' + searchInput.toLowerCase())
            let filteredFilters = this.state.allFilters.filter(option=> regex.test(option.label.toLowerCase()))
            let genreOptions = filteredFilters.filter(option=> option.type === "genre")
            let moodOptions = filteredFilters.filter(option=> option.type === "mood")
            let emotionOptions = filteredFilters.filter(option=> option.type === "emotion")
            let attributeOptions = filteredFilters.filter(option=> option.type === "attribute")
            let lyricOptions = filteredFilters.filter(option=> option.type === "lyric")
            let bpmOptions = filteredFilters.filter(option=> option.type === "bpm")
            let cityOptions = filteredFilters.filter(option=> option.type === "city")
            let instrumentOptions = filteredFilters.filter(option=> option.type === "instrument")
            return (

                    api.getArtistsByName(searchInput)
                        .then(res=>{
                            let artistOptions = []
                            let keywordOptions = []
                            if(res.data.success){
                                if(res.data.artists?.length > 0){
                                    artistOptions = res.data.artists.map(artist=>{
                                        let artistOption ={
                                            label: artist.artistName,
                                            value: artist.artistName,
                                            include: true,
                                            type: "artist",
                                            profilePhoto: artist.profilePhoto
                                        }
                                        return artistOption
                                    })
                                }

                            }
                           return  api.getKeywords(searchInput)
                                .then(res=>{
                                    if(res.data?.keywords?.length > 0){
                                        keywordOptions = res.data.keywords.map(keyword=>{
                                            let keywordOption ={
                                                label: keyword.name,
                                                value: keyword.name,
                                                include: true,
                                                type: "keyword",
                                            }
                                            return keywordOption
                                        })
                                    }
                                                return( [
                                                    {label: "Genres", options: genreOptions},
                                                    {label: "Moods", options: moodOptions},
                                                    {label: "emotions", options: emotionOptions},
                                                    {
                                                        label: "attributes",
                                                        options: attributeOptions
                                                      },
                                                      {
                                                        label: "lyrics",
                                                        options: lyricOptions
                                                      },
                                                      {
                                                        label: "bpm",
                                                        options: bpmOptions
                                                      },
                                                      {
                                                        label: "instruments",
                                                        options: instrumentOptions
                                                      },
                                                      {
                                                        label: "cities",
                                                        options: cityOptions
                                                      },
                                                      {
                                                        label: "artists",
                                                        options: artistOptions
                                                      },
                                                      {
                                                        label: "keywords",
                                                        options: keywordOptions
                                                      }

                                                ])
                                            })
                                            })


            )
        }
    }
    getArtistOptions = (name)=>{
        if(name=== this.state.lastNameSearched){
            return
        }
        let artistOptions = []
        api.getArtistsByName(name)
            .then(res=>{
                if(res.data.success){
                    res.data.artists.forEach(artist=>{
                        artistOptions.push({
                            label: artist.artistName,
                            value: artist.artistName,
                            include: true,
                            type: "artist",
                            profilePhoto: artist.profilePhoto
                        })
                    })
                    this.setState({artistOptions: artistOptions.length > 0 ? artistOptions: [{"type":"artist",}] , lastNameSearched: name})
                }
            })
    }
    getKeywordOptions = (input)=>{
        if(input=== this.state.lastKeywordSearched){
            return
        }
        let keywordOptions = []
        keywordOptions.push({
            include: true,
            type: "keyword",
        })
        api.getKeywords(input)
            .then(res=>{
                if(res.data?.keywords?.length > 0){
                    res.data.keywords.forEach(keyword=>{
                        keywordOptions.push({
                            label: keyword.name,
                            value: keyword.name,
                            include: true,
                            type: "keyword",
                        })
                    })
                    this.setState({keywordOptions: keywordOptions.length > 0 ? keywordOptions: [{
                        "include":true,"type":"keyword",}] , lastKeywordSearched: input})
                }
            })
    }
    componentDidMount = ()=>{

        // let parsedQuery = queryString.parse(this.props.location.search)
        let query = this.props.location.search.length > 0 ? this.props.location.search.substring(1) : ""
        let parsedQuery = queryStringer.parse(query)
        let searchOptions =[]
        let artistSearched;
        let genreOptions = []
        let emotionOptions = []
        let moodOptions = []
        let attributeOptions = []
        let instrumentOptions = []
        let cityOptions = []
        let lyricOptions = []
        let bmpOptions = []
        api.getUsedTags()
        .then(res=>{
            if(res.data){
                res.data.forEach(trackTag=>{
                    let tag = trackTag.tag
                    switch(tag.type){
                        case 1:
                            genreOptions.push({
                                type: "genre",
                                value: tag.id,
                                label: tag.name,
                                id: tag.id,
                                include: true
                        })
                            break;
                        case 2:
                            emotionOptions.push({
                                type: "emotion",
                                value: tag.id,
                                label: tag.name,
                                id: tag.id,
                                include: true
                            })
                            break;
                        case 3:
                            moodOptions.push({
                                type: "mood",
                                value: tag.id,
                                label: tag.name,
                                id: tag.id,
                                include: true
                            })
                            break;
                        case 4:
                            attributeOptions.push({
                                type: "attribute",
                                value: tag.id,
                                label: tag.name,
                                id: tag.id,
                                include: true
                            })
                            break;
                        case 5:
                            instrumentOptions.push({
                                type: "instrument",
                                value: tag.id,
                                label: tag.name,
                                id: tag.id,
                                include: true
                            })
                            break;
                        case 6:
                            cityOptions.push({
                                type: "city",
                                value: tag.id,
                                label: tag.name,
                                id: tag.id,
                                include: true,
                                count: trackTag.usedCount
                            })
                            break;
                        case 7:
                            lyricOptions.push({
                                type: 'lyric',
                                value: tag.id,
                                label: tag.name,
                                id: tag.id,
                                include: true
                            })
                            break;
                        case 8:
                            bmpOptions.push({
                                type: 'bpm',
                                value: tag.id,
                                label: tag.name,
                                id: tag.id,
                                include: true
                            })
                            break;
                        default:
                            break;
                    }
                })
            }
            let alphaSort = (a,b)=>{
                if (a.label > b.label) {
                    return 1;
                }
                if (b.label > a.label) {
                    return -1;
                }
                return 0;
            }
        for (const key in parsedQuery){
            switch(key){
                case "inc_gen":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "genre", label: genreOptions.filter(genre=>genre.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key]), include: true})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "genre", label: genreOptions.filter(genre=>genre.id=== parseInt(q))[0].label, value: parseInt(q), include: true})
                        })
                    }
                }
                break;
                case "exc_gen":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "genre", label: genreOptions.filter(genre=>genre.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key])})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "genre", label: genreOptions.filter(genre=>genre.id=== parseInt(q))[0].label, value: parseInt(q)})
                        })
                    }
                }
                break;
                case "inc_moo":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "mood", label: moodOptions.filter(mood=>mood.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key]), include: true})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "mood",label: moodOptions.filter(mood=>mood.id=== parseInt(q))[0].label, value: parseInt(q), include: true})
                        })
                    }
                }
                break;
                case "exc_moo":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "mood", label: moodOptions.filter(mood=>mood.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key])})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "mood", label: moodOptions.filter(mood=>mood.id=== parseInt(q))[0].label,  value: parseInt(q)})
                        })
                    }
                }
                break;
                case "inc_emo":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "emotion", label: emotionOptions.filter(emotion=>emotion.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key]), include: true})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "emotion",label: emotionOptions.filter(emotion=>emotion.id=== parseInt(q))[0].label, value: parseInt(q), include: true})
                        })
                    }
                }
                break;
                case "exc_emo":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "emotion", label: emotionOptions.filter(emotion=>emotion.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key])})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "emotion", label: emotionOptions.filter(emotion=>emotion.id=== parseInt(q))[0].label,  value: parseInt(q)})
                        })
                    }
                }
                break;
                case "inc_att":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "attribute", label: attributeOptions.filter(attribute=>attribute.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key]), include: true})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "attribute", label: attributeOptions.filter(attribute=>attribute.id=== parseInt(q))[0].label,  value: parseInt(q), include: true})
                        })
                    }
                }
                break;
                case "exc_att":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "attribute", label: attributeOptions.filter(attribute=>attribute.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key])})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "attribute",label: attributeOptions.filter(attribute=>attribute.id=== parseInt(q))[0].label,  value: parseInt(q)})
                        })
                    }
                }
                break;
                case "lyrics":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "lyric", label: lyricOptions.filter(lyric=>lyric.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key]), include: true})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "lyric", label: lyricOptions.filter(lyric=>lyric.id=== parseInt(q))[0].label, value: parseInt(q), include: true})
                        })
                    }
                }
                break;
                case "exc_lyrics":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "lyric", label: lyricOptions.filter(lyric=>lyric.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key])})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "lyric", label: lyricOptions.filter(lyric=>lyric.id=== parseInt(q))[0].label,  value: parseInt(q)})
                        })
                    }
                }
                break;
                case "inc_bmi":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "bmi", label: bmpOptions.filter(bmpOption=>bmpOption.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key]), include: true})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "bmi", label: bmpOptions.filter(bmpOption=>bmpOption.id=== parseInt(q))[0].label,  value: parseInt(q), include: true})
                        })
                    }
                }
                break;
                case "exc_bmi":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "bmi", label: bmpOptions.filter(bmpOption=>bmpOption.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key]),})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "bmi", label: bmpOptions.filter(bmpOption=>bmpOption.id=== parseInt(q))[0].label,  value: parseInt(q)})
                        })
                    }
                }
                break;
                case "inc_reg":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "city", label: cityOptions.filter(city=>city.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key]), include: true})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "city", label: cityOptions.filter(city=>city.id=== parseInt(q))[0].label,  value: parseInt(q), include: true})
                        })
                    }
                }
                break;
                case "exc_reg":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "city", label: cityOptions.filter(city=>city.id=== parseInt(parsedQuery[key]))[0].label, value: parseInt(parsedQuery[key])})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "city", label: cityOptions.filter(city=>city.id=== parseInt(q))[0].label,  value: parseInt(q)})
                        })
                    }
                }
                break;
                case "inc_key":{
                    if(typeof parsedQuery[key] ==="string"){
                        searchOptions.push({ type: "keyword", label: parsedQuery[key], value: parsedQuery[key], include: true})
                    }
                    else{
                        parsedQuery[key].forEach(q=>{
                            searchOptions.push({type: "keyword", label: q, value: q, include: true})
                        })
                    }
                }
                break;
                case "artist":{
                    artistSearched = true
                 }
                default:
                 break;
            }
        }
        let countSort = ((a,b)=>{
            if(a.count < b.count){
                return 1
            }
            else if( a.count > b.count){
                return -1
            }
            else {
                return 0
            }
        })

        this.setState({genreOptions: genreOptions.sort(alphaSort), emotionOptions: emotionOptions.sort(alphaSort), moodOptions: moodOptions.sort(alphaSort), attributeOptions: attributeOptions.sort(alphaSort), lyricOptions: lyricOptions.sort(alphaSort),
        instrumentOptions: instrumentOptions.sort(alphaSort), cityOptions: cityOptions.sort(countSort), allFilters: genreOptions.concat(emotionOptions.sort(alphaSort), moodOptions.sort(alphaSort), attributeOptions.sort(alphaSort), instrumentOptions.sort(alphaSort), cityOptions.sort(countSort),lyricOptions.sort(alphaSort), bmpOptions.sort(alphaSort))})
        })

        api.getAllArtists(query)
            .then(res=>{
                if(res.data.success){
                    let artists = [...res.data.artists]
                    let artistOptions = []
                    artistOptions.push({
                        include: true,
                        type: "artist",

                    })
                    let keywordOptions = []
                    keywordOptions.push({
                        include: true,
                        type:"keyword",

                    })
                    if(artistSearched){
                        searchOptions.push({
                            include: true,
                            label: artists[0].artistName,
                            profilePhoto: artists[0].profilePhoto,
                            type: "track",
                            value: artists[0].artistName
                        })
                    }
                    let toGetTracks = []
                    artists.forEach((artist, index)=>{
                        artist.tracks.forEach((track,ind)=>{
                            if(ind === 0){
                                toGetTracks.push(track.trackId)
                            }
                            let parsedTags = track.tags
                            artists[index].tracks[ind].tags = parsedTags
                            if(artist.mostRecent){
                                if(track.initialApprovalDate > artist.mostRecent){
                                    artists[index].mostRecent = track.initialApprovalDate
                                }
                            }
                            else{
                                artists[index].mostRecent = track.initialApprovalDate
                            }
                        })

                        let compare = (a, b)=> {
                            const scoreA = a.score;
                            const scoreB = b.score;

                            let comparison = 0;
                            if (scoreA > scoreB) {
                              comparison = 1;
                            } else if (scoreA < scoreB) {
                              comparison = -1;
                            }
                            return comparison *-1;
                          }

                        let featuredOrder = artist.tracks.concat().sort(compare).slice(0,5)
                        artists[index].featuredOrder = featuredOrder
                    })

                    this.context.getTracksInfo(toGetTracks)
                    let featured = res.data.featured || []

                    let filteredArtists =[...artists].map(artist=>{
                        artist.filteredTracks = [...artist.tracks]
                        return artist
                    })
                    let compare = (a, b)=> {
                        // Use toUpperCase() to ignore character casing
                        const aScore = a.artistScore
                        const bScore = b.artistScore

                        let comparison = 0;
                        if (aScore < bScore) {
                          comparison = 1;
                        } else if (aScore > bScore) {
                          comparison = -1;
                        }
                        return comparison;
                      }
                      filteredArtists = filteredArtists.sort(compare)

                    this.setState({artists: [...artists], filteredArtists: {1: filteredArtists}, currentPage: 1, artistsLoaded: true, featuredArtists: featured, artistOptions: artistOptions, searchOptions: searchOptions, keywordOptions: keywordOptions, totalResults: res.data.totalFound}, ()=>{
                    })
                }
            })

    }
    playTracks = (tracks, artistId)=>{
        let playList = tracks
        playList.forEach((track, index)=>{
            playList[index].idKey = "browseArtistCard-" + artistId + "-trackId-" + track.trackId
        })
        this.context.setPlaylist(playList)
        this.context.loadTrackInMediaPlayer(playList[0].trackId, playList[0].idKey)
        this.context.setAutoPlay(true)
        this.setState({showArtistTippy: false, playingArtistId: artistId})
    }
    playFeaturedTracks = (tracks, artistId)=>{
        let playList = tracks
        playList.forEach((track, index)=>{
            playList[index].idKey = "browseFeaturedArtistCard-" + artistId + "-trackId-" + track.trackId
        })
        this.context.setPlaylist(playList)
        this.context.loadTrackInMediaPlayer(playList[0].trackId, playList[0].idKey)
        this.context.setAutoPlay(true)
        this.setState({showArtistTippy: false, playingArtistId: artistId})
    }
    pauseTrack = (artistId)=>{
        this.context.playPause();
    }

    toggleFilterMenu = ()=>{
        this.setState({filterMenuOpen: !this.state.filterMenuOpen})
    }
    toggleMobileFilter = (filter)=>{
        this.setState({[`isMobile${filter}Open`]: !this.state[`isMobile${filter}Open`]})
    }
    toggleFilter = (filter)=>{
        this.setState({[`is${filter}Open`]: !this.state[`is${filter}Open`]})
    }
    toggleSortTippy = ()=>{
        this.setState({isSortTippyOpen: !this.state.isSortTippyOpen})
    }
    toggleArtistCardTippy = (artistId)=>{
        this.setState({[`isArtistCardTippyOpen${artistId}`]: !this.state[`isArtistCardTippyOpen${artistId}`]})
    }
    toggleFeaturedCardTippy = (artistId)=>{
        this.setState({[`isFeaturedCardTippyOpen${artistId}`]: !this.state[`isFeaturedCardTippyOpen${artistId}`]})
    }
    shareArtist = (artistId, artistURL)=>{
        var textArea = document.createElement("textarea");

        // Place in top-left corner of screen regardless of scroll position.
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em';
        textArea.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = 0;

        // Clean up any borders.
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';

        // Avoid flash of white box if rendered for any reason.
        textArea.style.background = 'transparent';

        if(window.location.port){
            textArea.value = window.location.protocol + window.location.hostname + ":" + window.location.port + "/artists/" +artistURL;
        }
        else{
            textArea.value = window.location.protocol + window.location.hostname + "/artists/" +artistURL;
        }

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          var successful = document.execCommand('copy');
          if(successful){
              Success({message: "Artist URL Copied to Clipboard"})
          }
          else{
              Error({message: "Couldn't Copy Artist URL "})
          }
        } catch (err) {
        }

        document.body.removeChild(textArea);

        this.setState({[`isArtistCardTippyOpen${artistId}`]: false})

    }
    shareFeatured = (artistId, artistURL)=>{
        var textArea = document.createElement("textarea");

        // Place in top-left corner of screen regardless of scroll position.
        textArea.style.position = 'fixed';
        textArea.style.top = 0;
        textArea.style.left = 0;

        // Ensure it has a small width and height. Setting to 1px / 1em
        // doesn't work as this gives a negative w/h on some browsers.
        textArea.style.width = '2em';
        textArea.style.height = '2em';

        // We don't need padding, reducing the size if it does flash render.
        textArea.style.padding = 0;

        // Clean up any borders.
        textArea.style.border = 'none';
        textArea.style.outline = 'none';
        textArea.style.boxShadow = 'none';

        // Avoid flash of white box if rendered for any reason.
        textArea.style.background = 'transparent';

        if(window.location.port){
            textArea.value = window.location.protocol + window.location.hostname + ":" + window.location.port + "/artists/" +artistURL;
        }
        else{
            textArea.value = window.location.protocol + window.location.hostname + "/artists/" +artistURL;
        }

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
          var successful = document.execCommand('copy');
          if(successful){
              Success({message: "Artist URL Copied to Clipboard"})
          }
          else{
              Error({message: "Couldn't Copy Artist URL "})
          }
        } catch (err) {
        }

        document.body.removeChild(textArea);

        this.setState({[`isFeaturedCardTippyOpen${artistId}`]: false})

    }
    sortBy = (criteria, overRide)=>{
        if(criteria !== this.state.sortCriteria || overRide){
            let unsortedArtist = [...this.state.filteredArtists]
            let sorted
            let compare
            switch(criteria){
                case "Most Relevant":
                        compare = (a, b)=> {
                            // Use toUpperCase() to ignore character casing
                            const aScore = a.score
                            const bScore = b.score

                            let comparison = 0;
                            if (aScore < bScore) {
                              comparison = 1;
                            } else if (aScore > bScore) {
                              comparison = -1;
                            }
                            return comparison;
                          }
                        if(window.screen.width < 600){
                            criteria = "Relevant"
                        }
                        sorted = unsortedArtist.sort(compare)
                        break;
                case "Relevant":
                    compare = (a, b)=> {
                        // Use toUpperCase() to ignore character casing
                        const aScore = a.score
                        const bScore = b.score

                        let comparison = 0;
                        if (aScore < bScore) {
                          comparison = 1;
                        } else if (aScore > bScore) {
                          comparison = -1;
                        }
                        return comparison;
                      }
                    sorted = unsortedArtist.sort(compare)
                    break;
                case "Recently Added":
                        compare = (a, b)=> {
                            // Use toUpperCase() to ignore character casing
                            const aMostRecent = a.mostRecent
                            const bMostRecent = b.mostRecent

                            let comparison = 0;
                            if (aMostRecent < bMostRecent) {
                              comparison = 1;
                            } else if (aMostRecent > bMostRecent) {
                              comparison = -1;
                            }
                            return comparison;
                          }
                        if(window.screen.width < 600){
                            criteria = "Recent"
                        }
                        sorted = unsortedArtist.sort(compare)
                        break;
                case "Recent":
                    compare = (a, b)=> {
                        // Use toUpperCase() to ignore character casing
                        const aMostRecent = a.mostRecent
                        const bMostRecent = b.mostRecent

                        let comparison = 0;
                        if (aMostRecent < bMostRecent) {
                          comparison = 1;
                        } else if (aMostRecent > bMostRecent) {
                          comparison = -1;
                        }
                        return comparison;
                      }
                    sorted = unsortedArtist.sort(compare)
                    break;
                case "Name - Asc":
                    compare = (a, b)=> {
                        // Use toUpperCase() to ignore character casing
                        const aName = a.artistName.toUpperCase();
                        const bName = b.artistName.toUpperCase();

                        let comparison = 0;
                        if (aName < bName) {
                          comparison = -1;
                        } else if (aName > bName) {
                          comparison = 1;
                        }
                        return comparison;
                      }
                    if(window.screen.width < 600){
                        criteria = "Name - A"
                    }
                    sorted = unsortedArtist.sort(compare)
                    break;
                case "Name - A":
                    compare = (a, b)=> {
                        // Use toUpperCase() to ignore character casing
                        const aName = a.artistName.toUpperCase();
                        const bName = b.artistName.toUpperCase();

                        let comparison = 0;
                        if (aName < bName) {
                          comparison = -1;
                        } else if (aName > bName) {
                          comparison = 1;
                        }
                        return comparison;
                      }
                    sorted = unsortedArtist.sort(compare)
                    break;
                case "Name - Desc":
                    compare = (a, b)=> {
                        // Use toUpperCase() to ignore character casing
                        const aName = a.artistName.toUpperCase();
                        const bName = b.artistName.toUpperCase();

                        let comparison = 0;
                        if (aName < bName) {
                          comparison = -1;
                        } else if (aName > bName) {
                          comparison = 1;
                        }
                        return comparison*-1;
                      }
                    if(window.screen.width < 600){
                        criteria = "Name - D"
                    }
                    sorted = unsortedArtist.sort(compare)
                    break;
                case "Name - D":
                    compare = (a, b)=> {
                        // Use toUpperCase() to ignore character casing
                        const aName = a.artistName.toUpperCase();
                        const bName = b.artistName.toUpperCase();

                        let comparison = 0;
                        if (aName < bName) {
                          comparison = -1;
                        } else if (aName > bName) {
                          comparison = 1;
                        }
                        return comparison*-1;
                      }
                    sorted = unsortedArtist.sort(compare)
                    break;
                case "Followers - Asc":
                    compare = (a, b)=> {
                        // Use toUpperCase() to ignore character casing
                        const aFollowers = a.followerCount
                        const bFollowers = b.followerCount

                        let comparison = 0;
                        if (aFollowers < bFollowers) {
                          comparison = 1;
                        } else if (aFollowers > bFollowers) {
                          comparison = -1;
                        }
                        return comparison;
                      }
                    if(window.screen.width < 600){
                        criteria = "Followers - A"
                    }
                    sorted = unsortedArtist.sort(compare)
                    break;
                case "Followers - A":
                    compare = (a, b)=> {
                        // Use toUpperCase() to ignore character casing
                        const aFollowers = a.followerCount
                        const bFollowers = b.followerCount

                        let comparison = 0;
                        if (aFollowers < bFollowers) {
                          comparison = 1;
                        } else if (aFollowers > bFollowers) {
                          comparison = -1;
                        }
                        return comparison;
                      }
                    sorted = unsortedArtist.sort(compare)
                    break;
                case "Followers - Desc":
                    compare = (a, b)=> {
                        // Use toUpperCase() to ignore character casing
                        const aFollowers = a.followerCount
                        const bFollowers = b.followerCount

                        let comparison = 0;
                        if (aFollowers < bFollowers) {
                          comparison = 1;
                        } else if (aFollowers > bFollowers) {
                          comparison = -1;
                        }
                        return comparison *-1;
                      }
                    if(window.screen.width < 600){
                        criteria = "Follwers - D"
                    }
                    sorted = unsortedArtist.sort(compare)
                    break;
                case "Followers - D":
                    compare = (a, b)=> {
                        // Use toUpperCase() to ignore character casing
                        const aFollowers = a.followerCount
                        const bFollowers = b.followerCount

                        let comparison = 0;
                        if (aFollowers < bFollowers) {
                          comparison = 1;
                        } else if (aFollowers > bFollowers) {
                          comparison = -1;
                        }
                        return comparison *-1;
                      }
                    sorted = unsortedArtist.sort(compare)
                    break;
                default:
                      sorted = unsortedArtist
            }
            this.setState({sortCriteria: criteria, isSortTippyOpen: false, filteredArtists: {1: sorted}, currentPage: 1})
        }
        else{
            this.setState({isSortTippyOpen: false, currentPage: 1})
        }
    }
    addSearchOption = (label, value, type, id)=>{

        let searchOptions = [...this.state.searchOptions];
        switch(type){
            case "lyric":
                let existingLyric  = searchOptions.findIndex(searchOption=> searchOption.type === "lyric" && searchOption.include)
                if(existingLyric === -1){
                    let removeIndex = searchOptions.findIndex(searchOption => searchOption.value === value)
                    searchOptions.splice(removeIndex, 1)
                    searchOptions.push({ type: type, label: label, value: value, include: true, id: id})
                    this.handleSearchChange(searchOptions)
                }
                else{
                    if(searchOptions[existingLyric].include && value === searchOptions[existingLyric].value){
                        return
                    }
                    else{
                        searchOptions.splice(existingLyric, 1, {type: type, label: label, value: value, include: true})
                        let removeIndex = searchOptions.findIndex(searchOption => searchOption.value === value && !searchOption.include)
                        if(removeIndex !== -1){
                            searchOptions.splice(removeIndex, 1)
                        }
                        this.handleSearchChange(searchOptions)
                    }
                }
            break;
            case "bpm":
                let existingBpm  = searchOptions.findIndex(searchOption=> searchOption.type === "bpm" && searchOption.include)
                if(existingBpm === -1){
                    let removeIndex = searchOptions.findIndex(searchOption => searchOption.value === value)
                    searchOptions.splice(removeIndex, 1)
                    searchOptions.push({ type: type, label: label, value: value, include: true, id: id})
                    this.handleSearchChange(searchOptions)
                }
                else{
                    if(searchOptions[existingBpm].include && value === searchOptions[existingBpm].value){
                        return
                    }
                    else{
                        searchOptions.splice(existingBpm, 1, {type: type, label: label, value: value, include: true})
                        let removeIndex = searchOptions.findIndex(searchOption => searchOption.value === value && !searchOption.include)
                        if(removeIndex !== -1){
                            searchOptions.splice(removeIndex, 1)
                        }
                        this.handleSearchChange(searchOptions)
                    }
                }
            break;
            case "city":
                //find the index of an included city
                let existingCity  = searchOptions.findIndex(searchOption=> (searchOption.type === "city" && searchOption.include))
                //if there is no included city
                if(existingCity === -1){
                    //see if the specific city is already tagged
                    let removeIndex = searchOptions.findIndex(searchOption => searchOption.value === value)
                    //if so, remove it from the search
                    if(removeIndex !== -1){
                        searchOptions.splice(removeIndex, 1)
                    }
                    searchOptions.push({ type: type, label: label, value: value, include: true})
                    this.handleSearchChange(searchOptions)
                }
                else{
                    //else test to see if the existing included city was just selected
                    if(searchOptions[existingCity].include && value === searchOptions[existingCity].value){
                        return
                    }
                    //if not
                    else{

                        searchOptions.splice(existingCity, 1, {type: type, label: label, value: value, include: true})
                        let removeIndex = searchOptions.findIndex(searchOption => searchOption.value === value && !searchOption.include)
                        if(removeIndex !== -1){
                            searchOptions.splice(removeIndex, 1)
                        }
                        this.handleSearchChange(searchOptions)
                    }
                }
            break;
            case "artist":
                let existingArtist = searchOptions.findIndex(searchOption=> (searchOption.type === "artist"))
                if(existingArtist === -1){
                    searchOptions.push({ type: type, label: label, value: value, include: true, id: id})
                    this.handleSearchChange(searchOptions)
                }else{
                    searchOptions.splice(existingArtist, 1, { type: type, label: label, value: value, include: true})
                    this.handleSearchChange(searchOptions)
                }
            break;
            default:
                let existingIndex = searchOptions.findIndex(searchOption=> searchOption.value === value)

                if(existingIndex === -1){
                    searchOptions.push({ type: type, label: label, value: value, include: true, id: id})
                    this.handleSearchChange(searchOptions)
                }
                else{
                    if(searchOptions[existingIndex].include){
                        return
                    }
                    else{
                        searchOptions[existingIndex].include = true
                        this.handleSearchChange(searchOptions)
                    }
                }
    }
    }
    excludeSearchOption = (label, value, type, id)=>{
        let searchOptions = [...this.state.searchOptions];
        let existingIndex = searchOptions.findIndex(searchOption=> searchOption.value === value)
        if(existingIndex === -1){
            searchOptions.push({ type: type, label: label, value: value, include: false, id: id})
            this.handleSearchChange(searchOptions)
        }
        else{
            if(!searchOptions[existingIndex].include){
                return
            }
            else{
                searchOptions[existingIndex].include = false
                this.handleSearchChange(searchOptions)
            }
        }
    }
    handleSearchChange = (selectedSearchOptions)=>{

        if(selectedSearchOptions){
            if(selectedSearchOptions.length > 0){

                            let queryString;
                            selectedSearchOptions.forEach((option, index)=>{
                                if(option.__isNew__){
                                    selectedSearchOptions[index].type= "keyword"
                                    selectedSearchOptions[index].include = true
                                }
                            })
                            let keywords = selectedSearchOptions.filter((searchOption => searchOption.__isNew__))
                            queryString = Object.keys(selectedSearchOptions).map(key=>{
                                switch(selectedSearchOptions[key].type){
                                    case "genre":
                                        return  (selectedSearchOptions[key].include ? "inc_gen=" : "exc_gen=") + encodeURIComponent(selectedSearchOptions[key].value)
                                    case "mood":
                                        return  (selectedSearchOptions[key].include ? "inc_moo=" : "exc_moo=") + encodeURIComponent(selectedSearchOptions[key].value)
                                    case "emotion":
                                        return  (selectedSearchOptions[key].include ? "inc_emo=" : "exc_emo=") + encodeURIComponent(selectedSearchOptions[key].value)
                                    case "attribute":
                                        return  (selectedSearchOptions[key].include ? "inc_att=" : "exc_att=") + encodeURIComponent(selectedSearchOptions[key].value)
                                    case "lyric":
                                        return  (selectedSearchOptions[key].include ? "lyrics=" : "exc_lyrics=") + encodeURIComponent(selectedSearchOptions[key].value)
                                    case "bpm":
                                        return  (selectedSearchOptions[key].include ? "inc_bmi=" : "exc_bmi=") + encodeURIComponent(selectedSearchOptions[key].value)
                                    case "instrument":
                                        return  (selectedSearchOptions[key].include ? "inc_ins=" : "exc_ins=") + encodeURIComponent(selectedSearchOptions[key].value)
                                    case "city":
                                        return  (selectedSearchOptions[key].include ? "inc_reg=" : "exc_reg=") + encodeURIComponent(selectedSearchOptions[key].value)
                                    case "keyword":
                                        return "inc_key="+ encodeURIComponent(selectedSearchOptions[key].value)
                                    case "artist":
                                        return "artist=" + encodeURIComponent(selectedSearchOptions[key].value)
                                    default:
                                        return ""
                                }
                            }).join('&')
                            this.setState({searchOptions: selectedSearchOptions, artistsLoaded: false},()=>{
                                api.getAllArtists(queryString)
                                    .then(res=>{
                                        let artists = res.data.artists

                                        if(selectedSearchOptions.length > 1){
                                            let query = this.props.location.search.length > 0 ? this.props.location.search.substring(1) : ""
                                            let parsedQuery = queryStringer.parse(query)
                                            let allTracks = []
                                            let toGetTracks = []

                                            artists.forEach((artist,index)=>{
                                                artist.tracks.forEach((track, ind)=>{
                                                    if(ind === 0){
                                                        toGetTracks.push(track.trackId)
                                                    }
                                                    allTracks.push(track.trackId)
                                                    if(artist.mostRecent){
                                                        if(track.initialApprovalDate > artist.mostRecent){
                                                            artists[index].mostRecent = track.initialApprovalDate
                                                        }
                                                    }
                                                    else{
                                                        artists[index].mostRecent = track.initialApprovalDate
                                                    }
                                                })
                                            })

                                            this.context.getTracksInfo(toGetTracks)
                                            let searchBody={
                                                tagsIncluded: selectedSearchOptions.filter(selectedOption=>selectedOption.include).map(selected=>selected.value),
                                                tagsExcluded: selectedSearchOptions.filter(selectedOption=>!selectedOption.include).map(selected=>selected.value),
                                                keywords: keywords.map(key=>key.value),
                                                results: allTracks,
                                                deviceInfo: deviceDetect(),
                                                url: queryString,
                                                previousSearch: parsedQuery.searchId ? parseInt(parsedQuery.searchId) : null,
                                                referrer: this.props.location.state ? this.props.location.state.from : this.props.location.pathname
                                            }
                                            api.saveNewSearch(searchBody)
                                                .then(searchRes=>{
                                                    if (searchRes.data.success){
                                                        queryString +=`&searchId=${searchRes.data.search.id}`

                                                        if(queryString.indexOf('artist=') !==-1){
                                                            this.props.history.push({
                                                                pathname: "/artists/"+ res.data.artists[0]?.artistURL
                                                            })
                                                        }
                                                        else{
                                                            this.props.history.push({
                                                                search:queryString,
                                                            })
                                                        }
                                                        // window.history.pushState(queryString, "Artist Browse", )
                                                    }
                                                })
                                        }
                                        else{
                                            let toGetTracks = []
                                            artists.forEach((artist,index)=>{
                                                toGetTracks.push(artist.tracks[0].trackId)

                                            })
                                            this.context.getTracksInfo(toGetTracks)
                                            if(queryString.indexOf('artist=') !==-1){
                                                this.props.history.push({
                                                    pathname: "/artists/"+ res.data.artists[0]?.artistURL
                                                })
                                            }
                                            else{
                                                this.props.history.push({
                                                    search:queryString,
                                                })
                                            }
                                        }
                                        this.setState({filteredArtists: {1: artists},currentPage: 1, artistsLoaded: true, sortCriteria: "Most Relevant", totalResults: res.data.totalFound})
                                    })
                                })
            }
            else {
                window.history.pushState([], "Artist Browse","artists?")
                this.setState({searchOptions: [], artistsLoaded: false})

                api.getAllArtists()
                .then(res=>{
                    let artists = res.data.artists
                    let toGetTracks = []

                    artists.forEach((artist,index)=>{
                        artist.tracks.forEach((track, ind)=>{
                            if(ind === 0){
                                toGetTracks.push(track.trackId)
                            }
                            if(artist.mostRecent){
                                if(track.initialApprovalDate > artist.mostRecent){
                                    artists[index].mostRecent = track.initialApprovalDate
                                }
                            }
                            else{
                                artists[index].mostRecent = track.initialApprovalDate
                            }
                        })
                    })
                    this.context.getTracksInfo(toGetTracks)
                    this.setState({searchOptions: [], filteredArtists: {1: res.data.artists}, currentPage: 1, artistsLoaded: true, totalResults: res.data.totalFound})
                })
            }
        }
        else {

            window.history.pushState([], "Artist Browse","artists?")
            this.setState({searchOptions: [], artistsLoaded: false})

            api.getAllArtists()
            .then(res=>{
                let artists = res.data.artists
                let toGetTracks = []


                artists.forEach((artist,index)=>{
                    artist.tracks.forEach((track, ind)=>{
                        if(ind === 0){
                            toGetTracks.push(track.trackId)
                        }
                        if(artist.mostRecent){
                            if(track.initialApprovalDate > artist.mostRecent){
                                artists[index].mostRecent = track.initialApprovalDate
                            }
                        }
                        else{
                            artists[index].mostRecent = track.initialApprovalDate
                        }
                    })
                })
                this.context.getTracksInfo(toGetTracks)
                this.setState({searchOptions: [], filteredArtists: {1: res.data.artists}, currentPage: 1, artistsLoaded: true, totalResults: res.data.totalFound})
            })
        }
    }

    onPageChanged = data => {
        const { filteredArtists } = this.state;
        const { currentPage, totalPages, pageLimit } = data;
        // const searchBarEl = document.getElementById("trackSearch")
        // searchBarEl.scrollIntoView()
        const offset = (currentPage - 1) * pageLimit;
        // const currentFilteredTracks = filteredTracks.slice(offset, offset + pageLimit);
        // this.context.setPlaylist(this.state.filteredTracks[currentPage])
        this.setState({ currentPage,  totalPages });
      }
    gotoPage = (page, totalPages) => {
        // const { onPageChanged = f => f } = this.props;
        const currentPage = Math.max(0, Math.min(page, totalPages));
        console.log("page ", page , totalPages)
        let query = this.props.location.search.length > 0 ? this.props.location.search.substring(1) : ""
        if(query.length > 0){
            query = query.replace(/\&page\=\d/g, "")
            query = query.replace(/page\=\d/g, "")
            if(query.length > 0){
                query+= "&page=" + page
            }
            else{
                query+= "page=" + page
            }
        }
        else{
            query+= "page=" + page
        }
        this.props.history.push({
            search: query,
        })
        const paginationData = {
          currentPage,
          totalPages: totalPages,
          pageLimit: 18,
          totalRecords: this.state.totalResults
        };
        if(this.state.filteredArtists[currentPage]?.length > 0){
            this.setState({ currentPage }, () => this.onPageChanged(paginationData));
        }
        else{
            this.setState({artistsLoaded: false}, ()=>{
                api.getAllArtists(query)
                    .then(res=>{
                        if(res.data){
                            let artists = res.data.artists
                            let toGetTracks = []
                            artists.forEach((artist,index)=>{
                                toGetTracks.push(artist.tracks[0].trackId)

                            })
                            this.context.getTracksInfo(toGetTracks)
                            this.setState(prevState=>({...prevState, filteredArtists: {...prevState.filteredArtists, [currentPage]: artists}, totalResults: res.data.totalFound, artistsLoaded: true, currentPage: currentPage}),()=>{
                                this.setState({  }, () => this.onPageChanged(paginationData));
                            })

                        }
                    })
            })
        }
      }
    play=(tracks, idKey)=>{
        this.context.loadTrackInMediaPlayer(tracks[0].trackId, idKey )
        this.context.setPlaylist(tracks.map(track=>track.trackId))
    }
    render () {
        const settings = {
            arrows: false,
            swipeToSlide: true,
            infinite: true,
            variableWidth: true,
            slidesToShow: 2,
            edgeFriction: '.05',
            adaptiveHeight: true,
            draggable: true,
        }

        return (
            <FavoritesConsumer>
                {({artists, tracks, albums, collections, likeTrack, unlikeTrack, followArtist, unfollowArtist})=>(
                    <>
                      <Helmet>
                        <title>Artists - Deep Sounds | Music for Creators</title>
                        <meta name="description" content="Check out our incredible roster of independent artists." />
                      </Helmet>
                        {/* <div className="app-container"> */}
                        <SideBar filters={this.state.allFilters} filterMenuOpen={this.state.filterMenuOpen} toggleFilterMenu={this.toggleFilterMenu} toggleFilter={this.toggleFilter} isGenresOpen={this.state.isGenresOpen} isMoodsOpen={this.state.isMoodsOpen} isAttributesOpen={this.state.isAttributesOpen} isInstrumentsOpen={this.state.isInstrumentsOpen}
                                isEmotionOpen={this.state.isEmotionOpen} isLyricsOpen={this.state.isLyricsOpen} isBPMOpen={this.state.isBPMOpen} isCitiesOpen={this.state.isCitiesOpen} addSearchOption={this.addSearchOption} excludeSearchOption={this.excludeSearchOption} selectedSearchOptions={this.state.searchOptions}/>

                        <div className="app-main">

                            {/* {# Featured Collections #} */}
                            {!this.state.artistsLoaded ?
                                <div style={{margin: "240px auto", width: "50%"}}>
                                    <CircularIndeterminant size={40} forceCenter={true}/>
                                </div>
                                :
                                <>
                                <div className="featured-carousel my-30 lg:my-50 xl:my-80">
                                    <div className="container">
                                        <div className="grid-row-sm">
                                            <div className="col w-full xl:w-1/3">
                                                <div className="featured-carousel-header">
                                                    <h2 className="featured-title">
                                                        <span className="inline-block xl:block">Featured&nbsp;</span>
                                                        <span className="inline-block xl:block">Artists</span>
                                                    </h2>
                                                    <p className="featured-desc">
                                                        Check out our incredible roster of independent artists.
                                                    </p>
                                                    <div className="ui-control-arrows flex">
                                                        <button onClick={(e)=>{e.preventDefault();this.slider.slickPrev()}}  className="circle-icon-border lg:circle-icon-lg icon-chevron-left mr-15"></button>
                                                        <button onClick={(e)=>{e.preventDefault();this.slider.slickNext()}} className="circle-icon-border lg:circle-icon-lg icon-chevron-right"></button>
                                                    </div>
                                                </div>
                                            </div>

                                                <div className="col w-full xl:w-2/3">
                                                    <Slider ref={slider => (this.slider = slider)} {...settings} className="card-carousel">
                                                        {this.state.featuredArtists.map((artist, index)=>
                                                            <div key={"featuredArtist" + artist.artistName}  className="carousel-slide" >
                                                                <FeaturedArtistCard {...artist} idKey={`artist-${artist.artistId}-album-${this.context.loadedTracks[artist.tracks[0]?.trackId]?.albumId}-track-${artist.tracks[0]?.trackId}-element-featuredArtistItemCard-${artist.artistId}`} playTracks={this.play} pauseTrack={this.pauseTrack}  isPlaying={(this.context.mediaPlayer.isTrackPlaying || this.context.isVideoPlaying)}
                                                                    contextIdKey={this.context.mediaPlayer.idKey} toggleFeaturedTippy={this.toggleFeaturedCardTippy} isArtistTippyOpen={this.state[`isFeaturedCardTippyOpen${artist.artistId}`]} shareArtist={this.shareFeatured} play={this.play} handlePlay={this.context.handlePlay} handlePause={this.context.handlePause} />
                                                            </div>
                                                        )}
                                                    </Slider>
                                                </div>
                                        </div>
                                    </div>
                                </div>

                            <div className="container">
                            {/* // {# Search #} */}
                            <SearchArtists loadOptions={this.loadOptions} filters={this.state.allFilters} filterMenuOpen={this.state.filterMenuOpen} toggleFilterMenu={this.toggleFilterMenu} toggleMobileFilter={this.toggleMobileFilter} isGenresOpen={this.state.isMobileGenresOpen} isMoodsOpen={this.state.isMobileMoodsOpen} isAttributesOpen={this.state.isMobileAttributesOpen}
                                isEmotionOpen={this.state.isMobileEmotionOpen} isLyricsOpen={this.state.isMobileLyricsOpen} isBPMOpen={this.state.isMobileBPMOpen} isCitiesOpen={this.state.isMobileCitiesOpen} selectedSearchOptions={this.state.searchOptions} searchOptions={this.state.allFilters} handleSearchChange={this.handleSearchChange}
                                addSearchOption={this.addSearchOption} excludeSearchOption={this.excludeSearchOption} artistOptions={this.state.artistOptions} getArtistOptions={this.getArtistOptions} keywordOptions={this.state.keywordOptions} getKeywordOptions={this.getKeywordOptions}/>
                            {/* {# Sort Bar #} */}
                            <SortBar sortCriteria={this.state.sortCriteria} resultsText={window.screen.width > 600 ? `Showing ${this.state.filteredArtists[this.state.currentPage].length} Artists`: `${this.state.filteredArtists[this.state.currentPage].length} Artists` } sortBarId={"artist_sort"} marginClasses={"mb-20 md:mb-30"} toggleSortTippy={this.toggleSortTippy} isSortTippyOpen={this.state.isSortTippyOpen} sortBy={this.sortBy} artistBrowse={true}/>
                            {/* // {# Artist Cards #} */}
                                    {/* {% for artist in artists.slice(5,15) %} */}
                                    {!this.state.artistsLoaded ?
                                        <CircularIndeterminant size={40} forceCenter={true}/>
                                        :
                                    <>
                                    {this.state.filteredArtists[this.state.currentPage].length > 0 ?
                                        <div className="grid-row mb-40">
                                            {this.state.filteredArtists[this.state.currentPage].map((artist, index)=>
                                              <div
                                                key={"filteredArtist" + artist.artistName + index}
                                                className="col w-1/2 lg:w-1/3 mb-20 md:mb-40"
                                              >
                                                  <ArtistCard artistId={artist.artistId}  idKey={`artist-${artist.artistId}-album-${this.context.loadedTracks[artist.tracks[0]?.trackId]?.albumId}-track-${artist.tracks[0]?.trackId}-element-artistItemCard-${artist.artistId}`} profilePhoto={artist.profilePhoto} totalTracks={artist.totalTracks} artistName={artist.artistName} artistURL={artist.artistURL} tracks={artist.tracks} albums={artist.albums} primaryGenre={artist.primaryGenreName} secondaryGenre={artist.secondaryGenreName} following={artists?.filter(fav=>fav.artistId === artist.artistId).length === 1} followArtist={followArtist} unfollowArtist={unfollowArtist}
                                                      isPlaying={(this.context.mediaPlayer.isTrackPlaying || this.context.isVideoPlaying)} playTracks={this.playTracks} toggleArtistCardTippy={this.toggleArtistCardTippy} isArtistTippyOpen={this.state[`isArtistCardTippyOpen${artist.artistId}`]} shareArtist={this.shareArtist} searchTerms={this.state.searchOptions.length}
                                                      contextIdKey={this.context.mediaPlayer.idKey} play={this.play} handlePlay={this.context.handlePlay} handlePause={this.context.handlePause} />
                                              </div>
                                            )}
                                        </div>
                                     : this.state.artistsLoaded ?<div className="track-list-container mb-40 text-h4 text-white" style={{textAlign: "center", padding: "60px"}}>
                                            <p>Sorry, your search resulted in no matches.</p>
                                        </div>: <></>}
                                    </>
                                    }
                                    {/* {% endfor %} */}

                            {/* // {# Pagination #} */}
                                <Pagination pageLimit={18} currentPage={this.state.currentPage} totalRecords={this.state.totalResults} gotoPage={this.gotoPage}/>

                            </div>
                            </>
                            }
                            <Footer/>
                        </div>
                    {/* </div> */}
                    </>
                )}
            </FavoritesConsumer>
        );
    }

}
BrowseArtists.contextType = PlayerContext

export default BrowseArtists

import moment from "moment";

const getUserProps = (user) => {
  let userProps = {};

  // console.log('intercom user', user);

  if (user?.userHash) {
    const createdOn = user?.createdOn;
    let createdAt = null;
    createdAt = moment(createdOn).format("X");

    userProps = {
      userId: user.user_id?.toString(),
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
      createdAt: createdAt,
      userHash: user.userHash,
      customAttributes: {
        admin: user.admin,
        artist: user.artist,
        artist_name: user.artistName,
        confirmed: user.confirmed,
        customer: user.customer,
        incomplete: user.incomplete,
        manager: user.manager,
        rep: user.rep,
        signup_status: user.signupStatus,
        stripe_connected: user.stripeConnected,
        user_URL: user.userUrl,
        subscription_learn_more_type: user.subscriptionLearnMoreType,
        subscription_learn_more_price: user.subscriptionLearnMorePrice,
        subscription_learn_more_clicks: user.subscriptionLearnMoreClicks,
        subscription_learn_more_impressions: user.subscriptionLearnMoreImpressions,
        subscription_learn_more_last_clicked: user.subscriptionLearnMoreLastClicked,
        subscription_learn_more_track_id: user.subscriptionLearnMoreTrackId,
        subscription_learn_more_track_price_id: user.subscriptionLearnMoreTrackPriceId,
        subscription_learn_more_track_price_amount: user.subscriptionLearnMoreTrackPriceAmount
      },
    };
  }
  else {
    userProps = {
      userId: null,
      name: null,
      email: null,
    };
  }

  return userProps;
}
export default getUserProps;

export const APP_NAME = "Deep Sounds";

export const REDIRECT_CONFIRM = "/signup/confirm";

export const LICENSE_MAIN_CATEGORIES = [
  {
    key: "B",
    name: "Commercial",
    description:
      "Commercial content that is directly or indirectly promoting a brand or product.",
    legal:
      "A <strong>[ProductName]</strong> is a license to an individual, Business, Sole Proprietorships, Non-Profit Organization which is defined as a not-for-profit charitable entity (501(c)(3) or an international equivalent), educational entity, religious entity, or governmental agency for a single use of a Track as background music in promotional or educational multimedia content for such individual or entity within the restrictions listed below:",
  },
  {
    key: "C",
    name: "Film",
    description:
      "Motion pictures including feature films, documentaries, and short films.",
  },
  {
    key: "D",
    name: "Television Show",
    description: "TV show, series, webisode or TV programming."
  },
];

export const PERSONAL_LICENSE_OPTIONS = [
  {
    key: "W1",
    name: "Yes",
    description: "Your content will be promoting a business or product.",
    rerouteMessage: "In order to continue with a license you will need to select a commercial license.",
    rerouteKey: "B"
  },
  {
    key: "W2",
    name: "No",
    description: "Your content will not be promoting a business or product."
  }
];

import React from "react";
import currencyFormatter from "../../utils/currencyFormatter";
import { Tooltip } from "react-tippy";
import { NavLink } from "react-router-dom";

const SalesCard = ({
  cardClass = "",
  link = "#",
  textClass,
  title = "Sales",
  totalAmount,
  tooltipText,
  totalSubtext,
}) => {
  return (
    <NavLink to={link} className="col flex items-stretch w-full md:w-1/4 mb-30">
      <div className={`pricing-card w-full ${cardClass}`}>
        <Tooltip
          useContext={true}
          animation="shift"
          arrow="true"
          position="top"
          html={
            <>
              {/* <div className="price-tool-title mb-10">{feature.name}</div> */}
              <div className="text-gray-500 price-tool-desc">{tooltipText || title}</div>
            </>
          }
        >
          {/* {# Header #} */}
          <div className="pricing-card-header mb-0">
            <h2 className={`pricing-card-title ${textClass}`}>{title}</h2>
            <div className="pricing-card-price-block">
              <h3 className="mr-10">{currencyFormatter(totalAmount)}</h3>
              {/* {% if card.price_subtext %} */}
              <span className="pricing-card-price-subtext">{totalSubtext}</span>
              {/* {% endif %} */}
            </div>
            {/* <p className="text-gray-500">{description}</p> */}
          </div>
        </Tooltip>
      </div>
    </NavLink>
  );
};

export default SalesCard;

import React, { Component } from "react";
import ArtistsPayoutRow from "./artistsPayoutRow";
import currencyFormatter from "../../utils/currencyFormatter";
const moment = require("moment");

class ArtistsPayoutTable extends Component {
  state = {
    totalPayoutTotal: 0,
  };
  componentDidMount = () => {
    let totalPayoutTotal = 0;
    this.props.artistSubscriptionPayouts.forEach((payout) => {
      if (payout.status !== "ineligible") {
        totalPayoutTotal += payout.payoutTotal;
      }
    });
    this.setState({ totalPayoutTotal: totalPayoutTotal });
  };
  componentDidUpdate = () => {
    let totalPayoutTotal = 0;
    this.props.artistSubscriptionPayouts.forEach((payout) => {
      if (payout.status !== "ineligible") {
        totalPayoutTotal += payout.payoutTotal;
      }
    });
    if (totalPayoutTotal !== this.state.totalPayoutTotal) {
      this.setState({ totalPayoutTotal: totalPayoutTotal });
    }
  };
  render() {
    let totalApprovedTracks = 0;
    let totalSubscriptionLicenseRevenue = 0;
    let totalSeniorityDays = 0;
    let totalSeniorityRate = 0;
    let totalSubscriptionPayRate = 0;
    let totalApprovedTracksRate = 0;
    let totalPotPercentage = 0;
    let totalPayoutTotal = 0;
    return (
      <div class="bg-gray-100 p-20 pb-10 lg:p-25 lg:pb-15 mb-40">
        {/* {# Table Header #} */}
        <div class="flex flex-col md:flex-row items-start md:items-center justify-between mb-30 lg:mb-40">
          {/* {# Title #} */}
          <div class="flex-1 flex items-center mb-15 md:mb-0">
            <h3 class="text-label-lg mr-10">
              Subscription Royalty Payouts for{" "}
              {moment(this.props.currentSubscriptionPayout?.forMonth).utc().format("MM/YY")}
            </h3>
          </div>
          <div>
            <div>
              Total Should Be{" "}
              {currencyFormatter(this.props.currentSubscriptionPayout?.artistPool / 100)}
            </div>
            <div>
              Total From Individual Payouts is{" "}
              {currencyFormatter(this.state.totalPayoutTotal / 100)}
              {this.props.currentSubscriptionPayout?.artistPool === this.state.totalPayoutTotal && (
                <span className="icon-check text-icon-sm ml-5"></span>
              )}
            </div>
          </div>
          <div className="ml-5">
            {this.props.currentSubscriptionPayout?.status !== "transferErrors" &&
              this.props.currentSubscriptionPayout?.status !== "overdue" && (
                <button
                  disabled={
                    this.props.currentSubscriptionPayout?.status === "approved" ||
                    this.props.currentSubscriptionPayout?.status === "complete"
                  }
                  onClick={() =>
                    this.props.handlePWModalOpen(this.props.currentSubscriptionPayout?.id)
                  }
                  className="btn btn-primary"
                >
                  Approve Payout
                </button>
              )}
            {this.props.currentSubscriptionPayout?.status === "transferErrors" && (
              <button
                disabled={this.props.currentSubscriptionPayout?.status === "approved"}
                onClick={() =>
                  this.props.handlePWModalOpenForReapprove(this.props.currentSubscriptionPayout?.id)
                }
                className="btn btn-primary"
              >
                Retry Payout
              </button>
            )}
            {this.props.currentSubscriptionPayout?.status === "overdue" && (
              <button
                disabled={this.props.currentSubscriptionPayout?.status === "approved"}
                onClick={() =>
                  this.props.handlePWModalOpenForApproveAndDistribute(
                    this.props.currentSubscriptionPayout?.id
                  )
                }
                className="btn btn-primary"
              >
                Approve and Distribute Payout
              </button>
            )}
          </div>
        </div>

        {/* // {# Table #} */}
        <div class="table-wrap ds-scrollbar">
          <table class="stats-table w-full">
            <thead class="text-left">
              <tr>
                <th class="text-label-sm">Artist</th>
                <th class="text-label-sm">Approved Tracks</th>
                <th class="text-label-sm">Subscription License Revenue</th>
                <th class="text-label-sm">Seniority Days</th>
                <th class="text-label-sm">Approved Tracks Pay Rate</th>
                <th class="text-label-sm">Subscription License Pay Rate</th>
                <th class="text-label-sm">Seniority Pay Rate</th>
                <th class="text-label-sm">Pot Percentage</th>
                <th class="text-label-sm">Subscription Royalty Total</th>
                <th class="text-label-sm">Description</th>
                <th class="text-label-sm">Status</th>
                <th class="text-label-sm">Stripe Id</th>
              </tr>
            </thead>

            <tbody>
              {/* {% for item in purchases %} */}
              {this.props?.artistSubscriptionPayouts
                ?.filter((payout) => payout.status !== "ineligible")
                ?.map((payout) => {
                  totalApprovedTracks += payout.approvedTracks;
                  totalSubscriptionLicenseRevenue += payout.subscriptionLicenseRevenue;
                  totalSeniorityDays += payout.seniorityDays;
                  totalSubscriptionPayRate += payout.subscriptionPayRate;
                  totalApprovedTracksRate += payout.approvedTracksPayRate;
                  totalSeniorityRate += payout.seniorityPayRate;
                  totalPotPercentage += payout.potPercentage;
                  totalPayoutTotal += payout.payoutTotal;
                  return <ArtistsPayoutRow key={payout.id} {...payout} />;
                })}
              <tr>
                <td>Total</td>
                <td>{totalApprovedTracks}</td>
                <td>{currencyFormatter(totalSubscriptionLicenseRevenue / 100)}</td>
                <td>{totalSeniorityDays}</td>
                <td>{(totalApprovedTracksRate * 100).toFixed(1)}%</td>
                <td>{(totalSubscriptionPayRate * 100).toFixed(1)}%</td>
                <td>{(totalSeniorityRate * 100).toFixed(1)}%</td>
                <td>{(totalPotPercentage * 100).toFixed(1)}%</td>
                <td>{currencyFormatter(totalPayoutTotal / 100)}</td>
                <td>N/A</td>
                <td>N/A</td>
                <td>N/A</td>
              </tr>
              <tr>
                <td colSpan={12} style={{ textAlign: "center" }}>
                  Ineligible Artists
                </td>
              </tr>

              {this.props?.artistSubscriptionPayouts
                ?.filter((payout) => payout.status === "ineligible")
                ?.map((payout) => {
                  return <ArtistsPayoutRow key={payout.id} {...payout} />;
                })}
              {/* {% endfor %} */}
            </tbody>
          </table>
        </div>

        {/* // {# Pagination #}
                // {% include "components/tables/table-pagination.html" %} */}
      </div>
    );
  }
}
export default ArtistsPayoutTable;

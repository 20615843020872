import moment from "moment";
import { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import React from "react";
import resolveConfig from "tailwindcss/resolveConfig";
import tailwindConfig from "../../tailwind.config.js";
import CityGroup from "./cityGroup";
import FilterGroup from "./filterGroup";

const tw = resolveConfig(tailwindConfig);
var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment);

const ArtistFilters = (props) => (
  <ul className="browse-filters">
    {/* {# Emotions #} */}
    <FilterGroup
      selectedSearchOptions={props.selectedSearchOptions}
      groupTitle="Emotion"
      type="emotion"
      filterOptions={props.filters.filter(
        (filterOpt) => filterOpt.type === "emotion"
      )}
      disabledFilterOptions={props.disabledFilters?.filter(
        (filterOpt) => filterOpt.type === "emotion"
      )}
      toggleOpen={() => props.toggleFilter("Emotion")}
      isOpen={props.isEmotionOpen}
      addSearchOption={props.addSearchOption}
      excludeSearchOption={props.excludeSearchOption}
    />
    {/* Genres */}
    <FilterGroup
      selectedSearchOptions={props.selectedSearchOptions}
      groupTitle="Genres"
      type="genre"
      filterOptions={props.filters.filter(
        (filterOpt) => filterOpt.type === "genre"
      )}
      disabledFilterOptions={props.disabledFilters?.filter(
        (filterOpt) => filterOpt.type === "genre"
      )}
      toggleOpen={() => props.toggleFilter("Genres")}
      isOpen={props.isGenresOpen}
      addSearchOption={props.addSearchOption}
      excludeSearchOption={props.excludeSearchOption}
    />
    {/* {# Mood #} */}
    <FilterGroup
      selectedSearchOptions={props.selectedSearchOptions}
      groupTitle="Mood"
      type="mood"
      filterOptions={props.filters.filter(
        (filterOpt) => filterOpt.type === "mood"
      )}
      disabledFilterOptions={props.disabledFilters?.filter(
        (filterOpt) => filterOpt.type === "mood"
      )}
      toggleOpen={() => props.toggleFilter("Moods")}
      isOpen={props.isMoodsOpen}
      addSearchOption={props.addSearchOption}
      excludeSearchOption={props.excludeSearchOption}
    />
    {/* {# Attributes #} */}
    <FilterGroup
      selectedSearchOptions={props.selectedSearchOptions}
      groupTitle="Attributes"
      type="attribute"
      filterOptions={props.filters.filter(
        (filterOpt) => filterOpt.type === "attribute"
      )}
      disabledFilterOptions={props.disabledFilters?.filter(
        (filterOpt) => filterOpt.type === "attribute"
      )}
      toggleOpen={() => props.toggleFilter("Attributes")}
      isOpen={props.isAttributesOpen}
      addSearchOption={props.addSearchOption}
      excludeSearchOption={props.excludeSearchOption}
    />
    {/* {# Lyrics #} */}
    <FilterGroup
      selectedSearchOptions={props.selectedSearchOptions}
      groupTitle="Lyrics"
      type="lyric"
      filterOptions={props.filters.filter(
        (filterOpt) => filterOpt.type === "lyric"
      )}
      disabledFilterOptions={props.disabledFilters?.filter(
        (filterOpt) => filterOpt.type === "lyric"
      )}
      toggleOpen={() => props.toggleFilter("Lyrics")}
      isOpen={props.isLyricsOpen}
      addSearchOption={props.addSearchOption}
      excludeSearchOption={props.excludeSearchOption}
    />
    {/* {# BPM #} */}
    <FilterGroup
      selectedSearchOptions={props.selectedSearchOptions}
      groupTitle="BPM"
      type="bpm"
      filterOptions={props.filters.filter(
        (filterOpt) => filterOpt.type === "bpm"
      )}
      disabledFilterOptions={props.disabledFilters?.filter(
        (filterOpt) => filterOpt.type === "bpm"
      )}
      toggleOpen={() => props.toggleFilter("BPM")}
      isOpen={props.isBPMOpen}
      addSearchOption={props.addSearchOption}
      excludeSearchOption={props.excludeSearchOption}
    />
    {/* Instruments */}
    <FilterGroup
      selectedSearchOptions={props.selectedSearchOptions}
      groupTitle="Instruments"
      type="instrument"
      filterOptions={props.filters.filter(
        (filterOpt) => filterOpt.type === "instrument"
      )}
      disabledFilterOptions={props.disabledFilters?.filter(
        (filterOpt) => filterOpt.type === "instrument"
      )}
      toggleOpen={() => props.toggleFilter("Instruments")}
      isOpen={props.isInstrumentsOpen}
      addSearchOption={props.addSearchOption}
      excludeSearchOption={props.excludeSearchOption}
    />
    {/* {# City #} */}
    {props.showMoreCities ? (
      <CityGroup
        showMoreCities={props.showMoreCities}
        toggleShowMoreCities={props.toggleShowMoreCities}
        selectedSearchOptions={props.selectedSearchOptions}
        groupTitle="City"
        type="city"
        totalCities={
          props.filters.filter((filterOpt) => filterOpt.type === "city")?.length
        }
        filterOptions={props.filters.filter(
          (filterOpt) => filterOpt.type === "city"
        )}
        disabledFilterOptions={props.disabledFilters?.filter(
          (filterOpt) => filterOpt.type === "city"
        )}
        toggleOpen={() => props.toggleFilter("Cities")}
        isOpen={props.isCitiesOpen}
        addSearchOption={props.addSearchOption}
        excludeSearchOption={props.excludeSearchOption}
      />
    ) : (
      <CityGroup
        showMoreCities={props.showMoreCities}
        toggleShowMoreCities={props.toggleShowMoreCities}
        selectedSearchOptions={props.selectedSearchOptions}
        groupTitle="City"
        type="city"
        totalCities={
          props.filters.filter((filterOpt) => filterOpt.type === "city")?.length
        }
        filterOptions={props.filters
          .filter((filterOpt) => filterOpt.type === "city")
          .slice(0, 20)
        }
        disabledFilterOptions={
          props.disabledFilters?.filter(
            (filterOpt) => filterOpt.type === "city"
          )
          .slice(0, 20)
        }
        toggleOpen={() => props.toggleFilter("Cities")}
        isOpen={props.isCitiesOpen}
        addSearchOption={props.addSearchOption}
        excludeSearchOption={props.excludeSearchOption}
      />
    )}

    {props.trackBrowse && (
      <li className="filter-group bg-gray-100">
        {/* Filter Header */}
        <div className="filter-header filter-header-toggle">
          Track Length
          {/* Form Switch */}
          <label className="form-switch">
            <input
              type="checkbox"
              onChange={props.enableLengthRange}
              checked={props.isLengthRangeEnabled}
            />
            <span className="form-switch-slider"></span>
          </label>
        </div>
        {/* Filter Conditional - toggle .block and .hidden */}
        {props.isLengthRangeEnabled && (
          <div className="block">
            <div className="py-25 pl-40 pr-30">
              {/* Slider Block */}
              <div className="flex items-center">
                {/* Value */}
                <div className="flex-none">
                  <div className="w-50 text-gray-400 text-sm leading-snug">
                    {moment
                      .duration(
                        Math.floor(props.lengthFilterValue[0]),
                        "seconds"
                      )
                      .format("m:ss", { forceLength: true, trim: false })}
                  </div>
                </div>
                {/* Slider */}
                <div className="flex-1">
                  <Range
                    min={props.lengthFilterMin}
                    max={props.lengthFilterMax}
                    value={props.lengthFilterValue}
                    onChange={props.onSliderChange}
                    onAfterChange={props.sliderFinished}
                    step={15}
                    count={2}
                    railStyle={{
                      height: 1,
                      background: tw.theme.colors.gray[300],
                    }}
                    handleStyle={[
                      {
                        height: 12,
                        width: 12,
                        marginLeft: -6,
                        marginTop: -6,
                        backgroundColor: tw.theme.colors.white,
                        border: 0,
                        outline: "none",
                        boxShadow: "none",
                      },
                      {
                        height: 12,
                        width: 12,
                        marginLeft: 0,
                        marginTop: -6,
                        backgroundColor: tw.theme.colors.white,
                        border: 0,
                        outline: "none",
                        boxShadow: "none",
                      },
                    ]}
                    trackStyle={[
                      {
                        background: "red",
                        height: 1,
                      },
                    ]}
                  />
                </div>
                <div className="flex-none">
                  <div className="w-50 text-gray-400 text-sm text-right leading-snug">
                    {moment
                      .duration(
                        Math.floor(props.lengthFilterValue[1]),
                        "seconds"
                      )
                      .format("m:ss", { forceLength: true, trim: false })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </li>
    )}
  </ul>
);

export default ArtistFilters;

import debounce from "lodash.debounce"
import throttle from "lodash.throttle"
import moment from "moment"
import React from 'react'
import { withRouter } from "react-router-dom"
import api from "../components/utils/api"

const PlayerContext = React.createContext()
var momentDurationFormatSetup = require("moment-duration-format");
momentDurationFormatSetup(moment)

// The number of seconds that counts as a "play" for stats
const playCountSeconds = 1;
class PlayerProvider extends React.Component {
  state = {
    visible: "",
    playList: [],
    trackIndex: "",
    playing: false,
    playSubmitted: false,
    projectId: null,
    collectionId: null,
    featuredId: null,
    volume: .75,
    isVideoStudioOpen: false,
    regionPlaying: false,
    currentTime: 0,
    formattedCurrentTime: "0:00",
    ratioCurrentTime: 0,
    autoPlay: false,
    loadedTracks: {},
    loadedVersions: {},
    loadedStems: {},
    loadedArtists: {},
    loadedAlbums: {},
    mediaPlayer: {},
    isLoaderOpen: false,
    isMainOpen: false,
    isDemoOpen: false,
    isMini: false,
    video: null,
    videoURL: null,
    isVideoPlaying: false,
    videoDurationSeconds: 0,
    videoDurationFormatted: "",
    playedPercent: 0,
    playedSeconds: 0,
    playedFormatted: "0:00",
    videoVolume: 1,
    isMuted: false,
    videoTimelineWidth: 0,
    initialLeftCutoff: 0,
    updatedLeftOffset: 0,
    trackStartTime: 0,
    isLoopEnabled: true,
  }
  hidePlayer = ()=>{
    this.setState({visible: false})
    return
  }
  setVideoStudio = (isOpen)=>{
    this.setState({isVideoStudioOpen: isOpen})
  }
  showPlayer = ()=>{
    this.setState({visible: true})
  }
  setPlaylist = (playList)=>{

    this.setState({playList: playList})
  }

  setWaveFormEl = (element)=>{

    this.setState({waveFormEl: element})
  }


  pause=()=>{
    this.setState({playing: false})
  }
  playPause= ()=>{

      if(this.state.waveFormEl?.regions?.list){
        let regionKey = Object.keys(this.state.waveFormEl?.regions?.list)[0]

        let regionRef = this.state.waveFormEl?.regions?.list[regionKey]

        if(this.state.playing){
          this.pauseRegion()
          this.props.pauseVideo()
        }
        else{
          this.playRegion()
          this.props.playVideo()
        }
      }
      else{
        this.setState({playing: !this.state.playing})
      }
  }
  playRegion = (offset)=>{


    if(this.state.waveFormEl?.regions?.list){
      let regionKey = Object.keys(this.state.waveFormEl?.regions?.list)[0]

      let regionRef = this.state.waveFormEl?.regions?.list[regionKey]

      let regionStart = regionRef.start

      this.setState({playing: true, regionPlaying: true,},()=> regionRef.play(regionStart + offset))
    }
  }
  pauseRegion= ()=>{
    // let regionKey = Object.keys(this.state.waveFormEl?.regions?.list)[0]
    // let regionRef = this.state.waveFormEl?.regions?.list[regionKey]
    // let regionStart = regionRef.start
    // let duration = this.state.waveFormEl.getDuration()
    // let seekTo = regionStart/duration
    // let regionKey = Object.keys(this.state.waveFormEl?.regions?.list)[0]
    // let regionRef = this.state.waveFormEl?.regions?.list[regionKey]

    this.setState({playing: false, regionPlaying: false}, ()=>{
      this.state.waveFormEl.pause()
      // this.state.waveFormEl.seekTo(0)
    })
  }
  playNext = ()=>{
    if(this.state.playList?.length > 0){
      let currentIndex =this.state.playList.indexOf(this.state.mediaPlayer.trackId)
      let playList = [...this.state.playList]
      if(playList.length > (currentIndex + 1)){
        this.loadTrackInMediaPlayer(playList[currentIndex + 1],this.state.mediaPlayer.idKey.replace(/-(track|track\(fromStem\)|track\(fromVersion\))-\d*-/g, `-track-${playList[currentIndex + 1]}-`).replace(/-version-\d*-/g,"").replace(/-stem-\d*-/g,"") )
      }
      else{
        if(currentIndex === playList.length -1){
          this.handlePause()
        }
        else{
          this.loadTrackInMediaPlayer(playList[0], this.state.mediaPlayer.idKey.replace(/-(track|track\(fromStem\)|track\(fromVersion\))-\d*-/g, `-track-${playList[currentIndex + 1]}-`).replace(/-version-\d*-/g,"").replace(/-stem-\d*-/g,""))

        }
        return
      }
    }
    else{
      return
    }
  }
  loadNext = ()=>{
    if(this.state.playList?.length > 0){
      let currentIndex =this.state.playList.findIndex(track=> track.url === this.state.currentTrack.url || track.idKey === this.state.mediaPlayer.idKey)
      let playList = [...this.state.playList]
      if(playList.length > (currentIndex + 1)){
        if(!playList[currentIndex+1].peaks){
          api.getTrackById(playList[currentIndex+1].trackId)
            .then(res=>{
              let trackRes = res.data.track
              let track = {
                  trackId: trackRes.trackId,
                  artistURL: trackRes.artistURL,
                  mp3URL: trackRes.mp3URL,
                  peaks: trackRes.peaks,
                  imageURL: trackRes.imageURL,
                  duration: trackRes.duration,
                  trackTitle: trackRes.trackTitle,
                  baseTitle: trackRes.baseTitle,
                  artistName: trackRes.artistName,
                  liked: trackRes.liked,
                  trackStatus: trackRes.trackStatus,
                  artistId: trackRes.artistId,
                  trackURL: trackRes.trackURL,
                  albumTitle: trackRes.albumTitle,
                  albumId: trackRes.albumId,
                  idKey: playList[currentIndex+1].idKey
              }
              api.getAudio("MP3", track.trackId)
                .then(sourceRes=>{
                  track.url= sourceRes.data.url
                  playList[currentIndex +1] = track
                  this.setState({playList: playList})
                })
        })
        }
        else{
          let track = playList[currentIndex +1]
            api.getAudio("MP3", track.trackId)
              .then(sourceRes=>{
                track.url= sourceRes.data.url
                playList[currentIndex +1] = track
                this.setState({playList: playList})
              })
        }
      }
      else{
        return
      }
    }
    else{
      return
    }
  }
  playPrev = ()=>{
    if(this.state.playList.length > 0){
      let currentIndex =this.state.playList.indexOf(this.state.mediaPlayer.trackId)
      let playList = [...this.state.playList]
      if(currentIndex > 0){
        this.loadTrackInMediaPlayer(playList[currentIndex - 1],this.state.mediaPlayer.idKey.replace(/-(track|track\(fromStem\)|track\(fromVersion\))-\d*-/g, `-track-${playList[currentIndex - 1]}-`).replace(/-version-\d*-/g,"").replace(/-stem-\d*-/g,""))
      }
    }
  }
  likeTrack = ()=>{
    api.likeTrack(this.state.mediaPlayer.trackId)
        .then(res=>{

            if(res.data.success){
                let track = this.state.currentTrack
                // likedTracks.push(trackId.toString())
                track.liked = 1

                if(window.location.search.includes("searchId")){
                  const queryStringer = require("query-string")
                  let query =window.location.search.length > 0 ? window.location.search : ""
                  let parsedQuery = queryStringer.parse(query)
                  if(parsedQuery.searchId){
                    let searchBody = {
                        type: "likeTrack",
                        trackId: this.state.mediaPlayer.trackId
                    }
                    api.saveActionToSearch(parseInt(parsedQuery.searchId), {action: searchBody})
                        .then(res=>console.log(res))
                  }

                }
                this.setState({currentTrack: track})
            }
        })
        .catch(error=>{
          if(error.response?.status === 401){
            this.props.triggerLoginModal()
          }
        })
  }
  unlikeTrack = ()=>{
    api.unlikeTrack(this.state.mediaPlayer.trackId)
      .then(res=>{

        if(res.data.success){
            let track = this.state.currentTrack
            // likedTracks.push(trackId.toString())
            track.liked = 0
            this.setState({currentTrack: track})
        }
    })
  }
  setProjectId = (projectId)=>{
    this.setState({projectId: projectId || 0})
  }
  setCollectionId = (collectionId)=>{
    this.setState({collectionId: collectionId || 0})
  }
  setFeaturedId = (featuredId)=>{
    this.setState({featuredId: featuredId || 0})
  }
  setVolume = (value)=>{

    if(value?.x || value?.x ===0){
      this.setState({
        volume: value.x
      }, ()=> this.state.waveFormEl.setVolume(this.state.volume)
      )
    }
    else{
      let {target} = value
      let realValue = target.value

      this.setState({
        volume: realValue
      }, ()=> this.state.waveFormEl.setVolume(this.state.volume)
      )
    }

  }
  toggleRegion = (isRegionEnabled)=>{



  }
  updateRegion = (event)=>{

    this.state.waveFormEl.clearRegions();
    this.state.waveFormEl.addRegion({start: event.start, end: event.end,color: 'hsla(100, 100%, 30%, 0.1)', drag: false, })
    let startFormatted = moment.duration(Math.floor(event.start), 'seconds').format("m:ss",{forceLength: true, trim: false})
    let endFormatted = moment.duration(Math.floor(event.end), 'seconds').format("m:ss",{forceLength: true, trim: false})

    this.setState({region:{
      start: event.start,
      end: event.end,
      startFormatted: startFormatted,
      endFormatted: endFormatted
    }})
  }
  setRegion = (region)=>{


    if(this.state.waveFormEl){
      let regionKey = Object.keys(this.state.waveFormEl?.regions?.list || {})[0]

      let regionRef = this.state.waveFormEl?.regions?.list[regionKey]
      if(regionRef){
        this.state.waveFormEl.clearRegions();
      }
      if(this.state.waveFormEl){



        let regionEl =this.state.waveFormEl.addRegion({start: region.start, end: region.end,color: 'hsla(100, 100%, 30%, 0.1)', drag: false, })
        regionEl.resize = false
        this.setState({region: region})

      }

    }
    else{
      setTimeout(()=>this.setRegion(region), 100)
    }

  }
  playWithOffset = ()=>{
    this.setState({playing: true}, ()=>{
      this.state.waveFormEl.play(this.state.offsetTrackStart)
      this.setState({offsetTrackStart: 0})
    })
  }
  setTrackOffsetStart = ()=>{
    this.setState({offsetTrackStart: this.state.currentTime})
  }
  resetTrackOffsetStart = ()=>{
    this.setState({offsetTrackStart: 0})
  }

  updatePlayCount = () => {
    console.log('increasing play count for track', this.state.mediaPlayer.trackId);
    api.increasePlayCount(this.state.mediaPlayer.trackId)
    .then(res => {
      console.log('increasePlayCount res', res);
    })
    .catch(error => {
      console.log('increasePlayCount error', error.response);
    })
  }

  formatCurrentTime = (currentTime) => {
    return moment.duration(Math.floor(currentTime), 'seconds')
      .format("m:ss", { forceLength: true, trim: false });
  }

  updateTime = (currentTime, ratio = null) => {
  // console.log('updating time for track', this.state.mediaPlayer.trackId);
  // console.log('is play submitted?', this.state.mediaPlayer.playSubmitted);

  const formattedCurrentTime = this.formatCurrentTime(currentTime);

  let ratioCurrentTime = null;

  if (ratio) {
    ratioCurrentTime = currentTime * ratio;
  }

  if (
    currentTime >= playCountSeconds &&
    !this.state.mediaPlayer.playSubmitted
  ) {
    this.setState(
      ({ mediaPlayer }) => {
        return {
          mediaPlayer: {
            ...mediaPlayer,
            currentTime,
            formattedCurrentTime: formattedCurrentTime,
            ratioCurrentTime,
            playSubmitted: true,
          },
        };
      },
      () => {
        this.updatePlayCount();
      }
    );
  } else {
    this.setState(({ mediaPlayer }) => {
      return {
        mediaPlayer: {
          ...mediaPlayer,
          currentTime,
          formattedCurrentTime: formattedCurrentTime,
          ratioCurrentTime,
        },
      };
    });
  }
};

  // updateTimeWithRatio = (currentTime, ratio) => {
  //   // console.log('updating time with ratio', currentTime, ratio);

  //   console.log('updating time with ratio for track', this.state.mediaPlayer.trackId);
  //   console.log('is play submitted?', this.state.mediaPlayer.playSubmitted);

  //   const formattedCurrentTime = this.formatCurrentTime(currentTime);


  // }
  setAutoPlay = (shouldAutoPlay)=>{
    this.setState({autoPlay: shouldAutoPlay})
  }
  updateLoadedTracks = (tracks)=>{
    this.setState(({loadedTracks, loadedStems, loadedVersions})=>{
      let newStems = {}
      let newVersions = {}

      return({
        loadedTracks: {
          ...loadedTracks,
          ...tracks.reduce((toAddTracks, track)=>{
              track.stems = track.stems.reduce((trackStems, stem)=>{
                newStems[stem.id] = stem;
                trackStems.push(stem.id)
                return trackStems
              },[])
              track.versions = track.versions.reduce((trackVersions, version)=>{
                newVersions[version.id] = version;
                trackVersions.push(version.id)
                return trackVersions
              },[])
              toAddTracks[track.trackId] = {...loadedTracks[track.trackId],
                ...track}
              toAddTracks[track.trackId].retreivedAt = Date.now()
            return toAddTracks
          }, {})
        },
        loadedStems: {
          ...loadedStems,
          ...newStems
        },
        loadedVersions: {
          ...loadedVersions,
          ...newVersions
        },
        areTracksLoading: false,
      })
    })
  }
  getTracksInfo = (trackIds)=>{
    let needsInfo = [];
    trackIds.forEach(trackId=>{
      if(this.state.loadedTracks[trackId]?.peaks){
      }
      else{
        needsInfo.push(trackId)
      }
    })
    if(needsInfo.length > 0){
      this.setState({areTracksLoading: true}, ()=>{
        api.getTrackInfo(needsInfo)
        .then(res=>{
          let newTracks = res.data.tracks

          this.updateLoadedTracks(newTracks)
        })
      })
    }
  }
  getVersionInfo = (trackId)=>{
    let checkVersions = this.state.loadedTracks[trackId].versions;
    let needsInfo = [];
    checkVersions.forEach(version=>{
      if(this.state.loadedVersions[version].peaks){
      }
      else{
        needsInfo.push(version)
      }
    })
    if(needsInfo.length > 0){
      api.getVersionInfo(needsInfo)
      .then(res=>{
        let newVersions = res.data.versions
        this.setState(({loadedVersions})=>{
          return({
            loadedVersions: {
              ...loadedVersions,
              ...newVersions.reduce((toAddVersions, version)=>{
                toAddVersions[version.id] = {...loadedVersions[version.id], ...version}
                return toAddVersions
              }, {})
            }
          })
        })

      })
    }
  }
  getStemInfo = (trackId)=>{
    let checkStems = this.state.loadedTracks[trackId].stems;
    let needsInfo = [];
    checkStems.forEach(stem=>{
      if(this.state.loadedStems[stem].peaks){
      }
      else{
        needsInfo.push(stem)
      }
    })
    if(needsInfo.length > 0){
      api.getStemInfo(needsInfo)
      .then(res=>{
        let newStems = res.data.stems
        this.setState(({loadedStems})=>{
          return({
            loadedStems: {
              ...loadedStems,
              ...newStems.reduce((toAddStems, stem)=>{
                toAddStems[stem.id] = {...loadedStems[stem.id], ...stem}
                return toAddStems
              }, {})
            }
          })
        })

      })
    }
  }
  waveFinishedAction = ()=>{
    if(this.state.autoPlay && !this.state.isMainOpen){
      this.playNext()
    }
    else{
      this.handlePause()
    }
  }
  loadTrackInMediaPlayer = (trackId, idKey) => {

    if(this.context.waveFormEl){
      this.handlePause()
    }
    if(this.state.visible){

    }
    else{
      this.showPlayer()
    }

    //check to see if loaded tracks (trackId) includes all needed properties for track
    let loadedTrack = this.state.loadedTracks[trackId];


    if(loadedTrack?.imageURL && loadedTrack?.duration && loadedTrack?.peaks){
      //check to see if there is a cloudfrontURL available,
      if(this.state.waveFormEl){
        this.state.waveFormEl.pause()

      }
      this.setState(({loadedTracks, mediaPlayer})=>{
        return({
          mediaPlayer:{
            ...mediaPlayer,
            isTrackPlaying: false,
            trackId: loadedTrack.trackId,
            albumId: loadedTrack.albumId,
            artistId: loadedTrack.artistId,
            imageURL: loadedTrack.imageURL,
            trackTitleDisplay: loadedTrack.trackTitle,
            trackURL: loadedTrack.trackURL,
            artistNameDisplay: loadedTrack.artistName,
            artistURL: loadedTrack.artistURL,
            currentTime: 0,
            formattedCurrentTime: "0:00",
            durationDisplay: loadedTrack.duration,
            isLiked: loadedTrack.isLiked,
            peaks: loadedTrack.peaks,
            isPlaying: false,
            idKey: idKey,
            isLoading: true,
            playSubmitted: false,
          },
          isVideoPlaying: false,
          updatedLeftOffset: this.state.updatedLeftOffset < this.state.initialLeftCutoff ? this.state.initialLeftCutoff : this.state.updatedLeftOffset
        })
      }, ()=>{
          if(loadedTrack.cloudfrontURL){
            this.setState(({mediaPlayer})=>{
              return({
                mediaPlayer:{
                  ...mediaPlayer,
                  cloudfrontURL: loadedTrack.cloudfrontURL,
                  isLoading: false,
                  playSubmitted: false,
                },
                updatedLeftOffset: this.state.updatedLeftOffset < this.state.initialLeftCutoff ? this.state.initialLeftCutoff : this.state.updatedLeftOffset
              })
            }, ()=>{
              this.wavesurferLoad(this.state.mediaPlayer.cloudfrontURL, JSON.parse(this.state.mediaPlayer.peaks))

            })
          }
          else{
            api.getAudio("MP3", trackId)
                .then(sourceRes=>{
                  this.setState(({loadedTracks, mediaPlayer})=>{
                    return({
                      loadedTracks:{
                        ...loadedTracks,
                        [trackId]: {
                          ...loadedTracks[trackId],
                          cloudfrontURL: sourceRes.data.url
                        }
                      },
                      mediaPlayer:{
                        ...mediaPlayer,
                        cloudfrontURL: sourceRes.data.url,
                        isLoading: false,
                        playSubmitted: false,
                      },
                      updatedLeftOffset: this.state.updatedLeftOffset < this.state.initialLeftCutoff ? this.state.initialLeftCutoff : this.state.updatedLeftOffset
                    })
                  }, ()=>{
                    this.wavesurferLoad(this.state.mediaPlayer.cloudfrontURL, JSON.parse(this.state.mediaPlayer.peaks))

                  })
                })
          }
        })

      }
      else {
          api.getTrackForMediaPlayer(trackId)
            .then(res=>{

              let track = res.data.track
              this.setState(({loadedTracks, loadedStems, loadedVersions, mediaPlayer})=>{
                let newStems = {}
                let newVersions = {}
                if (track?.stems?.length) {
                  track.stems = track.stems.reduce((trackStems, stem)=>{
                    newStems[stem.id] = stem;
                    trackStems.push(stem.id)
                    return trackStems
                  },[]);
                }
                if (track?.versions?.length) {
                  track.versions = track.versions.reduce((trackVersions, version)=>{
                    newVersions[version.id] = version;
                    trackVersions.push(version.id)
                    return trackVersions
                  },[])
                }
                return({
                  loadedTracks: {
                    ...loadedTracks,
                    [trackId]: {
                      ...track,
                      retreivedAt: Date.now()
                    }
                  },
                  loadedStems: {
                    ...loadedStems,
                    ...newStems
                  },
                  loadedVersions: {
                    ...loadedVersions,
                    ...newVersions
                  },
                  mediaPlayer: {
                    ...mediaPlayer,
                    playSubmitted: false,
                    isTrackPlaying: false
                  },
                  isVideoPlaying: false,
                  updatedLeftOffset: this.state.updatedLeftOffset < this.state.initialLeftCutoff ? this.state.initialLeftCutoff : this.state.updatedLeftOffset
                })
              }, ()=>this.loadTrackInMediaPlayer(trackId, idKey))
            })
      }

    }
    loadTrackInMediaPlayerNoPlay = (trackId, idKey) =>{

      if(this.context.waveFormEl){
        this.handlePause()
      }
      if(this.state.visible){

      }
      else{
        this.showPlayer()
      }

      //check to see if loaded tracks (trackId) includes all needed properties for track
      let loadedTrack = this.state.loadedTracks[trackId]


      if(loadedTrack?.imageURL && loadedTrack?.duration && loadedTrack?.peaks){
        //check to see if there is a cloudfrontURL available,
        if(this.state.waveFormEl){
          this.state.waveFormEl.pause()

        }
        this.setState(({loadedTracks, mediaPlayer})=>{
          return({
            mediaPlayer:{
              ...mediaPlayer,
              isTrackPlaying: false,
              trackId: loadedTrack.trackId,
              albumId: loadedTrack.albumId,
              artistId: loadedTrack.artistId,
              imageURL: loadedTrack.imageURL,
              trackTitleDisplay: loadedTrack.trackTitle,
              trackURL: loadedTrack.trackURL,
              artistNameDisplay: loadedTrack.artistName,
              artistURL: loadedTrack.artistURL,
              currentTime: 0,
              formattedCurrentTime: "0:00",
              durationDisplay: loadedTrack.duration,
              isLiked: loadedTrack.isLiked,
              peaks: loadedTrack.peaks,
              isPlaying: false,
              idKey: idKey,
              isLoading: true,
              playSubmitted: false,
            },
            isVideoPlaying: false,
            updatedLeftOffset: this.state.updatedLeftOffset < this.state.initialLeftCutoff ? this.state.initialLeftCutoff : this.state.updatedLeftOffset
          })
        }, ()=>{
            if(loadedTrack.cloudfrontURL){
              this.setState(({mediaPlayer})=>{
                return({
                  mediaPlayer:{
                    ...mediaPlayer,
                    cloudfrontURL: loadedTrack.cloudfrontURL,
                    isLoading: false,
                    playSubmitted: false,
                  },
                  updatedLeftOffset: this.state.updatedLeftOffset < this.state.initialLeftCutoff ? this.state.initialLeftCutoff : this.state.updatedLeftOffset
                })
              }, ()=>{
                this.wavesurferLoadNoPlay(this.state.mediaPlayer.cloudfrontURL, JSON.parse(this.state.mediaPlayer.peaks))

              })
            }
            else{
              api.getAudio("MP3", trackId)
                  .then(sourceRes=>{
                    this.setState(({loadedTracks, mediaPlayer})=>{
                      return({
                        loadedTracks:{
                          ...loadedTracks,
                          [trackId]: {
                            ...loadedTracks[trackId],
                            cloudfrontURL: sourceRes.data.url
                          }
                        },
                        mediaPlayer:{
                          ...mediaPlayer,
                          cloudfrontURL: sourceRes.data.url,
                          isLoading: false,
                          playSubmitted: false,
                        },
                        updatedLeftOffset: this.state.updatedLeftOffset < this.state.initialLeftCutoff ? this.state.initialLeftCutoff : this.state.updatedLeftOffset
                      })
                    }, ()=>{
                      this.wavesurferLoadNoPlay(this.state.mediaPlayer.cloudfrontURL, JSON.parse(this.state.mediaPlayer.peaks))

                    })
                  })
            }
          })

        }
        else{
            api.getTrackForMediaPlayer(trackId)
              .then(res=>{

                let track = res.data.track
                this.setState(({loadedTracks, loadedStems, loadedVersions, mediaPlayer})=>{
                  let newStems = {}
                  let newVersions = {}
                  track.stems = track.stems.reduce((trackStems, stem)=>{
                    newStems[stem.id] = stem;
                    trackStems.push(stem.id)
                    return trackStems
                  },[])
                  track.versions = track.versions.reduce((trackVersions, version)=>{
                    newVersions[version.id] = version;
                    trackVersions.push(version.id)
                    return trackVersions
                  },[])

                  return({
                    loadedTracks: {
                      ...loadedTracks,
                      [trackId]: {
                        ...track,
                        retreivedAt: Date.now()
                      }
                    },
                    loadedStems: {
                      ...loadedStems,
                      ...newStems
                    },
                    loadedVersions: {
                      ...loadedVersions,
                      ...newVersions
                    },
                    mediaPlayer: {
                      ...mediaPlayer,
                      isTrackPlaying: false,
                      playSubmitted: false,
                    },
                    isVideoPlaying: false,
                    updatedLeftOffset: this.state.updatedLeftOffset < this.state.initialLeftCutoff ? this.state.initialLeftCutoff : this.state.updatedLeftOffset
                  })
                }, ()=>this.loadTrackInMediaPlayerNoPlay(trackId, idKey))
              })
        }

      }
    loadStemInMediaPlayer = (trackId, stemId, idKey) =>{

      if(this.context.waveFormEl){
        this.handlePause()
      }
      if(this.state.visible){

      }
      else{
        this.showPlayer()
      }

      //check to see if loaded tracks (trackId) includes all needed properties for track
      let loadedTrack = this.state.loadedTracks[trackId]
      let loadedStem = this.state.loadedStems[stemId]


      if(loadedTrack?.imageURL && loadedStem?.duration && loadedStem?.peaks){
        //check to see if there is a cloudfrontURL available,
        if(this.state.waveFormEl){
          this.state.waveFormEl.pause()
        }
        this.setState(({loadedTracks, mediaPlayer})=>{
          return({
            mediaPlayer:{
              ...mediaPlayer,
              isTrackPlaying: false,
              trackId: loadedTrack.trackId,
              albumId: loadedTrack.albumId,
              artistId: loadedTrack.artistId,
              imageURL: loadedTrack.imageURL,
              trackTitleDisplay: loadedTrack.trackTitle + " - "+ loadedStem.name,
              trackURL: loadedTrack.trackURL,
              artistNameDisplay: loadedTrack.artistName,
              artistURL: loadedTrack.artistURL,
              currentTime: 0,
              formattedCurrentTime: "0:00",
              durationDisplay: loadedStem.duration,
              isLiked: loadedTrack.isLiked,
              peaks: loadedStem.peaks,
              isPlaying: false,
              idKey: idKey,
              isLoading: true,
              playSubmitted: false,
            },
            isVideoPlaying: false,
            updatedLeftOffset: this.state.updatedLeftOffset < this.state.initialLeftCutoff ? this.state.initialLeftCutoff : this.state.updatedLeftOffset

          })
        }, ()=>{
            if(loadedStem.cloudfrontURL){
              this.setState(({mediaPlayer})=>{
                return({
                  mediaPlayer:{
                    ...mediaPlayer,
                    cloudfrontURL: loadedStem.cloudfrontURL,
                    isLoading: false,
                    playSubmitted: false,
                  },
                  updatedLeftOffset: this.state.updatedLeftOffset < this.state.initialLeftCutoff ? this.state.initialLeftCutoff : this.state.updatedLeftOffset
                })
              }, ()=>{
                this.wavesurferLoad(this.state.mediaPlayer.cloudfrontURL, JSON.parse(this.state.mediaPlayer.peaks))

              })
            }
            else{
              api.getStemAudio("MP3", stemId)
                  .then(sourceRes=>{
                    this.setState(({loadedStems, mediaPlayer})=>{
                      return({
                        loadedStems:{
                          ...loadedStems,
                          [stemId]: {
                            ...loadedStems[stemId],
                            cloudfrontURL: sourceRes.data.url
                          }
                        },
                        mediaPlayer:{
                          ...mediaPlayer,
                          cloudfrontURL: sourceRes.data.url,
                          isLoading: false,
                          playSubmitted: false,
                        },
                        updatedLeftOffset: this.state.updatedLeftOffset < this.state.initialLeftCutoff ? this.state.initialLeftCutoff : this.state.updatedLeftOffset

                      })
                    }, ()=>{
                      this.wavesurferLoad(this.state.mediaPlayer.cloudfrontURL, JSON.parse(this.state.mediaPlayer.peaks))

                    })
                  })
            }
          })

        }
        else{
            api.getTrackForMediaPlayer(trackId)
              .then(res=>{

                let track = res.data.track
                this.setState(({loadedTracks, loadedStems, loadedVersions, mediaPlayer})=>{
                  let newStems = {}
                  let newVersions = {}
                  track.stems = track.stems.reduce((trackStems, stem)=>{
                    newStems[stem.id] = stem;
                    trackStems.push(stem.id)
                    return trackStems
                  },[])
                  track.versions = track.versions.reduce((trackVersions, version)=>{
                    newVersions[version.id] = version;
                    trackVersions.push(version.id)
                    return trackVersions
                  },[])

                  return({
                    loadedTracks: {
                      ...loadedTracks,
                      [trackId]: {
                        ...track,
                        retreivedAt: Date.now()
                      }
                    },
                    loadedStems: {
                      ...loadedStems,
                      ...newStems
                    },
                    loadedVersions: {
                      ...loadedVersions,
                      ...newVersions
                    },
                    mediaPlayer: {
                      ...mediaPlayer,
                      isTrackPlaying: false,
                      playSubmitted: false,
                    },
                    isVideoPlaying: false,
                    updatedLeftOffset: this.state.updatedLeftOffset < this.state.initialLeftCutoff ? this.state.initialLeftCutoff : this.state.updatedLeftOffset
                  })
                }, ()=>this.loadStemInMediaPlayer(trackId, stemId, idKey))
              })
        }

      }
      loadVersionInMediaPlayer = (trackId, versionId, idKey) =>{

        if(this.context.waveFormEl){
          this.handlePause()
        }
        if(this.state.visible){

        }
        else{
          this.showPlayer()
        }

        //check to see if loaded tracks (trackId) includes all needed properties for track
        let loadedTrack = this.state.loadedTracks[trackId]
        let loadedVersion = this.state.loadedVersions[versionId]


        if(loadedTrack?.imageURL && loadedVersion?.duration && loadedVersion?.peaks){
          //check to see if there is a cloudfrontURL available,
          if(this.state.waveFormEl){
            this.state.waveFormEl.pause()
          }
          this.setState(({loadedTracks, mediaPlayer})=>{
            return({
              mediaPlayer:{
                ...mediaPlayer,
                isTrackPlaying: false,
                trackId: loadedTrack.trackId,
                albumId: loadedTrack.albumId,
                artistId: loadedTrack.artistId,
                imageURL: loadedTrack.imageURL,
                trackTitleDisplay: loadedTrack.trackTitle + " - "+ loadedVersion.name,
                trackURL: loadedTrack.trackURL,
                artistNameDisplay: loadedTrack.artistName,
                artistURL: loadedTrack.artistURL,
                currentTime: 0,
                formattedCurrentTime: "0:00",
                durationDisplay: loadedVersion.duration,
                isLiked: loadedTrack.isLiked,
                peaks: loadedVersion.peaks,
                isPlaying: false,
                idKey: idKey,
                isLoading: true,
                playSubmitted: false,
              },
              isVideoPlaying: false,
              updatedLeftOffset: this.state.updatedLeftOffset < this.state.initialLeftCutoff ? this.state.initialLeftCutoff : this.state.updatedLeftOffset
            })
          }, ()=>{
              if(loadedVersion.cloudfrontURL){
                this.setState(({mediaPlayer})=>{
                  return({
                    mediaPlayer:{
                      ...mediaPlayer,
                      cloudfrontURL: loadedVersion.cloudfrontURL,
                      isLoading: false,
                      playSubmitted: false,
                    }
                  })
                }, ()=>{
                  this.wavesurferLoad(this.state.mediaPlayer.cloudfrontURL, JSON.parse(this.state.mediaPlayer.peaks))

                })
              }
              else{
                api.getVersionAudio("MP3", versionId)
                    .then(sourceRes=>{
                      this.setState(({loadedVersions, mediaPlayer})=>{
                        return({
                          loadedVersions:{
                            ...loadedVersions,
                            [versionId]: {
                              ...loadedVersions[versionId],
                              cloudfrontURL: sourceRes.data.url
                            }
                          },
                          mediaPlayer:{
                            ...mediaPlayer,
                            cloudfrontURL: sourceRes.data.url,
                            isLoading: false,
                            playSubmitted: false,
                          }
                        })
                      }, ()=>{
                        this.wavesurferLoad(this.state.mediaPlayer.cloudfrontURL, JSON.parse(this.state.mediaPlayer.peaks))

                      })
                    })
              }
            })

          }
          else{
              api.getTrackForMediaPlayer(trackId)
                .then(res=>{

                  let track = res.data.track
                  this.setState(({loadedTracks, loadedStems, loadedVersions, mediaPlayer})=>{
                    let newStems = {}
                    let newVersions = {}
                    track.stems = track.stems.reduce((trackStems, stem)=>{
                      newStems[stem.id] = stem;
                      trackStems.push(stem.id)
                      return trackStems
                    },[])
                    track.versions = track.versions.reduce((trackVersions, version)=>{
                      newVersions[version.id] = version;
                      trackVersions.push(version.id)
                      return trackVersions
                    },[])

                    return({
                      loadedTracks: {
                        ...loadedTracks,
                        [trackId]: {
                          ...track,
                          retreivedAt: Date.now()
                        }
                      },
                      loadedStems: {
                        ...loadedStems,
                        ...newStems
                      },
                      loadedVersions: {
                        ...loadedVersions,
                        ...newVersions
                      },
                      mediaPlayer: {
                        ...mediaPlayer,
                        isTrackPlaying: false,
                        playSubmitted: false,
                      },
                      isVideoPlaying: false,
                    })
                  }, ()=>this.loadVersionInMediaPlayer(trackId, versionId, idKey))
                })
          }

        }
    setVideoStudioWaveform = (waveformEl)=>{
      this.setState({videoStudioWaveform: waveformEl})
    }
    //helper function to get video studio timeline width
    getVideoTimelineWidthNew = ()=>{
      let videoTimeline = document.getElementById('video-timeline')
      let videoStudioControls = document.getElementById('videoStudioControls')
      let videoStudioDuration = document.getElementById('videoStudioDuration')
      if(videoTimeline){
          let width = videoTimeline.offsetWidth;
          let totalControlWidth = videoStudioControls.offsetWidth;
          let durationWidth = videoStudioDuration.offsetWidth;

          let waveFormInitialLeftCutoff = totalControlWidth - width - durationWidth
          return {videoTimelineWidth: width, initialLeftOffset: waveFormInitialLeftCutoff}
      }
      else{
        return null
      }
    }
    //draw/redraw VideoStudioTimeline
    drawVideoStudioTimeline = (retry)=>{
      //get tje width of the video Studio timeline

        if(!this.state.isMini && this.state.isMainOpen){
        let {videoTimelineWidth, initialLeftOffset} = this.getVideoTimelineWidthNew()

        if(videoTimelineWidth && initialLeftOffset && this.state.waveFormEl){
          let duration = this.state.waveFormEl.getDuration()

          let ratio = duration/this.state.videoDurationSeconds

          let waveWidth = ratio * videoTimelineWidth
          //get the change in position of the video studio waveform

          let changeInPos = initialLeftOffset - (this.state.updatedLeftOffset || initialLeftOffset);

          if(changeInPos >=0){
            this.setState({videoStudioWaveWidth: waveWidth,  initialLeftCutoff: initialLeftOffset, initialLeftOffset: initialLeftOffset},()=>{

                this.state.videoStudioWaveform.backend.peaks = JSON.parse(this.state.mediaPlayer?.peaks)
                this.state.videoStudioWaveform.drawBuffer()
                // let ratio = 0

            })
          } else if (changeInPos < 0){
            this.setState({videoStudioWaveWidth: waveWidth, initialLeftOffset: initialLeftOffset, initialLeftCutoff: initialLeftOffset,},()=>{

              this.state.videoStudioWaveform.backend.peaks = JSON.parse(this.state.mediaPlayer?.peaks)
              this.state.videoStudioWaveform.drawBuffer()
              // let ratio = 0

          })
          }



        }
        else if(videoTimelineWidth === 0){
          if(retry){
            setTimeout(()=>this.drawVideoStudioTimeline(true), 175)
          }
        }
      }
    }
    updateRegionNew = (region, updatedOffset)=>{


      if(this.state.waveFormEl){
        let regionKey = Object.keys(this.state.waveFormEl?.regions?.list || {})[0]

        let regionRef = this.state.waveFormEl?.regions?.list[regionKey]
        if(regionRef){
          this.state.waveFormEl.clearRegions();
        }
        if(this.state.waveFormEl){
          let regionEl =this.state.waveFormEl.addRegion({start: region.start, end: region.end,color: 'hsla(100, 100%, 30%, 0.1)', drag: false, })
          regionEl.resize = false

          this.setState({region: region, updatedLeftOffset: updatedOffset || this.state.updatedLeftOffset}, ()=>{
            if(!this.state.isMini){
              this.drawVideoStudioTimeline()
            }
          })
        }

      }
      else{
        setTimeout(()=>this.updateRegionNew(region, updatedOffset), 100)
      }

    }
    updateRegionAndPlay = (region, updatedOffset)=>{


      if(this.state.waveFormEl){
        let regionKey = Object.keys(this.state.waveFormEl?.regions?.list || {})[0]

        let regionRef = this.state.waveFormEl?.regions?.list[regionKey]
        if(regionRef){
          this.state.waveFormEl.clearRegions();
        }
        if(this.state.waveFormEl){
          let regionEl =this.state.waveFormEl.addRegion({start: region.start, end: region.end,color: 'hsla(100, 100%, 30%, 0.1)', drag: false, })
          regionEl.resize = false

          this.setState({region: region, updatedLeftOffset: updatedOffset || this.state.updatedLeftOffset}, ()=>{
            if(!this.state.isMini){
              this.drawVideoStudioTimeline()
            }
            this.handlePlay(0)

          })
        }

      }
      else{
        setTimeout(()=>this.updateRegionAndPlay(region, updatedOffset), 100)
      }

    }
  updateRegionNoRedraw = (region, updatedOffset)=>{


    if(this.state.waveFormEl){
      let regionKey = Object.keys(this.state.waveFormEl?.regions?.list || {})[0]

      let regionRef = this.state.waveFormEl?.regions?.list[regionKey]
      if(regionRef){
        this.state.waveFormEl.clearRegions();
      }
      if(this.state.waveFormEl){
        let regionEl =this.state.waveFormEl.addRegion({start: region.start, end: region.end,color: 'hsla(100, 100%, 30%, 0.1)', drag: false, })
        regionEl.resize = false

        this.setState({region: region, updatedLeftOffset: updatedOffset || this.state.updatedLeftOffset}, ()=>{

        })
      }

    }
    else{
      setTimeout(()=>this.setRegion(region), 100)
    }

  }
  wavesurferLoad = (cloudfrontURL, parsedPeaks)=>{

    //if video studio is open
    if(this.state.isMainOpen){
      //make sure wavesurferEl is loaded
      if(this.state.waveFormEl){

        this.state.waveFormEl.load(cloudfrontURL, parsedPeaks)

        // this.redrawCallback()
        this.state.waveFormEl.on("ready", ()=>{
          //set the region for the waveform
          let trackDuration = this.state.waveFormEl.getDuration()
          let start = 0
          let end =  start + this.state.videoDurationSeconds - this.state.trackStartTime
          let region = {
                start: start,
                end: end > trackDuration ? trackDuration : Math.abs(end),
                startFormatted: moment.duration(Math.floor(start), 'seconds').format("m:ss",{forceLength: true, trim: false}),
                endFormatted: moment.duration(Math.floor(end > trackDuration ? trackDuration : Math.abs(end)), 'seconds').format("m:ss",{forceLength: true, trim: false})
            }
           this.updateRegionAndPlay(region)
          //draw the waveform under the video if the player is not mini
          //
          // this.getVideoTimelineWidth()
        })
        this.state.waveFormEl.on('finish', ()=>{

          this.waveFinishedAction()
        })
        // this.state.waveFormEl.on("ready", ()=>{
        //   let startFormatted = moment.duration(0, 'seconds').format("m:ss",{forceLength: true, trim: false})
        //   let endFormatted = moment.duration(Math.floor(this.state.videoDurationSeconds), 'seconds').format("m:ss",{forceLength: true, trim: false})
        //   let region ={
        //     start: 0,
        //     end: this.state.videoDurationSeconds,
        //     startFormatted: startFormatted,
        //     endFormatted: endFormatted
        //   }
        //   this.setRegion(region)
        //   this.getVideoTimelineWidth()
        //   // this.initializeRegion()
        // })
      }
      else{
        setTimeout(()=>this.wavesurferLoad(cloudfrontURL, parsedPeaks), 100 )
      }
    }
    else{
      if(this.state.waveFormEl){
        this.state.waveFormEl.load(cloudfrontURL, parsedPeaks)
        this.state.waveFormEl.on("ready", ()=>{
          this.handlePlay()
        })
        this.state.waveFormEl.on('finish', ()=>{

          this.waveFinishedAction()
        })
      }
      else{
        setTimeout(()=>this.wavesurferLoad(cloudfrontURL, parsedPeaks), 100 )
      }
    }

  }
  wavesurferLoadNoPlay = (cloudfronURL, parsedPeaks)=>{

    if(this.state.waveFormEl){
      this.state.waveFormEl.on("ready", ()=>{
      })
      this.state.waveFormEl.load(cloudfronURL, parsedPeaks)

    }
    else{
      setTimeout(()=>this.wavesurferLoadNoPlay(cloudfronURL, parsedPeaks), 100 )
    }
  }
  handlePlay = (offset) => {
    // console.log('handlingPlay');
    if(this.state.isMainOpen) {

      if(offset === undefined || offset === null || isNaN(Number(offset))){
        if(this.state.playedSeconds > this.state.trackStartTime){
            this.setState(({mediaPlayer})=>{
              return({
                mediaPlayer: {
                  ...mediaPlayer,
                  isTrackPlaying: true
                },
                isVideoPlaying: true
              })
            }, ()=>{
              let regionKey = Object.keys(this.state.waveFormEl?.regions?.list)[0]

              let regionRef = this.state.waveFormEl?.regions?.list[regionKey]

              regionRef.play(this.state.playedSeconds + this.state.region.start - this.state.trackStartTime)
            })
          }
        else{
          this.setState({isVideoPlaying: true})
        }
      }
      else{
          this.state.videoElement.seekTo(offset + this.state.trackStartTime)
          this.setState(({mediaPlayer})=>{
            return({
              mediaPlayer: {
                ...mediaPlayer,
                isTrackPlaying: true
              },
              isVideoPlaying: true
            })
          }, ()=>{
            let regionKey = Object.keys(this.state.waveFormEl?.regions?.list)[0]

            let regionRef = this.state.waveFormEl?.regions?.list[regionKey]

            regionRef.play(offset + this.state.region.start - this.state.trackStartTime)
          })
      }
    }
    else{

      this.setState(({mediaPlayer})=>{
        return({
          mediaPlayer: {
            ...mediaPlayer,
            isTrackPlaying: true
          },
        })
      }, ()=>{
        this.state.waveFormEl.play()
      })
    }

  }
  handlePause = ()=>{
    if(this.state.isMainOpen){
      this.state.waveFormEl.pause()
      this.setState(({mediaPlayer})=>{
        return({
          mediaPlayer: {
            ...mediaPlayer,
            isTrackPlaying: false
          },
          isVideoPlaying: false
        })
      })

    }
    else{

      this.state.waveFormEl.pause()
      this.setState(({mediaPlayer})=>{
        return({
          mediaPlayer: {
            ...mediaPlayer,
            isTrackPlaying: false
          }
        })
      }, ()=>{
      })
    }
  }
  getVideoTimelineWidth =  throttle(()=>{

    let videoTimeline = document.getElementById('video-timeline')
    let videoStudioControls = document.getElementById('videoStudioControls')
    let videoStudioDuration = document.getElementById('videoStudioDuration')
    if(videoTimeline){
        let width = videoTimeline.offsetWidth;
        let totalControlWidth = videoStudioControls.offsetWidth;
        let durationWidth = videoStudioDuration.offsetWidth;

        let waveFormInitialLeftCutoff = totalControlWidth - width - durationWidth
        this.setState({videoTimelineWidth : width, initialLeftCutoff: waveFormInitialLeftCutoff}, ()=>{
          if(this.redrawCallback){
            // this.redrawCallback()
          }
          })
    }
}, 500)
openVideoLoader = ()=>{
    this.setState({isLoaderOpen: true})
}
closeVideoLoader = ()=>{
  if (this.props.history.location.search === "?videoStudioDemo") {
    this.setState(
      {
        isLoaderOpen: false,
      }, () => {
        this.props.history.replace("/")
      }
    );
    if (!this.props.user?.user_id) {
      this.hidePlayer();
    }
  }
  else {
    this.setState({isLoaderOpen: false})
  }
}
openVideoMain = ()=>{

        this.setState({isMainOpen: true},
        )
}
openVideoDemo = ()=>{
    this.setState({isDemoOpen: true})
}
closeVideoMain = () => {
    if(this.props.history.location.search === "?videoStudioDemo"){
      if (!this.props?.user?.user_id) {
        this.setState({
          isMainOpen: false,
          isDemoOpen: false,
          waveFormEl: null,
          isVideoPlaying: false,
          videoElement: null,
          videoURL: null,
          video: null,
        });
        this.hidePlayer();
      }
      else {
        this.setState(({ mediaPlayer }) => {
          return({
            mediaPlayer: {
              ...mediaPlayer,
              isTrackPlaying: false,
              currentTime: 0,
              formattedCurrentTime: "0:00"

            },
            isMainOpen: false,
            isDemoOpen: false,
            waveFormEl: null,
            isVideoPlaying: false,
            videoElement: null,
            videoURL: null,
            video: null,
            playedPercent: 0,
            playedSeconds: 0,
            playedFormatted:  moment.duration(Math.floor(0), 'seconds').format("m:ss",{forceLength: true, trim: false})
          })

        }, ()=>{
          if(this.state.mediaPlayer?.peaks){
            this.reloadPlayer(this.state.mediaPlayer.cloudfrontURL, JSON.parse(this.state.mediaPlayer.peaks))
            this.props.history.replace("/")
          }
          setTimeout(()=>{
            this.setState({isMini: false})
          }, 100)
        })
      }
    }
    else{
      this.setState(({mediaPlayer})=>{
        return({
          mediaPlayer: {
            ...mediaPlayer,
            isTrackPlaying: false,
            currentTime: 0,
            formattedCurrentTime: "0:00"
          },
          isMainOpen: false,
          isDemoOpen: false,
          waveFormEl: null,
          isVideoPlaying: false,
          videoElement: null,
          playedPercent: 0,
          playedSeconds: 0,
          playedFormatted:  moment.duration(Math.floor(0), 'seconds').format("m:ss",{forceLength: true, trim: false})
        })
      }, ()=>{
        if(this.state.mediaPlayer?.peaks){
          // this.unloadVideo(true)
          this.reloadPlayer(this.state.mediaPlayer.cloudfrontURL, JSON.parse(this.state.mediaPlayer.peaks))
        }
      })
    }
}
forceReload = ()=>{
  this.setState(({mediaPlayer})=>{
    return({
      mediaPlayer: {
        ...mediaPlayer,
        isTrackPlaying: false,
        currentTime: 0,
        formattedCurrentTime: "0:00"
      },
      waveFormEl: null,
      isVideoPlaying: false,
      videoElement: null,
      playedPercent: 0,
      playedSeconds: 0,
      playedFormatted:  moment.duration(Math.floor(0), 'seconds').format("m:ss",{forceLength: true, trim: false})
    })
  }, ()=>{
    if(this.state.mediaPlayer?.peaks){
      // this.unloadVideo(true)
      this.reloadPlayer(this.state.mediaPlayer.cloudfrontURL, JSON.parse(this.state.mediaPlayer.peaks))
    }
  })}
closeVideoDemo = ()=>{
  if(this.props.history.location.search === "?videoStudioDemo"){
    this.setState({isMainOpen: false, isDemoOpen: false, waveFormEl: null}, ()=>{
        this.props.history.replace("/")
        this.unloadVideo(true)
        if(this.state.mediaPlayer?.peaks){
          this.reloadPlayer(this.state.mediaPlayer.cloudfrontURL, JSON.parse(this.state.mediaPlayer.peaks))
        }
      })
}
else{
    this.setState({isMainOpen: false, isDemoOpen: false, waveFormEl: null},()=>{
      if(this.state.mediaPlayer?.peaks){
        this.reloadPlayer(this.state.mediaPlayer.cloudfrontURL, JSON.parse(this.state.mediaPlayer.peaks))
      }
    })
}
}
minifyPlayer = ()=>{

    this.setState({isMini: true})
}
maximizePlayer = ()=>{

    this.setState({isMini: false}, ()=>{
    })
}
setVideoElement = (element)=>{

  this.setState(({mediaPlayer})=>{
    return({
      mediaPlayer: {
        ...mediaPlayer,
        isTrackPlaying: false,
        currentTime: 0,
        formattedCurrentTime: "0:00"

      },
      videoElement: element
    })
  },()=>{
    this.setVideoDurationFromElement()
  })
}
loadVideo = (video)=>{

    let videoURL = URL.createObjectURL(video)
    if(this.state.waveFormEl){
      this.state.waveFormEl.pause()
    }
    this.setState(({mediaPlayer})=>{
      return({
        mediaPlayer: {
          ...mediaPlayer,
          isTrackPlaying: false,
          currentTime: 0,
          formattedCurrentTime: "0:00"
        },
        // video: video,
        videoURL: videoURL,
        isMainOpen: true,
        isDemoOpen: true,
        isLoaderOpen: false,
        isMini: false
      })
    })
}
unloadVideo = (toReopen)=>{
  this.setState(({mediaPlayer})=>{
    return({
      mediaPlayer: {
        ...mediaPlayer,
        isTrackPlaying: false,
        currentTime: 0,
        formattedCurrentTime: "0:00"
      },
      // video: video,
      videoURL: null,
      isMainOpen: false,
      isDemoOpen: false,
      waveFormEl: null,
      isLoaderOpen: !toReopen,
      isMini: false,
      video: null,
      videoElement: null,
      isVideoPlaying: false
    })
  }, ()=>{
    if(this.state.mediaPlayer?.peaks){
      this.reloadPlayer(this.state.mediaPlayer.cloudfrontURL, JSON.parse(this.state.mediaPlayer.peaks))
    }
  })
}
reloadPlayer=()=>{
  this.wavesurferLoadNoPlay(this.state.mediaPlayer.cloudfrontURL, JSON.parse(this.state.mediaPlayer.peaks))
}
reloadPlayerFromPublic = (audioProcessFunction)=>{

  // this.state.waveFormEl.pause()
  // this.state.waveFormEl.on('audioprocess', ()=>{

  // })
  // this.state.waveFormEl = null
  this.setState(({mediaPlayer, loadedTracks})=>{
    // audioProcessFunction.cancel()
    return({
      mediaPlayer:{
        ...mediaPlayer,
        isTrackPlaying: false,
        isLoading: true,
        currentTime: 0,
        formattedCurrentTime: "0:00",
        ratioCurrentTime: 0
      },
    })
  }, ()=>{
    // audioProcessFunction.cancel()
    this.setState(({loadedTracks})=>{
      for(const id in loadedTracks){

        loadedTracks[id].cloudfrontURL = null
      }
      return({
        loadedTracks:{
          ...loadedTracks,
        }})
    },()=>{
      // audioProcessFunction.cancel()
      this.loadTrackInMediaPlayer(this.state.mediaPlayer.trackId, this.state.mediaPlayer.idKey)

    })
  })
}
pruneURLs = ()=>{
  this.setState(({loadedTracks})=>{
    for(const id in loadedTracks){

      loadedTracks[id].cloudfrontURL = null
    }
    return({
      loadedTracks:{
        ...loadedTracks,
      }})
  })
}
setVideoDurationFromElement = ()=>{
  let duration = this.state.videoElement.getDuration()

  if(typeof duration === "number"){
      let formatted = moment.duration(Math.floor( duration), 'seconds').format("m:ss",{forceLength: true, trim: false})
      this.setState({
          videoDurationSeconds:  duration,
          videoDurationFormatted: formatted
      },()=>{

        // this.setRegion()
        this.initializeRegion()
      })
  }

}
initializeRegion(){

  this.state.waveFormEl.load(this.state.mediaPlayer.cloudfrontURL, JSON.parse(this.state.mediaPlayer.peaks))
  this.state.waveFormEl.on("ready", ()=>{
    let startFormatted = moment.duration(0, 'seconds').format("m:ss",{forceLength: true, trim: false})
    let endFormatted = moment.duration(Math.floor(this.state.videoDurationSeconds), 'seconds').format("m:ss",{forceLength: true, trim: false})
    let region ={
      start: 0,
      end: this.state.videoDurationSeconds,
      startFormatted: startFormatted,
      endFormatted: endFormatted
    }
    this.updateRegionNew(region)
    // this.getVideoTimelineWidth()
  })
}
setVideoDuration = (duration)=>{

  if(typeof duration === "number"){
      let formatted = moment.duration(Math.floor(duration), 'seconds').format("m:ss",{forceLength: true, trim: false})
      this.setState({
          videoDurationSeconds: duration,
          videoDurationFormatted: formatted
      })
  }

}
updateVideoProgress = (progress)=>{
  if(this.state.isVideoPlaying){
    let {played, playedSeconds} = progress;
    if(!this.state.mediaPlayer?.isTrackPlaying &&playedSeconds >= this.state.trackStartTime){
      let formatted = moment.duration(Math.floor(playedSeconds), 'seconds').format("m:ss",{forceLength: true, trim: false})
      this.setState(({mediaPlayer})=>{
        return({
          mediaPlayer: {
            ...mediaPlayer,
            isTrackPlaying: true,
            playedPercent: played,
            playedSeconds: playedSeconds,
            playedFormatted: formatted
          }
        })
      }, ()=>{
        let regionKey = Object.keys(this.state.waveFormEl?.regions?.list)[0]

        let regionRef = this.state.waveFormEl?.regions?.list[regionKey]


        regionRef.play(this.state.playedSeconds + this.state.region.start - this.state.trackStartTime)
      })
    }else{
      let formatted = moment.duration(Math.floor(playedSeconds), 'seconds').format("m:ss",{forceLength: true, trim: false})
      this.setState({
          playedPercent: played,
          playedSeconds: playedSeconds,
          playedFormatted: formatted
      })
    }

  }
}
updateVideoOffset =(leftOffset)=>{
  let {videoTimelineWidth} = this.getVideoTimelineWidthNew()


  let actualOffset = leftOffset - this.state.initialLeftCutoff;

  let offsetRatio = actualOffset/videoTimelineWidth;
  let trackStartTime = offsetRatio * this.state.videoDurationSeconds;
  if(trackStartTime < 0){
      trackStartTime = 0
  }
  this.setState({trackStartTime: trackStartTime})
}
videoSeekTo = (videoDurationPercentage)=>{

  if(this.state.isVideoPlaying){
    this.state.waveFormEl.pause()

      this.setState(({mediaPlayer})=>{
        return({
          mediaPlayer: {
            ...mediaPlayer,
            isTrackPlaying: false,
          },
          isVideoPlaying: false
        })
      }, ()=>{
        let parsedPercentage;
        if(videoDurationPercentage === 0){
            parsedPercentage =0.01
        }
        else if(videoDurationPercentage ===100){
            parsedPercentage = 0.99
        }
        else{
            parsedPercentage = videoDurationPercentage/100
        }
        if(this.state.videoElement){
            this.state.videoElement.seekTo(parsedPercentage, "fraction")
        }
        this.setState({
            playedPercent: parsedPercentage,
            playedSeconds: parsedPercentage * this.state.videoDurationSeconds,
            playedFormatted:  moment.duration(Math.floor(parsedPercentage * this.state.videoDurationSeconds), 'seconds').format("m:ss",{forceLength: true, trim: false})
        }, ()=>this.handlePlay())
      })


  }
  else{
    let parsedPercentage;
    if(videoDurationPercentage === 0){
        parsedPercentage =0.01
    }
    else if(videoDurationPercentage ===100){
        parsedPercentage = 0.99
    }
    else{
        parsedPercentage = videoDurationPercentage/100
    }
    if(this.state.videoElement){
        this.state.videoElement.seekTo(parsedPercentage, "fraction")
    }
    let videoSeekTime = parsedPercentage * this.state.videoDurationSeconds
    let trackSeekTime = videoSeekTime - (this.state.trackStartTime || 0) + (this.state.region?.start || 0)

    let trackSeekPercentage
    if(trackSeekTime >=0){
      trackSeekPercentage = trackSeekTime / this.state.waveFormEl.getDuration()
      this.state.waveFormEl.seekTo(trackSeekPercentage)
    }
    else{
      trackSeekTime = 0
      trackSeekPercentage = 0;
      this.state.waveFormEl.seekTo(trackSeekPercentage)
    }
    let formattedCurrentTime = moment.duration(Math.floor(trackSeekTime), 'seconds').format("m:ss",{forceLength: true, trim: false})
    this.setState(({mediaPlayer})=>{
      return({
        mediaPlayer:{
          ...mediaPlayer,
          currentTime: trackSeekTime,
          formattedCurrentTime: formattedCurrentTime
        }
      })
    }, ()=>{
        this.setState({
          playedPercent: parsedPercentage,
          playedSeconds: parsedPercentage * this.state.videoDurationSeconds,
          playedFormatted:  moment.duration(Math.floor(parsedPercentage * this.state.videoDurationSeconds), 'seconds').format("m:ss",{forceLength: true, trim: false})
        })
    })

  }

}
seekTo = (audioTrackPercentage, audioTrackDuration, regionStart, regionEnd)=>{
  let seekTime = 0;
  seekTime += this.state.trackStartTime
  seekTime += audioTrackPercentage * audioTrackDuration
  seekTime -= regionStart

  this.state.videoElement.seekTo(seekTime, "seconds")
  this.setState({
      playedPercent: seekTime/this.state.videoDurationSeconds,
      playedSeconds: seekTime,
      playedFormatted:  moment.duration(Math.floor(seekTime), 'seconds').format("m:ss",{forceLength: true, trim: false})
  })
}
playVideoLoop =debounce(()=>{

  this.setState({
      isVideoPlaying: false,
      playedPercent: this.state.trackStartTime > .05 ? (this.state.trackStartTime - .05)/this.state.videoDurationSeconds :  this.state.trackStartTime/this.state.videoDurationSeconds,
      playedSeconds: this.state.trackStartTime > .05 ?  this.state.trackStartTime - .05 : this.state.trackStartTime,
      playedFormatted: this.state.trackStartTime > .05 ? moment.duration(Math.floor(this.state.trackStartTime -.05), 'seconds').format("m:ss",{forceLength: true, trim: false}) : moment.duration(Math.floor(this.state.trackStartTime), 'seconds').format("m:ss",{forceLength: true, trim: false})
  }, ()=>{
      setTimeout(()=>{
          this.setState({isVideoPlaying: true},()=>{
              if(this.state.videoElement){
                  if(this.state.trackStartTime > .05){
                      this.state.videoElement.seekTo(this.state.trackStartTime -.1, "seconds")
                  }
                  else{
                      this.state.videoElement.seekTo(this.state.trackStartTime, "seconds")
                  }


              }
          })
      }, 100)
  })
}, 150, {"leading": true, "trailing": false})
  setRedrawCallback= (cb)=>{
    this.redrawCallback = cb
  }
  changeVideoVolume = (newVolume)=>{
    let {target} = newVolume
    let value = target.value
    this.setState({videoVolume: value})
}
toggleMute = ()=>{
  this.setState({isMuted: !this.state.isMuted})
}
toggleLoop = ()=>{
  this.setState({isLoopEnabled: !this.state.isLoopEnabled})
}
resetPlayed = ()=>{

  this.setState({
      playedSeconds: 0,
      isVideoPlaying: false
  })
}
replayVideo = ()=>{
  this.state.videoElement.seekTo(0, "seconds")
  this.setState({
      playedPercent: 0,
      playedSeconds: 0,
      playedFormatted:  moment.duration(Math.floor(0), 'seconds').format("m:ss",{forceLength: true, trim: false})
  })
}

  playCollectionURL = (collectionURL) => {
    // alert("Playing " + collectionURL);
    // query
    // api.getCollection
  }

  render() {
    return (
        <PlayerContext.Provider
          value={{
            visible: this.state.visible,
            playList: this.state.playList,
            trackIndex: this.state.trackIndex,
            playing: this.state.playing,
            projectId: this.state.projectId,
            collectionId: this.state.collectionId,
            featuredId: this.state.featuredId,
            volume: this.state.volume,
            isVideoStudioOpen: this.state.isVideoStudioOpen,
            hidePlayer: this.hidePlayer,
            showPlayer: this.showPlayer,
            setCurrentTime: this.setCurrentTime,
            playPause: this.playPause,
            playRegion: this.playRegion,
            pauseRegion: this.pauseRegion,
            setWaveFormEl: this.setWaveFormEl,
            setVideoStudioWaveform: this.setVideoStudioWaveform,
            waveFormEl: this.state.waveFormEl,
            setPlaylist: this.setPlaylist,
            playNext: this.playNext,
            playPrev: this.playPrev,
            likeTrack: this.likeTrack,
            unlikeTrack: this.unlikeTrack,
            setProjectId: this.setProjectId,
            setCollectionId: this.setCollectionId,
            setFeaturedId: this.setFeaturedId,
            setVolume: this.setVolume,
            toggleRegion: this.toggleRegion,
            updateRegion: this.updateRegion,
            setRegion: this.setRegion,
            setVideoStudio: this.setVideoStudio,
            user: this.props.user,
            triggerLoginModal: this.props.triggerLoginModal,
            region: this.state.region,
            regionPlaying: this.state.regionPlaying,
            pause: this.pause,
            updateTime: this.updateTime,
            setTrackOffsetStart: this.setTrackOffsetStart,
            currentTime: this.state.currentTime,
            formattedCurrentTime: this.state.formattedCurrentTime,
            ratioCurrentTime: this.state.ratioCurrentTime,
            offsetTrackStart: this.state.offsetTrackStart,
            playWithOffset: this.playWithOffset,
            resetTrackOffsetStart: this.resetTrackOffsetStart,
            autoPlay: this.state.autoPlay,
            setAutoPlay: this.setAutoPlay,
            loadedTracks: this.state.loadedTracks,
            loadedVersions: this.state.loadedVersions,
            loadedStems: this.state.loadedStems,
            loadedArtists: this.state.loadedArtists,
            loadedAlbums: this.state.loadedAlbums,
            updateLoadedTracks: this.updateLoadedTracks,
            loadTrackInMediaPlayer: this.loadTrackInMediaPlayer,
            loadTrackInMediaPlayerNoPlay: this.loadTrackInMediaPlayerNoPlay,
            mediaPlayer: this.state.mediaPlayer,
            handlePlay: this.handlePlay,
            handlePause: this.handlePause,
            getVersionInfo: this.getVersionInfo,
            getStemInfo: this.getStemInfo,
            getTracksInfo: this.getTracksInfo,
            areTracksLoading: this.state.areTracksLoading,
            isLoaderOpen: this.state.isLoaderOpen,
            isMainOpen: this.state.isMainOpen,
            isDemoOpen: this.state.isDemoOpen,
            isMini: this.state.isMini,
            video: this.state.video,
            videoURL: this.state.videoURL,
            isVideoPlaying: this.state.isVideoPlaying,
            videoDurationSeconds: this.state.videoDurationSeconds,
            videoDurationFormatted: this.state.videoDurationFormatted,
            playedPercent: this.state.playedPercent,
            playedSeconds: this.state.playedSeconds,
            playedFormatted: this.state.playedFormatted,
            videoVolume: this.state.videoVolume,
            isMuted: this.state.isMuted,
            videoTimelineWidth: this.state.videoTimelineWidth,
            initialLeftCutoff: this.state.initialLeftCutoff,
            getVideoTimelineWidth: this.getVideoTimelineWidth,
            trackStartTime: this.state.trackStartTime,
            videoElement: this.state.videoElement,
            isLoopEnabled: this.state.isLoopEnabled,
            openVideoLoader: this.openVideoLoader,
            closeVideoLoader: this.closeVideoLoader,
            openVideoMain: this.openVideoMain,
            openVideoDemo: this.openVideoDemo,
            closeVideoMain: this.closeVideoMain,
            closeVideoDemo: this.closeVideoDemo,
            minifyPlayer: this.minifyPlayer,
            maximizePlayer: this.maximizePlayer,
            setVideoElement: this.setVideoElement,
            loadVideo: this.loadVideo,
            unloadVideo: this.unloadVideo,
            setVideoDuration: this.setVideoDuration,
            updateVideoProgress: this.updateVideoProgress,
            updateVideoOffset: this.updateVideoOffset,
            videoSeekTo: this.videoSeekTo,
            seekTo: this.seekTo,
            playVideoLoop: this.playVideoLoop,
            setRedrawCallback: this.setRedrawCallback,
            updateRegionNew: this.updateRegionNew,
            updateRegionAndPlay: this.updateRegionAndPlay,
            videoStudioWaveform: this.state.videoStudioWaveform,
            updateRegionNoRedraw: this.updateRegionNoRedraw,
            updatedLeftOffset: this.state.updatedLeftOffset,
            videoStudioWaveWidth: this.state.videoStudioWaveWidth,
            loadStemInMediaPlayer: this.loadStemInMediaPlayer,
            loadVersionInMediaPlayer: this.loadVersionInMediaPlayer,
            drawVideoStudioTimeline: this.drawVideoStudioTimeline,
            reloadPlayer: this.reloadPlayer,
            reloadPlayerFromPublic: this.reloadPlayerFromPublic,
            isLoginModalOpen: this.props.isLoginModalOpen,
            pruneURLs: this.pruneURLs,
            changeVideoVolume: this.changeVideoVolume,
            toggleMute: this.toggleMute,
            toggleLoop: this.toggleLoop,
            resetPlayed: this.resetPlayed,
            replayVideo: this.replayVideo,
            forceReload: this.forceReload,
            playCollectionURL: this.playCollectionURL
          }}
        >
          {React.cloneElement(this.props.children, {user: this.props.user, triggerLoginModal: this.props.triggerLoginModal, isLoginModalOpen: this.props.isLoginModalOpen})}
        </PlayerContext.Provider>
    )
  }
}

const PlayerConsumer = PlayerContext.Consumer
PlayerProvider = withRouter(PlayerProvider)

export { PlayerProvider, PlayerConsumer, PlayerContext }


import React, { Component } from "react"
import MicroModal from "react-micro-modal"
import { NavLink } from "react-router-dom"
import { PlayerConsumer } from "../../PlayerContext"
import { ProjectsContext } from "../../ProjectsContext"
import { UserConsumer } from "../../contexts/User"
import { confirm } from "../confirmModal/index"
import CircularIndeterminant from "../Loading/circularIndeterminant"
import LoadingContainer from "../Loading/LoadingContainer"
import { Error, Success, SuccessRedirect } from "../Toast/index"
import api from "../utils/api"
import CreateProjectModal from "./createProjectModal"
import ProjectNotFound from "../NotFound/projectNotFound";
import PendingProjectItem from "./pendingProjectItem"
import ProjectItem from "./projectItem"
const queryString = require('query-string');

class Projects extends Component{
    state={
        isLoaded: false,
        newProjectName: "",
        newProjectClient: "",
        projectTypes: [{label: "Commercial", value: 1}, {label: "Movie", value: 2}],
        allProjects: [],
        myProjects: [],
        sharedProjects: [],
        completeProjects: [],
        invitedProjects: [],
        filteredCollaborators: [],
        activeOption: 0
    }
    handleInputChange = (event, projectId)=>{
        this.props.handleFormUnsaved(true)
        const target = event.target;
        const value = target.value;
        const name = target.name;

        if(name === "newProjectName"){
            let existingProjectNames = this.state.myProjects.map(project=>project.name)
            let isNameTaken = existingProjectNames.filter(name=>name?.toUpperCase()===value?.toUpperCase()).length > 0

            if(isNameTaken){
                this.setState({newProjectNameError: "Project name already used. Please choose a different name"})
            }
            else if(value.length < 1){
                this.setState({newProjectNameError: "Project name required"})
            }
            else{
                this.setState({newProjectNameError: ""})
            }
        }
        else if(name==="collaboratorEmail"){
            if(value.length > 2){
                let filteredOptions = this.context.myCollaborators.filter(collab=>{
                //     if(this.state.project.collaborators.filter(collaborator=>collaborator.email === collab.email || collaborator.em === collab.email).length >0)return false
                    if(projectId){
                        if(this.state.myProjects.filter(project=>project.id === projectId)[0].collaborators.filter(collaborator=>collaborator.email === collab.email || collaborator.em === collab.email).length >0)return false
                    }
                    return (collab.name.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1 || collab.email.toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) > -1)
                })
                this.setState({filteredCollaborators: filteredOptions})
            }
            else{
                this.setState({filteredCollaborators: []})
            }

        }
        this.setState({
            [name]: value,
        });
    }
    onKeyDown = (e, setValueCB)=>{
        const { activeOption, filteredCollaborators } = this.state;
        if(filteredCollaborators.length > 0){
            if (e.keyCode === 13) {
                e.preventDefault()
                setValueCB("collaboratorEmail", filteredCollaborators[activeOption].email)
                this.setState({
                    activeOption: 0,
                    showOptions: false,
                    collaboratorEmail: filteredCollaborators[activeOption].email,
                    filteredCollaborators: [],
                    lastProfilePhoto: filteredCollaborators[activeOption].profilePhoto
                });
            } else if (e.keyCode === 38) {
                if (activeOption === 0) {
                return;
                }
                this.setState({ activeOption: activeOption - 1 }, ()=>{
                    let activeElement = document.getElementsByClassName('option-active')[0]


                    activeElement.scrollIntoView()

                });
            } else if (e.keyCode === 40) {
                if (activeOption === filteredCollaborators.length - 1) {

                return;
                }
                this.setState({ activeOption: activeOption + 1 },()=>{
                    let activeElement = document.getElementsByClassName('option-active')[0]


                    activeElement.scrollIntoView()

                });
            }
        }
    }
    onClick= (email, setValueCB)=>{


        setValueCB("collaboratorEmail", email)
        this.setState({
            activeOption: 0,
            showOptions: false,
            collaboratorEmail: email,
            lastProfilePhoto: this.state.filteredCollaborators.filter(collab=>collab.email===email)[0].profilePhoto,
            filteredCollaborators: [],
        })
    }
    handleProjectTypeChange= (selectedType)=>{
        if(selectedType){
            this.setState({newProjectType: selectedType, projectTypeError: ""})
        }
    }
    successToastSubmit = (projectId)=>{

    }
    removeCollaborator = async (collaboratorId, projectId)=>{
        if(await confirm("Are you sure you want to remove this collaborator?", "Remove Collaborator", true)){
            api.removeCollaborator(collaboratorId, projectId)
                .then(res=>{
                    if(res.data.success){
                        let myProjects = [...this.state.myProjects]
                        let updatedProjIndex = myProjects.findIndex(proj=>proj.id===projectId)
                        let collaborators = myProjects[updatedProjIndex].collaborators
                        collaborators = collaborators.filter(collaborator=>collaborator.pjCId !== collaboratorId)
                        myProjects[updatedProjIndex].collaborators = collaborators
                        Success({message: "Collaborator Removed"})
                        this.setState({myProjects: myProjects})
                    }
                })
        }
    }
    addCollaboratorToState = (collaboratorEmail, collaboratorId, projectId)=>{
        let myProjects = [...this.state.myProjects]
        let updatedProjIndex = myProjects.findIndex(proj=>proj.id===projectId)
        let collaborators = myProjects[updatedProjIndex].collaborators
        collaborators.push({email: collaboratorEmail, pjCId: collaboratorId, status: "invited"})
        myProjects[updatedProjIndex].collaborators = collaborators
        this.setState({myProjects: myProjects})
    }
    submitNewProject = (cb)=>{
        if(!this.state.newProjectNameError && this.state.newProjectName.length > 0){
            let projectBody = {
                name: this.state.newProjectName,
            }
            api.submitNewProject(projectBody)
                .then(res=>{
                    if(res.data.success){
                        window.location.replace('/dashboard/projects/edit/'+ res.data.project.id)
                    }
                    else{
                        Error({message:"An error occurred!", onClose: this.errorToast})
                    }
                    cb()
                })
                .catch(error=>{
                    Error({message:"An error occurred!", onClose: this.errorToast})
                })
        }
        else{
            Error({message:"An error occurred!", onClose: this.errorToast})
            if(this.state.newProjectName.length < 1){
                this.setState({newProjectNameError: "Project name required"})
            }
        }
    }
    componentDidUpdate = (prevProps)=>{
        if(prevProps.match.params.filter !== this.props.match.params.filter){
            if (this.props.match.params.filter === "invitations"){
                let parsedQuery = queryString.parse(this.props.location.search)
                if(parsedQuery.eM){
                    api.confirmInvite(parsedQuery.em)
                    .then(res=>{
                        if(res.data){
                            this.setState({wrongEmail: true})
                        }
                        else{

                        }
                    })
                }

            }else{
                this.setState({wrongEmail: false})
            }
        }

    }
    componentDidMount = () => {
      console.log('Projects componentDidMount user', this.props.user);

        if (this.props.match.params.filter === "invitations"){
            let parsedQuery = queryString.parse(this.props.location.search)
            if(parsedQuery.eM){
                api.confirmInvite(parsedQuery.eM)
                .then(res=>{
                    if(res.data){
                    }
                    else{
                        this.setState({wrongEmail: true})

                    }
                })
            }

        }

        let projectTypes = []
        api.getAllProjectTypes()
            .then(res=>{
                res.data.forEach(projType=>{
                    projectTypes.push({label: projType.name, value: projType.id})
                })
                this.setState({projectTypes: projectTypes})
            })
        api.getMyProjects()
            .then(res=>{
                let completeProjects = []
                let myProjects = res.data.myProjects;
                let everySingleTrack = []
                myProjects.forEach((project, projInd)=>{
                    let tracks = []

                    project.scenes.forEach(scene=>{
                        scene.sceneTracks.forEach(sceneTrack=>{
                            let positiveVotes = sceneTrack.sceneTrackVotes.filter(vote=>vote.voteValue === 1).length
                            let negativeVotes = sceneTrack.sceneTrackVotes.length - positiveVotes
                            tracks.push({ ...sceneTrack.tracks, positiveVotes: positiveVotes, negativeVotes: negativeVotes})
                            if(everySingleTrack.findIndex(track=>track.trackId === sceneTrack.tracks.trackId) ===-1){
                                everySingleTrack.push({...sceneTrack.tracks})
                            }
                        })
                    })
                    let compare = (a, b)=> {
                        const scoreA = a.positiveVotes - a.negativeVotes;
                        const scoreB = b.positiveVotes - b.negativeVotes;

                        let comparison = 0;
                        if (scoreA > scoreB) {
                          comparison = -1;
                        } else if (scoreA < scoreB) {
                          comparison = 1;
                        }
                        return comparison *1;
                      }
                    tracks = tracks.sort(compare)
                    myProjects[projInd].allTracks = tracks
                    myProjects[projInd].isOwner = true
                    if(myProjects[projInd].projectLink !== null){
                        myProjects[projInd].projectLink.passwordEnabled =  myProjects[projInd].projectLink.password === null ? "disabled" : "enabled"
                    }
                    let activeCollabs = project.collaborators.filter(collaborator=> collaborator.status==="accepted" || collaborator.status === "accepted-complete")
                    activeCollabs.splice(0, 0, {firstName: project.ownerFn, lastName: project.ownerLn, email: project.ownerEmail, profilePhoto: project.ownerPhoto})
                    let collaboratorNum = activeCollabs.length
                    let myCollabPosition = activeCollabs.findIndex(collab=>collab.email.toLowerCase() === this.props.user.email.toLowerCase())
                    let tempCollab

                    switch(collaboratorNum){
                        case 0:
                        case 1:
                            break;
                        case 2:
                            tempCollab = activeCollabs[1]
                            activeCollabs[1] = activeCollabs[myCollabPosition]
                            activeCollabs[myCollabPosition] = tempCollab
                            break;
                        case 3:
                            tempCollab = activeCollabs[2]
                            activeCollabs[2] = activeCollabs[myCollabPosition]
                            activeCollabs[myCollabPosition] = tempCollab
                            break;
                        case 4:
                            tempCollab = activeCollabs[3]
                            activeCollabs[3] = activeCollabs[myCollabPosition]
                            activeCollabs[myCollabPosition] = tempCollab
                            break;
                        default:
                            tempCollab = activeCollabs[2]
                            activeCollabs[2] = activeCollabs[myCollabPosition]
                            activeCollabs[myCollabPosition] = tempCollab
                            break;
                    }
                    myProjects[projInd].activeCollabs = activeCollabs
                })
                myProjects = myProjects.filter(project=>{
                    if(project.status !=="complete"){
                      return true
                    }
                    else{
                      completeProjects.push(project)
                      return false
                    }
                  })
                let sharedProjects = res.data.sharedProjects;
                sharedProjects.forEach((project, projInd)=>{
                    let tracks = []

                    project.scenes.forEach(scene=>{
                        scene.sceneTracks.forEach(sceneTrack=>{
                            let positiveVotes = sceneTrack.sceneTrackVotes.filter(vote=>vote.voteValue === 1).length
                            let negativeVotes = sceneTrack.sceneTrackVotes.length - positiveVotes
                            tracks.push({ ...sceneTrack.tracks, positiveVotes: positiveVotes, negativeVotes: negativeVotes})
                            if(everySingleTrack.findIndex(track=>track.trackId === sceneTrack.tracks.trackId) ===-1){
                                everySingleTrack.push({...sceneTrack.tracks})
                            }
                        })
                    })
                    let compare = (a, b)=> {
                        const scoreA = a.positiveVotes - a.negativeVotes;
                        const scoreB = b.positiveVotes - b.negativeVotes;

                        let comparison = 0;
                        if (scoreA > scoreB) {
                          comparison = -1;
                        } else if (scoreA < scoreB) {
                          comparison = 1;
                        }
                        return comparison *1;
                      }
                    tracks = tracks.sort(compare)
                    sharedProjects[projInd].allTracks = tracks
                    sharedProjects[projInd].client = project.client
                    sharedProjects[projInd].id = project.id
                    sharedProjects[projInd].name = project.name
                    sharedProjects[projInd].collaborators = project.collaborators
                    sharedProjects[projInd].projectTypes = project.projectTypes
                    sharedProjects[projInd].projectActions = project.projectActions
                    let activeCollabs = project.collaborators.filter(collaborator=> collaborator.status==="accepted" || collaborator.status === "accepted-complete")
                    activeCollabs.splice(0, 0, {firstName: project.ownerFn, lastName: project.ownerLn, email: project.ownerEmail, profilePhoto: project.ownerPhoto})
                    let collaboratorNum = activeCollabs.length
                    let myCollabPosition = activeCollabs.findIndex(collab=>collab.email.toLowerCase() === this.props.user.email.toLowerCase())
                    let tempCollab

                    switch(collaboratorNum){
                        case 0:
                        case 1:
                            break;
                        case 2:
                            tempCollab = activeCollabs[1]
                            activeCollabs[1] = activeCollabs[myCollabPosition]
                            activeCollabs[myCollabPosition] = tempCollab
                            break;
                        case 3:
                            tempCollab = activeCollabs[2]
                            activeCollabs[2] = activeCollabs[myCollabPosition]
                            activeCollabs[myCollabPosition] = tempCollab
                            break;
                        case 4:
                            tempCollab = activeCollabs[3]
                            activeCollabs[3] = activeCollabs[myCollabPosition]
                            activeCollabs[myCollabPosition] = tempCollab
                            break;
                        default:
                            tempCollab = activeCollabs[2]
                            activeCollabs[2] = activeCollabs[myCollabPosition]
                            activeCollabs[myCollabPosition] = tempCollab
                            break;
                    }
                    project.activeCollabs = activeCollabs
                })
                let invitedProjects = []
                sharedProjects = sharedProjects.filter(project=>{
                    if(project.collabStatus === "accepted"){
                      return true
                    }
                    else if(project.collabStatus === "accepted-complete"){
                      completeProjects.push(project)
                      return false
                    }
                    else if(project.collabStatus === "invited" || project.collabStatus === "invited-notuser"){
                      invitedProjects.push(project)
                      return false
                    }
                    else{
                      return false
                    }
                  })
                myProjects = myProjects.sort(function(project1, project2){
                    let project1LastAction = project1.projectActions
                    if(project1LastAction.length > 0){
                        project1LastAction = project1LastAction[0].updatedOn
                    }
                    else{
                        project1LastAction = "0000-00-00T00:00:00.000Z"
                    }
                    let project2LastAction = project2.projectActions
                    if(project2LastAction.length > 0){
                        project2LastAction = project2LastAction[0].updatedOn
                    }
                    else{
                        project2LastAction = "0000-00-00T00:00:00.000Z"
                    }
                    if(project1LastAction > project2LastAction) return -1
                    if(project1LastAction < project2LastAction) return 1
                  })
                sharedProjects = sharedProjects.sort(function(project1, project2){
                    let project1LastAction = project1.projectActions
                    if(project1LastAction.length > 0){
                        project1LastAction = project1LastAction[0].updatedOn
                    }
                    else{
                        project1LastAction = "0000-00-00T00:00:00.000Z"
                    }
                    let project2LastAction = project2.projectActions
                    if(project2LastAction.length > 0){
                        project2LastAction = project2LastAction[0].updatedOn
                    }
                    else{
                        project2LastAction = "0000-00-00T00:00:00.000Z"
                    }
                    if(project1LastAction > project2LastAction) return -1
                    if(project1LastAction < project2LastAction) return 1
                  })
                let allProjects = myProjects.concat(sharedProjects)
                allProjects = allProjects.sort(function(project1, project2){
                    let project1LastAction = project1.projectActions
                    if(project1LastAction.length > 0){
                        project1LastAction = project1LastAction[0].updatedOn
                    }
                    else{
                        project1LastAction = "0000-00-00T00:00:00.000Z"
                    }
                    let project2LastAction = project2.projectActions
                    if(project2LastAction.length > 0){
                        project2LastAction = project2LastAction[0].updatedOn
                    }
                    else{
                        project2LastAction = "0000-00-00T00:00:00.000Z"
                    }
                    if(project1LastAction > project2LastAction) return -1
                    if(project1LastAction < project2LastAction) return 1
                  })
                this.props.updateLoadedTracks(everySingleTrack)
                this.setState({
                    allProjects: allProjects,
                    myProjects: myProjects,
                    sharedProjects: sharedProjects,
                    completeProjects: completeProjects,
                    invitedProjects: invitedProjects,
                    isLoaded: true
                })
            })
    }
    acceptInvitation=(projectId)=>{
        api.respondToInvite(projectId, {accepted: true})
            .then(res=>{
                if(res.data.success){
                    SuccessRedirect({message: "Invitation Accepted", onClose:()=>{this.props.history.push("/dashboard/projects/edit/"+projectId)}})
                }
                else{
                    Error({message: "An error occured"})
                }
            })
            .catch(error=>{
                Error({message: "An error occured"})
            })
    }
    declineInvitation=(projectId)=>{
        api.respondToInvite(projectId, {accepted: false})
        .then(res=>{
            if(res.data.success){
                Success({message: "Invitation Declined"})
                let invitedProjects = [...this.state.invitedProjects]
                invitedProjects = invitedProjects.filter(project=>project.id !== projectId)
                this.setState({invitedProjects: invitedProjects})
            }
            else{
                    Error({message: "An error occured"})
                }
        })
        .catch(error=>{
            Error({message: "An error occured"})
        })
    }
    updateParent=(name, client, projectType, projectId)=>{
        let myProjects = [...this.state.myProjects]
        let projIndex = myProjects.findIndex(project=>project.id === projectId)
        myProjects[projIndex].name = name
        myProjects[projIndex].client = client
        myProjects[projIndex].projectTypes = {name: projectType.label, id: projectType.value}
        this.setState({myProjects: myProjects})
    }
    deleteCB=(projectId)=>{
        let myProjects = [...this.state.myProjects]
        let completeProjects = [...this.state.completeProjects]
        myProjects = myProjects.filter(project=>project.id !== projectId)
        completeProjects = completeProjects.filter(project=>project.id !== projectId)
        this.setState({myProjects: myProjects, completeProjects: completeProjects})
    }
    leaveProjectCB = (projectId)=>{
        let sharedProjects = [...this.state.sharedProjects]
        sharedProjects = sharedProjects.filter(project=>project.id !== projectId)
        this.setState({sharedProjects: sharedProjects})
    }
    markIncompleteCB = (projectId)=>{
        let allProjects = [...this.state.allProjects]
        let sharedProjects = [...this.state.sharedProjects]
        let myProjects = [...this.state.myProjects]
        let completeProjects = [...this.state.completeProjects]
        completeProjects = completeProjects.filter(project=>{
            if(project.id === projectId){
                project.status="active"
                if(project.isOwner){
                    myProjects.unshift(project)
                }
                else{
                    sharedProjects.unshift(project)
                }
                allProjects.unshift(project)
            }
            else{
                return true
            }
        })

        this.setState({allProjects: allProjects,myProjects: myProjects, completeProjects: completeProjects, sharedProjects: sharedProjects})
    }
    markCompleteCB = (projectId)=>{
        let allProjects = [...this.state.allProjects]
        let myProjects = [...this.state.myProjects]
        let sharedProjects = [...this.state.sharedProjects]
        let completeProjects = [...this.state.completeProjects]
        myProjects = myProjects.filter(project=>{
            if(project.id === projectId){
                project.status="complete"

                completeProjects.unshift(project)
            }
            else{
                return true
            }
        })
        sharedProjects =  sharedProjects.filter(project=>{
            if(project.id === projectId){
                project.status="accepted-complete"
                completeProjects.unshift(project)
            }
            else{
                return true
            }
        })
        allProjects = allProjects.filter(project=>project.id !== projectId)
        this.setState({allProjects: allProjects,myProjects: myProjects, completeProjects: completeProjects, sharedProjects: sharedProjects})
    }
    generateProjectLink = (projectId)=>{
        api.generateProjectLink(projectId || this.state.project?.id)
            .then(res=>{
                if(res.data.success){
                    let projectLinkData = res.data.projectLink
                    let myProjects = [...this.state.myProjects]
                    let projIndex = myProjects.findIndex(project=>project.id === projectId)
                    myProjects[projIndex].projectLink ={
                        projectLink: projectLinkData.url,
                        projectLinkActive: projectLinkData.status,
                        projectLinkId: projectLinkData.id
                    }
                    this.setState({myProjects: myProjects})
                }

            })
    }
    enableLink=(isEnabled, projectId)=>{
        // e.preventDefault()

        let myProjects = [...this.state.myProjects]
        let projIndex = myProjects.findIndex(project=>project.id === projectId)
        myProjects[projIndex].projectLink.projectLinkActive = isEnabled
        // project.projectLinkActive = isEnabled
        this.setState({myProjects: myProjects})
    }
    enablePassword=(isEnabled, projectId)=>{
        let myProjects = [...this.state.myProjects]
        let projIndex = myProjects.findIndex(project=>project.id === projectId)
        myProjects[projIndex].projectLink.passwordEnabled = isEnabled
        this.setState({myProjects: myProjects})
    }
    updateProjectLink= async (projectLinkId, projectId)=>{
        let myProjects = [...this.state.myProjects]
        let projIndex = myProjects.findIndex(project=>project.id === projectId)
        let projectBody={
            passwordSubmit: myProjects[projIndex].projectLink.passwordEnabled ==="enabled" && myProjects[projIndex].projectLink.projectLinkActive === "active" ? myProjects[projIndex].projectLink.password : null,
            status: myProjects[projIndex].projectLink.projectLinkActive
        }
        return api.updateProjectLink(projectLinkId, projectBody)
            .then(res=>{

                Success({message: "Share URL Updated!"})

                if(myProjects[projIndex].projectLink.passwordEnabled === "disabled" || myProjects[projIndex].projectLink.projectLinkActive === "disabled"){
                    myProjects[projIndex].projectLink.password = ""
                    myProjects[projIndex].projectLink.passwordEnabled = "disabled"
                    this.setState({myProjects: myProjects})
                }
                return res
            })
    }

    updatePassword=(event, cb, projectId)=>{
        event.preventDefault()
        let {name ,value} = event.target
        let myProjects = [...this.state.myProjects]
        let projIndex = myProjects.findIndex(project=>project.id === projectId)

        myProjects[projIndex].projectLink.password = value
        cb(value)
        this.setState({myProjects: myProjects})
    }
    clearPW=(projectId)=>{

        let myProjects = [...this.state.myProjects]
        let projIndex = myProjects.findIndex(project=>project.id === projectId)
        if(myProjects[projIndex].projectLink.password === true){
            myProjects[projIndex].projectLink.password = ""
            this.setState({myProjects: myProjects})
        }
    }
    pwBlur=(projectId)=>{
        let myProjects = [...this.state.myProjects]
        let projIndex = myProjects.findIndex(project=>project.id === projectId)
        if(myProjects[projIndex].projectLink.password === "" && myProjects[projIndex].projectLink.passwordExists){
            myProjects[projIndex].projectLink.password = true
            this.setState({myProjects: myProjects})
        }
    }
    render(){
        return(
          this.state.wrongEmail ? (
            <ProjectNotFound>
              <div>
                Looks like that invite was meant for another email. <br/>Please contact the project administrator and have them invite the correct one.
              </div>
            </ProjectNotFound>
          ) : (
            <div class="container mb-40 lg:mb-80">
              {/* {# App Header #}*/}
              <div class="view-header">
                  <h1 class="view-title">Projects</h1>
                  <div class="view-header-controls">
                      <MicroModal
                          trigger={handleOpen => <button onClick={handleOpen} class="btn btn-primary" data-micromodal-trigger="create_project_modal">
                                  <span class="icon-plus mr-5"></span> Create Project
                              </button>}
                          closeOnEscapePress={true}
                          closeOnOverlayClick={true}
                          closeOnAnimationEnd={true}
                          modalOverlayClassName="modal-overlay"
                          modalClassName="modal micromodal-slide is-open"
                          modalOverlayStyles={null}
                          children={handleClose=><CreateProjectModal handleClose={()=>{handleClose();this.props.handleFormUnsaved(false)}} handleInputChange={this.handleInputChange} newProjectName={this.state.newProjectName} clientName={this.state.newProjectName}
                              newProjectType={this.state.newProjectType} projectTypes={this.state.projectTypes} handleProjectTypeChange={this.handleProjectTypeChange} submitNewProject={this.submitNewProject} newProjectNameError={this.state.newProjectNameError}
                              projectTypeError={this.state.projectTypeError}/>}
                          containerStyles={{background: "#131313", padding:"asdf", maxWidth: "40.625rem !important", maxHeight: "100vh", borderRadius: "asdf", overflowY: "auto", boxSizing: "border-box"}}
                      />


                  </div>
              </div>
              {/* {# Subnav #} */}
              <div className="tabs mb-40">
                  <ul className="tab-items">
                      <li className={`tab-item ${this.props.match.params.filter.toLowerCase() === "all" ? "active" : ""}`}>
                          <NavLink to="/dashboard/projects/all" className='tab-item-title' >All</NavLink>
                      </li>
                      <li className={`tab-item ${this.props.match.params.filter.toLowerCase() === "my-projects" ? "active" : ""}`}>
                          <NavLink to="/dashboard/projects/my-projects" className="tab-item-title">My Projects</NavLink>
                      </li>
                      <li className={`tab-item ${this.props.match.params.filter.toLowerCase() === "shared" ? "active" : ""}`}>
                          <NavLink to="/dashboard/projects/shared" className="tab-item-title">Shared With Me</NavLink>
                      </li>
                      <li className={`tab-item ${this.props.match.params.filter.toLowerCase() === "invitations" ? "active" : ""}`}>
                          <NavLink to="/dashboard/projects/invitations" className="tab-item-title">Invitations <span class="tab-item-notifier">{this.state.invitedProjects.length >0 && this.state.invitedProjects.length}</span></NavLink>
                      </li>
                      <li className={`tab-item ${this.props.match.params.filter.toLowerCase() === "completed" ? "active" : ""}`}>
                          <NavLink to="/dashboard/projects/completed" className="tab-item-title">Completed</NavLink>
                      </li>
                  </ul>
              </div>

              {/* Projects */}
              {!this.state.isLoaded && <CircularIndeterminant forceCenter/>}
              {(this.state.isLoaded && this.props.match.params.filter.toLowerCase() === "all") &&
                  (this.state.allProjects.length ?
                      this.state.allProjects.map(project=>{
                          return(
                              <ProjectItem name={project.name} tracks={project.allTracks} projectId={project.id} collaborators={project.collaborators} isOwner={project.isOwner} addCollaboratorToState={this.addCollaboratorToState} removeCollaborator={this.removeCollaborator}
                                  projectType={project.projectTypes} client={project.client} handleFormUnsaved={this.props.handleFormUnsaved}  projectTypes={this.state.projectTypes}  coverArt={project.coverArt}  updateParent={this.updateParent} complete={project.status === "complete"}
                                  purchaseId={project.purchaseId} deleteCB={this.deleteCB} markCompleteCB={this.markCompleteCB} isOwner={project.isOwner} handleInputChange={this.handleInputChange}
                                  projectLink={project.projectLink?.projectLink} projectLinkActive={project.projectLink?.projectLinkActive} passwordEnabled={project.projectLink?.passwordEnabled} enableLink={this.enableLink} enablePassword={this.enablePassword} updateProjectLink={this.updateProjectLink}
                                  updatePassword={this.updatePassword} password={project.projectLink?.password} projectLinkId={project.projectLink?.projectLinkId} clearPW={this.clearPW} pwBlur={this.pwBlur} generateProjectLink={this.generateProjectLink} collaboratorEmail={this.state.collaboratorEmail} filteredCollaborators={this.state.filteredCollaborators}
                                  onKeyDown={this.onKeyDown} activeOption={this.state.activeOption} onClick={this.onClick} updateLatest={this.context.updateLatest} ownerEmail={project.ownerEmail} ownerName={project.ownerFn + " " +project.ownerLn} ownerPhoto={project.ownerPhoto} activeCollabs={project.activeCollabs} userSet={project.userSet}/>
                          )
                      })
                  :
                      <div class="prompt" data-micromodal-trigger="create_project_modal">
                          <div class="prompt-content">
                              <div class="text-icon-xl mb-15"><span class="icon-add-project-lg"></span></div>
                              <h3 class="prompt-title">You do not have any projects.</h3>
                              <p>Add some projects.</p>
                          </div>
                      </div>
                  )
              }
              {(this.state.isLoaded && this.props.match.params.filter.toLowerCase() === "my-projects") &&
                  (this.state.myProjects.length ?
                      this.state.myProjects.map(project=>{
                          return(
                              <ProjectItem name={project.name} tracks={project.allTracks} projectId={project.id}  collaborators={project.collaborators} isOwner={project.isOwner} addCollaboratorToState={this.addCollaboratorToState} removeCollaborator={this.removeCollaborator}
                                  projectType={project.projectTypes} client={project.client} handleFormUnsaved={this.props.handleFormUnsaved} projectTypes={this.state.projectTypes} coverArt={project.coverArt} updateParent={this.updateParent} complete={project.status === "complete"}
                                  purchaseId={project.purchaseId} deleteCB={this.deleteCB} markCompleteCB={this.markCompleteCB} isOwner={project.isOwner} handleInputChange={this.handleInputChange}
                                  projectLink={project.projectLink?.projectLink} projectLinkActive={project.projectLink?.projectLinkActive} passwordEnabled={project.projectLink?.passwordEnabled} enableLink={this.enableLink} enablePassword={this.enablePassword} updateProjectLink={this.updateProjectLink}
                                  updatePassword={this.updatePassword} password={project.projectLink?.password} projectLinkId={project.projectLink?.projectLinkId} clearPW={this.clearPW} pwBlur={this.pwBlur} generateProjectLink={this.generateProjectLink}
                                  collaboratorEmail={this.state.collaboratorEmail} filteredCollaborators={this.state.filteredCollaborators} userSet={project.userSet}
                                  onKeyDown={this.onKeyDown} activeOption={this.state.activeOption} onClick={this.onClick}  updateLatest={this.context.updateLatest} ownerEmail={project.ownerEmail} ownerName={project.ownerFn + " " +project.ownerLn} ownerPhoto={project.ownerPhoto} activeCollabs={project.activeCollabs}/>
                          )
                      })
                  :
                      <div class="prompt" data-micromodal-trigger="create_project_modal">
                          <div class="prompt-content">
                              <div class="text-icon-xl mb-15"><span class="icon-add-project-lg"></span></div>
                              <h3 class="prompt-title">You do not have any projects.</h3>
                              <p>Add some projects.</p>
                          </div>
                      </div>
                  )
              }
              {(this.state.isLoaded && this.props.match.params.filter.toLowerCase() === "shared") &&
                  (this.state.sharedProjects.length ?
                      this.state.sharedProjects.map(project=>{
                          return(
                              <ProjectItem name={project.name} complete={project.status === "accepted-complete"} markCompleteCB={this.markCompleteCB} tracks={project.allTracks} projectId={project.id}  collaborators={project.collaborators} leaveProjectCB={this.leaveProjectCB} projectLink={project?.projects?.projectLink?.projectLink} projectLinkActive={project?.projects?.projectLink?.projectLinkActive}  updateLatest={this.context.updateLatest}
                              ownerEmail={project?.ownerEmail} ownerName={project?.ownerFn + " " +project?.ownerLn} ownerPhoto={project?.ownerPhoto}  handleInputChange={this.handleInputChange} handleFormUnsaved={this.props.handleFormUnsaved} activeCollabs={project.activeCollabs} userSet={project.userSet}/>
                          )
                      })
                  :
                      <div class="prompt" data-micromodal-trigger="create_project_modal">
                          <div class="prompt-content">
                              <div class="text-icon-xl mb-15"><span class="icon-no-tracks-lg"></span></div>
                              <h3 class="prompt-title">You have not been shared on any projects.</h3>
                              <p>To see something here, first accept a project invitation.</p>
                          </div>
                      </div>
                  )
              }
              {(this.state.isLoaded && this.props.match.params.filter.toLowerCase() === "invitations") &&
                  (this.state.invitedProjects.length ?
                      this.state.invitedProjects.map(project=>{
                          return(
                              <PendingProjectItem name={project.name} tracks={project.allTracks} projectId={project.id}  collaborators={project.collaborators} acceptInvitation={this.acceptInvitation} declineInvitation={this.declineInvitation}/>
                          )
                      })
                  :
                      <div class="prompt" data-micromodal-trigger="create_project_modal">
                          <div class="prompt-content">
                              <div class="text-icon-xl mb-15"><span class="icon-no-tracks-lg"></span></div>
                              <h3 class="prompt-title">You have no current project invitations.</h3>
                              <p>Make sure your collaborator used the correct email address to invite you.</p>
                          </div>
                      </div>
                  )
              }
              {(this.state.isLoaded && this.props.match.params.filter.toLowerCase() === "completed") &&
                  (this.state.completeProjects.length ?
                      this.state.completeProjects.map(project=>{
                          return(
                              <ProjectItem name={project.name} tracks={project.allTracks} projectId={project.id}  collaborators={project.collaborators} isOwner={project.isOwner} complete={true} deleteCB={this.deleteCB} markIncompleteCB={this.markIncompleteCB}
                              purchaseId={project.purchaseId} deleteCB={this.deleteCB} markCompleteCB={this.markCompleteCB} isOwner={project.isOwner}  updateLateset={this.context.updateLateset} ownerEmail={project.ownerEmail || project?.ownerEmail} ownerName={project.ownerFn || project?.ownerFn + " " +project.ownerLn || project?.ownerLn} ownerPhoto={project.ownerPhoto || project?.ownerPhoto}
                              activeCollabs={project.activeCollabs} userSet={project.userSet}/>
                          )
                      })
                  :
                      <div class="prompt" data-micromodal-trigger="create_project_modal">
                          <div class="prompt-content">
                              <div class="text-icon-xl mb-15"><span class="icon-no-tracks-lg"></span></div>
                              <h3 class="prompt-title">You have no completed projects.</h3>
                              <p>Complete some projects.</p>
                          </div>
                      </div>
                  )
              }
              {/* {# Project Item #} */}

            </div>
          )
        )
    }
}
Projects.contextType = ProjectsContext
export const ProjectsWrapped = (props) => (
  <UserConsumer>
    {({ user, userLoaded}) => (
      <LoadingContainer isLoaded={userLoaded}>
        <PlayerConsumer>
          {({updateLoadedTracks})=>(
            <Projects user={user} {...props} updateLoadedTracks={updateLoadedTracks}/>
          )}
        </PlayerConsumer>
      </LoadingContainer>
    )}
  </UserConsumer>

)
// export default Projects;

import axios from "axios";

const productsAPI = {
  getProductByKey: function ({ key }) {
    return axios({
      method: "get",
      url: "/api/products/byKey/" + key,
    });
  }
}
export default productsAPI;

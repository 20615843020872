import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { handleAPIError } from '../../utils/handleAPIError';
import { Error, Success } from '../Toast';
import api from '../utils/api';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from "joi";

const queryString = require('query-string')
const params = queryString.parse(window.location.search);

const schema = Joi.object({
  email: Joi.string()
      .email({ tlds: { allow: false } })
      .required()
      .messages({
          "any.required": "Please enter an Email Address",
          "string.empty": "Please enter an Email Address",
          "string.email": "Please enter a valid Email Address",
      }),
  password: Joi.string()
      .required()
      .messages({
          "string.required": "Please enter a Password",
      })
});

const LoginForm = (props) => {

  const { control, register, watch, handleSubmit, formState, setError, errors } = useForm({
    mode: "onSubmit",
    reValidateMode: 'onTouched',
    defaultValues: {},
    resolver: joiResolver(schema),
    context: undefined,
    criteriaMode: "firstError",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const [loginEmail, setLoginEmail] = useState(null);
  const [loginAction, setLoginAction] = useState('login');
  const [isPasswordResetSent, setIsPasswordResetSent] = useState(false);


  useEffect(() => {
    // Show all errors as Toastify
    Object.entries(errors).map((error) => (
        Error({
            message: error[1].message,
            toastId: error[0]
        })
    ))
  },[errors]);

  const onSubmit = (data, event) => {
    console.log('submit data', data);
    console.log('loginAction', loginAction);
    event.preventDefault();

    const loginFunc = props.login;

    if (loginAction === "reset" && loginEmail) {
      api.forgotPassword({email: data.email})
        .then(res => {
          console.log('forgotPassword res', res);
          Success({
            message: "Password reset email was sent."
          });
          setIsPasswordResetSent(true);
        })
        .catch(error => {
          handleAPIError(error);
        })
    }
    else if (loginAction === "login" && data.email && data.password) {
      loginFunc(
        {
          email: data.email,
          password: data.password
        },
        params?.redirect?.replace(/['"]+/g, ''),
        props.reapplyPromo
      )
    }
    else {
      Error({
        message: "Please enter a valid email and password."
      })
    }
  }


  return (
    <form onSubmit={handleSubmit(onSubmit)} >
        {props.loginAction === "reset" && (
            <h3 className="">Password Reset</h3>
        )}
        <div className="grid-row-sm">
            <div className="col w-full">
                <div className="input-group-sm input-inline">
                    <input
                        className="form-input"
                        aria-describedby="emailBlock"
                        autoComplete="email"
                        type="email"
                        name="email"
                        placeholder="Email Address"
                        ref={register}
                    />
                    <label className="input-label" htmlFor="email">Email Address</label>
                </div>
            </div>
        </div>

        {props.loginAction === "login" && (
            <div className="grid-row-sm">
                <div className="col w-full">
                    <div className="input-group-sm input-inline">
                        <input
                          className="form-input"
                          autoComplete="new-password"
                          type="password"
                          name="password"
                          placeholder="Password"
                          ref={register}
                        />
                        <label className="input-label" htmlFor="password">Password</label>
                    </div>
                </div>
            </div>
        )}

        <div className="input-group-sm">
            {loginAction === "reset" ? (
              isPasswordResetSent ? (
                  <button
                    type="button"
                    className="btn btn-stroke form-btn w-full"
                    disabled
                  >
                    Password Reset Sent
                  </button>
              ) : (
                  <button
                    className="btn btn-primary form-btn w-full"
                    name="reset"
                    type="submit"
                  >
                    Send Password Reset
                  </button>
              )
            ) : (
              <button
                className="btn btn-primary form-btn w-full"
                name="login"
                type="submit"
              >
                Sign In
              </button>
            )}
        </div>
        <div className="flex flex-wrap justify-between text-gray-400 text-sm">
            {loginAction === "reset" ? (
                <a
                  href="# "
                  onClick={(event) => {
                    event.preventDefault();
                    setLoginAction("login");
                  }}
                  className="text-link-gray py-3"
                >
                  Back to Sign In
                </a>
            ) : (
              <a
                href="# "
                onClick={(event) => {
                  event.preventDefault();
                  setLoginAction("reset");
                }}
                className="text-link-gray py-3"
              >
                Forgot Password?
              </a>
            )}
            <div
              onClick={()=>props.toggleLogin(false)}
              className="text-link-gray py-3"
            >
              Don't have an account?
            </div>
        </div>
    </form>
  )
}

export default LoginForm
